import * as actions from "./actionTypes";
import invoiceAPIS from "api/InvoiceAPIs/index";
import randomColor from "randomcolor";

const fetch_appdetails_withModule = (app, customerId) => async (dispatch) => {
  const filter = { appIds: [...app], customerId: customerId };

  dispatch({
    type: "FETCHING_APP_USAGE",
  });
  try {
    const res = await invoiceAPIS.fetch_app_module_for_graph(
      JSON.stringify(filter)
    );
    console.log(
      "ressssssssssssssssssssssssssssssssssssssssssssssssssssssssss",
      res
    );
    const usageData = res.data.data || [];
    let filterData = [];
    let appData = usageData.appData || [];
    let app_N = 0;
    appData.map((row, id) => (app_N = app_N + row.total));
    appData.map((row, id) => {
      filterData.push({
        name: row.name,
        area: ((row.total / app_N) * 100).toFixed(2),
        appId: row.application_id,
      });
    });

    let moduleData = {};
    let module_data = usageData.moduleData || [];
    module_data.map((row, id) => {
      Object.keys(row).map((mod, i) => {
        let n = 0;
        let moduleArray = row[mod] || [];
        //find n
        moduleArray.map((i) => (n = n + i.total));
        //create new module array
        moduleData[mod] = moduleArray.map((mod, i) => {
          return {
            name: mod.name,
            id: mod.id,
            area: ((mod.total / n) * 100).toFixed(2),
          };
        });
        return 0;
      });
      return 0;
    });

    dispatch({
      type: "FETCH_APP_USAGE_DATA",
      payload: {
        filterData,
        moduleData,
      },
    });
  } catch (err) {
    dispatch({
      type: "FETCH_FAIL_APP_USAGE",
    });
  }
};

const actionfetchAllCreditDebitInvoices = (id) => {
  console.log({ actions });
  return async (dispatch) => {
    dispatch({
      type: actions.FETCHING_CREDIT_DEBIT_INVOICE,
      loading: true,
    });
    try {
      let res = await invoiceAPIS.fetchAllCredit_Debit_Notes(id);
      dispatch({
        type: actions.FETCHING_CREDIT_DEBIT_INVOICE_SUCCESS,
        loading: false,
        totalCount: res._totalcount,
        data: res.data || [],
      });
    } catch (err) {
      dispatch({
        type: actions.FETCHING_CREDIT_DEBIT_INVOICE_FAILURE,
        loading: false,
        error: err,
      });
    }
  };
};

const addCreditNotes = (id, payload) => {
  return async (dispatch) => {
    dispatch({
      type: actions.ADD_CREDITNOTES,
      loading: true,
    });
    try {
      let res = await invoiceAPIS.addCreditNotes(id, payload);
      dispatch({
        type: actions.ADD_CREDITNOTES_SUCCESS,
        loading: false,
        payload: res.data.data || [],
      });
      return res;
    } catch (err) {
      dispatch({
        type: actions.ADD_CREDITNOTES_FAILURE,
        loading: false,
        error: err,
      });
      return err;
    }
  };
};

const getCreditdetailsById = (Id, invoiceId, payload) => {
  return async (dispatch) => {
    dispatch({
      type: actions.GET_CREDITNOTES,
      loading: true,
    });
    try {
      let res = await invoiceAPIS.getCreditdetailsById(Id, invoiceId, payload);
      dispatch({
        type: actions.GET_CREDITNOTES_SUCCESS,
        loading: false,
        payload: res.data.data || [],
      });
      return res;
    } catch (err) {
      dispatch({
        type: actions.GET_CREDITNOTES_FAILURE,
        loading: false,
        error: err,
      });
      return err;
    }
  };
};

const ApproveCredit_Debit = (Id, invoiceId, type) => {
  return async (dispatch) => {
    try {
      let res = await invoiceAPIS.ApproveCredit_Debit(Id, invoiceId, type);
      return res;
    } catch (err) {
      throw err;
    }
  };
};

const RejectCredit_Debit = (Id, invoiceId, type) => {
  return async (dispatch) => {
    try {
      let res = await invoiceAPIS.RejectCredit_Debit(Id, invoiceId, type);
      return res;
    } catch (err) {
      throw err;
    }
  };
};

const setDataSet = (appUsageDetails, n) => {
  let color = [];
  let per = [];
  let name = [];
  Object.keys(appUsageDetails).map((row) => {
    let total = 0;
    if (appUsageDetails[row]) {
      appUsageDetails[row].billableService.map(
        (itm) => (total = total + itm.GrossAmount)
      );

      total = Math.round((total / n) * 100);

      color.push(randomColor());
      per.push(total);
      name.push(appUsageDetails[row].appDetails.name);
    }
  });
  return {
    color,
    per,
    name,
  };
};

const createDataSet = (appUsageDetails) => {
  let allAppDetailChart = [];

  Object.keys(appUsageDetails).map((row) => {
    let color = [];
    let per = [];
    let name = [];
    let total = 0;
    let app_name = "";
    let bill = [];
    app_name = appUsageDetails[row].appDetails.name;
    appUsageDetails[row].billableService.map((itm) =>
      bill.push({
        name: itm.BillableDetails.name,
        amount: itm.GrossAmount,
        color: randomColor(),
      })
    );
    bill.map((itm) => (total = total + itm.amount));
    bill.map((itm) => {
      per.push(Math.round((itm.amount / total) * 100));
      name.push(itm.name);
      color.push(itm.color);
    });
    allAppDetailChart.push({
      name: app_name,
      bill: bill,
      total: total,
      chartProps: {
        datasets: [
          {
            label: "Dataset",
            data: [...(per || [])],
            backgroundColor: [...(color || [])],
            hoverBackgroundColor: [...(color || [])],
            hoverOffset: 3,
          },
        ],
        // labels: [...(name || [])],
      },
    });
  });
  console.log(allAppDetailChart, "all App detail chart");
  return allAppDetailChart;
};

const fetchInvoiceDetailsById = (id, customerId) => async (dispatch) => {
  dispatch({
    type: "FETCHING_INVOICE_DETAILS_BY_ID",
  });
  try {
    const res = await invoiceAPIS.fethcInvoiceDetailsById(id);
    console.log("grouped by app wtr billable", res.data.data);
    const data = res.data?.data?.BillableDetails || [];
    const app = res?.data?.data?.Applications || [];
    let appUsageDetails = {};
    data.forEach((f) => {
      if (appUsageDetails[f.ApplicationID] === undefined) {
        const { AppDetails, ...billableData } = f;
        appUsageDetails[f.ApplicationID] = {
          appDetails: AppDetails || {},
          billableService: [billableData],
        };
      } else {
        const arr = appUsageDetails[f.ApplicationID];
        const billable = arr.billableService;
        appUsageDetails[f.ApplicationID] = {
          ...arr,
          billableService: [...billable, f],
        };
      }
    });
    console.log("grouped by app wtr billable", 1, data);
    const payload = res.data.data || {};
    const n = payload?.GrossAmount || 0;
    const chartProps = await setDataSet(appUsageDetails, n);
    const allAppDetailedChart = await createDataSet(appUsageDetails);
    console.log(
      "grouped by app wtr billable",
      appUsageDetails,
      data,
      chartProps
    );
    dispatch({
      type: "FETCH_INVOICE_DETAILS_BY_ID_SUCCESS",
      payload: res.data.data || [],
      nonGrpData: data,
      appUsageDetails: appUsageDetails,
      chartProps: {
        datasets: [
          {
            label: "Dataset",
            data: [...(chartProps.per || [])],
            backgroundColor: [...(chartProps.color || [])],
            hoverBackgroundColor: [...(chartProps.color || [])],
            hoverOffset: 3,
          },
        ],
        labels: [...(chartProps.name || [])],
      },
      allAppDetailedChart: allAppDetailedChart,
    });
    app.length !== 0 && dispatch(fetch_appdetails_withModule(app, customerId));
  } catch (err) {
    console.log("hgfhgsafhgsdhgadG", err);
    dispatch({
      type: "FETCH_INVOICE_DETAILS_BY_ID_FAILED",
    });
  }
};

const updateCreditDebitHiddenCol = (hidden) => {
  return async (dispatch) => {
    dispatch({
      type: "UPDATE_CREDIT_DEBIT_HIDDEN_COL",
      payload: hidden,
    });
  };
};
const updateGraphHiddenCol = (hide) => {
  return async (dispatch) => {
    dispatch({
      type: "UPDATE_GRAPH_HIDDEN_COL",
      payload: hide,
    });
  };
};
const loadingDispatchInvoice = (bool) => ({
  type: actions.LOAD_LOADING,
  payload: bool,
});

const Apis = {
  loadingDispatchInvoice,
  actionfetchAllCreditDebitInvoices,
  addCreditNotes,
  getCreditdetailsById,
  ApproveCredit_Debit,
  RejectCredit_Debit,
  fetchInvoiceDetailsById,
  fetch_appdetails_withModule,
  updateCreditDebitHiddenCol,
  updateGraphHiddenCol,
};
export default Apis;
