import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Grid,
  Typography,
  Button,
  TextField,
  InputAdornment,
  IconButton,
  FormLabel,
  CircularProgress,
} from "@material-ui/core/";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
// import Visibility from "@material-ui/icons/Visibility";
// import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
export const InputTextField = withStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      backgroundColor: "white",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "solid 2px black",
    },
  },
}))(TextValidator);
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginLeft: "15px",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: "white",
  },
  title: {
    color: "white",
    fontSize: "16px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "20px",
    },
  },
  loginText: {
    fontWeight: 700,
  },
  margin: {
    marginBottom: 6,
  },
  wrapper: {
    margin: theme.spacing(0),
    position: "relative",
    marginTop: "1%",
  },
  buttonProgress: {
    color: "green",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -8,
    marginLeft: -12,
  },
  lableText: {
    fontSize: "16px",
    fontWeight: "bold",
    fontFamily: "Nunito",
    color: "#000",
    paddingTop: "10px",
    paddingBottom: "5px",
    //marginBottom: "10px",
  },
}));

export default function LoginForm(props) {
  const { error_ob, handleOnfocus, history } = props;

  const classes = useStyles();
  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const getTextFieldValue = (value) => {
    setUser({ ...value });
  };

  const handleForgetPassword = () => {
    props.history.push("/login/forget-password");
  };

  return (
    <>
      <Container component="main" style={{ maxWidth: "560px" }}>
        <div className={classes.paper}>
          <Typography
            //component="h1"
            //variant="h6"
            // className={classes.loginText}
            color="primary"
            style={{
              fontSize: "23px",
              fontFamily: "Nunito",
              fontWeight: "900",
              marginTop: "20px",
              marginBottom: "15px",
              marginRight: "3px",
            }}
          >
            Login
          </Typography>
          <ValidatorForm
            className={classes.form}
            onSubmit={(e) => props.submitLogin(e, user)}
          >
            <FormLabel className={classes.lableText}>
              Email ID <span style={{ color: "red" }}>*</span>
            </FormLabel>
            <InputTextField
              size="small"
              autoComplete="off"
              placeholder=""
              variant="outlined"
              className={classes.margin}
              fullWidth
              id="email"
              value={user.email}
              name="username"
              onChange={(e) =>
                getTextFieldValue({ ...user, email: e.target.value.trim() })
              }
              validators={["required"]}
              errorMessages={["Please enter email"]}
              error={error_ob.email}
              helperText={<div>{error_ob.email && "Invalid Email"}</div>}
              onFocus={handleOnfocus}
            />
            <FormLabel className={classes.lableText}>
              Password <span style={{ color: "red" }}>*</span>
            </FormLabel>
            <InputTextField
              size="small"
              variant="outlined"
              value={user.password}
              fullWidth
              name="password"
              autoComplete="new-password"
              onChange={(e) =>
                getTextFieldValue({ ...user, password: e.target.value })
              }
              type={showPassword ? "text" : "password"}
              id="password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      style={{ width: "40px", height: "40px" }}
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? (
                        <VisibilityOutlinedIcon />
                      ) : (
                        <VisibilityOffOutlinedIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              validators={["required"]}
              errorMessages={["Please enter password"]}
              error={error_ob.pass}
              helperText={
                <div style={{ minHeight: "22px" }}>
                  {error_ob.pass && "Invalid Password"}
                </div>
              }
              onFocus={handleOnfocus}
            />

            <div className={classes.wrapper}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={props.loading}
                style={{ border: "solid 2px black" }}
              >
                Login
              </Button>
              {props.loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
            <div
              style={{
                alignSelf: "center",
                marginTop: 30,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  fontFamily: "Nunito",
                  fontWeight: 600,
                  marginLeft: 10,
                }}
              >
                <text style={{ color: "#666666", fontSize: "14px" }}>
                  Forgot Password
                </text>
              </span>
              <Typography
                style={{
                  color: "#031CCA",
                  fontWeight: "300",
                  marginLeft: 16,
                }}
              >
                <Link style={{ fontSize: "14px" }} to="/login/forget-password">
                  {" "}
                  <u>Click Here</u>
                </Link>
              </Typography>
            </div>
            <Grid container justify="center"></Grid>
          </ValidatorForm>
        </div>
      </Container>
    </>
  );
}
