import { ApiHelper } from "api/services/helper/ApiHelper";
import getURL from "../config";
import API_helpers from "../index";

async function fetchCDUmaster(page, size) {
  let url = "";
  if (page !== undefined && size !== undefined) {
    // url = getURL("CDU_MASTER_FEILDS");
    url = getURL("FETCH_DATASTANDARDS");
    url = `${url}?page=${page}&size=${size}`;
  } else {
    url = getURL("FETCH_DATASTANDARDS");
    // url = getURL("CDU_MASTER_FEILDS");
  }
  try {
    let res = await API_helpers.GET(url, {
      headers: { "Content-Type": "application/json" },
    });

    if (page !== undefined && size !== undefined) {
      return res.data || [];
    } else {
      return (res.data || {}).data || [];
    }
  } catch (err) {
    throw err;
  }
}

async function addCDUFeilds(payload) {
  let url = getURL("ADD_CDU_MASTER");
  try {
    let res = await API_helpers.POST(url, { ...payload });
    return res || [];
  } catch (err) {
    return err;
  }
}
const deleteCDUFeilds = async (payload) => {
  let { _id, ...data } = payload;
  try {
    let res = await API_helpers.DELETE(
      getURL("DELETE_CDU_MASTER_FEILDS")(payload.dataKey),
      { ...data }
    );
    return res || [];
  } catch (err) {
    return err;
  }
};

const editCDUFeilds = async (payload) => {
  let { _id, ...data } = payload;
  try {
    let res = await API_helpers.PUT(
      getURL("EDIT_CDU_MASTER_FEILDS")(payload.dataKey),
      {
        ...data,
      }
    );
    return res || [];
  } catch (err) {
    return err;
  }
};

async function fetchCDUValues(filters) {
  let url = getURL("CDU_MASTER_FEILDS_VALUES");
  url = url + `?filters=${JSON.stringify(filters)}`;
  try {
    let res = await API_helpers.GET(url, {
      headers: {
        "Content-Type": "application/json",
        userdetails: JSON.stringify({ name: "palani" }),
        organization: "11111",
      },
    });
    return (res.data || {}).data || [];
  } catch (err) {
    throw err;
  }
}

const fetchCDUMasterValues = async (payload) => {
  let { dataKey, page, size } = payload;
  //http://localhost:7042/api/v1/cdu/values?filters={"dataKey":"statename"}&page=2&size=3
  let url = getURL("FETCH_CDU_VALUES");
  url = `${url}?filters={"dataKey":${dataKey}}&page=${page}&size=${size}`;
  console.log(url, "fetch fields values");
  try {
    let res = await API_helpers.GET(url);
    return res;
  } catch (err) {
    return err;
  }
};

const addCDUValue = async (payload) => {
  try {
    let res = await API_helpers.POST(getURL("ADD_CDU_VALUE"), payload);
    return res;
  } catch (err) {
    return err;
  }
};
const editCduValue = async (dataKey, valueId, payload) => {
  console.log(dataKey, valueId, payload, "edit");
  try {
    let res = await API_helpers.PUT(
      getURL("EDIT_CDU_VALUE")(dataKey, valueId),
      payload
    );
    return res;
  } catch (err) {
    return err;
  }
};

const deleteCDUValue = async (dataKey, valueId, payload) => {
  try {
    let res = await API_helpers.DELETE(
      getURL("DELETE_CDU_VALUE")(dataKey, valueId),
      payload
    );
    return res || [];
  } catch (err) {
    return err;
  }
};

const BulkUploadCduReferenceValue = async (formData) => {
  try {
    let res = await API_helpers.POST(getURL("BULK_UPLOAD_VALUES"), formData, {
      headers: {
        "content-type": "multipart/form-data;boundary=something",
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

const BulkUploadCduFields = async (formData) => {
  try {
    let res = await API_helpers.POST(getURL("BULK_UPLOAD_CDU"), formData, {
      headers: {
        "content-type": "multipart/form-data;boundary=something",
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

const FetchCduFieldsValue = async (key) => {
  try {
    let res = await ApiHelper.GET(getURL("FETCH_FIELDS_VALUES")(key));
  } catch (err) {
    return err;
  }
};

let APIs = {
  fetchCDUmaster,
  fetchCDUValues,
  addCDUFeilds,
  deleteCDUFeilds,
  editCDUFeilds,
  editCduValue,
  deleteCDUValue,
  addCDUValue,
  fetchCDUMasterValues,
  BulkUploadCduReferenceValue,
  BulkUploadCduFields,
  FetchCduFieldsValue,
};

export default APIs;
