const Styles = (theme) => ({
  gridclrbtn: {
    textAlign: "end",
    marginBottom: "10px",
    borderWidth: 0,
    // backgroundColor: "#fafafa",
  },
  clrbtn: {
    borderWidth: 0,
    backgroundColor: "#fafafa",
  },
  divider: {
    marginTop: "12px",
    height: "2px",
    background: "rgb(175, 175, 175)",
  },

  clear: {
    textTransform: "none",
    cursor: "pointer",
    color: "blue",
  },
});
export default Styles;
