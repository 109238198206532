import { useState, useEffect } from "react";
import getURL from "../../api/config";
import API_helpers from "../../api/index";

const useAxiosIIFE = (str, ...objPara) => {
  const [data, setData] = useState(null);

  const getData = async (URL) => {
    try {
      const response = await API_helpers.GET(URL);
      let data = response?.data?.data || null;
      setData(data);
    } catch (err) {
      setData(null);
    }
  };

  useEffect(() => {
    console.log("obj parameter", objPara);
    let URL = "";
    if (objPara === undefined) {
      URL = getURL(str);
    } else {
      URL = getURL(str)(...objPara);
    }
    getData(URL);
  }, [str]);

  return data;
};

export default useAxiosIIFE;
