import React from "react";
import { Grid, Typography } from "@material-ui/core";
import FilterForm from "./component/FilterComponent";
import CustomerListTable from "./component/CustomerListTable";

const CustomerList = (props) => {
  const { classes } = props;

  return (
    <>
      <div>
        <Grid container>
          <Grid item xs={12}>
            <Grid container>
              <Grid item md={9} xs={12} container alignItems="center">
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                  Customer List {`( ${props.totalCount} )`}
                </Typography>
              </Grid>
              <Grid item md={3} xs={12} style={{ paddingRight: "8px" }}>
                <FilterForm {...props} />
              </Grid>
              <Grid item md={12}>
                <CustomerListTable data={props.subscriberData} {...props} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
export default CustomerList;
