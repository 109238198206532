import { withSnackbar } from "notistack";
import React from "react";
import { withRouter } from "react-router-dom";
// import { Grid } from "@material-ui/core";
import LoginForm from "./LoginForm";
import helpers from "../../helpers/helpers";
import { connect } from "react-redux";
// import PropTypes from "prop-types";
import { logInfo } from "./redux/action";
import { userDetailAction } from "../UserProfile/redux/action";
import { PRIVATE_ROUTE_FALSE, PRIVATE_ROUTE_TRUE } from "./redux/actionType";
import {
  USER_RESET,
  RESET_ORGANIZATION,
} from "../UserProfile/redux/actionTypes";
import { getOrganizationDetails } from "../Organization/OrganizationDetails/redux/action";
import loginAPi from "../../api/LoginApi";
import { compose } from "redux";

class Login extends React.Component {
  state = {
    message: "",
    color: "",
    login: false,
    loading: false,
    error_ob: {
      email: false,
      pass: false,
    },
    sessionExpire: false,
    oldRoute: {},
  };

  componentDidMount() {
    const { location } = this.props;
    const { data } = location.state || { data: null };
    this.props.resetUserInfo();
    this.props.resetOrganization();
    this.props.privateRouteFalse();
    localStorage.clear();
    sessionStorage.clear();
    console.log("called login", this.props);

    if (data && data.key === "expired") {
      this.setState({
        sessionExpire: true,
        oldRoute: {
          ...location.state.data,
        },
      });
      this.props.enqueueSnackbar(
        "Session expired. Please Login again to continue.",
        {
          variant: "error",
          autoHideDuration: 1000,
        }
      );
    }
  }

  handleOnfocus = () => {
    this.setState({
      error_ob: { email: false, pass: false },
    });
  };

  submitLogin = async (e, user) => {
    e.preventDefault();
    const { oldRoute, sessionExpire } = this.state;
    await this.setState({
      loading: true,
    });
    // console.log(user, "this is user", 1, user.email);
    let validEmail = helpers.validateEmail(user.email.trim());
    if (!validEmail) {
      this.setState({
        error_ob: { email: true, pass: false },
        loading: false,
      });

      return;
    }
    this.setState({
      error_ob: { email: false, pass: false },
    });

    const { _msg, _status, data } = await loginAPi.login(user);
    let info = data ? data : {};
    console.log(_msg, data, _status, info, "sssss");
    if (_status === 200) {
      await this.props.addLogInfo(info);
      let id = info?._id || 0;
      this.props.enqueueSnackbar(_msg, {
        variant: "success",
        autoHideDuration: 1000,
      });

      if (info.twofactorauthentication) {
        this.props.history.push("/login/verify-otp", {
          userData: { ...(info || {}) },
        });
      } else {
        const accessToken = { accessToken: info?.accessToken || null };
        sessionStorage.setItem("token", JSON.stringify(accessToken));
        this.props.privateRouteTrue();
        this.props.user_detailAction(id);
        await this.props.fetchOrganizationDetails();

        if (sessionExpire) {
          oldRoute.state
            ? this.props.history.push(oldRoute.path, {
                ...oldRoute.state,
              })
            : this.props.history.push(oldRoute.path);
        } else {
          this.props.history.push("/app/applications");
        }
      }
    } else {
      this.props.enqueueSnackbar(_msg, {
        variant: "error",
        autoHideDuration: 1000,
      });
      this.setState({
        loading: false,
      });
    }
  };
  render() {
    console.log(this.state.message, this.state.color, this.state.loading);
    const { error_ob } = this.state;
    return (
      <>
        <LoginForm
          message={this.state.message}
          loading={this.state.loading}
          color={this.state.color}
          login={this.state.login}
          error_ob={error_ob}
          handleOnfocus={this.handleOnfocus}
          submitLogin={(event, user) => this.submitLogin(event, user)}
          {...this.props}
        />
      </>
    );
  }
}

// Login.PropTypes = {

// };
const mapDispatchToProps = (dispatch) => ({
  addLogInfo: (data) => dispatch(logInfo(data)),
  user_detailAction: (id) => dispatch(userDetailAction(id)),
  fetchOrganizationDetails: () => dispatch(getOrganizationDetails()),
  resetUserInfo: () =>
    dispatch({
      type: USER_RESET,
    }),
  resetOrganization: () =>
    dispatch({
      type: RESET_ORGANIZATION,
    }),
  privateRouteFalse: () =>
    dispatch({
      type: PRIVATE_ROUTE_FALSE,
    }),
  privateRouteTrue: () =>
    dispatch({
      type: PRIVATE_ROUTE_TRUE,
    }),
});
const mapStateToProps = (state) => {
  return {};
};

const LoginComponent = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withSnackbar
)(Login);

export default LoginComponent;

// if (user.username === "admin@nviera.com" && user.password === "admin@123") {
//   this.setState({ loading: true });
//   this.props.enqueueSnackbar("Login Success", {
//     variant: "success",
//     autoHideDuration: 1000,
//   });
//   setTimeout(async () => {
//     await this.setState({
//       login: true,
//       loading: false,
//     });
//     // this.props.history.push("/app/applications");
//   }, 1000);
// } else {
//   this.props.enqueueSnackbar("Invalid Username or Password", {
//     variant: "error",
//     autoHideDuration: 1000,
//   });
//   await this.setState({
//     login: false,
//     loading: false,
//   });
// }
