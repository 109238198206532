import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({ 
    mainContainer: {
        // width: 1305,
        [theme.breakpoints.down('lg')]: {
            width: 'auto',
            height: 'auto',
        },
    },
    passwordContainer: {
        // width: 1305,
        marginTop: '2em',
        // marginBottom: '1em',
        [theme.breakpoints.down('lg')]: {
            width: 'auto',
            height: 'auto',
        },
    },
    title: {
        ...theme.typography.fontFamily,
        fontSize: '1rem',
        color: '#000000',
        fontWeight: 600
    },
    titleC: {
        padding: '12px 21px 12px 4px '
    },
    appBtn: {
        position: 'absolute',
        marginLeft: '9.1%'
    },
    button: {
        ...theme.typography.fontFamily,
        textTransform: 'none',
        fontSize: '14px',
        margin: theme.spacing(1),
        // color:theme.palette.primary.main
    },
    avatar: {
        width: 66,
        height: 66,
        marginTop: '14px',
        border: '1px solid #D1D1D1',
        opacity: 1
    },
    divider: {
        borderBottom: '1px solid #E2E2E2',
        marginLeft: 2
    },
    errorUp:{
        ...theme.typography.fontFamily,
        fontSize:13,
    }
}));
