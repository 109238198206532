import * as actions from "./actionTypes";
import teamsAPIS from "api/TeamsAPIs/index";

function actionfetchAllTeams(pageno, size, filters, sort) {
  console.log({ actions });
  return async (dispatch) => {
    dispatch({
      type: actions.FETCHING_TEAMS,
      loading: true,
    });
    try {
      let data = await teamsAPIS.fetchAllTeamsWithParams(
        pageno,
        size,
        filters,
        sort
      );
      dispatch({
        type: actions.FETCHING_TEAMS_SUCCESS,
        loading: false,
        totalCount: data._totalcount,
        data: data.data || [],
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: actions.FETCHING_TEAMS_FAILURE,
        loading: false,
        error: err,
      });
    }
  };
}

function blockTeamManagement(teamId, type) {
  return async (dispatch) => {
    try {
      let blockdata = await teamsAPIS.blockTeamManagement(teamId, type);
      const { _msg, _status } = blockdata.data;
      return {
        success: true,
        _msg,
      };
    } catch (err) {
      const { _msg } = err?.response?.data || { _msg: "Network issue!" };
      return {
        success: false,
        _msg,
      };
    }
  };
}
function lockTeamManagement(teamId, type) {
  return async (dispatch) => {
    try {
      let res = await teamsAPIS.lockTeamManagement(teamId, type);
      return res;
    } catch (err) {
      return err;
    }
  };
}

const updateFilterData = ({ filter, filterArray }) => ({
  type: actions.UPDATE_FILTER_DATA,
  payload: { filter, filterArray },
});

const updateSort = ({ sorting, sort }) => ({
  type: actions.UPDATE_TEAMS_SORT,
  payload: { sorting, sort },
});

const updateTeamHiddenCol = (hidden) => ({
  type: actions.UPDATE_TEAM_HIDDEN_COL,
  payload: hidden,
});
export default {
  actionfetchAllTeams,
  blockTeamManagement,
  lockTeamManagement,
  updateFilterData,
  updateSort,
  updateTeamHiddenCol,
};
