import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";
import Styles from "./styles";
import Breadcrumb from "components/Breadcrumbs/Breadcrumb";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import {} from "./Redux/action";
import { ValidatorForm } from "react-material-ui-form-validator";
import { withSnackbar } from "notistack";
import Loader from "../../components/common/stuff/Loader";
import AppUsageData from "./AppUsageData";

class AppUsageDescriptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      customerID: 0,
    };
  }

  async componentDidMount() {
    const { location } = this.props;
    if (location.state !== undefined && location.state.customerData) {
      const { customerData } = location.state;
      this.setState({
        data: { ...customerData },
        customerID: customerData._id,
      });
    } else {
      this.props.history.push("/app/customer-details");
    }
  }

  onSubmitFunc = async () => {};
  render() {
    const {} = this.state;
    const { loading } = this.props;
    return (
      <div>
        {loading && <Loader />}
        <div>
          <Breadcrumb
            {...this.props}
            links={[
              { name: "Customer List", url: "/app/customer-management" },
              {
                name: "Customer Details",
                url: "/app/customer-details",
                routeParams: { customerData: this.state.data },
              },
              {
                name: "App Usage Description",
                url: "/app/app-usage-description",
              },
            ]}
          />
        </div>
        <ValidatorForm onSubmit={this.onSubmitFunc}>
          <AppUsageData />
        </ValidatorForm>
      </div>
    );
  }
}

AppUsageDescriptions.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => ({});
const mapStateToProps = (state) => {
  return {};
};
const AppUsageDescriptionsForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppUsageDescriptions);

export default withRouter(
  withStyles(Styles)(withSnackbar(AppUsageDescriptionsForm))
);
