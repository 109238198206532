import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Typography } from "@material-ui/core";
export default function TwoFactorAuthDialog({ cancelTwoFA, proceed, open }) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={cancelTwoFA}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography> Enabling 2FA will ask you to login again </Typography>
            <Typography> Please confirm to proceed </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelTwoFA} color="primary">
            Cancel
          </Button>
          <Button onClick={() => proceed(true)} color="primary" autoFocus>
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
