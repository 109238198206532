import React, { Component } from "react";
import Breadcrumb from "components/Breadcrumbs/Breadcrumb";
import SearchIcon from "@material-ui/icons/Search";
import FilterComponent from "components/FilterComponent/FilterComponent";
import {
  Typography,
  Grid,
  Button,
  InputAdornment,
  Tooltip,
  IconButton,
  Link,
} from "@material-ui/core/";
import { Pagination } from "@material-ui/lab";
import { CustomTextField } from "components/Customized";
import DexTable from "components/DexTable/DexTable";
import AddIcon from "@material-ui/icons/Add";
import actions from "./redux/action";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";
import { RiEditLine, RiLockLine } from "react-icons/ri";
import { AiOutlineLock } from "react-icons/ai";
import { withSnackbar } from "notistack";
import TeamsAPIs from "api/TeamsAPIs/index";
import BlockIcon from "@material-ui/icons/Block";
import ModalBlock from "./components/ModalBlock";
import LockIcon from "@material-ui/icons/Lock";
import { withRouter } from "react-router";
import dataOptions from "./filterOptions.json";
import HelpersFunc from "helpers/helpers";
import { checkPermission } from "helpers/permissons";
import HelpdeskFAB from "../../views/HelpdeskFAB/HelpdeskFab";

const findAndExecuteAjax = (type) => {
  switch (type) {
    case "Team Name":
      return (operator, value) => {
        if (operator === "Contains of") {
          return { name: { $regex: value, $options: "i" } };
        }
        if (operator === "Starts With") {
          return { name: { $regex: `^${value}`, $options: "i" } };
        }
        if (operator === "Ends With") {
          return { name: { $regex: `${value}$`, $options: "i" } };
        }
        if (operator === "Equals To") {
          return { name: { $regex: `^${value}$`, $options: "i" } };
        }
      };

    case "Created By":
      return (operator, value) => {
        if (operator === "Contains of") {
          return { createdby: { $regex: value, $options: "i" } };
        }
        if (operator === "Starts With") {
          return { createdby: { $regex: `^${value}`, $options: "i" } };
        }
        if (operator === "Ends With") {
          return { createdby: { $regex: `${value}$`, $options: "i" } };
        }
        if (operator === "Equals To") {
          return { createdby: value, $options: "i" };
        }
      };

    case "Created On":
      return (operator, value) => {
        console.log(operator, 23456543234);
        var timestamp = new Date(value.split(".").join("-")).getTime() / 1000;
        // if (operator === "Equals To") {
        //   return { createdon: { $eq: timestamp } };
        // }
        if (operator === "Equals To") {
          let gte = new Date(value.split(".").join("-")).getTime() / 1000;
          let lte = new Date(`${value}T23:59:59+05:30`).getTime() / 1000;
          return { createdon: { $gte: gte, $lte: lte } };
        }
        if (operator === "Greater than") {
          let gt = new Date(`${value}T23:59:59+05:30`).getTime() / 1000;
          return { createdon: { $gt: gt } };
        }
        if (operator === "Lesser than") {
          let lt = new Date(`${value}T00:00:00+05:30`).getTime() / 1000;
          return { createdon: { $lt: lt } };
        }
        if (operator === "Greater than or Equal To") {
          return { createdon: { $gte: timestamp } };
        }
        if (operator === "Less than or Equals To") {
          return { createdon: { $lte: timestamp } };
        }
      };

    default:
      return {};
  }
};

const Sytles = (theme) => ({
  footer: {
    position: "fixed",
    bottom: 0,
    left: 0,
    padding: 20,
    width: "100%",
    textAlign: "right",
    zIndex: 999,
    backgroundColor: "#f2f2f2",
  },
  customPagination: {
    width: "fit-content",
    marginLeft: "auto",
    "& .MuiPaginationItem-page.Mui-selected": {
      border: "0.5px solid #707070",
      margin: "0 10px",
      height: 30,
      width: 30,
      padding: "10px 13px",
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      boxShadow: "0px 3px 10px #5757571a",
      lineHeight: 1,
      borderRadius: 5,
      opacity: 1,
    },
    "& .MuiPaginationItem-page": {
      font: "normal normal normal 14px/19px Nunito Sans",
      letterSpacing: 0,
      color: "#000000",
      opacity: 0.6,
    },
  },
  block: {
    color: "red",
  },
  lock: {
    color: "orange",
  },
  active: {},
});

class TeamsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data_options: [...dataOptions],
      tm: false,
      lm: false,
      page: 1,
      size: 10,
      filters: {},
      sort: { createdOn: -1 },
      sorting: [{ columnName: "createdon", direction: "desc" }],
      edit: false,
      searchkey: "",
      value: 0,
      openDropdown: false,
      setDropdown: false,
      setOpen: false,
      info: { status: "", name: "", teamId: 0 },
      lockinfo: { status: "", name: "", teamId: 0 },
      teaminfo: [],
      count: 0,
    };
  }

  fetchData = (filters, arr, search) => {
    let page = 1;
    this.setState({
      page: 1,
    });
    let { filterProps, updateFilter } = this.props;
    updateFilter({ filter: filters, filterArray: arr });
    let { size } = this.state;
    this.props.getData(
      page,
      size,
      JSON.stringify(filters),
      JSON.stringify(filterProps.sort)
    );
    if (!search) {
      this.setState({
        searchkey: "",
      });
    }
  };

  async componentDidMount() {
    let { filterProps } = this.props;
    // this.fetchData(filterProps.filters, filterProps.filterArray);
    this.props.getData(
      1,
      this.state.size,
      JSON.stringify(filterProps.filters),
      JSON.stringify(filterProps.sort)
    );
  }

  filterFunc = (arr) => {
    let filters = {};
    if (arr.length === 1) {
      const { type, operator, value } = arr[0];
      console.log(value);
      var category_id = this.props.data;
      console.log(category_id, operator, value, "catid");
      let filter = HelpersFunc.findAndExecuteAjax(type)(operator, value);
      this.getApplicationsByFilter({ ...filter }, arr);
    } else {
      arr.map((item, id) => {
        const { type, operator, value } = item;
        let filter = HelpersFunc.findAndExecuteAjax(type)(operator, value);
        filters = { ...filters, ...filter };
      });
      this.getApplicationsByFilter({ ...filters }, arr);
    }
  };

  getApplicationsByFilter = async (filters, arr) => {
    this.fetchData(filters, arr);
  };

  handleSortByChange = (sorting) => {
    let { filterProps, updateSorting, getData } = this.props;
    let filters = { ...filterProps.filters };
    let sort = {};
    sorting.forEach((f) => {
      sort[f.columnName] = f.direction == "asc" ? 1 : -1;
    });
    updateSorting({ sorting, sort });
    getData(
      this.state.page,
      this.state.size,
      JSON.stringify(filters),
      JSON.stringify(sort)
    );
  };

  searchAndPaginationHelperFunc = (page, val, filter_Props) => {
    this.props.getData(
      page,
      this.state.size,
      JSON.stringify({
        ...filter_Props.filters,
        name: { $regex: "^" + val, $options: "i" },
      }),
      JSON.stringify(filter_Props.sort)
    );
  };

  // searchAndPaginationHelperFunc = (page, val) => {
  //   let { filterProps } = this.props;
  //   let filtersCopy = { ...filterProps.filters };
  //   let filters = {
  //     ...filtersCopy,
  //     name: { $regex: "^" + val, $options: "i" },
  //   };
  //   // this.fetchData(filters, filterProps.filterArray, true);
  //   this.props.getData(
  //     page,
  //     this.state.size,
  //     JSON.stringify(filters),
  //     JSON.stringify(filterProps.sort)
  //   );
  // };

  onChangeSearchAppName = (event) => {
    let page = 1;
    this.setState({
      searchkey: event.target.value,
      page: page,
    });
    this.searchAndPaginationHelperFunc(
      page,
      event.target.value,
      this.props.filterProps
    );
  };
  handlePageChange = async (e, newPage) => {
    this.setState({
      page: newPage,
    });
    this.fetchingData(newPage);
  };
  fetchingData = async (Page) => {
    let { filterProps } = this.props;
    if (this.state.searchkey.trim().length !== 0) {
      this.searchAndPaginationHelperFunc(
        Page,
        this.state.searchkey,
        filterProps
      );
    } else {
      await this.props.getData(
        Page,
        this.state.size,
        JSON.stringify(filterProps.filters),
        JSON.stringify(filterProps.sort)
      );
    }
  };

  viewTeamdata = (row) => {
    let data = { ...row };
    row.status !== "blocked" &&
      this.props.history.push("/app/create-team", {
        team_data: data,
        edit: true,
        disabled: true,
      });
  };

  openDropDown = () => {
    this.setState({ openDropDown: true });
  };
  dropUp = () => {
    this.setState({ setDropdown: true });
  };
  displayMsg = (type, msg) => {
    this.props.enqueueSnackbar(msg, {
      variant: type ? "success" : "error",
      autoHideDuration: 3000,
    });
  };
  blockUserdata = async () => {
    if (this.state.info.status === "false") {
      const { success, _msg } = await this.props.blockTeamManagement(
        this.state.info.teamId,
        "block"
      );
      this.displayMsg(success, _msg);
    } else {
      const { success, _msg } = await this.props.blockTeamManagement(
        this.state.info.teamId,
        "unblock"
      );
      this.displayMsg(success, _msg);
    }
    this.setState({
      setOpen: false,
    });
    this.fetchingData(this.state.page);
    // let { filterProps } = this.props;
    // await this.props.getData(
    //   this.state.page,
    //   this.state.size,
    //   JSON.stringify(filterProps.filters),
    //   JSON.stringify(filterProps.sort)
    // );
  };
  displayMsg1 = (type, msg) => {
    this.props.enqueueSnackbar(msg, {
      variant: type ? "success" : "error",
      autoHideDuration: 3000,
    });
  };
  lockteam = async () => {
    if (this.state.lockinfo.status === "false") {
      const { status, data, response } = await this.props.lockTeamManagement(
        this.state.lockinfo.teamId,
        "lock"
      );
      console.log("msg18", data, response);
      if (response) {
        const _msg = response?.data?._msg || "Connection error";
        this.displayMsg1(false, _msg);
      } else {
        this.displayMsg1(true, data._msg);
      }
    } else {
      const { status, data, response } = await this.props.lockTeamManagement(
        this.state.lockinfo.teamId,
        "unlock"
      );
      console.log("msg18", data, response);

      if (response) {
        const _msg = response?.data?._msg || "Connection error";
        this.displayMsg1(false, _msg);
      } else {
        this.displayMsg1(true, data._msg);
      }
    }
    this.setState({
      setOpen: false,
    });
    this.fetchData(this.state.page);
  };

  memberProvider = ({ row }) => {
    let openModal = (e) => {
      e && e.stopPropagation();
      this.setState({
        teaminfo: [...row.users],
        setOpen: true,
        tm: true,
        lm: false,
      });
    };
    return (
      <Link
        component="button"
        variant="body2"
        style={{ color: "#2185d0" }}
        onClick={(e) => openModal(e)}
      >
        {row.users.length} Members
      </Link>
    );
  };

  createTeam = () => {
    const teamPayload = {
      name: "",
      users: [],
      type: "internal",
    };
    this.props.history.push("/app/create-team", {
      team_data: teamPayload,
      edit: false,
    });
  };

  openLockModal = (row, e) => {
    e && e.stopPropagation();
    this.setState({
      lockinfo: {
        name: row.name,
        teamId: row._id,
        status: row.lock_status,
      },
      lm: true,
      tm: false,
      setOpen: true,
    });
  };

  editTeamdata = async (row, e) => {
    console.log(row, 2345678909876543);
    e && e.stopPropagation();
    let data = { ...row };
    this.props.history.push("/app/create-team", {
      team_data: data,
      edit: true,
      disabled: false,
    });
  };

  actionProvider = ({ row }) => {
    let openModal = (e) => {
      e && e.stopPropagation();
      this.setState({
        info: {
          name: row.name,
          teamId: row._id,
          // status: row.status,
          status: row.block_status,
        },
        lm: false,
        tm: false,
        setOpen: true,
      });
    };

    let flag = row.block_status !== "false" ? true : false;
    let lockflag = row.lock_status !== "false" ? true : false;
    let perm = false;
    if (!flag || !lockflag) {
      perm = checkPermission("btn35");
    }
    console.log("ty456789", flag, lockflag, perm, row);
    return (
      // <div
      //   style={{
      //     display: "flex",
      //     justifyContent: "space-around",
      //   }}
      // >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <Tooltip title="Edit" aria-label="edit">
            <IconButton
              disabled={!perm}
              color="inherit"
              size="small"
              onClick={(e) => this.editTeamdata(row, e)}
            >
              <RiEditLine fontSize="20px" />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip
            // title={flag ? "Unlock" : "Lock"}
            title="Lock"
            aria-label="lock"
          >
            <IconButton
              // disabled={flag ? true : false}
              size="small"
              color="inherit"
              disabled={!checkPermission("btn36")}
              onClick={(e) =>
                checkPermission("btn36") && this.openLockModal(row, e)
              }
            >
              <RiLockLine
                fontSize="20px"
                className={
                  lockflag ? this.props.classes.lock : this.props.classes.active
                }
              />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title={flag ? "Unblock" : "Block"} aria-label="block">
            <IconButton
              size="small"
              color="inherit"
              disabled={!checkPermission("btn37")}
              onClick={(e) => checkPermission("btn37") && openModal(e)}
            >
              <BlockIcon
                style={{ fontSize: "20px" }}
                className={
                  flag ? this.props.classes.block : this.props.classes.active
                }
              />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      // </div>
    );
  };

  handleClose = () => {
    this.setState({
      setOpen: false,
    });
  };

  render() {
    let { data_options } = this.state;
    let { data, classes, loading, totalCount, filterProps, hidden } =
      this.props;

    const { info, lockinfo } = this.state;
    let type = info.status === "false" ? "block" : "unblock";

    let locktype = lockinfo.status === "false" ? "lock" : "unlock";

    return (
      <>
        <Grid container>
          <Grid item sm={12} md={12} xs={12} lg={12}>
            <Breadcrumb
              {...this.props}
              links={[
                { name: "Teams" },
                {
                  name: "Teams List",
                },
              ]}
            />
          </Grid>
          <Grid item sm={7} md={7} lg={7} xs={12}>
            <Typography variant="h6" style={{ fontWeight: "bold" }}>
              Teams {`( ${this.props.totalCount} )`}
            </Typography>
          </Grid>
          <Grid item sm={5} md={5} lg={5} xs={12}>
            <Grid container spacing={2}>
              <Grid item sm={5} md={5} lg={5} xs={12}>
                <CustomTextField
                  className=" mt-0 "
                  autoComplete="off"
                  fullWidth
                  id="filled-search"
                  type="text"
                  variant="outlined"
                  size="small"
                  placeholder="Search"
                  value={this.state.searchkey}
                  margin="normal"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {" "}
                        <SearchIcon />{" "}
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    this.onChangeSearchAppName(e);
                  }}
                />
              </Grid>
              <Grid item sm={3} md={3} lg={3} xs={12}>
                <FilterComponent
                  getDataByFilter={this.getApplicationsByFilter}
                  dataOptions={data_options}
                  filterFunc={this.filterFunc}
                  filterHistory={filterProps.filterArray}
                />
              </Grid>
              <Grid item sm={4} md={4} lg={4} xs={12}>
                <Button
                  style={{
                    color: "white",
                    width: "100%",
                    boxShadow: "none",
                    padding: "6px 11px 6px 11px",
                  }}
                  variant="contained"
                  color="primary"
                  size="small"
                  alignItems="center"
                  disabled={!checkPermission("btn33")}
                  onClick={() => checkPermission("btn33") && this.createTeam()}
                  startIcon={<AddIcon />}
                >
                  Create Teams
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12} md={12} xs={12} lg={12} className="mt-2">
            <DexTable
              data={[...data]}
              columnOrder={[
                "name",
                "members",
                "createdby",
                "createdon",
                "actions",
              ]}
              columns={[
                { name: "name", title: "Team Name" },
                { name: "members", title: "Team Members" },
                { name: "createdby", title: "Created By" },
                { name: "createdon", title: "Created On" },
                { name: "actions", title: "Actions" },
              ]}
              columWidthsType="percentage"
              defaultColumnWidths={[
                { columnName: "name", width: 20 },
                { columnName: "members", width: 25 },
                { columnName: "createdby", width: 20 },
                { columnName: "createdon", width: 15 },
                { columnName: "actions", width: 18 },
              ]}
              formatters={[
                { formatterFn: this.actionProvider, columns: ["actions"] },
                { formatterFn: this.memberProvider, columns: ["members"] },
              ]}
              sorting={filterProps.sorting}
              onSortingChange={this.handleSortByChange}
              dateColumns={["createdon"]}
              disableReordering={false}
              height="60vh"
              loading={loading}
              tableType="virtual"
              hidden={[...hidden]}
              onClickRow={(row) => this.viewTeamdata(row)}
              setHiddenColmn={(hidden) => {
                this.props.setHiddenColumn(hidden);
              }}
              Read={checkPermission("btn34")}
            />
          </Grid>
          {data.length !== 0 && (
            <div className={classes.footer}>
              <div className={classes.customPagination}>
                <Pagination
                  count={Math.ceil(totalCount / 10)}
                  page={this.state.page}
                  onChange={this.handlePageChange}
                  shape="rounded"
                  showFirstButton
                  showLastButton
                />
              </div>
            </div>
          )}
          <HelpdeskFAB category="Teams" />
        </Grid>

        <ModalBlock
          tm={this.state.tm}
          lm={this.state.lm}
          teaminfo={this.state.teaminfo}
          color="white"
          open={this.state.setOpen}
          yes={`Yes, ${this.state.lm ? locktype : type} it`}
          handleClose={this.handleClose}
          action={this.state.lm ? this.lockteam : this.blockUserdata}
          text={`Are you sure you want to ${this.state.lm ? locktype : type} ${
            info.name
          } ?`}
          Icon={
            this.state.lm ? (
              <AiOutlineLock
                style={{ fontSize: "50px", marginBottom: "15px" }}
              />
            ) : (
              <BlockIcon style={{ fontSize: "50px", marginBottom: "15px" }} />
            )
          }
        />
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    data: state.teamManagement.data,
    loading: state.teamManagement.loading,
    totalCount: state.teamManagement.totalCount,
    filterProps: state.teamManagement.filterProps,
    hidden: state.teamManagement.hidden,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getData: (pageno, size, filters, sort) =>
      dispatch(actions.actionfetchAllTeams(pageno, size, filters, sort)),
    blockTeamManagement: (teamId, type) =>
      dispatch(actions.blockTeamManagement(teamId, type)),
    lockTeamManagement: (teamId, type) =>
      dispatch(actions.lockTeamManagement(teamId, type)),
    updateFilter: (filter) => dispatch(actions.updateFilterData(filter)),
    updateSorting: (sortProps) => dispatch(actions.updateSort(sortProps)),
    setHiddenColumn: (hidden) => dispatch(actions.updateTeamHiddenCol(hidden)),
  };
};
const TeamsManagement = connect(mapStateToProps, mapDispatchToProps)(TeamsForm);
export default withRouter(withStyles(Sytles)(withSnackbar(TeamsManagement)));
