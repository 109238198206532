/**
 * converts json schema to tree structure
 * Tree will have three types of nodes.
 *  - Array
 *  - Object
 *  - Field
 * Node for type Array and Object can have childeren of any type but Field node cannot have childeren
 *
 * Node structure is as follows.
 * {
 *  title : "field title"
 *  type : "<Array or Object or Field"
 *  children : "Array of Nodes"
 * }
 */

function SchematoTree(jsonSchema) {
  // convert to json object.
  let schemaObj = JSON.parse(jsonSchema);
  let parsefn = initialize(schemaObj);
  return parsefn(schemaObj);
}

function initialize(schema) {
  var rootSchema = schema;

  return function parseSchema(schemaObj, key = "root", parentKey = "$") {
    if (schemaObj == undefined) {
      return {};
    }
    if (schemaObj.hasOwnProperty("$ref")) {
      let defkeys = schemaObj["$ref"].split("/");
      defkeys.shift(); // remove first element that is #
      console.log("test", defkeys, key);
      var newSchema = { ...rootSchema };
      schemaObj = defkeys.reduce(function (schema, k) {
        console.log(schema);
        return schema[k];
      }, newSchema);
      key = "arrayElement";
    }
    let type = schemaObj["type"];
    console.log("console.log(schemaObj);console.log(schemaObj);", schemaObj);
    switch (type) {
      case "array":
        var node = {
          id: parentKey + "__" + key,
          title: key,
          type: schemaObj["type"],
          desc: schemaObj["desc"],
          nodeType: "array",
        };

        if (schemaObj["items"] === undefined) {
          let msg = "Invalid json";
          throw msg;
        }
        if (typeof schemaObj !== "object") {
          let msg = "Invalid json";
          throw msg;
        }
        var items = schemaObj["items"];

        node.children = [
          parseSchema(items, "arrayElement", parentKey + "__" + key),
        ];
        return node;
      case "object":
        var node = {
          id: parentKey + "__" + key,
          title: key,
          type: schemaObj["type"],
          desc: schemaObj["desc"],
          nodeType: "object",
        };

        var properties = schemaObj["properties"] || {};
        console.log(properties, schemaObj);
        node.children = Object.keys(properties).map(function (k) {
          return parseSchema(properties[k], k, parentKey + "__" + key);
        });
        return node;
      case "number":
      case "string":
      case "boolean":
      case "integer":
        var node = {
          id: parentKey + "__" + key,
          title: key,
          type: schemaObj["type"],
          desc: schemaObj["desc"],
          format: schemaObj["format"],
          nodeType: "field",
        };
        return node;
      case "null":
      default:
        let msg = "Invalid json";
        throw msg;
    }
  };
}

export default SchematoTree;
