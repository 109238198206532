import { React, useState, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import FormControl from "@material-ui/core/FormControl";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { RiEditLine, RiDeleteBin5Line } from "react-icons/ri";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import { ApplicationDetailsUpadateHiddenCol } from "../ApplicationDetails/Redux/action";
import {
  Button,
  TextField,
  Typography,
  FormLabel,
  Select,
  Tooltip,
  IconButton,
  Grid,
} from "@material-ui/core";

import withSection from "components/Section/Section";
import DexTable from "components/DexTable/DexTable";
import AddIcon from "@material-ui/icons/Add";
import WrappedDexTable from "components/DexTable/WrappedDexTable";
import { checkPermission } from "helpers/permissons";
import { confirmDialog } from "components/DeleteConfirmationDialog/Index";
import { dltMsg } from "helpers/helpers";

const useStyles = makeStyles((theme) => ({
  line: {
    marginTop: -23,
  },
  table: {
    minWidth: 500,
  },
  dividerline: {
    // border: "1px solid black",
    width: "70%",
    margin: "-25px 0px 0px 125px",
    [theme.breakpoints.up("md")]: {
      width: "82%",
      margin: "-25px 0px 0px 260px",
    },
  },
}));
const StyledTableCell = withStyles((theme) => ({
  head: {
    // backgroundColor: "#999682",
    backgroundColor: theme.palette.primary.main,

    color: theme.palette.common.white,
  },
  body: {
    // height: "14vh",
    width: 15,
    height: 1,
  },
  btn: {
    width: "300px",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({}))(TableRow);

const CustomTableRow = (props) => {
  const {
    row,
    removeRows,
    index,
    editWebhook,
    deleteWebhook,
    addWebhook,
    appName,
  } = props;
  const classes = useStyles();
  const [edit, setEdit] = useState(row._id == undefined || false);
  const [payload, setPayload] = useState(row);
  const onChange = (e) => {
    let newpayload = { ...payload };
    newpayload[e.currentTarget.name] = e.currentTarget.value;
    console.log(newpayload);
    setPayload(newpayload);
  };

  return (
    <TableRow>
      <StyledTableCell component="th" scope="row">
        <FormControl
          fullWidth
          size="small"
          variant="outlined"
          className={classes.btn}
          disabled={edit ? false : true}
        >
          <Select
            required
            name="type"
            value={payload.type}
            native
            onChange={(e) => onChange(e, index)}
          >
            <option value="">- Select -</option>
            <option value="On Subscribe">On Subscribe</option>
            <option value="On Unsubscribe">On Unsubscribe</option>
            <option value="On User Create">On User Create</option>
          </Select>
        </FormControl>
      </StyledTableCell>
      <StyledTableCell align="left">
        <TextField
          disabled={edit ? false : true}
          required
          className={classes.btn}
          size="small"
          variant="outlined"
          fullWidth
          id="name"
          value={payload.url}
          name="url"
          onChange={(e) => onChange(e, index)}
        />
      </StyledTableCell>
      <StyledTableCell align="left">
        <FormControl
          fullWidth
          disabled={edit ? false : true}
          required
          size="small"
          variant="outlined"
          className={classes.btn}
          value={payload.method}
          name="method"
        >
          <Select
            required
            name="method"
            value={payload.method}
            native
            onChange={(e) => onChange(e, index)}
          >
            <option value="none">-Select-</option>
            <option value="GET">GET</option>
            <option value="POST">POST</option>
            <option value="PUT">PUT</option>
          </Select>
        </FormControl>
      </StyledTableCell>
      <StyledTableCell align="left">
        <TextField
          disabled={edit ? false : true}
          required
          className={classes.btn}
          size="small"
          variant="outlined"
          fullWidth
          name="apiKey"
          value={payload.apiKey}
          onChange={(e) => onChange(e, index)}
        />
      </StyledTableCell>
      <StyledTableCell align="left">
        {edit && (
          <>
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              style={{
                marginLeft: "30px",
                backgroundColor: "transparent",
                boxShadow: "none",
                color: "#9e9e9e",
              }}
              onClick={async () => {
                if (row._id == undefined) {
                  await addWebhook(row.appId, payload);
                } else {
                  await editWebhook(row.appId, row._id, payload);
                }
                setEdit(false);
              }}
            >
              Save
            </Button>{" "}
            <Button
              style={{
                marginLeft: 0,
                backgroundColor: "transparent",
                boxShadow: "none",
                color: "#9e9e9e",
              }}
              variant="contained"
              color="primary"
              onClick={() => {
                if (row._id == undefined) {
                  removeRows(row, props.tableRow.rowId);
                  return;
                }
                setEdit(false);
              }}
            >
              Cancel
            </Button>
          </>
        )}
        {!edit && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
            }}
          >
            <div item style={{ width: "4%" }}>
              <Tooltip title="Edit" aria-label="edit">
                <IconButton color="inherit">
                  <RiEditLine
                    style={{ fontSize: "20px" }}
                    onClick={() => {
                      setEdit(true);
                    }}
                  />
                </IconButton>
              </Tooltip>
            </div>
            <div item style={{ width: "4%" }}>
              <Tooltip title="Delete" aria-label="delete">
                <IconButton color="inherit">
                  <RiDeleteBin5Line
                    fontSize="20px"
                    onClick={() => deleteWebhook(row.appId, row._id)}
                  />
                </IconButton>
              </Tooltip>
            </div>
            <div item style={{ width: "4%" }}>
              {props.addNewRow}
            </div>
          </div>
        )}
      </StyledTableCell>
    </TableRow>
  );
};

function ApplicationWebhooksDex(props) {
  const { appId, webhooks, appTable, appName } = props;
  const { webhooksHidden } = appTable;

  const classes = useStyles();
  const initialRows = [
    { type: "", applicationApiUrl: "", applicationMethod: "" },
  ];
  let [rows, setRows] = useState(webhooks);

  const addRows = () => {
    const item = { appId, type: "", url: "", method: "", apiKey: "" };
    setRows([...rows, item]);
  };

  useEffect(() => {
    setRows(webhooks);
    console.log(rows);
  }, [webhooks]);

  const [edit, setEdit] = useState(false);

  const removeRows = (payload, index) => {
    console.log({ payload });
    if (rows.length != 1) {
      var filtered = rows.filter(function (el, i) {
        console.log(index, i, index !== i);
        return index !== i;
      });
      console.log({ filtered });
      setRows([...filtered]);
    }
  };

  const onChange = (event, index) => {
    let payload = rows.filter(function (el, i) {
      console.log(index, i, index !== i);
      return index == i;
    })[0];
    let newpayload = { ...payload };
    newpayload[event.currentTarget.name] = event.currentTarget.value;
    let webhooks = [...rows];
    webhooks[index] = newpayload;
    console.log({ newpayload });
    setRows([...webhooks]);
  };

  const pushToWebhooks = () => {
    let data = {
      type: "",
      url: "",
      method: "",
      apiKey: "",
    };
    checkPermission("btn206") &&
      props.history.push("/app/create-webhook-form", {
        webhooksData: data,
        edit: false,
        ajax: true,
        appID: props.appId,
        appName: appName,
      });
  };

  const editWebhooksdata = ({ row }, e, val) => {
    e && e.stopPropagation();
    checkPermission("btn204") &&
      props.history.push("/app/create-webhook-form", {
        webhooksData: val ? val : row,
        edit: true,
        ajax: true,
        appID: props.appId,
        disabled: e ? false : true,
        appName: appName,
      });
  };

  const removeWebhooksdata = async ({ row }, e) => {
    e && e.stopPropagation();
    await props.deleteWebhook(row.appId, row._id);
  };

  const actionProvider = (row) => {
    console.log(row, "3436436");
    return (
      <div
        style={{
          display: "flex",
        }}
      >
        <div style={{ marginRight: 20 }}>
          <Tooltip title="Edit" aria-label="edit">
            <IconButton
              size="small"
              color="inherit"
              disabled={!checkPermission("btn204")}
              onClick={(e) =>
                checkPermission("btn204") && editWebhooksdata(row, e)
              }
            >
              <RiEditLine fontSize="20px" />
            </IconButton>
          </Tooltip>
        </div>
        <div>
          {/* {rows.length > 1 && ( */}
          {/* <Tooltip title="Delete" aria-label="delete">
               <IconButton
                 color="primary"
                 onClick={(e) => removeWebhooksdata(row, e)}
               >
                <RiDeleteBin5Line fontSize="22px" /> */}
          <Tooltip title="Delete" aria-label="delete">
            <IconButton
              size="small"
              color="inherit"
              disabled={!checkPermission("btn112")}
              onClick={(e) => {
                e.stopPropagation();
                checkPermission("btn112") &&
                  confirmDialog(dltMsg("this webhook"), () =>
                    removeWebhooksdata(row, e)
                  );
              }}
            >
              <RiDeleteBin5Line style={{ fontSize: "20px" }} color="inherit" />
            </IconButton>
          </Tooltip>
          {/* )} */}
        </div>
      </div>
    );
  };

  const viewWebhookdata = (row) => {
    editWebhooksdata({}, null, row);
  };
  return (
    <div>
      <Grid container spacing={2}>
        <Grid xs={12} item container justifyContent="flex-end">
          <Button
            size="small"
            variant="contained"
            onClick={() => checkPermission("btn206") && pushToWebhooks()}
            startIcon={<AddIcon />}
            disabled={!checkPermission("btn206")}
            style={{ padding: "4px 40px 4px 40px" }}
          >
            Create Webhooks
          </Button>
        </Grid>
        <Grid item xs={12}>
          <WrappedDexTable
            data={rows}
            // hideColumnChooser={true}
            columWidthsType="percentage"
            columnOrder={["type", "url", "method", "apiKey", "actions"]}
            columns={[
              { name: "type", title: "Type" },
              { name: "url", title: "URL" },
              { name: "method", title: "Method" },
              { name: "apiKey", title: "API Key" },
              { name: "actions", title: "Actions" },
            ]}
            defaultColumnWidths={[
              { columnName: "type", width: 20 },
              { columnName: "url", width: 20 },
              { columnName: "method", width: 20 },
              { columnName: "apiKey", width: 20 },
              { columnName: "actions", width: 19 },
            ]}
            disableReordering={true}
            pagination={false}
            noDataMsg="Webhooks not found"
            formatters={[{ formatterFn: actionProvider, columns: ["actions"] }]}
            onClickRow={(row) => viewWebhookdata(row)}
            Read={checkPermission("btn227")}
            hidden={[...webhooksHidden]}
            setHiddenColmn={(hidden) => {
              props.setHiddenColumn(hidden, "webhooksHidden");
            }}
            // tableRow={(props) => {
            //   return (
            //     <CustomTableRow
            //       {...props}
            //       removeRows={removeRows}
            //       addWebhook={addWebhook}
            //       editWebhook={editWebhook}
            //       deleteWebhook={deleteWebhook}
            //       addNewRow={
            //         props.tableRow.rowId == rows.length - 1 && (
            //           <Tooltip title="Add" aria-label="add">
            //             <IconButton>
            //               <AddCircleOutlineOutlinedIcon
            //                 color="secondary"
            //                 onClick={addRows}
            //               />
            //             </IconButton>
            //           </Tooltip>
            //         )
            //       }
            //     />
            //   );
            // }}
          />
        </Grid>
      </Grid>
    </div>
  );
}
const mapDispatchToProps = (dispatch) => ({
  setHiddenColumn: (hidden, type) =>
    dispatch(ApplicationDetailsUpadateHiddenCol(hidden, type)),
});

const mapStateToProps = (state) => {
  return {
    appTable: state.appDetailsRedcer.appTable,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withSection(ApplicationWebhooksDex, "Webhooks")));
