import getURL from "../../../../api/config";
import API_helpers from "../../../../api/index";
import helpers from "helpers/helpers";
import axios from "axios";
import * as types from "./actionTypes";
import appApi from "api/ApplicationAPIs/index";
export const getApplications = (page, size, filters, sort, num) => {
  let user_info = helpers.decodeAccessToken();
  let userId = user_info.userdetails.id;
  console.log({ page, size, filters, sort });
  let url = getURL("APPLICATIONS");
  url = url + "?";
  if (page != undefined) {
    url = `${url}page=${page}&`;
  }
  if (size != undefined) {
    url = `${url}size=${size}&`;
  }
  if (filters != undefined && filters != "" && filters) {
    url = `${url}filters=${filters}&`;
  }
  if (sort != undefined && sort != "") {
    url = `${url}sort=${sort}&`;
  }
  return async (dispatch) => {
    dispatch({
      type: types.FETCHING_APPLICATIONS,
    });
    try {
      console.log("url", url.substr(0, url.length - 1));
      let res = await API_helpers.GET(url.substr(0, url.length - 1));
      let sub_app_res = await appApi.getSubscribedApplications(userId);
      sub_app_res = (sub_app_res.data.data || []).map((f) => f.appID);
      let filt = JSON.parse(filters);
      let data = num === "nullArray" ? [] : res.data.data || [];
      //if appId exist in subscription array then that app is subscribed so appSubscribe is true
      data = data.map((item) => {
        if (sub_app_res.includes(item._id)) {
          return {
            ...item,
            appSubscribe: true,
          };
        } else {
          return {
            ...item,
            appSubscribe: false,
          };
        }
      });

      dispatch({
        type: types.FETCHING_APPLICATIONS_SUCCESS,
        payload: data,
        totalCount: res.data.totalCount || 0,
        status: filt.status,
        num: num,
      });
    } catch (err) {
      dispatch({
        type: types.FECTH_APPLICATIONS_FAILED,
        payload: [],
        error: err.message || "Error occured while fetching the data.",
      });
    }
  };
};

export const updateFilterData = ({ filter, filterArray }) => ({
  type: types.UPDATE_APPLICATION_FILTER_DATA,
  payload: {
    filter,
    filterArray,
  },
});

export const updateSort = ({ sorting, sort }) => ({
  type: types.UPDATE_APP_SORT,
  payload: { sorting, sort },
});

export const storeSearchedValue = (value) => ({
  type: types.STORE_SEARCHED_VALUE,
  payload: value,
});

export const updateApplicationHiddenTableCol = (hidden) => ({
  type: types.UPDATE_COLUMN_HIDDEN,
  payload: hidden,
});
