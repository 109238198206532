import * as actions from "./actionTypes";
import datastandardsAPIS from "api/DataStandardsAPIs/index";
import { Hidden } from "@material-ui/core";

function actionfetchAllDataStandards(pageno, size, filters, sort) {
  console.log({ actions });
  return async (dispatch) => {
    dispatch({
      type: actions.FETCHING_DATASTANDARDS,
      loading: true,
    });
    try {
      let data = await datastandardsAPIS.fetchAllDataStandardsWithParams(
        pageno,
        size,
        filters,
        sort
      );
      dispatch({
        type: actions.FETCHING_DATASTANDARDS_SUCCESS,
        loading: false,
        totalCount: data._totalcount,
        data: data.data || [],
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: actions.FETCHING_DATASTANDARDS_FAILURE,
        loading: false,
        error: err,
      });
    }
  };
}

function blockDatatandards(dataStandardId, type) {
  return async (dispatch) => {
    try {
      let blockdata = await datastandardsAPIS.blockDatatandards(
        dataStandardId,
        type
      );
      const { _msg, _status } = blockdata.data;
      return {
        success: true,
        _msg,
      };
    } catch (err) {
      const { _msg } = err?.response?.data || { _msg: "Network issue!" };
      return {
        success: false,
        _msg,
      };
    }
  };
}

export const bulkUploadfiles = (payload) => async () => {
  try {
    const res_data = await datastandardsAPIS.bulkUploadfiles(payload);
    const { _msg, _status, data } = res_data.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    const { _msg, _status, data } = err.response.data;
    return {
      _msg,
      _status,
      data,
    };
  }
};

const updateFilterData = ({ filter, filterArray }) => ({
  type: actions.UPDATE_DATASTANDARDS_FILTER_DATA,
  payload: { filter, filterArray },
});

const updateSort = ({ sorting, sort }) => ({
  type: actions.UPDATE_DATASTANDARDS_SORT,
  payload: { sorting, sort },
});

const updateDataStandardsHiddenCol = (hidden) => ({
  type: actions.UPDATE_DS_HIDDEN_COL,
  payload: hidden,
});
export default {
  actionfetchAllDataStandards,
  blockDatatandards,
  updateFilterData,
  updateSort,
  updateDataStandardsHiddenCol,
};
