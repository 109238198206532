/**
 * Contains all ajax call for Applications
 */

import getURL from "../config";
import API_helpers from "../index";
import { w3cwebsocket as W3CWebSocket } from "websocket";
const checkCDUField = async (feild, indType) => {
  let URL = getURL("CHECK_CDU_FEILD")(feild, indType);
  try {
    const res = await API_helpers.GET(URL);
    return res;
  } catch (err) {
    throw err;
  }
};

async function editBasicDetails(appId, payload) {
  let url = getURL("APPLICATION_ENDPOINT")(appId);
  try {
    let res = await API_helpers.PUT(url, payload, {
      headers: { "Content-Type": "application/json" },
    });
    return res;
  } catch (err) {
    throw err;
  }
}

async function editModule(appId, moduleId, payload) {
  let url = getURL("MODULE_END_POINT")(appId, moduleId);
  try {
    let res = await API_helpers.PUT(url, payload, {
      headers: { "Content-Type": "application/json" },
    });
    return res;
  } catch (err) {
    throw err;
  }
}

async function deleteModule(appId, moduleId, payload) {
  let url = getURL("MODULE_END_POINT")(appId, moduleId);
  try {
    let res = await API_helpers.DELETE(url, payload, {
      headers: { "Content-Type": "application/json" },
    });
    return res;
  } catch (err) {
    throw err;
  }
}

async function addModule(appId, payload) {
  let url = getURL("ADD_MODULE")(appId);
  try {
    let res = await API_helpers.POST(url, payload, {
      headers: { "Content-Type": "application/json" },
    });
    return res;
  } catch (err) {
    throw err;
  }
}

async function addApplicationAPI(appId, payload) {
  let url = getURL("ADD_API")(appId);
  try {
    let res = await API_helpers.POST(url, payload, {
      headers: { "Content-Type": "application/json" },
    });
    return res;
  } catch (err) {
    throw err;
  }
}

async function editApplicationAPI(appId, apiId, payload) {
  let url = getURL("APPLICATION_API_END_POINT")(appId, apiId);
  try {
    let res = await API_helpers.PUT(url, payload, {
      headers: { "Content-Type": "application/json" },
    });
    return res;
  } catch (err) {
    throw err;
  }
}

async function deleteApplicationAPI(appId, apiId, payload) {
  let url = getURL("APPLICATION_API_END_POINT")(appId, apiId);
  try {
    let res = await API_helpers.DELETE(url, {
      headers: { "Content-Type": "application/json" },
    });
    return res;
  } catch (err) {
    throw err;
  }
}

async function addWebhook(appId, payload) {
  let url = getURL("ADD_WEBHOOK")(appId);
  try {
    let res = await API_helpers.POST(url, payload, {
      headers: { "Content-Type": "application/json" },
    });
    return res;
  } catch (err) {
    throw err;
  }
}

async function editBillableService(appId, billableId, payload) {
  let url = getURL("APPLICATION_BILLING_END_POINT")(appId, billableId);
  try {
    let res = await API_helpers.PUT(url, payload, {
      headers: { "Content-Type": "application/json" },
    });
    return res;
  } catch (err) {
    throw err;
  }
}

async function deleteBillableService(appId, billableId) {
  let url = getURL("APPLICATION_BILLING_END_POINT")(appId, billableId);
  try {
    let res = await API_helpers.DELETE(url, {
      headers: { "Content-Type": "application/json" },
    });
    return res;
  } catch (err) {
    throw err;
  }
}

async function editWebhook(appId, webhookId, payload) {
  let url = getURL("WEBHOOK_END_POINT")(appId, webhookId);
  try {
    let res = await API_helpers.PUT(url, payload, {
      headers: { "Content-Type": "application/json" },
    });
    return res;
  } catch (err) {
    throw err;
  }
}

async function deleteWebhook(appId, webhookId, payload) {
  let url = getURL("WEBHOOK_END_POINT")(appId, webhookId);
  try {
    let res = await API_helpers.DELETE(url, payload, {
      headers: { "Content-Type": "application/json" },
    });
    return res;
  } catch (err) {
    throw err;
  }
}

async function addBillableService(appId, payload) {
  let url = getURL("ADD_APPLICATION_BILLING")(appId);
  try {
    let res = await API_helpers.POST(url, payload, {
      headers: { "Content-Type": "application/json" },
    });
    return res;
  } catch (err) {
    throw err;
  }
}

async function addEnvironment(appId, payload) {
  let url = getURL("ADD_ENVIRONMENT")(appId);
  try {
    let res = await API_helpers.POST(url, payload, {
      headers: { "Content-Type": "application/json" },
    });
    return res;
  } catch (err) {
    throw err;
  }
}

async function editEnvironment(appId, envId, payload) {
  let url = getURL("EDIT_ENVIRONMENT")(appId, envId);
  try {
    let res = await API_helpers.PUT(url, payload, {
      headers: { "Content-Type": "application/json" },
    });
    return res;
  } catch (err) {
    throw err;
  }
}

async function deleteEnvironment(appId, envId, payload) {
  let url = getURL("EDIT_ENVIRONMENT")(appId, envId);
  try {
    let res = await API_helpers.DELETE(url, payload, {
      headers: { "Content-Type": "application/json" },
    });
    return res;
  } catch (err) {
    throw err;
  }
}

async function addRole(appId, payload) {
  let url = getURL("APPLICATION_ROLES_ADD")(appId);
  try {
    let res = await API_helpers.POST(url, payload, {
      headers: { "Content-Type": "application/json" },
    });
    return res;
  } catch (err) {
    throw err;
  }
}

async function editRole(appId, roleId, payload) {
  let url = getURL("APPLICATION_ROLES_END_POINT")(appId, roleId);
  try {
    let res = await API_helpers.PUT(url, payload, {
      headers: { "Content-Type": "application/json" },
    });
    return res;
  } catch (err) {
    throw err;
  }
}

async function deleteRole(appId, roleId) {
  let url = getURL("APPLICATION_ROLES_END_POINT")(appId, roleId);
  try {
    let res = await API_helpers.DELETE(url, {
      headers: { "Content-Type": "application/json" },
    });
    return res;
  } catch (err) {
    throw err;
  }
}

async function publishApi(appId, apiId, payload) {
  let url = getURL("APPLICATION_API_PUBLISH")(appId, apiId);
  try {
    let res = await API_helpers.PUT(url, {
      headers: { "Content-Type": "application/json" },
    });
    return res;
  } catch (err) {
    throw err;
  }
}

async function get_Categories() {
  try {
    let res = await API_helpers.GET(getURL("CATEGORIES"));
    return res;
  } catch (err) {
    return err;
  }
}

const fetchIntrodocumentaion = async (appId) => {
  const URL = getURL("GET_SECTIONS")(appId);
  try {
    return await API_helpers.GET(URL);
  } catch (err) {
    return err;
  }
};

const getBillableService = async () => {
  try {
    let res = await API_helpers.GET(getURL("BILLABLE_SERVICES"));
    return res;
  } catch (err) {
    throw err;
  }
};

const getTags = async () => {
  try {
    const res = await API_helpers.GET(getURL("TAGS"));
    return res;
  } catch (err) {
    throw err;
  }
};

async function authorizationSync(appId) {
  let url = getURL("AUTHORIZATION_SYNC")(appId);
  try {
    let res = await API_helpers.GET(url, {
      headers: { "Content-Type": "application/json" },
    });
    return res;
  } catch (err) {
    throw err;
  }
}

async function billableSync(appId) {
  let url = getURL("BILLABLE_SYNC")(appId);
  try {
    let res = await API_helpers.GET(url, {
      headers: { "Content-Type": "application/json" },
    });
    return res;
  } catch (err) {
    throw err;
  }
}
// let res = await API_helpers.POST(getURL("OUTGOING_API")(appId), data);

async function addOutgoingAPI(appId, payload) {
  let url = getURL("OUTGOING_API")(appId);
  try {
    let res = await API_helpers.POST(url, payload, {
      headers: { "Content-Type": "application/json" },
    });
    return res;
  } catch (err) {
    throw err;
  }
}

async function editOutgoingAPI(appId, id, payload) {
  try {
    const res = await API_helpers.PUT(
      getURL("EDIT_OUTGOING_API")(appId, id),
      payload
    );
    return res;
  } catch (err) {
    throw err;
  }
}

async function getApplicationsApi(appId) {
  const filters = { isPublished: true };
  let url = getURL("GET_ALL_APPLICATIONS_API")(appId);
  url = `${url}?filters=${JSON.stringify(filters)}`;
  try {
    let res = await API_helpers.GET(url);
    return res;
  } catch (err) {
    throw err;
  }
}

async function getOutgoingDetails(appId, apiId) {
  try {
    let res = await API_helpers.GET(
      getURL("FETCH_OUTGOING_DETAILS")(appId, apiId)
    );
    return res;
  } catch (err) {
    throw err;
  }
}

async function getenvironmentTags(appId, envId) {
  try {
    const res = await API_helpers.GET(
      getURL("ENVIRONMENT_TAGSTATUS")(appId, envId)
    );
    return res;
  } catch (err) {
    throw err;
  }
}

async function fetchDocumentation(appId, docId) {
  const url = getURL("FETCH_DOCUMENTATION")(appId);
  let URL = docId ? `${url}?documentId={sectionId:"${docId}}` : url;
  try {
    const res = await API_helpers.GET(URL);
    return res;
  } catch (err) {
    throw err;
  }
}

const fetch_documents = async (appId, type) => {
  try {
    const res = await API_helpers.GET(
      getURL("USAGE_DOCUMENTATION")(appId, type)
    );
    return res;
  } catch (err) {
    throw err;
  }
};

const add_documents = async (appId, type, payload) => {
  try {
    const res = await API_helpers.POST(
      getURL("USAGE_DOCUMENTATION")(appId, type),
      {
        ...payload,
      }
    );
    return res;
  } catch (err) {
    throw err;
  }
};

const update_documents = async (appId, docId, payload) => {
  try {
    const res = await API_helpers.PUT(
      getURL("UPDATE_USAGE_DOCUMENTATION")(appId, docId),
      {
        ...payload,
      }
    );
    return res;
  } catch (err) {
    throw err;
  }
};

const delete_documentation = async (appId, docId) => {
  try {
    const res = await API_helpers.DELETE(
      getURL("UPDATE_USAGE_DOCUMENTATION")(appId, docId)
    );
    return res;
  } catch (err) {
    throw err;
  }
};
const assignOrDissmiss = async (type, appId, teamId) => {
  try {
    const res = await API_helpers.GET(
      getURL("SELECT_OR_UNSELECT_TEAM")(type, appId, teamId),
      {
        headers: {
          "Content-Type": "application/json",
          userdetails: JSON.stringify({ name: "palani" }),
          organization: "11111",
        },
      }
    );
    return res;
  } catch (err) {
    throw err;
  }
};

const getApplicationsByStatus = async (status) => {
  try {
    let res = await API_helpers.GET(
      getURL("GET_APPLICATIONS_BY_STATUS")(status)
    );
    return res;
  } catch (err) {
    throw err;
  }
};

const fetchIndustryType = async () => {
  try {
    const res = await API_helpers.GET(getURL("FETCH_INDUSTRY_TYPE"), {
      headers: {
        "Content-Type": "application/json",
        userdetails: JSON.stringify({ name: "palani" }),
        organization: "11111",
      },
    });
    return res.data?.data || [];
  } catch (err) {
    throw err;
  }
};

const add_category = async (value) => {
  try {
    const res = await API_helpers.POST(getURL("ADD_CATEGORY"), { name: value });
    return res;
  } catch (err) {
    throw err;
  }
};
async function addSubscribe(appId, payload) {
  let url = getURL("APPLICATION_SUBSCRIBE")(appId);
  try {
    let res = await API_helpers.POST(url, payload, {
      headers: { "Content-Type": "application/json" },
    });
    return res;
  } catch (err) {
    throw err;
  }
}
async function removeSubscribe(appId, userId) {
  let url = getURL("APPLICATION_UNSUBSCRIBE")(appId, userId);
  try {
    let res = await API_helpers.DELETE(url, {
      headers: { "Content-Type": "application/json" },
    });
    return res;
  } catch (err) {
    throw err;
  }
}
const getNotifications = async (userId, page) => {
  try {
    let res = await API_helpers.GET(
      getURL("FETCH_NOTIFICATIONS")(userId, page)
    );
    return res;
  } catch (err) {
    throw err;
  }
};

const getSubscribedApplications = async (userId) => {
  try {
    let res = await API_helpers.GET(
      getURL("FETCH_USER_SUBSCRIBED_APPLICATIONS")(userId)
    );
    return res;
  } catch (err) {
    throw err;
  }
};

const getWebSocket = () => {
  const tokenData = JSON.parse(sessionStorage.getItem("token"));
  if (tokenData) {
    const token = tokenData.accessToken;
    console.log("tokokokokokok", token);
    try {
      const web_socket = new W3CWebSocket(
        getURL("FETCH_USER_NOTIFICATIONS")(token)
      );
      return web_socket;
    } catch (err) {
      throw err;
    }
  }
};

async function editNotification(id, payload) {
  let url = getURL("UPDATE_NOTIFICATIONS")(id);
  try {
    let res = await API_helpers.PUT(url, payload, {
      headers: { "Content-Type": "application/json" },
    });
    return res;
  } catch (err) {
    throw err;
  }
}

const add_app_document = async (payload, appId) => {
  let url = getURL("ADD_DOCUMENT")(appId);

  try {
    let res = await API_helpers.POST(url, payload, {
      headers: { "Content-Type": "application/json" },
    });
    return res;
  } catch (err) {
    throw err;
  }
};

const fetchSections = async (appId, type, Id) => {
  try {
    let url = getURL("GET_SECTIONS_USAGE")(appId, type, Id);
    let res = await API_helpers.GET(url, {
      headers: { "Content-Type": "application/json" },
    });
    return res;
  } catch (err) {
    throw err;
  }
};

async function sequanceorder(appId) {
  let url = getURL("SEQUENCE_ORDER")(appId);
  try {
    let res = await API_helpers.PUT(url, {
      headers: { "Content-Type": "application/json" },
    });
    return res;
  } catch (err) {
    throw err;
  }
}

const deleteSection = async (appId, secId) => {
  const url = getURL("DELETE_SECTION")(appId, secId);
  try {
    let res = await API_helpers.DELETE(url, {
      headers: { "Content-Type": "application/json" },
    });
    return res;
  } catch (err) {
    throw err;
  }
};

const addSection = async (appId, payload) => {
  try {
    return API_helpers.POST(getURL("ADD_DOCUMENT")(appId), payload, {
      headers: { "Content-Type": "application/json" },
    });
  } catch (err) {
    throw err;
  }
};

const updateSection = async (appId, secId, payload) => {
  try {
    return API_helpers.PUT(
      getURL("UPDATE_DOCUMENTATION")(appId, secId),
      payload,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
  } catch (err) {
    throw err;
  }
};

const fetchSequenceOrder = (appId, payload) => {
  let url = getURL("SEQUENCE_ORDER")(appId);
  try {
    return API_helpers.PUT(url, payload);
  } catch (err) {
    throw err;
  }
};

let APIs = {
  editBasicDetails,
  editModule,
  addModule,
  deleteModule,
  addApplicationAPI,
  editApplicationAPI,
  deleteApplicationAPI,
  addWebhook,
  editWebhook,
  deleteWebhook,
  addBillableService,
  editBillableService,
  addEnvironment,
  editEnvironment,
  deleteEnvironment,
  deleteBillableService,
  addRole,
  editRole,
  deleteRole,
  publishApi,
  authorizationSync,
  addOutgoingAPI,
  editOutgoingAPI,
  getApplicationsApi,
  getOutgoingDetails,
  getenvironmentTags,
  get_Categories,
  fetchDocumentation,
  add_documents,
  fetch_documents,
  update_documents,
  delete_documentation,
  assignOrDissmiss,
  billableSync,
  fetchIndustryType,
  getBillableService,
  getTags,
  getApplicationsByStatus,
  checkCDUField,
  add_category,
  addSubscribe,
  removeSubscribe,
  getNotifications,
  getSubscribedApplications,
  getWebSocket,
  editNotification,
  add_app_document,
  fetchSections,
  sequanceorder,
  deleteSection,
  addSection,
  updateSection,
  fetchSequenceOrder,
};

export default APIs;
