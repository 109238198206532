const Styles = (theme) => ({
  // root: {
  //   marginTop: "8px",
  // },
  title: {
    textTransform: "capitalize",
    fontWeight: "bold",
    justifyContent: "center",
  },
  divider: {
    marginTop: "12px",
    height: "2px",
    background: "rgb(175, 175, 175)",
  },
  radio: {
    marginLeft: "30px",
  },
  radioTwo: {
    marginLeft: "37px",
  },
});
export default Styles;
