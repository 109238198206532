// /**
//  * Contains All the API call for the Billable services
//  */
import getURL from "../config";
import API_helpers from "../index";

// // const getSubscriptionModel = async () => {
// //   try {
// //     let res = await API_helpers.GET(getURL("FETCH_ALL_SUBSCRIPTION_MODEL"));
// //     return res;
// //   } catch (err) {
// //     throw err;
// //   }
// // };

const getStatusProfileData = async (page, size, filters, sort) => {
  console.log(page, size, filters, sort, 45678765445);
  let url = getURL("FETCH_ALL_STATUS_PROFILE");
  url = url + "?";
  let params = { page, size, filters, sort };
  Object.keys(params)
    .filter((k) => params[k] != undefined || params[k] != null)
    .forEach((k) => (url = `${url}${k}=${params[k]}&`));
  url = url.substr(0, url.length - 1);
  console.log(url, "urll");
  try {
    let res = await API_helpers.GET(url, {
      headers: {
        "Content-Type": "application/json",
        userdetails: JSON.stringify({ name: "palani" }),
        organization: "11111",
      },
    });
    return res;
  } catch (err) {
    throw err;
  }
};

const addStatusProfile = async (name, payload) => {
  try {
    let res = await API_helpers.POST(
      getURL("ADD_STATUS_PROFILE")(name),
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          userdetails: JSON.stringify({ name: "palani" }),
          organization: "11111",
        },
      }
    );
    return res;
  } catch (err) {
    throw err;
  }
};

const getStatusProfileByName = async (name) => {
  try {
    let res = await API_helpers.GET(
      getURL("GET_STATUS_PROFILE_BY_NAME")(name),
      {
        headers: {
          "Content-Type": "application/json",
          userdetails: JSON.stringify({ name: "palani" }),
          organization: "11111",
        },
      }
    );
    console.log(
      "============================================getStatusProfileByName"
    );
    return res.data;
  } catch (err) {
    throw err;
  }
};

const setDefaultStatusProfile = async (payload) => {
  try {
    let res = await API_helpers.POST(
      getURL("SET_DEFAULT_STATUS_PROFILE"),
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          userdetails: JSON.stringify({ name: "palani" }),
          organization: "11111",
        },
      }
    );
    return res.data;
  } catch (err) {
    throw err;
  }
};

const deleteStatusProfile = async (name, payload) => {
  try {
    let res = await API_helpers.PUT(
      getURL("DELETE_STATUS_PROFILE")(name),
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          userdetails: JSON.stringify({ name: "palani" }),
          organization: "11111",
        },
      }
    );
    console.log(res, "---------respone");
    return res.data;
  } catch (err) {
    throw err;
  }
};

const EditStatusProfile = async (name, statusName, payload) => {
  try {
    let res = await API_helpers.PUT(
      getURL("EDIT_STATUS_PROFILE")(name, statusName),
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          userdetails: JSON.stringify({ name: "palani" }),
          organization: "11111",
        },
      }
    );
    return res.data;
  } catch (err) {
    throw err;
  }
};
var APIs = {
  getStatusProfileData,
  getStatusProfileByName,
  addStatusProfile,
  setDefaultStatusProfile,
  deleteStatusProfile,
  EditStatusProfile,
};

export default APIs;
