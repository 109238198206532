import getURL from "../../../../api/config";
import API_helpers from "../../../../api/index";
import axios from "axios";
import APIs from "../../../../api/ApplicationAPIs/index";
import * as types from "./actionTypes";
import teamsAPIS from "api/TeamsAPIs/index";
let updateTeams = [];
let clickedTeam = { users: [] };
let selectedTeam = [];

export const getApplicationDetails = (id) => {
  return async (dispatch) => {
    dispatch({
      type: types.FETCH_APPLICATION_DETAILS,
    });
    try {
      let res = await API_helpers.GET(getURL("FETCH_APPLICATION_DETAILS")(id), {
        headers: {
          "Content-Type": "application/json",
          userdetails: JSON.stringify({ name: "palani" }),
          organization: "11111",
        },
      });
      dispatch({
        type: types.FETCH_APPLICATION_DETAILS_SUCCESS,
        payload: res.data.data || [],
        message: res.data._msg,
      });
    } catch (err) {
      const { _msg } = err?.response?.data || {};
      dispatch({
        type: types.FETCH_APPLICATION_DETAILS_FAILED,
        message: _msg || "Network issue",
      });
    }
  };
};

export const clearMessage = () => ({
  type: types.CLEAR_MESSAGE,
});

export const publishApplication = (status, id) => {
  console.log("publish applications", status, id);
  // /applications/{appId}/state
  return async (dispatch) => {
    try {
      let res = await API_helpers.POST(
        getURL("PUBLISH_APPLICATIONS")(id),
        { ...status },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      dispatch({
        type: types.PUBLISHED_APPLICATION,
        message: res.data._msg,
      });
      console.log(res.data, "publish applications");
    } catch (err) {
      const { _msg } = err?.response?.data || {};
      dispatch({
        type: types.PUBLISH_APPLICATION_FAILED,
        message: _msg || "Network issue",
      });
    }
  };
};

export const getApplicationsApi = (appId) => {
  console.log(appId, "applications that are published6766");
  return async (dispatch) => {
    dispatch({
      type: types.FETCHING_APPLICATIONS_API,
    });
    try {
      let res = await API_helpers.GET(
        getURL("GET_ALL_APPLICATIONS_API")(appId)
      );
      let data = [];
      console.log(appId, res.data.data, "vfhghjgfjdgjgdjgdjj");
      if (res.data.data) {
        data = res.data.data;
      }
      console.log(res.data, "applications that are published6766");
      dispatch({
        type: types.FETCHED_APPLICATIONS_API,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: types.FETCHE_APPLICATIONS_API_FAILED,
      });
    }
  };
};

export const outgoingApi = (appId, apiId, url) => {
  return async (dispatch) => {
    console.log(appId, apiId, url, "hsghgsfhfsh");
    try {
      let res = await API_helpers.POST(
        getURL("OUTGOING_API")(appId, apiId),
        url
      );
      console.log(res, "saving outgoing");
    } catch (err) {
      console.log(err, "saving outgoing");
    }
  };
};

export const updateApplicationAPIS = (data) => ({
  type: types.UPDATE_APP_APIS,
  payload: data,
});

export const usageDocumentaion =
  (appId, docId, val, payload) => async (dispatch) => {
    dispatch({
      type: types.ACTION_BEGIN_USAGE_DOC,
    });
    try {
      let res = {};
      if (docId !== "add" && val !== "delete") {
        res = await APIs.update_documents(appId, docId, payload);
      }
      if (docId === "add") {
        res = await APIs.add_documents(appId, "/add", payload);
      }
      if (val === "delete") {
        res = await APIs.delete_documentation(appId, docId);
      }
      const { _msg } = res.data;
      dispatch({
        type: types.ACTION_SUCCESS_USAGE_DOC,
        msg: _msg,
        single: val,
      });
      dispatch({
        type: types.RESET_DOC,
      });
    } catch (err) {
      const { _msg } = err?.response?.data || { _msg: "Network Error" };
      dispatch({
        type: types.ACTION_FAILED_USAGE_DOC,
        msg: _msg,
      });
      dispatch({
        type: types.RESET_DOC,
      });
    }
  };

export const FetchDoc = (appId, type) => async (dispatch) => {
  dispatch({
    type: types.FETCH_DOC,
  });
  try {
    let res = await APIs.fetch_documents(appId, type);
    dispatch({
      type: types.FETCHED_DOC,
      payload: res.data.data || [],
    });
  } catch (err) {
    dispatch({
      type: types.FETCH_DOC_FAILED,
    });
  }
};

export const fetchTeamData = (filter, team) => async (dispatch) => {
  dispatch({
    type: types.FETCH_TEAMS_FOR_APP_DETAIL,
  });
  try {
    let res = await teamsAPIS.fetchAllTeamsWithParams(
      undefined,
      undefined,
      filter
    );

    let data = res.data || [];
    let result = {};
    let clickedTeamData = { users: [] };
    if (team) {
      result = await updateTeamsInAppDetails({ team, data });
    }

    dispatch({
      type: types.FETCHED_TEAMS_FOR_APP_DETAIL,
      loading: false,
      totalCount: res._totalcount,
      payload: result.updateTeams || data,
      selectedTeam: team,
      clickedTeam: clickedTeamData,
    });
  } catch (err) {
    dispatch({
      type: types.FETCH_TEAMS_FOR_APP_DETAIL_FAIL,
    });
  }
};

const updateTeamsInAppDetails = (params) => {
  let { team, data } = params;
  let updatedTeamData = [];
  data.map((item, id) => {
    let flag = false;
    team.map((itm, idx) => {
      if (data[id]._id === team[idx]._id) {
        updatedTeamData.push({ ...item, checked: true });
        flag = true;
      }
      return 0;
    });
    if (!flag) {
      updatedTeamData.push({ ...item, checked: false });
    }
    return 0;
  });
  selectedTeam = team;
  return {
    selectedTeam,
    updateTeams: updatedTeamData,
    clickedTeam,
  };
};

const filterPayload = (params) => {
  let { selTeam, team, data, val } = params;
  updateTeams = data.map((item, id) => {
    if (item._id === team._id) {
      clickedTeam = val ? { ...item } : { users: [] };
      return {
        ...item,
        checked: val,
      };
    } else {
      return { ...item };
    }
  });

  if (val) {
    selectedTeam = selTeam.concat(updateTeams.filter((item) => item.checked));
    selectedTeam = Array.from(new Set(selectedTeam.map((a) => a.name))).map(
      (name) => {
        return selectedTeam.find((a) => a.name === name);
      }
    );
  } else {
    selectedTeam = selTeam.filter((itm) => itm.name !== team.name);
  }

  return {
    selectedTeam,
    updateTeams,
    clickedTeam,
  };
};

export const show_selectedTeam = (team, data, val) => async (dispatch) => {};

export const handleSelectTeam =
  (selTeam, team, data, val, appId) => async (dispatch) => {
    dispatch({
      type: types.ASSIGN_DISMISS_TEAM,
    });

    try {
      let type = val ? "assign" : "dismiss";
      let res = await APIs.assignOrDissmiss(type, appId, team._id);
      const { _status, _msg } = res.data;
      if (_status === 200) {
        let result = await filterPayload({ selTeam, team, data, val });
        dispatch({
          type: types.ADD_TEAM_TO_APP_DETAIL,
          updateTeams: result.updateTeams,
          selectedTeam: result.selectedTeam,
          clickedTeam: result.clickedTeam,
          msg: _msg,
        });
        dispatch({
          type: types.RESET_PROPS,
        });
      }
    } catch (err) {
      console.log("ererererer", err);
      const { _msg } = err?.response?.data || { _msg: "Network issue" };
      dispatch({
        type: types.RESET_TEAM_IN_APP_DETAIL,
        msg: _msg,
      });
      dispatch({
        type: types.RESET_PROPS,
      });
    }
  };

export const fecthIntroDocuments = (appId, docId) => async (dispatch) => {
  dispatch({
    type: types.FETCH_INTRO_DOC,
  });
  try {
    const res = await APIs.fetchDocumentation(appId, docId);
    const { _msg } = res.data;
    dispatch({
      type: types.FETCHED_INTRO_DOC,
      payload: res.data.data || [],
      msg: _msg,
    });
    dispatch({
      type: types.RESET_DOC,
    });
  } catch (err) {
    const { _msg } = err?.response?.data || {};
    dispatch({
      type: types.FETCH_INTRO_DOC_FAIL,
      msg: _msg || "Network issue",
    });
    dispatch({
      type: types.RESET_DOC,
    });
  }
};

export const set_ClickedTeam = (team) => ({
  type: types.SET_CLICKED_TEAM,
  payload: team,
});

export const ApplicationDetailsUpadateHiddenCol = (hidden, key) => ({
  type: types.UPDATE_APPLICATION_DETAILS_HIDDEN_COL,
  payload: hidden,
  key: key,
});

export const ApplicationOnboardUpadateHiddenCol = (hide, key) => ({
  type: types.UPDATE_ONBOARD_HIDDEN_COL,
  payload: hide,
  key: key,
});
