import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  Typography,
  Button,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
  Paper,
  Table,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    borderBottom: "1px solid #E5E5E5",
  },
  modalheight: {
    // height: "430px",
    width: "600px",
  },
}));

export default function ModalBlockComponent(props) {
  const {
    open,
    handleClose,
    text,
    action,
    Icon,
    yes,
    color,
    projectassignedModal,
    blockmodal,
    assigned,
    assignStatus,
  } = props;
  const classes = useStyles();
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={!projectassignedModal ? "lg" : "sm"}
        fullWidth={!projectassignedModal ? false : true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {!projectassignedModal ? (
          <DialogContent
            style={{
              height: 200,
              width: 450,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <DialogContentText id="alert-dialog-description">
              {Icon && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {Icon}
                </div>
              )}
              <Typography
                style={{
                  fontSize: "0.875rem",
                }}
                variant="body2"
              >
                {text}
              </Typography>
            </DialogContentText>
          </DialogContent>
        ) : (
          <>
            <DialogTitle className={classes.title}>
              {" "}
              <Typography gutterBottom variant="h4">
                <b>Assigned</b>
              </Typography>
            </DialogTitle>
            <DialogContent className={classes.modalheight}>
              <DialogContentText id="alert-dialog-description">
                <TableContainer style={{ height: "410px" }}>
                  <Table
                    // className={classes.table}
                    style={{ border: "1px solid #E5E5E5" }}
                    size="medium"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>Project Name</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {assignStatus.appstatus === 200 ? (
                        assigned?.map((item) => {
                          return (
                            <div style={{ borderBottom: "1px solid #DFE3E6" }}>
                              <TableRow>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{
                                    paddingLeft: 35,
                                    height: "50px",
                                    borderBottom: "1px solid #fff",
                                  }}
                                >
                                  {item.name}
                                </TableCell>
                              </TableRow>
                            </div>
                          );
                        })
                      ) : (
                        <div
                          style={{
                            textAlign: "center",
                            padding: "10px 0",
                            color: "#000000",
                          }}
                        >
                          <Typography variant="body2">
                            {assignStatus.message}
                          </Typography>
                        </div>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </DialogContentText>
            </DialogContent>
          </>
        )}
        {!projectassignedModal ? (
          <DialogActions style={{ backgroundColor: "#F9FAFD" }}>
            <Button
              variant="outlined"
              size="small"
              onClick={handleClose}
              color="primary"
              autoFocus
            >
              No
            </Button>
            <Button
              style={{ color: "white" }}
              variant="contained"
              size="small"
              onClick={action}
              color="primary"
            >
              {yes}
            </Button>
          </DialogActions>
        ) : (
          <DialogActions>
            <Button variant="contained" size="small" onClick={handleClose}>
              Close
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
}
