import { Grid } from "@material-ui/core";
import React, { Component } from "react";
import InvoiceSummary from "./InvoiceSummary";
import Notes from "../Notes";
import PrintCrDrNote from "../print/PrintCrDrNote";
import PrintAppDetails from "../print/PrintInoiceDetails";
const PrintDetails = (props) => {
  return (
    <div style={{ width: "100%" }}>
      {/* <Grid container spacing={1}>
        <Grid item md={12}>
          <h5>Print Invoice</h5>
          <br />
        </Grid>
        <Grid item md={12}>
          <div
            style={{ width: "100%", padding: "50px", border: "solid 1px red" }}
          ></div>
         
        </Grid>
      </Grid> */}
      <div style={{ width: "100%" }}>
        <InvoiceSummary type="print" {...props} />
      </div>
      <div style={{ width: "100%", marginTop: 30 }}>
        <PrintCrDrNote {...props} />
      </div>
      <div style={{ width: "100%", marginTop: 30 }}>
        <PrintAppDetails {...props} />
      </div>
    </div>
  );
};
export default PrintDetails;
