export const filterData = (object) => {
  return [
    ...Object.keys(object).map((r) => {
      let weight =
        parseFloat(object[r].tps) > 0 ? parseFloat(object[r].tps) : 1;
      return {
        source: object[r].source,
        target: object[r].destination,
        weight: weight,
      };
    }),
  ];
};
