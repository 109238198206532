/**
 *
 */

import React, { Component } from "react";
import Breadcrumb from "components/Breadcrumbs/Breadcrumb";
import { CustomTextField } from "components/Customized";
import SearchIcon from "@material-ui/icons/Search";
import FilterComponent from "components/FilterComponent/FilterComponent";
import { Link } from "react-router-dom";
import {
  Typography,
  Grid,
  Button,
  InputAdornment,
  Tooltip,
  IconButton,
} from "@material-ui/core/";
import AddIcon from "@material-ui/icons/Add";
import actions from "./redux/action";
import { connect } from "react-redux";
import DexTable from "components/DexTable/DexTable";
import { withStyles } from "@material-ui/styles";
import { Pagination } from "@material-ui/lab";
import { RiEditLine, RiDeleteBin5Line } from "react-icons/ri";
import pricingMasterAPIS from "api/PricingMasterAPIs/index";
import { withSnackbar } from "notistack";
import dataOptions from "./filterOptions.json";
import { checkPermission } from "../../helpers/permissons";
import HelpdeskFAB from "../../views/HelpdeskFAB/HelpdeskFab";
import { confirmDialog } from "components/DeleteConfirmationDialog/Index";
import { dltMsg } from "helpers/helpers";
const Sytles = (theme) => ({
  footer: {
    position: "fixed",
    bottom: 0,
    left: 0,
    padding: 20,
    width: "100%",
    textAlign: "right",
    zIndex: 999,
    backgroundColor: "#f2f2f2",
  },
  customPagination: {
    width: "fit-content",
    marginLeft: "auto",
    "& .MuiPaginationItem-page.Mui-selected": {
      border: "0.5px solid #707070",
      margin: "0 10px",
      height: 30,
      width: 30,
      padding: "10px 13px",
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      boxShadow: "0px 3px 10px #5757571a",
      lineHeight: 1,
      borderRadius: 5,
      opacity: 1,
    },
    "& .MuiPaginationItem-page": {
      font: "normal normal normal 14px/19px Nunito Sans",
      letterSpacing: 0,
      color: "#000000",
      opacity: 0.6,
    },
  },
});

const findAndExecuteAjax = (type) => {
  switch (type) {
    case "Application Name":
      return (operator, value) => {
        if (operator === "Contains of") {
          return { appId: { $regex: value } };
        }
        if (operator === "Starts With") {
          return {
            appId: { $regex: `^${value}` },
          };
        }
        if (operator === "Ends With") {
          return {
            appId: { $regex: `${value}$` },
          };
        }
        if (operator === "Equals To") {
          // return { "application.name": value, $options: "i" };
          return {
            appId: { $regex: `^${value}$` },
          };
        }
      };
    case "Type":
      return (operator, value) => {
        if (operator === "Equals To") {
          return { type: value };
        }
      };
    case "Valid From":
      return (operator, value) => {
        console.log(operator, 23456543234);
        var timestamp = new Date(value.split(".").join("-")).getTime() / 1000;
        if (operator === "Equals To") {
          let gte = new Date(value.split(".").join("-")).getTime() / 1000;
          let lte = new Date(`${value}T23:59:59+05:30`).getTime() / 1000;
          return { validFrom: { $gte: gte, $lte: lte } };
        }
        if (operator === "Greater than") {
          return { validFrom: { $gt: timestamp } };
        }
        if (operator === "Lesser than") {
          return { validFrom: { $lt: timestamp } };
        }
        if (operator === "Greater than or Equal To") {
          return { validFrom: { $gte: timestamp } };
        }
        if (operator === "Less than or Equals To") {
          return { validFrom: { $lte: timestamp } };
        }
      };

    case "Valid To":
      return (operator, value) => {
        var timestamp = new Date(value.split(".").join("-")).getTime() / 1000;
        if (operator === "Equals To") {
          let gte = new Date(value.split(".").join("-")).getTime() / 1000;
          let lte = new Date(`${value}T23:59:59+05:30`).getTime() / 1000;
          return { validTill: { $gte: gte, $lte: lte } };
        }
        if (operator === "Greater than") {
          return { validTill: { $gt: timestamp } };
        }
        if (operator === "Lesser than") {
          return { validTill: { $lt: timestamp } };
        }
        if (operator === "Greater than or Equal To") {
          return { validTill: { $gte: timestamp } };
        }
        if (operator === "Less than or Equals To") {
          return { validTill: { $lte: timestamp } };
        }
      };
    case "Module":
      return (operator, value) => {
        if (operator === "Equals To") {
          return { module: value };
        }
      };
    case "Billable Service":
      return (operator, value) => {
        if (operator === "Equals To") {
          return { billableId: value };
        }
      };

    default:
      return {};
  }
};

class PricingMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data_options: [...dataOptions],
      openDropdown: false,
      setDropdown: false,
      page: 1,
      size: 10,
      filters: {},
      sort: { createdon: -1 },
      sorting: [{ columnName: "validFrom", direction: "desc" }],

      edit: false,
      allPublishedApp: [],
      searchkey: "",
      value: 0,
      allBS: [],
      allModules: [],
      openDropdown: false,
      setDropdown: false,
    };
  }

  mapDataToDataOptions = (data, type) => {
    let filter_Data = this.state.data_options.map((item, id) => {
      if (item.value === type) {
        return {
          ...item,
          fieldValues: data.map((e, id) => {
            return {
              key: id + 1,
              value: e.name,
            };
          }),
        };
      } else {
        return { ...item };
      }
    });
    this.setState({ data_options: [...filter_Data] });
  };

  getAllModlues = async () => {
    try {
      let res = await pricingMasterAPIS.fetchAllModules();
      this.setState({
        allModules: [...(res.data.data || [])],
      });
      await this.mapDataToDataOptions(res.data.data || [], "Module");
    } catch (err) {}
  };

  getAllBillableService = async () => {
    try {
      let res = await pricingMasterAPIS.fetchAllBillableServices();
      this.setState({
        allBS: [...(res.data.data || [])],
      });
      await this.mapDataToDataOptions(res.data.data || [], "Billable Service");
    } catch (err) {}
  };

  getAllPublishedApp = async () => {
    try {
      let res = await pricingMasterAPIS.getApplicationsByStatus("Published");
      this.setState({
        allPublishedApp: [...(res.data.data || [])],
      });
      await this.mapDataToDataOptions(res.data.data || [], "Application Name");
    } catch (err) {}
  };

  async componentDidMount() {
    await this.getAllBillableService();
    await this.getAllModlues();
    await this.getAllPublishedApp();
    let { filterProps } = this.props;
    let { page, size } = this.state;
    this.props.getData(
      page,
      size,
      JSON.stringify(filterProps.filters),
      JSON.stringify(filterProps.sort)
    );

    // let res = await pricingMasterAPIS.getApplicationsByStatus("Published");

    // if (res.data.data) {
    //   await this.setState({
    //     allPublishedApp: [...(res.data.data || [])],
    //   });
    // }
  }

  openDropDown = () => {
    this.setState({ openDropDown: true });
  };
  dropUp = () => {
    this.setState({ setDropdown: true });
  };

  handleSortByChange = (sorting) => {
    let { filterProps, updateSorting, getData } = this.props;
    let filters = { ...filterProps.filters };
    let sort = {};
    sorting.forEach((f) => {
      sort[f.columnName] = f.direction == "asc" ? 1 : -1;
    });
    updateSorting({ sorting, sort });
    getData(
      this.state.page,
      this.state.size,
      JSON.stringify(filters),
      JSON.stringify(sort)
    );
  };

  editPricingMaster = (row, e) => {
    e && e.stopPropagation();
    const { billable, ...otherData } = row;
    if (otherData.type === "slab") {
      let qty = otherData.quantityScale.map((item, id) => {
        return {
          ...item,
          end: item.end ? item.end.toString() : "",
          price: item.price ? item.price.toString() : "",
          add: true,
          edit: true,
          status: true,
        };
      });
      if (qty.length === 0) {
        otherData.quantityScale = [
          {
            start: 0,
            end: "",
            price: "",
            add: true,
            edit: false,
          },
        ];
      } else {
        otherData.quantityScale = [...qty];
      }
    } else {
      otherData.quantityScale = [
        {
          start: 0,
          end: "",
          price: "",
          add: true,
          edit: false,
        },
      ];
    }
    // console.log(otherData, otherData.quantityScale, "450fjhgfjhgfjgfjfgjfg");
    this.props.history.push("/app/editpricingmaster", {
      PricingMaster_Data: otherData,
      edit: true,
      allPublishedApp: this.state.allPublishedApp,
      disabled: e ? false : true,
    });
  };

  onChangeSearchAppName = async (value) => {
    this.setState({
      searchkey: value,
    });
    let { filters } = this.state;
    let { filterProps } = this.props;

    if (value.trim().length !== 0) {
      filters["application.name"] = {
        $regex: value,
        $options: "i",
      };
    } else {
      filters = {};
    }
    this.setState({ filters });

    this.props.getData(
      this.state.page,
      this.state.size,
      JSON.stringify(filters),
      JSON.stringify(filterProps.sort)
    );
  };

  removePricingMaster = async (row, e) => {
    e.stopPropagation();
    let { page, size, filters, sort } = this.state;
    try {
      let res = await pricingMasterAPIS.deletePricingMaster(row._id);
      this.props.enqueueSnackbar(res.data._msg || "something went wrong", {
        variant: "success",
      });
    } catch (err) {
      this.props.enqueueSnackbar(
        err.response.data._msg || "something went wrong",
        {
          variant: "error",
        }
      );
    }
    this.props.getData();
  };

  actionProvider = ({ row }) => {
    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        <Grid item>{/* null grid for column chooser icon */}</Grid>
        <Grid item>
          <Tooltip title="Edit" aria-label="edit">
            <IconButton
              color="inherit"
              size="small"
              disabled={!checkPermission("btn17")}
              onClick={(e) => {
                e.stopPropagation();
                checkPermission("btn17") && this.editPricingMaster(row, e);
              }}
            >
              <RiEditLine fontSize="20px" />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title="Delete" aria-label="delete">
            <IconButton
              size="small"
              color="inherit"
              disabled={!checkPermission("btn20")}
              onClick={(e) => {
                console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee");
                e.stopPropagation();
                checkPermission("btn20") &&
                  confirmDialog(dltMsg(row?.application.name || ""), () =>
                    this.removePricingMaster(row, e)
                  );
              }}
            >
              <RiDeleteBin5Line fontSize="20px" />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    );
  };

  productName = ({ row }) => {
    return (
      <>
        <Typography>{row?.application?.name || ""}</Typography>
      </>
    );
  };

  moduleName = ({ row }) => {
    return (
      <>
        <Typography>{row?.module?.name || ""}</Typography>
      </>
    );
  };

  billabelService = ({ row }) => {
    let name = "";
    this.state.allBS.map((item, id) => {
      name = item.id === row.billableId ? item.name : name;
    });
    return (
      <div>
        <Typography>{name}</Typography>
      </div>
    );
  };

  createPricing = () => {
    let data = {
      appId: "",
      quantity: "",
      billableId: "",
      type: "flat",
      module: "",
      quantityScale: [
        {
          start: 0,
          end: "",
          price: "",
          add: true,
          edit: false,
        },
      ],
      validFrom: "",
      validTill: "",
      counterType: "",
      discount: [],
    };

    this.props.history.push("/app/editpricingmaster", {
      PricingMaster_Data: data,
      edit: false,
      allPublishedApp: this.state.allPublishedApp,
    });
  };

  handlePageChange = async (e, newPage) => {
    console.log("newpage", typeof newPage, newPage);
    this.setState({
      page: newPage,
    });
    let { filterProps } = this.props;
    await this.props.getData(
      newPage,
      this.state.size,
      JSON.stringify(filterProps.filters),
      JSON.stringify(filterProps.sort)
    );
  };

  viewPricingdata = (row) => {
    this.editPricingMaster(row);
  };

  openDropDown = () => {
    this.setState({ openDropDown: true });
  };
  dropUp = () => {
    this.setState({ setDropdown: true });
  };

  filterFunc = async (arr) => {
    console.log(this.state.allBS, this.state.allModules, 456764345);
    let filters = {};
    if (arr.length === 1) {
      let { columns, operator, value } = arr[0];
      if (columns === "Module") {
        this.state.allModules.map((item, id) => {
          if (value === item.name) {
            value = item._id;
          }
        });
      }
      if (columns === "Billable Service") {
        this.state.allBS.map((item, id) => {
          if (value === item.name) {
            value = item.id;
          }
        });
      }

      if (columns === "Application Name") {
        this.state.allPublishedApp.map((item, id) => {
          if (value === item.name) {
            value = item._id;
          }
        });
      }
      let filter = findAndExecuteAjax(columns)(operator, value);
      this.getApplicationsByFilter({ ...filter }, arr);
    } else {
      arr.map((item, id) => {
        let { columns, operator, value } = item;
        if (columns === "Module") {
          this.state.allModules.map((item, id) => {
            if (value === item.name) {
              value = item._id;
            }
          });
        }
        if (columns === "Billable Service") {
          this.state.allBS.map((item, id) => {
            if (value === item.name) {
              value = item.id;
            }
          });
        }

        if (columns === "Application Name") {
          this.state.allPublishedApp.map((item, id) => {
            if (value === item.name) {
              value = item._id;
            }
          });
        }

        let filter = findAndExecuteAjax(columns)(operator, value);
        filters = { ...filters, ...filter };
      });
      this.getApplicationsByFilter({ ...filters }, arr);
    }
  };

  getApplicationsByFilter = async (filters, arr) => {
    let page = 1;
    this.setState({
      page: 1,
    });
    let { filterProps, updateFilter } = this.props;
    updateFilter({ filter: filters, filterArray: arr });
    let { size } = this.state;
    this.props.getData(
      page,
      size,
      JSON.stringify(filters),
      JSON.stringify(filterProps.sort)
    );
  };

  valid_from = ({ row }) => {};

  render() {
    console.log("Data", this.state.data_options, this.props.data);

    let { data, totalCount, loading, classes, filterProps, hidden } =
      this.props;
    // data = [
    //   {
    //     product: "Task Schedular app",
    //     price: "$3.25",
    //     service: "Cpu Usage",
    //     module: "Billing",
    //     validfrom: "6th sept 2021",

    //     validto: "9th sept 2021",
    //     scale: "0-1000",
    //   },
    //   {
    //     product: "HMD",
    //     price: "$3.25",
    //     service: "Api Hits",
    //     module: "Billing",
    //     validfrom: "6th sept 2021",
    //     validto: "9th sept 2021",
    //     scale: "0-1000",
    //   },
    // ];
    return (
      <Grid container>
        <Grid item sm={12} md={12} xs={12} lg={12}>
          <Breadcrumb
            {...this.props}
            links={[
              { name: "Pricing Master" },
              {
                name: "Pricing Master List",
              },
            ]}
          />
        </Grid>
        <Grid item sm={7} md={7} lg={7} xs={12}>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            Pricing Master {`( ${this.props.totalCount} )`}
          </Typography>
        </Grid>
        <Grid item sm={5} md={5} lg={5} xs={12}>
          <Grid container spacing={2}>
            <Grid item sm={5} md={5} lg={5} xs={12}>
              {/* <CustomTextField
                className=" mt-0 "
                autoComplete="off"
                fullWidth
                id="filled-search"
                type="text"
                variant="outlined"
                size="small"
                placeholder="Search"
                value={this.state.searchkey}
                margin="normal"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {" "}
                      <SearchIcon />{" "}
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  this.onChangeSearchAppName(e.target.value);
                }}
              /> */}
            </Grid>
            <Grid item sm={3} md={3} lg={3} xs={12}>
              <FilterComponent
                getDataByFilter={this.getApplicationsByFilter}
                data={[]}
                openDropDown={this.openDropDown}
                dropUp={this.dropUp}
                open={this.openDropdown}
                filterData={this.state.filterData}
                dataOptions={this.state.data_options}
                filterFunc={this.filterFunc}
                filterHistory={filterProps.filterArray}
              />
            </Grid>
            <Grid item sm={4} md={4} lg={4} xs={12}>
              <Button
                // component={Link}
                style={{
                  color: "white",
                  width: "100%",
                  boxShadow: "none",
                  padding: "6px 11px 6px 11px",
                }}
                variant="contained"
                color="primary"
                size="small"
                alignItems="center"
                onClick={checkPermission("btn15") && this.createPricing}
                id="btn15"
                disabled={!checkPermission("btn15")}
                startIcon={<AddIcon />}
              >
                Create Pricing Master
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12} md={12} xs={12} lg={12} className="mt-2">
          <DexTable
            data={[...data]}
            columnOrder={[
              "application",
              "module",
              "name",
              "type",
              "counterType",
              "validFrom",
              "validTill",
              "actions",
            ]}
            columns={[
              { name: "application", title: "Application" },
              { name: "module", title: "Module" },
              { name: "name", title: "Billable Service" },
              { name: "type", title: "Type" },
              { name: "counterType", title: "Counter Type" },
              { name: "validFrom", title: "Valid From" },
              { name: "validTill", title: "Valid Till" },
              { name: "actions", title: "Actions" },
            ]}
            columWidthsType="percentage"
            defaultColumnWidths={[
              // { columnName: "application", width: 15 },
              // { columnName: "module", width: 15 },
              // { columnName: "name", width: 14 },
              // { columnName: "type", width: 12 },
              // { columnName: "validFrom", width: 15 },
              // { columnName: "validTill", width: 12 },
              // { columnName: "actions", width: 15 },
              { columnName: "application", width: 20 },
              { columnName: "module", width: 20 },
              { columnName: "name", width: 20 },
              { columnName: "type", width: 20 },
              { columnName: "counterType", width: 20 },
              { columnName: "validFrom", width: 20 },
              { columnName: "validTill", width: 20 },
              { columnName: "actions", width: 15 },
            ]}
            formatters={[
              { formatterFn: this.actionProvider, columns: ["actions"] },
              {
                formatterFn: this.productName,
                columns: ["application"],
              },
              {
                formatterFn: this.moduleName,
                columns: ["module"],
              },
              {
                formatterFn: this.billabelService,
                columns: ["name"],
              },
            ]}
            dateColumns={["createdOn", "validFrom", "validTill"]}
            hidden={[...hidden]}
            sorting={filterProps.sorting}
            onSortingChange={this.handleSortByChange}
            disableReordering={false}
            height="60vh"
            loading={loading}
            tableType="virtual"
            onClickRow={(row) => this.viewPricingdata(row)}
            setHiddenColmn={(hidden) => {
              this.props.setHiddenColumn(hidden);
            }}
            Read={checkPermission("btn16")}
          />
        </Grid>
        {data.length !== 0 && (
          <div className={classes.footer}>
            <div className={classes.customPagination}>
              <Pagination
                count={Math.ceil(totalCount / 10)}
                page={this.state.page}
                onChange={this.handlePageChange}
                shape="rounded"
                showFirstButton
                showLastButton
              />
            </div>
          </div>
        )}
        <HelpdeskFAB category="Pricing Master" />
      </Grid>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    data: state.PricingMaster.data,
    loading: state.PricingMaster.loading,
    totalCount: state.PricingMaster.totalCount,
    filterProps: state.PricingMaster.filterProps,
    hidden: state.PricingMaster.hidden,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getData: (pageno, size, filters, sort) =>
      dispatch(actions.actionFetchPricingMaster(pageno, size, filters, sort)),
    deletePricingMaster: (_id) => dispatch(actions.deletePricingMaster(_id)),
    updateFilter: (filter) => dispatch(actions.updateFilterData(filter)),
    updateSorting: (sortProps) => dispatch(actions.updateSort(sortProps)),
    setHiddenColumn: (hidden) =>
      dispatch(actions.updatePricingMasterHiddenCol(hidden)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(Sytles)(withSnackbar(PricingMaster)));
