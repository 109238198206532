import * as types from "./actionTypes";
import APIS from "api/DataStandardsAPIs/index";
import VALUEMAPAPI from "api/ValueMapAPI/index";

export const getDatastandardsById = (id) => async () => {
  try {
    const res = await APIS.getDataStandardsById(id);
    return (
      res.data.data || {
        type: "",
      }
    );
  } catch (err) {
    return { type: "" };
  }
};

export const getAllUsers = () => async (dispatch) => {
  // try {
  //   let res = await APIS.fetchAllDataStandardsWithParams();
  //   console.log(res.data, 6586587687);
  //   dispatch({
  //     type: types.FETCHING_DATASTANDARDS,
  //     payload: res.data || [],
  //   });
  // } catch (err) {
  //   dispatch({
  //     type: types.FETCHING_DATASTANDARDS_FAILED,
  //   });
  // }
};

export const AddDatastandards = (payload) => async () => {
  try {
    const res = await APIS.AddDatastandards(payload);
    return res;
  } catch (err) {
    return err;
  }
};

export const editDataStandards = (dataStandardId, payload) => async () => {
  try {
    const res = await APIS.editDataStandards(dataStandardId, payload);
    return res;
  } catch (err) {
    return err;
  }
};

export const fetchAllValueMapData = (name) => async (dispatch) => {
  try {
    const res = await APIS.fetchValueMap(name);

    dispatch({
      type: types.FETCH_VALUE_MAP_DATA_SUCCESS,
      loading: false,
      payload: res.data.data || [],
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: types.FETCH_VALUE_MAP_DATA_FAILED,
      loading: false,
      error: err,
    });
  }
};

export const deleteValueMap = (fieldname, _id) => async (dispatch) => {
  console.log(_id, "==============4444");
  dispatch({
    type: types.DELETE_VALUEMAP_DATA,
    loading: true,
  });
  try {
    let res = await VALUEMAPAPI.deleteValueMapData(fieldname, _id);
    const { _msg, data } = res.data;
    return {
      _status: true,
      _msg,
      data,
    };
  } catch (err) {
    const { _msg } = err?.response?.data || {
      _msg: "Connection error!",
    };

    return {
      _msg,
    };
  }
};

export const loadingDispatch = (bool) => async (dispatch) => {
  dispatch({
    type: types.LOAD_LOADING,
    payload: bool,
  });
};

export const updateValueMapHiddenCol = (hidden) => async (dispatch) => {
  dispatch({
    type: types.UPDATE_VALUE_MAP_HIDDEN_COL,
    payload: hidden,
  });
};
