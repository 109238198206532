import React from "react";
import { IconButton, makeStyles, Typography } from "@material-ui/core";
import { useState } from "react";
import HelpdeskModal from "./HelpdeskModal";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import html2canvas from "html2canvas";
import HelpdeskSvg from "./helpdesk.svg";
// import useCheckPermission from "./hooks/useCheckPermission";

const useStyles = makeStyles((theme) => ({
  helpdesk: {
    backgroundColor: theme.palette.primary.main,
    position: "fixed",
    bottom: 50,
    right: 0,
    padding: "7px 10px 7px",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "10px 0px 0px 10px",
    cursor: "pointer",
    zIndex: 999999,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
    "&.Mui-disabled": {
      backgroundColor: `${theme.palette.primary.main}!important`,
      // color: '#fff !important',
      opacity: "0.3 !important",
    },
  },
  root: {
    width: 130,
    right: -89,
    transition: theme.transitions.create(["right"], {
      duration: 300,
    }),
    "&:hover": {
      textDecoration: "none",
      right: 0,
    },
  },
}));

const Helpdesk = (props) => {
  const { children, category } = props;
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.apearenceReducer);
  const { userInfo, success, loading: loadDetails } = userDetails;

  //   const { checkCompPermission } = useCheckPermission();

  //   const userDetails = useSelector((state) => state.userDetails);
  //   const { userInfo } = userDetails;
  const issueAll = useSelector((state) => state.issueAll);

  const helpDeskHides = [
    "/",
    // "/user-profile",
    // "/register",
    // "/reset-password",
    // "/verify-otp",
    "/app/create-helpdesk",
    "/app/helpdesk",
  ];
  const history = useHistory();
  const [helpdeskModal, setHelpdeskModal] = useState(false);

  function onStartCapture() {
    html2canvas(document.getElementById("root")).then(function (canvas) {
      const base64image = canvas.toDataURL("image/png");
      let data = {
        appId: "",
        module: "",
        category,
      };
      history.push("/app/create-helpdesk", {
        Helpdesk_Data: data,
        edit: false,
        screenCapture: true,
        helpDeskPage: false,
        state: { img: base64image },
      });
      console.log("base64image", base64image);
    });
  }

  console.log("34567sdfghj", userInfo);

  return (
    <>
      {children}
      {!helpDeskHides.includes(location?.pathname) && (
        <IconButton
          size="small"
          // disabled={!checkCompPermission("Helpdesk", ["Create"])}
          id="test"
          className={classes.helpdesk}
          classes={{ root: classes.root }}
          // onClick={() => setHelpdeskModal(true)}
          onClick={
            () => {
              if (userInfo.screenCapture) {
                setHelpdeskModal(true);
              } else {
                onStartCapture();
              }
            }
            // userInfo.screenCapture && userInfo.screenCapture !== "Prompt"
            // ? onStartCapture()
            // : setHelpdeskModal(true)
          }
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: "auto",
              maxHeight: "1.3rem",
            }}
          >
            <img
              src={HelpdeskSvg}
              alt="helpdesk"
              style={{ marginRight: "auto" }}
            />

            <Typography
              variant="body2"
              style={{
                color: "#fff",
                minWidth: "100%",
              }}
            >
              Raise Ticket
            </Typography>
          </div>
        </IconButton>
      )}
      <HelpdeskModal
        helpdeskModal={helpdeskModal}
        setHelpdeskModal={setHelpdeskModal}
        onStartCapture={onStartCapture}
      />
    </>
  );
};

export default Helpdesk;
