import { React, useEffect, useState } from "react";
import WrappedDexTable from "components/DexTable/WrappedDexTable";
import {
  Button,
  TextField,
  Typography,
  FormLabel,
  Select,
  Tooltip,
  IconButton,
  Grid,
  Chip,
} from "@material-ui/core";
import { checkPermission } from "helpers/permissons";
import AddIcon from "@material-ui/icons/Add";
import withSection from "components/Section/Section";
// import VisibilityIcon from "@material-ui/icons/Visibility";
import Helpers from "helpers/helpers";
import { RiEditLine, RiDeleteBin5Line } from "react-icons/ri";
import { confirmDialog } from "components/DeleteConfirmationDialog/Index";
import { dltMsg } from "helpers/helpers";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import { ApplicationDetailsUpadateHiddenCol } from "../ApplicationDetails/Redux/action";
import useAxiosIIFE from "customHooks/ApiHooks/Index";
const Documentation_DEX = (props) => {
  const {
    data,
    usageDocumentData,
    documents,
    enqueueSnackbar,
    appData,
    appTable,
  } = props;
  const { usageDocHidden } = appTable;
  const appId = appData._id;
  const appName = appData.name;
  // let [rows, setRows] = useState(data);
  console.log(data, "fett");
  // introDocuments

  useEffect(() => {
    const { success, message } = documents;
    message !== null &&
      enqueueSnackbar(message, {
        variant: success ? "success" : "error",
      });
    message !== null && props.getDocuments(appId, "");
  }, [documents]);

  // const pushToDocumentation = () => {
  //   checkPermission("btn122") &&
  //     props.history.push("/app/document/create_document/" + appId, {
  //       appData: appData,
  //     });
  // };

  const pushToUsageDocuments = () => {
    props.history.push("/app/create-doc", {
      data: {
        ID: appId,
        name: appName,
        isEdit: false,
        isDisabled: false,
        modules: props.modulesData,
      },
    });
  };

  // const actionProvider = (row) => {
  //   console.log(row, "3436436");
  //   return (
  //     <div
  //       style={{
  //         display: "flex",
  //       }}
  //     >
  //       <div style={{ marginRight: 20 }}>
  //         <Tooltip title="Edit" aria-label="edit">
  //           <IconButton size="small" color="primary">
  //             <VisibilityIcon fontSize="20px" />
  //           </IconButton>
  //         </Tooltip>
  //       </div>
  //     </div>
  //   );
  // };

  const editUsageDocument = (row, e, type) => {
    const { filePath, documents, ...other } = row;
    e && e.stopPropagation();
    checkPermission("btn120") &&
      props.history.push("/app/create-doc", {
        data: {
          ID: appId,
          name: appName,
          isDisabled: type,
          isEdit: true,
          modules: props.modulesData,
          usageDoc: { ...other },
        },
      });
  };

  const delete_usage_doc = async (row) => {
    const { _id, appId } = row;
    await props.deleteUsageDoc(appId, _id, "delete");
  };
  const actionProvider = ({ row }) => {
    return (
      <div
        style={{
          display: "flex",
        }}
      >
        <div style={{ marginRight: 20 }}>
          <Tooltip title="Edit" aria-label="edit">
            <IconButton
              color="inherit"
              size="small"
              disabled={!checkPermission("btn120")}
              onClick={(e) =>
                checkPermission("btn120") && editUsageDocument(row, e, false)
              }
            >
              <RiEditLine fontSize="20px" />
            </IconButton>
          </Tooltip>
        </div>

        <div>
          <Tooltip title="Delete" aria-label="delete">
            <IconButton
              size="small"
              color="inherit"
              disabled={!checkPermission("btn121")}
              onClick={(e) => {
                e.stopPropagation();
                checkPermission("btn121") &&
                  confirmDialog(dltMsg(row?.name || ""), () =>
                    delete_usage_doc(row, e)
                  );
              }}
            >
              <RiDeleteBin5Line fontSize="20px" />
            </IconButton>
          </Tooltip>
        </div>
        {/* )} */}
      </div>
    );
  };

  const displayModuleName = ({ row }) => {
    return (
      <div>
        <Typography>{row.module.name}</Typography>
      </div>
    );
  };

  const handleClick = (row) => {
    editUsageDocument(row, undefined, true);
  };

  console.log(usageDocumentData, "usage documentation");
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item md={12}>
          {/* {appData._id !== undefined && (
            <IntroDocSection
              pushToDocumentation={pushToDocumentation}
              appId={appData._id}
            />
          )} */}
        </Grid>
        <Grid xs={12} item container justifyContent="flex-end" direction="row">
          <Button
            size="small"
            variant="contained"
            onClick={() => checkPermission("btn119") && pushToUsageDocuments()}
            startIcon={<AddIcon />}
            disabled={!checkPermission("btn119")}
            style={{ padding: "4px 25px 4px 25px" }}
          >
            Create
          </Button>
        </Grid>
        <Grid item xs={12}>
          <WrappedDexTable
            data={usageDocumentData || []}
            // hideColumnChooser={true}
            columWidthsType="percentage"
            columnOrder={["name", "moduleName", "description", "actions"]}
            columns={[
              { name: "name", title: "Name" },
              { name: "moduleName", title: "Module Name" },
              // { name: "description", title: "Description" },
              { name: "actions", title: "Actions" },
            ]}
            defaultColumnWidths={[
              { columnName: "name", width: 45 },
              { columnName: "moduleName", width: 35 },
              // { columnName: "description", width: 30 },
              { columnName: "actions", width: 10 },
            ]}
            disableReordering={true}
            pagination={false}
            onClickRow={handleClick}
            noDataMsg="Usage documentation not found"
            formatters={[
              { formatterFn: actionProvider, columns: ["actions"] },
              { formatterFn: displayModuleName, columns: ["moduleName"] },
            ]}
            Read={checkPermission("btn229")}
            hidden={[...usageDocHidden]}
            setHiddenColmn={(hidden) => {
              props.setHiddenColumn(hidden, "usageDocHidden");
            }}
            // tableRow={(props) => {
            //   return (
            //     <CustomTableRow
            //       {...props}
            //       removeRows={removeRows}
            //       addWebhook={addWebhook}
            //       editWebhook={editWebhook}
            //       deleteWebhook={deleteWebhook}
            //       addNewRow={
            //         props.tableRow.rowId == rows.length - 1 && (
            //           <Tooltip title="Add" aria-label="add">
            //             <IconButton>
            //               <AddCircleOutlineOutlinedIcon
            //                 color="secondary"
            //                 onClick={addRows}
            //               />
            //             </IconButton>
            //           </Tooltip>
            //         )
            //       }
            //     />
            //   );
            // }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setHiddenColumn: (hidden, type) =>
    dispatch(ApplicationDetailsUpadateHiddenCol(hidden, type)),
});

const mapStateToProps = (state) => {
  return {
    appTable: state.appDetailsRedcer.appTable,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withSection(Documentation_DEX, "Usage Documentation")));
