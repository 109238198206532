import * as actions from "./actionTypes";
const intialState = {
  hidden: ["amount", "CreatedAt"],
  hide: ["unit", "cost", "module"],
  data: [],
  loading: false,
  load: false,
  totalCount: 0,
  adddata: {},
  nonGrpData: [],
  InvoiceData: { Notes: [], CustomerDetails: {} },
  getdata: {},
  appUsageDetails: {},
  appDataSet: {
    datasets: [
      {
        label: "Dataset",
        data: [],
        backgroundColor: [],
        hoverBackgroundColor: [],
        hoverOffset: 3,
      },
    ],
  },
  allAppDetailedChart: [],
  appUsageData: {
    allApps: [],
    moduleByApp: [],
  },
  appLoading: false,
};
const reducer = (state = intialState, action) => {
  switch (action.type) {
    case actions.FETCHING_CREDIT_DEBIT_INVOICE:
      return { ...state, loading: action.loading };
    case actions.FETCHING_CREDIT_DEBIT_INVOICE_SUCCESS:
      return {
        ...state,
        loading: action.loading,
        data: action.data,
        totalCount: action.totalCount,
      };
    case actions.FETCHING_CREDIT_DEBIT_INVOICE_FAILURE:
      return { ...state, loading: action.loading, error: action.error };

    case actions.ADD_CREDITNOTES:
      return { ...state, loading: action.loading };
    case actions.ADD_CREDITNOTES_SUCCESS:
      return { ...state, loading: action.loading, adddata: action.payload };
    case actions.ADD_CREDITNOTES_FAILURE:
      return { ...state, loading: action.loading, error: action.error };

    case actions.GET_CREDITNOTES:
      return { ...state, loading: action.loading };
    case actions.GET_CREDITNOTES_SUCCESS:
      return { ...state, loading: action.loading, getdata: action.payload };
    case actions.GET_CREDITNOTES_FAILURE:
      return { ...state, loading: action.loading, error: action.error };

    case actions.FETCHING_INVOICE_DETAILS_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case actions.FETCH_INVOICE_DETAILS_BY_ID_SUCCESS:
      return {
        ...state,
        InvoiceData: action.payload,
        loading: false,
        nonGrpData: action.nonGrpData,
        appUsageDetails: action.appUsageDetails,
        appDataSet: action.chartProps,
        allAppDetailedChart: action.allAppDetailedChart,
      };

    case actions.LOAD_LOADING:
      return { ...state, load: action.payload };

    case actions.FETCH_INVOICE_DETAILS_BY_ID_FAILED:
      return {
        ...state,
        loading: false,
        InvoiceData: { Notes: [] },
        appUsageDetails: {},
        appDataSet: {
          datasets: [
            {
              label: "Dataset",
              data: [],
              backgroundColor: [],
              hoverBackgroundColor: [],
              hoverOffset: 3,
            },
          ],
        },
        allAppDetailedChart: [],
      };

    case "FETCHING_APP_USAGE": {
      return {
        ...state,
        appLoading: true,
      };
    }
    case "FETCH_APP_USAGE_DATA":
      return {
        ...state,
        appLoading: false,
        appUsageData: {
          allApps: action.payload.filterData,
          moduleByApp: action.payload.moduleData,
        },
      };
    case "FETCH_FAIL_APP_USAGE":
      return {
        ...state,
        appLoading: false,
        appUsageData: {
          allApps: [],
          moduleByApp: [],
        },
      };
    case "UPDATE_CREDIT_DEBIT_HIDDEN_COL":
      return {
        ...state,
        hidden: action.payload,
      };

    case "UPDATE_GRAPH_HIDDEN_COL":
      return {
        ...state,
        hide: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
export default reducer;
