export const LOGING = "LOGING";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const ADD_USER_LOG_INFO = "ADD_USER_LOG_INFO";

export const GENERATING_QR_CODE = "GENERATING_QR_CODE";
export const GENERATED_QR_CODE = "GENERATED_QR_CODE";
export const GENERATE_QR_CODE_FAILED = "GENERATE_QR_CODE_FAILED";

export const VERIFYING_2FA_CODE = "VERIFYING_2FA_CODE";
export const VERIFY_2FA_CODE_SUCCESS = "VERIFY_2FA_CODE_SUCCESS";
export const VERIFY_2FA_FAIL = "VERIFY_2FA_FAIL";

export const RESET_LOGIN_PROPS = "RESET_LOGIN_PROPS";

export const SIGNUP = "SIGNUP";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAIL = "SIGNUP_FAIL";
export const CLEAR_MSG = "CLEAR_MSG";

export const PRIVATE_ROUTE_TRUE = "PRIVATE_ROUTE_TRUE";
export const PRIVATE_ROUTE_FALSE = "PRIVATE_ROUTE_FALSE";
