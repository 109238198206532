import { React, useState, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import AddIcon from "@material-ui/icons/Add";
import DeviceHubIcon from "@material-ui/icons/DeviceHub";
import {
  Tooltip,
  Button,
  TextField,
  IconButton,
  Typography,
} from "@material-ui/core";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import withSection from "components/Section/Section";
import DexTable from "components/DexTable/DexTable";
import Grid from "@material-ui/core/Grid";
import { RiEditLine, RiDeleteBin5Line } from "react-icons/ri";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import WrappedDexTable from "components/DexTable/WrappedDexTable";
import { checkPermission } from "helpers/permissons";
import { confirmDialog } from "components/DeleteConfirmationDialog/Index";
import { dltMsg } from "helpers/helpers";
import { ApplicationDetailsUpadateHiddenCol } from "../ApplicationDetails/Redux/action";
const useStyles = makeStyles((theme) => ({
  button: {
    padding: "4px 30px",
  },
  dividerline: {
    width: "70%",
    margin: "-25px 0px 0px 125px",
    [theme.breakpoints.up("md")]: {
      width: "82%",
      margin: "-25px 0px 0px 260px",
    },
  },
}));
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    width: 15,
    height: 1,
  },
  btn: {
    width: "300px",
  },
}))(TableCell);

function OutgoingAPIDex(props) {
  const { appId, data, routeToCDUMap, appTable, appName } = props;
  const { outgoingApiHidden } = appTable;
  const classes = useStyles();
  let [rows, setRows] = useState([]);

  useEffect(() => {
    setRows(data);
  }, [data]);

  const pushToCreateForm = () => {
    const data = {
      name: "",
      url: "",
      outgoingAppId: "",
      outgoingApiId: "",
    };
    checkPermission("btn116") &&
      props.history.push("/app/outgoing-api-form", {
        apiData: data,
        edit: false,
        appID: props.appId,
        appName: appName,
      });
  };

  const viewOutgoingdata = (row) => {
    console.log("clicked");
    props.history.push("/app/outgoing-api-form", {
      apiData: { ...row },
      edit: true,
      allPublishedAppData: props.allPublishedApp,
      appID: appId,
      appName: appName,
      disabled: true,
    });
  };

  const applicationNameProvider = ({ row }) => {
    return (
      <>
        {props.allPublishedApp.map((item, id) => (
          <Typography variant="body">
            {item._id === row.outgoingAppId ? item.name : ""}
          </Typography>
        ))}
      </>
    );
  };

  const viewOutgoingApi = (row) => {
    editApplicationOutgoing(row);
  };

  const editApplicationOutgoing = (row, e) => {
    console.log("outgoing api ", row);
    e && e.stopPropagation();
    checkPermission("btn117") &&
      props.history.push("/app/outgoing-api-form", {
        apiData: { ...row },
        edit: true,
        appID: appId,
        appName: appName,
        disabled: e ? false : true,
      });
  };

  const actionProvider = ({ row }) => {
    const { appId } = props;

    return (
      <>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          {/* <Grid item md={3}>
            <Tooltip title="CDU Mapping" aria-label="add">
              <IconButton
                color="inherit"
                size="small"
                onClick={(e) => {
                  console.log({ appId, row });
                  // e.stopPropagation();
                  props.routeToCDUMap(appId, row);
                }}
              >
                <DeviceHubIcon style={{ fontSize: "20px" }} />
              </IconButton>
            </Tooltip>
          </Grid> */}
          <Grid item md>
            <Tooltip title="Edit" aria-label="edit">
              <IconButton
                color="inherit"
                size="small"
                onClick={(e) => {
                  checkPermission("btn117") && editApplicationOutgoing(row, e);
                }}
                disabled={!checkPermission("btn117")}
              >
                {" "}
                <RiEditLine style={{ fontSize: "20px" }} />{" "}
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item md>
            {/* {props.length > 1 && ( */}
            {/* <Tooltip title="Delete" aria-label="delete">
                 <IconButton
                  color="primary"
                  onClick={async (e) => {
                    e.stopPropagation();
                    await props.deleteOutgoingApi(appId, row._id);
                  }}
                >
                  <RiDeleteBin5Line fontSize="22px" /> */}
            <Tooltip title="Delete" aria-label="delete">
              <IconButton
                color="inherit"
                size="small"
                disabled={!checkPermission("btn118")}
                onClick={async (e) => {
                  e.stopPropagation();
                  if (checkPermission("btn118")) {
                    confirmDialog(dltMsg(row?.outgoingApiName || ""), () =>
                      props.deleteOutgoingApi(appId, row._id)
                    );
                  }
                }}
              >
                <RiDeleteBin5Line
                  color="inherit"
                  style={{ fontSize: "20px" }}
                />
              </IconButton>
            </Tooltip>
            {/* )} */}
          </Grid>
        </Grid>
      </>
    );
  };

  console.log("dbghjadsfhfahfsahfga", rows);
  return (
    <div id="appliation-outgoingapis-section">
      <Grid container spacing={2}>
        <Grid
          item
          md={12}
          container
          justifyContent="flex-end"
          alignItems="center"
        >
          <div>
            <Button
              startIcon={<AddIcon />}
              onClick={pushToCreateForm}
              variant="contained"
              size="small"
              className={classes.button}
              disabled={!checkPermission("btn116")}
            >
              Create Outgoing API
            </Button>
          </div>
        </Grid>
        <Grid item md={12}>
          <WrappedDexTable
            data={rows}
            // hideColumnChooser={true}
            columWidthsType="percentage"
            columnOrder={[
              "outgoingAppId",
              "name",
              "outgoingApiName",
              "url",
              "actions",
            ]}
            columns={[
              { name: "outgoingAppId", title: "Applications" },
              { name: "name", title: "Name" },
              { name: "outgoingApiName", title: "APIs" },
              { name: "url", title: " Proxy URL" },
              { name: "actions", title: "Actions" },
            ]}
            defaultColumnWidths={[
              { columnName: "name", width: 10 },
              { columnName: "url", width: 45 },
              { columnName: "outgoingAppId", width: 15 },
              { columnName: "outgoingApiName", width: 15 },
              { columnName: "actions", width: 10 },
            ]}
            CreateModules
            disableReordering={true}
            pagination={false}
            formatters={[
              { formatterFn: actionProvider, columns: ["actions"] },
              {
                formatterFn: applicationNameProvider,
                columns: ["outgoingAppId"],
              },
            ]}
            hidden={[...outgoingApiHidden]}
            setHiddenColmn={(hidden) => {
              props.setHiddenColumn(hidden, "outgoingApiHidden");
            }}
            onClickRow={viewOutgoingApi}
            noDataMsg="Outgoing API not found"
            Read={checkPermission("btn228")}
            // tableRow={(p) => {
            //   return (
            //     <CustomTableRow
            //       {...p}
            //       history={props.history}
            //       {...props}
            //       applicationApi={props.applicationApi}
            //       getAppApi={props.getAppApi}
            //       allPublishedApp={props.allPublishedApp}
            //       appId={appId}
            //       deleteOutgoingApi={props.deleteOutgoingApi}
            //       routeToCDUMap={routeToCDUMap}
            //       tableType="virtual"
            //       length={rows.length}
            //     />
            //   );
            // }}
          />
        </Grid>
      </Grid>
    </div>
  );
}
const mapDispatchToProps = (dispatch) => ({
  setHiddenColumn: (hidden, type) =>
    dispatch(ApplicationDetailsUpadateHiddenCol(hidden, type)),
});

const mapStateToProps = (state) => {
  return {
    appTable: state.appDetailsRedcer.appTable,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withSection(OutgoingAPIDex, "Outgoing API")));

const CustomTableRow = (props) => {
  const { appId, row } = props;
  const editApplicationOutgoing = (row, e) => {
    e && e.stopPropagation();
    checkPermission("btn117") &&
      props.history.push("/app/outgoing-api-form", {
        apiData: { ...row },
        edit: true,
        allPublishedAppData: props.allPublishedApp,
        appID: appId,
        disabled: e ? false : true,
      });
  };

  return (
    <TableRow onClick={() => editApplicationOutgoing(row)}>
      <StyledTableCell>
        <Typography variant="body"> {row.name} </Typography>
      </StyledTableCell>
      <StyledTableCell>
        {props.allPublishedApp.map((item, id) => (
          <Typography variant="body">
            {item._id === row.outgoingAppId ? item.name : ""}
          </Typography>
        ))}
      </StyledTableCell>
      <StyledTableCell align="left">
        <Typography variant="body">{row.outgoingApiName}</Typography>
      </StyledTableCell>
      <StyledTableCell>
        <Typography variant="body"> {row.url} </Typography>
      </StyledTableCell>
      <StyledTableCell>
        {/* <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
          }}
        >
          <div style={{ width: "4%" }}>
            <Tooltip title="CDU Mapping" aria-label="add">
              <IconButton
                color="primary"
                onClick={() => {
                  console.log({ appId, row });
                  props.routeToCDUMap(appId, row);
                }}
              >
               <DeviceHubIcon /> 
              </IconButton>
            </Tooltip>
          </div>
          <div style={{ width: "4%" }}>
            <Tooltip title="Edit" aria-label="edit">
              <IconButton
                color="primary"
                onClick={(e) => {
                  editApplicationOutgoing(row, e);
                }}
              >
                {" "}
                <RiEditLine style={{ fontSize: "22px" }} />{" "}
              </IconButton>
            </Tooltip>
          </div>
          <div style={{ width: "4%" }}>
            {props.length > 1 && (
              <Tooltip title="Delete" aria-label="delete">
                <IconButton
                  color="primary"
                  onClick={async () => {
                    await props.deleteOutgoingApi(appId, row._id);
                  }}
                >
                  {" "}
                  <RiDeleteBin5Line fontSize="22px" />{" "}
                </IconButton>
              </Tooltip>
            )}
          </div>
        </div> */}
      </StyledTableCell>
      <StyledTableCell></StyledTableCell>
    </TableRow>
  );
};
