import React, { useEffect, useState } from "react";
import { Typography, Grid, InputAdornment } from "@material-ui/core";
import ChartsPage from "./chart";
import { CustomTextField } from "components/Customized";
import SearchIcon from "@material-ui/icons/Search";
import DataCard from "../component/DataCard";
import DrillDownPieGraph from "../PieDrillDownApp/index";
const AppGraphs = (props) => {
  const { allAppDetailedChart } = props;
  const [dataSet, setDataSet] = useState([]);
  const [searchKey, setSearchKey] = useState("");

  const filterDataSet = (value) => {
    setSearchKey(value);
    let data = [...allAppDetailedChart];
    if (value.trim() !== "") {
      data = data.filter((itm) => {
        return itm.name.toLowerCase().includes(value.toLowerCase());
      });
    }
    setDataSet([...data]);
    console.log(data, value, "filter 234");
  };
  useEffect(() => {
    setDataSet([...allAppDetailedChart]);
  }, [allAppDetailedChart]);

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item md={6}>
          <Grid container spacing={1}>
            <Grid item md={12}>
              {/* <Typography variant="h5" style={{ fontWeight: "bold" }}>
                Total Amount
              </Typography> */}
            </Grid>
            <Grid item md={12} container justifyContent="center">
              {/* <div style={{ width: "350px" }}> */}
              {/* <ChartsPage {...props} /> */}
              <DrillDownPieGraph {...props} />
              {/* </div> */}
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6}>
          <Grid container spacing={1}>
            <Grid item md={12}>
              <Grid
                container
                spacing={1}
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Typography variant="h5">Applications List</Typography>
                </Grid>
                <Grid item>
                  <CustomTextField
                    className=" mt-0 "
                    fullWidth
                    id="filled-search"
                    type="text"
                    variant="outlined"
                    size="small"
                    placeholder="Search by name"
                    value={searchKey}
                    onChange={(e) => filterDataSet(e.target.value)}
                    margin="normal"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {" "}
                          <SearchIcon />{" "}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12}>
              <Grid
                container
                spacing={1}
                style={{
                  height: dataSet.length > 4 ? "60vh" : "auto",
                  overflow: "auto",
                }}
              >
                {dataSet.map((item, index) => {
                  return (
                    <Grid key={index} item md={12}>
                      <DataCard key={index} app={item} {...props} />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
export default AppGraphs;
