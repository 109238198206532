import React, { Component } from "react";
import PropTypes from "prop-types";
import CustomerDetail from "./CustomerDetails";
import { withStyles } from "@material-ui/styles";
import Styles from "./styles";
import Breadcrumb from "components/Breadcrumbs/Breadcrumb";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import {
  fetchCustomerApp,
  fetchCustomerInvoice,
  updateCustomerProfile,
  fetchCustomerSettingData,
  disableEdit,
  fetchApplicationUsageDetails,
  fetchCustomerIsssues,
  updateEndEdit,
  update_subscribedApp_status,
} from "./redux/action";
import { ValidatorForm } from "react-material-ui-form-validator";
import { withSnackbar } from "notistack";
import Loader from "../../components/common/stuff/Loader";

class CustomerProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      customerID: 0,
      customerSetting: {
        CustomerId: "",
        billingDayOfMonth: "1",
        billingDuePeriod: "",
        tax: 7,
      },
    };
  }

  onChangeValue = (customerSetting) => {
    this.setState({
      customerSetting: { ...customerSetting },
    });
  };

  getSettingData = async (id) => {
    let { CreatedAt, DeletedAt, UpdatedAt, ID, ...data } =
      await this.props.getCustomerSetting(id);
    this.setState({
      customerSetting: { ...data },
    });
  };

  async componentDidMount() {
    const {
      location,
      getCustomerSubApp,
      getCustomerInvoice,
      getCustomerIssues,
    } = this.props;
    if (location.state !== undefined && location.state.customerData) {
      const { customerData, isEdit } = location.state;
      if (isEdit) {
        this.props.disablePropsEdit(false);
      }
      this.setState({
        data: { ...customerData },
        customerID: customerData._id,
      });
      await getCustomerSubApp(customerData._id);
      await getCustomerInvoice(customerData._id);
      await getCustomerIssues(customerData._id);
      await this.getSettingData(customerData._id);
    } else {
      this.props.history.push("/app/customer-management");
    }
  }

  getUsageDiscriptionOFApp = async (appId) => {
    const { customerID } = this.state;
    let flag = await this.props.getAppUsage(customerID, appId);
    return flag;
  };

  onSubmitFunc = async () => {
    const { customerSetting, customerID } = this.state;
    let payload = {
      ...customerSetting,
      CustomerId: customerID,
      billingDayOfMonth: parseInt(customerSetting.billingDayOfMonth),
      billingDuePeriod: parseInt(customerSetting.billingDuePeriod),
      tax: parseFloat(customerSetting.tax),
    };
    const { _status, _msg } = await this.props.update_customerProfile(
      payload,
      customerID
    );
    this.props.enqueueSnackbar(_msg || "connection error!", {
      variant: _status === 200 ? "success" : "error",
      autoHideDuration: 1000,
    });
    if (_status === 200) {
      this.props.history.push("/app/customer-management");
    }
  };

  display_msg = (msg, success) => {
    this.props.enqueueSnackbar(msg, {
      variant: success ? "success" : "error",
      autoHideDuration: 1000,
    });
    if (success) {
      this.props.getCustomerSubApp(this.state.customerID);
    }
  };

  submitEndDate = async (val) => {
    const { _msg, success } = await this.props.updateEndEdit(val);
    console.log("update status", _msg, success);
    this.display_msg(_msg, success);
  };

  submitStatusApproved = async (val) => {
    const { _msg, success } = await this.props.updateSubAppLaunchStatus(val);
    console.log("sadftfdjasfdhas", _msg, success);
    this.display_msg(_msg, success);
  };

  render() {
    const { data, customerSetting } = this.state;
    const { loading, billableServices } = this.props;
    return (
      <div>
        {loading && <Loader />}
        <div>
          <Breadcrumb
            {...this.props}
            links={[
              { name: "Customer List", url: "/app/customer-management" },
              {
                name: "Customer Details",
                url: "/app/customer-details",
              },
            ]}
          />
        </div>
        <ValidatorForm onSubmit={this.onSubmitFunc}>
          <CustomerDetail
            customerSetting={customerSetting}
            onChangeValue={this.onChangeValue}
            details={data}
            getUsageDiscriptionOFApp={this.getUsageDiscriptionOFApp}
            submitEndDate={this.submitEndDate}
            submitStatusApproved={this.submitStatusApproved}
            {...this.props}
          />
        </ValidatorForm>
      </div>
    );
  }
}

CustomerProfile.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  getCustomerSubApp: (id) => dispatch(fetchCustomerApp(id)),
  getCustomerInvoice: (id) => dispatch(fetchCustomerInvoice(id)),
  update_customerProfile: (payload, id) =>
    dispatch(updateCustomerProfile(payload, id)),
  getCustomerSetting: (id) => dispatch(fetchCustomerSettingData(id)),
  disablePropsEdit: (type) => dispatch(disableEdit(type)),
  getAppUsage: (custId, appId) =>
    dispatch(fetchApplicationUsageDetails(custId, appId)),
  getCustomerIssues: (id) => dispatch(fetchCustomerIsssues(id)),
  updateEndEdit: (val) => dispatch(updateEndEdit(val)),
  updateSubAppLaunchStatus: (val) => dispatch(update_subscribedApp_status(val)),
});
const mapStateToProps = (state) => {
  return {
    ListOfApp: state.customerProfile.listOfApp,
    invoiceData: state.customerProfile.invoiceData,
    loading: state.customerProfile.loading,
    propsDisabled: state.customerProfile.propsDisabled,
    billableServices: state.customerProfile.billableServices,
    customerIssues: state.customerProfile.customerIssues,
    info: state.customerProfile.info,
  };
};
const CustomerProfileForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerProfile);

export default withRouter(
  withStyles(Styles)(withSnackbar(CustomerProfileForm))
);
