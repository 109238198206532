import getURL from "../../../api/config";
import API_helpers from "../../../api/index";
import axios from "axios";
import * as types from "./actionType";

export const fetchCustomerList = (page, size, filters, sort) => {
  let url = getURL("FETCH_CUSTOMER_LIST");
  url = url + "?";
  if (page != undefined) {
    url = `${url}page=${page}&`;
  }
  if (size != undefined) {
    url = `${url}size=${size}&`;
  }
  if (filters != undefined && filters != "" && filters) {
    url = `${url}filters=${filters}&`;
  }
  if (sort != undefined && sort != "") {
    url = `${url}sort=${sort}&`;
  }
  return async (dispatch) => {
    dispatch({
      type: types.FETCHING_CUSTOMER_LIST,
    });
    try {
      let res = await API_helpers.GET(url.substr(0, url.length - 1), {
        headers: {
          userdetails: JSON.stringify({ name: "palani" }),
          organization: "11111",
        },
      });

      let totalCount = res.data._totalcount;
      if (res.data) {
        totalCount = res.data._totalcount;
      }
      dispatch({
        type: types.FETCHED_CUSTOMER_LIST,
        payload: res.data.data || [],
        totalCount: totalCount,
      });
    } catch (err) {
      dispatch({
        type: types.FETCH_CUSTOMER_LIST_FAILED,
      });
    }
  };
};

const updateFilterData = ({ filter, filterArray }) => ({
  type: types.UPDATE_CUSTOMER_FILTER_DATA,
  payload: { filter, filterArray },
});

const updateSort = ({ sorting, sort }) => ({
  type: types.UPDATE_CUSTOMER_SORT,
  payload: { sorting, sort },
});

export const updateCustomerHiddenTableCol = (hidden) => ({
  type: types.UPDATE_CUSTOMER_COLUMN_HIDDEN,
  payload: hidden,
});

export default {
  updateFilterData,
  updateSort,
  updateCustomerHiddenTableCol,
};
