import React, { Component } from "react";
import {
  Typography,
  Grid,
  Button,
  Divider,
  TextField,
} from "@material-ui/core/";
import Breadcrumb from "components/Breadcrumbs/Breadcrumb";
import TeamUsers from "./TeamUsers";
import { LOAD_LOADING } from "./redux/actionTypes";
import { getAllUsers, add_team, update_team_member } from "./redux/action";
import { connect } from "react-redux";
import APIs from "../../api/UserManagementAPIs/index";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { withSnackbar } from "notistack";
import moment from "moment";
import helpers from "../../helpers/helpers";

import { CustomNumberFeild } from "components/Customized";
import { RiEditLine } from "react-icons/ri";
import Loader from "../../components/common/stuff/Loader";
import TeamApi from "api/TeamsAPIs/index";
import { checkPermission } from "helpers/permissons";
class CreateTeam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ID: localStorage.getItem("userManagementId"),
      error: false,
      isEdit: false,
      ajax: false,
      teamsPayload: {
        name: "",
        users: [],
      },
      err_msg: "",
      index: 0,
      isDisabled: false,
      locationStateData: {},
    };
  }

  onChange = (value) => {
    this.setState({
      teamsPayload: { ...value },
    });
  };

  fetchTeamData = async (id) => {
    try {
      const res = await TeamApi.getTeamData(id);
      this.setState({
        teamsPayload: {
          ...(res.data.data || {}),
        },
      });
    } catch (err) {}
  };

  async componentDidMount() {
    const { location } = this.props;
    this.props.setLoader(true);
    if (location.state !== undefined) {
      let filter = {};
      await this.props.getUsers(JSON.stringify(filter));
      let { team_data, edit, disabled } = location.state;
      if (edit || disabled) {
        this.fetchTeamData(team_data._id);
      } else {
        this.setState({
          teamsPayload: {
            ...this.state.teamsPayload,
            ...team_data,
          },
        });
      }
      this.setState({
        isEdit: edit,
        isDisabled: disabled,
        locationStateData: {
          ...team_data,
        },
      });
    }
    this.props.setLoader(false);
  }

  editUserdata = async () => {
    let {
      _id,
      roles,
      email,
      updatedby,
      updatedon,
      createdby,
      createdon,
      ...data
    } = this.state.userData;
    this.props.setLoader(true);
    try {
      const res = await APIs.editUserManagement(_id, data);
      this.props.enqueueSnackbar(
        res.data._msg || "User details updated successfully",
        {
          variant: "success",
          autoHideDuration: 2000,
        }
      );
      this.props.setLoader(false);
      this.props.history.push("/app/user-management");
    } catch (err) {
      this.props.enqueueSnackbar("Something went wrong", {
        variant: "error",
        autoHideDuration: 2000,
      });
      this.props.setLoader(false);
    }
  };

  addUserdata = async (type) => {
    let { userData } = this.state;
    if (userData.roles.length === 0) {
      this.props.enqueueSnackbar("Please add roles!", {
        variant: "error",
        autoHideDuration: 2000,
      });
      return;
    }
    let userRoles = [];
    userData.roles.map((item, id) => {
      if (item) {
        userRoles.push(item.id);
      }
    });
    userData.roles = [...userRoles];
    this.props.setLoader(true);
    try {
      const res = await APIs.addUserManagement(userData);
      this.props.enqueueSnackbar(res.data._msg || "User Created successfully", {
        variant: "success",
        autoHideDuration: 2000,
      });
      if (type && res.data) {
        this.props.setLoader(false);
        this.props.history.push("/app/user-management");
      } else {
        this.props.setLoader(false);
        this.setState({
          userData: {
            name: "",
            email: "",
            contactNo: "",
            roles: [],
          },
          autoCompleteVal: null,
          isEdit: false,
        });
      }
    } catch (err) {
      this.props.setLoader(false);
      this.props.enqueueSnackbar(
        err.response.data._msg || "Something Went wrong",
        {
          variant: "error",
          autoHideDuration: 2000,
        }
      );
    }
  };

  multiAdd = async () => {
    const payload = { ...this.state.teamsPayload };
    let { isEdit } = this.state;
    if (isEdit) {
    } else {
      let { status, data, response } = await this.props.addTeam(payload);
      let flag = status === 201 ? true : false;
      this.props.enqueueSnackbar(
        flag
          ? data._msg
          : response.data
          ? response.data._msg
          : "something went wrong",
        {
          variant: flag ? "success" : "error",
          autoHideDuration: 2000,
        }
      );
      if (flag) {
        //loadin false
        this.props.history.push("/app/team-management");
      } else {
        //loading false
      }
    }
  };

  deleteTeamMember = async (user, update) => {
    const { locationStateData } = this.state;
    let payload = [user];
    this.props.setLoader(true);
    let { _id, users } = this.state.teamsPayload;
    const { data, status, response } = await this.props.updateMember(
      false,
      _id,
      payload
    );
    let flag = status === 200 ? true : false;
    if (flag) {
      let refreshPayload = { ...this.state.teamsPayload };
      refreshPayload.users = [...update];
      // this.props.history.push("/app/create-team", {
      //   team_data: refreshPayload,
      //   edit: true,
      // });
      // this.setState({
      //   teamsPayload: { ...this.state.teamsPayload, users: [...update] },
      // });
      this.fetchTeamData(locationStateData._id || 0);
    }

    this.props.enqueueSnackbar(
      flag
        ? data && data._msg
        : response && response.data
        ? response.data._msg
        : "something went wrong",
      {
        variant: flag ? "success" : "error",
        autoHideDuration: 2000,
      }
    );
    this.props.setLoader(false);
  };
  updateTeamMember = async (user) => {
    const { locationStateData } = this.state;
    let { _id, users } = this.state.teamsPayload;
    this.props.setLoader(true);
    const { data, status, response } = await this.props.updateMember(
      true,
      _id,
      user
    );

    let flag = status === 200 ? true : false;
    if (flag) {
      let update = [...users];
      let refreshPayload = { ...this.state.teamsPayload };
      update.push(user[0]);
      refreshPayload.users = [...update];
      this.props.setLoader(false);
      // this.props.history.push("/app/create-team", {
      //   team_data: refreshPayload,
      //   edit: true,
      // });
      // this.setState({
      //   teamsPayload: { ...this.state.teamsPayload, users: [...update] },
      // });
      this.fetchTeamData(locationStateData._id || 0);
    }

    this.props.enqueueSnackbar(
      flag
        ? data && data._msg
        : response && response.data
        ? response.data._msg
        : "something went wrong",
      {
        variant: flag ? "success" : "error",
        autoHideDuration: 2000,
      }
    );
    this.props.setLoader(false);
    this.props.getUsers({});
  };

  onSubmitUser = async (e) => {
    this.props.setLoader(true);
    await this.multiAdd();
    this.props.setLoader(false);
  };

  handleUsersRoles = (arr, type) => {
    if (type === undefined) {
      this.setState({
        teamsPayload: { ...this.state.teamsPayload, users: [...arr] },
      });
    } else {
      let data = arr.filter((item, id) => {
        return item.status === true;
      });
      this.updateTeamMember(data);
    }
  };
  handleDelete = (id, user) => {
    let update = [...this.state.teamsPayload.users];
    update = update.map((item, idx) => {
      if (idx === id) {
        return undefined;
      } else {
        return item;
      }
    });
    if (user) {
      this.deleteTeamMember(user, update);
    } else {
      this.setState({
        teamsPayload: { ...this.state.teamsPayload, users: [...update] },
      });
    }
  };

  render() {
    const { teamsPayload, isEdit, isDisabled } = this.state;
    const { loading } = this.props;
    console.log(teamsPayload, "team payload");
    return (
      <div>
        <ValidatorForm
          ref="form"
          autoComplete="off"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              return;
            }
          }}
          onSubmit={(e) => {
            this.onSubmitUser(e);
          }}
        >
          {loading && <Loader />}
          <Grid container>
            <Grid item sm={12} md={12} xs={12} lg={12}>
              <Breadcrumb
                {...this.props}
                links={[
                  { name: "Teams", url: "/app/team-management" },
                  {
                    name: `${isEdit ? teamsPayload.name : "Teams"}`,
                    url: "/app/create-team",
                  },
                ]}
              />
            </Grid>

            <Grid item sm={6} md={6} lg={6} xs={12}>
              <Typography variant="h6" style={{ fontWeight: "bold" }}>
                {isEdit ? teamsPayload.name : "Teams"}
              </Typography>
            </Grid>
            <Grid
              item
              sm={6}
              md={6}
              container
              justifyContent="flex-end"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                {!isEdit && (
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={() => {
                      this.props.history.push("/app/team-management");
                    }}
                  >
                    Cancel
                  </Button>
                )}
              </Grid>
              <Grid item>
                {/* <Button
                  name="multiple"
                  color={isEdit ? "" : "primary"}
                  size="small"
                  variant={isEdit ? "contained" : "outlined"}
                  type="submit"
                >
                  {isEdit ? "Save" : "Create and Add Another"}
                </Button> */}
              </Grid>
              <Grid item>
                {!isEdit && (
                  <Button
                    name="single"
                    size="small"
                    variant={"contained"}
                    type="submit"
                    disabled={!checkPermission("btn33")}
                  >
                    {"Create"}
                  </Button>
                )}
              </Grid>
              <Grid item>
                {isDisabled && (
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() =>
                      this.setState({
                        isDisabled: false,
                      })
                    }
                    disabled={!checkPermission("btn38")}
                    startIcon={<RiEditLine style={{ fontSize: "20px" }} />}
                  >
                    Edit
                  </Button>
                )}
              </Grid>
            </Grid>
            <Grid item md={12}>
              <Divider
                style={{
                  marginTop: "12px",
                  height: "2px",
                  background: "rgb(175, 175, 175)",
                }}
              />
            </Grid>

            <Grid item md={12} style={{ marginTop: "22px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4} md={3}>
                  <Typography style={{ marginBottom: "2px" }} variant="body2">
                    Name
                  </Typography>
                  <TextValidator
                    size="small"
                    variant="outlined"
                    fullWidth
                    name="name"
                    disabled={isEdit ? true : false}
                    value={teamsPayload.name}
                    onChange={(e) =>
                      this.onChange({
                        ...teamsPayload,
                        name: e.target.value.trimStart(),
                      })
                    }
                    validators={["required", "isString"]}
                    errorMessages={["Please enter team name", "Invalid input"]}
                    helperText={<div style={{ height: "12px" }}></div>}
                  />
                </Grid>

                {isEdit && (
                  <>
                    {teamsPayload.createdby && (
                      <Grid item md={3} xs={12} sm={3}>
                        <Typography
                          style={{ marginBottom: "2px" }}
                          variant="body2"
                        >
                          Created by
                        </Typography>
                        <TextField
                          disabled={true}
                          size="small"
                          variant="outlined"
                          fullWidth
                          value={teamsPayload.createdby}
                        />
                      </Grid>
                    )}
                    {teamsPayload.createdon && (
                      <Grid item md={3} xs={12} sm={3}>
                        <Typography
                          style={{ marginBottom: "2px" }}
                          variant="body2"
                        >
                          Created on
                        </Typography>
                        <TextField
                          disabled={true}
                          size="small"
                          variant="outlined"
                          fullWidth
                          value={helpers.date_Func(teamsPayload.createdon)}
                        />
                      </Grid>
                    )}
                    {teamsPayload.updatedby && (
                      <Grid item md={3} xs={12} sm={3}>
                        <Typography
                          style={{ marginBottom: "2px" }}
                          variant="body2"
                        >
                          Updated by
                        </Typography>
                        <TextField
                          disabled={true}
                          size="small"
                          variant="outlined"
                          fullWidth
                          value={teamsPayload.updatedby}
                        />
                      </Grid>
                    )}
                    {teamsPayload.updatedon && (
                      <Grid item md={3} xs={12} sm={3}>
                        <Typography
                          style={{ marginBottom: "2px" }}
                          variant="body2"
                        >
                          Updated on
                        </Typography>
                        <TextField
                          disabled={true}
                          size="small"
                          variant="outlined"
                          Customer
                          fullWidth
                          value={
                            helpers.date_Func(teamsPayload.updatedon)
                            // moment
                            // .unix(teamsPayload.updatedon)
                            // .format("DD-MM-YYYY, hh:MM A")
                          }
                        />
                      </Grid>
                    )}
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </ValidatorForm>

        <Grid item md={12}>
          <div>
            <TeamUsers
              updateTeamMember={this.updateTeamMember}
              handleUsersRoles={this.handleUsersRoles}
              {...this.props}
              // rolesMap={this.state.teamsPayload.users}
              userMap={this.state.teamsPayload.users}
              isEdit={this.state.isEdit}
              // rolesData={this.props.rolesData}
              userData={this.props.userData}
              handleDelete={this.handleDelete}
              getUsers={this.props.getUsers}
              isDisabled={isDisabled}
            />
          </div>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.createTeamReducer.users,
    loading: state.createTeamReducer.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoader: (bool) => dispatch({ type: LOAD_LOADING, payload: bool }),
    getUsers: (filter) => dispatch(getAllUsers(filter)),
    updateMember: (type, id, users) =>
      dispatch(update_team_member(type, id, users)),
    addTeam: (payload) => dispatch(add_team(payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(CreateTeam));
