import React from "react";
import { connect } from "react-redux";
import { addUsageParams, updateBillingService } from "../redux/action";
import { getApplicationDetails } from "../ApplicationDetails/Redux/action";
import {
  Button,
  Grid,
  TextField,
  Typography,
  FormControl,
  FormHelperText,
  Divider,
} from "@material-ui/core";
import {
  TextValidator,
  SelectValidator,
  ValidatorForm,
} from "react-material-ui-form-validator";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";
import Styles from "./Style";
import { isDuplicate } from "../../../helpers/helpers";
import Breadcrumb from "components/Breadcrumbs/Breadcrumb";
import APIs from "../../../api/ApplicationAPIs/index";
import { withSnackbar } from "notistack";
import { RiEditLine } from "react-icons/ri";

import { checkPermission } from "helpers/permissons";
import HelpdeskFAB from "../../../views/HelpdeskFAB/HelpdeskFab";

class CustomBillingService extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ID: localStorage.getItem("appId"),
      modules: [],
      check_name: "",
      check_datakey: "",
      err_msg: "",
      error: false,
      error_: false,
      billingData: {
        name: "",
        dataKey: "",
        description: "",
        moduleName: "",
      },

      BillingServiceName: [],
      isEdit: false,
      index: 0,
      ajax: false,
      isDisabled: false,
    };
  }
  componentDidMount() {
    const { location } = this.props;
    if (location.state !== undefined) {
      let {
        billableServices,
        usageParamsData,
        edit,
        ajax,
        appID,
        moduleData,
        disabled,
        appName,
      } = location.state;

      this.setState({
        modules: [...moduleData],
        BillingServiceName: [...billableServices],
        billingData: {
          ...usageParamsData,
        },
        isEdit: edit,

        check_name: usageParamsData.name,
        check_datakey: usageParamsData.dataKey,
        // checkEdit: {
        //  name: usageParamsData.name,
        //   datakey: usageParamsData.dataKey
        // },
        ajax: ajax,
        ID: appID,
        isDisabled: disabled,
        appName: appName,
      });
    }
  }

  onchangeBillingServiceName = (e, value) => {
    if (e.target.name === "name") {
      this.state.BillingServiceName.map((item, id) => {
        if (item.name === value.name) {
          value.id = item._id;
        }
      });
    }
    this.setState({
      billingData: { ...value },
    });
  };

  handleOnchange = (value) => {
    this.setState({
      billingData: { ...value },
    });
  };

  multilineAdd = async () => {
    const { isEdit, billingData } = this.state;
    if (isEdit) {
      let updateUsageParams = [...this.props.usageParams];
      updateUsageParams = updateUsageParams.map((item, index) => {
        return item.key === billingData.key ? { ...billingData } : { ...item };
      });
      await this.props.edit_usageParams(updateUsageParams);
      this.props.history.push("/app/onboard-application", { status: true });
    } else {
      await this.props.add_usageParams(this.state.billingData);
      this.setState({
        billingData: {
          name: "",
          dataKey: "",
          description: "",
          moduleName: "",
          key: this.props.usageParams.length,
        },
        isEdit: false,
      });
    }
  };

  singleObjectSubmit = async () => {
    let basicDetails = localStorage.getItem("basicDetails");

    if (this.state.ajax) {
      console.log(this.state.billingData, "1111");
    } else {
      const { billingData } = this.state;
      await this.props.add_usageParams(billingData);
      // this.props.history.push("/app/onboard-application", {
      //   stateData: {
      //     basicDetails,
      //   },
      // });
      this.props.history.push("/app/onboard-application", { status: true });
    }
  };

  editBillableService = async () => {
    let { _id, key, ...data } = this.state.billingData;
    let res = await APIs.editBillableService(
      this.state.billingData.appId,
      _id,
      data
    );
    if (res.data) {
      this.props.enqueueSnackbar("Billing Services updated successfully", {
        variant: "success",
      });
      this.props.history.push("/app/detail", { appId: this.state.ID });
    }
  };

  addBillableService = async () => {
    let { key, ...data } = this.state.billingData;
    try {
      const res = await APIs.addBillableService(this.state.ID, data);
      this.props.enqueueSnackbar(
        res.data._msg || "Billing Services saved successfully",
        { variant: "success", autoHideDuration: 3000 }
      );
    } catch (err) {
      this.props.enqueueSnackbar(err.message || "Something Went wrong", {
        variant: "error",
        autoHideDuration: 3000,
      });
      console.error(err);
    }
  };

  ajaxFuncs = async () => {
    let { isEdit } = this.state;
    if (isEdit) {
      this.editBillableService();
    } else {
      await this.addBillableService();
      await this.props.getDetails(this.state.ID);
      this.setState({
        billingData: {
          name: "",
          dataKey: "",
          moduleName: "",
          description: "",
        },
        isEdit: false,
      });
    }
  };

  singleAjaxFunc = async () => {
    await this.addBillableService();
    this.props.history.push("/app/detail", { appId: this.state.ID });
  };

  onSubmitAjaxCall = (e) => {
    const { billingData } = this.state;

    let flag = false;
    let flagTwo = false;

    if (!this.state.isEdit || this.state.check_name !== billingData.name) {
      flag = isDuplicate(this.props.billingUsageParams, (f) => {
        return f.name.toLowerCase() === billingData.name.toLowerCase();
      });
    }

    // if (
    //   !this.state.isEdit ||
    //   this.state.check_datakey !== billingData.dataKey
    // ) {
    //   flagTwo = isDuplicate(this.props.billingUsageParams, (f) => {
    //     return f.dataKey.toLowerCase() === billingData.dataKey.toLowerCase();
    //   });
    // }

    if (flag || flagTwo) {
      this.setState({
        error: flag,
        error_: flagTwo,
      });
      return;
    }

    this.setState({
      err_msg: "",
      error: false,
      error_: false,
    });

    if (e.nativeEvent.submitter.name === "multiple") {
      this.ajaxFuncs();
    }
    if (e.nativeEvent.submitter.name === "single") {
      this.singleAjaxFunc();
    }
  };

  onSubmit = async (e) => {
    const { billingData } = this.state;
    const { usageParams } = this.props;

    let flag = false;
    let flagTwo = false;

    if (!this.state.isEdit || this.state.check_name !== billingData.name) {
      flag = isDuplicate(usageParams, (f) => {
        return f.name.toLowerCase() === billingData.name.toLowerCase();
      });
    }

    if (
      !this.state.isEdit ||
      this.state.check_datakey !== billingData.dataKey
    ) {
      flagTwo = isDuplicate(usageParams, (f) => {
        return f.dataKey.toLowerCase() === billingData.dataKey.toLowerCase();
      });
    }

    if (flag || flagTwo) {
      this.setState({
        error: flag,
        error_: flagTwo,
      });
      return;
    }

    this.setState({
      err_msg: "",
      error: false,
      error_: false,
    });

    if (e.nativeEvent.submitter.name === "multiple") {
      this.multilineAdd();
    }

    if (e.nativeEvent.submitter.name === "single") {
      this.singleObjectSubmit();
    }
  };

  setDuplicateNull = () => {
    this.setState({
      error: false,
      error_: false,
      err_msg: "",
    });
  };

  render() {
    const {
      error,
      billingData,
      BillingServiceName,
      isEdit,
      error_,
      ajax,
      ID,
      isDisabled,
      appName,
    } = this.state;
    const { classes } = this.props;
    console.log(this.props.billingUsageParams, billingData, module, "redux");

    return (
      <div>
        <Grid item sm={12} md={12} xs={12} lg={12}>
          <Breadcrumb
            {...this.props}
            links={[
              { name: "Applications", url: "/app/applications" },
              {
                name: `${ajax ? `${appName}` : "Onboard Application"}`,
                url: `${ajax ? "/app/detail" : "/app/onboard-application"}`,
                routeParams: ajax ? { appId: this.state.ID } : { status: true },
              },
              {
                name: "Billable Service",
                url: "/app/create-billing-form",
              },
            ]}
          />
        </Grid>
        <ValidatorForm
          autoComplete="off"
          onSubmit={(e) => {
            if (ajax) {
              this.onSubmitAjaxCall(e);
            } else {
              this.onSubmit(e);
            }
          }}
        >
          <Grid container spacing={0}>
            <Grid item sm={12} md={12} xs={12} lg={12}>
              <Grid
                container
                spacing={1}
                direction="row"
                alignItems="center"
                className={classes.root}
              >
                <Grid item md={6}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="h6" className={classes.title}>
                      Billable Service
                    </Typography>
                  </div>
                </Grid>
                <Grid
                  item
                  sm={6}
                  container
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    {!isDisabled && (
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() => {
                          if (ajax) {
                            this.props.history.push("/app/detail", {
                              appId: ID,
                            });
                          } else {
                            this.props.history.push(
                              "/app/onboard-application",
                              {
                                status: true,
                              }
                            );
                          }
                        }}
                      >
                        Cancel
                      </Button>
                    )}
                  </Grid>
                  <Grid item>
                    {!isDisabled && (
                      <Button
                        name="multiple"
                        color="primary"
                        size="small"
                        variant={isEdit ? "contained" : "outlined"}
                        disabled={
                          isEdit
                            ? !checkPermission("btn107")
                            : !checkPermission("btn106")
                        }
                        type="submit"
                      >
                        {isEdit ? "Save" : "Create and Add Another"}
                      </Button>
                    )}
                  </Grid>
                  <Grid item>
                    {!isEdit && (
                      <Button
                        name="single"
                        size="small"
                        variant="contained"
                        type="submit"
                        disabled={!checkPermission("btn105")}
                      >
                        {"Create"}
                      </Button>
                    )}
                  </Grid>
                  <Grid>
                    {isDisabled && (
                      <Button
                        size="small"
                        variant="contained"
                        onClick={() =>
                          this.setState({
                            isDisabled: false,
                          })
                        }
                        disabled={!checkPermission("btn107")}
                        startIcon={<RiEditLine style={{ fontSize: "20px" }} />}
                      >
                        Edit
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12}>
              <Divider className={classes.divider} />
            </Grid>
            <Grid item md={12} style={{ marginTop: "22px" }}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <div>
                    <Typography style={{ marginBottom: "2px" }} variant="body2">
                      {" "}
                      Billable Service <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <FormControl error={error} fullWidth>
                      <SelectValidator
                        disabled={isDisabled}
                        variant="outlined"
                        fullWidth
                        size="small"
                        SelectProps={{
                          native: true,
                        }}
                        value={billingData.name}
                        onChange={(e) =>
                          this.onchangeBillingServiceName(e, {
                            ...billingData,
                            name: e.target.value,
                          })
                        }
                        inputProps={{
                          name: "name",
                        }}
                        onFocus={this.setDuplicateNull}
                        validators={["required"]}
                        errorMessages={["Please select billing "]}
                        error={error}
                      >
                        <option value={""}>- select -</option>
                        {BillingServiceName.map((item) => (
                          <option value={item.name}>{item.name}</option>
                        ))}
                      </SelectValidator>
                      {error ? (
                        <FormHelperText>
                          {error ? "Billing service is already selected" : null}
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                  </div>
                </Grid>

                <Grid item xs={2}>
                  <div>
                    <Typography style={{ marginBottom: "2px" }} variant="body2">
                      {" "}
                      Module Name <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <FormControl fullWidth>
                      <SelectValidator
                        disabled={isDisabled}
                        variant="outlined"
                        fullWidth
                        size="small"
                        SelectProps={{
                          native: true,
                        }}
                        value={billingData.moduleName}
                        onChange={(e) =>
                          this.onchangeBillingServiceName(e, {
                            ...billingData,
                            moduleName: e.target.value,
                            moduleId:
                              this.state.modules.find(
                                (f) => f.name === e.target.value
                              )._id || "",
                          })
                        }
                        inputProps={{
                          name: "moduleName",
                        }}
                        onFocus={this.setDuplicateNull}
                        validators={["required"]}
                        errorMessages={["Please select module name "]}
                        error={error}
                      >
                        <option value={""}>- select -</option>
                        {this.state.modules.map((item) => (
                          <option value={item.name}>{item.name}</option>
                        ))}
                      </SelectValidator>
                    </FormControl>
                  </div>
                </Grid>

                <Grid item md={2}>
                  <div style={{ width: "100%" }}>
                    <Typography style={{ marginBottom: "2px" }} variant="body2">
                      Data Key <span style={{ color: "red" }}>*</span>{" "}
                    </Typography>
                    <TextValidator
                      disabled={isDisabled}
                      //label="Data Key"
                      fullWidth
                      size="small"
                      variant="outlined"
                      //required
                      value={billingData.dataKey}
                      id="key"
                      onChange={(e) =>
                        this.handleOnchange({
                          ...billingData,
                          dataKey: e.target.value.trimStart(),
                        })
                      }
                      name="billingDataKey"
                      validators={["required"]}
                      errorMessages={["Please enter data key"]}
                      onFocus={this.setDuplicateNull}
                      error={error_}
                      helperText={
                        error_ ? "Duplicate data key is not allowed" : null
                      }
                    />
                  </div>
                </Grid>
                <Grid item md={6}>
                  <Grid container justifyContent="flex-end">
                    <Grid item md={6}>
                      <div style={{ width: "100%" }}>
                        <Typography
                          style={{ marginBottom: "2px" }}
                          variant="body2"
                        >
                          Description
                        </Typography>
                        <TextField
                          disabled={isDisabled}
                          //label="Description"
                          fullWidth
                          size="small"
                          multiline
                          rows={7}
                          value={billingData.description}
                          variant="outlined"
                          onChange={(e) =>
                            this.handleOnchange({
                              ...billingData,
                              description: e.target.value,
                            })
                          }
                          id="description"
                          name="billingDescription"
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <HelpdeskFAB />
          </Grid>
        </ValidatorForm>
      </div>
    );
  }
}
CustomBillingService.propTypes = {
  classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => {
  return {
    usageParams: state.application.onBoard.usageParams,
    billingUsageParams:
      state.appDetailsRedcer.applicationDetails.billingUsageParams || [],
  };
};
const mapDispatchToProps = (dispatch) => ({
  getDetails: (id) => dispatch(getApplicationDetails(id)),
  add_usageParams: (data) => dispatch(addUsageParams(data)),
  edit_usageParams: (data) => dispatch(updateBillingService(data)),
});

const CreateBillingService = connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomBillingService);

export default withStyles(Styles)(withSnackbar(CreateBillingService));
