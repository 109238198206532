import React from "react";
import DisplayGraph from "./Components/DisplayGraph";

const AppUsageData = () => {
  return (
    <div>
      <DisplayGraph name="Billable Service One" />
    </div>
  );
};
export default AppUsageData;
