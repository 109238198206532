import React, { Component } from "react";
import Breadcrumb from "components/Breadcrumbs/Breadcrumb";
import { Typography, Grid, Button, Divider } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Styles from "./styles.js";
import {
  ValidatorForm,
  SelectValidator,
  TextValidator,
} from "react-material-ui-form-validator";
import pricingMasterAPIS from "api/PricingMasterAPIs/index";
import { withSnackbar } from "notistack";
import actions from "./redux/action";
import { connect } from "react-redux";
import APIs from "../../api/InvoiceAPIs/index";
import HelpdeskFAB from "../../views/HelpdeskFAB/HelpdeskFab";
import Loader from "../../components/common/stuff/Loader";

class AddCreditnote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      billableServices: [],
      modules: [],
      InvoiceData: {},
      CreditData: {
        type: "credit",
        applicationId: "",
        billableId: "",
        moduleId: "",
        amount: "",
        description: "",
      },
      // InvoiceCreateId: {},
      ID: 0,
      isDisabled: false,
    };
  }

  getModuleAndBillableService = async (appId, id) => {
    let res = await pricingMasterAPIS.fetchModulesByApplicationId(appId);
    console.log("gethBillable", res);
    if (res.data.data) {
      this.setState({
        modules: [...res.data.data],
      });
    }
    let data = { moduleId: id };
    let response = await pricingMasterAPIS.fetchBillServiceByAppIdModID(
      appId,
      data
    );
    if (response.data.data) {
      this.setState({
        billableServices: [...response.data.data],
      });
    }
  };

  fetchBillableAndmodule = async (appId) => {
    let res = await pricingMasterAPIS.fetchModulesByApplicationId(appId);

    if (res.data.data) {
      this.setState({
        modules: [...res.data.data],
      });
    }
    let response = await pricingMasterAPIS.fetchBillServiceByAppIdModID(appId);
    if (response.data.data) {
      this.setState({
        billableServices: [...response.data.data],
      });
    }
  };

  async componentDidMount() {
    const { location } = this.props;
    if (location.state !== undefined) {
      let { Credit_Data, invoiceDataID, Invoice_Data, disabled } =
        location.state;
      console.log(invoiceDataID, Credit_Data, "crid");

      this.setState({
        InvoiceData: { ...Invoice_Data },
        ID: invoiceDataID.ID,
        CreditData: {
          ...this.state.CreditData,
          ...Credit_Data,
          module: Credit_Data.module._id,
          invoiceId: invoiceDataID.ID,
        },
        isDisabled: disabled,
      });
      // this.getModuleAndBillableService(
      //   Credit_Data.applicationId,
      //   Credit_Data.module._id
      // );
    } else {
      this.props.history.push("/app/view-invoice", {
        Invoice_Data: this.state.InvoiceData,
        invoiceId: this.state.CreditData.invoiceId,
      });
    }
  }

  addCredit = async () => {
    this.props.setLoading(true);
    try {
      const res = await APIs.addCreditNotes(
        this.state.ID,
        this.state.CreditData
      );
      this.props.setLoading(false);
      this.props.enqueueSnackbar(
        res.data._msg || "Credit Note added successfully",
        { variant: "success", autoHideDuration: 2000 }
      );
      if (res.data) {
        this.props.history.push("/app/view-invoice", {
          Invoice_Data: this.state.InvoiceData,
          invoiceId: this.state.CreditData.invoiceId,
          modules: this.state.CreditData.moduleId,
        });
      }
    } catch (err) {
      this.props.enqueueSnackbar(
        err?.response?.data?._msg || "Something Went wrong",
        {
          variant: "error",
          autoHideDuration: 1000,
        }
      );
      this.props.setLoading(false);
    }
  };

  handleChange = async (val, id) => {
    this.setState({
      CreditData: { ...val },
    });
  };

  handleChangeOfModule = async (val) => {
    let data = { moduleId: val.module };
    this.setState({
      CreditData: { ...val },
    });
  };

  onSubmitData(e) {
    this.addCredit();
  }

  render() {
    const { modules, CreditData, isDisabled } = this.state;
    const { classes, appUsageData, appUsageDetails, load } = this.props;
    const { allApps, moduleByApp } = appUsageData || { allApps: [] };
    console.log(
      "dghjgeytytyetyety",
      allApps,
      appUsageData,
      appUsageDetails,
      modules
    );
    // Object.keys(appUsageDetails||{}).map(f=>)
    return (
      <div>
        {load && <Loader />}
        <Grid item sm={12} md={12} xs={12} lg={12}>
          <Breadcrumb
            {...this.props}
            links={[
              { name: "Invoices", url: "/app/invoice" },
              {
                name: "Invoice Details",
                url: "/app/view-invoice",
                routeParams: { Invoice_Data: this.state.InvoiceData },
              },
              {
                name: "Credit Note",
                url: "/app/create-credit",
              },
            ]}
          />
        </Grid>
        <ValidatorForm
          ref="form"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              return;
            }
          }}
          onSubmit={(e) => {
            this.onSubmitData(e);
          }}
        >
          <Grid container>
            <Grid item sm={6} md={6} lg={6} xs={12}>
              <Typography variant="h6" style={{ fontWeight: "bold" }}>
                Credit Note
              </Typography>
            </Grid>
            <Grid
              item
              sm={6}
              md={6}
              container
              justifyContent="flex-end"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={() => {
                    this.props.history.push("/app/view-invoice", {
                      Invoice_Data: this.state.InvoiceData,
                    });
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              {!isDisabled && (
                <Grid item>
                  <Button
                    name="single"
                    size="small"
                    variant={"contained"}
                    type="submit"
                  >
                    {"Create"}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item md={12}>
            <Divider className={classes.dividerline} />
          </Grid>
          <Grid item md={12} style={{ marginTop: "22px" }}>
            <Grid container spacing={2}>
              <Grid item container direction="row" spacing={2} sm={9} md={9}>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography style={{ marginBottom: "2px" }} variant="body2">
                    Credit amount <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextValidator
                    disabled={isDisabled}
                    size="small"
                    variant="outlined"
                    fullWidth
                    name="amount"
                    value={CreditData.amount}
                    onChange={(e) =>
                      this.handleChange({
                        ...CreditData,
                        amount: parseInt(e.target.value) || 0,
                      })
                    }
                    validators={["required"]}
                    errorMessages={["Amount is required"]}
                  />
                </Grid>

                <Grid item xs={12} sm={4} md={4}>
                  <Typography style={{ marginBottom: "2px" }} variant="body2">
                    Assign Application <span style={{ color: "red" }}>*</span>
                  </Typography>

                  <SelectValidator
                    variant="outlined"
                    disabled={isDisabled}
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    inputProps={{
                      name: "moduleName",
                    }}
                    validators={["required"]}
                    errorMessages={["Please select Application"]}
                    value={CreditData.applicationId}
                    onChange={(e) => {
                      let bill =
                        appUsageDetails[e.target.value].billableService || [];
                      this.setState({
                        billableServices: bill.map((b) => b.BillableDetails),
                        modules: moduleByApp[e.target.value],
                      });

                      this.handleChange(
                        {
                          ...CreditData,
                          applicationId: e.target.value,
                        },
                        e.target.value
                      );
                    }}
                  >
                    <option value={""}>- select -</option>
                    {allApps.map((item, idx) => (
                      <option key={idx} value={item.appId}>
                        {item.name}
                      </option>
                    ))}
                  </SelectValidator>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography style={{ marginBottom: "2px" }} variant="body2">
                    Modules
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </Typography>
                  <SelectValidator
                    disabled={isDisabled}
                    variant="outlined"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    inputProps={{
                      name: "moduleName",
                    }}
                    // validators={["required"]}
                    // errorMessages={["Please select Module name"]}
                    value={CreditData.moduleId}
                    onChange={(e) =>
                      this.handleChangeOfModule({
                        ...CreditData,
                        moduleId: e.target.value,
                      })
                    }
                  >
                    <option>- select -</option>

                    {modules.map((item, id) => (
                      <option value={item.id}>{item.name}</option>
                    ))}
                  </SelectValidator>
                </Grid>

                <Grid item xs={12} sm={4} md={4}>
                  <Typography style={{ marginBottom: "2px" }} variant="body2">
                    Billable Services<span style={{ color: "red" }}>*</span>
                  </Typography>

                  <SelectValidator
                    disabled={isDisabled}
                    variant="outlined"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    inputProps={{
                      name: "billableservice",
                    }}
                    validators={["required"]}
                    errorMessages={["Please select Billable Service"]}
                    value={CreditData.billableId}
                    onChange={(e) =>
                      this.handleChange({
                        ...CreditData,
                        billableId: e.target.value,
                      })
                    }
                  >
                    <option value={""}>- select -</option>
                    {this.state.billableServices.map((item) => (
                      <option value={item._id}>{item.name}</option>
                    ))}
                  </SelectValidator>
                </Grid>
              </Grid>
              <Grid item container direction="row" sm={3} md={3}>
                <Grid item xs={12}>
                  <Typography style={{ marginBottom: "2px" }} variant="body2">
                    Comment <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextValidator
                    autoComplete="off"
                    disabled={isDisabled}
                    multiline
                    rows={7}
                    size="small"
                    variant="outlined"
                    fullWidth
                    id="Comment"
                    value={CreditData.description}
                    onChange={(e) =>
                      this.handleChange({
                        ...CreditData,
                        description: e.target.value,
                      })
                    }
                    name="Comment"
                    validators={["required"]}
                    errorMessages={["Please enter Comment"]}
                  />
                </Grid>
              </Grid>
            </Grid>
            <HelpdeskFAB />
          </Grid>
        </ValidatorForm>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  console.log("fhjfgjyety65ry5r", state.invoiceDetails);
  return {
    load: state.invoiceDetails.load,
    adddata: state.invoiceDetails.adddata,
    appUsageData: state.invoiceDetails.appUsageData,
    appUsageDetails: state.invoiceDetails.appUsageDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addCreditNotes: (id, payload) =>
      dispatch(actions.addCreditNotes(id, payload)),
    // getCreditdetailsById: (Id, invoiceId, payload) =>
    //   dispatch(actions.getCreditdetailsById(Id, invoiceId, payload)),
    setLoading: (bool) => dispatch(actions.loadingDispatchInvoice(bool)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(Styles)(withSnackbar(AddCreditnote)));
