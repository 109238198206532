import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  TableHead,
  TableRow,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Checkbox,
  IconButton,
  Grid,
  Typography,
  Select,
  FormControl,
  Tooltip,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core/";
import CloseIcon from "@material-ui/icons/Close";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import { useEffect } from "react";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,

    color: theme.palette.common.white,
    padding: 10,
    paddingLeft: 15,
    fontSize: 14,
  },
  body: {
    fontSize: 12,
    backgroundColor: "#F0F0F0",
    paddingTop: 0,
    paddingBottom: 2,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default function Referencemappingtable(props) {
  const { data, cdu_values, onChange, rulesAndType } = props;
  const rows = [];
  const classes = useStyles();
  let rows_data = [
    {
      key: 1,
    },
  ];

  const [addrow, setaddRow] = React.useState(rows_data);

  useEffect(() => {
    setaddRow(cdu_values);
  }, [cdu_values]);

  const addFilter = () => {
    let copy = [...addrow];
    let addId = {
      key: addrow.length + 1,
    };
    copy.push(addId);
    setaddRow(copy);
  };

  const removeFilter = () => {
    if (addrow.length != 1) {
      let copy = [...addrow];
      let rowRemoved = copy.filter((row) => {
        return row.key != copy.length;
      });
      setaddRow(rowRemoved);
    }
  };
  const setValidator = (rules, msg) => {
    const {
      maxLength,
      minLength,
      pattern,
      maximum,
      minimum,
      multipleOf,
      exclusiveMaximum,
      exclusiveMinimum,
    } = rules;
    let type = rulesAndType.type;
    if (type === "string") {
      let min = minLength;
      let max = maxLength;
      let patt = pattern;
      if (msg === undefined) {
        return [
          `matchRegexp:${patt}`,
          `maxStringLength:${max}`,
          `minStringLength:${min}`,
        ];
      } else {
        return [
          "Invalid pattern",
          `Maximum length is ${max}`,
          `Minimum length ${min}`,
        ];
      }
    } else {
      let min = exclusiveMinimum ? minimum + 1 : minimum;
      let max = exclusiveMaximum ? maximum - 1 : maximum;
      if (msg === undefined) {
        return [`maxNumber:${max}`, `minNumber:${min}`];
      } else {
        return [`Maximum is ${max}`, `Minimum is ${min}`];
      }
    }
  };

  return (
    <div className="pr-3 pl-3">
      <ValidatorForm>
        <TableContainer
          style={{ overflowY: "auto", height: "57vh", width: "100%" }}
        >
          <Table className={classes.table} aria-label="customized table">
            <TableHead size="small">
              <TableRow>
                <StyledTableCell align="left">Active Metadata</StyledTableCell>
                <StyledTableCell align="left">Passive Metadata</StyledTableCell>
                {/* <StyledTableCell align="center">Actions</StyledTableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(cdu_values).map((f) => (
                <>
                  <StyledTableRow>
                    <StyledTableCell align="left">
                      {" "}
                      <Typography>{f}</Typography>{" "}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {props.rulesAndType.type !== "boolean" ? (
                        <TextValidator
                          autoComplete="off"
                          style={{
                            backgroundColor: "white",
                            marginTop: "10px",
                          }}
                          name="field_name"
                          id="outlined-search"
                          type={
                            rulesAndType.type === "string" ? "text" : "number"
                          }
                          variant="outlined"
                          size="small"
                          fullWidth
                          margin="normal"
                          value={cdu_values[f] || ""}
                          onChange={(e) => {
                            props.onChange(f, e.currentTarget.value);
                          }}
                          validators={setValidator(rulesAndType.rules)}
                          errorMessages={setValidator(
                            rulesAndType.rules,
                            "msg"
                          )}
                        />
                      ) : (
                        <FormControl>
                          {/* <FormLabel component="legend"></FormLabel> */}
                          <RadioGroup
                            value={cdu_values[f] || false}
                            style={{ display: "flex", flexDirection: "row" }}
                            onChange={(e) => {
                              props.onChange(
                                f,
                                e.currentTarget.value === "true" ? true : false
                              );
                            }}
                          >
                            <FormControlLabel
                              value={true}
                              control={<Radio />}
                              label="True"
                            />
                            <FormControlLabel
                              value={false}
                              control={<Radio />}
                              label="false"
                            />
                          </RadioGroup>
                        </FormControl>
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </ValidatorForm>
    </div>
  );
}
