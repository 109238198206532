import React, { useEffect, useState } from "react";

import {
  Grid,
  Button,
  Typography,
  Modal,
  Radio,
  Divider,
  Dialog,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  FormControl,
  DialogActions,
  DialogTitle,
  DialogContent,
  InputAdornment,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import CommentIcon from "@material-ui/icons/Comment";
import Styles from "./Styles";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";
import {
  TextValidator,
  SelectValidator,
  ValidatorForm,
} from "react-material-ui-form-validator";
import { CustomTextField } from "components/Customized";
import SearchIcon from "@material-ui/icons/Search";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));
const AddRole = (props) => {
  const {
    openmodal,
    handleCloseModal,
    handleClose,
    property,
    userData,
    Modaldata,
    Appdata,
    userMap,
  } = props;

  const [teamUsers, setteamUsers] = useState([]);
  // const classes = useStyles();
  const { classes } = props;
  const [rowUserData, setRowUserData] = useState([]);
  const [searchKey, setSearchKey] = useState({ name: "" });

  useEffect(() => {
    setteamUsers([...userMap]);
  }, [userMap]);

  useEffect(() => {
    if (props.isEdit) {
      setRowUserData([...userData]);
    }
  }, [userData, props.isEdit]);

  const handleOnchange = (e, user, id) => {
    if (e.target.checked) {
      let data = [...teamUsers];
      data[id] = user;
      setteamUsers([...data]);
    } else {
      let data = [...teamUsers];
      data = data.map((item, idx) => {
        if (idx === id) {
          return undefined;
        } else {
          return item;
        }
      });
      setteamUsers([...data]);
    }
  };

  const radio_handleOnchange = (user, id) => {
    let data = [...rowUserData];
    data = data.map((item, idx) => {
      return id === idx ? { ...user } : { ...item, status: false };
    });
    setRowUserData([...data]);
  };

  const searchTeamMember = async (value) => {
    setSearchKey({ ...value });
    let filter = {};
    filter["name"] = { $regex: "^" + value.name, $options: "i" };
    props.getUsers(filter);
  };

  console.log(teamUsers, "ghgjgj");
  return (
    <>
      <ValidatorForm>
        <Dialog
          open={openmodal}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <DialogTitle className={classes.title1}>
            {" "}
            <Typography gutterBottom variant="h4">
              <b> Add Team Member</b>
            </Typography>
          </DialogTitle>
          <Divider />
          {!props.isEdit ? (
            <DialogContent style={{ width: "600px", height: "430px" }}>
              <Grid item sm={12}>
                <CustomTextField
                  className=" mt-0 "
                  autoComplete="off"
                  fullWidth
                  id="filled-search"
                  type="text"
                  variant="outlined"
                  size="small"
                  placeholder="Search"
                  onChange={(e) =>
                    searchTeamMember({ ...searchKey, name: e.target.value })
                  }
                  // value={this.state.searchkey}
                  margin="normal"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {" "}
                        <SearchIcon />{" "}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid container>
                <List className={classes.root}>
                  {userData.map((value, id) => {
                    let status =
                      teamUsers[id] && teamUsers[id].id === value._id
                        ? true
                        : false;
                    return (
                      <ListItem key={id} className={classes.liststyles}>
                        <ListItemIcon>
                          <Checkbox
                            color="primary"
                            checked={status}
                            onChange={(e) =>
                              handleOnchange(
                                e,
                                {
                                  name: value.name,
                                  email: value.email,
                                  id: value._id,
                                  status: true,
                                },
                                id
                              )
                            }
                            edge="start"
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ "aria-labelledby": id }}
                          />
                        </ListItemIcon>
                        <div style={{ width: "170px" }}>
                          <ListItemText id={id} primary={value.name} />
                        </div>
                        <ListItemText id={id} primary={value.email} />
                      </ListItem>
                    );
                  })}
                </List>
              </Grid>
              <Grid container>
                <div
                  className=" mr-1 ml-1"
                  style={{ borderBottom: "1px solid #D8D8D8", width: "100%" }}
                ></div>
              </Grid>
            </DialogContent>
          ) : (
            <DialogContent style={{ width: "600px", height: "430px" }}>
              <Grid item sm={12}>
                <CustomTextField
                  className=" mt-0 "
                  autoComplete="off"
                  fullWidth
                  id="filled-search"
                  type="text"
                  variant="outlined"
                  size="small"
                  placeholder="Search"
                  // value={this.state.searchkey}
                  margin="normal"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {" "}
                        <SearchIcon />{" "}
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) =>
                    searchTeamMember({ ...searchKey, name: e.target.value })
                  }
                />
              </Grid>
              <Grid container className={classes.cardListStyle}>
                <List className={classes.root}>
                  {rowUserData.map((value, id) => {
                    return (
                      <ListItem key={id} className={classes.liststyles}>
                        <ListItemIcon>
                          <Checkbox
                            // className={classes.radioStyle}
                            value={value.status}
                            onChange={(e) =>
                              radio_handleOnchange(
                                { ...value, status: true },
                                id
                              )
                            }
                          />
                        </ListItemIcon>
                        <div style={{ width: "170px" }}>
                          <ListItemText id={id} primary={value.name} />
                        </div>
                        <ListItemText id={id} primary={value.email} />
                      </ListItem>
                    );
                  })}
                </List>
              </Grid>
              <Grid container>
                <div
                  className=" mr-1 ml-1"
                  style={{ borderBottom: "1px solid #D8D8D8", width: "100%" }}
                ></div>
              </Grid>
            </DialogContent>
          )}
          <DialogActions className={classes.actions}>
            <Button size="small" variant="outlined" onClick={handleCloseModal}>
              Cancel
            </Button>
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                if (props.isEdit) {
                  props.onsubmit(rowUserData, "radio");
                } else {
                  props.onsubmit(teamUsers);
                }
              }}
            >
              Add team members
            </Button>
          </DialogActions>
        </Dialog>
      </ValidatorForm>
    </>
  );
};

// export default AddRole;
export default withStyles(Styles)(AddRole);
