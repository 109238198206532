
import {IconButton,Typography,} from "@material-ui/core"
import Mail from '@material-ui/icons/MailOutline';
const EmailSent=(props)=>{
    return(<div style={{textAlign:"center"}} >
        <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
            <IconButton>
                <Mail color="primary" size="small"/>
            </IconButton>
            <Typography color="primary" variant="h6" style={{fontWeight:600}} >Email Sent</Typography>
        </div>
        <Typography variant="body2">{`Reset password code has been sent to the email ${props.username}`}</Typography>
    </div>)
}
export default EmailSent;