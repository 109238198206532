export const FETCHING_TEAMS = "FETCHING_TEAMS";
export const FETCHING_TEAMS_SUCCESS = "FETCHING_TEAMS_SUCCESS";
export const FETCHING_TEAMS_FAILURE = "FETCHING_TEAMS_FAILURE";

export const BLOCK_TEAMS = "BLOCK_TEAMS";
export const BLOCK_TEAMS_SUCCESS = "BLOCK_TEAMS_SUCCESS";
export const BLOCK_TEAMS_FAILURE = "BLOCK_TEAMS_FAILURE";

export const LOCK_TEAMS = "LOCK_TEAMS";
export const LOCK_TEAMS_SUCCESS = "LOCK_TEAMS_SUCCESS";
export const LOCK_TEAMS_FAILURE = "LOCK_TEAMS_FAILURE";

export const UPDATE_FILTER_DATA = "UPDATE_FILTER_DATA";
export const CLEAR_FILTER_DATA = "CLEAR_FILTER_DATA";

export const UPDATE_TEAMS_SORT = "UPDATE_TEAMS_SORT";

export const UPDATE_TEAM_HIDDEN_COL="UPDATE_TEAM_HIDDEN_COL"