export const FETCHING_CUSTOMER_SUB_APP = "FETCHING_CUSTOMER_SUB_APP";
export const FETCHED_CUSTOMER_SUB_APP = "FETCHED_CUSTOMER_SUB_APP";
export const FETCH_CUSTOMER_SUB_APP_FAILED = "FETCH_CUSTOMER_SUB_APP_FAILED";
export const FETCHING_INVOICE = "FETCHING_INVOICE";
export const FETCHED_INVOICE = "FETCHED_INVOICE";
export const FETCH_INVOICE_FAILED = "FETCH_INVOICE_FAILED";
export const UPDATING_CUSTOMER_PROFILE = "UPDATING_CUSTOMER_PROFILE";
export const UPDATED_CUSTOMER_PROFILE = "UPDATED_CUSTOMER_PROFILE";
export const UPDATE_CUSTOMER_PROFILE_FAILED = "UPDATE_CUSTOMER_PROFILE_FAILED";
export const ENABLE_EDIT = "ENABLE_EDIT";
export const FETCHING_CUST_APP_BILLABLE_SERVICES =
  "FETCHING_CUST_APP_BILLABLE_SERVICES";
export const FETCHED_CUST_APP_BILLABLE_SERVICES =
  "FETCHED_CUST_APP_BILLABLE_SERVICES";
export const FETCH_CUST_APP_BILLABLE_SERVICES_FAILED =
  "FETCH_CUST_APP_BILLABLE_SERVICES_FAILED";
export const FETCHING_CUSTOMER_ISSUE = "FETCHING_CUSTOMER_ISSUE";
export const FETCHED_CUSTOMER_ISSUE = "FETCHED_CUSTOMER_ISSUE";
export const FETCH_CUSTOMER_ISSUE_FAILED = "FETCH_CUSTOMER_ISSUE_FAILED";

export const UPDATING_ENDDATE_SUBSCRIPTION = "UPDATING_ENDDATE_SUBSCRIPTION";
export const UPDATED_ENDDATE_SUBSCRIPTION = "UPDATED_ENDDATE_SUBSCRIPTION";
export const UPDATE_ENDDATE_SUBSCRIPTION = "UPDATE_ENDDATE_SUBSCRIPTION";
export const UPDATE_CUSTOMER_PROFILE_HIDDEN_COL="UPDATE_CUSTOMER_PROFILE_HIDDEN_COL";