/**
 * Contains All the API call for the Billable services
 */
import getURL from "../config";
import API_helpers from "../index";

async function fetchAllPricingMaster() {
  let url = getURL("PRICING_MASTER");
  try {
    let res = await API_helpers.GET(url, {
      headers: { "Content-Type": "application/json" },
    });
    return (res.data || {}).data || [];
  } catch (err) {
    throw err;
  }
}

const fetchPricingDataById = async (id) => {
  let url = getURL("PRICING_MASTER");
  url = `${url}/${id}`;
  try {
    let res = await API_helpers.GET(url, {
      headers: { "Content-Type": "application/json" },
    });
    return res;
  } catch (err) {
    throw err;
  }
};

// async function editBillableServices(billableId, payload) {
//   let url = getURL("EDIT_BILLABLESERVICES")(billableId);
//   try {
//     let res = await API_helpers.PUT(url, payload, {
//       headers: { "Content-Type": "application/json" },
//     });
//     return res.data;
//   } catch (err) {
//     throw err;
//   }
// }

async function fetchAllPricingMasterWithParams(page, size, filters, sort) {
  let url = getURL("PRICING_MASTER");
  url = url + "?";
  let params = { page, size, filters, sort };
  Object.keys(params)
    .filter((k) => params[k] != undefined || params[k] != null)
    .forEach((k) => (url = `${url}${k}=${params[k]}&`));
  url = url.substr(0, url.length - 1);
  try {
    let res = await API_helpers.GET(url, {
      headers: { "Content-Type": "application/json" },
    });
    return res.data;
  } catch (err) {
    throw err;
  }
}

async function deletePricingMaster(id) {
  let url = getURL("DELETE_PRICING_MASTER")(id);
  try {
    let res = await API_helpers.DELETE(url, {
      headers: { "Content-Type": "application/json" },
    });
    return res;
  } catch (err) {
    throw err;
  }
}
async function editPricingMaster(id, data) {
  let { _id, ...payload } = data;
  let url = getURL("EDIT_PRICING_MASTER")(id);
  try {
    let res = await API_helpers.PUT(url, payload, {
      headers: { "Content-Type": "application/json" },
    });
    return res;
  } catch (err) {
    throw err;
  }
}

const getApplicationsByStatus = async (status) => {
  try {
    let res = await API_helpers.GET(
      getURL("GET_APPLICATIONS_BY_STATUS")(JSON.stringify({ status }))
    );
    return res;
  } catch (err) {
    throw err;
  }
};
const getApplicationsBywithoutStatus = async () => {
  try {
    let res = await API_helpers.GET(
      getURL("GET_APPLICATIONS_BY_WITHOUT_STATUS")
    );
    return res;
  } catch (err) {
    throw err;
  }
};

// async function addBillableServices(payload) {
//   let url = getURL("ADD_BILLABLESERVICES");
//   try {
//     let res = await API_helpers.POST(url, { ...payload });
//     //   payload, {
//     //   headers: { "Content-Type": "application/json" },
//     // });
//     return res.data;
//   } catch (err) {
//     throw err;
//   }
// }
const getBillableService = async () => {
  try {
    let res = await API_helpers.GET(getURL("BILLABLE_SERVICES"));
    return res;
  } catch (err) {
    throw err;
  }
};

const fetchModulesByApplicationId = async (id) => {
  try {
    let res = await API_helpers.GET(
      getURL("FETCH_MODULES_BY_APPLICATION_ID")(id)
    );
    return res;
  } catch (err) {
    throw err;
  }
};

const fetchBillServiceByAppIdModID = async (id, mod) => {
  let filter = JSON.stringify({ ...mod });
  console.log(filter, "gkjhgjgjh");
  try {
    let res = await API_helpers.GET(
      getURL("FETCH_BILLABLE_SERVICE_BY_APPID_MOD_ID")(id, filter)
    );
    return res;
  } catch (err) {
    throw err;
  }
};

const fetchSubscriptionPlan = async () => {
  try {
    let res = await API_helpers.GET(getURL("FETCH_ALL_SUBSCRIPTION_PLAN"));
    return res;
  } catch (err) {
    throw err;
  }
};

const addPricingMaster = async (data) => {
  try {
    const res = await API_helpers.POST(getURL("ADD_PRICING_MASTER"), {
      ...data,
    });
    return res;
  } catch (err) {
    throw err;
  }
};

const fetchAllBillableServices = async () => {
  try {
    let res = await API_helpers.GET(getURL("FETCH_ALL_BILLABLE_SERVICES"));
    return res;
  } catch (err) {
    return err;
  }
};
const fetchAllModules = async () => {
  try {
    let res = await API_helpers.GET(getURL("FETCH_ALL_MODULES"));
    return res;
  } catch (err) {
    return err;
  }
};

var APIs = {
  fetchAllPricingMaster,
  fetchAllPricingMasterWithParams,
  deletePricingMaster,
  getApplicationsByStatus,
  getBillableService,
  fetchModulesByApplicationId,
  fetchBillServiceByAppIdModID,
  fetchSubscriptionPlan,
  addPricingMaster,
  editPricingMaster,
  fetchAllBillableServices,
  fetchAllModules,
  fetchPricingDataById,
  getApplicationsBywithoutStatus,
};

export default APIs;
