/**
 *
 */

import * as actions from "./actionTypes";

const intialState = {
  data: [],
  loading: false,
  editdata: {},
  totalCount: 0,
  deldata: {},
  adddata: {},
  filterProps: {
    filters: {},
    filterArray: [{ key: 1, columns: "", operator: "", value: "" }],
    sort: { createdOn: -1 },
    sorting: [{ columnName: "createdOn", direction: "desc" }],
  },
  hidden: ["description"],
};

export default (state = intialState, action) => {
  switch (action.type) {
    case actions.FETCHING_BILLABLE_SERVICES:
      return { ...state, loading: action.loading };
    case actions.FETCHING_BILLABLE_SERVICES_SUCCESS:
      return {
        ...state,
        loading: action.loading,
        data: action.data,
        totalCount: action.totalCount,
      };
    case actions.FETCHING_BILLABLE_SERVICES_FAILURE:
      return { ...state, loading: action.loading, error: action.error };

    case actions.EDIT_BILLABLE_SERVICES:
      return { ...state, loading: action.loading };
    case actions.EDIT_BILLABLE_SERVICES_SUCCESS:
      return { ...state, loading: action.loading, editdata: action.editdata };
    case actions.EDIT_BILLABLE_SERVICES_FAILURE:
      return { ...state, loading: action.loading, error: action.error };

    case actions.DELETE_BILLABLE_SERVICES:
      return { ...state, loading: action.loading };
    case actions.DELETE_BILLABLE_SERVICES_SUCCESS:
      return { ...state, loading: action.loading, deldata: action.deldata };
    case actions.DELETE_BILLABLE_SERVICES_FAILURE:
      return { ...state, loading: action.loading, error: action.error };

    case actions.ADD_BILLABLE_SERVICES:
      return { ...state, loading: action.loading };
    case actions.ADD_BILLABLE_SERVICES_SUCCESS:
      return { ...state, loading: action.loading, adddata: action.adddata };
    case actions.ADD_BILLABLE_SERVICES_FAILURE:
      return { ...state, loading: action.loading, error: action.error };
    case actions.UPDATE_BILLABLE_FILTER_DATA:
      return {
        ...state,
        filterProps: {
          ...state.filterProps,
          filters: action.payload.filter,
          filterArray: action.payload.filterArray,
        },
      };
    case actions.UPDATE_BILLABLE_SORT:
      return {
        ...state,
        filterProps: {
          ...state.filterProps,
          sorting: action.payload.sorting,
          sort: action.payload.sort,
        },
      };
    case actions.UPDATE_BILLABLE_HIDDEN_COL:
      return {
        ...state,
        hidden: action.payload,
      };
    default:
      return state;
  }
};
