import React, { Component } from "react";
import { Typography, Grid, Button, Divider } from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import Upload from "./../../../assets/images/Icon metro-folder-upload.svg";
import SchematoTree from "helpers/schematotree";
import FileExplorerTheme from "react-sortable-tree-theme-full-node-drag";

import SortableTree from "@nosferatu500/react-sortable-tree";

import {
  // SortableTreeWithoutDndContext as SortableTree,
  toggleExpandedForAll,
} from "@nosferatu500/react-sortable-tree";

import "@nosferatu500/react-sortable-tree/style.css";

import Node from "./Node";
import { confirmDialog } from "components/DeleteConfirmationDialog/Index";

const CustomDropperIcon = () => (
  <img alt="drop-icon" src={Upload} width="100" height="50" />
);

class TreeComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadedFiles: [],
    };
  }
  updateUploadedFiles = (files) => {
    this.setState({ uploadedFiles: files });
    try {
      var reader = new FileReader();
      reader.onload = (e) => {
        try {
          console.log("8727389472939", reader.result);
          let node = SchematoTree(reader.result);
          console.log("8727389472939", JSON.stringify(node));
          let nodes = [];
          nodes.push(node);
          console.log(
            "0982384023",
            toggleExpandedForAll({ treeData: nodes }, true)
          );
          this.props.setNodes([
            ...toggleExpandedForAll({ treeData: nodes }, true),
          ]);
        } catch (err) {
          if (err === "Invalid json") {
            this.props.enqueueSnackbar(err, {
              variant: "error",
            });
          }
        }
      };
      reader.readAsText(files[0].file);
    } catch (error) {}
  };

  canDrag = ({ node }) => {
    //if title is arrayElement or root, dont allow to drag
    if (node.title == "root" || node.title == "arrayElement") {
      return false;
    }
    return true;
  };

  canNodeHaveChildren = (node) => {
    switch (node.type) {
      case "string":
      case "boolean":
      case "integer":
      case "number":
        return false;
      default:
        return true;
    }
  };
  canDrop = ({ node, nextParent }) => {
    let arrElmt = (nextParent?.children || []).filter(
      (f) => f.title === "arrayElement"
    );
    console.log("next parent", node, nextParent, arrElmt);

    if (arrElmt.length > 0) return false; // in array all keys should be child of arrayElement.
    if (nextParent == undefined) return false;
    let duplicates = (nextParent.children || []).filter(
      (f) => f.title == node.title
    );
    if (duplicates.length > 1) return false; // should not allow duplicate names.

    return true;
  };

  render() {
    let classes = this.props.classes;
    return (
      <Grid container spacing={3} style={{ padding: "15px" }}>
        <Grid item sm={10} md={10} xs={10} lg={10}>
          <Typography variant="h6" className={classes.title}>
            {this.props.name}
          </Typography>
        </Grid>

        {this.props.pageFrom !== "outgoingAPI" && (
          <Grid item sm={2} md={2} xs={2} lg={2}>
            {this.props.panel === "api" && this.props.nodes.length !== 0 && (
              <Button
                name="single"
                size="small"
                variant="outlined"
                fullWidth
                onClick={() => {
                  confirmDialog(
                    "Do you want to copy this Passive Metadata?",
                    () => this.props.copyNode()
                  );
                }}
              >
                Copy
              </Button>
            )}
          </Grid>
        )}

        <Grid
          item
          sm={12}
          md={12}
          style={{ padding: 0, marginBottom: 10, marginTop: 0 }}
        >
          <Divider className={classes.divider} />
        </Grid>
        {this.props.nodes.length === 0 && this.props.panel === "api" ? (
          <Grid container>
            <Grid item sm={12} md={12} className={classes.modalDropzone}>
              <DropzoneArea
                acceptedFiles={[".json"]}
                Icon={CustomDropperIcon}
                showPreviews={false}
                filesLimit={1}
                dropzoneText={"Upload Json Schema for " + this.props.name}
                dropzoneClass="documentDropperClass"
                dropzoneParagraphClass="documentDropperTextClass"
                showAlerts={["error"]}
                showPreviewsInDropzone={false}
                onChange={(files) => {
                  const temFiles = files.map((file, index) => ({
                    name: file.name,
                    version: "",
                    file,
                  }));
                  this.updateUploadedFiles(temFiles);
                }}
              />
            </Grid>
            <Grid
              item
              sm={12}
              md={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              [OR]
            </Grid>
            <Grid
              item
              sm={12}
              md={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                name="single"
                size="small"
                variant="contained"
                onClick={() => {
                  this.props.addInitialNode(this.props.panel);
                }}
              >
                {" "}
                Add Nodes Manually{" "}
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid container>
            <Grid
              item
              sm={12}
              md={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: 700,
                paddingBottom: "10%",
                overflowY: "auto",
              }}
            >
              <SortableTree
                style={{ height: "100%", width: "100%" }}
                treeData={this.props.nodes}
                onChange={(treeData) => this.props.setNodes(treeData)}
                canDrag={this.canDrag}
                canNodeHaveChildren={this.canNodeHaveChildren}
                canDrop={this.canDrop}
                nodeContentRenderer={(nodeprops, key) => {
                  return (
                    <Node
                      key={key}
                      {...nodeprops}
                      panel={this.props.panel}
                      updateNodeType={this.props.updateNodeType}
                      updateNodeTitle={this.props.updateNodeTitle}
                      insertNewNode={this.props.insertNewNode}
                      removeNode={this.props.removeNode}
                      CduDataStandards={this.props.CduDataStandards}
                      {...this.props}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item md>
              <br />
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  }
}
export default TreeComponent;
