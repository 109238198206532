import * as actions from "./actionTypes";
import APIS from "api/SubscriptionModelAPIs/index";

function actionfetchAllSubscription() {
  return async (dispatch) => {
    dispatch({
      type: actions.FETCH_SUBSCRIBSION,
      loading: true,
    });
    try {
      let data = await APIS.getSubscriptionModel();
      dispatch({
        type: actions.FETCHING_SUBSCRIBSION_SUCCESS,
        loading: false,
        totalCount: data._totalcount,
        data: data.data || [],
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: actions.FETCH_SUBSCRIBSION_FAILED,
        loading: false,
        error: err,
      });
    }
  };
}

function addSubscription(payload) {
  return async (dispatch) => {
    dispatch({
      type: actions.ADD_SUBSCRIBSION,
      loading: true,
    });
    try {
      let adddata = await APIS.addSubscription(payload);
      dispatch({
        type: actions.ADD_SUBSCRIBSION_SUCCESS,
        loading: false,
        adddata,
      });
    } catch (err) {
      dispatch({
        type: actions.ADD_SUBSCRIBSION_FAILURE,
        loading: false,
        error: err,
      });
    }
  };
}

function editSubscription(id, payload) {
  return async (dispatch) => {
    dispatch({
      type: actions.EDIT_SUBSCRIBSION,
      loading: true,
    });
    try {
      let editdata = await APIS.editSubscription(id, payload);
      dispatch({
        type: actions.EDIT_SUBSCRIBSION_SUCCESS,
        loading: false,
        editdata,
      });
    } catch (err) {
      dispatch({
        type: actions.EDIT_SUBSCRIBSION_FAILURE,
        loading: false,
        error: err,
      });
    }
  };
}

const updateFilterData = ({ filter, filterArray }) => ({
  type: actions.UPDATE_SUBSCRIPTION_FILTER_DATA,
  payload: { filter, filterArray },
});

const updateSort = ({ sorting, sort }) => ({
  type: actions.UPDATE_SUBSCRIPTION_SORT,
  payload: { sorting, sort },
});

// const updateHiddenSubscriptionCol =({hidden})=>({
//   type: actions.UPDATE_SUBSCRIPTION_HIDDEN_COL,
//   payload:hidden,
// })

function updateHiddenSubscriptionCol(hidden) {
  return async (dispatch) => {
    dispatch({
      type: actions.UPDATE_SUBSCRIPTION_HIDDEN_COL,
      payload: hidden,
    });
  };
}

export default {
  actionfetchAllSubscription,
  addSubscription,
  editSubscription,
  updateFilterData,
  updateSort,
  updateHiddenSubscriptionCol,
};
