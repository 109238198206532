import { React, useState, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import Avatar from "@material-ui/core/Avatar";
import StarIcon from "@material-ui/icons/Star";
import Divider from "@material-ui/core/Divider";
import DropDown from "../../../components/DropDown/DropDown";
import {
  IconButton,
  CardContent,
  CardHeader,
  FormGroup,
  Card,
  Grid,
  Button,
  TextField,
  Typography,
  FormLabel,
  Select,
  FormControl,
  Tooltip,
} from "@material-ui/core";
import SyncIcon from "@material-ui/icons/Sync";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import ApplicationAPIs from "../../../api/ApplicationAPIs/index";
import { useSnackbar } from "notistack";
import withSection from "components/Section/Section";

const useStyles = makeStyles((theme) => ({
  line: {
    marginTop: -23,
  },

  dividerline: {
    // border: "1px solid black",
    width: "70%",
    margin: "-25px 0px 0px 110px",
    [theme.breakpoints.up("md")]: {
      width: "82%",
      margin: "-25px 0px 0px 260px",
    },
  },
}));

const getBillableServiceOptions = (billableServices, selectedServices) => {
  return (billableServices || [])
    .filter((b) => {
      let s = (selectedServices || []).find((f) => f.id == b._id);
      return s == undefined || s.length == 0;
    })
    .map((item) => {
      return { value: item.name, key: item._id };
    });
};

const getBillingName = (billId, billableServices) => {
  let billservice = billableServices.filter((f) => {
    return (f._id = billId);
  });
  return billservice.length > 0 ? billableServices[0].name : "";
};

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,

    color: theme.palette.common.white,
  },
  body: {
    height: 1,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({}))(TableRow);

const CustomTableRow = (props) => {
  const {
    row,
    appId,
    index,
    onChange,
    removeRows,
    addNewRow,
    billableServices,
    deleteBillableService,
    editBillableService,
    addBillableService,
  } = props;
  console.log({ row, addNewRow });
  const classes = useStyles();
  const [edit, setEdit] = useState(row._id == undefined || false);
  return (
    <>
      <StyledTableCell component="th" scope="row">
        <FormControl
          disabled={edit ? false : true}
          label="Billable Service"
          fullWidth
          size="small"
          variant="outlined"
          className={classes.formControl}
        >
          <Select
            native
            onChange={(e) => onChange(e, index)}
            value={row.id}
            name="id"
          >
            <option value="">-Select-</option>
            {getBillableServiceOptions(billableServices).map((item) => (
              <option value={item.key}>{item.value}</option>
            ))}
          </Select>
        </FormControl>
      </StyledTableCell>
      <StyledTableCell align="left">
        <TextField
          disabled={edit ? false : true}
          required
          className={classes.btn}
          size="small"
          onChange={(e) => onChange(e, index)}
          variant="outlined"
          fullWidth
          value={row.description}
          name="description"
        />
      </StyledTableCell>
      <StyledTableCell align="left">
        <TextField
          disabled={edit ? false : true}
          required
          className={classes.btn}
          size="small"
          onChange={(e) => onChange(e, index)}
          variant="outlined"
          fullWidth
          name="datakey"
          value={row.datakey}
        />
      </StyledTableCell>
      <StyledTableCell align="left">
        {edit && (
          <>
            <Button
              disabled={row.id == ""}
              variant="contained"
              color="secondary"
              type="submit"
              style={{
                marginLeft: 0,
                backgroundColor: "transparent",
                boxShadow: "none",
                color: "#9e9e9e",
              }}
              onClick={async () => {
                if (row._id == undefined) {
                  await addBillableService(appId, row);
                } else {
                  await editBillableService(appId, row._id, row);
                }
                setEdit(false);
              }}
              variant="contained"
            >
              Save
            </Button>{" "}
            <Button
              style={{
                marginLeft: 0,
                backgroundColor: "transparent",
                boxShadow: "none",
                color: "#9e9e9e",
              }}
              variant="contained"
              color="primary"
              onClick={async () => {
                if (row._id == undefined) {
                  removeRows(index);
                } else {
                  setEdit(false);
                }
              }}
            >
              Cancel
            </Button>
          </>
        )}
        {!edit && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
            }}
          >
            <div style={{ width: "4%" }}>
              <Tooltip title="Edit" aria-label="edit">
                <EditOutlinedIcon
                  onClick={() => {
                    setEdit(true);
                  }}
                />
              </Tooltip>
            </div>

            {props.length > 1 && (
              <div style={{ width: "4%" }}>
                <Tooltip title="Delete" aria-label="delete">
                  <DeleteOutlineIcon
                    onClick={async () => {
                      await deleteBillableService(appId, row._id);
                    }}
                  />
                </Tooltip>
              </div>
            )}

            <div style={{ width: "4%" }}>{addNewRow}</div>
          </div>
        )}
      </StyledTableCell>
    </>
  );
};

function BillingConfig(props) {
  const {
    billableServices,
    billing_data,
    billingWebhook,
    deleteBillableService,
    billingUsageParams,
    addBillableService,
    editBillableService,
    appId,
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  console.log({ billingWebhook });
  const [edit, setEdit] = useState(false);
  console.log(billableServices, 6786);
  let [rows, setRows] = useState(
    billingUsageParams || [
      {
        appId: appId,
        dataKey: "",
        description: "",
        name: "CPU",
        id: "610c5db2caca44b4670c0caf",
      },
    ]
  );
  let [billingWebhookPayload, setBillingWebhookPayload] = useState({});

  useEffect(() => {
    setRows(billingUsageParams || []);
    if (billingWebhook) {
      setBillingWebhookPayload({ ...billingWebhook });
    }
  }, [billingUsageParams, billingWebhook]);

  const onChange = (event, index) => {
    let payload = rows.filter(function (el, i) {
      console.log(index, i, index !== i);
      return index == i;
    })[0];
    let newpayload = { ...payload };
    newpayload[event.currentTarget.name] = event.currentTarget.value;
    console.log(newpayload, "checking");
    let data = [...rows];
    data[index] = newpayload;
    setRows([...data]);
  };
  const classes = useStyles();

  const initialRows = [
    { key: 1, name: "", description: "", billingDataKey: "" },
  ];

  const addRows = (event) => {
    const item = {
      new: true,
      name: "CPU",
      description: "",
      dataKey: "",
      id: "",
    };
    setRows([...rows, item]);
  };
  const removeRows = (index) => {
    let data = [...rows];
    if (rows.length != 1) {
      let filtered = data.filter((el, i) => {
        return i !== index;
      });
      setRows([...filtered]);
    }
  };

  const saveBillingWebhooks = async (e) => {
    try {
      const res = await ApplicationAPIs.editBasicDetails(appId, {
        billingWebhook: billingWebhookPayload,
      });
      enqueueSnackbar(
        res.data._msg || "Application details saved successfully",
        { variant: "success", autoHideDuration: 3000 }
      );
      setEdit(false);
    } catch (err) {
      enqueueSnackbar(err.message || "Something Went wrong", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  return (
    <div id="appliation-billable-section">
      <div className="w-100 d-flex justify-content-end">
        {billingUsageParams.length == 0 && (
          <Tooltip title="Add" aria-label="add">
            <Button
              startIcon={<AddCircleOutlineOutlinedIcon />}
              color="secondary"
              onClick={addRows}
            >
              Add Billing
            </Button>
          </Tooltip>
        )}
      </div>

      <div>
        <Typography>Setup billing webhook</Typography>
        <div>
          <Grid container spacing={3} style={{ padding: "15px" }}>
            <Grid item xs={6} sm={3}>
              <Typography variant="subtitle2" style={{ marginBottom: "5px" }}>
                URL
              </Typography>
              <TextField
                className={classes.btn}
                size="small"
                variant="outlined"
                fullWidth
                id="url"
                disabled={!edit}
                // value={(billingWebhook || {}).url}
                value={billingWebhookPayload.url}
                onChange={(e) => {
                  setBillingWebhookPayload({
                    ...billingWebhookPayload,
                    url: e.target.value,
                  });
                }}
                name="billingUrl"
                defaultValue={(billingWebhook || {}).url}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <Typography variant="subtitle2" style={{ marginBottom: "5px" }}>
                Method
              </Typography>
              {/* <TextField
                className={classes.btn}
                size="small"
                variant="outlined"
                fullWidth
                id="method"
                value={billingWebhookPayload.method}
                disabled={!edit}
                defaultValue={(billingWebhook || {}).method}
                name="billingMethod"
                onChange={(e) => {
                  setBillingWebhookPayload({
                    ...billingWebhookPayload,
                    method: e.target.value,
                  });
                }}
              /> */}
              <FormControl
                fullWidth
                disabled={!edit}
                size="small"
                variant="outlined"
                className={classes.btn}
                name="billingMethod"
              >
                <Select
                  name="billingMethod"
                  native
                  value={billingWebhookPayload.method}
                  onChange={(e) => {
                    setBillingWebhookPayload({
                      ...billingWebhookPayload,
                      method: e.target.value,
                    });
                  }}
                >
                  <option value="">-Select-</option>
                  <option value="GET">GET</option>
                  <option value="POST">POST</option>
                  <option value="PUT">PUT</option>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Typography variant="subtitle2" style={{ marginBottom: "5px" }}>
                API Key (Security)
              </Typography>
              <TextField
                className={classes.btn}
                size="small"
                variant="outlined"
                fullWidth
                id="apikey"
                disabled={!edit}
                defaultValue={(billingWebhook || {}).apiKey}
                value={billingWebhookPayload.apiKey}
                name="billingApiKey"
                onChange={(e) => {
                  setBillingWebhookPayload({
                    ...billingWebhookPayload,
                    apiKey: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={6} sm={3} style={{ marginBottom: "10px" }}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-end"
                style={{ height: "100%" }}
              >
                {!edit && (
                  <>
                    <Grid item xs={3}>
                      <Tooltip title="Sync" aria-label="sync">
                        <Button>
                          {" "}
                          <SyncIcon color="secondary" />{" "}
                        </Button>
                      </Tooltip>
                    </Grid>

                    <Grid item xs={3}>
                      <Tooltip title="Edit" aria-label="edit">
                        <Button onClick={() => setEdit(true)}>
                          {" "}
                          <EditOutlinedIcon />{" "}
                        </Button>
                      </Tooltip>
                    </Grid>
                    {/* <Grid item xs={3}>
                      <Tooltip title="Delete" aria-label="delete">
                        <Button>
                          {" "}
                          <DeleteOutlineIcon />{" "}
                        </Button>
                      </Tooltip>
                    </Grid> */}
                  </>
                )}
                {edit && (
                  <>
                    <Grid item xs={4}></Grid>
                    <Grid item xs={4}>
                      <Button
                        variant="contained"
                        color="secondary"
                        style={{
                          marginLeft: 0,
                          backgroundColor: "transparent",
                          boxShadow: "none",
                          color: "#9e9e9e",
                        }}
                        onClick={(e) => {
                          saveBillingWebhooks(e);
                        }}
                        variant="contained"
                      >
                        Save
                      </Button>
                    </Grid>
                    <Grid item xs={4}>
                      <Button
                        style={{
                          marginLeft: 0,
                          backgroundColor: "transparent",
                          boxShadow: "none",
                          color: "#9e9e9e",
                        }}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          setEdit(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </div>
        <br />

        <TableContainer style={{ marginTop: "10px", borderRadius: 0 }}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">
                  {" "}
                  Billing Service{" "}
                </StyledTableCell>
                <StyledTableCell align="left"> Description </StyledTableCell>
                <StyledTableCell align="left"> Data Key </StyledTableCell>
                <StyledTableCell align="center" width="15%">
                  Actions
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <StyledTableRow key={row.name}>
                  <CustomTableRow
                    row={row}
                    length={rows.length}
                    appId={appId}
                    onChange={onChange}
                    removeRows={removeRows}
                    billableServices={billableServices}
                    addBillableService={addBillableService}
                    editBillableService={editBillableService}
                    deleteBillableService={deleteBillableService}
                    usedServices={rows}
                    index={index}
                    addNewRow={
                      index == rows.length - 1 && (
                        <Tooltip title="Add" aria-label="add">
                          <AddCircleOutlineOutlinedIcon
                            color="secondary"
                            onClick={addRows}
                          />
                        </Tooltip>
                      )
                    }
                  />
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default withSection(BillingConfig, "Billable Services");
