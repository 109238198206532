import {
  ADD_ORGANIZATION_DATA,
  ORGANIZATIONS_FILTER,
  ORGANIZATIONS_SORT,
  ACTIVE_INACTIVE_ORG,
  ADD_CURRENT_ORGANIZATION_DATA,
  CHANGE_LOGO,
  FETCH_ORGANIZATION_DETAILS_FAIL,
  FETCH_ORGANIZATION_DETAILS_SUCCESS,
  RESET_ORGANIZATION,
  FETCHING_ORG_DETAILS,
} from "./actionTypes";

const initialState = {
  //   org_list: {
  //     data: [],
  //     error: false,
  //     loading: false,
  //   },
  //   organizationFilter: [{ id: uuidv4(), column: "", operator: "", value: "" }],
  //   organizationSort: [{ columnName: "createdon", direction: "desc" }],
  currentOrgData: {
    title: "",
  },
  organizationDetails: {},
  loading: false,
};

export default function organizationReducer(state = initialState, action) {
  switch (action.type) {
    // case ADD_ORGANIZATION_DATA: {
    //   return {
    //     ...state,
    //     org_list: {
    //       ...state.org_list,
    //       ...action.payload,
    //     },
    //   };
    // }

    // case ACTIVE_INACTIVE_ORG: {
    //   console.log("payload", action.payload);
    //   return {
    //     ...state,
    //     org_list: {
    //       ...state.org_list,
    //       data:
    //         state.org_list.data &&
    //         state.org_list.data.map((item) => {
    //           if (item._id === action.payload.orgID) {
    //             return {
    //               ...item,
    //               status: action.payload.status,
    //             };
    //           } else {
    //             return item;
    //           }
    //         }),
    //     },
    //   };
    // }
    // case ADD_CURRENT_ORGANIZATION_DATA: {
    //   return {
    //     ...state,
    //     currentOrgData: { ...action.payload },
    //   };
    // }
    // case CHANGE_LOGO: {
    //   return {
    //     ...state,
    //     currentOrgData: {
    //       data: {
    //         ...state.currentOrgData.data,
    //         logo: action.logo,
    //         favicon: action.favicon,
    //         title: action.title,
    //       },
    //     },
    //   };
    // }
    case CHANGE_LOGO: {
      return {
        ...state,
        currentOrgData: {
          data: {
            ...state.currentOrgData.data,
            logo: action.logo,
            favicon: action.favicon,
            title: action.title,
          },
        },
      };
    }
    case FETCHING_ORG_DETAILS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ORGANIZATION_DETAILS_SUCCESS:
      console.log(action.payload, "organization78");
      return {
        ...state,
        organizationDetails: action.payload,
        loading: false,
      };
    case FETCH_ORGANIZATION_DETAILS_FAIL:
      return {
        ...state,
        organizationDetails: {},
        loading: false,
      };
    case RESET_ORGANIZATION:
      return {
        ...state,
        organizationDetails: {},
      };
    default:
      return state;
  }
}
