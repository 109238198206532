import React, { Component } from "react";
import PropTypes from "prop-types";
import { isDescendant } from "helpers/tree-utils";
import "./Node.css";
import NodeContent from "./NodeContent";
import { Handle, Position } from "react-flow-renderer";

const classnames = function classnames(...classes) {
  return classes.filter(Boolean).join(" ");
};

class NodeRendererDefault extends Component {
  constructor(props) {
    super(props);
    this.boxRef = React.createRef();
  }
  render() {
    const {
      scaffoldBlockPxWidth,
      toggleChildrenVisibility,
      connectDragPreview,
      connectDragSource,
      isDragging,
      canDrop,
      canDrag,
      node,
      title,
      subtitle,
      draggedNode,
      path,
      treeIndex,
      isSearchMatch,
      isSearchFocus,
      buttons,
      className,
      style,
      didDrop,
      treeId,
      isOver, // Not needed, but preserved for other renderers
      parentNode, // Needed for dndManager
      rowDirection,
      ...otherProps
    } = this.props;
    console.log("node titlesssssssssssssssssssssssssseedswde", parentNode);
    const nodeTitle = title || node.title;
    const nodeSubtitle = subtitle || node.subtitle;
    const rowDirectionClass = rowDirection === "rtl" ? "rst__rtl" : null;

    let handle;
    if (canDrag) {
      if (typeof node.children === "function" && node.expanded) {
        // Show a loading symbol on the handle when the children are expanded
        //  and yet still defined by a function (a callback to fetch the children)
        handle = (
          <div className="rst__loadingHandle">
            <div className="rst__loadingCircle">
              {[...new Array(12)].map((_, index) => (
                <div
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  className={classnames(
                    "rst__loadingCirclePoint",
                    rowDirectionClass
                  )}
                />
              ))}
            </div>
          </div>
        );
      } else {
        // Show the handle used to initiate a drag-and-drop
        handle = connectDragSource(<div className="rst__moveHandle" />, {
          dropEffect: "copy",
        });
      }
    }

    const isDraggedDescendant = draggedNode && isDescendant(draggedNode, node);
    const isLandingPadActive = !didDrop && isDragging;

    let buttonStyle = { left: -0.5 * scaffoldBlockPxWidth };
    if (rowDirection === "rtl") {
      buttonStyle = { right: -0.5 * scaffoldBlockPxWidth };
    }
    console.log("scaffoldBlockPxWidth", scaffoldBlockPxWidth);

    return (
      <div>
        {this.props.showConnector && (
          <Handle
            className={[
              "circle-port ",
              this.props.panel == "api"
                ? "circle-port-right"
                : "circle-port-left",
            ].join(" ")}
            position={
              this.props.panel == "api" ? Position.Right : Position.Left
            }
            type={this.props.panel == "api" ? "source" : "target"}
            id={this.props.panel + "_" + this.props.path.join("_")}
            key={this.props.panel + "_" + this.props.path.join("_")}
            style={{ zIndex: 99999 }}
          />
        )}
        {toggleChildrenVisibility &&
          node.children &&
          (node.children.length > 0 || typeof node.children === "function") && (
            <div>
              <button
                type="button"
                aria-label={node.expanded ? "Collapse" : "Expand"}
                className={classnames(
                  node.expanded ? "rst__collapseButton" : "rst__expandButton",
                  rowDirectionClass
                )}
                style={buttonStyle}
                onClick={() => {
                  !this.props.noActions &&
                    toggleChildrenVisibility({
                      node,
                      path,
                      treeIndex,
                    });
                }}
              />

              {node.expanded && !isDragging && (
                <div
                  style={{ width: scaffoldBlockPxWidth }}
                  className={classnames("rst__lineChildren", rowDirectionClass)}
                />
              )}
            </div>
          )}

        <div className={classnames("rst__rowWrapper", rowDirectionClass)}>
          {/* Set the row preview to be used during drag and drop */}
          {connectDragPreview(
            <div
              className={classnames(
                "rst__row",
                isLandingPadActive && "rst__rowLandingPad",
                isLandingPadActive && !canDrop && "rst__rowCancelPad",
                isSearchMatch && "rst__rowSearchMatch",
                isSearchFocus && "rst__rowSearchFocus",
                rowDirectionClass,
                className
              )}
              style={{
                opacity: isDraggedDescendant ? 0.5 : 1,
                ...style,
              }}
            >
              {this.props.rowDirection !== "rtl" && handle}
              <NodeContent {...this.props} boxRef={this.boxRef} />
              {this.props.rowDirection === "rtl" && handle}
            </div>
          )}
        </div>
      </div>
    );
  }
}

NodeRendererDefault.defaultProps = {
  isSearchMatch: false,
  isSearchFocus: false,
  canDrag: false,
  toggleChildrenVisibility: null,
  buttons: [],
  className: "",
  style: {},
  parentNode: null,
  draggedNode: null,
  canDrop: false,
  title: null,
  subtitle: null,
  rowDirection: "ltr",
};

NodeRendererDefault.propTypes = {
  node: PropTypes.shape({}).isRequired,
  title: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  subtitle: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  path: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  treeIndex: PropTypes.number.isRequired,
  treeId: PropTypes.string.isRequired,
  isSearchMatch: PropTypes.bool,
  isSearchFocus: PropTypes.bool,
  canDrag: PropTypes.bool,
  scaffoldBlockPxWidth: PropTypes.number.isRequired,
  toggleChildrenVisibility: PropTypes.func,
  buttons: PropTypes.arrayOf(PropTypes.node),
  className: PropTypes.string,
  style: PropTypes.shape({}),

  // Drag and drop API functions
  // Drag source
  connectDragPreview: PropTypes.func.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  parentNode: PropTypes.shape({}), // Needed for dndManager
  isDragging: PropTypes.bool.isRequired,
  didDrop: PropTypes.bool.isRequired,
  draggedNode: PropTypes.shape({}),
  // Drop target
  isOver: PropTypes.bool.isRequired,
  canDrop: PropTypes.bool,

  // rtl support
  rowDirection: PropTypes.string,
};

export default NodeRendererDefault;
