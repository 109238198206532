import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { Typography } from "@material-ui/core";
import Loader from "components/common/stuff/Loader";
import CircularProgress from "@mui/material/CircularProgress";
// import DialogTitle from "@material-ui/core/DialogTitle";

export default function ModalApproveComponent(props) {
  const { open, handleClose, text, action, Icon, yes, color, loading, load } =
    props;
  return (
    <div>
      {/* {load && <Loader />} */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent
          style={{
            height: "150px",
            width: "400px",
            marginTop: "30px",
            textAlign: "center",
          }}
        >
          <DialogContentText id="alert-dialog-description">
            {Icon && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {Icon}
              </div>
            )}
            <Typography variant="body2">{text}</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            size="small"
            onClick={handleClose}
            color="primary"
            autoFocus
          >
            No
          </Button>

          <div>
            <Button
              style={{ color: "white", position: "relative" }}
              variant="contained"
              size="small"
              disabled={load}
              onClick={action}
              color="primary"
            >
              {yes}{" "}
            </Button>
            {load && (
              <CircularProgress
                size={24}
                sx={{
                  position: "absolute",
                  // top: "50%",
                  // left: "50%",
                  marginTop: "5px",
                  marginLeft: "-65px",
                }}
              />
            )}
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
