import withSection from "components/Section/Section";
import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Paper,
  FilledInput,
  Checkbox,
  InputAdornment,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import CustomChips from "components/Chips/Chips";
import manageStyle from "styles/CommonStyles/manageScreenStyles";
import { useSnackbar } from "notistack";
import ViewTeams from "./ViewTeams";

const AppTeam = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    appTeamData,
    handleSelectTeam,
    getTeamsData,
    appId,
    msg,
    success,
    historyTeamData,
    setClickedTeam,
    actions,
    editDisable,
  } = props;
  const { teams, selectedTeams, clickedTeam } = appTeamData || {
    teams: [],
    selectedTeams: [],
    clickedTeam: { users: [] },
  };
  const styles = manageStyle();
  const [teamSearchInput, setTeamSearchInput] = useState(null);
  // const [selectedTeams, setSelectedTeams] = useState([]);
  const [teamLoader, setTeamLoader] = useState(false);
  // const [teams, setTeams] = useState([]);
  const [error, setError] = useState({
    name: { isError: false },
    languages: { isError: false },
  });

  useEffect(() => {
    if (msg !== null && msg !== undefined) {
      console.log("shsg643674hvjbgj9", success, msg);
      enqueueSnackbar(msg || "Network issue", {
        variant: success ? "success" : "error",
      });
    }
  }, [msg]);

  useEffect(() => {
    if (actions === "FETCH_APPLICATION_DETAILS_SUCCESS") {
      // console.log(historyTeamData, "app teamszzzzzzzzz");
      const team_ = historyTeamData || [];
      console.log("historyTeamData", team_);
      getTeamsData(JSON.stringify({}), team_);
    }
  }, [historyTeamData]);

  const handleTeamSearch = (searchInput) => {
    if (searchInput) {
      getTeamsData(
        JSON.stringify({ name: { $regex: `^${searchInput}`, $options: "i" } }),
        selectedTeams
      );
    } else {
      getTeamsData({}, selectedTeams);
    }
  };
  const handleSelectedTeamDelete = (teamToDelete) => {
    handleSelectTeam(selectedTeams, teamToDelete, teams, false, appId);
  };
  // console.log(
  //   clickedTeam,
  //   teams,
  //   historyTeamData,
  //   selectedTeams,
  //   "Teams app details"
  // );
  return (
    <div>
      {editDisable && (
        <>
          {editDisable && (
            <FilledInput
              name="teamSearchInput"
              value={teamSearchInput}
              onChange={(e) => {
                setTeamSearchInput(e.target.value);
                handleTeamSearch(e.target.value);
              }}
              className={`${styles.input} ${styles.searchInput2} ${
                styles.customSearchInput
              } ${
                error.teams?.isError &&
                selectedTeams.length === 0 &&
                styles.errorSearch
              }`}
              placeholder="Search"
              autoComplete="off"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton edge="end">
                    <img src="/images/search.svg" alt="card" />
                  </IconButton>
                </InputAdornment>
              }
            />
          )}
          {/* {error.teams?.isError && selectedTeams.length === 0 && (
          <p className={styles.errorMsg}>{"Teams is not selected"}</p>
        )} */}

          <CustomChips
            isEditMode={!editDisable}
            chipsData={selectedTeams || []}
            handleDelete={handleSelectedTeamDelete}
          />
          <br />
          {teamLoader && <CircularProgress color="primary" />}
          <Grid container spacing={5}>
            <Grid
              item
              xs={6}
              sm={6}
              style={{ borderRight: "1px solid rgba(0, 40, 85, .4)" }}
            >
              <div
                className={styles.section}
                style={{
                  opacity: 1,
                  maxHeight: "25rem",
                  overflow: "auto",
                  padding: "0 1rem 1rem 0",
                }}
              >
                <Grid container spacing={3}>
                  {teams.length > 0 &&
                    teams
                      .filter((team) => team.block_status === "false")
                      .map((team, index) => (
                        <Grid item xs={12} sm={12} key={index}>
                          <Paper
                            className={styles.paper}
                            onClick={() => setClickedTeam(team)}
                            style={{ cursor: "pointer" }}
                          >
                            <div className={styles.spaceBetweenText}>
                              <Typography variant="body1">
                                {team.name}
                              </Typography>
                              <div className={styles.technologyCheck}>
                                <Typography variant="body1">
                                  {team.org}
                                </Typography>
                                <Checkbox
                                  disabled={!editDisable}
                                  color="primary"
                                  checked={
                                    team.checked || false
                                    // selectedTeams.find(
                                    //   (item) => item._id === team._id
                                    // )
                                    //   ? true
                                    //   : false
                                  }
                                  className={styles.teamCheckbox}
                                  onChange={(e) =>
                                    handleSelectTeam(
                                      selectedTeams,
                                      team,
                                      teams,
                                      e.target.checked,
                                      appId
                                    )
                                  }
                                  name={team.name}
                                />
                              </div>
                            </div>
                          </Paper>
                        </Grid>
                      ))}
                </Grid>
              </div>
            </Grid>
            <Grid item xs={6} sm={6}>
              <div className={styles.section}>
                <Grid container spacing={3}>
                  {clickedTeam?.users.map((member, index) => (
                    <Grid item xs={6} sm={6} key={index}>
                      <Paper className={styles.paper}>
                        <div className={styles.teamWrapper}>
                          {member?.image ? (
                            <img
                              src={member?.image}
                              className={styles.memberProfile}
                              alt="profile"
                            />
                          ) : (
                            <img
                              src="/images/users/default.svg"
                              alt="profile"
                            />
                          )}
                          <div className={styles.teamMember}>
                            <Typography
                              variant="body1"
                              className={styles.teamMemberName}
                            >
                              {member?.name}
                            </Typography>
                            <Typography
                              variant="body2"
                              className={styles.teamMemberDesgination}
                            >
                              {member?.desgination}
                            </Typography>
                          </div>
                        </div>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </div>
            </Grid>
          </Grid>
        </>
      )}
      {!editDisable && (
        <div>
          <ViewTeams
            projectTeams={historyTeamData || []}
            {...props}
            appId={appId}
          />
        </div>
      )}
      <br />
      <br />
    </div>
  );
};
export default withSection(AppTeam, "Team");
