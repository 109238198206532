import { React, useState, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import Avatar from "@material-ui/core/Avatar";
import StarIcon from "@material-ui/icons/Star";
import Divider from "@material-ui/core/Divider";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import ShareIcon from "@material-ui/icons/Share";
import DeviceHubIcon from "@material-ui/icons/DeviceHub";
import ContextDialog from "./ContextDialog";
import DexTable from "components/DexTable/DexTable";
import AddIcon from "@material-ui/icons/Add";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import { ApplicationDetailsUpadateHiddenCol } from "../ApplicationDetails/Redux/action";
import {
  IconButton,
  Tooltip,
  CardContent,
  CardHeader,
  FormGroup,
  Card,
  Grid,
  Button,
  TextField,
  Typography,
  FormLabel,
  Menu,
  MenuItem,
} from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import withSection from "components/Section/Section";
import { RiEditLine, RiDeleteBin5Line } from "react-icons/ri";
import WrappedDexTable from "components/DexTable/WrappedDexTable";
import helpers, { dltMsg } from "../../../helpers/helpers";
import { checkPermission } from "helpers/permissons";
import { confirmDialog } from "components/DeleteConfirmationDialog/Index";
const styles = (length) => {
  let obj = {
    fontSize: "20px",
    color: length <= 1 ? "#bdbdbd" : "",
    cursor: length <= 1 ? "text" : "pointer",
  };
  return obj;
};

const useStyles = makeStyles((theme) => ({
  line: {
    marginTop: -23,
  },

  button: {
    padding: "4px 57px",
  },

  dividerline: {
    // border: "1px solid black",
    width: "70%",
    margin: "-25px 0px 0px 125px",
    [theme.breakpoints.up("md")]: {
      width: "82%",
      margin: "-25px 0px 0px 260px",
    },
  },
}));
// const TableCell = withStyles((theme) => ({
//   body:{
//     padding:"2px,20px,2px,20px"
//   }
// }))(TableCell);

const StyledMenu = withStyles({})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({}))(MenuItem);

const CustomTableRow = (props) => {
  const { appId, row, appName } = props;

  const classes = useStyles();
  const [edit, setEdit] = useState(row._id == undefined || false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [payload, setPayload] = useState(row);
  const onChange = (e) => {
    let newpayload = { ...payload };
    newpayload[e.currentTarget.name] = e.currentTarget.value;
    console.log(newpayload);
    setPayload(newpayload);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const editAPI = (row, e) => {
    e && e.stopPropagation();
    checkPermission("btn132") &&
      props.history.push("/app/create-api-form", {
        apiData: row,
        edit: true,
        ajax: true,
        appID: appId,
        appName: appName,
        disabled: e ? false : true,
      });
  };

  return (
    <TableRow onClick={() => editAPI(row)}>
      <TableCell align="left">
        <Typography variant="body2">{row.name}</Typography>
      </TableCell>

      <TableCell align="left" style={{}}>
        <Typography variant="body2">
          {row.apiType !== "MessageBroker" ? row.url : row?.source?.topic || ""}
        </Typography>
      </TableCell>
      <TableCell align="left">
        {row.apiType !== "MessageBroker" && (
          <Typography variant="body2">{row.method}</Typography>
        )}
      </TableCell>
      <TableCell align="center"></TableCell>
    </TableRow>
  );
};

const StyledTableRow = withStyles((theme) => ({}))(TableRow);

function ApplicationAPI(props) {
  const {
    appId,
    data,
    editApplicationAPI,
    deleteApplicationAPI,
    routeToCDUMap,
    publishApi,
    appStatus,
    appTable,
    appName,
  } = props;
  const { apiHidden } = appTable;
  const classes = useStyles();

  let [rows, setRows] = useState([...data]);
  useEffect(() => {
    setRows([...(data || [])]);
  }, [data]);

  const pushToCreateForm = () => {
    let data = {
      name: "",
      apiType: "",
      url: "",
      method: "",
      deprication_date: "",
      source: {
        cluster: "",
        topic: "",
        username: "",
        password: "",
      },
    };
    checkPermission("btn231") &&
      props.history.push("/app/create-api-form", {
        apiData: data,
        edit: false,
        ajax: true,
        appID: appId,
        appName: appName,
      });
  };

  const editAPI = (row, e) => {
    e && e.stopPropagation();
    checkPermission("btn132") &&
      props.history.push("/app/create-api-form", {
        apiData: row,
        edit: true,
        ajax: true,
        appID: appId,
        disabled: e ? false : true,
        appName: appName,
      });
  };
  const actionProvider = ({ row }) => {
    return (
      <>
        <Grid container spacing={2}>
          <Grid item>
            <IconButton size="small" color="inherit">
              <ContextDialog
                style={{ fontSize: "20px" }}
                appId={appId}
                row={row}
              />
            </IconButton>
          </Grid>
          <Grid item>
            <Tooltip title="Edit" aria-label="edit">
              <IconButton
                size="small"
                color="inherit"
                disabled={!checkPermission("btn132")}
              >
                <RiEditLine
                  style={{ fontSize: "20px" }}
                  onClick={(e) => {
                    checkPermission("btn132") && editAPI(row, e);
                  }}
                />
              </IconButton>
            </Tooltip>
          </Grid>
          {/* <Grid item>
            <Tooltip title="CDU Mapping" aria-label="add">
              <IconButton color="primary">
                <DeviceHubIcon
                  onClick={() => {
                    props.routeToCDUMap(appId, row);
                  }}
                />
              </IconButton>
            </Tooltip>
          </Grid> */}
          {props.appStatus === "Published" && (
            <Grid item>
              <Tooltip title="Publish" aria-label="publish">
                <IconButton
                  size="small"
                  color="inherit"
                  disabled={!checkPermission("btn200")}
                >
                  <SendIcon
                    style={{ fontSize: "20px" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      checkPermission("btn200") && publishApi(appId, row);
                    }}
                  />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
          <Grid item>
            {/* {props.length > 1 && ( */}

            <Tooltip title="Delete" aria-label="delete">
              <IconButton
                color="inherit"
                size="small"
                disabled={!checkPermission("btn100")}
                onClick={(e) => {
                  e.stopPropagation();
                  if (checkPermission("btn100")) {
                    confirmDialog(dltMsg(row?.name || ""), () => {
                      deleteApplicationAPI(appId, row._id);
                    });
                  }
                }}
                // disabled={props.length <= 1 ? true : false}
              >
                <RiDeleteBin5Line
                  color="inherit"
                  // styles={helpers.disableDeleteFnc(props.length)}
                  // style={styles(props.length)}
                  fontSize="20px"
                />
              </IconButton>
            </Tooltip>
            {/* )} */}
          </Grid>
        </Grid>
      </>
    );
  };

  const urlOrTopicProvider = ({ row }) => {
    console.log("gsadjgjhsafjhasfhg", row.url);
    return (
      <>
        <span style={{ textTransform: "none" }}>
          {" "}
          {row.apiType !== "MessageBroker" ? row.url : row?.source?.topic || ""}
        </span>
      </>
    );
  };

  const methodProvider = ({ row }) => {
    return (
      <>
        <span style={{ textTransform: "none" }}>{row.method}</span>
      </>
    );
  };

  return (
    <div id="appliation-apis-section">
      <Grid container spacing={2}>
        <Grid
          item
          md={12}
          container
          alignItems="center"
          justifyContent="flex-end"
        >
          {/* <Tooltip title="Add" aria-label="add"> */}
          <Button
            size="small"
            variant="contained"
            onClick={() => checkPermission("btn231") && pushToCreateForm()}
            startIcon={<AddIcon />}
            className={classes.button}
            disabled={!checkPermission("btn231")}
          >
            Create API
          </Button>
          {/* </Tooltip> */}
        </Grid>
        <Grid item md={12}>
          <WrappedDexTable
            data={rows}
            // hideColumnChooser={true}
            columWidthsType="percentage"
            columnOrder={["name", "url", "method", "actions"]}
            columns={[
              { name: "name", title: "Name" },
              { name: "url", title: "URL/Topic" },
              { name: "method", title: "Method" },
              { name: "actions", title: "Actions" },
            ]}
            tableType="virtual"
            defaultColumnWidths={[
              { columnName: "name", width: 10 },
              { columnName: "url", width: 58 },
              { columnName: "method", width: 10 },
              { columnName: "actions", width: 20 },
            ]}
            disableReordering={true}
            pagination={false}
            Read={checkPermission("btn224")}
            formatters={[
              { formatterFn: actionProvider, columns: ["actions"] },
              {
                formatterFn: urlOrTopicProvider,
                columns: ["url"],
              },
              {
                formatterFn: methodProvider,
                columns: ["method"],
              },
            ]}
            hidden={[...apiHidden]}
            setHiddenColmn={(hidden) => {
              props.setHiddenColumn(hidden, "apiHidden");
            }}

            // tableRow={(p) => {
            //   return (
            //     <CustomTableRow
            //       appId={appId}
            //       length={rows.length}
            //       history={props.history}
            //       removeRows={removeRows}
            //       onChange={onChange}
            //       addApplicationAPI={addApplicationAPI}
            //       editApplicationAPI={editApplicationAPI}
            //       deleteApplicationAPI={deleteApplicationAPI}
            //       publishApi={publishApi}
            //       routeToCDUMap={routeToCDUMap}
            //       addNewRow={
            //         p.tableRow.rowId == rows.length - 1 && (
            //           <Tooltip title="Add New API" aria-label="add">
            //             <IconButton color="primary">
            //               <AddCircleOutlineOutlinedIcon
            //                 color="secondary"
            //                 onClick={addRows}
            //               />
            //             </IconButton>
            //           </Tooltip>
            //         )
            //       }
            //       {...p}
            //       {...props}
            //     />
            //   );
            // }}
          />
        </Grid>
      </Grid>
    </div>
  );
}
const mapDispatchToProps = (dispatch) => ({
  setHiddenColumn: (hidden, type) =>
    dispatch(ApplicationDetailsUpadateHiddenCol(hidden, type)),
});

const mapStateToProps = (state) => {
  return {
    appTable: state.appDetailsRedcer.appTable,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withSection(ApplicationAPI, "API")));
