/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { useSnackbar } from "notistack";
import { TextValidator } from "react-material-ui-form-validator";
import Dialoguemodal from "../BillingMaster/Dialoguemodal";

const filter = createFilterOptions();

export default function AddCategoryBilling(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [getvalue, setValue] = useState({ name: "" });
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setValue(props.obj);
  }, [props.obj]);

  const add_bill_category = async () => {
    let { categoryId, _msg, success } = await props.addCategory(getvalue.name);
    if (success) {
      props.onChange({ ...props.billingData, category: categoryId });
    } else {
      setValue(null);
      enqueueSnackbar(_msg, {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
    setOpen(false);
  };

  const openModal = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setValue(null);
    setOpen(false);
  };

  const selectCategory = (value) => {
    setValue(value);
    if (value) {
      setValue({ name: value.name });
      props.onChange({ ...props.billingData, category: value._id });
    } else {
      props.onChange({
        ...props.billingData,
        category: "",
      });
    }
  };
  console.log(props.defaultCategory, "ghchgsfh");
  return (
    <div>
      <Autocomplete
        disabled={props.isDisabled}
        fullWidth
        style={{ width: "100%" }}
        size="small"
        value={getvalue}
        onChange={(event, newValue) => {
          if (typeof newValue === "string") {
            setValue({
              name: newValue,
            });
            openModal();
          } else if (newValue && newValue.inputValue) {
            openModal();
            setValue({
              name: newValue.inputValue,
            });
          } else {
            // setValue(newValue);
            selectCategory(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          // Suggest the creation of a new value
          if (params.inputValue !== "") {
            filtered.push({
              inputValue: params.inputValue,
              name: `Add "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        options={props.defaultCategory}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.name;
        }}
        renderOption={(option) => option.name}
        freeSolo
        renderInput={(params) => (
          <TextValidator
            {...params}
            variant="outlined"
            value={getvalue ? getvalue.name : ""}
            validators={["required"]}
            errorMessages={["Please select category"]}
          />
        )}
      />
      <Dialoguemodal
        open={open}
        handleClose={handleClose}
        add={add_bill_category}
        text={getvalue}
      />
    </div>
  );
}
