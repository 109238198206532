import React from "react";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";

import Typography from "@mui/material/Typography";

import Chip from "@mui/material/Chip";

export default function SidePanel() {
  return (
    <Paper
      sx={{ width: 300, maxWidth: "100%" }}
      style={{ height: "400px", padding: 8 }}
    >
      <Typography variant={"h5"} style={{ marginBottom: 10 }}>
        Operations
      </Typography>
      <Divider />
      <MenuList>
        <MenuItem>
          <Chip
            draggable
            label="Concatenate"
            onDragStart={(e) => {
              e.dataTransfer.setData("nodeType", "concat");
            }}
            style={{
              background: "#e91e63",
              height: "50px",
              width: "200px",
              color: "white",
              fontSize: "16px",
            }}
          />
        </MenuItem>
        {/* <MenuItem>
          <Chip 
            draggable 
            onDragStart = {(e)=>{
              e.dataTransfer.setData("nodeType", "join");
            }}
            label="Join"  style ={{
              background: "#00a0b2",
              height: "50px",
              width: "200px",
              color: "white",
              fontSize: "20px"
            }} />
        </MenuItem> */}
        <MenuItem>
          <Chip
            draggable
            onDragStart={(e) => {
              e.dataTransfer.setData("nodeType", "filter");
            }}
            label="Filter"
            style={{
              background: "#ff5722",
              height: "50px",
              width: "200px",
              color: "white",
              fontSize: "16px",
            }}
          />
        </MenuItem>
        <MenuItem>
          <Chip
            draggable
            onDragStart={(e) => {
              e.dataTransfer.setData("nodeType", "split");
            }}
            label="Split"
            style={{
              background: "#ffa726",
              height: "50px",
              width: "200px",
              color: "white",
              fontSize: "16px",
            }}
          />
        </MenuItem>
        <MenuItem>
          <Chip
            draggable
            label="Expression"
            onDragStart={(e) => {
              e.dataTransfer.setData("nodeType", "expression");
            }}
            style={{
              background: "#38929B",
              height: "50px",
              width: "200px",
              color: "white",
              fontSize: "16px",
            }}
          />
        </MenuItem>
      </MenuList>
    </Paper>
  );
}
