export const FETCHING_CREDIT_DEBIT_INVOICE = "FETCHING_CREDIT_DEBIT_INVOICE";
export const FETCHING_CREDIT_DEBIT_INVOICE_SUCCESS =
  "FETCHING_CREDIT_DEBIT_INVOICE_SUCCESS";
export const FETCHING_CREDIT_DEBIT_INVOICE_FAILURE =
  "FETCHING_CREDIT_DEBIT_INVOICE_FAILURE";

export const ADD_CREDITNOTES = "ADD_CREDITNOTES";
export const ADD_CREDITNOTES_SUCCESS = "ADD_CREDITNOTES_SUCCESS";
export const ADD_CREDITNOTES_FAILURE = "ADD_CREDITNOTES_FAILURE";

export const GET_CREDITNOTES = "GET_CREDITNOTES";
export const GET_CREDITNOTES_SUCCESS = "GET_CREDITNOTES_SUCCESS";
export const GET_CREDITNOTES_FAILURE = "GET_CREDITNOTES_FAILURE";
//fecth invoice details by id
export const FETCHING_INVOICE_DETAILS_BY_ID = "FETCHING_INVOICE_DETAILS_BY_ID";
export const FETCH_INVOICE_DETAILS_BY_ID_SUCCESS =
  "FETCH_INVOICE_DETAILS_BY_ID_SUCCESS";
export const FETCH_INVOICE_DETAILS_BY_ID_FAILED =
  "FETCH_INVOICE_DETAILS_BY_ID_FAILED";
export const UPDATE_CREDIT_DEBIT_HIDDEN_COL="UPDATE_CREDIT_DEBIT_HIDDEN_COL";
export const UPDATE_GRAPH_HIDDEN_COL="UPDATE_GRAPH_HIDDEN_COL";
export const LOAD_LOADING = "LOAD_LOADING";