import { React, useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircle from "@material-ui/icons/AddCircleOutlineOutlined";
import withSection from "components/Section/Section";
import DexTable from "components/DexTable/DexTable";
import { useSnackbar } from "notistack";
import { checkPermission } from "helpers/permissons";
import {
  IconButton,
  TextField,
  TableContainer,
  TableBody,
  Table,
  TableHead,
  Tooltip,
  Grid,
  Button,
} from "@material-ui/core";

import AddIcon from "@material-ui/icons/Add";
import { RiDeleteBin5Line, RiEditLine } from "react-icons/ri";
import WrappedDexTable from "components/DexTable/WrappedDexTable";

import { useEffect } from "react";
import { confirmDialog } from "components/DeleteConfirmationDialog/Index";
import { dltMsg } from "helpers/helpers";

import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import { ApplicationOnboardUpadateHiddenCol } from "../ApplicationDetails/Redux/action";
const useStyles = makeStyles((theme) => ({
  table: {
    maxWidth: "99%",
  },
}));

const StyledTableCell = withStyles((theme) => ({}))(TableCell);
const StyledTableRow = withStyles((theme) => ({}))(TableRow);

function ApplicationModules(props) {
  const {
    applicationModule,
    handleSetColor,
    errValue,
    setApplicationModule,
    duplicate,
    modules,
    permission,
    onBoardTable,
  } = props;
  const { moduleHide } = onBoardTable;
  const [status, setStatus] = useState(false);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    setApplicationModule([...modules]);
  }, [modules]);

  useEffect(() => {
    handleSetColor(errValue.module ? "red" : "inherit");
  }, [handleSetColor, errValue]);

  const handleOnchange = (id, value) => {
    console.log({ id, value });
    applicationModule[id] = value;
    setApplicationModule([...applicationModule]);
  };

  const addApplicationModule = () => {
    let updateApplicationModule = [...applicationModule];
    let createModule = {
      name: "",
      description: "",
    };
    updateApplicationModule.push(createModule);
    setApplicationModule([...updateApplicationModule]);
  };

  const removeApplicationModule = async ({ row }) => {
    let flag = false;
    props.billingData.map((item, id) =>
      item.moduleName === row.name ? (flag = true) : null
    );
    if (!flag) {
      let data = [...modules];
      let filteredUsageParams = data.filter((item, index) => {
        return item.key !== row.key;
      });
      await props.deleteModule(filteredUsageParams);
    } else {
      enqueueSnackbar("Module is used in Billing Service", {
        variant: "error",
      });
    }
  };

  let error = false;
  const setDuplicateNull = () => {
    let handleDuplicate = [...props.duplicate];
    handleDuplicate[0] = [];
    props.setDuplicate([...handleDuplicate]);
  };

  const pushToCreateForm = () => {
    let data = {
      name: "",
      description: "",
      key: modules.length,
    };
    props.history.push("/app/create-modules-form", {
      moduleData: data,
      edit: false,
    });
  };

  const editApplicationModule = ({ row }) => {
    console.log(row, "this is edit", 23);
    props.history.push("/app/create-modules-form", {
      moduleData: row,
      edit: true,
    });
  };

  const actionProvider = (row) => {
    console.log(row, "3436436");
    return (
      <div
        style={{
          display: "flex",
        }}
      >
        <div style={{ marginRight: 20 }}>
          <Tooltip title="Edit" aria-label="edit">
            <IconButton
              size="small"
              color="inherit"
              disabled={!checkPermission("btn77")}
              onClick={() =>
                checkPermission("btn77") && editApplicationModule(row)
              }
            >
              <RiEditLine fontSize="20px" />
            </IconButton>
          </Tooltip>
        </div>
        <div>
          <Tooltip title="Delete" aria-label="delete">
            <IconButton
              disabled={!checkPermission("btn83")}
              size="small"
              color="inherit"
              onClick={() =>
                checkPermission("btn83") &&
                confirmDialog(dltMsg(row?.row?.name || ""), () =>
                  removeApplicationModule(row)
                )
              }
            >
              <RiDeleteBin5Line fontSize="20px" />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    );
  };

  console.log(
    "this is application module",
    applicationModule,
    props.duplicate,
    checkPermission("btn216")
  );
  return (
    <div>
      <Grid container spacing={2}>
        <Grid xs={12} item container justifyContent="flex-end">
          <Button
            size="small"
            variant="contained"
            onClick={() => checkPermission("btn71") && pushToCreateForm()}
            startIcon={<AddIcon />}
            style={{ padding: "4px 43px 4px 43px" }}
            disabled={!checkPermission("btn71")}
          >
            Create Modules
          </Button>
        </Grid>
        <Grid item xs={12}>
          <WrappedDexTable
            data={[...applicationModule]}
            // hideColumnChooser={true}
            columWidthsType="percentage"
            columnOrder={["name", "description", "actions"]}
            columns={[
              { name: "name", title: "Module Name" },
              { name: "description", title: "Description" },
              { name: "actions", title: "Actions" },
            ]}
            formatters={[{ formatterFn: actionProvider, columns: ["actions"] }]}
            defaultColumnWidths={[
              { columnName: "name", width: 37 },
              { columnName: "description", width: 47 },
              { columnName: "actions", width: 14 },
            ]}
            hidden={[...moduleHide]}
            setHiddenColmn={(hide) => {
              props.setHiddenColumn(hide, "moduleHide");
            }}
            pagination={false}
            noDataMsg="Modules not found"
            Read={checkPermission("btn216")}
          />
        </Grid>
      </Grid>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setHiddenColumn: (hide, type) =>
    dispatch(ApplicationOnboardUpadateHiddenCol(hide, type)),
});

const mapStateToProps = (state) => {
  return {
    onBoardTable: state.appDetailsRedcer.onBoardTable,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withSection(ApplicationModules, "Modules *")));
