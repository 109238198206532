export const FETCH_PRICING_MASTER = "FETCH_PRICING_MASTER";
export const FETCH_PRICING_MASTER_SUCCESS = "FETCH_PRICING_MASTER_SUCCESS";
export const FETCH_PRICING_MASTER_FAILURE = "FETCH_PRICING_MASTER_FAILURE";

export const EDIT_PRICING_MASTER = "EDIT_PRICING_MASTER";
export const EDIT_PRICING_MASTER_SUCCESS = "EDIT_PRICING_MASTER_SUCCESS";
export const EDIT_PRICING_MASTER_FAILURE = "EDIT_PRICING_MASTER_FAILURE";

export const DELETE_PRICING_MASTER = "DELETE_PRICING_MASTER";
export const DELETE_PRICING_MASTER_SUCCESS = "DELETE_PRICING_MASTER_SUCCESS";
export const DELETE_PRICING_MASTER_FAILURE = "DELETE_PRICING_MASTER_FAILURE";

export const LOAD_LOADING = "LOAD_LOADING";

export const UPDATE_PRICING_FILTER_DATA = "UPDATE_PRICING_FILTER_DATA";
export const CLEAR_PRICING_FILTER_DATA = "CLEAR_PRICING_FILTER_DATA";

export const UPDATE_PRICINGMASTER_SORT = "UPDATE_PRICINGMASTER_SORT";

export const UPDATE_PM_HIDDEN_COL="UPDATE_PM_HIDDEN_COL";
