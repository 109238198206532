/**
 * import all actions
 */

import * as actions from "./actionTypes";
import pricingMasterAPIS from "api/PricingMasterAPIs/index";

function actionFetchPricingMaster(pageno, size, filters, sort) {
  console.log({ actions });
  return async (dispatch) => {
    dispatch({
      type: actions.FETCH_PRICING_MASTER,
      loading: true,
    });
    try {
      let res = await pricingMasterAPIS.fetchAllPricingMasterWithParams(
        pageno,
        size,
        filters,
        sort
      );
      dispatch({
        type: actions.FETCH_PRICING_MASTER_SUCCESS,
        loading: false,
        totalCount: res.totalCount,
        data: res.data || [],
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: actions.FETCH_PRICING_MASTER_FAILURE,
        loading: false,
        error: err,
      });
    }
  };
}

function deletePricingMaster(_id) {
  return async (dispatch) => {
    dispatch({
      type: actions.DELETE_PRICING_MASTER,
      loading: true,
    });
    try {
      let deldata = await pricingMasterAPIS.deletePricingMaster(_id);
      dispatch({
        type: actions.DELETE_PRICING_MASTER_SUCCESS,
        loading: false,
        deldata,
      });
      // dispatch();
    } catch (err) {
      dispatch({
        type: actions.DELETE_PRICING_MASTER_FAILURE,
        loading: false,
        error: err,
      });
    }
  };
}
function editPricingMaster(_id, payload) {
  return async (dispatch) => {
    dispatch({
      type: actions.EDIT_PRICING_MASTER,
      loading: true,
    });
    try {
      let editdata = await pricingMasterAPIS.editPricingMaster(_id, payload);
      dispatch({
        type: actions.EDIT_PRICING_MASTER_SUCCESS,
        loading: false,
        editdata,
      });
    } catch (err) {
      dispatch({
        type: actions.EDIT_PRICING_MASTER_FAILURE,
        loading: false,
        error: err,
      });
    }
  };
}

function loadingDispatch(bool) {
  return (dispatch) => {
    dispatch({
      type: actions.LOAD_LOADING,
      payload: bool,
    });
  };
}

const updateFilterData = ({ filter, filterArray }) => ({
  type: actions.UPDATE_PRICING_FILTER_DATA,
  payload: { filter, filterArray },
});

const updateSort = ({ sorting, sort }) => ({
  type: actions.UPDATE_PRICINGMASTER_SORT,
  payload: { sorting, sort },
});
const updatePricingMasterHiddenCol =(hidden)=>({
type:actions.UPDATE_PM_HIDDEN_COL,
payload:hidden
})
export default {
  actionFetchPricingMaster,
  deletePricingMaster,
  editPricingMaster,
  loadingDispatch,
  updateFilterData,
  updateSort,
  updatePricingMasterHiddenCol
};
