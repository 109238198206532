import React from "react";
import {
  Typography,
  Grid,
  Button,
  Divider,
  TextField,
  FormControl,
  Select,
  IconButton,
  Tooltip,
  Card,
} from "@material-ui/core/";
import DexTable from "components/DexTable/DexTable";
import { RiEditLine, RiDeleteBin5Line } from "react-icons/ri";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import { withSnackbar } from "notistack";
import Styles from "../Styles";
import { withStyles } from "@material-ui/styles";
import { connect } from "react-redux";
import { checkPermission } from "helpers/permissons";
import { updateValueMapHiddenCol } from "../redux/action";
const ValueMapTable = (props) => {
  const { valdata, removeValueMap, hidden } = props;
  const { row } = removeValueMap;

  const history = useHistory();
  // console.log(removeValueMap, row, "=============hgasjasjdh");
  // console.log(row, "=============h11111gasjasjdh");
  const actionProvider = ({ row }) => {
    return (
      <div
        style={{
          display: "flex",
        }}
      >
        {props.isDisabled && (
          <div style={{ marginRight: 20 }}>
            <Tooltip title="Edit" aria-label="edit">
              <IconButton
                color="inherit"
                disabled={!checkPermission("btn66")}
                onClick={(e) => props.editValueMap(row, e)}
              >
                <RiEditLine fontSize="20px" />
              </IconButton>
            </Tooltip>
          </div>
        )}
        {props.isDisabled && (
          <div>
            <Tooltip title="Delete" aria-label="delete">
              <IconButton
                color="inherit"
                disabled={!checkPermission("btn67")}
                onClick={(e) => props.removeValueMap(row, e)}
              >
                <RiDeleteBin5Line fontSize="20px" />
              </IconButton>
            </Tooltip>
          </div>
        )}
      </div>
    );
  };
  const { loading } = props;

  // const viewValueMapdata = (row) => {
  //   editValueMap(row);
  // };

  return (
    // "_id": "6177b55f2f7471d2f7e5949f",
    // "description": "Deatils Desc",
    // "fieldName": "description",
    // "value": "Details"

    <div>
      <DexTable
        data={[...valdata]}
        columnOrder={["value", "description", "actions"]}
        columns={[
          { name: "value", title: "Value" },
          { name: "description", title: "Description" },
          { name: "actions", title: "Actions" },
        ]}
        columWidthsType="percentage"
        defaultColumnWidths={[
          { columnName: "value", width: 45 },
          { columnName: "description", width: 40 },
          { columnName: "actions", width: 10 },
        ]}
        formatters={[{ formatterFn: actionProvider, columns: ["actions"] }]}
        hidden={[...hidden]}
        // sorting={this.state.sorting}
        // onSortingChange={this.handleSortByChange}
        // disableReordering={false}
        loading={loading}
        // hideColumnChooser={true}
        tableType="virtual"
        // onClickRow={(row) => viewValueMapdata(row)}
        setHiddenColmn={(hidden) => {
          props.setHiddenColumn(hidden);
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    hidden: state.createDataStandards.hidden,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setHiddenColumn: (hidden) => dispatch(updateValueMapHiddenCol(hidden)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(Styles)(withSnackbar(ValueMapTable)));
