import * as actions from "./actionTypes";
import HelpDeskAPIS from "api/HelpDeskAPIs/index";
import StatusApi from "../../../api/StatusProfileAPIs/index";

// const fetch_All_status_profile = async () => {
//   try {
//     const res = await StatusApi.getStatusProfileData();

//   } catch (err) {

//   }
// };

function actionfetchAllHelpDeskData(pageno, size, filters, sort) {
  console.log({ actions });
  return async (dispatch) => {
    dispatch({
      type: actions.FETCH_HELPDESKDATA,
      loading: true,
    });
    try {
      let data = await HelpDeskAPIS.fetchAllHelpDeskData(
        pageno,
        size,
        filters,
        sort
      );
      dispatch({
        type: actions.FETCH_HELPDESKDATA_SUCCESS,
        loading: false,
        totalCount: data._totalcount,
        data: data.data || [],
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: actions.FETCH_HELPDESKDATA_FAILURE,
        loading: false,
        error: err,
      });
    }
  };
}

export const deleteHelpDeskData = (id) => async (dispatch) => {
  dispatch({
    type: actions.DELETE_HELPDESKDATA,
    loading: true,
  });
  try {
    let res = await HelpDeskAPIS.deleteHelpDeskData(id);
    dispatch({
      type: actions.DELETE_HELPDESKDATA_SUCCESS,
      loading: false,
    });
    return res;
    console.log(res, "001");
  } catch (err) {
    dispatch({
      type: actions.DELETE_HELPDESKDATA_FAILURE,
      loading: false,
    });
    console.log(err.response, "002");
    return err;
  }
};

const updateFilterData = ({ filter, filterArray }) => ({
  type: actions.UPDATE_HELPDESK_FILTER_DATA,
  payload: { filter, filterArray },
});

const updateSort = ({ sorting, sort }) => ({
  type: actions.UPDATE_HELPDESK_SORT,
  payload: { sorting, sort },
});

const udateHelpDeskHiddenCol=(hidden)=>({
  type:actions.UPDATE_HELPDESK_HIDDEN_COL,
  payload:hidden
})

export default {
  actionfetchAllHelpDeskData,
  updateFilterData,
  updateSort,
  udateHelpDeskHiddenCol
};
