/**
 * Sankey chart component.
 */
import React from "react";
import Sankey, { Tooltip, Link, Node, Label } from "devextreme-react/sankey";
import CustomizeLinkTooltip from "./Tooltip";

const CustomSankey = (props) => {
  const { graphData, sankeyData, toolTip } = props;

  // const setUpToolTip = (e) => {
  //   let connection = e.target.connection;
  //   let toolTipInfo = {};
  //   Object.keys(sankeyData || {}).map((r) => {
  //     if (
  //       sankeyData[r].source === connection.source &&
  //       sankeyData[r].destination === connection.target
  //     ) {
  //       toolTipInfo = sankeyData[r];
  //     }
  //   });

  //   const x = e.event.originalEvent.originalEvent.x;
  //   const y = e.event.originalEvent.originalEvent.y;
  //   setToolTip({
  //     ...toolTip,
  //     enabled: true,
  //     style: {
  //       display: "block",
  //       position: "absolute",
  //       left: x > 1000 ? 1000 : x,
  //       top: y,
  //     },
  //     toolTipInfo,
  //   });
  // };
  console.log("sankey chart props", graphData, sankeyData);

  const customizeText = (node) => {
    let stringArr = node.title.split("/");
    let name = stringArr[stringArr.length - 1];
    return `${node.title.split(":")[0]}/${name}`;
  };

  return (
    <>
      <Sankey
        id="sankey"
        dataSource={graphData}
        sourceField="source"
        targetField="target"
        weightField="weight"
        title=""
        onLinkClick={(e) => props.onClickLinkFunc(e)}
        onNodeClick={(e) => props.onClickNodeFunc(e)}
      >
        <Label customizeText={customizeText} />
        <Tooltip enabled={false}></Tooltip>
        <Link colorMode="gradient"></Link>
        <Node width={15} padding={20}></Node>
      </Sankey>
      <CustomizeLinkTooltip toolTip={toolTip} />
    </>
  );
};

export default CustomSankey;
