import * as actions from "./actionTypes";
import roleManagementAPIS from "api/RoleManagementAPIs/index";

function actionfetchAllUserManagementroles(pageno, size, filters, sort) {
  console.log({ actions });
  return async (dispatch) => {
    dispatch({
      type: actions.FETCHING_USER_ROLES,
      loading: true,
    });
    try {
      let data = await roleManagementAPIS.fetchAllUserManagementrolesWithParams(
        pageno,
        size,
        filters,
        sort
      );
      dispatch({
        type: actions.FETCHING_USER_ROLES_SUCCESS,
        loading: false,
        totalCount: data._totalcount,
        data: data.data || [],
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: actions.FETCHING_USER_ROLES_FAILURE,
        loading: false,
        error: err,
      });
    }
  };
}

function fetchMasterPermissions() {
  console.log({ actions });
  return async (dispatch) => {
    dispatch({
      type: actions.FETCHING_MASTER_PERMISSIONS,
      loading: true,
    });
    try {
      let res = await roleManagementAPIS.fetchMasterPermissions();
      console.log("role checking", res.data);
      dispatch({
        type: actions.FETCHING_MASTER_PERMISSIONS_SUCCESS,
        loading: false,
        rolesData: res.data.data || [],
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: actions.FETCHING_MASTER_PERMISSIONS_FAILURE,
        loading: false,
        error: err,
      });
    }
  };
}

const updateFilterData = ({ filter, filterArray }) => ({
  type: actions.UPDATE_ROLES_FILTER_DATA,
  payload: { filter, filterArray },
});

const updateSort = ({ sorting, sort }) => ({
  type: actions.UPDATE_ROLES_SORT,
  payload: { sorting, sort },
});

const updateHiddenTableCol = (hidden) => ({
  type: actions.UPDATE_HIDDEN_COL,
  payload: hidden,
});
export default {
  actionfetchAllUserManagementroles,
  fetchMasterPermissions,
  updateFilterData,
  updateSort,
  updateHiddenTableCol,
};
