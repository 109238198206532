import React, { Component } from "react";
import {
  Typography,
  Grid,
  Button,
  Divider,
  TextField,
  Avatar,
  IconButton,
  Tooltip,
} from "@material-ui/core/";
import Breadcrumb from "components/Breadcrumbs/Breadcrumb";
import StatusAction from "../StatusProfile/redux/action";
import {
  TextValidator,
  SelectValidator,
  ValidatorForm,
} from "react-material-ui-form-validator";
import { withSnackbar } from "notistack";
import moment from "moment";
import HelpDeskCommentSection from "./HelpDeskCommentSection";
import PropTypes from "prop-types";
import Styles from "./Styles.js";
import { withStyles } from "@material-ui/styles";
import { connect } from "react-redux";
import helpDeskAPIS from "api/HelpDeskAPIs/index";
import pricingMasterAPIS from "api/PricingMasterAPIs/index";
import { RiEditLine } from "react-icons/ri";
import HelpDeskImage from "./HelpDeskImage";
import actions from "./redux/action";
import { checkPermission } from "helpers/permissons";
import { Autocomplete } from "@material-ui/lab";
import helpers from "../../helpers/helpers";
class CreateHelpDesk extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      isEdit: false,
      ajax: false,
      err_msg: "",
      index: 0,
      isDisabled: false,
      allPublishedApp: [],
      modules: [],
      statusData: [],
      HelpdeskData: {
        issues: "",
        category: "",
        appId: "",
        module: "",
        image: [],
        type: "Admin",
      },
      comments: [],
      createdByIds: [],
      base64: "",
      files: 1,
      filesNew: [],
      droppedFiles: [],
      images: [],
      random: Math.random(),
      hosaImage: [],
      customerDetails: {},
      customerProfile: false,
      ExistImages: [],
    };
  }

  handleImg = (newFiles, e) => {
    const { HelpdeskData, ExistImages } = this.state;
    let tempFiles = [...HelpdeskData.image];
    newFiles.map((file) => {
      if (
        HelpdeskData.image.filter((f) => f.name === file.name).length === 0 &&
        ExistImages.filter((f) => f.filename === file.name).length === 0
      ) {
        tempFiles.push(file);
      }
    });
    if (tempFiles.length !== 0) {
      this.setState({
        HelpdeskData: { ...HelpdeskData, image: [...tempFiles] },
      });
    }
  };

  handleDeleteNewScreenShot = (image) => {
    let { HelpdeskData } = this.state;
    let modifiedFiles = HelpdeskData.image.filter(
      (img) => img.name !== image.name
    );
    this.setState({
      HelpdeskData: { ...HelpdeskData, image: modifiedFiles },
      random: Math.random(),
    });
    this.props.enqueueSnackbar("Deleted Image successfully", {
      variant: "success",
    });
  };

  handleDeleteImage = async (img) => {
    let helpdeskId = this.state.HelpdeskData._id;
    let imageName = img.filename;
    let res = await helpDeskAPIS.deleteImage(helpdeskId, imageName);
    if (res.data._status === 200) {
      const { ExistImages } = this.state;
      let modifiedFiles = ExistImages.filter(
        (im, id) => im.filename !== img.filename
      );
      this.setState({
        ExistImages: modifiedFiles,
      });
    }

    if (res.data && res.data._status === 200) {
      this.props.enqueueSnackbar(
        res.data.data._msg || "Image deleted successfully",
        {
          variant: "success",
          autoHideDuration: 2000,
        }
      );
    }

    // const { HelpdeskData } = this.state;
    // let modifiedFiles = HelpdeskData.image.filter((img, id) => index !== id);
    // let base64Img = this.state.images.filter((im, id) => id !== index);
    // console.log(modifiedFiles, "========================deleted");
    // this.setState({
    //   HelpdeskData: { ...HelpdeskData, image: [...modifiedFiles] },
    //   images: [...base64Img],
    // });

    // this.setState({
    //   random: Math.random(),
    // });
  };

  getModules = async (appId, id) => {
    let res = await pricingMasterAPIS.fetchModulesByApplicationId(appId);
    if (res.data.data) {
      this.setState({
        modules: [...res.data.data],
      });
    }
  };

  sendImageFormData = async (id, payload) => {
    console.log("updating helpdesk90", payload, id);
    try {
      let res = await helpDeskAPIS.sendImages_formData(id, payload);
      return true;
    } catch (err) {
      return false;
    }
  };

  updateHelpDeskIssues = async (id, payload) => {
    const { screenshots, ...otherData } = payload;
    console.log("updating helpdesk", payload, id);
    try {
      let res = await helpDeskAPIS.update_issues(id, otherData);
      return true;
    } catch (err) {
      return false;
    }
  };

  editData = async () => {
    let { HelpdeskData } = this.state;
    console.log("===========hell", HelpdeskData);
    let { image, _id, comment, ...data } = HelpdeskData;
    let formData = new FormData();

    for (let i in image) {
      formData.append("file", image[i]);
    }
    // formData.append("file", image);
    console.log(image, "jhbjjhabsd", formData, "hgjdgjgdjgdj675675e757r576t57");
    let updateImg = await this.sendImageFormData(_id, formData);
    let updateIssue = await this.updateHelpDeskIssues(_id, data);
    console.log("ghdfhfasdhg", image, updateIssue);
    if (updateIssue) {
      this.props.enqueueSnackbar("Issue has been updated successfully", {
        variant: "success",
        autoHideDuration: 2000,
      });
      this.props.history.push("/app/helpdesk");
    } else {
      this.props.enqueueSnackbar("Issue update failed", {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
    // try {
    //   const res = await helpDeskAPIS.editHelpDeskData(_id, ...data);

    //   this.props.enqueueSnackbar("Field Edited successfully", {
    //     variant: "success",
    //     autoHideDuration: 2000,
    //   });
    //   this.props.history.push("/app/helpdesk");
    // } catch (err) {
    //   this.props.enqueueSnackbar("Something Went wrong", {
    //     variant: "error",
    //     autoHideDuration: 2000,
    //   });
    // }
  };

  // adding -

  // issue/{issueid}

  // for meta- issue/{id}/update

  statusProvider = ({ row }) => {
    const { status_data } = this.props;
    console.log(status_data, "status_data");
    let color = "";
    let status_name = "";
  };

  addData = async () => {
    let { HelpdeskData } = this.state;
    const { status_data } = this.props;
    let dstatus = "";
    status_data.map((item, id) => {
      if (item.name === "Helpdesk") {
        let statusArr = item.status || [];
        let s_arry = statusArr.filter((f) => f.default);
        // .map((item, id) => item.status);
        dstatus = s_arry[0].status;
        console.log(s_arry, "s_arrrrrrrrrrrrrrrrrrrry");
      }
    });
    console.log("status_dataaaaaaa", status_data);
    let { image, ...data } = HelpdeskData;
    console.log("helpdeskkkkdata", HelpdeskData, data, dstatus);
    data["status"] = dstatus;
    let formData = new FormData();
    formData.append("meta", JSON.stringify({ ...data }));
    image.map((row, id) => {
      formData.append("file", row);
    });

    console.log("==========help", image, formData);

    try {
      const res = await helpDeskAPIS.addHelpDeskData(formData);
      console.log("response", res);
      this.props.enqueueSnackbar(res._msg || "Field Created successfully", {
        variant: "success",
        autoHideDuration: 2000,
      });

      this.setState({
        loading: false,
      });
      this.props.history.push("/app/helpdesk");
    } catch (err) {
      this.props.enqueueSnackbar(
        err.response.data._msg || "Something Went wrong",
        {
          variant: "error",
          autoHideDuration: 2000,
        }
      );
    }
  };

  onSubmitTask = (e) => {
    const { isEdit } = this.state;
    if (e.nativeEvent.submitter.name === "single") {
      this.setState({
        loading: true,
      });

      isEdit ? this.editData() : this.addData(true);
    }
  };

  fetch_image = async (id, name) => {
    try {
      let res = await helpDeskAPIS.fetch_helpdesk_image(id, name);
      let base = {};
      if (res.data.data) {
        base = {
          base64: `data:image/png;base64,${res.data.data?.filecontent}`,
          filename: res.data.data?.filename,
        };
        return base;
      }
    } catch {
      return null;
    }
  };

  fetchAllPublishedApp = async (val) => {
    try {
      let res = await pricingMasterAPIS.getApplicationsByStatus(val);
      this.setState({
        allPublishedApp: [...(res.data.data || [])],
      });
    } catch (err) {}
  };

  //   history.location.state !== undefined &&
  //     fetch(history.location?.state?.img)
  //       .then((res) => res.blob())
  //       .then((blob) => {
  //         const file = new File([blob], "capture.png", { type: "image/png" });
  // this.setState({
  //   HelpdeskData:{...this.state.HelpdeskData,image:[file]}
  // })

  //      });

  displayCapturedImage = () => {
    this.props.history.location.state !== undefined &&
      this.props.history.location.state.screenCapture === true &&
      fetch(this.props.history.location?.state?.state?.img)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], "capture.png", { type: "image/png" });

          this.setState({
            HelpdeskData: {
              ...this.state.HelpdeskData,
              image: [file],
            },
          });
        });
  };

  async componentDidMount() {
    const { location } = this.props;
    console.log("location state", location.state);

    if (location.state !== undefined) {
      this.fetchAllPublishedApp("Published");
      let {
        edit,
        disabled,
        helpDeskPage,
        Helpdesk_Data,
        customerData,
        customerProfile,
      } = location.state;

      !helpDeskPage && this.displayCapturedImage();
      customerProfile &&
        this.setState({
          customerDetails: { ...customerData },
          customerProfile: true,
        });

      if (edit || disabled) {
        const { _id, screenshots } = Helpdesk_Data;
        console.log("help desk page", Helpdesk_Data);
        let allImages = screenshots ? screenshots : [];
        let updateImg = [];
        console.log("dsghghsgd", allImages);
        allImages.map(async (img, id) => {
          let base = await this.fetch_image(_id, img);
          if (base) {
            updateImg.push({ ...base });
            this.setState({
              ExistImages: [...updateImg],
            });
          }
        });

        if (
          Helpdesk_Data.category === "Applications" ||
          Helpdesk_Data.category === "Onboard Application"
        ) {
          this.getModules(Helpdesk_Data.appId, Helpdesk_Data.module);
        }
        await this.fetchStatus(Helpdesk_Data.status);

        this.setState({
          HelpdeskData: {
            ...this.state.HelpdeskData,
            ...Helpdesk_Data,
            module: Helpdesk_Data?.module || "",
            status: Helpdesk_Data.status || "",
            comment: Helpdesk_Data.comment || [],
            category: Helpdesk_Data.category || "",
          },
          isEdit: edit,
          isDisabled: disabled,
        });
        this.fetchComments(_id);
      } else {
        this.setState({
          HelpdeskData: {
            ...this.state.HelpdeskData,
            ...Helpdesk_Data,
            module: Helpdesk_Data.module,
          },
          isEdit: edit,
          isDisabled: disabled,
        });
      }
      if (edit && Helpdesk_Data.image === null) {
        Helpdesk_Data.image = [];
      }
    } else {
      this.props.history.push("/app/helpdesk");
    }
  }

  fetchModule = async (appId) => {
    let res = await pricingMasterAPIS.fetchModulesByApplicationId(appId);
    if (res.data.data) {
      this.setState({
        modules: [...res.data.data],
      });
    }
  };

  fetchStatus = async () => {
    try {
      let res = await helpDeskAPIS.fetchStatus();
      console.log(res, "-------status");
      this.setState({
        statusData: [...(res.data.status || [])],
      });
    } catch (err) {}
  };

  handleChange = async (val, id) => {
    this.setState({
      HelpdeskData: { ...val },
    });

    if (id) {
      this.fetchModule(id);
    }
  };

  handleChangeOfModule = async (val) => {
    let data = { moduleId: val.module };
    this.setState({
      HelpdeskData: { ...val },
    });
  };

  handleChangeOfStatus = async (id) => {
    // this.setState({
    //   HelpdeskData: { ...val },
    // });
    let { statusData, HelpdeskData } = this.state;
    let statusVal = statusData.find((item) => item._id == id);
    this.setState({
      HelpdeskData: {
        ...HelpdeskData,
        status: statusVal.status,
        color: statusVal.color,
        id: id,
      },
    });
    this.fetchStatus();
  };

  updateToState = (arr) => {
    this.setState({
      HelpdeskData: { ...this.state.HelpdeskData, comment: [...arr] },
    });
  };

  handleSubmitComment = (val) => {
    const { comment } = this.state.HelpdeskData;
    console.log(val, "commentttt");
    if (val === undefined || val.trim() === "") {
      this.props.enqueueSnackbar("Please enter a valid comment", {
        variant: "error",
      });
    } else {
      // console.log(val, "--------------11111");
      const { comment } = this.state.HelpdeskData;
      this.setState({
        HelpdeskData: {
          ...this.state.HelpdeskData,
          comment: [...comment, val],
        },
      });
    }
  };

  editBilling = (row, e) => {
    e && e.stopPropagation();
    let data = { ...row, category: row.category._id };
    this.props.history.push("/app/edit_billable", {
      billingData: data,
      edit: true,
      disabled: e ? false : true,
    });
  };

  deleteFunc = (index) => {
    let { images } = this.state;
    let filteredImages = images.filter((item, i) => i !== index);
    this.setState({ images: filteredImages });

    // this.setState({
    //   images: [
    //     // ...this.state.images,
    //     {
    //       filename: e.target.fileName,
    //       baseString: e.target.result,
    //     },
    //   ],
    // });
  };

  display_msg = (type, msg) => {
    this.props.enqueueSnackbar(msg, {
      variant: type ? "success" : "error",
    });
  };

  fetchComments = async (id) => {
    try {
      let { data } = await helpDeskAPIS.helpDeskComment(id);
      this.setState({
        comments: data?.data || [],
      });
    } catch (err) {}
  };

  handleAddComments = async (val) => {
    if (val === undefined || val.trim() === "") {
      this.display_msg(false, "Please enter a valid comment");
      return;
    }
    let userId = this.props.userLoginData?._id;
    console.log("userrrrrrrrrrrrrrrrrId", userId, this.props.userLoginData);
    let payload = {
      message: val,
      type: "admin",
      // userId: userId,
    };
    let { _id } = this.state.HelpdeskData;
    try {
      let { data } = await helpDeskAPIS.helpDeskComment(_id, payload);
      this.display_msg(true, data._msg);
      this.fetchComments(_id);
    } catch (err) {
      this.display_msg(false, err?.response?.data?._msg || "Connection error!");
    }
  };

  render() {
    const {
      isEdit,
      isDisabled,
      modules,
      base64,
      HelpdeskData,
      image,
      statusData,
      customerDetails,
      customerProfile,
      ExistImages,
    } = this.state;
    const { classes } = this.props;

    // console.log(
    //   HelpdeskData,
    //   ExistImages,
    //   "dhghjgeuteuteutueteutreytryefytefhyjertef"
    // );
    console.log("vdhsghjfsadghfhgs", HelpdeskData.module, modules);

    return (
      <div>
        <ValidatorForm
          ref="form"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              return;
            }
          }}
          onSubmit={(e) => {
            this.onSubmitTask(e);
          }}
        >
          {/* {loading && <Loader />} */}
          <Grid container>
            <Grid item sm={12} md={12} xs={12} lg={12}>
              <Breadcrumb
                {...this.props}
                links={[
                  {
                    name: customerProfile ? "Customer Details" : "Help Desk",
                    url: customerProfile
                      ? "/app/customer-details"
                      : "/app/helpdesk",
                    routeParams: customerProfile
                      ? { customerData: { ...customerDetails } }
                      : undefined,
                  },
                  {
                    name: `${isEdit ? "View" : "Create Ticket"}`,
                    // url: "/app/create-helpdesk",
                  },
                ]}
              />
            </Grid>

            <Grid item sm={6} md={6} lg={6} xs={12}>
              <Typography variant="h6" style={{ fontWeight: "bold" }}>
                {isEdit ? "Edit" : "Create Ticket"}
              </Typography>
            </Grid>
            <Grid
              item
              sm={6}
              md={6}
              container
              justifyContent="flex-end"
              alignItems="center"
              spacing={1}
            >
              {/* {!isDisabled && ( */}
              <>
                {!isDisabled && (
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      onClick={() => {
                        this.props.history.push("/app/helpdesk");
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                )}
                {!isDisabled && (
                  <Grid item>
                    <Button
                      name="single"
                      size="small"
                      variant={"contained"}
                      type="submit"
                      disabled={
                        isEdit
                          ? !checkPermission("btn30")
                          : !checkPermission("btn27")
                      }
                    >
                      {isEdit ? "Save" : "Create"}
                    </Button>
                  </Grid>
                )}
              </>
              {/* )} */}
              <Grid item>
                {isDisabled && (
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() =>
                      checkPermission("btn30") &&
                      this.setState({
                        isDisabled: false,
                      })
                    }
                    startIcon={<RiEditLine style={{ fontSize: "20px" }} />}
                  >
                    Edit
                  </Button>
                )}
              </Grid>
            </Grid>
            <Grid item md={12}>
              <Divider
                style={{
                  marginTop: "12px",
                  height: "2px",
                  background: "rgb(175, 175, 175)",
                }}
              />
            </Grid>
            <Grid item md={12} style={{ marginTop: "22px" }}>
              <Grid container spacing={3}>
                <Grid item container direction="row" spacing={2} sm={9} md={9}>
                  {isEdit && (
                    <Grid item xs={12} sm={4} md={4}>
                      <Typography
                        style={{ marginBottom: "2px" }}
                        variant="body2"
                      >
                        Ticket Id
                      </Typography>
                      <TextField
                        disabled={true}
                        size="small"
                        variant="outlined"
                        fullWidth
                        value={HelpdeskData._id}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={4} md={4}>
                    <Typography style={{ marginBottom: "2px" }} variant="body2">
                      Select Category <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <SelectValidator
                      disabled={isDisabled}
                      size="small"
                      variant="outlined"
                      fullWidth
                      SelectProps={{
                        native: true,
                      }}
                      value={HelpdeskData.category}
                      inputProps={{
                        name: "category",
                      }}
                      onChange={(e) =>
                        this.handleChange({
                          ...HelpdeskData,
                          category: e.target.value,
                        })
                      }
                      validators={["required"]}
                      errorMessages={["Please select category"]}
                    >
                      <option value="">-select-</option>

                      <option value="Dashboard">Dashboard</option>
                      <option value="Sections">Sections</option>
                      <option value="Applications">Applications</option>
                      <option value="Onboard Application">
                        Onboard Application
                      </option>
                      <option value="Customers">Customers</option>
                      <option value="Active Metadata">Active Metadata</option>
                      <option value="Billable Services">
                        Billable Services
                      </option>
                      <option value="Subscription Plan">
                        Subscription Plan
                      </option>
                      <option value="Pricing Master">Pricing Master</option>
                      <option value="Invoices">Invoices</option>
                      <option value="Help Desk">Help Desk</option>
                      <option value="Status Profile">Status Profile</option>
                      <option value="Users">Users</option>
                      <option value="Roles">Roles</option>
                      <option value="Teams">Teams</option>
                      <option value="Organization">Organization</option>
                    </SelectValidator>
                  </Grid>

                  {HelpdeskData.category === "Applications" ||
                  HelpdeskData.category === "Onboard Application" ? (
                    <>
                      <Grid item xs={12} sm={4} md={4}>
                        <Typography
                          style={{ marginBottom: "2px" }}
                          variant="body2"
                        >
                          Select Applications{" "}
                          <span style={{ color: "red" }}>*</span>
                        </Typography>
                        <SelectValidator
                          disabled={isDisabled}
                          size="small"
                          variant="outlined"
                          fullWidth
                          SelectProps={{
                            native: true,
                          }}
                          value={HelpdeskData.appId}
                          inputProps={{
                            name: "name",
                          }}
                          onChange={(e) =>
                            this.handleChange(
                              {
                                ...HelpdeskData,
                                appId: e.target.value,
                              },
                              e.target.value
                            )
                          }
                          validators={["required"]}
                          errorMessages={["Please select application name"]}
                        >
                          <option value={""}>- select -</option>
                          {this.state.allPublishedApp.map((item) => (
                            <option value={item._id}>{item.name}</option>
                          ))}
                        </SelectValidator>
                      </Grid>

                      <Grid item xs={12} sm={4} md={4}>
                        <Typography
                          style={{ marginBottom: "2px" }}
                          variant="body2"
                        >
                          Select Modules <span style={{ color: "red" }}>*</span>
                        </Typography>
                        <SelectValidator
                          disabled={isDisabled}
                          size="small"
                          variant="outlined"
                          fullWidth
                          SelectProps={{
                            native: true,
                          }}
                          inputProps={{
                            name: "moduleName",
                          }}
                          value={HelpdeskData.module}
                          onChange={(e) =>
                            this.handleChangeOfModule({
                              ...HelpdeskData,
                              module: e.target.value,
                            })
                          }
                          validators={["required"]}
                          errorMessages={["Please select module name"]}
                        >
                          <option value="">- select -</option>

                          {modules.map((item, id) => (
                            <option value={item._id}>{item.name}</option>
                          ))}
                        </SelectValidator>
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}

                  {isEdit && (
                    <>
                      <Grid item md={4} xs={12} sm={4}>
                        <Typography
                          style={{ marginBottom: "2px" }}
                          variant="body2"
                        >
                          Created on
                        </Typography>
                        <TextField
                          disabled={true}
                          size="small"
                          variant="outlined"
                          fullWidth
                          // value={moment
                          //   .unix(HelpdeskData.createdon)
                          //   .format("DD-MM-YYYY, hh:MM A")}
                          value={helpers.date_Func(HelpdeskData.createdon)}
                        />
                      </Grid>
                      <Grid item md={4} xs={12} sm={4}>
                        <Typography
                          style={{ marginBottom: "2px" }}
                          variant="body2"
                        >
                          Created by
                        </Typography>
                        <TextField
                          disabled={true}
                          size="small"
                          variant="outlined"
                          fullWidth
                          value={HelpdeskData.createdby}
                        />
                      </Grid>
                      {HelpdeskData.updatedon ? (
                        <Grid item md={4} xs={12} sm={4}>
                          <Typography
                            style={{ marginBottom: "2px" }}
                            variant="body2"
                          >
                            Updated on
                          </Typography>
                          <TextField
                            disabled={true}
                            size="small"
                            variant="outlined"
                            Customer
                            fullWidth
                            // value={moment
                            //   .unix(HelpdeskData.updatedon)
                            //   .format("DD-MM-YYYY, hh:MM A")}
                            value={helpers.date_Func(HelpdeskData.updatedon)}
                          />
                        </Grid>
                      ) : null}
                      <Grid item md={4} xs={12} sm={4}>
                        <Typography
                          style={{ marginBottom: "2px" }}
                          variant="body2"
                        >
                          Updated by
                        </Typography>
                        <TextField
                          disabled={true}
                          size="small"
                          variant="outlined"
                          fullWidth
                          value={HelpdeskData.updatedby}
                        />
                      </Grid>
                      <Grid item md={4} xs={12} sm={4}>
                        {/* <Typography
                            style={{ marginTop: "2px" }}
                            variant="body2"
                          >
                            Status
                          </Typography>
                          <FormGroup>
                            <Select
                              fullWidth
                              variant="outlined"
                              style={{
                                // width: "20%",
                                borderTopRightRadius: "0px",
                                borderBottomRightRadius: "0px",
                              }}
                              SelectProps={{
                                native: true,
                              }}
                              inputProps={{
                                name: "status",
                              }}
                              value={HelpdeskData.id}
                              onChange={(e) =>
                                // this.handleChangeOfStatus({
                                //   ...HelpdeskData,
                                //   status: e.target.value,
                                //   color:
                                // })
                                this.handleChangeOfStatus(e.target.value)
                              }
                            >
                              {statusData.map((item, id, key) => (
                                <MenuItem key={key} value={item._id}>
                                  {item.status}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormGroup> */}

                        <Autocomplete
                          disabled={isDisabled}
                          value={{ status: HelpdeskData?.status || "" }}
                          size="medium"
                          variant="outlined"
                          fullWidth
                          id="combo-box-demo"
                          options={statusData}
                          getOptionLabel={(option) => option.status || ""}
                          onChange={(e, v, reason) => {
                            console.log(v, "===============");
                            this.setState({
                              // ...this.state.helpDeskData,
                              helpDeskData: {
                                status: v.status,
                              },
                            });
                            this.handleChange({
                              ...HelpdeskData,
                              status: (v || {}).status || "",
                            });
                            // onChange(
                            //   {
                            //     currentTarget: {
                            //       name: "status_name",
                            //       value: (v || {}).value || "",
                            //     },
                            //   },
                            //   index
                            // );
                          }}
                          renderInput={(params) => (
                            <Grid item>
                              <Typography
                                variant="body2"
                                style={{ marginBottom: "2px" }}
                              >
                                Select Status
                              </Typography>
                              <TextField
                                {...params}
                                size="small"
                                variant="outlined"
                                fullWidth
                                name="status_name"
                                placeholder="Select Status"
                              />
                            </Grid>
                          )}
                        />
                        {/* <Autocomplete
                              options={statusData}
                              getOptionLabel={(option) => option.status}
                              // className={`${classes.searchInput2} ${
                              //   error.status?.isError && classes.errorAuto
                              // }`}
                              style={{ width: "100%" }}
                              onChange={(e, input) => {
                                if (input) {
                                  

                                  this.setState({
                                    // ...this.state.helpDeskData,
                                    helpDeskData: {
                                      status: input.status,
                                    },
                                  });

                                  // setHelpDeskData({
                                  //   ...helpDeskData,
                                  //   status: input.status,
                                  // });
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="standard"
                                  label="Search Status"
                                  placeholder="Search Status"
                                />
                              )}
                            /> */}

                        {/* {error.status?.isError && (
                            <p className={classes.errorMsg}>
                              {"Status is Required"}
                            </p>
                          )} */}
                        <Grid
                          item
                          style={{
                            position: "absolute",
                            marginTop: "10px",
                          }}
                        >
                          {this.state.helpDeskData?.status &&
                          this.state.statusData.length > 0 ? (
                            <div style={{ display: "flex", marginLeft: 12 }}>
                              <div
                                style={{
                                  backgroundColor: this.state.statusData.find(
                                    (s) =>
                                      s.status ===
                                      this.state.helpDeskData?.status
                                  )?.color,
                                  height: 15,
                                  width: 15,
                                  borderRadius: "50%",
                                  position: "relative",
                                  right: 10,
                                }}
                              ></div>
                              <Typography
                                variant="h6"
                                className={classes.fieldText}
                                style={{
                                  color: this.state.statusData.find(
                                    (s) =>
                                      s.status ===
                                      this.state.helpDeskData?.status
                                  )?.color,
                                }}
                              >
                                {this.state.statusData
                                  .find(
                                    (s) =>
                                      s.status ===
                                      this.state.helpDeskData?.status
                                  )
                                  ?.status.charAt(0)
                                  .toUpperCase() +
                                  this.state.statusData
                                    .find(
                                      (s) =>
                                        s.status ===
                                        this.state.helpDeskData?.status
                                    )
                                    ?.status.slice(1)}
                              </Typography>
                            </div>
                          ) : (
                            <Typography
                              variant="h6"
                              className={classes.fieldText}
                            >
                              {/* Status is not set */}
                              {/* {this.state.helpDeskData.status} */}
                              {/* {HelpdeskData.status} */}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>
                <Grid item xs={12} sm={5} md={3}>
                  <Typography style={{ marginBottom: "2px" }} variant="body2">
                    Issue <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextValidator
                    autoComplete="off"
                    disabled={isDisabled}
                    multiline
                    rows={7}
                    size="small"
                    variant="outlined"
                    fullWidth
                    id="Issue"
                    value={HelpdeskData.issues}
                    onChange={(e) =>
                      this.handleChange({
                        ...HelpdeskData,
                        issues: e.target.value,
                      })
                    }
                    name="Issue"
                    validators={["required"]}
                    errorMessages={["Issue is required"]}
                    helperText={<div style={{ height: "12px" }}></div>}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid>
            <HelpDeskImage
              handleImg={this.handleImg}
              // newFiles={this.newFiles}
              isDisabled={this.state.isDisabled}
              // handleImageChange={this.handleImg}
              files={this.state.files}
              imageBase64={base64}
              images={this.state.images}
              hosaImage={this.state.hosaImage}
              ExistImages={ExistImages}
              // image={image}
              // deleteFunc={this.deleteFunc}
              HelpdeskData={HelpdeskData}
              handleDeleteImage={this.handleDeleteImage}
              handleDeleteNewScreenShot={this.handleDeleteNewScreenShot}
              random={this.state.random}
              {...this.props}
            />
          </Grid>

          {isEdit && (
            <Grid>
              <HelpDeskCommentSection
                HelpdeskData={HelpdeskData}
                comments={this.state.comments}
                updateToState={this.updateToState}
                handleSubmitComment={this.handleSubmitComment}
                handleAddComments={this.handleAddComments}
                isDisabled={this.state.isDisabled}
                {...this.props}
              />
            </Grid>
          )}
        </ValidatorForm>
      </div>
    );
  }
}

CreateHelpDesk.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    editdata: state.helpdesk.editdata,
    status_data: state.statusProfile.data,
    userLoginData: state.loginReducer.userLoginData,
    // data: state.helpdesk.data,
    // loading: state.helpdesk.loading,
    // totalCount: state.helpdesk.totalCount,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getData: () => dispatch(actions.getData()),
    addHelpDeskData: (payload) => dispatch(actions.addHelpDeskData(payload)),
    editHelpDeskData: (id, payload) =>
      dispatch(actions.editHelpDeskData(id, payload)),
    getAllStatus: () =>
      dispatch(StatusAction.actionfetchAllStatusProfileData()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(Styles)(withSnackbar(CreateHelpDesk)));
