import { TableCell, TableRow, TextField, Typography } from "@material-ui/core";
import { React, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import WrappedDexTable from "components/DexTable/WrappedDexTable";
import withSection from "components/Section/Section";

import { CustomNumberFeild } from "components/Customized/index";


import Paper from "@material-ui/core/Paper";
const useStyles = makeStyles({
  table: {
    // minWidth: 200,
  },
});

function DiscountTable(props) {
  const { isEdit, discount } = props;
  const classes = useStyles();
  const [arr, setArr] = useState([]);

  console.log(discount, props.data, "dbjdgjghgjhSBJgsj");

  useEffect(() => {
    console.log("rendered!!!!", 99999);
    setArr([...props.data]);
  }, [props.data]);

  const min = 0;
  const max = 100;

  const handleChange = (val, idx) => {
    if (val.unit > max) {
      val.unit = max;
    }
    if (val.unit < min || val.unit === "-0") {
      val.unit = min;
    }

    let data = [...discount];
    let flag = false;
    discount.map((item, id) => {
      if (item.subscriptionId === val.subscriptionId) {
        data[id] = { ...item, ...val };
        flag = true;
      }
    });
    if (!flag) {
      data.push(val);
      props.addSubscriptionPlan(data);
    } else {
      props.addSubscriptionPlan(data);
    }
  };

  const getUnit = (row, id) => {
    let unit = "";
    discount.map((item, id) => {
      return item.subscriptionId === row.subscription ? (unit = item.unit) : "";
    });
    return unit;
  };
  console.log(discount, "qdtyt4545jgfjghfghu986796789");
  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: "40%" }}>Subscription</TableCell>
              <TableCell align="left" style={{ width: "30%" }}>
                Discount (%)
              </TableCell>
              {/* <TableCell align="left"></TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {arr.map((row, index) => {
              let unit = getUnit(row, index);
              return (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {row.subscription}
                  </TableCell>
                  <TableCell align="left">
                    {/* <TextField
                      className="mt-2"
                      fullWidth
                      variant="outlined"
                      style={{ width: "150px" }}
                      type="number"
                      inputProps={{ inputProps: { min: 1, max: 100 } }}
                      size="small"
                      value={unit}
                      onChange={(e) =>
                        handleChange(
                          { ...arr[index], unit: e.target.value, _id: row._id },
                          index
                        )
                      }
                    /> */}

                    <CustomNumberFeild

                      inputProps={{ min, max }}
                      disabled={props.isDisabled}
                      className="mt-2"
                      fullWidth
                      variant="outlined"
                      style={{ width: "40%", paddingBottom: "5px" }}
                      type="number"
                      //inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                      // inputProps={{ inputProps: { min: 1, max: 100 } }}
                      size="small"
                      value={unit || ""}
                      onChange={(e) =>
                        handleChange(
                          {
                            unit: parseInt(e.target.value),
                            _id: row._id,
                            subscriptionId: row.subscription,
                          },
                          index
                        )
                      }
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <br />
    </>
  );
}
export default withSection(DiscountTable, "Discount");
