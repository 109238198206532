import React, { Component } from "react";
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";
import "@webscopeio/react-textarea-autocomplete/style.css";
import { Typography } from "@mui/material";
const Item = ({ entity: { name, char } }) => <div>{`${name}: ${char}`}</div>;
class ReactTextareaComponent extends Component {
  render() {
    const { text, options } = this.props;
    const showOptionData = (options || []).map((row) => {
      return { name: row.label, char: `\${${row.value}\}` };
    });
    console.log("85245691735", showOptionData, options);

    return (
      <>
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <h6 style={{ fontWeight: "500" }}>{text}</h6>
          <ReactTextareaAutocomplete
            onChange={(e) => {
              console.log(e.target.value);
              this.props.onChange(e);
            }}
            className="my-textarea"
            loadingComponent={() => <span>Loading</span>}
            style={{
              fontSize: "18px",
              lineHeight: "20px",
              padding: 5,
            }}
            ref={(rta) => {
              this.rta = rta;
            }}
            innerRef={(textarea) => {
              this.textarea = textarea;
            }}
            containerStyle={{
              marginTop: 10,
              width: 400,
              height: 100,
              // margin: "20px auto",
            }}
            value={this.props.value}
            minChar={0}
            trigger={{
              "${": {
                dataProvider: (token) => {
                  return [...(showOptionData || [])];
                },
                component: Item,
                output: (item, trigger) => item.char,
              },
            }}
          />
        </div>
      </>
    );
  }
}

export default ReactTextareaComponent;
