import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FilterIcon from "@material-ui/icons/FilterList";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Cancel } from "@material-ui/icons";
// import AddCircle from "@material-ui/icons/AddCircleOutlineOutlined";
import AddIcon from "@material-ui/icons/Add";
import FilterOption from "./FilterOption.jsx";
import {
  Button,
  Card,
  CardHeader,
  IconButton,
  Popover,
  Typography,
  Grid,
  CardContent,
  TextField,
  FormLabel,
  Divider,
  CardActions,
  Container,
  Badge,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
  action: {
    display: "flex",
  },
  button: {
    margin: theme.spacing(1),
  },
  cancelIcon: {
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
  errorMsg: {
    color: "#DF0E0E",
    font: "normal normal 600 12px/16px Nunito;",
    margin: 0,
    paddingTop: 1,
    paddingBottom: 1,
    minHeight: "1vh",
    border: "solid 1px #FFFFFF",
  },
}));

const Filter = (props) => {
  const Classes = useStyles();
  const { dataOptions, open, classes, filterHistory } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [err, setErr] = React.useState({ col: false, val: false, op: false });
  const [toggle, setToggle] = React.useState(false);
  const [filterValues, setFilterValues] = useState({
    columns: "",
    operator: "",
    value: "",
  });
  const openPopover = (event) => {
    setToggle(true);
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (filterHistory) {
      setRow([...filterHistory]);
    }
  }, [filterHistory]);

  let rows_data = [{ key: 1, columns: "", operator: "", value: "" }];

  const [rows, setRow] = React.useState(rows_data);

  // function to add filter.

  const addFilter = () => {
    setFilterValues({ columns: "", operator: "", value: "" });
    let copy = [...rows];
    let addId = {
      key: rows.length + 1,
      columns: "",
      operator: "",
      value: "",
    };
    copy.push(addId);
    setRow(copy);
  };

  const removeFilter = (index) => {
    console.log("removeFilter", index);
    if (rows.length !== 1) {
      let copy = [...rows];
      let rowRemoved = copy.filter((row, i) => {
        console.log({ index, i });
        return i !== index;
      });
      console.log({ rowRemoved });
      setRow(rowRemoved);
    }
  };

  const popUpClose = () => {
    setToggle(false);
    setAnchorEl(null);
  };

  const handleClose = () => {
    let flag = false;
    rows.map((item, id) => {
      if (
        item.columns.trim() === "" ||
        item.value.trim() === "" ||
        item.operator.trim() === ""
      ) {
        setErr({
          col: item.columns.trim() === "",
          val: item.value.trim() === "",
          op: item.operator.trim() === "",
        });
        flag = true;
      }
    });
    if (flag) {
      return;
    }
    props.filterFunc(rows);
    popUpClose();
  };

  const handleClear = () => {
    handleClearFilters();
    setToggle(false);
  };
  const handleClearFilters = () => {
    setRow([...rows_data]);
    props.getDataByFilter({}, rows_data);
    setErr({
      col: false,
      val: false,
      op: false,
    });
    setFilterValues({ columns: "", operator: "", value: "" });
  };

  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <Badge
        badgeContent={
          rows &&
          rows.length > 0 &&
          rows[0].columns && (
            <Cancel
              className={Classes.cancelIcon}
              onClick={() => handleClearFilters()}
            />
          )
        }
      >
        <Button
          style={{ backgroundColor: "white", height: "35px" }}
          className={classes}
          size="small"
          fullWidth
          variant="outlined"
          startIcon={<FilterIcon />}
          onClick={(e) => openPopover(e)}
          endIcon={
            <IconButton size="small">
              {!toggle ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </IconButton>
          }
        >
          Filter
        </Button>
      </Badge>

      <Popover
        id={id}
        open={toggle}
        anchorEl={anchorEl}
        onClose={() => popUpClose()}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Card
          // className=" w-60  "
          style={{
            width: "700px",
            padding: "15px",
          }}
        >
          <CardHeader>
            <Typography variant="body2">Filter</Typography>
          </CardHeader>
          <CardActions>
            <Grid container spacing={2} alignItems="flex-end">
              <Grid item xs={12} md={10} style={{ marginTop: "-30px" }}>
                <Typography variant="body2" style={{ fontWeight: "bold" }}>
                  Filters
                </Typography>
              </Grid>
              {rows.map((row, index) => (
                <FilterOption
                  index={index}
                  removeFilter={removeFilter}
                  row={row}
                  filters={dataOptions}
                  filterValues={filterValues}
                  setFilterValues={setFilterValues}
                  setRow={setRow}
                  rows={rows}
                  err={err}
                  Classes={Classes}
                  setErr={setErr}
                />
              ))}
              <Grid item xs={12} md={10} style={{ marginBottom: "20px" }}>
                <Button
                  color="secondary"
                  size="small"
                  onClick={addFilter}
                  startIcon={<AddIcon />}
                >
                  {" "}
                  ADD
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid
                container
                item
                xs={12}
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <div>
                  <Button
                    size="small"
                    style={{ padding: "5px 26px" }}
                    className={Classes.button}
                    variant="outlined"
                    onClick={() => handleClear()}
                  >
                    {" "}
                    Clear
                  </Button>
                  <Button
                    className={Classes.button}
                    variant="contained"
                    size="small"
                    color="primary"
                    style={{ color: "white", padding: "5px 26px" }}
                    type="button"
                    onClick={() => handleClose()}
                  >
                    Submit
                  </Button>
                </div>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </Popover>
    </div>
  );
};
export default Filter;
