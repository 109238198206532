const Styles = (theme) => ({
  mt: {
    marginTop: theme.spacing(2),
  },
  avatarimage: {
    width: theme.spacing(9),
    height: theme.spacing(9),
    [theme.breakpoints.up("md")]: {
      width: theme.spacing(10),
      height: theme.spacing(10),
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  radiobtn: {
    fontSize: "10px",
  },
  chip: {
    margin: theme.spacing(0.5),
    borderRadius: "4px",
    color: "#2185D0",
    backgroundColor: "#F0F0F0",
    fontSize: "12px",
  },
  cmncolor: {
    backgroundColor: "white",
  },
  rightcard: {
    // padding: "500px 0px 0px 0px",
    [theme.breakpoints.up("sm")]: {
      padding: "15px",
    },
  },
  radiobtnnew: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      justifyContent: "space-around",
      // padding: "15px",
    },
    // marginLeft: "-12%",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  title: {
    color: "white",
    fontSize: "16px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "20px",
    },
  },
  loginText: {
    fontWeight: 550,
    marginBottom: 5,
  },
  margin: {
    marginBottom: 20,
  },
  linkTest: {
    color: "black",
    "&:hover": {
      textDecoration: "none",
      color: "#002855",
    },
  },
  appbarresponsive: {
    backgroundColor: "#fafafa",
    boxShadow: "none",
    color: "#000",
    position: "static",
  },
  appTopNav: {
    position: "fixed",
    width: "97.2%",
    marginTop: "-10px",
    zIndex: 2,
    backgroundColor: "#f2f2f2",
    [theme.breakpoints.only("sm")]: {
      width: "96%",
    },
    [theme.breakpoints.only("xs")]: {
      width: "92%",
    },
  },
  cardstyle: {
    paddingTop: "14%",
    [theme.breakpoints.up("sm")]: {
      padding: "1.2% 1%",
    },
    // [theme.breakpoints.only("sm")]: {
    //   MarginBottom: "10%",
    // },
  },
  card: {
    width: "100%",
    marginTop: "20px",
  },
  customBadgeDraft: {
    backgroundColor: "#FBBD08",
  },
  applicationvlline: {
    borderLeft: "2px solid #cdc8c8",
    height: "28px",
    marginTop: "19px",
  },
  footer: {
    position: "fixed",
    bottom: 0,
    left: 0,
    padding: 20,
    width: "100%",
    textAlign: "right",
    zIndex: 999,
    backgroundColor: "#f2f2f2",
  },
  customPagination: {
    width: "fit-content",
    marginLeft: "auto",
    "& .MuiPaginationItem-page.Mui-selected": {
      border: "0.5px solid #707070",
      margin: "0 10px",
      height: 30,
      width: 30,
      padding: "10px 13px",
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      boxShadow: "0px 3px 10px #5757571a",
      lineHeight: 1,
      borderRadius: 5,
      opacity: 1,
    },
    "& .MuiPaginationItem-page": {
      font: "normal normal normal 14px/19px Nunito Sans",
      letterSpacing: 0,
      color: "#000000",
      opacity: 0.6,
    },
  },
});
export default Styles;
