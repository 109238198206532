import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  InputLabel,
  Typography,
  Button,
  TextField,
  FormControl,
  Select,
  CardContent,
  Divider,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import Avatar from "@material-ui/core/Avatar";
import PublishIcon from "@material-ui/icons/Publish";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Styles from "../Styles/Styles";
import AddTags from "../Components/AddTags";
import AutoComplete from "../Components/AutoComplete";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { useEffect } from "react";
import { TextValidator } from "react-material-ui-form-validator";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { RiUpload2Line, RiDeleteBin5Line } from "react-icons/ri";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import { CustomNumberFeild } from "components/Customized";

const ApplicationBasicEdit = (props) => {
  const {
    basicInfo,
    setBasicInfo,
    classes,
    chipData,
    setChipData,
    defaultTags,
    showEdit,
    value,
    defaultCategory,
    editDisable,
    setMicroRadio,
    microRadio,
  } = props;

  const setAppIcon = (e) => {
    let base64String = "";
    console.log(e, "tdfhdjg7r575");
    // var file = document.querySelector("input[type=file]")["files"][0];
    var reader = new FileReader();
    reader.onload = function () {
      base64String = reader.result.replace("data:", "").replace(/^.+,/, "");
      handleOnChange({ ...basicInfo, applicationIconBase64: base64String });
    };
    reader.readAsDataURL(e.target.files[0]);
    e.target.form.reset();
  };

  const removeAppIcon = () => {
    handleOnChange({ ...basicInfo, applicationIconBase64: "" });
  };

  let appType = null;
  useEffect(() => {
    props.valuesFromBasicInfo({ ...basicInfo });
  }, [basicInfo]);

  appType =
    basicInfo.applicationType !== undefined ? basicInfo.applicationType : "";

  const handleOnChange = (value) => {
    // if (value.applicationType === "Only API") {
    //   const { isMicroFrontend, ...other } = value;
    //   setBasicInfo({ ...other });
    // } else {
    //   setBasicInfo({ ...value });
    // }
    setBasicInfo({ ...value });
  };

  console.log("baisc info", basicInfo, editDisable);
  return (
    <div className="mt-3">
      <div className=" mt-1  mt-md-1 w-100">
        <Grid container spacing={2} className=" pr-3 pt-3">
          <Grid item xs={12} sm={2} md={2} container justifyContent="center">
            <div>
              <img
                // className={classes.avatarimage}
                style={{
                  textIndent: "10000px",
                  textAlign: "center",
                  objectFit: "fill",
                  width: "100px",
                  height: "100px",
                  borderRadius: "50px",
                }}
                // style={{
                //   border: "solid 1px green",
                //   width: "100px",
                //   height: "100px",
                //   objectFit: "fill",
                // }}
                //  alt="Remy Sharp"
                src={`data:image/png;base64,${basicInfo.applicationIconBase64}`}
              />
            </div>
            {editDisable && (
              <>
                <Grid container justifyContent="center" alignItems="flex-end">
                  <Button component="label" startIcon={<RiUpload2Line />}>
                    Upload
                    <input type="file" hidden onChange={(e) => setAppIcon(e)} />
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
          {/* <Grid item xs={12} sm={2} md={2} align="center">
          <Avatar
            className={classes.avatarimage}
            style={{ width: "100px", height: "100px" }}
            alt="Remy Sharp"
            src={`data:image/png;base64,${basicInfo.applicationIconBase64}`}
          />
          {!editDisable ? null : (
            <Button
              component="label"
              startIcon={<PublishIcon />}
              className="mt-2"
            >
              Upload
              <input type="file" hidden onChange={(e) => setAppIcon(e)} />
            </Button>
          )}
        </Grid> */}
          <Grid item xs={12} sm={5} md={7}>
            <Grid container alignItems="center" spacing={3}>
              <Grid item xs={10} sm={12} md={6}>
                <Typography variant="body2" className="mb-1">
                  Name <span style={{ color: "red" }}>*</span>{" "}
                </Typography>
                <TextValidator
                  disabled={!editDisable}
                  label=""
                  autoComplete="off"
                  variant="outlined"
                  size="small"
                  name="applicationName"
                  value={basicInfo.name}
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    handleOnChange({ ...basicInfo, name: e.target.value });
                  }}
                  validators={["required"]}
                  errorMessages={["Please enter the name"]}
                />
              </Grid>
              <Grid item xs={10} sm={12} md={6}>
                <Typography variant="body2" className="mb-1">
                  Category <span style={{ color: "red" }}>*</span>
                </Typography>
                <AutoComplete
                  editDisable={editDisable}
                  addCategory={props.addCategory}
                  defaultCategory={props.defaultCategory}
                  setBasicInfo={setBasicInfo}
                  basicInfo={basicInfo}
                  handleOnChange={handleOnChange}
                  edit={true}
                />
              </Grid>

              <Grid item xs={10} sm={12} md={6}>
                <Typography variant="body2" className="mb-1">
                  {" "}
                  Short Key
                </Typography>
                <TextField
                  disabled={!editDisable}
                  fullWidth
                  size="small"
                  variant="outlined"
                  value={basicInfo.shortKey}
                  onChange={(e) => {
                    handleOnChange({ ...basicInfo, shortKey: e.target.value });
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={8} md={6}>
                <div>
                  <Typography variant="body2" className="mb-1">
                    {" "}
                    Admin Email
                  </Typography>

                  <TextValidator
                    fullWidth
                    disabled={!editDisable}
                    size="small"
                    variant="outlined"
                    value={basicInfo.email}
                    onChange={(e) => {
                      handleOnChange({
                        ...basicInfo,
                        email: e.target.value,
                      });
                    }}
                    validators={["required", "isEmail"]}
                    errorMessages={[
                      "Please enter the admin email",
                      "Invalid email",
                    ]}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={5} md={3}>
            <Grid container alignItems="center" justifyContent="center">
              <Grid item xs={10} sm={12}>
                <Typography variant="body2" className="mb-1">
                  {" "}
                  Description
                </Typography>
                <TextareaAutosize
                  disabled={!editDisable}
                  className={classes.cmncolor}
                  autoComplete="off"
                  placeholder=""
                  variant="outlined"
                  size="small"
                  multiline={true}
                  maxRows={6}
                  value={basicInfo.description}
                  name="appdescription"
                  style={{
                    width: "100%",
                    maxHeight: "130px",
                    minHeight: "130px",
                    // resize: "none",
                    overflowY: "auto",
                    backgroundColor: "#fafafa",
                  }}
                  // disabled={!editDisable}

                  onChange={(e) => {
                    handleOnChange({
                      ...basicInfo,
                      description: e.target.value,
                    });
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container className="mt-2">
              <Grid
                item
                sm={2}
                md={2}
                container
                justifyContent="center"
                alignItems="flex-start"
                className="pr-2"
              >
                {editDisable && basicInfo.applicationIconBase64?.trim() !== "" && (
                  <Button
                    component="label"
                    startIcon={<RiDeleteBin5Line />}
                    onClick={() => removeAppIcon()}
                  >
                    Remove
                  </Button>
                )}
              </Grid>

              <Grid item xs={12} sm={9} md={10}>
                <Grid
                  conatiner
                  spacing={1}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item md={12}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          width: "33.8%",
                          border: "solid 1px #AFAFAF",
                          borderRadius: 4,
                          display: "flex",
                          padding: "7px",
                          marginLeft: "2px",
                          alignItems: "center",
                        }}
                      >
                        <RadioGroup
                          name="applicationType"
                          value={appType}
                          onChange={(e) => {
                            if (e.target.value === "Only API") {
                              setMicroRadio(false);
                            } else {
                              setMicroRadio(true);
                            }

                            handleOnChange({
                              ...basicInfo,
                              applicationType: e.target.value,
                            });
                          }}
                        >
                          <Typography variant="body2"> Type </Typography>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <FormControlLabel
                              disabled={!editDisable}
                              name="BothGUIandAPI"
                              value="Both GUI and API"
                              control={<Radio color="primary" />}
                              label={
                                <Typography style={{ fontSize: 14 }}>
                                  Both GUI and API
                                </Typography>
                              }
                            />{" "}
                            <FormControlLabel
                              disabled={!editDisable}
                              name="OnlyGUI"
                              value="Only GUI"
                              control={<Radio color="primary" />}
                              label={
                                <Typography style={{ fontSize: 14 }}>
                                  Only GUI
                                </Typography>
                              }
                            />
                            <FormControlLabel
                              disabled={!editDisable}
                              name="OnlyAPI"
                              value="Only API"
                              control={<Radio color="primary" />}
                              label={
                                <Typography style={{ fontSize: 14 }}>
                                  Only API
                                </Typography>
                              }
                            />
                          </div>
                        </RadioGroup>
                      </div>
                      <div
                        style={{
                          width: "64%",
                          display: "flex",
                          alignItems: "flex-start",
                          paddingLeft: "2px",
                        }}
                      >
                        {microRadio && (
                          <div
                            style={{
                              width: "52.5%",
                              border: "solid 1px #AFAFAF",
                              borderRadius: 4,
                              padding: "7px",
                            }}
                          >
                            <div style={{ width: "50%" }}>
                              <RadioGroup
                                name="isMicroFrontend"
                                value={basicInfo.isMicroFrontend || false}
                                onChange={(e) => {
                                  console.log(
                                    "dsgjgdjkfgj",
                                    typeof e.target.value
                                  );
                                  handleOnChange({
                                    ...basicInfo,
                                    isMicroFrontend: e.target.value === "true",
                                  });
                                }}
                              >
                                <div>
                                  <Typography variant="body2">SPA </Typography>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div>
                                    <FormControlLabel
                                      disabled={!editDisable}
                                      name="True"
                                      value={true}
                                      control={<Radio color="primary" />}
                                      label={
                                        <Typography style={{ fontSize: 15 }}>
                                          True
                                        </Typography>
                                      }
                                    />{" "}
                                  </div>
                                  <div>
                                    <FormControlLabel
                                      disabled={!editDisable}
                                      name="False"
                                      value={false}
                                      control={<Radio color="primary" />}
                                      label={
                                        <Typography style={{ fontSize: 15 }}>
                                          False
                                        </Typography>
                                      }
                                    />
                                  </div>
                                </div>
                              </RadioGroup>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </Grid>

                  <Grid item md={12} style={{ marginTop: "30px" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          width: "33.8%",
                          border: "solid 1px #AFAFAF",
                          borderRadius: 4,
                          display: "flex",
                          padding: "7px",
                          marginLeft: "2px",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <div>
                            <RadioGroup
                              name="enableTrialAccess"
                              value={basicInfo.enableTrialAccess || false}
                              onChange={(e) => {
                                handleOnChange({
                                  ...basicInfo,
                                  enableTrialAccess: e.target.value === "true",
                                });
                              }}
                            >
                              <div>
                                <Typography variant="body2">
                                  Enable Trial Access{" "}
                                </Typography>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div>
                                  <FormControlLabel
                                    disabled={!editDisable}
                                    name="True"
                                    value={true}
                                    control={<Radio color="primary" />}
                                    label={
                                      <Typography style={{ fontSize: 15 }}>
                                        True
                                      </Typography>
                                    }
                                  />{" "}
                                </div>
                                <div>
                                  <FormControlLabel
                                    disabled={!editDisable}
                                    name="False"
                                    value={false}
                                    control={<Radio color="primary" />}
                                    label={
                                      <Typography style={{ fontSize: 15 }}>
                                        False
                                      </Typography>
                                    }
                                  />
                                </div>
                              </div>
                            </RadioGroup>
                          </div>
                          <div>
                            {basicInfo.enableTrialAccess && (
                              <div style={{ marginLeft: "15px" }}>
                                <Typography variant="body2" className="mb-1">
                                  {" "}
                                  Trial Period (In Days)
                                </Typography>

                                <CustomNumberFeild
                                  type="number"
                                  disabled={!editDisable}
                                  size="small"
                                  variant="outlined"
                                  value={basicInfo.trialPeriod}
                                  validators={["required", `minNumber:${1}`]}
                                  errorMessages={[
                                    "Please enter the trial period!",
                                    "Invalid trial period",
                                  ]}
                                  onChange={(e) => {
                                    handleOnChange({
                                      ...basicInfo,
                                      trialPeriod:
                                        e.target.value.trim().length !== 0
                                          ? parseInt(e.target.value)
                                          : "",
                                    });
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "64%",
                          display: "flex",
                          alignItems: "flex-start",
                          paddingLeft: "2px",
                        }}
                      >
                        <div
                          style={{
                            width: "52.5%",
                            border: "solid 1px #AFAFAF",
                            borderRadius: 4,
                            display: "flex",
                            padding: "7px",
                            alignItems: "center",
                          }}
                        >
                          <RadioGroup
                            name="launchType"
                            value={basicInfo.launchType || ""}
                            onChange={(e) => {
                              handleOnChange({
                                ...basicInfo,
                                launchType: e.target.value,
                              });
                            }}
                          >
                            <div>
                              <Typography variant="body2">
                                Subscription type{" "}
                              </Typography>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <div>
                                <FormControlLabel
                                  disabled={!editDisable}
                                  name="ManualLaunch"
                                  value={"ManualLaunch"}
                                  control={<Radio color="primary" />}
                                  label={
                                    <Typography style={{ fontSize: 15 }}>
                                      Manual
                                    </Typography>
                                  }
                                />
                              </div>
                              <div>
                                <FormControlLabel
                                  // disabled={!editDisable}
                                  name="AutoLaunch"
                                  value={"AutoLaunch"}
                                  control={<Radio color="primary" />}
                                  label={
                                    <Typography style={{ fontSize: 15 }}>
                                      Auto
                                    </Typography>
                                  }
                                />{" "}
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginBottom: "6px",
                                }}
                              >
                                <Tooltip title="Subscription type info">
                                  <InfoIcon style={{ fontSize: "23px" }} />
                                </Tooltip>
                              </div>
                            </div>
                          </RadioGroup>
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{ marginTop: 15, paddingBottom: 10 }}
                  ></Grid>
                  <Grid item xs={12} sm={12}>
                    <div className={classes.tags}>
                      <Typography variant="body2">Tags</Typography>
                      <AddTags
                        editDisable={editDisable}
                        defaultTags={defaultTags}
                        setChipData={(tags) => {
                          handleOnChange({ ...basicInfo, tags });
                        }}
                        tags={basicInfo.tags}
                        edit={true}
                        value={
                          basicInfo.tags !== undefined
                            ? [...basicInfo.tags]
                            : []
                        }
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
ApplicationBasicEdit.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(Styles)(ApplicationBasicEdit);
