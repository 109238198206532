import * as actions from "./actionType";
const intialState = {
  loading: false,
  load: false,
};
const reducer = (state = intialState, action) => {
  switch (action.type) {
    case actions.LOAD_LOADING:
      return { ...state, load: action.payload };

    default:
      return {
        ...state,
      };
  }
};
export default reducer;
