import React, { useState, useEffect } from "react";

const createNestedMenu = (arr = [], parent = 0) => {
  let fix = [];

  for (let i in arr) {
    if (arr.hasOwnProperty(i)) {
      if (arr[i].parent === parent) {
        let children = createNestedMenu(arr, arr[i].id);

        if (children.length) {
          arr[i].children = children;
        }

        fix.push(arr[i]);
      }
    }
  }

  return fix;
};

// const generateFlatMenu = (arr = [], parent = 0, index = 0, recursive = 1) => {
//   let fix = [],
//     parentTemp = Number(parent),
//     indexTemp = Number(index),
//     recursiveTemp = recursive + 1;

//   for (let i in arr) {
//     const idx = Number(i) + 1;
//     if (arr.hasOwnProperty(i)) {
//       let indexFix =
//         recursive === 1
//           ? idx * 1000
//           : recursive === 2
//           ? idx * 100 + indexTemp
//           : idx + indexTemp;
//       let fixTemp = {
//         id: arr[i].id,
//         name: arr[i].label,
//         parent: parentTemp,
//         orderNo: indexFix,
//       };

//       fix.push(fixTemp);

//       if (Array.isArray(arr[i].children)) {
//         fix = fix.concat(
//           generateFlatMenu(arr[i].children, arr[i].id, indexFix, recursiveTemp)
//         );
//       }
//     }
//   }

//   return fix;
// };

// const Helper = (props) => {
//   const [menus, setMenus] = useState([]);

//   //   console.log("prooooooooooooooooooooooooooops", props);
//   useEffect(() => {
//     props.data.map((p, i) => {
//       Object.assign(p, { ParentId: 0 });
//       p.sections.map((c, i) => {
//         Object.assign(c, { ParentId: p._id });
//       });
//     });

//     setMenus(props.data || []);
//   }, [props.data]);

//   console.log("prooooooooooooooooooooooooooops---", menus);

//   return <></>;
// };

export { createNestedMenu };
//export default Helper;
