import React from "react";
import DexTable from "components/DexTable/DexTable.jsx";
import WrappedDexTable from "components/DexTable/WrappedDexTable";
import { Badge, Typography, Grid, Avatar } from "@material-ui/core";
import withSection from "components/Section/Section";
import moment from "moment";
import { checkPermission } from "helpers/permissons";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import { customerUpadateHiddenCol } from "../CustomerProfile/redux/action";
const InvoiceTable = (props) => {
  const { classes, colTable } = props;
  const { invoice } = colTable;

  function statusProvider({ value }) {
    let color = null;
    if (value === "Published") {
      color = classes.activeBadge;
    } else {
      color = classes.blockBadge;
    }
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Badge
          classes={{ badge: color }}
          variant="dot"
          style={{ marginRight: "10px" }}
        />
        <Typography variant="body1"> {value} </Typography>
      </div>
    );
  }
  const handleClick = (row) => {
    props.history.push("/app/view-invoice", {
      Invoice_Data: row,
      routeFrom: "customer-profile",
    });
  };

  const appProvider = ({ row }) => {
    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item>
          <Avatar
            style={{ width: "40px", height: "40px" }}
            alt="img"
            src={`data:image/png;base64,${row.applicationIconBase64}`}
          />
          {/* <Avatar style={{ borderRadius: "10px" }}></Avatar> */}
        </Grid>
        <Grid item className="pl-3">
          <Typography variant="body1">
            {row.name}
            <br />
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const dateTo = (info) => {
    const { row } = info;
    const newdate = row ? row.DateTo : "--";
    // var check = moment(newdate, "YYYY/MM/DD");
    // var month = check.format("M");
    // var month = moment(newdate);
    // month.month();
    return (
      <div>
        <Typography> {newdate?.split("T")[0]} </Typography>
        {/* <Typography> {month.format("ddd MMM DD YYYY")} </Typography> */}
      </div>
    );
  };
  const dateFrom = (info) => {
    const { row } = info;
    const newdate = row ? row.DateFrom : "--";
    const month = moment(newdate);
    month.month();
    return (
      <div>
        {/* <Typography> {newdate.split("T")[0]} </Typography> */}
        <Typography> {month.format(" MMM ")} </Typography>
      </div>
    );
  };
  console.log("gdhfdhgdjg", props.data);
  return (
    <div className={classes.table}>
      <WrappedDexTable
        data={[...props.data]}
        // hideColumnChooser={true}
        columnOrder={[
          "DateFrom",
          "ID",
          "TotalInvoiceAmount",
          "Status",
          "DateTo",
          //   "actions",
        ]}
        columns={[
          { name: "DateFrom", title: "Invoice Month" },
          { name: "ID", title: "Invoice Number" },
          { name: "TotalInvoiceAmount", title: "Amount" },
          { name: "Status", title: "Status" },
          { name: "DateTo", title: "Due" },
          //   { name: "actions", title: "Actions" },
        ]}
        defaultColumnWidths={[
          { columnName: "DateFrom", width: 250 },
          { columnName: "ID", width: 250 },
          { columnName: "TotalInvoiceAmount", width: 300 },
          { columnName: "Status", width: 250 },
          { columnName: "DateTo", width: 300 },
          //   { columnName: "actions", width: 200 },
        ]}
        amountColumn={["TotalInvoiceAmount"]}
        formatters={[
          { formatterFn: dateTo, columns: ["DateTo"] },
          //  { formatterFn: statusProvider, columns: ["status"] },
          { formatterFn: dateFrom, columns: ["DateFrom"] },
        ]}
        hidden={[...invoice]}
        setHiddenColmn={(hidden) => {
          props.setHiddenColumn(hidden, "invoice");
        }}
        pagination={false}
        // paginationConfig={{
        //   currentPage: this.state.page,
        //   pageSize: this.state.size,
        //   onCurrentPageChange: this.handlePageChange,
        //   onPageSizeChange: this.handleLimitChangePageRow,
        //   pageSizes: [4, 5, 6],
        //   totalCount: totalCount,
        // }}
        onClickRow={handleClick}
        Read={checkPermission("btn65")}

        // disableReordering={false}
      />
    </div>
  );
};
const mapDispatchToProps = (dispatch) => ({
  setHiddenColumn: (hidden, type) =>
    dispatch(customerUpadateHiddenCol(hidden, type)),
});
const mapStateToProps = (state) => {
  return {
    colTable: state.customerProfile.colTable,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withSection(InvoiceTable, "Invoices")));
