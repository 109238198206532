export const FETCHING_DATASTANDARDS = "FETCHING_DATASTANDARDS";
export const FETCHING_DATASTANDARDS_FAILED = "FETCHING_DATASTANDARDS_FAILED";

export const FETCH_VALUEMAP_DATA = "FETCH_VALUEMAP_DATA";
export const FETCH_VALUE_MAP_DATA_SUCCESS = "FETCH_VALUE_MAP_DATA_SUCCESS";
export const FETCH_VALUE_MAP_DATA_FAILED = "FETCH_VALUE_MAP_DATA_FAILED";

export const DELETE_VALUEMAP_DATA = "DELETE_VALUEMAP_DATA";
export const DELETE_VALUEMAP_DATA_SUCCESS = "DELETE_VALUEMAP_DATA_SUCCESS";
export const DELETE_VALUEMAP_DATA_FAILURE = "DELETE_VALUEMAP_DATA_FAILURE";

export const LOAD_LOADING = "LOAD_LOADING";

export const UPDATE_VALUE_MAP_HIDDEN_COL="UPDATE_VALUE_MAP_HIDDEN_COL";
