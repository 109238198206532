import { makeStyles } from "@material-ui/core";
export default makeStyles((theme) => ({
  // root: {
  //   marginTop: "8px",
  // },
  title: {
    textTransform: "capitalize",
    fontWeight: "bold",
    justifyContent: "center",
  },
  divider: {
    marginTop: "12px",
    height: "2px",
    background: "rgb(175, 175, 175)",
  },
}));
