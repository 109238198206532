import React, { useEffect } from "react";
import API_helpers from "../../../api/index";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { checkPermission } from "../../../helpers/permissons";
import {
  getApplicationDetails,
  publishApplication,
  outgoingApi,
  FetchDoc,
  usageDocumentaion,
  fetchTeamData,
  handleSelectTeam,
  set_ClickedTeam,
  show_selectedTeam,
} from "../ApplicationDetails/Redux/action";
import * as actionTypes from "../ApplicationDetails/Redux/actionTypes";
import CircularProgress from "@material-ui/core/CircularProgress";
import ApplicationDesc from "./ApplicationDesc";
import Environment from "./Environment";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import BillingConfig from "./BillingConfig";
import AuthorizationObjects from "./AuthorizationObjects";
import ApplicationAPI from "./ApplicationAPI";
import { Grid, Button, Backdrop } from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import ApplicationModules from "./ApplicationModules";
import ApplicationWebhooks from "./ApplicationWebhooks";
import { withStyles } from "@material-ui/core/styles";
import ApplicationAPIs from "../../../api/ApplicationAPIs/index";
import BillableServicesAPIs from "../../../api/BillableServiceAPIs/index";
import { withSnackbar } from "notistack";
import axios from "axios";
import getURL from "../../../api/config";
import OutgoingAPI from "./OutgoingApi";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import MenuIcon from "@material-ui/icons/Menu";
import ApplicationModulesDEX from "./ApplicationModules_DEX";
import ApplicationWebhooksDEX from "./ApplicationWebhooks_DEX";
import BillingConfigDEX from "./BillingConfig_DEX";
import AuthorizationObjectsDEX from "./AuthorizationObjects_DEX";
import OutgoingAPIDEX from "./OutgoingApi_DEX";
import Breadcrumb from "components/Breadcrumbs/Breadcrumb";
import moment from "moment";
import { RiEditLine } from "react-icons/ri";
import Avatar from "@material-ui/core/Avatar";
import ApplicationBasicEdit from "./ApplicationBasicEdit";
import Badge from "@material-ui/core/Badge";
import { ValidatorForm } from "react-material-ui-form-validator";
import DocumentationDEX from "./Documentation_DEX";
import AppTeam from "../Components/Teams";
import HelpdeskFAB from "../../../views/HelpdeskFAB/HelpdeskFab";

import IntroDocTable from "./IntroDocTable";
import helpers from "../../../helpers/helpers";
const useStyles = makeStyles((theme) => ({
  exampleWrapper: {
    position: "fixed",
    right: -23,
    bottom: 3,
    marginTop: theme.spacing(3),
    height: 0,
  },
  radioGroup: {
    margin: theme.spacing(1, 6),
    backgroundColor: "none",
  },
  speedDial: {
    position: "absolute",
    "&.MuiSpeedDial-directionUp, &.MuiSpeedDigetApplicationDetailsal-directionLeft":
      {
        bottom: theme.spacing(2),
        right: theme.spacing(2),
      },
    "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
  },
  compstyle: {
    backgroundColor: "red",
  },
  custbadge: {
    backgroundColor: "warning",
  },
}));

const actions = [
  {
    icon: (
      <Button
        size="small"
        style={{ fontSize: "8px" }}
        href="#appliation-webhook-section"
        color="primary"
      >
        Webhook
      </Button>
    ),
    name: "Webhook",
  },
  {
    icon: (
      <Button
        size="small"
        style={{ fontSize: "8px" }}
        href="#appliation-billable-section"
        color="primary"
      >
        Billable Services
      </Button>
    ),
    name: "Billable Services",
  },
  {
    icon: (
      <Button
        style={{ fontSize: "8px" }}
        size="small"
        href="#appliation-outgoingapis-section"
        color="primary"
      >
        Roles
      </Button>
    ),
    name: "Roles",
  },
  {
    icon: (
      <Button
        style={{ fontSize: "8px" }}
        size="small"
        href="#appliation-apis-section"
        color="primary"
      >
        Outgoing <br />
        APIs
      </Button>
    ),
    name: "Outgoing APIs",
  },
  {
    icon: (
      <Button
        style={{ fontSize: "8px" }}
        size="small"
        href="#appliation-module-section"
        color="primary"
      >
        APIs
      </Button>
    ),
    name: "API",
  },
  {
    icon: (
      <Button
        style={{ fontSize: "8px" }}
        size="small"
        href="#appliation-environment-section"
        color="primary"
      >
        Modules
      </Button>
    ),
    name: "Modules",
  },
  {
    icon: (
      <Button
        style={{ fontSize: "8px" }}
        size="small"
        href="#application-desc"
        color="primary"
      >
        Environment
      </Button>
    ),
    name: "Environment",
  },
];

function Speeddial() {
  const classes = useStyles();
  const [direction, setDirection] = React.useState("up");
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <div className={classes.exampleWrapper}>
      <SpeedDial
        ariaLabel="SpeedDial example"
        className={classes.speedDial}
        onClose={handleClose}
        icon={<MenuIcon style={{ width: "17px", color: "white" }} />}
        onOpen={handleOpen}
        open={open}
        direction={direction}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={handleClose}
          />
        ))}
      </SpeedDial>
    </div>
  );
}

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  indicator: {
    backgroundColor: "#1890ff",
  },
  tabname: {
    textTransform: "lowercase",
    fontWeight: "bold",
  },
  customBadgeDraft: {
    backgroundColor: "#FBBD08",
  },
  applicationvlline: {
    borderLeft: "2px solid #cdc8c8",
    height: "28px",
    margin: "0px 10px",
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

class Applicationview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      applicationApi: [],
      allPublishedApp: [],
      category: [],
      applicationTags: [],
      value: 0,
      billingData: null,
      billableServices: [], // stores all the master billable services fetched from billable microservices
      data: {
        authorization: {},
      },
      environmentPayload: {
        name: "",
        domainUrl: "",
        isProductionEnvironment: false,
        isHealthCheckEnable: false,
        healthCheckUrl: "",
        method: "POST",
      },
      basicInfo: {},
      editDisable: false,
      editSave: false,
      envTag: {},
      documentationData: [],
      app_id: 0,
    };
  }

  async componentDidMount() {
    const { getDetails, getDocuments, location } = this.props;
    if (location.state !== undefined) {
      const id = location.state.appId;
      localStorage.setItem("appId", id);
      getDetails(id);
      getDocuments(id, "");
      this.setState({
        app_id: id,
        editDisable: location?.state?.edit,
      });
    } else {
      this.props.history.push("/app/applications");
    }
    let status = JSON.stringify({ status: "Published" });
    await this.getCategories();
    await this.getApplicationsByStatus(status);
    await this.getApplicationsTags();
    try {
      let billableServices =
        await BillableServicesAPIs.fetchAllBillableServices();
      this.setState({ billableServices });
    } catch (err) {}
  }

  addCategory = async (value) => {
    try {
      let res = await ApplicationAPIs.add_category(value);
      let categoryId = null;
      categoryId = res?.data?.data?.categoryId || null;
      if (categoryId) {
        this.getCategories();
      }
      return categoryId;
    } catch (err) {
      // let status = 0;
      // if (err.response) {
      //   status = err.response.status;
      // }
      // return status;
      throw err;
    }
  };

  editBasicDetails = async (appId, payload) => {
    const { getDetails, location } = this.props;
    try {
      let p = { ...payload };
      delete p["_id"];
      console.log({ payload, p });
      const res = await ApplicationAPIs.editBasicDetails(appId, p);
      this.props.enqueueSnackbar(
        res.data._msg || "Application details saved successfully",
        { variant: "success", autoHideDuration: 3000 }
      );
      getDetails(appId);
      return true;
    } catch (err) {
      let msg = err.response ? err.response.data._msg : null;
      this.props.enqueueSnackbar(msg || "Something Went wrong", {
        variant: "error",
        autoHideDuration: 3000,
      });
      return false;
    }
  };

  editModule = async (appId, moduleId, payload) => {
    const { getDetails, location } = this.props;
    try {
      let p = { ...payload };
      delete p["_id"];
      console.log({ payload, p });
      const res = await ApplicationAPIs.editModule(appId, moduleId, p);
      console.log("called notfication");
      this.props.enqueueSnackbar(
        res.data._msg || "Module details saved successfully",
        { variant: "success", autoHideDuration: 3000 }
      );
      getDetails(appId);
    } catch (err) {
      this.props.enqueueSnackbar(err.message || "Something Went wrong", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  deleteModule = async (appId, moduleId) => {
    console.log({ appId, moduleId });
    const { getDetails, location } = this.props;
    try {
      const res = await ApplicationAPIs.deleteModule(appId, moduleId);
      this.props.enqueueSnackbar(
        res.data._msg || "Module details deleted successfully",
        { variant: "success", autoHideDuration: 3000 }
      );
      getDetails(appId);
    } catch (err) {
      this.props.enqueueSnackbar(
        err.response.data._msg || "Something Went wrong",
        {
          variant: "error",
          autoHideDuration: 3000,
        }
      );
    }
  };

  addModule = async (appId, payload) => {
    const { getDetails, location } = this.props;
    try {
      const res = await ApplicationAPIs.addModule(appId, payload);
      this.props.enqueueSnackbar(
        res.data._msg || "Module details saved successfully",
        { variant: "success", autoHideDuration: 3000 }
      );
      getDetails(appId);
    } catch (err) {
      this.props.enqueueSnackbar(err.message || "Something Went wrong", {
        variant: "error",
        autoHideDuration: 3000,
      });
      console.error(err);
    }
  };

  addWebhook = async (appId, payload) => {
    const { getDetails, location } = this.props;
    try {
      const res = await ApplicationAPIs.addWebhook(appId, payload);
      this.props.enqueueSnackbar(
        res.data._msg || "Webhook details added successfully",
        { variant: "success", autoHideDuration: 3000 }
      );
      getDetails(appId);
    } catch (err) {
      this.props.enqueueSnackbar(err.message || "Something Went wrong", {
        variant: "error",
        autoHideDuration: 3000,
      });
      console.error(err);
    }
  };

  editWebhook = async (appId, webhookId, payload) => {
    const { getDetails, location } = this.props;
    try {
      let p = { ...payload };
      delete p["_id"];
      console.log({ payload, p });
      const res = await ApplicationAPIs.editWebhook(appId, webhookId, p);
      console.log("called notfication");
      this.props.enqueueSnackbar(
        res.data._msg || "Webhook details saved successfully",
        { variant: "success", autoHideDuration: 3000 }
      );
      getDetails(appId);
    } catch (err) {
      this.props.enqueueSnackbar(err.message || "Something Went wrong", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  deleteWebhook = async (appId, webhookId) => {
    console.log({ appId, webhookId });
    const { getDetails, location } = this.props;
    try {
      const res = await ApplicationAPIs.deleteWebhook(appId, webhookId);
      this.props.enqueueSnackbar(
        res.data._msg || "Webhook details deleted successfully",
        { variant: "success", autoHideDuration: 3000 }
      );
      getDetails(appId);
    } catch (err) {
      this.props.enqueueSnackbar(err.message || "Something Went wrong", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  addOutgoingApi = async (appId, payload) => {
    const { getDetails } = this.props;
    console.log("payload check", payload, appId);
    let { _id, ...data } = payload;
    try {
      let res = await API_helpers.POST(getURL("OUTGOING_API")(appId), data);
      this.props.enqueueSnackbar(
        res.data._msg || "Outgoing API  added successfully",
        { variant: "success", autoHideDuration: 3000 }
      );
      getDetails(appId);
    } catch (err) {
      this.props.enqueueSnackbar(err.message || "Something Went wrong", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  addApplicationAPI = async (appId, payload) => {
    const { getDetails } = this.props;
    try {
      const res = await ApplicationAPIs.addApplicationAPI(appId, payload);
      this.props.enqueueSnackbar(
        res.data._msg || "API details added successfully",
        { variant: "success", autoHideDuration: 3000 }
      );
      getDetails(appId);
    } catch (err) {
      this.props.enqueueSnackbar(err.message || "Something Went wrong", {
        variant: "error",
        autoHideDuration: 3000,
      });
      console.error(err);
    }
  };

  editOutGoingApi = async (appId, id, payload) => {
    let { _id, ...data } = payload;
    const { getDetails } = this.props;
    console.log(data, id, "edit outgoing api");
    try {
      const res = await API_helpers.PUT(
        getURL("EDIT_OUTGOING_API")(appId, id),
        data
      );
      this.props.enqueueSnackbar(
        res.data._msg || "Outgoing API details saved successfully",
        { variant: "success", autoHideDuration: 3000 }
      );
      getDetails(appId);
    } catch (err) {
      this.props.enqueueSnackbar(err.message || "Something Went wrong", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  editApplicationAPI = async (appId, apiId, payload) => {
    const { getDetails } = this.props;
    try {
      let p = { ...payload };
      delete p["_id"];
      const res = await ApplicationAPIs.editApplicationAPI(appId, apiId, p);
      this.props.enqueueSnackbar(
        res.data._msg || "API details saved successfully",
        { variant: "success", autoHideDuration: 3000 }
      );
      getDetails(appId);
    } catch (err) {
      this.props.enqueueSnackbar(err.message || "Something Went wrong", {
        variant: "error",
        autoHideDuration: 3000,
      });
      console.error(err);
    }
  };

  deleteOutgoingApi = async (appId, id) => {
    const { getDetails } = this.props;
    try {
      const res = await API_helpers.DELETE(
        getURL("DELETE_OUTGOING_API")(appId, id)
      );
      this.props.enqueueSnackbar(
        res.data._msg || "Outgoing  API details deleted successfully",
        { variant: "success", autoHideDuration: 3000 }
      );
      getDetails(appId);
    } catch (err) {
      this.props.enqueueSnackbar(err.message || "Something Went wrong", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  deleteApplicationAPI = async (appId, apiId) => {
    const { getDetails } = this.props;
    try {
      const res = await ApplicationAPIs.deleteApplicationAPI(appId, apiId);
      this.props.enqueueSnackbar(
        res.data._msg || "Application API details deleted successfully",
        { variant: "success", autoHideDuration: 3000 }
      );
      getDetails(appId);
    } catch (err) {
      this.props.enqueueSnackbar(err.message || "Something Went wrong", {
        variant: "error",
        autoHideDuration: 3000,
      });
      console.error(err);
    }
  };

  addEnvironment = async (appId, payload) => {
    console.log("addEnvironmet", { appId, payload });
    const { getDetails } = this.props;
    try {
      const res = await ApplicationAPIs.addEnvironment(appId, payload);
      this.props.enqueueSnackbar(
        res.data._msg || "Environment details added successfully",
        { variant: "success", autoHideDuration: 3000 }
      );
      getDetails(appId);
    } catch (err) {
      this.props.enqueueSnackbar(err.message || "Something Went wrong", {
        variant: "error",
        autoHideDuration: 3000,
      });
      console.error(err);
    }
  };

  editEnvironment = async (appId, envId, payload) => {
    const { getDetails } = this.props;
    try {
      let p = { ...payload };
      delete p["_id"];
      const res = await ApplicationAPIs.editEnvironment(appId, envId, p);
      this.props.enqueueSnackbar(
        res.data._msg || "Environment details saved successfully",
        { variant: "success", autoHideDuration: 3000 }
      );
      getDetails(appId);
    } catch (err) {
      this.props.enqueueSnackbar(err.message || "Something Went wrong", {
        variant: "error",
        autoHideDuration: 3000,
      });
      console.error(err);
    }
  };

  deleteEnvironment = async (appId, evnId) => {
    const { getDetails } = this.props;
    try {
      const res = await ApplicationAPIs.deleteEnvironment(appId, evnId);
      this.props.enqueueSnackbar(
        res.data._msg || "Environment details deleted successfully",
        { variant: "success", autoHideDuration: 3000 }
      );
      getDetails(appId);
    } catch (err) {
      this.props.enqueueSnackbar(err.message || "Something Went wrong", {
        variant: "error",
        autoHideDuration: 3000,
      });
      console.error(err);
    }
  };

  addBillableService = async (appId, payload) => {
    const { getDetails } = this.props;
    try {
      const res = await ApplicationAPIs.addBillableService(appId, payload);
      this.props.enqueueSnackbar(
        res.data._msg || "BillableService details added successfully",
        { variant: "success", autoHideDuration: 3000 }
      );

      getDetails(appId);
    } catch (err) {
      this.props.enqueueSnackbar(err.message || "Something Went wrong", {
        variant: "error",
        autoHideDuration: 3000,
      });
      console.error(err);
    }
  };

  editBillableService = async (appId, billableId, payload) => {
    const { getDetails } = this.props;
    try {
      let p = { ...payload };
      delete p["_id"];
      const res = await ApplicationAPIs.editBillableService(
        appId,
        billableId,
        p
      );
      this.props.enqueueSnackbar(
        res.data._msg || "BillableService details saved successfully",
        { variant: "success", autoHideDuration: 3000 }
      );
      getDetails(appId);
    } catch (err) {
      this.props.enqueueSnackbar(err.message || "Something Went wrong", {
        variant: "error",
        autoHideDuration: 3000,
      });
      console.error(err);
    }
  };

  getApplicationsTags = async () => {
    try {
      let res = await ApplicationAPIs.getTags();
      //  axios.get(getURL("TAGS"));
      this.setState({ applicationTags: res.data.data || [] });
    } catch (err) {}
  };

  deleteBillableService = async (appId, billableId) => {
    const { getDetails } = this.props;
    try {
      const res = await ApplicationAPIs.deleteBillableService(
        appId,
        billableId
      );
      this.props.enqueueSnackbar(
        res.data._msg || "BillableService details deleted successfully",
        { variant: "success", autoHideDuration: 3000 }
      );
      getDetails(appId);
    } catch (err) {
      console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee", err.response.data._msg);
      this.props.enqueueSnackbar(
        err.response.data._msg || "Something Went wrong",
        {
          variant: "error",
          autoHideDuration: 3000,
        }
      );
      console.error(err);
    }
  };

  addRoles = async (appId, payload) => {
    console.log("addEnvironmet", { appId, payload });
    const { getDetails } = this.props;
    try {
      const res = await ApplicationAPIs.addRole(appId, payload);
      this.props.enqueueSnackbar(
        res.data._msg || "Authorization Roles added successfully",
        {
          variant: "success",
          autoHideDuration: 3000,
        }
      );
      getDetails(appId);
    } catch (err) {
      this.props.enqueueSnackbar(err.message || "Something Went wrong", {
        variant: "error",
        autoHideDuration: 3000,
      });
      console.error(err);
    }
  };

  editRoles = async (appId, roleId, payload) => {
    const { getDetails, location } = this.props;
    try {
      let p = { ...payload };
      delete p["_id"];
      const res = await ApplicationAPIs.editRole(appId, roleId, p);
      this.props.enqueueSnackbar(
        res.data._msg || "Authorization Roles saved successfully",
        {
          variant: "success",
          autoHideDuration: 3000,
        }
      );
      getDetails(appId);
    } catch (err) {
      this.props.enqueueSnackbar(err.message || "Something Went wrong", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  deleteRoles = async (appId, roleId) => {
    const { getDetails } = this.props;
    try {
      const res = await ApplicationAPIs.deleteRole(appId, roleId);
      this.props.enqueueSnackbar(
        res.data._msg || "Authorization Roles deleted Successfully",
        { variant: "success", autoHideDuration: 3000 }
      );
      getDetails(appId);
    } catch (err) {
      this.props.enqueueSnackbar(err.message || "Something Went wrong", {
        variant: "error",
        autoHideaDuration: 3000,
      });
      console.error(err);
    }
  };

  getCategories = async () => {
    try {
      let res = await ApplicationAPIs.get_Categories();
      this.setState({ category: res?.data?.data || [] });
    } catch (err) {}
  };

  getApplicationsByStatus = async (status) => {
    try {
      // let res = await axios.get(getURL("GET_APPLICATIONS_BY_STATUS")(status));
      // console.log(res.data.data, "response4343434434");
      const res = await ApplicationAPIs.getApplicationsByStatus(status);
      this.setState({
        allPublishedApp: res?.data?.data || [],
      });
    } catch (err) {}
  };

  fetchDocumentation = async (appId) => {
    try {
      let res = await ApplicationAPIs.fetchDocumentation(appId);
      if (res.data) {
        this.setState({ documentationData: res.data.data || [] });
      }
    } catch (err) {}
  };

  fetchDetails = () => {};

  static getDerivedStateFromProps(props, state) {
    console.log({ props });
    if (props.actions === actionTypes.FETCH_APPLICATION_DETAILS_FAILED) {
      props.history.push("/app/applications");
      props.enqueueSnackbar(
        props.message || "Error occurred while fetching applicaiton",
        { variant: "error", autoHideDuration: 3000 }
      );
    }

    if (props.actions == actionTypes.PUBLISHED_APPLICATION) {
      props.enqueueSnackbar(props.message || "Application status updated", {
        variant: "success",
        autoHideDuration: 2000,
      });
      props.history.push("/app/applications");
    }
    if (props.actions == actionTypes.PUBLISH_APPLICATION_FAILED) {
      props.enqueueSnackbar(
        props.message || "Application status update failed",
        {
          variant: "error",
          autoHideDuration: 3000,
        }
      );
    }
    props.updateAction();
  }

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  submitEnvironment = (value, appId, envId, type) => {
    let { environmentPayload } = this.state;
    const environment = [];
    console.log(appId, envId, environmentPayload, "sdsdsdsd");
    environment.push(value);
    if (type == "update") {
      this.props.editEnvironmentsData(appId, envId, value);
    } else {
      this.props.add_Environment(appId, value);
    }
  };

  routeToCDUMap = (appId, apiDetails) => {
    this.props.history.push("/app/map-cdu", {
      appName: this.props.appDetails.applicationDetail.name,
      appId,
      apiDetails,
      cduMapping_input: apiDetails.req,
      cduMapping_output: apiDetails.res,
    });
  };

  routeToOutputMap = (appId, outgoingAPI, apiDetails) => {
    this.props.history.push("/app/outgoingcdu", {
      appName: this.props.appDetails.applicationDetail.name,
      appId,
      apiDetails,
      outgoingAPI,
      from: "app",
    });
  };

  onClickPublishApplication = () => {
    let { _id } = this.props.appDetails.applicationDetail;
    console.log(this.props.appDetails, _id);
    let payload = { status: "Published" };
    this.props.publish_Application(payload, _id);
  };

  getApplicationsApi = async (appId) => {
    console.log(appId, "applications that are published6766");
    try {
      let res = await API_helpers.GET(
        getURL("GET_ALL_APPLICATIONS_API")(appId)
      );
      return res.data.data || [];
    } catch (err) {
      return [];
    }
  };
  onClickPublishApplication = (id) => {
    let { _id } = this.props.appDetails.applicationDetail;
    console.log(this.props.appDetails, _id);
    let payload = { status: "Published" };
    this.props.publish_Application(payload, _id);
  };

  publishApi = async (appId, apiId, payload) => {
    console.log("published", { appId, apiId });
    const { getDetails } = this.props;
    try {
      const res = await ApplicationAPIs.publishApi(appId, apiId._id);
      this.props.enqueueSnackbar(res.data._msg || "APIs published", {
        variant: "success",
        autoHideDuration: 3000,
      });
      getDetails(appId);
    } catch (err) {
      this.props.enqueueSnackbar(
        err.response.data._msg || "Something Went wrong",
        {
          variant: "error",
          autoHideDuration: 3000,
        }
      );
      console.error(err);
    }
  };

  authorizationSync = async (appId) => {
    console.log("Sync", { appId });
    const { getDetails } = this.props;
    try {
      const res = await ApplicationAPIs.authorizationSync(appId);
      this.props.enqueueSnackbar(res.data._msg || "Sync", {
        variant: "success",
        autoHideDuration: 3000,
      });
      getDetails(appId);
    } catch (err) {
      this.props.enqueueSnackbar(
        err.response.data._msg || "Something Went wrong",
        {
          variant: "error",
          autoHideDuration: 3000,
        }
      );
      console.error(err);
    }
  };

  billableSync = async (appId) => {
    console.log("Sync", { appId });
    const { getDetails } = this.props;
    try {
      const res = await ApplicationAPIs.billableSync(appId);
      this.props.enqueueSnackbar(res.data._msg || "Sync", {
        variant: "success",
        autoHideDuration: 3000,
      });
      getDetails(appId);
    } catch (err) {
      this.props.enqueueSnackbar(err.message || "Something Went wrong", {
        variant: "error",
        autoHideDuration: 3000,
      });
      console.error(err);
    }
  };

  valuesFromBasicInfo = (values) => {
    this.setState({
      basicInfo: { ...values },
    });
  };

  submitApplicationBasicInfo = async (e) => {
    let flag = false;
    e.preventDefault();

    let basicInfopayload = { ...this.state.basicInfo };
    const { name, _id } = this.props.appDetails.applicationDetail;
    console.log(name, _id, basicInfopayload);

    if (basicInfopayload.applicationType === "Only API") {
      const { isMicroFrontend, ...other } = basicInfopayload;
      basicInfopayload = { ...other };
    }

    try {
      if (name == basicInfopayload.name) {
        let { name, ...data } = basicInfopayload;
        flag = await this.editBasicDetails(_id, data);
      } else {
        flag = await this.editBasicDetails(_id, basicInfopayload);
      }
      this.props.getDetails(_id);
    } catch (err) {}
    if (flag) {
      this.setState({ editDisable: false });
    }
  };

  getenvironment_Tags = async (appId, envId) => {
    console.log("environmenttag", 98899, { appId, envId });
    const { getDetails } = this.props;
    try {
      const res = await ApplicationAPIs.getenvironmentTags(appId, envId);
      getDetails(appId);
      const { _msg, _status, data } = res.data;
      // console.log(res.data, "oiuythjk");
      return {
        _status,
        data,
        _msg,
      };
      // console.log(res, "environment");
      // return res;
    } catch (err) {
      // console.log(err.response, "oiuythjk");
      return {
        _msg: err?.response?.data?._msg || "Network issue!",
      };
    }
  };

  handleBasicEdit = () => {
    this.setState({ editDisable: true });
  };

  handleBasicCancel = () => {
    this.props.history.push("/app/applications");
    // this.setState({ editDisable: false });
  };

  getStatusColor = (status, classes) => {
    console.log(classes.customBadgeDraft, "heheytrefehfe");
    if (status == "Published") {
      return (
        <div>
          <Typography
            variant="body1"
            style={{ color: "#2185D0", fontWeight: "bold" }}
          >
            <Badge
              color="secondary"
              variant="dot"
              style={{
                marginRight: "10px",
                marginBottom: "5px",
                display: "inline-block",
              }}
            />
            Published
          </Typography>
        </div>
      );
    } else {
      return (
        <div>
          <Typography
            variant="body1"
            style={{ color: "#FBBD08", fontWeight: "bold" }}
          >
            <Badge
              classes={{ badge: classes.customBadgeDraft }}
              variant="dot"
              style={{
                marginRight: "10px",
                marginBottom: "5px",
                display: "inline-block",
              }}
            />
            Draft
          </Typography>
        </div>
      );
    }
  };

  render() {
    const { appDetails, classes, loading } = this.props;
    let check_app_status =
      appDetails.applicationDetail?.status !== "Published"
        ? true
        : false || false;
    return (
      <div>
        <ValidatorForm
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              return;
            }
          }}
          onSubmit={(e) => this.submitApplicationBasicInfo(e)}
        >
          <Backdrop open={loading} className={classes.backdrop}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <Grid container alignItems="center">
            <Grid item md={12}>
              <Grid
                container
                loading={true}
                style={{
                  position: "fixed",
                  zIndex: 889,
                  backgroundColor: "#f2f2f2",
                  width: "98%",
                  top: "55px",
                  appDetails,
                }}
              >
                <Grid item sm={12} md={12} xs={12} lg={12}>
                  <Breadcrumb
                    {...this.props}
                    links={[
                      { name: "Applications", url: "/app/applications" },
                      {
                        name: appDetails?.applicationDetail?.name,
                        url: "/app/detail",
                        routeParams: {
                          appId: appDetails?.applicationDetail?._id,
                        },
                      },
                    ]}
                  />
                </Grid>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  style={{
                    paddingBottom: "10px",
                    borderBottom: "2px solid rgba(175,175,175)",
                  }}
                >
                  <Grid item spacing={3} sm={6}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        variant="h6"
                        style={{
                          textTransform: "capitalize",
                          fontWeight: "bold",
                          justifyContent: "center",
                        }}
                      >
                        {appDetails.applicationDetail.name}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid
                    item
                    sm={6}
                    container
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={1}
                  >
                    {this.state.editDisable ? (
                      <Grid item xs={12}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            type="submit"
                            color="white"
                            variant="contained"
                            size="small"
                            style={{ padding: "5px 32px" }}
                            disabled={!checkPermission("btn115")}
                            // onClick={(e) => this.submitApplicationBasicInfo(e)}
                          >
                            {" "}
                            Save{" "}
                          </Button>{" "}
                          <Button
                            type="submit"
                            color="white"
                            variant="contained"
                            size="small"
                            style={{ padding: "5px 32px", marginLeft: 5 }}
                            onClick={() => this.handleBasicCancel()}
                          >
                            {" "}
                            Cancel{" "}
                          </Button>
                        </div>
                      </Grid>
                    ) : (
                      <Grid item xs={12}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            variant="body1"
                            style={{ color: "#212529", fontWeight: "bold" }}
                          >
                            {" "}
                            Created On -
                            {helpers.date_Func(
                              appDetails.applicationDetail.createdon
                            )}
                            {/* {moment
                              .unix(appDetails.applicationDetail.createdon)
                              .format(Helpers.getDateFormat())} */}
                          </Typography>
                          <Typography
                            variant="body1"
                            style={{ paddingLeft: 10 }}
                          >
                            {this.getStatusColor(
                              appDetails.applicationDetail.status,
                              classes
                            )}
                          </Typography>
                          <div className={classes.applicationvlline}></div>
                          <Button
                            onClick={() =>
                              checkPermission("btn115") &&
                              this.handleBasicEdit()
                            }
                            color="secondary "
                            variant="contained"
                            size="small"
                            disabled={!checkPermission("btn115")}
                            style={{ padding: "5px 32px" }}
                            startIcon={<RiEditLine />}
                          >
                            {" "}
                            Edit{" "}
                          </Button>
                          {appDetails?.applicationDetail?.status !==
                            "Published" && (
                            <Button
                              disabled={!checkPermission("btn114")}
                              onClick={() => this.onClickPublishApplication()}
                              startIcon={<SendIcon />}
                              style={{
                                // width: "100px",
                                color: "white",
                                padding: "5px 22px 5px 22px",
                                marginLeft: 10,
                                // height: "40px",
                              }}
                              variant="contained"
                              color="primary"
                              alignItems="center"
                              size="small"
                            >
                              Publish
                            </Button>
                          )}
                        </div>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <div className={classes.root}>
            <Grid
              container
              spacing={2}
              style={
                {
                  // paddingTop: "5px",
                  // marginRight: "30%",
                  // marginLeft: "-2.3%",
                }
              }
            >
              <Grid item xs={12}>
                <ApplicationDesc
                  editDisable={this.state.editDisable}
                  valuesFromBasicInfo={this.valuesFromBasicInfo}
                  category={this.state.category}
                  defaultTags={this.state.applicationTags}
                  data={appDetails.applicationDetail || {}}
                  editBasicDetails={this.editBasicDetails}
                  addCategory={this.addCategory}
                  {...this.props}
                />
                <Environment
                  appId={appDetails.applicationDetail._id}
                  appName={appDetails.applicationDetail.name}
                  appDetails={appDetails.applicationDetail}
                  addEnvironment={this.addEnvironment}
                  editEnvironment={this.editEnvironment}
                  environments={appDetails.environments || []}
                  deleteEnvironment={this.deleteEnvironment}
                  getenvironmentTags={this.getenvironment_Tags}
                  envTag={this.state.envTag}
                  {...this.props}
                />

                <ApplicationModulesDEX
                  appName={appDetails.applicationDetail.name}
                  appId={appDetails.applicationDetail._id}
                  addModule={this.addModule}
                  editModule={this.editModule}
                  deleteModule={this.deleteModule}
                  data={appDetails.applicationModules || []}
                  // modules={this.props.detail.modules}
                  {...this.props}
                />

                <ApplicationAPI
                  appId={appDetails.applicationDetail._id}
                  appName={appDetails.applicationDetail.name}
                  appStatus={appDetails.applicationDetail.status}
                  addApplicationAPI={this.addApplicationAPI}
                  editApplicationAPI={this.editApplicationAPI}
                  deleteApplicationAPI={this.deleteApplicationAPI}
                  publishApi={this.publishApi}
                  data={appDetails.applicationApi || []}
                  routeToCDUMap={this.routeToCDUMap}
                  {...this.props}
                />

                <OutgoingAPIDEX
                  allPublishedApp={this.state.allPublishedApp}
                  getAppApi={async (appId) =>
                    await this.getApplicationsApi(appId)
                  }
                  applicationApi={this.state.applicationApi}
                  outgoingAPIS={(appId, apiId, url) =>
                    this.props.outgoingAPIS(appId, apiId, url)
                  }
                  appId={appDetails.applicationDetail._id}
                  appName={appDetails.applicationDetail.name}
                  addOutgoingApi={this.addOutgoingApi}
                  editOutGoingApi={this.editOutGoingApi}
                  deleteOutgoingApi={this.deleteOutgoingApi}
                  data={appDetails.outgoingApi || []}
                  routeToCDUMap={this.routeToOutputMap}
                  {...this.props}
                />

                <AuthorizationObjectsDEX
                  webhooks={
                    appDetails.applicationDetail.authorizationWebhook || {
                      url: "",
                      method: "",
                      apiKey: "",
                    }
                  }
                  authorization={appDetails.authorization || []}
                  appName={appDetails.applicationDetail.name}
                  appId={appDetails.applicationDetail._id}
                  addRoles={this.addRoles}
                  editRoles={this.editRoles}
                  deleteRoles={this.deleteRoles}
                  authorizationSync={this.authorizationSync}
                  singleSignOn={appDetails.applicationDetail.singleSignOn}
                  {...this.props}
                />

                <BillingConfigDEX
                  billingWebhook={
                    appDetails.applicationDetail.billingWebhook || {
                      url: "",
                      method: "",
                      apiKey: "",
                    }
                  }
                  appName={appDetails.applicationDetail.name}
                  billableServices={this.state.billableServices || {}}
                  billingUsageParams={appDetails.billingUsageParams || []}
                  addBillableService={this.addBillableService}
                  editBillableService={this.editBillableService}
                  deleteBillableService={this.deleteBillableService}
                  appId={appDetails.applicationDetail._id}
                  modulesData={appDetails.applicationModules || []}
                  billableSync={this.billableSync}
                  {...this.props}
                />

                <ApplicationWebhooksDEX
                  appName={appDetails.applicationDetail.name}
                  appId={appDetails.applicationDetail._id}
                  addWebhook={this.addWebhook}
                  editWebhook={this.editWebhook}
                  deleteWebhook={this.deleteWebhook}
                  webhooks={appDetails.webhooks || []}
                  {...this.props}
                />

                <div>
                  <AppTeam
                    appName={appDetails.applicationDetail.name}
                    editDisable={this.state.editDisable}
                    appId={appDetails.applicationDetail._id}
                    historyTeamData={appDetails.teams}
                    {...this.props}
                  />
                </div>
                <div>
                  {appDetails.applicationDetail._id && (
                    <IntroDocTable
                      appData={appDetails.applicationDetail}
                      {...this.props}
                    />
                  )}
                </div>

                <DocumentationDEX
                  appData={appDetails.applicationDetail}
                  data={appDetails.introDocuments}
                  modulesData={appDetails.applicationModules || []}
                  // editWebhook={this.editWebhook}
                  // deleteWebhook={this.deleteWebhook}
                  // webhooks={appDetails.webhooks || []}
                  {...this.props}
                />
              </Grid>
            </Grid>
          </div>
        </ValidatorForm>
        <HelpdeskFAB category="Applications" />
      </div>
    );
  }
}
Applicationview.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  getDetails: (id) => dispatch(getApplicationDetails(id)),
  getDocuments: (id, type) => dispatch(FetchDoc(id, type)),
  publish_Application: (status, app_id) =>
    dispatch(publishApplication(status, app_id)),
  outgoingAPIS: (appId, apiId, url) => dispatch(outgoingApi(appId, apiId, url)),
  updateAction: () => dispatch({ type: actionTypes.UPDATE_ACTIONS }),
  deleteUsageDoc: (appId, docId, val) =>
    dispatch(usageDocumentaion(appId, docId, val)),
  getTeamsData: (filter, historyTeamData) =>
    dispatch(fetchTeamData(filter, historyTeamData)),
  handleSelectTeam: (selectedTeam, team, data, val, appId) =>
    dispatch(handleSelectTeam(selectedTeam, team, data, val, appId)),
  showSelectedTeam: (team, data, val) =>
    dispatch(show_selectedTeam(team, data, val)),
  setClickedTeam: (team) => dispatch(set_ClickedTeam(team)),
});

const mapStateToProps = (state) => {
  return {
    appDetails: state.appDetailsRedcer.applicationDetails,
    message: state.appDetailsRedcer.message,
    loading: state.appDetailsRedcer.loading,
    actions: state.appDetailsRedcer.action,
    detail: state.appDetailsRedcer.detail,
    apis: state.appDetailsRedcer.apis || [],
    usageDocumentData: state.appDetailsRedcer.usageDocumentData,
    documents: state.appDetailsRedcer.documents,
    appTeamData: state.appDetailsRedcer.appTeamData,
    success: state.appDetailsRedcer.success,
    msg: state.appDetailsRedcer.msg,
  };
};

const ApplicationDetailPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withSnackbar
)(Applicationview);

// export default withRouter(
//   connect(
//     mapStateToProps,
//     mapDispatchToProps
//   )(withStyles(styles)(withSnackbar(Applicationview)))
// );
export default ApplicationDetailPage;
