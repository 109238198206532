import { useHistory } from "react-router-dom";

const { Typography } = require("@material-ui/core");

const ErrorPageForNoAccess = () => {
  // const history = useHistory();
  // const token = JSON.parse(sessionStorage.getItem("token"));
  // if (!token) {
  //   history.push("/login");
  // }
  return (
    <div
      style={{
        width: "100%",
        justifyContent: "center",
        display: "flex",
        marginTop: 80,
      }}
    >
      <Typography variant="body2" style={{ fontWeight: 800 }}>
        {"No permission to access this page...!"}
      </Typography>
    </div>
  );
};
export default ErrorPageForNoAccess;
