import React, { Component } from "react";
import Breadcrumb from "components/Breadcrumbs/Breadcrumb";
import SearchIcon from "@material-ui/icons/Search";
import FilterComponent from "components/FilterComponent/FilterComponent";
import { Link } from "react-router-dom";
import {
  Typography,
  Grid,
  Button,
  InputAdornment,
  Tooltip,
  IconButton,
} from "@material-ui/core/";
import AddIcon from "@material-ui/icons/Add";
import actions from "./redux/action";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";
import { RiEditLine, RiDeleteBin5Line } from "react-icons/ri";
import { withSnackbar } from "notistack";
import { CustomTextField } from "components/Customized";
import DexTable from "components/DexTable/DexTable";
import { Pagination } from "@material-ui/lab";
import APIs from "api/RoleManagementAPIs/index";
import dataOptions from "./filterOptions.json";
import HelpersFunc from "helpers/helpers";
import { checkPermission } from "helpers/permissons";
import HelpdeskFAB from "../../views/HelpdeskFAB/HelpdeskFab";
import { confirmDialog } from "components/DeleteConfirmationDialog/Index";
import { dltMsg } from "helpers/helpers";
const Sytles = (theme) => ({
  footer: {
    position: "fixed",
    bottom: 0,
    left: 0,
    padding: 20,
    width: "100%",
    textAlign: "right",
    zIndex: 999,
    backgroundColor: "#f2f2f2",
  },
  customPagination: {
    width: "fit-content",
    marginLeft: "auto",
    "& .MuiPaginationItem-page.Mui-selected": {
      border: "0.5px solid #707070",
      margin: "0 10px",
      height: 30,
      width: 30,
      padding: "10px 13px",
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      boxShadow: "0px 3px 10px #5757571a",
      lineHeight: 1,
      borderRadius: 5,
      opacity: 1,
    },
    "& .MuiPaginationItem-page": {
      font: "normal normal normal 14px/19px Nunito Sans",
      letterSpacing: 0,
      color: "#000000",
      opacity: 0.6,
    },
  },
});

class RoleManagementForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data_options: [...dataOptions],
      page: 1,
      size: 10,
      filters: {},
      sort: { createdOn: -1 },
      sorting: [{ columnName: "createdon", direction: "desc" }],
      edit: false,
      searchkey: "",
      value: 0,
      openDropdown: false,
      setDropdown: false,
    };
  }
  fetchData = (filters, arr, search) => {
    let page = 1;
    this.setState({
      page: page,
    });
    let { filterProps, updateFilter } = this.props;
    updateFilter({ filter: filters, filterArray: arr });
    let { size } = this.state;
    this.props.getData(
      page,
      size,
      JSON.stringify(filters),
      JSON.stringify(filterProps.sort)
    );
    if (!search) {
      this.setState({
        searchkey: "",
      });
    }
  };

  async componentDidMount() {
    let { filterProps } = this.props;
    this.props.getData(
      1,
      this.state.size,
      JSON.stringify(filterProps.filters),
      JSON.stringify(filterProps.sort)
    );
    // this.fetchData(filterProps.filters, filterProps.filterArray);
  }

  filterFunc = (arr) => {
    let filters = {};
    if (arr.length === 1) {
      const { type, operator, value } = arr[0];
      console.log(value);
      var category_id = this.props.data;
      console.log(category_id, operator, value, "catid");
      let filter = HelpersFunc.findAndExecuteAjax(type)(operator, value);
      this.getApplicationsByFilter({ ...filter }, arr);
    } else {
      arr.map((item, id) => {
        const { type, operator, value } = item;
        let filter = HelpersFunc.findAndExecuteAjax(type)(operator, value);
        filters = { ...filters, ...filter };
      });
      this.getApplicationsByFilter({ ...filters }, arr);
    }
  };

  getApplicationsByFilter = async (filters, arr) => {
    this.fetchData(filters, arr);
  };

  openDropDown = () => {
    this.setState({ openDropDown: true });
  };
  dropUp = () => {
    this.setState({ setDropdown: true });
  };

  handleSortByChange = (sorting) => {
    let { filterProps, updateSorting, getData } = this.props;
    let filters = { ...filterProps.filters };
    let sort = {};
    sorting.forEach((f) => {
      sort[f.columnName] = f.direction == "asc" ? 1 : -1;
    });
    updateSorting({ sorting, sort });
    getData(
      this.state.page,
      this.state.size,
      JSON.stringify(filters),
      JSON.stringify(sort)
    );
  };

  // handlePageChange = async (e, newPage) => {
  //   console.log("newpage", typeof newPage, newPage);
  //   this.setState({
  //     page: newPage,
  //   });
  //   let { filterProps } = this.props;
  //   if (this.state.searchkey.trim().length !== 0) {
  //     //when search is enabled while user click pagination we need this function!
  //     this.searchAndPaginationHelperFunc(newPage, this.state.searchkey);
  //   } else {
  //     await this.props.getData(
  //       newPage,
  //       this.state.size,
  //       JSON.stringify(filterProps.filters),
  //       JSON.stringify(filterProps.sort)
  //     );
  //   }
  // };

  handlePageChange = async (e, newPage) => {
    this.setState({
      page: newPage,
    });
    this.fetchingData(newPage);
  };

  fetchingData = async (Page) => {
    let { filterProps } = this.props;
    if (this.state.searchkey.trim().length !== 0) {
      this.searchAndPaginationHelperFunc(
        Page,
        this.state.searchkey,
        filterProps
      );
    } else {
      await this.props.getData(
        Page,
        this.state.size,
        JSON.stringify(filterProps.filters),
        JSON.stringify(filterProps.sort)
      );
    }
  };

  onChangeSearchAppName = (event) => {
    let page = 1;
    this.setState({
      searchkey: event.target.value,
      page: page,
    });
    this.searchAndPaginationHelperFunc(
      page,
      event.target.value,
      this.props.filterProps
    );
  };

  // searchAndPaginationHelperFunc = (page, val) => {
  //   let { filterProps } = this.props;
  //   let filtersCopy = { ...filterProps.filters };
  //   let filters = {
  //     ...filtersCopy,
  //     name: { $regex: "^" + val, $options: "i" },
  //   };
  //   // this.fetchData(filters, filterProps.filterArray, true);
  //   this.props.getData(
  //     page,
  //     this.state.size,
  //     JSON.stringify(filters),
  //     JSON.stringify(filterProps.sort)
  //   );
  // };

  searchAndPaginationHelperFunc = (page, val, filter_Props) => {
    this.props.getData(
      page,
      this.state.size,
      JSON.stringify({
        ...filter_Props.filters,
        name: { $regex: "^" + val, $options: "i" },
      }),
      JSON.stringify(filter_Props.sort)
    );
  };

  removeRoledata = async (row, e) => {
    e && e.stopPropagation();
    let { page, size, filters, sort } = this.state;
    try {
      let res = await APIs.deleteMasterRolemanagement(row._id);
      this.props.enqueueSnackbar(res.data._msg || "Role deleted successfully", {
        variant: "success",
        autoHideDuration: 2000,
      });
      console.log("hsvahgfasg", res);
      const rem = (this.props.totalCount - 1) % 10;
      if (rem === 0 && this.state.page !== 1) {
        this.setState({
          page: page - 1,
        });

        this.fetchingData(this.state.page);
        // await this.props.getData(page - 1, size, filters, sort);
      } else {
        this.fetchingData(this.state.page);
        // await this.props.getData(page, size, filters, sort);
      }
    } catch (err) {
      this.props.enqueueSnackbar(
        err.response.data._msg || "something went wrong!",
        {
          variant: "error",
          autoHideDuration: 2000,
        }
      );
    }
  };

  editRoledata = (row, e) => {
    e && e.stopPropagation();
    let data = { ...row };
    console.log(row, "this is data");
    this.props.history.push("/app/create-rolemanagement", {
      roleData: data,
      edit: true,
      ajax: true,

      disabled: false,
    });
  };

  actionProvider = ({ row }) => {
    return (
      // <div
      //   style={{
      //     display: "flex",
      //   }}
      // >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <Tooltip title="Edit" aria-label="edit">
            <IconButton
              color="inherit"
              size="small"
              disabled={!checkPermission("btn41")}
              onClick={(e) =>
                checkPermission("btn41") && this.editRoledata(row, e)
              }
            >
              <RiEditLine fontSize="20px" />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title="Delete" aria-label="delete">
            <IconButton
              size="small"
              color="inherit"
              disabled={!checkPermission("btn42")}
              onClick={(e) => {
                e.stopPropagation();
                checkPermission("btn42") &&
                  confirmDialog(dltMsg(row?.name || ""), () =>
                    this.removeRoledata(row, e)
                  );
                // this.removeRoledata(row, e)
              }}
            >
              <RiDeleteBin5Line fontSize="20px" />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    );
  };

  viewRoledata = (row) => {
    let data = { ...row };
    this.props.history.push("/app/create-rolemanagement", {
      roleData: data,
      edit: true,
      disabled: true,
    });
  };

  createRole = () => {
    // claims: [
    //   {
    //     resourcetype: "",
    //     permissions: [""],
    //     fieldscope: {},
    //   },
    // ],
    let data = {
      name: "",
      claims: [],
    };
    this.props.history.push("/app/create-rolemanagement", {
      roleData: data,
      edit: false,
    });
  };
  render() {
    console.log("Data", this.props.data);
    let { data_options } = this.state;
    let { data, classes, loading, totalCount, filterProps, hidden } =
      this.props;

    return (
      <>
        <Grid container>
          <Grid item sm={12} md={12} xs={12} lg={12}>
            <Breadcrumb
              {...this.props}
              links={[
                { name: "Roles" },
                {
                  name: "Roles List",
                },
              ]}
            />
          </Grid>
          <Grid item sm={7} md={7} lg={7} xs={12}>
            <Typography variant="h6" style={{ fontWeight: "bold" }}>
              Roles {`( ${this.props.totalCount} )`}
            </Typography>
          </Grid>
          <Grid item sm={5} md={5} lg={5} xs={12}>
            <Grid container spacing={2}>
              <Grid item sm={5} md={5} lg={5} xs={12}>
                <CustomTextField
                  className=" mt-0 "
                  autoComplete="off"
                  fullWidth
                  id="filled-search"
                  type="text"
                  variant="outlined"
                  size="small"
                  placeholder="Search"
                  value={this.state.searchkey}
                  margin="normal"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {" "}
                        <SearchIcon />{" "}
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    this.onChangeSearchAppName(e);
                  }}
                />
              </Grid>
              <Grid item sm={3} md={3} lg={3} xs={12}>
                <FilterComponent
                  getDataByFilter={this.getApplicationsByFilter}
                  dataOptions={data_options}
                  filterFunc={this.filterFunc}
                  filterHistory={filterProps.filterArray}
                />
              </Grid>
              <Grid item sm={4} md={4} lg={4} xs={12}>
                <Button
                  style={{
                    color: "white",
                    width: "100%",
                    boxShadow: "none",
                    padding: "6px 11px 6px 11px",
                  }}
                  variant="contained"
                  color="primary"
                  size="small"
                  alignItems="center"
                  disabled={!checkPermission("btn40")}
                  onClick={() => checkPermission("btn40") && this.createRole()}
                  startIcon={<AddIcon />}
                >
                  Add Role
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12} md={12} xs={12} lg={12} className="mt-2">
            <DexTable
              data={[...data]}
              columnOrder={[
                "name",
                "numUsers",
                "createdby",
                "createdon",
                "actions",
              ]}
              columns={[
                { name: "name", title: "Role Name" },
                { name: "numUsers", title: "No. Of Users" },
                { name: "createdby", title: "Created By" },
                { name: "createdon", title: "Created On" },
                { name: "actions", title: "Actions" },
              ]}
              columWidthsType="percentage"
              defaultColumnWidths={[
                { columnName: "name", width: 20 },
                { columnName: "numUsers", width: 25 },
                { columnName: "createdby", width: 20 },
                { columnName: "createdon", width: 15 },
                { columnName: "actions", width: 18 },
              ]}
              formatters={[
                { formatterFn: this.actionProvider, columns: ["actions"] },
              ]}
              hidden={[...hidden]}
              onClickRow={(row) => this.viewRoledata(row)}
              Read={checkPermission("btn43")}
              sorting={filterProps.sorting}
              onSortingChange={this.handleSortByChange}
              dateColumns={["createdon"]}
              disableReordering={false}
              height="60vh"
              tableType="virtual"
              setHiddenColmn={(hidden) => {
                this.props.setHiddenColumn(hidden);
              }}
            />
          </Grid>

          {data.length !== 0 && (
            <div className={classes.footer}>
              <div className={classes.customPagination}>
                <Pagination
                  count={Math.ceil(totalCount / 10)}
                  page={this.state.page}
                  onChange={this.handlePageChange}
                  shape="rounded"
                  showFirstButton
                  showLastButton
                />
              </div>
            </div>
          )}
          <HelpdeskFAB category="Roles" />
        </Grid>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    data: state.roleManagement.data,
    loading: state.roleManagement.loading,
    totalCount: state.roleManagement.totalCount,
    filterProps: state.roleManagement.filterProps,
    hidden: state.roleManagement.hidden,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getData: (pageno, size, filters, sort) =>
      dispatch(
        actions.actionfetchAllUserManagementroles(pageno, size, filters, sort)
      ),
    updateFilter: (filter) => dispatch(actions.updateFilterData(filter)),
    updateSorting: (sortProps) => dispatch(actions.updateSort(sortProps)),
    setHiddenColumn: (hidden) => dispatch(actions.updateHiddenTableCol(hidden)),

    //   deleteUserManagement: (usermanagementId) =>
    //     dispatch(actions.deleteUserManagement(usermanagementId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(Sytles)(withSnackbar(RoleManagementForm)));
