import React, { useEffect, useState } from "react";
import Breadcrumb from "components/Breadcrumbs/Breadcrumb";
import Loader from "../../components/common/stuff/Loader";
import { useSelector, useDispatch } from "react-redux";
import nodataimg from "assets/images/nodata.svg";

import * as action from "./Redux/action";
import {
  Typography,
  Grid,
  Button,
  InputAdornment,
  Divider,
} from "@material-ui/core/";
import SearchIcon from "@material-ui/icons/Search";
import CustomSankey from "./components/Sankey";
import { CustomTextField } from "components/Customized";
import { MuiNativeSelect } from "components/DropDown/MuiDropDown";
const titleStyle = {
  textTransform: "capitalize",
  fontWeight: "bold",
  justifyContent: "center",
  paddingBottom: "0px",
};
const options = [
  { label: "5s", value: 5 },
  { label: "10s", value: 10 },
  { label: "20s", value: 20 },
  { label: "30s", value: 30 },
  { label: "1m", value: 60 },
  { label: "2m", value: 120 },
  { label: "3m", value: 180 },
];
const Monitoring = (props) => {
  const [time_, setTime_] = useState(10);
  const dispatch = useDispatch();
  const monitor_state = useSelector((state) => state.monitorReducer);
  const { loading, sankeyData, graphData, toolTip } = monitor_state;
  useEffect(() => {
    return () => {
      dispatch(action.terminateToolTip());
    };
  }, []);

  useEffect(() => {
    dispatch(action.messageBrokerData(time_));
    const interval = setInterval(() => {
      dispatch(action.messageBrokerData(time_));
    }, time_ * 1000);
    return () => {
      clearInterval(interval);
    };
  }, [time_]);

  const onClickLinkFunc = (e) => {
    dispatch(action.setUpLinkToolTip(e, sankeyData));
  };
  const onClickNodeFunc = (e) => {
    dispatch(action.setUpNodeToolTip(e, sankeyData));
  };

  return (
    <>
      <Breadcrumb
        {...props}
        links={[
          { name: "Monitoring" },
          {
            name: "Monitoring List ",
          },
        ]}
      />
      <Grid container spacing={1}>
        <Grid item sm={12} md={12} xs={12} lg={12}></Grid>
        <Grid item md={12}>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h6" style={titleStyle}>
                  Message Broker Monitoring
                </Typography>
              </div>
            </Grid>
            <Grid item md={6}>
              <Grid
                container
                spacing={2}
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <Grid item md={5}>
                  <CustomTextField
                    className=" mt-0 "
                    autoComplete="off"
                    fullWidth
                    id="filled-search"
                    type="text"
                    variant="outlined"
                    size="small"
                    placeholder="Search Application"
                    // value={this.state.searchkey}
                    margin="normal"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {" "}
                          <SearchIcon />{" "}
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {}}
                  />
                </Grid>
                <Grid item md={2}>
                  <div>
                    <MuiNativeSelect
                      options={options}
                      value={time_}
                      title="Refresh Interval"
                      handleChange={(e) => setTime_(e.target.value || 10)}
                    />
                    <div style={{ padding: 4 }}></div>
                  </div>
                </Grid>
                <Grid item md={2}>
                  <div>
                    <Button
                      name="single"
                      size="small"
                      variant="contained"
                      color="primary"
                      onClick={() => {}}
                      fullWidth
                      style={{ color: "white" }}
                    >
                      Filters
                    </Button>
                    <div style={{ padding: 4 }}></div>
                  </div>
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={12}>
              <Divider
                sytle={{
                  marginTop: "12px",
                  height: "2px",
                  background: "rgb(175, 175, 175)",
                }}
              />
            </Grid>
            <Grid item sm={12} md={12} lg={12}>
              {graphData.length > 0 ? (
                <div style={{ width: "100%" }}>
                  <CustomSankey
                    graphData={graphData}
                    sankeyData={sankeyData}
                    toolTip={toolTip}
                    onClickLinkFunc={onClickLinkFunc}
                    onClickNodeFunc={onClickNodeFunc}
                  />
                </div>
              ) : (
                <NoDataFound />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const NoDataFound = () => {
  return (
    <>
      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            // border: "solid 0.5px rgba(0, 0, 0, 0.12)",
            width: "50%",
            textAlign: "center",
            padding: 8,
          }}
        >
          <Typography
            style={{
              fontSize: "20px",
              fontWeight: 600,
              textAlign: "center",
            }}
          >
            {"Currently no listeners are running"}
          </Typography>
          <img src={nodataimg} alt="notfound" />
        </div>
      </div>
    </>
  );
};

export default Monitoring;
