import React, { Component } from "react";
import PrintDetails from "./PrintDetails";
import { Button, Grid } from "@material-ui/core";
import PrintComponents from "react-print-components";
import { RiDownload2Line } from "react-icons/ri";

class PrintInvoice extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div style={{ width: "100%" }}>
        <Grid container>
          <Grid item md={12}>
            <PrintComponents
              trigger={
                <Button
                  className={classes.button}
                  variant="outlined"
                  color="primary"
                  size="small"
                  alignItems="center"
                  startIcon={<RiDownload2Line />}
                >
                  Download Invoice
                </Button>
              }
            >
              <div style={{ width: "100%" }}>
                <PrintDetails {...this.props} />
              </div>
            </PrintComponents>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default PrintInvoice;
