/**
 *
 */

import React, { Component } from "react";
import Breadcrumb from "components/Breadcrumbs/Breadcrumb";
import { CustomTextField } from "components/Customized";
import SearchIcon from "@material-ui/icons/Search";
import FilterComponent from "components/FilterComponent/FilterComponent";
import { Link } from "react-router-dom";
import {
  Typography,
  Grid,
  Button,
  InputAdornment,
  Tooltip,
  IconButton,
} from "@material-ui/core/";
import AddIcon from "@material-ui/icons/Add";
import actions from "./redux/action";
import { connect } from "react-redux";
import DexTable from "components/DexTable/DexTable";
import { withStyles } from "@material-ui/styles";
import { Pagination } from "@material-ui/lab";
import { RiEditLine, RiDeleteBin5Line } from "react-icons/ri";
import { withSnackbar } from "notistack";
import appApi from "api/ApplicationAPIs/index";
import dataOptions from "./filterOptions.json";
import HelpersFunc from "helpers/helpers";
import APIs from "../../api/BillableServiceAPIs/index";
import { checkPermission } from "../../helpers/permissons";
import HelpdeskFAB from "../../views/HelpdeskFAB/HelpdeskFab";
import { confirmDialog } from "components/DeleteConfirmationDialog/Index";
import { dltMsg } from "helpers/helpers";
const Sytles = (theme) => ({
  footer: {
    position: "fixed",
    bottom: 0,
    left: 0,
    padding: 20,
    width: "100%",
    textAlign: "right",
    zIndex: 999,
    backgroundColor: "#f2f2f2",
  },
  customPagination: {
    width: "fit-content",
    marginLeft: "auto",
    "& .MuiPaginationItem-page.Mui-selected": {
      border: "0.5px solid #707070",
      margin: "0 10px",
      height: 30,
      width: 30,
      padding: "10px 13px",
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      boxShadow: "0px 3px 10px #5757571a",
      lineHeight: 1,
      borderRadius: 5,
      opacity: 1,
    },
    "& .MuiPaginationItem-page": {
      font: "normal normal normal 14px/19px Nunito Sans",
      letterSpacing: 0,
      color: "#000000",
      opacity: 0.6,
    },
  },
});

class BillingMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      size: 10,
      filters: {},
      sort: { createdOn: -1 },
      sorting: [{ columnName: "createdOn", direction: "desc" }],
      edit: false,
      searchkey: "",
      value: 0,
      openDropdown: false,
      setDropdown: false,
      filterData: [],
      data_options: [...dataOptions],
    };
  }

  getCategories = async () => {
    try {
      let res = await APIs.getAllCategories();
      if (res.data && res.data.data) {
        this.setState({ category: [...res.data.data] });
        let filter_Data = this.state.data_options.map((item, id) => {
          if (item.value === "Category") {
            return {
              ...item,
              fieldValues: res.data.data.map((e, id) => {
                return {
                  key: id + 1,
                  value: e.name,
                };
              }),
            };
          } else {
            return { ...item };
          }
        });
        this.setState({ data_options: [...filter_Data] });
      }
    } catch (err) {}
  };

  componentDidMount() {
    let { filterProps } = this.props;
    let { page, size } = this.state;
    this.getCategories();
    this.props.getData(
      page,
      size,
      JSON.stringify(filterProps.filters),
      JSON.stringify(filterProps.sort)
    );
  }

  filterFunc = (arr) => {
    let filters = {};

    if (arr.length === 1) {
      let { columns, operator, value, type } = arr[0];
      // console.log(value, "dfghjk4567898765434567");
      if (columns === "Category") {
        this.state.category.map((item, id) => {
          if (value === item.name) {
            value = item._id;
          }
        });
      }
      let filter = HelpersFunc.findAndExecuteAjax(type)(operator, value);
      this.getApplicationsByFilter({ ...filter }, arr);
    } else {
      arr.map((item, id) => {
        let { columns, operator, value, type } = item;
        if (columns === "Category") {
          this.state.category.map((item, id) => {
            if (value === item.name) {
              value = item._id;
            }
          });
        }
        let filter = HelpersFunc.findAndExecuteAjax(type)(operator, value);
        filters = { ...filters, ...filter };
      });
      this.getApplicationsByFilter({ ...filters }, arr);
    }
  };

  getApplicationsByFilter = async (filters, arr, search) => {
    let page = 1;
    this.setState({
      page: 1,
    });
    let { filterProps, updateFilter } = this.props;
    updateFilter({ filter: filters, filterArray: arr });
    this.props.getData(
      page,
      this.state.size,
      JSON.stringify(filters),
      JSON.stringify(filterProps.sort)
    );
    if (!search) {
      this.setState({
        searchkey: "",
      });
    }
  };

  openDropDown = () => {
    this.setState({ openDropDown: true });
  };
  dropUp = () => {
    this.setState({ setDropdown: true });
  };

  handleSortByChange = (sorting) => {
    let { filterProps, updateSorting, getData } = this.props;
    let filters = { ...filterProps.filters };
    let sort = {};
    sorting.forEach((f) => {
      sort[f.columnName] = f.direction == "asc" ? 1 : -1;
    });
    updateSorting({ sorting, sort });
    getData(
      this.state.page,
      this.state.size,
      JSON.stringify(filters),
      JSON.stringify(sort)
    );
  };
  // handlePageChange = async (e, newPage) => {
  //   this.setState({
  //     page: newPage,
  //   });

  //   let { filterProps } = this.props;
  //   if (this.state.searchkey.trim().length !== 0) {
  //     //when search is enabled while user click pagination we need this function!
  //     this.searchAndPaginationHelperFunc(newPage, this.state.searchkey);
  //   } else {
  //     await this.props.getData(
  //       newPage,
  //       this.state.size,
  //       JSON.stringify(filterProps.filters),
  //       JSON.stringify(filterProps.sort)
  //     );
  //   }
  // };
  handlePageChange = async (e, newPage) => {
    this.setState({
      page: newPage,
    });
    this.fetchingData(newPage);
  };

  fetchingData = async (Page) => {
    let { filterProps } = this.props;
    if (this.state.searchkey.trim().length !== 0) {
      this.searchAndPaginationHelperFunc(
        Page,
        this.state.searchkey,
        filterProps
      );
    } else {
      await this.props.getData(
        Page,
        this.state.size,
        JSON.stringify(filterProps.filters),
        JSON.stringify(filterProps.sort)
      );
    }
  };
  // searchAndPaginationHelperFunc = (page, val) => {
  //   let { filterProps } = this.props;
  //   let filtersCopy = { ...filterProps.filters };
  //   let filters = {
  //     ...filtersCopy,
  //     name: { $regex: "^" + val, $options: "i" },
  //   };

  //   // this.getApplicationsByFilter(filters, filterProps.filterArray, true);
  //   this.props.getData(
  //     page,
  //     this.state.size,
  //     JSON.stringify(filters),
  //     JSON.stringify(filterProps.sort)
  //   );
  // };
  searchAndPaginationHelperFunc = (page, val, filter_Props) => {
    this.props.getData(
      page,
      this.state.size,
      JSON.stringify({
        ...filter_Props.filters,
        name: { $regex: "^" + val, $options: "i" },
      }),
      JSON.stringify(filter_Props.sort)
    );
  };
  onChangeSearchAppName = (event) => {
    let page = 1;
    this.setState({
      searchkey: event.target.value,
      page: page,
    });
    this.searchAndPaginationHelperFunc(
      page,
      event.target.value,
      this.props.filterProps
    );
  };

  // editBilling = (row) => {

  //   this.props.history.push("/app/edit_billable", {
  //     billingData: row,
  //     edit: true,
  //     ajax: true,
  //   });
  //   console.log(row, "111");
  // };

  editBilling = (row, e) => {
    e && e.stopPropagation();

    let data = { ...row, category: row.category._id };
    this.props.history.push("/app/edit_billable", {
      billingData: data,
      edit: true,
      disabled: e ? false : true,
    });
  };

  removeBilling = async (row, e) => {
    e && e.stopPropagation();
    let { filterProps } = this.props;
    let { page, size } = this.state;

    let res = await this.props.deleteBillableServices(row._id);
    const { _status, _msg } = res;
    this.props.enqueueSnackbar(_msg, {
      variant: _status ? "success" : "error",
      autoHideDuration: 2000,
    });
    this.fetchingData(this.state.page);
    // _status &&
    //   (await this.props.getData(
    //     page,
    //     size,
    //     filterProps.filters,
    //     filterProps.sort
    //   ));
  };

  actionProvider = ({ row, e }) => {
    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <Tooltip title="Edit" aria-label="edit">
            <IconButton
              color="inherit"
              size="small"
              disabled={!checkPermission("btn5")}
              onClick={(e) =>
                checkPermission("btn5") && this.editBilling(row, e)
              }
            >
              <RiEditLine fontSize="20px" />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title="Delete" aria-label="delete">
            <IconButton
              size="small"
              color="inherit"
              disabled={!checkPermission("btn6")}
              onClick={(e) => {
                e.stopPropagation();
                checkPermission("btn6") &&
                  confirmDialog(dltMsg(row?.name || ""), () =>
                    this.removeBilling(row, e)
                  );
              }}
            >
              <RiDeleteBin5Line fontSize="20px" />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    );
  };

  categoryProvider = (item) => {
    let category = item.value || [];
    return (category || {}).name || "";
  };

  createBilling = () => {
    let data = {
      name: "",
      unit: "",
      category: "",
      description: "",
    };
    this.props.history.push("/app/edit_billable", {
      billingData: data,
      edit: false,
      ajax: true,
    });
  };

  viewBillingdata = (row) => {
    this.editBilling(row);
  };

  render() {
    console.log("Data", this.props.data);
    let { data, totalCount, loading, classes, filterProps, hidden } =
      this.props;
    let { data_options } = this.state;

    return (
      <Grid container>
        <Grid item sm={12} md={12} xs={12} lg={12}>
          <Breadcrumb
            {...this.props}
            links={[
              { name: "Billable Services", url: "/app/billing" },
              {
                name: "Billable Services List",
              },
            ]}
          />
        </Grid>
        <Grid item sm={7} md={7} lg={7} xs={12}>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            Billable Services {`( ${this.props.totalCount} )`}
          </Typography>
        </Grid>
        <Grid item sm={5} md={5} lg={5} xs={12}>
          <Grid container spacing={2}>
            <Grid item sm={5} md={5} lg={5} xs={12}>
              <CustomTextField
                className=" mt-0 "
                autoComplete="off"
                fullWidth
                id="filled-search"
                type="text"
                variant="outlined"
                size="small"
                placeholder="Search"
                value={this.state.searchkey}
                margin="normal"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {" "}
                      <SearchIcon />{" "}
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  this.onChangeSearchAppName(e);
                }}
              />
            </Grid>
            <Grid item sm={3} md={3} lg={3} xs={12}>
              <FilterComponent
                getDataByFilter={this.getApplicationsByFilter}
                dataOptions={data_options}
                filterFunc={this.filterFunc}
                filterHistory={filterProps.filterArray}
              />
            </Grid>
            <Grid item sm={4} md={4} lg={4} xs={12}>
              <Button
                style={{
                  color: "white",
                  width: "100%",
                  boxShadow: "none",
                  padding: "6px 11px 6px 11px",
                }}
                disabled={!checkPermission("btn3")}
                variant="contained"
                color="primary"
                size="small"
                alignItems="center"
                onClick={() => checkPermission("btn3") && this.createBilling()}
                startIcon={<AddIcon />}
              >
                Create Billable Service
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12} md={12} xs={12} lg={12} className="mt-2">
          <DexTable
            data={[...data]}
            columnOrder={[
              "name",
              "category",
              "description",
              "unit",
              "createdOn",
              "actions",
            ]}
            columns={[
              { name: "name", title: "Billable Service Name" },
              { name: "category", title: "Category" },
              { name: "description", title: "Description" },
              { name: "unit", title: "Unit" },
              { name: "createdOn", title: "Created On" },
              { name: "actions", title: "Actions" },
            ]}
            columWidthsType="percentage"
            defaultColumnWidths={[
              // { columnName: "name", width: 20 },
              // { columnName: "unit", width: 15 },
              // { columnName: "description", width: 20 },
              // { columnName: "category", width: 15 },
              // { columnName: "createdOn", width: 15 },
              // { columnName: "actions", width: 14 },
              { columnName: "name", width: 20 },
              { columnName: "unit", width: 18 },
              { columnName: "description", width: 20 },
              { columnName: "category", width: 18 },
              { columnName: "createdOn", width: 20 },
              { columnName: "actions", width: 15 },
            ]}
            formatters={[
              { formatterFn: this.actionProvider, columns: ["actions"] },
              { formatterFn: this.categoryProvider, columns: ["category"] },
            ]}
            sorting={filterProps.sorting}
            onSortingChange={this.handleSortByChange}
            dateColumns={["createdOn"]}
            disableReordering={false}
            hidden={[...hidden]}
            // height="520px"
            height="60vh"
            loading={loading}
            tableType="virtual"
            onClickRow={(row) => this.viewBillingdata(row)}
            setHiddenColmn={(hidden) => {
              this.props.setHiddenColumn(hidden);
            }}
            Read={checkPermission("btn4")}
          />
        </Grid>
        {data.length !== 0 && (
          <div className={classes.footer}>
            <div className={classes.customPagination}>
              <Pagination
                count={Math.ceil(totalCount / 10)}
                page={this.state.page}
                onChange={this.handlePageChange}
                shape="rounded"
                showFirstButton
                showLastButton
              />
            </div>
          </div>
        )}
        <HelpdeskFAB category="Billable Services" />
      </Grid>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    data: state.billingMaster.data,
    loading: state.billingMaster.loading,
    totalCount: state.billingMaster.totalCount,
    filterProps: state.billingMaster.filterProps,
    hidden: state.billingMaster.hidden,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getData: (pageno, size, filters, sort) =>
      dispatch(
        actions.actionFetchBillableServices(pageno, size, filters, sort)
      ),
    deleteBillableServices: (billableId) =>
      dispatch(actions.deleteBillableServices(billableId)),
    updateFilter: (filter) => dispatch(actions.updateFilterData(filter)),
    updateSorting: (sortProps) => dispatch(actions.updateSort(sortProps)),
    setHiddenColumn: (hidden) =>
      dispatch(actions.udateBillingHiddenCol(hidden)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(Sytles)(withSnackbar(BillingMaster)));

// findAndExecuteAjax = (type) => {
//   switch (type) {
//     case "Application Name":
//       return (operator, value) => {
//         if (operator === "Contains of") {
//           return { name: { $regex: value, $options: "i" } };
//         }
//         if (operator === "Starts With") {
//           return { name: { $regex: `^${value}`, $options: "i" } };
//         }
//         if (operator === "Ends With") {
//           return { name: { $regex: `${value}$`, $options: "i" } };
//         }
//         if (operator === "Equals To") {
//           return { name: { $regex: `^${value}$`, $options: "i" } };
//         }
//       };

//     case "Created On":
//       return (operator, value) => {
//         console.log(operator, 23456543234);
//         var timestamp = new Date(value.split(".").join("-")).getTime() / 1000;
//         if (operator === "Equals To") {
//           let gte = new Date(value.split(".").join("-")).getTime() / 1000;
//           let lte = new Date(`${value}T23:59:59+05:30`).getTime() / 1000;
//           return { createdOn: { $gte: gte, $lte: lte } };
//         }
//         // if (operator === "Equals To") {
//         //   return { createdOn: { $eq: timestamp } };
//         // }
//         if (operator === "Greater than") {
//           return { createdOn: { $gt: timestamp } };
//         }
//         if (operator === "Lesser than") {
//           return { createdOn: { $lt: timestamp } };
//         }
//         if (operator === "Greater than or Equal To") {
//           return { createdOn: { $gte: timestamp } };
//         }
//         if (operator === "Less than or Equals To") {
//           return { createdOn: { $lte: timestamp } };
//         }
//       };
//     case "Category":
//       return (operator, value) => {
//         let val = 0;
//         this.state.category.map((item, id) => {
//           if (value === item.name) {
//             val = item._id;
//           }
//         });
//         if (operator === "Equals To") {
//           return { category: val };
//         }
//       };

//     case "Unit":
//       return (operator, value) => {
//         if (operator === "Contains of") {
//           return { unit: { $regex: value, $options: "i" } };
//         }
//         if (operator === "Starts With") {
//           return { unit: { $regex: `^${value}`, $options: "i" } };
//         }
//         if (operator === "Ends With") {
//           return { unit: { $regex: `${value}$`, $options: "i" } };
//         }
//         if (operator === "Equals To") {
//           return { name: { $regex: `^${value}$`, $options: "i" } };
//         }
//       };

//     default:
//       return {};
//   }
// };
