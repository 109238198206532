import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import { useEffect } from "react";
import ContextApi from "../../../api/Context/index";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Divider } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import Tooltip from "@material-ui/core/Tooltip";
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const style_ = {
  textTransform: "none",
};

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.white,
  },
  body: {
    backgroundColor: "#ffffff",
  },
  btn: {
    width: "300px",
  },
}))(TableCell);

function ClipboardCopy({ copyText }) {
  const [isCopied, setIsCopied] = useState(false);

  // This is the function we wrote earlier
  async function copyTextToClipboard(text) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  }

  // onClick handler function for the copy button
  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <Tooltip title={isCopied ? "Copied!" : "Copy"}>
        <Link onClick={handleCopyClick}> {copyText} </Link>
      </Tooltip>
    </div>
  );
}

export default function ContextDialog(props) {
  const [open, setOpen] = React.useState(false);
  const { appId, row } = props;
  const classes = useStyles();
  const [data, setData] = useState({});
  const [err, setErr] = useState({});
  const handleClickOpen = (e) => {
    fetchContext();
    setOpen(true);
  };

  const handleClose = (e) => {
    setOpen(false);
  };

  const fetchContext = async () => {
    try {
      let res = await ContextApi.fetchContextByAppIdAndApId(appId, row._id);
      setData(res || {});
      setErr(false);
    } catch (err) {
      setErr(true);
      setData({});
    }
  };

  // useEffect(() => {
  //   row && fetchContext();
  // }, [row]);
  console.log("propppppppppppppppppppppppppppppppppppppppppppps", props);
  return (
    <div>
      <Tooltip title="View Context" aria-label="View Context">
        <VisibilityOutlinedIcon onClick={(e) => handleClickOpen(e)} />
      </Tooltip>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth="true"
      >
        <DialogTitle id="alert-dialog-title">{"Context Details"}</DialogTitle>
        <Divider />

        <DialogContent>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <StyledTableCell variant="head">API Name</StyledTableCell>
                  <StyledTableCell style={style_} variant="body">{`${
                    row.name || "-"
                  }`}</StyledTableCell>
                </TableRow>
                {row && row.apiType !== "MessageBroker" ? (
                  <TableRow>
                    <StyledTableCell variant="head">Proxy URL</StyledTableCell>
                    <StyledTableCell style={style_} variant="body">
                      {data._id === undefined ? (
                        <p>Context not defined</p>
                      ) : (
                        <>
                          {/* <Link
                          style={{ textTransform: "none" }}
                        >{`http://sos.proxyserver.com/serve/contexts/${data._id}`}</Link> */}
                          <ClipboardCopy copyText={`${data.proxy}${data._id}`} />
                        </>
                      )}
                    </StyledTableCell>
                  </TableRow>
                ) : null}

                {row && row.apiType !== "MessageBroker" ? (
                  <TableRow>
                    <StyledTableCell variant="head">API URL</StyledTableCell>
                    <StyledTableCell style={style_} variant="body">
                      {/* <Link>{`${row.url}`}</Link> */}
                      <ClipboardCopy copyText={`${row.url}`} />
                    </StyledTableCell>
                  </TableRow>
                ) : (
                  <TableRow>
                    <StyledTableCell variant="head">Topic</StyledTableCell>
                    <StyledTableCell style={style_} variant="body">
                      {/* <Link>{`${row.url}`}</Link> */}
                      <ClipboardCopy copyText={`${row?.source?.topic || ""}`} />
                    </StyledTableCell>
                  </TableRow>
                )}

                <TableRow>
                  <StyledTableCell variant="head">Context ID</StyledTableCell>
                  <StyledTableCell style={style_} variant="body">{`${
                    data._id || "Not defined"
                  }`}</StyledTableCell>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={(e) => handleClose(e)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
