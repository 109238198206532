import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Tooltip from "@material-ui/core/Tooltip";

import Icon from "@material-ui/core/Icon";

const useStyles = makeStyles((theme) => ({
  fab: {
    margin: theme.spacing(2),
  },
  absolute: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(3),
  },
}));

export default function CustomIcon(props) {
  const classes = useStyles();

  return (
    <Tooltip title={props.tooltip} aria-label="add">
      <Icon style={props.iconstyle}>{props.name}</Icon>
    </Tooltip>
  );
}
