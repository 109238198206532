/**
 * Content component for node...
 */

import React, { Component, useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Tooltip, TextField } from "@material-ui/core";
import ButtonGroup from "@mui/material/ButtonGroup";
import IconButton from "@mui/material/IconButton";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import Stack from "@mui/material/Stack";
import { blue, indigo, cyan } from "@mui/material/colors";
import Avatar from "@mui/material/Avatar";
import APIs from "../../../api/ApplicationAPIs/index";
import { IoWarningOutline } from "react-icons/ri";
import { Handle, Position } from "react-flow-renderer";
import { Circle } from "@mui/icons-material";
import WarningIcon from "@material-ui/icons/Warning";
import MenuItem from "@mui/material/MenuItem";
import CancelIcon from "@mui/icons-material/Cancel";
import FormControl from "@mui/material/FormControl";
import { Select, FormHelperText } from "@mui/material";
import CustomAutoComplete from "components/DropDown/AutoComplete";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const menuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

let dummy = [];
for (let i = 0; i < 50; i++) {
  dummy.push({
    name: `ab${i}`,
  });
}

const WarningComponent = (props) => {
  const { title, cduField } = props.node;
  return (
    <>
      <Typography>
        {title !== "arrayElement" && title !== "root" && !cduField && (
          <Tooltip title="Not a Active Metadata field">
            <WarningIcon
              style={{
                color: "orange",
                fontSize: 14,
                marginRight: 2,
              }}
            />
          </Tooltip>
        )}
        {title}{" "}
      </Typography>
    </>
  );
};

const NativeDropdown = ({
  value,
  handleChange,
  elements,
  onKeyDown,
  autoFocus,
  onBlur,
}) => {
  const err = elements.length === 0;
  return (
    <FormControl variant="standard" fullWidth error={err}>
      <Select
        autoFocus={autoFocus}
        // native
        value={value}
        onChange={handleChange}
        MenuProps={menuProps}
        onBlur={onBlur}
      >
        {elements.map((item, index) => (
          <MenuItem key={index} value={item.name}>
            {item.name}
          </MenuItem>
        ))}
      </Select>

      {err && <FormHelperText>Please select industry type!</FormHelperText>}
    </FormControl>
  );
};

const classnames = function classnames(...classes) {
  return classes.filter(Boolean).join(" ");
};

const getNodeType = (type) => {
  console.log("type", type);
  switch (type) {
    case "array":
      return {
        text: "A",
        color: blue[300],
        tooltipText: "Array - Click to change Type",
        noActionsTip: "Array",
      };
    case "object":
      return {
        text: "O",
        color: indigo[300],
        tooltipText: "Object - Click to change Type",
        noActionsTip: "Object",
      };
    case "string":
      return {
        text: "S",
        color: cyan[300],
        tooltipText: "String - Click to change Type",
        noActionsTip: "String",
      };
    case "number":
      return {
        text: "N",
        color: cyan[300],
        tooltipText: "Number - Click to change Type",
        noActionsTip: "Number",
      };
    case "boolean":
      return {
        text: "B",
        color: cyan[300],
        tooltipText: "Boolean - Click to change Type",
        noActionsTip: "Boolean",
      };
    case "integer":
      return {
        text: "I",
        color: cyan[300],
        tooltipText: "Integer - Click to change Type",
        noActionsTip: "Integer",
      };
    default:
  }
};

const getNextType = (nodetype, childrenCount, title) => {
  console.log({ nodetype, childrenCount });
  let types = ["array", "object", "string", "number", "boolean", "integer"];

  if (childrenCount !== 0) {
    types = ["object", "array"];
  }
  if (title === "root") {
    types = types.filter((f) => f === "array" || f === "object");
  }
  console.log("typestypes", 0, title, types);

  if (title === "arrayElement") {
    console.log("typestypes", 1, types);
    types = types.filter((f) => f !== "array");
  }

  let currentIndex = types.findIndex((type) => {
    return type == nodetype;
  });

  if (currentIndex == types.length - 1) {
    return types[0];
  } else {
    return types[currentIndex + 1];
  }
};

export default class NodeContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editable: props.node.title == "" ? true : false,
      title: props.node.title,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      editable: nextProps.node.title == "" ? true : false,
      title: nextProps.title,
    });
  }
  onDoubleClickTitle = () => {
    if (
      this.props.node.title === "root" ||
      this.props.node.title === "arrayElement"
    )
      return;
    this.setState({ editable: true });
  };
  onPressEnter = (e) => {
    const { props } = this;
    if (e.key === "Enter") {
      if (!this.state.title || this.state.title.trim().length === 0) {
        props.enqueueSnackbar("Child node can't be empty", {
          variant: "error",
        });
        return;
      }
      const parentChildren = props?.parentNode?.children || [];
      if (
        props.node.title !== this.state.title &&
        parentChildren.map((cld) => cld.title).includes(this.state.title)
      ) {
        props.enqueueSnackbar("Key already exists", {
          variant: "error",
        });
      } else {
        this.props.updateNodeTitle(
          props.panel,
          this.state.title,
          props.node,
          props.path
        );
        this.setState({ editable: false });
      }
    }
  };
  showAddbutton() {
    return (
      ["string", "number", "boolean", "integer", "array"].findIndex((type) => {
        return this.props.node.type === type;
      }) === -1
    );
  }

  // componentDidMount() {}
  onselectValue = (value) => {
    let { props } = this;
    const parentChildren = props?.parentNode?.children || [];

    if (props.CduDataStandards.filter((e) => e.name === value).length !== 0) {
      if (
        props.node.title !== value &&
        parentChildren.map((cld) => cld.title).includes(value)
      ) {
        props.enqueueSnackbar("Key already exists", {
          variant: "error",
        });
      } else {
        this.setState({
          editable: false,
          title: value,
        });
        this.props.updateNodeTitle(props.panel, value, props.node, props.path);
      }
    }
  };
  render() {
    let { props } = this;
    let nodeType = getNodeType(props.node.type);

    return (
      <>
        {this.props.pageFrom === "outgoingAPI" ? (
          <Paper
            square
            style={{
              width: "300px",
              height: "50px",
              padding: 7,
            }}
          >
            {this.props.panel === "cdu" ? (
              <Grid
                contianer
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "300px",
                  height: "100%",
                }}
              >
                <Grid item sm={8} md={8} lg={8}>
                  <Stack>
                    <Typography>{props.node.title}</Typography>
                    <Typography variant="caption" gutterBottom>
                      {props.node.desc}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid
                  item
                  sm={4}
                  md={4}
                  lg={4}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <ButtonGroup size="small" aria-label="split button">
                    <Tooltip title={nodeType.tooltipText}>
                      <IconButton
                        color="success"
                        component="span"
                        style={{ padding: 3 }}
                      >
                        <Avatar
                          style={{
                            width: 22,
                            height: 22,
                            backgroundColor: nodeType.color,
                            fontSize: 18,
                          }}
                        >
                          {nodeType.text}
                        </Avatar>
                      </IconButton>
                    </Tooltip>
                  </ButtonGroup>
                </Grid>
              </Grid>
            ) : (
              <Grid
                contianer
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "300px",
                  height: "100%",
                }}
              >
                <Grid item sm={8} md={8} lg={8}>
                  <Stack>
                    <Typography
                      variant="body"
                      onDoubleClick={this.onDoubleClickTitle}
                    >
                      {this.state.editable && !this.props.noActions ? (
                        props.panel === "api" ? (
                          <TextField
                            autoFocus
                            size="small"
                            onKeyDown={this.onPressEnter}
                            onChange={(e) => {
                              this.setState({
                                title: e.currentTarget.value.trim(),
                              });
                            }}
                            value={this.state.title || ""}
                          />
                        ) : (
                          <CustomAutoComplete
                            options={(props.CduDataStandards || []).map((f) => {
                              return {
                                value: f.name,
                                label: f.name,
                              };
                            })}
                            value={this.state.title || ""}
                            handleChange={(val) => {
                              this.onselectValue(val);
                            }}
                          />
                          // <NativeDropdown
                          //   elements={props.CduDataStandards}
                          //   onKeyDown={this.onPressEnter}
                          //   size="small"
                          //   autoFocus
                          //   value={this.state.title}
                          //   onBlur={(e) => {
                          //     this.onselectValue(e.target.value);
                          //   }}
                          //   handleChange={(e) => {
                          //     this.onselectValue(e.target.value);
                          //   }}
                          // />
                        )
                      ) : props.panel === "cdu" ? (
                        <WarningComponent {...props} />
                      ) : (
                        <Typography>{props.node.title}</Typography>
                      )}
                    </Typography>
                    <Typography variant="caption" gutterBottom>
                      {props.node.desc}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid
                  item
                  sm={4}
                  md={4}
                  lg={4}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <ButtonGroup size="small" aria-label="split button">
                    {props.panel === "api" && props.node.title === "root" && (
                      <Tooltip title="Remove">
                        <IconButton
                          style={{ padding: 3 }}
                          size="small"
                          color="error"
                        >
                          <CancelIcon onClick={this.props.removeTree} />
                        </IconButton>
                      </Tooltip>
                    )}
                    {!this.props.noActions && this.showAddbutton() && (
                      <Tooltip title="Add node">
                        <IconButton
                          color="success"
                          component="span"
                          style={{ padding: 3 }}
                        >
                          <AddCircleOutlineOutlinedIcon
                            onClick={() => {
                              props.insertNewNode(props.panel, props.treeIndex);
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    )}
                    {this.props.noActions ||
                      (this.props.node.title !== "root" &&
                        this.props.node.title !== "arrayElement" && (
                          <Tooltip title="Remove node">
                            <IconButton
                              color="error"
                              component="span"
                              style={{ padding: 3 }}
                              onClick={() => {
                                props.removeNode(props.panel, props.path);
                              }}
                            >
                              <RemoveCircleOutlineOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                        ))}
                    {this.props.noActions ? (
                      <Tooltip title={nodeType.noActionsTip}>
                        <Avatar
                          sx={{
                            width: 22,
                            height: 22,
                            bgcolor: nodeType.color,
                          }}
                          style={{ fontSize: 18 }}
                        >
                          {nodeType.text}
                        </Avatar>
                      </Tooltip>
                    ) : (
                      <Tooltip title={nodeType.tooltipText}>
                        <IconButton
                          color="success"
                          component="span"
                          style={{ padding: 3 }}
                          onClick={(e) => {
                            let ndType = getNextType(
                              props.node.type,
                              props.node.children?.length || 0,
                              props.node.title
                            );

                            if (
                              props.node.children?.length !== 0 &&
                              props.node.title === "arrayElement" &&
                              props.node.ndType === "object"
                            ) {
                              //if array element type is object should'nt update node.
                              return;
                            }
                            props.updateNodeType(
                              props.panel,
                              ndType,
                              props.node,
                              props.path
                            );
                          }}
                        >
                          <Avatar
                            style={{
                              width: 22,
                              height: 22,
                              backgroundColor: nodeType.color,
                              fontSize: 18,
                            }}
                          >
                            {nodeType.text}
                          </Avatar>
                        </IconButton>
                      </Tooltip>
                    )}
                  </ButtonGroup>
                </Grid>
              </Grid>
            )}
          </Paper>
        ) : (
          <Paper
            square
            style={{
              width: "300px",
              height: "50px",
              padding: 7,
            }}
          >
            <Grid
              contianer
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "300px",
                height: "100%",
              }}
            >
              <Grid item sm={8} md={8} lg={8}>
                <Stack>
                  <Typography
                    variant="body"
                    onDoubleClick={this.onDoubleClickTitle}
                  >
                    {this.state.editable && !this.props.noActions ? (
                      props.panel === "api" ? (
                        <TextField
                          autoFocus
                          size="small"
                          onKeyDown={this.onPressEnter}
                          onChange={(e) => {
                            this.setState({
                              title: e.currentTarget.value.trim(),
                            });
                          }}
                          value={this.state.title || ""}
                        />
                      ) : (
                        <CustomAutoComplete
                          options={(props.CduDataStandards || []).map((f) => {
                            return {
                              value: f.name,
                              label: f.name,
                            };
                          })}
                          value={this.state.title || ""}
                          handleChange={(val) => {
                            this.onselectValue(val);
                          }}
                        />
                        // <NativeDropdown
                        //   elements={props.CduDataStandards}
                        //   onKeyDown={this.onPressEnter}
                        //   size="small"
                        //   autoFocus
                        //   value={this.state.title}
                        //   onBlur={(e) => {
                        //     this.onselectValue(e.target.value);
                        //   }}
                        //   handleChange={(e) => {
                        //     this.onselectValue(e.target.value);
                        //   }}
                        // />
                      )
                    ) : props.panel === "cdu" ? (
                      <WarningComponent {...props} />
                    ) : (
                      <Typography>{props.node.title}</Typography>
                    )}
                  </Typography>
                  <Typography variant="caption" gutterBottom>
                    {props.node.desc}
                  </Typography>
                </Stack>
              </Grid>
              <Grid
                item
                sm={4}
                md={4}
                lg={4}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ButtonGroup size="small" aria-label="split button">
                  {props.panel === "api" && props.node.title === "root" && (
                    <Tooltip title="Remove">
                      <IconButton
                        style={{ padding: 3 }}
                        size="small"
                        color="error"
                      >
                        <CancelIcon onClick={this.props.removeTree} />
                      </IconButton>
                    </Tooltip>
                  )}
                  {!this.props.noActions && this.showAddbutton() && (
                    <Tooltip title="Add node">
                      <IconButton
                        color="success"
                        component="span"
                        style={{ padding: 3 }}
                      >
                        <AddCircleOutlineOutlinedIcon
                          onClick={() => {
                            props.insertNewNode(props.panel, props.treeIndex);
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  )}
                  {this.props.noActions ||
                    (this.props.node.title !== "root" &&
                      this.props.node.title !== "arrayElement" && (
                        <Tooltip title="Remove node">
                          <IconButton
                            color="error"
                            component="span"
                            style={{ padding: 3 }}
                            onClick={() => {
                              props.removeNode(props.panel, props.path);
                            }}
                          >
                            <RemoveCircleOutlineOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      ))}
                  {this.props.noActions ? (
                    <Tooltip title={nodeType.noActionsTip}>
                      <Avatar
                        sx={{ width: 22, height: 22, bgcolor: nodeType.color }}
                        style={{ fontSize: 18 }}
                      >
                        {nodeType.text}
                      </Avatar>
                    </Tooltip>
                  ) : (
                    <Tooltip title={nodeType.tooltipText}>
                      <IconButton
                        color="success"
                        component="span"
                        style={{ padding: 3 }}
                        onClick={(e) => {
                          let ndType = getNextType(
                            props.node.type,
                            props.node.children?.length || 0,
                            props.node.title
                          );

                          if (
                            props.node.children?.length !== 0 &&
                            props.node.title === "arrayElement" &&
                            props.node.ndType === "object"
                          ) {
                            //if array element type is object should'nt update node.
                            return;
                          }
                          props.updateNodeType(
                            props.panel,
                            ndType,
                            props.node,
                            props.path
                          );
                        }}
                      >
                        <Avatar
                          style={{
                            width: 22,
                            height: 22,
                            backgroundColor: nodeType.color,
                            fontSize: 18,
                          }}
                        >
                          {nodeType.text}
                        </Avatar>
                      </IconButton>
                    </Tooltip>
                  )}
                </ButtonGroup>
              </Grid>
            </Grid>
          </Paper>
        )}
      </>
    );
  }
}
