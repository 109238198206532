const Styles=theme=>({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },
      avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
      },
      form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
      },
      submit: {
        margin: theme.spacing(3, 0, 2),
      },
      title: {
        color: "white",
        fontSize: "16px",
        [theme.breakpoints.up("sm")]: {
          fontSize: "20px",
        },
      },
      loginText: {
        fontWeight: 550,
        marginBottom:5
      },
      margin:{
        marginBottom:20
      },
      linkTest:{
        color:"black",
        "&:hover": {
            textDecoration:"none",
        color:"#002855"
          },
      }
  });
  export default Styles;