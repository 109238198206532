import React from "react";
import { Typography, Grid, Button } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import AppGraph from "../Invoice/component/AppGraph";
// import AppDetailsinvoice from "./appDetailsTable";
import AppDetailsinvoice from "./component/AppDetailsTable";
import DrillDownPieGraph from "./PieDrillDownApp/index.jsx";
import { CSVLink } from "react-csv";
import { RiDownload2Line } from "react-icons/ri";
import withSection from "components/Section/Section";
import invoiceFun from "./services";

const headers = [
  { label: "Application", key: "Application" },
  { label: "billable Service", key: "billableService" },
  { label: "Units", key: "Units" },
  { label: "UnitsConsumed", key: "UnitsConsumed" },
  { label: "Cost", key: "Cost" },
  { label: "Amount", key: "Amount" },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function BasicTabs(props) {
  const { nonGrpData } = props;
  // const { } = props;
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let data_csv = nonGrpData.map((row, id) => {
    const { type, total, totalFlatCost } = invoiceFun.costValue(row);
    return {
      Application: row?.AppDetails?.name,
      billableService: row?.BillableDetails?.name || "",
      Units: row?.BillableDetails?.unit || "",
      UnitsConsumed: row.Quantity,
      Cost: type ? "-" : totalFlatCost,
      Amount: row.GrossAmount,
    };
  });

  const csvreport = {
    data: data_csv,
    headers: headers,
    filename: "Invoice.csv",
  };

  return (
    <div style={{ width: "100%" }}>
      <Grid container>
        <Grid item md={12}>
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab
                    style={{ textTransform: "none" }}
                    label="Graph"
                    {...a11yProps(0)}
                  />
                  <Tab
                    style={{ textTransform: "none" }}
                    label="Table"
                    {...a11yProps(1)}
                  />
                </Tabs>
                {value === 1 && (
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "flex-end",
                    }}
                  >
                    <div>
                      <Button
                        size="small"
                        style={{ padding: "0 2px", margin: 0 }}
                        endIcon={
                          <RiDownload2Line style={{ fontSize: "15px" }} />
                        }
                      >
                        <CSVLink {...csvreport}>Download</CSVLink>
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </Box>

            <TabPanel value={value} index={0}>
              <Grid container>
                <Grid item md={12}>
                  <AppGraph {...props} />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <AppDetailsinvoice {...props} />
            </TabPanel>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default withSection(BasicTabs, "Application Usage Details");
