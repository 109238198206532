// /**
//  *
//  */

import * as actions from "./actionTypes";

const intialState = {
  data: [],
  loading: false,
  //   editdata: {},
  totalCount: 0,
  // deldata: {},
  //   adddata: {},
  hidden: [],
};

export default (state = intialState, action) => {
  switch (action.type) {
    case actions.FETCH_STATUS_PROFILE:
      return { ...state, loading: action.loading };
    case actions.FETCH_STATUS_PROFILE_SUCCESS:
      return {
        ...state,
        loading: action.loading,
        data: action.data,
        totalCount: action.totalCount,
      };
    case actions.FETCH_STATUS_PROFILE_FAILURE:
      return { ...state, loading: action.loading, error: action.error };

    // case actions.DELETE_HELPDESKDATA:
    //   return { ...state, loading: action.loading };
    // case actions.DELETE_HELPDESKDATA_SUCCESS:
    //   return { ...state, loading: action.loading, deldata: action.deldata };
    // case actions.DELETE_HELPDESKDATA_FAILURE:
    //   return { ...state, loading: action.loading, error: action.error };
    case actions.UPDATE_STATUS_PROFILE_HIDDEN_COL:
      return {
        ...state,
        hidden: action.payload,
      };
    default:
      return state;
  }
};
