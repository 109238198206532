import React from "react";
import PropTypes from "prop-types";
import loginRoutes from "../../routes/loginRoutes.jsx";
import { withStyles } from "@material-ui/core/styles";
import Styles from "../../styles/Styles-root";
import { Paper, Grid, AppBar, Toolbar, Typography } from "@material-ui/core";
import { Route, Switch, Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { SnackbarProvider } from "notistack";
import { useSelector, useDispatch } from "react-redux";

// import Logo from "assets/images/smrt-trains.png"
import Logo from "assets/images/Kailash_new.png";
const useStyles = makeStyles((theme) => ({
  title: {
    color: "white",
    fontSize: "16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      fontSize: "20px",
    },
  },
}));
function NavBar() {
  const classes = useStyles();
  const OrgData = useSelector((state) => state.editorganizationReducer);
  const { currentOrgData } = OrgData;
  console.log(currentOrgData, "dfghjkl");
  return (
    <>
      <AppBar
        color="primary"
        position="fixed"
        style={{
          zIndex: "8888",
          height: "61px",
        }}
      >
        <Toolbar>
          <Typography className={classes.title} variant="h6" noWrap>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                alt="logo"
                src={Logo}
                style={{
                  width: "80px",
                }}
              />
            </div>
          </Typography>
        </Toolbar>
      </AppBar>
    </>
  );
}
class LoginLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      shouldlogout: false,
      title: "",
      defaultSelectedKeys: "",
    };
    // this.gotoRoute = this.gotoRoute.bind(this);
  }

  getRoutesBasedOnRole = () => {
    let mainRoutes = [];
    mainRoutes = loginRoutes;
    return mainRoutes;
  };

  render() {
    let { classes } = this.props;
    let loginRoutes = this.getRoutesBasedOnRole();
    return (
      <>
        <div
          style={{
            height: "100vh",
            backgroundColor: "#FAFAFA",
          }}
        >
          <NavBar />
          <div style={{ paddingTop: "4%" }}>
            <Grid container>
              <Grid item xs={12}>
                <Switch>
                  {this.getRoutesBasedOnRole().map((prop, key) => {
                    return (
                      <Route
                        exact
                        path={prop.url}
                        component={prop.component}
                        key={key}
                      />
                    );
                  })}
                  <Redirect from="/" to="/login" />
                </Switch>
              </Grid>
            </Grid>
          </div>
        </div>
      </>
    );
  }
}
LoginLayout.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(Styles)(LoginLayout);
