import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  TableHead,
  TableRow,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Checkbox,
  IconButton,
  Grid,
  Select,
  FormControl,
  Tooltip,
  capitalize,
} from "@material-ui/core/";
import CloseIcon from "@material-ui/icons/Close";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { useEffect } from "react";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,

    color: theme.palette.common.white,
    padding: 10,
    paddingLeft: 15,
    fontSize: 14,
  },
  body: {
    fontSize: 12,
    backgroundColor: "#F0F0F0",
    paddingTop: 0,
    paddingBottom: 1,
  },
}))(TableCell);

const getCDUoptions = (cduMasterValues) => {
  return cduMasterValues.map((v) => {
    return { value: v.dataKey, label: v.dataKey };
  });
};

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default function InputPayload(props) {
  const { active, values, cduValues, handleInputCDUvalues, type } = props;
  const classes = useStyles();

  const [rows, setRows] = React.useState([]);
  useEffect(() => {
    setRows([...values]);
  }, [values]);
  const onChange = (e, index) => {
    console.log(e, index);
    let cduMap = rows[index] || {};
    let newcduMap = { ...cduMap };
    newcduMap[e.currentTarget.name] = e.currentTarget.value;
    rows[index] = newcduMap;
    let new_rows = [...rows];
    console.log({ rows });
    handleInputCDUvalues(type, [...new_rows]);
  };

  const addRow = () => {
    console.log("sdfsdfsdf");
    let copy = [...rows];
    let addId = {
      key: rows.length + 1,
    };
    setRows([...copy, addId]);
    handleInputCDUvalues(type, [...copy, addId]);
  };

  const removeRow = (index) => {
    if (rows.length != 1) {
      let copy = [...rows];
      let rowRemoved = copy.filter((row, i) => {
        return index == i;
      });
      setRows([...rowRemoved]);
      handleInputCDUvalues(type, [...rowRemoved]);
    }
  };

  console.log(rows[0]);

  return (
    <TableContainer
      style={{
        overflowY: "auto",
        padding: "0% 1% 0% 1%",
        width: "100%",
      }}
    >
      <Table className={classes.table} aria-label="customized table">
        <TableHead size="small">
          <TableRow>
            <StyledTableCell style={{ width: "30%" }}>
              Application Field
            </StyledTableCell>
            <StyledTableCell style={{ width: "30%" }} align="left">
              CDU Field
            </StyledTableCell>
            <StyledTableCell style={{ width: "25%" }} align="center">
              CDU Values
            </StyledTableCell>
            <StyledTableCell style={{ width: "15%" }} align="center">
              Actions
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(({ field_name, cdu_name, valueMap }, index) => (
            <>
              <StyledTableRow>
                <StyledTableCell scope="row">
                  <TextField
                    style={{ backgroundColor: "white" }}
                    id="outlined-search"
                    onChange={(e) => onChange(e, index)}
                    name="field_name"
                    type="text"
                    variant="outlined"
                    size="small"
                    fullWidth
                    margin="normal"
                    value={field_name}
                  />
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Autocomplete
                    autoComplete={true}
                    style={{ paddingTop: "8px" }}
                    single
                    value={cdu_name}
                    inputValue={cdu_name}
                    onChange={(e, v, reason) => {
                      onChange(
                        {
                          currentTarget: {
                            name: "cdu_name",
                            value: (v || {}).value || "",
                          },
                        },
                        index
                      );
                    }}
                    getOptionSelected={(o, v) => {
                      return v === cdu_name;
                    }}
                    renderOption={(o, v) => {
                      return o.label;
                    }}
                    size="small"
                    variant="outlined"
                    fullWidth
                    id="tags-filled"
                    options={getCDUoptions(cduValues || [])}
                    getOptionLabel={(option) => option.label || ""}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        {...params}
                        variant="outlined"
                        name="cdu_name"
                        placeholder="Select Field"
                        value={cdu_name}
                      />
                    )}
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Checkbox
                    onChange={(e) =>
                      onChange(
                        {
                          currentTarget: {
                            name: e.currentTarget.name,
                            value: e.currentTarget.checked,
                          },
                        },
                        index
                      )
                    }
                    name="valueMap"
                    checked={valueMap}
                    color="primary"
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <Grid container direction="row" justifyContent="left" alignItems="center" >
                    <Grid item>
                      {rows.length > 1 && (
                        <Tooltip title="Cancel">
                          <IconButton onClick={(e) => { props.handleRemove(index); }} aria-label="cancel" >
                            <CloseIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Grid>
                    <Grid item>
                      {rows.length - 1 === index && (
                        <Tooltip title="Add">
                          <IconButton onClick={addRow} aria-label="add">
                            <AddCircleOutlineIcon color="secondary" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Grid>
                  </Grid>
                </StyledTableCell>
              </StyledTableRow>
            </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
