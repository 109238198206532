/**
 * Validators for checking if the edges are proper are not.
 */
import { getNodeFromTitle } from "helpers/tree-utils";

function validateEdge(
  currentEdge,
  nodes,
  edges,
  mappingType,
  api_nodes,
  cdu_nodes
) {
  let finalSource = mappingType.split("to")[0];
  let finalTarget = mappingType.split("to")[1];

  let sourceNode = getNodeById(currentEdge.source, nodes);
  let targetNode = getNodeById(currentEdge.target, nodes);
  let soruceTreeNodes = finalSource === "api" ? api_nodes : cdu_nodes;
  let targetTreeNodes = finalTarget === "api" ? api_nodes : cdu_nodes;

  let isValid = true;
  // source node validation, i
  // rule 1 - if source is operation then, all target nodes should be final target
  // rule 2 - if source is finalSource then target could be any of the other nodes,
  switch (sourceNode.type) {
    case "concat":
    case "split":
    case "join":
    case "filter":
      isValid = isValid && targetNode.data.panel == finalTarget;
      if (!isValid) {
        console.log("broken targetnode validation");
      }
      break;
    default:
      break;
  }
  switch (targetNode.type) {
    case "concat":
    case "split":
    case "join":
    case "filter":
      isValid = isValid && sourceNode.data.panel == finalSource;
      if (!isValid) {
        console.log("broken targetnode validation");
      }
      break;
    default:
      break;
  }
  // individual validation
  switch (sourceNode.type) {
    case "concat":
      isValid =
        isValid &&
        validateConcatEdge(
          "source",
          currentEdge,
          nodes,
          edges,
          targetTreeNodes
        );
      break;
    case "split":
      isValid =
        isValid &&
        validateSplitEdge("source", currentEdge, nodes, edges, targetTreeNodes);
      break;
    case "join":
      isValid =
        isValid &&
        validateJoinEdge("source", currentEdge, nodes, edges, targetTreeNodes);
      break;
    case "filter":
      break;
    default:
      break;
  }

  switch (targetNode.type) {
    case "concat":
      isValid =
        isValid &&
        validateConcatEdge(
          "target",
          currentEdge,
          nodes,
          edges,
          soruceTreeNodes
        );
      break;
    case "split":
      isValid =
        isValid &&
        validateSplitEdge("target", currentEdge, nodes, edges, soruceTreeNodes);
      break;
    case "join":
      isValid =
        isValid &&
        validateJoinEdge("target", currentEdge, nodes, edges, soruceTreeNodes);
      break;
    case "filter":
      break;
    default:
      break;
  }
  // duplicate edge validation,
  // rule - with same target and target handle no other edge should exists in the edges array.
  // this rule should be applied for all nodes.
  let filteredEdges = edges.filter((edge) => {
    console.log(
      { edge, currentEdge },
      edge.target == currentEdge.target &&
        edge.targetHandle == currentEdge.targetHandle
    );
    return (
      edge.target == currentEdge.target &&
      edge.targetHandle == currentEdge.targetHandle
    );
  });
  if (targetNode.type !== "expression") {
    isValid = isValid && filteredEdges.length === 0;
  }
  if (!isValid) {
    console.log("broken duplicate Edge validation");
  }
  // node level validation
  // rule -1 if the target is finalTarget and top level node is mapped, the childeren cannot be mapped.
  // the handles of sortable tree is made in such a way that, the parent node id is part of child node id
  // check contains of with targetHandle...
  if (currentEdge.target == finalTarget) {
    // check all the edges with finalTarget as target.
    let filteredEdges = edges.filter((edge) => {
      return (
        (edge.target == finalTarget &&
          currentEdge.targetHandle.includes(edge.targetHandle)) ||
        (edge.target == finalTarget &&
          edge.targetHandle.includes(currentEdge.targetHandle))
      );
    });

    isValid = isValid && filteredEdges.length === 0; /// no handle to parent node...
  }
  //if children has edge parent should have edge.

  if (!isValid) {
    console.log("broken parent node validation");
  }
  return isValid;
}

function getNodeById(id, nodes) {
  return nodes.find((node) => node.id === id);
}

/**
 * rules for split edge are
 * - if the target is split, the source node handle should be a string
 * - if the source node is split, the target node handle should be a string
 */
function validateSplitEdge(type, currentEdge, nodes, edges, treeNodes) {
  if (type == "source") {
    // get the node from the edge target handle.
    let title = currentEdge.targetHandle.slice(8);
    let node = getNodeFromTitle(title, treeNodes);
    // check type is string or not.
    return node.type == "string";
  } else {
    let title = currentEdge.sourceHandle.slice(8);
    let node = getNodeFromTitle(title, treeNodes);
    // check type is string or not.
    return node.type == "string";
  }
}

/**
 * rules for join edge are
 * - if target is join, the source node handle should be an array
 * - if soruce is join, the target node handle should be a string
 */
function validateJoinEdge(type, currentEdge, nodes, edges, treeNodes) {
  if (type == "source") {
    // get the node from the edge target handle.
    let title = currentEdge.targetHandle.slice(8);
    let node = getNodeFromTitle(title, treeNodes);
    // check type is string or not.
    return node.type == "string";
  } else {
    let title = currentEdge.sourceHandle.slice(8);
    let node = getNodeFromTitle(title, treeNodes);
    // array should be array of strings
    if (node.children?.length !== 0) {
      let isValid =
        node.children[0].title == "arrayElement" &&
        node.children[0].type == "string";
      return isValid;
    } else {
      return false;
    }
    // check type is string or not.
  }
}

/**
 * rules for concat are
 * - if target is concat, all the soruce nodes should be string
 * - if source is concat, the output nodes should be string
 */
function validateConcatEdge(type, currentEdge, nodes, edges, treeNodes) {
  if (type == "source") {
    // get the node from the edge target handle.
    let title = currentEdge.targetHandle.slice(8);
    let node = getNodeFromTitle(title, treeNodes);
    // check type is string or not.
    return node.type == "string";
  } else {
    let title = currentEdge.sourceHandle.slice(8);
    let node = getNodeFromTitle(title, treeNodes);
    // check type is string or not.
    return node.type == "string";
  }
}

/**
 * rules for filter are bit unclear, :)
 */
function validateFilterEdge() {}
//
function validateNode(node, nodes, level) {
  // rule -1 : node title should not be duplicate in the same level.,
  // rule -2 : only array and object are allowed to have children.
  // rule -3 : the first children of array should always be array Element(need not be checked as it is restricted to edit..)
}

export default {
  validateEdge,
};
