import React, { useEffect, useState } from "react";
import { withSnackbar } from "notistack";
// import ToggleButton from "@material-ui/core/ToggleButton";
import {
  CardContent,
  CardHeader,
  Divider,
  FormGroup,
  Card,
  Grid,
  Button,
  FormControl,
  TextField,
  Typography,
  FormLabel,
  IconButton,
  Tooltip,
  Badge,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import RefreshIcon from "@material-ui/icons/Refresh";
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { addEnvironment } from "../redux/action";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import withSection from "components/Section/Section";
import { RiEditLine, RiDeleteBin5Line } from "react-icons/ri";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import DexTable from "components/DexTable/DexTable";
import WrappedDexTable from "components/DexTable/WrappedDexTable";
import EnvironmentTags from "./EnvironmentTags";
import { checkPermission } from "helpers/permissons";
import ApplicationAPIs from "../../../api/ApplicationAPIs/index";
import { confirmDialog } from "components/DeleteConfirmationDialog/Index";
import { dltMsg } from "helpers/helpers";
import { connect } from "react-redux";
import { ApplicationDetailsUpadateHiddenCol } from "../ApplicationDetails/Redux/action";
import actions from "../redux/action";
const useStyles = makeStyles((theme) => ({
  activeBadge: {
    backgroundColor: "#21BA45",
  },
  inactiveBadge: {
    backgroundColor: "#BEBEBE",
  },

  blockBadge: {
    backgroundColor: "#DB2828",
  },
  badge: {
    display: "flex",
    alignItems: "center",
  },
}));

const styles = (length) => {
  let obj = {
    fontSize: "20px",
    color: length <= 1 ? "#bdbdbd" : "",
    cursor: length <= 1 ? "text" : "pointer",
  };
  return obj;
};

const EnvTags = ({ row }) => {
  let { appId, _id } = row;
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [status, setStatus] = useState("");
  let color = null;
  if (status === "pass") {
    color = classes.activeBadge;
  } else if (status === "fail") {
    color = classes.inactiveBadge;
  } else {
    color = classes.blockBadge;
  }

  const health_check = async () => {
    try {
      const res = await ApplicationAPIs.getenvironmentTags(appId, _id);
      const { _msg, data } = res.data;
      setStatus(data?.message || "");
    } catch (err) {
      setStatus("");
    }
  };

  useEffect(() => {
    health_check();
  }, [row]);

  const getEnvTag = async (e) => {
    e && e.stopPropagation();
    try {
      const res = await ApplicationAPIs.getenvironmentTags(appId, _id);
      const { _msg, data } = res.data;
      enqueueSnackbar(_msg, {
        variant: "success",
        autoHideDuration: 2000,
      });
      setStatus(data?.message || "");
    } catch (err) {
      const { _msg } = err?.response?.data || {
        _msg: "connection error!",
      };

      enqueueSnackbar(_msg, {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  };
  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Badge
          classes={{ badge: color }}
          variant="dot"
          style={{ marginRight: "10px" }}
        />
        <Typography variant="body1">{status}</Typography>&nbsp;
        <IconButton color="inherit" size="small" onClick={(e) => getEnvTag(e)}>
          <RefreshIcon fontSize="20px" />
        </IconButton>
      </div>
    </>
  );
};

const Environment = (props) => {
  const {
    environments,
    appId,
    editEnvironment,
    addEnvironment,
    deleteEnvironment,
    getenvironmentTags,
    envTag,
    appTable,
    appName,
  } = props;
  const { env } = appTable;

  const pushToEnvironmentForm = () => {
    let data = {
      name: "",
      domainUrl: "",
      isProductionEnvironment: false,
      isHealthCheckEnable: false,
      healthCheckUrl: "",
      method: "POST",
    };
    checkPermission("btn213") &&
      props.history.push("/app/create-env-form", {
        environment_data: data,
        appName: appName,
        edit: false,
        ajax: true,
        appId: appId,
      });
  };

  const edit_Environment = ({ row }, e, val) => {
    e && e.stopPropagation();

    checkPermission("btn201") &&
      props.history.push("/app/create-env-form", {
        environment_data: val ? val : row,
        appName: appName,
        appId: appId,
        edit: true,
        ajax: true,
        disabled: e ? false : true,
      });
  };

  const removeEnvironmentData = async ({ row }, e) => {
    console.log(row, "deleted");
    e && e.stopPropagation();
    await props.deleteEnvironment(row.appId, row._id);
  };

  const actionProvider = (row) => {
    console.log(row.row.isProductionEnvironment, "3436436");
    let isProduction = row ? row.row.isProductionEnvironment : false;
    return (
      <div
        style={{
          display: "flex",
        }}
      >
        <div style={{ marginRight: 20 }}>
          <Tooltip title="Edit" aria-label="edit">
            <IconButton
              color="inherit"
              size="small"
              disabled={!checkPermission("btn201")}
              onClick={(e) =>
                checkPermission("btn201") && edit_Environment(row, e)
              }
            >
              <RiEditLine fontSize="20px" />
            </IconButton>
          </Tooltip>
        </div>
        {/* {environments.length > 1 && ( */}
        <div>
          {!isProduction && (
            // <Tooltip title="Delete" aria-label="delete">
            //   <IconButton
            //     color="primary"
            //     onClick={(e) => removeEnvironmentData(row, e)}
            //   >
            //     <RiDeleteBin5Line fontSize="22px" />
            <Tooltip
              title={environments.length <= 1 ? "" : "Delete"}
              aria-label="delete"
            >
              <IconButton
                color="inherit"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  environments.length > 1 &&
                    checkPermission("btn96") &&
                    confirmDialog(dltMsg(row?.row?.name || ""), () =>
                      removeEnvironmentData(row, e)
                    );
                }}
                disabled={!checkPermission("btn96")}
              >
                <RiDeleteBin5Line
                  color="inherit"
                  style={styles(environments.length)}
                />
              </IconButton>
            </Tooltip>
          )}
        </div>
        {/* )} */}
      </div>
    );
  };
  const markAsProduction = ({ row }) => {
    return (
      <Typography variant="body2">
        {" "}
        {row.isProductionEnvironment ? "Yes" : "No"}
      </Typography>
    );
  };

  const healthCheckUrl = ({ row }) => {
    return (
      <div>
        {row.isHealthCheckEnable ? <EnvTags key={row._id} row={row} /> : "NA"}
      </div>
    );
  };

  const viewEnvdata = (row) => {
    edit_Environment({}, null, row);
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid xs={12} item container justifyContent="flex-end">
          <Button
            size="small"
            variant="contained"
            onClick={() => checkPermission("btn213") && pushToEnvironmentForm()}
            startIcon={<AddIcon />}
            style={{ padding: "4px 30px 4px 30px" }}
            disabled={!checkPermission("btn213")}
          >
            Create Environment
          </Button>
        </Grid>
        <Grid item xs={12}>
          <WrappedDexTable
            data={[...environments]}
            // hideColumnChooser={true}
            columWidthsType="percentage"
            columnOrder={[
              "name",
              "domainUrl",
              "isProductionEnvironment",
              "isHealthCheckEnable",
              "actions",
            ]}
            columns={[
              { name: "name", title: "Environment Name" },
              { name: "domainUrl", title: "Domain URL" },
              {
                name: "isProductionEnvironment",
                title: "Mark as production ",
              },
              { name: "isHealthCheckEnable", title: "Health Check" },
              { name: "actions", title: "Actions" },
            ]}
            formatters={[
              { formatterFn: actionProvider, columns: ["actions"] },
              {
                formatterFn: markAsProduction,
                columns: ["isProductionEnvironment"],
              },
              {
                formatterFn: healthCheckUrl,
                columns: ["isHealthCheckEnable"],
              },
            ]}
            defaultColumnWidths={[
              { columnName: "name", width: 19 },
              { columnName: "domainUrl", width: 25 },
              { columnName: "isProductionEnvironment", width: 20 },
              { columnName: "isHealthCheckEnable", width: 16 },
              { columnName: "actions", width: 19 },
            ]}
            hidden={[...env]}
            setHiddenColmn={(hidden) => {
              props.setHiddenColumn(hidden, "env");
            }}
            pagination={false}
            noDataMsg="Environment not found"
            onClickRow={(row) => viewEnvdata(row)}
            Read={checkPermission("btn223")}
          />
        </Grid>
      </Grid>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => ({
  setHiddenColumn: (hidden, type) =>
    dispatch(ApplicationDetailsUpadateHiddenCol(hidden, type)),
});

const mapStateToProps = (state) => {
  return {
    appTable: state.appDetailsRedcer.appTable,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withSection(Environment, "Environment")));
