import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  Divider,
  Button,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import React from "react";
import Style from "./styles";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Outgoingcdumappingtable from "../OutgoingCdu/Outgoingcdumappingtable";
import contextAPIs from "../../api/Context/index";
import Breadcrumb from "components/Breadcrumbs/Breadcrumb";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

class Outgoingcdumap extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      appId: "",
      apiDetails: {},
      cdu: [],
      currentTab: 0,
      cduMapping_input: [],
      masterCduInput: [],
      cduMapping_output: [],
      masterCduOutput: [],
    };
  }

  toggleTabs = (e, tab) => {
    console.log(tab);
    this.setState({
      currentTab: tab,
      // cduMapping_input: [...this.state.cduMapping_input],
      // cduMapping_output: [...this.state.cduMapping_output]
    });
  };

  // componentDidMount = async () => {
  //   const { getDetails, location } = this.props;
  //   if (location.state != undefined) {
  //     let appId = location.state.appId;
  //     let apiDetails = location.state.apiDetails;
  //     let appName = location.state.appName;
  //     let outgoingAPI = location.state.outgoingAPI;
  //     let from = location.state.from || "app";
  //     await this.setState({ appId, outgoingAPI, appName, apiDetails });
  //     if (from == "app") {
  //       let exitsingCDU = await this.getCDUContext();
  //       await this.getCDUMasterContext(exitsingCDU);
  //     } else {
  //       let cduMapping_input = location.state.cduMapping_input || [];
  //       let cduMapping_output = location.state.cduMapping_output || [];
  //       this.setState({ cduMapping_input, cduMapping_output });
  //     }
  //   } else {
  //     this.props.history.push("/app/applications");
  //   }
  // };

  findfield_name = (cdu, cdu_name) => {
    let c = cdu.filter((f) => f.cdu_name == cdu_name);
    return (c[0] || {}).field_name || "";
  };

  find_values = (cdu, cdu_name) => {
    let c = cdu.filter((f) => f.cdu_name == cdu_name);
    return c[0] || {};
  };

  getCDUContext = async () => {
    try {
      let filter = {};
      filter.group = this.state.appId;
      filter.proxyURL = this.state.outgoingAPI.url;
      console.log({ filter });
      let res = await contextAPIs.fetchContextWithFilter({ ...filter });
      if ((res.data || {}).length == undefined || (res.data || {}).length == 0)
        return { res: [], req: [] }; // dummy emty resp
      console.log(res.data);
      return res.data[0]; // always one context
    } catch (err) {
      console.log(err);
    }
  };

  getCDUMasterContext = async (exitsingCDU) => {
    try {
      console.log(exitsingCDU);

      let res = await contextAPIs.fetchContextByAppIdAndApId(
        this.state.outgoingAPI.outgoingAppId,
        this.state.outgoingAPI.outgoingApiId
      );
      let cduMapping_input = [];
      (res.req || []).forEach((f) => {
        let d = {
          cdu_name: f.cdu_name,
          field_name: this.findfield_name(exitsingCDU.req, f.cdu_name),
          values: this.find_values(exitsingCDU.req, f.cdu_name),
        };
        console.log(d);
        cduMapping_input.push(d);
      });
      let cduMapping_output = [];
      (res.res || []).forEach((f) => {
        let d = {
          cdu_name: f.cdu_name,
          field_name: this.findfield_name(exitsingCDU.res, f.cdu_name),
          values: this.find_values(exitsingCDU.res, f.cdu_name),
        };
        console.log(d);
        cduMapping_output.push(d);
      });
      this.setState({
        r_ctxId: res._id,
        res,
        cduMapping_input,
        cduMapping_output,
      });
    } catch (err) {
      console.log(err);
    }
  };

  handleInputCDUvalues = (type, rows) => {
    if (type == "input") {
      console.log({ rows }, "inmaposdfsdf");
      this.setState({ cduMapping_input: [...rows] });
    } else {
      this.setState({ cduMapping_output: [...rows] });
    }
  };

  handlevaluemap = () => {
    this.props.history.push("/app/outgoingvaluemap", {
      r_ctxId: this.state.r_ctxId,
      appId: this.state.appId,
      apiDetails: this.state.apiDetails,
      outgoingAPI: this.state.outgoingAPI,
      cduMapping_input: this.state.cduMapping_input,
      cduMapping_output: this.state.cduMapping_output,
    });
  };

  handleCancel = () => {
    this.props.history.push("/app/detail");
  };

  render() {
    const { props, state } = this;
    return (
      <div style={{ padding: "10px 0px 0px 0px" }}>
        <Grid container spacing={1}>
          {/* <Breadcrumb
            {...this.props}
            links={[
              { name: "Applications", url: "/app/applications" },
              {
                name: this.state.appName,
                url: "/app/detail",
                routeParams: { appId: this.state.appId },
              },
              {
                name: "Outgoing Api Mapping",
                url: "/app/outgoingcdu",
                routeParams: this.props.location.state,
              },
            ]}
          /> */}
          <Grid container item xs={12} justify="space-between">
            <div>
              <Typography variant="h6">API - Outgoing CDU mapping</Typography>
            </div>
            <div>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    style={{ marginTop: "2px", padding: "5px 26px" }}
                    onClick={this.handleCancel}
                  >
                    {" "}
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    size="small"
                    variant="contained"
                    style={{ padding: "5px 24px" }}
                    color="white"
                    onClick={this.handlevaluemap}
                  >
                    Create Caller Context
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid container item xs={12} style={{ marginTop: "15px" }}>
            <Grid item xs={12}>
              <Card style={{ minHeight: "70vh" }}>
                <CardHeader
                  style={{ paddingTop: "1%" }}
                  title={
                    <AppBar
                      position="static"
                      style={{
                        backgroundColor: "transparent",
                        borderBottom: "1px solid #e8e8e8",
                        boxShadow: "none",
                        color: "gray",
                      }}
                    >
                      <Tabs
                        value={this.state.currentTab}
                        onChange={this.toggleTabs}
                        aria-label="simple tabs example"
                      >
                        <Tab
                          style={{ textTransform: "capitalize" }}
                          label={
                            <Typography variant="subtitle1">
                              Input Payload
                            </Typography>
                          }
                          {...a11yProps(0)}
                        />
                        <Tab
                          style={{ textTransform: "capitalize" }}
                          label={
                            <Typography variant="subtitle1">
                              Output Payload
                            </Typography>
                          }
                          {...a11yProps(1)}
                        />
                      </Tabs>
                    </AppBar>
                  }
                />
                <CardContent style={{ padding: "0px" }}>
                  <TabPanel
                    value={this.state.currentTab}
                    index={0}
                    style={{ padding: "0px" }}
                  >
                    <Outgoingcdumappingtable
                      active={this.state.currentTab == 0}
                      values={this.state.cduMapping_input}
                      cduValues={[...this.state.cdu]}
                      handleInputCDUvalues={this.handleInputCDUvalues}
                      type="input"
                    />
                  </TabPanel>
                  <TabPanel value={this.state.currentTab} index={1}>
                    <Outgoingcdumappingtable
                      active={this.state.currentTab == 1}
                      values={this.state.cduMapping_output}
                      cduValues={[...this.state.cdu]}
                      handleInputCDUvalues={this.handleInputCDUvalues}
                      type="output"
                    />
                  </TabPanel>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(Style)(Outgoingcdumap);
