import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  IconButton,
  Typography,
  DialogContentText,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { RiDeleteBin5Line } from "react-icons/ri";
import create from "zustand";

const useConfirmDialogStore = create((set) => ({
  message: "",
  onSubmit: undefined,
  close: () => set({ onSubmit: undefined }),
}));

export const confirmDialog = (message, onSubmit) => {
  useConfirmDialogStore.setState({
    message,
    onSubmit,
  });
};

const ConfirmDialogComponent = () => {
  // destructure the store data and functions
  const { message, onSubmit, close } = useConfirmDialogStore();
  console.log("confirmation dialog", message, onSubmit);
  return (
    // if the onSubmit is undefined the dialog will be closed.
    // close() function sets the onSubmit to undefined,
    // so it will close the dialog, if we pass it to the onClose attribute.
    <div>
      <Dialog open={Boolean(onSubmit)} onClose={close} maxWidth="lg">
        <DialogContent
          style={{
            height: 200,
            width: 450,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>
            <DialogContentText id="alert-dialog-description">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <RiDeleteBin5Line style={{ height: 75, width: 75 }} />
                <Typography
                  style={{
                    marginTop: 10,
                  }}
                >
                  {message}
                </Typography>
              </div>
            </DialogContentText>
          </div>
        </DialogContent>
        <DialogActions style={{ backgroundColor: "#F9FAFD" }}>
          <Button
            size="small"
            color="primary"
            variant="outlined"
            onClick={close}
          >
            Cancel
          </Button>
          <Button
            size="small"
            color="primary"
            variant="contained"
            onClick={() => {
              if (onSubmit) {
                onSubmit();
              }
              close();
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConfirmDialogComponent;
