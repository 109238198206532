import React from "react";
import { Typography, Grid, Divider, TextField } from "@material-ui/core";
import Organization from "./Organization";
import InvoiceTable from "./InvoiceTable";
import Products from "./ProductsTable";
import CustomerSetting from "./CustomerSetting";
import CustomerIssue from "./CustomerIssue";
const DetailForm = (props) => {
  const { details } = props;
  let data = [];
  if (props.ListOfApp) {
    data = props.ListOfApp[0] !== undefined ? props.ListOfApp : [];
  }
  console.log(props.ListOfApp, data, "this is app data");
  return (
    <div>
      <div>
        <Divider />
        <div style={{ marginTop: "1%" }}>
          <Grid container spacing={3}>
            <Grid item md={3}>
              <Typography style={{ marginBottom: "2px" }} variant="body2">
                Name
              </Typography>
              <TextField
                value={details.name}
                fullWidth
                size="small"
                variant="outlined"
                disabled
              />
            </Grid>
            <Grid item md={3}>
              <Typography style={{ marginBottom: "2px" }} variant="body2">
                Email
              </Typography>
              <TextField
                value={details.email}
                fullWidth
                size="small"
                variant="outlined"
                disabled
              />
            </Grid>
            <Grid item md={3}>
              <Typography style={{ marginBottom: "2px" }} variant="body2">
                Phone number
              </Typography>
              <TextField
                value={details.phonenumber}
                fullWidth
                size="small"
                variant="outlined"
                disabled
              />
            </Grid>
            {/* <Grid item md={3}>
              <Typography style={{ marginBottom: "2px" }} variant="body2">
                Organization Role
              </Typography>
              <TextField
                fullWidth
                value={details.organization_role}
                size="small"
                variant="outlined"
                disabled
              />
            </Grid> */}
            <Grid item md={12}>
              <div>
                <Organization {...props} />
              </div>
            </Grid>
            <Grid item md={12}>
              <div>
                <CustomerSetting {...props} />
              </div>
            </Grid>
            <Grid item md={12}>
              <div>
                <Products data={[...(data || [])]} {...props} />
              </div>
            </Grid>
            <Grid item md={12}>
              <InvoiceTable data={props.invoiceData} {...props} />
            </Grid>
            <Grid item md={12}>
              <CustomerIssue {...props} />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};
export default DetailForm;
