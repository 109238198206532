import React from "react";
import { Grid, IconButton, Typography } from "@material-ui/core";
import withSection from "components/Section/Section";
import { TextValidator } from "react-material-ui-form-validator";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { SelectDayComponent } from "components/SetDayComponent";
import EventTable from "./components/EventTable";
const useStyles = makeStyles((theme) => ({
  customHoverFocus: {
    "MuiIconButton-label": {
      background: theme.palette.primary,
    },
  },
}));

// schedule: {
//     start_time: "",
//     end_time: "",
//     repeat_days: [],
//     valid_from: "",
//     valid_till: "",
//   },

const SchedulingComponent = (props) => {
  const { isDisabled, outgoing, error, err_msg, handleOnchange } = props;
  const classes = useStyles();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={3}>
          <div style={{ width: "100%" }}>
            <Typography style={{ marginBottom: "2px" }} variant="body2">
              Valid From:
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextValidator
              type="date"
              disabled={isDisabled}
              style={{ width: "100%" }}
              fullwidth
              name="valid-from"
              value={outgoing?.schedule?.valid_from || ""}
              variant="outlined"
              onChange={(e) =>
                handleOnchange({
                  ...outgoing,
                  schedule: {
                    ...outgoing.schedule,
                    valid_from: e.target.value,
                  },
                })
              }
              validators={["required"]}
              errorMessages={["Please enter date"]}
              autoComplete="off"
              size="small"
              //   error={error}
              //   onFocus={this.setDuplicateNull}
              //   validators={["required"]}
              //   errorMessages={["Please enter proxy url"]}
              //   helperText={err_msg}
            />
          </div>
        </Grid>
        <Grid item md={3}>
          <div style={{ width: "100%" }}>
            <Typography style={{ marginBottom: "2px" }} variant="body2">
              Valid Till:
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextValidator
              disabled={isDisabled}
              style={{ width: "100%" }}
              fullwidth
              name="valid-till"
              value={outgoing?.schedule?.valid_till || ""}
              type="date"
              variant="outlined"
              validators={["required"]}
              errorMessages={["Please enter valid till"]}
              onChange={(e) =>
                handleOnchange({
                  ...outgoing,
                  schedule: {
                    ...outgoing.schedule,
                    valid_till: e.target.value,
                  },
                })
              }
              autoComplete="off"
              size="small"
              //   error={error}
              //   onFocus={this.setDuplicateNull}
              //   validators={["required"]}
              //   errorMessages={["Please enter proxy url"]}
              //   helperText={err_msg}
            />
          </div>
        </Grid>
        <Grid item md={12}>
          <EventTable handleOnchange={handleOnchange} outgoing={outgoing} />
        </Grid>
      </Grid>
    </>
  );
};
export default withSection(SchedulingComponent, "Scheduling");
