/**
 * value
 */

import React, {
  Component,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import ReactFlow, {
  MiniMap,
  Controls,
  updateEdge,
  applyEdgeChanges,
  applyNodeChanges,
  MarkerType,
  Background,
  addEdge,
  ReactFlowProvider,
  useNodesState,
  useEdgesState,
} from "react-flow-renderer";
import { Grid } from "@material-ui/core";
import CustomValueMapNode from "./CustomValueMapNode";
import CustomEdge from "./CustomEdge";
import SidePanel from "./SidePanel";
import ConcatNode from "./Operations/ConcatNode";
import JoinNode from "./Operations/JoinNode";
import FilterNode from "./Operations/FilterNode";
import SplitNode from "./Operations/SplitNode";
import ExpressionNode from "./Operations/Expression";
import { v4 as uuidv4 } from "uuid";
import validators from "./validators";
import { getNodeFromTitle } from "helpers/tree-utils";
import { confirmDialog } from "components/DeleteConfirmationDialog/Index";

const connectionLineStyle = { stroke: "#000000", width: 2 };

const snapGrid = [20, 20];
const edgeTypes = {
  custom: CustomEdge,
};

// UUID generation for new nodes.
const getId = (type) => {
  return `${type}-${uuidv4()}`;
};

const nodeTypes = {
  sortableTree: CustomValueMapNode,
  concat: ConcatNode,
  join: JoinNode,
  split: SplitNode,
  filter: FilterNode,
  expression: ExpressionNode,
};

const generateEdgeId = (params, type) => {
  if (type === "apitocdu") {
    if (params.source === "api") {
      return `reactflow__edge-api${params.sourceHandle}-${params.target}${params.targetHandle}`;
    } else {
      return `reactflow__edge-${params.source}${params.sourceHandle}-cdu${params.targetHandle}`;
    }
  } else {
    if (params.source === "cdu") {
      return `reactflow__edge-cdu${params.sourceHandle}-${params.target}${params.targetHandle}`;
    } else {
      return `reactflow__edge-${params.source}${params.sourceHandle}-api${params.targetHandle}`;
    }
  }
};

const ValueMapFlowRenderer = (props) => {
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const reactFlowRef = useRef(null);
  const onConnect = (params) => {
    console.log("123cnn", 1, params, props);
    // is a Valid edge...
    if (
      !validators.validateEdge(
        params,
        props.nodes,
        props.edges,
        props.type,
        props.api_nodes,
        props.cdu_nodes
      )
    ) {
      // show alert may be ...
      console.log("edge cannot be created main ");
      return;
    }

    if (params.source === "cdu" && params.target === "api") {
      let src_title = params.sourceHandle.slice(8);
      let trg_title = params.targetHandle.slice(8);
      // removing cdu__$__ it fixed size for both types...
      let cduNode = getNodeFromTitle(src_title, props.cdu_nodes);
      let apiNode = getNodeFromTitle(trg_title, props.api_nodes);
      console.log("normal edges validation cdu to api", cduNode, apiNode);
      if (cduNode.type !== apiNode.type) {
        //type should be same
        return;
      }
      if (
        cduNode.type === "array" &&
        apiNode.type === "array" &&
        cduNode.children.find((f) => f.title === "arrayElement").type !==
          apiNode.children.find((f) => f.title === "arrayElement").type
      ) {
        return;
      }
    }
    if (params.source === "api" && params.target === "cdu") {
      let src_title = params.sourceHandle.slice(8);
      let trg_title = params.targetHandle.slice(8);
      // removing cdu__$__ it fixed size for both types...
      let apiNode = getNodeFromTitle(src_title, props.api_nodes);
      let cduNode = getNodeFromTitle(trg_title, props.cdu_nodes);
      console.log("normal edges validation api to cdu", cduNode, apiNode);

      if (cduNode.type !== apiNode.type) {
        //type should be same
        return;
      }

      console.log();
    }

    // if the target node is filter node, then find the source node
    //and add it as input to filternode.

    if (params.target.includes("expression")) {
      const targetNode = props.nodes.find((r) => r.id === params.target);
      console.log("sagjgsajkgskdgeywey", targetNode);

      let sourceNodeData = {};
      if (props.type === "apitocdu") {
        // retrive path from titles ...
        let title = params.sourceHandle.slice(8);
        // removing cdu__$__ it fixed size for both types...
        let node = getNodeFromTitle(title, props.api_nodes);
        console.log("123cnn", 2, node);
        props.onChangeNodeData(params.target, "filter", {
          connectedNode: [
            ...(targetNode?.data?.input?.connectedNode || []),
            {
              node,
              parentPath: title,
              edgeId: generateEdgeId(params, props.type),
            },
          ],
        });
        // use node to get the nodedata ...
      } else {
        let title = params.sourceHandle.slice(8); // removing cdu__$__ it fixed size for both types...
        let node = getNodeFromTitle(title, props.cdu_nodes);
        console.log("123cnn", 4, node);
        // use node to get the nodedata ...
        props.onChangeNodeData(params.target, "filter", {
          connectedNode: [
            ...(targetNode?.data?.input?.connectedNode || []),
            {
              node,
              parentPath: title,
              edgeId: generateEdgeId(params, props.type),
            },
          ],
        });
      }
    }

    if (params.target.includes("filter")) {
      if (props.type === "apitocdu") {
        // retrive path from titles ...
        let title = params.sourceHandle.slice(8);
        // removing cdu__$__ it fixed size for both types...
        let node = getNodeFromTitle(title, props.api_nodes);
        console.log("123cnn", 2, node);
        if (node.type !== "array") {
          return;
        }
        props.onChangeNodeData(params.target, "filter", {
          connectedNode: node,
          parentPath: title,
          edgeId: generateEdgeId(params, props.type),
        });
        // use node to get the nodedata ...
      } else {
        let title = params.sourceHandle.slice(8); // removing cdu__$__ it fixed size for both types...
        let node = getNodeFromTitle(title, props.cdu_nodes);
        console.log("123cnn", 4, node);
        // use node to get the nodedata ...
        if (node.type !== "array") {
          return;
        }
        props.onChangeNodeData(params.target, "filter", {
          connectedNode: node,
          parentPath: title,
          edgeId: generateEdgeId(params, props.type),
        });
      }
    }

    // get node infromation
    props.onEdgesChange(
      addEdge(
        {
          ...params,
          data: {
            onDeleteEdge: props.deleteEdge,
          },
          type: "custom",
          style: { stroke: "#000" },
          markerEnd: {
            type: MarkerType.ArrowClosed,
          },
        },
        props.edges
      )
    );
  };
  const onConnectStart = (e, p) => {
    console.log({ e, p });
  };

  // function to add operation nodes by dragging and dropping the node...
  const onDrop = (event) => {
    console.log("8023742374239", event);
    event.preventDefault();
    const reactFlowBounds = reactFlowRef.current.getBoundingClientRect();
    const type = event.dataTransfer.getData("nodeType");
    const position = reactFlowInstance.project({
      x: event.clientX - reactFlowBounds.left,
      y: event.clientY - reactFlowBounds.top,
    });
    console.log("event", type, event.dataTransfer);

    if (
      type === "filter" ||
      type === "split" ||
      type === "concat" ||
      type === "expression"
    ) {
      const newNode = {
        id: getId(type),
        type,
        position,
        sourcePosition: props.type == "cdutoapi" ? "right" : "left",
        targetPosition: props.type == "cdutoapi" ? "left" : "right",
        data: {
          type: props.type,
          label: `${type} node`,
          inputCount: 0,
          input: {},
          onChangeNodeData: props.onChangeNodeData,
          onNodeDelete: props.onNodeDelete,
          getEdgesWithNodeIdAndHandleId: props.getEdgesWithNodeIdAndHandleId,
          deleteMultipleEdges: props.deleteMultipleEdges,
        },
      };
      props.onNodesChange([...props.nodes, newNode]);
    }
  };

  /// On drag over to drag and drop the operation node
  const onDragOver = (event) => {
    event.preventDefault();
    console.log("DRAG");
    event.dataTransfer.dropEffect = "move";
  };
  // to update the edge connections
  const onEdgeUpdate = (oldEdge, newConnection) => {
    // need to perform validation here too, to check if the new edge is proper or not
    if (
      !validators.validateEdge(
        newConnection,
        props.nodes,
        props.edges,
        props.type,
        props.api_nodes,
        props.cdu_nodes
      )
    ) {
      // show alert may be ...
      console.log("edge cannot be created ");
      return;
    }
    props.onEdgesChange(updateEdge(oldEdge, newConnection, props.edges));
  };

  const onEdgeUpdateStart = async (e, edge, handleType) => {
    console.log("testeedeeedd", edge, handleType);
    await e.preventDefault();
  };
  const onEdgeClick = (e, edge) => {
    props.onEdgesChange(
      props.edges.map((newEdge) => {
        if (newEdge.id == edge.id) {
          newEdge.selected = !newEdge.selected;
          newEdge.animated = !newEdge.animated;
        }
        return newEdge;
      })
    );
  };
  // function to capture the data from operation component
  console.log("sajdjsadjasjdgsjkagdjksagjdkgsajkdga", JSON.stringify(props));
  return (
    <>
      <Grid container spacing={1} style={{ marginTop: "5px" }}>
        <Grid item sm={2} md={2} lg={2}>
          <SidePanel />
        </Grid>
        <Grid
          item
          sm={10}
          md={10}
          lg={10}
          style={{
            borderColor: "black",
            borderWidth: "1px",
            borderStyle: "solid",
          }}
        >
          <div style={{ height: "700px", width: "100%" }} ref={reactFlowRef}>
            {/* <ReactFlowProvider> */}
            <ReactFlow
              nodes={props.nodes}
              edges={props.edges}
              onNodesChange={(changes) => {
                props.onNodesChange(applyNodeChanges(changes, props.nodes));
              }}
              onEdgesChange={(changes) => {
                props.onEdgesChange(applyEdgeChanges(changes, props.edges));
              }}
              onEdgeUpdate={onEdgeUpdate}
              onConnect={onConnect}
              nodeTypes={nodeTypes}
              connectionLineStyle={connectionLineStyle}
              fitView
              onInit={setReactFlowInstance}
              edgeTypes={edgeTypes}
              onConnectStart={onConnectStart}
              onDrop={onDrop}
              onDragOver={onDragOver}
              elementsSelectable={true}
            >
              <Background variant="lines" gap={12} size={1} />
            </ReactFlow>
            {/* </ReactFlowProvider> */}
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default ValueMapFlowRenderer;

///////
