/**
 * Contains All the API call for the Billable services
 */
import getURL from "../config";
import API_helpers from "../index";

async function fetchAllBillableServices() {
  let url = getURL("BILLABLE_SERVICES");
  try {
    let res = await API_helpers.GET(url, {
      headers: { "Content-Type": "application/json" },
    });
    return (res.data || {}).data || [];
  } catch (err) {
    throw err;
  }
}

// async function fetchAllBillableServicesWithParams(page, size, filters, sort) {
//   let url = getURL("BILLABLE_SERVICES");
//   url = url + "?";
//   let params = { page, size, filters, sort };
//   Object.keys(params)
//     .filter((k) => params[k] != undefined || params[k] != null)
//     .forEach((k) => (url = `${url}${k}=${params[k]}&`));
//   url = url.substr(0, url.length - 1);
//   try {
//     let res = await API_helpers.GET(url, {
//       headers: { "Content-Type": "application/json" },
//     });
//     return (res.data || {}).data || [];
//   } catch (err) {
//     throw err;
//   }
// }

async function editBillableServices(billableId, payload) {
  let url = getURL("EDIT_BILLABLESERVICES")(billableId);
  try {
    let res = await API_helpers.PUT(url, payload, {
      headers: { "Content-Type": "application/json" },
    });
    return res.data;
  } catch (err) {
    throw err;
  }
}

async function fetchAllBillableServicesWithParams(page, size, filters, sort) {
  let url = getURL("BILLABLE_SERVICES");
  url = url + "?";
  let params = { page, size, filters, sort };
  Object.keys(params)
    .filter((k) => params[k] != undefined || params[k] != null)
    .forEach((k) => (url = `${url}${k}=${params[k]}&`));
  url = url.substr(0, url.length - 1);
  try {
    let res = await API_helpers.GET(url, {
      headers: { "Content-Type": "application/json" },
    });
    return res.data;
  } catch (err) {
    throw err;
  }
}

async function deleteBillableServices(billableId) {
  let url = getURL("DELETE_BILLABLESERVICES")(billableId);
  try {
    let res = await API_helpers.DELETE(url, {
      headers: { "Content-Type": "application/json" },
    });
    return res;
  } catch (err) {
    throw err;
  }
}

async function addBillableServices(payload) {
  let url = getURL("ADD_BILLABLESERVICES");
  try {
    let res = await API_helpers.POST(url, { ...payload });
    //   payload, {
    //   headers: { "Content-Type": "application/json" },
    // });
    return res.data;
  } catch (err) {
    throw err;
  }
}

async function getAllCategories() {
  let url = getURL("BILLING_CATEGORIES")
  try {
    let res = await API_helpers.GET(url);
    return res
  } catch (err) {
    throw err;
  }
}

async function addCategories(value){
  let url = getURL("ADD_BILLING_CATEGORY")
  try{
    let res = await API_helpers.POST(url,{ name: value });
    return res
  }catch (err) {
    throw err;
  }
}

var APIs = {
  fetchAllBillableServices,
  fetchAllBillableServicesWithParams,
  editBillableServices,
  deleteBillableServices,
  addBillableServices,
  getAllCategories,
  addCategories,
};

export default APIs;
