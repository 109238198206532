import React from "react";
import {
  getBezierPath,
  getSmoothStepPath,
  getEdgeCenter,
  getMarkerEnd,
} from "react-flow-renderer";

const foreignObjectSize = 40;

export default function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  selected,
  ...otherProps
}) {
  console.log({ otherProps, markerEnd });
  const edgePath = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const borderRadius = 2;

  const [edgeCenterX, edgeCenterY] = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });

  // const edgePath = getSmoothStepPath({
  //   sourceX,
  //   sourceY,
  //   sourcePosition,
  //   targetX,
  //   targetY,
  //   targetPosition,
  //   borderRadius,
  // })
  console.log({ selected });
  return (
    <>
      <path
        id={id}
        style={{
          ...style,
          cursor: "pointer",
          strokeWidth: "3px",
          stroke: "#b1b1b6",
        }}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
      />
      {selected && (
        <foreignObject
          width={foreignObjectSize}
          height={foreignObjectSize}
          x={edgeCenterX - foreignObjectSize / 2}
          y={edgeCenterY - foreignObjectSize / 2}
          className="edgebutton-foreignobject"
          requiredExtensions="http://www.w3.org/1999/xhtml"
        >
          <body>
            <button
              className="edgebutton"
              onClick={(e) => {
                e.preventDefault();
                otherProps.data.onDeleteEdge(id);
              }}
            >
              ×
            </button>
          </body>
        </foreignObject>
      )}
    </>
  );
}
