const pricingDetails = {
  name: 'pricingDetailsPlugin',
  display: 'command',

  title: 'Add pricing details',
  buttonClass: '',
  innerHTML: '<i class="fas fa-dollar-sign"></i>',

  add: function (core, targetElement) {
    const context = core.context;
    context.pricingDetailsPlugin = {
      targetButton: targetElement
    };
  },

  active: function (element) {
    if (!element) {
      this.util.removeClass(this.context.pricingDetailsPlugin.targetButton, 'active');
    } else if (/^mark$/i.test(element.nodeName) && element.style.backgroundColor.length > 0) {
      this.util.addClass(this.context.pricingDetailsPlugin.targetButton, 'active');
      return true;
    }

    return false;
  },

  action: function () {
    let ele = '<div contenteditable="false" id="pricing123" class="__se__p-bordered se-component pricing123"><pricing>Pricing details</pricing></div><br />'
    const newNode = this.functions.insertHTML(ele)
    this.nodeChange(newNode, null, null, null)

    // let pricingDetails = null;
    // console.clear()
    // axios
    // .create({
    //   baseURL: process.env.REACT_APP_BASE_URL_BS,
    //   headers: {
    //     organization: "11111",
    //     userdetails: JSON.stringify({name: "palani"})
    //   }
    // })
    // .get("/api/v1/applications/614ad2bc169f431bc5afd9e1/pricingServices")
    // .then((response) => {
    //   console.log(response)
    //   pricingDetails = response.data.data

    //   let ele = ''
    //   console.log("What is pricingDetails: ", pricingDetails)
    //   if (pricingDetails) {
    //     ele = '<p>Price</p><table><tr><th>Scale</th><th>Price</th></tr>'
    //     for (var i = 0; i < pricingDetails.length; i++) {
    //       let item = pricingDetails[i]
    //       if (item.type === 'slab' && item.quantityScale.length > 0) {
    //         for (var j = 0; j < item.quantityScale.length; j++) {
    //           let tupple = item.quantityScale[j]
    //           ele += '<tr><td>'+tupple.start+'-'+tupple.end+'</td><td>'+tupple.price+'</td></tr>'
    //         }
    //       }
    //       if(item.discount.length > 0) {
    //         ele += '</table><p>Discounts</p><table><tr><th>Subscription Plan</th><th>Discount</th></tr>'
    //         for (var j=0; j<item.discount.length; j++) {
    //           let tupple = item.discount[j]
    //           ele += '<tr><td>'+tupple.subscriptionId+'</td><td>'+tupple.unit+'</td></tr>'
    //         }
    //       }
    //     }
    //     ele = ele + '</table>'
    //   }

    //   const newNode = this.setContents(ele)
    //   this.nodeChange(newNode, null, null, null)
    // })
    // .catch((error) => {
    //   console.log(error)
    // })

    

    // const ele = "<div><p>Hi</p></div>"
    // const newNode = this.util.innerHTML(ele)
    // const newNode = this.util.createElement(ele);
    // this.nodeChange(ele, null, null, null);

    // if (!this.util.hasClass(this.context.pricingDetailsPlugin.targetButton, 'active')) {
    //   const newNode = this.util.createElement('MARK');
    //   newNode.style.backgroundColor = 'hsl(60,75%,60%)';
    //   this.nodeChange(newNode, ['background-color'], null, null);
    // } else {
    //   this.nodeChange(null, ['background-color'], ['mark'], true);
    // }
  }
}

export default pricingDetails