/**
 * Pagination higher order component to maintain pagination logic
 */

import React, { Component, useState, useEffect } from "react";
import Pagination from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  customPagination: {
    width: "fit-content",
    marginLeft: "auto",
    marginTop: "5px",
    "& .MuiPaginationItem-page.Mui-selected": {
      border: "0.5px solid #707070",
      margin: "0 10px",
      height: 35,
      width: 30,
      padding: "10px 13px",
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      boxShadow: "0px 3px 10px #5757571a",
      lineHeight: 1,
      borderRadius: 5,
      opacity: 1,
    },
    "& .MuiPaginationItem-page": {
      font: "normal normal normal 14px/19px Nunito Sans",
      letterSpacing: 0,
      color: "#000000",
      opacity: 0.6,
    },
  },
}));

function withPagination(WrappedComponent) {
  return (props) => {
    console.log("wrappedpagintation", { props });
    const [masterdata, setMasterdata] = useState(props.data || []);
    console.log("it is datadatata gthf fdfd", props.data);
    const [page, setPage] = useState(1);
    const [data, setData] = useState(
      (props.data || []).slice((page - 1) * 5, (page - 1) * 5 + 5) || []
    );

    console.log(
      "wrappedpagintation",
      props.data.length,
      masterdata,
      masterdata.slice((page - 1) * 5, (page - 1) * 5 + 5)
    );
    useEffect(() => {
      setMasterdata([...(props.data || [])]);
      let newdata = [...(props.data || [])].slice(
        (page - 1) * 5,
        (page - 1) * 5 + 5
      );

      if (newdata.length === 0) {
        let firstPageData = [...(props.data || [])].slice(0, 5);
        setPage(1);
        setData(firstPageData);
      } else {
        setData(newdata);
      }
    }, [page, props.data]);

    const onChange = (e, pageno) => {
      setPage(pageno);
    };
    const classes = useStyles();
    return (
      <div>
        <WrappedComponent {...props} data={data} />
        <div className={classes.customPagination}>
          <Pagination
            count={Math.ceil(masterdata.length / 5)}
            page={page}
            onChange={onChange}
            shape="rounded"
            showFirstButton
            showLastButton
          />
        </div>
      </div>
    );
  };
}

export default withPagination;
