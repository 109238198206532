import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Dialog, DialogContent, DialogActions } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { TextField, Button } from "@material-ui/core/";

// const useStyles = makeStyles({
//   avatar: {
//     backgroundColor: blue[100],
//     color: blue[600],
//   },
// });

const UpdateStatusModal = (props) => {
  const { open, setDialog, custUrl, setCustUrl } = props;
  const [err, setErr] = useState(false);

  const onSubmitFunc = async () => {
    if (
      custUrl.customerUrl !== null &&
      custUrl.customerUrl.trim().length === 0
    ) {
      setErr(true);
    } else {
      await props.submitStatusApproved(custUrl);
      setCustUrl({
        subId: 0,
        launchStatus: "Approved",
        custUrl: "",
      });
      setDialog(false);
    }
  };
  console.log("url4520", custUrl);
  return (
    <Dialog
      // fullWidth
      // maxWidth={"md"}
      onClose={() => setDialog(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={open}
    >
      <DialogTitle>
        <Typography>{"Update status"}</Typography>
      </DialogTitle>
      <DialogContent
        style={{
          //   height: "150px",
          width: "400px",
          marginTop: "20px",
        }}
      >
        <div>
          {
            <div>
              <Typography>Customer URL:</Typography>
              <TextField
                id="customer-url"
                name="customerUrl"
                type="text"
                variant="outlined"
                size="small"
                fullWidth
                margin="normal"
                onChange={(e) => {
                  setErr(false);
                  setCustUrl({
                    ...custUrl,
                    customerUrl: e.target.value,
                  });
                }}
                value={custUrl.customerUrl}
                error={err}
                helperText={
                  <span
                    style={{
                      color: "red",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      border: "solid 1px #FFFFFF",
                    }}
                  >
                    {err && "Please enter URL"}
                  </span>
                }
              />
            </div>
          }
        </div>
      </DialogContent>

      <DialogActions>
        <Button
          style={{
            marginLeft: 0,
            // backgroundColor: "transparent",
            // boxShadow: "none",
            // color: "#9e9e9e",
          }}
          size="small"
          variant="contained"
          color="primary"
          onClick={() => {
            onSubmitFunc();
          }}
        >
          Submit
        </Button>

        <Button
          style={{
            marginLeft: 0,
            backgroundColor: "transparent",
            boxShadow: "none",
            color: "#9e9e9e",
          }}
          variant="contained"
          color="primary"
          onClick={() => {
            setErr(false);
            setDialog(false);
            setCustUrl({
              launchStatus: "Approved",
              customerUrl: "",
              subId: 0,
            });
          }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateStatusModal;
