/**
 * Contains All the API call for the Billable services
 */
import getURL from "../config";
import API_helpers from "../index";

// const getSubscriptionModel = async () => {
//   try {
//     let res = await API_helpers.GET(getURL("FETCH_ALL_SUBSCRIPTION_MODEL"));
//     return res;
//   } catch (err) {
//     throw err;
//   }
// };

// const getValueMap = async (page, size, filters, sort) => {
//   let url = getURL("FETCH_ALL_VALUEMAP_DATA");
//   url = url + "?";
//   let params = { page, size, filters, sort };
//   Object.keys(params)
//     .filter((k) => params[k] != undefined || params[k] != null)
//     .forEach((k) => (url = `${url}${k}=${params[k]}&`));
//   url = url.substr(0, url.length - 1);
//   console.log(url, "urll");
//   try {
//     let res = await API_helpers.GET(url, {
//       headers: {
//         "Content-Type": "application/json",
//       },
//     });
//     return res;
//   } catch (err) {
//     throw err;
//   }
// };

// async function fetchValueMap(page, size, filters, sort) {
//   let url = getURL("FETCH_ALL_VALUEMAP_DATA");
//   url = url + "?";
//   let params = { page, size, filters, sort };
//   Object.keys(params)
//     .filter((k) => params[k] != undefined || params[k] != null)
//     .forEach((k) => (url = `${url}${k}=${params[k]}&`));
//   url = url.substr(0, url.length - 1);
//   console.log(url, "urll");
//   try {
//     let res = await API_helpers.GET(url, {
//       headers: {
//         "Content-Type": "application/json",
//         userdetails: JSON.stringify({ name: "palani" }),
//         organization: "11111",
//       },
//     });
//     return res.data;
//   } catch (err) {
//     throw err;
//   }
// }

const addValueMap = async (name, payload) => {
  try {
    let res = await API_helpers.POST(
      getURL("ADD_VALUEMAP_DATA")(name),
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          organization: "11111",
          userdetails: JSON.stringify({ name: "palani" }),
        },
      }
    );
    return res;
  } catch (err) {
    throw err;
  }
};

const editValueMap = async (name, payload, id) => {
  try {
    let res = await API_helpers.PUT(
      getURL("EDIT_VALUEMAP_DATA")(name, id),
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          organization: "11111",
          userdetails: JSON.stringify({ name: "palani" }),
        },
      }
    );
    return res;
  } catch (err) {
    throw err;
  }
};

const deleteValueMapData = async (fieldname, id) => {
  try {
    let res = await API_helpers.DELETE(
      getURL("DELETE_VALUE_MAP_DATA")(fieldname, id),
      {
        headers: {
          "Content-Type": "application/json",
          organization: "11111",
          userdetails: JSON.stringify({ name: "palani" }),
        },
      }
    );
    return res;
  } catch (err) {
    throw err;
  }
};

var APIs = {
  // getValueMap,
  addValueMap,
  editValueMap,
  deleteValueMapData,
  // fetchValueMap,
  // addSubscription,
  // editSubscription,
  // deleteSubscription,
};

export default APIs;
