import { React, useState, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import Avatar from "@material-ui/core/Avatar";
import StarIcon from "@material-ui/icons/Star";
import Divider from "@material-ui/core/Divider";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import ShareIcon from "@material-ui/icons/Share";
import DeviceHubIcon from "@material-ui/icons/DeviceHub";
import {
  IconButton,
  Tooltip,
  CardContent,
  CardHeader,
  FormGroup,
  Card,
  Grid,
  Button,
  TextField,
  Typography,
  FormLabel,
  MenuItem,
} from "@material-ui/core";
import withSection from "components/Section/Section";

const useStyles = makeStyles((theme) => ({
  line: {
    marginTop: -23,
  },

  dividerline: {
    // border: "1px solid black",
    width: "70%",
    margin: "-25px 0px 0px 125px",
    [theme.breakpoints.up("md")]: {
      width: "82%",
      margin: "-25px 0px 0px 260px",
    },
  },
}));
const StyledTableCell = withStyles((theme) => ({
  head: {
    // backgroundColor: "#999682",
    backgroundColor: theme.palette.primary.main,

    color: theme.palette.common.white,
  },
  body: {
    // height: "14vh",
    width: 15,
    height: 1,
  },
  btn: {
    width: "300px",
  },
}))(TableCell);

const CustomTableRow = (props) => {
  const {
    appId,
    row,
    removeRows,
    index,
    onChange,
    addOutgoingApi,
    deleteApplicationAPI,
  } = props;
  const classes = useStyles();
  const [edit, setEdit] = useState(row._id == undefined || false);
  const [apis, setAPIs] = useState([]);
  useEffect(async () => {
    console.log(row.outgoingAppId, "app id");
    let data = await props.getAppApi(row.outgoingAppId);
    setAPIs([...data]);
  }, [row]);

  console.log(apis, row, "apis");
  return (
    <>
      <StyledTableCell component="th" scope="row">
        <TextField
          disabled={edit ? false : true}
          style={{ width: "100%" }}
          fullwidth
          label="Proxy URL"
          size="small"
          name="url"
          value={row.url}
          variant="outlined"
          onChange={(e) => onChange(e, index)}
        />
      </StyledTableCell>
      <StyledTableCell align="left">
        <FormControl
          disabled={edit ? false : true}
          size="small"
          fullWidth
          variant="outlined"
        >
          {/* <InputLabel htmlFor="outlined-age-native-simple">
           
          </InputLabel> */}
          <Select
            required
            native
            inputProps={{
              name: "outgoingAppId",
              id: "appId",
            }}
            value={row.outgoingAppId}
            onChange={(e) => {
              onChange(e, index);
            }}
          >
            <option aria-label="None" value="">
              {" "}
              -Select-
              {"  "}
            </option>
            {props.allPublishedApp.map((item, index) => (
              <option key={index} value={item._id}>
                {item.name}
              </option>
            ))}
          </Select>
        </FormControl>
      </StyledTableCell>
      <StyledTableCell align="left">
        <FormControl
          disabled={edit ? false : true}
          size="small"
          fullWidth
          variant="outlined"
        >
          {/* <InputLabel htmlFor="outlined-age-native-simple">APIS</InputLabel> */}
          <Select
            native
            // label="APIS"
            inputProps={{
              name: "outgoingApiId",
              id: "outlined-age-native-simple",
            }}
            value={row.outgoingApiId}
            onChange={(e) => onChange(e, index)}
          >
            <option aria-label="None" value="">
              {" "}
              -Select-
              {"  "}{" "}
            </option>
            {apis.map((item, index) => (
              <option key={index} value={item._id} aria-label="None">
                {item.name}
              </option>
            ))}
          </Select>
        </FormControl>
      </StyledTableCell>
      <StyledTableCell align="center">
        {edit && (
          <>
            <Button
              variant="contained"
              color="secondary"
              style={{
                marginLeft: 0,
                backgroundColor: "transparent",
                boxShadow: "none",
                color: "#9e9e9e",
              }}
              variant="contained"
              onClick={async () => {
                if (row._id === undefined) {
                  await addOutgoingApi(appId, row);
                } else {
                  await props.editOutGoingApi(appId, row._id, row);
                }
                setEdit(false);
              }}
            >
              Save
            </Button>{" "}
            <Button
              style={{
                marginLeft: 0,
                backgroundColor: "transparent",
                boxShadow: "none",
                color: "#9e9e9e",
              }}
              variant="contained"
              color="primary"
              onClick={(event) => {
                if (row._id == undefined) {
                  removeRows(event, index);
                } else {
                  setEdit(false);
                }
              }}
            >
              Cancel
            </Button>
          </>
        )}
        {!edit && (
          <>
            <Tooltip title="CDU Mapping" aria-label="add">
              <Button
                onClick={() => {
                  console.log({ appId, row });
                  props.routeToCDUMap(appId, row);
                }}
              >
                {" "}
                <DeviceHubIcon />{" "}
              </Button>
            </Tooltip>
            <Tooltip title="Edit" aria-label="edit">
              <Button
                onClick={() => {
                  setEdit(true);
                }}
              >
                {" "}
                <EditOutlinedIcon />{" "}
              </Button>
            </Tooltip>
            <Tooltip title="Delete" aria-label="delete">
              <Button
                onClick={async () => {
                  await props.deleteOutgoingApi(appId, row._id);
                }}
              >
                {" "}
                <DeleteOutlineIcon />{" "}
              </Button>
            </Tooltip>
            {props.addNewRow}
          </>
        )}
      </StyledTableCell>
    </>
  );
};

function OutgoingAPI(props) {
  const {
    appId,
    data,
    editApplicationAPI,
    deleteApplicationAPI,
    routeToCDUMap,
  } = props;
  let [appApiCount, setAppApiCount] = useState(1);
  const [edit, setEdit] = useState(false);
  const classes = useStyles();

  let [rows, setRows] = useState([]);

  useEffect(() => {
    setRows(data);
    console.log(rows);
  }, [data]);

  const addRows = () => {
    const item = { url: "", outgoingAppId: "", outgoingApiId: "" };
    setRows([...rows, item]);
  };

  const removeRows = (payload, index) => {
    console.log({ payload });
    if (rows.length != 1) {
      var filtered = rows.filter(function (el, i) {
        console.log(index, i, index !== i);
        return index !== i;
      });
      console.log({ filtered });
      setRows([...filtered]);
    }
  };

  const onChange = (event, index) => {
    let payload = rows.filter(function (el, i) {
      console.log(index, i, index !== i);
      return index == i;
    })[0];
    let newpayload = { ...payload };
    newpayload[event.currentTarget.name] = event.currentTarget.value;
    console.log(newpayload, "payloadddd");
    if (event.currentTarget.name === "outgoingAppId") {
      newpayload["outgoingApiId"] = "";
    }
    let data = [...rows];
    data[index] = newpayload;
    setRows([...data]);
  };
  console.log(rows, data, "jfghfghfghfg");
  return (
    <div id="appliation-outgoingapis-section">
      <div className="w-100 d-flex justify-content-end">
        {data.length == 0 && (
          <Tooltip title="Add" aria-label="add">
            <Button
              startIcon={<AddCircleOutlineOutlinedIcon />}
              color="secondary"
              onClick={addRows}
            >
              Add Outgoing API
            </Button>
          </Tooltip>
        )}
      </div>

      <div>
        <TableContainer style={{ borderRadius: "0" }}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left" style={{ width: "25%" }}>
                  {" "}
                  Proxy URL{" "}
                </StyledTableCell>
                <StyledTableCell align="left"> Applications </StyledTableCell>
                <StyledTableCell align="left"> APIS </StyledTableCell>
                <StyledTableCell align="center" style={{ width: "23%" }}>
                  Actions
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow key={row.name}>
                  <CustomTableRow
                    row={row}
                    applicationApi={props.applicationApi}
                    getAppApi={props.getAppApi}
                    allPublishedApp={props.allPublishedApp}
                    appId={appId}
                    index={index}
                    removeRows={removeRows}
                    onChange={onChange}
                    addOutgoingApi={props.addOutgoingApi}
                    editOutGoingApi={props.editOutGoingApi}
                    deleteOutgoingApi={props.deleteOutgoingApi}
                    routeToCDUMap={routeToCDUMap}
                    addNewRow={
                      index == rows.length - 1 && (
                        <Tooltip title="Add" aria-label="add">
                          <Button
                            // startIcon={}
                            color="secondary"
                            onClick={addRows}
                          >
                            <AddCircleOutlineOutlinedIcon />
                          </Button>
                        </Tooltip>
                      )
                    }
                  />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default withSection(OutgoingAPI, "Outgoing API");
