//import { ApiHelper } from "./helper/ApiHelper";
import { BASE_URL } from "./helper/config";
import { USERPROFILE } from "./helper/config"
// const apiHelper = new ApiHelper();

export class UserProfile {
  fetchDetails(userid) {
    // const uri = `${BASE_URL}/api/v1.0.0/users/${userid}`;
    // return apiHelper.get(uri);
  }
  editDetails(userid,val){
    // const uri = `${BASE_URL}/api/v1.0.0/users/${userid}`;
    // return apiHelper.put(uri,val);
  }
  change2FA(userid,val){
    // const uri = `${BASE_URL}/api/v1.0.0/users/twofactorauthentication/${userid}`;
    // return apiHelper.put(uri,val);
  }
}
