import { Typography, Grid, Link, Badge } from "@material-ui/core";
import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
// import ModalTable from "./ModalTable";
import { Pagination } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import nodataimg from "assets/images/nodata.svg";

import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function InvoiceTable(props) {
  const classes = useStyles();
  const { nonGrpData } = props;
  return (
    <TableContainer>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Application</TableCell>
            <TableCell align="left">Module</TableCell>
            <TableCell align="left">Billable Service</TableCell>
            <TableCell align="left">Units</TableCell>
            <TableCell align="left">Units Consumed</TableCell>
            <TableCell align="left">Type</TableCell>
            <TableCell align="right">Price/Qty</TableCell>
            <TableCell align="right">Amount</TableCell>
            {/* <TableCell align="right">Slab details</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {nonGrpData.map((row) => {
            const type = row.Type === "slab";
            const totalFlatCost = (!type && row.Slabs[0]?.TotalCost) || 0;
            return (
              <TableRow key={row.name}>
                <TableCell component="th" scope="row">
                  {row?.AppDetails?.name || ""}
                </TableCell>
                <TableCell align="left">{row?.Module?.name || ""}</TableCell>
                <TableCell align="left">
                  {row?.BillableDetails?.name || ""}
                </TableCell>
                <TableCell align="left">
                  {" "}
                  {row?.BillableDetails?.unit || ""}
                </TableCell>
                <TableCell align="left">{row.Quantity}</TableCell>
                <TableCell align="left">{row.Type}</TableCell>
                <TableCell align="right">
                  {type && "-"}
                  {!type && totalFlatCost}
                </TableCell>
                <TableCell align="right">{row.GrossAmount}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const h3style = {
  display: "block",
  fontSize: "1.17em",
  marginBlockStart: "1em",
  marginBlockEnd: "1em",
  marginInlineStart: "0px",
  marginInlineEnd: "0px",
  fontWeight: "bold",
};

function TableComponent(props) {
  const classes = useStyles();
  const { billableService } = props;
  return (
    // <TableContainer>
    <Table className={classes.table} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>Billable Service</TableCell>
          <TableCell>Units</TableCell>
          <TableCell>Units Consumed</TableCell>
          <TableCell>Cost</TableCell>
          {/* <TableCell>Slab details</TableCell> */}
          <TableCell align="right">Amount</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {billableService.map((historyRow, key) => {
          let total = 0;
          const type = historyRow.Type === "slab";
          const flatQuantity = (!type && historyRow.Slabs[0]?.Quantity) || 0;

          const totalFlatCost = (!type && historyRow.Slabs[0]?.TotalCost) || 0;
          if (type && historyRow.Slabs) {
            historyRow.Slabs.map((itm, i) => (total = total + itm.TotalCost));
          }

          return (
            <TableRow key={key}>
              <TableCell
                component="th"
                scope="row"
                className={classes.tableheight}
              >
                {historyRow?.BillableDetails?.name || " "}
              </TableCell>
              <TableCell>{historyRow.BillableDetails.unit}</TableCell>
              <TableCell align="left">{historyRow.Quantity}</TableCell>
              <TableCell align="left">
                {" "}
                {type && total}
                {!type && totalFlatCost}
              </TableCell>
              {/* <TableCell align="left">
                {type && (
                  <Link
                    style={{
                      textDecoration: "none",
                      cursor: "pointer",
                    }}
                  >
                    view
                  </Link>
                )}
                {!type && <Typography>{flatQuantity}</Typography>}
              </TableCell> */}
              <TableCell align="right">{historyRow.GrossAmount}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
    // </TableContainer>
  );
}

const NoDataFound = (props) => {
  return (
    <td>
      <div
        style={{
          position: "absolute",
          textAlign: "center",
          // maxWidth: "11rem",
          // justifyContent:"center",
          // alignItems:"center",
          marginTop: "4%",
          marginLeft: "43%",
          // top:"calc(30% + 2rem)",
          // left:"calc(50% - 4rem)"
        }}
      >
        <img src={nodataimg} alt="notfound" />
        <h5 style={{ textAlign: "center", color: "#666666", ...h3style }}>
          {props.msg || "Data Not Found"}
        </h5>
      </div>
    </td>
  );
};

const Row = (props) => {
  const { rowData } = props;
  const { appDetails, billableService } = rowData || {
    appDetails: {},
    billableService: [],
  };
  return (
    <div>
      {
        <div style={{ width: "100%" }}>
          <div style={{ padding: "3px" }}>
            <Typography variant="body2">{appDetails.name}</Typography>
          </div>
          <TableComponent billableService={billableService} />
        </div>
      }
    </div>
  );
};

function CollapsibleTable(props) {
  const { classes, appUsageDetails } = props;
  return (
    <>
      {/* <TableContainer>
        <Table aria-label="collapsible table" className={classes.tableborder}>
          <TableHead>
            <TableRow>
              <TableCell>Application</TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
          {Object.keys(appUsageDetails).length !== 0 ? (
            <TableBody>
              {Object.keys(appUsageDetails).map((row, index) => {
                return (
                  <Row key={index} rowData={appUsageDetails[row]} {...props} />
                );
              })}
            </TableBody>
          ) : (
            <NoDataFound />
          )}
        </Table>
      </TableContainer> */}
      <div style={{ width: "100%" }}>
        <div style={{ width: "100%" }}>
          <Typography
            style={{ fontWeight: 800, color: "black", paddingBottom: "5px" }}
            variant="body2"
          >
            Application:
          </Typography>
        </div>
        {Object.keys(appUsageDetails).length !== 0 ? (
          <TableBody>
            {Object.keys(appUsageDetails).map((row, index) => {
              return (
                <Row key={index} rowData={appUsageDetails[row]} {...props} />
              );
            })}
          </TableBody>
        ) : (
          <NoDataFound />
        )}
      </div>
    </>
  );
}

const PrintAppDetails = (props) => {
  const { classes } = props;

  return (
    <div style={{ width: "100%" }}>
      {/* <CollapsibleTable {...props} /> */}
      <InvoiceTable {...props} />
    </div>
  );
};
export default PrintAppDetails;
