import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { CardContent, Card, Badge } from "@material-ui/core";
import ApplicationBasicEdit from "./ApplicationBasicEdit";
import moment from "moment";
import { RiEditLine } from "react-icons/ri";
const useStyles = makeStyles((theme) => ({
  line: {
    marginTop: -23,
  },
  para: {
    // display: "flex",
    // justifyContent: "space-between",
    flexDirection: "column",

    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "20px 65px 0px 65px",

      // border: "1px soild red",
    },
  },
  paragraph: {
    display: "flex",
    justifyContent: "flex-start",
    textAlign: "justify",
    marginTop: "10px",
    [theme.breakpoints.up("lg")]: {
      padding: "5px 65px 0px 65px",

      border: "1px soild red",
    },
  },
  customBadgeDraft: {
    backgroundColor: "#FBBD08",
  },
  iconname: {
    marginRight: "30px",
    fontSize: 50,
    alignItems: "center",
  },
  createdOn: {
    display: "flex",
    margin: "10px 20px 0px 0px",
  },
  basicdetailtext: {
    marginTop: "2%",
    [theme.breakpoints.up("md")]: {
      fontWeight: "bold",

      marginTop: "15px",
      marginLeft: "15px",
    },
  },
  description: {
    paddingTop: "1%",
    [theme.breakpoints.up("md")]: {
      marginLeft: "3%",
      borderRadius: "100px",
    },
  },
  tags: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: "0px 0px 0px 3px",
    // marginLeft: 0,
    [theme.breakpoints.up("md")]: {
      marginLeft: "10px",
      borderRadius: "100px",
    },
  },
  card: {
    width: "100%",
    marginTop: "65px",
  },
  status: {
    display: "flex",
    padding: "20px",
    width: "800px",
    // height: "60px",
  },
  maindiv: {
    display: "flex",
    padding: "15px",
  },
  approved: {
    display: "flex",
    marginRight: "400px",
    marginTop: "10px",
    color: "#21BA45",
  },
  reviewd: {
    margin: "-30px 0px 10px 0px",
    [theme.breakpoints.up("sm")]: {
      margin: "0px",
    },
  },
  avatar: {
    width: "90px",
    height: "90px",
    [theme.breakpoints.up("sm")]: {
      // margin: "0px",
      width: "50px",
      height: "50px",
    },
  },
  name: {
    display: "flex",
    fontSize: "200px",
    [theme.breakpoints.up("sm")]: {
      color: "#081E38", // margin: "0px",
      // width: "50px",
      marginTop: "10px",
      // height: "50px",
    },
  },
  email: {
    position: "relative",
    top: "50%",
    Right: "50%",
    zIndex: "1",
    [theme.breakpoints.up("md")]: {
      position: 0,
      top: 0,
      margin: "10px 0px 0px 30px",
      color: "#8C8C8C",
    },
  },
  customBadgeApproved: {
    backgroundColor: "#21BA45",
  },
  chip: {
    margin: theme.spacing(0.5),
    borderRadius: "4px",
    color: "#2185D0",
    backgroundColor: "#F0F0F0",
    fontSize: "12px",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalsize: {
    backgroundColor: theme.palette.background.paper,
    width: "25%",
    height: "35%",
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
  },
  large: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    marginRight: "2%",
  },
  cardstyle: {
    paddingTop: "14%",
    [theme.breakpoints.up("sm")]: {
      padding: "2% 1%",
    },
    // [theme.breakpoints.only("sm")]: {
    //   MarginBottom: "10%",
    // },
  },
}));

const getApplicationTypeColor = (appType) => {
  switch (appType) {
    case "Only API":
      return "#FBBD08";
    case "Both GUI and API":
      return "#1E561F";
    case "Only GUI":
      return "#2185D0";
    default:
  }
};

function ApplicationDesc(props) {
  const { data, defaultTags, category, editDisable } = props;

  console.log(data, 123);
  const classes = useStyles();
  const [hideEdit, showEdit] = useState(true);
  const [basicInfo, setBasicInfo] = useState({});
  const [microRadio, setMicroRadio] = useState(true);
  const [appName, setAppname] = useState("");
  const [app_category, setAppCategory] = useState("");

  // let check_app_status =
  //   data.applicationDetail.status !== "Published" ? true : false;
  useEffect(() => {
    const {
      applicationIconBase64,
      tags,
      category,
      name,
      // singleSignOn,
      description,
      applicationType,
      shortKey,
      isMicroFrontend,
      trialPeriod,
      launchType,
      enableTrialAccess,
      email,
    } = data;
    console.log("savdhgfdsfdjfjtds", isMicroFrontend);
    setBasicInfo({
      name: name,
      applicationIconBase64: applicationIconBase64,
      description: description,
      category: data.category ? data.category._id : 0,
      // singleSignOn: singleSignOn,
      applicationType: applicationType,
      tags: tags,
      shortKey: shortKey,
      isMicroFrontend: isMicroFrontend || false,
      trialPeriod: trialPeriod,
      launchType: launchType,
      enableTrialAccess: enableTrialAccess,
      email: email,
    });
    setAppname(name);
    setAppCategory(category);
    console.log("fasytsaf", applicationType);
    applicationType === "Only API" ? setMicroRadio(false) : setMicroRadio(true);
  }, [data]);

  const [openmodal, setOpenmodal] = React.useState(false);

  const handleOpenmodal = () => {
    setOpenmodal(true);
  };

  const handleClosemodal = () => {
    setOpenmodal(false);
  };

  const submitApplicationBasic = async (e) => {
    e.preventDefault();
    try {
      let payload = { ...basicInfo };
      console.log({ payload }, "this place where our sudheer got confused");
      if (appName == basicInfo.name) {
        let { name, ...payload } = basicInfo;
        await props.editBasicDetails(data._id, payload);
      } else {
        await props.editBasicDetails(data._id, basicInfo);
      }
    } catch (err) {}
    showEdit(false);
  };

  const getStatusColor = (status) => {
    if (status == "Published") {
      return (
        <div>
          <Typography variant="body1" style={{ color: "#2185D0" }}>
            <Badge
              color="secondary"
              variant="dot"
              style={{ marginRight: "10px" }}
            />
            Published
          </Typography>
        </div>
      );
    } else {
      return (
        <Typography style={{ color: "#FBBD08" }}>
          <Badge
            classes={{ badge: classes.customBadgeDraft }}
            variant="dot"
            style={{ marginRight: "10px" }}
          />
          Draft
        </Typography>
      );
    }
  };
  let shortKey = data.shortKey || " ";
  return (
    <Card
      id="application-desc"
      variant="outlined"
      style={{ backgroundColor: "transparent", border: "none" }}
      className={classes.card}
    >
      <CardContent>
        <ApplicationBasicEdit
          editDisable={editDisable}
          valuesFromBasicInfo={props.valuesFromBasicInfo}
          addCategory={props.addCategory}
          defaultCategory={category}
          defaultTags={defaultTags}
          setBasicInfo={setBasicInfo}
          basicInfo={basicInfo}
          setMicroRadio={setMicroRadio}
          microRadio={microRadio}
          showEdit={showEdit}
          submitApplicationBasic={submitApplicationBasic}
        />
      </CardContent>
    </Card>
  );
}

export default ApplicationDesc;
