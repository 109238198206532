export const FETCH_APPLICATION_DETAILS = "FETCH_APPLICATION_DETAILS";
export const FETCH_APPLICATION_DETAILS_FAILED =
  "FETCH_APPLICATION_DETAILS_FAILED";
export const FETCH_APPLICATION_DETAILS_SUCCESS =
  "FETCH_APPLICATION_DETAILS_SUCCESS";

export const EDIT_ENVIRONMENT = "EDIT_ENVIRONMENT";
export const EDITED_ENVIRONMENT = "EDITED_ENVIRONMENT";
export const EDIT_ENVIRONMENT_FAILED = "EDIT_ENVIRONMENT_FAILED";

export const ADD_ENVIRONMENT = "ADD_ENVIRONMENT";
export const ENVIRONMENT_ADDED = "ENVIRONMENT_ADDED";
export const ADD_ENVIRONMENT_FAILED = "ADD_ENVIRONMENT_FAILED";

export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const PUBLISHED_APPLICATION = "PUBLISHED_APPLICATION ";
export const PUBLISHING_APPLICATION = "PUBLISHING_APPLICATION";
export const PUBLISH_APPLICATION_FAILED = "PUBLISH_APPLICATION_FAILED";

export const FETCHING_APPLICATIONS_API = "FETCHING_APPLICATIONS_API";
export const FETCHED_APPLICATIONS_API = "FETCHED_APPLICATIONS_API";
export const FETCHE_APPLICATIONS_API_FAILED = "FETCHE_APPLICATIONS_API_FAILED";

export const SAVE_OUTGOING_API = "SAVE_OUTGOING_API";
export const SAVED_OUTGOING_API = "SAVED_OUTGOING_API";
export const SAVE_OUTGOING_API_FAILED = "SAVE_OUTGOING_API_FAILED";
export const UPDATE_ACTIONS = "UPDATE_ACTIONS";
export const UPDATE_APP_APIS = "UPDATE_APP_APIS";

export const ACTION_BEGIN_USAGE_DOC = "ACTION_BEGIN_USAGE_DOC";
export const ACTION_SUCCESS_USAGE_DOC = "ACTION_SUCCESS_USAGE_DOC";
export const ACTION_FAILED_USAGE_DOC = "ACTION_FAILED_USAGE_DOC";
export const RESET_DOC = "RESET_DOC";

export const FETCH_DOC = "FETCH_DOC";
export const FETCHED_DOC = "FETCHED_DOC";
export const FETCH_DOC_FAILED = "FETCH_DOC_FAILED";

export const FETCH_TEAMS_FOR_APP_DETAIL = "FETCH_TEAMS_FOR_APP_DETAIL";
export const FETCHED_TEAMS_FOR_APP_DETAIL = "FETCHED_TEAMS_FOR_APP_DETAIL";
export const FETCH_TEAMS_FOR_APP_DETAIL_FAIL =
  "FETCH_TEAMS_FOR_APP_DETAIL_FAIL";

export const ADD_TEAM_TO_APP_DETAIL = "ADD_TEAM_TO_APP_DETAIL";
export const ASSIGN_DISMISS_TEAM = "ASSIGN_DISMISS_TEAM";
export const RESET_TEAM_IN_APP_DETAIL = "RESET_TEAM_IN_APP_DETAIL";

export const FETCH_INTRO_DOC = "FETCH_INTRO_DOC";
export const FETCHED_INTRO_DOC = "FETCHED_INTRO_DOC";
export const FETCH_INTRO_DOC_FAIL = "FETCH_INTRO_DOC_FAIL";

export const RESET_PROPS = "RESET_PROPS";

export const UPDATE_TEAMS_FOR_APP_DETAIL = "UPDATE_TEAMS_FOR_APP_DETAIL";
export const SET_CLICKED_TEAM = "SET_CLICKED_TEAM";

export const CLEAR_ALL_APPLICATIONS_DETAILS = "CLEAR_ALL_APPLICATIONS_DETAILS";
export const UPDATE_APPLICATION_DETAILS_HIDDEN_COL="UPDATE_APPLICATION_DETAILS_HIDDEN_COL";
export const UPDATE_ONBOARD_HIDDEN_COL="UPDATE_ONBOARD_HIDDEN_COL";