/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

export default function CustomAutoComplete(props) {
  const { id, options, handleChange, value, style, disabled, label } = props;

  return (
    <Autocomplete
      id={id || "autocomplete"}
      options={options || []}
      value={options.filter((f) => f.value === value)[0] || null}
      disabled={disabled}
      getOptionLabel={(option) => option.label}
      fullWidth
      style={{ ...(style || { width: "100%" }) }}
      size="small"
      onChange={
        handleChange
          ? (e, val) => {
              console.log("autocomplete", e, val);
              handleChange(val?.value || "");
            }
          : (e, val) => {
              console.log("autocomplete", e, val);
            }
      }
      renderInput={(params) => (
        <TextField {...params} label={label || ""} variant="outlined" />
      )}
    />
  );
}
