import React from "react";
import {
  Grid,
  Button,
  FormControl,
  Select,
  MenuItem,
  Typography,
  Card,
  TextField,
} from "@material-ui/core";
import Styles from "../Styles";
import { withStyles } from "@material-ui/styles";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import HelpdeskFAB from "../../HelpdeskFAB/HelpdeskFab";

const contentMediaTypeValues = [
  "audio/aac",
  "application/x-abiword",
  "application/x-freearc",
  "video/x-msvideo",
  "application/vnd.amazon.ebook",
  "application/octet-stream",
  "image/bmp",
  "application/x-bzip",
  "application/x-bzip2",
  "application/x-cdf",
  "application/x-csh",
  "text/css",
  "text/csv",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-fontobject",
  "application/epub+zip",
  "application/gzip",
  "image/gif",
  "text/html",
  "image/vnd.microsoft.icon",
  "text/calendar",
  "application/java-archive",
  "image/jpeg",
  "application/json",
  "application/ld+json",
  "audio/midi audio/x-midi",
  "text/javascript",
  "audio/mpeg",
  "video/mp4",
  "video/mpeg",
  "application/vnd.apple.installer+xml",
  "application/vnd.oasis.opendocument.presentation",
  "application/vnd.oasis.opendocument.spreadsheet",
  "application/vnd.oasis.opendocument.text",
  "audio/ogg",
  "video/ogg",
  "application/ogg",
  "audio/opus",
  "font/otf",
  "image/png",
  "application/pdf",
  "application/x-httpd-php",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.rar",
];

const FieldRules = (props) => {
  const {
    classes,
    strval,
    intval,
    numval,
    data,
    isEdit,
    isDisabled,
    invalidData,
  } = props;

  return (
    <>
      <div>
        <Grid container spacing={3} style={{ marginTop: 0 }}>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Typography className="mb-1" variant="body2">
                  Field Rules <span>(optional)</span>
                </Typography>
              </Grid>
              <Grid item xs={6} className={classes.gridclrbtn}>
                {/* <Button
                  contained="outlined"
                  className={classes.clrbtn}
                  onClick={(e) => props.onClear(e)}
                >
                  Clear all
                </Button> */}
                {!isDisabled && (
                  <Typography
                    onClick={(e) => props.onClear(e)}
                    className={classes.clear}
                    variant="button"
                  >
                    Clear all
                  </Typography>
                )}
              </Grid>
            </Grid>
            <div>
              <Card variant="outlined" className="p-4">
                {data.type === "string" && (
                  <Grid container spacing={3}>
                    <Grid item xs={3} sm={3}>
                      <Typography className="mb-1" variant="body2">
                        Min Length
                      </Typography>

                      <TextField
                        disabled={isDisabled}
                        autoComplete="off"
                        size="small"
                        variant="outlined"
                        fullWidth
                        name="name"
                        placeholder="Enter min"
                        value={strval.minLength || ""}
                        onChange={(e) =>
                          props.onChangeString({
                            minLength: parseInt(e.target.value) || 0,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      <Typography className="mb-1" variant="body2">
                        Max Length
                      </Typography>
                      <TextField
                        disabled={isDisabled}
                        autoComplete="off"
                        size="small"
                        variant="outlined"
                        fullWidth
                        name="name"
                        placeholder="Enter max"
                        value={strval.maxLength || ""}
                        onChange={(e) =>
                          props.onChangeString({
                            maxLength: parseInt(e.target.value) || 0,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <Typography className="mb-1" variant="body2">
                        Pattern
                      </Typography>
                      <TextValidator
                        disabled={isDisabled}
                        style={{ width: "100%" }}
                        fullwidth
                        name="name"
                        variant="outlined"
                        placeholder="Enter pattern"
                        value={strval.pattern || ""}
                        onChange={(e) =>
                          props.onChangeString({ pattern: e.target.value })
                        }
                        autoComplete="off"
                        size="small"
                        // error={error}
                        validators={[
                          "minStringLength:0",
                          "maxStringLength:300",
                        ]}
                        errorMessages={[
                          "Invalid input",
                          "Not exceed more than 300 characters",
                        ]}
                        // helperText={err_msg}
                      />
                      {/* <TextField
                        disabled={isDisabled}
                        autoComplete="off"
                        size="small"
                        variant="outlined"
                        fullWidth
                        name="name"
                        placeholder="Enter pattern"
                        value={strval.pattern || ""}
                        onChange={(e) =>
                          props.onChangeString({ pattern: e.target.value })
                        }
                        error={invalidData}
                        helperText={
                          invalidData
                            ? "Pattern length must be greater than or equal to 3"
                            : ""
                        }
                      /> */}
                    </Grid>
                  </Grid>
                )}
                {data.type === "integer" && (
                  <>
                    <Grid container spacing={3}>
                      <Grid item xs={3} sm={3}>
                        <Typography className="mb-1" variant="body2">
                          Minimum
                        </Typography>
                        <TextField
                          disabled={isDisabled}
                          autoComplete="off"
                          size="small"
                          variant="outlined"
                          fullWidth
                          name="name"
                          placeholder="Enter min"
                          value={intval.minimum || ""}
                          onChange={(e) =>
                            props.onChangeInteger({
                              minimum: parseInt(e.target.value) || 0,
                            })
                          }
                        />
                      </Grid>
                      <Grid item xs={3} sm={3}>
                        <Typography className="mb-1" variant="body2">
                          Maximum
                        </Typography>
                        <TextField
                          disabled={isDisabled}
                          autoComplete="off"
                          size="small"
                          variant="outlined"
                          fullWidth
                          name="name"
                          placeholder="Enter min"
                          value={intval.maximum || ""}
                          onChange={(e) =>
                            props.onChangeInteger({
                              maximum: parseInt(e.target.value) || 0,
                            })
                          }
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <Typography className="mb-1" variant="body2">
                          Multiple Of
                        </Typography>
                        <TextField
                          disabled={isDisabled}
                          autoComplete="off"
                          size="small"
                          variant="outlined"
                          fullWidth
                          name="name"
                          placeholder="Enter multiple of"
                          value={intval.multipleOf || ""}
                          onChange={(e) =>
                            props.onChangeInteger({
                              multipleOf: parseInt(e.target.value) || 0,
                            })
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <Typography className="mb-1" variant="body2">
                          Exclusive Minimum
                        </Typography>
                        <FormControl
                          disabled={isDisabled}
                          fullWidth
                          size="small"
                          variant="outlined"
                          value={intval.exclusiveMinimum || ""}
                          name="authMethod"
                        >
                          <Select
                            name="authMethod"
                            native
                            value={intval.exclusiveMinimum}
                            onChange={(e) => {
                              props.onChangeInteger({
                                exclusiveMinimum:
                                  e.target.value === "true" ? true : false,
                              });
                            }}
                          >
                            <option value="">-Select-</option>
                            <option value={true}>True</option>
                            <option value={false}>False</option>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography className="mb-1" variant="body2">
                          Exclusive Maximum
                        </Typography>
                        <FormControl
                          disabled={isDisabled}
                          fullWidth
                          size="small"
                          variant="outlined"
                          value={intval.exclusiveMaximum}
                          name="authMethod"
                        >
                          <Select
                            name="authMethod"
                            native
                            value={intval.exclusiveMaximum}
                            onChange={(e) =>
                              props.onChangeInteger({
                                exclusiveMaximum:
                                  e.target.value === "true" ? true : false,
                              })
                            }
                          >
                            <option value="">-Select-</option>
                            <option value={true}>True</option>
                            <option value={false}>False</option>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </>
                )}
                {data.type === "number" && (
                  <>
                    <Grid container spacing={3}>
                      <Grid item xs={3} sm={3}>
                        <Typography className="mb-1" variant="body2">
                          Minimum
                        </Typography>
                        <TextField
                          disabled={isDisabled}
                          autoComplete="off"
                          size="small"
                          variant="outlined"
                          fullWidth
                          name="name"
                          placeholder="Enter min"
                          value={numval.minimum || ""}
                          onChange={(e) =>
                            props.onChangeNumber({
                              minimum: parseInt(e.target.value) || 0,
                            })
                          }
                        />
                      </Grid>
                      <Grid item xs={3} sm={3}>
                        <Typography className="mb-1" variant="body2">
                          Maximum
                        </Typography>
                        <TextField
                          disabled={isDisabled}
                          autoComplete="off"
                          size="small"
                          variant="outlined"
                          fullWidth
                          name="name"
                          placeholder="Enter min"
                          value={numval.maximum || ""}
                          onChange={(e) =>
                            props.onChangeNumber({
                              maximum: parseInt(e.target.value) || 0,
                            })
                          }
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <Typography className="mb-1" variant="body2">
                          Multiple Of
                        </Typography>
                        <TextField
                          disabled={isDisabled}
                          autoComplete="off"
                          size="small"
                          variant="outlined"
                          fullWidth
                          name="name"
                          placeholder="Enter multiple of"
                          value={numval.multipleOf || ""}
                          onChange={(e) =>
                            props.onChangeNumber({
                              multipleOf: parseInt(e.target.value) || 0,
                            })
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <Typography className="mb-1" variant="body2">
                          Exclusive Minimum
                        </Typography>
                        <FormControl
                          disabled={isDisabled}
                          fullWidth
                          size="small"
                          variant="outlined"
                          value={numval.exclusiveMinimum || ""}
                          name="authMethod"
                        >
                          <Select
                            name="authMethod"
                            native
                            value={numval.exclusiveMinimum}
                            onChange={(e) => {
                              props.onChangeNumber({
                                exclusiveMinimum:
                                  e.target.value === "true" ? true : false,
                              });
                            }}
                          >
                            <option value="">-Select-</option>
                            <option value={true}>True</option>
                            <option value={false}>False</option>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography className="mb-1" variant="body2">
                          Exclusive Maximum
                        </Typography>
                        <FormControl
                          disabled={isDisabled}
                          fullWidth
                          size="small"
                          variant="outlined"
                          value={numval.exclusiveMaximum}
                          name="authMethod"
                        >
                          <Select
                            name="authMethod"
                            native
                            value={numval.exclusiveMaximum}
                            onChange={(e) =>
                              props.onChangeNumber({
                                exclusiveMaximum:
                                  e.target.value === "true" ? true : false,
                              })
                            }
                          >
                            <option value="">-Select-</option>
                            <option value={true}>True</option>
                            <option value={false}>False</option>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </>
                )}
                {data.type === "string" && (
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <Typography variant="body2" className="mb-1">
                        {" "}
                        Content Encoding{" "}
                      </Typography>
                      <FormControl
                        fullWidth
                        disabled={isDisabled}
                        // disabled={!edit}
                        size="small"
                        variant="outlined"
                        value={strval.contentEncoding || ""}
                        name="authMethod"
                      >
                        <Select
                          name="authMethod"
                          native
                          value={strval.contentEncoding || ""}
                          onChange={(e) =>
                            props.onChangeString({
                              contentEncoding: e.target.value,
                            })
                          }
                        >
                          <option value="">-Select-</option>
                          <option value="binary">binary</option>
                          <option value="base64">base64</option>
                          <option value="quoted-printable">
                            quoted-printable
                          </option>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" className="mb-1">
                        {" "}
                        Content MediaType{" "}
                      </Typography>
                      <FormControl
                        disabled={isDisabled}
                        fullWidth
                        size="small"
                        variant="outlined"
                        value={strval.contentMediaType || ""}
                        name="authMethod"
                      >
                        <Select
                          name="authMethod"
                          value={strval.contentMediaType || ""}
                          onChange={(e) =>
                            props.onChangeString({
                              contentMediaType: e.target.value,
                            })
                          }
                        >
                          {contentMediaTypeValues.map((item, index) => (
                            <MenuItem key={index} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                )}
                {data.type === "boolean" || data.type === "" ? (
                  <Card style={{ boxShadow: "none", height: "139px" }}>
                    <div>nill</div>
                  </Card>
                ) : null}
              </Card>
            </div>
          </Grid>
          <HelpdeskFAB />
        </Grid>
      </div>
    </>
  );
};
export default withStyles(Styles)(FieldRules);
