import React, { useState } from "react";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import {
  Backdrop,
  CircularProgress,
  Button,
  Container,
  Grid,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  FormLabel,
} from "@material-ui/core";
import { useHistory, useLocation } from "react-router";
import { useSnackbar } from "notistack";
import CustomButton from "../../components/common/Button/CustomButton";
import loginAPi from "../../api/LoginApi/index";
import useStyles from "./commonStyles";
import { useEffect } from "react";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { Text } from "devextreme-react/linear-gauge";

export default function SignupScreen(props) {
  const [dataObj, setDataObj] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    userID: "",
  });
  const [userInfo, setUserInfo] = useState({});
  const [errorObj, setErrorObj] = useState({
    email: false,
    password: false,
    confirmPassword: false,
  });
  const [loading, setLoading] = useState(false);
  const styles = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const handleMouseDownConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const { screenType } = props;

  useEffect(() => {
    const path = window.location.pathname;
    let base64 = path.split("/login/register/")[1];
    if (base64) {
      let userInfoStr = new Buffer.from(base64, "base64");
      let userInfoObj = JSON.parse(userInfoStr.toString());
      console.log("userinfoobj", userInfoObj);
      setUserInfo(userInfoObj);
      setDataObj((prevState) => ({
        ...prevState,
        username: userInfoObj.name,
        email: userInfoObj.email,
        userID: userInfoObj._id,
        organization: userInfoObj.organization,
      }));
    }
    const { state } = props.location;
    if (state && state.type === "reset-pass") {
      setDataObj((prev) => ({
        ...prev,
        email: state.email,
      }));
    }
  }, []);
  console.log(dataObj, "sign up");
  // useEffect(() => {
  //   if (location.state) {
  //     const { email } = location.state;
  //     console.log("from signup", email);
  //     setDataObj((prevState) => ({
  //       ...prevState,
  //       email: email,
  //     }));
  //   }
  // }, []);

  const handleSignupClick = async () => {
    var errorFlag = false;
    var tempError = {};
    for (var obj in dataObj) {
      if (dataObj[obj] === "") {
        errorFlag = true;
        tempError = { ...tempError, [obj]: true };
      }
    }
    if (dataObj.password !== dataObj.confirmPassword) {
      errorFlag = true;
      setErrorObj((prevState) => ({ ...prevState, confirmPassword: true }));
    }
    setErrorObj((prevState) => ({
      ...prevState,
      ...tempError,
    }));
    if (!errorFlag) {
      setLoading(true);
      const req_data = {
        password: dataObj.password,
      };
      console.log(req_data, "req passowrd");
      const { _msg, _status } = await loginAPi.signUp(req_data, dataObj.userID);
      if (_status === 200) {
        setLoading(false);
        enqueueSnackbar(_msg, { variant: "success" });
        history.push("/");
      } else {
        setLoading(false);
        enqueueSnackbar(
          _msg
            ? _msg
            : "Unable to process your request, please try after sometime",
          { variant: "error" }
        );
      }
    }
  };

  const handleResetPassword = async () => {
    var errorFlag = false;
    var tempError = {};
    // for (var obj in dataObj) {
    //   if (dataObj[obj] === "") {
    //     errorFlag = true;
    //     tempError = { ...tempError, [obj]: true };
    //   }
    // }
    if (dataObj.password !== dataObj.confirmPassword) {
      errorFlag = true;
      setErrorObj((prevState) => ({ ...prevState, confirmPassword: true }));
    }
    setErrorObj((prevState) => ({
      ...prevState,
      ...tempError,
    }));

    if (!errorFlag) {
      setLoading(true);
      const req_data = {
        email: dataObj.email,
        code: dataObj.otp,
        newpassword: dataObj.password,
      };
      console.log("ashiwn", req_data);
      const { _msg, _status, data } = await loginAPi.verifyCodeForgetPassword(
        req_data
      );
      if (_status === 200) {
        setLoading(false);
        enqueueSnackbar(_msg, { variant: "success" });
        history.push("/");
      } else {
        setLoading(false);
        enqueueSnackbar(_msg, { variant: "error" });
      }
    }
  };

  const handleTextChange = (key, value) => {
    setErrorObj((prevState) => ({
      ...prevState,
      [key]: false,
    }));
    setDataObj((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  return (
    <div className={styles.containerReset}>
      <Backdrop
        className={styles.backdrop}
        open={loading}
        onClick={() => setLoading(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {screenType === "reset" && (
        <div className={styles.line1}>
          <Button size="small" onClick={() => history.push("/")}>
            <ArrowBackIosIcon
              onClick={() => history.push("/")}
              fontSize="small"
            />
            <text className={styles.label}>Back to Login</text>
          </Button>
        </div>
      )}
      {screenType === "signup" && (
        <div className={styles.welcome_message}>
          <text className={styles.big_text}>Hey {userInfo?.name || ".."},</text>
          <text className={styles.big_text}>
            Welcome to {userInfo.organization}
          </text>
        </div>
      )}
      {screenType === "reset" && (
        <div className={styles.welcome_message} style={{ marginTop: "1rem" }}>
          <text className={styles.big_text}>Reset your password</text>
        </div>
      )}
      <div className={styles.email}>
        <label className={styles.label}>
          Email ID<text className={styles.star}> *</text>
        </label>
        <input
          className={styles.email_text}
          style={errorObj.email ? { borderColor: "red" } : {}}
          value={dataObj.email}
          onChange={(e) => handleTextChange("email", e.target.value)}
          disabled={true}
        ></input>
        {errorObj.email && (
          <text className={styles.error_text}>Invalid Email</text>
        )}
      </div>
      {screenType === "reset" && (
        <div className={styles.email} style={{ marginTop: 20 }}>
          <label className={styles.label}>
            Enter code <text className={styles.star}> *</text>
          </label>
          <input
            autoComplete="off"
            className={styles.password_text}
            style={errorObj.otp ? { borderColor: "red" } : {}}
            value={dataObj.otp}
            onChange={(e) => handleTextChange("otp", e.target.value)}
          ></input>
          {errorObj.otp && (
            <text className={styles.error_text}>Invalid OTP</text>
          )}
        </div>
      )}
      <text
        className={styles.big_text}
        style={{ marginTop: 35, alignSelf: "center", marginRight: "20rem" }}
      >
        Set your password
      </text>
      <div className={styles.password}>
        <label className={styles.label}>
          New Password <text className={styles.star}> *</text>
        </label>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          autoComplete="new-password"
          className={styles.set_password_text}
          style={errorObj.password ? { borderColor: "red" } : {}}
          value={dataObj.password}
          onChange={(e) => handleTextChange("password", e.target.value)}
          type={showPassword ? "text" : "password"}
          // id="password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  style={{ width: "40px", height: "40px" }}
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? (
                    <VisibilityOutlinedIcon />
                  ) : (
                    <VisibilityOffOutlinedIcon />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {errorObj.password && (
          <text className={styles.error_text}>Invalid Password</text>
        )}
      </div>
      {
        <div className={styles.password}>
          <label className={styles.label}>
            Confirm Password <text className={styles.star}> *</text>
          </label>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            autoComplete="new-password"
            className={styles.set_password_text}
            style={errorObj.confirmPassword ? { borderColor: "red" } : {}}
            value={dataObj.confirmPassword}
            onChange={(e) =>
              handleTextChange("confirmPassword", e.target.value)
            }
            type={showConfirmPassword ? "text" : "password"}
            // id="password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    style={{ width: "40px", height: "40px" }}
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirmPassword}
                    onMouseDown={handleMouseDownConfirmPassword}
                  >
                    {showConfirmPassword ? (
                      <VisibilityOutlinedIcon />
                    ) : (
                      <VisibilityOffOutlinedIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {errorObj.confirmPassword && (
            <text className={styles.error_text}>
              Password didn't match. Please, try again
            </text>
          )}
        </div>
      }
      {screenType === "signup" && (
        <div style={{ marginTop: 37, alignSelf: "center" }}>
          <CustomButton type="long" label="Next" onClick={handleSignupClick} />
        </div>
      )}
      {screenType === "reset" && (
        <div style={{ marginTop: 37, alignSelf: "center" }}>
          <CustomButton
            type="long"
            label="Reset password"
            onClick={handleResetPassword}
          />
        </div>
      )}
    </div>
  );
}
