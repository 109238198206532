import { React, useState, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import Divider from "@material-ui/core/Divider";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import withSection from "components/Section/Section";

import {
  IconButton,
  CardContent,
  FormGroup,
  Card,
  Grid,
  Button,
  TextField,
  Typography,
  FormLabel,
  CardHeader,
  Tooltip,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 500,
  },
  line: {
    marginTop: -23,
  },
  dividerline: {
    width: "70%",
    margin: "-25px 0px 0px 125px",
    [theme.breakpoints.up("md")]: {
      width: "82%",
      margin: "-25px 0px 0px 260px",
    },
  },
  btn: {
    marginTop: "-10px",
    backgroundColor: "white",
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    // backgroundColor: "#999682",
    backgroundColor: theme.palette.primary.main,

    color: theme.palette.common.white,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({}))(TableRow);

const CustomTableRow = (props) => {
  const {
    data,
    editModule,
    addModule,
    removeRows,
    index,
    onChange,
    deleteModule,
  } = props;
  const classes = useStyles();
  const [edit, setEdit] = useState(data._id == undefined || false);

  return (
    <>
      <StyledTableCell component="th" scope="row" align="left">
        <TextField
          style={{ marginTop: "2px" }}
          disabled={edit ? false : true}
          className={classes.btn}
          size="small"
          variant="outlined"
          onChange={(e) => onChange(e, index)}
          fullWidth
          id="name"
          value={data.name}
          name="name"
        />
      </StyledTableCell>
      <StyledTableCell align="left">
        <TextField
          style={{ marginTop: "2px" }}
          disabled={edit ? false : true}
          className={classes.btn}
          size="small"
          variant="outlined"
          fullWidth
          id="name"
          value={data.description}
          name="description"
          onChange={(e) => onChange(e, index)}
        />
      </StyledTableCell>
      <StyledTableCell align="center">
        {edit && (
          <div>
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              style={{
                marginLeft: 0,
                backgroundColor: "transparent",
                boxShadow: "none",
                color: "#9e9e9e",
              }}
              onClick={async () => {
                if (data._id == undefined) {
                  await addModule(data.appId, data);
                } else {
                  await editModule(data.appId, data._id, data);
                }
                setEdit(false);
              }}
            >
              Save
            </Button>{" "}
            <Button
              style={{
                marginLeft: 0,
                backgroundColor: "transparent",
                boxShadow: "none",
                color: "#9e9e9e",
              }}
              variant="contained"
              color="primary"
              onClick={() => {
                if (data._id == undefined) {
                  removeRows(data, index);
                  return;
                }
                setEdit(false);
              }}
            >
              Cancel
            </Button>
          </div>
        )}
        {!edit && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
            }}F
          >
            <div style={{ width: "4%" }}>
              <Tooltip title="Edit" aria-label="edit">
                <EditOutlinedIcon
                  onClick={() => {
                    setEdit(true);
                  }}
                />
              </Tooltip>
            </div>
            <div style={{ width: "4%" }}>
              <Tooltip title="Delete" aria-label="delete">
                <DeleteOutlineIcon
                  onClick={async () => await deleteModule(data.appId, data._id)}
                />
              </Tooltip>
            </div>
            <div style={{ width: "4%" }}>{props.addNewRow}</div>
          </div>
        )}
      </StyledTableCell>
    </>
  );
};

const initialRows = [{ key: 1, moduleName: "", moduleDescription: "" }];

function ApplicationModules(props) {
  const { appId, data, editModule, addModule, deleteModule } = props;
  const classes = useStyles();

  console.log({ data });
  const [rows, setRows] = useState(data);

  const addRows = () => {
    const item = { appId, name: "", description: "" };
    setRows([...rows, item]);
  };

  useEffect(() => {
    setRows(data);
    console.log(rows);
  }, [data]);

  const removeRows = (payload, index) => {
    console.log({ payload });
    if (rows.length != 1) {
      var filtered = rows.filter(function (el, i) {
        console.log(index, i, index !== i);
        return index !== i;
      });
      console.log({ filtered });
      setRows([...filtered]);
    }
  };
  const onChange = (event, index) => {
    let payload = rows.filter(function (el, i) {
      console.log(index, i, index !== i);
      return index == i;
    })[0];
    let newpayload = { ...payload };
    newpayload[event.currentTarget.name] = event.currentTarget.value;
    console.log(newpayload);
    let data = [...rows];
    data[index] = newpayload;
    setRows([...data]);
  };

  return (
    <div>
      <div className="w-100 d-flex justify-content-end">
        {data.length == 0 && (
          <Tooltip title="Add" aria-label="add">
            <Button
              startIcon={<AddCircleOutlineOutlinedIcon />}
              color="secondary"
              onClick={addRows}
            >
              Add Module
            </Button>
          </Tooltip>
        )}
      </div>
      <TableContainer style={{ borderRadius: "0" }}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">
                <Typography>Module name</Typography>{" "}
              </StyledTableCell>
              <StyledTableCell align="left">Description</StyledTableCell>
              <StyledTableCell align="center" style={{ width: "15%" }}>
                Actions
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <StyledTableRow style={{ alignItems: "center" }}>
                <CustomTableRow
                  data={row}
                  onChange={onChange}
                  index={index}
                  removeRows={removeRows}
                  editModule={editModule}
                  addModule={addModule}
                  deleteModule={deleteModule}
                  addNewRow={
                    index == rows.length - 1 && (
                      <Tooltip title="Add" aria-label="add">
                        <AddCircleOutlineOutlinedIcon
                          onClick={addRows}
                          color="secondary"
                        />
                      </Tooltip>
                    )
                  }
                />
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default withSection(ApplicationModules, "Modules");
