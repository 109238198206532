import React from "react";
import { CustomTextField } from "components/Customized";
import FilterComponent from "components/FilterComponent/FilterComponent";
import { Grid, InputAdornment } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
const FilterForm = (props) => {
  const { classes, filterProps } = props;
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={2}
      >
        <Grid item md={8}>
          <CustomTextField
            autoComplete="off"
            fullWidth
            variant="outlined"
            size="small"
            placeholder="Search"
            value={props.searchkey}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {" "}
                  <SearchIcon />{" "}
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              props.onChangeSearchAppName(e);
            }}
          />
        </Grid>
        <Grid item md={4}>
          <FilterComponent
            getDataByFilter={props.getDataByFilter}
            dataOptions={props.data_options}
            filterFunc={props.filterFunc}
            filterHistory={filterProps.filterArray}
          />
        </Grid>
      </Grid>
    </>
  );
};
export default FilterForm;
