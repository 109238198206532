import getURL from "../config";
import API_helpers from "../index";

async function fetchAllHelpDeskData(page, size, filters, sort) {
  let url = getURL("FETCH_HELP_DATA");
  url = url + "?";
  let params = { page, size, filters, sort };
  Object.keys(params)
    .filter((k) => params[k] != undefined || params[k] != null)
    .forEach((k) => (url = `${url}${k}=${params[k]}&`));
  url = url.substr(0, url.length - 1);
  console.log(url, "urll");
  try {
    let res = await API_helpers.GET(url, {
      headers: {
        "Content-Type": "application/json",
        userdetails: JSON.stringify({ name: "palani" }),
        organization: "11111",
      },
    });
    return res.data;
  } catch (err) {
    throw err;
  }
}

async function addHelpDeskData(payload) {
  console.log(payload, 34565434543);
  let url = getURL("ADD_HELP_DATA");
  try {
    let res = await API_helpers.POST(url, payload, {
      headers: {
        "Content-Type": "multipart/form-data;boundary=something",
        userdetails: JSON.stringify({ name: "palani" }),
        organization: "11111",
      },
    });
    return res.data;
  } catch (err) {
    throw err;
  }
}

async function deleteHelpDeskData(id) {
  let url = getURL("DELETE_HELP_DESK_DATA")(id);
  try {
    let res = await API_helpers.DELETE(url, {
      headers: {
        "Content-Type": "application/json",
        organization: "11111",
        userdetails: JSON.stringify({ name: "palani" }),
      },
    });
    return res;
  } catch (err) {
    throw err;
  }
}

async function editHelpDeskData(id, payload) {
  // let url = getURL("EDIT_HELP_DESK_DATA")(id);
  // try {
  //   let res = await API_helpers.PUT(url, payload, {
  //     headers: {
  //       // "Content-Type": "multipart/form-data;boundary=something",
  //       "Content-Type": "application/json",
  //       userdetails: JSON.stringify({ name: "palani" }),
  //       organization: "11111",
  //     },
  //   });
  //   return res.data;
  // } catch (err) {
  //   throw err;
  // }
}

async function sendImages_formData(id, payload) {
  let url = getURL("EDIT_HELP_DESK_IMAGE")(id);
  try {
    let res = await API_helpers.PUT(url, payload, {
      headers: {
        "Content-Type": "multipart/form-data;boundary=something",
        userdetails: JSON.stringify({ name: "palani" }),
        organization: "11111",
      },
    });
    return res;
  } catch (err) {
    throw err;
  }
}
const update_issues = async (id, payload) => {
  let url = getURL("UPDATE_HELP_DESK_ISSUES")(id);
  try {
    let res = await API_helpers.PUT(url, payload, {
      headers: {
        "Content-Type": "application/json",
        userdetails: JSON.stringify({ name: "palani" }),
        organization: "11111",
      },
    });
    return res;
  } catch (err) {
    throw err;
  }
};

const fetch_helpdesk_image = async (id, name) => {
  let url = getURL("FETCH_HELP_DESK_IMAGE")(id, name);
  try {
    let res = await API_helpers.GET(url, {
      headers: {
        "Content-Type": "application/json",
        userdetails: JSON.stringify({ name: "palani" }),
        organization: "11111",
      },
    });
    return res;
  } catch (err) {
    throw err;
  }
};

const fetchStatus = async () => {
  let url = getURL("FETCH_STATUS");
  try {
    let res = await API_helpers.GET(url, {
      headers: {
        "Content-Type": "application/json",
        userdetails: JSON.stringify({ name: "palani" }),
        organization: "11111",
      },
    });
    return res.data;
  } catch (err) {
    throw err;
  }
};

async function deleteImage(helpdeskId, imageToDelete) {
  let url = getURL("DELETE_HELP_DESK_IMAGE")(helpdeskId, imageToDelete);
  try {
    let res = await API_helpers.DELETE(url, {
      headers: {
        "Content-Type": "application/json",
        organization: "11111",
        userdetails: JSON.stringify({ name: "palani" }),
      },
    });
    return res;
  } catch (err) {
    throw err;
  }
}

async function helpDeskComment(_id, payload) {
  let url = getURL("HELPDESK_COMMENT")(_id);
  try {
    let res = null;
    if (payload) {
      res = await API_helpers.POST(url, payload);
    } else {
      res = await API_helpers.GET(url);
    }

    return res;
  } catch (err) {
    throw err;
  }
}

async function getUsersDetails(payload) {
  let url = getURL("USERS_DETAILS");
  try {
    let res = await API_helpers.GET(url, payload);
    return res;
  } catch (err) {
    throw err;
  }
}

var APIs = {
  fetchAllHelpDeskData,
  addHelpDeskData,
  deleteHelpDeskData,
  editHelpDeskData,
  sendImages_formData,
  update_issues,
  fetch_helpdesk_image,
  fetchStatus,
  deleteImage,
  helpDeskComment,
  getUsersDetails,
};

export default APIs;
