import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actionTypes from "../redux/actionTypes";
import PropTypes from "prop-types";
import AppBasic from "./AppBasic";
import Environment from "../Components/Environment";
import BillingConfig from "../Components/BillingConfig";
import AuthorizationObjects from "../Components/AuthorizationObjects";
import ApplicationAPI from "../Components/ApplicationAPI";
import Document from "../Components/Document";
import ApplicationModules from "../Components/ApplicationModules";
import ApplicationWebhooks from "../Components/ApplicationWebhooks";
import { checkPermission } from "../../../helpers/permissons";
import axios from "axios";
import {
  IconButton,
  CardContent,
  Container,
  Card,
  Grid,
  Button,
  TextField,
  Typography,
  FormLabel,
} from "@material-ui/core";
import SnackbarComponent from "../Components/SnackBar";
import EditIcon from "@material-ui/icons/Edit";
import SendIcon from "@material-ui/icons/Send";
import {
  onboardApplication,
  clearMessage,
  updateBillingService,
  updateWebhooks,
  updateModule,
  updateAuthObject,
  updateAPI,
  updateEnvironmentForm,
  addAppBasicInfo,
  addAuthWebhooks,
  addBillingWebhooks,
  cancelApplication,
  fetchTeams,
  handleSelectTeam,
  set_ClickedTeam,
} from "../redux/action";
import { DataUsageOutlined } from "@material-ui/icons";
import ApplicationComponent from "./ApplicationComponents";
import getURL from "../../../api/config";
import API_helpers from "../../../api/index";
import Loader from "components/common/stuff/Loader";
import API from "../../../api/ApplicationAPIs";

class ApplicationOnBoard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addedCategory: "",
      open: false,
      payload: {
        _id: 0,
        environment: [
          {
            name: "",
            domainUrl: "",
            isProductionEnvironment: false,
            isHealthCheckEnable: false,
            healthCheckUrl: "",
            method: "POST",
          },
        ],
      },
      billableServices: [],
      applicationTags: [],
      environmentPayload: {},
      category: [],
    };
    this.envRef = React.createRef();
  }

  getApplicationsTags = async () => {
    try {
      // let res = await axios.get(getURL("TAGS"));
      const res = await API.getTags();
      this.setState({ applicationTags: res.data.data || [] });
    } catch (err) {}
  };

  getBillingService = async () => {
    try {
      // let res = await axios.get(getURL("BILLABLE_SERVICES"));
      const res = await API.getBillableService();
      this.setState({ billableServices: res.data.data || [] });
    } catch (err) {}
  };

  getCategories = async () => {
    try {
      // let res = await axios.get(getURL("CATEGORIES"));
      const res = await API.get_Categories();
      this.setState({ category: res.data.data || [] });
    } catch (err) {}
  };

  componentDidMount() {
    let { state } = this.props.location;
    console.log("refreshed", state);
    this.getBillingService();
    this.getApplicationsTags();
    this.getCategories();
    if (state === undefined || !state.status) {
      console.log("storage refreshed");
      this.props.cancelApp();
      this.props.getTeamsData({});
    }
  }

  componentWillReceiveProps = (props, state) => {
    if (props.actions === actionTypes.ONBOARDED_APPLICATION) {
      setTimeout(() => {
        console.log("hsahkjgdhsafghsafghfsag", 2000, props);
        checkPermission("btn214") &&
          props.history.push("/app/detail", { appId: props.app_id });
      }, 2000);
      console.log("hsahkjgdhsafghsafghfsag", 1, props);
      props.cancelApp();
    } else if (props.actions === actionTypes.ONBOARDED_APPLICATION_FAILED) {
    } else {
      //do nothing
    }
  };

  addCategory = async (value) => {
    try {
      let res = await API_helpers.POST(getURL("ADD_CATEGORY"), { name: value });
      let categoryId = "";
      if (res.data.data.categoryId) {
        this.getCategories();
        categoryId = res.data.data.categoryId;
      }
      return { categoryId, success: true };
    } catch (err) {
      const _msg = err?.response?.data?._msg || "Connection error!";
      return {
        _msg,
        success: false,
      };
    }
  };

  render() {
    // const permission = {
    //   create: checkPermission("Applications", "Create"),
    //   edit: checkPermission("Applications", "Edit"),
    //   delete: checkPermission("Applications", "Delete"),
    // };

    const { payload, applicationTags } = this.state;
    const { message, success, onBoard, loading, allTeamsData } = this.props;
    console.log(onBoard, allTeamsData, 46666635);
    let dataTags = applicationTags ? applicationTags : ["#react"];
    const color = success === "fail" ? "error" : "success";
    console.log(message, color, applicationTags, 408);
    return (
      <Grid container>
        <Grid item xs={12}>
          {loading && <Loader />}
          <ApplicationComponent
            message={message}
            color={color}
            defaultTags={dataTags}
            onBoardApplications={(data) => this.props.onBoardApplications(data)}
            data={payload.environment}
            billableServices={this.state.billableServices}
            defaultCategory={this.state.category || []}
            history={this.props.history}
            addCategory={this.addCategory}
            usageParams={onBoard.usageParams}
            {...this.props}
          />
        </Grid>
      </Grid>
    );
  }
}
ApplicationOnBoard.propTypes = {};

const mapDispatchToProps = (dispatch) => ({
  onBoardApplications: (data) => dispatch(onboardApplication(data)),
  clearMessage: () => dispatch(clearMessage()),
  deleteBS: (payload) => dispatch(updateBillingService(payload)),
  deleteWebhhoks: (payload) => dispatch(updateWebhooks(payload)),
  deleteModule: (payload) => dispatch(updateModule(payload)),
  deleteAuthRole: (data) => dispatch(updateAuthObject(data)),
  deleteAPI: (data) => dispatch(updateAPI(data)),
  deleteENV: (data) => dispatch(updateEnvironmentForm(data)),
  addAppBasicDetails: (data) => dispatch(addAppBasicInfo(data)),
  addAuthWBH: (data) => dispatch(addAuthWebhooks(data)),
  addBillingWebhooks: (data) => dispatch(addBillingWebhooks(data)),
  cancelApp: () => dispatch(cancelApplication()),
  getTeamsData: (filter, selectedTeam) =>
    dispatch(fetchTeams(filter, selectedTeam)),
  handleSelectTeam: (selectedTeams, team, data, val) =>
    dispatch(handleSelectTeam(selectedTeams, team, data, val)),
  setClickedTeam: (team) => dispatch(set_ClickedTeam(team)),
});
const mapStateToProps = (state) => {
  return {
    message: state.application.message,
    success: state.application.success,
    actions: state.application.action,
    app_id: state.application.app_id,
    onBoard: state.application.onBoard,
    loading: state.application.loading,
    appTeamData: state.application.appTeamData,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ApplicationOnBoard)
);
