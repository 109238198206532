import React, { useEffect } from "react";
import { LineChart } from "components/CustomGraph/index";
import withSection from "components/Section/Section";
const DisplayGraph = (props) => {
  const { name, handleSetText } = props;

  useEffect(() => {
    handleSetText(name);
  }, []);
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          width: "50%",
        }}
      >
        <LineChart />
      </div>
    </div>
  );
};
export default withSection(DisplayGraph);
