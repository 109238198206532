import {
  ADD_ORGANIZATION_DATA,
  ORGANIZATIONS_FILTER,
  ORGANIZATIONS_SORT,
  ACTIVE_INACTIVE_ORG,
  ADD_CURRENT_ORGANIZATION_DATA,
  CHANGE_LOGO,
} from "./actionTypes";
import organizationService from "api/OrganizationAPIs/index";

export const getOrganizationDetails = async () => {
  try {
    const res_data = await organizationService.getOrganizationDetails();
    const { _msg, _status, data } = res_data.data;
    console.log(res_data.data, res_data._msg, "oiuythjk");
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    console.log(err.response);
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const getOrganizationDetailsByID = async () => {
  try {
    const res_data = await organizationService.getOrganizationDetailsByID(
    );
    const { _msg, _status, data } = res_data.data;
    console.log(res_data.data, res_data._msg, "oiuythjk");
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    console.log(err.response);
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const editOrganization = async (payload) => {
  try {
    const res = await organizationService.editOrganization( payload);
    const { _msg, _status, data } = res.data;
    console.log(res.data, res._msg, "oiuythjk");
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    console.log(err.response);
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

// function add_org_listscreen_data(payload) {
//   return {
//     type: ADD_ORGANIZATION_DATA,
//     payload: payload,
//   };
// }

// export function add_organization_data(currentPage, filter, sort) {
//   return function (dispatch) {
//     dispatch(
//       add_org_listscreen_data({
//         loading: true,
//       })
//     );
//     organizationService
//       .getOrganizationsList(currentPage, filter, sort)
//       .then((res) => {
//         if (res.status === 200) {
//           const { data, _totalcount } = res.data;
//           if (data) {
//             dispatch(
//               add_org_listscreen_data({
//                 loading: false,
//                 data: data,
//                 error: false,
//                 _totalcount: _totalcount,
//               })
//             );
//           } else {
//             dispatch(
//               add_org_listscreen_data({
//                 loading: false,
//                 data: [],
//                 error: false,
//                 _totalcount: _totalcount,
//               })
//             );
//           }
//         }
//       })
//       .catch(() => {
//         dispatch(
//           add_org_listscreen_data({
//             loading: false,
//             data: [],
//             error: false,
//           })
//         );
//       });
//   };
// }

// export const active_inactive_org = (orgID, status) => {
//   return {
//     type: ACTIVE_INACTIVE_ORG,
//     payload: {
//       orgID,
//       status,
//     },
//   };
// };

// export const add_current_organization_data = (currentOrgData) => {
//   return {
//     type: ADD_CURRENT_ORGANIZATION_DATA,
//     payload: currentOrgData,
//   };
// };

// export function add_current_organization_data_action() {
//   return function (dispatch) {
//     organizationService
//       .fetchCurrentORGDetails()
//       .then((res) => {
//         if (res.status === 200) {
//           const { data } = res.data;
//           if (data) {
//             console.log("test call data", data);
//             dispatch(
//               add_current_organization_data({
//                 data: data,
//               })
//             );
//           } else {
//             dispatch(
//               add_current_organization_data({
//                 data: [],
//               })
//             );
//           }
//         }
//       })
//       .catch((err) => {
//         dispatch(
//           add_current_organization_data({
//             data: [],
//           })
//         );
//       });
//   };
// }

export const change_logo = (logo, favicon, title) => {
  return {
    type: CHANGE_LOGO,
    logo: logo,
    favicon: favicon,
    title: title,
  };
};
