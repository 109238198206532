// contains API helper functions
//import React from "react";
import axios from "axios";

const AUTH_POST = async (url, payload, options = {}) => {
  try {
    let res = await axios.post(url, payload, {
      headers: { ...options.headers } || {},
    });
    return res;
  } catch (err) {
    throw err;
  }
};

const POST = async (url, payload, options = {}) => {
  const token = JSON.parse(sessionStorage.getItem("token"));
  const { accessToken } = token || {};
  console.log({ ...options.headers }, "5u8y698y69");
  try {
    let res = await axios.post(url, payload, {
      headers: { ...options.headers, token: accessToken } || {},
    });
    return res;
  } catch (err) {
    throw err;
  }
};

const GET = async (url, options = {}) => {
  const token = JSON.parse(sessionStorage.getItem("token"));
  const { accessToken } = token || {};
  try {
    let res = await axios.get(url, {
      headers: { ...options.headers, token: accessToken } || {},
    });
    return res;
  } catch (err) {
    if (
      err.message === "Failed to fetch" ||
      err.message === "Network request failed"
    ) {
    } else {
      //
    }
    throw err;
  }
};

const PUT = async (url, payload, options = {}) => {
  const token = JSON.parse(sessionStorage.getItem("token"));
  const { accessToken } = token || {};
  try {
    let res = await axios.put(url, payload, {
      headers: { ...options.headers, token: accessToken } || {},
    });
    return res;
  } catch (err) {
    throw err;
  }
};

const DELETE = async (url, options) => {
  const token = JSON.parse(sessionStorage.getItem("token"));
  const { accessToken } = token || {};
  let authToken = localStorage.getItem("token");
  try {
    let res = await axios.delete(url, {
      method: "DELETE",
      headers:
        {
          token: accessToken,
          Authorization: `Bearer ${authToken || ""}`,
          ...(options || {}).headers,
        } || {},
    });
    return res;
  } catch (err) {
    console.log(err.message);
    if (
      err.message === "Failed to fetch" ||
      err.message === "Network request failed"
    ) {
      throw err;
    } else {
      throw err;
    }
  }
};

export default { GET, PUT, POST, DELETE, AUTH_POST };
