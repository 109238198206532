import React, { useEffect, useState } from "react";

import {
  Grid,
  Button,
  Typography,
  Modal,
  Divider,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  InputAdornment,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import CommentIcon from "@material-ui/icons/Comment";
import {
  TextValidator,
  SelectValidator,
  ValidatorForm,
} from "react-material-ui-form-validator";
import { CustomTextField } from "components/Customized";
import SearchIcon from "@material-ui/icons/Search";
import styles from "./Styles";
import { withStyles } from "@material-ui/styles";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));
const AddRole = (props) => {
  const {
    openmodal,
    handleCloseModal,
    handleClose,
    property,
    rolesData,
    Modaldata,
    Appdata,
    rolesMap,
    classes,
  } = props;
  const [roles, setRoles] = useState([]);
  // const classes = useStyles();
  const [getRoleData, setRoleData] = useState([]);
  const [searchKey, setSearchKey] = useState({ name: "" });
  useEffect(() => {
    setRoles([...rolesMap]);
  }, [rolesMap]);

  useEffect(() => {
    if (props.isEdit) {
      setRoleData([...rolesData]);
    }
  }, [rolesData, props.isEdit]);

  const handleOnchange = (e, role, id) => {
    if (e.target.checked) {
      let data = [...roles];
      data[id] = role;
      setRoles([...data]);
    } else {
      let data = [...roles];
      data = data.map((item, idx) => {
        if (idx === id) {
          return undefined;
        } else {
          return item;
        }
      });
      setRoles([...data]);
    }
  };

  const radio_handleOnchange = (role, id) => {
    let data = [...getRoleData];
    console.log(getRoleData, role, id, "object");
    data = data.map((item, idx) => {
      return id === idx ? { ...role } : { ...item, status: false };
    });
    setRoleData([...data]);
    console.log(getRoleData, role, id, data, "object");
  };

  console.log(roles, rolesData, "ghgjgj");

  const searchRoleName = async (value) => {
    setSearchKey({ ...value });
    let filter = {};
    filter["name"] = { $regex: "^" + value.name, $options: "i" };
    props.searchRole(filter);
  };
  console.log("add role modal", roles, getRoleData);
  return (
    <>
      <ValidatorForm>
        <Dialog
          open={openmodal}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <DialogTitle id="alert-dialog-title">
            <Typography color="primary">Select Roles</Typography>
          </DialogTitle>
          <Divider />
          {!props.isEdit ? (
            <DialogContent>
              <Grid item sm={12}>
                <CustomTextField
                  className=" mt-1 "
                  autoComplete="off"
                  fullWidth
                  id="filled-search"
                  type="text"
                  variant="outlined"
                  size="small"
                  placeholder="Search"
                  onChange={(e) =>
                    searchRoleName({ ...searchKey, name: e.target.value })
                  }
                  // value={this.state.searchkey}
                  margin="normal"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {" "}
                        <SearchIcon />{" "}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid container style={{ width: "400px", height: "400px" }}>
                <List className={classes.root}>
                  {rolesData.map((value, id) => {
                    let status =
                      roles[id] && roles[id].id === value._id ? true : false;
                    return (
                      <ListItem key={id} className={classes.liststyle}>
                        <ListItemIcon>
                          <Checkbox
                            color="primary"
                            checked={status}
                            onChange={(e) =>
                              handleOnchange(
                                e,
                                {
                                  name: value.name,
                                  id: value._id,
                                  status: true,
                                },
                                id
                              )
                            }
                            edge="start"
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ "aria-labelledby": id }}
                          />
                        </ListItemIcon>
                        <ListItemText id={id} primary={value.name} />
                      </ListItem>
                    );
                  })}
                </List>
              </Grid>
              <Divider />
            </DialogContent>
          ) : (
            <DialogContent>
              <Grid item sm={12}>
                <CustomTextField
                  className=" mt-1 "
                  autoComplete="off"
                  fullWidth
                  id="filled-search"
                  type="text"
                  variant="outlined"
                  size="small"
                  placeholder="Search"
                  onChange={(e) =>
                    searchRoleName({ ...searchKey, name: e.target.value })
                  }
                  // value={this.state.searchkey}
                  margin="normal"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {" "}
                        <SearchIcon />{" "}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid container style={{ width: "400px", height: "400px" }}>
                <List className={classes.root}>
                  {getRoleData.map((value, id) => {
                    return (
                      <ListItem key={id} className={classes.liststyle}>
                        <ListItemIcon>
                          <RadioGroup
                            className={classes.radioStyle}
                            value={value.status || false}
                            onChange={(e) =>
                              radio_handleOnchange(
                                { ...value, status: true },
                                id
                              )
                            }
                          >
                            <FormControlLabel
                              value={true}
                              control={<Radio />}
                            />
                          </RadioGroup>
                        </ListItemIcon>
                        <ListItemText id={id} primary={value.name} />
                      </ListItem>
                    );
                  })}
                </List>
              </Grid>
              <Divider />
            </DialogContent>
          )}
          <DialogActions className="mb-2">
            <Button size="small" variant="outlined" onClick={handleCloseModal}>
              Cancel
            </Button>
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                if (props.isEdit) {
                  let data = getRoleData.filter((row) => row.status);
                  if (data.length !== 0) {
                    props.onsubmit(getRoleData, "radio");
                  } else {
                    props.enqueueSnackbar("Please select role!", {
                      variant: "error",
                    });
                  }
                } else {
                  props.onsubmit(roles);
                }
              }}
            >
              Add Roles
            </Button>
          </DialogActions>
        </Dialog>
      </ValidatorForm>
    </>
  );
};

export default withStyles(styles)(AddRole);
