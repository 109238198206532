import FormControl from "@mui/material/FormControl";
import { Select, FormHelperText, InputLabel } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import MuiFormControl from "@material-ui/core/FormControl";
import MuiSelect from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiSelect-outlined": {
      height: "14px",
    },
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const menuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};
export const MuiDropDown = ({
  value,
  handleChange,
  elements,
  onKeyDown,
  autoFocus,
  onBlur,
  err,
  variant,
  message,
  size,
  label,
  disabled,
}) => {
  const doNothing = () => {};
  return (
    <FormControl
      size={size || "small"}
      variant={variant || "outlined"}
      fullWidth
      error={err || false}
    >
      {label && <InputLabel id="mui-dropdown">{label}</InputLabel>}
      <Select
        disabled={disabled || false}
        style={{ width: "200px" }}
        // autoFocus={autoFocus || false}
        value={value || ""}
        onChange={handleChange}
        MenuProps={menuProps}
        onBlur={onBlur || doNothing}
        label={label || ""}
        labelId="mui-dropdown"
        id="mui-dropdown"
      >
        {elements.map((item, index) => (
          <MenuItem key={index} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>

      {err && <FormHelperText>{message}</FormHelperText>}
    </FormControl>
  );
};

export const MuiNativeSelect = ({ value, handleChange, options, title }) => {
  const classes = useStyles();
  return (
    <>
      {/* {title && (
        <Typography style={{ marginBottom: 2, fontSize: 16 }}>
          {title}
        </Typography>
      )} */}
      <MuiFormControl
        size="small"
        fullWidth
        variant="outlined"
        className={classes.root}
      >
        {title && (
          <InputLabel style={{ fontSize: 14, margin: 0 }} id="mui-dropdown">
            {title}
          </InputLabel>
        )}
        <MuiSelect
          native
          value={value || ""}
          onChange={handleChange}
          inputProps={{
            name: "drop-down",
            id: "demo-customized-select-native",
          }}
        >
          <option aria-label="None" value="">
            -Select-
          </option>
          {options.map((item, index) => (
            <option key={index} value={item.value}>
              {item.label}
            </option>
          ))}
        </MuiSelect>
      </MuiFormControl>
    </>
  );
};
