import * as types from "./actionType";
const initialState = {
  subscribers: [],
  loading: false,
  totalCount: 0,
  filterProps: {
    filters: {},
    filterArray: [{ key: 1, columns: "", operator: "", value: "" }],
    sort: { createdOn: -1 },
    sorting: [{ columnName: "createdon", direction: "desc" }],
  },
  hidden: ["email", "phonenumber"],
};
export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case types.FETCHING_CUSTOMER_LIST:
      return {
        ...state,
        loading: true,
      };
    case types.FETCHED_CUSTOMER_LIST:
      return {
        ...state,
        loading: false,
        subscribers: [...action.payload],
        totalCount: action.totalCount,
      };
    case types.FETCH_CUSTOMER_LIST_FAILED:
      return {
        ...state,
        loading: false,
        subscribers: [],
      };

    case types.UPDATE_CUSTOMER_FILTER_DATA:
      return {
        ...state,
        filterProps: {
          ...state.filterProps,
          filters: action.payload.filter,
          filterArray: action.payload.filterArray,
        },
      };
    case types.UPDATE_CUSTOMER_SORT:
      return {
        ...state,
        filterProps: {
          ...state.filterProps,
          sorting: action.payload.sorting,
          sort: action.payload.sort,
        },
      };

    case types.UPDATE_CUSTOMER_COLUMN_HIDDEN:
      return {
        ...state,
        hidden: action.payload,
      };
    default:
      return state;
  }
}
