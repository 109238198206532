import React from "react";
import { Redirect, Route } from "react-router-dom";
import { hasPermission } from "../../helpers/permissons";
const AuthenticatedRoute = (props) => {
  const { component, path, permission, resource } = props;
  if (hasPermission(permission, resource)) {
    return <Route path={path} component={component}  />;
  } else {
    return <Redirect to="/app/error" />;
  }
  
};
export default AuthenticatedRoute;
