import React, { useEffect, useState } from "react";
import { Handle, Position } from "react-flow-renderer";
import Chip from "@mui/material/Chip";
import MenuItem from "@mui/material/MenuItem";
import { Grid, Paper, TextField, List, ListItem } from "@material-ui/core";
import { MuiDropDown } from "components/DropDown/MuiDropDown";
import CustomAutocomplete from "components/DropDown/AutoComplete";
import { StayCurrentLandscapeRounded } from "@mui/icons-material";
import { IconButton } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
const operations = [
  {
    label: "Greater than",
    value: ">",
  },
  {
    label: "Lesser than",
    value: "<",
  },
  {
    label: "Greater than or Equal To",
    value: ">=",
  },
  {
    label: "Less than or Equals To",
    value: "<=",
  },
  {
    label: "Equals To",
    value: "==",
  },
  {
    label: "Like",
    value: "%",
  },
  {
    label: "Not like",
    value: "!%",
  },
];

const getOptionsFromNode = (node) => {
  const nodeArr = node.children || [];
  let options = [];
  nodeArr.forEach((currentNode) => {
    let title = currentNode.title === "arrayElement" ? "#" : currentNode.title;
    if (title !== "#") {
      options.push({ value: title, label: title });
    }
    if (currentNode.children && currentNode.children.length !== 0) {
      recursiveHelper(`${title}`, currentNode.children, options);
    }
  });
  return options;
};
// takes childrens and returns titles
const recursiveHelper = (parentTitle, nodes, options) => {
  nodes.forEach((node) => {
    let title = node.title == "arrayElement" ? "#" : node.title;
    if (title !== "#" && node.type !== "array") {
      options.push({
        value: `${parentTitle}_${title}`,
        label: `${parentTitle}_${title}`,
      });
    }

    if (node.children && node.children.length !== 0) {
      recursiveHelper(`${parentTitle}_${title}`, node.children, options);
    }
  });
};

export default function FilterNode(props) {
  const [state_, setState_] = useState({
    options: [],
    rtl: props.data.type == "cdutoapi",
  });
  const { options, rtl } = state_;

  useEffect(() => {
    const data = getOptionsFromNode(props.data.input?.connectedNode || {});
    setState_({
      options: data,
      rtl: props.data.type == "cdutoapi",
    });
  }, [props]);

  console.log("fiter props", { ...props });

  let all_operations = [...operations];
  const Pattern =
    props.data.input?.filterOp === "%" || props.data.input?.filterOp === "!%";

  return (
    <Paper
      elevation={props.selected ? 12 : 3}
      style={{
        borderRadius: 16,
      }}
    >
      <div>
        {props.selected && (
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={(e) => {
              props.data.onNodeDelete(props.id);
            }}
            style={{
              position: "absolute",
              top: "-29px",
              right: "-31px",
              color: "red",
            }}
          >
            <CancelIcon />
          </IconButton>
        )}
        <Handle
          className={`circle-port ${
            !rtl ? "circle-port-left" : "circle-port-right"
          }`}
          position={!rtl ? Position.Left : Position.Right}
          type={"target"}
          id={"input"}
          key={"input"}
        />
        <Chip
          label="Filter"
          style={{
            background: "#ff5722",
            height: "50px",
            width: "100%",
            color: "white",
            fontSize: "20px",
            borderBottomLeftRadius: "0px",
            borderBottomRightRadius: "0px",
          }}
        />
        <Grid
          container
          spacing={1}
          style={{ padding: "7px", marginTop: "9px" }}
        >
          <Grid item md={12}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div style={{ paddingLeft: "2px" }}>
                <CustomAutocomplete
                  disabled={options.length === 0}
                  options={options}
                  label="Field"
                  style={{ width: "200px" }}
                  value={props.data.input?.filterOn || ""}
                  handleChange={(value) => {
                    props.data.onChangeNodeData(props.id, props.type, {
                      filterOn: value || "",
                    });
                  }}
                />
                {/* <MuiDropDown
                  label="Field"
                  elements={options}
                  disabled={options.length === 0}
                  value={props.data.input?.filterOn}
                  handleChange={(e) => {
                    props.data.onChangeNodeData(props.id, props.type, {
                      filterOn: e.target.value,
                    });
                  }}
                  err={false}
                  variant="outlined"
                  size="small"
                  message={null}
                /> */}
              </div>
              <div>
                <CustomAutocomplete
                  disabled={false}
                  options={all_operations}
                  label="Operation"
                  style={{ width: "200px" }}
                  value={props.data.input?.filterOp || ""}
                  handleChange={(value) => {
                    props.data.onChangeNodeData(props.id, props.type, {
                      filterOp: value || "",
                    });
                  }}
                />
              </div>
              <div style={{ paddingRight: "2px" }}>
                <TextField
                  autoComplete="off"
                  label={Pattern ? "Pattern" : "Value"}
                  value={props.data.input?.filterValue || ""}
                  fullWidth
                  size="small"
                  style={{ width: "200px" }}
                  variant="outlined"
                  onChange={(e) => {
                    props.data.onChangeNodeData(props.id, props.type, {
                      filterValue: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </Grid>
          <Grid item md={4}>
            {/* <MuiDropDown
              label="Operation"
              elements={[...(all_operations || [])]}
              value={props.data.input?.filterOp}
              handleChange={(e) => {
                props.data.onChangeNodeData(props.id, props.type, {
                  filterOp: e.target.value,
                });
              }}
              // onKeyDown={}
              autoFocus
              // onBlur={}
              err={false}
              variant="outlined"
              size="small"
              message={null}
            /> */}
          </Grid>
          <Grid item md={4}></Grid>
          <Grid item sm={12} md={12} lg={12} xs={12}>
            <List dense={true}>
              {(options.length !== 0 ? options : [{ label: "" }]).map((d) => {
                return !rtl ? (
                  <ListItem
                    style={{ justifyContent: "flex-end", color: "#545151" }}
                  >
                    <Handle
                      className={"circle-port circle-port-right"}
                      position={Position.Right}
                      type={"soruce"}
                      id={`output_${d.label}`}
                      key={`output_${d.label}`}
                    />
                    {d.label}
                  </ListItem>
                ) : (
                  <ListItem
                    style={{ justifyContent: "flex-start", color: "#545151" }}
                  >
                    {d.label}
                    <Handle
                      className={"circle-port circle-port-left"}
                      position={Position.Left}
                      type={"soruce"}
                      id={`output_${d.label}`}
                      key={`output_${d.label}`}
                    />
                  </ListItem>
                );
              })}
            </List>
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
}
