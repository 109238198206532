import React from "react";
import withSection from "components/Section/Section";
import DexTable from "components/DexTable/DexTable";
import { Typography } from "@material-ui/core";
import { checkPermission } from "helpers/permissons";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import { customerUpadateHiddenCol } from "../CustomerProfile/redux/action";
const CustomerIssue = (props) => {
  const { customerIssues, details, colTable } = props;
  const { issue } = colTable;
  //   console.log(customerIssues, "sdfgh3456y");
  const statusProvider = ({ row }) => {
    return <Typography>{row.status}</Typography>;
  };

  const pushToHelpDeskPage = (row) => {
    let rowData = { ...row };
    props.history.push("/app/create-helpdesk", {
      Helpdesk_Data: rowData,
      edit: true,
      disabled: true,
      customerProfile: true,
      customerData: { ...details },
    });
  };

  return (
    <div>
      <DexTable
        data={[...(customerIssues || [])]}
        columnOrder={["_id", "category", "createdon", "status", "actions"]}
        columns={[
          { name: "_id", title: "Ticket ID" },
          { name: "category", title: "Category" },
          { name: "createdon", title: "Created On" },
          { name: "status", title: "Status" },
          //   { name: "actions", title: "Actions" },
        ]}
        columWidthsType="percentage"
        defaultColumnWidths={[
          { columnName: "_id", width: 20 },
          { columnName: "category", width: 25 },
          { columnName: "createdon", width: 20 },
          { columnName: "status", width: 15 },
          { columnName: "actions", width: 18 },
        ]}
        formatters={[
          //   { formatterFn: this.actionProvider, columns: ["actions"] },
          { formatterFn: statusProvider, columns: ["status"] },
        ]}
        // sorting={this.state.sorting}
        // onSortingChange={this.handleSortByChange}
        dateColumns={["createdon"]}
        // hideColumnChooser={true}
        disableReordering={false}
        tableType="virtual"
        onClickRow={pushToHelpDeskPage}
        Read={checkPermission("btn64")}
        hidden={[...issue]}
        setHiddenColmn={(hidden) => {
          props.setHiddenColumn(hidden, "issue");
        }}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setHiddenColumn: (hidden, type) =>
    dispatch(customerUpadateHiddenCol(hidden, type)),
});
const mapStateToProps = (state) => {
  return {
    colTable: state.customerProfile.colTable,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withSection(CustomerIssue, "Issues")));
