import React, { useEffect } from "react";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import "@fortawesome/fontawesome-free/css/all.css";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import {
  createTheme,
  MuiThemeProvider,
  makeStyles,
} from "@material-ui/core/styles";
import { SnackbarProvider } from "notistack";
import AdminLayout from "./layout/AdminLayout/AdminLayout";
import Login from "./views/login/Login";
import LoginLayout from "./layout/LoginLayout/LoginLayout";
import applicationTheme from "./styles/theme/applicationTheme";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { useSelector } from "react-redux";
import Styles from "./styles/Styles-root";
import appApi from "api/ApplicationAPIs/index";
import axios from "axios";

// axios.interceptors.response.use(
//   (response) => {
//     console.log("dgjgdghdgjdf6767i6", response);
//     return response;
//   },
//   (err) => {
//     console.log("dhgdhghjdghgdf", err);
//     return err;
//   }
// );

const useStyles = makeStyles(Styles);
function App() {
  const loginDetails = useSelector((state) => state.loginReducer);
  const { privateRoute } = loginDetails;
  const userDetails = useSelector((state) => state.apearenceReducer);
  const { userInfo } = userDetails;
  const organizationInfo = useSelector((state) => state.organizationReducer);
  const { organizationDetails } = organizationInfo;
  const org_info = organizationDetails || {};

  const secondary_color = "";
  const theme = createTheme(applicationTheme(userInfo.color, secondary_color));
  let classes = useStyles();

  useEffect(() => {
    document.getElementById("myFavicon2").href =
      org_info.favicon || "/favicon.ico";
    const tabTitle = document.getElementById("tabTitle");
    tabTitle.innerHTML = org_info.title || "SOS";
  });

  // console.log(userInfo.color, "this is color");

  return (
    <>
      <MuiThemeProvider theme={theme}>
        <SnackbarProvider
          style={{ marginTop: "50px", width: "36vw" }}
          classes={{
            variantSuccess: classes.success,
            variantError: classes.error,
          }}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          maxSnack={2}
        >
          <DndProvider backend={HTML5Backend}>
            <BrowserRouter>
              <Switch>
                <Route
                  path="/app"
                  render={(props) =>
                    privateRoute ? (
                      <AdminLayout privateRoute={privateRoute} {...props} />
                    ) : (
                      <Redirect to="/" />
                    )
                  }
                />
                <Route
                  path="/"
                  render={(props) => <LoginLayout {...props} />}
                />
              </Switch>
            </BrowserRouter>
          </DndProvider>
        </SnackbarProvider>
      </MuiThemeProvider>
    </>
  );
}
export default App;
