import { React, useEffect, useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import SyncIcon from "@material-ui/icons/Sync";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import ApplicationAPIs from "../../../api/ApplicationAPIs/index";
import { useSnackbar } from "notistack";
import {
  Grid,
  Button,
  TextField,
  Typography,
  Tooltip,
  IconButton,
  FormControl,
  Select,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormHelperText,
} from "@material-ui/core";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import { ApplicationDetailsUpadateHiddenCol } from "../ApplicationDetails/Redux/action";
import withSection from "components/Section/Section";
import DexTable from "components/DexTable/DexTable";
import { RiEditLine, RiDeleteBin5Line } from "react-icons/ri";
import AddIcon from "@material-ui/icons/Add";
import WrappedDexTable from "components/DexTable/WrappedDexTable";
import { checkPermission } from "helpers/permissons";
import { confirmDialog } from "components/DeleteConfirmationDialog/Index";
import { dltMsg } from "helpers/helpers";

const styles = (length) => {
  let obj = {
    fontSize: "20px",
    color: length <= 1 ? "#bdbdbd" : "",
    cursor: length <= 1 ? "text" : "pointer",
  };
  return obj;
};

const useStyles = makeStyles((theme) => ({
  line: {
    marginTop: -23,
  },
  dividerline: {
    width: "70%",
    margin: "-25px 0px 0px 125px",
    [theme.breakpoints.up("md")]: {
      width: "82%",
      margin: "-25px 0px 0px 260px",
    },
  },
  table: {
    minWidth: 500,
  },
}));

function AuthorizationObjectsDex(props) {
  const {
    authorization,
    webhooks,
    appId,
    authorizationSync,
    singleSignOn,
    editRoles,
    addRoles,
    deleteRole,
    appTable,
    appName,
  } = props;
  const { authorizationHidden } = appTable;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [edit, setEdit] = useState(false);
  console.log({ authorization });
  let [rows, setRows] = useState(authorization || []);
  let [webhooksPayload, setWebhooksPayload] = useState({
    url: "",
    method: "",
    apiKey: "",
  });
  let [single_sign_on, setSingleSignOn] = useState(null);
  const [err, setErr] = useState({
    url: false,
    method: false,
    apiKey: false,
  });

  useEffect(() => {
    setRows([...authorization]);
    if (webhooks) {
      Object.keys(webhooks).length !== 0 && setWebhooksPayload({ ...webhooks });
    }
    if (singleSignOn) {
      setSingleSignOn(singleSignOn);
    }
  }, [authorization, webhooks, singleSignOn]);

  const saveWebhooks = async (e) => {
    // console.log("gdhghdfdfhg", single_sign_on, webhooksPayload);
    const set_err = err;
    const { method, url, apiKey } = webhooksPayload;
    let payload = { ...webhooksPayload };
    if (single_sign_on === "Supported") {
      set_err.apiKey = apiKey ? apiKey.trim() === "" : true;
      set_err.method = method ? method.trim() === "" : true;
      set_err.url = url ? url.trim() === "" : true;
      setErr({ ...set_err });
      if (set_err.method || set_err.apiKey || set_err.url) {
        return;
      }
    } else {
      payload = {};
    }
    try {
      const res = await ApplicationAPIs.editBasicDetails(appId, {
        authorizationWebhook: payload,
        singleSignOn: single_sign_on,
      });
      enqueueSnackbar(
        res.data._msg || "Application details saved successfully",
        { variant: "success", autoHideDuration: 3000 }
      );
      props.getDetails(appId);
      setEdit(false);
    } catch (err) {
      enqueueSnackbar(err.message || "Something Went wrong", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  const updateWebHookPayload = () => {
    Object.keys(webhooks).length !== 0
      ? setWebhooksPayload({ ...webhooks })
      : setWebhooksPayload({
          url: "",
          method: "",
          apiKey: "",
        });
    setErr({
      url: false,
      method: false,
      apiKey: false,
    });
  };

  const pushToCreateForm = () => {
    let data = {
      name: "",
      description: "",
      applicationRoleName: "",
    };
    checkPermission("btn207") &&
      props.history.push("/app/create-auth-form", {
        authRoleData: data,
        edit: false,
        ajax: true,
        appID: props.appId,
        appName: appName,
      });
  };

  const removerole = async ({ row }, e) => {
    e && e.stopPropagation();
    await props.deleteRoles(row.appId, row._id);
  };

  const editAuthObj = ({ row }, e, val) => {
    e && e.stopPropagation();
    checkPermission("btn202") &&
      props.history.push("/app/create-auth-form", {
        authRoleData: val ? val : row,
        edit: true,
        ajax: true,
        appID: props.appId,
        disabled: e ? false : true,
        appName: appName,
      });
  };

  const actionProvider = (row) => {
    console.log(row, "3436436");
    return (
      <div
        style={{
          display: "flex",
        }}
      >
        <div style={{ marginRight: 20 }}>
          <Tooltip title="Edit" aria-label="edit">
            <IconButton
              size="small"
              color="inherit"
              disabled={!checkPermission("btn202")}
              onClick={(e) => checkPermission("btn202") && editAuthObj(row, e)}
            >
              <RiEditLine fontSize="20px" />
            </IconButton>
          </Tooltip>
        </div>
        {/* {authorization.length > 1 && ( */}
        <div>
          <Tooltip
            title={authorization.length <= 1 ? "" : "Delete"}
            aria-label="delete"
          >
            <IconButton
              color="inherit"
              size="small"
              disabled={!checkPermission("btn104")}
              onClick={(e) => {
                e.stopPropagation();
                authorization.length > 1 &&
                  checkPermission("btn104") &&
                  confirmDialog(dltMsg(row?.row?.name || ""), () =>
                    removerole(row, e)
                  );
              }}
            >
              {/* <IconButton color="primary" onClick={(e) => removerole(row, e)}> */}
              <RiDeleteBin5Line
                color="inherit"
                style={styles(authorization.length)}
              />
            </IconButton>
          </Tooltip>
        </div>
        {/* )} */}
      </div>
    );
  };
  const viewAuthdata = (row) => {
    editAuthObj({}, null, row);
  };

  return (
    <div>
      {/* <ValidatorForm
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            return;
          }
        }}
        onSubmit={async (e) => {
          e.preventDefault();
          // saveWebhooks(e);
        }}
      > */}
      <div>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={12} sm={6} md={8} container direction="row">
            <Typography variant="body2" className="pr-3 mt-2">
              Single sign on :
            </Typography>
            <RadioGroup
              value={single_sign_on}
              onChange={(e) => {
                setSingleSignOn(e.target.value);
              }}
            >
              <div style={{ display: "flex" }}>
                <FormControlLabel
                  disabled={!edit}
                  name="supported"
                  value="Supported"
                  control={<Radio color="primary" />}
                  label="Supported"
                />{" "}
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                <FormControlLabel
                  disabled={!edit}
                  value="Not Supported"
                  control={<Radio color="primary" />}
                  label="Not Supported"
                  color="primary"
                />
              </div>
            </RadioGroup>
          </Grid>
          <Grid
            item
            md={4}
            container
            spacing={2}
            alignItems="center"
            justifyContent="flex-end"
          >
            {!edit && (
              <>
                {single_sign_on === "Supported" && (
                  // <Grid container>
                  <Grid item xs={1}>
                    <Tooltip title="Sync" aria-label="sync">
                      <IconButton
                        size="small"
                        onClick={() => {
                          authorizationSync(appId);
                        }}
                      >
                        {" "}
                        <SyncIcon
                          style={{ fontSize: "20px" }}
                          color="secondary"
                        />{" "}
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  // </Grid>
                )}
                {/* <Grid conatiner spacing={2} className="pr-2"> */}
                <Grid item xs={1}>
                  <Tooltip title="Edit" aria-label="edit">
                    <IconButton
                      size="small"
                      color="inherit"
                      disabled={!checkPermission("btn208")}
                      onClick={() => checkPermission("btn208") && setEdit(true)}
                    >
                      {" "}
                      <RiEditLine style={{ fontSize: "20px" }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                {/* </Grid> */}
                {/* <Grid item xs={3}>
                      <Tooltip title="Delete" aria-label="delete">
                        <Button > <DeleteOutlineIcon /> </Button>
                      </Tooltip>
                    </Grid> */}
              </>
            )}
            {edit && (
              <>
                <Grid item xs={3}></Grid>
                <Grid item xs={2}>
                  <Button
                    name="authWebhook"
                    color="secondary"
                    size="small"
                    style={{
                      marginLeft: 10,
                      backgroundColor: "transparent",
                      boxShadow: "none",
                      color: "#9e9e9e",
                    }}
                    disabled={!checkPermission("btn209")}
                    onClick={async (e) => {
                      e.preventDefault();
                      checkPermission("btn209") && saveWebhooks(e);
                    }}
                  >
                    Save
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    size="small"
                    style={{
                      marginLeft: 10,
                      backgroundColor: "transparent",
                      boxShadow: "none",
                      color: "#9e9e9e",
                    }}
                    color="primary"
                    onClick={() => {
                      setEdit(false);
                      setSingleSignOn(singleSignOn);
                      updateWebHookPayload();
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        {single_sign_on === "Supported" && (
          <div>
            <Typography>Setup webhook</Typography>
            <Grid container spacing={2} className="mt-2">
              <Grid item xs={12} sm={4} md={4}>
                <Typography variant="body2" className="mb-1">
                  {" "}
                  URL{" "}
                </Typography>
                <TextField
                  name="authUrl"
                  disabled={!edit}
                  autoComplete="off"
                  className={classes.btn}
                  size="small"
                  variant="outlined"
                  fullWidth
                  id="authUrl"
                  value={webhooksPayload.url}
                  onChange={(e) => {
                    setWebhooksPayload({
                      ...webhooksPayload,
                      url: e.target.value.trimStart(),
                    });
                  }}
                  error={err.url}
                  defaultValue={(webhooks || {}).url}
                  helperText={err.url ? "Url is required" : ""}
                  onFocus={() =>
                    setErr({
                      ...err,
                      url: false,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2} md={2}>
                <Typography variant="body2" className="mb-1">
                  {" "}
                  Method{" "}
                </Typography>

                <FormControl
                  fullWidth
                  disabled={!edit}
                  size="small"
                  variant="outlined"
                  className={classes.btn}
                  value={webhooksPayload.method}
                >
                  <Select
                    name="authMethod"
                    native
                    value={webhooksPayload.method}
                    onChange={(e) => {
                      setWebhooksPayload({
                        ...webhooksPayload,
                        method: e.target.value,
                      });
                    }}
                    error={err.method}
                    onFocus={() =>
                      setErr({
                        ...err,
                        method: false,
                      })
                    }
                  >
                    <option value="">-Select-</option>
                    <option value="GET">GET</option>
                    <option value="POST">POST</option>
                    <option value="PUT">PUT</option>
                  </Select>
                  <FormHelperText style={{ color: "red" }}>
                    {err.method ? "Method is required" : ""}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={2} md={2}>
                <Typography variant="body2" className="mb-1">
                  {" "}
                  API Key (Security){" "}
                </Typography>
                <TextField
                  className={classes.btn}
                  disabled={!edit}
                  autoComplete="off"
                  size="small"
                  variant="outlined"
                  fullWidth
                  id="apikey"
                  // onChange={(e) => handleChangeRole(e)}
                  name="authApiKey"
                  value={webhooksPayload.apiKey}
                  onChange={(e) => {
                    setWebhooksPayload({
                      ...webhooksPayload,
                      apiKey: e.target.value.trimStart(),
                    });
                  }}
                  error={err.apiKey}
                  onFocus={() =>
                    setErr({
                      ...err,
                      apiKey: false,
                    })
                  }
                  defaultValue={(webhooks || {}).apiKey}
                  helperText={err.apiKey ? "Api key is required" : ""}
                />
              </Grid>
              <Grid
                xs={12}
                sm={4}
                item
                container
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => checkPermission("btn74") && pushToCreateForm()}
                  startIcon={<AddIcon />}
                  style={{ padding: "4px 54px 4px 54px" }}
                  disabled={!checkPermission("btn74")}
                >
                  Create Roles
                </Button>
              </Grid>
            </Grid>
          </div>
        )}
      </div>
      {/* </ValidatorForm> */}

      {single_sign_on === "Supported" && (
        <Grid container spacing={2}>
          {/* <Grid item xs={12} container justifyContent="flex-end">
            <Button
              size="small"
              variant="contained"
              onClick={() => checkPermission("btn207") && pushToCreateForm()}
              startIcon={<AddIcon />}
              style={{ padding: "4px 54px 4px 54px" }}
              disabled={!checkPermission("btn207")}
            >
              Create Roles
            </Button>
          </Grid> */}
          <Grid item md={12}>
            <WrappedDexTable
              data={authorization}
              // hideColumnChooser={true}
              columWidthsType="percentage"
              columnOrder={[
                "name",
                "applicationRoleName",
                "description",
                "actions",
              ]}
              columns={[
                { name: "name", title: "Role name" },
                {
                  name: "applicationRoleName",
                  title: "Application role name",
                },
                { name: "description", title: "Description" },
                { name: "actions", title: "Actions" },
              ]}
              defaultColumnWidths={[
                { columnName: "name", width: 20 },
                { columnName: "applicationRoleName", width: 20 },
                { columnName: "description", width: 40 },
                { columnName: "actions", width: 19 },
              ]}
              disableReordering={true}
              pagination={false}
              noDataMsg="Roles not found"
              formatters={[
                { formatterFn: actionProvider, columns: ["actions"] },
              ]}
              hidden={[...authorizationHidden]}
              setHiddenColmn={(hidden) => {
                props.setHiddenColumn(hidden, "authorizationHidden");
              }}
              onClickRow={(row) => viewAuthdata(row)}
              Read={checkPermission("btn225")}
            />
          </Grid>
        </Grid>
      )}
    </div>
  );
}
const mapDispatchToProps = (dispatch) => ({
  setHiddenColumn: (hidden, type) =>
    dispatch(ApplicationDetailsUpadateHiddenCol(hidden, type)),
});

const mapStateToProps = (state) => {
  return {
    appTable: state.appDetailsRedcer.appTable,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withSection(AuthorizationObjectsDex, "Authorization Roles")));
