import { React, useEffect, useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import Divider from "@material-ui/core/Divider";
import SyncIcon from "@material-ui/icons/Sync";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import ApplicationAPIs from "../../../api/ApplicationAPIs/index";
import { useSnackbar } from "notistack";
import {
  IconButton,
  CardHeader,
  CardContent,
  FormGroup,
  Card,
  Grid,
  Button,
  TextField,
  Typography,
  FormLabel,
  Tooltip,
  FormControl,
  Select,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@material-ui/core";
import { RemoveFromQueue } from "@material-ui/icons";
import withSection from "components/Section/Section";

const useStyles = makeStyles((theme) => ({
  line: {
    marginTop: -23,
  },
  dividerline: {
    width: "70%",
    margin: "-25px 0px 0px 125px",
    [theme.breakpoints.up("md")]: {
      width: "82%",
      margin: "-25px 0px 0px 260px",
    },
  },
  table: {
    minWidth: 500,
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({}))(TableRow);

const CustomTableRow = (props) => {
  const {
    row,
    onChange,
    addNewRow,
    index,
    removeRows,
    appId,
    addRoles,
    editRoles,
    deleteRoles,
  } = props;
  const classes = useStyles();
  const [edit, setEdit] = useState(row._id == undefined || false);
  return (
    <>
      <StyledTableCell component="th" scope="row">
        <TextField
          disabled={edit ? false : true}
          required
          className={classes.btn}
          size="small"
          variant="outlined"
          fullWidth
          onChange={(e) => onChange(e, index)}
          name="name"
          value={row.name}
        />
      </StyledTableCell>
      <StyledTableCell align="left">
        <TextField
          disabled={edit ? false : true}
          required
          className={classes.btn}
          size="small"
          variant="outlined"
          fullWidth
          value={row.applicationRoleName}
          name="applicationRoleName"
          onChange={(e) => onChange(e, index)}
        />
      </StyledTableCell>
      <StyledTableCell align="left">
        <TextField
          disabled={edit ? false : true}
          required
          className={classes.btn}
          size="small"
          onChange={(e) => onChange(e, index)}
          variant="outlined"
          fullWidth
          name="description"
          value={row.description}
        />
      </StyledTableCell>
      <StyledTableCell align="left">
        {edit && (
          <>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              style={{
                marginLeft: 0,
                backgroundColor: "transparent",
                boxShadow: "none",
                color: "#9e9e9e",
              }}
              onClick={async () => {
                if (row._id === undefined) {
                  await addRoles(appId, row);
                } else {
                  await editRoles(appId, row._id, row);
                }
                setEdit(false);
              }}
            >
              Save
            </Button>{" "}
            <Button
              style={{
                marginLeft: 0,
                backgroundColor: "transparent",
                boxShadow: "none",
                color: "#9e9e9e",
              }}
              variant="contained"
              color="primary"
              onClick={() => {
                if (row._id == undefined) {
                  removeRows(index);
                } else {
                  setEdit(false);
                }
              }}
            >
              Cancel
            </Button>
          </>
        )}
        {!edit && (
          <Grid
            container
            justifyContent="space-around"
            alignItems="center"
            spacing={1}
          >
            <Grid item xs={1}>
              <div style={{ width: "4%" }}>
                <Tooltip title="Edit" aria-label="edit">
                  <EditOutlinedIcon
                    onClick={() => {
                      setEdit(true);
                    }}
                  />
                </Tooltip>
              </div>
            </Grid>
            {props.length > 1 && (
              <Grid item xs={1}>
                <div style={{ width: "4%" }}>
                  <Tooltip title="Delete" aria-label="delete">
                    <DeleteOutlineIcon
                      onClick={async () => await deleteRoles(appId, row._id)}
                    />
                  </Tooltip>
                </div>
              </Grid>
            )}
            <Grid item xs={1}>
              <div style={{ width: "4%" }}>{addNewRow}</div>
            </Grid>
          </Grid>
        )}
      </StyledTableCell>
    </>
  );
};

function AuthorizationObjects(props) {
  const { authorization, webhooks, appId, authorizationSync, singleSignOn } =
    props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [edit, setEdit] = useState(false);
  console.log({ authorization });
  let [rows, setRows] = useState(authorization || []);
  let [webhooksPayload, setWebhooksPayload] = useState({});
  let [single_sign_on, setSingleSignOn] = useState(null);

  useEffect(() => {
    setRows([...authorization]);
    if (webhooks) {
      setWebhooksPayload({ ...webhooks });
    }
    if (singleSignOn) {
      setSingleSignOn(singleSignOn);
    }
  }, [authorization, webhooks, singleSignOn]);

  const addRows = () => {
    const item = {
      name: "",
      applicationRoleName: "",
      description: "",
    };
    setRows([...rows, item]);
  };
  const removeRows = (index) => {
    if (rows.length != 1) {
      var filtered = rows.filter(function (el, i) {
        console.log(index, i, index !== i);
        return index !== i;
      });
      console.log({ filtered });
      setRows([...filtered]);
    }
  };

  const onChange = (event, index) => {
    let payload = rows.filter(function (el, i) {
      console.log(index, i, index !== i);
      return index == i;
    })[0];
    let newpayload = { ...payload };
    newpayload[event.currentTarget.name] = event.currentTarget.value;
    console.log(newpayload);
    let data = [...rows];
    data[index] = newpayload;
    setRows([...data]);
  };
  const handleChangeRole = (event) => {
    let rolesObject = rows;
    console.log(rolesObject);
  };

  const saveWebhooks = async (e) => {
    try {
      const res = await ApplicationAPIs.editBasicDetails(appId, {
        authorizationWebhook: webhooksPayload,
        singleSignOn: single_sign_on,
      });
      enqueueSnackbar(
        res.data._msg || "Application details saved successfully",
        { variant: "success", autoHideDuration: 3000 }
      );
      setEdit(false);
    } catch (err) {
      enqueueSnackbar(err.message || "Something Went wrong", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };
  console.log(
    "hhhhhhh",
    // webhooksPayload,
    // props.data,
    single_sign_on,
    singleSignOn
    // authorization
  );

  //const onSubmitAuthRoles = () => {};

  return (
    <div id="appliation-roles-section">
      <div className="w-100 d-flex justify-content-end">
        {authorization.length == 0 && (
          <Tooltip title="Add" aria-label="add">
            <Button
              startIcon={<AddCircleOutlineOutlinedIcon />}
              color="secondary"
              onClick={addRows}
            >
              Add Roles
            </Button>
          </Tooltip>
        )}
      </div>

      <div>
        <div>
          <Grid container spacing={1} justifyContent="space-between">
            <Grid item xs={12} sm={6} md={9} container direction="row">
              <Typography variant="body2" className="p-2">
                Single sign on :
              </Typography>
              <RadioGroup
                value={single_sign_on}
                onChange={(e) => setSingleSignOn(e.target.value)}
              >
                <div style={{ display: "flex" }}>
                  <FormControlLabel
                    disabled={!edit}
                    name="supported"
                    value="Supported"
                    control={<Radio color="primary" />}
                    label="Supported"
                  />{" "}
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  <FormControlLabel
                    disabled={!edit}
                    value="Not Supported"
                    control={<Radio color="primary" />}
                    label="Not Supported"
                    color="primary"
                  />
                </div>
              </RadioGroup>
            </Grid>
            <Grid
              item
              md={3}
              container
              justifyContent="flex-end"
              className="mt-2"
            >
              {!edit && (
                <>
                  {single_sign_on === "Supported" && (
                    <Grid>
                      <Grid item xs={3}>
                        <Tooltip title="Sync" aria-label="sync">
                          <Button
                            onClick={() => {
                              authorizationSync(appId);
                            }}
                          >
                            {" "}
                            <SyncIcon color="secondary" />{" "}
                          </Button>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  )}
                  <Grid conatiner className="pr-2">
                    <Grid item xs={3}>
                      <Tooltip title="Edit" aria-label="edit">
                        <Button onClick={() => setEdit(true)}>
                          {" "}
                          <EditOutlinedIcon />
                        </Button>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  {/* <Grid item xs={3}>
                      <Tooltip title="Delete" aria-label="delete">
                        <Button > <DeleteOutlineIcon /> </Button>
                      </Tooltip>
                    </Grid> */}
                </>
              )}
              {edit && (
                <>
                  <Grid item xs={4}></Grid>
                  <Grid item xs={4}>
                    <Button
                      color="secondary"
                      style={{
                        marginLeft: 0,
                        backgroundColor: "transparent",
                        boxShadow: "none",
                        color: "#9e9e9e",
                      }}
                      onClick={async () => {
                        saveWebhooks();
                      }}
                    >
                      Save
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      style={{
                        marginLeft: 0,
                        backgroundColor: "transparent",
                        boxShadow: "none",
                        color: "#9e9e9e",
                      }}
                      color="primary"
                      onClick={() => {
                        setSingleSignOn(singleSignOn);
                        setEdit(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{ padding: "15px" }}>
            {single_sign_on === "Supported" && (
              <>
                <Grid item xs={6} sm={4}>
                  <Typography
                    variant="subtitle2"
                    style={{ marginBottom: "5px" }}
                  >
                    {" "}
                    URL{" "}
                  </Typography>
                  <TextField
                    name="authUrl"
                    disabled={!edit}
                    className={classes.btn}
                    size="small"
                    variant="outlined"
                    fullWidth
                    id="authUrl"
                    value={webhooksPayload.url}
                    onChange={(e) => {
                      setWebhooksPayload({
                        ...webhooksPayload,
                        url: e.target.value,
                      });
                    }}
                    defaultValue={(webhooks || {}).url}
                    // onChange={(e) => handleChangeRole(e)}
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <Typography
                    variant="subtitle2"
                    style={{ marginBottom: "5px" }}
                  >
                    {" "}
                    Method{" "}
                  </Typography>

                  {/* <TextField
                className={classes.btn}
                disabled={!edit}
                size="small"
                name="authMethod"
                variant="outlined"
                fullWidth
                id="method"
                // onChange={(e) => handleChangeRole(e)}
                value={webhooksPayload.method}
                onChange={(e) => {
                  setWebhooksPayload({
                    ...webhooksPayload,
                    method: e.target.value,
                  });
                }}
                defaultValue={(webhooks || {}).method}
              /> */}

                  <FormControl
                    fullWidth
                    disabled={!edit}
                    size="small"
                    variant="outlined"
                    className={classes.btn}
                    value={webhooksPayload.method}
                    name="authMethod"
                  >
                    <Select
                      name="authMethod"
                      native
                      value={webhooksPayload.method}
                      onChange={(e) => {
                        setWebhooksPayload({
                          ...webhooksPayload,
                          method: e.target.value,
                        });
                      }}
                    >
                      <option value="">-Select-</option>
                      <option value="GET">GET</option>
                      <option value="POST">POST</option>
                      <option value="PUT">PUT</option>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={4}>
                  <Typography
                    variant="subtitle2"
                    style={{ marginBottom: "5px" }}
                  >
                    {" "}
                    API Key (Security){" "}
                  </Typography>
                  <TextField
                    className={classes.btn}
                    disabled={!edit}
                    size="small"
                    variant="outlined"
                    fullWidth
                    id="apikey"
                    // onChange={(e) => handleChangeRole(e)}
                    name="authApiKey"
                    value={webhooksPayload.apiKey}
                    onChange={(e) => {
                      setWebhooksPayload({
                        ...webhooksPayload,
                        apiKey: e.target.value,
                      });
                    }}
                    defaultValue={(webhooks || {}).apiKey}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={6} sm={3} style={{ marginBottom: "10px" }}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-end"
                style={{ height: "100%" }}
              ></Grid>
            </Grid>
          </Grid>
        </div>
        <br />
        {single_sign_on === "Supported" && (
          <>
            <Typography>Roles</Typography>
            <form>
              <TableContainer style={{ marginTop: "10px", borderRadius: "0" }}>
                <Table className={classes.table} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="left">
                        {" "}
                        Role name <span style={{ color: "red" }}>*</span>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {" "}
                        Application role name{" "}
                        <span style={{ color: "red" }}>*</span>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {" "}
                        Description{" "}
                      </StyledTableCell>
                      <StyledTableCell align="center" width="15%">
                        {" "}
                        Actions
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {rows.map((row, index) => (
                      <StyledTableRow key={index}>
                        <CustomTableRow
                          row={row}
                          length={rows.length}
                          appId={appId}
                          onChange={onChange}
                          removeRows={removeRows}
                          index={index}
                          addRoles={props.addRoles}
                          editRoles={props.editRoles}
                          deleteRoles={props.deleteRoles}
                          addNewRow={
                            index == rows.length - 1 && (
                              <Tooltip title="Add" aria-label="add">
                                <AddCircleOutlineOutlinedIcon
                                  onClick={addRows}
                                  color="secondary"
                                />
                              </Tooltip>
                            )
                          }
                        />
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </form>
          </>
        )}
      </div>
    </div>
  );
}

export default withSection(AuthorizationObjects, "Authorization Roles");
