import React, { useEffect, useState } from "react";
import { Typography, Grid } from "@material-ui/core";
import { Settings } from "@material-ui/icons";
import Logo from "assets/images/Kailash_new.png";
import { useSelector } from "react-redux";
const PrintSummary = (props) => {
  const organizationInfo = useSelector((state) => state.organizationReducer);
  const { organizationDetails } = organizationInfo || {
    organizationDetails: {
      logo: "",
    },
  };

  const { classes, appUsageDetails, invoiceSummary, InvoiceData } = props;
  const { CustomerDetails } = props.InvoiceData || {
    CustomerDetails: {},
  };

  const [customerData, setCustomerData] = useState({
    companyName: "",
    GTax_registration_number: "",
    phonenumber: "",
    email: "",
    address: "",
    billingDuePeriod: "",
    billingDayOfMonth: "",
  });
  // const [settingData, setSettingData] = useState({
  //   billingDayOfMonth: "",
  //   billingDuePeriod: "",
  // });
  console.log("CustomerDetails", CustomerDetails);
  // console.log("CustomerDetailss", CustomerDetails.alias);
  // console.log(
  //   "CustomerDetailsss",
  //   CustomerDetails["GST/Tax_registration_number"]
  // );

  // console.log(
  //   "CustomerDetails",
  //   CustomerDetails["GST/Tax_registration_number"]
  // );
  console.log("invoiceData", InvoiceData);

  useEffect(() => {
    if (CustomerDetails) {
      setCustomerData({
        companyName: CustomerDetails?.organization_name || "",
        GTax_registration_number:
          CustomerDetails["GST/Tax_registration_number"] || "",
        phonenumber: CustomerDetails["phonenumber"] || "",
        email: CustomerDetails["email"] || "",
        address: CustomerDetails["address"] || "",
        billingDuePeriod: CustomerDetails?.settings?.billingDuePeriod || "",
        billingDayOfMonth: CustomerDetails?.settings?.billingDayOfMonth || "",
      });
    }
  }, [CustomerDetails]);
  console.log("fdsfhgfghfahg", organizationDetails);
  return (
    <div style={{ width: "100%", marginTop: "50px" }}>
      <Grid style={{ display: "flex", justifyContent: "end" }}>
        <Grid item xs={12}>
          <div style={{ display: "flex" }}>
            <div style={{ fontWeight: "bold", fontSize: "26px" }}>
              <img
                alt="logo"
                src={organizationDetails.logo}
                style={{
                  width: "100px",
                }}
              />
            </div>
          </div>
          <div style={{ fontWeight: "bold", fontSize: "26px" }}>
            Company Name:{customerData.companyName}
          </div>
          <div>Address: {customerData.address}</div>
          {/* <div>City,Pincode</div> */}
          <div>Phone No: {customerData.phonenumber}</div>
          <div>TIN: {customerData.GTax_registration_number} </div>
          <div>Email: {customerData.email}</div>
          <br />
        </Grid>
        <Grid item xs={12} style={{ textAlign: "right" }}>
          <div style={{ fontWeight: "bold", fontSize: "26px" }}>Invoice</div>
          <div>Invoice Date:{customerData.billingDayOfMonth}</div>
          <div>Invoice No:{InvoiceData.ID}</div>
          <div>Due Date:{customerData.billingDuePeriod}</div>

          <div>Country:{}</div>
        </Grid>
      </Grid>
      {/* <Grid style={{ display: "flex" }}>
        <Grid item xs={12}>
          <div style={{ fontWeight: "bold", fontSize: "26px" }}>Bill To</div>
          <div>Street Address</div>
          <div>City,Pincode</div>
          <div>Phneno</div>
          <div>TIN</div>
          <div>Email</div>
        </Grid>
        <Grid item xs={12}>
          <div style={{ fontWeight: "bold", fontSize: "26px" }}>Ship To</div>
          <div>Street Address</div>
          <div>City,Pincode</div>
          <div>Phneno</div>
          <div>TIN</div>
          <div>Email</div>
        </Grid>
      </Grid> */}

      {/* <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography className={classes.heading} variant="h5">
          Total
        </Typography>
        <Typography className={classes.totalvalue}>{`${parseInt(
          InvoiceData.TotalInvoiceAmount || 0
        ).toFixed(2)}`}</Typography>
      </div> */}

      {/* <div
            style={{ padding: "2px", borderBottom: "1px", color: "black" }}
          /> */}
      <div>
        {/* <Typography variant="h5" className={classes.summary}>
          Invoice Summary
        </Typography> */}
        {/* <Typography variant="body2" style={{ fontWeight: 800, color: "black" }}>
          {"Invoice Summary"}
        </Typography> */}

        {/* {Object.keys(appUsageDetails).map((row) => {
          const { appDetails, billableService } = appUsageDetails[row] || {};
          return (
            <div style={{ width: "100%" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="h4"
                  style={{
                    fontWeight: "bold",
                    textTransform: "capitalize",
                  }}
                >
                  {appDetails.name}
                </Typography>
              </div>
              <div style={{ width: "100%" }}>
                <div>
                  {billableService.map((row) => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "row",
                      }}
                    >
                      <div style={{ marginLeft: "2%" }}>
                        <Typography>{row?.BillableDetails.name}</Typography>
                      </div>
                      <div>
                        <Typography>{`${parseInt(row.GrossAmount || 0).toFixed(
                          2
                        )}`}</Typography>
                      </div>
                    </div>
                  ))}
                </div>
                <br />
              </div>
            </div>
          );
        })} */}
        {/* <div style={{ width: "100%" }}>
          <div style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 3,
              }}
            >
              <Typography variant="body2">Tax </Typography>
              <Typography>{`${parseInt(InvoiceData.TaxAmt || 0).toFixed(
                2
              )}`}</Typography>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 3,
              }}
            >
              <Typography variant="body2">Credit note </Typography>
              <Typography>{`-${parseInt(
                invoiceSummary.TotalCredit || 0
              ).toFixed(2)}`}</Typography>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="body2">Debit note </Typography>
              <Typography>{`${parseInt(invoiceSummary.TotalDebit || 0).toFixed(
                2
              )}`}</Typography>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};
export default PrintSummary;
