import { makeStyles } from "@material-ui/core";
import { Brightness1 } from "@material-ui/icons";
export default makeStyles((theme) => ({
  // Header css
  main: {
    marginTop: "4rem",

    "& .MuiContainer-root": {
      paddingLeft: 30,
      paddingRight: 30,
    },
  },
  table: {
    minWidth: 650,
    lineHeight: "inherit",
    // marginTop: theme.spacing(1),
    "& thead th": {
      fontWeight: "500",
      color: "white",
      backgroundColor: "#002855",
      padding: 12,
    },
    "& tbody td": {
      fontWeight: "300",
      // backgroundColor : 'red',
      padding: 10,
    },
    "& tbody tr:hover": {
      backgroundColor: "#F5F5F5",
      cursor: "pointer",
    },
  },
  topContent: {
    position: "fixed",
    top: "3.7rem",
    left: 0,
    padding: "0 30px",
    zIndex: 1000,
    width: "100%",
    backgroundColor: theme.palette.background.default,
  },
  topContentItems: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  topContentItem: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
  },
  topContentItem2: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "100%",
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
  },
  title: {
    font: "normal normal bold 21px/28px Nunito",
    letterSpacing: 0,
    color: "#161616",
    opacity: 1,
    margin: 0,
  },
  input: {
    width: 300,
    height: 32,
    paddingLeft: 10,
    border: "1px solid #DEDEDF",
    borderRadius: 5,
    margin: "0px 20px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",

    "&:hover::before": {
      borderBottom: "unset",
      transition: "unset",
    },

    "&::before": {
      borderBottom: "unset",
      transition: "unset",
    },

    "& input": {
      padding: "unset",
    },
  },
  search: {
    width: 300,
    height: 32,
    paddingLeft: 10,
    border: "1px solid #DEDEDF",
    borderRadius: 5,
    margin: "0px 20px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",

    "&:hover::before": {
      borderBottom: "unset",
      transition: "unset",
    },

    "&::before": {
      borderBottom: "unset",
      transition: "unset",
    },

    "& input": {
      padding: "unset",
    },
  },
  iconwrapper: {
    display: "flex",
    flexDirection: "row",
  },
  toggleicons: {
    margin: "0px 10px",
    padding: "3px 5px",
    cursor: "pointer",
    "&.toggleActive": {
      background: theme.palette.primary.main,
      borderRadius: 5,
      padding: "3px 5px",
      margin: "0 20px",

      "& img": {
        filter: "brightness(0) invert(1)",
      },
    },
  },

  // table css
  head: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  pageContent: {
    boxShadow: "none",
    position: "relative",
    top: "7rem",
    margin: "0 30px",
    background: "transparent",
  },
  myTable: {
    marginBottom: "5rem",
    boxSizing: "content-box",
    height: 450,
    overflow: "auto",
  },
  customTable: {
    "& thead": {
      backgroundColor: theme.palette.primary.main,
      border: "1px solid #E2E2E3",

      "& tr th": {
        color: "#FFFFFF",
        padding: "12px 10px",
        paddingLeft: 30,
        font: "normal normal 600 16px/22px Nunito Sans",

        "& span": {
          color: "#FFFFFF",
        },
      },
    },
    "& tbody": {
      "& tr td": {
        padding: "0px 10px",
        paddingLeft: 30,
        font: "normal normal normal 14px/19px Nunito Sans",
      },
      "& tr:hover": {
        "& td span": {
          visibility: "visible",
        },
      },
    },
  },
  // rows
  actioniconwrapper: {
    textAlign: "right",
    // border: "1px solid red",
  },
  actionIcons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    "&:hover": {
      backgroundColor: "transparent",
    },
    "& span": {
      visibility: "hidden",
    },
  },
  actionIcon: {
    fontSize: 15,
    marginRight: 20,
    color: "#535353",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  // MuiDivider: {
  //   width: "1px",
  //   height: "35px",
  //   color: "#535353",
  //   padding: 0,
  // },

  // footer
  footer: {
    position: "fixed",
    bottom: 0,
    left: 0,
    padding: 20,
    width: "100%",
    textAlign: "right",
    zIndex: 999,
    backgroundColor: "#f2f2f2",
  },
  customPagination: {
    width: "fit-content",
    marginLeft: "auto",
    "& .MuiPaginationItem-page.Mui-selected": {
      border: "0.5px solid #707070",
      margin: "0 10px",
      height: 30,
      width: 30,
      padding: "10px 13px",
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      boxShadow: "0px 3px 10px #5757571a",
      lineHeight: 1,
      borderRadius: 5,
      opacity: 1,
    },
    "& .MuiPaginationItem-page": {
      font: "normal normal normal 14px/19px Nunito Sans",
      letterSpacing: 0,
      color: "#000000",
      opacity: 0.6,
    },
  },
  // Cards
  cardWraper: {
    display: "flex",
    flexWrap: "wrap",
    backgroundColor: theme.palette.background.default,
    boxShadow: "none",
    position: "absolute",
    paddingTop: "8rem",
  },
  singlecard: {
    // margin: 10,
    // height: 171,
    padding: "1px 5px 15px",
    // width: 307,
    borderRadius: 10,
    // border: "1px solid red",
  },
  cardline1: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    color: "#535353",
    marginTop: 20,
    marginLeft: 10,
    marginRight: 10,
  },
  projecttitle: {
    marginTop: 10,
    marginLeft: 10,
    fontSize: 16,
  },
  cardDetails: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    textAlign: "center",
    border: "1px solid #D4D4D4",
    color: "#535353",
    marginTop: 20,
    height: 62,
    fontSize: 12,
    marginLeft: 10,
    marginRight: 10,
    borderRadius: 10,
  },
  cardicons: {
    color: "#535353",
  },
  MuiDivider: {
    width: "2px",
    height: "30px",
    color: "#535353",
  },
  MuiDivider2: {
    width: "3px",
    height: "30px",
  },

  // Teams
  // internalExternal: {
  //   // marginLeft : "27%",
  //   // marginRight: "5%",
  //   display: "flex",
  //   justifyContent: "space-between",
  //   alignItems: "center",
  //   width: 250,
  // },
  editIcon: {
    right: 0,
    position: "absolute",
    marginRight: 21,
  },
  // =======
  // Teams
  internalExternal: {
    // marginLeft : "27%",
    marginRight: "5%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: 300,
  },
  Filter: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #D0D0D0",
    borderRadius: 3,
    opacity: 1,
  },
  FilterBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    // width: 120,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #D0D0D0",
    borderRadius: "3px",
    opacity: 1,
    padding: "0 5px",
  },

  editActionBtn: {
    right: 0,
    position: "absolute",
  },
  // DocumentsList
  documensBtn: {
    color: "#fff",
    margin: "0 0 0 20px",
    padding: "6 20px",
    fontSize: "15px",
    width: 254,
    textTransform: "capitalize",
    backgroundColor: "#002855",
  },

  // Access Maintenance
  accessTab: {
    borderBottom: "1px solid rgba(0, 40, 85, 1)",
    marginBottom: "1.5rem",

    "& button:first-child": {
      minWidth: 100,
    },
    "& button:last-child": {
      minWidth: 300,
    },
    "& span": {
      fontSize: 14,
      textTransform: "capitalize",
    },
  },
  accessTab2: {
    borderBottom: "1px solid rgba(0, 40, 85, 1)",
    marginBottom: "0.5rem",
    textAlign: "left",
    letterSpacing: 0,
    color: "#1B1C1D",
    opacity: 1,
    "& button": {
      minWidth: 100,
      paddingRight: 20,
      // border: "1px solid",
    },
    "& span": {
      font: "normal normal normal 14px/24px Nunito !important",
      fontSize: 14,
      textTransform: "capitalize",
    },
  },
  dataStandardsAccessTab: {
    marginBottom: "1rem",
    borderRadius: "4px 0px 0px 4px",
    height: "32px",
    margin: "12px 0",
    display: "flex",
    alignItems: "center",
    minHeight: "auto !important",
    "& button": {
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      border: "1px solid #DEDFDF",
      opacity: "1",
      color: "#000000DE",
      width: "164px",
      height: "32px",
    },

    "& span": {
      font: "normal normal bold 14px/19px Nunito",
      textTransform: "capitalize",
    },
  },

  dataStandardFormControlLabel: {
    marginLeft: 0,
    padding: 0,
    display: "flex",
    flexDirection: "column-reverse",
    alignItems: "flex-start",
    "&> span": {
      font: "normal normal 600 14px/19px Nunito !important",
      textTransform: "capitalize",
    },
    "&> span:nth-child(1)": {
      marginLeft: "-12px",
    },
  },

  toggletab: {
    "&.toggleActive": {
      background: `${theme.palette.primary.main} 0% 0% no-repeat padding-box`,
      border: "1px solid #DEDFDF",
      borderRadius: "4px 0px 0px 4px",
      opacity: "1",
      width: "164px,",
      color: "#FFFFFF",
    },
  },
  toggleTabFieldGroups: {
    height: "32px",
    "&.toggleActive": {
      background: `${theme.palette.primary.main} 0% 0% no-repeat padding-box`,
      border: "1px solid #DEDFDF",
      borderRadius: "4px 0px 0px 4px",
      opacity: "1",
      width: "164px,",
      color: "#FFFFFF",
    },
  },

  documentsListAccessTab: {
    // marginBottom: "1.5rem",
    // marginLeft: "-16px",

    "& button:first-child": {
      minWidth: 100,
    },
    "& span": {
      font: "normal normal bold 14px/19px Nunito Sans",
      textTransform: "capitalize",
    },
  },
  customMenuButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: 180,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #052855",
    borderRadius: 5,
    opacity: 1,
    padding: "0 5px",
    cursor: "pointer",
    marginLeft: 20,
    marginRight: 20,
  },
  customMenuButtonPopup: {
    width: "auto",
    marginTop: 3,
    marginRight: 30,
  },
  customMenuButtonWrap: {
    padding: "10px 15px 10px",
    background: "#222222 0% 0% no-repeat padding-box",
    color: "#FFFFFF",

    // "&:after": {
    //   content: "",
    //   position: 'absolute',
    //   right: 4,
    //   top: -22,
    //   width: 0,
    //   height: 0,
    //   borderStyle: 'solid',
    //   borderWidth: '0 17px 17px 17px',
    //   borderColor: 'transparent transparent #222222 transparent',
    // },

    "& .menuItem": {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",

      "& h3": {
        font: "normal normal normal 12px/16px Nunito Sans",
        margin: 10,
      },
    },
  },

  modalHeader: {
    backgroundColor: theme.palette.background.default,
    color: "#000000",
    width: "100%",
    margin: 0,
    padding: "10px 24px",

    "& h2": {
      font: "normal normal 600 16px/22px Nunito",
    },
  },
  modalContent: {
    padding: "15px 24px 48px",
  },
  modalLabel: {
    font: "normal normal 600 14px/19px Nunito",
    color: "#333333DE",
    marginTop: 10,
  },
  // css for documents list
  documentsTabHeadings: {
    font: "normal normal bold 21px/28px Nunito Sans",
  },
  documentsType: {
    width: 196,
    height: 32,
    font: "normal normal 600 14px/19px Nunito Sans",
    backgroundColor: "#002855",
    color: "#FFFFFF",
    textTransform: "none",
  },
  documentsCreateButton: {
    backgroundColor: "#002855",
    border: "1px solid #002855",
    color: "#FFFFFF",
    fontSize: "14px",
    marginTop: 12,
    textTransform: "none",
    font: "normal normal 600 14px/19px Nunito Sans",
    width: 120,
    height: 32,
    marginRight: 120,
  },
  documentsCreatePageButton: {
    backgroundColor: "#002855",
    border: "1px solid #002855;",
    color: "#FFFFFF",
    fontSize: "14px",
    width: 85,
    height: 40,
    marginLeft: 24,
    textTransform: "none",
  },
  documentsCancelButton: {
    backgroundColor: "white",
    border: "1px solid #002855",
    color: "#002855",
    width: 85,
    height: 40,
    marginRight: 120,
    fontSize: 14,
    textTransform: "none",
    marginTop: 328,
    right: 0,
    position: "absolute",
    font: "normal normal 600 14px/19px Nunito Sans",
  },
  documentsSearchInputBox: {
    maxWidth: "350px",
    height: 37,
    paddingLeft: 10,
    border: "1px solid #DEDEDF",
    borderRadius: 5,
    margin: "0px 20px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",

    "&:hover::before": {
      borderBottom: "unset",
      transition: "unset",
    },

    "&::before": {
      borderBottom: "unset",
      transition: "unset",
    },

    "& input": {
      padding: "unset",
    },
  },
  labelStyle_active: {
    fontSize: 21,
    font: "normal normal normal 14px/19px Nunito Sans",
    color: theme.palette.primary.main,
    position: "relative",
    left: 32,
    "&::after": {
      content: '""',
      height: 2,
      width: " 141px",
      backgroundColor: theme.palette.primary.main,
      position: "absolute",
      top: "30px",
      left: 45,
    },
  },
  labelStyle_inactive: {
    fontSize: 21,
    font: "normal normal normal 14px/19px Nunito Sans",
    position: "relative",
    color: theme.palette.primary.main,
    "&::after": {
      content: '""',
      height: 2,
      width: " 141px",
      backgroundColor: theme.palette.primary.main,
      position: "absolute",
      top: "25px",
      left: 0,
    },
  },

  textarea: {
    width: "100%",
    height: 144,
    top: 240,
    left: 474,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: 5,
  },
  documentInput: {
    width: "100%",
    height: 37,
    // backgroundColor: "#fff",
    marginLeft: 20,
    paddingLeft: 10,
    border: "1px solid #DEDEDF",
    borderRadius: 5,
    margin: "0px 20px",
    // background: "#FFFFFF 0% 0% no-repeat padding-box",
  },
  createDocumentsBtnGroup: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  createDocBackImg: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "50%",
    marginRight: "20px",
    cursor: "pointer",
  },
  documentPageCancelButton: {
    backgroundColor: "white",
    border: "1px solid #002855;",
    color: "#002855",
    fontSize: "14px",
    width: 85,
    height: 40,
    marginLeft: 24,
    textTransform: "none",
    font: "normal normal 600 14px/19px Nunito Sans",
  },
  headings: {
    font: "normal normal 600 12px/16px Nunito",
    fontSize: 16,
    letterSpacing: 0,
    color: "#000000",
    width: "100%",
  },
  createDocumentsCard: {
    top: 71,
    position: "absolute",
    zIndex: 999,
    width: 1195,
    height: 601,
    marginLeft: 148,
    background: "#FFFFFF",
    opacity: 1,
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: 5,
    backdropFilter: "blur(100px)",
  },
  //Glossary
  glossaryInput: {
    width: "auto",
  },

  glossaryTopContent: {
    display: "flex",
    marginRight: "auto",
    marginTop: "25px",
  },
  glossaryTopContentBtns: {
    display: "flex",
    flexDirection: "row-reverse",
    marginLeft: "auto",
  },

  // status profile
  completedStatus: {
    display: "flex",
    "& span": {
      height: 16,
      width: 16,
      borderRadius: "50%",
      display: "inline-block",
      border: "1px solid #bbb",
      marginLeft: 12,
      marginTop: 19,
      // backgroundColor: "#21BA45",
    },
    "& p": {
      fontSize: 16,
      marginLeft: 8,
      color: "#21BA45",
      font: "normal normal 600 16px/22px Nunito",
    },
  },
  statusModal: {
    marginTop: 15,
    "& .MuiDialog-paperWidthSm": {
      borderRadius: 10,
    },
    "& hr": {
      width: "100%",
      height: "1px",
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      opacity: 0.3,
      color: "#002855",
    },
  },
  statusDialogContent: {
    "& .MuiPaper-outlined": {
      marginTop: "18px",
    },
  },
  statusDialogBoxSize: {
    height: 500,
    width: "auto",
    marginTop: 12,
  },
  statusModalHeader: {
    fontSize: "16px",
    font: "normal normal 600 16px/22px Nunito",
    marginLeft: 27,
    marginTop: 17,
  },
  statusModalCloseButton: {
    width: 100,
    height: 40,
    backgroundColor: theme.palette.primary.main,
    color: "#FFFFFF",
    marginLeft: 409,
    marginBottom: 12,
    textTransform: "capitalize",
    font: "normal normal bold 14px/19px Nunito",
    "&:hover": {
      transition: "unset",
      backgroundColor: theme.palette.primary.main,
    },
  },
  actionIconStatusProfile: {
    fontSize: 15,
    marginRight: 20,
    color: "#002855",
    font: "normal normal 600 14px/19px Nunito Sans",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  statusProfileDetails: {
    "&:hover": {
      backgroundColor: "#f2f2f2",
    },
  },
  errorMsg: {
    color: "#DF0E0E",
    font: "normal normal 600 12px/16px Nunito;",
    margin: 0,
    paddingTop: "8px",
  },
  error: {
    border: "1px solid #E22626 !important",
  },

  //  documentsImporticon
  // importIconColor: {
  //   filter: " Brightness(0.5)",
  // },
  iconHover: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  topContent: {
    position: "fixed",
    // top: "20%",
    left: 0,
    padding: "0 30px 0 30px",
    zIndex: 1000,
    width: "100%",
    backgroundColor: theme.palette.background.default,
    overflow: "hidden",
  },
  rootPaper: {
    width: "100%",
    marginTop: "6em",
  },
  container: {
    maxHeight: 440,
  },
}));
