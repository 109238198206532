import {
  ADD_ORGANIZATION_DATA,
  ORGANIZATIONS_FILTER,
  ORGANIZATIONS_SORT,
  ACTIVE_INACTIVE_ORG,
  ADD_CURRENT_ORGANIZATION_DATA,
  CHANGE_LOGO,
} from "./actionTypes";

const initialState = {
  // org_list: {
  //   data: [],
  //   error: false,
  //   loading: false,
  // },
  //   organizationFilter: [{ id: uuidv4(), column: "", operator: "", value: "" }],
  //   organizationSort: [{ columnName: "createdon", direction: "desc" }],
  // currentOrgData: {
  //   title: "",
  // },
  currentOrgData: {
    title: "",
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    // case ADD_ORGANIZATION_DATA: {
    //   return {
    //     ...state,
    //     org_list: {
    //       ...state.org_list,
    //       ...action.payload,
    //     },
    //   };
    // }

    // case ACTIVE_INACTIVE_ORG: {
    //   console.log("payload", action.payload);
    //   return {
    //     ...state,
    //     org_list: {
    //       ...state.org_list,
    //       data:
    //         state.org_list.data &&
    //         state.org_list.data.map((item) => {
    //           if (item._id === action.payload.orgID) {
    //             return {
    //               ...item,
    //               status: action.payload.status,
    //             };
    //           } else {
    //             return item;
    //           }
    //         }),
    //     },
    //   };
    // }
    // case ADD_CURRENT_ORGANIZATION_DATA: {
    //   return {
    //     ...state,
    //     currentOrgData: { ...action.payload },
    //   };
    // }
    case CHANGE_LOGO: {
      return {
        ...state,
        currentOrgData: {
          data: {
            ...state.currentOrgData.data,
            logo: action.logo,
            favicon: action.favicon,
            title: action.title,
          },
        },
      };
    }
    default:
      return state;
  }
};
