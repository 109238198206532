import { Grid, TextField } from "@material-ui/core";
import React from "react";
import { withStyles } from "@material-ui/styles";
import Styles from "./styles";
import PropTypes from "prop-types";
import Breadcrumb from "components/Breadcrumbs/Breadcrumb";
import CustomerList from "./CustomerList";
import { connect } from "react-redux";
import { fetchCustomerList } from "./redux/action";
import { withSnackbar } from "notistack";
import { withRouter } from "react-router";
import APIs from "api/CustomerManagementAPI";
import dataOptions from "./filterOptions.json";
import HelpersFunc from "helpers/helpers";
import { disableEdit } from "../CustomerProfile/redux/action";
import actions from "./redux/action";
import { checkPermission } from "helpers/permissons";
import HelpdeskFAB from "../../views/HelpdeskFAB/HelpdeskFab";

class CustomerManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      size: 10,
      page: 1,
      filters: {},
      searchkey: "",
      data_options: [...dataOptions],
      sort: { createdOn: -1 },
      sorting: [{ columnName: "createdon", direction: "desc" }],
    };
  }

  async componentDidMount() {
    this.props.disablePropsEdit(true);
    let { filterProps } = this.props;
    await this.props.getCustomerList(
      this.state.page,
      this.state.size,
      JSON.stringify(filterProps.filters),
      JSON.stringify(filterProps.sort)
    );
  }

  handleSortByChange = (sorting) => {
    let { filterProps, updateSorting, getCustomerList } = this.props;
    let filters = { ...filterProps.filters };
    let sort = {};
    sorting.forEach((f) => {
      sort[f.columnName] = f.direction == "asc" ? 1 : -1;
    });
    updateSorting({ sorting, sort });
    getCustomerList(
      this.state.page,
      this.state.size,
      JSON.stringify(filters),
      JSON.stringify(sort)
    );
  };
  filterFunc = (arr) => {
    let filters = {};
    if (arr.length === 1) {
      let { operator, value, type } = arr[0];
      let filter = HelpersFunc.findAndExecuteAjax(type)(operator, value);
      this.getFilteredData({ ...filter }, arr);
    } else {
      arr.map((item, id) => {
        const { operator, value, type } = item;
        let filter = HelpersFunc.findAndExecuteAjax(type)(operator, value);
        filters = { ...filters, ...filter };
        return 0;
      });
      this.getFilteredData({ ...filters }, arr);
    }
  };

  getFilteredData = async (filters, arr, search) => {
    let page = 1;
    this.setState({
      page: 1,
    });
    let { filterProps, updateFilter } = this.props;
    updateFilter({ filter: filters, filterArray: arr });
    this.props.getCustomerList(
      page,
      this.state.size,
      JSON.stringify(filters),
      JSON.stringify(filterProps.sort)
    );
    if (!search) {
      this.setState({
        searchkey: "",
      });
    }
  };
  displayMsg = (type, msg) => {
    this.props.enqueueSnackbar(msg, {
      variant: type ? "success" : "error",
      autoHideDuration: 3000,
    });
  };
  blockOrUnblock_Customer = async (subID, type) => {
    try {
      let res = await APIs.blockorUnblockSubscriber(subID, type);
      let { filterProps } = this.props;
      // await this.props.getCustomerList(
      //   this.state.page,
      //   this.state.size,
      //   JSON.stringify(filterProps.filters),
      //   JSON.stringify(filterProps.sort)
      // );

      this.fetchingData(this.state.page);
      const { _msg, _status } = res.data;
      this.displayMsg(true, _msg);

      // return res;
    } catch (err) {
      const { _msg } = err?.response?.data || { _msg: "Network issue!" };
      this.displayMsg(false, _msg);

      // throw err;
    }
    this.fetchingData(this.state.page);
  };
  handleLimitChangePageRow = async (size) => {
    await this.setState({
      size: size,
      page: 0,
    });
    let { filterProps } = this.props;
    this.props.getCustomerList(
      this.state.page + 1,
      size,
      JSON.stringify(filterProps.filters),
      JSON.stringify(filterProps.sort)
    );
  };

  searchAndPaginationHelperFunc = (page, val, filter_Props) => {
    this.props.getCustomerList(
      page,
      this.state.size,
      JSON.stringify({
        ...filter_Props.filters,
        name: { $regex: "^" + val, $options: "i" },
      }),
      JSON.stringify(filter_Props.sort)
    );
  };

  onChangeSearchAppName = (event) => {
    let page = 1;
    this.setState({
      searchkey: event.target.value,
      page: page,
    });
    this.searchAndPaginationHelperFunc(
      page,
      event.target.value,
      this.props.filterProps
    );
  };

  handlePageChange = (e, newPage) => {
    this.setState({
      page: newPage,
    });

    this.fetchingData(newPage);
  };
  fetchingData = async (page) => {
    let { filterProps } = this.props;
    if (this.state.searchkey.trim().length !== 0) {
      //when search is enabled while user click pagination we need this function!
      this.searchAndPaginationHelperFunc(
        page,
        this.state.searchkey,
        filterProps
      );
    } else {
      this.props.getCustomerList(
        page,
        this.state.size,
        JSON.stringify(filterProps.filters),
        JSON.stringify(filterProps.sort)
      );
    }
  };

  render() {
    const { props } = this;
    const { data_options, size, page, filter } = this.state;
    return (
      <>
        <div>
          <Grid container>
            <Grid item xs={12}>
              <Breadcrumb
                {...props}
                links={[
                  {
                    name: "Customer Management",
                    url: "/app/customer-management",
                  },
                  {
                    name: "Customer List",
                  },
                ]}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomerList
                size={size}
                page={page}
                sorting={this.props.filterProps.sorting}
                searchkey={this.state.searchkey}
                handleSortByChange={this.handleSortByChange}
                data_options={data_options}
                blockOrUnblockCustomer={this.blockOrUnblock_Customer}
                filter={filter}
                handlePageChange={this.handlePageChange}
                handleLimitChangePageRow={this.handleLimitChangePageRow}
                onChangeSearchAppName={this.onChangeSearchAppName}
                filterFunc={this.filterFunc}
                getDataByFilter={this.getFilteredData}
                {...props}
              />
            </Grid>
          </Grid>
          <HelpdeskFAB category="Customers" />
        </div>
      </>
    );
  }
}
CustomerManagement.propTypes = {
  classes: PropTypes.object.isRequired,
};
const mapDispatchToProps = (dispatch) => ({
  getCustomerList: (page, size, filters, sort) =>
    dispatch(fetchCustomerList(page, size, filters, sort)),
  disablePropsEdit: (type) => dispatch(disableEdit(type)),
  updateFilter: (filter) => dispatch(actions.updateFilterData(filter)),
  updateSorting: (sortProps) => dispatch(actions.updateSort(sortProps)),
});
const mapStateToProps = (state) => {
  return {
    subscriberData: state.customerManagement.subscribers,
    totalCount: state.customerManagement.totalCount,
    filterProps: state.customerManagement.filterProps,
  };
};
const CustomerManagementForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerManagement);

export default withRouter(
  withStyles(Styles)(withSnackbar(CustomerManagementForm))
);
