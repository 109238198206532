import jwt_decode from "jwt-decode";
import BtnPermission from "./buttonPermission.json";
// const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
// const { accessToken } = userInfo || {};

const passResourceAndPermission = (permission, resource) => {
  const token = JSON.parse(sessionStorage.getItem("token"));

  const { accessToken } = token || {};
  let userPermissions = null;

  if (accessToken) {
    userPermissions = jwt_decode(accessToken);
  }
  let flag = false;

  const resourceObj = userPermissions?.actions
    ? userPermissions.actions[resource]
    : undefined || undefined;
  if (resourceObj) {
    flag = resourceObj.permissions.some((r) => permission.indexOf(r) >= 0);
  }
  return flag;
};

export const checkPermission = (Id) => {
  const { resource, permission } = BtnPermission[Id];
  return passResourceAndPermission(permission, resource);
};

export const hasPermission = (permission, resource) => {
  if (permission === null && resource === null) {
    return true;
  } else {
    return passResourceAndPermission(permission, resource);
  }
};
