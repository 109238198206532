import React, { Component } from "react";
import { Typography, Grid, Button, Divider } from "@material-ui/core/";
import Breadcrumb from "components/Breadcrumbs/Breadcrumb";
// import actions from "./redux/action";
import addValueMap from "./redux/action";
import editValueMap from "./redux/action";
import APIs from "../../api/DataStandardsAPIs/index";
import { connect } from "react-redux";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { withSnackbar } from "notistack";
// import { addValueMap, editValueMap } from "./redux/action";
import Styles from "./Styles.js";
import { withStyles } from "@material-ui/styles";
import { checkPermission } from "../../helpers/permissons";
import { RiEditLine } from "react-icons/ri";
import APIS from "api/ValueMapAPI/index";

class CreateDataStandards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ID: localStorage.getItem("valueMap"),
      error: false,
      isEdit: false,
      //   ajax: false,
      //   err_msg: "",
      //   index: 0,
      ValueMapData: {
        value: "",
        description: "",
        fieldName: "",
      },
      isDisabled: false,
      dataStandards: {},
      dataStandardId: 0,
    };
  }

  addCduFieldValue = async (name, payload, type) => {
    try {
      let data = await APIS.addValueMap(name, payload);
      console.log("_msggggggggggggggggggggggggggggggggggggg", data);
      this.props.enqueueSnackbar(data.data._msg, {
        variant: "success",
        autoHideDuration: 2000,
      });
      if (type) {
        this.setState({
          loading: false,
        });
        this.pushBackToPage();
      } else {
        this.setState({
          ValueMapData: {
            ...this.state.ValueMapData,
            value: "",
            description: "",
          },
          autoCompleteVal: null,
          isEdit: false,
          loading: false,
        });
      }
    } catch (err) {
      console.log("34567sdfgh", err);
      const { _msg } = err?.response?.data || { _msg: "Network issue!" };
      this.props.enqueueSnackbar(_msg, {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  };

  addValueMapData = async (type) => {
    let { ValueMapData } = this.state;
    const { fieldName, ...data } = ValueMapData;
    await this.addCduFieldValue(fieldName, data, type);
  };

  pushBackToPage = () => {
    this.props.history.push("/app/create-datastandards", {
      data_Standards: { ...this.state.dataStandards },
      edit: true,
      disabled: false,
    });
  };

  editData = async () => {
    const { _id, fieldName, ...data } = this.state.ValueMapData;
    try {
      const res = await APIS.editValueMap(fieldName, data, _id);
      this.props.enqueueSnackbar(
        res.data._msg || "Field details updated successfully",
        {
          variant: "success",
          autoHideDuration: 2000,
        }
      );
      this.pushBackToPage();
    } catch (err) {
      this.props.enqueueSnackbar(err.response.data._msg || "Network issue!", {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  };

  multiAdd = async () => {
    let { isEdit } = this.state;
    if (isEdit) {
      await this.editData();
    } else {
      this.addValueMapData(false);
    }
  };

  onSubmitData = (e) => {
    console.log(e.nativeEvent.submitter.name, "ersdtyutretyu");
    if (e.nativeEvent.submitter.name === "multiple") {
      this.setState({
        loading: true,
      });
      this.multiAdd();
    }
    if (e.nativeEvent.submitter.name === "single") {
      this.addValueMapData(true);
    }
  };

  onChange = (value) => {
    this.setState({
      ValueMapData: { ...value },
    });
  };

  async componentDidMount() {
    const { location } = this.props;
    console.log(location.state, "lkjhgfghjk");
    if (location.state !== undefined) {
      let { edit, disabled, ValueMap_Data, dataStandards } = location.state;
      console.log(dataStandards, "345678dfgh");
      this.setState({
        ValueMapData: {
          ...this.state.ValueMapData,
          ...ValueMap_Data,
        },
        isEdit: edit,
        // checkEdit: {
        //   value: ValueMap_Data.value,
        //   description: ValueMap_Data.description,
        // },
        isDisabled: disabled,
        dataStandards: dataStandards,
      });
    }
  }

  render() {
    const { ValueMapData, isEdit, strval, intval, isDisabled } = this.state;
    const { classes, loading, dataStandards } = this.props;
    console.log(ValueMapData.fieldName, "-------------team");

    return (
      <div>
        <ValidatorForm
          ref="form"
          autoComplete="off"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              return;
            }
          }}
          onSubmit={(e) => {
            e.preventDefault();
            this.onSubmitData(e);
          }}
        >
          <Grid container>
            <Grid item sm={12} md={12} xs={12} lg={12}>
              <Breadcrumb
                {...this.props}
                links={[
                  { name: "Active Metadata", url: "/app/data_standards" },
                  {
                    name: `${ValueMapData.fieldName}`,
                    url: "/app/create-datastandards",
                    routeParams: {
                      data_Standards: { ...this.state.dataStandards },
                      edit: true,
                      disabled: true,
                    },
                  },
                  {
                    name: "Create Value",
                    url: "",
                  },
                ]}
              />
            </Grid>

            <Grid item sm={6} md={6} lg={6} xs={12}>
              <Typography variant="h6" style={{ fontWeight: "bold" }}>
                {isEdit ? ValueMapData.fieldName : "Create Value Map"}
              </Typography>
            </Grid>
            <Grid
              item
              sm={6}
              md={6}
              container
              justifyContent="flex-end"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                {!isDisabled && (
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={() => {
                      this.pushBackToPage();
                    }}
                  >
                    Cancel
                  </Button>
                )}
              </Grid>
              <Grid item>
                {!isDisabled && (
                  <Button
                    name="multiple"
                    color={isEdit ? "" : "primary"}
                    size="small"
                    variant={isEdit ? "contained" : "outlined"}
                    type="submit"
                    disabled={
                      isEdit
                        ? !checkPermission("btn66")
                        : !checkPermission("btn68")
                    }
                  >
                    {isEdit ? "Save" : "Create and Add Another"}
                  </Button>
                )}
              </Grid>
              <Grid item>
                {!isEdit && (
                  <Button
                    name="single"
                    size="small"
                    variant={"contained"}
                    type="submit"
                    disabled={!checkPermission("btn68")}
                  >
                    {"Create"}
                  </Button>
                )}
              </Grid>
              <Grid item>
                {isDisabled && (
                  <Button
                    size="small"
                    variant="contained"
                    // onClick={() =>
                    //   this.setState({
                    //     isDisabled: false,
                    //   })
                    // }
                    disabled={!checkPermission("btn66")}
                    startIcon={<RiEditLine style={{ fontSize: "20px" }} />}
                  >
                    Edit
                  </Button>
                )}
              </Grid>
            </Grid>
            <Grid item md={12}>
              <Divider className={classes.divider} />
            </Grid>
            <Grid item md={12} style={{ marginTop: "22px" }}>
              <Grid container>
                <Grid item container direction="row" spacing={2} sm={7} md={7}>
                  <Grid item xs={12} sm={4} md={4}>
                    <Typography style={{ marginBottom: "2px" }} variant="body2">
                      Value <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextValidator
                      disabled={isDisabled}
                      size="small"
                      variant="outlined"
                      fullWidth
                      name="name"
                      value={ValueMapData.value}
                      onChange={(e) =>
                        this.onChange({
                          ...ValueMapData,
                          value: e.target.value.trimStart(),
                        })
                      }
                      validators={["required"]}
                      errorMessages={["Value is required"]}
                      // helperText={<div style={{ height: "12px" }}></div>}
                    />
                  </Grid>
                </Grid>
                <Grid item md={2} xs={0} sm={0}></Grid>
                <Grid item xs={12} sm={5} md={3}>
                  <Typography style={{ marginBottom: "2px" }} variant="body2">
                    Description
                  </Typography>
                  <TextValidator
                    autoComplete="off"
                    disabled={isDisabled}
                    multiline
                    rows={7}
                    size="small"
                    variant="outlined"
                    maxlength="300"
                    fullWidth
                    id="Description"
                    value={ValueMapData.description}
                    onChange={(e) =>
                      this.onChange({
                        ...ValueMapData,
                        description: e.target.value,
                      })
                    }
                    name="Description"
                    validators={[
                      // "required",
                      "minStringLength:0",
                      "maxStringLength:180",
                    ]}
                    errorMessages={[
                      // "Field Description is required",
                      "Invalid input",

                      "Not exceed more than 180 character",
                    ]}
                    helperText={<div style={{ height: "12px" }}></div>}
                  />
                  {/* <TextValidator
                    autoComplete="off"
                    disabled={isDisabled}
                    multiline
                    rows={7}
                    size="small"
                    variant="outlined"
                    fullWidth
                    id="Description"
                    value={ValueMapData.description}
                    onChange={(e) =>
                      this.onChange({
                        ...ValueMapData,
                        description: e.target.value,
                      })
                    }
                    name="Description"
                   
                  /> */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ValidatorForm>
      </div>
    );
  }
}

// CreateDataStandards.propTypes = {
//   classes: PropTypes.object.isRequired,
// };

const mapStateToProps = (state) => {
  return {
    adddata: state.valueMap.adddata,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //   getUsers: () => dispatch(getAllUsers()),
    //   AddDatastandards: (payload) => dispatch(AddDatastandards(payload)),
    // getData: (pageno, size, filters, sort) =>
    //   dispatch(actions.fetchAllValueMapData(pageno, size, filters, sort)),

    addValueMap: (payload) => dispatch(addValueMap(payload)),
    // editValueMap: (valueMapId, payload) =>
    //   dispatch(editValueMap(valueMapId, payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(Styles)(withSnackbar(CreateDataStandards)));
