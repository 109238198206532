import { React, useState, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import {
  Grid,
  Button,
  TextField,
  Typography,
  Select,
  FormControl,
  Tooltip,
  IconButton,
  FormHelperText,
} from "@material-ui/core";
import SyncIcon from "@material-ui/icons/Sync";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import ApplicationAPIs from "../../../api/ApplicationAPIs/index";
import { useSnackbar } from "notistack";
import withSection from "components/Section/Section";
import DexTable from "components/DexTable/DexTable";
import { RiEditLine, RiDeleteBin5Line } from "react-icons/ri";
import AddIcon from "@material-ui/icons/Add";
import WrappedDexTable from "components/DexTable/WrappedDexTable";
import { checkPermission } from "helpers/permissons";
import { confirmDialog } from "components/DeleteConfirmationDialog/Index";
import { dltMsg } from "helpers/helpers";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import { ApplicationDetailsUpadateHiddenCol } from "../ApplicationDetails/Redux/action";
const styles = (length) => {
  let obj = {
    fontSize: "20px",
    color: length <= 1 ? "#bdbdbd" : "",
    cursor: length <= 1 ? "text" : "pointer",
  };
  return obj;
};

const useStyles = makeStyles((theme) => ({
  line: {
    marginTop: -23,
  },

  dividerline: {
    // border: "1px solid black",
    width: "70%",
    margin: "-25px 0px 0px 110px",
    [theme.breakpoints.up("md")]: {
      width: "82%",
      margin: "-25px 0px 0px 260px",
    },
  },
}));

const getBillableServiceOptions = (billableServices, selectedServices) => {
  return (billableServices || [])
    .filter((b) => {
      let s = (selectedServices || []).find((f) => f.id == b._id);
      return s == undefined || s.length == 0;
    })
    .map((item) => {
      return { value: item.name, key: item._id };
    });
};

const getBillingName = (billId, billableServices) => {
  let billservice = billableServices.filter((f) => {
    return (f._id = billId);
  });
  return billservice.length > 0 ? billableServices[0].name : "";
};

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,

    color: theme.palette.common.white,
  },
  body: {
    height: 1,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({}))(TableRow);

const CustomTableRow = (props) => {
  const {
    row,
    appId,
    index,
    // onChange,
    removeRows,
    addNewRow,
    billableServices,
    deleteBillableService,
    editBillableService,
    addBillableService,
    appName,
  } = props;

  console.log({ row, addNewRow });
  const classes = useStyles();
  const [edit, setEdit] = useState(row._id == undefined || false);
  const [payload, setPayload] = useState(row);
  const onChange = (e) => {
    let newpayload = { ...payload };
    newpayload[e.currentTarget.name] = e.currentTarget.value;
    console.log(newpayload);
    setPayload({ ...newpayload });
  };

  console.log(payload.datakey, payload, "payload");
  return (
    <TableRow>
      <StyledTableCell component="th" scope="row">
        <FormControl
          disabled={edit ? false : true}
          label="Billable Service"
          fullWidth
          size="small"
          variant="outlined"
          className={classes.formControl}
        >
          <Select
            native
            onChange={(e) => onChange(e, index)}
            value={payload.id}
            name="id"
          >
            <option value="">-Select-</option>
            {getBillableServiceOptions(billableServices).map((item) => (
              <option value={item.key}>{item.value}</option>
            ))}
          </Select>
        </FormControl>
      </StyledTableCell>
      <StyledTableCell align="left">
        <TextField
          disabled={edit ? false : true}
          required
          className={classes.btn}
          size="small"
          onChange={(e) => onChange(e, index)}
          variant="outlined"
          fullWidth
          value={payload.description}
          name="description"
        />
      </StyledTableCell>
      <StyledTableCell align="left">
        <TextField
          disabled={edit ? false : true}
          required
          className={classes.btn}
          size="small"
          onChange={(e) => onChange(e, index)}
          variant="outlined"
          fullWidth
          name="dataKey"
          value={payload.dataKey}
        />
      </StyledTableCell>
      <StyledTableCell align="left">
        {edit && (
          <>
            <Button
              disabled={payload.id == ""}
              variant="contained"
              color="secondary"
              style={{
                marginLeft: "30px",
                backgroundColor: "transparent",
                boxShadow: "none",
                color: "#9e9e9e",
              }}
              onClick={async () => {
                if (row._id === undefined) {
                  await addBillableService(appId, payload);
                } else {
                  await editBillableService(appId, row._id, payload);
                }
                setEdit(false);
              }}
            >
              Save
            </Button>{" "}
            <Button
              style={{
                marginLeft: 0,
                backgroundColor: "transparent",
                boxShadow: "none",
                color: "#9e9e9e",
              }}
              variant="contained"
              color="primary"
              onClick={async () => {
                if (row._id == undefined) {
                  removeRows(props.tableRow.rowId);
                } else {
                  setEdit(false);
                }
              }}
            >
              Cancel
            </Button>
          </>
        )}
        {!edit && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
            }}
          >
            <div style={{ width: "4%" }}>
              <Tooltip title="Edit" aria-label="edit">
                <IconButton color="inherit">
                  <RiEditLine
                    style={{ fontSize: "20px" }}
                    onClick={() => {
                      setEdit(true);
                    }}
                  />
                </IconButton>
              </Tooltip>
            </div>
            {/* {props.length > 1 && ( */}
            <div style={{ width: "4%" }}>
              <Tooltip title="Delete" aria-label="delete">
                <IconButton
                  color="inherit"
                  disabled={props.length > 1 ? false : true}
                >
                  <RiDeleteBin5Line
                    fontSize="20px"
                    onClick={async () => {
                      await deleteBillableService(appId, row._id);
                    }}
                  />
                </IconButton>
              </Tooltip>
            </div>
            {/* )} */}
            <div style={{ width: "4%" }}>{addNewRow}</div>
          </div>
        )}
      </StyledTableCell>
    </TableRow>
  );
};

function BillingConfigDex(props) {
  const {
    billableServices,
    billing_data,
    billingWebhook,
    deleteBillableService,
    billingUsageParams,
    addBillableService,
    editBillableService,
    billableSync,
    appId,
    appTable,
    appName,
  } = props;
  const { billingServicesHidden } = appTable;
  const { enqueueSnackbar } = useSnackbar();
  console.log({ billingWebhook });
  const [edit, setEdit] = useState(false);
  console.log(billableServices, 6786);
  let [rows, setRows] = useState(
    billingUsageParams || [
      {
        appId: appId,
        dataKey: "",
        description: "",
        name: "",
        id: "",
      },
    ]
  );
  let [billingWebhookPayload, setBillingWebhookPayload] = useState({
    url: "",
    method: "",
    apiKey: "",
  });

  const [err, setErr] = useState({
    url: false,
    method: false,
    apiKey: false,
  });

  const settingBillingWebhook = () => {
    Object.keys(billingWebhook).length === 0
      ? setBillingWebhookPayload({ url: "", method: "", apiKey: "" })
      : setBillingWebhookPayload({
          ...billingWebhook,
        });
    setEdit(false);
  };

  useEffect(() => {
    setRows(billingUsageParams || []);
    if (billingWebhook) {
      Object.keys(billingWebhook).length !== 0 &&
        setBillingWebhookPayload({ ...billingWebhook });
    }
  }, [billingUsageParams, billingWebhook]);

  const onChange = (event, index) => {
    let payload = rows.filter(function (el, i) {
      console.log(index, i, index !== i);
      return index == i;
    })[0];
    let newpayload = { ...payload };
    newpayload[event.currentTarget.name] = event.currentTarget.value;
    console.log(newpayload, "checking");
    let data = [...rows];
    data[index] = newpayload;
    setRows([...data]);
  };

  const classes = useStyles();

  const initialRows = [
    { key: 1, name: "", description: "", billingDataKey: "" },
  ];

  const addRows = (event) => {
    const item = {
      new: true,
      name: "",
      description: "",
      dataKey: "",
      id: "",
    };
    setRows([...rows, item]);
  };
  const removeRows = (index) => {
    let data = [...rows];
    if (rows.length != 1) {
      let filtered = data.filter((el, i) => {
        return i !== index;
      });
      setRows([...filtered]);
    }
  };

  const removeBillingService = async ({ row }, e) => {
    e && e.stopPropagation();
    await props.deleteBillableService(row.appId, row._id);
  };

  const pushToCreateForm = () => {
    let data = {
      name: "",
      description: "",
      dataKey: "",
    };
    checkPermission("btn212") &&
      props.history.push("/app/create-billing-form", {
        billableServices: billableServices,
        usageParamsData: data,
        edit: false,
        ajax: true,
        appID: props.appId,
        moduleData: props.modulesData,
        appName: appName,
      });
  };
  const editUsageParams = ({ row }, e, val) => {
    e && e.stopPropagation();
    console.log(row, "this is edit", 23);
    checkPermission("btn203") &&
      props.history.push("/app/create-billing-form", {
        billableServices: billableServices,
        usageParamsData: val ? val : row,
        edit: true,
        ajax: true,
        appID: props.appId,
        moduleData: props.modulesData,
        disabled: e ? false : true,
        appName: appName,
      });
  };
  const saveBillingWebhooks = async (e) => {
    const set_err = err;
    const { apiKey, method, url } = billingWebhookPayload;
    set_err.apiKey = apiKey ? apiKey.trim() === "" : true;
    set_err.method = method ? method.trim() === "" : true;
    set_err.url = url ? url.trim() === "" : true;
    setErr({ ...set_err });
    if (set_err.method || set_err.apiKey || set_err.url) {
      return;
    }
    try {
      const res = await ApplicationAPIs.editBasicDetails(appId, {
        billingWebhook: billingWebhookPayload,
      });
      enqueueSnackbar(
        res.data._msg || "Application details saved successfully",
        { variant: "success", autoHideDuration: 3000 }
      );
      props.getDetails(appId);
      setEdit(false);
    } catch (err) {
      enqueueSnackbar(err.message || "Something Went wrong", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };
  console.log(billingUsageParams, "step by step");

  const actionProvider = (row) => {
    return (
      <div
        style={{
          display: "flex",
        }}
      >
        <div style={{ marginRight: 20 }}>
          <Tooltip title="Edit" aria-label="edit">
            <IconButton
              color="inherit"
              size="small"
              disabled={!checkPermission("btn203")}
              onClick={(e) =>
                checkPermission("btn203") && editUsageParams(row, e)
              }
            >
              <RiEditLine fontSize="20px" />
            </IconButton>
          </Tooltip>
        </div>
        <div>
          {/* {rows.length > 1 && ( */}
          {/* <Tooltip title="Delete" aria-label="delete">
              <IconButton
                color="primary"
                onClick={(e) => removeBillingService(row, e)}
              >
                <RiDeleteBin5Line fontSize="22px" /> */}
          <Tooltip title={rows.length <= 1 ? "" : "Delete"} aria-label="delete">
            <IconButton
              color="inherit"
              size="small"
              disabled={!checkPermission("btn108")}
              onClick={(e) => {
                e.stopPropagation();
                rows.length > 1 &&
                  checkPermission("btn108") &&
                  confirmDialog(dltMsg(row?.row?.name || ""), () =>
                    removeBillingService(row, e)
                  );
              }}
            >
              <RiDeleteBin5Line color="inherit" style={styles(rows.length)} />
            </IconButton>
          </Tooltip>
          {/* )} */}
        </div>
      </div>
    );
  };

  const viewBillingdata = (row) => {
    editUsageParams({}, null, row);
  };
  console.log("dddddddertrdsata", billingWebhookPayload, rows);
  return (
    <div>
      <Grid container spacing={1} justifyContent="space-between">
        <Grid
          item
          xs={12}
          sm={6}
          md={8}
          container
          direction="row"
          className="pt-3"
        >
          <Typography>Setup billing webhook</Typography>
        </Grid>
        <Grid
          item
          md={4}
          container
          className="pr-3 "
          spacing={2}
          alignItems="center"
          justifyContent="flex-end"
        >
          {!edit && (
            <>
              <Grid item>
                <Tooltip title="Sync" aria-label="sync">
                  <IconButton
                    size="small"
                    // onClick={() => {
                    //   billableSync(appId);
                    // }}
                  >
                    {" "}
                    <SyncIcon
                      style={{ fontSize: "20px" }}
                      color="secondary"
                    />{" "}
                  </IconButton>
                </Tooltip>
              </Grid>

              <Grid item>
                <Tooltip title="Edit" aria-label="edit">
                  <IconButton
                    size="small"
                    color="inherit"
                    disabled={!checkPermission("btn210")}
                    onClick={() => checkPermission("btn210") && setEdit(true)}
                  >
                    {" "}
                    <RiEditLine style={{ fontSize: "20px" }} />{" "}
                  </IconButton>
                </Tooltip>
              </Grid>
            </>
          )}
          {edit && (
            <>
              <Grid item xs={4}></Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  style={{
                    marginLeft: 10,
                    backgroundColor: "transparent",
                    boxShadow: "none",
                    color: "#9e9e9e",
                  }}
                  disabled={!checkPermission("btn211")}
                  onClick={(e) => {
                    checkPermission("btn211") && saveBillingWebhooks(e);
                  }}
                >
                  Save
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Button
                  size="small"
                  style={{
                    marginLeft: 10,
                    backgroundColor: "transparent",
                    boxShadow: "none",
                    color: "#9e9e9e",
                  }}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    settingBillingWebhook();
                  }}
                >
                  Cancel
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Typography variant="body2" className="mb-1">
              URL
            </Typography>
            <TextField
              className={classes.btn}
              size="small"
              variant="outlined"
              fullWidth
              id="url"
              disabled={!edit}
              // value={(billingWebhook || {}).url}
              value={billingWebhookPayload.url}
              onChange={(e) => {
                setBillingWebhookPayload({
                  ...billingWebhookPayload,
                  url: e.target.value,
                });
              }}
              name="billingUrl"
              // defaultValue={(billingWebhook || {}).url}
              error={err.url}
              helperText={err.url ? "Url is required" : ""}
              onFocus={() =>
                setErr({
                  ...err,
                  url: false,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2" className="mb-1">
              Method
            </Typography>
            <FormControl
              fullWidth
              disabled={!edit}
              size="small"
              variant="outlined"
              className={classes.btn}
              name="billingMethod"
            >
              <Select
                name="billingMethod"
                native
                value={billingWebhookPayload.method || ""}
                onChange={(e) => {
                  setBillingWebhookPayload({
                    ...billingWebhookPayload,
                    method: e.target.value,
                  });
                }}
                error={err.method}
                onFocus={() =>
                  setErr({
                    ...err,
                    method: false,
                  })
                }
              >
                <option value="">-Select-</option>
                <option value="GET">GET</option>
                <option value="POST">POST</option>
                <option value="PUT">PUT</option>
              </Select>
              <FormHelperText style={{ color: "red" }}>
                {err.method ? "Method is required" : ""}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2" className="mb-1">
              API Key (Security)
            </Typography>
            <TextField
              className={classes.btn}
              size="small"
              variant="outlined"
              fullWidth
              id="apikey"
              disabled={!edit}
              // defaultValue={(billingWebhook || {}).apiKey}
              value={billingWebhookPayload.apiKey || ""}
              name="billingApiKey"
              onChange={(e) => {
                setBillingWebhookPayload({
                  ...billingWebhookPayload,
                  apiKey: e.target.value,
                });
              }}
              error={err.apiKey}
              onFocus={() =>
                setErr({
                  ...err,
                  apiKey: false,
                })
              }
              helperText={err.apiKey ? "Api key is required" : ""}
            />
          </Grid>
          <Grid
            xs={12}
            sm={4}
            item
            container
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Button
              size="small"
              variant="contained"
              onClick={() => checkPermission("btn212") && pushToCreateForm()}
              disabled={!checkPermission("btn212")}
              startIcon={<AddIcon />}
              style={{ padding: "4px 22px 4px 22px" }}
            >
              Create Billable Services
            </Button>
          </Grid>
          <Grid
            xs={12}
            sm={4}
            item
            container
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            {/* <Button
              size="small"
              variant="contained"
              onClick={() => pushToCreateForm()}
              startIcon={<AddIcon />}
              style={{ padding: "4px 22px 4px 22px" }}
            >
              Create Billable Services
            </Button> */}
          </Grid>
          {/* <Grid item xs={12}>
            <WrappedDexTable
              data={rows}
              columWidthsType="percentage"
              columnOrder={["name", "description", "dataKey", "actions"]}
              columns={[
                { name: "name", title: "Billable Service" },
                { name: "description", title: "Description" },
                { name: "dataKey", title: "Data Key" },
                { name: "actions", title: "Actions" },
              ]}
              defaultColumnWidths={[
                { columnName: "name", width: 20 },
                { columnName: "description", width: 40 },
                { columnName: "dataKey", width: 20 },
                { columnName: "actions", width: 19 },
              ]}
              disableReordering={true}
              pagination={false}
              noDataMsg="Billable Services not found"
              formatters={[
                { formatterFn: actionProvider, columns: ["actions"] },
              ]}
            />
          </Grid> */}
        </Grid>
      </div>
      <Grid container spacing={2}>
        <Grid xs={12} item container justifyContent="flex-end">
          {/* <Button
            size="small"
            variant="contained"
            onClick={() => checkPermission("btn212") && pushToCreateForm()}
            disabled={!checkPermission("btn212")}
            startIcon={<AddIcon />}
            style={{ padding: "4px 22px 4px 22px" }}
          >
            Create Billable Services
          </Button> */}
        </Grid>
        <Grid item xs={12}>
          <WrappedDexTable
            data={rows}
            // hideColumnChooser={true}
            columWidthsType="percentage"
            columnOrder={[
              "name",
              "moduleName",
              "description",
              "dataKey",
              "actions",
            ]}
            columns={[
              { name: "name", title: "Billable Service" },
              { name: "moduleName", title: "Module" },
              { name: "description", title: "Description" },
              { name: "dataKey", title: "Data Key" },
              { name: "actions", title: "Actions" },
            ]}
            defaultColumnWidths={[
              { columnName: "name", width: 20 },
              { columnName: "moduleName", width: 20 },
              { columnName: "description", width: 20 },
              { columnName: "dataKey", width: 20 },
              { columnName: "actions", width: 19 },
            ]}
            hidden={[...billingServicesHidden]}
            setHiddenColmn={(hidden) => {
              props.setHiddenColumn(hidden, "billingServicesHidden");
            }}
            disableReordering={true}
            pagination={false}
            noDataMsg="Billable Services not found"
            formatters={[{ formatterFn: actionProvider, columns: ["actions"] }]}
            onClickRow={(row) => viewBillingdata(row)}
            Read={checkPermission("btn226")}
          />
        </Grid>
      </Grid>
    </div>
  );
}
const mapDispatchToProps = (dispatch) => ({
  setHiddenColumn: (hidden, type) =>
    dispatch(ApplicationDetailsUpadateHiddenCol(hidden, type)),
});

const mapStateToProps = (state) => {
  return {
    appTable: state.appDetailsRedcer.appTable,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withSection(BillingConfigDex, "Billable Services")));
