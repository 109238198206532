import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Button,
  Grid,
  TextField,
  Typography,
  Divider,
} from "@material-ui/core";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Breadcrumb from "components/Breadcrumbs/Breadcrumb";
import { addAuthObject, updateAuthObject } from "../redux/action";
import { isDuplicate } from "../../../helpers/helpers";
import { withStyles } from "@material-ui/styles";
import Styles from "./Style";
import APIs from "../../../api/ApplicationAPIs/index";
import { getApplicationDetails } from "../../Application/ApplicationDetails/Redux/action";
import { withSnackbar } from "notistack";
import { RiEditLine } from "react-icons/ri";
import { checkPermission } from "helpers/permissons";
import HelpdeskFAB from "../../../views/HelpdeskFAB/HelpdeskFab";

class CustomAuthRole extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ID: 0,
      checkEdit: {},
      check_name: "",
      error: false,
      authData: {
        name: "",
        description: "",
        applicationRoleName: "",
        key: "",
      },
      err_msg: "",
      isEdit: false,
      ajax: false,
      isDisabled: false,
    };
  }
  handleOnchange = (value) => {
    this.setState({
      authData: { ...value },
    });
  };

  componentDidMount() {
    const { location } = this.props;
    if (location.state !== undefined) {
      let { authRoleData, edit, ajax, appID, disabled, appName } =
        location.state;
      this.setState({
        authData: {
          ...authRoleData,
        },
        isEdit: edit,
        checkEdit: {
          name: authRoleData.name,
          appRoleName: authRoleData.applicationRoleName,
        },
        ajax: ajax,
        ID: appID,
        isDisabled: disabled,
        appName: appName,
      });
    }
  }

  multilineAdd = async () => {
    const { isEdit, authData } = this.state;
    console.log(authData, "this is auth");
    if (isEdit) {
      let updateAuthRole = [...this.props.authRole];
      updateAuthRole = updateAuthRole.map((item, index) => {
        return item.key === authData.key ? { ...authData } : { ...item };
      });
      await this.props.edit_AuthParams(updateAuthRole);
      this.props.history.push("/app/onboard-application", { status: true });
    } else {
      await this.props.add_AuthParams(this.state.authData);
      this.setState({
        authData: {
          name: "",
          description: "",
          applicationRoleName: "",
          key: this.props.authRole.length,
        },
        isEdit: false,
      });
    }
  };

  singleObjectSubmit = async () => {
    let basicDetails = localStorage.getItem("basicDetails");
    if (this.state.ajax) {
      console.log(this.state.authData, "1111");
    } else {
      const { authData } = this.state;
      await this.props.add_AuthParams(authData);
      this.props.history.push("/app/onboard-application", {
        // stateData: {
        //   basicDetails,
        // },
        status: true,
      });
    }
  };

  editRole = async () => {
    let { _id, key, ...data } = this.state.authData;
    try {
      let res = await APIs.editRole(this.state.authData.appId, _id, data);
      if (res.data) {
        this.props.enqueueSnackbar(res.data._msg || "", {
          variant: "success",
        });
        this.props.history.push("/app/detail", { appId: this.state.ID });
      }
    } catch (err) {
      this.props.enqueueSnackbar(
        err?.response?.data?._msg || "Something Went wrong",
        {
          variant: "error",
          autoHideDuration: 3000,
        }
      );
      console.error(err);
    }
  };

  addRole = async () => {
    let { key, ...data } = this.state.authData;
    try {
      const res = await APIs.addRole(this.state.ID, data);
      if (res.data) {
        this.props.enqueueSnackbar(
          res.data._msg || "Authorization Object details saved successfully",
          { variant: "success", autoHideDuration: 3000 }
        );
      }
    } catch (err) {
      this.props.enqueueSnackbar(
        err?.response?.data?._msg || "Something Went wrong",
        {
          variant: "error",
          autoHideDuration: 3000,
        }
      );
      console.error(err);
    }
  };

  ajaxFuncs = async () => {
    let { isEdit } = this.state;
    if (isEdit) {
      this.editRole();
    } else {
      await this.addRole();
      await this.props.getDetails(this.state.ID);
      this.setState({
        authData: {
          name: "",
          description: "",
          applicationRoleName: "",
        },
        isEdit: false,
      });
    }
  };

  singleAjaxFunc = async () => {
    await this.addRole();
    this.props.history.push("/app/detail", { appId: this.state.ID });
  };

  onSubmitajax = (e) => {
    const { authData, isEdit, checkEdit } = this.state;
    let flag = false;
    console.log(this.props.authorization, "rolesgfhgfhg");
    if (
      !isEdit ||
      checkEdit.name !== authData.name ||
      checkEdit.appRoleName !== authData.applicationRoleName
    ) {
      flag = isDuplicate(
        this.props.authorization,
        (f) =>
          f.name === authData.name &&
          f.applicationRoleName === authData.applicationRoleName
      );
    }

    if (flag) {
      this.setState({
        err_msg: "role name and application role name already exist",
        error: true,
      });
      return;
    }

    this.setState({
      err_msg: "",
      error: false,
    });
    if (e.nativeEvent.submitter.name === "multiple") {
      this.ajaxFuncs();
    }

    if (e.nativeEvent.submitter.name === "single") {
      this.singleAjaxFunc();
    }
  };

  onSubmit = async (e) => {
    const { authData, isEdit, checkEdit } = this.state;

    const { onBoard } = this.props;
    let flag = false;

    if (
      !isEdit ||
      checkEdit.name !== authData.name ||
      checkEdit.appRoleName !== authData.applicationRoleName
    ) {
      flag = isDuplicate(
        this.props.authRole,
        (f) =>
          f.name === authData.name &&
          f.applicationRoleName === authData.applicationRoleName
      );
    }

    if (flag) {
      this.setState({
        err_msg: "role name and application role name already exist",
        error: true,
      });
      return;
    }

    this.setState({
      err_msg: "",
      error: false,
    });

    if (e.nativeEvent.submitter.name === "multiple") {
      this.multilineAdd();
    }

    if (e.nativeEvent.submitter.name === "single") {
      this.singleObjectSubmit();
    }
  };

  setDuplicateNull = () => {
    this.setState({
      error: false,
      err_msg: "",
    });
  };

  render() {
    const { authData, isEdit, error, err_msg, ajax, isDisabled, appName } =
      this.state;
    const { classes } = this.props;
    console.log(this.props.authorization, 344, "rolesgfhgfhg");
    return (
      <div>
        <Grid item sm={12} md={12} xs={12} lg={12}>
          <Breadcrumb
            {...this.props}
            links={[
              { name: "Applications", url: "/app/applications" },
              {
                name: `${ajax ? `${appName}` : "Onboard Application"}`,
                url: `${ajax ? "/app/detail" : "/app/onboard-application"}`,
                routeParams: ajax ? { appId: this.state.ID } : { status: true },
              },
              {
                name: "Authorization Roles",
                url: "/app/create-auth-form",
              },
            ]}
          />
        </Grid>
        <ValidatorForm
          autoComplete="off"
          ref="form"
          onSubmit={(e) => {
            if (ajax) {
              this.onSubmitajax(e);
            } else {
              this.onSubmit(e);
            }
          }}
        >
          <Grid container spacing={0}>
            <Grid item sm={12} md={12} xs={12} lg={12}>
              <Grid
                container
                spacing={1}
                direction="row"
                className={classes.root}
                alignItems="center"
              >
                <Grid item md={6}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h6" className={classes.title}>
                      Authorization Roles
                    </Typography>
                  </div>
                </Grid>
                <Grid
                  item
                  sm={6}
                  container
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    {!isDisabled && (
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() => {
                          if (ajax) {
                            this.props.history.push("/app/detail", {
                              appId: this.state.ID,
                            });
                          } else {
                            this.props.history.push(
                              "/app/onboard-application",
                              {
                                status: true,
                              }
                            );
                          }
                        }}
                      >
                        Cancel
                      </Button>
                    )}
                  </Grid>
                  <Grid item>
                    {!isDisabled && (
                      <Button
                        color="primary"
                        size="small"
                        name="multiple"
                        variant={isEdit ? "contained" : "outlined"}
                        type="submit"
                        disabled={
                          isEdit
                            ? !checkPermission("btn103")
                            : !checkPermission("btn102")
                        }
                      >
                        {isEdit ? "Save" : "Create and Add Another"}
                      </Button>
                    )}
                  </Grid>
                  <Grid item>
                    {!isEdit && (
                      <Button
                        size="small"
                        name="single"
                        variant="contained"
                        type="submit"
                        disabled={!checkPermission("btn101")}
                      >
                        {"Create"}
                      </Button>
                    )}
                  </Grid>
                  <Grid>
                    {isDisabled && (
                      <Button
                        size="small"
                        variant="contained"
                        onClick={() =>
                          this.setState({
                            isDisabled: false,
                          })
                        }
                        disabled={!checkPermission("btn103")}
                        startIcon={<RiEditLine style={{ fontSize: "20px" }} />}
                      >
                        Edit
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12}>
              <Divider className={classes.divider} />
            </Grid>
            {/* <Grid item md={12}> */}
            <Grid
              container
              spacing={2}
              md={12}
              xs={12}
              item
              style={{
                marginTop: 14,
                display: "flex",
              }}
            >
              <Grid item md={3} style={{ width: "100%" }}>
                <Typography style={{ marginBottom: "2px" }} variant="body2">
                  Role Name <span style={{ color: "red" }}>*</span>{" "}
                </Typography>
                <TextValidator
                  disabled={isDisabled}
                  size="small"
                  variant="outlined"
                  fullWidth
                  value={authData.name}
                  id="key"
                  onChange={(e) =>
                    this.handleOnchange({
                      ...authData,
                      name: e.target.value.trimStart(),
                    })
                  }
                  name="authRoleName"
                  validators={["required"]}
                  errorMessages={["Please enter role name "]}
                  error={error}
                  helperText={err_msg}
                />
              </Grid>

              <Grid item md={3} style={{ width: "100%" }}>
                <Typography style={{ marginBottom: "2px" }} variant="body2">
                  Application Role Name <span style={{ color: "red" }}>*</span>{" "}
                </Typography>
                <TextValidator
                  disabled={isDisabled}
                  size="small"
                  variant="outlined"
                  fullWidth
                  name="authApplicationRoleName"
                  value={authData.applicationRoleName}
                  id="key"
                  onChange={(e) =>
                    this.handleOnchange({
                      ...authData,
                      applicationRoleName: e.target.value.trimStart(),
                    })
                  }
                  validators={["required"]}
                  errorMessages={["please enter Application role name"]}
                  error={error}
                  helperText={err_msg}
                />
              </Grid>
              <Grid item md={6} style={{ width: "100%" }}>
                <Grid container justifyContent="flex-end">
                  <Grid item md={6}>
                    <Typography style={{ marginBottom: "2px" }} variant="body2">
                      Description
                    </Typography>
                    <TextValidator
                      disabled={isDisabled}
                      value={authData.description}
                      size="small"
                      onChange={(e) =>
                        this.handleOnchange({
                          ...authData,
                          description: e.target.value,
                        })
                      }
                      // value={authData.description}
                      multiline
                      rows={7}
                      variant="outlined"
                      fullWidth
                      name="authDescription"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* </Grid> */}
            <HelpdeskFAB />
          </Grid>
        </ValidatorForm>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  add_AuthParams: (data) => dispatch(addAuthObject(data)),
  edit_AuthParams: (data) => dispatch(updateAuthObject(data)),
  getDetails: (id) => dispatch(getApplicationDetails(id)),
});
const mapStateToProps = (state) => {
  return {
    authRole: state.application.onBoard.authRole || [],
    authorization:
      state.appDetailsRedcer.applicationDetails.authorization || [],
  };
};

const CustomAuthForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomAuthRole);
export default withStyles(Styles)(withSnackbar(CustomAuthForm));
