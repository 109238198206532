const Styles = (theme) => ({
  // status profile
  completedStatus: {
    display: "flex",
    "& span": {
      height: 16,
      width: 16,
      borderRadius: "50%",
      display: "inline-block",
      border: "1px solid #bbb",
      marginLeft: 12,
      marginTop: 19,
      // backgroundColor: "#21BA45",
    },
  },
  predefinedColorDots: {
    height: "15px",
    width: "15px",
    borderRadius: "50%",
    display: " inline-block",
    border: "1px solid #bbb",
    marginRight: "5px",
  },
  statusModalCloseButton: {
    width: 100,
    height: 40,
    backgroundColor: theme.palette.primary.main,
    color: "#FFFFFF",
    marginLeft: 409,
    marginBottom: 12,
    textTransform: "capitalize",
    font: "normal normal bold 14px/19px Nunito",
    "&:hover": {
      transition: "unset",
      backgroundColor: theme.palette.primary.main,
    },
  },
  footer: {
    position: "fixed",
    bottom: 0,
    left: 0,
    padding: 20,
    width: "100%",
    textAlign: "right",
    zIndex: 999,
    backgroundColor: "#f2f2f2",
  },
  customPagination: {
    width: "fit-content",
    marginLeft: "auto",
    "& .MuiPaginationItem-page.Mui-selected": {
      border: "0.5px solid #707070",
      margin: "0 10px",
      height: 30,
      width: 30,
      padding: "10px 13px",
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      boxShadow: "0px 3px 10px #5757571a",
      lineHeight: 1,
      borderRadius: 5,
      opacity: 1,
    },
    "& .MuiPaginationItem-page": {
      font: "normal normal normal 14px/19px Nunito Sans",
      letterSpacing: 0,
      color: "#000000",
      opacity: 0.6,
    },
  },
  iconHover: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  wrapper: {
    // position: "relative",
    // top: "6rem",
    margin: "26px 10px 1rem 0px",
  },
  fieldHeading: {
    font: "normal normal 600 14px/19px Nunito;",
    letterSpacing: 0,
    marginTop: "20px",
    color: "#000000",
    width: "100%",
    opacity: 1,
  },
  fileName: {
    font: "normal normal 12px/16px Nunito;",
    letterSpacing: 0,
    color: "#000000",
    width: "100%",
  },
  fieldHeading2: {
    font: "normal normal 600 14px/19px Nunito",
    letterSpacing: 0,
    color: "#000000",
    width: "100%",
  },
  border: {
    borderBottom: "2px solid #afafaf",
    opacity: 0.4,
    width: "100%",
    marginBottom: "1rem",
  },
  innerCircleColorPicker: {
    margin: "4px",
    height: "20px",
    width: "20px",
    borderRadius: "50%",
    // border: "5px solid black",
  },
  inputTypeColorElement: {
    width: "23px",
    height: "21px",
    border: "none",
    borderRadius: "100%",
    // marginLeft: "10px",
    marginTop: "6px",

    "&::-webkit-color-swatch-wrapper ": {
      padding: "0",
    },
    "&::-webkit-color-swatch": {
      border: "1px",
      borderRadius: "50%",
      // backgroundColor:"red!important",
    },
  },
  MuiCenterDivider: {
    width: "2px",
    height: "300px",
    color: "#535353",
  },
  newStatusAddBox: {
    border: "1px solid #D6D6D6",
    width: "80%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "10px",
    opacity: 1,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    "& span": {
      font: "normal normal 600 16px/22px Nunito",
      fontSize: 16,
      marginTop: "2px",
      // marginLeft:"5px"
    },
  },
  predefinedColorDots: {
    height: "15px",
    width: "15px",
    borderRadius: "50%",
    display: " inline-block",
    border: "1px solid #bbb",
    marginRight: "5px",
  },

  active: {
    borderColor: "blue",
    border: "2px solid",
    borderRadius: "50%",
    marginLeft: 5,
    marginRight: 5,
    // border: "2px solid",
    // marginLeft: "3px",
    // borderColor: "blue",
    height: "32px",
    width: "32px",
    // marginTop: "12px",
    // marginRight: "3px",
    // borderRadius: "50%",
    // position:"relative"
  },
  inactive: {
    borderColor: "transparent",
  },

  colorPicker: {
    marginLeft: 12,
    "& .MuiInput-underline::before": {
      borderBottom: "unset",
    },
    "& .MuiInput-underline::after": {
      borderBottom: "unset",
    },
  },
  customColorDot: {
    height: "18px",
    width: "18px",
    borderRadius: "50%",
    display: "inline-block",
    border: "1px solid #bbb",
    marginTop: "16px",
    marginLeft: "10px",
    // position:"absolute"
  },
  markAsDefault: {
    opacity: 0,
    paddingRight: "10px",
    "&:hover": {
      opacity: 1,
    },
  },
});

export default Styles;
