import { React, useState, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import AddIcon from "@material-ui/icons/Add";

import SaveIcon from "@material-ui/icons/Save";
import {
  Tooltip,
  Button,
  TextField,
  IconButton,
  Typography,
  TableContainer,
  TableBody,
  Divider,
} from "@material-ui/core";
import {
  TextValidator,
  SelectValidator,
  ValidatorForm,
} from "react-material-ui-form-validator";
import Grid from "@material-ui/core/Grid";
import { RiEditLine, RiDeleteBin5Line } from "react-icons/ri";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";
import { SelectDayComponent } from "../../../../../components/SetDayComponent/index";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTableCell-head": {},
  },
  table: {
    minWidth: 650,
  },
  button: {
    padding: "4px 30px",
  },
  dividerline: {
    width: "70%",
    margin: "-25px 0px 0px 125px",
    [theme.breakpoints.up("md")]: {
      width: "82%",
      margin: "-25px 0px 0px 260px",
    },
  },
}));
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    width: 15,
    height: 1,
  },
  btn: {
    width: "300px",
  },
}))(TableCell);

const EventTable = (props) => {
  const { handleOnchange, outgoing } = props;
  const slots = outgoing?.schedule?.slots || [
    { start_time: "", end_time: "", repeated_day: "Sunday" },
  ];
  const classes = useStyles();
  const updateTopFunc = (eventArr) => {
    handleOnchange({
      ...outgoing,
      schedule: {
        ...outgoing.schedule,
        slots: eventArr,
      },
    });
  };
  const onAdd = (index) => {
    let arr = [
      ...slots,
      { start_time: "", end_time: "", repeated_day: "Sunday" },
    ];
    updateTopFunc(arr);
  };

  const onDelete = (index) => {
    const arr = slots.filter((val, id) => id !== index);
    updateTopFunc(arr);
  };

  const onValueChange = (val, index) => {
    let arr = [...slots];
    arr[index] = {
      ...val,
    };
    updateTopFunc(arr);
  };

  return (
    <div>
      <Typography
        style={{
          color: "#262626",
          fontWeight: 600,
          textAlign: "start",
          fontSize: "16px",
        }}
      >
        <b> {"Slot Configuration"}</b>
      </Typography>
      <Divider style={{ marginBottom: "40px" }} />
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead className={classes.root}>
            <TableRow>
              <TableCell style={{ width: "20%" }}>Slot Start Time</TableCell>
              <TableCell style={{ width: "20%" }} align="center">
                Slot End Time
              </TableCell>
              <TableCell style={{ width: "20%" }} align="center">
                {" "}
                Day
              </TableCell>
              <TableCell style={{ width: "8%" }} align="center">
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {slots.map((row, index) => (
              <TableRow
                key={index}
                style={{ paddingTop: "2px", paddingBottom: "2px" }}
              >
                <StyledTableCell align="left">
                  <div style={{ width: "100%" }}>
                    <TextValidator
                      className={classes.fieldStyle}
                      style={{ width: "100%" }}
                      fullwidth
                      name="valid-till"
                      value={row.start_time || ""}
                      variant="outlined"
                      onChange={(e) => {
                        onValueChange(
                          {
                            ...row,
                            start_time: e.target.value,
                          },
                          index
                        );
                      }}
                      autoComplete="off"
                      size="small"
                      type="time"
                      validators={["required"]}
                      errorMessages={["Please enter start time"]}
                    />
                  </div>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <div style={{ width: "100%" }}>
                    <TextValidator
                      className={classes.fieldStyle}
                      style={{ width: "100%" }}
                      fullwidth
                      name="valid-till"
                      value={row.end_time || ""}
                      variant="outlined"
                      onChange={(e) => {
                        onValueChange(
                          {
                            ...row,
                            end_time: e.target.value,
                          },
                          index
                        );
                      }}
                      autoComplete="off"
                      size="small"
                      type="time"
                      validators={["required"]}
                      errorMessages={["Please enter end time"]}
                    />
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div>
                    <SelectDayComponent
                      background="white"
                      required={true}
                      defaultValue={row.repeated_day}
                      //   text="Repeat On:"
                      onChange={(value) => {
                        console.log("repaedefrege", value);
                        onValueChange(
                          {
                            ...row,
                            repeated_day: value,
                          },
                          index
                        );
                      }}
                    />
                  </div>
                </StyledTableCell>

                <StyledTableCell align="left">
                  <div
                    style={{
                      display: "flex",

                      justifyContent: "flex-end",
                      paddingBottom: "20px",
                    }}
                  >
                    {slots.length - 1 === index && (
                      <Tooltip title={`Add`}>
                        <IconButton
                          size="small"
                          onClick={() => onAdd(index)}
                          disabled={props.isDisabled}
                        >
                          <AddIcon
                            style={{
                              minHeight: "20px",
                              marginTop: "-2px",
                              marginRight: "13px",
                            }}
                            color="inherit"
                          />
                        </IconButton>
                      </Tooltip>
                    )}

                    {slots.length > 1 && (
                      <div>
                        {" "}
                        <IconButton
                          size="small"
                          onClick={() => onDelete(index)}
                          disabled={props.isDisabled}
                        >
                          <RiDeleteBin5Line color="inherit" fontSize="20px" />
                        </IconButton>
                      </div>
                    )}
                  </div>
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default EventTable;
