const Styles = (theme) => ({
  modelFooter: {
    textAlign: "center",
    background: "#FAFAFA 0% 0% no-repeat padding-box",
    borderRadius: "0px 0px 10px 10px",
    opacity: "1",
    paddingBottom: "15px",

    "& .MuiGrid-item Button": {
      width: "266px",
      height: "40px",
      textTransform: "capitalize",
      font: "normal normal 600 12px/16px Nunito",
    },
  },
  modalTitle: {
    textAlign: "center",
    fontWeight: "bold",
  },

  modalHeading: {
    textAlign: "center",
    fontWeight: "100px",
  },
  modalDropzone: {
    "& .documentDropperClass": {
      paddingTop: "3.5rem",
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      border: `3px solid ${theme.palette.primary.main}`,
      borderRadius: 10,
      textAlign: "center",
      font: "normal normal 600 16px/22px Nunito",
      color: `${theme.palette.primary.main}`,

      "& .MuiDropzoneArea-textContainer": {
        display: "flex",
        flexDirection: "column-reverse",
        alignItems: "center",
        justifyContent: "center",

        "& .MuiDropzoneArea-icon": {
          color: `${theme.palette.primary.main}`,
          transform: "scale(1.4)",
        },

        "& .documentDropperTextClass": {
          color: `${theme.palette.primary.main}`,
          font: "normal normal 600 16px/22px Nunito",
        },
      },
    },
  },
  modalstyle: {
    height: "150px",
    width: "400px",
    marginTop: "30px",
    textAlign: "center",
  },

  modaltextstyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalFileContainer: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 2px 6px #00000014",
    borderRadius: "6px",
    opacity: "1",
    marginTop: "20px",
    width: "553px",
    height: "50px",
    justifyContent: "space-between",
  },
  file: {
    display: "flex",
    marginTop: "12px",
    marginLeft: "10px",

    "& span": {
      marginLeft: "10px",
    },
  },

  fileDelete: {
    display: "flex",
    marginTop: "12px",
    justifyContent: "flex-end",
    marginRight: "10px",
  },
  //pagination
  footer: {
    position: "fixed",
    bottom: 0,
    left: 0,
    padding: 20,
    width: "100%",
    textAlign: "right",
    zIndex: 999,
    backgroundColor: "#f2f2f2",
  },
  customPagination: {
    width: "fit-content",
    marginLeft: "auto",
    "& .MuiPaginationItem-page.Mui-selected": {
      border: "0.5px solid #707070",
      margin: "0 10px",
      height: 30,
      width: 30,
      padding: "10px 13px",
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      boxShadow: "0px 3px 10px #5757571a",
      lineHeight: 1,
      borderRadius: 5,
      opacity: 1,
    },
    "& .MuiPaginationItem-page": {
      font: "normal normal normal 14px/19px Nunito Sans",
      letterSpacing: 0,
      color: "#000000",
      opacity: 0.6,
    },
  },
  block: {
    color: "red",
  },
  active: {},
});

export default Styles;
