import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Button,
  Divider,
  TextField,
} from "@material-ui/core/";
import Breadcrumb from "components/Breadcrumbs/Breadcrumb";
import { makeStyles } from "@material-ui/core/styles";
// import AddCategoryBilling from "./AddCategoryBilling";
// import actions from "./redux/action";
import { connect } from "react-redux";
import APIs from "../../api/SubscriptionModelAPIs/index";
import actions from "./redux/action";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { isDuplicate } from "../../helpers/helpers";
import { withSnackbar } from "notistack";
import { RiEditLine } from "react-icons/ri";

import { CustomNumberFeild } from "components/Customized";

import { checkPermission } from "../../helpers/permissons";
import HelpdeskFAB from "../../views/HelpdeskFAB/HelpdeskFab";

class CreateSubscription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ID: localStorage.getItem("subscriptionPlan"),
      error: false,
      isEdit: false,
      ajax: false,
      index: 0,
      SubscriptionData: {
        subscription: "",
        value: "",
        description: "",
      },
      isDisabled: false,
      checkEdit: {},
    };
  }

  onChange = (value) => {
    this.setState({
      SubscriptionData: { ...value },
    });
  };

  async componentDidMount() {
    const { location } = this.props;
    if (location.state !== undefined) {
      await this.props.actionfetchAllSubscription();

      let { subscription_Data, edit, disabled } = location.state;
      this.setState({
        SubscriptionData: {
          ...this.state.SubscriptionData,
          ...subscription_Data,
        },
        isEdit: edit,
        checkEdit: {
          subscription: subscription_Data.subscription,
          value: subscription_Data.value,
        },
        isDisabled: disabled,
      });
    }
  }

  editSubscriptionPlan = async () => {
    let { _id, ...data } = this.state.SubscriptionData;
    try {
      const res = await APIs.editSubscription(_id, data);
      this.props.enqueueSnackbar(
        res.data._msg || "Subscription Plan updated successfully",
        {
          variant: "success",
          autoHideDuration: 2000,
        }
      );
      this.props.history.push("/app/subscription");
    } catch (err) {
      this.props.enqueueSnackbar(err?.response?.data._msg || "Network Error", {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  };

  addSubscriptionPlan = async (type) => {
    let { SubscriptionData } = this.state;
    try {
      const res = await APIs.addSubscription(SubscriptionData);
      this.props.enqueueSnackbar(
        res.data._msg || "Subscription Plan added successfully",
        { variant: "success", autoHideDuration: 2000 }
      );
      if (type && res.data) {
        this.props.history.push("/app/subscription");
      } else {
        this.setState({
          SubscriptionData: {
            subscription: "",
            value: "",
            description: "",
          },
          autoCompleteVal: null,
          isEdit: false,
        });
      }
    } catch (err) {
      console.log(err.response, "res");
      this.props.enqueueSnackbar(err?.response?.data._msg || "Network  Error", {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  };

  multiAdd = async () => {
    let { isEdit } = this.state;
    if (isEdit) {
      await this.editSubscriptionPlan();
    } else {
      this.addSubscriptionPlan(false);
    }
  };

  onSubmitData = (e) => {
    const { SubscriptionData, isEdit, checkEdit } = this.state;
    // let flag = false;
    // if (
    //   !isEdit ||
    //   checkEdit.subscription !== SubscriptionData.subscription ||
    //   checkEdit.value !== SubscriptionData.value
    // ) {
    //   flag = isDuplicate(
    //     this.props.data || [],
    //     (f) =>
    //       f.subscription === SubscriptionData.subscription &&
    //       f.value === SubscriptionData.value
    //   );
    // }
    // console.log(flag, "flag");

    // if (flag) {
    //   this.setState({
    //     error: true,
    //   });
    //   return;
    // }

    // this.setState({
    //   error: false,
    // });

    if (e.nativeEvent.submitter.name === "multiple") {
      this.multiAdd();
    }
    if (e.nativeEvent.submitter.name === "single") {
      this.addSubscriptionPlan(true);
    }
  };

  setDuplicateNull = () => {
    this.setState({
      error: false,
    });
  };

  render() {
    const { SubscriptionData, isEdit, error, isDisabled } = this.state;
    return (
      <div>
        <ValidatorForm
          ref="form"
          autoComplete="off"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              return;
            }
          }}
          onSubmit={(e) => {
            this.onSubmitData(e);
          }}
        >
          <Grid container>
            <Grid item sm={12} md={12} xs={12} lg={12}>
              <Breadcrumb
                {...this.props}
                links={[
                  { name: "Subscription Plan", url: "/app/subscription" },
                  {
                    name: `${
                      isEdit
                        ? SubscriptionData.subscription
                        : "Create Subscription Plan"
                    }`,
                    url: "/app/create-subscription-plan",
                  },
                ]}
              />
            </Grid>
            <Grid item sm={6} md={6} lg={6} xs={12}>
              <Typography variant="h6" style={{ fontWeight: "bold" }}>
                {isEdit
                  ? SubscriptionData.subscription
                  : "Create Subscription Plan"}
              </Typography>
            </Grid>
            <Grid
              item
              sm={6}
              md={6}
              container
              justifyContent="flex-end"
              alignItems="center"
              spacing={1}
            >
              {!isDisabled && (
                <>
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      onClick={() => {
                        this.props.history.push("/app/subscription");
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      name="multiple"
                      color={isEdit ? "" : "primary"}
                      size="small"
                      variant={isEdit ? "contained" : "outlined"}
                      type="submit"
                      id={isEdit ? "btn13" : "btn12"}
                      disabled={
                        isEdit
                          ? !checkPermission("btn13")
                          : !checkPermission("btn12")
                      }
                    >
                      {isEdit ? "Save" : "Create and Add Another"}
                    </Button>
                  </Grid>
                </>
              )}
              <Grid item>
                {!isEdit && (
                  <Button
                    name="single"
                    size="small"
                    variant="contained"
                    type="submit"
                  >
                    {"Create"}
                  </Button>
                )}
              </Grid>
              <Grid item>
                {isDisabled && (
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() =>
                      checkPermission("btn13") &&
                      this.setState({
                        isDisabled: false,
                      })
                    }
                    startIcon={<RiEditLine style={{ fontSize: "20px" }} />}
                  >
                    Edit
                  </Button>
                )}
              </Grid>
            </Grid>

            <Grid item md={12}>
              <Divider
                style={{
                  marginTop: "12px",
                  height: "2px",
                  background: "rgb(175, 175, 175)",
                }}
              />
            </Grid>
            <Grid container spacing={2} className="mt-3">
              <Grid item sm={3} md={3} style={{ width: "100%" }}>
                <Typography style={{ marginBottom: "2px" }} variant="body2">
                  Subscription Plan <span style={{ color: "red" }}>*</span>
                </Typography>
                <TextValidator
                  disabled={isDisabled}
                  size="small"
                  variant="outlined"
                  fullWidth
                  name="name"
                  value={SubscriptionData.subscription}
                  onChange={(e) =>
                    this.onChange({
                      ...SubscriptionData,
                      subscription: e.target.value.trimStart(),
                    })
                  }
                  validators={["required"]}
                  errorMessages={["Please enter subscription"]}
                  onFocus={this.setDuplicateNull}
                  error={error}
                  helperText={error ? "Subscription Plan already exist" : ""}
                />
              </Grid>
              <Grid item sm={3} md={3} style={{ width: "100%" }}>
                <Typography style={{ marginBottom: "2px" }} variant="body2">
                  Duration (in Months) <span style={{ color: "red" }}>*</span>
                </Typography>

                <CustomNumberFeild
                  disabled={isDisabled}
                  size="small"
                  variant="outlined"
                  fullWidth
                  type="number"
                  name="value"
                  value={SubscriptionData.value}
                  onChange={(e) =>
                    this.onChange({
                      ...SubscriptionData,
                      value: parseInt(e.target.value),
                    })
                  }
                  validators={["required", "minNumber:0"]}
                  errorMessages={["Please enter duration", "Invalid month"]}
                  onFocus={this.setDuplicateNull}
                  error={error}
                  helperText={
                    error ? "Duration for this plan already exist" : ""
                  }
                />
              </Grid>
              <Grid item sm={3} md={3} style={{ width: "100%" }}></Grid>
              <Grid item sm={3} md={3}>
                <Typography style={{ marginBottom: "2px" }} variant="body2">
                  Description
                </Typography>
                <TextField
                  disabled={isDisabled}
                  size="small"
                  multiline
                  rows={7}
                  variant="outlined"
                  fullWidth
                  name="authDescription"
                  value={SubscriptionData.description}
                  onChange={(e) =>
                    this.onChange({
                      ...SubscriptionData,
                      description: e.target.value,
                    })
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <HelpdeskFAB category="Subscription Plan" />
        </ValidatorForm>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    editdata: state.subscriptionPlan.editdata,
    loading: state.subscriptionPlan.loading,
    adddata: state.subscriptionPlan.adddata,
    data: state.subscriptionPlan.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actionfetchAllSubscription: () =>
      dispatch(actions.actionfetchAllSubscription()),
    addSubscription: (payload) => dispatch(actions.addSubscription(payload)),
    editSubscription: (id, payload) =>
      dispatch(actions.editSubscription(id, payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(CreateSubscription));
