const Styles = (theme) => ({
  footer: {
    // position: "fixed",
    bottom: 20,
    left: 0,
    padding: "20px 20px 0px 20px",
    width: "100%",
    textAlign: "right",
    zIndex: 999,
    backgroundColor: "#f2f2f2",
  },
  customPagination: {
    width: "fit-content",
    marginLeft: "auto",
    "& .MuiPaginationItem-page.Mui-selected": {
      border: "0.5px solid #707070",
      margin: "0 10px",
      height: 30,
      width: 30,
      padding: "10px 13px",
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      boxShadow: "0px 3px 10px #5757571a",
      lineHeight: 1,
      borderRadius: 5,
      opacity: 1,
    },
    "& .MuiPaginationItem-page": {
      font: "normal normal normal 14px/19px Nunito Sans",
      letterSpacing: 0,
      color: "#000000",
      opacity: 0.6,
    },
  },
  activeBadge: {
    backgroundColor: "#21BA45",
  },
  approve: {
    color: "#21BA45",
  },
  pendingBadge: {
    backgroundColor: "#FFA500",
  },
  reject: {
    color: "red",
  },
  approvecancel: {
    color: "rgb(175, 175, 175)",
  },
  flx: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  dividerline: {
    marginTop: "12px",
    height: "2px",
    background: "rgb(175, 175, 175)",
  },
  totalvalue: {
    fontSize: "18px",
    padding: "16px",
  },
  summary: {
    color: "#8c8c8c",
  },
  heading: {
    padding: "16px",
  },
  content: {
    paddingTop: "16px",
  },
  issued: {
    fontSize: "12px",
    color: "#8c8c8c",
  },
  creditamount: {
    display: "flex",
    justifyContent: "space-between",
    padding: "15px 20px 6px 20px",
    color: "#8c8c8c",
  },
  totalamount: {
    display: "flex",
    justifyContent: "space-between",
    padding: "8px 20px 6px 20px",
  },
  tax: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0px 20px 6px 20px",
    color: "#8c8c8c",
  },
  backgroundcolor: {
    marginTop: "15px",
    backgroundColor: "#F4F9FD",
    height: "150px",
  },
  amount: {
    fontSize: "16px",
  },
  percentval: {
    color: "#8c8c8c",
  },
  button: {
    width: "100%",
    boxShadow: "none",
    padding: "6px 11px 6px 11px",
  },
  tableheight: {
    height: "50px",
  },
  collapsablestyle: {
    margin: "10px",
  },
  tableborder: {
    border: "1px solid #e0dbdb",
  },
  dataGrid: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  },
  flexx: {
    display: "inline-flex",
    flexWrap: "wrap",
  },
  dataStyle: {
    display: "flex",
    justifyContent: "space-between",
  },
  flxW100AlgnC: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  color1: {
    backgroundColor: "#013478",
  },
  color2: {
    backgroundColor: "#fbbd2c",
  },

  color3: {
    backgroundColor: "#b5cc2c",
  },
  badge: {
    display: "flex",
    alignItems: "center",
  },
  inactiveBadge: {
    backgroundColor: "#BEBEBE",
  },
  flxRowAlgnC: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  smallChart: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});
export default Styles;
