import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  TableHead,
  TableRow,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Checkbox,
  IconButton,
  Grid,
  Typography,
  Select,
  FormControl,
  Tooltip,
} from "@material-ui/core/";
import CloseIcon from "@material-ui/icons/Close";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { useEffect } from "react";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,

    color: theme.palette.common.white,
    padding: 10,
    paddingLeft: 15,
    fontSize: 14,
  },
  body: {
    fontSize: 12,
    backgroundColor: "#F0F0F0",
    paddingTop: 0,
    paddingBottom: 2,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default function Referencemappingtable(props) {
  const { data, cdu_values, onChange } = props;
  const rows = [];
  const classes = useStyles();
  let rows_data = [
    {
      key: 1,
    },
  ];

  const [addrow, setaddRow] = React.useState(rows_data);

  useEffect(() => {
    setaddRow(cdu_values);
  }, [cdu_values]);

  const addFilter = () => {
    let copy = [...addrow];
    let addId = {
      key: addrow.length + 1,
    };
    copy.push(addId);
    setaddRow(copy);
  };

  const removeFilter = () => {
    if (addrow.length != 1) {
      let copy = [...addrow];
      let rowRemoved = copy.filter((row) => {
        return row.key != copy.length;
      });
      setaddRow(rowRemoved);
    }
  };

  return (
    <div className="pr-3 pl-3">
      {Object.keys(cdu_values).length == 0 ? (
        <p>No Reference Values Defined</p>
      ) : (
        <TableContainer
          style={{ overflowY: "auto", height: "57vh", width: "100%" }}
        >
          <Table className={classes.table} aria-label="customized table">
            <TableHead size="small">
              <TableRow>
                <StyledTableCell align="left">CDU Value</StyledTableCell>
                <StyledTableCell align="left">
                  Application value
                </StyledTableCell>
                {/* <StyledTableCell align="center">Actions</StyledTableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(cdu_values).map((f) => (
                <>
                  <StyledTableRow>
                    <StyledTableCell align="left">
                      {" "}
                      <Typography>{f}</Typography>{" "}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <TextField
                        style={{ backgroundColor: "white", marginTop: "10px" }}
                        name="field_name"
                        id="outlined-search"
                        type="search"
                        variant="outlined"
                        size="small"
                        fullWidth
                        margin="normal"
                        value={cdu_values[f]}
                        onChange={(e) => {
                          props.onChange(f, e.currentTarget.value);
                        }}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
}
