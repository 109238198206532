import React from "react";
import { withRouter } from "react-router-dom";
// import { Grid } from "@material-ui/core";
import ResetForm from "./ResetForm";
class Reset extends React.Component {

  render() {
    const {history}=this.props;
    return (
      <>
        <ResetForm  history={history}/>
      </>
    );
  }
}
export default  withRouter( Reset);
