import React, { useRef, useState } from "react";
import { DropzoneArea } from "material-ui-dropzone";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  FileUploadContainer,
  FormField,
  DragDropText,
  UploadFileBtn,
  FilePreviewContainer,
  ImagePreview,
  PreviewContainer,
  PreviewList,
  FileMetaData,
  InputLabel,
} from "./file-upload.styles";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  Grid,
  ImageList,
  Button,
  Typography,
  TextField,
} from "@material-ui/core/";
import { ImFolderUpload } from "react-icons/im";
const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 16,
  },
}));

const KILO_BYTES_PER_BYTE = 1000;
const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 500000;

const convertNestedObjectToArray = (nestedObj) =>
  Object.keys(nestedObj).map((key) => nestedObj[key]);

const convertBytesToKB = (bytes) => Math.round(bytes / KILO_BYTES_PER_BYTE);

const FileUpload = ({
  props,
  label,
  updateFiles,
  maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
  ...otherProps
}) => {
  const classes = useStyles();
  // const { handleChange } = props;

  const fileInputField = useRef(null);
  const [files, setFiles] = useState({});
  const [add, setAdd] = React.useState(true);
  const [msg, setMsg] = useState("");
  const handleUploadBtnClick = () => {
    fileInputField.current.click();
    setAdd(true);
  };

  const addNewFiles = (newFiles) => {
    for (let file of newFiles) {
      if (file.size < maxFileSizeInBytes) {
        if (!otherProps.multiple) {
          return { file };
        }
        files[file.name] = file;
      }
    }
    return { ...files };
  };

  const callUpdateFiles = (files) => {
    const filesAsArray = convertNestedObjectToArray(files);
    updateFiles(filesAsArray);
  };

  const handleNewFileUpload = (e) => {
    console.log(e.target.files, "fbhgfghfghfhjfhgdfhg");
    if (e.target.files[0].type === "text/csv") {
      setMsg("");
      const { files: newFiles } = e.target;
      if (newFiles.length) {
        let updatedFiles = addNewFiles(newFiles);
        setFiles(updatedFiles);
        callUpdateFiles(updatedFiles);
        setAdd(false);
      }
    } else {
      console.log(e);
      setMsg("Please upload only .csv file");
    }
  };

  const removeFile = (fileName) => {
    delete files[fileName];
    setFiles({ ...files });
    callUpdateFiles({ ...files });
    setAdd(true);
  };

  return (
    <div>
      {/* <DropzoneArea
        dropzoneParagraphClass={classes.root}
        onChange={(files) => handleChange(files)}
      /> */}
      {add ? (
        <>
          <FileUploadContainer>
            <InputLabel>{label}</InputLabel>
            <Typography
              variant="subtitle2"
              style={{
                textAlign: "center",
                marginTop: "65px",
                color: "#8C8C8C",
              }}
            >
              Drag and drop or click to browse here
            </Typography>{" "}
            <Typography
              variant="caption"
              style={{
                textAlign: "center",

                color: "#8C8C8C",
              }}
            >
              Files Supported : CSV ,XLS
            </Typography>
            <UploadFileBtn type="button" onClick={handleUploadBtnClick}>
              <ImFolderUpload fontSize="50px" />
            </UploadFileBtn>
            <FormField
              type="file"
              ref={fileInputField}
              onChange={handleNewFileUpload}
              title=""
              value=""
              {...otherProps}
            />
            <p style={{ color: "red", fontSize: 14 }}>{msg}</p>
          </FileUploadContainer>
          <span>
            <Grid justify="space-between" container spacing={1}>
              {/* <Grid item >
                <p style={{ color: "#979797", fontSize: "11px" }}>File Supported : PNG,JPEG</p>
              </Grid> */}
              {/* <Grid item >
            <p style={{ color: "#979797", fontSize: "9px" }}>Maximum size : 2 MB</p>
          </Grid> */}
            </Grid>
          </span>
        </>
      ) : (
        <FilePreviewContainer>
          <PreviewList>
            {Object.keys(files).map((fileName, index) => {
              let file = files[fileName];
              let isImageFile = file.type.split("/")[0] === "image";
              return (
                <div key={fileName}>
                  <div style={{ width: "100%" }}>
                    <Grid container direction="row" alignItems="center">
                      <Grid item>
                        <TextField
                          fullWidth
                          style={{ fontSize: "10px", width: "100%" }}
                          size="small"
                          variant="outlined"
                          margin="normal"
                          id="name"
                          value={file.name}
                          name="username"
                        />
                      </Grid>
                      <Grid
                        item
                        style={{ marginTop: "9px", marginLeft: "10px" }}
                      >
                        {/* <aside> */}
                        {/* <span>{convertBytesToKB(file.size)} kb</span> */}
                        <DeleteIcon onClick={() => removeFile(fileName)} />
                        {/* </aside> */}
                      </Grid>
                    </Grid>
                    {/* {isImageFile && (
                    <ImagePreview
                      src={URL.createObjectURL(file)}
                      alt={`file preview ${index}`}
                    />
                  )} */}
                    {/* <FileMetaData isImageFile={isImageFile}>
                    <span>{file.name}</span>
                    <aside>
                      <span>{convertBytesToKB(file.size)} kb</span>
                      <DeleteIcon onClick={() => removeFile(fileName)} />
                    </aside>
                  </FileMetaData> */}
                  </div>
                </div>
              );
            })}
          </PreviewList>
        </FilePreviewContainer>
      )}
      {/* <Button component="label" style={{ marginLeft: "10px" }}>
        Upload
        <input type="file" onChange={handleChange} hidden />
      </Button> */}
    </div>
  );
};
export default FileUpload;
