import { React, useEffect, useState } from "react";
import {
  Grid,
  Button,
  TextareaAutosize,
  Avatar,
  Typography,
} from "@material-ui/core";
import withSection from "components/Section/Section";
import moment from "moment";
import { withSnackbar } from "notistack";
import APIs from "../../api/UserManagementAPIs/index";
import APIS from "../../api/LoginApi/index";

function HelpdeskComment(props) {
  const { classes, isDisabled, comments } = props;
  const [todos, setTodos] = useState([]);
  const [commentnew, setCommentnew] = useState({ val: "" });
  console.log(commentnew, "---------00000000");
  useEffect(() => {
    setCommentnew("");
  }, [setCommentnew]);
  return (
    <Grid
      container
      style={isDisabled ? { pointerEvents: "none", opacity: "0.7" } : null}
    >
      <Grid item sm={10} xs={10} md={10}>
        <label htmlFor="message" className={classes.fieldHeading}>
          Add your Comment
        </label>
        <textarea
          name="message"
          value={commentnew.val}
          onChange={(e) => {
            setCommentnew({ ...commentnew, val: e.target.value });
          }}
          className={`${classes.textarea}`}
          rows="8"
        ></textarea>
        <div style={{ marginTop: 10, textAlign: "right" }}>
          <Button
            color="primary"
            variant="contained"
            disabled={false}
            onClick={() => {
              props.handleAddComments(commentnew.val);
              setCommentnew({ ...commentnew, val: "" });
            }}
            style={{ color: "white", padding: "4px 45px" }}
          >
            Send
          </Button>
        </div>
        <Grid item md={12}>
          <HelperComponents arr={comments} />
        </Grid>
      </Grid>
    </Grid>
  );
}
export default withSection(HelpdeskComment, "Helpdesk Comment Section");

const HelperComponents = (props) => {
  const { arr } = props;
  const renderComponent = (val, index) => {
    return <CommentComponents val={val} index={index} key={index} />;
  };
  return arr.map(renderComponent);
};

const CommentComponents = ({ val, index }) => {
  const [userInfo, setUserInfo] = useState({});
  const getUserInfo = async (id) => {
    try {
      let res = await APIS.fetchDetails(id);
      setUserInfo(res.data || {});
    } catch (err) {
      setUserInfo({});
    }
  };
  useEffect(() => {
    getUserInfo(val.createdby);
  }, [val]);
  return (
    <div key={index}>
      <Grid
        container
        spacing={3}
        style={{
          margin: "10px -10px",
          width: "80%",
        }}
        key={index}
      >
        <Grid item>
          <Avatar
            alt="img"
            variant="circle"
            // className={classes.avatar}
            style={{ cursor: "pointer" }}
          />
        </Grid>
        <Grid item sm={8} xs={8}>
          <span>{userInfo?.name || ""}</span>
          <span
            style={{
              font: "normal normal normal 12px/16px Nunito",
              color: "#A8A8A8",
              marginLeft: 10,
            }}
          ></span>
          <p
            style={{
              font: "normal normal normal 14px/19px Nunito",
              color: "#000000",
            }}
          >
            {val.message}
          </p>
          <span
            style={{
              font: "normal normal normal 12px/16px Nunito",
              color: "#A8A8A8",
              marginLeft: 10,
            }}
          ></span>
        </Grid>
      </Grid>
    </div>
  );
};
