import { React, useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import Avatar from "@material-ui/core/Avatar";
import StarIcon from "@material-ui/icons/Star";
import Divider from "@material-ui/core/Divider";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import ShareIcon from "@material-ui/icons/Share";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircle from "@material-ui/icons/AddCircleOutlineOutlined";
import { checkPermission } from "helpers/permissons";
import {
  IconButton,
  CardContent,
  FormGroup,
  Card,
  Grid,
  Button,
  TextField,
  Tooltip,
  Typography,
  FormLabel,
  FormHelperText,
} from "@material-ui/core";
import { RiDeleteBin5Line, RiEditLine } from "react-icons/ri";
import DexTable from "components/DexTable/DexTable.jsx";
import withSection from "components/Section/Section";
import { useEffect } from "react";
import AddIcon from "@material-ui/icons/Add";
import WrappedDexTable from "components/DexTable/WrappedDexTable";
import { confirmDialog } from "components/DeleteConfirmationDialog/Index";
import { dltMsg } from "helpers/helpers";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import { ApplicationOnboardUpadateHiddenCol } from "../ApplicationDetails/Redux/action";
const useStyles = makeStyles((theme) => ({
  table: {
    width: "99%",
  },
  button: {
    padding: "4px 57px",
  },
}));
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {},
  btn: {},
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  // root: {
  //   "&:nth-of-type(odd)": {
  //     backgroundColor: theme.palette.action.hover,
  //   },
  // },
}))(TableRow);

function ApplicationAPI(props) {
  let error = false;
  const { applicationApi, setApplicationApi, permission, onBoardTable } = props;
  const { api } = onBoardTable;
  const classes = useStyles();
  const [isPubsub, setPusub] = useState(false);
  const handleOnchange = (id, value) => {
    if (value.apiType == "Pub/Sub") {
      let { method, url, ...data } = value;
      applicationApi[id] = data;
    } else {
      let { topic, ...data } = value;
      applicationApi[id] = data;
    }
    console.log(applicationApi, "check topic");
    setApplicationApi([...applicationApi]);
  };

  useEffect(() => {
    if (props.application_API) {
      setApplicationApi([...props.application_API]);
    }
  }, [props.application_API, setApplicationApi]);

  const addApplicationApi = () => {
    let updateApplicationApi = [...applicationApi];
    let createApplicationApi = {
      apiType: "",
      url: "",
      method: "",
    };
    updateApplicationApi.push(createApplicationApi);
    setApplicationApi([...updateApplicationApi]);
  };
  const removeApplicationApi = (id) => {
    if (applicationApi.length > 1) {
      let copy = [...applicationApi];
      let deleteApplicationApi = copy.filter((item, index) => {
        return index != id;
      });
      setApplicationApi([...deleteApplicationApi]);
    }
    if (props.duplicate[2].length !== 0) {
      setDuplicateNull();
    }
  };
  console.log(applicationApi, "paramsappapi");

  const setDuplicateNull = () => {
    let handleDuplicate = [...props.duplicate];
    handleDuplicate[2] = [];
    props.setDuplicate([...handleDuplicate]);
  };

  const pushToCreateForm = () => {
    let data = {
      name: "",
      apiType: "",
      url: "",
      method: "",
      deprication_date: "",
      key: props.application_API.length,
      source: {
        cluster: "",
        topic: "",
        username: "",
        password: "",
      },
    };
    props.history.push("/app/create-api-form", {
      apiData: data,
      edit: false,
    });
  };
  const editAPI = ({ row }) => {
    console.log(row, "this is edit", 23);
    props.history.push("/app/create-api-form", {
      apiData: row,
      edit: true,
    });
  };

  const removeAPI = async ({ row }) => {
    console.log(row, "row edit");
    let data = [...applicationApi];

    let filterApplicationApi = data.filter((item, index) => {
      return item.key !== row.key;
    });
    await props.deleteAPI(filterApplicationApi);
  };

  const actionProvider = (row) => {
    console.log(row, "3436436");
    return (
      <div
        style={{
          display: "flex",
        }}
      >
        <div style={{ marginRight: 20 }}>
          <Tooltip title="Edit" aria-label="edit">
            <IconButton
              size="small"
              color="inherit"
              disabled={!checkPermission("btn79")}
              onClick={() => checkPermission("btn79") && editAPI(row)}
            >
              <RiEditLine fontSize="20px" />
            </IconButton>
          </Tooltip>
        </div>
        <div>
          <Tooltip title="Delete" aria-label="delete">
            <IconButton
              size="small"
              color="inherit"
              disabled={!checkPermission("btn85")}
              onClick={() =>
                checkPermission("btn85") &&
                confirmDialog(dltMsg(row?.row?.name || ""), () =>
                  removeAPI(row)
                )
              }
            >
              <RiDeleteBin5Line fontSize="20px" />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    );
  };
  const provideType = ({ row }) => {
    const topic = row?.source?.topic || "";
    return (
      <Typography variant="body2">
        {row.apiType === "Https" ? row.url : topic}{" "}
      </Typography>
    );
  };

  const message_brokerFunc = ({ row }) => {
    const message_broker = row.messageBroker || "";
    return <Typography variant="body2">{message_broker} </Typography>;
  };
  const cluster_func = ({ row }) => {
    const cluster = row?.source?.cluster || "";
    return <Typography variant="body2">{cluster} </Typography>;
  };

  const username_func = ({ row }) => {
    const username = row?.source?.username || "";

    return <Typography variant="body2">{username} </Typography>;
  };

  const password_func = ({ row }) => {
    const password = row?.source?.password || "";

    return <Typography variant="body2"> {password} </Typography>;
  };

  return (
    <div style={{ overflow: "auto" }}>
      <Grid container>
        <Grid
          item
          md={12}
          container
          alignItems="center"
          justifyContent="flex-end"
        >
          <Button
            style={{ marginBottom: "15px" }}
            size="small"
            variant="contained"
            onClick={() => checkPermission("btn73") && pushToCreateForm()}
            startIcon={<AddIcon />}
            className={classes.button}
            disabled={!checkPermission("btn73")}
          >
            Create API
          </Button>
        </Grid>
        <Grid item xs={12}>
          <WrappedDexTable
            data={[...applicationApi]}
            // hideColumnChooser={true}
            columWidthsType="percentage"
            columnOrder={[
              "name",
              "apiType",
              "type",
              "method",
              "messageBroker",
              "cluster",
              "username",
              "password",
              "actions",
            ]}
            columns={[
              { name: "name", title: "API Name" },
              { name: "apiType", title: "API Type" },
              { name: "type", title: "URL/Topic" },
              { name: "method", title: "Method" },
              {
                name: "messageBroker",
                title: "Message Broker",
              },
              {
                name: "cluster",
                title: "Cluster",
              },
              {
                name: "username",
                title: "User Name",
              },
              // {
              //   name: "password",
              //   title: "Password",
              // },
              { name: "actions", title: "Actions" },
            ]}
            formatters={[
              { formatterFn: actionProvider, columns: ["actions"] },
              { formatterFn: provideType, columns: ["type"] },
              { formatterFn: message_brokerFunc, columns: ["messageBroker"] },
              { formatterFn: cluster_func, columns: ["cluster"] },
              { formatterFn: username_func, columns: ["username"] },
              { formatterFn: password_func, columns: ["password"] },
            ]}
            defaultColumnWidths={[
              { columnName: "name", width: 16 },
              { columnName: "apiType", width: 16 },
              { columnName: "type", width: 16 },
              { columnName: "method", width: 16 },
              { columnName: "messageBroker", width: 16 },
              { columnName: "cluster", width: 16 },
              { columnName: "username", width: 16 },
              { columnName: "password", width: 16 },
              { columnName: "actions", width: 16 },
            ]}
            hidden={[...api]}
            setHiddenColmn={(hide) => {
              props.setHiddenColumn(hide, "api");
            }}
            pagination={false}
            noDataMsg="API not found"
            Read={checkPermission("btn217")}
          />
        </Grid>
      </Grid>
    </div>
  );
}
const mapDispatchToProps = (dispatch) => ({
  setHiddenColumn: (hide, type) =>
    dispatch(ApplicationOnboardUpadateHiddenCol(hide, type)),
});

const mapStateToProps = (state) => {
  return {
    onBoardTable: state.appDetailsRedcer.onBoardTable,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withSection(ApplicationAPI, "API")));
