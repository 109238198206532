import { Chip, makeStyles } from "@material-ui/core";
import React from "react";

export const useStyles = makeStyles((theme) => ({
  selectedServiceChips: {
    marginTop: 20,
  },
  selectedServiceChip: {
    marginRight: 15,
    marginBottom: 15,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #707070",
    borderRadius: "16px",
    "& span": {
      font: "normal normal 600 14px/19px Nunito",
      color: "#000000",
      marginRight: 10,
    },
    "& svg": {
      color: theme.palette.primary.main,
    },
    "&.Mui-disabled": {
      opacity: 1,
      "& svg": {
        display: "none",
      },
    },
  },
}));

const CustomChips = (props) => {
  const styles = useStyles();

  return (
    <div className={styles.selectedServiceChips}>
      {props.chipsData.map((data, index) => (
        <Chip
          label={data.name}
          onDelete={() => props.handleDelete(data)}
          className={styles.selectedServiceChip}
          key={index}
          disabled={props.isEditMode}
        />
      ))}
    </div>
  );
};

export default CustomChips;
