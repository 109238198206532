const Styles = (theme) => ({
  title: {
    textTransform: "capitalize",
    fontWeight: "bold",
    justifyContent: "center",
  },
  divider: {
    marginTop: "12px",
    height: "2px",
    background: "rgb(175, 175, 175)",
  },
  liststyle: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #e0e0e0",
    opacity: 1,
    width: "400px",
    // padding: "5px 0",
    borderRadius: 0,
  },
  radioStyle: {
    height: "42px",
  },
  teamCardStyle: {
    // width: "345px",
    marginRight: "10px",
    // backgroundColor: "transparent",
    background: "rgb(245, 245, 245)",
    border: "1px solid rgb(203, 203, 203)",
  },
});
export default Styles;
