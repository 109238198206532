import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";
import AddCircle from "@material-ui/icons/AddCircleOutlineOutlined";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { checkPermission } from "helpers/permissons";
import {
  IconButton,
  CardContent,
  FormGroup,
  Card,
  Grid,
  Button,
  TextField,
  Typography,
  FormLabel,
  Select,
  Tooltip,
  InputLabel,
  FormHelperText,
} from "@material-ui/core";
import withSection from "components/Section/Section";
import { RiDeleteBin5Line, RiEditLine } from "react-icons/ri";
import DexTable from "components/DexTable/DexTable.jsx";
import { useEffect } from "react";
import WrappedDexTable from "components/DexTable/WrappedDexTable";
import { confirmDialog } from "components/DeleteConfirmationDialog/Index";
import { dltMsg } from "helpers/helpers";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import { ApplicationOnboardUpadateHiddenCol } from "../ApplicationDetails/Redux/action";
const useStyles = makeStyles((theme) => ({
  line: {
    marginTop: -23,
  },
  table: {
    width: "99%",
  },

  dividerline: {
    // border: "1px solid black",
    width: "70%",
    margin: "-25px 0px 0px 125px",
    [theme.breakpoints.up("md")]: {
      width: "82%",
      margin: "-25px 0px 0px 260px",
    },
  },
}));
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,

    color: theme.palette.common.white,
  },
  body: {
    // height: "14vh",
    width: 15,
    height: 1,
  },
  btn: {
    width: "300px",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({}))(TableRow);

function ApplicationWebhooks(props) {
  let error = false;
  // const webhookData = useRef()
  const {
    applicationWebhook_multiple,
    setApplicationWebhook_multiple,
    webhooks,
    permission,
    onBoardTable,
  } = props;
  const { webhook } = onBoardTable;
  const classes = useStyles();

  console.log(webhooks, "this is webhooks");
  useEffect(() => {
    setApplicationWebhook_multiple([...webhooks]);
  }, [webhooks]);

  const removeApiWebhook = (id) => {
    if (applicationWebhook_multiple.length > 1) {
      let copy = [...applicationWebhook_multiple];
      let deleteApplicationWebhook = copy.filter((item, index) => {
        return index != id;
      });
      setApplicationWebhook_multiple(deleteApplicationWebhook);
    }
    if (props.duplicate[5].length !== 0) {
      setDuplicateNull();
    }
  };

  const addApplicationWebhook = () => {
    let updateApplicationWebhook_multiple = [...applicationWebhook_multiple];
    let createApplicationWebhook = {
      data: {
        type: "",
        url: "",
        method: "",
        apiKey: "",
      },
    };
    updateApplicationWebhook_multiple.push(createApplicationWebhook);
    setApplicationWebhook_multiple(updateApplicationWebhook_multiple);
  };

  const handleOnchange = (id, value) => {
    console.log({ id, value });
    applicationWebhook_multiple[id].data = value;
    setApplicationWebhook_multiple([...applicationWebhook_multiple]);
  };

  console.log(props.duplicate, "webhooks");

  const setDuplicateNull = () => {
    let handleDuplicate = [...props.duplicate];
    handleDuplicate[5] = [];
    props.setDuplicate([...handleDuplicate]);
  };

  const pushToWebhooksForm = () => {
    let data = {
      type: "",
      url: "",
      method: "",
      apiKey: "",
      key: props.webhooks.length || 0,
    };
    props.history.push("/app/create-webhook-form", {
      webhooksData: data,
      edit: false,
    });
  };

  const editWebhooks = ({ row }) => {
    console.log(row, "edit");
    props.history.push("/app/create-webhook-form", {
      webhooksData: row,
      edit: true,
    });
  };

  const removeWebhooks = async ({ row }) => {
    console.log(row, "row remove");
    let data = [...applicationWebhook_multiple];

    let filteredWebhooks = data.filter((item, index) => {
      return item.key !== row.key;
    });
    await props.deleteWebhhoks(filteredWebhooks);
  };

  const actionProvider = (row) => {
    console.log(row, "3436436");
    return (
      <div
        style={{
          display: "flex",
        }}
      >
        <div style={{ marginRight: 20 }}>
          <Tooltip title="Edit" aria-label="edit">
            <IconButton
              size="small"
              color="inherit"
              disabled={!checkPermission("btn82")}
              onClick={() => checkPermission("btn82") && editWebhooks(row)}
            >
              <RiEditLine fontSize="20px" />
            </IconButton>
          </Tooltip>
        </div>
        <div>
          <Tooltip title="Delete" aria-label="delete">
            <IconButton
              size="small"
              color="inherit"
              disabled={!checkPermission("btn88")}
              onClick={() =>
                checkPermission("btn88") &&
                confirmDialog(dltMsg("this webhook"), () => removeWebhooks(row))
              }
            >
              <RiDeleteBin5Line fontSize="20px" />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid xs={12} item container justifyContent="flex-end">
          <Button
            size="small"
            variant="contained"
            onClick={() => checkPermission("btn76") && pushToWebhooksForm()}
            startIcon={<AddIcon />}
            style={{ padding: "4px 40px 4px 40px" }}
            disabled={!checkPermission("btn76")}
          >
            Create Webhooks
          </Button>
        </Grid>
        <Grid item xs={12}>
          <WrappedDexTable
            data={[...applicationWebhook_multiple]}
            // hideColumnChooser={true}
            columWidthsType="percentage"
            columnOrder={["type", "url", "method", "apiKey", "actions"]}
            columns={[
              { name: "type", title: "Type" },
              { name: "url", title: "URL" },
              { name: "method", title: "Method" },
              { name: "apiKey", title: "API Key" },
              { name: "actions", title: "Actions" },
            ]}
            formatters={[{ formatterFn: actionProvider, columns: ["actions"] }]}
            defaultColumnWidths={[
              { columnName: "type", width: 19 },
              { columnName: "url", width: 25 },
              { columnName: "method", width: 16 },
              { columnName: "apiKey", width: 24 },
              { columnName: "actions", width: 15 },
            ]}
            hidden={[...webhook]}
            setHiddenColmn={(hide) => {
              props.setHiddenColumn(hide, "webhook");
            }}
            pagination={false}
            noDataMsg="Webhooks not found"
            Read={checkPermission("btn221")}
          />
        </Grid>
      </Grid>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setHiddenColumn: (hide, type) =>
    dispatch(ApplicationOnboardUpadateHiddenCol(hide, type)),
});

const mapStateToProps = (state) => {
  return {
    onBoardTable: state.appDetailsRedcer.onBoardTable,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withSection(ApplicationWebhooks, "Webhooks")));
