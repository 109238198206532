import * as types from "./actionTypes";
export default function reducer(
  state = {
    userInfo: {
      dateFormat: "YYYY-MM-DD",
      timeZone: "UTC",
      time: "h:mm",
      color: "#002855",
    },
  },
  action = {}
) {
  switch (action.type) {
    case types.USER_DETAILS_REQUEST:
      return { ...state, loading: true };
    case types.USER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        userInfo: { ...state.userInfo, ...action.payload },
      };
    case types.USER_RESET:
      return {
        ...state,
        userInfo: {
          dateFormat: "YYYY-MM-DD",
          timeZone: "UTC",
          time: "h:mm",
          color: "#A6AB83",
        },
        userFavourites: [],
        userSubscriptions: [],
      };
    case types.USER_RETAIN:
      return {
        ...state,
        success: false,
      };
    case types.USER_DETAILS_FAIL:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
}
