import getURL from "../config";
import API_helpers from "../index";

const getDataStandardsById = async (id) => {
  try {
    let url = getURL("FETCH_DATASTANDARDS") + `/${id}`;
    const res = await API_helpers.GET(url, {
      headers: {
        "Content-Type": "application/json",
        userdetails: JSON.stringify({ name: "palani" }),
        organization: "11111",
      },
    });
    return res;
  } catch (err) {
    throw err;
  }
};

async function fetchAllDataStandardsWithParams(page, size, filters, sort) {
  let url = getURL("FETCH_DATASTANDARDS");
  url = url + "?";
  let params = { page, size, filters, sort };
  Object.keys(params)
    .filter((k) => params[k] != undefined || params[k] != null)
    .forEach((k) => (url = `${url}${k}=${params[k]}&`));
  url = url.substr(0, url.length - 1);
  console.log(url, "urll");
  try {
    let res = await API_helpers.GET(url, {
      headers: {
        "Content-Type": "application/json",
        userdetails: JSON.stringify({ name: "palani" }),
        organization: "11111",
      },
    });
    return res.data;
  } catch (err) {
    throw err;
  }
}

async function blockDatatandards(dataStandardId, type) {
  let url = getURL("BLOCK_DATASTANDARDS")(dataStandardId, type);
  try {
    let res = await API_helpers.PUT(
      url,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          userdetails: JSON.stringify({ name: "palani" }),
          organization: "11111",
        },
      }
    );
    return res;
  } catch (err) {
    throw err;
  }
}

const AddDatastandards = async (payload) => {
  try {
    let res = await API_helpers.POST(getURL("ADD_DATASTANDARDS"), payload, {
      headers: {
        "Content-Type": "application/json",
        userdetails: JSON.stringify({ name: "palani" }),
        organization: "11111",
      },
    });
    return res;
  } catch (err) {
    throw err;
  }
};

const editDataStandards = async (dataStandardId, payload) => {
  try {
    let res = await API_helpers.PUT(
      getURL("EDIT_DATASTANDARDS")(dataStandardId),
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          userdetails: JSON.stringify({ name: "palani" }),
          organization: "11111",
        },
      }
    );
    return res;
  } catch (err) {
    throw err;
  }
};

const bulkUploadFieldValue = async (field_name, payload) => {
  try {
    let res = await API_helpers.POST(
      getURL("BULK_UPLOAD_FIELD_VALUE")(field_name),
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return res;
  } catch (err) {
    throw err;
  }
};

const bulkUploadfiles = async (payload) => {
  try {
    let res = await API_helpers.POST(getURL("BULKUPLOAD_FILES"), payload, {
      headers: {
        "Content-Type": "multipart/form-data",
        userdetails: JSON.stringify({ name: "palani" }),
        organization: "11111",
      },
    });
    return res;
  } catch (err) {
    throw err;
  }
};

const fetchValueMap = async (name, page, size, filters, sort) => {
  let url = getURL("FETCH_ALL_VALUEMAP_DATA")(name);
  url = url + "?";
  let params = { page, size, filters, sort };
  Object.keys(params)
    .filter((k) => params[k] != undefined || params[k] != null)
    .forEach((k) => (url = `${url}${k}=${params[k]}&`));
  url = url.substr(0, url.length - 1);
  console.log(url, "urll");
  try {
    let res = await API_helpers.GET(url, {
      headers: {
        "Content-Type": "application/json",
        userdetails: JSON.stringify({ name: "palani" }),
        organization: "11111",
      },
    });
    return res;
  } catch (err) {
    throw err;
  }
};



var APIs = {
  fetchAllDataStandardsWithParams,
  blockDatatandards,
  AddDatastandards,
  editDataStandards,
  bulkUploadfiles,
  fetchValueMap,
  getDataStandardsById,
  bulkUploadFieldValue,
};

export default APIs;
