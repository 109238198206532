import React from "react";

import {
  Grid,
  Button,
  Typography,
  Divider,
  Card,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";

// import {
//   TextValidator,
//   SelectValidator,
//   ValidatorForm,
// } from "react-material-ui-form-validator";
import DexTable from "components/DexTable/DexTable";

const UploadFile = (props) => {
  const {
    openmodal,
    handleCloseModal,
    handleClose,
    property,
    classes,
    Modaldata,
    Appdata,
    ModalTabledata,
    slabs,
    total,
  } = props;

  console.log(slabs, "3456789sdfgh");
  // console.log(ModalTabledata, "ddd");
  // const data = [
  //   {
  //     requests: "gelli",
  //     price: "jjj",
  //     total: "asas",
  //   },
  // ];

  return (
    <>
      {/* <ValidatorForm> */}
      <Dialog
        // fullWidth={true}
        maxWidth="md"
        open={openmodal}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <DialogTitle id="alert-dialog-title">
          {/* <Typography color="primary">API Calls </Typography> */}
        </DialogTitle>
        {/* <Divider /> */}
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <Typography variant="body1">{"Slab wise breakup"}</Typography>
            </Grid>

            <Grid item sm={12}>
              <DexTable
                data={[...(slabs || [])]}
                columnOrder={["Start", "End", "Quantity", "Price", "TotalCost"]}
                columns={[
                  {
                    name: "End",
                    title: "End",
                  },
                  {
                    name: "Start",
                    title: "start",
                  },
                  {
                    name: "Quantity",
                    title: "Quantity",
                  },
                  { name: "Price", title: "Price" },
                  { name: "TotalCost", title: "Total Price" },
                ]}
                columWidthsType="percentage"
                defaultColumnWidths={[
                  { columnName: "Start", width: 15 },
                  { columnName: "End", width: 15 },
                  { columnName: "Quantity", width: 20 },
                  { columnName: "Price", width: 20 },
                  { columnName: "TotalCost", width: 20 },
                ]}
                disableReordering={false}
                // loading={loading}
                hideColumnChooser={true}
                tableType="virtual"
              />
            </Grid>
            <Grid item sm={12} className="d-flex flex-row justify-content-end">
              <Typography
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
                variant="body1"
              >
                Total Amount :
              </Typography>
              &nbsp; &nbsp;
              <Typography
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
                variant="body2"
              >
                {total}
              </Typography>
            </Grid>
          </Grid>

          {/* <hr /> */}
        </DialogContent>
        <DialogActions className="mb-2">
          <Button size="small" variant="outlined" onClick={handleCloseModal}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/* </ValidatorForm> */}
    </>
  );
};

export default UploadFile;
