export const FETCHING_BILLABLE_SERVICES = "FETCH_BILLABLE_SERVICES";
export const FETCHING_BILLABLE_SERVICES_SUCCESS =
  "FETCHING_BILLABLE_SERVICES_SUCCESS";
export const FETCHING_BILLABLE_SERVICES_FAILURE =
  "FETCHING_BILLABLE_SERVICES_FAILURE";

export const EDIT_BILLABLE_SERVICES = "EDIT_BILLABLE_SERVICES";
export const EDIT_BILLABLE_SERVICES_SUCCESS = "EDIT_BILLABLE_SERVICES_SUCCESS";
export const EDIT_BILLABLE_SERVICES_FAILURE = "EDIT_BILLABLE_SERVICES_FAILURE";

export const DELETE_BILLABLE_SERVICES = "DELETE_BILLABLE_SERVICES";
export const DELETE_BILLABLE_SERVICES_SUCCESS =
  "DELETE_BILLABLE_SERVICES_SUCCESS";
export const DELETE_BILLABLE_SERVICES_FAILURE =
  "DELETE_BILLABLE_SERVICES_FAILURE";

export const ADD_BILLABLE_SERVICES = "ADD_BILLABLE_SERVICES";
export const ADD_BILLABLE_SERVICES_SUCCESS = "ADD_BILLABLE_SERVICES_SUCCESS";
export const ADD_BILLABLE_SERVICES_FAILURE = "ADD_BILLABLE_SERVICES_FAILURE";

export const UPDATE_BILLABLE_FILTER_DATA = "UPDATE_BILLABLE_FILTER_DATA";
export const CLEAR_BILLABLE_FILTER_DATA = "CLEAR_BILLABLE_FILTER_DATA";

export const UPDATE_BILLABLE_SORT = "UPDATE_BILLABLE_SORT";

export const UPDATE_BILLABLE_HIDDEN_COL = "UPDATE_BILLABLE_HIDDEN_COL";
