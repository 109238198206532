import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import { Button, TextField, Typography } from "@material-ui/core";
import Add from "@material-ui/icons/Add";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
const filter = createFilterOptions();
const top100Films = [
  { title: "The Shawshank Redemption", year: 1994 },
  { title: "The Godfather", year: 1972 },
  { title: "The Godfather: Part II", year: 1974 },
  { title: "The Dark Knight", year: 2008 },
];
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "start",
    flexWrap: "wrap",
    listStyle: "none",
    margin: 0,
  },
  chipBtn: {
    backgroundColor: "white",
    border: "solid 0.1px gray",
    borderRadius: 20,
    color: "gray",
    padding: theme.spacing(0.5),
    margin: theme.spacing(0.5),
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

export default function AddTags(props) {
  const classes = useStyles();
  const { chipData, setChipData, defaultTags, value, edit } = props;

  const addTags = (valueArray) => {
    console.log("slkdfjsf", valueArray);
    let updateChipdata = [...valueArray];
    if (edit) {
      setChipData([...updateChipdata]);
    } else {
      props.handleOnChange({ ...props.basicInfo, tags: [...updateChipdata] });
    }
  };
  console.log(chipData, "fvfghfgh");

  let isTrue =
    !props.editDisable && props.editDisable !== undefined ? true : false;

  return (
    <div className={classes.root}>
      <div className="w-100">
        <div className="w-100">
          <Autocomplete
            multiple
            disabled={isTrue}
            value={value}
            onChange={(event, newValue) => addTags(newValue)}
            size="small"
            variant="outlined"
            fullWidth
            id="tags-filled"
            options={defaultTags.map((option) => option)}
            freeSolo
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField size="small" {...params} variant="outlined" />
            )}
          />
        </div>
      </div>
    </div>
  );
}
