/**
 * import all actions
 */

import * as actions from "./actionTypes";
import HelpDeskAPIS from "api/HelpDeskAPIs/index";

export default {
  addHelpDeskData,
  editHelpDeskData
};

export const getData = () => async (dispatch) => {
  try {
    let res = await HelpDeskAPIS.fetchAllHelpDeskData();
    console.log(res.data, "6586587687");
    dispatch({
      type: actions.FETCH_HELPDESKDATA,
      payload: res.data || [],
    });
  } catch (err) {
    dispatch({
      type: actions.FETCH_HELPDESKDATA_FAILURE,
    });
  }
};

function addHelpDeskData(payload) {
  return async (dispatch) => {
    dispatch({
      type: actions.ADD_HELP_DESK_DATA,
      loading: true,
    });
    try {
      let adddata = await HelpDeskAPIS.addHelpDeskData(payload);
      dispatch({
        type: actions.ADD_HELP_DESK_DATA_SUCCESS,
        loading: false,
        adddata,
      });
    } catch (err) {
      dispatch({
        type: actions.ADD_HELP_DESK_DATA_FAILURE,
        loading: false,
        error: err,
      });
    }
  };
}

function editHelpDeskData(id, payload) {
  return async (dispatch) => {
    dispatch({
      type: actions.EDIT_HELP_DESK_DATA,
      loading: true,
    });
    try {
      let editdata = await HelpDeskAPIS.editHelpDeskData(id, payload);
      dispatch({
        type: actions.EDIT_HELP_DESK_DATA_SUCCESS,
        loading: false,
        editdata,
      });
    } catch (err) {
      dispatch({
        type: actions.EDIT_HELP_DESK_DATA_FAILURE,
        loading: false,
        error: err,
      });
    }
  };
}
