import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  TableHead,
  TableRow,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Checkbox,
  Typography,
  IconButton,
  Grid,
  Select,
  FormControl,
  Tooltip,
  capitalize,
} from "@material-ui/core/";

import { useEffect } from "react";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,

    color: theme.palette.common.white,
    padding: 10,
    paddingLeft: 15,
    fontSize: 14,
  },
  body: {
    fontSize: 12,
    backgroundColor: "#F0F0F0",
    paddingTop: 0,
    paddingBottom: 1,
  },
}))(TableCell);

const getCDUoptions = (cduMasterValues) => {
  return cduMasterValues.map((v) => {
    return { value: v.dataKey, label: v.dataKey };
  });
};

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const rows = [{ field_name: "Value" }, { field_name: "Value2" }];

export default function Outgoingcdumappingtable(props) {
  const { active, values, cduValues, handleInputCDUvalues, type } = props;
  const classes = useStyles();
  const [rows, setRows] = React.useState(values);
  useEffect(() => {
    setRows(values);
  }, [values, active]);
  const onChange = (e, index) => {
    console.log(e);
    let cduMap = rows[index] || {};
    let newcduMap = { ...cduMap };
    newcduMap[e.currentTarget.name] = e.currentTarget.value;
    rows[index] = newcduMap;
    let new_rows = [...rows];
    console.log({ rows });
    handleInputCDUvalues(type, [...new_rows]);
  };

  const addRow = () => {
    console.log("sdfsdfsdf");
    let copy = [...rows];
    let addId = {
      key: rows.length + 1,
    };
    // copy.push(addId);
    setRows([...copy, addId]);
    handleInputCDUvalues(type, [...copy, addId]);
  };

  const removeRow = () => {
    if (rows.length != 1) {
      let copy = [...rows];
      let rowRemoved = copy.filter((row) => {
        return row.key != copy.length;
      });
      setRows([...rowRemoved]);
      handleInputCDUvalues(type, [...rowRemoved]);
    }
  };

  return (
    <TableContainer
      className="pr-3 pl-3"
      // style={{ border: "1px solid red", overflowY: "auto", width: "100%" }}
    >
      <Table className={classes.table} aria-label="customized table">
        <TableHead size="small">
          <TableRow>
            <StyledTableCell style={{ width: "30%" }}>
              {" "}
              CDU Field
            </StyledTableCell>
            <StyledTableCell style={{ width: "30%" }} align="left">
              Application Field
            </StyledTableCell>
            <StyledTableCell style={{ width: "20%" }} align="center">
              Actions
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {values.map((row, index) => (
            <>
              <StyledTableRow>
                <StyledTableCell scope="row">
                  <Typography>{row.cdu_name}</Typography>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <TextField
                    style={{ backgroundColor: "white" }}
                    id="outlined-search"
                    name="field_name"
                    type="text"
                    variant="outlined"
                    size="small"
                    fullWidth
                    margin="normal"
                    value={row.field_name}
                    onChange={(e) => onChange(e, index)}
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Checkbox
                    name="valueMap"
                    checked={row.valueMap}
                    onChange={(e) =>
                      onChange(
                        {
                          currentTarget: {
                            name: e.currentTarget.name,
                            value: e.currentTarget.checked,
                          },
                        },
                        index
                      )
                    }
                    color="primary"
                  />
                </StyledTableCell>
              </StyledTableRow>
            </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
