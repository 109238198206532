import React, { Component } from "react";
import Loader from "components/common/stuff/Loader";
import {
  Typography,
  Grid,
  Button,
  Tooltip,
  IconButton,
  Badge,
  Chip,
} from "@material-ui/core";
import withSection from "components/Section/Section";
import AddIcon from "@material-ui/icons/Add";
import DoneAllOutlinedIcon from "@material-ui/icons/DoneAllOutlined";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import DexTable from "components/DexTable/DexTable";
import WrappedDexTable from "components/DexTable/WrappedDexTable";

import { Pagination } from "@material-ui/lab";
import { withRouter } from "react-router-dom";
import pricingMasterAPIS from "api/PricingMasterAPIs/index";
import ModalApproveComponent from "./component/ModalApproveComponent";
import Styles from "./styles.js";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";
import { withSnackbar } from "notistack";
import actions from "./redux/action";
import { TripOriginSharp } from "@material-ui/icons";
import PrintCrDrNote from "./print/PrintCrDrNote";

class CreditNote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      approvemodal: false,
      rejectmodal: false,
      allPublishedApp: [],
      setOpen: false,
      InvoiceData: [],
      ID: 0,
      info: { status: "", invoiceId: 0 },
      rejectinfo: { status: "", invoiceId: 0 },
    };
  }

  helperFunc = async (msg, type, id, CustomerId) => {
    this.props.enqueueSnackbar(msg, {
      variant: type ? "success" : "error",
      autoHideDuration: 2000,
    });
    id && this.props.getNotes(id, CustomerId);
  };

  approveData = async () => {
    const { locationData, data } = this.props;
    this.props.setLoading(true);
    if (this.state.info.status !== "Approved") {
      try {
        let res = await this.props.ApproveCredit_Debit(
          locationData.ID,
          this.state.selectedRow.ID,
          "Approved"
        );
        this.props.setLoading(false);
        this.helperFunc(
          res.data._msg,
          true,
          locationData.ID,
          locationData.CustomerId
        );
      } catch (err) {
        let msg = err?.response?.data?._msg || "Network  issue!";
        this.helperFunc(msg, false);
        this.props.setLoading(false);
      }
    }
    this.setState({
      setOpen: false,
    });
  };

  rejectData = async () => {
    const { locationData, data } = this.props;
    this.props.setLoading(true);
    if (this.state.rejectinfo.status !== "Rejected") {
      try {
        let res = await this.props.RejectCredit_Debit(
          locationData.ID,
          this.state.selectedRow.ID,
          "Rejected"
        );
        this.props.setLoading(false);
        this.helperFunc(
          res.data._msg,
          true,
          locationData.ID,
          locationData.CustomerId
        );
      } catch (err) {
        let msg = err?.response?.data?._msg || "Network  issue!";
        this.helperFunc(msg, false, locationData.ID);
        this.props.setLoading(false);
      }
    }
    this.setState({
      setOpen: false,
    });
  };

  handleClose = () => {
    this.setState({
      setOpen: false,
    });
  };

  createCredit = (e) => {
    const { invoiceDataID } = this.props;
    console.log(invoiceDataID, "crid");
    let data = {
      applicationId: "",
      billableId: "",
      module: {},
      type: "credit",
      amount: "",
      description: "",
    };
    this.props.history.push("/app/create-credit", {
      invoiceDataID: invoiceDataID,
      Credit_Data: data,
      Invoice_Data: this.props.locationData,
    });
  };

  createDebit = () => {
    const { invoiceDataID } = this.props;
    let data = {
      applicationId: "",
      billableId: "",
      module: {},
      type: "debit",
      amount: "",
      description: "",
    };
    this.props.history.push("/app/create-debit", {
      invoiceDataID: invoiceDataID,
      Debit_Data: data,
      allPublishedApp: this.state.allPublishedApp,
      Invoice_Data: this.props.locationData,
    });
  };

  viewCreditData = (row, e) => {
    e && e.stopPropagation();
  };

  handleClickApprove = (row, e) => {
    e && e.stopPropagation();
    console.log(row, "ree");
    this.setState({
      info: {
        //   name: row.name,
        creditId: row.invoiceId,
        status: row.status,
      },
      selectedRow: row,
      approvemodal: true,
      rejectmodal: false,
      setOpen: true,
    });
  };

  handleClickReject = (row, e) => {
    e && e.stopPropagation();
    this.setState({
      rejectinfo: {
        //   name: row.name,
        rejectId: row.invoiceId,
        status: row.status,
      },
      selectedRow: row,
      approvemodal: false,
      rejectmodal: true,
      setOpen: true,
    });
  };

  statusProvider = ({ value }) => {
    let status = value || "";
    if (status === "Approved") {
      return (
        <div className="d-flex justify-content align-items-center">
          <Badge
            classes={{ badge: this.props.classes.activeBadge }}
            variant="dot"
            style={{ marginRight: "10px" }}
          />
          <Typography variant="body1"> {status} </Typography>
        </div>
      );
    }
    if (status === "Pending") {
      return (
        <div className="d-flex justify-content align-items-center">
          <Badge
            classes={{ badge: this.props.classes.pendingBadge }}
            variant="dot"
            style={{ marginRight: "10px" }}
          />
          <Typography variant="body1"> Pending </Typography>
        </div>
      );
    } else {
      return (
        <div className="d-flex justify-content align-items-center">
          <Badge color="error" variant="dot" style={{ marginRight: "10px" }} />
          <Typography variant="body1"> Rejected </Typography>
        </div>
      );
    }
  };

  amountProvider = ({ value }) => {
    let amount = value;
    return <div>{value ? <Typography> $ {amount}</Typography> : "-"}</div>;
  };

  tagProvider = ({ value }) => {
    const style = {
      color: value === "debit" ? "red" : "black",
    };
    return (
      <div>{value ? <Chip label={value} style={{ ...style }} /> : "-"}</div>
    );
  };

  issuedataProvider = ({ row }) => {
    return (
      <Typography variant="body1"> {row.CreatedAt.split("T")[0]} </Typography>
    );
  };
  billableService = ({ row }) => {
    return <Typography>{row?.BillableDetails?.name || ""}</Typography>;
  };
  applicationName = ({ row }) => {
    return <Typography>{row?.AppDetails?.name || ""}</Typography>;
  };
  actionProvider = ({ row }) => {
    let flag = row.status !== "Pending" ? true : false;
    let rejectflag = row.status !== "Pending" ? true : false;
    return (
      <div>
        {row.status == "Pending" ? (
          <>
            <Grid container spacing={2}>
              <Grid item>
                <Tooltip
                  title={rejectflag ? "Cancel" : "Reject"}
                  placement="bottom"
                >
                  <IconButton
                    size="small"
                    aria-label="reject"
                    onClick={(e) => {
                      this.handleClickReject(row, e);
                    }}
                  >
                    <CancelOutlinedIcon
                      style={{ fontSize: "20px" }}
                      className={
                        rejectflag
                          ? this.props.classes.reject
                          : this.props.classes.approvecancel
                      }
                    />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title={flag ? "Cancel" : "Approve"} placement="bottom">
                  <IconButton
                    size="small"
                    aria-label="approve"
                    onClick={(e) => {
                      this.handleClickApprove(row, e);
                    }}
                  >
                    <DoneAllOutlinedIcon
                      style={{ fontSize: "20px" }}
                      className={
                        flag
                          ? this.props.classes.approve
                          : this.props.classes.approvecancel
                      }
                    />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </>
        ) : (
          "-"
        )}
      </div>
    );
  };

  render() {
    const { classes, data, totalCount, loading, isPrint, hidden, load } =
      this.props;
    console.log(this.state.InvoiceData, "fdfghjkl");
    const { info, rejectinfo } = this.state;
    let type = info.status === "Pending" ? "Approve" : "Cancel";
    let rejecttype = rejectinfo.status === "Pending" ? "Reject" : "Cancel";
    let flag = info.status !== "Pending" ? true : false;

    return (
      <>
        {/* {
          <Grid
            // item
            // md={12}
            // sm={12}
            // lg={12}
            // className="mt-1"
            style={{ overflowX: "auto", border: "solid 1px red" }}
          > */}
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={2}
        >
          <Grid item md={2}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              size="medium"
              alignItems="center"
              onClick={(e) => this.createCredit(e)}
              startIcon={<AddIcon />}
            >
              Create Credit Note
            </Button>
          </Grid>
          <Grid item md={2}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              size="medium"
              alignItems="center"
              onClick={() => this.createDebit()}
              startIcon={<AddIcon />}
            >
              Create Debit Note
            </Button>
          </Grid>
        </Grid>
        <Grid
          item
          sm={12}
          md={12}
          xs={12}
          lg={12}
          className="mt-2"
          style={{ overflowX: "auto" }}
        >
          {
            <WrappedDexTable
              data={[...(data.data || [])]}
              columnOrder={[
                "ID",
                "billable",
                "application",
                "type",
                "amount",
                "CreatedAt",
                "status",
                "actions",
              ]}
              columns={[
                { name: "ID", title: "ID" },
                { name: "billable", title: "Billable Service" },
                { name: "application", title: "Application" },
                { name: "type", title: "Type" },
                { name: "amount", title: "Amount" },
                { name: "CreatedAt", title: "Issued Date" },
                { name: "status", title: "Status" },
                { name: "actions", title: "Actions" },
              ]}
              columWidthsType="percentage"
              defaultColumnWidths={[
                { columnName: "ID", width: 5 },
                { columnName: "billable", width: 15 },
                { columnName: "application", width: 15 },
                { columnName: "type", width: 15 },
                { columnName: "amount", width: 15 },
                { columnName: "CreatedAt", width: 15 },
                { columnName: "status", width: 15 },
                { columnName: "actions", width: 10 },
              ]}
              formatters={[
                { formatterFn: this.actionProvider, columns: ["actions"] },
                { formatterFn: this.statusProvider, columns: ["status"] },
                { formatterFn: this.tagProvider, columns: ["type"] },
                {
                  formatterFn: this.issuedataProvider,
                  columns: ["CreatedAt"],
                },

                {
                  formatterFn: this.billableService,
                  columns: ["billable"],
                },
                {
                  formatterFn: this.applicationName,
                  columns: ["application"],
                },
              ]}
              hidden={[...hidden]}
              pagination={false}
              // hideColumnChooser={true}
              setHiddenColmn={(hidden) => {
                this.props.setHiddenColumn(hidden);
              }}
              amountColumns={["amount"]}
              disableReordering={false}
              loading={loading}
              tableType="virtual"
            />
          }
        </Grid>
        {/* {data.length !== 0 && (
            <div className={classes.footer}>
              <div className={classes.customPagination}>
                <Pagination
                  count={Math.ceil(totalCount / 10)}
                  //   page={this.state.page}
                  onChange={this.handlePageChange}
                  shape="rounded"
                  showFirstButton
                  showLastButton
                />
              </div>
            </div>
             )} */}
        {/* </Grid>
        } */}
        <div></div>

        <ModalApproveComponent
          loading={loading}
          load={load}
          color="white"
          open={this.state.setOpen}
          yes={`Yes, ${this.state.rejectmodal ? rejecttype : type} it`}
          handleClose={this.handleClose}
          action={this.state.rejectmodal ? this.rejectData : this.approveData}
          text={`Are you sure you want to ${
            this.state.rejectmodal ? rejecttype : type
          }`}
          Icon={
            this.state.rejectmodal ? (
              <CancelOutlinedIcon
                style={{ fontSize: "50px", marginBottom: "15px" }}
              />
            ) : (
              <DoneAllOutlinedIcon
                style={{ fontSize: "50px", marginBottom: "15px" }}
                className={
                  flag
                    ? this.props.classes.approve
                    : this.props.classes.approvecancel
                }
              />
            )
          }
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    hidden: state.invoiceDetails.hidden,
    loading: state.invoiceDetails.loading,
    load: state.invoiceDetails.load,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // getCreditdetailsById: (Id, invoiceId, payload) =>
    //   dispatch(actions.getCreditdetailsById(Id, invoiceId, payload)),
    ApproveCredit_Debit: (Id, invoiceId, type) =>
      dispatch(actions.ApproveCredit_Debit(Id, invoiceId, type)),
    RejectCredit_Debit: (Id, invoiceId, type) =>
      dispatch(actions.RejectCredit_Debit(Id, invoiceId, type)),
    setHiddenColumn: (hidden) =>
      dispatch(actions.updateCreditDebitHiddenCol(hidden)),
    setLoading: (bool) => dispatch(actions.loadingDispatchInvoice(bool)),
  };
};
const CreditNoteComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(withSection(CreditNote, "Credit / Debit Notes"));
export default withRouter(
  withStyles(Styles)(withSnackbar(CreditNoteComponent))
);
