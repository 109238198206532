import * as actions from "./actionTypes";
const intialState = {
  data: [],
  loading: false,
  totalCount: 0,
  hidden: ["TotalInvoiceAmount", "TaxAmt", "name", "alias"],
};
const reducer = (state = intialState, action) => {
  switch (action.type) {
    case actions.FETCHING_INVOICE:
      return { ...state, loading: action.loading };
    case actions.FETCHING_INVOICE_SUCCESS:
      return {
        ...state,
        loading: action.loading,
        data: action.data,
        totalCount: action.totalCount,
      };
    case actions.FETCHING_INVOICE_FAILURE:
      return {
        ...state,
        loading: action.loading,
        error: action.error,
        totalCount: 0,
      };

    case actions.UPDATE_INVOICE_HIDDEN_COL:
      return {
        ...state,
        hidden: action.payload,
      };
    default:
      return state;
  }
};
export default reducer;
