import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { Typography } from "@material-ui/core";
// import DialogTitle from "@material-ui/core/DialogTitle";

export default function ModalComponent(props) {
  const { open, handleClose, text, action, Icon, yes, color } = props;
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent
          style={{
            height: "150px",
            width: "400px",
            marginTop: "30px",
            textAlign: "center",
          }}
        >
          <DialogContentText
            id="alert-dialog-description"
            style={{ textAlign: "center" }}
          >
            {Icon && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {Icon}
              </div>
            )}
            <Typography variant="body2">{text}</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            size="small"
            onClick={handleClose}
            color="primary"
            autoFocus
          >
            No
          </Button>
          <Button
            style={{ color: "white" }}
            variant="contained"
            size="small"
            onClick={action}
            color="primary"
          >
            {yes}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
