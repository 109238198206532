import { React, useState, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import withSection from "components/Section/Section";
import DexTable from "components/DexTable/DexTable";
import WrappedDexTable from "components/DexTable/WrappedDexTable";
import {
  Button,
  IconButton,
  TextField,
  Grid,
  Tooltip,
} from "@material-ui/core";
import { RiEditLine, RiDeleteBin5Line } from "react-icons/ri";
import AddIcon from "@material-ui/icons/Add";
import { checkPermission } from "helpers/permissons";
import { confirmDialog } from "components/DeleteConfirmationDialog/Index";
import { dltMsg } from "helpers/helpers";
import { connect } from "react-redux";
import { ApplicationDetailsUpadateHiddenCol } from "../ApplicationDetails/Redux/action";
import { withSnackbar } from "notistack";
const styles = (length) => {
  let obj = {
    fontSize: "20px",
    color: length <= 1 ? "#bdbdbd" : "",
    cursor: length <= 1 ? "text" : "pointer",
  };
  return obj;
};

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 500,
  },
  line: {
    marginTop: -23,
  },
  dividerline: {
    width: "70%",
    margin: "-25px 0px 0px 125px",
    [theme.breakpoints.up("md")]: {
      width: "82%",
      margin: "-25px 0px 0px 260px",
    },
  },
  btn: {
    marginTop: "-10px",
    backgroundColor: "white",
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    // backgroundColor: "#999682",
    backgroundColor: theme.palette.primary.main,

    color: theme.palette.common.white,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({}))(TableRow);

// const CustomTableRow = (props) => {
//   console.log({ props });
//   const { row, editModule, addModule, removeRows, index, deleteModule } = props;
//   const classes = useStyles();
//   const [edit, setEdit] = useState(row._id == undefined || false);
//   const [payload, setPayload] = useState(row);
//   const onChange = (e) => {
//     let newpayload = { ...payload };
//     newpayload[e.currentTarget.name] = e.currentTarget.value;
//     console.log(newpayload);
//     setPayload(newpayload);
//   };
// };

const initialRows = [{ key: 1, moduleName: "", moduleDescription: "" }];

function ApplicationModulesDex(props) {
  const { appId, data, appTable,appName } = props;
  const { module } = appTable;
  const classes = useStyles();

  console.log({ data });
  const [rows, setRows] = useState(data);

  const addRows = () => {
    const item = { appId, name: "", description: "" };
    setRows([...rows, item]);
  };

  useEffect(() => {
    setRows(data);
    console.log(rows);
  }, [data]);

  const removeRows = (payload, index) => {
    console.log({ payload });
    var filtered = rows.filter(function (el, i) {
      console.log(index, i, index !== i);
      return index !== i;
    });
    console.log({ filtered });
    setRows([...filtered]);
  };
  const onChange = (event, index) => {
    let payload = rows.filter(function (el, i) {
      console.log(index, i, index !== i);
      return index == i;
    })[0];
    let newpayload = { ...payload };
    newpayload[event.currentTarget.name] = event.currentTarget.value;
    console.log(newpayload);
    let data = [...rows];
    data[index] = newpayload;
    setRows([...data]);
  };

  const editApplicationModule = ({ row }, e, val) => {
    e && e.stopPropagation();
    checkPermission("btn131") &&
      props.history.push("/app/create-modules-form", {
        moduleData: val ? val : row,
        edit: true,
        ajax: true,
        appID: props.appId,
        disabled: e ? false : true,
        appName:appName
      });
  };

  const pushToCreateForm = () => {
    let data = {
      name: "",
      description: "",
    };
    checkPermission("btn205") &&
      props.history.push("/app/create-modules-form", {
        moduleData: data,
        edit: false,
        ajax: true,
        appID: props.appId,
        appName:appName
      });
  };
  console.log(props.appId, "this is module ");
  const removeModule = async ({ row }, e) => {
    e && e.stopPropagation();
    await props.deleteModule(row.appId, row._id);
  };

  const actionProvider = (row) => {
    console.log(row, "3436436");
    return (
      <div
        style={{
          display: "flex",
        }}
      >
        <div style={{ marginRight: 20 }}>
          <Tooltip title="Edit" aria-label="edit">
            <IconButton
              color="inherit"
              size="small"
              disabled={!checkPermission("btn131")}
              onClick={(e) =>
                checkPermission("btn131") && editApplicationModule(row, e)
              }
            >
              <RiEditLine fontSize="20px" />
            </IconButton>
          </Tooltip>
        </div>
        {/* {rows.length > 1 && ( */}
        <div>
          {/* <Tooltip title="Delete" aria-label="delete">
              <IconButton
                color="primary"
                onClick={(e) => removeModule(row, e)}
              > */}
          <Tooltip title={rows.length <= 1 ? "" : "Delete"} aria-label="delete">
            <IconButton
              size="small"
              color="inherit"
              disabled={!checkPermission("btn92")}
              onClick={(e) => {
                e.stopPropagation();
                rows.length > 1 &&
                  checkPermission("btn92") &&
                  confirmDialog(dltMsg(row?.row?.name || ""), () =>
                    removeModule(row, e)
                  );
              }}
            >
              <RiDeleteBin5Line color="inherit" style={styles(rows.length)} />
            </IconButton>
          </Tooltip>
        </div>
        {/* )} */}
      </div>
    );
  };

  const viewModuledata = (row) => {
    editApplicationModule({}, null, row);
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid xs={12} item container justifyContent="flex-end">
          <Button
            size="small"
            variant="contained"
            onClick={() => checkPermission("btn205") && pushToCreateForm()}
            startIcon={<AddIcon />}
            style={{ padding: "4px 43px 4px 43px" }}
            disabled={!checkPermission("btn205")}
          >
            Create Modules
          </Button>
        </Grid>
        <Grid item xs={12}>
          {/* <DexTable */}
          <WrappedDexTable
            data={rows}
            columWidthsType="percentage"
            columnOrder={["name", "description", "actions"]}
            columns={[
              { name: "name", title: "Module Name" },
              { name: "description", title: "Description" },
              { name: "actions", title: "Actions" },
            ]}
            defaultColumnWidths={[
              { columnName: "name", width: 30 },
              { columnName: "description", width: 50 },
              { columnName: "actions", width: 19 },
            ]}
            hidden={[...module]}
            setHiddenColmn={(hidden) => {
              props.setHiddenColumn(hidden, "module");
            }}
            disableReordering={true}
            pagination={false}
            // hideColumnChooser={true}
            noDataMsg="Modules not found"
            formatters={[{ formatterFn: actionProvider, columns: ["actions"] }]}
            onClickRow={(row) => viewModuledata(row)}
            Read={checkPermission("btn222")}
          />
        </Grid>
      </Grid>
    </div>
  );
}
const mapDispatchToProps = (dispatch) => ({
  setHiddenColumn: (hidden, type) =>
    dispatch(ApplicationDetailsUpadateHiddenCol(hidden, type)),
});

const mapStateToProps = (state) => {
  return {
    appTable: state.appDetailsRedcer.appTable,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withSection(ApplicationModulesDex, "Modules")));
