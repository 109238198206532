import getURL from "../config";
import API_helpers from "../index";

async function getOrganizationDetails() {
  let url = getURL("FETCH_ORGANIZATION");
  try {
    let res = await API_helpers.GET(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  } catch (err) {
    throw err;
  }
}

async function getOrganizationDetailsByID() {
  let url = getURL("FETCH_ORGANIZATIONBY_ID");
  try {
    let res = await API_helpers.GET(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  } catch (err) {
    throw err;
  }
}

async function editOrganization(payload) {
  let url = getURL("EDIT_ORGANIZATIONDETAILS");
  try {
    let res = await API_helpers.PUT(url, payload, {
      headers: { "Content-Type": "application/json" },
    });
    return res;
  } catch (err) {
    throw err;
  }
}
var APIs = {
  getOrganizationDetails,
  getOrganizationDetailsByID,
  editOrganization,
};

export default APIs;
