import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import {} from "../redux/action";
import { useSnackbar } from "notistack";
import {
  Button,
  Grid,
  TextField,
  Typography,
  Divider,
} from "@material-ui/core";
import { withSnackbar } from "notistack";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Styles from "./Styles";
import { withStyles } from "@material-ui/styles";
import Breadcrumb from "components/Breadcrumbs/Breadcrumb";
import { addModule, updateModule } from "../redux/action";
import { getApplicationDetails } from "../ApplicationDetails/Redux/action";
import { isDuplicate } from "../../../helpers/helpers";
import APIs from "../../../api/ApplicationAPIs/index";
import { RiEditLine } from "react-icons/ri";
import { checkPermission } from "helpers/permissons";
import HelpdeskFAB from "../../../views/HelpdeskFAB/HelpdeskFab";

class CreateModuleForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ID: 0,
      check_name: "",
      err_msg: "",
      error: false,
      modules: {
        name: "",
        description: "",
      },
      isEdit: false,
      index: 0,
      ajax: false,
      isDisabled: false,
    };
  }

  componentDidMount() {
    const { location } = this.props;
    if (location.state !== undefined) {
      let { edit, moduleData, ajax, appID, disabled, appName } = location.state;

      this.setState({
        modules: { ...moduleData },
        isEdit: edit,
        check_name: moduleData.name,
        ajax: ajax,
        appName: appName,
        ID: appID,
        isDisabled: disabled,
      });
    }
    //
  }

  handleOnchange = (value) => {
    this.setState({
      modules: { ...value },
    });
  };

  checkDuplicate = (value, data) => {
    let flag = false;
    data.map((item, id) =>
      value.name === item.name ? (flag = true) : (flag = false)
    );
    return flag;
  };

  multilineAdd = async () => {
    const { isEdit, modules } = this.state;
    if (isEdit) {
      let updateModules = [...this.props.onBoard.modules];
      let data = updateModules.map((item, index) => {
        return item.key === modules.key ? { ...modules } : { ...item };
      });
      await this.props.edit_module(data, this.state.check_name, modules.name);
      this.props.history.push("/app/onboard-application", { status: true });
    } else {
      await this.props.add_module(this.state.modules);
      this.setState({
        modules: {
          name: "",
          description: "",
          key: this.props.onBoard.modules.length,
        },
        isEdit: false,
      });
    }
  };

  singleObjectSubmit = async () => {
    if (this.state.ajax) {
      console.log(this.state.modules, "23232323");
    } else {
      const { modules } = this.state;
      await this.props.add_module(modules);
      this.props.history.push("/app/onboard-application", { status: true });
    }
  };

  //ajax call func
  editAppModule = async () => {
    let { _id, key, ...data } = this.state.modules;
    try {
      let res = await APIs.editModule(this.state.modules.appId, _id, data);
      if (res.data) {
        this.props.enqueueSnackbar("Application module updated successfully", {
          variant: "success",
        });
        this.props.history.push("/app/detail", { appId: this.state.ID });
      }
    } catch (err) {
      this.props.enqueueSnackbar(
        err.response.data._msg || "Something Went wrong",
        {
          variant: "error",
          autoHideDuration: 3000,
        }
      );
    }
  };

  addAppModule = async () => {
    let { key, ...data } = this.state.modules;
    try {
      const res = await APIs.addModule(this.state.ID, data);
      this.props.enqueueSnackbar(
        res.data._msg || "Module details added successfully",
        { variant: "success", autoHideDuration: 3000 }
      );
    } catch (err) {
      this.props.enqueueSnackbar(
        err?.response?.data?._msg || "Something Went wrong",
        {
          variant: "error",
          autoHideDuration: 3000,
        }
      );
      console.error(err);
    }
  };

  ajaxFuncs = async () => {
    let { isEdit } = this.state;
    if (isEdit) {
      this.editAppModule();
    } else {
      await this.addAppModule();
      await this.props.getDetails(this.state.ID);
      this.setState({
        modules: {
          name: "",
          description: "",
        },
        isEdit: false,
      });
    }
  };

  singleAjaxFunc = async () => {
    await this.addAppModule();
    this.props.history.push("/app/detail", { appId: this.state.ID });
  };

  onSubmitAjax = (e) => {
    const { modules } = this.state;
    let flag = false;
    // if (!this.state.isEdit || this.state.check_name !== modules.name) {
    //   flag = isDuplicate(this.props.applicationModules || [], (f) => {
    //     return f.name.toLowerCase() === modules.name.toLowerCase();
    //   });
    // }

    // if (flag) {
    //   this.setState({
    //     err_msg: "Module name is already added",
    //     error: true,
    //   });
    //   return;
    // }

    // this.setState({
    //   err_msg: "",
    //   error: false,
    // });

    if (e.nativeEvent.submitter.name === "multiple") {
      this.ajaxFuncs();
    }

    if (e.nativeEvent.submitter.name === "single") {
      this.singleAjaxFunc();
    }
  };

  onSubmit = async (e) => {
    const { modules } = this.state;
    const { onBoard } = this.props;
    let flag = false;
    if (!this.state.isEdit || this.state.check_name !== modules.name) {
      flag = isDuplicate(onBoard.modules, (f) => {
        return f.name.toLowerCase() === modules.name.toLowerCase();
      });
    }
    if (flag) {
      this.setState({
        err_msg: "Module name is already added",
        error: true,
      });
      return;
    }

    this.setState({
      err_msg: "",
      error: false,
    });

    if (e.nativeEvent.submitter.name === "multiple") {
      this.multilineAdd();
    }

    if (e.nativeEvent.submitter.name === "single") {
      this.singleObjectSubmit();
    }
  };

  setDuplicateNull = () => {
    this.setState({
      error: false,
      err_msg: "",
    });
  };

  render() {
    const { classes, onBoard } = this.props;
    const { isEdit, modules, error, err_msg, ajax, ID, isDisabled, appName } =
      this.state;
    console.log(
      onBoard.modules,
      modules,
      ajax,
      ID,
      onBoard.modules.length,
      this.props.applicationModules
    );
    return (
      <div>
        <Grid item sm={12} md={12} xs={12} lg={12}>
          <Breadcrumb
            {...this.props}
            links={[
              { name: "Applications", url: "/app/applications" },
              {
                name: `${ajax ? `${appName}` : "Onboard Application"}`,
                url: `${ajax ? "/app/detail" : "/app/onboard-application"}`,
                routeParams: ajax ? { appId: this.state.ID } : { status: true },
              },
              {
                name: "Modules",
                url: "/app/create-modules-form",
              },
            ]}
          />
        </Grid>
        <div>
          <ValidatorForm
            ref="form"
            onSubmit={(e) => {
              if (ajax) {
                this.onSubmitAjax(e);
              } else {
                this.onSubmit(e);
              }
            }}
          >
            <Grid container spacing={0}>
              <Grid item sm={12} md={12} xs={12} lg={12}>
                <Grid
                  container
                  spacing={1}
                  direction="row"
                  alignItems="center"
                  className={classes.root}
                >
                  <Grid item md={6}>
                    <div>
                      <Typography variant="h6" className={classes.title}>
                        Modules
                      </Typography>
                    </div>
                  </Grid>
                  <Grid
                    item
                    sm={6}
                    container
                    justifyContent="flex-end"
                    spacing={1}
                  >
                    <Grid item>
                      {!isDisabled && (
                        <Button
                          variant="outlined"
                          color="primary"
                          size="small"
                          onClick={() => {
                            if (ajax) {
                              this.props.history.push("/app/detail", {
                                appId: this.state.ID,
                              });
                            } else {
                              this.props.history.push(
                                "/app/onboard-application",
                                { status: true }
                              );
                            }
                          }}
                        >
                          Cancel
                        </Button>
                      )}
                    </Grid>
                    <Grid item>
                      {!isDisabled && (
                        <Button
                          color="primary"
                          size="small"
                          variant={isEdit ? "contained" : "outlined"}
                          type="submit"
                          name="multiple"
                          disabled={
                            isEdit
                              ? !checkPermission("btn91")
                              : !checkPermission("btn90")
                          }
                        >
                          {isEdit ? "Save" : "Create and Add Another"}
                        </Button>
                      )}
                    </Grid>
                    <Grid item>
                      {!isEdit && (
                        <Button
                          name="single"
                          appID
                          size="small"
                          variant="contained"
                          type="submit"
                          disabled={!checkPermission("btn89")}
                        >
                          {"Create"}
                        </Button>
                      )}
                    </Grid>
                    {isDisabled && (
                      <Button
                        size="small"
                        variant="contained"
                        onClick={() =>
                          this.setState({
                            isDisabled: false,
                          })
                        }
                        disabled={!checkPermission("btn91")}
                        startIcon={<RiEditLine style={{ fontSize: "20px" }} />}
                      >
                        Edit
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={12}>
                <Divider className={classes.divider} />
              </Grid>
              <Grid item md={12} style={{ marginTop: "22px" }}>
                <Grid container spacing={2}>
                  <Grid item md={3}>
                    <div style={{ width: "100%" }}>
                      <Typography
                        style={{ marginBottom: "2px" }}
                        variant="body2"
                      >
                        Name <span style={{ color: "red" }}>*</span>
                      </Typography>
                      <TextValidator
                        disabled={isDisabled}
                        autoComplete="off"
                        size="small"
                        variant="outlined"
                        fullWidth
                        value={modules.name}
                        id="name"
                        error={error}
                        onFocus={this.setDuplicateNull}
                        onChange={(e) =>
                          this.handleOnchange({
                            ...modules,
                            name: e.target.value.trimStart(),
                          })
                        }
                        name="moduleName"
                        validators={["required"]}
                        errorMessages={["Please enter module name"]}
                        helperText={err_msg}
                      />
                    </div>
                  </Grid>
                  <Grid item md={3}></Grid>
                  <Grid item md={3}></Grid>
                  <Grid item md={3}>
                    <div style={{ width: "100%" }}>
                      <Typography
                        variant="body2"
                        style={{ marginBottom: "2px" }}
                      >
                        Description
                      </Typography>
                      <TextValidator
                        disabled={isDisabled}
                        autoComplete="off"
                        multiline
                        rows={7}
                        size="small"
                        variant="outlined"
                        fullWidth
                        id="moduleDescription"
                        value={modules.description}
                        onChange={(e) =>
                          this.handleOnchange({
                            ...modules,
                            description: e.target.value,
                          })
                        }
                        name="moduleDescription"
                        // validators={["required"]}
                        // errorMessages={["Please enter module description"]}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <HelpdeskFAB />
            </Grid>
          </ValidatorForm>
        </div>
      </div>
    );
  }
}
CreateModuleForm.propTypes = {
  classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => {
  return {
    onBoard: state.application.onBoard,
    applicationModules:
      state.appDetailsRedcer.applicationDetails.applicationModules,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getDetails: (id) => dispatch(getApplicationDetails(id)),
  add_module: (data) => dispatch(addModule(data)),
  edit_module: (data, name, newName) =>
    dispatch(updateModule(data, name, newName)),
});

const CreateModules = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateModuleForm);

export default withStyles(Styles)(withSnackbar(CreateModules));
