import axios from "axios";
import getURL from "../config";
import API_helpers from "../index";

const getTeamData = async (id) => {
  let url = getURL("FETCH_TEAMS");
  try {
    url = `${url}/${id}`;
    console.log(url, "url sudheer");
    const res = await API_helpers.GET(url, {
      headers: {
        "Content-Type": "application/json",
        userdetails: JSON.stringify({ name: "palani" }),
        organization: "11111",
      },
    });
    console.log(res, "url sudheer");
    return res;
  } catch (err) {
    console.log(err, "url sudheer err");
    throw err;
  }
};

async function fetchAllTeamsWithParams(page, size, filters, sort) {
  let url = getURL("FETCH_TEAMS");
  url = url + "?";
  let params = { page, size, filters, sort };
  Object.keys(params)
    .filter((k) => params[k] != undefined || params[k] != null)
    .forEach((k) => (url = `${url}${k}=${params[k]}&`));
  url = url.substr(0, url.length - 1);
  console.log(url, "urll");
  try {
    let res = await API_helpers.GET(url, {
      headers: {
        "Content-Type": "application/json",
        userdetails: JSON.stringify({ name: "palani" }),
        organization: "11111",
      },
    });
    // console.log("sdfgh345678765456",res)
    return res.data;
  } catch (err) {
    throw err;
  }
}

async function blockTeamManagement(teamId, type) {
  let url = getURL("BLOCK_TEAMS")(teamId, type);
  try {
    let res = await API_helpers.POST(
      url,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          userdetails: JSON.stringify({ name: "palani" }),
          organization: "11111",
        },
      }
    );
    return res;
  } catch (err) {
    throw err;
  }
}

async function lockTeamManagement(teamId, type) {
  let url = getURL("LOCK_TEAMS")(teamId, type);
  try {
    let res = await API_helpers.POST(
      url,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          userdetails: JSON.stringify({ name: "palani" }),
          organization: "11111",
        },
      }
    );
    return res;
  } catch (err) {
    throw err;
  }
}

const addTeam = async (payload) => {
  try {
    let res = await API_helpers.POST(getURL("ADD_TEAM"), payload, {
      headers: {
        "Content-Type": "application/json",
        userdetails: JSON.stringify({ name: "palani" }),
        organization: "11111",
      },
    });
    return res;
  } catch (err) {
    throw err;
  }
};

const updateMember = async (type, id, payload) => {
  try {
    let res = await API_helpers.POST(getURL("UPDATE_MEMBER")(id), payload, {
      headers: {
        "Content-Type": "application/json",
        userdetails: JSON.stringify({ name: "palani" }),
        organization: "11111",
      },
    });
    return res;
  } catch (err) {
    throw err;
  }
};

const deleteTeamMember = async (id, payload) => {
  console.log(id, payload, "deeelletet");
  try {
    let res = await API_helpers.PUT(getURL("DELETE_TEAM_MEMBER")(id), payload, {
      headers: {
        "Content-Type": "application/json",
        userdetails: JSON.stringify({ name: "palani" }),
        organization: "11111",
      },
    });
    return res;
  } catch (err) {
    throw err;
  }
};

var APIs = {
  fetchAllTeamsWithParams,
  blockTeamManagement,
  addTeam,
  updateMember,
  deleteTeamMember,
  lockTeamManagement,
  getTeamData,
};

export default APIs;
