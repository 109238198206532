import React from "react";
import DexTable from "components/DexTable/DexTable.jsx";
import WrappedDexTable from "components/DexTable/WrappedDexTable";
import {
  Badge,
  Typography,
  Grid,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import BlockIcon from "@material-ui/icons/Block";
// import ViewIcon from "@material-ui/icons/Visibility";
import { RiEditLine } from "react-icons/ri";
import Pagination from "@material-ui/lab/Pagination";
import ModalComponent from "./ModalComponent";
import moment from "moment";
import { checkPermission } from "../../../helpers/permissons";
import helpers from "helpers/helpers";
import actions from "../redux/action";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";
import { withSnackbar } from "notistack";
const CustomerListTable = (props) => {
  let dateFormat = helpers.getDateFormat();
  const [open, setOpen] = React.useState(false);
  const { classes, data, subscriberData, hidden } = props;
  const [info, setInfo] = React.useState({
    name: "",
    subID: 0,
    userID: 0,
    status: "",
  });
  let totalCount = props.totalCount;
  console.log(subscriberData, 23, totalCount, "this is customer list data");
  function statusProvider({ value }) {
    let color = null;
    if (value === "active") {
      color = classes.activeBadge;
    } else if (value === "inactive") {
      color = classes.inactiveBadge;
    } else {
      color = classes.blockBadge;
    }

    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Badge
          classes={{ badge: color }}
          variant="dot"
          style={{ marginRight: "10px" }}
        />
        <Typography variant="body1"> {value} </Typography>
      </div>
    );
  }

  const actionProvider = ({ row }) => {
    const openModal = (e) => {
      e.stopPropagation();
      setInfo({
        ...info,
        name: row.name,
        subID: row._id,
        status: row.status,
      });
      setOpen(true);
    };

    const goToEditCustomerDetails = (e) => {
      e.stopPropagation();
      // row.status !== "inactive" &&
      props.history.push("/app/customer-details", {
        customerData: row,
        isEdit: row.status === "inactive" ? false : true,
      });
    };

    let flag = row.status !== "active" ? true : false;

    const blockOrUnblock = (e) => {
      flag
        ? checkPermission("btn_62") && openModal(e)
        : checkPermission("btn62") && openModal(e);
    };

    return (
      <Grid container direction="row" alignItems="center" spacing={2}>
        <Grid item>
          <Tooltip title={flag ? "Unblock" : "Block"} placement="bottom">
            <IconButton
              size="small"
              aria-label="block"
              color="inherit"
              id="btn62"
              disabled={
                flag ? !checkPermission("btn_62") : !checkPermission("btn62")
              }
              onClick={(e) => {
                e.stopPropagation();
                // checkPermission("btn62") && openModal(e);
                blockOrUnblock(e);
              }}
            >
              <BlockIcon
                style={{ fontSize: "20px" }}
                className={flag ? classes.block : classes.active}
              />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title="View" placement="bottom">
            <IconButton
              // disabled={props.isDisabled}
              size="small"
              color="inherit"
              aria-label="view"
              id="btn1"
              disabled={!checkPermission("btn1")}
              onClick={(e) => {
                checkPermission("btn1") && goToEditCustomerDetails(e);
              }}
            >
              <RiEditLine style={{ fontSize: "20px" }} />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    );
  };

  const handleClick = (row) => {
    // row.status !== "inactive" &&
    props.history.push("/app/customer-details", { customerData: row });
  };
  // const viewCustomerdata = (row) => {
  //   handleClick(row);
  // };

  const registeredOn = ({ row }) => {
    return (
      <Typography variant="body1">
        {moment(row.createdon).format(dateFormat)}{" "}
      </Typography>
    );
  };

  const handleClose = () => {
    setOpen(false);
  };
  const blockOrUnblock = async () => {
    if (info.status === "active") {
      await props.blockOrUnblockCustomer(info.subID, "block");
    } else {
      await props.blockOrUnblockCustomer(info.subID, "unblock");
    }
    setOpen(false);
  };
  const type = info.status === "active" ? "block" : "unblock";
  console.log("bugsbugs", 234, checkPermission("btn1"));
  console.log("dghjfdfhdg", props);

  return (
    <>
      <div className={classes.table}>
        <DexTable
          data={[...data]}
          columnOrder={[
            "name",
            "organization_name",
            "email",
            "phonenumber",
            "status",
            "createdon",
            "actions",
          ]}
          columns={[
            { name: "name", title: "Name" },
            { name: "organization_name", title: "Organization Name" },

            { name: "email", title: "Email" },
            { name: "phonenumber", title: "Phone Number" },
            { name: "status", title: "Status" },
            { name: "createdon", title: "Registered On" },
            { name: "actions", title: "Actions" },
          ]}
          defaultColumnWidths={[
            // { columnName: "name", width: 200 },
            // { columnName: "organization_name", width: 250 },
            // { columnName: "organization_role", width: 150 },
            // { columnName: "email", width: 250 },
            // { columnName: "phonenumber", width: 150 },
            // { columnName: "status", width: 100 },
            // { columnName: "createdon", width: 155 },
            // { columnName: "actions", width: 150 },
            { columnName: "name", width: 19 },
            { columnName: "organization_name", width: 19 },
            { columnName: "email", width: 19 },
            { columnName: "phonenumber", width: 19 },
            { columnName: "status", width: 19 },
            { columnName: "createdon", width: 19 },
            { columnName: "actions", width: 19 },
          ]}
          dateColumns={["createdon"]}
          columWidthsType="percentage"
          formatters={[
            { formatterFn: actionProvider, columns: ["actions"] },
            { formatterFn: statusProvider, columns: ["status"] },
            // { formatterFn: registeredOn, columns: ["createdon"] },
            //   { formatterFn: this.appProvider, columns: ["name"] },
          ]}
          pagination={false}
          paginationConfig={{
            currentPage: props.page,
            pageSize: props.size,
            onCurrentPageChange: props.handlePageChange,
            onPageSizeChange: props.handleLimitChangePageRow,
            pageSizes: [4, 5, 6],
            totalCount: totalCount,
          }}
          height="60vh"
          EmailColumn={["email"]}
          hidden={[...hidden]}
          sorting={props.sorting}
          loading={props.loading}
          onSortingChange={props.handleSortByChange}
          // onClickRow={(e) => handleClick(e)}
          onClickRow={(row) => {
            handleClick(row);
          }}
          setHiddenColmn={(hidden) => {
            props.setHiddenColumn(hidden);
          }}
          Read={checkPermission("btn2")}
          // onClickRow={handleClick}
          // disableReordering={false}
        />
        {data.length !== 0 && (
          <div className={classes.footer}>
            <div className={classes.customPagination}>
              <Pagination
                count={Math.ceil(totalCount / 10)}
                page={props.page}
                onChange={props.handlePageChange}
                shape="rounded"
                showFirstButton
                showLastButton
              />
            </div>
          </div>
        )}
      </div>
      <ModalComponent
        color="white"
        open={open}
        yes={`Yes, ${type} it`}
        x
        handleClose={handleClose}
        action={blockOrUnblock}
        text={`Are you sure you want to ${type} ${info.name}`}
        Icon={<BlockIcon style={{ fontSize: "50px", marginBottom: "15px" }} />}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setHiddenColumn: (hidden) =>
    dispatch(actions.updateCustomerHiddenTableCol(hidden)),
});

const mapStateToProps = (state) => {
  return {
    hidden: state.customerManagement.hidden,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(CustomerListTable));
