// import React, { Component } from "react";
// import {
//   Typography,
//   Grid,
//   Button,
//   Tooltip,
//   IconButton,
//   Badge,
//   Chip,
// } from "@material-ui/core";

// import DexTable from "components/DexTable/DexTable";

// const PrintCrDrNote = (props) => {
//   const { crdr_data } = props;
//   return (
//     <div style={{ width: "40%" }}>
//       {crdr_data.length !== 0 && (
//         // <DexTable
//         //   data={[...(crdr_data || [])]}
//         //   columnOrder={[
//         //     "ID",
//         //     "billable",
//         //     "application",
//         //     "type",
//         //     "amount",
//         //     "CreatedAt",
//         //     "status",
//         //     "actions",
//         //   ]}
//         //   columns={[
//         //     { name: "ID", title: "ID" },
//         //     { name: "billable", title: "Billable Service" },
//         //     { name: "application", title: "Application" },
//         //     { name: "type", title: "Type" },
//         //     { name: "amount", title: "Amount" },
//         //     { name: "CreatedAt", title: "Issued Date" },
//         //     { name: "status", title: "Status" },
//         //     { name: "actions", title: "Actions" },
//         //   ]}
//         //   columWidthsType="percentage"
//         //   defaultColumnWidths={[
//         //     { columnName: "ID", width: 5 },
//         //     { columnName: "billable", width: 12 },
//         //     { columnName: "application", width: 12 },
//         //     { columnName: "type", width: 12 },
//         //     { columnName: "amount", width: 12 },
//         //     { columnName: "CreatedAt", width: 12 },
//         //     { columnName: "status", width: 12 },
//         //     { columnName: "actions", width: 10 },
//         //   ]}
//         //   formatters={[
//         //     { formatterFn: props.actionProvider, columns: ["actions"] },
//         //     { formatterFn: props.statusProvider, columns: ["status"] },
//         //     { formatterFn: props.tagProvider, columns: ["type"] },
//         //     {
//         //       formatterFn: props.issuedataProvider,
//         //       columns: ["CreatedAt"],
//         //     },

//         //     {
//         //       formatterFn: props.billableService,
//         //       columns: ["billable"],
//         //     },
//         //     {
//         //       formatterFn: props.applicationName,
//         //       columns: ["application"],
//         //     },
//         //   ]}
//         //   pagination={false}
//         //   hideColumnChooser={true}
//         //   amountColumns={["amount"]}
//         //   disableReordering={false}
//         //   tableType="virtual"
//         // />
//       )}
//     </div>
//   );
// };

// export default PrintCrDrNote;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function PrintCrDrNote(props) {
  const classes = useStyles();
  const { data } = props;
  console.log("ghsvhdghsgajd", data);
  let crdrData = data?.data || [];
  let length = crdrData.length;
  return (
    <>
      {length !== 0 && (
        <TableContainer>
          <div>
            {
              <Typography
                variant="body2"
                style={{ fontWeight: 800, color: "black" }}
              >
                {"Credit / Debit Notes"}
              </Typography>
            }
          </div>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell align="right">billable</TableCell>
                <TableCell align="right">application</TableCell>
                <TableCell align="right">type</TableCell>
                <TableCell align="right">amount</TableCell>
                <TableCell align="right">Created On</TableCell>
                <TableCell align="right">status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {crdrData.map((row, id) => (
                <TableRow key={id}>
                  <TableCell component="th" scope="row">
                    {row.ID}
                  </TableCell>
                  <TableCell align="right">
                    {row.BillableDetails?.name || ""}
                  </TableCell>
                  <TableCell align="right">
                    {row.AppDetails?.name || ""}
                  </TableCell>
                  <TableCell align="right">{row.type}</TableCell>
                  <TableCell align="right">{row.amount}</TableCell>
                  <TableCell align="right">
                    {row.CreatedAt.split("T")[0]}
                  </TableCell>
                  <TableCell align="right">{row.status}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
