import React, {
  useState,
  // useEffect
} from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  makeStyles,
  //   Container,
  Grid,
  Typography,
  Button,
  //   Divider,
  //   IconButton,
} from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
// import ErrorOutlinedIcon from "@material-ui/icons/ErrorOutlined";
// import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import DeleteOutlineRoundedIcon from "@material-ui/icons/DeleteOutlineRounded";
// import { v4 as uuidv4 } from "uuid";
import { useSnackbar } from "notistack";
// import { withStyles } from "@material-ui/styles";
import { RiDownload2Line } from "react-icons/ri";
import Upload from "../../assets/images/Icon metro-folder-upload.svg";
const CustomDropperIcon = () => (
  <img src={Upload} width="100" height="50" alt="drop-down" />
);

const useStyles = makeStyles((theme) => ({
  modelFooter: {
    textAlign: "center",
    background: "#FAFAFA 0% 0% no-repeat padding-box",
    borderRadius: "0px 0px 10px 10px",
    opacity: "1",
    paddingBottom: "15px",

    "& .MuiGrid-item Button": {
      width: "266px",
      height: "40px",
      textTransform: "capitalize",
      font: "normal normal 600 12px/16px Nunito",
    },
  },
  modalTitle: {
    textAlign: "center",
    fontWeight: "bold",
  },

  modalHeading: {
    textAlign: "center",
    fontWeight: "100px",
  },
  modalDropzone: {
    "& .documentDropperClass": {
      paddingTop: "3.5rem",
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      border: `3px solid ${theme.palette.primary.main}`,
      borderRadius: 10,
      textAlign: "center",
      font: "normal normal 600 16px/22px Nunito",
      color: `${theme.palette.primary.main}`,

      "& .MuiDropzoneArea-textContainer": {
        display: "flex",
        flexDirection: "column-reverse",
        alignItems: "center",
        justifyContent: "center",

        "& .MuiDropzoneArea-icon": {
          color: `${theme.palette.primary.main}`,
          transform: "scale(1.4)",
        },

        "& .documentDropperTextClass": {
          color: `${theme.palette.primary.main}`,
          font: "normal normal 600 16px/22px Nunito",
        },
      },
    },
  },
  modalstyle: {
    height: "150px",
    width: "400px",
    marginTop: "30px",
    textAlign: "center",
  },

  modaltextstyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalFileContainer: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 2px 6px #00000014",
    borderRadius: "6px",
    opacity: "1",
    marginTop: "20px",
    width: "553px",
    height: "50px",
    justifyContent: "space-between",
  },
  file: {
    display: "flex",
    marginTop: "12px",
    marginLeft: "10px",

    "& span": {
      marginLeft: "10px",
    },
  },

  fileDelete: {
    display: "flex",
    marginTop: "12px",
    justifyContent: "flex-end",
    marginRight: "10px",
  },
}));

const BulkUploadModal = (props) => {
  const { open, handleCloseBulk, property, postBulkUpload } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [droppedDocuments, setDroppedDocuments] = useState([]);

  const uploadHandler = () => {
    if (droppedDocuments.length < 1) {
      enqueueSnackbar("No file uploaded!", {
        variant: "error",
      });
    } else {
      // console.log("droppedDocuments", droppedDocuments);
      postBulkUpload(droppedDocuments);
    }
  };

  return (
    <Dialog
      scroll="paper"
      className={classes.customModal}
      open={open}
      onClose={handleCloseBulk}
      maxWidth={"md"}
    >
      <DialogTitle>
        <div className={classes.modalTitle}>Bulk Upload</div>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className={classes.modalHeading}>
              Create multiple fields from CSV or Excel file
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.modalDropzone}>
            {/* <DropzoneArea /> */}
            <DropzoneArea
              acceptedFiles={[".csv", ".xlsx ", ".xls"]}
              Icon={CustomDropperIcon}
              showPreviews={false}
              filesLimit={1}
              dropzoneText="Drop your file here or browse"
              dropzoneClass="documentDropperClass"
              dropzoneParagraphClass="documentDropperTextClass"
              showAlerts={["error"]}
              showPreviewsInDropzone={false}
              onChange={(files) => {
                const temFiles = files.map((file, index) => ({
                  name: file.name,
                  version: "",
                  file,
                }));
                setDroppedDocuments(temFiles);
              }}
            />
          </Grid>
        </Grid>
        {droppedDocuments.map((item) => (
          <Grid container className={classes.modalFileContainer}>
            <Grid item sm={8} xs={8}>
              <div className={classes.file}>
                <FileCopyOutlinedIcon /> <span>{item.name}</span>
              </div>
            </Grid>
            <Grid item sm={3} xs={3}>
              <div
                style={{ cursor: "pointer" }}
                className={classes.fileDelete}
                onClick={() => setDroppedDocuments([])}
              >
                <DeleteOutlineRoundedIcon />
              </div>
            </Grid>
          </Grid>
        ))}
        <Grid container>
          <Grid item md={6}>
            <Typography className="pl-1 pt-2" variant="body2">
              {/* Upload .csv, .xls or .xlsx file */}
              Upload .csv
            </Typography>
          </Grid>
          <Grid item md={6}>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Button
                size="small"
                variant="outlined"
                startIcon={<RiDownload2Line />}
                className="mt-2"
              >
                <a
                  href={property.src}
                  download="Sample.csv"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  Download Sample
                </a>
              </Button>
            </Grid>
          </Grid>

          {/* <Grid container>
            <Grid item xs={12}></Grid>
          </Grid> */}
        </Grid>
      </DialogContent>
      <DialogActions className={classes.modelFooter}>
        <Grid container spacing={2} className="pl-3 pr-3 mt-2">
          <Grid item xs={6}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleCloseBulk}
              className="btnsmall"
              style={{ margin: "0" }}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              onClick={uploadHandler}
              className="btnsmall"
              style={{ margin: "0" }}
            >
              Upload and Create Fields
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default BulkUploadModal;
