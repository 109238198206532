// const baseURL = process.env.REACT_APP_AWS_BASE_URL;
// const baseURL = "http://49.205.218.104:6001";
const orgUrl = process.env.REACT_APP_ORG_BASE_URL;
const repoUrl = process.env.REACT_APP_AWS_BASE_URL;
const baseURL = process.env.REACT_APP_AWS_BASE_URL;
const releaseVersion = process.env.REACT_APP_RELEASE_VERSION;
// const baseURL = "http://49.205.218.104:6001";

// export const BASE_URL = `${baseURL}:6030`;
// export const BASE_URL2 = `${baseURL}:6004`;
// export const CONTAINER_MNGT = `${baseURL}:6004`;
// export const BASE_URL_2 = `${baseURL}:6013`;
// export const BASE_URL_3 = `${baseURL}:6004`;
// export const BASE_URL_4 = `${baseURL}:6014`;
// export const BASE_URL_5 = `${baseURL}:6004`;
// export const BASE_URL3 = `${baseURL}:6003`;
// export const BASE_URL18 = `${baseURL}:6018`;
// export const BASE_URL15 = `${baseURL}:6015`;
// export const BASE_URL45 = `${baseURL}:6045`;
// export const BASE_URL44 = `${baseURL}:6044`;
// export const MICROSERVICE = `${baseURL}:7011`;
// export const APPLICATION = `${baseURL}:7002`;
// export const CONTAINER = `${baseURL}:7005`;
// export const SIMULATION = `${baseURL}:7016`;
// export const TEMPLATE = `${baseURL}:7019`;
// export const PROJECTS = `${baseURL}:7014`;
// export const LANGUAGES = `${baseURL}:7010`;
// export const GLOSSARY = `${baseURL}:7009`;
// // export const REPOSITORY = `${baseURL}:7015`
// export const REPOSITORY = `${baseURL}:6014`;
// export const DOCUMENT = `${baseURL}:7006`;
// export const MODE = "development";

export const BASE_URL = `${baseURL}`;
export const ORG_URL = `${orgUrl}`;
export const BASE_URL2 = `${baseURL}`;
export const CONTAINER_MNGT = `${baseURL}`;
export const BASE_URL_2 = `${baseURL}`;
export const BASE_URL_3 = `${baseURL}`;
export const BASE_URL_4 = `${baseURL}`;
export const BASE_URL_5 = `${baseURL}`;
export const BASE_URL3 = `${baseURL}`;
export const BASE_URL18 = `${baseURL}`;
export const BASE_URL15 = `${baseURL}`;
export const BASE_URL45 = `${baseURL}`;
export const BASE_URL44 = `${baseURL}`;
export const MICROSERVICE = `${baseURL}`;
export const APPLICATION = `${baseURL}`;
export const CONTAINER = `${baseURL}`;
export const SIMULATION = `${baseURL}`;
export const TEMPLATE = `${baseURL}`;
export const PROJECTS = `${baseURL}`;
export const ENTITY_SCHEMA = `${baseURL}`;
export const TEAMS = `${baseURL}`;
export const LANGUAGES = `${baseURL}`;
export const GLOSSARY = `${baseURL}`;
// export const REPOSITORY = `${baseURL}:7015`
export const REPOSITORY = `${repoUrl}`;
export const DOCUMENT = `${baseURL}`;
export const STATUSPROFILE = `${baseURL}`;
export const ISSUEDETAILS = `${baseURL}`;
export const USERPROFILE=`${baseURL}`
export const MODE = "development";
export const RELEASE_VERSION = `${releaseVersion}`;
