import {
  Grid,
  Backdrop,
  CircularProgress,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Avatar,
  TextField,
  Button,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { ArrowDropDownRounded } from "@material-ui/icons";
// files
import CreateCustomerStyles from "../../../styles/CommonStyles/CreateAndDetailsScreenStyle";
import BreadCrumbs from "../../../components/Breadcrumbs/Breadcrumb";
// import {
//   fetchCurrentORGDetails,
//   EditCurrentORGDetails,
// } from "../../../utils/organization/organizationUtils";
import {
  getOrganizationDetails,
  getOrganizationDetailsByID,
  editOrganization,
  change_logo,
} from "./redux/action";
import { useSnackbar } from "notistack";
import { AppButton } from "../../../components/common/Button";
import { handleFormValidation } from "../../../components/common/Organizationvalidation";
import moment from "moment";
import { DropzoneArea } from "material-ui-dropzone";
// import { change_logo } from "../../../redux/actions/organizationAction";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import helpers from "../../../helpers/helpers";
const EditOrganizationScreen = (props) => {
  const styles = CreateCustomerStyles();
  const histroy = useHistory();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [orgID] = useState(props.match.params._id);
  const [organizationData, setOrganizationData] = useState("");
  const [address, setAddress] = useState({
    street: "",
    city: "",
    state: "",
    country: "",
  });
  const [LogoName, setLogoName] = useState("");
  const [Logo, setLogo] = useState("");
  const [faviconName, setFaviconName] = useState("");
  const [favicon, setFavicon] = useState("");
  const [uploadLogoModal, setUploadLogoModal] = useState(false);
  const [uploadFaviconModal, setUploadFaviconModal] = useState(false);

  const { change_logo, userInfo } = props;

  const [error, setError] = useState({
    name: false,
    contactNumber: false,
  });

  const [EditOrgdata, setEditOrgData] = useState({});

  const handleChange = (event) => {
    console.log("shgfsahgfAGHSG", event.target.value);
    const value = event.target.value;
    setEditOrgData({
      ...EditOrgdata,
      [event.target.name]: value,
    });
    setError({
      ...error,
      [event.target.name]: false,
    });
    setOrganizationData({
      ...organizationData,
      [event.target.name]: value,
    });
    console.log(error, "sdfghjkl");
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const { data } = await getOrganizationDetails();
      // console.log(_msg, data[0]._id, "oijuhyujio");
      const res = await getOrganizationDetailsByID();
      console.log(res, "kjhgfd");
      if (res._status === 200) {
        setOrganizationData(() => res.data || {});
        // setAddress(res.data ? res.data.address : "");
        let addressData = res.data.address ? res.data.address : "";
        let myAdd = [" "];
        if (typeof addressData !== "boolean") {
          myAdd = addressData.split(",");
        }
        setEditOrgData({
          ...EditOrgdata,
          street: myAdd[0],
          city: myAdd[1],
          state: myAdd[2],
          country: myAdd[3],
        });
        if (res.data?.logo?.length > 0) {
          setUploadLogoModal(true);
        }
        if (res.data?.favicon?.length > 0) {
          setUploadFaviconModal(true);
        }
        setLoading(false);
      } else {
        setLoading(false);
        enqueueSnackbar(res._msg, { variant: "error" });
      }
    };
    fetchData();
  }, [orgID]);

  const handleSubmit = async () => {
    console.log("sadgkdsaghdsg", EditOrgdata, organizationData);

    let FullAddress = `${EditOrgdata.street},${EditOrgdata.city},${EditOrgdata.state},${EditOrgdata.country}`;
    const { formIsValid, errors } = handleFormValidation(
      organizationData,
      error
    );
    if (formIsValid) {
      setLoading(true);
      console.log(EditOrgdata, organizationData, "dfghjkl");
      const req_body = {
        name:
          EditOrgdata.name !== undefined
            ? EditOrgdata.name
            : organizationData.name,
        description:
          EditOrgdata.description !== undefined
            ? EditOrgdata.description
            : organizationData.description,
        industry:
          EditOrgdata.industry !== undefined
            ? EditOrgdata.industry
            : organizationData.industry,
        //
        contactNumber:
          EditOrgdata.contactNumber !== undefined
            ? EditOrgdata.contactNumber
            : organizationData.contactNumber,
        address: FullAddress ? FullAddress : organizationData.address,
        //
        title:
          EditOrgdata.title !== undefined
            ? EditOrgdata.title
            : organizationData.title,
        logo: Logo ? Logo : uploadLogoModal ? organizationData.logo : "",
        favicon: favicon
          ? favicon
          : uploadFaviconModal
          ? organizationData.favicon
          : "",
      };
      console.log("req_body", req_body);
      // setLoading(false);
      const { data } = await getOrganizationDetails();
      const res = await editOrganization(req_body);

      if (res._status === 200) {
        console.log(data);
        enqueueSnackbar(res._msg ? res._msg : "Something went wrong", {
          variant: "success",
          autoHideDuration: 2000,
        });
        setLoading(true);
        change_logo(req_body.logo, req_body.favicon, req_body.title);
        histroy.push("/app/organization-details");
      } else {
        console.log("error : ", res._msg, data);
        setLoading(false);
        if (res._msg === "Invalid Json") {
          data.map((item) =>
            enqueueSnackbar(item ? item : "Something went wrong", {
              variant: "error",
              autoHideDuration: 2000,
            })
          );
        } else {
          enqueueSnackbar(res._msg ? res._msg : "Something went wrong", {
            variant: "error",
            autoHideDuration: 2000,
          });
        }
      }
    } else {
      console.log("Form has errors.");
      setError(errors);
    }
  };

  const handleUploadIcon = (files) => {
    if (files && files.length > 0) {
      var file = files[0];
      setLogoName(files[0].name);
      const reader = new FileReader();
      reader.onload = (event) => {
        setLogo(event.target.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleDeleteLogoClick = () => {
    setLogo("");
    setLogoName("");
  };
  const handleDeleteFaviconClick = () => {
    setFavicon("");
    setFaviconName("");
  };
  const handleUploadFavicon = (files) => {
    if (files && files.length > 0) {
      var file = files[0];
      setFaviconName(files[0].name);
      const reader = new FileReader();
      reader.onload = (event) => {
        setFavicon(event.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const customdropzoneImage = () => {
    return <img src="/images/dropzone.svg" alt="" />;
  };
  const rejectedfile = () => {
    enqueueSnackbar("Maximum file size allowed 1MB", {
      variant: "error",
    });
  };

  return (
    <main className={styles.main}>
      <Backdrop
        className={styles.backdrop}
        open={loading}
        onClick={() => setLoading(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <div className={styles.topContent}>
        <BreadCrumbs
          {...props}
          links={[
            {
              name: "Organization Details",
              url: "/app/organization-details",
            },
            {
              name: organizationData.name,
              url: "/app/edit-organization-details",
            },
          ]}
          // items={[
          //   { title: "Organizations", path: "/app/organization-deatils" },
          //   {
          //     title: organizationData.name
          //       ? `Edit ${organizationData.name}`
          //       : "",
          //   },
          // ]}
        />
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          style={{ marginBottom: "15px", marginTop: "10px" }}
        >
          <h2 className={styles.title}>
            {organizationData.name ? `Edit ${organizationData.name}` : ""}
          </h2>
          {/* <AppButton
            color="primary"
            buttonName="Save"
            variant="contained"
            disabled={false}
            style={{ margin: "unset" }}
            className="btnsmall"
            onClick={handleSubmit}
          /> */}
          <Button
            style={{ padding: "6px 15px" }}
            variant="contained"
            size="small"
            onClick={handleSubmit}
          >
            Save
          </Button>
        </Grid>

        <div className={styles.border_line} />
      </div>
      <div className={styles.wrapper} style={{ marginTop: 85 }}>
        <Grid container spacing={3}>
          <Grid item xs={9}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4} md={4}>
                <Typography
                  style={{ marginBottom: "2px", fontSize: "14px" }}
                  variant="body2"
                >
                  Organization Name <span style={{ color: "red" }}>*</span>
                </Typography>
                {/* <label className={styles.fieldHeading}>
                  Organization Name <span className={styles.red}>*</span>
                </label> */}
                <TextField
                  name="name"
                  type="text"
                  size="small"
                  variant="outlined"
                  fullWidth
                  autoComplete="off"
                  // className={styles.inputstyle}
                  value={organizationData.name}
                  onChange={handleChange}
                />
                {error.name && (
                  <p className={styles.errorMsg}>
                    {"Organization Name is required"}
                  </p>
                )}
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <Typography
                  style={{ marginBottom: "2px", fontSize: "14px" }}
                  variant="body2"
                >
                  Username <span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  type="text"
                  // className={styles.input2}
                  value={organizationData.username || ""}
                  size="small"
                  autoComplete="off"
                  variant="outlined"
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <Typography
                  style={{ marginBottom: "2px", fontSize: "14px" }}
                  variant="body2"
                >
                  Industry
                </Typography>
                <TextField
                  name="industry"
                  size="small"
                  variant="outlined"
                  fullWidth
                  value={organizationData.industry || ""}
                  // className={styles.inputstyle}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} sm={4} md={4}>
                <Typography
                  style={{ marginBottom: "2px", fontSize: "14px" }}
                  variant="body2"
                >
                  Created By
                </Typography>
                <TextField
                  type="text"
                  // className={styles.input2}
                  value={organizationData.createdby}
                  size="small"
                  autoComplete="off"
                  variant="outlined"
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <Typography
                  style={{ marginBottom: "2px", fontSize: "14px" }}
                  variant="body2"
                >
                  Created On
                </Typography>
                <TextField
                  type="text"
                  // className={styles.input2}
                  value={
                    userInfo?.timeZone
                      ? //  moment
                        //     .unix(organizationData.createdon)
                        //     .tz(userInfo.timeZone)
                        //     .format("DD-MM-YYYY, hh:mm A")
                        helpers.date_Func(organizationData.createdon)
                      : // .format(
                        //   userInfo.time === "h:mm"
                        //     ? `${userInfo.dateFormat}, ${userInfo.time} A`
                        //     : `${userInfo.dateFormat} ${userInfo.time}`
                        // )
                        // moment
                        //   .unix(organizationData.createdon)
                        //   .format("DD/MM/YYYY, hh:mm A")
                        helpers.date_Func(organizationData.createdon)
                  }
                  size="small"
                  autoComplete="off"
                  variant="outlined"
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <Typography
                  style={{ marginBottom: "2px", fontSize: "14px" }}
                  variant="body2"
                >
                  Updated By
                </Typography>
                <TextField
                  type="text"
                  // className={styles.input2}
                  value={organizationData.updatedby || " "}
                  size="small"
                  autoComplete="off"
                  variant="outlined"
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <Typography
                  style={{ marginBottom: "2px", fontSize: "14px" }}
                  variant="body2"
                >
                  Updated On
                </Typography>
                <TextField
                  value={
                    userInfo?.timeZone
                      ? // moment
                        //     .unix(organizationData.updatedon)
                        //     .tz(userInfo.timeZone)
                        //     .format("DD-MM-YYYY, hh:mm A")
                        helpers.date_Func(organizationData.updatedon)
                      : // .format(
                        //   userInfo.time === "h:mm"
                        //     ? `${userInfo.dateFormat}, ${userInfo.time} A`
                        //     : `${userInfo.dateFormat} ${userInfo.time}`
                        // )
                        // moment
                        //   .unix(organizationData.updatedon)
                        //   .format("DD/MM/YYYY, hh:mm A")
                        helpers.date_Func(organizationData.updatedon)
                  }
                  type="text"
                  // className={styles.input2}
                  size="small"
                  autoComplete="off"
                  variant="outlined"
                  fullWidth
                  disabled
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12}>
                <Typography
                  style={{ marginBottom: "2px", fontSize: "14px" }}
                  variant="body2"
                >
                  Description
                </Typography>
                {/* <label className={styles.fieldHeading}>Description</label> */}
                {/* <textarea
                  name="description"
                  defaultValue={organizationData.description || ""}
                  className={styles.textareastyle}
                  onChange={handleChange}
                  rows="8"
                  variant="outlined"
                  fullWidth
                  autoComplete="off"
                /> */}

                <TextField
                  // className={styles.textareastyle}
                  autoComplete="off"
                  onChange={handleChange}
                  size="small"
                  name="description"
                  multiline
                  rows={7}
                  variant="outlined"
                  fullWidth
                  value={organizationData.description || ""}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>

      <div className={styles.wrapper} style={{ marginTop: 15 }}>
        <Accordion className={styles.fieldPanel} defaultExpanded>
          <AccordionSummary
            expandIcon={
              <ArrowDropDownRounded className={styles.accordianIcon} />
            }
          >
            <Typography className={styles.fieldHeading2}>
              Contact details
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={3} style={{ marginTop: 15 }}>
              <Grid item xs={9}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4} md={4}>
                    <Typography
                      style={{ marginBottom: "2px", fontSize: "14px" }}
                      variant="body2"
                    >
                      Email <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      value={organizationData.email}
                      type="text"
                      // className={styles.input2}
                      size="small"
                      autoComplete="off"
                      variant="outlined"
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <Typography
                      style={{ marginBottom: "2px", fontSize: "14px" }}
                      variant="body2"
                    >
                      Phone Number <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      name="contactNumber"
                      // className={styles.inputstyle}
                      value={organizationData.contactNumber}
                      type="tel"
                      maxLength="10"
                      size="small"
                      variant="outlined"
                      fullWidth
                      // className={styles.input}
                      onChange={handleChange}
                    />
                    {error.contactNumber && (
                      <p className={styles.errorMsg}>
                        {"Phone Number is required"}
                      </p>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={3} md={3}>
                <Typography
                  style={{ marginBottom: "2px", fontSize: "14px" }}
                  variant="body2"
                >
                  Street
                </Typography>
                {/* <label className={styles.fieldHeading}>Street</label> */}
                <TextField
                  value={EditOrgdata.street || ""}
                  // className={styles.inputstyle}
                  name="street"
                  type="text"
                  size="small"
                  autoComplete="off"
                  variant="outlined"
                  fullWidth
                  // className={styles.input}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <Typography
                  style={{ marginBottom: "2px", fontSize: "14px" }}
                  variant="body2"
                >
                  City
                </Typography>
                {/* <label className={styles.fieldHeading}>City</label> */}
                <TextField
                  value={EditOrgdata.city || ""}
                  // className={styles.inputstyle}
                  name="city"
                  size="small"
                  autoComplete="off"
                  variant="outlined"
                  fullWidth
                  // className={styles.input}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <Typography
                  style={{ marginBottom: "2px", fontSize: "14px" }}
                  variant="body2"
                >
                  State
                </Typography>
                {/* <label className={styles.fieldHeading}>State</label> */}
                <TextField
                  value={EditOrgdata.state || ""}
                  // className={styles.inputstyle}
                  name="state"
                  size="small"
                  autoComplete="off"
                  variant="outlined"
                  fullWidth
                  // className={styles.input}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <Typography
                  style={{ marginBottom: "2px", fontSize: "14px" }}
                  variant="body2"
                >
                  Country
                </Typography>
                {/* <label className={styles.fieldHeading}>Country</label> */}
                <TextField
                  value={EditOrgdata.country || ""}
                  // className={styles.inputstyle}
                  name="country"
                  // className={styles.input}
                  size="small"
                  autoComplete="off"
                  variant="outlined"
                  fullWidth
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </div>
      <div className={styles.wrapper} style={{ marginTop: 15 }}>
        <Accordion className={styles.fieldPanel} defaultExpanded>
          <AccordionSummary
            expandIcon={
              <ArrowDropDownRounded className={styles.accordianIcon} />
            }
          >
            <Typography className={styles.fieldHeading2}>Logo</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              container
              spacing={3}
              style={{ marginTop: 5, marginBottom: 70 }}
            >
              <Grid item xs={12} sm={3} md={3}>
                <Typography
                  style={{ marginBottom: "2px", fontSize: "14px" }}
                  variant="body2"
                >
                  Page Title
                </Typography>
                {/* <label className={styles.fieldHeading}>Page Title</label> */}
                <TextField
                  name="title"
                  type="text"
                  // className={styles.inputstyle}
                  size="small"
                  variant="outlined"
                  fullWidth
                  // className={styles.input}
                  value={organizationData.title || ""}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={4}>
                <Typography
                  style={{ marginBottom: "2px", fontSize: "14px" }}
                  variant="body2"
                >
                  Upload Logo
                </Typography>
                {/* <div>
                  <label className={styles.fieldHeading}>Upload Logo</label>
                </div> */}
                {uploadLogoModal ? (
                  <Grid item xs={3} sm={3} style={{ marginTop: 20 }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: 200,
                      }}
                    >
                      <div>
                        <img
                          variant="square"
                          src={organizationData.logo}
                          alt=""
                          style={{ maxHeight: 100, maxWidth: 100 }}
                        />
                      </div>
                      <div style={{ cursor: "pointer" }}>
                        <img
                          src="/images/container/delete.svg"
                          alt="delete"
                          onClick={() => setUploadLogoModal(false)}
                        />
                      </div>
                    </div>
                  </Grid>
                ) : (
                  <div>
                    <div className={styles.documentDropperWrapper2}>
                      <DropzoneArea
                        Icon={customdropzoneImage}
                        acceptedFiles={["image/*"]}
                        className={styles.documentDropperClass}
                        showPreviews={false}
                        filesLimit={1}
                        dropzoneClass="documentDropperClass"
                        dropzoneParagraphClass="documentDropperTextClass"
                        get
                        maxFileSize={1000000}
                        showAlerts={false}
                        onDropRejected={rejectedfile}
                        showPreviewsInDropzone={false}
                        onChange={(f) => handleUploadIcon(f)}
                      />
                      <p
                        className={styles.fieldHeading}
                        style={{ width: "17rem" }}
                      >{`Upload jpg, png or svg with transparent background of size max 1MB`}</p>
                    </div>
                  </div>
                )}
                {Logo && Logo.length > 0 && (
                  <div
                    className={styles.doc_container}
                    style={{
                      marginTop: 25,
                      width: 350,
                      paddingBottom: 50,
                      // border: "1px solid",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <Avatar src={Logo} alt=" " />
                    </div>
                    <div>
                      <input
                        className={styles.value}
                        value={LogoName}
                        placeholder="Enter Image Name"
                      ></input>
                    </div>
                    <div
                      style={{
                        marginTop: 10,
                      }}
                      onClick={handleDeleteLogoClick}
                    >
                      <img
                        src="/images/deleteLine.svg"
                        height={18}
                        width={18}
                        color="#000000"
                        alt="icon"
                      />
                    </div>
                  </div>
                )}
              </Grid>
              <Grid item xs={4}>
                <Typography
                  style={{ marginBottom: "2px", fontSize: "14px" }}
                  variant="body2"
                >
                  Upload Favicon
                </Typography>
                {/* <div>
                  <label className={styles.fieldHeading}>Upload Favicon</label>
                </div> */}
                {uploadFaviconModal ? (
                  <Grid item xs={3} sm={3} style={{ marginTop: 20 }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: 200,
                      }}
                    >
                      <div>
                        <img
                          variant="square"
                          src={organizationData.favicon}
                          alt=""
                          style={{ maxHeight: 100, maxWidth: 100 }}
                        />
                      </div>
                      <div style={{ cursor: "pointer" }}>
                        <img
                          src="/images/container/delete.svg"
                          alt="delete"
                          onClick={() => setUploadFaviconModal(false)}
                        />
                      </div>
                    </div>
                  </Grid>
                ) : (
                  <div>
                    <div className={styles.documentDropperWrapper2}>
                      <DropzoneArea
                        Icon={customdropzoneImage}
                        acceptedFiles={["image/*"]}
                        className={styles.documentDropperClass}
                        showPreviews={false}
                        filesLimit={1}
                        dropzoneClass="documentDropperClass"
                        dropzoneParagraphClass="documentDropperTextClass"
                        get
                        maxFileSize={1000000}
                        showAlerts={false}
                        onDropRejected={rejectedfile}
                        showPreviewsInDropzone={false}
                        onChange={handleUploadFavicon}
                      />
                      <p
                        className={styles.fieldHeading}
                      >{`Allowed jpg, png or svg of size max 1MB`}</p>
                    </div>
                  </div>
                )}
                {favicon && favicon.length > 0 && (
                  <div
                    className={styles.doc_container}
                    style={{
                      marginTop: 25,
                      width: 350,
                      paddingBottom: 50,
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <Avatar src={favicon} alt=" " />
                    </div>
                    <div>
                      <input
                        className={styles.value}
                        value={faviconName}
                        placeholder="Enter Image Name"
                      ></input>
                    </div>
                    <div
                      style={{
                        marginTop: 10,
                      }}
                      onClick={handleDeleteFaviconClick}
                    >
                      <img
                        src="/images/deleteLine.svg"
                        height={18}
                        width={18}
                        color="#000000"
                        alt="icon"
                      />
                    </div>
                  </div>
                )}
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </div>
    </main>
  );
};

const mapDispatchToProps = {
  change_logo,
};

const mapStateToProps = (state) => {
  const { userInfo } = state.apearenceReducer;
  return {
    userInfo,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditOrganizationScreen);
