const Styles = (theme) => ({
  main: {
    paddingTop: "4rem",

    "& .MuiContainer-root": {
      paddingLeft: 30,
      paddingRight: 30,
    },
  },
  topContent: {
    position: "fixed",
    top: "3.7rem",
    left: 0,
    padding: "0 30px",
    zIndex: 1000,
    width: "100%",
    backgroundColor: theme.palette.background.default,
  },
  wrapper: {
    // position: "relative",
    // top: "6rem",
    margin: "5.5rem 30px 1rem 30px",
  },
  fieldWrap: {
    marginTop: "1rem",
  },
  fieldPanel: {
    marginBottom: "1rem",
    marginTop: "1rem",
    boxShadow: "unset",
    border: "unset",
    backgroundColor: "transparent",

    "&:before": {
      backgroundColor: "unset",
    },

    "& .MuiAccordionSummary-root": {
      minHeight: 48,
      padding: 0,
      borderBottom: "2px solid #afafaf",

      "& .MuiAccordionSummary-content.Mui-expanded": {
        margin: 0,
      },

      "& .MuiIconButton-root": {
        padding: "0px 12px",

        "& .MuiTouchRipple-root": {
          display: "none",
        },
      },
    },

    "& .MuiAccordionDetails-root": {
      display: "block",
    },
  },
  accordianIcon: {
    fontSize: "3rem",
    paddingTop: 0,
    paddingBottom: 0,
    color: "#000000",
  },
  heading: {
    font: "normal normal bold 21px/28px Nunito",
    letterSpacing: 0,
    color: "#161616",
    marginTop: 10,
    marginBottom: 10,
  },
  sectionHeading: {
    font: "normal normal 600 16px/19px Nunito;",
    letterSpacing: 0,
    color: "#000000",
    width: "100%",
  },
  fieldgroupHeading: {
    font: "normal normal 600 15px/21px Nunito;",
    letterSpacing: 0,
    color: "#000000",
    width: "100%",
    borderBottom: "2px solid #afafaf",
    margin: 0,
    padding: "1rem 0 .5rem",
  },
  fieldHeading: {
    font: "normal normal 600 14px/19px Nunito;",
    letterSpacing: 0,
    color: "#000000",
    width: "100%",
    opacity: 1,
  },
  fileName: {
    font: "normal normal 12px/16px Nunito;",
    letterSpacing: 0,
    color: "#000000",
    width: "100%",
  },
  fieldHeading2: {
    font: "normal normal 600 14px/19px Nunito",
    letterSpacing: 0,
    color: "#000000",
    width: "100%",
  },
  documentDropperWrapper: {
    // marginTop: "1.5rem",
    // marginBottom: "1.5rem",

    "& .documentDropperClass": {
      width: "20rem",
      minHeight: "100px",
      padding: "1.5rem 3rem 0rem",
      border: "2.5px solid",
      borderColor: theme.palette.primary.main,
      borderRadius: 10,

      "& .MuiDropzoneArea-textContainer": {
        display: "flex",
        flexDirection: "column-reverse",
        alignItems: "center",
        justifyContent: "space-around",

        "& .MuiDropzoneArea-icon": {
          color: "#00285580",
          transform: "scale(1.4)",
        },

        "& .documentDropperTextClass": {
          fontSize: "1rem",
          fontWeight: "600",
          color: "#00285580",
        },
      },
    },
  },
  documentDropperWrapper2: {
    // marginTop: "1.5rem",
    // marginBottom: "1.5rem",

    "& .documentDropperClass": {
      width: "20rem",
      minHeight: "100px",
      padding: "1.5rem 3rem 0rem",
      border: "2.5px solid",
      borderColor: theme.palette.primary.main,
      borderRadius: 10,

      "& .MuiDropzoneArea-textContainer": {
        display: "flex",
        flexDirection: "column-reverse",
        alignItems: "center",
        justifyContent: "space-around",

        "& .MuiDropzoneArea-icon": {
          color: "#00285580",
          transform: "scale(1.4)",
        },

        "& .documentDropperTextClass": {
          fontSize: "1rem",
          fontWeight: "600",
          color: "#00285580",
        },
      },
    },
  },
  ErrordocumentDropperWrapper2: {
    marginTop: "1.5rem",
    marginBottom: "1.5rem",

    "& .documentDropperClass": {
      width: "20rem",
      minHeight: "100px",
      padding: "1.5rem 3rem 0rem",
      border: "2.5px solid",
      borderColor: "#E22626",
      borderRadius: 10,

      "& .MuiDropzoneArea-textContainer": {
        display: "flex",
        flexDirection: "column-reverse",
        alignItems: "center",
        justifyContent: "space-around",

        "& .MuiDropzoneArea-icon": {
          color: "#00285580",
          transform: "scale(1.4)",
        },

        "& .documentDropperTextClass": {
          fontSize: "1rem",
          fontWeight: "600",
          color: "#00285580",
        },
      },
    },
  },
  droppedDocuments: {
    marginTop: "2rem",
    marginBottom: "1rem",
    border: "2px solid",
    borderColor: theme.palette.primary.main,
    borderRadius: 15,
    padding: "1.5rem 1rem 1.5rem .8rem",
  },
  documentEditorWrapper: {
    marginTop: "1.5rem",
    marginBottom: "2rem",
    "& .rdw-editor-main": {
      background: "#FFFFFF",
      padding: "0 10px",
      maxHeight: "20rem",
      minHeight: "20rem",

      "& .public-DraftStyleDefault-ltr": {
        margin: "10px 0",
      },
    },
  },
  avatar: {
    marginRight: 10,
  },
  input: {
    width: "100%",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    height: "38px",
    padding: "0 15px",
    marginTop: 5,
    marginBottom: 5,
    outline: "none",
    font: "normal normal normal 12px/16px Nunito",
    // color: "#000000C6",

    "&::before": {
      borderBottom: "unset",
    },
  },
  input2: {
    width: "100%",
    background: "#f5f5f5 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    height: "38px",
    padding: "0 15px",
    marginTop: 5,
    marginBottom: 5,
    outline: "none",
    font: "normal normal normal 12px/16px Nunito",

    "&::before": {
      borderBottom: "unset",
    },
  },
  actionIcon: {
    padding: 8,
    margin: "0 10px",
  },
  textarea: {
    width: "100%",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    // height: "80%",
    marginTop: "5px",
    resize: "vertical",
    minHeight: "2.2rem",
    maxHeight: "13.2rem",
    padding: "10px 15px",
    outline: "none",
    font: "normal normal normal 12px/16px Nunito;",
  },
  imgStyle: {
    width: "150px",

    marginLeft: "-70px",
  },
  delBtnStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  fieldHeading: {
    font: "normal normal 600 14px/19px Nunito;",
    letterSpacing: 0,
    color: "#000000",
    width: "100%",
    opacity: 1,
  },
  testModal: {
    "& .ril__toolbar": {
      // top:'5rem',
      top: "92vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "unset",

      "& button": {
        backgroundColor: "rgba(0, 0, 0, 1)",
      },
    },
  },
  errorMsg: {
    color: "#DF0E0E",
    font: "normal normal 600 12px/16px Nunito;",
    margin: 0,
  },
  searchInput2: {
    width: "25%",
  },
  errorAuto: {
    // borderBottom: "2px solid #E22626 !important",
    "& .MuiAutocomplete-inputRoot::before": {
      borderBottom: "1px solid #E22626 !important",
    },
    marginBottom: 5,
  },
  fieldText: {
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: 10,
    font: "normal normal 600 12px/16px Nunito",
  },
});
export default Styles;
