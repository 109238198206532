import React from "react";
import { connect } from "react-redux";
import { addWebhooksUsageParams, updateWebhooks } from "../redux/action";
import { getApplicationDetails } from "../ApplicationDetails/Redux/action";
import {
  Button,
  Grid,
  TextField,
  Typography,
  FormControl,
  FormHelperText,
  Select,
  Divider,
} from "@material-ui/core";
import {
  TextValidator,
  SelectValidator,
  ValidatorForm,
} from "react-material-ui-form-validator";
import Breadcrumb from "components/Breadcrumbs/Breadcrumb";
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import Styles from "./Styles";
import { isDuplicate } from "../../../helpers/helpers";
import APIs from "../../../api/ApplicationAPIs/index";
import { withSnackbar } from "notistack";
import { RiEditLine } from "react-icons/ri";
import { checkPermission } from "helpers/permissons";
import HelpdeskFAB from "../../../views/HelpdeskFAB/HelpdeskFab";
class CreateWebhookForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ID: 0,
      error: false,
      err_msg: "",
      webhookData: {
        type: "",
        url: "",
        method: "",
        apiKey: "",
        key: "",
      },
      isEdit: false,
      checkEdit: {},
      ajax: false,
      isDisabled: false,
    };
  }

  componentDidMount() {
    const { location } = this.props;
    if (location.state !== undefined) {
      let { webhooksData, edit, ajax, appID, disabled, appName } =
        location.state;
      this.setState({
        webhookData: {
          ...webhooksData,
        },
        isEdit: edit,
        checkEdit: {
          type: webhooksData.type,
          url: webhooksData.url,
          method: webhooksData.method,
        },
        ajax: ajax,
        ID: appID,
        isDisabled: disabled,
        appName: appName,
      });
    }
  }
  handleOnchange = (value) => {
    this.setState({
      webhookData: { ...value },
    });
  };

  multilineAdd = async () => {
    const { isEdit, webhookData } = this.state;
    if (isEdit) {
      let updateWebhooksdata = [...this.props.webhooks];
      updateWebhooksdata = updateWebhooksdata.map((item, index) => {
        return item.key === webhookData.key ? { ...webhookData } : { ...item };
      });
      await this.props.edit_webhooks(updateWebhooksdata);
      this.props.history.push("/app/onboard-application", { status: true });
    } else {
      await this.props.add_webhooks(this.state.webhookData);
      this.setState({
        webhookData: {
          type: "",
          url: "",
          method: "",
          apiKey: "",
          key: this.props.webhooks.length,
        },
        isEdit: false,
      });
    }
  };

  singleObjectSubmit = async () => {
    if (this.state.ajax) {
      console.log(this.state.webhookData, "23232323");
    } else {
      const { webhookData } = this.state;
      await this.props.add_webhooks(webhookData);
      this.props.history.push("/app/onboard-application", { status: true });
    }
  };

  //ajax call func
  editAppWebhooks = async () => {
    let { _id, key, ...data } = this.state.webhookData;
    try {
      const res = await APIs.editWebhook(
        this.state.webhookData.appId,
        _id,
        data
      );
      this.props.enqueueSnackbar(
        res.data._msg || "Application Webhooks updated successfully",
        {
          variant: "success",
          autoHideDuration: 3000,
        }
      );
      this.props.history.push("/app/detail", { appId: this.state.ID });
    } catch (err) {
      this.props.enqueueSnackbar(err.message || "Something Went wrong", {
        variant: "error",
        autoHideDuration: 3000,
      });
      console.error(err);
    }
    // let res = await APIs.editWebhook(this.state.webhookData.appId, _id, data);
    // if (res.data) {
    //   this.props.enqueueSnackbar("Application Webhooks updated successfully", {
    //     variant: "success",
    //   });
    //   this.props.history.push("/app/detail", { appId: this.state.ID });
    // }
  };

  addWebhooks = async () => {
    let { key, ...data } = this.state.webhookData;
    try {
      const res = await APIs.addWebhook(this.state.ID, data);
      this.props.enqueueSnackbar(
        res.data._msg || "Webhook details saved successfully",
        { variant: "success", autoHideDuration: 3000 }
      );
    } catch (err) {
      this.props.enqueueSnackbar(
        err?.response?.data?._msg || "Something Went wrong",
        {
          variant: "error",
          autoHideDuration: 3000,
        }
      );
      console.error(err);
    }
  };

  ajaxFuncs = async () => {
    let { isEdit } = this.state;
    if (isEdit) {
      this.editAppWebhooks();
    } else {
      await this.addWebhooks();
      await this.props.getDetails(this.state.ID);
      this.setState({
        webhookData: {
          type: "",
          url: "",
          method: "",
          apiKey: "",
        },
        isEdit: false,
      });
    }
  };

  singleAjaxFunc = async () => {
    await this.addWebhooks();
    this.props.history.push("/app/detail", { appId: this.state.ID });
  };

  onSubmitajax = (e) => {
    const { webhookData, isEdit, checkEdit } = this.state;
    let flag = false;

    if (
      !isEdit ||
      checkEdit.url !== webhookData.url ||
      checkEdit.method !== webhookData.method ||
      checkEdit.type !== webhookData.type
    ) {
      flag = isDuplicate(
        this.props.webhooks_details || [],
        (f) =>
          f.url === webhookData.url &&
          f.method === webhookData.method &&
          f.type === webhookData.type
      );
    }

    if (flag) {
      this.setState({
        error: true,
      });
      return;
    }

    this.setState({
      err_msg: "",
      error: false,
    });

    if (e.nativeEvent.submitter.name === "multiple") {
      this.ajaxFuncs();
    }

    if (e.nativeEvent.submitter.name === "single") {
      this.singleAjaxFunc();
    }
  };

  onSubmit = async (e) => {
    const { webhookData, isEdit, checkEdit } = this.state;
    let flag = false;
    if (
      !isEdit ||
      checkEdit.url !== webhookData.url ||
      checkEdit.method !== webhookData.method ||
      checkEdit.type !== webhookData.type
    ) {
      flag = isDuplicate(
        this.props.webhooks,
        (f) =>
          f.url === webhookData.url &&
          f.method === webhookData.method &&
          f.type === webhookData.type
      );
    }

    if (flag) {
      this.setState({
        error: true,
      });
      return;
    }

    this.setState({
      err_msg: "",
      error: false,
    });

    if (e.nativeEvent.submitter.name === "multiple") {
      this.multilineAdd();
    }

    if (e.nativeEvent.submitter.name === "single") {
      this.singleObjectSubmit();
    }
  };

  setDuplicateNull = () => {
    this.setState({
      error: false,
      err_msg: "",
    });
  };

  render() {
    const {
      webhookData,
      isEdit,
      error,
      err_msg,
      ajax,
      ID,
      isDisabled,
      appName,
    } = this.state;
    const { classes } = this.props;
    console.log(webhookData, ajax, ID, "thisb is webhooks");
    return (
      <div>
        <Grid item sm={12} md={12} xs={12} lg={12}>
          <Breadcrumb
            {...this.props}
            links={[
              { name: "Applications", url: "/app/applications" },
              {
                name: `${ajax ? `${appName}` : "Onboard Application"}`,
                url: `${ajax ? "/app/detail" : "/app/onboard-application"}`,
                routeParams: ajax ? { appId: this.state.ID } : { status: true },
              },
              {
                name: "Webhooks",
                url: "/app/create-webhook-form",
              },
            ]}
          />
        </Grid>
        <ValidatorForm
          ref="form"
          autoComplete="off"
          onSubmit={(e) => {
            if (ajax) {
              this.onSubmitajax(e);
            } else {
              this.onSubmit(e);
            }
          }}
        >
          <Grid container spacing={0}>
            <Grid item sm={12} md={12} xs={12} lg={12}>
              <Grid
                container
                spacing={1}
                direction="row"
                className={classes.root}
                alignItems="center"
              >
                <Grid item md={6}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="h6" className={classes.title}>
                      Webhooks
                    </Typography>
                  </div>
                </Grid>
                <Grid
                  item
                  sm={6}
                  md={6}
                  container
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    {!isDisabled && (
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() => {
                          if (ajax) {
                            this.props.history.push("/app/detail", {
                              appId: this.state.ID,
                            });
                          } else {
                            this.props.history.push(
                              "/app/onboard-application",
                              {
                                status: true,
                              }
                            );
                          }
                        }}
                      >
                        Cancel
                      </Button>
                    )}
                  </Grid>
                  <Grid item>
                    {!isDisabled && (
                      <Button
                        name="multiple"
                        color="primary"
                        size="small"
                        variant={isEdit ? "contained" : "outlined"}
                        type="submit"
                        disabled={
                          isEdit
                            ? !checkPermission("btn111")
                            : !checkPermission("btn110")
                        }
                      >
                        {isEdit ? "Save" : "Create and Add Another"}
                      </Button>
                    )}
                  </Grid>
                  <Grid item>
                    {!isEdit && (
                      <Button
                        name="single"
                        size="small"
                        variant="contained"
                        type="submit"
                        disabled={!checkPermission("btn109")}
                      >
                        {"Create"}
                      </Button>
                    )}
                  </Grid>
                  <Grid>
                    {isDisabled && (
                      <Button
                        size="small"
                        variant="contained"
                        onClick={() =>
                          this.setState({
                            isDisabled: false,
                          })
                        }
                        disabled={!checkPermission("btn111")}
                        startIcon={<RiEditLine style={{ fontSize: "20px" }} />}
                      >
                        Edit
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12}>
              <Divider className={classes.divider} />
            </Grid>
            <Grid item md={12} style={{ marginTop: "22px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3} md={3}>
                  <Typography style={{ marginBottom: "2px" }} variant="body2">
                    Type <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <FormControl error={error} fullWidth>
                    <SelectValidator
                      disabled={isDisabled}
                      variant="outlined"
                      //   style={{ width: "200px" }}
                      fullWidth
                      size="small"
                      value={webhookData.type}
                      SelectProps={{
                        native: true,
                      }}
                      name="applicationWebHookType"
                      onChange={(e) =>
                        this.handleOnchange({
                          ...webhookData,
                          type: e.target.value,
                        })
                      }
                      onFocus={this.setDuplicateNull}
                      validators={["required"]}
                      errorMessages={["Please select Type "]}
                      error={error}
                    >
                      <option value="">- select -</option>
                      <option value="On Subscribe">On Subscribe</option>
                      <option value="On Unsubscribe">On Unsubscribe</option>
                      <option value="On User Create">On User Create</option>
                      <option value="On Secret Key Generator">
                        On Secret Key Generator
                      </option>
                    </SelectValidator>
                    {error ? (
                      <FormHelperText>
                        Duplicate Type is not allowed!
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>
                <Grid item md={3} xs={12} sm={3}>
                  <Typography style={{ marginBottom: "2px" }} variant="body2">
                    URL <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextValidator
                    disabled={isDisabled}
                    validators={["required"]}
                    errorMessages={["URL is required"]}
                    fullWidth
                    size="small"
                    variant="outlined"
                    id="url"
                    value={webhookData.url}
                    name="applicationWebHookUrl"
                    onChange={(e) =>
                      this.handleOnchange({
                        ...webhookData,
                        url: e.target.value.trimStart(),
                      })
                    }
                    onFocus={this.setDuplicateNull}
                    error={error}
                    helperText={error ? "URL is already exist" : ""}
                  />
                </Grid>
                <Grid item md={3} xs={12} sm={3}>
                  <Typography style={{ marginBottom: "2px" }} variant="body2">
                    Method <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <FormControl error={error} fullWidth name="method">
                    <SelectValidator
                      disabled={isDisabled}
                      variant="outlined"
                      fullWidth
                      size="small"
                      SelectProps={{
                        native: true,
                      }}
                      value={webhookData.method}
                      name="applicationWebHookMethod"
                      onChange={(e) =>
                        this.handleOnchange({
                          ...webhookData,
                          method: e.target.value,
                        })
                      }
                      onFocus={this.setDuplicateNull}
                      validators={["required"]}
                      errorMessages={["Please select Method "]}
                      error={error}
                    >
                      <option value="">- select -</option>
                      <option value="GET">GET</option>
                      <option value="POST">POST</option>
                      <option value="PUT">PUT</option>
                    </SelectValidator>
                    {error ? (
                      <FormHelperText>
                        Duplicate METHOD is not allowed!
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>
                <Grid item md={3} xs={12} sm={3}>
                  <Typography style={{ marginBottom: "2px" }} variant="body2">
                    API Key
                  </Typography>
                  <TextValidator
                    disabled={isDisabled}
                    fullWidth
                    size="small"
                    variant="outlined"
                    id="apiKey"
                    onChange={(e) =>
                      this.handleOnchange({
                        ...webhookData,
                        apiKey: e.target.value.trimStart(),
                      })
                    }
                    value={webhookData.apiKey}
                    name="applicationWebHookApiKey"
                  />
                </Grid>
              </Grid>
            </Grid>
            <HelpdeskFAB />
          </Grid>
        </ValidatorForm>
      </div>
    );
  }
}

CreateWebhookForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    webhooks: state.application.onBoard.webhooks,
    webhooks_details: state.appDetailsRedcer.applicationDetails.webhooks,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getDetails: (id) => dispatch(getApplicationDetails(id)),
  add_webhooks: (data) => dispatch(addWebhooksUsageParams(data)),
  edit_webhooks: (data) => dispatch(updateWebhooks(data)),
});
const CreateWebhook = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateWebhookForm);

export default withStyles(Styles)(withSnackbar(CreateWebhook));
