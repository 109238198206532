const Styles = (theme) => ({
  root: {},

  flexBwCenter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  p: {
    font: "normal normal normal 12px/16px Nunito",
    color: "#BEBEBE",
    marginRight: 50,
  },
  activeFltr: {
    marginRight: "25px",
  },
  activeBadge: {
    backgroundColor: "#21BA45",
  },
  inactiveBadge: {
    backgroundColor: "#BEBEBE",
  },

  blockBadge: {
    backgroundColor: "#DB2828",
  },
  h6: {
    fontWeight: "bolder",
  },

  chart: {
    marginTop: "2%",
    display: "flex",
    justifyContent: "center",
  },

  cardTitle: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 15,
  },
  minCard: {
    padding: "5px",
    backgroundColor: "#E8F3FA",
    borderRadius: 5,

    //opacity: "10%",
  },
  body2: {
    color: "#2185D0",
    opacity: "100%",
    fontWeight: "bold",
    font: "normal normal 600 14px/19px Nunito",
  },
  span: {
    font: "normal normal 600 16px/22px Nunito",
    color: "#262626",
    fontWeight: "bolder",
  },
  typoTitle: {
    font: "normal normal normal 14px/19px Nunito",
    color: "#BEBEBE",
    opacity: 1,
    marginBottom: 5,
  },
  typoVal: {
    font: "normal normal 600 14px/19px Nunito",
    fontWeight: "bold",
    color: "#262626",
    opacity: 1,
  },
  chartTitle: {
    font: "normal normal bold 16px/22px Nunito",
    color: "#161616",
  },
  tabs: {
    textTransform: "capitalize",
  },
  table: {
    marginTop: 15,
  },
});
export default Styles;
