export const FETCH_HELPDESKDATA = "FETCH_HELPDESKDATA";

export const FETCH_HELPDESKDATA_FAILURE = "FETCH_HELPDESKDATA_FAILURE";

export const ADD_HELP_DESK_DATA = "ADD_HELP_DESK_DATA";
export const ADD_HELP_DESK_DATA_SUCCESS = "ADD_HELP_DESK_DATA_SUCCESS";
export const ADD_HELP_DESK_DATA_FAILURE = "ADD_HELP_DESK_DATA_FAILURE";


export const EDIT_HELP_DESK_DATA = "EDIT_HELP_DESK_DATA";
export const EDIT_HELP_DESK_DATA_SUCCESS = "EDIT_HELP_DESK_DATA_SUCCESS";
export const EDIT_HELP_DESK_DATA_FAILURE = "EDIT_HELP_DESK_DATA_FAILURE";
