import * as actions from "./actionTypes";
import invoiceAPIS from "api/InvoiceAPIs/index";

export default {
  actionfetchAllInvoices,
  updateInvoiceHiddenCol,
};

function updateInvoiceHiddenCol(hidden) {
  // type: actions.UPDATE_INVOICE_HIDDEN_COL,
  // payload: hidden,

  return async (dispatch) => {
    dispatch({
      type: actions.UPDATE_INVOICE_HIDDEN_COL,
      payload: hidden,
    });
  };
}

function actionfetchAllInvoices(pageno, size, filters, sort) {
  console.log({ actions });
  return async (dispatch) => {
    dispatch({
      type: actions.FETCHING_INVOICE,
      loading: true,
    });
    try {
      const res = await invoiceAPIS.fetchAllInvoicesWithParams(
        pageno,
        size,
        filters,
        sort
      );

      let total = res.totalCount;
      dispatch({
        type: actions.FETCHING_INVOICE_SUCCESS,
        loading: false,
        data: res.data || [],
        totalCount: total,
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: actions.FETCHING_INVOICE_FAILURE,
        loading: false,
        error: err,
      });
    }
  };
}
