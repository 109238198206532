import React from "react";
import { connect } from "react-redux";
import { addAPI, updateAPI } from "../redux/action";
import { getApplicationDetails } from "../ApplicationDetails/Redux/action";
import {
  Button,
  Grid,
  Typography,
  FormControl,
  FormHelperText,
  Divider,
  TextField,
  IconButton,
} from "@material-ui/core";
import {
  TextValidator,
  SelectValidator,
  ValidatorForm,
} from "react-material-ui-form-validator";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Card, CardContent, CardHeader } from "@material-ui/core";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";
import Styles from "./styles";
import Breadcrumb from "components/Breadcrumbs/Breadcrumb";
import Box from "@material-ui/core/Box";
import ApplicationAPIs from "api/ApplicationAPIs/index";
import { withSnackbar } from "notistack";
import APIs from "api/ApplicationAPIs";
import { RiEditLine } from "react-icons/ri";
import Upload from "./Img/Icon metro-folder-upload.svg";
import { checkPermission } from "helpers/permissons";
import HelpdeskFAB from "../../../views/HelpdeskFAB/HelpdeskFab";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
const api_initialData = {
  name: "",
  apiType: "",
  url: "",
  method: "",
  deprication_date: "",
  industryType: "",
  source: {
    cluster: "",
    topic: "",
    username: "",
    password: "",
  },
  res: {},
  req: {},
  messageBroker: "",
};

class CreateAPI extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      appId: 0,
      err_msg: "",
      error: false,
      api: {
        ...api_initialData,
      },
      isEdit: false,
      checkEdit: {},
      currentTab: 0,
      isReferenceMapEnabed: false,
      cdu: [],
      showValue: false,
      expanded: "",
      inputBody: null,
      outputBody: null,
      ajax: false,
      isDisabled: false,
      inputPayload: [],
      noValuesHasIt: false,
      industryType: [],
      showPassword: false,
    };
  }

  fetchIndustry_type = async () => {
    try {
      const res = await APIs.fetchIndustryType();
      this.setState({
        industryType: res || [],
      });
    } catch (err) {}
  };

  async componentDidMount() {
    console.log("executed component did mount");
    const { location } = this.props;
    if (location.state !== undefined) {
      let { apiData, edit, ajax, appID, disabled, from, appName } =
        location.state;

      if (from === "dataFabric-cdutoapi") {
        let { mapping } = location.state;
        // this.setState({ req: { ...mapping }, res: res });
        this.setState({
          api: {
            ...apiData,
            req: {
              ...mapping,
            },
          },
        });
      } else if (from === "dataFabric-apitocdu") {
        let { mapping } = location.state;
        // this.setState({ res: mapping, req: req });
        this.setState({
          api: {
            ...apiData,
            res: {
              ...mapping,
            },
          },
        });
      } else {
        this.setState({
          api: {
            ...apiData,
          },
        });
      }
      this.setState({
        // api: { ...apiData },
        isEdit: edit,
        ajax: ajax,
        appId: appID,
        isDisabled: disabled,
        appName: appName,
      });
    }
    await this.fetchIndustry_type();
  }

  handleOnchange = (value, type) => {
    this.setState({
      api: { ...value },
    });
    if (type === "industryType") {
      this.setState({
        cduMapping_input: [{}],
        cduMapping_output: [{}],
      });
    }
  };

  multilineAdd = async () => {
    const { isEdit, api } = this.state;
    if (isEdit) {
      let updateAPI = [...this.props.applicationApi];
      updateAPI = updateAPI.map((item, index) => {
        return item.key === api.key
          ? {
              ...api,
            }
          : { ...item };
      });
      await this.props.edit_api(updateAPI);
      this.props.history.push("/app/onboard-application", { status: true });
    } else {
      console.log(this.state.api, "api api api");
      await this.props.add_api({
        ...this.state.api,
      });
      this.setState({
        api: {
          ...api_initialData,
          key: this.props.applicationApi.length,
        },
        isEdit: false,
      });
    }
  };

  findDuplicateApiName = () => {
    const { applicationApi } = this.props;
    const { apiData } = this.props.location?.state;
    const { api } = this.state;

    if (this.state.isEdit) {
      return applicationApi
        .filter((f) => f.name !== apiData.name)
        .map((f) => f.name.toUpperCase())
        .indexOf(api.name.toUpperCase());
    } else {
      return applicationApi
        .map((f) => f.name.toUpperCase())
        .indexOf(api.name.toUpperCase());
    }
  };

  singleObjectSubmit = async () => {
    await this.props.add_api({
      ...this.state.api,
    });
    this.props.history.push("/app/onboard-application", { status: true });
  };

  editApplication_API = async (appId, apiId, payload) => {
    try {
      let p = { ...payload };
      delete p["_id"];
      const res = await ApplicationAPIs.editApplicationAPI(appId, apiId, p);
      this.props.enqueueSnackbar(
        res.data._msg || "API details saved successfully",
        { variant: "success", autoHideDuration: 3000 }
      );
      this.props.history.push("/app/detail", { appId: appId });
    } catch (err) {
      this.props.enqueueSnackbar(
        err?.response?.data?._msg || "Something Went wrong",
        {
          variant: "error",
          autoHideDuration: 3000,
        }
      );
      console.error(err);
    }
  };

  // functions
  navigateToDatafabric = (type) => {
    let { api, isEdit, ajax, appId, isDisabled, appName } = this.state;
    let flag = false;
    if (api.apiType === "Https" && api.method !== "GET") {
      flag = true;
    }
    this.props.history.push("/app/data-fabric", {
      type: type,
      apiData: { ...api },
      edit: isEdit,
      ajax,
      appID: appId,
      disabled: isDisabled,
      api_Type: api.apiType,
      toggleBtn: flag,
      appName,
      pageFrom: "appAPIs",
    });
  };

  ///

  checkCduMappingPayload = (payload) => {
    const { req, res } = payload;
    const filteredReq = req.filter(
      (item) =>
        item.cdu_name &&
        item.cdu_name.length !== 0 &&
        item.field_name &&
        item.field_name.trim().length !== 0
    );
    const filteredRes = res.filter(
      (item) =>
        item.cdu_name &&
        item.cdu_name.length !== 0 &&
        item.field_name &&
        item.field_name.trim().length !== 0
    );
    payload.req = filteredReq;
    payload.res = filteredRes;
    return payload;
  };

  //creating api after onboarding,
  addApplicationAPI = async (appId, payload) => {
    try {
      const res = await APIs.addApplicationAPI(appId, payload);
      console.log("ressssssssssssssssssssssssssssss", res);
      this.props.enqueueSnackbar(
        res.data._msg || "API details added successfully",
        { variant: "success", autoHideDuration: 3000 }
      );
    } catch (err) {
      console.log("errr");
      const msg = err?.response?.data?._msg || "Connection error!";
      this.props.enqueueSnackbar(msg, {
        variant: "error",
        autoHideDuration: 3000,
      });
      console.error(err);
    }
  };

  singleObjectSubmitAjax = async () => {
    const { api } = this.state;
    await this.addApplicationAPI(this.state.appId, {
      ...api,
    });
    this.props.history.push("/app/detail", {
      appId: this.state.appId,
    });
  };

  multilineAddAjaxFunc = async () => {
    const { isEdit, api } = this.state;
    if (isEdit) {
      await this.editApplication_API(api.appId, api._id, {
        ...api,
      });
    } else {
      await this.addApplicationAPI(this.state.appId, {
        ...api,
      });
      this.props.getDetails(this.state.appId);
      this.setState({
        api: {
          name: "",
          apiType: "",
          url: "",
          method: "",
          deprication_date: "",
          industryType: "",
          source: {
            cluster: "",
            topic: "",
            username: "",
            password: "",
          },
          req: {},
          res: {},
        },
        isEdit: false,
      });
    }
  };

  // While onboarding the application

  onSubmit = async (e) => {
    // [TODO] Need to check from the for duplicates in onboarded page.

    if (this.findDuplicateApiName() !== -1) {
      this.props.enqueueSnackbar("API name is already exist!", {
        variant: "error",
        autoHideDuration: 3000,
      });
      return;
    }
    if (e.nativeEvent.submitter.name === "multiple") {
      this.multilineAdd();
    }
    if (e.nativeEvent.submitter.name === "single") {
      this.singleObjectSubmit();
    }
  };

  // While edit we are using this function
  onSubmitAjaxFunc = async (e) => {
    if (e.nativeEvent.submitter.name === "multiple") {
      this.multilineAddAjaxFunc();
    }
    if (e.nativeEvent.submitter.name === "single") {
      this.singleObjectSubmitAjax();
    }
  };
  handleClickShowPassword = () =>
    this.setState({ showPassword: !this.state.showPassword });
  handleMouseDownPassword = () =>
    this.setState({ showPassword: !this.state.showPassword });
  render() {
    const {
      error,
      api,
      isEdit,
      ajax,
      isDisabled,
      cdu,
      industryType,
      req,
      res,
      appName,
    } = this.state;
    const { API_data } = this.props;
    const isIndustryTypeEnabled =
      api.industryType && api.industryType.trim().length !== 0;
    // console.log(cduMapping_input, cduMapping_output, api, "this is sapi");

    const { classes } = this.props;
    console.log("props and state", this.props);
    return (
      <div>
        <Breadcrumb
          {...this.props}
          links={[
            { name: "Applications", url: "/app/applications" },
            {
              name: `${ajax ? `${appName}` : "Onboard Application"}`,
              url: `${ajax ? "/app/detail" : "/app/onboard-application"}`,
              routeParams: ajax
                ? { appId: this.state.appId }
                : { status: true },
            },
            {
              name: "Application API",
              url: "/app/create-auth-form",
            },
          ]}
        />
        <ValidatorForm
          autoComplete="off"
          onSubmit={(e) => {
            if (ajax) {
              this.onSubmitAjaxFunc(e);
            } else {
              this.onSubmit(e);
            }
          }}
        >
          <Grid container spacing={0}>
            <Grid item sm={12} md={12} xs={12} lg={12}>
              <Grid
                container
                spacing={1}
                direction="row"
                alignItems="center"
                className={classes.root}
              >
                <Grid item md={6}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="h6" className={classes.title}>
                      API
                    </Typography>
                  </div>
                </Grid>
                <Grid
                  item
                  sm={6}
                  container
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    {!isDisabled && (
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() => {
                          if (ajax) {
                            this.props.history.push("/app/detail", {
                              appId: this.state.appId,
                            });
                          } else {
                            this.props.history.push(
                              "/app/onboard-application",
                              {
                                status: true,
                              }
                            );
                          }
                        }}
                      >
                        Cancel
                      </Button>
                    )}
                  </Grid>
                  <Grid item>
                    {!isDisabled && (
                      <Button
                        name="multiple"
                        color="primary"
                        size="small"
                        style={{ color: "white" }}
                        disabled={
                          isEdit
                            ? !checkPermission("btn99")
                            : !checkPermission("btn98")
                        }
                        variant="contained"
                        // variant={isEdit ? "contained" : "outlined"}
                        type="submit"
                      >
                        {isEdit ? "Save" : "Create and Add Another"}
                      </Button>
                    )}
                  </Grid>
                  <Grid item>
                    {!isEdit && (
                      <Button
                        name="single"
                        size="small"
                        variant="contained"
                        type="submit"
                        disabled={!checkPermission("btn97")}
                      >
                        {"Create"}
                      </Button>
                    )}
                  </Grid>
                  <Grid>
                    {isDisabled && (
                      <Button
                        size="small"
                        variant="contained"
                        disabled={!checkPermission("btn99")}
                        onClick={() =>
                          this.setState({
                            isDisabled: false,
                          })
                        }
                        startIcon={<RiEditLine style={{ fontSize: "20px" }} />}
                      >
                        Edit
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12}>
              <Divider className={classes.divider} />
            </Grid>
            <Grid
              item
              md={12}
              style={{ marginTop: "22px" }}
              container
              justifyContent="center"
            >
              <Grid container spacing={2}>
                <Grid item md={3}>
                  <div style={{ width: "100%" }}>
                    <Typography style={{ marginBottom: "2px" }} variant="body2">
                      API Name <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextValidator
                      disabled={isDisabled}
                      fullWidth
                      size="small"
                      variant="outlined"
                      value={api.name}
                      onChange={(e) =>
                        this.handleOnchange({
                          ...api,
                          name: e.target.value.trimStart(),
                        })
                      }
                      name="apiname"
                      validators={["required"]}
                      errorMessages={["Please enter api name"]}
                      onFocus={this.setDuplicateNull}
                    />
                  </div>
                </Grid>
                <Grid item md={3}>
                  <div style={{ width: "100%" }}>
                    <Typography style={{ marginBottom: "2px" }} variant="body2">
                      Industry Type <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <FormControl
                      error={error}
                      size="small"
                      variant="outlined"
                      fullWidth
                    >
                      <SelectValidator
                        disabled={isDisabled}
                        fullWidth
                        variant="outlined"
                        size="small"
                        SelectProps={{
                          native: true,
                        }}
                        value={api.industryType}
                        inputProps={{
                          name: "apiIndustryType",
                          id: "apiIndustryType",
                        }}
                        onFocus={this.setDuplicateNull}
                        onChange={(e) =>
                          this.handleOnchange(
                            {
                              ...api,
                              industryType: e.target.value,
                            },
                            "industryType"
                          )
                        }
                        validators={["required"]}
                        errorMessages={["Please select industry type"]}
                        error={error}
                      >
                        <option value=""> - select -</option>
                        {industryType.map((item) => (
                          <option value={item}>{item}</option>
                        ))}
                      </SelectValidator>
                      {error ? (
                        <FormHelperText>
                          {/* {error ? "Duplicate api method is not allowed" : null} */}
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                  </div>
                </Grid>
                <Grid item md={3}>
                  <div style={{ width: "100%" }}>
                    <Typography style={{ marginBottom: "2px" }} variant="body2">
                      EOL
                    </Typography>
                    <TextValidator
                      disabled={isDisabled}
                      fullWidth
                      type="date"
                      autoComplete="off"
                      size="small"
                      variant="outlined"
                      id="date"
                      value={api.deprication_date}
                      name="deprication_date"
                      onChange={(e) =>
                        this.handleOnchange({
                          ...api,
                          deprication_date: e.target.value,
                        })
                      }
                    />
                  </div>
                </Grid>
                <Grid item md={3}>
                  <div style={{ width: "100%" }}>
                    <Typography style={{ marginBottom: "2px" }} variant="body2">
                      API Type <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <FormControl error={error} fullWidth>
                      <SelectValidator
                        disabled={isDisabled}
                        variant="outlined"
                        fullWidth
                        size="small"
                        SelectProps={{
                          native: true,
                        }}
                        value={api.apiType}
                        onChange={(e) => {
                          const {
                            method,
                            res,
                            source,
                            url,
                            messageBroker,
                            ...otherApiData
                          } = api;
                          this.handleOnchange({
                            ...otherApiData,
                            apiType: e.target.value,
                          });
                        }}
                        inputProps={{
                          name: "apiType",
                        }}
                        onFocus={this.setDuplicateNull}
                        validators={["required"]}
                        errorMessages={["Please select api type"]}
                        error={error}
                      >
                        <option value="">- select -</option>
                        <option value="Https">HTTPS</option>
                        <option value="MessageBroker">Message Broker</option>
                        {/* <option value="Pub/Sub">Pub/Sub</option> */}
                      </SelectValidator>
                      {error ? (
                        <FormHelperText>
                          {error ? "Duplicate api type is not allowed" : null}
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                  </div>
                </Grid>
                {api.apiType === "MessageBroker" && (
                  <Grid item md={3}>
                    <div style={{ width: "100%" }}>
                      <Typography
                        style={{ marginBottom: "2px" }}
                        variant="body2"
                      >
                        Message Broker <span style={{ color: "red" }}>*</span>
                      </Typography>
                      <FormControl
                        // error={error}
                        size="small"
                        variant="outlined"
                        fullWidth
                      >
                        <SelectValidator
                          disabled={isDisabled}
                          fullWidth
                          variant="outlined"
                          size="small"
                          SelectProps={{
                            native: true,
                          }}
                          value={api.messageBroker}
                          inputProps={{
                            name: "messageBroker",
                            id: "messageBroker",
                          }}
                          onFocus={this.setDuplicateNull}
                          onChange={(e) =>
                            this.handleOnchange({
                              ...api,
                              messageBroker: e.target.value,
                            })
                          }
                          validators={["required"]}
                          errorMessages={["Please select message broker"]}
                          error={error}
                        >
                          <option value=""> - select -</option>
                          <option value="Kafka">Kafka</option>
                          <option value="RabbitMQ">RabbitMQ</option>
                          <option value="MQTT">MQTT</option>
                        </SelectValidator>
                        {/* {error ? (
                          <FormHelperText>
                            {error
                              ? "Duplicate message broker is not allowed"
                              : null}
                          </FormHelperText>
                        ) : null} */}
                      </FormControl>
                    </div>
                  </Grid>
                )}{" "}
                {api.apiType === "MessageBroker" && (
                  <Grid item md={3}>
                    <div style={{ width: "100%" }}>
                      <Typography
                        style={{ marginBottom: "2px" }}
                        variant="body2"
                      >
                        Broker Address <span style={{ color: "red" }}>*</span>
                      </Typography>
                      <TextValidator
                        disabled={isDisabled}
                        fullWidth
                        size="small"
                        variant="outlined"
                        value={api?.source?.cluster || ""}
                        onChange={(e) =>
                          this.handleOnchange({
                            ...api,
                            source: {
                              ...api.source,
                              cluster: e.target.value.trimStart(),
                            },
                          })
                        }
                        name="cluster-address"
                        validators={["required"]}
                        errorMessages={["Please enter cluster address"]}
                        onFocus={this.setDuplicateNull}
                      />
                    </div>
                  </Grid>
                )}
                {api.apiType === "MessageBroker" && (
                  <Grid item md={3}>
                    <div style={{ width: "100%" }}>
                      <Typography
                        style={{ marginBottom: "2px" }}
                        variant="body2"
                      >
                        Username
                      </Typography>
                      <TextValidator
                        autoComplete="off"
                        disabled={isDisabled}
                        fullWidth
                        size="small"
                        variant="outlined"
                        value={api?.source?.username || ""}
                        onChange={(e) =>
                          this.handleOnchange({
                            ...api,
                            source: {
                              ...api.source,
                              username: e.target.value,
                            },
                          })
                        }
                        name="user-name"
                        onFocus={this.setDuplicateNull}
                      />
                    </div>
                  </Grid>
                )}
                {api.apiType === "MessageBroker" && (
                  <Grid item md={3}>
                    <div style={{ width: "100%" }}>
                      <Typography
                        style={{ marginBottom: "2px" }}
                        variant="body2"
                      >
                        Password
                      </Typography>
                      <TextValidator
                        disabled={isDisabled}
                        fullWidth
                        autoComplete="new-password"
                        size="small"
                        variant="outlined"
                        type={this.state.showPassword ? "text" : "password"}
                        value={api?.source?.password || ""}
                        onChange={(e) =>
                          this.handleOnchange({
                            ...api,
                            source: {
                              ...api.source,
                              password: e.target.value,
                            },
                          })
                        }
                        name="password"
                        onFocus={this.setDuplicateNull}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                size="small"
                                // style={{ width: "40px", height: "40px" }}
                                aria-label="toggle password visibility"
                                onClick={this.handleClickShowPassword}
                                onMouseDown={this.handleMouseDownPassword}
                              >
                                {this.state.showPassword ? (
                                  <VisibilityOutlinedIcon />
                                ) : (
                                  <VisibilityOffOutlinedIcon />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </Grid>
                )}
                {api.apiType !== "MessageBroker" ? (
                  <>
                    <Grid item md={3}>
                      <div style={{ width: "100%" }}>
                        <Typography
                          style={{ marginBottom: "2px" }}
                          variant="body2"
                        >
                          Method <span style={{ color: "red" }}>*</span>
                        </Typography>
                        <FormControl
                          error={error}
                          size="small"
                          variant="outlined"
                          fullWidth
                        >
                          <SelectValidator
                            disabled={isDisabled}
                            fullWidth
                            variant="outlined"
                            size="small"
                            SelectProps={{
                              native: true,
                            }}
                            value={api.method || ""}
                            inputProps={{
                              name: "applicationMethod",
                              id: "applicationMethod",
                            }}
                            onFocus={this.setDuplicateNull}
                            onChange={(e) => {
                              const { source, ...removedSource } = api;
                              this.handleOnchange({
                                ...removedSource,
                                method: e.target.value,
                              });
                            }}
                            validators={["required"]}
                            errorMessages={["Please select Method "]}
                            error={error}
                          >
                            <option value=""> - select -</option>
                            <option value="GET">GET</option>
                            <option value="POST">POST</option>
                            <option value="PUT">PUT</option>
                            <option value="PATCH">PATCH</option>
                            <option value="DELETE">DELETE</option>
                          </SelectValidator>
                          {error ? (
                            <FormHelperText>
                              {error
                                ? "Duplicate api method is not allowed"
                                : null}
                            </FormHelperText>
                          ) : null}
                        </FormControl>
                      </div>
                    </Grid>
                  </>
                ) : null}
                <Grid item md={3}>
                  {api.apiType !== "MessageBroker" ? (
                    <div style={{ width: "100%" }}>
                      <Typography
                        style={{ marginBottom: "2px" }}
                        variant="body2"
                      >
                        URL <span style={{ color: "red" }}>*</span>
                      </Typography>
                      <TextValidator
                        disabled={isDisabled}
                        fullWidth
                        autoComplete="off"
                        size="small"
                        variant="outlined"
                        id="url"
                        value={api.url || ""}
                        name="applicationApiUrl"
                        error={error}
                        onFocus={this.setDuplicateNull}
                        validators={["required"]}
                        errorMessages={["Please enter api url"]}
                        helperText={
                          error ? "Duplicate api url is not allowed" : null
                        }
                        onChange={(e) =>
                          this.handleOnchange({
                            ...api,
                            url: e.target.value.trimStart(),
                          })
                        }
                      />
                    </div>
                  ) : (
                    <div style={{ width: "100%" }}>
                      <Typography
                        style={{ marginBottom: "2px" }}
                        variant="body2"
                      >
                        Topic <span style={{ color: "red" }}>*</span>
                      </Typography>
                      <TextValidator
                        disabled={isDisabled}
                        fullWidth
                        autoComplete="off"
                        size="small"
                        variant="outlined"
                        id="Topic"
                        value={api?.source?.topic || ""}
                        name="applicationApiTopic"
                        onChange={(e) =>
                          this.handleOnchange({
                            ...api,
                            source: {
                              ...api.source,
                              topic: e.target.value.trimStart(),
                            },
                          })
                        }
                        validators={["required"]}
                        errorMessages={["Please enter topic"]}
                      />
                    </div>
                  )}
                </Grid>
                <Grid item md={3}>
                  {api.apiType === "MessageBroker" && (
                    <div style={{ width: "100%" }}>
                      <Typography
                        style={{ marginBottom: "2px" }}
                        variant="body2"
                      >
                        Method <span style={{ color: "red" }}>*</span>
                      </Typography>
                      <FormControl
                        error={error}
                        size="small"
                        variant="outlined"
                        fullWidth
                      >
                        <SelectValidator
                          disabled={isDisabled}
                          fullWidth
                          variant="outlined"
                          size="small"
                          SelectProps={{
                            native: true,
                          }}
                          value={api.method}
                          inputProps={{
                            name: "applicationMethod",
                            id: "applicationMethod",
                          }}
                          onFocus={this.setDuplicateNull}
                          onChange={(e) =>
                            this.handleOnchange({
                              ...api,
                              method: e.target.value,
                            })
                          }
                          validators={["required"]}
                          errorMessages={["Please select Method"]}
                          error={error}
                        >
                          <option value=""> - select -</option>
                          <option value="publish">Publish</option>
                          <option value="subscribe">Subscribe</option>
                        </SelectValidator>
                        {/* {error ? (
                        <FormHelperText>
                          {error ? "Duplicate api method is not allowed" : null}
                        </FormHelperText>
                      ) : null} */}
                      </FormControl>
                    </div>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ValidatorForm>
        <br />
        {isIndustryTypeEnabled && (
          <Grid
            container
            spacing={0}
            style={
              isDisabled ? { pointerEvents: "none", opacity: "0.7" } : null
            }
          >
            <Grid
              container
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ minHeight: "32px", alignItems: "center" }}
            >
              <Grid item xs={12} sm={8} md={8} lg={8}>
                <Typography variant="h5"> Active Metadata Mapping </Typography>
              </Grid>
            </Grid>
            <Divider
              style={{ width: "100%", marginTop: "1%", marginBottom: "5px" }}
            />
            <br />
            <br />
            <Grid container>
              {api.apiType === "Https" && (
                <Grid item md={6}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    {api.method !== "GET" && (
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => {
                          this.navigateToDatafabric("cdutoapi");
                        }}
                        style={{ marginRight: 10, color: "white" }}
                      >
                        Input Mapping
                      </Button>
                    )}

                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => {
                        this.navigateToDatafabric("apitocdu");
                      }}
                      style={{ color: "white" }}
                    >
                      Output Mapping
                    </Button>
                  </div>
                </Grid>
              )}

              {api.method === "subscribe" && (
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => {
                      this.navigateToDatafabric("cdutoapi");
                    }}
                  >
                    Input Mapping
                  </Button>
                </Grid>
              )}
              {api.method === "publish" && (
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => {
                      this.navigateToDatafabric("apitocdu");
                    }}
                  >
                    Output Mapping
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
        <HelpdeskFAB />
      </div>
    );
  }
}
CreateAPI.propTypes = {
  classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => {
  return {
    applicationApi: state.application?.onBoard?.API || [],
    API_data: state.appDetailsRedcer.applicationDetails.applicationApi || [],
  };
};
const mapDispatchToProps = (dispatch) => ({
  add_api: (data) => dispatch(addAPI(data)),
  edit_api: (data) => dispatch(updateAPI(data)),
  getDetails: (id) => dispatch(getApplicationDetails(id)),
});

const CreateAPIForm = connect(mapStateToProps, mapDispatchToProps)(CreateAPI);

export default withStyles(Styles)(withSnackbar(CreateAPIForm));
