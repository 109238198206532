import React from "react";

import {
  Button,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";

import PieChart, {
  Series,
  Label,
  Connector,
  Size,
  Export,
  Title,
  Font,
} from "devextreme-react/pie-chart";
import { RiArrowLeftFill } from "react-icons/ri";

class DrillDownPieGraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstLevel: true,
      module_data: [],
      name: "",
    };
    this.pointClickHandler = this.pointClickHandler.bind(this);
    this.legendClickHandler = this.legendClickHandler.bind(this);
  }

  render() {
    const { appUsageData } = this.props;
    const { firstLevel, module_data, name } = this.state;
    const { allApps } = appUsageData;
    console.log("app usage data", allApps, appUsageData, 345678);
    const length = allApps.length !== 0;
    return (
      <Grid container justifyContent="center">
        <Grid
          item
          md={12}
          container
          justifyContent="flex-start"
          style={{ minHeight: "35px" }}
        >
          {!firstLevel && (
            <div>
              <Tooltip title="Back">
                <IconButton size="small" onClick={this.onButtonClick}>
                  <RiArrowLeftFill
                    style={{ fontSize: "24px", color: "black" }}
                  />
                </IconButton>
              </Tooltip>
            </div>
          )}
        </Grid>
        <Grid item md={8} container justifyContent="center">
          {firstLevel ? (
            length ? (
              <div>
                <PieChart
                  export={false}
                  id="pie"
                  dataSource={allApps}
                  palette="Bright"
                  onPointClick={this.pointClickHandler}
                  onLegendClick={this.legendClickHandler}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <Title text="Applications">
                    <Font color="black" size={16} />
                  </Title>
                  <Series argumentField="name" valueField="area">
                    <Label visible={true}>
                      <Connector visible={true} width={1} />
                    </Label>
                  </Series>
                  <Size width={500} />
                  <Export enabled={true} />
                </PieChart>
              </div>
            ) : (
              <Typography>{"Loading..."}</Typography>
            )
          ) : (
            <PieChart
              export={false}
              id="pie"
              dataSource={module_data}
              palette="Bright"
              // onPointClick={this.pointClickHandler}
              // onLegendClick={this.legendClickHandler}
            >
              <Title text={`Module wise for ${name}`}>
                <Font color="black" size={16} />
              </Title>
              <Series argumentField="name" valueField="area">
                <Label visible={true}>
                  <Connector visible={true} width={1} />
                </Label>
              </Series>

              <Size width={500} />
              <Export enabled={true} />
            </PieChart>
          )}
        </Grid>
      </Grid>
    );
  }

  onButtonClick = () => {
    this.setState({
      firstLevel: true,
    });
  };

  pointClickHandler(e) {
    const { appId, name } = e.target.data;
    const { appUsageData } = this.props;
    const { moduleByApp } = appUsageData;
    this.setState({
      firstLevel: false,
      module_data: moduleByApp[appId] || [],
      name: name,
    });
    // this.toggleVisibility(e.target);
  }

  legendClickHandler(e) {
    const arg = e.target;
    const item = e.component.getAllSeries()[0].getPointsByArg(arg)[0];
    this.toggleVisibility(item);
  }

  toggleVisibility(item) {
    item.isVisible() ? item.hide() : item.show();
  }
}

export default DrillDownPieGraph;
