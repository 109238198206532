import React, { Component } from "react";
import Breadcrumb from "components/Breadcrumbs/Breadcrumb";
import SearchIcon from "@material-ui/icons/Search";
import FilterComponent from "components/FilterComponent/FilterComponent";
import { Link } from "react-router-dom";
import {
  Typography,
  Grid,
  Button,
  InputAdornment,
  Tooltip,
  IconButton,
} from "@material-ui/core/";
import AddIcon from "@material-ui/icons/Add";
import actions from "./redux/action";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";
import { RiEditLine, RiDeleteBin5Line } from "react-icons/ri";
import { withSnackbar } from "notistack";
import { CustomTextField } from "components/Customized";
import DexTable from "components/DexTable/DexTable";
// import UserManagementAPIs from "api/UserManagementAPIs/index";
import UserManagementAPIs from "api/UserManagementAPIs/index";
import BlockIcon from "@material-ui/icons/Block";
import { Pagination } from "@material-ui/lab";
import ModalBlockComponent from "./components/ModalBlockComponent";
import dataOptions from "./filterOptions.json";
import ProjectLogo from "../../assets/images/project_list.svg";
import { projectsAssigned, storeSearchedValue } from "./redux/action";
import HelpersFunc from "helpers/helpers";
import { checkPermission } from "helpers/permissons";
import HelpdeskFAB from "../../views/HelpdeskFAB/HelpdeskFab";
import { ValueErrorBar } from "devextreme-react/polar-chart";
import action from "views/BillingMaster/redux/action";

const findAndExecuteAjax = (type) => {
  switch (type) {
    case "User Name":
      return (operator, value) => {
        if (operator === "Contains of") {
          return { name: { $regex: value, $options: "i" } };
        }
        if (operator === "Starts With") {
          return { name: { $regex: `^${value}`, $options: "i" } };
        }
        if (operator === "Ends With") {
          return { name: { $regex: `${value}$`, $options: "i" } };
        }
        if (operator === "Equals To") {
          return { name: { $regex: `^${value}$`, $options: "i" } };
        }
      };

    case "Email":
      return (operator, value) => {
        if (operator === "Contains of") {
          return { email: { $regex: value, $options: "i" } };
        }
        if (operator === "Starts With") {
          return { email: { $regex: `^${value}`, $options: "i" } };
        }
        if (operator === "Ends With") {
          return { email: { $regex: `${value}$`, $options: "i" } };
        }
        if (operator === "Equals To") {
          return { email: { $regex: `^${value}$`, $options: "i" } };
        }
      };
    case "Contact Number":
      return (operator, value) => {
        if (operator === "Contains of") {
          return { contactNo: { $regex: value, $options: "i" } };
        }
        if (operator === "Starts With") {
          return { contactNo: { $regex: `^${value}`, $options: "i" } };
        }
        if (operator === "Ends With") {
          return { contactNo: { $regex: `${value}$`, $options: "i" } };
        }
        if (operator === "Equals To") {
          return { contactNo: { $regex: `^${value}$`, $options: "i" } };
        }
      };

    case "Created On":
      return (operator, value) => {
        var timestamp = new Date(value.split(".").join("-")).getTime() / 1000;
        // if (operator === "Equals To") {
        //   return { createdon: { $eq: timestamp } };
        // }
        if (operator === "Equals To") {
          let gte = new Date(value.split(".").join("-")).getTime() / 1000;
          let lte = new Date(`${value}T23:59:59+05:30`).getTime() / 1000;
          return { createdon: { $gte: gte, $lte: lte } };
        }
        if (operator === "Greater than") {
          return { createdon: { $gt: timestamp } };
        }
        if (operator === "Lesser than") {
          return { createdon: { $lt: timestamp } };
        }
        if (operator === "Greater than or Equal To") {
          return { createdon: { $gte: timestamp } };
        }
        if (operator === "Less than or Equals To") {
          return { createdon: { $lte: timestamp } };
        }
      };

    default:
      return {};
  }
};

const Sytles = (theme) => ({
  footer: {
    position: "fixed",
    bottom: 0,
    left: 0,
    padding: 20,
    width: "100%",
    textAlign: "right",
    zIndex: 999,
    backgroundColor: "#f2f2f2",
  },
  customPagination: {
    width: "fit-content",
    marginLeft: "auto",
    "& .MuiPaginationItem-page.Mui-selected": {
      border: "0.5px solid #707070",
      margin: "0 10px",
      height: 30,
      width: 30,
      padding: "10px 13px",
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      boxShadow: "0px 3px 10px #5757571a",
      lineHeight: 1,
      borderRadius: 5,
      opacity: 1,
    },
    "& .MuiPaginationItem-page": {
      font: "normal normal normal 14px/19px Nunito Sans",
      letterSpacing: 0,
      color: "#000000",
      opacity: 0.6,
    },
  },
  block: {
    color: "red",
  },
  active: {},
});

class userManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      size: 10,
      filters: {},
      sort: { createdOn: -1 },
      sorting: [{ columnName: "createdon", direction: "desc" }],
      edit: false,
      searchkey: "",
      value: 0,
      openDropdown: false,
      setDropdown: false,
      setOpen: false,
      info: { status: "", name: "", userId: 0 },
      data_options: [...dataOptions],
      projectassignedModal: false,
      blockmodal: false,
      assigned: [],
      assignStatus: { appstatus: "", message: "" },
      delInfo: { name: "", delUserId: 0 },
      deleteUserModal: false,
    };
  }
  fetchData = (filters, arr, search) => {
    let page = 1;
    this.setState({
      page: 1,
    });
    let { filterProps, updateFilter } = this.props;
    updateFilter({ filter: filters, filterArray: arr });
    let { size } = this.state;
    this.props.getData(
      page,
      this.state.size,
      JSON.stringify(filters),
      JSON.stringify(filterProps.sort)
    );
    if (!search) {
      this.setState({
        searchkey: "",
      });
    }
  };

  async componentDidMount() {
    let { filterProps, searchedValue } = this.props;
    // this.setState({ searchkey: searchedValue });
    // this.fetchData(filterProps.filters, filterProps.filterArray, 0);
    this.props.getData(
      1,
      this.state.size,
      JSON.stringify(filterProps.filters),
      JSON.stringify(filterProps.sort)
    );
    this.props.getrolesData();
    let res = await UserManagementAPIs.fetchAllUserManagementRoles();
    console.log(res, "gfjfg", 122);
  }

  filterFunc = (arr) => {
    let filters = {};
    if (arr.length === 1) {
      const { type, operator, value } = arr[0];
      console.log(value);
      var category_id = this.props.data;
      console.log(category_id, operator, value, "catid");
      let filter = HelpersFunc.findAndExecuteAjax(type)(operator, value);
      console.log(filter, "sudheer filter usermanagement");
      this.getApplicationsByFilter({ ...filter }, arr);
    } else {
      arr.map((item, id) => {
        const { type, operator, value } = item;
        let filter = HelpersFunc.findAndExecuteAjax(type)(operator, value);
        filters = { ...filters, ...filter };
      });
      this.getApplicationsByFilter({ ...filters }, arr);
    }
  };

  getApplicationsByFilter = async (filters, arr) => {
    this.fetchData(filters, arr);
  };

  handleSortByChange = (sorting) => {
    let { filterProps, updateSorting, getData } = this.props;
    let filters = { ...filterProps.filters };
    let sort = {};
    sorting.forEach((f) => {
      sort[f.columnName] = f.direction == "asc" ? 1 : -1;
    });
    updateSorting({ sorting, sort });
    getData(
      this.state.page,
      this.state.size,
      JSON.stringify(filters),
      JSON.stringify(sort)
    );
  };
  searchAndPaginationHelperFunc = (page, val, filter_Props) => {
    this.props.getData(
      page,
      this.state.size,
      JSON.stringify({
        ...filter_Props.filters,
        name: { $regex: "^" + val, $options: "i" },
      }),
      JSON.stringify(filter_Props.sort)
    );
  };

  // searchAndPaginationHelperFunc = (page, val, filter_Props) => {
  //   // let { filterProps } = this.props;
  //   // let filtersCopy = { ...filterProps.filters };
  //   // let filters = {
  //   //   ...filtersCopy,
  //   //   name: { $regex: "^" + val, $options: "i" },
  //   // };
  //   // // this.fetchData(filters, filterProps.filterArray, true);

  //   this.props.getData(
  //     page,
  //     this.state.size,
  //     JSON.stringify({
  //       ...filter_Props.filters,
  //       name: { $regex: "^" + val, $options: "i" },
  //     }),
  //     JSON.stringify(filter_Props.sort)
  //   );
  // };
  onChangeSearchAppName = (event) => {
    let page = 1;
    this.setState({
      searchkey: event.target.value,
      page: page,
    });
    this.searchAndPaginationHelperFunc(
      page,
      event.target.value,
      this.props.filterProps
    );
  };

  handlePageChange = async (e, newPage) => {
    this.setState({
      page: newPage,
    });
    this.fetchingData(newPage);
  };

  fetchingData = async (Page) => {
    let { filterProps } = this.props;
    if (this.state.searchkey.trim().length !== 0) {
      this.searchAndPaginationHelperFunc(
        Page,
        this.state.searchkey,
        filterProps
      );
    } else {
      await this.props.getData(
        Page,
        this.state.size,
        JSON.stringify(filterProps.filters),
        JSON.stringify(filterProps.sort)
      );
    }
  };

  // handlePageChange = async (e, newPage) => {
  //   this.setState({
  //     page: newPage,
  //   });
  //   let { filterProps } = this.props;
  //   if (this.state.searchkey.trim().length !== 0) {
  //     //when search is enabled while user click pagination we need this function!
  //     this.searchAndPaginationHelperFunc(newPage, this.state.searchkey);
  //   } else {
  //     await this.props.getData(
  //       newPage,
  //       this.state.size,
  //       JSON.stringify(filterProps.filters),
  //       JSON.stringify(filterProps.sort)
  //     );
  //   }
  // };

  openDropDown = () => {
    this.setState({ openDropDown: true });
  };
  dropUp = () => {
    this.setState({ setDropdown: true });
  };

  editUserdata = (row, e) => {
    e && e.stopPropagation();

    let data = { ...row };
    this.props.history.push("/app/create-usermanagement", {
      userData: data,
      edit: true,
      ajax: true,
      disabled: false,
    });
  };

  removeUserdata = async (row, e) => {
    console.log("dfgdfghd", 667);
    e && e.stopPropagation();
    let res = await this.props.deleteUserManagement(
      this.state.delInfo.delUserId
    );
    if (res.data && res.data._status === 200) {
      this.props.enqueueSnackbar(res.data._msg || "User deleted successfully", {
        variant: "success",
        autoHideDuration: 2000,
      });
      this.setState({
        setOpen: false,
      });
      let { filterProps } = this.props;
      const rem = (this.props.totalCount - 1) % 10;
      console.log("dfgdfghd", rem, this.state.page - 1, this.props.totalCount);

      if (rem === 0 && this.state.page !== 1) {
        this.setState({
          page: this.state.page - 1,
        });
        console.log("dfgdfghd", this.state.page - 1);
        // await this.props.getData(
        //   this.state.page - 1,
        //   this.state.size,
        //   JSON.stringify(filterProps.filters),
        //   JSON.stringify(filterProps.sort)
        // );
        this.fetchingData(this.state.page);
      } else {
        // await this.props.getData(
        //   this.state.page,
        //   this.state.size,
        //   JSON.stringify(filterProps.filters),
        //   JSON.stringify(filterProps.sort)
        // );
        this.fetchingData(this.state.page);
      }
    } else {
      this.props.enqueueSnackbar(res.response.data._msg, {
        variant: "error",
        autoHideDuration: 2000,
      });
      this.setState({
        setOpen: false,
      });
    }
  };

  displayMsg = (type, msg) => {
    this.props.enqueueSnackbar(msg, {
      variant: type ? "success" : "error",
      autoHideDuration: 3000,
    });
  };
  blockUserdata = async () => {
    // let res = await this.props.blockUserManagement(row._id);
    // if (res.data) {￼￼￼​
    //   this.props.enqueueSnackbar("User details blocked", {
    //     variant: "success",
    //     autoHideDuration: 2000,
    //   });
    // } else {
    //   this.props.enqueueSnackbar("User details blocked", {
    //     variant: "error",
    //     autoHideDuration: 2000,
    //   });
    // }
    if (this.state.info.status === "active") {
      const { success, _msg } = await this.props.blockUserManagement(
        this.state.info.userId,
        "block"
      );
      // console.log("ressssssssssssssssssssssssss", res);
      this.displayMsg(success, _msg);
    } else {
      const { success, _msg } = await this.props.blockUserManagement(
        this.state.info.userId,
        "unblock"
      );
      this.displayMsg(success, _msg);
    }

    this.setState({
      setOpen: false,
    });

    this.fetchingData(this.state.page);
  };

  delModal = (row, e) => {
    e && e.stopPropagation();
    this.setState({
      delInfo: {
        name: row.name,
        delUserId: row._id,
      },
      setOpen: true,
      projectassignedModal: false,
      blockmodal: false,
      deleteUserModal: true,
    });
  };

  actionProvider = ({ row, e }) => {
    console.log(row, "row");
    let flag = row.status !== "active" ? true : false;
    const blockUnblock = (e) => {
      if (flag) {
        checkPermission("btn48") && openModal(e);
      } else {
        checkPermission("btn_48") && openModal(e);
      }
    };

    let openModal = (e) => {
      e && e.stopPropagation();
      this.setState({
        info: {
          name: row.name,
          userId: row._id,
          status: row.status,
        },
        setOpen: true,
        projectassignedModal: false,
        blockmodal: true,
        deleteUserModal: false,
      });
    };

    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <Tooltip title="Edit" aria-label="edit">
            <IconButton
              disabled={flag && !checkPermission("btn47") ? true : false}
              size="small"
              color="inherit"
              onClick={(e) =>
                checkPermission("btn47") && this.editUserdata(row, e)
              }
            >
              <RiEditLine fontSize="20px" />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title={flag ? "Unblock" : "Block"} aria-label="block">
            <IconButton
              size="small"
              color="inherit"
              disabled={
                flag ? !checkPermission("btn48") : !checkPermission("btn_48")
              }
              onClick={(e) => blockUnblock(e)}
            >
              <BlockIcon
                style={{ fontSize: "20px" }}
                className={
                  flag ? this.props.classes.block : this.props.classes.active
                }
              />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title="Delete" aria-label="delete">
            <IconButton
              disabled={flag && !checkPermission("btn49") ? true : false}
              size="small"
              color="inherit"
              // onClick={(e) => this.removeUserdata(row, e)}
              onClick={(e) => checkPermission("btn49") && this.delModal(row, e)}
            >
              <RiDeleteBin5Line fontSize="20px" />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    );
  };

  projectAssignedModal = async (row, e) => {
    e && e.stopPropagation();
    const { _msg, _status, data } = await projectsAssigned(row._id);
    let assigndata = [{ ...data }];
    this.setState({
      assigned: [...(data || [])],
      assignStatus: { appstatus: _status, message: _msg },
      projectassignedModal: true,
      blockmodal: false,
      deleteUserModal: false,
      setOpen: true,
    });
  };

  projectsProvider = ({ row }) => {
    return (
      <div className="ml-5">
        <span onClick={(e) => this.projectAssignedModal(row, e)}>
          <img src={ProjectLogo} style={{ width: "35px" }} alt="icon" />
        </span>
      </div>
    );
  };

  teamsProvider = ({ row }) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        {row.teams?.map((item, id) => (
          <>
            <span style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  height: "8px",
                  width: "8px",
                  backgroundColor: "rgb(163, 51, 200)",
                  borderRadius: "3px",
                }}
              ></div>
              <div style={{ padding: "0px 5px" }}>{item.name}</div>
            </span>
          </>
        ))}
      </div>
    );
  };

  handleClose = () => {
    this.setState({
      setOpen: false,
      setProject: false,
    });
  };

  createUser = () => {
    let data = {
      name: "",
      email: "",
      contactNo: "",
      roles: [],
    };
    // this.props.store_SearchedValue(this.state.searchkey);
    this.props.history.push("/app/create-usermanagement", {
      userData: data,
      edit: false,
      ajax: true,
    });
  };

  viewUserdata = (row) => {
    // this.props.store_SearchedValue(this.state.searchkey);
    let data = { ...row };
    row.status != "inactive" &&
      this.props.history.push("/app/create-usermanagement", {
        userData: data,
        edit: true,
        disabled: true,
      });
  };

  render() {
    let { data, classes, loading, totalCount, rolesData, filterProps, hidden } =
      this.props;
    let { data_options } = this.state;
    const { info, assigned, assignStatus, delInfo } = this.state;
    let type = info.status === "active" ? "block" : "unblock";
    return (
      <>
        <Grid container>
          <Grid item sm={12} md={12} xs={12} lg={12}>
            <Breadcrumb
              {...this.props}
              links={[
                { name: "Users" },
                {
                  name: "Users List ",
                },
              ]}
            />
          </Grid>
          <Grid item sm={7} md={7} lg={7} xs={12}>
            <Typography variant="h6" style={{ fontWeight: "bold" }}>
              Users {`( ${this.props.totalCount} )`}
            </Typography>
          </Grid>
          <Grid item sm={5} md={5} lg={5} xs={12}>
            <Grid container spacing={2}>
              <Grid item sm={5} md={5} lg={5} xs={12}>
                <CustomTextField
                  className=" mt-0 "
                  autoComplete="off"
                  fullWidth
                  id="filled-search"
                  type="text"
                  variant="outlined"
                  size="small"
                  placeholder="Search"
                  value={this.state.searchkey}
                  margin="normal"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {" "}
                        <SearchIcon />{" "}
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    this.onChangeSearchAppName(e);
                  }}
                />
              </Grid>
              <Grid item sm={3} md={3} lg={3} xs={12}>
                <FilterComponent
                  getDataByFilter={this.getApplicationsByFilter}
                  dataOptions={data_options}
                  filterFunc={this.filterFunc}
                  filterHistory={filterProps.filterArray}
                />
              </Grid>
              <Grid item sm={4} md={4} lg={4} xs={12}>
                <Button
                  style={{
                    color: "white",
                    width: "100%",
                    boxShadow: "none",
                    padding: "6px 11px 6px 11px",
                  }}
                  variant="contained"
                  color="primary"
                  size="small"
                  alignItems="center"
                  disabled={!checkPermission("btn45")}
                  onClick={() => checkPermission("btn45") && this.createUser()}
                  startIcon={<AddIcon />}
                >
                  Create Users
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12} md={12} xs={12} lg={12} className="mt-2">
            <DexTable
              data={[...data]}
              columnOrder={[
                "name",
                "email",
                "team",
                "projects",
                "contactNo",
                "createdon",
                "actions",
              ]}
              columns={[
                { name: "name", title: "User Name" },
                { name: "email", title: "Email" },
                { name: "team", title: "Teams" },
                { name: "projects", title: "Applications Assigned" },
                { name: "contactNo", title: "Contact No" },
                { name: "createdon", title: "CreatedOn" },
                { name: "actions", title: "Actions" },
              ]}
              columWidthsType="percentage"
              defaultColumnWidths={[
                //old
                // { columnName: "name", width: 15 },
                // { columnName: "email", width: 20 },
                // { columnName: "team", width: 18 },
                // { columnName: "projects", width: 12 },
                // { columnName: "createdon", width: 15 },
                // { columnName: "actions", width: 18 },
                { columnName: "name", width: 18 },
                { columnName: "email", width: 20 },
                { columnName: "team", width: 24 },
                { columnName: "projects", width: 18 },
                { columnName: "contactNo", width: 15 },
                { columnName: "createdon", width: 15 },
                { columnName: "actions", width: 18 },
              ]}
              formatters={[
                { formatterFn: this.actionProvider, columns: ["actions"] },
                { formatterFn: this.projectsProvider, columns: ["projects"] },
                { formatterFn: this.teamsProvider, columns: ["team"] },
              ]}
              EmailColumn={["email"]}
              sorting={filterProps.sorting}
              onSortingChange={this.handleSortByChange}
              dateColumns={["createdon"]}
              disableReordering={false}
              // isCustomHeight={false}
              hidden={[...hidden]}
              height="60vh"
              loading={loading}
              tableType="virtual"
              onClickRow={(row) => this.viewUserdata(row)}
              setHiddenColmn={(hidden) => {
                this.props.setHiddenColumn(hidden);
              }}
              Read={checkPermission("btn46")}
            />
            <HelpdeskFAB category="Users" />
          </Grid>
          {data.length !== 0 && (
            <div className={classes.footer}>
              <div className={classes.customPagination}>
                <Pagination
                  count={Math.ceil(totalCount / 10)}
                  page={this.state.page}
                  onChange={this.handlePageChange}
                  shape="rounded"
                  showFirstButton
                  showLastButton
                />
              </div>
            </div>
          )}
        </Grid>
        <ModalBlockComponent
          assigned={this.state.assigned}
          assignStatus={this.state.assignStatus}
          blockmodal={this.state.blockmodal}
          projectassignedModal={this.state.projectassignedModal}
          deleteUserModal={this.state.deleteUserModal}
          color="white"
          open={this.state.setOpen}
          yes={this.state.deleteUserModal ? "Yes, Delete" : `Yes, ${type} it`}
          handleClose={this.handleClose}
          action={
            this.state.deleteUserModal
              ? this.removeUserdata
              : this.blockUserdata
          }
          text={
            this.state.deleteUserModal
              ? `Are you sure you want to delete  ${delInfo.name} ?`
              : `Are you sure you want to ${type} ${info.name} ?`
          }
          Icon={
            this.state.deleteUserModal ? (
              <RiDeleteBin5Line
                style={{ height: 75, width: 75, marginBottom: 10 }}
              />
            ) : (
              <BlockIcon style={{ fontSize: "50px", marginBottom: "15px" }} />
            )
            // <BlockIcon style={{ fontSize: "50px", marginBottom: "15px" }} />
          }
        />
      </>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    data: state.userManagement.data,
    loading: state.userManagement.loading,
    totalCount: state.userManagement.totalCount,
    rolesData: state.userManagement.rolesData,
    filterProps: state.userManagement.filterProps,
    searchedValue: state.userManagement.searchedValue,
    hidden: state.userManagement.hidden,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getData: (pageno, size, filters, sort) =>
      dispatch(
        actions.actionfetchAllUserManagement(pageno, size, filters, sort)
      ),
    getrolesData: () => dispatch(actions.fetchAllUserManagementRoles()),
    deleteUserManagement: (usermanagementId) =>
      dispatch(actions.deleteUserManagement(usermanagementId)),
    blockUserManagement: (userManagementId, type) =>
      dispatch(actions.blockUserManagement(userManagementId, type)),
    updateFilter: (filter) => dispatch(actions.updateFilterData(filter)),
    updateSorting: (sortProps) => dispatch(actions.updateSort(sortProps)),
    setHiddenColumn: (hidden) => dispatch(actions.updateHiddenTableCol(hidden)),

    //  store_SearchedValue: (value) => dispatch(actions.storeSearchedValue(value)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(Sytles)(withSnackbar(userManagement)));
