import * as types from "../redux/actionTypes";

const appearReducer = (state = { appear: false }, action) => {
  switch (action.type) {
    case types.USER_VISIBLE_REQUEST:
      return { appear: action.payload };
    case types.USER_VISIBLE_RESET:
      return {  };
    default:
      return state;
  }
};

export default appearReducer;
