const styles = (theme) => ({
  paper: {
    border: "1px solid #E2E2E3",
    borderRadius: "unset",
    boxShadow: "unset",
  },
  myDevTable: {
    "& .MuiToolbar-root": {
      position: "absolute",
      zIndex: 501,
      right: "10px",
      borderBottom: 0,
      marginTop: "-10px",
    },
    "& thead": {
      backgroundColor: theme.palette.primary.main,
      border: "1px solid #E2E2E3",

      "& tr th": {
        color: "#FFFFFF",
        textTransform: "capitalize",
        fontWeight: "500",
        padding: "11px 20px 11px 30px !important",
        fontSize: "14px",

        "& span": {
          font: "normal normal 500 14px/22px Nunito !important",
          color: "#FFFFFF",
        },

        "& svg": {
          color: "#FFFFFF !important",
        },

        "& > div:last-child div": {
          backgroundColor: "#FFFFFF !important",
        },

        "&:nth-last-child(2) .MuiTableSortLabel-root": {
          position: "relative",
          right: 0,
        },
      },
    },
    "& tbody": {
      "& tr td": {
        padding: "2px 20px 2px 30px !important",
        font: "normal normal normal 14px/19px Nunito",
        color: "#666666",
        height: 47,
        textTransform: "none",
        fontSize: "14px",
      },
      "& tr": {
        background: "#FFFFFF",
      },
      "& tr:hover": {
        background: "#F9FAFB 0% 0% no-repeat padding-box !important",
        cursor: "pointer",
      },
      "& tr:last-child td": {
        borderBottom: "unset",
      },
    },
    chooseIcons: {
      position: "relative",
      right: 0,
      top: 35,
      zIndex: 501,
      color: "#FFFFFF",
      background: theme.palette.primary.main,
      border: "solid 1px red",
      borderRadius: 0,
      padding: "0px",
    },
    // For Toolbar
    // "& div:first-child": {
    //   borderBottom: "unset",
    //   minHeight: 0,
    //   "& > button": {
    //     position: "relative",
    //     right: 0,
    //     top: 34,
    //     zIndex: 501,
    //     color: "#FFFFFF",
    //     background: theme.palette.primary.main,
    //     borderRadius: 0,
    //     padding: "0px",
    //   },
    // },

    // For pagination
    "& div div:last-child": {
      // "& div:first-child": {
      //     display: 'none'
      // },
      "& div:first-child span": {
        fontSize: 14,
        // display: 'none'
      },

      "& div:last-child span": {
        fontSize: 14,
      },

      "& div:last-child .MuiButton-text": {
        padding: "10px 10px",
        lineHeight: 1,
        margin: "0 10px",
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        boxShadow: "0px 3px 10px #5757571A",
        border: "0.5px solid #707070",
        borderRadius: 5,
      },

      // "& td span": {
      //     visibility: "visible",
      // },
    },
  },

  listScreenTable: {
    "& tbody tr:last-child td": {
      borderBottom: "1px solid #E2E2E3",
    },
  },

  actionIcons: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    position: "relative",
    // left: 35
    left: -10,
  },
  actionIcon: {
    padding: 8,
    margin: "0 5px",

    // "& span": {
    //     visibility: "hidden",
    // },
  },
  selectedRowsText: {
    fontSize: 14,
    position: "absolute",
    bottom: 25,
    left: 20,
  },
  loader: {
    position: "absolute",
    top: "calc(45% - 10px)",
    left: "calc(50% - 10px)",
  },
});

export default styles;
