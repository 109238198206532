export const handleFormValidation = (data, error) => {
  let errors = {
    name: "",
    contactNumber: "",
  };
  let formIsValid = true;

  for (var obj in error) {
    console.log(obj, "dataoiuytfghj");
    // if (data[obj].name?.length === 0) {
    //   errors[obj] = true;
    //   formIsValid = false;
    // }
    if (data.name?.length === 0) {
      errors.name = true;
      errors.contactNumber = false;
      console.log(errors, "lkjhgfdfghjkl;");
      formIsValid = false;
    }
    if (data.contactNumber?.length === 0) {
      errors.name = false;
      errors.contactNumber = true;
      formIsValid = false;
    }
    if (data.name?.length === 0 && data.contactNumber?.length === 0) {
      errors.name = true;
      errors.contactNumber = true;
      formIsValid = false;
    }
  }
  return { formIsValid, errors };
};
