import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  Divider,
  Button,
  AppBar,
  Tabs,
  Box,
  Tab,
} from "@material-ui/core";
import FileUpload from "../../components/FileUpload/FileUpload";
import PropTypes from "prop-types";
import Referencemappingtable from "./Referencemappingtable";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { TitleSharp } from "@material-ui/icons";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CDUMasterAPIs from "../../api/CduMaster/index";
import ApplicationAPIs from "../../api/ApplicationAPIs/index";
import { withSnackbar } from "notistack";
import { DropzoneArea } from "material-ui-dropzone";
import Breadcrumb from "components/Breadcrumbs/Breadcrumb";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Styles = (theme) => ({
  dropzoneStyle: {
    minHeight: "130px",
    width: "100%",
  },
  dropzoneParagraphClass: {
    fontSize: "15px",
    padding: "1%",
  },
});

const getValuesForGivenDataKey = (dataKeys, dataKey) => {
  console.log(dataKeys, dataKey);
  let filtered = dataKeys.filter((f) => {
    return f.dataKey == dataKey;
  });
  return (filtered[0] || {}).values || [];
};

class Valuemap extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      userUploadedJson: "",
      apiDetails: {},
      uploadefile: [],
      currentTab: 0,
      expanded: "",
      cduMapping_output: [],
      cduMapping_input: [],
      inputValues: [],
      outputValues: [],
      selectedCdu_name: "",
    };
  }

  toggleTabs = (e, tab) => {
    console.log(tab);
    this.setState({
      currentTab: tab,
      expanded: "",
    });
  };

  handleBack = () => {
    let appId = this.state.appId;
    let apiDetails = this.state.apiDetails;
    let cduMapping_input = this.state.cduMapping_input || [{}];
    let cduMapping_output = this.state.cduMapping_output || [{}];
    this.props.history.push("/app/map-cdu", {
      appId,
      apiDetails,
      cduMapping_input,
      cduMapping_output,
    });
  };

  handleChangeAccordion = (panel) => (event, expanded) => {
    // setExpanded(isExpanded ? panel : false);
    // if(this.state.selectedCdu_name)
    this.setState({ expanded: expanded ? panel : false });
  };

  handleSubmit = () => {
    this.props.history.push("/app/onboard-application");
  };

  handleCancel = () => {
    this.props.history.push("/app/detail", { appId: this.state.appId });
  };

  updateUploadedFiles = (files) => {
    this.setState({ ...this.state, uploadefile: files });
  };

  componentDidMount = async () => {
    const { getDetails, location } = this.props;
    if (location.state != undefined) {
      let appId = location.state.appId;
      let apiDetails = location.state.apiDetails;
      let appName = location.state.appName;
      let cduMapping_input = location.state.cduMapping_input || [{}];
      let cduMapping_output = location.state.cduMapping_output || [{}];
      await this.setState({
        appName,
        appId,
        apiDetails,
        cduMapping_input,
        cduMapping_output,
      });
      let inputDatakeys = this.state.cduMapping_input.filter((f) => f.valueMap);
      let outputDatakeys = this.state.cduMapping_output.filter(
        (f) => f.valueMap
      );
      let inputValues = await this.fetchCDUValues({
        dataKey: { $in: inputDatakeys.map((f) => f.cdu_name) },
      });
      let outputValues = await this.fetchCDUValues({
        dataKey: { $in: outputDatakeys.map((f) => f.cdu_name) },
      });
      let latestCduMap_input = [];
      cduMapping_input.forEach((f) => {
        let input_value_map = {};
        let values = getValuesForGivenDataKey(inputValues, f.cdu_name);
        values.forEach((f) => {
          input_value_map[f.value] = "";
        });
        // merge cdu values and new values
        let newValues = { ...input_value_map, ...(f.values || {}) };
        let latestCduMap = {
          cdu_name: f.cdu_name,
          field_name: f.field_name,
          values: newValues,
          valueMap: f.valueMap,
        };
        latestCduMap_input.push(latestCduMap);
      });
      let latestCduMap_output = [];
      console.log("ouputvalues", { outputValues });
      cduMapping_output.forEach((f) => {
        let output_value_map = {};
        let values = getValuesForGivenDataKey(outputValues, f.cdu_name);
        values.forEach((f) => {
          output_value_map[f.value] = "";
        });
        // merge cdu values and new values
        let newValues = { ...output_value_map, ...(f.values || {}) };
        let latestCduMap = {
          cdu_name: f.cdu_name,
          field_name: f.field_name,
          values: newValues,
          valueMap: f.valueMap,
        };
        latestCduMap_output.push(latestCduMap);
      });

      this.setState({
        inputValues,
        outputValues,
        cduMapping_output: latestCduMap_output,
        cduMapping_input: latestCduMap_input,
      });
    } else {
      this.props.history.push("/app/applications");
    }
  };

  onChange = (e) => {
    console.log(e.target.files[0]);
    let reader = new FileReader();
    reader.onload = (e) => {
      let base64Data = reader.result;
      const database64 = base64Data.split(";base64,")[1];
      let buff = new Buffer(database64, "base64");
      let base64ToStringNew = buff.toString("ascii");
      let jsonObj = JSON.parse(base64ToStringNew);
      this.setState({ userUploadedJson: jsonObj });
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  fetchCDUValues = async (filters) => {
    try {
      let res = await CDUMasterAPIs.fetchCDUValues(filters);
      return res;
    } catch (err) {
      console.error(err);
    }
  };

  getAccordionValues() {
    if (this.state.currentTab == 0) {
      let datakeys = this.state.cduMapping_input.filter((f) => f.valueMap);
      return datakeys;
    } else {
      let datakeys = this.state.cduMapping_output.filter((f) => f.valueMap);
      // this.getCDUmasterDetails({datakey:{"$in":datakeys.map(f=>f.cdu_name)}})
      return datakeys;
    }
  }

  getCduValueForSelected_name = () => {
    if (this.state.currentTab == 0) {
      let datakeys = this.state.cduMapping_input.filter((f) => {
        return f.cdu_name == this.state.expanded;
      });
      console.log({ datakeys });
      return (datakeys[0] || {}).values || {};
    } else {
      let datakeys = this.state.cduMapping_output.filter((f) => {
        return f.cdu_name == this.state.expanded;
      });
      console.log({ datakeys });
      return (datakeys[0] || {}).values || {};
    }
  };

  onChangeOutputValues = (cduvalue, appValue) => {
    let datakeys = this.state.cduMapping_output.filter((f) => {
      return f.cdu_name == this.state.expanded;
    });
    let values = (datakeys[0] || {}).values || {}; /// get the values of selected field
    let tem_obj = {};
    tem_obj[cduvalue] = appValue;
    let newValues = { ...values, ...tem_obj }; // merge te changes made for the selected field
    console.log({ newValues });
    let datakey = datakeys[0];
    datakey.values = newValues;
    let newDatakeys = [
      ...this.state.cduMapping_output.filter((f) => {
        return f.cdu_name != this.state.expanded;
      }),
      datakey,
    ];
    this.setState({ cduMapping_output: newDatakeys });
  };

  onChangeInputValues = (cduvalue, appValue) => {
    let datakeys = this.state.cduMapping_input.filter((f) => {
      return f.cdu_name == this.state.expanded;
    });
    let values = (datakeys[0] || {}).values || {}; /// get the values of selected field
    let tem_obj = {};
    tem_obj[cduvalue] = appValue;
    let newValues = { ...values, ...tem_obj }; // merge te changes made for the selected field
    console.log({ newValues });
    let datakey = datakeys[0];
    datakey.values = newValues;
    let newDatakeys = [
      ...this.state.cduMapping_input.filter((f) => {
        return f.cdu_name != this.state.expanded;
      }),
      datakey,
    ];
    this.setState({ cduMapping_input: newDatakeys });
  };

  onSaveCDUValues = async () => {
    let payload = {};
    payload.req = this.state.cduMapping_input;
    payload.res = this.state.cduMapping_output;
    const { getDetails } = this.props;
    try {
      let p = { ...payload };
      const res = await ApplicationAPIs.editApplicationAPI(
        this.state.appId,
        this.state.apiDetails._id,
        p
      );
      this.props.enqueueSnackbar(
        res.data._msg || "API details saved successfully",
        { variant: "success", autoHideDuration: 3000 }
      );
      this.props.history.push("/app/detail", { appId: this.state.appId });
    } catch (err) {
      this.props.enqueueSnackbar(err.message || "Something Went wrong", {
        variant: "error",
        autoHideDuration: 3000,
      });
      console.error(err);
    }
  };

  uploadValuesFiles = (files) => {
    if (this.state.currentTab == 0) {
      this.uploadInputValuesFiles(files);
    } else {
      this.uploadOutputValuesFiles(files);
    }
  };

  uploadInputValuesFiles = (files) => {
    if (files.length == 0) return;
    try {
      var reader = new FileReader();
      reader.onload = (e) => {
        console.log(reader.result);
        let data = reader.result;
        let parseData = data.split("\r\n").map((f) => f.split(","));
        let header = parseData.shift(1);
        let cduValues = {};
        parseData.forEach((f) => {
          cduValues[f[0]] = f[1];
        });
        let datakeyIndex = this.state.cduMapping_input.findIndex((f) => {
          return f.cdu_name == this.state.expanded;
        });
        // let datakey = {...datakeys[0],values:cduValues}
        let cduMapping_input = this.state.cduMapping_input;
        cduMapping_input[datakeyIndex] = {
          ...cduMapping_input[datakeyIndex],
          values: cduValues,
        };
        // let newDatakeys = [...this.state.cduMapping_input.filter(f=>{return f.cdu_name != this.state.expanded}),datakey]
        this.setState({ cduMapping_input: [...cduMapping_input] });
        // console.log(parseData,cduValues)
      };
      reader.readAsText(files[0]);
      this.setState({ ...this.state, inputFile: [...files] });
    } catch {}
  };
  uploadOutputValuesFiles = (files) => {
    if (files.length == 0) return;
    try {
      var reader = new FileReader();
      reader.onload = (e) => {
        console.log(reader.result);
        let data = reader.result;
        let parseData = data.split("\r\n").map((f) => f.split(","));
        let header = parseData.shift(1);
        let cduValues = {};
        parseData.forEach((f) => {
          cduValues[f[0]] = f[1];
        });
        let datakeyIndex = this.state.cduMapping_output.findIndex((f) => {
          return f.cdu_name == this.state.expanded;
        });
        // let datakey = {...datakeys[0],values:cduValues}
        let cduMapping_output = this.state.cduMapping_output;
        cduMapping_output[datakeyIndex] = {
          ...cduMapping_output[datakeyIndex],
          values: cduValues,
        };
        // let newDatakeys = [...this.state.cduMapping_output.filter(f=>{return f.cdu_name != this.state.expanded}),datakey]
        this.setState({ cduMapping_output: [...cduMapping_output] });
        // console.log(parseData,cduValues)
      };
      reader.readAsText(files[0]);
      this.setState({ ...this.state, inputFile: [...files] });
    } catch {}
  };

  removeValuesFiles = () => {};

  render() {
    const { props, state } = this;
    const { classes } = props;
    const { userUploadedJson } = state;
    console.log(userUploadedJson);
    return (
      <div style={{ padding: "10px 0px 0px 0px" }}>
        <Grid container spacing={1}>
          <Breadcrumb
            {...this.props}
            links={[
              { name: "Applications", url: "/app/applications" },
              {
                name: this.state.appName,
                url: "/app/detail",
                routeParams: { appId: this.state.appId },
              },
              {
                name: `${this.state.apiDetails.name} Field Mapping`,
                url: "/app/map-cdu",
                routeParams: this.props.location.state,
              },
              {
                name: `${this.state.apiDetails.name} Value Mapping`,
                url: "/app/valuemap",
                routeParams: this.props.location.state,
              },
            ]}
          />
          <Grid container item xs={12} justify="space-between">
            <div>
              <Typography variant="h6">
                <span style={{ fontWeight: "semibold" }}>
                  {this.state.apiDetails.name || "API"}{" "}
                </span>
                - Common Data Field mapping
              </Typography>
            </div>
            <div>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <Button
                    size="small"
                    variant="outlined"
                    style={{ padding: "5px 32px" }}
                    color="primary"
                    onClick={this.handleBack}
                  >
                    {" "}
                    Back{" "}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    style={{ padding: "5px 26px" }}
                    onClick={this.handleCancel}
                  >
                    {" "}
                    Cancel{" "}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    style={{ color: "white", padding: "5px 32px" }}
                    onClick={this.onSaveCDUValues}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Grid>

          <Grid item xs={12} style={{ marginTop: "15px" }}>
            <Grid container spacing={1}>
              <Grid container item xs={3} sm={3} md={3}>
                <Card style={{ width: "100%", minHeight: "70vh" }}>
                  <CardHeader
                    titleTypographyProps={{ variant: "body2" }}
                    title="Upload Json schema"
                  ></CardHeader>
                  <Divider style={{ margin: "1%" }} />
                  <CardContent>
                    <Grid container spacing={1}>
                      {this.getAccordionValues().map((f, index) => {
                        console.log(f, "sdfsdfsfdf");
                        return (
                          <Grid container item xs={12} sm={12} md={12}>
                            <Accordion
                              expanded={this.state.expanded === f.cdu_name}
                              onChange={this.handleChangeAccordion(f.cdu_name)}
                              style={{ maxWidth: "100%", width: "100%" }}
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                              >
                                <FormControlLabel
                                  aria-label={f.cdu_name}
                                  onClick={(event) => event.stopPropagation()}
                                  onFocus={(event) => event.stopPropagation()}
                                  onChange={(event) => {
                                    console.log(event);
                                    if (event.target.checked) {
                                      this.setState({ expanded: f.cdu_name });
                                    } else {
                                      this.setState({ expanded: "" });
                                    }
                                  }}
                                  control={
                                    <Checkbox
                                      checked={
                                        this.state.expanded == f.cdu_name
                                      }
                                    />
                                  }
                                  label={
                                    <span style={{ wordBreak: "break-word" }}>
                                      {f.cdu_name}
                                    </span>
                                  }
                                />
                              </AccordionSummary>
                              <AccordionDetails>
                                <DropzoneArea
                                  key={this.state.currentTab + f.cdu_name}
                                  acceptedFiles={[".csv"]}
                                  style={{ width: "100%" }}
                                  filesLimit={1}
                                  clearOnUnmount={true}
                                  // initialFiles ={this.state.outputFile}
                                  onChange={this.uploadValuesFiles}
                                  onDelete={this.removeValuesFiles}
                                  dropzoneParagraphClass={
                                    classes.dropzoneParagraphClass
                                  }
                                  showPreviewsInDropzone={true}
                                  useChipsForPreview
                                  showAlerts={false}
                                  dropzoneText="Upload Output CDU Mapping file"
                                  dropzoneClass={classes.dropzoneStyle}
                                />
                              </AccordionDetails>
                            </Accordion>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={9} sm={9} md={9}>
                <Card style={{ minHeight: "70vh" }}>
                  <CardHeader
                    style={{ paddingTop: "1%" }}
                    title={
                      <AppBar
                        position="static"
                        style={{
                          backgroundColor: "transparent",
                          borderBottom: "1px solid #e8e8e8",
                          boxShadow: "none",
                          color: "gray",
                        }}
                      >
                        <Tabs
                          value={this.state.currentTab}
                          onChange={this.toggleTabs}
                          aria-label="simple tabs example"
                        >
                          <Tab
                            style={{ textTransform: "capitalize" }}
                            label={
                              <Typography variant="subtitle1">
                                Input Payload
                              </Typography>
                            }
                            {...a11yProps(0)}
                          />
                          <Tab
                            style={{ textTransform: "capitalize" }}
                            label={
                              <Typography variant="subtitle1">
                                Output Payload
                              </Typography>
                            }
                            {...a11yProps(1)}
                          />
                        </Tabs>
                      </AppBar>
                    }
                  />
                  <CardContent style={{ padding: "0px" }}>
                    <TabPanel
                      value={this.state.currentTab}
                      index={0}
                      style={{ padding: "0px" }}
                    >
                      {this.state.expanded && this.state.expanded !== "" ? (
                        <Referencemappingtable
                          cdu_field={this.state.expanded}
                          cdu_values={this.getCduValueForSelected_name()}
                          onChange={this.onChangeInputValues}
                        />
                      ) : (
                        <p className="ml-3">Please select any field</p>
                      )}
                    </TabPanel>
                    <TabPanel value={this.state.currentTab} index={1}>
                      {this.state.expanded && this.state.expanded !== "" ? (
                        <Referencemappingtable
                          cdu_field={this.state.expanded}
                          cdu_values={this.getCduValueForSelected_name()}
                          onChange={this.onChangeOutputValues}
                        />
                      ) : (
                        <p className="ml-3">Please select any field</p>
                      )}
                    </TabPanel>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}
export default withStyles(Styles)(withSnackbar(Valuemap));
