import React from "react";
import {
  Typography,
  Grid,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
} from "@material-ui/core";
import withSection from "components/Section/Section";
import { TextValidator } from "react-material-ui-form-validator";

import { CustomNumberFeild } from "components/Customized/index";
const CustomerSetting = ({ customerSetting, onChangeValue, propsDisabled }) => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={3}>
          <Typography style={{ marginBottom: "2px" }} variant="body2">
            Billing Day of the Month
          </Typography>

          <CustomNumberFeild
            disabled={propsDisabled}
            variant="outlined"
            fullWidth
            size="small"
            type="number"
            value={customerSetting.billingDayOfMonth}
            onChange={(e) =>
              onChangeValue({
                ...customerSetting,
                billingDayOfMonth: e.target.value,
              })
            }
            name="billingDayOfTheMonth"
            validators={["required", "minNumber:1", "maxNumber:28"]}
            errorMessages={[
              "This field is required",
              "Invalid day",
              "Invalid Day",
            ]}
          />
        </Grid>
        <Grid item lg={3}>
          <Typography style={{ marginBottom: "2px" }} variant="body2">
            Billing Payment Due
          </Typography>
          <TextValidator
            disabled={propsDisabled}
            variant="outlined"
            value={customerSetting.billingDuePeriod}
            fullWidth
            size="small"
            onChange={(e) =>
              onChangeValue({
                ...customerSetting,
                billingDuePeriod: e.target.value,
              })
            }
            name="billingPaymentDue"
            validators={["required", "minNumber:1", "maxNumber:28"]}
            errorMessages={[
              "This field is required",
              "Invalid day",
              "Invalid Day",
            ]}
            // value={""}
          />
        </Grid>
        <Grid item lg={3}>
          <Typography style={{ marginBottom: "2px" }} variant="body2">
            Tax(%)
          </Typography>
          <TextValidator
            disabled={propsDisabled}
            variant="outlined"
            value={customerSetting.tax}
            fullWidth
            size="small"
            // onChange={(e) =>
            //   onChangeValue({
            //     ...customerSetting,
            //     tax:
            //       e.target.value.trim().length !== 0
            //         ? parseFloat(e.target.value)
            //         : 0,
            //   })
            // }
            onChange={(e) =>
              onChangeValue({
                ...customerSetting,
                tax: e.target.value,
              })
            }
            name="tax-percentage"
            validators={["required", "minNumber:0", "maxNumber:100"]}
            errorMessages={["This field is required", "Invalid", "Invalid"]}
            // value={""}
          />
        </Grid>
        {/* <Grid item md={3}>
          <FormControl style={{ marginBottom: "2px" }}>
            <Typography style={{ marginBottom: "2px" }} variant="body2">
              Send Invoice via Email
            </Typography>
            <RadioGroup
              style={{ display: "flex", flexDirection: "row" }}
              // onChange={(e) => {}}
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid> */}
      </Grid>
    </>
  );
};

export default withSection(CustomerSetting, "Settings");
