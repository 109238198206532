import * as actions from "./actionTypes";

const intialState = {
  data: [],
  loading: false,
  totalCount: 0,
  adddata: {},
  editdata: {},
};

export default (state = intialState, action) => {
  switch (action.type) {
    // case actions.FETCH_VALUEMAP_DATA:
    //   return { ...state, loading: action.loading };
    // case actions.FETCHING_VALUEMAP_DATA_SUCCESS:
    //   console.log(action.data, 234567890987654);
    //   return {
    //     ...state,
    //     loading: action.loading,
    //     data: action.data,
    //     totalCount: action.totalCount,
    //   };
    // case actions.FETCH_VALUEMAP_DATA_FAILED:
    //   return { ...state, loading: action.loading, error: action.error };

    case actions.ADD_VALUEMAP_DATA:
      return { ...state, loading: action.loading };
    case actions.ADD_VALUEMAP_DATA_SUCCESS:
      return { ...state, loading: action.loading, adddata: action.adddata };
    case actions.ADD_VALUEMAP_DATA_FAILURE:
      return { ...state, loading: action.loading, error: action.error };

    case actions.EDIT_VALUEMAP_DATA:
      return { ...state, loading: action.loading };
    case actions.EDIT_VALUEMAP_DATA_SUCCESS:
      return { ...state, loading: action.loading, editdata: action.editdata };
    case actions.EDIT_VALUEMAP_DATA_FAILURE:
      return { ...state, loading: action.loading, error: action.error };
    default:
      return state;
  }
};
