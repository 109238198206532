import axios from "axios";
import * as types from "./actionType";
import loginAPi from "../../../api/LoginApi";

export const logInfo = (data) => ({
  type: types.ADD_USER_LOG_INFO,
  payload: data,
});

export const generateQR_code = (id) => async (dispatch) => {
  dispatch({
    type: types.GENERATING_QR_CODE,
  });
  try {
    const res = await loginAPi.generateQR_codeApi(id);
    dispatch({
      type: types.GENERATED_QR_CODE,
      payload: res.data.data || null,
    });
  } catch (err) {
    console.log(err, "sudheerertre");
    dispatch({
      type: types.GENERATE_QR_CODE_FAILED,
    });
  }
};

export const action_verify2FACode = (id, code) => async (dispatch) => {
  dispatch({
    type: types.VERIFYING_2FA_CODE,
  });
  try {
    const res = await loginAPi.verify2FACode(id, code);
    const info = res.data.data || {};
    const accessToken = { accessToken: info?.accessToken || null };
    sessionStorage.setItem("token", JSON.stringify(accessToken));
    dispatch({
      type: types.VERIFY_2FA_CODE_SUCCESS,
      payload: res.data.data || null,
      msg: res.data._msg,
    });
  } catch (err) {
    let msg = err?.response?.data?._msg || "Network issue!";
    dispatch({
      type: types.VERIFY_2FA_FAIL,
      msg: msg,
    });
  }
};

export const resetProps = () => ({
  type: types.RESET_LOGIN_PROPS,
});
export const clearMsg = () => ({
  type: types.CLEAR_MSG,
});
export const action_signUp = (id) => async (dispatch) => {
  dispatch({
    type: types.SIGNUP,
  });
  try {
    const res = await loginAPi.signUp(id);
    dispatch({
      type: types.SIGNUP_SUCCESS,
      payload: res.data.data || null,
    });
  } catch (err) {
    console.log(err, "signuperr");
    dispatch({
      type: types.SIGNUP_FAIL,
    });
  }
};
