import React, { useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  TableHead,
  TableRow,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Checkbox,
  Typography,
  IconButton,
  Grid,
  Select,
  FormControl,
  Tooltip,
  capitalize,
} from "@material-ui/core/";
import { useEffect } from "react";
import WrappedDexTable from "components/DexTable/WrappedDexTable";

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function Outgoingcdumappingtable(props) {
  const { type, cduMapping } = props;

  console.log(props.data, "daaadardadat");
  return (
    <div>
      {/* <WrappedDexTable
        key={4}
        data={[...props.data]}
        columWidthsType="percentage"
        columnOrder={["cdu_name", "field_name", "actions"]}
        columns={[
          { name: "cdu_name", title: "CDU Field" },
          { name: "field_name", title: " Application Field" },
          { name: "actions", title: "Actions" },
        ]}
        defaultColumnWidths={[
          { columnName: "cdu_name", width: 37 },
          { columnName: "field_name", width: 47 },
          { columnName: "actions", width: 14 },
        ]}
        pagination={false}
        noDataMsg="No Data"
        tableRow={(p) => {
          return (
            <CustomTable
              {...p}
              {...props}
              // cduMapping_input={cduMapping_input}
              // setCduMapping_input={setCduMapping_input}
            />
          );
        }}
      /> */}
      <TableContainer className={props.classes.table}>
        <Table aria-label="simple table">
          <TableHead>
            <StyledTableRow>
              <TableCell>Active Metadata</TableCell>
              <TableCell align="left">Passive Metadata</TableCell>
              <TableCell align="left">Actions</TableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {props.data.map((row, index) => (
              <StyledTableRow key={index}>
                <TableCell align="left">{row.cdu_name}</TableCell>
                <TableCell align="left">
                  <div>
                    <TextField
                      style={{ backgroundColor: "white", width: "50%" }}
                      value={
                        (cduMapping[index] && cduMapping[index].field_name) ||
                        ""
                      }
                      onChange={(e) => {
                        props.handleOnChange(
                          index,
                          {
                            ...cduMapping[index],
                            field_name: e.target.value,
                            cdu_name: row.cdu_name,
                          },
                          type
                        );
                      }}
                      variant="outlined"
                      size="small"
                    />
                  </div>
                </TableCell>
                <TableCell align="left">
                  {" "}
                  <Checkbox
                    color="primary"
                    checked={cduMapping[index] && cduMapping[index].valueMap}
                    onChange={(e) => {
                      props.handleOnChange(
                        index,
                        { ...cduMapping[index], valueMap: e.target.checked },
                        type
                      );
                    }}
                  />
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
