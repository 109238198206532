import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TablePagination,
  Typography,
  Link,
  IconButton,
  Badge,
  TableFooter,
  TableContainer,
  Tooltip,
  Grid,
} from "@material-ui/core/";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import Chip from "@material-ui/core/Chip";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import StarIcon from "@material-ui/icons/Star";
import Avatar from "@material-ui/core/Avatar";
import moment from "moment";
import Pagination from "@material-ui/lab/Pagination";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,

    color: theme.palette.common.white,
  },
  body: {
    // height: "14vh",
    // width: 15,
    fontSize: 13,
    padding: 8,
    cursor: "pointer",
  },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
  root: {
    cursor: "pointer",
  },
}))(TableRow);
const useStyles = makeStyles((theme) => ({
  table: {
    backgroundColor: "white",
    // maxWidth: "96vw",
    width: "100%",
    // marginTop: 40,
  },
  pagination: {
    margin: "0px 20px 10px 0px",
  },
  chip: {
    margin: theme.spacing(0.5),
    borderRadius: "4px",
    color: "#2185D0",
    backgroundColor: "#F0F0F0",
    fontSize: "12px",
  },
  star: {
    display: "flex",
    marginRight: "0px 50px 0px 50px",
    color: "#E0B400",
  },
  customBadgeApproved: {
    backgroundColor: "#21BA45",
  },
  customBadgeDraft: {
    backgroundColor: "#FBBD08",
  },
  fontstyle: {
    fontSize: "14px",
    textTransform: "capitalize",
  },
  tableheadingfont: {
    fontSize: "1rem",

    textTransform: "capitalize",
  },
  tablecontainerresponsive: {
    width: "100%",
    borderRadius: "0.5px",
    paddingTop: "120px",

    [theme.breakpoints.only("xs")]: {
      paddingTop: "77%",
    },
    [theme.breakpoints.only("sm")]: {
      paddingTop: "17%",
    },
  },
}));

const GetTagComponent = (tags, classes) => {
  // tags length is more than 2, show tags + number of extra tags.
  let tag_components = tags
    .filter(function (d, i) {
      return i < 2;
    })
    .map(function (i) {
      return <Chip className={classes.chip} label={i} />;
    });
  if (tags.length > 2) {
    tag_components.push(<p> +{tags.length - 2}</p>);
  }
  return tag_components;
};

function Applicationtable(props) {
  const { data, totalCount, size, page, defaultStatus } = props;
  const classes = useStyles();
  const [order, setOrder] = React.useState("desc");
  const [sortBy, setSetSortBy] = React.useState("createdOn");

  const createSortHandler = (property) => (event) => {
    setOrder(order == "desc" ? "asc" : "desc");
    setSetSortBy(property);
    props.handleOrderChange(order);
    props.handleSortByChange({ createdOn: order == "desc" ? -1 : 1 });
    console.log("order", order);
  };

  const getStatusColor = (status) => {
    if (status == "Published") {
      return (
        <div>
          <Typography variant="body1" style={{ color: "#2185D0" }}>
            <Badge
              color="secondary"
              variant="dot"
              style={{ marginRight: "10px" }}
            />
            Published
          </Typography>
        </div>
      );
    } else {
      return (
        <Typography style={{ color: "#FBBD08" }}>
          <Badge
            classes={{ badge: classes.customBadgeDraft }}
            variant="dot"
            style={{ marginRight: "10px" }}
          />
          Draft
        </Typography>
      );
    }
  };
  console.log(data, "dfdfhgdf");
  return (
    <div>
      <TableContainer className={classes.tablecontainerresponsive}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell className={classes.tableheadingfont}>
                Application
              </StyledTableCell>
              <StyledTableCell
                className={classes.tableheadingfont}
                align="left"
              >
                Status
              </StyledTableCell>
              <StyledTableCell
                className={classes.tableheadingfont}
                align="left"
              >
                Category
              </StyledTableCell>
              <StyledTableCell
                className={classes.tableheadingfont}
                align="left"
              >
                <TableSortLabel
                  style={{ color: "white" }}
                  active={sortBy === "createdOn"}
                  direction={order}
                  onClick={createSortHandler("createdOn")}
                >
                  Created on
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell
                className={classes.tableheadingfont}
                align="left"
              >
                Tags
              </StyledTableCell>
              <StyledTableCell
                className={classes.tableheadingfont}
                align="left"
              ></StyledTableCell>
            </TableRow>
          </TableHead>

          {data.length > 0 ? (
            <TableBody>
              {data.map((item, index) => (
                <StyledTableRow
                  hover
                  key={index}
                  onClick={() => props.handleClick(item._id)}
                >
                  <StyledTableCell component="th" scope="row">
                    <div>
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <Grid item>
                          <Avatar
                            style={{
                              width: "50px",
                              height: "50px",
                            }}
                            alt="img"
                            src={`data:image/png;base64,${item.applicationIconBase64}`}
                          />
                          {/* <Avatar style={{ borderRadius: "10px" }}></Avatar> */}
                        </Grid>
                        <Grid item className="pl-3">
                          <Typography className={classes.fontstyle}>
                            {item.name}
                            <br />
                          </Typography>
                          <Link
                            style={{
                              fontSize: "12px",
                            }}
                          >
                            {item.link}
                          </Link>
                        </Grid>
                      </Grid>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    rowsPerPage
                    className={classes.fontstyle}
                  >
                    {getStatusColor(item.status)}
                  </StyledTableCell>
                  <StyledTableCell align="left" className={classes.fontstyle}>
                    {item.category[0].name || null}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Typography className={classes.fontstyle}>
                      {moment.unix(item.createdOn).format("DD MMM YYYY")}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <div
                      style={{
                        display: "flex",
                        direction: "row",
                        alignItems: "baseline",
                      }}
                    >
                      {GetTagComponent([...item.tags] || [], classes)}
                    </div>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Link onClick={() => props.handleClick(item._id)}>
                      View
                    </Link>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          ) : null}
        </Table>
        {data.length > 0 ? (
          <TablePagination
            component="div"
            count={totalCount}
            onPageChange={(event, newPage) =>
              props.handlePageChange(event, newPage)
            }
            onChangeRowsPerPage={(event) =>
              props.handleLimitChangePageRow(event)
            }
            page={page - 1}
            rowsPerPage={size}
            rowsPerPageOptions={[5, 10, 15, 20]}
          />
        ) : (
          // <Pagination
          //   count={totalCount}
          //   shape="rounded"
          //   onPageChange={(event, newPage) =>
          //     props.handlePageChange(event, newPage)
          //   }
          //   onChangeRowsPerPage={(event) =>
          //     props.handleLimitChangePageRow(event)
          //   }
          //   defaultPage={1}
          //   showFirstButton
          //   showLastButton
          // />
          <div
            className="d-flex justify-content-center p-3"
            style={{ width: "100%" }}
          >
            <Typography
              style={{ opacity: 0.5 }}
              align="center"
              variant="body1"
              color="primary"
            >
              {"No Data Found"}
            </Typography>
          </div>
        )}
      </TableContainer>
    </div>
  );
}

export default Applicationtable;
