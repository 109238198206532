import React, { Component } from "react";
import SunEditor, { buttonList } from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { Grid, Typography, Button } from "@material-ui/core";
import Breadcrumb from "components/Breadcrumbs/Breadcrumb";
import Styles from "./Styles";
import { withStyles } from "@material-ui/styles";
import Paper from "@material-ui/core/Paper";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import Brightness1RoundedIcon from "@mui/icons-material/Brightness1Rounded";
import TextField from "@material-ui/core/TextField";
import getURL from "../../api/config";
import API_helpers from "../../api/index";
import { withSnackbar } from "notistack";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { checkPermission } from "helpers/permissons";
import {
  align,
  font,
  fontColor,
  fontSize,
  formatBlock,
  hiliteColor,
  horizontalRule,
  lineHeight,
  list,
  paragraphStyle,
  table,
  template,
  textStyle,
  image,
  link,
} from "suneditor/src/plugins";
import pricingDetails from "../../components/suneditor/pricing_details_plugin";
import { FaChevronRight, FaChevronDown } from "react-icons/fa";
class CreateDocument extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slide: {},
      appName: "",
      open: false,
      createSection: false,
      sectionName: "",
      createDocumentName: false,
      htmlContent: "",
      sections: [],
      submitBtn: true,
      updateBtn: false,
      sectionId: "",
      selectedIndex: "",
      createSubSectionFlag: false,
      subSectionName: "",
      parentSectionId: "",
      collectionOfIds: [],
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleCreateSection = this.handleCreateSection.bind(this);
    this.handleSectioNameChange = this.handleSectioNameChange.bind(this);
    this.handleHtmlContentChange = this.handleHtmlContentChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleSectionClick = this.handleSectionClick.bind(this);
  }
  componentDidMount() {
    const { location } = this.props;
    const { appData } = location.state;
    // console.log(location, "345678dfghjhgfdfgh");
    this.setState({
      appName: appData?.name || "",
    });
    this.fetchSections();
  }

  async fetchSections() {
    const appId = this.props.match.params.id;
    let url = getURL("GET_SECTIONS")(appId);
    try {
      let res = await API_helpers.GET(url, {
        headers: { "Content-Type": "application/json" },
      });

      this.setState({
        sections: res.data.data,
      });

      let collectionOfIds = [];

      if (this.state.sections != null) {
        this.state.sections.map(function (item, index) {
          this.setState({
            [item._id]: false,
            ["dynamicSectionsName" + item._id]: item.name,
            ["dynamicSectionEdit" + item._id]: false,
          });
          collectionOfIds.push(item._id);
          if (item.sections != null) {
            let subSections = item.sections;
            subSections.map(function (value, key) {
              this.setState({
                [value._id]: false,
                ["dynamicSectionsName" + value._id]: value.name,
                ["dynamicSectionEdit" + value._id]: false,
              });
              collectionOfIds.push(value._id);
            }, this);
          }
        }, this);
        this.setState({
          collectionOfIds: collectionOfIds,
        });
      }
      //return (res.data || {}).data || [];
    } catch (err) {
      throw err;
    }
  }
  async handleSectionClick(id, e, index, subsection = false) {
    // close all sections first only if not subsection
    if (!subsection) {
      let sections = this.state.sections;
      for (let i = 0; i < sections.length; i++) {
        let sec_id = sections[i]._id;
        if (id !== sec_id) {
          this.setState({
            [sec_id]: false,
            createSubSectionFlag: false,
          });
        }
      }
    }

    if (subsection) {
      this.setState({
        createSubSectionFlag: false,
      });
    }

    const appId = this.props.match.params.id;
    let url = getURL("GET_SECTION_CONTENT")(appId, id);
    try {
      let res = await API_helpers.GET(url, {
        headers: { "Content-Type": "application/json" },
      });

      this.state.collectionOfIds.map((doc_id) => {
        this.setState({
          ["dynamicSectionEdit" + doc_id]: id !== doc_id ? false : true,
        });
      });

      this.setState({
        // [id]: !this.state[id],
        [id]: true,
        htmlContent: res.data.data.content,
        sectionId: id,
        submitBtn: false,
        updateBtn: true,
        createSection: false,
        selectedIndex: id,
        // ["dynamicSectionEdit" + id]: !this.state["dynamicSectionEdit" + id],
      });
      console.log("this.state:", this.state.collectionOfIds, id);
      //return (res.data || {}).data || [];
    } catch (err) {
      throw err;
    }
  }

  handleClick = (id, e, index_) => {
    this.setState({
      slide: {
        [index_]: !this.state.slide[index_],
      },
    });
    this.handleSectionClick(id, e, 0);
    // this.setState({
    //   [id]: !this.state[id],
    //   sectionId: id,
    //   submitBtn: false,
    //   updateBtn: true,
    //   createSection: false,
    //   selectedIndex: id,
    //   // ["dynamicSectionEdit" + id]: !this.state["dynamicSectionEdit" + id],
    //   htmlContent: "",
    // });
  };

  handleOnBlur = (id, e) => {
    // alert("blurred")
    // this.setState({
    //   [id]: !this.state[id],
    //   htmlContent: "",
    //   submitBtn: true,
    //   updateBtn: false
    // })
  };
  handleCreateSection = () => {
    this.setState({
      createSection: true,
      sectionId: "",
      htmlContent: "",
      submitBtn: true,
      updateBtn: false,
      slide: {},
    });
    this.state.collectionOfIds.map((doc_id) => {
      this.setState({
        ["dynamicSectionEdit" + doc_id]: false,
      });
    });
  };

  handleSectioNameChange = (event) => {
    this.setState({
      sectionName: event.target.value,
    });
  };

  handleDynoSectioNameChange = (id, event) => {
    this.setState({
      ["dynamicSectionsName" + id]: event.target.value,
    });
  };

  async handleSectionDelete(id, e) {
    const appId = this.props.match.params.id;
    let url = getURL("DELETE_SECTION")(appId, id);
    try {
      let res = await API_helpers.DELETE(url, {
        headers: { "Content-Type": "application/json" },
      });
      this.props.enqueueSnackbar("Section deleted", {
        variant: "success",
      });
      this.setState({
        slide: {},
        open: false,
        createSection: false,
        sectionName: "",
        createDocumentName: false,
        htmlContent: "",
        sections: [],
        submitBtn: true,
        updateBtn: false,
        sectionId: "",
        selectedIndex: "",
        createSubSectionFlag: false,
        subSectionName: "",
        parentSectionId: "",
      });
      this.fetchSections();
    } catch (err) {
      throw err;
    }
  }

  handleHtmlContentChange = (event) => {
    console.log("html content", event);
    this.setState({
      htmlContent: event,
    });
  };

  handleSectionEdit = (id, e) => {
    this.setState({
      ["dynamicSectionEdit" + id]: !this.state["dynamicSectionEdit" + id],
    });
  };

  async handleSubmit() {
    const appId = this.props.match.params.id;
    let url = getURL("ADD_DOCUMENT")(appId);
    let sectionName = "";
    if (this.state.parentSectionId !== "") {
      sectionName = this.state.subSectionName;
    } else {
      sectionName = this.state.sectionName;
    }
    if (sectionName.trim().length === 0) {
      this.props.enqueueSnackbar("Section name can not be empty", {
        variant: "error",
      });
      return false;
    }
    const content =
      this.state.htmlContent === "<p><br></p>" ? "" : this.state.htmlContent;
    try {
      let res = await API_helpers.POST(
        url,
        {
          name: sectionName,
          content: content,
          sectionId: this.state.parentSectionId,
          type: "intro",
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      const msg = res?.data?._msg || "Document updated";
      this.props.enqueueSnackbar(msg, {
        variant: "success",
      });
      this.setState({
        slide: {},
        open: false,
        createSection: false,
        sectionName: "",
        createDocumentName: false,
        htmlContent: "",
        sections: [],
        submitBtn: true,
        updateBtn: false,
        sectionId: "",
        selectedIndex: "",
        createSubSectionFlag: false,
        subSectionName: "",
        parentSectionId: "",
      });
      this.fetchSections();
      // window.location.reload();
      //return (res.data || {}).data || [];
    } catch (err) {
      const msg = err?.response?.data?._msg || "Connection error!";
      console.log("error msg", msg);
      this.props.enqueueSnackbar(msg, {
        variant: "error",
      });
    }
  }
  async handleUpdate() {
    const appId = this.props.match.params.id;
    let url = getURL("UPDATE_DOCUMENTATION")(appId, this.state.sectionId);
    let activeSectionId = this.state.sectionId;
    const payload =
      this.state.htmlContent === "<p><br></p>" ? "" : this.state.htmlContent;

    try {
      let res = await API_helpers.PUT(
        url,
        {
          name: this.state["dynamicSectionsName" + activeSectionId],
          content: payload,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      const msg = res?.data?._msg || "Document updated";
      this.props.enqueueSnackbar(msg, {
        variant: "success",
      });
      this.setState({
        slide: {},
        open: false,
        createSection: false,
        sectionName: "",
        createDocumentName: false,
        htmlContent: "",
        sections: [],
        submitBtn: true,
        updateBtn: false,
        sectionId: "",
        selectedIndex: "",
        createSubSectionFlag: false,
        subSectionName: "",
        parentSectionId: "",
      });
      this.fetchSections();
    } catch (err) {
      const msg = err?.response?.data?._msg || "Connection error!";
      console.log("error msg", msg);
      this.props.enqueueSnackbar(msg, {
        variant: "error",
        autoHideDuration: 1000,
      });
    }
  }

  subsection(classes, item, index) {
    let currentSection = item;
    let id = item._id;
    return (
      <>
        <Grid container>
          <Grid md={11}>
            <ListItem
              button
              onClick={(e) => this.handleClick(item._id, e, index)}
              className={classes.dropDownList}
            >
              <ListItemIcon className={classes.dropDownListIcon}>
                {!this.state.slide[index] ? (
                  <FaChevronRight className={classes.sideBarIcon} />
                ) : (
                  <FaChevronDown className={classes.sideBarIcon} />
                )}
              </ListItemIcon>
              {this.state["dynamicSectionEdit" + id] === true && (
                <TextField
                  // onBlur={() => {
                  //   this.setState({
                  //     ["dynamicSectionEdit" + id]: false,
                  //   });
                  // }}
                  variant="standard"
                  autoFocus="on"
                  value={this.state["dynamicSectionsName" + id]}
                  onChange={(e) => this.handleDynoSectioNameChange(id, e)}
                />
              )}
              {this.state["dynamicSectionEdit" + id] === false && (
                <ListItemText
                  primary={currentSection.name}
                  className={classes.paperLeftSideBar}
                />
              )}
              {/* {this.state[id] ? <ExpandLess /> : <ExpandMore />} */}
            </ListItem>
            {this.state.slide[index] ? (
              <Collapse in={this.state[id]} timeout="auto" unmountOnExit>
                <List component="div">
                  {currentSection.sections != null &&
                    currentSection.sections.map(function (value, key) {
                      let subId = value._id;
                      return (
                        <Grid container>
                          <Grid md={11}>
                            <ListItem
                              key={key}
                              selected={this.state.selectedIndex === subId}
                              button
                              onClick={(e) =>
                                this.handleSectionClick(value._id, e, key, true)
                              }
                              className={classes.innerNestedHeading}
                            >
                              {this.state["dynamicSectionEdit" + subId] ===
                                true && (
                                <TextField
                                  // onBlur={() => {
                                  //   this.setState({
                                  //     ["dynamicSectionEdit" + subId]: false,
                                  //   });
                                  // }}
                                  variant="standard"
                                  autoFocus="on"
                                  value={
                                    this.state["dynamicSectionsName" + subId]
                                  }
                                  onChange={(e) =>
                                    this.handleDynoSectioNameChange(subId, e)
                                  }
                                />
                              )}
                              {this.state["dynamicSectionEdit" + subId] ===
                                false && (
                                <>
                                  {/* <Brightness1RoundedIcon
                                    className={classes.sideBarIcon}
                                    style={{
                                      fontSize: "8px",
                                      color: "#757575",
                                    }}
                                  />{" "} */}
                                  <ListItemText primary={value.name} />
                                </>
                              )}
                            </ListItem>
                          </Grid>
                          <Grid md={1}>
                            <DeleteOutlinedIcon
                              onClick={(e) =>
                                this.handleSectionDelete(subId, e)
                              }
                              style={{
                                marginTop: "8px",
                                fontWeight: "800",
                                color: "red",
                                cursor: "pointer",
                              }}
                            />
                          </Grid>
                        </Grid>
                      );
                    }, this)}
                  {this.state.createSubSectionFlag === true && (
                    <ListItem className={classes.innerNestedHeading}>
                      <TextField
                        autoFocus
                        value={this.state.subSectionName}
                        onChange={this.handleSubSectionName}
                      />
                    </ListItem>
                  )}
                  {this.state.createSubSectionFlag === false && (
                    <ListItem
                      button
                      onClick={(e) => this.createSubSection(id, e)}
                      className={classes.innerNestedHeading}
                    >
                      <AddOutlinedIcon
                        role="button"
                        style={{
                          fontSize: "18px",
                          border: "2px solid #2185d0",
                          borderRadius: "50px",
                          color: "#2185d0",
                        }}
                      />
                      &nbsp;
                      <ListItemText primary="Sub section" />
                    </ListItem>
                  )}
                </List>
              </Collapse>
            ) : null}
          </Grid>
          <Grid md={1} style={{ paddingTop: "5px" }}>
            <DeleteOutlinedIcon
              onClick={(e) => this.handleSectionDelete(id, e)}
              style={{ fontWeight: "800", color: "red", cursor: "pointer" }}
            />
          </Grid>
        </Grid>
      </>
    );
  }

  createSubSection = (id, e) => {
    this.setState({
      submitBtn: true,
      updateBtn: false,
      createSubSectionFlag: !this.state.createSubSectionFlag,
      parentSectionId: id,
      htmlContent: "",
    });
  };

  handleSubSectionName = (e) => {
    this.setState({
      subSectionName: e.target.value,
    });
  };

  render() {
    const { ajax, appName } = this.state;
    const { classes } = this.props;
    console.log("hdgsjdsjhgsdah", this.state);
    return (
      <>
        <Grid item sm={12} md={12} xs={12} lg={12}>
          <Breadcrumb
            {...this.props}
            links={[
              { name: "Applications", url: "/app/applications" },
              {
                name: "Application Details",
                url: "/app/detail",
                routeParams: { appId: this.props.match.params.id },
              },
              {
                name: "Add Document",
              },
            ]}
          />
          {/* 
					<Breadcrumb
						{...this.props}
						links={[
							{ name: "Applications", url: "/app/applications" },
							{
								name: "Add Document",

							},
						]}
					/> */}
        </Grid>
        <Grid container spacing={0}>
          <Grid item sm={12} md={12} xs={12} lg={12}>
            <Grid
              container
              spacing={1}
              direction="row"
              alignItems="center"
              className={classes.root}
            >
              <Grid item md={9}>
                <div>
                  <Typography variant="h6" className={classes.title}>
                    {`${appName} Intro`}
                  </Typography>

                  {this.state.createDocumentName && (
                    <TextField label="Document name" variant="standard" />
                  )}
                </div>
              </Grid>
              <Grid item sm={3} container justifyContent="flex-end">
                <Grid item>
                  {this.state.submitBtn && (
                    <Button
                      size="small"
                      variant="contained"
                      type="button"
                      disabled={!checkPermission("btn130")}
                      onClick={this.handleSubmit}
                    >
                      {"Submit"}
                    </Button>
                  )}
                  {this.state.updateBtn && (
                    <Button
                      size="small"
                      variant="contained"
                      type="button"
                      onClick={this.handleUpdate}
                    >
                      {"Update"}
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Paper className={classes.paperBody}>
          <Grid container spacing={0}>
            <Grid item sm={3} md={3} xs={3} lg={3}>
              <Grid container style={{ borderBottom: "2px solid #eee" }}>
                <Grid item md={11}>
                  <Typography className={classes.paperLeftTitle}>
                    {" "}
                    Sections{" "}
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={1}
                  style={{ paddingTop: "12px", color: "#262626" }}
                >
                  <AddOutlinedIcon
                    role="button"
                    onClick={this.handleCreateSection}
                  />
                </Grid>
              </Grid>
              <Grid>
                {this.state.createSection && (
                  <ListItem>
                    <TextField
                      label="Section name"
                      variant="standard"
                      autoFocus="on"
                      value={this.state.sectionName}
                      onChange={this.handleSectioNameChange}
                    />
                  </ListItem>
                )}

                {this.state.sections &&
                  this.state.sections.map(function (item, index) {
                    let id = item._id;
                    console.log("what is item here: ", item);
                    return this.subsection(classes, item, index);
                  }, this)}
                {/* <ListItem button onClick={() => this.handleClick('001')} className={classes.dropDownList}>
                  <ListItemIcon className={classes.dropDownListIcon}>
                    <ArrowForwardIosIcon className={classes.sideBarIcon} />
                  </ListItemIcon>
                  <ListItemText primary="Wikipedia" className={classes.paperLeftSideBar} />
                  {this.state.open ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={this.state.open} timeout="auto" unmountOnExit>
                  <List component="div">
                    <ListItem button className={classes.innerNestedHeading}>
                      <ArrowForwardIosIcon className={classes.sideBarIcon} style={{ fontSize: "12px" }} />
                      &nbsp;<ListItemText primary="Testing1" />
                    </ListItem>

                    <ListItem button className={classes.innerNestedHeading} >
                      <AddOutlinedIcon role="button" style={{ fontSize: "18px", border: "2px solid #2185d0", borderRadius: "50px", color: "#2185d0" }} />
                      &nbsp;<ListItemText primary="Add sub section" />
                    </ListItem>
                  </List>
                </Collapse> */}
              </Grid>
            </Grid>
            <Grid item sm={9} md={9} xs={9} lg={9}>
              <SunEditor
                // value={this.state.htmlContent}
                onChange={this.handleHtmlContentChange}
                setOptions={{
                  minHeight: "63vh",
                  placeholder: "Please type here...",
                  plugins: [
                    align,
                    font,
                    fontColor,
                    fontSize,
                    formatBlock,
                    hiliteColor,
                    horizontalRule,
                    lineHeight,
                    list,
                    paragraphStyle,
                    table,
                    template,
                    textStyle,
                    image,
                    link,
                    pricingDetails,
                  ],
                  buttonList: [
                    ["undo", "redo"],
                    ["font", "fontSize", "formatBlock"],
                    ["paragraphStyle"],
                    [
                      "bold",
                      "underline",
                      "italic",
                      "strike",
                      "subscript",
                      "superscript",
                    ],
                    ["fontColor", "hiliteColor"],
                    ["removeFormat"],
                    "/", // Line break
                    ["outdent", "indent"],
                    ["align", "horizontalRule", "list", "lineHeight"],
                    ["table", "link", "image"],
                    ["pricingDetailsPlugin"],
                  ],
                  formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
                  font: [
                    "Arial",
                    "Calibri",
                    "Comic Sans",
                    "Courier",
                    "Garamond",
                    "Georgia",
                    "Impact",
                    "Lucida Console",
                    "Palatino Linotype",
                    "Segoe UI",
                    "Tahoma",
                    "Times New Roman",
                    "Trebuchet MS",
                  ],
                }}
                setContents={this.state.htmlContent}
              />
            </Grid>
          </Grid>
        </Paper>
      </>
    );
  }
}

export default withStyles(Styles)(withSnackbar(CreateDocument));
