export const FETCHING_DATASTANDARDS = "FETCHING_DATASTANDARDS";
export const FETCHING_DATASTANDARDS_SUCCESS = "FETCHING_DATASTANDARDS_SUCCESS";
export const FETCHING_DATASTANDARDS_FAILURE = "FETCHING_DATASTANDARDS_FAILURE";

export const BLOCK_DATASTANDARDS = "BLOCK_DATASTANDARDS";
export const BLOCK_DATASTANDARDS_SUCCESS = "BLOCK_DATASTANDARDS_SUCCESS";
export const BLOCK_DATASTANDARDS_FAILURE = "BLOCK_DATASTANDARDS_FAILURE";

export const UPDATE_DATASTANDARDS_FILTER_DATA =
  "UPDATE_DATASTANDARDS_FILTER_DATA";
export const CLEAR_DATASTANDARDS_FILTER_DATA =
  "CLEAR_DATASTANDARDS_FILTER_DATA";

export const UPDATE_DATASTANDARDS_SORT = "UPDATE_DATASTANDARDS_SORT";
export const UPDATE_DS_HIDDEN_COL = "UPDATE_DS_HIDDEN_COL";
