import { getMsgBrokerData } from "api/MonitoringApi/index";
import * as types from "./actionType";
export const messageBrokerData = (interval) => async (dispatch) => {
  dispatch({
    type: types.FETCHING_MONITORING_DATA,
  });
  try {
    const res = await getMsgBrokerData(interval);
    console.log("monitoring", res.data);

    const data = res.data?.data || [];
    let monitoringData = {};

    data.forEach((f) => {
      let obj = {
        destAddr: f.destAddr,
        srcAddr: f.srcAddr,
        destTopic: f.destTopic,
        srcTopic: f.srcTopic,
        destType: f.destType,
        srcType: f.srcType,
        messageLen: f.messageLen,
        source:
          f.srcType + ":" + f.srcAddr + "/" + f.srcTopic + "/" + f.srcName,
        destination:
          f.destType + ":" + f.destAddr + "/" + f.destTopic + "/" + f.destName,
        tps: f.tps,
      };
      monitoringData = {
        ...monitoringData,
        [f.contextId + f.txnType]: {
          ...obj,
        },
      };
    });
    dispatch({
      type: types.FETCHED_MONITORING_DATA,
      payload: monitoringData,
    });
  } catch (err) {
    console.log("cvcvcvcv", err);
    dispatch({
      type: types.FETCH_MONITORING_DATA_FAIL,
    });
  }
};

export const setUpNodeToolTip = (e, sankeyData) => (dispatch) => {
  const title = e.target.title;
  const x = e.event.originalEvent.originalEvent.x;
  const y = e.event.originalEvent.originalEvent.y;
  console.log(e, title, sankeyData);
  let toolTipInfo = {};
  Object.keys(sankeyData || {}).map((r) => {
    const {
      srcTopic,
      messageLen,
      srcAddr,
      tps,
      srcType,
      destAddr,
      destTopic,
      destType,
    } = sankeyData[r];
    if (sankeyData[r].source === title) {
      toolTipInfo = {
        topic: srcTopic,
        messageLen,
        addr: srcAddr,
        tps,
        type: srcType,
        node: "Source",
      };
    }
    if (sankeyData[r].destination === title) {
      toolTipInfo = {
        addr: destAddr,
        topic: destTopic,
        type: destType,
        node: "Destination",
        tps,
        messageLen,
      };
    }
    return null;
  });

  let payload = {
    enabled: true,
    style: {
      display: "block",
      position: "absolute",
      left: x > 1000 ? 1000 : x,
      top: y > 530 ? 530 : y,
    },
    toolTipInfo,
  };
  dispatch({
    type: types.SET_UP_TOOLTIP,
    payload,
  });
};

export const setUpLinkToolTip = (e, sankeyData) => (dispatch) => {
  let connection = e.target.connection;
  let toolTipInfo = {};
  Object.keys(sankeyData || {}).map((r) => {
    if (
      sankeyData[r].source === connection.source &&
      sankeyData[r].destination === connection.target
    ) {
      toolTipInfo = sankeyData[r];
    }
    return null;
  });
  const x = e.event.originalEvent.originalEvent.x;
  const y = e.event.originalEvent.originalEvent.y;
  let payload = {
    enabled: true,
    style: {
      display: "block",
      position: "absolute",
      left: x > 1000 ? 1000 : x,
      top: y > 530 ? 530 : y,
    },
    toolTipInfo,
  };
  dispatch({
    type: types.SET_UP_TOOLTIP,
    payload,
  });
};

export const closeToolTip = () => (dispatch) => {
  dispatch({
    type: types.CLOSE_TOOLTIP,
  });
};

export const terminateToolTip = () => (dispatch) => {
  dispatch({
    type: types.TERMINATE_MONITORING_COMPONENT,
  });
};
