const Styles = (theme) => ({
  title: {
    textTransform: "capitalize",
    fontWeight: "bold",
    justifyContent: "center",
  },
  divider: {
    marginTop: "12px",
    height: "2px",
    background: "rgb(175, 175, 175)",
  },
});
export default Styles;
