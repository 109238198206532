/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import User from "./Assets/profile.svg";
import { Pagination } from "@material-ui/lab";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import moment from "moment";
import Loader from "../../components/common/stuff/Loader";
import appApi from "api/ApplicationAPIs/index";
// const userProfile = new UserProfile();
// const apiService = new UiApplicationService();
import { makeStyles } from "@material-ui/core";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import APIs from "api/ApplicationAPIs";
import { Button } from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

const useStyles = makeStyles((theme) => ({
  notification: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    minHeight: 78,
    border: "1px solid #EEEEEE",
    width: "100%",
    cursor: "pointer",
    "& .profilepic": {
      display: "flex",
      alignItems: "center",
      marginLeft: 30,
    },
    "& .date": {
      fontSize: 14,
      color: "rgb(107, 119, 140)",
      fontWeight: 400,
    },
    "& .message": {
      fontWeight: "500",
      marginLeft: 30,
    },
    "& .markAsRead": {
      marginRight: 30,
      fontSize: 14,
      cursor: "pointer",
    },
  },
}));

// const client = new W3CWebSocket(
//   "ws://49.205.193.195:7100/api/v1.0.0/websocket"
// );

//const client = appApi.getWebSocket();

const Notifications = (props) => {
  console.log("proppppppppppppppppppppppppppppppppppppppppps", props);
  const userDetails = useSelector((state) => state.apearenceReducer);

  const { userInfo } = userDetails;
  const [notifications, setNotifications] = useState([]);

  const [loading, setLoading] = useState(false);
  const history = useHistory();
  //const styles = Styles();
  const { enqueueSnackbar } = useSnackbar();

  // const handleChangePage = (event, value) => {
  //   setCurrentPage(value);
  // };

  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalCount, setTotalCount] = React.useState(0);

  const handleChangePage = async (event, newPage) => {
    console.log(
      "100000000000000000000000000000000000000000000000000000000000",
      newPage
    );
    setPage(newPage);

    const resp = await appApi.getNotifications(userInfo._id, newPage);
    if (resp?.data?.data !== null) {
      setNotifications(resp?.data ? resp?.data : []);

      // dispatch(addUserNotification(resp?.data ? resp?.data : {}));
    }
  };

  const handleChangeRowsPerPage = (event) => {
    console.log("200000000000000000000000000000000000000000000000000000000000");
    setRowsPerPage(parseInt(event.target.value));
    fetchData();
    setPage(0);
  };

  console.log("notiiiiiiiiiiiiiiiiiiiiiiification", notifications);
  const dispatch = useDispatch();
  const fetchData = async (newPage) => {
    try {
      setLoading(true);
      const resp = await appApi.getNotifications(userInfo._id, page);
      // const resp = await appApi.getWebSocket();
      setTotalCount(resp?.data?._totalcount || 0);
      console.log("resp:", resp?.data);
      if (resp?.data?.data !== null) {
        setNotifications(resp?.data ? resp?.data : []);

        // dispatch(addUserNotification(resp?.data ? resp?.data : {}));
      }
      // enqueueSnackbar(resp?.data?._msg || "", {
      //   variant: "success",
      // });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log("error", err);
      console.log("error", err.response);
      enqueueSnackbar("No notifications found", {
        variant: "error",
      });
      //   dispatch(addUserNotification({}));
    }
  };
  const updateNotification = async (id, type) => {
    try {
      const res = await appApi.editNotification(id, {
        status: "seen",
      });
      enqueueSnackbar(res.data?._msg, { variant: "success" });

      if (!type && res.data.data) {
        history.push("/app/detail", {
          appId: res.data.data.meta.applicationID,
        });
      } else {
        fetchData();
      }
    } catch (err) {
      let { _msg } = err?.response?.data || { _msg: "Connection error!" };
      enqueueSnackbar(_msg, { variant: "error" });
    }
  };

  const classes = useStyles();
  useEffect(() => {
    fetchData();
  }, [userInfo._id]);

  const fetching_notifications = async () => {
    try {
    } catch (err) {
      console.log("erereregcng", err);
    }
  };

  // useEffect(() => {
  //   console.log("weeeeeeeeeeeeee", client);
  //   client.onopen = () => {
  //     console.log("websocket client connected2");
  //   };
  //   client.onmessage = (message) => {
  //     const socketMessage = message.data;
  //     console.log("websocket_msg bathnda", socketMessage);
  //   };
  //   client.onerror = () => {
  //     console.log("websocket errorrrrrrrrrrrr");
  //   };
  // }, []);

  //   const handleMarkedAsRead = async (id) => {
  //     console.log("id", id);
  //     const data = await userProfile.markAsRead(id, {
  //       status: "seen",
  //     });
  //     console.log("data", data);
  //     if (data?.status < 210) {
  //       fetchData();
  //     } else {
  //       enqueueSnackbar("Something went wrong", {
  //         variant: "error",
  //       });
  //     }
  //   };

  //   const handleNavigation = async (item) => {
  //     // Project
  //     if (item?.groupname?.split("_")[0] === "project") {
  //       handleMarkedAsRead(item._id);
  //       const { _status } = await fetchProjectById(
  //         item?.groupname?.split("_")[1]
  //       );
  //       if (_status < 210) {
  //         history.push(`/projects/details/${item?.groupname?.split("_")[1]}`);
  //       } else {
  //         enqueueSnackbar("Project doesn't exist", { variant: "error" });
  //       }
  //     }
  //     // Service
  //     else if (item?.groupname?.split("_")[0] === "service") {
  //       handleMarkedAsRead(item._id);
  //       const { _status } = await fetchServiceById(
  //         item?.groupname?.split("_")[1]
  //       );
  //       if (_status < 210) {
  //         history.push(`/services/details/${item?.groupname?.split("_")[1]}`);
  //       } else {
  //         enqueueSnackbar("Service doesn't exist", { variant: "error" });
  //       }
  //     }
  //     // Application
  //     else if (item?.groupname?.split("_")[0] === "application") {
  //       handleMarkedAsRead(item._id);
  //       const { _status } = await getApplicationDataByID(
  //         item?.groupname?.split("_")[1]
  //       );
  //       if (_status < 210) {
  //         history.push(`/application/details/${item?.groupname?.split("_")[1]}`);
  //       } else {
  //         enqueueSnackbar("Application doesn't exist", {
  //           variant: "error",
  //         });
  //       }
  //     }
  //     // UI Application
  //     else if (item?.groupname?.split("_")[0] === "uiApplication") {
  //       handleMarkedAsRead(item._id);
  //       const resp = await apiService.getApplicationById(
  //         item?.groupname?.split("_")[1]
  //       );
  //       if (resp?.status < 210) {
  //         history.push(`/ui-applications/edit/${item?.groupname?.split("_")[1]}`);
  //       } else {
  //         enqueueSnackbar("UI Application doesn't exist", {
  //           variant: "error",
  //         });
  //       }
  //     }
  //   };

  const DateCell = (date) => {
    let time = moment.unix(date).fromNow();
    return time;
  };
  console.log("notiiiiiiiiiiification", notifications);
  return (
    <>
      {loading && <Loader />}
      <div style={{ paddingBottom: 100, width: "100%" }}>
        {notifications?.data &&
          notifications?.data.length > 0 &&
          notifications?.data?.map((item) => {
            return (
              <div
                className={classes.notification}
                style={{
                  backgroundColor:
                    item?.status === "unseen" ? "#F5FAFF" : "#FFFFFF",
                  fontSize: "14px",
                }}
                onClick={() => {
                  item.status === "seen"
                    ? history.push("/app/detail", {
                        appId: item.meta.applicationID,
                      })
                    : updateNotification(item._id);
                }}
                // onClick={() => handleNavigation(item)}
              >
                <div className="profilepic">
                  <img src={User} alt="" height={32} width={32} />

                  <div style={{ marginLeft: "30px" }}>
                    <></>
                    {/* {item?.status == "unseen" ? (
                        <FiberManualRecordIcon
                          style={{
                            color: "orange",
                            fontSize: "medium",
                            marginRight: "5px",
                          }}
                        />
                      ) : (
                        <FiberManualRecordIcon
                          style={{
                            color: "blue",
                            fontSize: "medium",
                            marginRight: "5px",
                          }}
                        />
                      )} */}
                    <span className="message"> {item?.message}</span>
                    {item?.updatedby && <span> by {item?.updatedby}</span>}
                    <span className="date">
                      {"  "}
                      {DateCell(
                        item?.createdon ? item?.createdon : item?.createdon
                      )}
                    </span>
                  </div>
                </div>
                <div>
                  <span
                    className="markAsRead"
                    // onClick={() => {
                    //   // console.log("ulle", e);
                    // }}
                  >
                    {item?.status === "unseen" && (
                      <Button
                        variant="text"
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          updateNotification(item?._id, true);
                        }}
                      >
                        <b> Mark as Read</b>
                      </Button>
                    )}
                  </span>
                </div>

                {""}
                {/* <div>
                  <span
                    className="markAsRead"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleMarkedAsRead(item?._id);
                    }}
                  >
                    {item?.status === "unseen" && <b>Mark as Read</b>}
                  </span>
                </div> */}
              </div>
            );
          })}
        {notifications?.data?.length !== 0 && (
          <div
            style={{ float: "right", color: "gray", marginTop: "20px" }}
            className={classes.footer}
          >
            <div className={classes.customPagination}>
              <Pagination
                count={Math.ceil(totalCount / 10)}
                page={page}
                onChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                shape="rounded"
                showFirstButton
                showLastButton
              />
            </div>
          </div>
        )}
      </div>
      {notifications?.data?.length === 0 && (
        <div
          style={{
            display: "flex",
            // border: "5px solid red",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <h2>No Notifications found</h2>
        </div>
      )}
    </>
  );
};

export default Notifications;
