import React, { useState } from "react";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { IconButton, Backdrop, CircularProgress } from "@material-ui/core";
import { useHistory } from "react-router";
import { useSnackbar } from "notistack";
import { forgetPasswordFunc } from "./redux/action";
import { useDispatch, useSelector } from "react-redux";
//import { forgetPassword } from "../../utils/authentication/authenticationUtils";
import CustomButton from "../../components/common/Button/CustomButton";
import useStyles from "./commonStyles";
import helpers from "../../helpers/helpers";
import loginAPi from "../../api/LoginApi/index";

export default function ForgetPassword(props) {
  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [errorObj, setErrorObj] = useState({
    email: false,
  });
  const [loading, setLoading] = useState(false);
  const styles = useStyles();
  const history = useHistory();
  let dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleButtonClick = async () => {
    var errorFlag = false;
    const emailValid = helpers.validateEmail(email);
    if (email === "" || !emailValid) {
      errorFlag = true;
      setErrorObj({
        email: true,
      });
    }
    if (!errorFlag) {
      setLoading(true);
      const req_data = {
        email: email,
      };
      const { _msg, _status, data } = await loginAPi.forgetPassword(req_data);
      console.log(data, "234tyuioiu6y5433245");
      if (_status === 200) {
        setLoading(false);
        enqueueSnackbar(_msg, { variant: "success" });
        setEmailSent(!emailSent);
        setTimeout(() => {
          history.push({
            pathname: "/login/reset-password",
            state: {
              email: email,
              type: "reset-pass",
            },
          });
        }, 4000);
      } else {
        setLoading(false);
        enqueueSnackbar(_msg, { variant: "error" });
      }
      console.log(req_data, "234tyuioiu6y5433245");
    }
  };
  const handleTextChange = (value) => {
    setErrorObj(() => ({
      email: false,
    }));
    setEmail(value);
  };

  console.log(history, "3456789098765432");
  return (
    <div className={styles.container}>
      <Backdrop
        className={styles.backdrop}
        open={loading}
        onClick={() => setLoading(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className={styles.line1}>
        <IconButton
          onClick={() => history.push("/login")}
          className={styles.backBtn}
        >
          <ArrowBackIosIcon fontSize="small" />
          <text className={styles.label}>Back to Login</text>
        </IconButton>
      </div>
      <text className={styles.big_text} style={{ alignSelf: "center" }}>
        Reset your password
      </text>
      <text
        className={styles.label}
        style={{ textAlign: "center", marginTop: 15 }}
      >
        Enter the email address you use to sign in
      </text>
      <div className={styles.email}>
        <label className={styles.label}>
          Email ID<text className={styles.star}> *</text>
        </label>
        <input
          className={styles.password_text}
          style={errorObj.email ? { borderColor: "red" } : {}}
          value={email}
          onChange={(e) => handleTextChange(e.target.value)}
        ></input>
        {errorObj.email && (
          <text className={styles.error_text}>Invalid Email</text>
        )}
      </div>
      {!emailSent && (
        <div style={{ marginTop: 37, alignSelf: "center" }}>
          <CustomButton
            type="long"
            label="Get reset code"
            onClick={() => handleButtonClick()}
          />
        </div>
      )}
      {emailSent && (
        <div style={{ alignSelf: "center", marginTop: 37 }}>
          <div style={{ textAlign: "center" }}>
            <img src="/images/login/mail_icon.svg" alt="email" />
            <text
              className={styles.label}
              style={{
                fontSize: 21,
                marginLeft: 5,
                position: "relative",
                bottom: 4,
              }}
            >
              Email Sent
            </text>
          </div>
          <text
            className={styles.label}
            style={{ color: "#707070", marginTop: 8 }}
          >
            {` Reset password code has been sent to the email ' ${email} '`}
          </text>
        </div>
      )}
    </div>
  );
}
