import React, { Component } from "react";
import Styles from "./styles.js";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import { withSnackbar } from "notistack";
import pricingMasterAPIS from "api/PricingMasterAPIs/index";
import { Typography, Grid, Button, Divider, Card } from "@material-ui/core";
import Breadcrumb from "components/Breadcrumbs/Breadcrumb";
import { RiDownload2Line } from "react-icons/ri";
import { GoCreditCard } from "react-icons/go";
import { ImCreditCard } from "react-icons/im";
import Modal from "./component/Modal";
import InvoiceSummary from "./component/InvoiceSummary";
import {
  TextValidator,
  SelectValidator,
  ValidatorForm,
} from "react-material-ui-form-validator";
import BasicTabs from "./InvoiceTabs";
import Notes from "./Notes";
import actions from "./redux/action";
import PrintInvoice from "./component/PrintInvoice.jsx";
import HelpdeskFAB from "../../views/HelpdeskFAB/HelpdeskFab";
import CustomerInfo from "./CustomerInfo.jsx";

class Invoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      invoiceData: {},
      customerProfile: false,
    };
  }

  getNotes = async (id, CustId) => {
    await this.props.getInvoiceDetailsById(id, CustId);
    await this.props.getData(id);
    // await this.props.getAppModuleDetail();
  };

  async componentDidMount() {
    const { location } = this.props;
    if (location.state !== undefined) {
      let { Invoice_Data, routeFrom } = location.state;
      console.log("invdata", Invoice_Data, "invdata");

      this.setState({
        data: { ...Invoice_Data },
        invoiceData: {
          ...Invoice_Data,
        },
      });

      if (routeFrom === "customer-profile") {
        this.setState({
          customerProfile: true,
        });
      }
      this.getNotes(Invoice_Data.ID, Invoice_Data.CustomerId);
    } else {
      this.props.history.push("/app/invoice");
    }
  }

  // months = [
  //   "Jan",
  //   "Feb",
  //   "March",
  //   "April",
  //   "May",
  //   "June",
  //   "July",
  //   "August",
  //   "September",
  //   "October",
  //   "November",
  //   "December",
  // ];

  invoice = [
    {
      value: "Application Usage",
      price: "$7,142.84",
    },
    {
      value: "Tax",
      price: "$7,142.84",
    },
  ];

  render() {
    const {
      data,
      classes,
      loading,
      totalCount,
      InvoiceData,
      appUsageDetails,
      allAppDetailedChart,
      nonGrpData,
      appUsageData,
    } = this.props;
    const { customerProfile } = this.state;

    return (
      <>
        <ValidatorForm>
          <Grid container>
            <Grid item sm={12} md={12} xs={12} lg={12}>
              <Breadcrumb
                {...this.props}
                links={[
                  customerProfile
                    ? {
                        name: "Customer Management",
                        url: "/app/customer-management",
                      }
                    : { name: "Invoices", url: "/app/invoice" },
                  {
                    name: "Invoice Details",
                    url: "/app/view-invoice",
                  },
                ]}
              />
            </Grid>

            <Grid item sm={7} md={7} lg={7} xs={12}>
              <Grid container>
                <Grid item sm={2} md={2} lg={2} xs={12}>
                  <Typography variant="h6" style={{ fontWeight: "bold" }}>
                    Invoice Details
                  </Typography>
                </Grid>
                {/* <Grid item sm={3} md={3} lg={3} xs={12}>
                  <SelectValidator
                    variant="outlined"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    inputProps={{
                      name: "moduleName",
                    }}
                    validators={["required"]}
                    errorMessages={["Please select module name"]}
                  >
                    <option>- select -</option>
                    {this.months.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </SelectValidator>
                </Grid> */}
              </Grid>
            </Grid>
            <Grid item sm={5} md={5} lg={5} xs={12}>
              <Grid
                container
                spacing={2}
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Grid item sm={4} md={4} lg={4} xs={12}>
                  <PrintInvoice
                    invoiceDataID={this.state.invoiceData}
                    getNotes={this.getNotes}
                    {...this.props}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12}>
              <Divider className={classes.dividerline} />
            </Grid>
            <br />
            <Grid item md={12}>
              <CustomerInfo {...this.props} />
            </Grid>

            <Grid item md={12}>
              <InvoiceSummary invoice={this.invoice} {...this.props} />
            </Grid>
            <Grid item md={12}>
              <Notes
                isPrint={false}
                locationData={InvoiceData || []}
                invoiceDataID={this.state.invoiceData}
                getNotes={this.getNotes}
                {...this.props}
              />
            </Grid>
            <Grid item md={12}>
              {Object.keys(appUsageDetails).length !== 0 && (
                <BasicTabs {...this.props} />
              )}
            </Grid>

            <HelpdeskFAB category="Invoices" />
          </Grid>
        </ValidatorForm>
      </>
    );
  }
}

Invoice.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    data: state.invoiceDetails.data,
    loading: state.invoiceDetails.loading,
    totalCount: state.invoiceDetails.totalCount,
    getdata: state.invoiceDetails.getdata,
    InvoiceData: state.invoiceDetails.InvoiceData,
    appUsageDetails: state.invoiceDetails.appUsageDetails,
    appDataSet: state.invoiceDetails.appDataSet,
    allAppDetailedChart: state.invoiceDetails.allAppDetailedChart,
    nonGrpData: state.invoiceDetails.nonGrpData,
    appUsageData: state.invoiceDetails.appUsageData,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getData: (id) => dispatch(actions.actionfetchAllCreditDebitInvoices(id)),
    getInvoiceDetailsById: (id, CustId) =>
      dispatch(actions.fetchInvoiceDetailsById(id, CustId)),
    getAppModuleDetail: () => dispatch(actions.fetch_appdetails_withModule()),
  };
};
const InvoiceComponent = connect(mapStateToProps, mapDispatchToProps)(Invoice);
export default withRouter(withStyles(Styles)(withSnackbar(InvoiceComponent)));
