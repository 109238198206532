// export const ADD_ORGANIZATION_DATA = "ADD_ORGANIZATION_DATA";
// export const ORGANIZATIONS_FILTER = "ORGANIZATIONS_FILTER";
// export const ORGANIZATIONS_SORT = "ORGANIZATIONS_SORT";
// export const ACTIVE_INACTIVE_ORG = "ACTIVE_INACTIVE_ORG";
// export const ADD_CURRENT_ORGANIZATION_DATA = "ADD_CURRENT_ORGANIZATION_DATA";
export const CHANGE_LOGO = "CHANGE_LOGO";
export const FETCHING_ORG_DETAILS="FETCHING_ORG_DETAILS"
export const FETCH_ORGANIZATION_DETAILS_SUCCESS =
  "FETCH_ORGANIZATION_DETAILS_SUCCESS";
export const FETCH_ORGANIZATION_DETAILS_FAIL =
  "FETCH_ORGANIZATION_DETAILS_FAIL";
export const RESET_ORGANIZATION = "RESET_ORGANIZATION";
