import { Typography, Grid, Link, Badge, Button } from "@material-ui/core";
import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import ModalTable from "./ModalTable";
import nodataimg from "assets/images/nodata.svg";
import { RiDownload2Line } from "react-icons/ri";
import WrappedDexTable from "components/DexTable/WrappedDexTable";
import invoiceFun from "../services";
import actions from "../redux/action";
import { connect } from "react-redux";
const h3style = {
  display: "block",
  fontSize: "1.17em",
  marginBlockStart: "1em",
  marginBlockEnd: "1em",
  marginInlineStart: "0px",
  marginInlineEnd: "0px",
  fontWeight: "bold",
};
const NoDataFound = (props) => {
  return (
    <td>
      <div
        style={{
          position: "absolute",
          textAlign: "center",
          // maxWidth: "11rem",
          // justifyContent:"center",
          // alignItems:"center",
          marginTop: "4%",
          marginLeft: "43%",
          // top:"calc(30% + 2rem)",
          // left:"calc(50% - 4rem)"
        }}
      >
        <img src={nodataimg} alt="notfound" />
        <h5 style={{ textAlign: "center", color: "#666666", ...h3style }}>
          {props.msg || "Data Not Found"}
        </h5>
      </div>
    </td>
  );
};
const getStatusColor = (type, classes) => {
  switch (type) {
    case "Pending":
      return (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{
              width: "50%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="body1">{type}</Typography>
            <Badge
              classes={{ badge: classes.pendingBadge }}
              variant="dot"
              style={{ marginLeft: 10 }}
            />
          </div>
          <div style={{ width: "100%" }}></div>
        </div>
      );
    case "Active":
      return (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{
              width: "50%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="body1">{type}</Typography>
            <Badge
              classes={{ badge: classes.activeBadge }}
              variant="dot"
              style={{ marginLeft: 10 }}
            />
          </div>
          <div style={{ width: "100%" }}></div>
        </div>
      );
    default:
      return <div></div>;
  }
};
function Row(props) {
  const [slabs, setSlab] = useState({ total: 0, arr: [] });
  const [open, setOpen] = React.useState(false);
  const [openmodal, setOpenModal] = React.useState(false);
  const { rowData, classes, InvoiceData, hidden } = props;
  const { appDetails, billableService } = rowData || {
    appDetails: {},
    billableService: [],
  };
  const { Status, GrossAmount } = InvoiceData || { status: "", GrossAmount: 0 };

  const handleOpenModal = (arr, total) => {
    setSlab({ ...slabs, arr: arr, total: total });
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  console.log(billableService, "sudheerbillable");
  return (
    <>
      <TableRow onClick={() => setOpen(!open)}>
        <TableCell className={classes.tableheight}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {appDetails.name}
        </TableCell>
        <TableCell></TableCell>
        <TableCell align="right">
          {<RiDownload2Line style={{ fontSize: "20px" }} />}
        </TableCell>
        {/* <TableCell align="left"> {getStatusColor(Status, classes)}</TableCell> */}
        {/* <TableCell align="left"> $ {GrossAmount}</TableCell> */}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box className={classes.collapsablestyle}>
              <Table
                size="small"
                aria-label="purchases"
                className={classes.tableborder}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Billable Service</TableCell>
                    <TableCell>Units</TableCell>
                    <TableCell>Units Consumed</TableCell>
                    <TableCell>Cost</TableCell>
                    <TableCell>Slab details</TableCell>
                    <TableCell align="right">Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {billableService.map((historyRow, key) => {
                    let total = 0;
                    const type = historyRow.Type === "slab";
                    const flatQuantity =
                      (!type && historyRow.Slabs[0]?.Quantity) || 0;

                    const totalFlatCost =
                      (!type && historyRow.Slabs[0]?.TotalCost) || 0;
                    if (type && historyRow.Slabs) {
                      historyRow.Slabs.map(
                        (itm, i) => (total = total + itm.TotalCost)
                      );
                    }

                    return (
                      <TableRow key={key}>
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.tableheight}
                        >
                          {historyRow?.BillableDetails?.name || " "}
                        </TableCell>
                        <TableCell>{historyRow.BillableDetails.unit}</TableCell>
                        <TableCell align="left">
                          {historyRow.Quantity}
                        </TableCell>
                        <TableCell align="left">
                          {" "}
                          {type && total}
                          {!type && totalFlatCost}
                        </TableCell>
                        <TableCell align="left">
                          {type && (
                            <Link
                              style={{
                                textDecoration: "none",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                handleOpenModal(historyRow.Slabs, total)
                              }
                            >
                              view
                            </Link>
                          )}
                          {!type && <Typography>{flatQuantity}</Typography>}
                        </TableCell>
                        <TableCell align="right">
                          {historyRow.GrossAmount}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <div>
        <ModalTable
          slabs={slabs.arr}
          total={slabs.total}
          openmodal={openmodal}
          handleCloseModal={handleCloseModal}
          // property={property}
          // classes={classes}
          // Modaldata={Modaldata}
          {...props}
        />
      </div>
    </>
  );
}
const setHight = (num) => {
  switch (num) {
    case 1:
      return {
        height: "15vh",
      };
    case 2:
      return {
        height: "30vh",
      };
    case 3:
      return {
        height: "45vh",
      };
    case 4:
      return {
        height: "60vh",
      };
    default:
      return {
        height: "70vh",
      };
  }
};

function CollapsibleTable(props) {
  const { classes, appUsageDetails, hidden } = props;
  const [totalCount, settotalCount] = React.useState(1);
  // style={setHight(Object.keys(appUsageDetails).length)}
  return (
    <>
      <TableContainer>
        <Table aria-label="collapsible table" className={classes.tableborder}>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: "6%" }} />
              <TableCell style={{ width: "35%" }}>Application</TableCell>
              <TableCell></TableCell>
              <TableCell align="left"></TableCell>
            </TableRow>
          </TableHead>
          {Object.keys(appUsageDetails).length !== 0 ? (
            <TableBody>
              {Object.keys(appUsageDetails).map((row, index) => {
                return (
                  <Row key={index} rowData={appUsageDetails[row]} {...props} />
                );
              })}
            </TableBody>
          ) : (
            <NoDataFound />
          )}
        </Table>
      </TableContainer>
      {/* {data.length !== 0 && (
        <div className={classes.footer}>
          <div className={classes.customPagination}>
            <Pagination
              count={Math.ceil(totalCount / 10)}
              //   page={this.state.page}
              // onChange={this.handlePageChange}
              shape="rounded"
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )} */}
    </>
  );
}

const InvoiceDetailsTable = (props) => {
  const { nonGrpData, hide } = props;
  const app_Name = ({ row }) => {
    console.log("hgdjgjhgdsjgh", row);
    return (
      <Typography variant="body1">{row?.AppDetails?.name || ""}</Typography>
    );
  };
  const billable_ServiceName = ({ row }) => {
    return (
      <Typography variant="body1">
        {row?.BillableDetails?.name || ""}
      </Typography>
    );
  };
  const display_unit = ({ row }) => {
    return (
      <Typography variant="body1">
        {row?.BillableDetails?.unit || ""}
      </Typography>
    );
  };

  const SlabComponent = ({ row }) => {
    const [slabs, setSlab] = useState({ total: 0, arr: [] });
    const [openmodal, setOpenModal] = React.useState(false);
    const type = row.Type === "slab";
    let total = 0;
    if (type && row.Slabs) {
      row.Slabs.map((itm, i) => (total = total + itm.TotalCost));
    }
    const flatQuantity = (!type && row.Slabs[0]?.Quantity) || 0;
    const handleOpenModal = (arr, total) => {
      setSlab({ ...slabs, arr: arr, total: total });
      setOpenModal(true);
    };
    const handleCloseModal = () => {
      setOpenModal(false);
    };
    return (
      <div>
        {type && (
          <Link
            style={{
              textDecoration: "none",
              cursor: "pointer",
            }}
            onClick={() => handleOpenModal(row.Slabs, total)}
          >
            view
          </Link>
        )}
        {!type && (
          <Typography>
            {
              // flatQuantity
              "-"
            }
          </Typography>
        )}
        <ModalTable
          slabs={slabs.arr}
          total={slabs.total}
          openmodal={openmodal}
          handleCloseModal={handleCloseModal}
          // property={property}
          // classes={classes}
          // Modaldata={Modaldata}
          {...props}
        />
      </div>
    );
  };

  const display_cost = ({ row }) => {
    // let total = 0;
    // const type = row.Type === "slab";
    // const totalFlatCost = (!type && row.Slabs[0]?.TotalCost) || 0;
    // if (type && row.Slabs) {
    //   row.Slabs.map((itm, i) => (total = total + itm.TotalCost));
    // }
    const { type, totalFlatCost } = invoiceFun.costValue(row);
    return (
      <Typography variant="body1">
        {" "}
        {type && "-"}
        {!type && totalFlatCost}
      </Typography>
    );
  };

  const display_slab = ({ row }) => {
    return (
      <div>
        <SlabComponent row={row} />
      </div>
    );
  };

  const display_module = ({ row }) => {
    return (
      <div>
        {<Typography variant="body2">{row?.Module?.name || "-"}</Typography>}
      </div>
    );
  };

  return (
    <div style={{ width: "100%" }}>
      <WrappedDexTable
        data={nonGrpData || []}
        // hideColumnChooser={true}
        columnOrder={[
          "appName",
          "module",
          "billableServiceName",
          "unit",
          "Quantity",
          "Type",
          "cost",
          "slabDetails",
          "GrossAmount",
        ]}
        columns={[
          { name: "appName", title: "Application" },
          { name: "billableServiceName", title: "Billable Service" },
          { name: "module", title: "Module" },
          { name: "unit", title: "Units" },
          { name: "Quantity", title: "Units Consumed" },
          { name: "cost", title: "Price/Qty" },
          { name: "GrossAmount", title: "Total Amount" },
          { name: "slabDetails", title: "Slab details" },
          { name: "Type", title: "Type" },
        ]}
        defaultColumnWidths={[
          { columnName: "appName", width: 150 },
          { columnName: "billableServiceName", width: 190 },
          { columnName: "module", width: 130 },
          { columnName: "unit", width: 150 },
          { columnName: "Quantity", width: 200 },
          { columnName: "cost", width: 130 },
          { columnName: "GrossAmount", width: 150 },
          { columnName: "slabDetails", width: 150 },
          { columnName: "Type", width: 130 },
        ]}
        formatters={[
          { formatterFn: app_Name, columns: ["appName"] },
          {
            formatterFn: billable_ServiceName,
            columns: ["billableServiceName"],
          },
          { formatterFn: display_unit, columns: ["unit"] },
          { formatterFn: display_cost, columns: ["cost"] },
          { formatterFn: display_slab, columns: ["slabDetails"] },
          {
            formatterFn: display_module,
            columns: ["module"],
          },
        ]}
        hidden={[...hide]}
        setHiddenColmn={(hidden) => {
          props.setHiddenColumn(hidden);
        }}
        pagination={false}
      />
    </div>
  );
};

const AppDetailsinvoice = (props) => {
  const { classes, hidden } = props;

  return (
    <Grid container style={{ paddingTop: 0 }}>
      <Grid item md={12} style={{ paddingTop: 6 }}>
        {/* <CollapsibleTable {...props} /> */}
        <InvoiceDetailsTable {...props} />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    hide: state.invoiceDetails.hide,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // getCreditdetailsById: (Id, invoiceId, payload) =>
    //   dispatch(actions.getCreditdetailsById(Id, invoiceId, payload)),
    setHiddenColumn: (hidden) => dispatch(actions.updateGraphHiddenCol(hidden)),
  };
};

// export default AppDetailsinvoice;
export default connect(mapStateToProps, mapDispatchToProps)(AppDetailsinvoice);
