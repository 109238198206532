/**
 * This is Higher Order component example for Section
 */
import React, { Component } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Grid, Divider, IconButton } from "@material-ui/core";

// function to take the component and name of the section
function withSection(WrappedComponent, name) {
  return class extends Component {
    constructor(props) {
      super(props);
      this.accordionSummaryRef = React.createRef();
      this.state = {
        expanded: true,
        color: "inherit",
        name: name || "",
      };
    }
    async handleChange() {
      await this.setState({ expanded: !this.state.expanded });
      console.log(this.accordionSummaryRef.current);
      if (this.state.expanded)
        this.accordionSummaryRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
    }

    handleSetColor = (color) => {
      this.setState({
        color,
      });
    };

    handleSetText = (txt) => {
      this.setState({
        name: txt,
      });
    };

    render() {
      let name = this.state.name.trim();
      let flag = name[name.length - 1] === "*" ? true : false;
      let value = flag ? name.slice(0, name.length - 1) : name;
      let star = flag ? name[name.length - 1] : null;
      console.log("12345", value, star);

      return (
        <Accordion
          style={{
            padding: "0",
            minHeight: "48px",
            width: "100%",
            marginLeft: "1px",
            boxShadow: "none",
            // borderBottom: "2px solid #afafaf",
            backgroundColor: "transparent",
            marginTop: "1%",
            marginBottom: "1%",
          }}
          expanded={this.state.expanded}
          onChange={(e) => {
            this.handleChange(e);
          }}
        >
          <div>
            <AccordionSummary
              expandIcon={
                <div style={{ height: "40px", width: "40px" }}>
                  <i class="fas fa-sort-down" style={{ fontSize: "30px" }} />
                </div>
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography style={{ color: this.state.color }} variant="h5">
                {value}
                <span style={{ color: "red" }}>{star}</span>
              </Typography>
            </AccordionSummary>
            <Grid item md={12}>
              <Divider
                style={{
                  marginBottom: "10px",
                  height: "2px",
                  background: "rgb(175, 175, 175)",
                }}
              />
            </Grid>
          </div>

          <AccordionDetails ref={this.accordionSummaryRef}>
            <WrappedComponent
              {...this.props}
              handleSetColor={this.handleSetColor}
              handleSetText={this.handleSetText}
            />
          </AccordionDetails>
        </Accordion>
      );
    }
  };
}

export default withSection;
