import * as types from "./actionType";
const initialState = {
  listOfApp: [],
  loading: false,
  invoiceData: [],
  invoiceDataLoading: false,
  propsDisabled: true,
  usages: [],
  billableServices: {},
  customerIssues: [],

  info: {
    message: null,
    success: null,
    loadingSubApp: false,
  },
  colTable: {
    application: ["startDate", "endDate", "type"],
    invoice: ["DateFrom"],
    issue: [],
  },
};
export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case types.FETCHING_CUSTOMER_SUB_APP:
    case types.FETCHING_INVOICE:
    case types.FETCHING_CUSTOMER_ISSUE:
      return {
        ...state,
        loading: true,
      };

    case types.FETCHED_CUSTOMER_SUB_APP:
      return {
        ...state,
        listOfApp: [...action.payload],
        info: {
          ...state.info,
          // message: action.msg,
          // success: true,
        },
        loading: false,
      };
    case types.FETCH_CUSTOMER_SUB_APP_FAILED:
      return {
        ...state,
        listOfApp: [],
        info: {
          ...state.info,
          message: action.msg,
          success: false,
        },
        loading: false,
      };

    case types.FETCHED_INVOICE:
      return {
        ...state,
        invoiceData: action.payload,
        loading: false,
        info: {
          ...state.info,
          // message: action.msg,
          // success: true,
        },
      };
    case types.FETCH_INVOICE_FAILED:
      return {
        ...state,
        invoiceData: [],
        loading: false,
        info: {
          ...state.info,
          message: action.msg,
          success: false,
        },
      };

    case types.FETCHED_CUSTOMER_ISSUE:
      return {
        ...state,
        loading: false,
        customerIssues: action.payload,
        info: {
          ...state.info,
          // message: action.msg,
          // success: true,
        },
      };
    case types.FETCH_CUSTOMER_ISSUE_FAILED:
      return {
        ...state,
        loading: false,
        customerIssues: [],
        info: {
          ...state.info,
          message: action.msg,
          success: false,
        },
      };

    case types.UPDATING_CUSTOMER_PROFILE:
      return {
        ...state,
        loading: true,
      };

    case types.UPDATED_CUSTOMER_PROFILE:
      return {
        ...state,
        loading: false,
      };
    case types.UPDATE_CUSTOMER_PROFILE_FAILED:
      return {
        ...state,
        loading: false,
      };
    case types.ENABLE_EDIT:
      return {
        ...state,
        propsDisabled: action.payload,
      };
    case types.FETCHING_CUST_APP_BILLABLE_SERVICES:
      return {
        ...state,
        loading: true,
      };
    case types.FETCHED_CUST_APP_BILLABLE_SERVICES:
      return {
        ...state,
        loading: false,
        billableServices: action.payload,
      };
    case types.FETCH_CUST_APP_BILLABLE_SERVICES_FAILED:
      return {
        ...state,
        loading: false,
        billableServices: {},
      };

    case types.UPDATING_ENDDATE_SUBSCRIPTION:
      return {
        ...state,
        loading: true,
      };

    case types.UPDATED_ENDDATE_SUBSCRIPTION:
      return {
        ...state,
        loading: false,
      };
    case types.UPDATE_ENDDATE_SUBSCRIPTION:
      return {
        ...state,
        loading: false,
      };
    case types.UPDATE_CUSTOMER_PROFILE_HIDDEN_COL:
      return {
        ...state,
        colTable: {
          ...state.colTable,
          [action.key]: action.payload,
        },
      };
    default:
      return state;
  }
}
