import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import {
  Typography,
  Avatar,
  Card,
  CardContent,
  Grid,
  Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  card: {
    display: " flex",
    flexDirection: " row",
    border: "1px solid rgb(203, 203, 203)",
    borderRadius: "5px",
    padding: "10px",
  },
  title: {
    backgroundColor: "rgba(0, 0, 0, 0.043)",
    borderBottom: "1px solid rgba(0, 0, 0, 0.043)",
  },
  modalheight: {
    height: "430px",
    width: "600px",
  },
  actions: {
    backgroundColor: "rgb(249, 250, 253)",
  },
  lockmodalheight: {
    height: "150px",
    width: "400px",
    marginTop: "30px",
    textAlign: "center",
  },
  icon: { display: "flex", alignItems: "center", justifyContent: "center" },
}));

export default function ModalBlock(props) {
  const {
    open,
    handleClose,
    text,
    action,
    Icon,
    yes,
    color,
    teaminfo,
    tm,
    lm,
  } = props;
  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={!tm ? false : true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {!tm ? (
          <DialogContent className={classes.lockmodalheight}>
            <DialogContentText id="alert-dialog-description">
              {Icon && <div className={classes.icon}>{Icon}</div>}
              <Typography variant="body2">{text}</Typography>
            </DialogContentText>
          </DialogContent>
        ) : (
          <>
            <DialogTitle className={classes.title}>
              {" "}
              <Typography gutterBottom variant="h4">
                <b>Team Members</b>
              </Typography>
            </DialogTitle>
            <DialogContent className={classes.modalheight}>
              <DialogContentText id="alert-dialog-description">
                <Grid container spacing={2}>
                  {teaminfo.map((item, id) => (
                    <Grid item md={6} className="mt-3">
                      <div className={classes.card}>
                        <Avatar />
                        <div className="ml-3">
                          <Typography>
                            <b>{item.name}</b>
                          </Typography>
                          <Typography>
                            <span style={{ fontSize: "12px" }}>
                              {item.email}
                            </span>
                          </Typography>
                        </div>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </DialogContentText>
            </DialogContent>
          </>
        )}
        {!tm ? (
          <DialogActions className={classes.actions}>
            <Button
              variant="outlined"
              size="small"
              onClick={handleClose}
              color="primary"
              autoFocus
            >
              No
            </Button>
            <Button
              style={{ color: "white" }}
              variant="contained"
              size="small"
              onClick={action}
              color="primary"
            >
              {yes}
            </Button>
          </DialogActions>
        ) : (
          <DialogActions className={classes.actions}>
            <Button
              variant="contained"
              size="small"
              onClick={handleClose}
              autoFocus
            >
              Done
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
}
