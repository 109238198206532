import React from "react";
import { Doughnut } from "react-chartjs-2";

const TotalChart = (props) => {
  const { classes, dataSet, total } = props;
  const data = {
    datasets: [
      {
        label: "Dataset",
        data: [80, 50, 160, 40],
        backgroundColor: [
          "#013478",
          "#fbbd2c",
          "#b5cc2c",

          "#2185d0",
          // "#f26365",
        ],
        hoverBackgroundColor: [
          "#013478",
          "#fbbd2c",
          "#b5cc2c",

          "#2185d0",
          // "#f26365",
        ],
        hoverOffset: 3,
      },
    ],
  };

  const plugins = [
    {
      beforeDraw: function (chart) {
        var width = chart.width,
          height = chart.height,
          ctx = chart.ctx;
        ctx.restore();
        var fontSize = (height / 150).toFixed(2);
        ctx.font = fontSize + "em sans-serif";
        ctx.textBaseline = "top";
        var text = total?.toString() || "",
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2;
        ctx.fillText(text, textX, textY);
        ctx.save();
      },
    },
  ];

  return (
    <div className={classes.smallChart}>
      <Doughnut
        type="doughnut"
        plugins={plugins}
        data={dataSet}
        //  options={{ responsive: true }}
        options={{
          // cutoutP: 50, //for donuts pie
          cutout: 40,
          responsive: true,
          // onClick: function (event, chartElements) {
          //   if (chartElements) {
          //     console.log(chartElements[0].label);
          //   }
          // },
        }}
      />
    </div>
  );
};

export default TotalChart;
