import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useSelector, useDispatch } from "react-redux";
import SunEditorComponent from "./SunEditor";
import {
  Button,
  Grid,
  Typography,
  Divider,
  FormControl,
} from "@material-ui/core";
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator";
import Breadcrumb from "components/Breadcrumbs/Breadcrumb";
import Loader from "components/common/stuff/Loader";
// import { isDuplicate } from "../../../helpers/helpers";
import { RiEditLine } from "react-icons/ri";
import { usageDocumentaion, fecthIntroDocuments } from "../Redux/action";
import docStyle from "./styles";
import { useHistory } from "react-router";
import DisplayUsageDoc from "./UsageDocuments";
import { checkPermission } from "helpers/permissons";
import HelpdeskFAB from "../../../../views/HelpdeskFAB/HelpdeskFab";

const CreateDoc = (props) => {
  const classes = docStyle();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const appDetails = useSelector((state) => state.appDetailsRedcer);
  const { documents, introDoc } = appDetails;
  console.log(introDoc, "djgjdgj764768468");
  let [doc, setDoc] = useState({
    type: "usage",
    module: "",
    name: "",
    description: "",
  });
  const [htmlContent, setHtmlContent] = useState("");
  const [docState, setDocState] = useState({
    isEdit: false,
    ID: 0,
    disable: false,
    modules: [],
    name: "",
  });
  const { isDisabled, ID, isEdit, modules, name } = docState;
  useEffect(() => {
    const { location } = props;
    if (location.state !== undefined) {
      const { data } = location.state;
      console.log("dsahwqtrtwrqtry ndcmv234354", data);
      setDocState({ ...data });
      if (data.isEdit || data.isDisabled) {
        const { module, ...otherUsageDoc } = data.usageDoc;
        let moduleID = module._id;

        setHtmlContent(otherUsageDoc?.description || "");
        setDoc({ ...doc, module: moduleID, ...otherUsageDoc });
      } else {
        setDoc({ ...doc, appId: data.ID });
      }
      // data.isEdit &&
      //   dispatch(fecthIntroDocuments(data.usageDoc.appId, data.usageDoc._id));
    } else {
      props.history.push("app/applications");
    }
  }, []);

  useEffect(() => {
    const { message, success, singleAdd } = documents;
    message !== null &&
      enqueueSnackbar(message, {
        variant: success ? "success" : "error",
      });
    singleAdd &&
      props.history.push("/app/detail", {
        appId: ID,
      });

    if (success && singleAdd !== null && !singleAdd) {
      setDoc({ ...doc, module: "", description: "", name: "" });
    }
  }, [documents]);

  const onchangeDoc = (values) => {
    setDoc({ ...values });
  };

  const singleAjaxFunc = (val) => {
    let payload = { ...doc };
    payload.description = htmlContent;
    if (isEdit) {
      const { _id, ...other } = payload;
      dispatch(usageDocumentaion(ID, _id, val, other));
      setDoc({ ...doc, module: "", description: "", name: "" });
      setHtmlContent("");
    } else {
      dispatch(usageDocumentaion(ID, "add", val, payload));
      setDoc({ ...doc, module: "", description: "", name: "" });
      setHtmlContent("");
    }
    setDoc({ ...doc, module: "", description: "", name: "" });
  };

  const ajaxFunc = () => {
    singleAjaxFunc(false);
  };
  const onSubmitAjax = (e) => {
    if (e.nativeEvent.submitter.name === "multiple") {
      ajaxFunc();
    }
    if (e.nativeEvent.submitter.name === "single") {
      singleAjaxFunc(true);
    }
  };

  const goToUsageDocumentaion = () => {
    // props.history.push(`/app/usagedocument/create_usage_document/${ID}`, {
    //   viewUsageDocData: docState,
    // });
    props.history.push(`/app/documentation/${ID}`, {
      documentationData: docState,
      type: "usage",
    });
  };

  const fetchDescription = (event) => {
    setHtmlContent(event);
  };
  console.log(doc, htmlContent, docState, "docdoc");
  return (
    <>
      <div>
        {documents.loading && <Loader />}
        <div>
          <Grid item sm={12} md={12} xs={12} lg={12}>
            <Breadcrumb
              {...props}
              links={[
                { name: "Applications", url: "/app/applications" },
                {
                  name: "Application Details",
                  url: `${"/app/detail"}`,
                  routeParams: { appId: ID },
                },
                {
                  name: "Document",
                  url: "/app/create-modules-form",
                },
              ]}
            />
          </Grid>
          <div>
            <ValidatorForm
              onSubmit={(e) => {
                onSubmitAjax(e);
              }}
            >
              <Grid container spacing={0}>
                <Grid item sm={12} md={12} xs={12} lg={12}>
                  <Grid
                    container
                    spacing={1}
                    direction="row"
                    alignItems="center"
                    className={classes.root}
                  >
                    <Grid item md={6}>
                      <div>
                        <Typography variant="h6" className={classes.title}>
                          Usage Documentation
                        </Typography>
                      </div>
                    </Grid>
                    <Grid
                      item
                      sm={6}
                      container
                      justifyContent="flex-end"
                      spacing={1}
                    >
                      <Grid item>
                        {!isDisabled && (
                          <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            onClick={() => {
                              props.history.push("/app/detail", {
                                appId: ID,
                              });
                            }}
                          >
                            Cancel
                          </Button>
                        )}
                      </Grid>

                      <Grid item>
                        {!isEdit && (
                          <Button
                            name="multiple"
                            size="small"
                            color="primary"
                            variant="outlined"
                            type="submit"
                            disabled={!checkPermission("btn128")}
                          >
                            {"Create and Add Another"}
                          </Button>
                        )}
                      </Grid>
                      <Grid item>
                        {!isDisabled && (
                          <Button
                            color="primary"
                            size="small"
                            variant={"contained"}
                            type="submit"
                            name="single"
                            disabled={
                              isEdit
                                ? !checkPermission("btn127")
                                : !checkPermission("btn126")
                            }
                          >
                            {isEdit ? "Save" : "Create"}
                          </Button>
                        )}
                      </Grid>
                      {isEdit && (
                        <Grid item>
                          <Button
                            size="small"
                            variant="contained"
                            disabled={!checkPermission("btn129")}
                            onClick={() => goToUsageDocumentaion()}
                          >
                            Open Editor
                          </Button>
                        </Grid>
                      )}

                      {isDisabled && (
                        <Grid item>
                          <Button
                            size="small"
                            variant="contained"
                            onClick={() =>
                              setDocState({ ...docState, isDisabled: false })
                            }
                            startIcon={
                              <RiEditLine style={{ fontSize: "20px" }} />
                            }
                          >
                            Edit
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={12}>
                  <Divider className={classes.divider} />
                </Grid>
                <Grid item md={12} style={{ marginTop: "22px" }}>
                  <Grid container spacing={2}>
                    <Grid item md={2}>
                      <div style={{ width: "100%" }}>
                        <Typography
                          style={{ marginBottom: "2px" }}
                          variant="body2"
                        >
                          Name <span style={{ color: "red" }}>*</span>
                        </Typography>
                        <TextValidator
                          disabled={isDisabled}
                          autoComplete="off"
                          size="small"
                          variant="outlined"
                          fullWidth
                          value={doc.name}
                          id="DocName"
                          //error={error}
                          onChange={(e) =>
                            onchangeDoc({ ...doc, name: e.target.value })
                          }
                          name="DocName"
                          validators={["required"]}
                          errorMessages={["Please enter document name"]}
                          //   helperText={err_msg}
                        />
                      </div>
                    </Grid>
                    {/* <Grid item md={3}>
                      <div style={{ width: "100%" }}>
                        <Typography
                          style={{ marginBottom: "2px" }}
                          variant="body2"
                        >
                          Module Id <span style={{ color: "red" }}>*</span>
                        </Typography>
                        <TextValidator
                          disabled={isDisabled}
                          autoComplete="off"
                          size="small"
                          variant="outlined"
                          fullWidth
                          //value={modules.name}
                          id="DocModuleId"
                          //error={error}
                          name="DocModuleId"
                          validators={["required"]}
                          errorMessages={["Please enter document Module Id"]}
                          //   helperText={err_msg}
                        />
                      </div>
                    </Grid> */}
                    <Grid item xs={2}>
                      <div>
                        <Typography
                          style={{ marginBottom: "2px" }}
                          variant="body2"
                        >
                          {" "}
                          Module Name <span style={{ color: "red" }}>*</span>
                        </Typography>
                        <FormControl fullWidth>
                          <SelectValidator
                            disabled={isDisabled}
                            variant="outlined"
                            fullWidth
                            size="small"
                            SelectProps={{
                              native: true,
                            }}
                            value={doc.module}
                            onChange={(e) =>
                              onchangeDoc({
                                ...doc,
                                module: e.target.value,
                              })
                            }
                            inputProps={{
                              name: "name",
                            }}
                            validators={["required"]}
                            errorMessages={["Please select module name "]}
                            // error={error}
                          >
                            <option value={""}>- select -</option>
                            {modules.map((item) => (
                              <option value={item._id}>{item.name}</option>
                            ))}
                          </SelectValidator>
                        </FormControl>
                      </div>
                    </Grid>

                    <Grid item md={8}></Grid>
                    <Grid item md={12}>
                      <div style={{ width: "100%" }}>
                        <Typography
                          variant="body2"
                          style={{ marginBottom: "2px" }}
                        >
                          Description
                        </Typography>
                        <SunEditorComponent
                          disabled={isDisabled}
                          setHtmlContent={setHtmlContent}
                          description={htmlContent}
                        />
                        {/* <TextValidator
                          //   disabled={}
                          autoComplete="off"
                          multiline
                          rows={7}
                          size="small"
                          variant="outlined"
                          fullWidth
                          value={doc.description}
                          id="moduleDescription"
                          onChange={(e) =>
                            onchangeDoc({
                              ...doc,
                              description: e.target.value,
                            })
                          }
                          name="moduleDescription"
                          // validators={["required"]}
                          // errorMessages={["Please enter module description"]}
                        /> */}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <HelpdeskFAB />
              </Grid>
              <br />
              {/* {isEdit && (
                <Grid container>
                  <Grid item md={12}>
                    <DisplayUsageDoc />
                  </Grid>
                </Grid>
              )} */}
            </ValidatorForm>
          </div>
        </div>
      </div>
    </>
  );
};
export default CreateDoc;
