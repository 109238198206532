import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import Avatar from "@material-ui/core/Avatar";
import StarIcon from "@material-ui/icons/Star";
import Divider from "@material-ui/core/Divider";
import VisibilityIcon from '@material-ui/icons/Visibility';
import {
  IconButton,
  CardContent,
  FormGroup,
  Card,
  Grid,
  FormControl,
  Select,
  Button,
  TextField,
  Typography,
  FormLabel,
} from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  line: {
    marginTop: -23,
  },

  dividerline: {
    // border: "1px solid black",
    width: "70%",
    margin: "-25px 0px 0px 125px",
    [theme.breakpoints.up("md")]: {
      width: "82%",
      margin: "-25px 0px 0px 260px",
    },
  },
}));

function Document(props) {

  let [data, setData] = React.useState({
    name: "on Subscribe",
    documentType: "",
    module: "Get",
    description: ""
  })

  const [isSwitch, setSwitch] = React.useState(false);

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "#002855",
      color: theme.palette.common.white,
    },
    body: {
      // height: "14vh",
      // width: 15,
      height: 1,
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    // root: {
    //   "&:nth-of-type(odd)": {
    //     backgroundColor: theme.palette.action.hover,
    //   },
    // },
  }))(TableRow);

  const rows = [
    {
      // documentname: "Document 1",
      documenttype: "Intro",
      module: "Module 1",
      description: "Description"
    },
  ];
  const classes = useStyles();

  const getValueOfDocumentation = (event) => {
    let object = data;
    if (event.target.name === "name") {
      object.name = event.target.value
    }
    else if (event.target.name === "documentType") {
      object.documentType = event.target.value
      // console.log(event.target.value, "dropdownggggg")
    }
    else if (event.target.name === "module") {
      object.module = event.target.value
    }
    else if (event.target.name === "description") {
      object.description = event.target.value
    }
    else {
      object.description = event.target.value
    }
    setData(object);
  }

  return (
    <div style={{ margin: "25px -5px 0px -5px" }}>
      {/* <div style={{ margin: "40px -15px 0px -15px" }}>
        <div style={{ marginBottom: "20px" }}> */}
      <div className="card mt-1  mt-md-3 w-100">
        <div className="card-header bg-white d-flex ">
          <Typography
            variant="h6"
            clasName={classes.line}
            color="theme.color.main.black"
          >
            Documentation
          </Typography>
          {/* <div style={{ display: "flex", alignItems: "center" }}>
            <Divider className={classes.dividerline} variant="inset" />
          </div> */}
        </div>
        <div className="card-body">
          {/* <form onSubmit={(e) => props.onSubmitDocumentdata(e, data)}> */}

            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left" >
                      Documentation Name
                    </StyledTableCell>
                    <StyledTableCell align="left" >
                      Documentation Type
                    </StyledTableCell>
                    <StyledTableCell align="left" >
                      Module
                    </StyledTableCell>
                    <StyledTableCell align="left" >
                      Description
                    </StyledTableCell>
                    <StyledTableCell
                      align="right"
                    // style={{ width: "00px" }}
                    ></StyledTableCell>
                    <StyledTableCell
                      align="right"
                    // style={{ width: "00px" }}
                    ></StyledTableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  <StyledTableRow >
                    <StyledTableCell component="th" scope="row">
                      <FormControl
                        size="small"
                        variant="outlined"
                        className={classes.btncolor}
                        required
                        style={{ width: "170px" }}
                      >
                        <Typography varient="body1">Document Name</Typography>
                        <Select
                          native
                          name="documentName"
                          value={data.name}
                          defaultValue={data.name}
                          onChange={(e) => {
                            setData({ ...data, name: e.target.value });
                          }}
                        >
                          <option value="onsubscribe">On subscribe</option>
                          <option value="onUnsubscribe">On Unsubscribe</option>
                          <option value="onusercreate">On User Create</option>
                        </Select>
                      </FormControl>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Typography varient="body1">Document type</Typography>
                      <TextField
                        style={{ width: "100%" }}
                        size="small"
                        variant="outlined"
                        required
                        id="documentType"
                        name="documentType"
                        onChange={getValueOfDocumentation}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <FormControl
                        size="small"
                        variant="outlined"
                        required
                        className={classes.btncolor}
                        style={{ width: "200px" }}
                      >
                        <Typography varient="body1">Module</Typography>
                        <Select
                          native
                          name="documentModule"
                          value={data.module}
                          defaultValue={data.module}
                          onChange={(e) => {
                            setData({ ...data, module: e.target.value });
                          }}
                        >
                          <option value="get">GET</option>
                          <option value="post">POST</option>
                          <option value="put">PUT</option>
                        </Select>
                      </FormControl>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Typography varient="body1">Description</Typography>
                      <TextField
                        style={{ width: "100%" }}
                        size="small"
                        variant="outlined"
                        id="description"
                        onChange={getValueOfDocumentation}
                        required
                        name="documentDescription"
                      />
                    </StyledTableCell>

                    <StyledTableCell align="left">
                      <Button
                        type="submit"
                        style={{
                          marginLeft: 0,
                          backgroundColor: "transparent",
                          boxShadow: "none",
                          color: "#9e9e9e",
                        }}
                        variant="contained"
                        color="primary"
                      >
                        Save
                      </Button>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {" "}
                      <Button
                        style={{
                          marginLeft: 0,
                          backgroundColor: "transparent",
                          boxShadow: "none",
                          color: "#9e9e9e",
                        }}
                        variant="contained"
                        color="primary"
                      >
                        Cancel
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow>

                </TableBody>
                {/* 
                   <TableBody>
                     {data.map((data) => ( 
                    <StyledTableRow >
                       <StyledTableCell component="th" scope="row">
                        documentname
                       </StyledTableCell>
                       <StyledTableCell align="left">documentType</StyledTableCell>
                       <StyledTableCell align="left">module</StyledTableCell>
                       <StyledTableCell align="left">description</StyledTableCell>
                       <StyledTableCell align="right">
                         <Grid container direction="row" justifyContent="flex-end" alignItems="center" >
                           <Grid item xs={3}>
                             <IconButton aria-label="visibility">
                               <VisibilityIcon />
                             </IconButton>
                           </Grid>
                           <Grid item xs={3}>
                             <IconButton aria-label="edit">
                               <EditOutlinedIcon />
                             </IconButton>
                           </Grid>
                           <Grid item xs={3} >
                             <IconButton aria-label="delete">
                               <DeleteOutlineIcon />
                             </IconButton>
                           </Grid>
                         </Grid>

                       </StyledTableCell>
                     </StyledTableRow>
                     ))} 
                   </TableBody> 
                   */}
              </Table>
            </TableContainer>
          {/* </form> */}
        </div>
      </div>
    </div >
  );
}

export default Document;
