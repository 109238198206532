import React, { useState } from "react";
import { Button, Badge, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

const Filter = (props) => {
  const { variant, size, style, type, status, badgeColor } = props;
  const [filterOn, setFilterOn] = useState(false);
  const handleClickShow = () => setFilterOn(!filterOn);
  return (
    <Button
      variant={variant}
      size={size}
      className={style}
      type={type}
      onClick={() => handleClickShow()}
      // endIcon={filterOn ? <ExpandMoreIcon /> : <ExpandLessIcon />}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <Badge
          classes={{ badge: badgeColor }}
          variant="dot"
          style={{ marginRight: "10px" }}
        />
        <Typography variant="body1"> {status} </Typography>
      </div>
    </Button>
  );
};
export default Filter;
