const Styles = (theme) => ({
  // root: {
  //   marginTop: "8px",
  // },
  title: {
    textTransform: "capitalize",
    fontWeight: "bold",
    justifyContent: "center",
  },
  divider: {
    marginTop: "12px",
    height: "2px",
    background: "rgb(175, 175, 175)",
  },
  dropzoneStyle: {
    width: "100%",
    border: `3px solid ${theme.palette.primary.main}`,
    borderColor: "none",
    borderRadius: "10px",
    width: " 20rem",
    padding: "1.5rem 3rem 0rem",
    minHeight: "200px",
  },
  dropzoneParagraphClass: {
    // fontSize: "15px",
    // padding: "1%",
    // marginTop: "5px",
    // marginBottom: "5px",
    color: "#00285580",
    fontSize: "1rem",
    fontWeight: 600,
  },
  documentDropperWrapper: {
    // marginTop: "1.5rem",
    // marginBottom: "1.5rem",

    "& .documentDropperClass": {
      // width: "15rem",
      minHeight: "100px",
      padding: "1.5rem 3rem 0rem",
      border: "2.5px solid",
      borderColor: theme.palette.primary.main,
      borderRadius: 10,

      "& .MuiDropzoneArea-textContainer": {
        display: "flex",
        flexDirection: "column-reverse",
        alignItems: "center",
        justifyContent: "space-around",

        "& .MuiDropzoneArea-icon": {
          color: "#00285580",
          transform: "scale(1.4)",
        },

        "& .documentDropperTextClass": {
          fontSize: "1rem",
          fontWeight: "600",
          color: "#00285580",
        },
      },
    },
  },
  documentDropperWrapper2: {
    // marginTop: "1.5rem",
    // marginBottom: "1.5rem",

    "& .documentDropperClass": {
      width: "20rem",
      minHeight: "100px",
      padding: "1.5rem 3rem 0rem",
      border: "2.5px solid",
      borderColor: theme.palette.primary.main,
      borderRadius: 10,

      "& .MuiDropzoneArea-textContainer": {
        display: "flex",
        flexDirection: "column-reverse",
        alignItems: "center",
        justifyContent: "space-around",

        "& .MuiDropzoneArea-icon": {
          color: "#00285580",
          transform: "scale(1.4)",
        },

        "& .documentDropperTextClass": {
          fontSize: "1rem",
          fontWeight: "600",
          color: "#00285580",
        },
      },
    },
  },
});
export default Styles;
