const Styles = (theme) => ({
    ListItem:{
        fontWeight:'800',
    },
   title: {
      textTransform: "capitalize",
      fontWeight: "bold",
      justifyContent: "center",
    },
    paperLeftTitle: {
        fontSize: "16px",
        fontWeight: "600",
        color: "#262626",
        paddingTop: "12px",
        paddingBottom: "12px",
        paddingLeft: "13px",
        paddingRight: "22px"
    },
    paperBody: {
        marginTop: "10px",
    },
    mtMb: {
        marginTop:"0px",
        marginBottom:"0px"
    }, 
    innerNestedHeading: {
        paddingLeft: "40px",
    },
    innerNestedSubHeading: {
        paddingLeft: "0px",
        fontWeight: "800",
        color: "#2185d0"
    },
    dropDownList: {
        paddingLeft:"10px"
    },
    dropDownListIcon: {
        minWidth: "20px"
    },
    sideBarIcon: {
        fontSize:"16px"
    } 
});
export default Styles;