import React, { Component } from "react";
import Breadcrumb from "components/Breadcrumbs/Breadcrumb";
import SearchIcon from "@material-ui/icons/Search";
import FilterComponent from "components/FilterComponent/FilterComponent";
import {
  Typography,
  Grid,
  Button,
  InputAdornment,
  Tooltip,
  IconButton,
  Link,
} from "@material-ui/core/";
import { Pagination } from "@material-ui/lab";
import { CustomTextField } from "components/Customized";
import DexTable from "components/DexTable/DexTable";
import AddIcon from "@material-ui/icons/Add";
import { bulkUploadfiles } from "./redux/action";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";
import { RiEditLine, RiDeleteBin5Line } from "react-icons/ri";
import { BiUpload } from "react-icons/bi";
import { AiOutlineLock } from "react-icons/ai";
import { withSnackbar } from "notistack";
import actions from "./redux/action";
import TeamsAPIs from "api/TeamsAPIs/index";
import BlockIcon from "@material-ui/icons/Block";
import { withRouter } from "react-router";
import BlockModal from "./components/BlockModal";
import BulkUploadModal from "../../components/UploadComponent/index";
import PropTypes from "prop-types";
import Styles from "./styles.js";
import dataOptions from "./filterOptions.json";
import HelpersFunc from "helpers/helpers";
import BulkUploadSampleCsv from "../../assets/files/bulkuploadsample.csv";
import { checkPermission } from "helpers/permissons";
import HelpdeskFAB from "../../views/HelpdeskFAB/HelpdeskFab";
class DataStandardslist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data_options: [...dataOptions],
      page: 1,
      size: 10,
      filters: {},
      sort: { createdOn: -1 },
      sorting: [{ columnName: "createdon", direction: "desc" }],
      edit: false,
      searchkey: "",
      value: 0,
      openDropdown: false,
      setDropdown: false,
      setOpen: false,
      info: { status: "", name: "", dataId: 0 },
      setOpenupload: false,
      property: { src: BulkUploadSampleCsv },
    };
  }

  fetchAllDataStatandars = (filters, arr, search) => {
    let page = 1;
    this.setState({
      page: 1,
    });
    let { filterProps, updateFilter } = this.props;
    updateFilter({
      filter: filters,
      filterArray: arr,
    });
    let { size } = this.state;
    this.props.getData(
      page,
      size,
      JSON.stringify(filters),
      JSON.stringify(filterProps.sort)
    );
    if (!search) {
      this.setState({
        searchkey: "",
      });
    }
  };

  async componentDidMount() {
    let { filterProps } = this.props;
    // this.fetchAllDataStatandars(filterProps.filters, filterProps.filterArray);
    this.props.getData(
      this.state.page,
      this.state.size,
      JSON.stringify(filterProps.filters),
      JSON.stringify(filterProps.sort)
    );
  }

  filterFunc = (arr) => {
    let filters = {};
    if (arr.length === 1) {
      let { operator, value, type } = arr[0];
      let filter = HelpersFunc.findAndExecuteAjax(type)(operator, value);
      this.getFilteredData({ ...filter }, arr);
    } else {
      arr.map((item, id) => {
        const { operator, value, type } = item;
        let filter = HelpersFunc.findAndExecuteAjax(type)(operator, value);
        filters = { ...filters, ...filter };
        return 0;
      });
      this.getFilteredData({ ...filters }, arr);
    }
  };
  getFilteredData = async (filters, arr) => {
    this.fetchAllDataStatandars(filters, arr);
  };

  handleSortByChange = (sorting) => {
    let { filterProps, updateSorting, getData } = this.props;
    let filters = { ...filterProps.filters };
    let sort = {};
    sorting.forEach((f) => {
      sort[f.columnName] = f.direction == "asc" ? 1 : -1;
    });
    updateSorting({ sorting, sort });
    getData(
      this.state.page,
      this.state.size,
      JSON.stringify(filters),
      JSON.stringify(sort)
    );
  };
  searchAndPaginationHelperFunc = (page, val, filter_Props) => {
    this.props.getData(
      page,
      this.state.size,
      JSON.stringify({
        ...filter_Props.filters,
        name: { $regex: "^" + val, $options: "i" },
      }),
      JSON.stringify(filter_Props.sort)
    );
  };
  onChangeSearchAppName = (event) => {
    let page = 1;
    this.setState({
      searchkey: event.target.value,
      page: page,
    });
    this.searchAndPaginationHelperFunc(
      page,
      event.target.value,
      this.props.filterProps
    );
  };

  handlePageChange = async (e, newPage) => {
    this.setState({
      page: newPage,
    });
    this.fetchingData(newPage);
  };

  fetchingData = async (Page) => {
    let { filterProps } = this.props;
    if (this.state.searchkey.trim().length !== 0) {
      this.searchAndPaginationHelperFunc(
        Page,
        this.state.searchkey,
        filterProps
      );
    } else {
      await this.props.getData(
        Page,
        this.state.size,
        JSON.stringify(filterProps.filters),
        JSON.stringify(filterProps.sort)
      );
    }
  };
  displayMsg = (type, msg) => {
    this.props.enqueueSnackbar(msg, {
      variant: type ? "success" : "error",
      autoHideDuration: 3000,
    });
  };
  blockDataStandards = async () => {
    if (this.state.info.status === "active") {
      const { success, _msg } = await this.props.blockDatatandards(
        this.state.info.dataId,
        "block"
      );
      this.displayMsg(success, _msg);
    } else {
      const { success, _msg } = await this.props.blockDatatandards(
        this.state.info.dataId,
        "unblock"
      );
      this.displayMsg(success, _msg);
    }
    this.setState({
      setOpen: false,
      setOpenupload: false,
    });
    this.fetchingData(this.state.page);
  };

  openDropDown = () => {
    this.setState({ openDropDown: true });
  };
  dropUp = () => {
    this.setState({ setDropdown: true });
  };

  editData = (row, e) => {
    e && e.stopPropagation();
    let data = { ...row };
    this.props.history.push("/app/create-datastandards", {
      data_Standards: data,
      edit: true,
      disabled: e ? false : true,
    });
  };

  createDataStandard = () => {
    const dataPayload = {
      name: "",
      type: "",
      description: "",
      rules: {},
      industryType: "",
    };
    this.props.history.push("/app/create-datastandards", {
      data_Standards: dataPayload,
      edit: false,
    });
  };

  actionProvider = ({ row }) => {
    console.log(row, "row");
    let openModal = (e) => {
      e && e.stopPropagation();
      this.setState({
        info: {
          name: row.name,
          dataId: row._id,
          status: row.status,
        },
        setOpen: true,
      });
    };
    let flag = row.status !== "active" ? true : false;
    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          {/* {!flag && ( */}
          <Tooltip title="Edit" aria-label="edit">
            <IconButton
              disabled={flag && !checkPermission("btn57") ? true : false}
              color="inherit"
              size="small"
              onClick={(e) => checkPermission("btn57") && this.editData(row, e)}
            >
              <RiEditLine fontSize="20px" />
            </IconButton>
          </Tooltip>
          {/* )} */}
        </Grid>

        <Grid item>
          <Tooltip title={flag ? "Unblock" : "Block"} aria-label="block">
            <IconButton
              size="small"
              color="inherit"
              disabled={!checkPermission("btn58")}
              onClick={(e) => checkPermission("btn58") && openModal(e)}
            >
              <BlockIcon
                style={{ fontSize: "18px" }}
                className={
                  flag ? this.props.classes.block : this.props.classes.active
                }
              />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    );
  };

  BulkDataStandard = () => {
    this.setState({
      setOpenupload: true,
    });
  };

  handleBulkClose = () => {
    this.setState({
      setOpenupload: false,
    });
  };

  handleClose = () => {
    this.setState({
      setOpen: false,
    });
  };

  viewDsdata = (row) => {
    this.editData(row);
  };

  postBulkUpload = async (doc) => {
    console.log("doc : ", doc);
    var formData = new FormData();
    formData.append(
      "meta",
      JSON.stringify({ name: doc[0].name, docType: doc[0].file.type })
    );
    formData.append("file", doc[0].file);
    const { _msg, _status, data } = await this.props.bulkUploadfiles(formData);
    if (_status === 200 || _status === 201) {
      this.props.enqueueSnackbar(_msg ? _msg : "Fields uploaded successfully", {
        variant: "success",
      });
      this.props.getData(
        this.state.page,
        this.state.size,
        JSON.stringify(this.props.filterProps.filters),
        JSON.stringify(this.props.filterProps.sort)
      );
    } else {
      if (_msg === "Invalid Json") {
        if (data) {
          data.map((item) =>
            this.props.enqueueSnackbar(item ? item : "Something went wrong", {
              variant: "error",
            })
          );
        }
      } else {
        this.props.enqueueSnackbar(_msg ? _msg : "Something went wrong", {
          variant: "error",
        });
      }
    }
    this.handleBulkClose();
  };

  render() {
    const { data, classes, loading, totalCount, filterProps, hidden } =
      this.props;
    const { info, data_options } = this.state;
    let type = info.status === "active" ? "block" : "unblock";
    return (
      <>
        <Grid container>
          <Grid item sm={12} md={12} xs={12} lg={12}>
            <Breadcrumb
              {...this.props}
              links={[
                { name: "Active Metadata", url: "/app/data_standards" },
                {
                  name: "Active Metadata List",
                },
              ]}
            />
          </Grid>
          <Grid item sm={5} md={5} lg={5} xs={12}>
            <Typography variant="h6" style={{ fontWeight: "bold" }}>
              Active Metadata {`( ${this.props.totalCount} )`}
            </Typography>
          </Grid>
          <Grid item sm={7} md={7} lg={7} xs={12}>
            <Grid
              container
              spacing={2}
              alignItems="center"
              // justifyContent="space-between"
              justifyContent="flex-end"
            >
              <Grid item>
                <CustomTextField
                  className=" mt-2"
                  autoComplete="off"
                  fullWidth
                  id="filled-search"
                  type="text"
                  variant="outlined"
                  size="small"
                  placeholder="Search"
                  value={this.state.searchkey}
                  margin="normal"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {" "}
                        <SearchIcon />{" "}
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    this.onChangeSearchAppName(e);
                  }}
                />
              </Grid>
              <Grid item>
                <FilterComponent
                  getDataByFilter={this.getFilteredData}
                  dataOptions={data_options}
                  filterFunc={this.filterFunc}
                  filterHistory={filterProps.filterArray}
                />
              </Grid>
              <Grid item>
                <Button
                  style={{
                    width: "100%",
                    boxShadow: "none",
                    // padding: "6px 5px 6px 5px",
                  }}
                  variant="outlined"
                  color="primary"
                  size="small"
                  alignItems="center"
                  disabled={!checkPermission("btn56")}
                  onClick={() =>
                    checkPermission("btn56") && this.BulkDataStandard()
                  }
                  startIcon={<BiUpload />}
                >
                  Bulk Upload
                </Button>
              </Grid>
              <Grid item>
                <Button
                  style={{
                    color: "white",
                    width: "100%",
                    boxShadow: "none",
                    // padding: "6px 5px 6px 5px",
                  }}
                  variant="contained"
                  color="primary"
                  size="small"
                  alignItems="center"
                  disabled={!checkPermission("btn54")}
                  onClick={() =>
                    checkPermission("btn54") && this.createDataStandard()
                  }
                  startIcon={<AddIcon />}
                >
                  Create
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12} md={12} xs={12} lg={12} className="mt-2">
            <DexTable
              data={[...data]}
              columnOrder={[
                "name",
                "type",
                "industryType",
                "description",
                "status",
                "createdon",
                "actions",
              ]}
              columns={[
                { name: "name", title: "Name" },
                { name: "type", title: "Type" },
                { name: "industryType", title: "Industry Type" },
                { name: "description", title: "Description" },
                { name: "status", title: "In Use" },
                { name: "createdon", title: "Created On" },
                { name: "actions", title: "Actions" },
              ]}
              columWidthsType="percentage"
              defaultColumnWidths={[
                // { columnName: "name", width: 15 },
                // { columnName: "type", width: 15 },
                // { columnName: "industryType", width: 15 },
                // { columnName: "status", width: 15 },
                // { columnName: "createdon", width: 15 },
                // { columnName: "actions", width: 18 },
                { columnName: "name", width: 20 },
                { columnName: "type", width: 18 },
                { columnName: "industryType", width: 20 },
                { columnName: "description", width: 18 },
                { columnName: "status", width: 18 },
                { columnName: "createdon", width: 18 },
                { columnName: "actions", width: 18 },
              ]}
              formatters={[
                { formatterFn: this.actionProvider, columns: ["actions"] },
              ]}
              sorting={filterProps.sorting}
              onClickRow={(row) => this.viewDsdata(row, info)}
              Read={checkPermission("btn55")}
              onSortingChange={this.handleSortByChange}
              dateColumns={["createdon"]}
              disableReordering={false}
              hidden={[...hidden]}
              setHiddenColmn={(hidden) => {
                this.props.setHiddenColumn(hidden);
              }}
              height="60vh"
              loading={loading}
              tableType="virtual"
            />
          </Grid>
          {data.length !== 0 && (
            <div className={classes.footer}>
              <div className={classes.customPagination}>
                <Pagination
                  count={Math.ceil(totalCount / 10)}
                  page={this.state.page}
                  onChange={this.handlePageChange}
                  shape="rounded"
                  showFirstButton
                  showLastButton
                />
              </div>
            </div>
          )}
          <HelpdeskFAB category="Active Metadata" />
        </Grid>

        <BlockModal
          color="white"
          open={this.state.setOpen}
          yes={`Yes, ${type} it`}
          handleClose={this.handleClose}
          action={this.blockDataStandards}
          text={`Are you sure you want to ${type} ${info.name}`}
          Icon={
            <BlockIcon style={{ fontSize: "50px", marginBottom: "15px" }} />
          }
        />

        <BulkUploadModal
          color="white"
          open={this.state.setOpenupload}
          handleCloseBulk={this.handleBulkClose}
          property={this.state.property}
          postBulkUpload={this.postBulkUpload}
          btn={"Upload and Create Fields"}
        />
      </>
    );
  }
}

DataStandardslist.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    data: state.dataStandards.data,
    loading: state.dataStandards.loading,
    totalCount: state.dataStandards.totalCount,
    filterProps: state.dataStandards.filterProps,
    hidden: state.dataStandards.hidden,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getData: (pageno, size, filters, sort) =>
      dispatch(
        actions.actionfetchAllDataStandards(pageno, size, filters, sort)
      ),
    blockDatatandards: (dataStandardId, type) =>
      dispatch(actions.blockDatatandards(dataStandardId, type)),
    bulkUploadfiles: (payload) => dispatch(bulkUploadfiles(payload)),
    updateFilter: (filter) => dispatch(actions.updateFilterData(filter)),
    updateSorting: (sortProps) => dispatch(actions.updateSort(sortProps)),
    setHiddenColumn: (hidden) =>
      dispatch(actions.updateDataStandardsHiddenCol(hidden)),
  };
};
const DataStandards = connect(
  mapStateToProps,
  mapDispatchToProps
)(DataStandardslist);
export default withRouter(withStyles(Styles)(withSnackbar(DataStandards)));
