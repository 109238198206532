import { DataArray } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import Nestable from "react-nestable";
// import "./styles.css";
import { createNestedMenu, generateFlatMenu, menus } from "./helper";
import { FaChevronRight, FaChevronDown } from "react-icons/fa";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { IconButton, TextField, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { style } from "@mui/system";
import ButtonComponent from "./Button";
const useStyles = makeStyles((theme) => ({
  root: {
    listStyleType: "none",

    ol: { listStyleType: "none" },
    "&ol": {
      listStyleType: "none",
    },
    "nestable nestable.ol.nestable-list nestable-group.nestable-list nestable-group ":
      {
        listStyleType: "none",
      },
    ".ol.nestable ": {
      listStyleType: "none",
    },
  },
}));
const DragComponent = (props) => {
  const [id, setIds] = useState({
    pId: null,
    cId: null,
    index: null,
    state: { key: "" },
  });

  const { handle_submit, onChangeSubSection, addSubSection, parentId } = props;
  console.log("propsssss", props);

  const [state_, setState_] = useState({
    items: [],
    itemsTemp: [],
  });
  const [text, setText] = useState(false);
  const classess = useStyles();

  const {
    classes,
    item,
    index,
    handleClick,
    state,
    handleDynoSectioNameChange,
    onBlur,
    handleSectionClick,
    handleSectionDelete,
    handleSubSectionName,
    createSubSection,
  } = props;
  const { items, itemsTemp } = state_;
  useEffect(() => {
    appendId(props.data || []);
  }, [props.data]);

  const renderTextField = () => {
    let sections = [...(state_.itemsTemp || [])];
    let section = sections[id.index];
    let children = (section.children || []).map((chld, idx) => {
      console.log("section and children", sections, section);
      if (chld.id === id.cId) {
        return {
          ...chld,
          status: true,
        };
      } else {
        return {
          ...chld,
          status: false,
        };
      }
    });
    sections[id.index] = { ...section, children: [...children] };
    console.log("ssseerrr", 4, section, children);
    setState_({
      ...state_,
      itemsTemp: [...sections],
    });
  };

  useEffect(() => {
    if (id.index !== null) {
      console.log("props345678", "id", id.index);
      renderTextField();
    }
  }, [id]);

  const appendId = (data) => {
    console.log("data unda", data);
    let dataArray = [];

    data &&
      data.map((p, sec_index) => {
        let objp = {};
        Object.assign(objp, { id: p._id, parent: 0, name: p.name });
        dataArray.push(objp);

        p.sections &&
          p.sections.map((c, idx) => {
            let objc = {};
            Object.assign(objc, {
              status: false,
              id: c._id,
              parent: p._id,
              name: (
                <div>
                  {/* {id.state.key === "click" ? ( */}
                    <Button
                      key={c._id}
                      onClick={() => {
                        setIds({
                          pId: p._id,

                          cId: c._id,

                          index: sec_index,

                          state: { key: "click" },
                        });
                      }}
                    >
                      {c.name}
                    </Button>
                  {/* ) : (
                    <TextField
                      key={c._id}
                      variant="standard"
                      size="small"
                      fullWidth
                      onChange={(e) => {
                        onChangeSubSection(e.target.value);
                      }}
                    />
                  )} */}
              </div>
                // <ButtonComponent
                //   id={id}
                //   setIds={setIds}
                //   classess={classess}
                //   name={c.name}
                //   cid={c._id}
                //   pid={p._id}
                // />
              ),
            });
            dataArray.push(objc);
            console.log("dataarray", dataArray);
          });
      });
    setState_({
      ...state_,
      itemsTemp: createNestedMenu(dataArray),
    });
  };

  const handleOnChangeSort = (params) => {
    setState_({
      itemsTemp: params.items || [],
    });
  };

  // const changeTextField = (e) => {
  //   e.setText(!text);
  // };
  console.log("props345678", id, props, state_.itemsTemp);
  return (
    <div className={classess.root}>
      <Nestable
        collapsed={true}
        style={{
          listStyleType: "none",
        }}
        className={classess.root}
        maxDepth={3}
        items={state_.itemsTemp}
        renderItem={({ item, collapseIcon }) => (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            {collapseIcon}
            {item.name}
            <div style={{ width: "80%" }}>
              {parentId === item.id && (
                <TextField
                  style={{ marginLeft: 30 }}
                  fullWidth
                  onKeyDown={(e) => {
                    if (e.key !== "Enter") {
                      return;
                    }
                    handle_submit();
                  }}
                  onChange={(e) => onChangeSubSection(e.target.value)}
                  autoFocus
                />
              )}
            </div>

            <div
              style={{
                marginLeft: 10,
                width: "20%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {item.parent == 0 ? (
                <IconButton style={{ borderRadius: "50px" }}>
                  <AddOutlinedIcon
                    onClick={(e) => {
                      setText(!text);
                      addSubSection(item);
                    }}
                    role="button"
                    style={{
                      fontSize: "15px",
                      border: "2px solid #2185d0",
                      borderRadius: "50px",
                      color: "#2185d0",
                    }}
                  />
                </IconButton>
              ) : null}

              {item.children.length == 0 ? (
                <IconButton style={{ borderRadius: "50px" }}>
                  <DeleteOutlinedIcon
                    onClick={(e) => handleSectionDelete(item.id, e)}
                    style={{
                      fontSize: "18px",
                      fontWeight: "800",
                      color: "red",
                      cursor: "pointer",
                    }}
                  />
                </IconButton>
              ) : null}
            </div>
          </div>
        )}
        onChange={handleOnChangeSort}
        renderCollapseIcon={({ isCollapsed }) =>
          isCollapsed ? (
            <IconButton>
              <FaChevronRight
                style={{
                  fontSize: "10px",
                  height: 15,
                  width: 15,
                  marginRight: 10,
                }}
                className={classes.sideBarIcon}
              />
            </IconButton>
          ) : (
            <IconButton>
              <FaChevronDown
                style={{
                  fontSize: "10px",
                  height: 15,
                  width: 15,
                  marginRight: 10,
                }}
                className={classes.sideBarIcon}
              />
            </IconButton>
          )
        }
      />
    </div>
  );
};
export default DragComponent;
// const rootElement = document.getElementById("root");
// ReactDOM.render(<App />, rootElement);
