import React from "react";
import { connect } from "react-redux";
import { addEnvironmentForm, updateEnvironmentForm } from "../redux/action";
import { getApplicationDetails } from "../ApplicationDetails/Redux/action";

import PropTypes from "prop-types";
import { isDuplicate } from "../../../helpers/helpers";
import { withSnackbar } from "notistack";
import APIs from "../../../api/ApplicationAPIs/index";
import {
  Button,
  Grid,
  TextField,
  Typography,
  FormControl,
  FormHelperText,
  FormLabel,
  Divider,
} from "@material-ui/core";
import {
  TextValidator,
  SelectValidator,
  ValidatorForm,
} from "react-material-ui-form-validator";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/styles";
import Styles from "./Style";
import Switch from "@material-ui/core/Switch";
import Breadcrumb from "components/Breadcrumbs/Breadcrumb";
import AddEnvironment from "./AddEnvironment";
import AlertDialog from "./modalEnv";
import { RiEditLine } from "react-icons/ri";

import { checkPermission } from "helpers/permissons";
import HelpdeskFAB from "../../../views/HelpdeskFAB/HelpdeskFab";

class CreateEnvironmentForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      appID: 0,
      count: 0,
      open: false,
      check_name: "",
      check_production: false,
      error: false,
      isEdit: false,
      index: 0,
      environmentData: {
        name: "",
        domainUrl: "",
        isProductionEnvironment: false,
        isHealthCheckEnable: false,
        healthCheckUrl: "",
        method: "POST",
      },
      isDisabled: false,

      // name: [],
    };
  }

  componentDidMount() {
    const { location } = this.props;
    let count = 0;

    if (location.state !== undefined) {
      let { environment_data, edit, ajax, appId, disabled, appName } =
        location.state;

      if (ajax) {
        this.props.environment_state.map((item, id) =>
          item.isProductionEnvironment ? count++ : null
        );
      } else {
        this.props.environments.map((item, id) =>
          item.isProductionEnvironment ? count++ : null
        );
      }

      this.setState({
        environmentData: {
          ...environment_data,
        },
        isEdit: edit,
        check_name: environment_data.name,
        check_production: environment_data.isProductionEnvironment,
        count: count,
        ajax: ajax,
        appID: appId,
        appName: appName,
        isDisabled: disabled,
      });
    }
  }

  handleOnchange = (value) => {
    this.setState({
      environmentData: { ...value },
    });
  };

  add = () => {
    this.setState({
      environmentData: {
        ...this.state.environmentData,
        isProductionEnvironment: true,
      },
      open: false,
    });
  };

  handleClose = () => {
    this.setState({
      environmentData: {
        ...this.state.environmentData,
        isProductionEnvironment: false,
      },
      open: false,
    });
  };

  markAsProductionEnv = (value) => {
    let length = 0;
    if (this.state.ajax) {
      length = this.props.environment_state.length;
    } else {
      length = this.props.environments.length;
    }

    if (
      length > 1 &&
      value.isProductionEnvironment === true &&
      !this.state.check_production &&
      this.state.count !== 0
    ) {
      this.setState({
        open: true,
      });
    } else {
      this.setState({
        environmentData: { ...value },
      });
    }
  };

  multilineAdd = async () => {
    const { isEdit, environmentData } = this.state;
    if (isEdit) {
      let updateEnvironment = [...this.props.environments];
      updateEnvironment = updateEnvironment.map((item, index) => {
        return item.key === environmentData.key
          ? { ...environmentData }
          : { ...item, isProductionEnvironment: false };
      });
      await this.props.edit_environments(updateEnvironment);
      this.props.history.push("/app/onboard-application", { status: true });
    } else {
      await this.props.add_environments(this.state.environmentData);
      this.setState({
        environmentData: {
          name: "",
          domainUrl: "",
          isProductionEnvironment: false,
          isHealthCheckEnable: false,
          healthCheckUrl: "",
          method: environmentData.method,
          key: this.props.environments.length,
        },
        isEdit: false,
      });
    }
  };

  singleObjectSubmit = async () => {
    const { environmentData } = this.state;
    await this.props.add_environments(environmentData);
    this.props.history.push("/app/onboard-application", { status: true });
  };

  onSubmit = async (e) => {
    let flag = false;
    const { environmentData, check_name, isEdit } = this.state;

    if (!isEdit || check_name !== environmentData.name) {
      flag = isDuplicate(this.props.environments, (f) => {
        return f.name.toLowerCase() === environmentData.name.toLowerCase();
      });
    }

    if (flag) {
      this.setState({
        err_msg: "Duplicate environment is not allowed",
        error: true,
      });
      return;
    }

    this.setState({
      err_msg: "",
      error: false,
    });

    if (e.nativeEvent.submitter.name === "multiple") {
      this.multilineAdd();
    }

    if (e.nativeEvent.submitter.name === "single") {
      this.singleObjectSubmit();
    }
  };

  setDuplicateNull = () => {
    this.setState({
      error: false,
      err_msg: "",
    });
  };

  editAppEnvironment = async () => {
    let { _id, key, ...data } = this.state.environmentData;
    try {
      let res = await APIs.editEnvironment(
        this.state.environmentData.appId,
        _id,
        data
      );

      if (res.data) {
        this.props.enqueueSnackbar(
          "Application environments updated successfully",
          {
            variant: "success",
          }
        );
        this.props.history.push("/app/detail", {
          appId: this.state.environmentData.appId,
        });
      }
    } catch (err) {
      this.props.enqueueSnackbar(err.message || "Something Went wrong", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  addAppEnvironment = async () => {
    let { key, ...data } = this.state.environmentData;
    try {
      const res = await APIs.addEnvironment(this.state.appID, data);
      this.props.enqueueSnackbar(
        res.data._msg || "Environment details added successfully",
        { variant: "success", autoHideDuration: 3000 }
      );
    } catch (err) {
      this.props.enqueueSnackbar(
        err?.response?.data?._msg || "Somethi Went wrong",
        {
          variant: "error",
          autoHideDuration: 3000,
        }
      );
      console.error(err);
    }
  };

  ajaxFuncs = async () => {
    let { isEdit } = this.state;
    if (isEdit) {
      this.editAppEnvironment();
    } else {
      await this.addAppEnvironment();
      await this.props.getDetails(this.state.appID);
      this.setState({
        environmentData: {
          name: "",
          domainUrl: "",
          isProductionEnvironment: false,
          isHealthCheckEnable: false,
          healthCheckUrl: "",
          method: "POST",
        },
        isEdit: false,
      });
    }
  };

  singleAjaxFunc = async () => {
    await this.addAppEnvironment();
    this.props.history.push("/app/detail", { appId: this.state.appID });
  };
  // environments
  onSubmitAjax = (e) => {
    const { environmentData, isEdit, check_name } = this.state;
    let flag = false;
    if (!isEdit || check_name !== environmentData.name) {
      flag = isDuplicate(this.props.environment_state, (f) => {
        return f.name.toLowerCase() === environmentData.name.toLowerCase();
      });
    }
    console.log("fgfgfgfgfgfgfgfgfg");
    if (flag) {
      this.setState({
        err_msg: "Duplicate environment is not allowed",
        error: true,
      });
      return;
    }

    this.setState({
      err_msg: "",
      error: false,
    });

    console.log("fgfgfgfgfgfgfgfgfg");

    if (e.nativeEvent.submitter.name === "multiple") {
      this.ajaxFuncs();
    }

    if (e.nativeEvent.submitter.name === "single") {
      this.singleAjaxFunc();
    }
  };

  render() {
    const { classes, row } = this.props;
    const {
      error,
      environmentData,
      isEdit,
      err_msg,
      ajax,
      appID,
      count,
      isDisabled,
      appName,
    } = this.state;
    console.log(environmentData, ajax, count, "envdata");
    // setEnvironment([...updatedEnvironmentHealCheck]);

    return (
      <div>
        {/* {row.map((row, index) => ( */}
        <Grid item sm={12} md={12} xs={12} lg={12}>
          {/* <Breadcrumb
            {...this.props}
            links={[
              { name: "Applications", url: "/app/applications" },
              {
                name: "Onboard Application",
                url: "/app/onboard-application",
              },
              {
                name: "Environment",
                url: "/app/create-env-form",
              },
            ]} */}

          <Breadcrumb
            {...this.props}
            links={[
              { name: "Applications", url: "/app/applications" },
              {
                name: `${ajax ? `${appName}` : "Onboard Application"}`,
                url: `${ajax ? "/app/detail" : "/app/onboard-application"}`,
                routeParams: ajax
                  ? { appId: this.state.appID }
                  : { status: true },
              },
              {
                name: "Environment",
                url: "/app/create-modules-form",
              },
            ]}
          />
        </Grid>
        <ValidatorForm
          autoComplete="off"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              return;
            }
          }}
          onSubmit={(e) => {
            if (ajax) {
              this.onSubmitAjax(e);
            } else {
              this.onSubmit(e);
            }
          }}
        >
          <Grid container spacing={0}>
            <Grid item sm={12} md={12} xs={12} lg={12}>
              <Grid
                container
                spacing={1}
                direction="row"
                alignItems="center"
                className={classes.root}
              >
                <Grid item md={6}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      // style={{ marginBottom: "2px" }}
                      variant="h6"
                      className={classes.title}
                    >
                      Environment
                    </Typography>
                  </div>
                </Grid>
                <Grid
                  item
                  sm={6}
                  container
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    {!isDisabled && (
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() => {
                          if (ajax) {
                            this.props.history.push("/app/detail", {
                              appId: appID,
                            });
                          } else {
                            this.props.history.push(
                              "/app/onboard-application",
                              {
                                status: true,
                              }
                            );
                          }
                        }}
                      >
                        Cancel
                      </Button>
                    )}
                  </Grid>
                  <Grid item>
                    {!isDisabled && (
                      <Button
                        name="multiple"
                        color="primary"
                        size="small"
                        variant={isEdit ? "contained" : "outlined"}
                        type="submit"
                        disabled={
                          isEdit
                            ? !checkPermission("btn95")
                            : !checkPermission("btn94")
                        }
                      >
                        {isEdit ? "Save" : "Create and Add Another"}
                      </Button>
                    )}
                  </Grid>
                  <Grid item>
                    {!isEdit && (
                      <Button
                        name="single"
                        size="small"
                        variant="contained"
                        type="submit"
                        disabled={!checkPermission("btn93")}
                      >
                        {"Create"}
                      </Button>
                    )}
                  </Grid>
                  {isDisabled && (
                    <Button
                      size="small"
                      variant="contained"
                      onClick={() =>
                        this.setState({
                          isDisabled: false,
                        })
                      }
                      disabled={!checkPermission("btn95")}
                      startIcon={<RiEditLine style={{ fontSize: "20px" }} />}
                    >
                      Edit
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12}>
              <Divider className={classes.divider} />
            </Grid>
            <Grid item md={12} style={{ marginTop: "22px" }}>
              <Grid container spacing={2}>
                <Grid item md={3}>
                  <div>
                    <Typography style={{ marginBottom: "2px" }} variant="body2">
                      {" "}
                      Environment Name <span style={{ color: "red" }}>*</span>
                    </Typography>
                    {/* <TextValidator
                      fullWidth
                      size="small"
                      variant="outlined"
                      value={environmentData.name}
                      id="name"
                      onChange={(e) =>
                        this.handleOnchange({
                          ...environmentData,
                          name: e.target.value,
                        })
                      }
                      name="environmentName"
                      validators={["required"]}
                      errorMessages={["Name is required"]}
                      onFocus={this.setDuplicateNull}
                      error={error}
                      helperText={err_msg}
                    /> */}
                    <AddEnvironment
                      environmentData={environmentData}
                      isEdit={isEdit}
                      handleOnchange={this.handleOnchange}
                      error={error}
                      errMsg={err_msg}
                      setDuplicateNull={this.setDuplicateNull}
                      isDisabled={isDisabled}
                    />
                  </div>
                </Grid>
                <Grid item md={3}>
                  <div style={{ width: "100%" }}>
                    <Typography style={{ marginBottom: "2px" }} variant="body2">
                      Domain URL <span style={{ color: "red" }}>*</span>{" "}
                    </Typography>
                    <TextValidator
                      disabled={isDisabled}
                      fullWidth
                      size="small"
                      variant="outlined"
                      value={environmentData.domainUrl}
                      id="email"
                      onChange={(e) =>
                        this.handleOnchange({
                          ...environmentData,
                          domainUrl: e.target.value.trimStart(),
                        })
                      }
                      name="domainUrl"
                      validators={["required"]}
                      errorMessages={["URL is required"]}
                      onFocus={this.setDuplicateNull}
                    />
                  </div>
                </Grid>

                <Grid item md={3}>
                  <FormControl className={classes.radioGroup}>
                    <FormLabel
                      style={{
                        marginBottom: "2px",
                        paddingLeft: "30px",
                        paddingTop: "10px",
                      }}
                    >
                      Mark as production environment
                    </FormLabel>
                    <RadioGroup
                      className={classes.radio}
                      value={
                        environmentData.isProductionEnvironment ? "Yes" : "No"
                      }
                      onChange={(e) =>
                        this.markAsProductionEnv({
                          ...environmentData,
                          isProductionEnvironment:
                            e.target.value === "Yes" ? true : false,
                        })
                      }
                    >
                      <div className="d-flex">
                        <div>
                          <FormControlLabel
                            disabled={isDisabled}
                            name="isProductionEnvironment"
                            value={"Yes"}
                            control={<Radio />}
                            label="Yes"
                          />
                        </div>
                        <div>
                          <FormControlLabel
                            disabled={isDisabled}
                            name="isProductionEnvironment"
                            value={"No"}
                            control={<Radio />}
                            label="No"
                          />
                        </div>
                      </div>
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={12} style={{ marginTop: 15 }}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <div className="d-flex flex-row align-center-center">
                    <Typography
                      className="pr-2 "
                      style={{ marginBottom: "2px", paddingTop: "8px" }}
                    >
                      Enable health check{" "}
                    </Typography>
                    <Switch
                      disabled={isDisabled}
                      color="primary"
                      inputProps={{ "aria-label": "primary checkbox" }}
                      value={environmentData.isHealthCheckEnable}
                      checked={environmentData.isHealthCheckEnable}
                      onChange={(e) =>
                        this.handleOnchange({
                          ...environmentData,
                          isHealthCheckEnable: e.target.checked,
                        })
                      }
                    />
                  </div>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  {environmentData.isHealthCheckEnable && (
                    <div>
                      <Typography style={{ marginBottom: "2px" }}>
                        Health check URL <span style={{ color: "red" }}>*</span>{" "}
                      </Typography>
                      <TextValidator
                        disabled={isDisabled}
                        autoComplete="off"
                        size="small"
                        variant="outlined"
                        fullWidth
                        id="number"
                        name="healthCheckUrl"
                        value={environmentData.healthCheckUrl}
                        onChange={(e) =>
                          this.handleOnchange({
                            ...environmentData,
                            healthCheckUrl: e.target.value,
                          })
                        }
                        validators={["required"]}
                        errorMessages={["Please enter health check url"]}
                      />
                    </div>
                  )}
                </Grid>

                <Grid container xs={12} sm={6} md={4}>
                  {environmentData.isHealthCheckEnable && (
                    <FormControl className={classes.radioGroup}>
                      <FormLabel
                        style={{
                          marginBottom: "2px",
                          paddingLeft: "37px",
                          paddingTop: "20px",
                        }}
                      >
                        {" "}
                        Method
                      </FormLabel>
                      <RadioGroup
                        className={classes.radioTwo}
                        aria-label="method"
                        name="environmentMethod"
                        onChange={(e) =>
                          this.handleOnchange({
                            ...environmentData,
                            method: e.target.value,
                          })
                        }
                        value={environmentData.method}
                      >
                        <div className="d-flex">
                          <div>
                            <FormControlLabel
                              disabled={isDisabled}
                              name="environmentPostMethod"
                              value="POST"
                              control={<Radio />}
                              label="Post"
                            />
                          </div>
                          <div>
                            <FormControlLabel
                              disabled={isDisabled}
                              name="environmentGetMethod"
                              value="GET"
                              control={<Radio />}
                              label="Get"
                            />
                          </div>
                        </div>
                      </RadioGroup>
                    </FormControl>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <HelpdeskFAB />
          </Grid>
        </ValidatorForm>
        <div>
          <AlertDialog
            open={this.state.open}
            add={this.add}
            handleClose={this.handleClose}
          />
        </div>
        {/* ))} */}
      </div>
    );
  }
}

CreateEnvironmentForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    environments: state.application.onBoard.environments,
    environment_state:
      state.appDetailsRedcer.applicationDetails.environments || [],
  };
};
const mapDispatchToProps = (dispatch) => ({
  getDetails: (id) => dispatch(getApplicationDetails(id)),
  add_environments: (data) => dispatch(addEnvironmentForm(data)),
  edit_environments: (data) => dispatch(updateEnvironmentForm(data)),
});
const CreateEnvironment = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateEnvironmentForm);

export default withStyles(Styles)(withSnackbar(CreateEnvironment));
