export const FETCHING_USER_MANAGEMENT = "FETCH_USER_MANAGEMENT";
export const FETCHING_USER_MANAGEMENT_SUCCESS =
  "FETCHING_USER_MANAGEMENT_SUCCESS";
export const FETCHING_USER_MANAGEMENT_FAILURE =
  "FETCHING_USER_MANAGEMENT_FAILURE";

export const FETCHING_USER_MANAGEMENT_ROLES = "FETCHING_USER_MANAGEMENT_ROLES";
export const FETCHING_USER_MANAGEMENT_ROLES_SUCCESS =
  "FETCHING_USER_MANAGEMENT_ROLES_SUCCESS";
export const FETCHING_USER_MANAGEMENT_ROLES_FAILURE =
  "FETCHING_USER_MANAGEMENT_ROLES_FAILURE";

export const ADD_USER_MANAGEMENT = "ADD_USER_MANAGEMENT";
export const ADD_USER_MANAGEMENT_SUCCESS = "ADD_USER_MANAGEMENT_SUCCESS";
export const ADD_USER_MANAGEMENT_FAILURE = "ADD_USER_MANAGEMENT_FAILURE";

export const EDIT_USER_MANAGEMENT = "EDIT_USER_MANAGEMENT";
export const EDIT_USER_MANAGEMENT_SUCCESS = "EDIT_USER_MANAGEMENT_SUCCESS";
export const EDIT_USER_MANAGEMENT_FAILURE = "EDIT_USER_MANAGEMENT_FAILURE";

export const DELETE_USER_MANAGEMENT = "DELETE_USER_MANAGEMENT";
export const DELETE_USER_MANAGEMENT_SUCCESS = "DELETE_USER_MANAGEMENT_SUCCESS";
export const DELETE_USER_MANAGEMENT_FAILURE = "DELETE_USER_MANAGEMENT_FAILURE";

export const BLOCK_USER_MANAGEMENT = "BLOCK_USER_MANAGEMENT";
export const BLOCK_USER_MANAGEMENT_SUCCESS = "BLOCK_USER_MANAGEMENT_SUCCESS";
export const BLOCK_USER_MANAGEMENT_FAILURE = "BLOCK_USER_MANAGEMENT_FAILURE";

export const LOAD_LOADING = "LOAD_LOADING";

export const UPDATE_USERS_FILTER_DATA = "UPDATE_USERS_FILTER_DATA";
export const CLEAR_USERS_FILTER_DATA = "CLEAR_USERS_FILTER_DATA";

export const UPDATE_USERS_SORT = "UPDATE_USERS_SORT";

export const USER_STORE_SEARCHED_VALUE = "USER_STORE_SEARCHED_VALUE";
export const USER_FETCH_SEARCHED_VALUE = "USER_FETCH_SEARCHED_VALUE";

export const UPDATE_HIDDEN_COL = "UPDATE_HIDDEN_COL";
