import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator";
import { CustomNumberFeild } from "../../components/Customized/index";
import { Select, FormControl, Typography, FormGroup } from "@material-ui/core/";
const useStyles = makeStyles((theme) => ({
  root: {
    border: "none",
  },
  rootTwo: {
    padding: 0,
    display: "flex",
    alignItems: "center",
    border: "solid 1px #D7D7D7",
    borderRadius: 3,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  phoneNum: {
    borderTopLeftRadius: "0px",
    borderBottomLeftRadius: "0px",
  },
  codeNum: { borderTopRightRadius: "0px", borderBottomRightRadius: "0px" },
}));

const styles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(0),
    width: 120,
  },
  phoneNum: {
    borderTopLeftRadius: "0px",
    borderBottomLeftRadius: "0px",
  },
  codeNum: { borderTopRightRadius: "0px", borderBottomRightRadius: "0px" },
  root: {
    ".MuiFormControl-root": {
      border: "none",
    },
  },
  select: {
    "&:before": {
      borderColor: "blue",
    },
    "&:after": {
      borderColor: "blue",
    },
  },
}));

const SelectComponentNative = (props) => {
  const {
    handleChangeCountryCode,
    code,
    disabled,
    countryCode,
    defaultCountryCode,
  } = props;
  const classes = styles();
  const defaultVal = countryCode.filter((abc) => abc.code === "+65");
  console.log("fsgcfgsafgfhffsa", code, defaultVal);
  return (
    <SelectValidator
      style={{
        borderRadius: "0px",
        width: "100%",
      }}
      disabled={disabled}
      value={code ? code : defaultVal.map((item) => item.code)}
      variant="outlined"
      size="small"
      SelectProps={{
        native: true,
      }}
      InputProps={{
        classes: {
          notchedOutline: classes.codeNum,
        },
      }}
      onChange={handleChangeCountryCode}
    >
      {countryCode.map((item, id) => (
        <option key={id} value={item.code}>
          {item.code}&nbsp; {item.name}
        </option>
      ))}
    </SelectValidator>
  );
};

const PhoneInputComponent = (props) => {
  const { handleChangeNumber, disabled, required, text, minNumber } = props;
  const classes = useStyles();

  return (
    <div style={{ width: "100%" }}>
      {/* <Typography style={{ marginBottom: "2px" }} variant="body2">
        {text} {required && <span style={{ color: "red" }}>*</span>}
      </Typography>
      <div component="form" className={classes.rootTwo}>
        <div>
          <SelectComponentNative {...props} />
        </div>
        <div style={{ width: "100%" }}>
          <TextValidator
            disabled={disabled}
            InputProps={{
              classes: {
                notchedOutline: classes.root,
              },
            }}
            name="phone"
            type="number"
            id="phone"
            size="small"
            variant="outlined"
            fullWidth
            value={numValue}
            onChange={handleChangeNumber}
            validators={["required"]}
            errorMessages={["Please enter number"]}
          />
        </div>
      </div>
      <div style={{ height: "12px" }}></div> */}
      <Typography style={{ marginBottom: "2px" }} variant="body2">
        {text} {required && <span style={{ color: "red" }}>*</span>}
      </Typography>
      <FormGroup row style={{ width: "100%" }}>
        <FormControl
          style={{
            width: "40%",
            borderRadius: "0px",
          }}
        >
          <SelectComponentNative {...props} />
        </FormControl>
        <div style={{ width: "60%" }}>
          <CustomNumberFeild
            disabled={disabled}
            style={{ width: "100%" }}
            fullWidth
            variant="outlined"
            placeholder="81476497"
            type="number"
            value={props.numValue || ""}
            size="small"
            onChange={handleChangeNumber}
            validators={[
              "required",
              `minStringLength:${
                props.numValue.trim().length === 0 ? 0 : minNumber
              }`,
            ]}
            InputProps={{
              classes: {
                notchedOutline: classes.phoneNum,
              },
            }}
            errorMessages={[
              "Phone number can not be empty",
              "Minimum number should be 8",
            ]}
            className="phonenumberField"
          />
        </div>
      </FormGroup>
      {/* {error && (
        <div>
          <Typography style={{ color: "#f44336", fontSize: "0.75rem" }}>
            {errMsg}
          </Typography>
        </div>
      )} */}
    </div>
  );
};
export default PhoneInputComponent;

// <FormControl
//   disabled={disabled}
//   size="small"
//   className={classes.formControl}
// >
//   <Select
//     disableUnderline
//     style={{ paddingLeft: "2px", width: "125px" }}
//     native
//     value={code ? code : defaultVal.map((item) => item.code)}
//     onChange={handleChangeCountryCode}
//     inputProps={{
//       name: "country-code",
//       id: "outlined-country-code-native-simple",
//     }}
//   >
//     {countryCode.map((item, id) => (
//       <option key={id} value={item.code}>
//         {item.code}&nbsp; {item.name}
//       </option>
//     ))}
//   </Select>
// </FormControl>
