import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  main: {
    marginTop: "4rem",

    "& .MuiContainer-root": {
      paddingLeft: 30,
      paddingRight: 30,
    },
  },
  topContent: {
    position: "fixed",
    top: "4rem",
    left: 0,
    padding: "0 15px 0 15px",
    zIndex: 1000,
    width: "100%",
    // backgroundColor: theme.palette.background.default,
    backgroundColor: "#f2f2f2",
    // border: "1px solid",
  },
  glossaryHr: {
    // marginLeft: "1rem",
    margin: "0px 0px 10px 0",
    backgroundColor: "#002855",
    height: 2,
  },
  wrapper: {
    position: "relative",
    top: "6.8rem",
    margin: "0 30px",
  },
  border_line: {
    border: "1px solid #002855",
    opacity: 0.4,
    width: "100%",
  },

  title: {
    font: "normal normal bold 21px/28px Nunito",
    letterSpacing: 0,
    color: "#161616",
    opacity: 1,
  },
  input: {
    // width: "100%",
    // color: "rgba(0, 0, 0, 0.9)",
    // background: "#FFFFFF 0% 0% no-repeat padding-box",
    // font: "normal normal normal 12px/16px Nunito",
    // border: "1px solid #DFDFDF",
    // borderRadius: "5px",
    // height: "38px",
    // marginTop: "5px",
    // padding: "12px",
    // "&:active, &:focus-visible": {
    //   border: "1px solid #DFDFDF",
    //   "&::placeholder": {
    //     color: "#707070 !important",
    //   },
    // },
    // "&:placeholder-shown": {
    //   "&.emptyField": {
    //     border: `1px solid #E22626`,
    //     "&::placeholder": {
    //       color: `#E22626`,
    //       opacity: 1,
    //     },
    //   },
    // },
    // "&.sameField": {
    //   border: `1px solid #E22626 !important`,
    // },
    // "&+p": {
    //   bottom: "-18px",
    //   position: "absolute",
    //   color: `#E22626`,
    //   font: "normal normal 600 12px/16px Nunito",
    //   opacity: 0.9,
    // },
  },
  inputstyle: {
    width: "100%",
    color: "rgba(0, 0, 0, 0.9)",
    background: "none",
    font: "normal normal normal 13px/16px Nunito",
    // border: "1px solid #DFDFDF",
    border: `0.5px solid #b9b9b9`,
    borderRadius: "5px",
    height: "38px",
    padding: "12px",
    "&:active, &:focus": {
      border: `0.5px solid ${theme.palette.primary.main}`,
      "&::placeholder": {
        color: `${theme.palette.primary.main}!important`,
      },
    },
    "&:placeholder-shown": {
      "&.emptyField": {
        border: `0.5px solid ${theme.palette.primary.main}`,
        "&::placeholder": {
          // color: `#E22626`,
          opacity: 1,
        },
      },
    },
    "&.sameField": {
      border: `0.5px solid ${theme.palette.primary.main}!important`,
    },
  },

  textareastyle: {
    width: "100%",
    color: "rgba(0, 0, 0, 0.9)",
    font: "normal normal normal 13px/16px Nunito",
    // background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: `0.5px solid #b9b9b9`,
    background: "none",
    borderRadius: "5px",
    height: "68%",
    resize: "none",
    padding: "10px 15px",
    "&:active, &:focus-visible": {
      border: `0.5px solid ${theme.palette.primary.main}`,
      "&::placeholder": {
        // color: "red !important",
      },
    },
    "&:placeholder-shown": {
      "&.emptyField": {
        border: `1px solid #E22626`,
        "&::placeholder": {
          color: `#E22626`,
          opacity: 1,
        },
      },
    },
  },

  textarea: {
    width: "100%",
    color: "rgba(0, 0, 0, 0.9)",
    font: "normal normal normal 13px/16px Nunito",
    // background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: `0.5px solid ${theme.palette.primary.main}`,
    borderRadius: "5px",
    height: "80%",
    marginTop: "5px",
    resize: "none",
    padding: "10px 15px",
    "&:active, &:focus-visible": {
      border: "1px solid #DFDFDF",
      "&::placeholder": {
        color: "#707070 !important",
      },
    },
    "&:placeholder-shown": {
      "&.emptyField": {
        border: `1px solid #E22626`,
        "&::placeholder": {
          color: `#E22626`,
          opacity: 1,
        },
      },
    },
  },
  // wrapper: {
  //   position: "relative",
  //   top: "3rem",
  // },
  heading: {
    font: "normal normal normal 18px/24px Nunito Sans",
    color: "#161616",
    opacity: 1,
  },
  fieldHeading: {
    font: "normal normal 600 16px/22px Nunito",
    fontSize: 13,
    "& span": {
      color: "#E22626",
    },
  },
  fieldHeading2: {
    font: "normal normal 600 12px/16px Nunito",
    letterSpacing: 0,
    color: "#000000",
    width: "100%",
    // marginBottom: 5
  },
  box: {
    display: "flex",
    // marginBottom: 40,
    width: "100%",
  },

  editMode: {
    background: "#f5f5f5 0% 0% no-repeat padding-box !important",
    borderBottom: "1px solid #DFDFDF",
  },
  error: {
    border: "1px solid #E22626 !important",
  },
  errorSearch: {
    borderBottom: "1px solid #E22626 !important",
  },

  // Teams
  fieldItem: {
    marginTop: "2rem",
    marginBottom: "2rem",
  },
  teamheading: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  customers: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "15%",
  },
  searchInput: {
    width: "50%",
  },
  searchInput2: {
    width: 400,
    // height: 37,
    border: "1px solid #DEDEDF",
    borderRadius: 5,
    background: "#FFFFFF 0% 0% no-repeat padding-box",

    "&:hover::before": {
      borderBottom: "unset",
      transition: "unset",
    },

    "&::before": {
      borderBottom: "unset",
      transition: "unset",
    },

    "& input": {
      padding: "unset",
    },
  },
  section: {
    marginTop: "2rem",
  },
  paper: {
    // margin: '10px 0',
    padding: "8px 15px",
    boxShadow: "0px 3px 6px #3C3C3C1A",
    borderRadius: "10px",
  },
  teamTab: {
    marginBottom: "1rem",

    "& button": {
      minWidth: 100,
    },
    "& span": {
      fontSize: 16,
      textTransform: "capitalize",
    },
  },
  teamMemberPaper: {
    borderRadius: 0,
    padding: "10px 15px",
    boxShadow: "0px 3px 6px #3C3C3C1A",
    textAlign: "center",

    "& img": {
      height: 60,
      width: 60,
      marginBottom: 15,
    },
  },
  teamWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: 10,
  },
  teamMember: {
    marginLeft: 20,
  },
  teamMemberName: {
    fontSize: 14,
    fontWeight: "600",
  },
  teamMemberDesgination: {
    fontSize: 12,
    marginTop: 5,
    opacity: 0.8,
    textAlign: "left",
  },
  teamCheckbox: {
    padding: "5px 5px 5px 10px",
  },
  technologyCheck: {
    display: "flex",
    alignItems: "center",
  },
  border: {
    background: "#002855 0% 0% no-repeat padding-box",
    border: "1px solid #002855",
    opacity: 0.4,
    height: 2,
  },
  spaceBetweenText: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    "& p": {
      fontSize: 14,
    },
  },

  // Details screen teams section
  singlecard: {
    height: 200,
    width: 200,
  },

  //assign
  assign: {
    margin: "20px 20px",
  },
  serviceLine1: {
    marginBottom: 15,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "100%",
    // border : "1px solid"
  },
  dropdowns: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    marginLeft: 20,
    marginRight: 20,
    marginTop: 10,
  },

  formControl: {
    width: 204,
    height: 55,
    backgroundColor: "#fff",
    // border: "1px solid #DEDEDF",
    borderRadius: 5,
  },

  //   table
  myTable: {
    marginTop: "5rem",
    height: 340,
    boxShadow: "none",
  },
  head: {
    backgroundColor: "#F5F7FA",
  },

  submitbuttonwrapper: {
    marginTop: "1rem",
    marginBottom: "1rem",
    display: "flex",
    justifyContent: "flex-end",
    // marginRight: 30,
    // backgroundColor: "#F5F7FA",
  },

  submitContainer: {
    marginTop: "1rem",
  },

  footer: {
    position: "fixed",
    bottom: 0,
    left: 0,
    zIndex: 999,
    textAlign: "right",
    width: "100%",
    padding: 20,
    backgroundColor: "#f2f2f2",
  },
  // Teams
  addTeamMember: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "1300px",
    marginTop: 10,
  },
  addTeamModal: {
    minWidth: "85px",
    height: "35px",
    display: "flex",
    padding: "0 10px",
    justifyContent: "space-around",
    alignItems: "center",
    font: "normal normal 600 12px/16px Nunito Sans",
    color: theme.palette.primary.main,
    opacity: 1,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 5,
    cursor: "pointer",
  },
  // Modal
  selectMemebrModal: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #A8A8A8",
    opacity: 1,
    padding: "5px 0",
    borderRadius: 0,
  },
  memberDeatils2: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "75%",
    paddingLeft: "1rem",
  },
  cancel: {
    background: "#F9FAFD 0% 0% no-repeat padding-box",
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 5,
    opacity: 1,
    color: theme.palette.primary.main,
  },
  submitMember: {
    background: "#052855 0% 0% no-repeat padding-box",
    backgroundColor: theme.palette.primary.main,
    borderRadius: 5,
    opacity: 1,
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      background: "#052855 0% 0% no-repeat padding-box",
    },
  },
  assignProject: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 60,
  },
  input2: {
    width: "100%",
    background: "#f5f5f5 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    height: "38px",
    padding: "0 15px",
    marginTop: 5,
    marginBottom: 5,
    outline: "none",

    "&::before": {
      borderBottom: "unset",
    },
  },
  textarea2: {
    width: "100%",
    background: "#f5f5f5 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    height: "80%",
    marginTop: "5px",
    resize: "none",
    padding: "10px 15px",
  },
  // Details Screen
  teamWrapper2: {
    display: "flex",
    alignItems: "center",

    "& img": {
      width: 40,
      height: 40,
    },
  },
  teamMemberswrapper: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #CBCBCB",
    padding: "15px",
    borderRadius: 5,
  },
  memberDeatils: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    paddingLeft: "1rem",
  },
  deleteMember: {
    paddingLeft: "2rem",
    "& span": {
      visibility: "hidden",
    },
  },

  // Conatiner management
  addordelete: {
    padding: "5px 0 0 5px",
    fontSize: "15px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "5%",
  },
  fieldPanel2: {
    font: "normal normal normal 21px/28px Nunito Sans",
    letterSpacing: 0,
    color: "#161616",
    fontWeight: 700,
  },
  fieldPanel: {
    marginBottom: "1rem",
    // marginTop: "1rem",
    boxShadow: "unset",
    border: "unset",
    backgroundColor: "transparent",

    "&:before": {
      backgroundColor: "unset",
    },

    "& .MuiAccordionSummary-root": {
      minHeight: 48,
      padding: 0,
      borderBottom: "2px solid #afafaf",

      "& .MuiAccordionSummary-content.Mui-expanded": {
        margin: 0,
      },

      "& .MuiIconButton-root": {
        padding: "0px 12px",

        "& .MuiTouchRipple-root": {
          display: "none",
        },
      },
    },

    "& .MuiAccordionDetails-root": {
      display: "block",
      padding: 0,
    },
  },
  accordianIcon: {
    fontSize: "3rem",
    paddingTop: 0,
    paddingBottom: 0,
    color: "#000000",
  },
  fieldHeading2: {
    font: "normal normal 600 16px/22px Nunito",
    letterSpacing: 0,
    color: "#000000",
    opacity: 1,
  },
  select: {
    width: "100%",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    height: "38px",
    marginTop: 5,

    "&::before": {
      borderBottom: "unset !important",
    },

    "& .MuiSelect-select": {
      paddingLeft: 15,
    },
  },
  dropdown: {
    height: 38,
    width: 307,
    border: "1px solid #DFDFDF",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    borderRadius: 5,
    "& option": {
      padding: 10,
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  documentDropperWrapper: {
    marginTop: "1.5rem",
    marginBottom: "1.5rem",

    "& .documentDropperClass": {
      width: "20rem",
      minHeight: "100px",
      padding: "1rem 3rem",
      border: "2px solid rgba(0, 40, 85, 1)",
      borderRadius: 15,

      "& .MuiDropzoneArea-textContainer": {
        display: "flex",
        flexDirection: "column-reverse",
        alignItems: "center",
        justifyContent: "space-around",

        "& .documentDropperTextClass": {
          fontSize: "1rem",
          color: "rgba(0, 0, 0, 0.8)",
        },
      },
    },
  },
  documentDropperWrapper2: {
    marginTop: "0.5rem",
    marginBottom: "1.5rem",

    "& .documentDropperClass": {
      width: "15rem",
      padding: "5px",
      border: "2px solid rgba(0, 40, 85, 1)",
      borderRadius: 15,
      minHeight: "125px !important",

      "& .MuiDropzoneArea-textContainer": {
        display: "flex",
        flexDirection: "column-reverse",
        alignItems: "center",
        justifyContent: "space-around",

        "& .documentDropperTextClass": {
          fontSize: "1rem",
          color: "rgba(0, 0, 0, 0.8)",
        },
      },
    },
  },

  // users
  profilepicwrapper: {
    width: 171,
    display: "flex",
    // justifyContent: "space-around",
    alignItems: "center",
    // border: "1px solid",
  },
  symbol: {
    height: 73,
    width: 73,
    display: "flex",
    justifyContent: "center",
    background: "#EDEDED",
    alignItems: "center",
    borderRadius: "50%",
  },
  profpicupload: {
    // width: 150,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: "#031CCA",
  },
  roles: {
    marginTop: 500,
    width: 230,
    height: 30,
    font: "normal normal 600 14px/20px Nunito",
    border: "1px solid #707070",
    borderRadius: 15,
    textAlign: "center",
    padding: 5,
  },
  customTable: {
    "& thead": {
      backgroundColor: theme.palette.primary.main,
      border: "1px solid #E2E2E3",

      "& tr th": {
        color: "#FFFFFF",
        padding: "8px 10px",

        "& span": {
          color: "#FFFFFF",
        },
      },
    },
  },
  // Application Governanace
  doc_container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  group1: {
    display: "flex",
    flexDirection: "column",
    marginLeft: 20,
  },
  label: {
    font: "normal normal normal 12px/16px Nunito",
    color: "#000000",
  },
  value: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: 5,
    width: 240,
    height: 30,
    marginTop: 5,
  },
  icon: {
    height: 34,
    width: 31,
    color: "#000000",
  },

  //Glossary List
  submitBtnBorderTop: {
    borderTop: "1px solid #C0C0C0",
    paddingTop: "20px",
    marginTop: "18rem",
  },
  createAddAnotherBtn: {
    marginLeft: "20px",
    textTransform: "inherit",
  },
  capitalizeBtn: {
    textTransform: "capitalize",
  },
  error: {
    border: "1px solid #E22626 !important",
  },
  red: {
    color: "#E22626",
  },
  errorAuto: {
    "& .MuiAutocomplete-inputRoot::before": {
      borderBottom: "2px solid #E22626",
    },
    marginBottom: 5,
  },
  errorMsg: {
    color: "#DF0E0E",
    // font: "normal normal 600 12px/16px Nunito;",
    margin: "4px 14px 0px",
    fontSize: "12px",
    paddingTop: 2,
  },
  submitButtons: {
    "& .MuiDialogActions-root": {
      justifyContent: "center !important",
    },
  },
}));
