import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { TextValidator } from "react-material-ui-form-validator";
import { isDuplicate } from "helpers/helpers";

const filter = createFilterOptions();

export default function AddEnvironment(props) {
  const { environmentData, isEdit } = props;
  const [value, setValue] = useState(null);
  useEffect(() => {
    setValue(environmentData.name);
  }, [environmentData]);
  const [environmets_data, setEnvironemntsData] = useState([
    { name: "Production" },
    { name: "Testing" },
    { name: "Development" },
    { name: "Cloud" },
  ]);
  console.log(environmets_data, "added");
  const selectValue = (val) => {
    if (val) {
      let data = [...environmets_data];
      let flag = isDuplicate(environmets_data, (f) => f.name === val);
      if (!flag) {
        data.push({ name: val });
      }
      setEnvironemntsData([...data]);
      props.handleOnchange({ ...props.environmentData, name: val });
    } else {
      props.handleOnchange({ ...props.environmentData, name: "" });
    }
  };

  return (
    <Autocomplete
      disabled={props.isDisabled}
      fullWidth
      style={{ width: "100%" }}
      size="small"
      value={value}
      onChange={(event, newValue) => {
        if (typeof newValue === "string") {
          selectValue(newValue);
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          //   setValue({
          //     name: newValue.inputValue,
          //   });
          selectValue(newValue.inputValue);
        } else {
          selectValue(newValue ? newValue.name : null);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        // Suggest the creation of a new value
        if (params.inputValue !== "") {
          filtered.push({
            inputValue: params.inputValue,
            name: `Add "${params.inputValue}"`,
          });
        }

        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="free-solo-with-text-demo"
      options={environmets_data}
      getOptionLabel={(option) => {
        console.log(option, "option");
        // Value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.name;
      }}
      renderOption={(option) => option.name}
      freeSolo
      renderInput={(params) => (
        <TextValidator
          {...params}
          value={value}
          variant="outlined"
          validators={["required"]}
          errorMessages={["Name is Required"]}
          error={props.error}
          helperText={props.errMsg}
          onFocus={props.setDuplicateNull}
        />
      )}
    />
  );
}
