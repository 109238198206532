import React, { Component } from "react";
import { BiUpload } from "react-icons/bi";

import {
  Typography,
  Grid,
  Button,
  Divider,
  TextField,
  FormControl,
  Select,
  IconButton,
  Tooltip,
  Card,
} from "@material-ui/core/";
import Breadcrumb from "components/Breadcrumbs/Breadcrumb";
import {
  getAllUsers,
  AddDatastandards,
  editDataStandards,
  deleteValueMap,
  loadingDispatch,
} from "./redux/action";
import APIs from "../../api/DataStandardsAPIs/index";
import API from "../../api/ApplicationAPIs/index";
import { connect } from "react-redux";
import helpers from "../../helpers/helpers";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator";
import { withSnackbar } from "notistack";
import moment from "moment";
import FieldRules from "./components/FieldRules";
import PropTypes from "prop-types";
import Styles from "./Styles.js";
import { withStyles } from "@material-ui/styles";
import { fetchAllValueMapData, getDatastandardsById } from "./redux/action";

import { RiEditLine } from "react-icons/ri";
import ValueMapTable from "./components/ValueMapTable";
import Loader from "../../components/common/stuff/Loader";
import { SportsVolleyballTwoTone } from "@material-ui/icons";
import { checkPermission } from "helpers/permissons";
import HelpdeskFAB from "../../views/HelpdeskFAB/HelpdeskFab";
import BulkUploadComponent from "../../components/UploadComponent/index";
import BulkUploadSampleCsv from "../../assets/files/BulkValue.csv";
import Modal from "../Application/Components/Modal";
import { IndustryTypesAutoComplete } from "./components/AutoComplete";
const filter = createFilterOptions();

class CreateDataStandards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ID: localStorage.getItem("dataStandardId"),
      error: false,
      isEdit: false,
      ajax: false,
      err_msg: "",
      index: 0,
      dataStandards: {
        name: "",
        type: "",
        description: "",
        rules: {},
        industryType: "",
        value: null,
      },
      industryTypes: [],
      name: "",
      strval: {},
      intval: {},
      numval: {},
      isDisabled: false,
      dataStandardId: 0,
      invalidData: false,
      property: { src: BulkUploadSampleCsv },
      openModal: false,
      read_value: null,
      open: false,
    };
  }

  fetchIndustry_type = async () => {
    try {
      const data = await API.fetchIndustryType();
      this.setState({
        industryTypes: data,
      });
    } catch (err) {
      this.setState({
        industryTypes: [],
      });
    }
  };

  async componentDidMount() {
    const { location } = this.props;

    this.props.setLoading(true);
    if (location.state !== undefined) {
      let { data_Standards, edit, disabled } = location.state;
      this.setState({
        isEdit: edit,
        isDisabled: data_Standards.status === "inactive" ? true : disabled,
      });
      edit && this.prePopulateData(data_Standards._id);
      edit && this.props.getDataStandardsValue(data_Standards.name);
    } else {
      this.props.history.push("/app/data_standards");
    }

    this.props.setLoading(false);
    await this.fetchIndustry_type();
  }

  prePopulateData = async (id) => {
    let res = await this.props.fetchDatastandardsById(id);
    if (res.type === "string") {
      this.setState({
        strval: { ...res.rules },
      });
    }
    if (res.type === "integer") {
      this.setState({
        intval: { ...res.rules },
      });
    }
    if (res.type === "number") {
      this.setState({
        numval: { ...res.rules },
      });
    }
    this.setState({
      dataStandards: {
        ...this.state.dataStandards,
        ...res,
      },
      dataStandardId: id,
    });
  };

  filterdata = () => {
    let { dataStandards, strval, intval, numval } = this.state;
    let payload = { ...dataStandards };
    if (dataStandards.type === "string") {
      payload.rules = { ...strval };
    } else if (dataStandards.type === "integer") {
      payload.rules = { ...intval };
    } else if (dataStandards.type === "number") {
      payload.rules = { ...numval };
    } else {
      delete payload["rules"];
    }
    return payload;
  };

  editData = async () => {
    let { _id, ...data } = this.filterdata();
    this.props.setLoading(true);
    try {
      const res = await APIs.editDataStandards(_id, data);
      this.props.enqueueSnackbar(
        res.data._msg || "Field details updated successfully",
        {
          variant: "success",
          autoHideDuration: 2000,
        }
      );
      this.props.setLoading(false);
      this.props.history.push("/app/data_standards");
    } catch (err) {
      let { _msg } = err?.response?.data || { _msg: "Connection error!" };
      if (_msg === "Invalid Json") {
        let msg = err.response.data.data[0];
        this.display_msg(false, msg);
      } else {
        this.display_msg(false, _msg);
      }
      this.props.setLoading(false);
    }
  };

  display_msg = (type, msg) => {
    this.props.enqueueSnackbar(msg, {
      variant: type ? "success" : "error",
      autoHideDuration: 2000,
    });
  };

  addData = async (type) => {
    let payload = this.filterdata();
    this.props.setLoading(true);
    try {
      const res = await APIs.AddDatastandards(payload);
      this.display_msg(true, res.data._msg);
      this.props.setLoading(false);
      if (type && res.data) {
        this.props.setLoading(false);
        this.props.history.push("/app/data_standards");
      } else {
        this.setState({
          dataStandards: {
            name: "",
            type: "",
            description: "",
            rules: {},
            industryType: "",
          },
          autoCompleteVal: null,
          isEdit: false,
        });
        this.props.setLoading(false);
      }
    } catch (err) {
      let { _msg } = err?.response?.data || { _msg: "Connection error!" };
      if (_msg === "Invalid Json") {
        let msg = err.response.data.data[0];
        this.display_msg(false, msg);
      } else {
        this.display_msg(false, _msg);
      }
      this.props.setLoading(false);
    }
  };

  multiAdd = async () => {
    let { isEdit } = this.state;
    if (isEdit) {
      await this.editData();
    } else {
      this.addData(false);
    }
  };

  removeValueMap = async (row, e) => {
    e && e.stopPropagation();
    const { _msg, _status } = await this.props.deleteValueMap(
      row.fieldName,
      row._id
    );
    this.props.enqueueSnackbar(_msg, {
      variant: _status ? "success" : "error",
      autoHideDuration: 2000,
    });
    if (_status) {
      this.props.getDataStandardsValue(row.fieldName);
    }
  };

  editValueMap = (row, e) => {
    e && e.stopPropagation();
    let data = { ...row };
    console.log(data, "---------------3333");
    this.props.history.push("/app/create-valuemap", {
      ValueMap_Data: data,
      edit: true,
      disabled: e ? false : true,
      dataStandards: this.state.dataStandards,
    });
  };

  onSubmitData = (e) => {
    if (e.nativeEvent.submitter.name === "multiple") {
      this.props.setLoading(true);
      this.multiAdd();
    }
    if (e.nativeEvent.submitter.name === "single") {
      this.props.setLoading(true);
      this.addData(true);
    }
  };

  onChange = (value) => {
    // if (value.hasOwnProperty("type")) {
    //   this.setState({ dataStandards: { ...value, rules: {} } });
    //   return;
    // }
    this.setState({
      dataStandards: { ...value },
    });
  };

  onChangeString = (val) => {
    let { strval, isEdit } = this.state;
    this.setState({ strval: { ...strval, ...val }, invalidData: false });
    console.log(strval.pattern && strval.pattern.length + 1, "kjhgfdsdfg");
    if (
      strval.pattern &&
      strval.pattern.length + 1 < 3
      // && strval.pattern === ""
    ) {
      this.setState({
        invalidData: true,
      });
    }
    if (!isEdit) {
      this.setState({
        invalidData: false,
      });
    }
  };

  onChangeInteger = (val) => {
    let { intval } = this.state;
    this.setState({ intval: { ...intval, ...val } });
  };

  onChangeNumber = (val) => {
    let { numval } = this.state;
    this.setState({ numval: { ...numval, ...val } });
  };
  onClear = (e) => {
    this.setState({
      strval: {},
      intval: {},
      numval: {},
    });
  };

  createValuemap = () => {
    let data = {
      value: "",
      description: "",
      fieldName: this.state.dataStandards.name,
    };
    this.props.history.push("/app/create-valuemap", {
      ValueMap_Data: data,
      edit: false,
      isDisabled: false,
      dataStandards: this.state.dataStandards,
    });
  };

  BulkValueDataStandard = () => {
    this.setState({
      openModal: true,
    });
  };

  postBulkUpload = async (doc) => {
    var formData = new FormData();
    formData.append(
      "meta",
      JSON.stringify({ name: doc[0].name, docType: doc[0].file.type })
    );
    formData.append("file", doc[0].file);
    try {
      const res = await APIs.bulkUploadFieldValue(
        this.state.dataStandards.name,
        formData
      );
      const { _msg } = res.data;
      this.props.enqueueSnackbar(_msg, {
        variant: "success",
      });
      this.props.getDataStandardsValue(this.state.dataStandards.name);
    } catch (err) {
      const { _msg } = err?.response?.data || {
        _msg: "Connection error!",
      };
      this.props.enqueueSnackbar(_msg, {
        variant: "error",
      });
    }

    this.handleBulkClose();
  };

  handleBulkClose = (e) => {
    this.setState({
      openModal: false,
    });
  };

  openIndustryModal = (value) => {
    this.setState({ open: true });
  };

  handleIndustryClose = () => {
    this.setState({ open: false });
  };

  render() {
    const {
      dataStandards,
      isEdit,
      strval,
      intval,
      numval,
      isDisabled,
      invalidData,
      industryType,
    } = this.state;
    const { status } = dataStandards;
    const { classes, loading, valdata, load } = this.props;
    console.log("industryTypeindustryType", industryType, dataStandards);

    return (
      <>
        <div>
          <ValidatorForm
            Form
            ref="form"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                return;
              }
            }}
            onSubmit={(e) => {
              this.onSubmitData(e);
            }}
          >
            {load && <Loader />}
            <Grid container>
              <Grid item sm={12} md={12} xs={12} lg={12}>
                <Breadcrumb
                  {...this.props}
                  links={[
                    { name: "Active Metadata", url: "/app/data_standards" },
                    {
                      name: `${isEdit ? dataStandards.name : "Create fields"}`,
                      url: "/app/create-team",
                    },
                  ]}
                />
              </Grid>

              <Grid item sm={6} md={6} lg={6} xs={12}>
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                  {isEdit ? dataStandards.name : "Create field"}
                </Typography>
              </Grid>
              <Grid
                item
                sm={6}
                md={6}
                container
                justifyContent="flex-end"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  {!isDisabled && (
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      onClick={() => {
                        this.props.history.push("/app/data_standards");
                      }}
                    >
                      Cancel
                    </Button>
                  )}
                </Grid>
                <Grid item>
                  {!isDisabled && (
                    <Button
                      name="multiple"
                      color={isEdit ? "" : "primary"}
                      size="small"
                      variant={isEdit ? "contained" : "outlined"}
                      type="submit"
                      disabled={
                        isEdit
                          ? !checkPermission("btn60")
                          : !checkPermission("btn59")
                      }
                    >
                      {isEdit ? "Save" : "Create and Add Another"}
                    </Button>
                  )}
                </Grid>
                <Grid item>
                  {!isEdit && (
                    <Button
                      name="single"
                      size="small"
                      variant={"contained"}
                      type="submit"
                      disabled={!checkPermission("btn59")}
                    >
                      {"Create"}
                    </Button>
                  )}
                </Grid>
                {dataStandards.status !== "inactive" && (
                  <Grid item>
                    {isDisabled && (
                      <Button
                        size="small"
                        variant="contained"
                        onClick={() =>
                          checkPermission("btn60") &&
                          this.setState({
                            isDisabled: false,
                          })
                        }
                        startIcon={<RiEditLine style={{ fontSize: "20px" }} />}
                        disabled={!checkPermission("btn60")}
                      >
                        Edit
                      </Button>
                    )}
                  </Grid>
                )}
              </Grid>
              <Grid item md={12}>
                <Divider className={classes.divider} />
              </Grid>
              <Grid item md={12} style={{ marginTop: "22px" }}>
                <Grid container>
                  <Grid
                    item
                    container
                    direction="row"
                    spacing={2}
                    sm={7}
                    md={7}
                  >
                    <Grid item xs={12} sm={4} md={4}>
                      <Typography
                        style={{ marginBottom: "2px" }}
                        variant="body2"
                      >
                        Field Name <span style={{ color: "red" }}>*</span>
                      </Typography>
                      <TextValidator
                        disabled={!isEdit ? false : true}
                        autoComplete="off"
                        size="small"
                        variant="outlined"
                        fullWidth
                        name="name"
                        value={dataStandards.name}
                        onChange={(e) =>
                          this.onChange({
                            ...dataStandards,
                            name: e.target.value.trimStart(),
                          })
                        }
                        validators={["required"]}
                        errorMessages={["Field Name is required"]}
                        helperText={<div style={{ height: "12px" }}></div>}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                      <Typography
                        style={{ marginBottom: "2px" }}
                        variant="body2"
                      >
                        Field Type <span style={{ color: "red" }}>*</span>
                      </Typography>
                      <FormControl fullWidth>
                        <SelectValidator
                          size="small"
                          variant="outlined"
                          name="type"
                          fullWidth
                          disabled={isDisabled}
                          native
                          SelectProps={{
                            native: true,
                          }}
                          value={dataStandards.type}
                          onChange={(e) =>
                            this.onChange({
                              ...dataStandards,
                              type: e.target.value,
                            })
                          }
                          validators={["required"]}
                          errorMessages={["Please select type "]}
                        >
                          <option value="">-Select-</option>
                          <option value={"string"}>{"string"}</option>
                          <option value={"boolean"}>{"boolean"}</option>
                          <option value={"integer"}>{"integer"}</option>
                          <option value={"number"}>{"number"}</option>
                        </SelectValidator>
                      </FormControl>
                    </Grid>
                    <Grid item md={4}>
                      <Typography
                        style={{ marginBottom: "2px" }}
                        variant="body2"
                      >
                        Industry Type <span style={{ color: "red" }}>*</span>
                      </Typography>
                      <IndustryTypesAutoComplete
                        disabled={isDisabled}
                        industryTypes={this.state.industryTypes || []}
                        value={this.state.dataStandards.industryType}
                        onChange={(value) =>
                          this.onChange({
                            ...dataStandards,
                            industryType: value,
                          })
                        }
                      />

                      <Modal
                        open={this.state.open}
                        handleClose={this.handleIndustryClose}
                        text={dataStandards.industryType}
                      />
                      {/* <FormControl
                        //  error={error}
                        size="small"
                        variant="outlined"
                        fullWidth
                      >
                        <SelectValidator
                          disabled={isDisabled}
                          fullWidth
                          variant="outlined"
                          size="small"
                          SelectProps={{
                            native: true,
                          }}
                          value={dataStandards.industryType}
                          inputProps={{
                            name: "apiIndustryType",
                            id: "apiIndustryType",
                          }}
                          onFocus={this.setDuplicateNull}
                          onChange={(e) =>
                            this.onChange(
                              {
                                ...dataStandards,
                                industryType: e.target.value,
                              },
                              "industryType"
                            )
                          }
                          validators={["required"]}
                          errorMessages={["Please select industry type"]}
                          // error={error}
                        >
                          <option value=""> - select -</option>
                          {industryType &&
                            industryType.map((item) => (
                              <option value={item}>{item}</option>
                            ))}
                        </SelectValidator>
                      </FormControl> */}
                      {/* <TextValidator
                        name="industryType"
                        disabled={isDisabled}
                        value={dataStandards.industryType}
                        fullWidth
                        size="small"
                        variant="outlined"
                        onChange={(e) =>
                          this.onChange({
                            ...dataStandards,
                            industryType: e.target.value,
                          })
                        }
                        validators={["required"]}
                        errorMessages={["Please select industry type"]}
                        helperText={<div style={{ height: "12px" }}></div>}
                      /> */}
                    </Grid>
                    <Grid item xs={12} sm={8} md={8}>
                      <FieldRules
                        onChangeString={this.onChangeString}
                        onChangeInteger={this.onChangeInteger}
                        onChangeNumber={this.onChangeNumber}
                        onClear={this.onClear}
                        isDisabled={this.state.isDisabled}
                        data={dataStandards}
                        strval={strval}
                        intval={intval}
                        numval={numval}
                        invalidData={invalidData}
                        {...this.props}
                      />
                    </Grid>
                  </Grid>
                  <Grid item md={2} xs={0} sm={0}></Grid>
                  <Grid item xs={12} sm={5} md={3}>
                    <Typography style={{ marginBottom: "2px" }} variant="body2">
                      Field Description <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextValidator
                      autoComplete="off"
                      disabled={isDisabled}
                      multiline
                      rows={7}
                      size="small"
                      variant="outlined"
                      maxlength="300"
                      fullWidth
                      id="Description"
                      value={dataStandards.description}
                      onChange={(e) =>
                        this.onChange({
                          ...dataStandards,
                          description: e.target.value.trimStart(),
                        })
                      }
                      name="Description"
                      validators={[
                        "required",
                        "minStringLength:0",
                        "maxStringLength:300",
                      ]}
                      errorMessages={[
                        "Field Description is required",
                        "Invalid input",

                        "Not exceed more than 300 characters",
                      ]}
                      helperText={<div style={{ height: "12px" }}></div>}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              sm={12}
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {!isDisabled && isEdit && (
                <div style={{ display: "flex" }}>
                  <Button
                    style={{
                      marginRight: 10,
                      width: "100%",
                      boxShadow: "none",
                      // padding: "6px 5px 6px 5px",
                    }}
                    variant="outlined"
                    color="primary"
                    size="small"
                    alignItems="center"
                    disabled={!checkPermission("btn232")}
                    onClick={() =>
                      checkPermission("btn232") && this.BulkValueDataStandard()
                    }
                    startIcon={<BiUpload />}
                  >
                    Bulk Upload
                  </Button>
                  <Button
                    name="single"
                    size="small"
                    disabled={!checkPermission("btn61")}
                    variant={"contained"}
                    onClick={() => this.createValuemap()}
                  >
                    {"Create"}
                  </Button>
                </div>
              )}
            </Grid>
            {isEdit && (
              <Grid item sm={12} className="mt-3">
                <ValueMapTable
                  valdata={valdata}
                  removeValueMap={this.removeValueMap}
                  isDisabled={!isDisabled}
                  // dataStandards={this.state.dataStandards}
                  editValueMap={this.editValueMap}
                />
              </Grid>
            )}
          </ValidatorForm>
          {isEdit ? (
            <div className="py-5 px-3">
              <Grid
                container
                spacing={3}
                // justifyContent="space-around"
                // alignItems="center"
                style={{
                  backgroundColor: "#ebebeb",
                }}
              >
                {dataStandards.createdon && (
                  <Grid item sm={3}>
                    <Typography variant="body2" align="center">
                      Created On:{" "}
                      <b>
                        {/* {moment
                        .unix(dataStandards.createdon)
                        .format("DD/MM/YYYY, hh:MM A")} */}
                        {helpers.date_Func(dataStandards.createdon)}
                      </b>
                    </Typography>
                  </Grid>
                )}
                {dataStandards.createdby && (
                  <Grid item sm={3}>
                    <Typography variant="body2" align="center">
                      Created By: <b>{dataStandards.createdby}</b>
                    </Typography>
                  </Grid>
                )}
                {dataStandards.updatedon && (
                  <Grid item sm={3}>
                    <Typography variant="body2" align="center">
                      Updated On:{" "}
                      <b>{helpers.date_Func(dataStandards.updatedon)}</b>
                    </Typography>
                  </Grid>
                )}
                {dataStandards.updatedby && (
                  <Grid item sm={3}>
                    {" "}
                    <Typography variant="body2" align="center">
                      Updated By: <b>{dataStandards.updatedby}</b>
                    </Typography>
                  </Grid>
                )}
                <HelpdeskFAB category="Active Metadata" />
              </Grid>
            </div>
          ) : null}
        </div>

        <BulkUploadComponent
          color="white"
          open={this.state.openModal}
          handleCloseBulk={this.handleBulkClose}
          property={this.state.property}
          postBulkUpload={this.postBulkUpload}
          btn={"Upload and Create Values"}
        />
      </>
    );
  }
}

CreateDataStandards.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    valdata: state.createDataStandards.data,
    loading: state.createDataStandards.loading,
    totalCount: state.createDataStandards.totalCount,
    load: state.createDataStandards.load,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUsers: () => dispatch(getAllUsers()),
    getDataStandardsValue: (name) => dispatch(fetchAllValueMapData(name)),
    setLoading: (bool) => dispatch(loadingDispatch(bool)),

    AddDatastandards: (payload) => dispatch(AddDatastandards(payload)),
    editDataStandards: (dataStandardId, payload) =>
      dispatch(editDataStandards(dataStandardId, payload)),
    deleteValueMap: (fieldname, id) => dispatch(deleteValueMap(fieldname, id)),
    fetchDatastandardsById: (id) => dispatch(getDatastandardsById(id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(Styles)(withSnackbar(CreateDataStandards)));
