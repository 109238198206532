const costValue = (row) => {
  let total = 0;
  const type = row.Type === "slab";
  const totalFlatCost = (!type && row.Slabs[0]?.TotalCost) || 0;
  if (type && row.Slabs) {
    row.Slabs.map((itm, i) => (total = total + itm.Price));
  }
  return {
    type,
    total,
    totalFlatCost,
  };
};

const invoiceFun = {
  costValue,
};
export default invoiceFun;
