import React, { useEffect } from "react";
import { checkPermission } from "helpers/permissons";
import { Typography, Grid, Button } from "@material-ui/core";
import FilterStatus from "./component/StatusFilter";
import DetailForm from "./DetailForm";
import { AppButton } from "components/common/Button";
import { RiEditLine } from "react-icons/ri";
import moment from "moment";
import helpers from "helpers/helpers";
import { useSnackbar } from "notistack";

const CustomerDetail = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { classes, details, propsDisabled, info } = props;
  const { message, success } = info;

  let color = "white";
  if (details.status === "inactive") {
    color = classes.inactiveBadge;
  } else if (details.status === "active") {
    color = classes.activeBadge;
  } else {
    color = classes.blockBadge;
  }
  let dateFormat = helpers.getDateFormat();

  useEffect(() => {
    if (message) {
      enqueueSnackbar(message, { variant: success ? "success" : "error" });
    }
  }, [message, success, enqueueSnackbar]);
  console.log("ghfjhsadfhgadsg", details);
  return (
    <div>
      <Grid container className={classes.root}>
        <Grid
          item
          md={12}
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6" className={classes.h6}>
            {details.organization_name}
          </Typography>
          <div className={props.classes.flexBwCenter}>
            <Typography variant="body1" className={classes.p}>
              Registered{" "}
              <span style={{ fontWeight: "bolder" }}>
                {" "}
                {/* {details.createdon && details.createdon.split("T")[0]} */}
                {details.createdon &&
                  moment
                    .unix(details.createdon)
                    .format(helpers.getDateFormat())}
              </span>
            </Typography>
            <FilterStatus
              variant="outlined"
              size="small"
              style={classes.activeFltr}
              type="button"
              badgeColor={color}
              status={details.status}
            />
            {/* <AppButton
                          color="primary"
                          buttonName="Edit"
                          variant="contained"
                          startIcon={<img src="/images/edit.svg" alt="" />}
                          disabled={false}
                          style={{ padding: "6px 15px" }}
                          className="btnsmall"
                          // onClick={() => {
                          //   setflagVisible(!flagVisible);
                          //   handleClick(true);
                          // }}
                        /> */}
            <div>
              {!propsDisabled ? (
                <Button
                  variant="contained"
                  size="small"
                  // startIcon={<RiEditLine />}
                  type="submit"
                  disabled={!checkPermission("btn63")}
                >
                  {" "}
                  Save{" "}
                </Button>
              ) : (
                details.status === "active" && (
                  <div>
                    <Button
                      variant="contained"
                      size="small"
                      type="button"
                      startIcon={<RiEditLine />}
                      onClick={(e) => {
                        e.preventDefault();
                        checkPermission("btn63") &&
                          props.disablePropsEdit(false);
                      }}
                      disabled={!checkPermission("btn63")}
                    >
                      {" "}
                      Edit{" "}
                    </Button>
                  </div>
                )
              )}
            </div>
          </div>
        </Grid>
        <Grid item md={12} style={{ marginTop: "1%" }}>
          <DetailForm {...props} />
        </Grid>
      </Grid>
    </div>
  );
};
export default CustomerDetail;
