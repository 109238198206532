import * as types from "./actionType";
import { filterData } from "../utils/index";

const INITIAL_STATE = {
  sankeyData: null,
  loading: false,
  graphData: [],
  toolTip: {
    style: {},
    enabled: false,
    toolTipInfo: {},
  },
};

const reducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case types.FETCHING_MONITORING_DATA:
      return {
        ...state,
        loading: true,
      };
    case types.FETCH_MONITORING_DATA_FAIL:
      return {
        ...INITIAL_STATE,
      };
    case types.FETCHED_MONITORING_DATA:
      return {
        ...INITIAL_STATE,
        sankeyData: action.payload,
        graphData: filterData(action.payload),
        loading: false,
      };
    case types.SET_UP_TOOLTIP:
      return {
        ...state,
        toolTip: action.payload,
      };
    case types.CLOSE_TOOLTIP:
      return {
        ...state,
        toolTip: {
          ...INITIAL_STATE.toolTip,
        },
      };

    case types.TERMINATE_MONITORING_COMPONENT:
      return {
        ...INITIAL_STATE,
      };

    default:
      return INITIAL_STATE;
  }
};
export default reducer;
