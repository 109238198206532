// constants
// #A6AB83
// const primary_color = "#a88c25";
// const primary_color = "#A6AB83";
// const secondary_color = "";

const applicationTheme = (primary_color, secondary_color) => ({
  typography: {
    fontFamily: ["Nunito"].join(","),
    body2: {
      fontSize: 14,
    },
    body1: {
      fontSize: "14px",
    },
    h4: {
      fontSize: 16,
    },
    h5: {
      fontSize: 16,
      fontWeight: 600,
    },
  },
  overrides: {
    MuiCheckbox: {
      // colorSecondary: {
      //   color: secondary_color,
      //   "&$checked": {
      //     color: secondary_color,
      //   },
      // },
      colorSecondary: {
        color: primary_color,
        "&$checked": {
          color: primary_color,
        },
      },
    },
    MuiFormHelperText: {
      root: { textTransform: "initial" },
    },
    MuiTableHead: {
      root: {
        backgroundColor: primary_color,
        color: "#f2f2f2",
      },
      head: {
        color: "#f2f2f2",
      },
    },
    MuiButton: {
      root: {
        fontWeight: 500,
        fontSize: "1rem",
        textTransform: "capitalize",
        color: "white",
        padding: "5px 10px",
        boxShadow: "none",
      },
      contained: {
        backgroundColor: primary_color,
        // boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
        color: "white",
        "&:hover": {
          backgroundColor: primary_color,
          color: "white",
        },
      },
      outlinedSizeSmall: {
        minWidth: "100px",
        minHeight: "32px",
      },
      containedSizeSmall: {
        minWidth: "100px",
        minHeight: "32px",
      },
      text: {
        backgroundColor: "none",
        color: "black",
      },
      outlined: {
        color: "#000",
        // color:primary_color,
        backgroundColor: "none",
        border: "1px solid #999682",
        "&:hover": {
          backgroundColor: "white",
        },
      },
    },
    MuiChip: {
      root: {
        margin: "2px",
        // backgroundColor:primary_color,
        fontSize: "12px",
        borderRadius: "4px",
      },
      label: {
        color: "inherit",
        fontWeight: "bold",
        fontSize: 14,
      },
      deleteIcon: {
        color: primary_color,
      },
    },
    MuiSelect: {
      outlined: {
        // height: "14px",
        //theme for mui select
      },
    },
    MuiTypography: {
      button: {
        fontWeight: 500,
      },
    },
    MuiInputBase: {
      root: {
        backgroundColor: "#ffffff",
        "&$disabled": {
          backgroundColor: "#f5f5f5",
        },
      },
    },

    MuiFormControlLabel: {
      label: {
        fontSize: "0.875rem",
        color: "#4D4D4D",
        fontWeight: "900",
        font: "normal normal bold 14px/16px Nunito",
      },
    },

    MuiBox: {
      root: {},
    },
    MuiAccordionDetails: {
      root: {
        display: "grid",
        padding: "0px 0px 0px",
      },
    },
    MuiAccordionSummary: {
      root: {
        padding: "0px",

        // border: "1px solid red",
      },
    },

    MuiTableSortLabel: {
      root: {
        color: "#f2f2f2",
      },
      active: {
        color: "#f2f2f2",
      },
      icon: {
        color: "#fff !important",
        opacity: "0.4  !important",
      },
      "MuiTableSortLabel-active": {
        color: "#f2f2f2",
      },
    },
    MuiTableCell: {
      body: {
        // padding :"10px 15px 10px 15px !important"
        padding: "2px 20px 2px 30px",
        fontSize: "14px",
      },
      root: {
        //This can be referred from Material UI API documentation.
        // padding: "11px 10px",
        padding: "11px 20px 11px 30px",
        fontWeight: "500",
        textTransform: "capitalize",
        color: "#f2f2f2",
      },
      head: {
        color: "#f2f2f2",
      },
    },

    MuiTab: {
      root: {
        fontWeight: "bold",
        color: "#002855",
        fontSize: "13px",
      },
      textColorInherit: {
        color: "none",
      },
    },
    PrivateTabIndicator: {
      colorSecondary: {
        backgroundColor: primary_color,
      },
    },
    MuiFormLabel: {
      asterisk: {
        color: "#db3131",
        "&$error": {
          color: "#db3131",
        },
      },
    },

    MuiFab: {
      root: {
        width: "40px",
        height: "40px",
      },
    },
    MuiBadge: {
      root: {
        display: "block",
      },
    },
  },

  palette: {
    secondary: {
      main: "#3D86C6",
    },
    primary: { main: primary_color },
    warning: {
      main: "#ff9800",
    },
    default: {
      main: "#21BA45",
    },
  },
  MuiIconButton: {
    root: {
      color: "#000",
    },
  },
});

export default applicationTheme;
