import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {} from "../ModuleDetails/redux/action";
import { Button, Grid, Typography, Divider } from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Styles from "./Styles";
import { withStyles } from "@material-ui/styles";
import Breadcrumb from "components/Breadcrumbs/Breadcrumb";
import { addModule, updateModule } from "../ModuleDetails/redux/action";
// import { isDuplicate } from "../../../../components/";

class DetailModuleForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      check_name: "",
      err_msg: "",
      error: false,
      modules: {
        name: "",
        description: "",
      },
      isEdit: false,
      index: 0,
    };
  }

  componentDidMount() {
    const { location } = this.props;
    if (location.state !== undefined) {
      let { edit, moduleData } = location.state;

      this.setState({
        modules: { ...moduleData },
        isEdit: edit,
        check_name: moduleData.name,
      });
    }
    //
  }

  handleOnchange = (value) => {
    this.setState({
      modules: { ...value },
    });
  };

  checkDuplicate = (value, data) => {
    let flag = false;
    data.map((item, id) =>
      value.name === item.name ? (flag = true) : (flag = false)
    );
    return flag;
  };

  multilineAdd = async () => {
    const { isEdit, modules } = this.state;
    if (isEdit) {
      let updateModules = [...this.props.onBoard.modules];
      let data = updateModules.map((item, index) => {
        return item.key === modules.key ? { ...modules } : { ...item };
      });
      await this.props.edit_module(data);
      // this.props.history.push("/app/onboard-application");
    } else {
      await this.props.add_module(this.state.modules);
      this.setState({
        modules: {
          name: "",
          description: "",
          key: this.props.onBoard.modules.length,
        },
        isEdit: false,
      });
    }
  };

  singleObjectSubmit = async () => {
    const { modules } = this.state;
    await this.props.add_module(modules);
    this.props.history.push("/app/detail");
  };

  onSubmit = async (e) => {
    const { modules } = this.state;
    const { onBoard } = this.props;
    let flag = false;

    // if (!this.state.isEdit || this.state.check_name !== modules.name) {
    //   flag = isDuplicate(onBoard.modules, (f) => {
    //     return f.name.toLowerCase() === modules.name.toLowerCase();
    //   });
    // }

    if (flag) {
      this.setState({
        err_msg: "Module name is already added",
        error: true,
      });
      return;
    }

    this.setState({
      err_msg: "",
      error: false,
    });

    if (e.nativeEvent.submitter.name === "multiple") {
      this.multilineAdd();
    }

    if (e.nativeEvent.submitter.name === "single") {
      this.singleObjectSubmit();
    }
  };

  setDuplicateNull = () => {
    this.setState({
      error: false,
      err_msg: "",
    });
  };

  render() {
    const { classes, onBoard } = this.props;
    const { isEdit, modules, error, err_msg } = this.state;
    console.log(
      onBoard.modules,
      modules,
      onBoard.modules.length,
      "this is moduleswwwwwwwwdddd"
    );
    return (
      <div>
        <Grid item sm={12} md={12} xs={12} lg={12}>
          <Breadcrumb
            {...this.props}
            links={[
              { name: "Applications", url: "/app/applications" },
              {
                name: "Detail",
                url: "/app/detail",
              },
              {
                name: "Modules",
                url: "/app/module-detail-form",
              },
            ]}
          />
        </Grid>
        <div>
          <ValidatorForm ref="form" onSubmit={(e) => this.onSubmit(e)}>
            <Grid container spacing={0}>
              <Grid item sm={12} md={12} xs={12} lg={12}>
                <Grid
                  container
                  spacing={1}
                  direction="row"
                  alignItems="center"
                  className={classes.root}
                >
                  <Grid item md={6}>
                    <div>
                      <Typography variant="h6" className={classes.title}>
                        Modules
                      </Typography>
                    </div>
                  </Grid>
                  <Grid
                    item
                    sm={6}
                    container
                    justifyContent="flex-end"
                    spacing={1}
                  >
                    <Grid item>
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() => {
                          this.props.history.push("/app/detail");
                        }}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        color="primary"
                        size="small"
                        variant={isEdit ? "contained" : "outlined"}
                        type="submit"
                        name="multiple"
                      >
                        {isEdit ? "Save" : "Create and Add Another"}
                      </Button>
                    </Grid>
                    <Grid item>
                      {!isEdit && (
                        <Button
                          name="single"
                          size="small"
                          variant="contained"
                          type="submit"
                        >
                          {"Create"}
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={12}>
                <Divider className={classes.divider} />
              </Grid>
              <Grid item md={12} style={{ marginTop: "22px" }}>
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <div style={{ width: "100%" }}>
                      <Typography
                        style={{ marginBottom: "2px" }}
                        variant="body2"
                      >
                        Name{" "}
                      </Typography>
                      <TextValidator
                        autoComplete="off"
                        size="small"
                        variant="outlined"
                        fullWidth
                        value={modules.name}
                        id="name"
                        error={error}
                        onFocus={this.setDuplicateNull}
                        onChange={(e) =>
                          this.handleOnchange({
                            ...modules,
                            name: e.target.value,
                          })
                        }
                        name="moduleName"
                        validators={["required"]}
                        errorMessages={["Please enter module name"]}
                        helperText={err_msg}
                      />
                    </div>
                  </Grid>
                  <Grid item md={6}>
                    <div style={{ width: "100%" }}>
                      <Typography
                        variant="body2"
                        style={{ marginBottom: "2px" }}
                      >
                        Description
                      </Typography>
                      <TextValidator
                        autoComplete="off"
                        multiline
                        rows={7}
                        size="small"
                        variant="outlined"
                        fullWidth
                        id="moduleDescription"
                        value={modules.description}
                        onChange={(e) =>
                          this.handleOnchange({
                            ...modules,
                            description: e.target.value,
                          })
                        }
                        name="moduleDescription"
                        validators={["required"]}
                        errorMessages={["Please enter module description"]}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </ValidatorForm>
        </div>
      </div>
    );
  }
}
DetailModuleForm.propTypes = {
  classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => {
  return {
    onBoard: state.application.onBoard,
  };
};
const mapDispatchToProps = (dispatch) => ({
  add_module: (data) => dispatch(addModule(data)),
  edit_module: (data) => dispatch(updateModule(data)),
});

const DetailModule = connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailModuleForm);

export default withStyles(Styles)(DetailModule);
