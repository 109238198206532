import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Grid, TextField, Typography } from "@material-ui/core";
import DS_Api from "../../../api/DataStandardsAPIs/index";
import WrappedDexTable from "components/DexTable/WrappedDexTable";
import { TableRow, TableCell } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";
import withPagination from "../../../components/PaginationHOC/PaginationHOC";
import { DropzoneArea } from "material-ui-dropzone";
import Upload from "../Img/Icon metro-folder-upload.svg";
import { RiDownload2Line } from "react-icons/ri";
import { CSVLink } from "react-csv";
import helpers from "../../../helpers/helpers";
import CircularProgress from "@material-ui/core/CircularProgress";
const headers = [
  { label: "Cdu Value", key: "CduValue" },
  { label: "Custom Value", key: "CustomValue" },
];

const CustomDropperIcon = () => (
  <img
    style={{ marginBottom: "5px" }}
    src={Upload}
    width="100"
    height="50"
    alt=""
  />
);
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function CustomTableContainer(allProps) {
  const { data, onChangeVal } = allProps;
  console.log("allProps", allProps);
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Field Value</TableCell>
            <TableCell align="left">Description</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, idx) => (
            <TableRow key={idx}>
              <TableCell
                component="th"
                scope="row"
                style={{ textTransform: "none" }}
              >
                {" "}
                {row.key}
              </TableCell>
              <TableCell align="left">
                {" "}
                <div style={{ width: "300px", overflowX: "auto" }}>
                  {row.description || ""}
                </div>
              </TableCell>
              <TableCell align="right">
                <div style={{ width: "100%" }}>
                  <TextField
                    variant="outlined"
                    size="small"
                    onChange={(e) =>
                      onChangeVal(
                        {
                          key: row.key,
                          value: e.target.value,
                          description: row.description,
                        },
                        idx,
                        data
                      )
                    }
                    fullWidth
                    value={row.value}
                  />
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const WrappedTable = withPagination(CustomTableContainer);

export default function FieldValueModal(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [fieldData, setFieldData] = useState([]);
  const [csvreport, setCsvReport] = useState({
    data: [],
    headers: headers,
    filename: "Cdu.csv",
  });

  const { open, handleModal, handleSubmit, fieldName, valueMapping } = props;

  const onChangeVal = (obj) => {
    setFieldData([
      ...fieldData.map((f) =>
        f.key === obj.key
          ? {
              ...obj,
            }
          : f
      ),
    ]);
  };

  const fetchFieldValues = async (name) => {
    let old_fieldValues = {};

    if (valueMapping[fieldName]) {
      let splited_values = valueMapping[fieldName].split(/[:,]/);
      let key = 0;
      while (splited_values.length > key) {
        old_fieldValues[splited_values[key]] = splited_values[key + 1];
        key = key + 2;
      }
    }

    try {
      const res = await DS_Api.fetchValueMap(name);
      const arr = res?.data?.data || [];
      const dataCsv = arr.map((row) => {
        return {
          CduValue: row.value,
          CustomValue: old_fieldValues[row.value] || "",
        };
      });

      setCsvReport({
        data: dataCsv,
        headers: headers,
        filename: "cdu_value_mapping.csv",
      });
      setFieldData([
        ...arr.map((row) => {
          return {
            key: row.value,
            value: old_fieldValues[row.value] || "",
            description: row.description,
          };
        }),
      ]);
    } catch (err) {
      setFieldData([]);
      setCsvReport({
        data: [],
        headers: headers,
        filename: "Cdu.csv",
      });
    }
  };

  const display_msg = (msg, type) => {
    enqueueSnackbar(msg, {
      variant: type ? "success" : "error",
    });
  };

  useEffect(() => {
    let fields = (fieldName || "").split(".");
    if (fields.length == 0) return;
    open && fetchFieldValues(fields[fields.length - 1]);
  }, [open, fieldName]);

  const uploaddedCsv = (files) => {
    try {
      const reader = new FileReader();
      reader.onload = async function (e) {
        try {
          const text = e.target.result;
          let data = await helpers
            .csvToArray(text)
            .filter((itm) => itm["Cdu Value"].trim().length !== 0);

          if (data.length !== fieldData.length) {
            return;
          }

          let check_data = [
            ...data.map((r) => r["Cdu Value"]),
            ...fieldData.map((r) => r.key),
          ];

          check_data = [...new Set(check_data)];
          if (check_data.length !== fieldData.length) {
            return;
          }
          let arr = data.map((row, idx) => {
            return {
              key: row["Cdu Value"],
              value: row["Custom Value"],
              description: fieldData[idx].description,
            };
          });

          setFieldData([...arr]);
        } catch (err) {
          setFieldData([...fieldData]);
        }
      };
      reader.readAsText(files[0]);
    } catch (err) {}
  };

  const uploadValuesFiles = (files) => {
    uploaddedCsv(files);
  };
  return (
    <div>
      <Dialog
        open={open}
        fullWidth
        maxWidth={"md"}
        onClose={() => handleModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Value Mapping</DialogTitle>
        {fieldData.length > 0 ? (
          <DialogContent>
            <Grid container spacing={1}>
              <Grid item md={3}>
                <Button
                  size="small"
                  style={{ padding: "0 2px", margin: 0, marginBottom: 2 }}
                  endIcon={<RiDownload2Line style={{ fontSize: "15px" }} />}
                >
                  <CSVLink {...csvreport}>Download </CSVLink>
                </Button>
                <DropzoneArea
                  Icon={CustomDropperIcon}
                  // key={this.state.currentTab + f.cdu_name}
                  acceptedFiles={[".csv"]}
                  style={{ width: "100%" }}
                  filesLimit={1}
                  clearOnUnmount={true}
                  // initialFiles ={this.state.outputFile}
                  onChange={uploadValuesFiles}
                  id="csvFile"
                  onDelete={() => {
                    fetchFieldValues(fieldName);
                  }}
                  // dropzoneParagraphClass={
                  //   classes.dropzoneParagraphClass
                  // }

                  showPreviewsInDropzone={true}
                  useChipsForPreview
                  showAlerts={false}
                  dropzoneText="Upload"
                  dropzoneClass="documentDropperClass"
                  dropzoneParagraphClass="documentDropperTextClass"
                />
              </Grid>
              <Grid item md={9}>
                <WrappedTable
                  onChangeVal={onChangeVal}
                  data={fieldData}
                  setFieldData={setFieldData}
                />
              </Grid>
            </Grid>
          </DialogContent>
        ) : (
          <DialogContent>
            {/* <CircularProgress /> */}
            <Typography>{"No field values found"}</Typography>
          </DialogContent>
        )}
        {fieldData.length > 0 && (
          <DialogActions>
            <Button onClick={() => handleModal(false)} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => handleSubmit(fieldName, fieldData)}
              color="primary"
              autoFocus
            >
              Submit
            </Button>
          </DialogActions>
        )}
        {fieldData.length === 0 && (
          <DialogActions>
            <Button onClick={() => handleModal(false)} color="primary">
              Cancel
            </Button>{" "}
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
}
