import React, { useEffect, useState } from "react";
import routes from "../../routes/routes";
import PropTypes from "prop-types";
import AuthenticatedRoute from "./AuthenticatedRoute";
import { withStyles } from "@material-ui/core/styles";
import { Paper, Grid } from "@material-ui/core";
import NavBar from "../../components/NavBar/Navbar";
import {
  Route,
  Switch,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";
import Styles from "../../styles/Styles-root";
import { SnackbarProvider, useSnackbar } from "notistack";
import DialogConfirmatinComponent from "../../components/DeleteConfirmationDialog/Index";
import helper from "helpers/helpers";
import moment from "moment";
class AdminLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      shouldlogout: false,
      title: "",
      defaultSelectedKeys: "",
    };
    // this.gotoRoute = this.gotoRoute.bind(this);
  }

  componentDidMount() {
    document.body.style.backgroundColor = "#f2f2f2";
  }

  getRoutesBasedOnRole = () => {
    let mainRoutes = [];
    mainRoutes = routes;
    return mainRoutes;
  };

  render() {
    const { props } = this;
    const { classes } = props;
    const expDateTime = helper.decodeAccessToken()?.exp || null;

    return (
      <>
        <Grid container>
          <Grid item xs={12}>
            <NavBar />
          </Grid>
          <Grid item xs={12}>
            <div className={classes.root}>
              <Switch>
                {this.getRoutesBasedOnRole().map((prop, key) => {
                  return (
                    <AuthenticatedRoute
                      path={prop.url}
                      permission={prop.permission}
                      resource={prop.resource}
                      component={prop.component}
                      key={key}
                    />
                  );
                })}
                <Redirect from="/app" to="/app/applications" />
              </Switch>
              {expDateTime && (
                <AuthVerify expDateTime={moment.unix(expDateTime).format()} />
              )}
              <DialogConfirmatinComponent />
            </div>
          </Grid>
        </Grid>
      </>
    );
  }
}
AdminLayout.propTypes = {
  classes: PropTypes.object.isRequired,
};

const AuthVerify = ({ expDateTime }) => {
  const history = useHistory();
  const location = useLocation();
  const currentDT = new Date(moment().format());
  const exp = new Date(expDateTime);
  const seconds = (exp.getTime() - currentDT.getTime()) / 1000;

  useEffect(() => {
    const timer = setTimeout(() => {
      history.push("/login", {
        data: {
          state: location.state ? location.state : undefined,
          path: location.pathname,
          key: "expired",
        },
      });
    }, seconds * 1000);
    return () => {
      console.log("clearing timer....!");
      clearTimeout(timer);
    };
  }, [location]);
  return <></>;
};

export default withStyles(Styles)(AdminLayout);
