import React, { useState, useEffect } from "react";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import { Backdrop, Button, CircularProgress } from "@material-ui/core";
import { useHistory, useLocation } from "react-router";
import { useSnackbar } from "notistack";
import { connect, useSelector, useDispatch } from "react-redux";
import { CustomButtonSubmit } from "../../components/common/Button/CustomButton";
import useStyles from "./commonStyles";
import {
  generateQR_code,
  action_verify2FACode,
  resetProps,
  clearMsg,
} from "./redux/action";
import { PRIVATE_ROUTE_TRUE } from "./redux/actionType";
import GoogleAuth from "assets/images/GoogleAuth.svg";
import { userDetailAction } from "../UserProfile/redux/action";
import { getOrganizationDetails } from "../Organization/OrganizationDetails/redux/action";
// import { userDetailAction } from "../../redux/actions/userProfileAction";
// import {
//   addUserFavouite,
//   addUserSubscription,
// } from "../../redux/actions/userPersonalAction";
import jwt_decode from "jwt-decode";

// import { addAuthData } from "../../redux/actions/authAction";
// import { add_current_organization_data_action } from "../../redux/actions/organizationAction";

function VerifyOTPScreen(props) {
  const [otp, setOtp] = useState("");
  const [base_64, setBase64] = useState("");
  const [userInfo, setUserInfo] = useState({});
  const [errorObj, setErrorObj] = useState({
    otp: false,
  });
  const dispatch = useDispatch();
  const loginState = useSelector((state) => state.loginReducer);
  const { QR_Code, verifyCodeRes, userLoginData, msg, success, loading } =
    loginState;
  const [load, setLoading] = useState(false);
  const styles = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [disableBtn, setDisableBtn] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { _id, Show_QR } = userLoginData;
  useEffect(() => {
    if (_id) {
      Show_QR && dispatch(generateQR_code(_id));
      // dispatch(generateQR_code(_id));
    } else {
      history.push("/");
    }
  }, []);

  const fetchRequiredUserProps = async () => {
    await dispatch({
      type: PRIVATE_ROUTE_TRUE,
    });
    await dispatch(userDetailAction(_id));
    await dispatch(getOrganizationDetails(_id));
    await dispatch(resetProps());
    history.push("/app/applications");
  };
  const displayMsg = () => {
    enqueueSnackbar(msg, { variant: success ? "success" : "error" });
    dispatch(clearMsg());
  };
  useEffect(() => {
    if (QR_Code) {
      const decodedQR = atob(QR_Code);
      setBase64(decodedQR);
    }
    if (verifyCodeRes) {
      fetchRequiredUserProps();
    }
  }, [QR_Code, verifyCodeRes]);

  useEffect(() => {
    if (msg) {
      displayMsg();
    }
  }, [msg]);

  const handleOTPChange = (value) => {
    setErrorObj(() => ({ otp: false }));
    setOtp(value);
  };

  const handleButtonClick = async () => {
    var errorFlag = false;
    if (otp === "") {
      errorFlag = true;
      setErrorObj(() => ({ otp: true }));
    }
    if (!errorFlag) {
      setLoading(true);
      // const { email } = location.state;
      // const req_data = {
      //   email: email,
      //   code: otp,
      // };
      console.log(userInfo._id, otp, "34567890");
      dispatch(action_verify2FACode(_id, otp));
      // const { _msg, _status, data } = await verifyEmailCodeLogin(req_data);
      // if (_status === 200) {
      //   var authdata = {};
      //   let userId = "";
      //   setLoading(false);
      //   const { accessToken, refreshToken, userDetails, userActions } = data;
      //   if (accessToken && refreshToken) {
      //     const decryptData = jwt_decode(accessToken);
      //     userId = userDetails?.id;
      //     authdata = {
      //       ...authdata,
      //       accesstoken: accessToken,
      //       refreshtoken: refreshToken,
      //       userid: userId,
      //       userActions: decryptData?.actions,
      //     };
      //     // authdata
      //     props.addAuthData(authdata);
      //     // user Detail
      //     props.userDetailAction();
      //     // userfav
      //     props.addUserFavouite(userId);
      //     // usersubscription
      //     props.addUserSubscription(userId);
      //     // Org Data
      //     props.add_current_organization_data_action();
      //     history.push("/dashboard");
      //   } else {
      //     setLoading(false);
      //     enqueueSnackbar(
      //       _msg
      //         ? _msg
      //         : "Unable to process your request, please try after sometime",
      //       { variant: "error" }
      //     );
      //   }
      // }
    }
    setLoading(false);
  };
  const handleResendCode = async () => {
    // try {
    //   setDisableBtn(true);
    //   const { _msg, _status } = await resendCode(history.location.state);
    //   if (_status === 200 || 201) {
    //     setDisableBtn(false);
    //     enqueueSnackbar(_msg ? _msg : "Otp sent successfully", {
    //       variant: "success",
    //     });
    //   }
    // } catch (error) {
    //   setDisableBtn(false);
    //   const { _msg, _status, data } = error.response.data;
    //   if (_msg) {
    //     enqueueSnackbar(
    //       _msg
    //         ? _msg
    //         : "Unable to process your request, please try after sometime",
    //       { variant: "error" }
    //     );
    //   }
    // }
  };
  // useEffect(() => {
  //   console.log(history.location.state.email);
  // }, [history]);
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleButtonClick();
      }}
    >
      <div className={styles.containerOtp}>
        <Backdrop
          className={styles.backdrop}
          open={loading}
          onClick={() => setLoading(false)}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        {QR_Code && (
          <text
            className={styles.big_text}
            style={{ alignSelf: "center", marginTop: 65, fontSize: 21 }}
          >
            {"Use"}{" "}
            <Chip
              avatar={<Avatar alt="A" src={GoogleAuth} />}
              label="Google Authenticator"
            />{" "}
            to scan the QR code below
          </text>
        )}
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            padding: 2,
          }}
        >
          {QR_Code && (
            <img
              width="180"
              src={`data:image/png;base64,${base_64}`}
              alt="QRCODE"
            />
          )}
        </div>
        {!QR_Code && <div style={{ height: "25vh" }}></div>}
        <text className={styles.subtitle2} style={{ alignSelf: "center" }}>
          Enter 6-digit verification code
        </text>
        {/* <text
        className={styles.label}
        style={{ textAlign: "center", marginTop: 15 }}
      >
        We've emailed your code
      </text> */}
        {/* <text
        className={styles.label}
        style={{ textAlign: "center", marginTop: 5 }}
      >
        You'll receive an email containing 6-digit verification code
      </text> */}

        <div className={styles.email} style={{ marginTop: 22.5 }}>
          <input
            required
            className={styles.otp_text}
            style={
              errorObj.otp
                ? { marginTop: 5, borderColor: "red" }
                : { marginTop: 5 }
            }
            value={otp}
            onChange={(e) => handleOTPChange(e.target.value)}
          ></input>
          {errorObj.otp && (
            <text className={styles.error_text}>Invalid OTP</text>
          )}
        </div>
        <div style={{ marginTop: 27, alignSelf: "center" }}>
          <CustomButtonSubmit
            type="submit"
            label="Verify Code"
            customStyles={{
              height: 40,
              width: 264,
              font: "normal normal normal 14px/19px Nunito",
              cursor: "pointer",
            }}
          />
        </div>
        <div style={{ alignSelf: "center", marginTop: 33 }}>
          {/* <text className={styles.resend_code}>
          Didn't receive the verification code yet?
          <Button
            className={styles.resendBtn}
            onClick={handleResendCode}
            disabled={disableBtn === true}
          >
            {" "}
            Resend Code
          </Button>
        </text> */}
        </div>
      </div>
    </form>
  );
}

const mapDispatchToProps = {
  // addAuthData,
  // addUserFavouite,
  // addUserSubscription,
  // userDetailAction,
  // add_current_organization_data_action,
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyOTPScreen);
