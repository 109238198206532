import React from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./breadCrumbstyle";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

const useStyles = makeStyles((theme) => ({
  bread: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    font: "normal normal normal 14px/19px Nunito",
    marginTop: "15px",

    "& ol li a": {
      // color: '#888888',
      color: "#2185D0",
    },

    // "& ol li:hover": {
    //   opacity: 1,
    // },

    // "& ol li a:hover": {
    //   textDecoration: 'none',
    // },

    "& ol li:last-child": {
      color: "#1B1C1D",
      fontWeight: 500,
    },
  },
}));

const CustomBreadcrumbs = (props) => {
  const classes = useStyles();
  const { links, history } = props;
  const handleClick = (e, f) => {
    e.preventDefault();
    console.log({ e, f }, "afds");
    history.push(f.url, f.routeParams || {});
  };
  return (
    <React.Fragment>
      <Breadcrumbs
        // style={{
        //   border: "solid 1px red",
        //   fontSize: "14px",
        //   lineHeight: "19px",
        //   paddingTop: "1%",
        //   paddingBottom: "1%",
        // }}
        className={classes.bread}
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {links &&
          links.map((f, i) => {
            return (
              <div>
                {i === links.length - 1 ? (
                  <Typography
                    style={{
                      fontSize: "14px",
                    }}
                    key={f.name}
                  >
                    {" "}
                    {f.name}{" "}
                  </Typography>
                ) : (
                  <Link
                    style={{ cursor: "pointer" }}
                    disable={i == links.length - 1}
                    color={i == links.length - 1 ? "textPrimary" : "secondary"}
                    onClick={(e) => {
                      e.preventDefault();
                      if (f.onClick !== undefined) {
                        f.onClick(f);
                      } else {
                        handleClick(e, f);
                      }
                    }}
                    key={f.name}
                  >
                    {f.name}
                  </Link>
                )}
              </div>
            );
          })}
      </Breadcrumbs>
    </React.Fragment>
  );
};

export default withStyles(styles)(CustomBreadcrumbs);
