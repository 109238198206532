/**
 *
 * Helper functions
 *
 */
import jwt_decode from "jwt-decode";
import moment from "moment";
export const dltMsg = (name) => {
  return `Are you sure you want to delete ${name}?`;
};

const decodeAccessToken = () => {
  const token = JSON.parse(sessionStorage.getItem("token"));
  const { accessToken } = token || {};
  let userInfo = null;
  if (accessToken) {
    userInfo = jwt_decode(accessToken);
  }
  return userInfo;
};

const checkImage = (file) => {
  if (file.type === "image/png" || file.type === "image/jpeg") {
    return true;
  } else {
    return false;
  }
};
const getDateFormat = () => {
  const storage = JSON.parse(localStorage.getItem("persist:root"));
  let userDetails = null;
  if (storage) {
    userDetails = JSON.parse(storage.apearenceReducer);
    userDetails = userDetails.userInfo;
  }
  let { dateFormat, time } = userDetails || {
    dateFormat: null,
    time: null,
    timeZone: null,
  };
  dateFormat = dateFormat || "YYYY-MM-DD";
  time = time || "h:mm";
  return `${dateFormat}, ${time} A`;
};

const date_Func = (unixTime) => {
  const { FORMAT, timeZone } = getDateFormatAdvance();
  return moment.unix(unixTime).tz(timeZone).format(FORMAT);
};

const getDateFormatAdvance = () => {
  const storage = JSON.parse(localStorage.getItem("persist:root"));
  let userDetails = null;
  if (storage) {
    userDetails = JSON.parse(storage.apearenceReducer);
    userDetails = userDetails.userInfo;
  }
  let { dateFormat, time, timeZone } = userDetails || {
    dateFormat: null,
    time: null,
    timeZone: null,
  };
  dateFormat = dateFormat || "YYYY-MM-DD";
  timeZone = timeZone || "UTC";
  time = time || "h:mm";
  const FORMAT = `${dateFormat}, ${time} A`;
  return {
    FORMAT,
    timeZone,
    time,
  };
};

const disableDeleteFnc = (length) => {
  let obj = {
    fontSize: "22px",
    color: length <= 1 ? "#bdbdbd" : "",
    cursor: length <= 1 ? "text" : "pointer",
  };
  return obj;
  return "MMM DD YYYY";
};

export function isDuplicate(data, val) {
  console.log(typeof val !== "function");
  if (typeof val !== "function") {
    return (
      data.findIndex((v) => {
        return v === val;
      }) !== -1
    );
  } else {
    return data.findIndex(val) !== -1;
  }
}
const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const assignKey = (type, value) => {
  let item = {};
  item[type] = { ...value };
  return item;
};
const findAndExecuteAjax = (type) => {
  switch (type) {
    case "name":
    case "email":
    case "organization_role":
    case "organization_name":
    case "phonenumber":
    case "unit":
    case "createdby":
    case "subscription":
    case "gstin":
    case "amount":
    case "invoice_number":
    case "industryType":
      return (operator, value) => {
        if (operator === "Contains of") {
          return assignKey(type, { $regex: value, $options: "i" });
        }
        if (operator === "Starts With") {
          return assignKey(type, { $regex: `^${value}`, $options: "i" });
        }
        if (operator === "Ends With") {
          return assignKey(type, { $regex: `${value}$`, $options: "i" });
        }
        if (operator === "Equals To") {
          return assignKey(type, { $regex: `^${value}$`, $options: "i" });
        }
      };

    case "status":
    case "type":
    case "category":
      return (operator, value) => {
        if (operator === "Equals To") {
          return { [type]: value };
        }
      };
    case "createdon":
    case "createdOn":
      return (operator, value) => {
        var timestamp = new Date(value.split(".").join("-")).getTime() / 1000;
        if (operator === "Equals To") {
          let gte = new Date(`${value}T00:00:00+05:30`).getTime() / 1000;
          let lte = new Date(`${value}T23:59:59+05:30`).getTime() / 1000;
          console.log(value, "dfghjk");
          return assignKey(type, { $gte: gte, $lte: lte });
        }
        if (operator === "Greater than") {
          let gt = new Date(`${value}T23:59:59+05:30`).getTime() / 1000;
          return assignKey(type, { $gt: gt });
        }
        if (operator === "Lesser than") {
          let lt = new Date(`${value}T00:00:00+05:30`).getTime() / 1000;
          return assignKey(type, { $lt: lt });
        }
        if (operator === "Greater than or Equal To") {
          return assignKey(type, { $gte: timestamp });
        }
        if (operator === "Less than or Equals To") {
          return assignKey(type, { $lte: timestamp });
        }
      };
    case "value":
      return (operator, value) => {
        let val = parseInt(value);
        if (operator === "Equals To") {
          return assignKey(type, { $eq: val });
        }
        if (operator === "Greater than") {
          return assignKey(type, { $gt: val });
        }
        if (operator === "Lesser than") {
          return assignKey(type, { $lt: val });
        }
        if (operator === "Greater than or Equal To") {
          return assignKey(type, { $gte: val });
        }
        if (operator === "Less than or Equals To") {
          return assignKey(type, { $lte: val });
        }
      };

    default:
      return {};
  }
};

const getMonth = (num) => {
  let data = [
    "",
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return data[num];
};

function csvToArray(str, delimiter = ",") {
  // slice from start of text to the first \n index
  // use split to create an array from string by delimiter
  const headers = str
    .slice(0, str.indexOf("\n"))
    .split(delimiter)
    .map((f) => f.split("\r")[0]);
  // slice from \n index + 1 to the end of the text
  // use split to create an array of each csv value row
  const rows = str
    .slice(str.indexOf("\n") + 1)
    .split("\n")
    .map((f) => f.split("\r")[0]);
  // Map the rows
  // split values from each row into an array
  // use headers.reduce to create an object
  // object properties derived from headers:values
  // the object passed as an element of the array

  const arr = rows.map(function (row) {
    const values = row.split(delimiter);
    const el = headers.reduce(function (object, header, index) {
      object[header] = values[index];
      return object;
    }, {});
    return el;
  });

  // return the array
  return arr;
}

const sectionsSequenceOrder = (arr) => {
  console.log("delete sections 789", 1, arr);
  let payload = [];
  arr.forEach((item) => {
    if (item.Head_ID === -1) {
      payload.push({
        id: item._id,
        childSequenceArray: [
          ...arr
            .filter((i) => i.Head_ID === item.ID)
            .map((r) => {
              return { id: r._id };
            }),
        ],
      });
    }
  });
  return payload;
};

const helpFunc = {
  getDateFormat,
  validateEmail,
  findAndExecuteAjax,
  disableDeleteFnc,
  getMonth,
  getDateFormatAdvance,
  date_Func,
  csvToArray,
  checkImage,
  decodeAccessToken,
  sectionsSequenceOrder,
};
export default helpFunc;

