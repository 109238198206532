import React, { Component } from "react";
import { Typography, Grid, Button, Divider } from "@material-ui/core";
import Styles from "./styles";
import Breadcrumb from "components/Breadcrumbs/Breadcrumb";
import ResizablePanels from "components/ResizablePanel";
import TreeComponent from "./components/TreeComponent";
import {
  changeNodeAtPath,
  addNodeUnderParent,
} from "@nosferatu500/react-sortable-tree";
import {
  removeNodeAtPath,
  addArrayElement,
  removeArrayElement,
  getFlatDataFromTree,
} from "helpers/tree-utils";
import ValueMapFlowRenderer from "./components/ValueMapFlowRender";
import extractschema from "helpers/extractSchema";
import DataStandardAPIs from "../../api/DataStandardsAPIs/index";
import ReactFlow, {
  applyEdgeChanges,
  applyNodeChanges,
} from "react-flow-renderer";
import APIs from "../../api/ApplicationAPIs/index";
import { dltMsg } from "helpers/helpers";
import { confirmDialog } from "components/DeleteConfirmationDialog/Index";
import FieldValueModal from "./components/FieldValueModal";
import Loader from "components/common/stuff/Loader";
import { withStyles } from "@material-ui/styles";
import { withSnackbar } from "notistack";
import actions from "./redux/action";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
const initialState = {
  ajax: true,
  type: "",
  cdu_nodes: [],
  api_nodes: [],
  arrows: [],
  isValueMapping: false,
  flowNodes: [],
  flowEdges: [],
  appID: 0,
  disabled: false,
  edit: false,
  apiData: {},
  CduDataStandards: [],
  cduFields: {},
  pageFrom: "",
  api_Type: null,
  appName: "",
};

class DataFabric extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
    };
  }

  cduFieldNameChecking = (fieldname, type) => {
    this.setState({
      cduFields: {
        ...this.state.cduFields,
        [fieldname]: type,
      },
    });
  };

  getCDUmasterDetails = async (filter) => {
    this.props.setLoading(true);
    try {
      let res = await DataStandardAPIs.fetchAllDataStandardsWithParams(
        undefined,
        undefined,
        JSON.stringify(filter)
      );
      this.setState({ CduDataStandards: res.data || [] });
      this.props.setLoading(false);
    } catch (err) {
      console.error(err);
      this.props.setLoading(false);
    }
  };

  onLoadCheckcduValue = (data) => {
    if (data.length !== 0) {
      this.copyNode(data);
    }
  };

  setNodesFromAPIData = (condition1, condition2, apiData, page_type) => {
    //condition1 type==="cdutoapi"
    //condition2  type==="apitocdu"
    if (condition1) {
      apiData.req = apiData.req || {};
      let flowNodes = (apiData.req.nodes || []).map((node) => {
        let newNode = { ...node };

        newNode.data = {
          ...node.data,
          onChangeNodeData: this.onChangeNodeData,
          onNodeDelete: this.onNodeDelete,
          getEdgesWithNodeIdAndHandleId: this.getEdgesWithNodeIdAndHandleId,
          deleteMultipleEdges: this.deleteMultipleEdges,
        };

        return newNode;
      });
      let flowEdges = (apiData.req.edges || []).map((edge) => {
        let newEdge = { ...edge };
        if (!newEdge.data) {
          newEdge.data = {};
        }
        newEdge.data = { ...newEdge.data, onDeleteEdge: this.deleteEdge };
        return newEdge;
      });
      this.setState({
        api_nodes: apiData.req.api_nodes || [],
        cdu_nodes: apiData.req.cdu_nodes || [],
        flowNodes: flowNodes,
        flowEdges: flowEdges,
      });
      page_type === "ApplicationAPI" &&
        this.onLoadCheckcduValue(apiData.req.cdu_nodes || []);
    }

    if (condition2) {
      apiData.res = apiData.res || {};
      let flowNodes = (apiData.res.nodes || []).map((node) => {
        let newNode = { ...node };
        newNode.data = {
          ...node.data,
          onChangeNodeData: this.onChangeNodeData,
          onNodeDelete: this.onNodeDelete,
          getEdgesWithNodeIdAndHandleId: this.getEdgesWithNodeIdAndHandleId,
          deleteMultipleEdges: this.deleteMultipleEdges,
        };
        return { ...newNode };
      });
      let flowEdges = (apiData.res.edges || []).map((edge) => {
        let newEdge = { ...edge };
        if (!newEdge.data) {
          newEdge.data = {};
        }
        newEdge.data = { ...newEdge.data, onDeleteEdge: this.deleteEdge };
        return { ...newEdge };
      });
      this.setState({
        api_nodes: apiData.res.api_nodes || [],
        cdu_nodes: apiData.res.cdu_nodes || [],
        flowNodes: flowNodes,
        flowEdges: flowEdges,
      });
      page_type === "ApplicationAPI" &&
        this.onLoadCheckcduValue(apiData.res.cdu_nodes || []);
    }
  };

  async componentDidMount() {
    const { location } = this.props;
    if (location.state !== undefined) {
      let {
        type,
        apiData,
        edit,
        ajax,
        appID,
        disabled,
        pageFrom,
        api_Type,
        toggleBtn,
        appName,
      } = location.state;

      if (apiData.industryType && apiData.industryType.trim().length !== 0) {
        await this.getCDUmasterDetails({
          industryType: `${apiData.industryType}`,
          status: "active",
        });
      }
      if (pageFrom !== "outgoingAPI") {
        // this.setNodesFromAPIData( type, apiData);
        this.setNodesFromAPIData(
          type === "cdutoapi",
          type === "apitocdu",
          apiData,
          "ApplicationAPI"
        );
      } else {
        this.setNodesFromAPIData(
          type === "apitocdu",
          type === "cdutoapi",
          apiData
        );
      }
      this.setState({
        type: type,
        apiData,
        edit,
        ajax,
        appID,
        disabled,
        pageFrom,
        api_Type,
        toggleBtn,
        appName,
      });
    }
  }

  setFlowNodes = (nodes) => {
    console.log("caling.....!nodes oops", nodes);
    this.setState({ flowNodes: nodes });
  };

  setFlowEdges = (edges) => {
    console.log(edges);
    this.setState({ flowEdges: edges });
  };

  addArrows = (arrow) => {
    this.setState({ arrows: [...this.state.arrows, arrow] });
  };

  toogleValueMapping = () => {
    const { state } = this;
    let { flowNodes } = state;
    if (flowNodes.length == 0) {
      flowNodes = [
        {
          id: "api",
          data: {
            name: "Passive Metadata",
            data: state.api_nodes,
            panel: "api",
            type: state.type,
            onChangeNodeData: this.onChangeNodeData,
          },
          position: { x: 0, y: 0 },
          type: "sortableTree",
          draggable: true,
        },
        {
          id: "cdu",
          data: {
            name: "Active Metadata",
            data: state.cdu_nodes,
            panel: "cdu",
            type: state.type,
            onChangeNodeData: this.onChangeNodeData,
          },
          position: { x: 1200, y: 0 },
          type: "sortableTree",
          draggable: true,
        },
      ];
    }
    flowNodes = flowNodes.map((node) => {
      if (node.id == "cdu") {
        return {
          ...node,
          data: {
            ...node.data,
            name: "Active Metadata",
            data: state.cdu_nodes,
            panel: "cdu",
            type: state.type,
            onChangeNodeData: this.onChangeNodeData,
          },
          position: { x: 1200, y: 0 },
          draggable: true,
        };
      }
      if (node.id == "api") {
        return {
          ...node,
          data: {
            ...node.data,
            name: "Passive Metadata",
            data: state.api_nodes,
            panel: "api",
            type: state.type,
            onChangeNodeData: this.onChangeNodeData,
          },
          position: { x: 0, y: 0 },
          draggable: true,
        };
      }
      return node;
    });
    this.setState({
      isValueMapping: !this.state.isValueMapping,
      flowNodes: flowNodes,
    });
  };

  addInitialNode = (panel) => {
    this.setState({
      api_nodes: [{ title: "root", type: "object" }],
    });
  };
  display_msg = (msg, type) => {
    this.props.enqueueSnackbar(msg, {
      variant: type ? "success" : "error",
    });
  };

  checkValidCdu = async (node, indType) => {
    let res = null;
    let newNode = {};
    if (node.title === "root" || node.title === "arrayElement") {
      newNode = { ...node, cduField: true };
    } else {
      try {
        res = await APIs.checkCDUField(node.title, indType);
        if (!res.data.data) {
          newNode = { ...node, cduField: false };
        } else {
          newNode = { ...node, cduField: true };
        }
      } catch (err) {
        newNode = { ...node, cduField: false };
      }
    }

    if (node.children && node.children.length !== 0) {
      let promises = [];
      for (let i in node.children) {
        let nds = node.children[i];
        if (nds.title.trim().length === 0) {
          return "empty";
        }
        try {
          let obj = await this.checkValidCdu(nds, indType);
          if (obj === "empty") {
            return "empty";
          }
          promises.push(obj);
        } catch (err) {}
      }
      // let promises = node.children.map(async (node) => {
      //   try {
      //     return await this.checkValidCdu(node, indType);
      //   } catch (err) {}
      // });
      let children = await Promise.all(promises);
      newNode = { ...newNode, children: [...children] };
    }
    return newNode;
  };

  copyNode = async (cdu_nodes_data) => {
    const { location } = this.props;
    const { apiData, api_nodes } = location.state;
    const API_NODES = [...(cdu_nodes_data || this.state.api_nodes)];

    let result = [];
    for (let i in API_NODES) {
      let nds = API_NODES[i];
      if (nds.title.trim().length === 0) {
        this.display_msg("Can not copy empty values", false);
        return;
      }
      try {
        let res = await this.checkValidCdu(nds, apiData.industryType);
        if (res === "empty") {
          this.display_msg("Can not copy empty values", false);
          return;
        }
        result.push(res);
      } catch (err) {}
    }

    // const result = API_NODES.map(async (nds, i) => {
    //   try {
    //     return await this.checkValidCdu(nds, apiData.industryType);
    //   } catch (err) {}
    // });

    console.log("result", result);
    Promise.all(result).then((nodes) => {
      this.setState({ cdu_nodes: [...nodes] });
    });
  };

  insertNewNode = (panel, key) => {
    if (panel == "cdu") {
      this.setState({
        cdu_nodes: addNodeUnderParent({
          treeData: this.state.cdu_nodes,
          parentKey: key,
          newNode: { title: "", type: "string" },
          getNodeKey: ({ treeIndex }) => treeIndex,
          expandParent: true,
          addAsFirstChild: true,
        }).treeData,
      });
    } else {
      this.setState({
        api_nodes: addNodeUnderParent({
          treeData: this.state.api_nodes,
          parentKey: key,
          newNode: { title: "", type: "string" },
          getNodeKey: ({ treeIndex }) => treeIndex,
          expandParent: true,
          addAsFirstChild: true,
        }).treeData,
      });
    }
  };

  generateTitleId = (panel, path, nodes) => {
    let ar_nodes = getFlatDataFromTree({
      treeData: nodes,
      getNodeKey: ({ treeIndex }) => treeIndex,
    });
    let title =
      // panel +
      // "__$__" +
      path
        .map((f) => {
          console.log("ar_nodes", ar_nodes[f]);
          return ar_nodes[f].node.title;
        })
        .join("__");
    console.log("ar_nodes", title);
    if (title.includes("arrayElement")) {
      return title;
    } else {
      return title.split("root__").join("") || null;
    }
  };

  updateNodeType = (panel, type, node, path) => {
    console.log({ panel, type, node, path });
    let prevType = node.type;
    node.type = type;
    this.removeFlowEdges(panel, path);
    if (panel == "cdu") {
      let newNodes = [];
      if (type == "array") {
        // if the current state is array - then add arrayElement and add children of current element to array Element.
        newNodes = addArrayElement(path, node, this.state.cdu_nodes);
      } else if (prevType == "array") {
        // if the previous state is array - then remove array element and add array element children to current node.
        newNodes = removeArrayElement(path, node, this.state.cdu_nodes);
      } else {
        newNodes = changeNodeAtPath({
          treeData: this.state.cdu_nodes,
          path,
          getNodeKey: ({ treeIndex }) => treeIndex,
          newNode: { ...node },
        });
      }
      this.setState({
        cdu_nodes: [...newNodes],
      });
    } else {
      let newNodes = [];
      if (type == "array") {
        // if the current state is array - then add arrayElement and add children of current element to array Element.
        newNodes = addArrayElement(path, node, this.state.api_nodes);
      } else if (prevType == "array") {
        // if the previous state is array - then remove array element and add array element children to current node.
        newNodes = removeArrayElement(path, node, this.state.api_nodes);
      } else {
        newNodes = changeNodeAtPath({
          treeData: this.state.api_nodes,
          path,
          getNodeKey: ({ treeIndex }) => treeIndex,
          newNode: { ...node },
        });
      }
      this.setState({
        api_nodes: [...newNodes],
      });
    }
  };

  updateNodeTitle = (panel, title, node, path) => {
    console.log({ panel, title, node, path });
    this.removeFlowEdges(panel, path);
    // let ids = node.id.split("__");
    // ids.length--; // remove last element
    // ids.push(title); // add new title
    // node.id = ids.join("__"); // join
    node.title = title;
    if (panel === "cdu") {
      node.cduField = true;
      // node.id = node.id.replace(node.title, title);
      this.setState({
        cdu_nodes: changeNodeAtPath({
          treeData: this.state.cdu_nodes,
          path,
          getNodeKey: ({ treeIndex }) => treeIndex,
          newNode: { ...node },
        }),
      });
    } else {
      this.setState({
        api_nodes: changeNodeAtPath({
          treeData: this.state.api_nodes,
          path,
          getNodeKey: ({ treeIndex }) => treeIndex,
          newNode: { ...node },
        }),
      });
    }
  };

  removeNode = (panel, path) => {
    this.removeFlowEdges(panel, path);
    console.log("all coonected edges", panel, path);
    if (panel == "cdu") {
      this.setState({
        cdu_nodes: removeNodeAtPath({
          treeData: this.state.cdu_nodes,
          path,
          getNodeKey: ({ treeIndex }) => treeIndex,
        }),
      });
    } else {
      this.setState({
        api_nodes: removeNodeAtPath({
          treeData: this.state.api_nodes,
          path,
          getNodeKey: ({ treeIndex }) => treeIndex,
        }),
      });
    }
  };

  removeTree = () => {
    const { pageFrom, cdu_nodes } = this.state;
    confirmDialog("Are you sure you want to remove?", () => {
      this.setState({
        cdu_nodes: pageFrom === "outgoingAPI" ? cdu_nodes : [],
        api_nodes: [],
        flowEdges: [],
        flowNodes: [],
      });
    });
  };

  routingParamsToApiPage = () => {
    const mapping = extractschema(
      this.state.api_nodes,
      this.state.cdu_nodes,
      this.state.flowNodes.map((node) => {
        let newnode = { ...node };
        delete newnode.data.onChangeNodeData;
        delete newnode.data.onNodeDelete;
        delete newnode.data.getEdgesWithNodeIdAndHandleId;
        delete newnode.data.deleteMultipleEdges;
        return newnode;
      }),
      this.state.flowEdges.map((edge) => {
        let newedge = { ...edge };
        console.log(newedge);
        delete newedge.data.onDeleteEdge;
        return newedge;
      }),
      this.state.type
    );
    const { apiData, edit, ajax, appID, disabled, type, api_Type, appName } =
      this.state;
    console.log("xxxxxxxxx", mapping);
    return {
      apiData,
      edit,
      ajax,
      appID,
      disabled,
      mapping,
      from: `dataFabric-${type}`,
      api_Type,
      appName,
    };
  };

  filterFieldNotRequired = (children) => {
    console.log("filter field  is not required", 123, children);
    let flag = false;
    children.map((n, i) => {
      if (n.children.length !== 0) {
        this.filterFieldNotRequired(n.children);
      }
      if (
        n.type === "string" ||
        (n.title === "arrayElement" &&
          n.type === "object" &&
          n.children.length === 0)
      ) {
        console.log("filter field  is not required", "true", n, i);
        flag = true;
      } else {
        console.log("filter field  is not required", "false", n, i);
        flag = false;
      }
    });
    return flag;
  };

  onClickSave = (e) => {
    let err_obj = {};
    const filterNodes = this.state.flowNodes.filter((n) => n.type === "filter");
    if (filterNodes.length !== 0) {
      filterNodes.map((nd) => {
        if (nd.data && nd.data.input) {
          const { filterOp, filterValue, filterOn } = nd.data.input || {
            filterOp: "",
            filterValue: "",
            filterOn: "",
          };
          let children = nd.data.input?.connectedNode?.children || [];
          if (children[0] && children[0].type !== "object") {
            err_obj = {
              filterOp: filterOp ? filterOp.trim().length === 0 : true,
              filterValue: filterValue ? filterValue.trim().length === 0 : true,
              filterOn: false,
            };
          } else {
            err_obj = {
              filterOp: filterOp ? filterOp.trim().length === 0 : true,
              filterValue: filterValue ? filterValue.trim().length === 0 : true,
              filterOn: filterOn ? filterOn.trim().length === 0 : true,
            };
          }
        }
      });
    }

    if (err_obj.filterOn || err_obj.filterOp || err_obj.filterValue) {
      this.display_msg("Filter fields are required", false);
      return;
    }

    let data = this.routingParamsToApiPage();
    console.log("datadata", data);
    const { pageFrom } = this.state;
    const url =
      pageFrom !== "outgoingAPI"
        ? "/app/create-api-form"
        : "/app/outgoing-api-form";
    console.log({ ...data });
    this.props.history.push(url, {
      ...data,
    });
    // need to add to redux store for transfering data to ...
  };

  canValueMapping(node) {
    console.log(node.title, node.children?.length, node.cduField);
    var childrenValue = true;
    if (node.children && node.children.length !== 0) {
      node.children.forEach((childNode) => {
        let value = this.canValueMapping(childNode);
        childrenValue = childrenValue && value;
      });
    }
    return node.cduField && childrenValue;
  }

  onChangeNodeData = (nodeId, nodeType, nodeData) => {
    let newNodes = this.state.flowNodes.map((node) => {
      if (node.id === nodeId) {
        node.data = {
          ...node.data,
          input: { ...node.data.input, ...nodeData },
        };
      }
      return node;
    });
    this.setFlowNodes([...newNodes]);
  };
  updatefilterFlowNodes = (edgeId) => {
    let flow_nodes = [];
    flow_nodes = this.state.flowNodes.map((f) => {
      let flag = f.data?.input?.edgeId === edgeId || false;
      if (flag) {
        return {
          ...f,
          data: {
            ...f.data,
            input: {},
          },
        };
      } else {
        return {
          ...f,
        };
      }
    });
    this.setState({
      flowNodes: [...flow_nodes],
    });
  };
  update_flow_nodes = (edgeId, title) => {
    let flow_Nodes = [];
    let edge_ids = [];
    flow_Nodes = this.state.flowNodes.map((f) => {
      if (f.type === "expression" && f.data.input.connectedNode) {
        let filterConnectedNode = f.data.input.connectedNode.filter(
          (f) => !edgeId.includes(f.edgeId)
        );
        return {
          ...f,
          data: {
            ...f.data,
            input: {
              ...f.data.input,
              connectedNode: [...filterConnectedNode],
            },
          },
        };
      } else if (
        (f.type === "filter" && edgeId.includes(f.data?.input?.edgeId)) ||
        (f.type === "filter" &&
          title &&
          title.includes(f.data?.input.parentPath))
      ) {
        if (
          title &&
          title.includes(f.data.input.parentPath) &&
          f.data.input.edgeId
        ) {
          edge_ids.push(
            f.data.input.edgeId
              .split(f.data?.input.parentPath + "-")[1]
              .split("input")[0]
          );
        }
        return {
          ...f,
          data: {
            ...f.data,
            input: {},
          },
        };
      } else {
        return {
          ...f,
        };
      }
    });
    this.setState({
      flowNodes: flow_Nodes,
    });
    edge_ids.length !== 0 && this.manageFilterInputEdges(edge_ids);
  };

  removeFlowEdges = (panel, path) => {
    if (panel === "cdu") {
      const title = this.generateTitleId(panel, path, this.state.cdu_nodes);
      let type = this.state.type === "cdutoapi" ? "source" : "target";
      let edges = this.getEdgesWithNodeIdAndHandleId(type, "cdu", title);
      console.log("ar_nodes", 2, edges, title, type);
      this.deleteMultipleEdges(edges.map((f) => f.id));
      this.update_flow_nodes(
        edges.map((f) => f.id),
        title
      );
    } else {
      const title = this.generateTitleId(panel, path, this.state.api_nodes);
      let type = this.state.type === "apitocdu" ? "source" : "target";
      let edges = this.getEdgesWithNodeIdAndHandleId(type, "api", title);
      this.deleteMultipleEdges(edges.map((f) => f.id));
      this.update_flow_nodes(
        edges.map((f) => f.id),
        title
      );
    }
  };

  manageFilterInputEdges = (filterInputEdge) => {
    console.log("deleting multiple edges", 2, filterInputEdge);
    let flw_edges = this.state.flowEdges.filter((edge) => {
      if (edge.target === "api" && !filterInputEdge.includes(edge.source)) {
        return edge;
      }
      if (edge.source === "cdu" && !filterInputEdge.includes(edge.target)) {
        return edge;
      }
    });
    this.setState({
      flowEdges: flw_edges,
    });
  };

  deleteEdge = (edgeId) => {
    let flow_edge = this.state.flowEdges.find((f) => f.id === edgeId);
    let filteredEdges = this.state.flowEdges.filter((f) => f.id !== edgeId);
    this.setState({ flowEdges: filteredEdges });
    //remove flownodes connected node
    if (
      flow_edge.id.includes("expression") ||
      flow_edge.id.includes("filter")
    ) {
      this.update_flow_nodes(flow_edge.id);
    }
    // this.manageFilterInputEdges(flow_edge.id, flow_edge.id);
  };

  deleteMultipleEdges = (edgeIds) => {
    if (edgeIds.length !== 0) {
      let filteredEdges = this.state.flowEdges.filter(
        (f) => !edgeIds.includes(f.id)
      );
      this.setState({ flowEdges: filteredEdges });
    } else {
      return null;
    }
  };

  getEdgesWithNodeIdAndHandleId = (type, nodeId, handleId) => {
    console.log("fjgfjgr6ruyr56", handleId);
    let edges = this.state.flowEdges.filter((f) => {
      if (type === "source") {
        return f.source === nodeId && f.sourceHandle.includes(handleId);
      } else {
        return f.target === nodeId && f.targetHandle.includes(handleId);
      }
    });
    return edges;
  };

  isAValidConnection = (edge) => {
    // get target node and source node
    //
  };
  onNodeDelete = (nodeId) => {
    confirmDialog(dltMsg("Node"), () => this.deleteNode(nodeId));
    //prosp.onSuccess(Data)
  };

  deleteNode = (nodeId) => {
    console.log(nodeId, this.state.flowNodes);
    let newNodes = this.state.flowNodes.filter((node) => node.id != nodeId);
    let edges = this.state.flowEdges.filter(
      (edge) => edge.source != nodeId && edge.target != nodeId
    );
    this.setFlowNodes([...newNodes]);
    this.setFlowEdges([...edges]);
  };

  toggleInputOutput = () => {
    //condition one type==="cdutoapi"
    //condition two type==="apitocdu"
    const currentType = this.state.type;
    if (currentType === "cdutoapi") {
      if (this.state.pageFrom !== "outgoingAPI") {
        this.setNodesFromAPIData(false, true, this.state.apiData);
      } else {
        this.setNodesFromAPIData(true, false, this.state.apiData);
      }
      // this.setNodesFromAPIData("apitocdu", this.state.apiData);
      this.setState({ type: "apitocdu" });
    } else {
      if (this.state.pageFrom !== "outgoingAPI") {
        this.setNodesFromAPIData(true, false, this.state.apiData);
      } else {
        this.setNodesFromAPIData(false, true, this.state.apiData);
      }
      // this.setNodesFromAPIData("cdutoapi", this.state.apiData);
      this.setState({ type: "cdutoapi" });
    }
  };

  render() {
    let { classes, location, load } = this.props;
    let { ajax, cdu_nodes, api_Type, toggleBtn, appName, apiData, pageFrom } =
      this.state;
    let { name } = apiData;
    let disabledMapping = true;
    cdu_nodes.forEach((nd) => {
      disabledMapping = disabledMapping && this.canValueMapping(nd);
    });
    console.log("this.props", this.props);
    const links = () => {
      if (this.state.pageFrom === "outgoingAPI") {
        return [
          { name: "Applications", url: "/app/applications" },
          {
            name: `${location?.state?.appName || "Application Details"}`,
            url: "/app/detail",
            routeParams: { appId: this.state.appID },
          },
          {
            name: `Outgoing API`,
            url: `/app/outgoing-api-form`,
            routeParams: {
              // ...this.routingParamsToApiPage(),
            },
            onClick: this.onClickSave,
          },
          {
            name: "Data Fabric",
          },
        ];
      } else {
        return [
          { name: "Applications", url: "/app/applications" },
          {
            name: `${
              ajax
                ? location?.state?.appName || "Application Details"
                : "Onboard Application"
            }`,
            url: `${ajax ? "/app/detail" : "/app/onboard-application"}`,
            routeParams: ajax ? { appId: this.state.appID } : { status: true },
          },
          {
            name: `${name}`,
            // name: `Application API`,
            url: `/app/create-api-form`,
            routeParams: {
              // ...this.routingParamsToApiPage(),
            },
            onClick: this.onClickSave,
          },
          {
            name: "Data Fabric",
          },
        ];
      }
    };
    console.log("data fab state", this.state);
    return (
      <>
        {load && <Loader />}

        <Breadcrumb {...this.props} links={[...links()]} />
        <Grid container spacing={0}>
          <Grid item md={12}>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {this.state.pageFrom === "outgoingAPI" ? (
                    <Typography variant="h6" className={classes.title}>
                      Data Fabric -{" "}
                      {this.state.type === "cdutoapi"
                        ? "Output Mapping"
                        : "Input Mapping"}
                    </Typography>
                  ) : (
                    <Typography variant="h6" className={classes.title}>
                      Data Fabric -{" "}
                      {this.state.type === "cdutoapi"
                        ? "Input Mapping"
                        : "Output Mapping"}
                    </Typography>
                  )}
                </div>
              </Grid>
              <Grid item md={6}>
                <Grid container spacing={2} justifyContent="flex-end">
                  {toggleBtn && !this.state.isValueMapping && (
                    <Grid item md={3}>
                      <Button
                        name="single"
                        size="small"
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => {
                          this.toggleInputOutput();
                        }}
                        style={{ color: "white" }}
                      >
                        Toggle Input/Output
                      </Button>
                    </Grid>
                  )}
                  {disabledMapping && (
                    <Grid item md={3}>
                      <Button
                        name="single"
                        size="small"
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => {
                          this.toogleValueMapping();
                        }}
                        style={{ color: "white" }}
                      >
                        {this.state.isValueMapping
                          ? "Structure Mapping"
                          : "Field Mapping"}
                      </Button>
                    </Grid>
                  )}
                  {disabledMapping && (
                    <Grid item md={3}>
                      <Button
                        name="single"
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          this.onClickSave();
                        }}
                        fullWidth
                        style={{ color: "white" }}
                      >
                        Save
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>

              <Grid item md={12}>
                <Divider className={classes.divider} />
              </Grid>
            </Grid>
          </Grid>

          {this.state.pageFrom == "appAPIs" && (
            <Grid item md={12}>
              {!this.state.isValueMapping ? (
                <ResizablePanels
                  key={1}
                  bkcolor="#f8f9fa"
                  displayDirection="row"
                  width="100%"
                  height="750px"
                  panelsSize={[50, 50]}
                  sizeUnitMeasure="%"
                >
                  <TreeComponent
                    {...this.props}
                    key={2}
                    name={"Passive Metadata"}
                    setNodes={(nodes) => {
                      this.setState({ api_nodes: nodes });
                    }}
                    panel={"api"}
                    nodes={this.state.api_nodes}
                    updateNodeType={this.updateNodeType}
                    updateNodeTitle={this.updateNodeTitle}
                    insertNewNode={this.insertNewNode}
                    removeNode={this.removeNode}
                    addInitialNode={this.addInitialNode}
                    copyNode={this.copyNode}
                    CduDataStandards={this.state.CduDataStandards}
                    removeTree={this.removeTree}
                  />
                  <TreeComponent
                    {...this.props}
                    key={1}
                    name={"Active Metadata"}
                    setNodes={(nodes) => {
                      this.setState({ cdu_nodes: nodes });
                    }}
                    panel={"cdu"}
                    nodes={this.state.cdu_nodes}
                    updateNodeType={this.updateNodeType}
                    updateNodeTitle={this.updateNodeTitle}
                    insertNewNode={this.insertNewNode}
                    removeNode={this.removeNode}
                    addInitialNode={this.addInitialNode}
                    copyNode={this.copyNode}
                    CduDataStandards={this.state.CduDataStandards}
                    removeTree={this.removeTree}
                  />
                </ResizablePanels>
              ) : (
                <ValueMapFlowRenderer
                  cdu_nodes={this.state.cdu_nodes}
                  api_nodes={this.state.api_nodes}
                  type={this.state.type}
                  edges={this.state.flowEdges}
                  nodes={this.state.flowNodes}
                  onNodesChange={this.setFlowNodes}
                  onEdgesChange={this.setFlowEdges}
                  onChangeNodeData={this.onChangeNodeData}
                  onNodeDelete={this.onNodeDelete}
                  deleteEdge={this.deleteEdge}
                  deleteMultipleEdges={this.deleteMultipleEdges}
                  getEdgesWithNodeIdAndHandleId={
                    this.getEdgesWithNodeIdAndHandleId
                  }
                />
              )}
            </Grid>
          )}

          {this.state.pageFrom === "outgoingAPI" && (
            <Grid item md={12}>
              {!this.state.isValueMapping ? (
                <ResizablePanels
                  key={1}
                  bkcolor="#f8f9fa"
                  displayDirection="row"
                  width="100%"
                  height="750px"
                  panelsSize={[50, 50]}
                  sizeUnitMeasure="%"
                >
                  <TreeComponent
                    name={"Active Metadata"}
                    setNodes={(nodes) => {
                      this.setState({ cdu_nodes: nodes });
                    }}
                    panel="cdu"
                    nodes={this.state.cdu_nodes}
                    updateNodeType={this.updateNodeType}
                    updateNodeTitle={this.updateNodeTitle}
                    insertNewNode={this.insertNewNode}
                    removeNode={this.removeNode}
                    addInitialNode={this.addInitialNode}
                    copyNode={this.copyNode}
                    CduDataStandards={this.state.CduDataStandards}
                    removeTree={this.removeTree}
                    pageFrom={pageFrom}
                    {...this.props}
                  />
                  <TreeComponent
                    key={2}
                    name={"Passive Metadata"}
                    setNodes={(nodes) => {
                      this.setState({ api_nodes: nodes });
                    }}
                    panel="api"
                    nodes={this.state.api_nodes}
                    updateNodeType={this.updateNodeType}
                    updateNodeTitle={this.updateNodeTitle}
                    insertNewNode={this.insertNewNode}
                    removeNode={this.removeNode}
                    addInitialNode={this.addInitialNode}
                    copyNode={this.copyNode}
                    CduDataStandards={this.state.CduDataStandards}
                    removeTree={this.removeTree}
                    pageFrom={pageFrom}
                    {...this.props}
                  />
                </ResizablePanels>
              ) : (
                <ValueMapFlowRenderer
                  cdu_nodes={this.state.cdu_nodes}
                  api_nodes={this.state.api_nodes}
                  type={this.state.type}
                  edges={this.state.flowEdges}
                  nodes={this.state.flowNodes}
                  onNodesChange={this.setFlowNodes}
                  onEdgesChange={this.setFlowEdges}
                  onChangeNodeData={this.onChangeNodeData}
                  onNodeDelete={this.onNodeDelete}
                  deleteEdge={this.deleteEdge}
                  deleteMultipleEdges={this.deleteMultipleEdges}
                  getEdgesWithNodeIdAndHandleId={
                    this.getEdgesWithNodeIdAndHandleId
                  }
                />
              )}
            </Grid>
          )}
        </Grid>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    load: state.dataFabricReducer.load,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (bool) => dispatch(actions.loadingDispatchDataFabric(bool)),
  };
};
const DataFabricComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(DataFabric);
export default withRouter(
  withStyles(Styles)(withSnackbar(DataFabricComponent))
);

// export default withRouter(withStyles(Styles)(withSnackbar(DataFabric)));
