import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
// import Menu from "@material-ui/core/Menu";
import AppBar from "@material-ui/core/AppBar";
import Link from "@material-ui/core/Link";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Popover from "@material-ui/core/Popover";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import MenuIcon from "@material-ui/icons/Menu";
import { Grid, Avatar, Divider } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Badge from "@material-ui/core/Badge";
import IconButton from "@material-ui/core/IconButton";
import MatxMenu from "./MatxMenu";
import { useHistory } from "react-router-dom";
// import Logo from "./Images/Logonew.png";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import Closeicon from "./Images/close 2.svg";
import Menuicon from "./Images/toggler.svg";
import Bell from "./Images/notification.svg";
import prashant_pic from "./Images/prashant.jpeg";
import Pipe from "./Images/Pipe.svg";
// import Backdrop from "@material-ui/core/Backdrop";
import useRecentlyVisted from "customHooks/useRecentlyVisted";
import Logo from "assets/images/Kailash_new.png";
import * as types from "../../views/UserProfile/redux/actionTypes";
import MuiLink from "@material-ui/core/Link";
import { hasPermission } from "helpers/permissons";
import appApi from "api/ApplicationAPIs/index";
import { useSnackbar } from "notistack";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { fontSize } from "suneditor/src/plugins";

const MuiLinkComponent = withStyles({
  root: {
    "&[disabled]": {
      color: "grey",
      cursor: "default",
      "&:hover": {
        textDecoration: "none",
        color: "grey",
      },
    },
  },
})(MuiLink);

const LinkComponent = ({ name, url, permission, resource, handleClick }) => {
  const flag = hasPermission(permission, resource);
  return (
    <>
      {flag ? (
        <Link
          style={{
            textDecoration: "none",
            color: "#000",
            cursor: "pointer",
          }}
          onClick={(e) => {
            handleClick(e, name, url);
          }}
        >
          {name}
        </Link>
      ) : (
        <MuiLinkComponent disabled id="disabled">
          {name}
        </MuiLinkComponent>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  papernew: {
    overflow: "hidden",
    marginTop: 45,
    width: "100vw",
    height: "520px",
    backgroundColor: "#f2f2f2",
  },
  typography: {
    padding: theme.spacing(2),
  },
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(3),
  },
  demo1: {
    margin: 20,
    backgroundColor: theme.palette.background.paper,
    backgroundColor: "#f2f2f2",
  },
  grow: {
    backgroundColor: "black",
    flexGrow: 1,
  },
  title: {
    color: "white",
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  profilepopup: {
    width: "500px",
    marginTop: 50,

    marginLeft: 80,
    color: "white",
  },
  paper: {
    marginTop: 49,
  },
  sectionDesktop: {
    display: "none",

    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  typography: {
    padding: theme.spacing(2),
  },
  vline: {
    [theme.breakpoints.up("md")]: {
      marginTop: "1%",
      borderLeft: "2px solid #002855",
      opacity: "0.3",
      height: "450px",
    },
  },
  vlineshort: {
    [theme.breakpoints.up("md")]: {
      marginTop: "3%",
      borderLeft: "2px solid #002855",
      opacity: "0.3",
      height: "350px",
    },
  },
  hline: {
    borderBottom: "2px solid #707070",
    opacity: "1",
    width: "120px",
  },
  hlineshort: {
    borderBottom: "2px solid #707070",
    opacity: "1",
    width: "55px",
  },
  // animation: {
  //   marginRight: theme.spacing(2),
  //   height: 35,
  //   width: 35,
  //   borderRadius: 10,
  //   transition: ".3s",
  //   backgroundColor: "#F2F2F2",
  //   transform: "rotate(45deg)",

  //   "&.MuiIconButton-root:hover": {
  //     backgroundColor: "#F2F2F2",
  //   },
  // },
  pipe: {
    width: "1.5px",
    marginRight: "2%",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  menutextcolor: {
    color: "#161616",
    marginTop: "1%",
    cursor: "context-menu",
    fontFamily: "Nunito",
    fontSize: "12px",
    // opacity: 3,
  },
  menuheadingcolor: {
    fontWeight: "bold",
    cursor: " context-menu",
  },
  menuPapernew: {
    width: "245px",
    height: "auto",
    marginTop: 33,
    borderRadius: 0,
    "& .MuiList-padding": {
      padding: 0,
    },
    boxShadow: "0px 3px 6px #00000029",
    border: "1px solid #BEBEBE",
    opacity: 1,
    backgroundColor: "#f2f2f2",
  },
  menuItemnew: {
    height: "3.7rem",
    color: theme.palette.common.black,
    opacity: 1,
    "&:hover": {
      opacity: 0.9,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    height: 35,
    width: 35,
    borderRadius: 10,
    transition: ".3s",
    backgroundColor: "transparent",
  },
  menuButtonClose: {
    marginRight: theme.spacing(2),
    height: 35,
    width: 35,
    borderRadius: 10,
    transition: ".3s",
    backgroundColor: "#F2F2F2",
    transform: "rotate(45deg)",
    "&.MuiIconButton-root:hover": {
      backgroundColor: "#F2F2F2",
    },
    "& img": {
      transform: "rotate(-45deg)",
    },
  },
  defaultTextColor: {},
}));

const MyMenu = (type) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.apearenceReducer);
  const { loading: loadDetails, userInfo, success } = userDetails;
  const organizationInfo = useSelector((state) => state.organizationReducer);
  const { organizationDetails } = organizationInfo;
  const org_info = organizationDetails || {};
  const { enqueueSnackbar } = useSnackbar();

  const pushTo = (type) => {
    if (type) {
      history.push("/app/setting");
    } else {
      dispatch({ type: types.USER_RESET });
      dispatch({ type: types.RESET_ORGANIZATION });
      history.push("/login");
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [pages, markRecentlyVist] = useRecentlyVisted();
  const [expandBtn, setExpandBtn] = useState(false);
  const [unscenceCount, setUnsceneCount] = useState(0);

  const handleClick = (e, pageName, url) => {
    // store the app name and url
    e.preventDefault();
    markRecentlyVist({ pageName, url });
    setOpen(false);
    history.push(url);
  };

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = (e) => {
    setOpen(false);
  };

  const classes = useStyles();
  console.log("this56", userInfo, org_info);

  console.log("expand", expandBtn);
  const client = appApi.getWebSocket();

  useEffect(() => {
    if (client) {
      client.onopen = (e) => {
        console.log("websocket openn", e);
      };
      client.onmessage = (e) => {
        console.log("websocket message", e);
        const socketMessage = JSON.parse(e.data);
        const msg = socketMessage.message;
        // if (msg != undefined) {
        //   enqueueSnackbar(socketMessage?.message || "", {
        //     variant: "success",
        //   });
        // }
        setUnsceneCount(socketMessage?.unseen_count || 0);
        console.log("websocket message", socketMessage.message);
      };
      client.onclose = (e) => {
        console.log("websocket client onclose", e);
      };
      client.onerror = (e) => {
        console.log("websocket errorrrrrrrrrrrr nav", e);
      };
    }
  }, []);

  return (
    <div className={classes.grow} id="heading">
      <div>
        <AppBar color="primary" style={{ zIndex: "8888", height: "65px" }}>
          <Toolbar>
            <Typography className={classes.title} variant="h6" noWrap>
              <div>
                <img
                  src={org_info.logo || Logo}
                  alt="logo"
                  style={{ width: "80px" }}
                />
              </div>
            </Typography>
            <div>
              {/* onMouseOver={handleOpen} */}
              <div id="menubutton1" className="pl-4">
                {open ? (
                  <IconButton
                    edge="start"
                    className={classes.menuButtonClose}
                    aria-label="close drawer"
                    onMouseEnter={() => setOpen(false)}
                  >
                    <img src={Closeicon} width="13" alt="icon" />
                  </IconButton>
                ) : (
                  <IconButton
                    alt="menu"
                    edge="start"
                    className={classes.menuButton}
                    aria-label="open drawer"
                    onMouseEnter={() => setOpen(true)}
                  >
                    <img src={Menuicon} alt="menuicon" />
                  </IconButton>
                )}
              </div>
              <Popover
                // onMouseLeave={handleClose}
                style={{ margin: "0% -1% 0% -1%" }}
                className={classes.popover}
                classes={{
                  paper: classes.papernew,
                }}
                open={open}
              >
                <div
                  // onMouseOver={handleOpen}
                  onMouseLeave={handleClose}
                  className={classes.demo1}
                >
                  <Grid container>
                    <Grid item md={3} xs={12} className="p-2">
                      <Typography
                        variant="subtitle1"
                        color="primary"
                        className={classes.menuheadingcolor}
                        style={{ fontFamily: "Nunito", fontSize: "15px" }}
                      >
                        Recently Visited
                        <div className={classes.hline}></div>
                      </Typography>
                      <div className="sub_div1">
                        {pages.map((f) => (
                          <Typography
                            variant="subtitle2"
                            style={{
                              color: "#161616",
                              fontSize: "12px",
                              fontFamily: "Nunito",
                              margin: "10px 0px 0px 0px",
                            }}
                          >
                            <Link
                              style={{
                                textDecoration: "none",
                                color: "#000",
                              }}
                              href={f.url}
                              onClick={(e) => {
                                handleClick(e, f.pageName, f.url);
                              }}
                            >
                              {f.pageName}
                            </Link>
                          </Typography>
                        ))}
                      </div>
                    </Grid>
                    <div className={classes.vline}></div>
                    &nbsp; &nbsp; &nbsp;
                    <Grid item md={2} xs={12} className="mt-3 ml-4">
                      <div>
                        <Typography
                          variant="subtitle1"
                          color="primary"
                          className={classes.menuheadingcolor}
                          style={{ fontFamily: "Nunito", fontSize: "15px" }}
                        >
                          Application management
                          <div className={classes.hline}></div>
                        </Typography>

                        <div className="pt-2">
                          <Typography
                            variant="subtitle2"
                            style={{
                              color: "#161616",
                              fontSize: "12px",
                              fontFamily: "Nunito",
                              margin: "10px 0px 0px 0px",
                            }}
                          >
                            {/* <Link
                              style={{
                                textDecoration: "none",
                                color: "#000",
                              }}
                              onClick={(e) => {
                                handleClick(
                                  e,
                                  "Applications",
                                  "/app/applications"
                                );
                              }}
                            >
                              Applications
                            </Link> */}
                            <LinkComponent
                              name="Applications"
                              url="/app/applications"
                              handleClick={handleClick}
                              permission={["ListAll"]}
                              resource="Applications"
                            />
                          </Typography>{" "}
                          <Typography
                            variant="subtitle2"
                            className={classes.menutextcolor}
                            style={{
                              color: "#161616",
                              fontSize: "12px",
                              fontFamily: "Nunito",
                              margin: "10px 0px 0px 0px",
                            }}
                          >
                            {/* <Link
                              href="/app/onboard-application"
                              style={{ textDecoration: "none", color: "#000" }}
                              onClick={(e) => {
                                handleClick(
                                  e,
                                  "Onboard Application",
                                  "/app/onboard-application"
                                );
                              }}
                            >
                              Onboard Application
                            </Link> */}

                            <LinkComponent
                              name="Onboard Application"
                              url="/app/onboard-application"
                              handleClick={handleClick}
                              permission={["Create"]}
                              resource="Applications"
                            />
                          </Typography>
                          {/* <Typography
                            variant="subtitle2"
                            className={classes.menutextcolor}
                            style={{ color: "#000", opacity: "50%" }}
                          >
                            Documentation
                          </Typography>{" "} */}
                        </div>
                      </div>
                      &nbsp;
                      <div>
                        <Typography
                          variant="subtitle1"
                          color="primary"
                          className={classes.menuheadingcolor}
                          style={{ fontFamily: "Nunito", fontSize: "15px" }}
                        >
                          Customer management
                          <div className={classes.hline}></div>
                        </Typography>
                        <div className="pt-2">
                          <Typography
                            variant="subtitle2"
                            className={classes.menutextcolor}
                            style={{
                              color: "#161616",
                              fontSize: "12px",
                              fontFamily: "Nunito",
                              margin: "10px 0px 0px 0px",
                            }}
                          >
                            {/* <Link
                              href="app/customer-management"
                              style={{ textDecoration: "none", color: "#000" }}
                              onClick={(e) => {
                                handleClick(
                                  e,
                                  "Customers",
                                  "/app/customer-management"
                                );
                              }}
                            >
                              Customers
                            </Link>{" "} */}

                            <LinkComponent
                              name="Customers"
                              url="/app/customer-management"
                              handleClick={handleClick}
                              permission={["ListAll"]}
                              resource="Accounts"
                            />
                          </Typography>{" "}
                          {/* <Typography
                            variant="subtitle2"
                            className={classes.menutextcolor}
                            style={{ color: "#000", opacity: "50%" }}
                          >
                            Subscriptions
                          </Typography>{" "} */}
                          <Typography
                            variant="subtitle2"
                            className={classes.menutextcolor}
                            style={{
                              fontFamily: "Nunito",
                              fontSize: "12px",
                              color: "#000",
                              opacity: "50%",
                              margin: "10px 0px 0px 0px",
                            }}
                          >
                            Channel management
                          </Typography>{" "}
                        </div>
                      </div>
                      &nbsp;
                      <div>
                        <Typography
                          variant="subtitle1"
                          color="primary"
                          className={classes.menuheadingcolor}
                          style={{ fontFamily: "Nunito", fontSize: "15px" }}
                        >
                          Data Fabric
                          <div className={classes.hlineshort}></div>
                        </Typography>
                        <div className="pt-2">
                          <Typography
                            variant="subtitle2"
                            className={classes.menutextcolor}
                            style={{
                              color: "#161616",
                              fontSize: "12px",
                              fontFamily: "Nunito",
                              margin: "10px 0px 0px 0px",
                            }}
                          >
                            <LinkComponent
                              name="Active Metadata"
                              url="/app/data_standards"
                              handleClick={handleClick}
                              permission={["ListAll"]}
                              resource="ActiveMetadata"
                            />
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            className={classes.menutextcolor}
                            style={{
                              color: "#161616",
                              fontSize: "12px",
                              fontFamily: "Nunito",
                              margin: "10px 0px 0px 0px",
                            }}
                          >
                            <LinkComponent
                              name="Monitoring"
                              url="/app/monitoring"
                              handleClick={handleClick}
                              permission={["ListAll"]}
                              resource="AdminUsers"
                            />
                          </Typography>{" "}
                        </div>
                      </div>
                      &nbsp;
                      <div>
                        <Typography
                          variant="subtitle1"
                          color="primary"
                          className={classes.menuheadingcolor}
                          style={{ fontFamily: "Nunito", fontSize: "15px" }}
                        >
                          Organization
                          <div className={classes.hlineshort}></div>
                        </Typography>
                        <div className="pt-2">
                          <Typography
                            variant="subtitle2"
                            className={classes.menutextcolor}
                            style={{
                              color: "#161616",
                              fontSize: "12px",
                              fontFamily: "Nunito",
                              margin: "10px 0px 0px 0px",
                            }}
                          >
                            <LinkComponent
                              name="Organization"
                              url="/app/organization-details"
                              handleClick={handleClick}
                              permission={["Read"]}
                              resource="Organization"
                            />
                          </Typography>{" "}
                        </div>
                      </div>
                    </Grid>
                    <div className={classes.vline}></div>
                    &nbsp; &nbsp; &nbsp;
                    <Grid item md={2} xs={12} className="mt-3 ml-4">
                      <div>
                        {" "}
                        <Typography
                          variant="subtitle1"
                          color="primary"
                          className={classes.menuheadingcolor}
                          style={{ fontFamily: "Nunito", fontSize: "15px" }}
                        >
                          Billing
                          <div className={classes.hlineshort}></div>
                        </Typography>
                        <div className="pt-2">
                          <Typography
                            variant="subtitle2"
                            className={classes.menutextcolor}
                            style={{
                              color: "#161616",
                              fontSize: "12px",
                              fontFamily: "Nunito",
                              margin: "10px 0px 0px 0px",
                            }}
                          >
                            {/* <Link
                              href="/app/billing"
                              style={{ textDecoration: "none", color: "#000" }}
                              onClick={(e) => {
                                handleClick(
                                  e,
                                  "Billable Services",
                                  "/app/billing"
                                );
                              }}
                            >
                              Billable Services
                            </Link> */}
                            <LinkComponent
                              name="Billable Services"
                              url="/app/billing"
                              handleClick={handleClick}
                              permission={["ListAll"]}
                              resource="BillableServices"
                            />
                          </Typography>{" "}
                          <Typography
                            variant="subtitle2"
                            className={classes.menutextcolor}
                            style={{
                              color: "#161616",
                              fontSize: "12px",
                              fontFamily: "Nunito",
                              margin: "10px 0px 0px 0px",
                            }}
                          >
                            {/* <Link
                              href="/app/subscription"
                              style={{ textDecoration: "none", color: "#000" }}
                              onClick={(e) => {
                                handleClick(
                                  e,
                                  "Subscription Plan",
                                  "/app/subscription"
                                );
                              }}
                            >
                              Subscription Plan
                            </Link> */}

                            <LinkComponent
                              name="Subscription Plan"
                              url="/app/subscription"
                              handleClick={handleClick}
                              permission={["ListAll"]}
                              resource="SubscriptionPlans"
                            />
                          </Typography>{" "}
                          <Typography
                            variant="subtitle2"
                            className={classes.menutextcolor}
                            style={{
                              color: "#161616",
                              fontSize: "12px",
                              fontFamily: "Nunito",
                              margin: "10px 0px 0px 0px",
                            }}
                          >
                            {/* <Link
                              href="/app/pricingmaster"
                              style={{ textDecoration: "none", color: "#000" }}
                              onClick={(e) => {
                                handleClick(
                                  e,
                                  "Pricing Master",
                                  "/app/pricingmaster"
                                );
                              }}
                            >
                              Pricing Master
                            </Link>{" "} */}

                            <LinkComponent
                              name="Pricing Master"
                              url="/app/pricingmaster"
                              handleClick={handleClick}
                              permission={["ListAll"]}
                              resource="PricingDefinition"
                            />
                          </Typography>{" "}
                          <Typography
                            variant="subtitle2"
                            className={classes.menutextcolor}
                            style={{
                              color: "#161616",
                              fontSize: "12px",
                              fontFamily: "Nunito",
                              margin: "10px 0px 0px 0px",
                            }}
                          >
                            {/* <Link
                              href="/app/invoice"
                              style={{ textDecoration: "none", color: "#000" }}
                              onClick={(e) => {
                                handleClick(e, "Invoices", "/app/invoice");
                              }}
                            >
                              Invoices
                            </Link> */}

                            <LinkComponent
                              name="Invoices"
                              url="/app/invoice"
                              handleClick={handleClick}
                              permission={["ListAll"]}
                              resource="Invoices"
                            />
                          </Typography>{" "}
                        </div>
                      </div>
                      &nbsp;
                      <div>
                        <Typography
                          variant="subtitle1"
                          color="primary"
                          className={classes.menuheadingcolor}
                          style={{ fontFamily: "Nunito", fontSize: "15px" }}
                        >
                          {"Configuration & Support"}
                          <div className={classes.hline}></div>
                        </Typography>
                        <div className="pt-2">
                          <Typography
                            variant="subtitle2"
                            className={classes.menutextcolor}
                            style={{
                              color: "#161616",
                              fontSize: "12px",
                              fontFamily: "Nunito",
                              margin: "10px 0px 0px 0px",
                            }}
                          >
                            {/* <Link
                              href="/app/helpdesk"
                              style={{ textDecoration: "none", color: "#000" }}
                              onClick={(e) => {
                                handleClick(e, "Help Desk", "/app/helpdesk");
                              }}
                            >
                              Help Desk
                            </Link> */}
                            <LinkComponent
                              name="Help Desk"
                              url="/app/helpdesk"
                              handleClick={handleClick}
                              permission={["ListAll"]}
                              resource="Helpdesk"
                            />
                          </Typography>{" "}
                          <Typography
                            variant="subtitle2"
                            className={classes.menutextcolor}
                            style={{
                              color: "#161616",
                              fontSize: "12px",
                              fontFamily: "Nunito",
                              margin: "10px 0px 0px 0px",
                            }}
                          >
                            {/* <Link
                              href="/app/status-profile"
                              style={{ textDecoration: "none", color: "#000" }}
                              onClick={(e) => {
                                handleClick(
                                  e,
                                  "Status Profile",
                                  "/app/status-profile"
                                );
                              }}
                            >
                              Status Profile
                            </Link> */}
                            <LinkComponent
                              name="Status Profile"
                              url="/app/status-profile"
                              handleClick={handleClick}
                              permission={["ListAll"]}
                              resource="Status"
                            />
                          </Typography>{" "}
                          {/* <Typography
                            variant="subtitle2"
                            className={classes.menutextcolor}
                            style={{ color: "#000", opacity: "50%" }}
                          >
                            Access management
                          </Typography>{" "} */}
                        </div>
                      </div>
                      &nbsp;
                      <div>
                        <Typography
                          variant="subtitle1"
                          color="primary"
                          className={classes.menuheadingcolor}
                          style={{ fontFamily: "Nunito", fontSize: "15px" }}
                        >
                          IAM
                          <div className={classes.hlineshort}></div>
                        </Typography>
                        <div className="pt-2">
                          <Typography
                            variant="subtitle2"
                            className={classes.menutextcolor}
                            style={{
                              color: "#161616",
                              fontSize: "12px",
                              fontFamily: "Nunito",
                              margin: "10px 0px 0px 0px",
                            }}
                          >
                            {/* <Link
                              href="/app/user-management"
                              style={{ textDecoration: "none", color: "#000" }}
                              onClick={(e) => {
                                handleClick(e, "Users", "/app/user-management");
                              }}
                            >
                              Users
                            </Link> */}
                            <LinkComponent
                              name="Users"
                              url="/app/user-management"
                              handleClick={handleClick}
                              permission={["ListAll"]}
                              resource="AdminUsers"
                            />
                          </Typography>{" "}
                          <Typography
                            variant="subtitle2"
                            className={classes.menutextcolor}
                            style={{
                              color: "#161616",
                              fontSize: "12px",
                              fontFamily: "Nunito",
                              margin: "10px 0px 0px 0px",
                            }}
                          >
                            {/* <Link
                              href="/app/role-management"
                              style={{ textDecoration: "none", color: "#000" }}
                              onClick={(e) => {
                                handleClick(e, "Roles", "/app/role-management");
                              }}
                            >
                              Roles
                            </Link> */}

                            <LinkComponent
                              name="Roles"
                              url="/app/role-management"
                              handleClick={handleClick}
                              permission={["ListAll"]}
                              resource="Roles"
                            />
                          </Typography>{" "}
                          <Typography
                            variant="subtitle2"
                            className={classes.menutextcolor}
                            style={{
                              color: "#161616",
                              fontSize: "12px",
                              fontFamily: "Nunito",
                              margin: "10px 0px 0px 0px",
                            }}
                          >
                            {/* <Link
                              href="/app/team-management"
                              style={{ textDecoration: "none", color: "#000" }}
                              onClick={(e) => {
                                handleClick(e, "Teams", "/app/team-management");
                              }}
                            >
                              Teams
                            </Link> */}
                            <LinkComponent
                              name="Teams"
                              url="/app/team-management"
                              handleClick={handleClick}
                              permission={["ListAll"]}
                              resource="Teams"
                            />
                          </Typography>{" "}
                        </div>
                      </div>
                    </Grid>
                    <div
                      className={classes.vlineshort}
                      style={{ marginTop: "17px" }}
                    ></div>
                    &nbsp; &nbsp; &nbsp;
                    {/* <div style={{ marginTop: "17px" }}>
                      <Typography
                        variant="subtitle1"
                        color="primary"
                        className={classes.menuheadingcolor}
                      >
                        Monitoring
                        <div className={classes.hlineshort}></div>
                      </Typography>
                      <div className="pt-2">
                        <Typography
                          variant="subtitle2"
                          className={classes.menutextcolor}
                          style={{
                            color: "#161616",
                            fontSize: "13px",
                            fontFamily: "Nunito",
                            margin: "10px 0px 0px 0px",
                          }}
                        > */}
                    {/* <Link
                              href="/app/user-management"
                              style={{ textDecoration: "none", color: "#000" }}
                              onClick={(e) => {
                                handleClick(e, "Users", "/app/user-management");
                              }}
                            >
                              Users
                            </Link> */}
                    {/* <LinkComponent
                            name="Monitoring"
                            url="/app/monitoring"
                            handleClick={handleClick}
                            permission={["ListAll"]}
                            resource="AdminUsers"
                          />
                        </Typography>{" "}
                      </div>
                    </div> */}
                  </Grid>
                  {/* version */}
                  <Typography className={classes.padding} />
                  <div style={{ position: "relative", top: "-4rem" }}>
                    <span>{process.env.REACT_APP_RELEASE_VERSION}</span>
                  </div>
                </div>
              </Popover>
            </div>
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              <IconButton
                style={{ backgroundColor: "transparent" }}
                color="inherit"
                onClick={() => {
                  history.push({
                    pathname: "/app/setting",
                    state: { key: "Navbar" },
                  });
                  setUnsceneCount(0);
                }}
              >
                {unscenceCount > 9 ? (
                  <Badge badgeContent={"9+"} color="error">
                    <img alt="bell" src={Bell} />
                  </Badge>
                ) : (
                  <Badge badgeContent={unscenceCount} color="error">
                    <img alt="bell" src={Bell} />
                  </Badge>
                )}
              </IconButton>

              <img alt="pipe" src={Pipe} className={classes.pipe} />
            </div>{" "}
            <Grid key="menu" item className="pl-3" style={{ display: "flex" }}>
              <MatxMenu
                expandBtn={expandBtn}
                setExpandBtn={setExpandBtn}
                menuButton={
                  <div
                    onClick={() => {
                      console.log("clickkkkkkkkkkkkkkkkkkkkkkkk");
                      setExpandBtn(!expandBtn);
                    }}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        marginRight: 10,
                      }}
                    >
                      <Avatar
                        style={{ width: "30px", height: "30px" }}
                        alt="img"
                        src={`${userInfo.image}`}
                      />
                    </div>
                    <div
                      style={{
                        marginRight: 10,
                      }}
                    >
                      <Typography
                        className={classes.title}
                        noWrap
                        style={{ cursor: "pointer" }}
                      >
                        {userInfo.name}
                      </Typography>
                    </div>

                    <div>
                      {expandBtn ? (
                        <ExpandLessIcon
                          style={{
                            color: "white",
                            fontSize: "20px",
                            marginTop: "-3px",
                          }}
                        />
                      ) : (
                        <ExpandMoreIcon
                          style={{
                            color: "white",
                            fontSize: "20px",
                            marginTop: "-3px",
                          }}
                        />
                      )}
                    </div>
                  </div>
                }
              >
                {userInfo.name && (
                  <MenuItem
                    style={{
                      marginBottom: "10px",
                      display: "flex",
                      width: "220px",
                    }}
                    onClick={() => {
                      pushTo(true);
                      setExpandBtn(!expandBtn);
                    }}
                  >
                    <Typography variant="body2">Settings</Typography>
                  </MenuItem>
                )}
                {userInfo.name && <Divider />}
                <MenuItem
                  style={{
                    marginTop: "10px",
                    width: "220px",
                    display: "flex",
                  }}
                  onClick={() => pushTo()}
                >
                  <Typography variant="body2">Signout</Typography>
                </MenuItem>
              </MatxMenu>
            </Grid>
          </Toolbar>
        </AppBar>
      </div>
    </div>
  );
};

export default MyMenu;
