import React, { useState, useEffect } from "react";
import { Grid, Tabs, Tab, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import listScreenStyles from "styles/CommonStyles/listScreenStyles";
import { AppButton } from "../../components/common/Button";
import { useSelector, useDispatch } from "react-redux";
import { RiEditLine, RiDeleteBin5Line } from "react-icons/ri";

import { changeTabByUser } from "./redux/action";
import { changeVisibility } from "./Apear/apearAction";
import { useSnackbar } from "notistack";
import * as types from "./redux/actionTypes";
import Profile from "./Profile";
import General from "./General";
import Apperance from "./Apperance";
import Notifications from "./Notifications";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    marginTop: "-1%",
    paddingLeft: "2em",
    paddingRight: "2em",
    background: "#FAFAFA 0% 0% no-repeat padding-box",
  },
  tabContainer: {
    borderBottom: "1px solid #E2E2E3",
  },
  tab: {
    ...theme.typography.fontFamily,
    ...theme.typography.tab,
    textTransform: "none",
    color: "#1B1C1D",
    opacity: "0.5",
    fontWeight: "normal",
    fontSize: "0.86rem",
    minwidth: "16px",
    // marginRight: "36px",
    textTransform: "none",
  },
  header: {
    fontSize: 12,
    color: "#1B1C1D",
  },
  settingsHead: {
    ...theme.typography.fontFamily,
    fontSize: "20px",
    fontWeight: 600,
    color: "#1B1C1D",
  },
  rootTab: {
    // minHeight:'58px'
    minWidth: "110px",
  },
}));
const tabArray = [
  { label: "Profile", index: 0 },
  { label: "General", index: 1, link: "/General" },
  { label: "Appearance", index: 2, link: "/Appearance" },
  { label: "Notifications", index: 3, link: "/Notifications" },
];

const Settings = (props) => {
  const classes = useStyles();
  const appearnce = useSelector((state) => state.apearenceReducer);
  const dispatch = useDispatch();
  const { appear } = appearnce;
  const { enqueueSnackbar } = useSnackbar();
  const selectTab = useSelector((state) => state.userSelectTab);
  const [flagVisible, setflagVisible] = useState(appear);
  const styles = listScreenStyles();
  const { userTab } = selectTab;
  const userDetails = useSelector((state) => state.apearenceReducer);
  const [tab, setTab] = useState(0);
  const handleChangeTab = (event, newValue) => {
    dispatch(changeTabByUser(newValue));
  };
  const handleClick = (val) => {
    if (val === true || val === "save") {
      dispatch(changeVisibility(val));
    } else if (val === false) {
      dispatch({ type: types.USER_VISIBLE_RESET });
    }
  };

  useEffect(() => {
    if (props.location.state && props.location.state.key !== undefined) {
      const { key } = props.location.state;
      key === "Navbar" && dispatch(changeTabByUser(3));
    } else {
      dispatch(changeTabByUser(0));
    }
  }, [props.location]);

  useEffect(() => {
    return () => {
      //cancel  update or navigate to other page! (unmount using hook)
      dispatch({ type: types.USER_VISIBLE_RESET });
    };
  }, []);
  console.log("settings propssssssssssssssssssssssssssssssss", props);
  return (
    <>
      {/* <Grid
        className={classes.mainContainer}
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item md={8} style={{ height: "40px" }}>
          <Typography className={classes.settingsHead}>Settings</Typography>
        </Grid>
        <Grid
          item
          md={4}
          container
          justifyContent="flex-end"
          style={{ height: "40px" }}
        >
          {userTab === 0 || userTab === 1 ? (
            <div>
              {!flagVisible ? (
                <AppButton
                  color="primary"
                  buttonName="Edit"
                  variant="contained"
                  startIcon={<img src="/images/edit.svg" alt="" />}
                  disabled={false}
                  style={{ padding: "6px 15px" }}
                  className="btnsmall"
                  onClick={() => {
                    setflagVisible(!flagVisible);
                    handleClick(true);
                  }}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <AppButton
                    color="primary"
                    buttonName="Cancel"
                    variant="contained"
                    // startIcon={<img src="/images/edit.svg" alt="" />}
                    disabled={false}
                    style={{ padding: "6px 15px" }}
                    className="btnsmall"
                    onClick={() => {
                      setflagVisible(false);
                      handleClick(false);
                    }}
                  />

                  <AppButton
                    color="primary"
                    buttonName="Save"
                    variant="contained"
                    // startIcon={<img src="/images/edit.svg" alt="" />}
                    disabled={false}
                    style={{ padding: "6px 15px" }}
                    className="btnsmall"
                    onClick={() => {
                      setflagVisible(false);
                      handleClick("save");
                    }}
                  />
                </div>
              )}
            </div>
          ) : (
            <Grid item container md justify="flex-end"></Grid>
          )}
        </Grid>
      </Grid>
      <Grid container direction="column">
        <Grid item container direction="column">
          <Grid item style={{ marginTop: 16 }}>
            <Tabs
              value={userTab}
              onChange={handleChangeTab}
              indicatorColor="primary"
              className={classes.tabContainer}
            >
              {tabArray.map((option, i) => (
                <Tab
                  disableRipple
                  key={i}
                  label={option.label}
                  className={classes.tab}
                  // style={{ color: tab === i ? '#002855' : 'red' }}
                  classes={{ root: classes.rootTab }}
                />
              ))}
            </Tabs>
          </Grid>
        </Grid>
        {userTab === 0 ? (
          <Grid item container style={{ marginTop: "1rem" }}>
            <Profile userDetails={userDetails} />
          </Grid>
        ) : userTab === 1 ? (
          <Grid item container style={{ marginTop: "1rem" }}>
            <General />
          </Grid>
        ) : userTab === 2 ? (
          <Grid
            item
            container
            md
            style={{ marginLeft: "-2em", marginTop: "1.0rem" }}
          >
            <Apperance tab={tab} changeTab={setTab} />
          </Grid>
        ) : userTab === 3 ? (
          <Grid item container style={{ marginTop: "1rem", marginLeft: "1em" }}>
            <Notifications />
          </Grid>
        ) : (
          ""
        )}
      </Grid> */}
      <Grid container direction="column" className={classes.mainContainer}>
        <Grid item container direction="column" className={styles.topContent}>
          <Grid
            item
            container
            direction="column"
            style={{ marginTop: "2.8em" }}
          >
            <Grid item container>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Typography className={classes.settingsHead}>
                  Settings
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                {userTab === 0 || userTab === 1 ? (
                  <Grid item container md justify="flex-end">
                    {!flagVisible ? (
                      <Grid item style={{ position: "absolute" }}>
                        <AppButton
                          color="primary"
                          buttonName="Edit"
                          variant="contained"
                          startIcon={<img src="/images/edit.svg" alt="" />}
                          disabled={false}
                          style={{ padding: "6px 15px" }}
                          className="btnsmall"
                          onClick={() => {
                            setflagVisible(!flagVisible);
                            handleClick(true);
                          }}
                        />
                      </Grid>
                    ) : (
                      <Grid
                        item
                        container
                        md
                        justify="flex-end"
                        style={{ position: "absolute" }}
                      >
                        <Grid item>
                          <AppButton
                            color="primary"
                            buttonName="Cancel"
                            variant="contained"
                            // startIcon={<img src="/images/edit.svg" alt="" />}
                            disabled={false}
                            style={{ padding: "6px 15px" }}
                            className="btnsmall"
                            onClick={() => {
                              setflagVisible(false);
                              handleClick(false);
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <AppButton
                            color="primary"
                            buttonName="Save"
                            variant="contained"
                            // startIcon={<img src="/images/edit.svg" alt="" />}
                            disabled={false}
                            style={{ padding: "6px 15px" }}
                            className="btnsmall"
                            onClick={() => {
                              setflagVisible(false);
                              handleClick("save");
                            }}
                          />
                        </Grid>
                        <Grid item></Grid>
                      </Grid>
                    )}
                  </Grid>
                ) : (
                  <Grid item container md justify="flex-end"></Grid>
                )}
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: 16 }}>
              <Tabs
                value={userTab}
                onChange={handleChangeTab}
                indicatorColor="primary"
                className={classes.tabContainer}
              >
                {tabArray.map((option, i) => (
                  <Tab
                    disableRipple
                    key={i}
                    label={option.label}
                    className={classes.tab}
                    // style={{ color: tab === i ? '#002855' : 'red' }}
                    classes={{ root: classes.rootTab }}
                  />
                ))}
              </Tabs>
            </Grid>
          </Grid>
        </Grid>

        {userTab === 0 ? (
          <Grid item container style={{ marginTop: "10rem" }}>
            <Profile />
          </Grid>
        ) : userTab === 1 ? (
          <Grid item container style={{ marginTop: "10rem" }}>
            <General handleClick={handleClick} />
          </Grid>
        ) : userTab === 2 ? (
          <Grid
            item
            container
            md
            style={{ marginLeft: "-2em", marginTop: "8.8rem" }}
          >
            <Apperance tab={tab} changeTab={setTab} />
          </Grid>
        ) : userTab === 3 ? (
          <Grid
            item
            container
            style={{
              marginTop: "11rem",
              marginLeft: "1em",
            }}
          >
            <Notifications setUnsceneCount={props.location.setUnsceneCount} />
          </Grid>
        ) : (
          ""
        )}
      </Grid>
    </>
  );
};

export default Settings;
