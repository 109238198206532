const LinkTable = ({
  destAddr,
  destTopic,
  destType,
  messageLen,
  srcAddr,
  srcType,
  tps,
  srcTopic,
}) => (
  <table style={{ borderCollapse: "collapse", width: "100%" }}>
    <thead>
      <tr>
        <Th></Th>
        <Th>Source</Th>
        <Th>Destination</Th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <Td>Type</Td>
        <Td> {srcType} </Td>
        <Td> {destType}</Td>
      </tr>
      <tr>
        <Td>Address</Td>
        <Td>{srcAddr} </Td>
        <Td>{destAddr}</Td>
      </tr>
      <tr>
        <Td>Topic</Td>
        <Td>{srcTopic}</Td>
        <Td>{destTopic}</Td>
      </tr>
      <tr>
        <Td>TPS</Td>
        <Td colSpan={2}>{tps}</Td>
      </tr>
      <tr>
        <Td>Size</Td>
        <Td colSpan={2}>{messageLen}</Td>
      </tr>
    </tbody>
  </table>
);

const Td = ({ colSpan, children }) => {
  return (
    <td
      style={{
        border: "1px solid #dddddd",
        textAlign: "left",
        padding: "2px 4px",
        fontSize: 12,
      }}
      colSpan={colSpan}
    >
      {children}
    </td>
  );
};
const Th = ({ children }) => {
  return (
    <th
      style={{
        border: "1px solid #dddddd",
        textAlign: "left",
        padding: "2px 4px",
        fontSize: 12,
        width: "100px",
      }}
    >
      {children}
    </th>
  );
};
export default LinkTable;
