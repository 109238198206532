import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";

const CustomerInfo = (props) => {
  const { InvoiceData } = props;
  const history = useHistory();
  console.log("sagdhjgdhj", InvoiceData);
  const { CustomerDetails } = InvoiceData || {
    CustomerDetails: {
      _id: null,
      name: "",
      organization_name: "",
      email: "",
      company_url: "",
      phonenumber: "",
    },
  };

  console.log("adsvhgdsajkgsadj", CustomerDetails);
  const { _id, phonenumber, organization_name, email, company_url, name } =
    CustomerDetails || {
      _id: null,
      name: "",
      organization_name: "",
      email: "",
      company_url: "",
      phonenumber: "",
    };
  return (
    <>
      <Grid container spacing={2} style={{ marginBottom: 8 }}>
        <Grid item md={2}>
          <Card
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (_id) {
                history.push("/app/customer-details", {
                  customerData: {
                    ...CustomerDetails,
                  },
                });
              }
            }}
          >
            <CardHeader
              title={
                <Typography
                  style={{
                    display: "flex",
                  }}
                >
                  <span style={{ fontWeight: 600, fontSize: "14px" }}>
                    Customer :
                  </span>
                  &nbsp; {name}
                </Typography>
              }
            />
            {/* <CardContent>
              <Grid container spacing={2}>
                <Grid item>
                  <Typography
                    style={{
                      fontWeight: 400,
                      fontSize: "14px",
                      display: "flex",
                    }}
                  >
                    <p> Name: </p>
                    {name}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography style={{ fontWeight: 400, fontSize: "14px" }}>
                    Organization: {organization_name}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography style={{ fontWeight: 400, fontSize: "14px" }}>
                    Email: {email}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography style={{ fontWeight: 400, fontSize: "14px" }}>
                    URL: {company_url}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography style={{ fontWeight: 400, fontSize: "14px" }}>
                    Phone: {phonenumber}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent> */}
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
export default CustomerInfo;
