import React from "react";
import TreeList, {
  Column,
  RowDragging,
  Button,
  HeaderFilter,
  Toolbar,
  Editing,
} from "devextreme-react/tree-list";
import { TextBox } from "devextreme-react/text-box";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import CheckBox from "devextreme-react/check-box";
import { Edit } from "@material-ui/icons";
import helpFunc from "../../helpers/helpers";
import { Grid } from "@material-ui/core";
import SunEditor, { buttonList } from "suneditor-react";

const expandedRowKeys = [1];

class TreeListComponent extends React.Component {
  constructor(props) {
    super(props);
    this.onReorder = this.onReorder.bind(this);
    this.onAllowDropInsideItemChanged =
      this.onAllowDropInsideItemChanged.bind(this);
    this.onAllowReorderingChanged = this.onAllowReorderingChanged.bind(this);
    this.onShowDragIconsChanged = this.onShowDragIconsChanged.bind(this);
    this.onDragEnd = this.onDragEnd(this);

    this.state = {
      treeListData: this.props.treeData,
      allowDropInsideItem: true,
      allowReordering: true,
      showDragIcons: true,
      subSectionCount: [],
      textBox: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.treeData !== state.treeListData) {
      return {
        treeListData: props.treeData || [],
      };
    }
    return null;
  }

  shouldComponentUpdate(nextProps, nextState) {
    console.log("jhdfjhuery7573764", nextProps, this.props);
    if (nextProps.treeData !== this.props.treeData) {
      return true;
    } else {
      return false;
    }
  }

  ondltFn(id) {
    this.props.handleSectionDelete(id);
  }

  // onDragChange(e) {
  //   console.log("ondragchange", e);
  //   const visibleRows = e.component.getVisibleRows();
  //   const sourceNode = e.component.getNodeByKey(e.itemData.ID);
  //   let targetNode = visibleRows[e.toIndex].node;
  //   console.log("levelssssssssssssssssssssss", sourceNode, targetNode);

  //   while (targetNode && targetNode.data) {
  //     if (targetNode.data.ID === sourceNode.data.ID) {
  //       e.cancel = true;
  //       break;
  //     }
  //     if (targetNode.level <= sourceNode.level && targetNode.level !== 1) {
  //       targetNode = targetNode.parent;
  //     } else {
  //       targetNode = sourceNode;
  //     }

  //     // targetNode = targetNode.parent;
  //   }
  // }
  onDragChange(e) {
    const visibleRows = e.component.getVisibleRows();
    const sourceNode = e.component.getNodeByKey(e.itemData.ID);
    let targetNode = visibleRows[e.toIndex].node;

    while (targetNode && targetNode.data) {
      if (targetNode.data.ID === sourceNode.data.ID) {
        e.cancel = true;
        break;
      }
      targetNode = targetNode.parent;
    }
  }

  onReorder(e) {
    const visibleRows = e.component.getVisibleRows();
    let sourceData = e.itemData;
    let { treeListData } = this.state;
    console.log("345678sdfghjhdsfghj", treeListData);
    const sourceIndex = treeListData.map((f) => f._id).indexOf(sourceData?._id);

    let targetData = null;
    if (e.dropInsideItem) {
      if (e.itemData.subSectionCount > 0) {
        return;
      }
      sourceData = { ...sourceData, Head_ID: visibleRows[e.toIndex].key };
      if (
        sourceData.subSectionCount === 0 &&
        treeListData.filter(
          (i) => i.ID === sourceData.Head_ID && i.Head_ID !== -1
        ).length !== 0
      ) {
        return;
      }

      treeListData = [
        ...treeListData.slice(0, sourceIndex),
        sourceData,
        ...treeListData.slice(sourceIndex + 1),
      ];
    } else {
      const toIndex = e.fromIndex > e.toIndex ? e.toIndex - 1 : e.toIndex;
      targetData = toIndex >= 0 ? visibleRows[toIndex].node.data : null;
      if (targetData && e.component.isRowExpanded(targetData.ID)) {
        sourceData = { ...sourceData, Head_ID: targetData.ID };
        targetData = null;
      } else {
        const headId = targetData ? targetData.Head_ID : -1;
        if (sourceData.Head_ID !== headId) {
          sourceData = { ...sourceData, Head_ID: headId };
        }
      }

      treeListData = [
        ...treeListData.slice(0, sourceIndex),
        ...treeListData.slice(sourceIndex + 1),
      ];

      const targetIndex =
        treeListData.map((f) => f._id).indexOf(targetData?._id) + 1;
      treeListData = [
        ...treeListData.slice(0, targetIndex),
        sourceData,
        ...treeListData.slice(targetIndex),
      ];
    }

    // this.setState({
    //   treeListData,
    // });

    const findItem = treeListData.find((item) => item.ID === e.itemData.ID);
    const targetNodes = treeListData.filter(
      (i) =>
        findItem.Head_ID !== -1 &&
        findItem.Head_ID === i.ID &&
        findItem.name !== i.name
    );

    console.log(
      "handle drag updated",
      e.itemData,
      treeListData,
      findItem,
      targetNodes,
      helpFunc.sectionsSequenceOrder(treeListData)
    );

    this.props.handleDragUpdate(
      e.itemData,
      helpFunc.sectionsSequenceOrder(treeListData),
      {
        name: e.itemData.name,
        content: e.itemData.content,
        sectionId: targetNodes[0] ? targetNodes[0]._id : "",
        appId: e.itemData.appId,
      }
    );
  }

  onAllowDropInsideItemChanged(args) {
    this.setState({
      allowDropInsideItem: args.value,
    });
  }

  onAllowReorderingChanged(args) {
    this.setState({
      allowReordering: args.value,
    });
  }

  onShowDragIconsChanged(args) {
    this.setState({
      showDragIcons: args.value,
    });
  }

  showAddIcon(e) {
    return e.row.data.Head_ID === -1;
  }
  showDisableAddIcon(e) {
    return e.row.data.Head_ID !== -1;
  }
  showDeleteIcon(e) {
    return e.row.data.subSectionCount === 0;
  }
  showDisableDeleteIcon(e) {
    return e.row.data.subSectionCount !== 0;
  }

  allowEditing = (e) => {
    return true;
  };

  onDragEnd(e) {
    console.log("endddddddddddd", e);
  }

  visibleIcon = (e) => {
    return e.row.data.status;
  };

  onSaveFunc = (e) => {
    const { changes } = e;
    const [save] = changes;
    const { data, type } = save || {
      type: "none",
      data: null,
    };

    if (changes.length === 0) {
      this.props.handleUpdate(null);
    }

    if (type !== "update" || !data) {
      return;
    }
    this.props.handleUpdate(data);
  };

  render() {
    const tree_ls_data = structuredClone(this.state.treeListData);
    console.log("calling123456", tree_ls_data);

    return (
      <TreeList
        style={{ border: "none" }}
        id="treeListData"
        dataSource={tree_ls_data}
        rootValue={-1}
        keyExpr="ID"
        showRowLines={false}
        showColumnLines={false}
        showBorders={false}
        parentIdExpr="Head_ID"
        dropInsideItem={true}
        defaultExpandedRowKeys={expandedRowKeys}
        columnAutoWidth={true}
        onRowRemoved={this.props.handleSectionDelete}
        onSaved={(e) => {
          this.onSaveFunc(e);
        }}
        // onRowUpdated={}
        onRowInserted={(e) => {
          this.props.handleCreationSection(e);
        }}
        onInitNewRow={(e) => {
          this.props.onClickToggleContent(null, false);
        }}
        editing={{
          allowAdding: this.showAddIcon ? true : this.showDisableAddIcon,
          allowUpdating: true,
          allowDeleting: this.showDeleteIcon
            ? true
            : this.showDisableDeleteIcon,
          texts: {
            addRow: "Add Section",
            // confirmDeleteMessage:"Are you sure want to delete"
          },
          useIcons: true,
        }}
        onRowClick={(e) => {
          this.props.onClickToggleContent(e.data, true);
        }}
        onEditingStart={(e) => {
          this.props.onClickToggleContent(e.data, false);
        }}
      >
        <RowDragging
          onDragChange={this.onDragChange}
          onReorder={(e) => {
            // if (e.itemData.subSectionCount > 0) {
            //   return;
            // }
            this.onReorder(e);
          }}
          allowDropInsideItem={this.state.allowDropInsideItem}
          allowReordering={this.state.allowReordering}
          showDragIcons={this.state.showDragIcons}
          onDragEnd={this.onDragEnd}
        />

        <Column dataField="name" caption="Sections" />
        <Column type="buttons" width={110}>
          <Button
            name="add"
            // onClick={(e) => {
            //   console.log("hgfhgffdd34567", e);
            // }}
            // visible={this.visibleIcon}
            disabled={this.showDisableAddIcon}
          />
          <Button
            name="edit"
            // visible={this.visibleIcon}
          />
          <Button
            name="delete"
            // visible={this.visibleIcon}
            disabled={this.showDisableDeleteIcon}
          />
        </Column>

        {/* <Column type="buttons">
            <Button render={btnEditRender} />
          </Column>
          <Column type="buttons">
            <Button render={btnRender} />
          </Column>
          <Column type="buttons">
            <Button
              style={{ paddingLeft: "1px" }}
              render={btnDelete}
              onClick={(value) => {
                console.log("value bathnda", value);
                this.props.handleSectionDelete(value.row.data._id);
              }}
            />
          </Column> */}
      </TreeList>
    );
  }
}

export default TreeListComponent;
