/**
 * Contains all ajax call for Applications
 */

import getURL from "../config";
import API_helpers from "../index";

const update_subapp_status = async (payload) => {
  const { subId, launchStatus, customerUrl } = payload;
  try {
    let res = await API_helpers.PUT(
      getURL("UPDATE_SUBSCRIBED_APP_STATUS")(subId),
      {
        launchStatus,
        customerUrl,
      },
      {
        headers: {
          organization: "11111",
          userdetails: JSON.stringify({ name: "palani" }),
        },
      }
    );
    return res;
  } catch (err) {
    throw err;
  }
};

const fetchAllCustomer = async (url) => {
  try {
    let res = await API_helpers.GET(url.substr(0, url.length - 1));
    return res;
  } catch (err) {
    throw err;
  }
};

const blockorUnblockSubscriber = async (subId, type) => {
  try {
    let res = await API_helpers.PUT(
      getURL("BLOCK_SUBSCRIBERS")(subId, type),
      {},
      {
        headers: {
          organization: "11111",
          userdetails: JSON.stringify({ name: "palani" }),
        },
      }
    );
    return res;
  } catch (err) {
    throw err;
  }
};

const getCustomerWiseInvoices = async (id) => {
  try {
    let res = await API_helpers.GET(getURL("GET_CUSTOMER_WISE_INVOICES")(id));
    return res;
  } catch (err) {
    throw err;
  }
};

const upadateCustomerProfile = async (payload, id) => {
  try {
    let res = await API_helpers.POST(getURL("UPDATE_CUSTOMER_PROFILE")(id), {
      ...payload,
    });
    return res;
  } catch (err) {
    throw err;
  }
};

const fetchCustomerSetting = async (id) => {
  try {
    let res = await API_helpers.GET(getURL("UPDATE_CUSTOMER_PROFILE")(id));
    return res;
  } catch (err) {
    throw err;
  }
};

const fetchAppUsage = async (cusId, appId) => {
  try {
    let res = await API_helpers.GET(
      getURL("FETCH_APP_USAGE_DETAILS")(cusId, appId)
    );
    return res;
  } catch (err) {
    throw err;
  }
};

const fetchCustomerIssues = async (id) => {
  let url = getURL("FETCH_HELP_DATA");
  url = `${url}?filters={"customerID":"${id}"}`;
  try {
    let res = await API_helpers.GET(url, {
      headers: {
        "Content-Type": "application/json",
        userdetails: JSON.stringify({ name: "palani" }),
        organization: "11111",
      },
    });
    return res;
  } catch (err) {
    throw err;
  }
};

const updateEndDateSubscription = async (payload, id) => {
  try {
    let res = await API_helpers.PUT(
      getURL("UPDATE_END_DATE_SUBSCRIPTIOPN")(id),
      {
        ...payload,
      },
      {
        headers: {
          organization: "11111",
          userdetails: JSON.stringify({ name: "palani" }),
        },
      }
    );
    return res;
  } catch (err) {
    throw err;
  }
};

let APIs = {
  fetchAllCustomer,
  blockorUnblockSubscriber,
  getCustomerWiseInvoices,
  upadateCustomerProfile,
  fetchCustomerSetting,
  fetchAppUsage,
  fetchCustomerIssues,
  updateEndDateSubscription,
  update_subapp_status,
};

export default APIs;
