import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  Divider,
  Button,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import Style from "./Styles";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Outgoingcdumappingtable from "./OutgoingCdumapTable";
import contextAPIs from "../../../../api/Context/index";
import Outgoingvaluemap from "./Outgoingvaluemap";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Outgoingcdumap = (props) => {
  const { cduMapping_input, cduMapping_output } = props;
  const [value, setValue] = React.useState(0);
  const [request, setRequest] = React.useState([]);
  const [response, setResponse] = React.useState([]);
  const [show, setShow] = useState(false);
  const getContextByAppIdAndApiId = async (appId, apiId) => {
    try {
      let res = await contextAPIs.fetchContextByAppIdAndApId(appId, apiId);
      if (res.req) {
        const dataReq = (res.req || []).map((row, id) => {
          return { ...row, index: id };
        });
        setRequest([...dataReq]);
      } else {
        setRequest([]);
      }

      if (res.res) {
        const dataRes = (res.res || []).map((row, id) => {
          return { ...row, index: id };
        });
        setResponse([...dataRes]);
      } else {
        setResponse([]);
      }
    } catch (err) {
      setResponse([]);
      setRequest([]);
      props.updateParesntState([], []);
    }
  };

  useEffect(() => {
    console.log(props.outgoing, "sudheerdfghjkjhgfd");
    const { outgoingAppId, outgoingApiId } = props.outgoing;
    console.log(outgoingAppId, outgoingApiId, "this is context api");
    if (
      props.outgoing.outgoingApiId &&
      props.outgoing.outgoingApiId.trim() != ""
    ) {
      getContextByAppIdAndApiId(outgoingAppId, outgoingApiId);
    }
  }, [props.outgoing.outgoingApiId]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  console.log(cduMapping_input, cduMapping_output, 456788765456);

  return (
    <div>
      <Grid container spacing={1}>
        <Grid className={props.classes.tab} container item xs={12}>
          <Grid item xs={12}>
            <Card style={{ width: "100%", height: "55vh" }}>
              <CardHeader
                style={{
                  paddingBottom: 0,
                  paddingTop: 0,
                  marginTop: 0,
                  marginBottom: 0,
                }}
                title={
                  <Tabs
                    aria-label="simple tabs example"
                    value={value}
                    onChange={handleChange}
                  >
                    <Tab
                      style={{ textTransform: "capitalize" }}
                      label={
                        <Typography variant="subtitle1">
                          Input Payload
                        </Typography>
                      }
                      {...a11yProps(0)}
                    />
                    <Tab
                      style={{ textTransform: "capitalize" }}
                      label={
                        <Typography variant="subtitle1">
                          Output Payload
                        </Typography>
                      }
                      {...a11yProps(1)}
                    />
                  </Tabs>
                }
              />
              <CardContent style={{ margin: 0 }}>
                <div>
                  <TabPanel value={value} index={0}>
                    <Outgoingcdumappingtable
                      handleOnChange={props.handleOnChange}
                      cduMapping={cduMapping_input}
                      data={request}
                      type="input"
                      {...props}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <Outgoingcdumappingtable
                      handleOnChange={props.handleOnChange}
                      data={response}
                      cduMapping={cduMapping_output}
                      type="output"
                      {...props}
                    />
                  </TabPanel>
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(Style)(Outgoingcdumap);
