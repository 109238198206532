import getURL from "../config";
import API_helpers from "../index";

const fetch_app_module_for_graph = async (filter) => {
  console.log("application appids", filter);
  let url = getURL("FETCH_APP_MODULE_DETAILS_FOR_GRAPH");
  url = `${url}?filters=${filter}`;
  try {
    const res = await API_helpers.GET(url);
    return res;
  } catch (err) {
    throw err;
  }
};

async function fetchAllInvoicesWithParams(page, size, filters, sort) {
  let url = getURL("FETCH_ALL_INVOICES");
  url = url + "?";
  let params = { page, size, filters, sort };
  Object.keys(params)
    .filter((k) => params[k] != undefined || params[k] != null)
    .forEach((k) => (url = `${url}${k}=${params[k]}&`));
  url = url.substr(0, url.length - 1);
  try {
    let res = await API_helpers.GET(url, {
      headers: { "Content-Type": "application/json" },
    });
    return res.data;
  } catch (err) {
    throw err;
  }
}

async function fetchAllCredit_Debit_Notes(Id) {
  try {
    let res = await API_helpers.GET(getURL("FETCH_CREDIT_DEBIT_NOTES")(Id));
    return res;
  } catch (err) {
    throw err;
  }
}

async function addCreditNotes(id, payload) {
  let url = getURL("ADD_CREDITNOTES")(id);
  try {
    let res = await API_helpers.POST(url, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  } catch (err) {
    throw err;
  }
}

async function getCreditdetailsById(Id, invoiceId, payload) {
  let url = getURL("GET_CREDIT_DETAILS")(Id, invoiceId);

  try {
    let res = await API_helpers.GET(url, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  } catch (err) {
    throw err;
  }
}

async function ApproveCredit_Debit(Id, invoiceId, type) {
  let url = getURL("APPROVE_INVOICE")(Id, invoiceId, type);
  try {
    let res = await API_helpers.POST(
      url,
      {},
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return res;
  } catch (err) {
    throw err;
  }
}

async function RejectCredit_Debit(Id, invoiceId, type) {
  let url = getURL("REJECT_INVOICE")(Id, invoiceId, type);
  try {
    const res = await API_helpers.POST(
      url,
      {},
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return res;
  } catch (err) {
    throw err;
  }
}

const fethcInvoiceDetailsById = async (id) => {
  try {
    const res = await API_helpers.GET(
      getURL("FETHC_INVOICE_DETAILS_BY_ID")(id),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return res;
  } catch (err) {
    throw err;
  }
};

var APIs = {
  fetchAllInvoicesWithParams,
  fetchAllCredit_Debit_Notes,
  addCreditNotes,
  getCreditdetailsById,
  ApproveCredit_Debit,
  RejectCredit_Debit,
  fethcInvoiceDetailsById,
  fetch_app_module_for_graph,
};

export default APIs;
