import axios from "axios";
//import createAuthRefreshInterceptor from "axios-auth-refresh";
import { BASE_URL } from "./config";
//import { store } from "../../redux/store";
// axios.defaults.headers.put["Content-Type"] = "application/json";

let accessToken = "";
let refreshToken = "";
let userId = "";

const listener = () => {
  // let state = store.getState();
  // const { accesstoken, refreshtoken, userid } = state.authReducer;
  // axios.defaults.headers.common['token'] = accesstoken;
  // accessToken = accesstoken;
  // refreshToken = refreshtoken;
  // userId = userid
};
// store.subscribe(listener);
// const { refreshToken, userid, accesstoken } = state.authReducer;

const refreshAuthLogic = async (failedRequest) => {
  await axios
    .put(`${BASE_URL}/api/v1.0.0/users/${userId}/createtokens`, {
      token: refreshToken,
    })
    .then((res) => {
      const { _msg, _status, data } = res.data;
      return Promise.resolve();
    });
};

// createAuthRefreshInterceptor(
//   axios,
//   refreshAuthLogic,
//   {
//     statusCodes: [401]
//   }
// )
// axios.defaults.headers.common = {'Authorization': `Bearer ${token}`,'Access-Control-Allow-Origin': '*' ,'Access-Control-Allow-Credentials':true}
export class ApiHelper {
  async get(uri, headers) {
    const response = await axios.get(uri, {
      headers: headers
        ? headers
        : { organization: "60ba3575e1bc3c06e220ab5a", token: accessToken },
    });
    return response;
  }
  async post(uri, data) {
    const response = await axios.post(uri, data, {
      headers: { organization: "60c4b8a9bf23a55ec0e278a1", token: accessToken },
    });
    return response;
  }
  async delete(uri) {
    const response = await axios.delete(uri, {
      headers: { organization: "60c4b8a9bf23a55ec0e278a1", token: accessToken },
    });
    return response;
  }
  // delete status
  // async deleteStatus(uri,data) {
  //   const response = await axios.delete(uri, data,{
  //     headers: { organization: "60c4b8a9bf23a55ec0e278a1" },
  //   });
  //   return response;
  // }
  async put(uri, data) {
    const response = await axios.put(uri, data, {
      headers: { organization: "60c4b8a9bf23a55ec0e278a1", token: accessToken },
    });
    return response;
  }
  async postFormData(uri, data) {
    const response = await axios.post(uri, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        organization: "60c4b8a9bf23a55ec0e278a1",
        token: accessToken,
      },
    });
    return response;
  }
  async update(uri, data) {
    const response = await axios.put(uri, data, {
      headers: {
        "Content-Type": "application/json",
        token: accessToken,
      },
    });
    return response;
  }
  async getWithBody(uri, data) {
    const response = await axios.get(uri, data, {
      headers: {
        organization: "60c4b8a9bf23a55ec0e278a1",
        token: accessToken,
      },
    });
    return response;
  }

  // handleError(error) {
  //   if (error.response && error.response.status) {
  //     switch (error.response.status) {
  //       // case 401:
  //       //   window.location.href = `/`;
  //       //   return Promise.reject(error.response.data);
  //       case 400:
  //         window.location.href = `/`;
  //         return Promise.reject(error.response.data);
  //       case 404:
  //         return Promise.reject(error.response);
  //       default:
  //         let err = new Error(error.response);
  //         return Promise.reject(err);
  //     }
  //   } else {
  //     let err = new Error(error.response);
  //     return Promise.reject(err);
  //   }
  // }
}
