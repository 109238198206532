/**
 * Generic Table component with pagination support
 */
import React, { Component, createRef, useEffect } from "react";
import "./style-css.css";
import {
  Grid,
  Table,
  VirtualTable,
  TableHeaderRow,
  DragDropProvider,
  TableColumnReordering,
  PagingPanel,
  TableSelection,
  TableColumnResizing,
  TableColumnVisibility,
  ColumnChooser,
  Toolbar,
} from "@devexpress/dx-react-grid-material-ui";
import { connectProps } from "@devexpress/dx-react-core";
import {
  PagingState,
  CustomPaging,
  DataTypeProvider,
  SelectionState,
} from "@devexpress/dx-react-grid";
import { IconButton, Typography, Button } from "@material-ui/core";
import DataGrid, { LoadPanel } from "devextreme-react/data-grid";
import moment from "moment";
import helpers from "helpers/helpers";
import { SortingState } from "@devexpress/dx-react-grid";
import nodataimg from "assets/images/nodata.svg";
import { CircularProgress, Paper } from "@material-ui/core";
import Styles from "./styles";
import { withStyles } from "@material-ui/core/styles";
import withPagination from "components/PaginationHOC/PaginationHOC";

const Overlay = (props) => (
  <ColumnChooser.Overlay {...props} visible={props.isShow} />
);

const toggleButton = () => null;

export const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export const getInsideTableHeight = (rows) => {
  // const height = ((94 + (48 * rows.length)) - rows.length % 2 ? rows.length : rows.length - 1)
  // console.log('height : ', height)
  // return height
  switch (rows.length) {
    case 1:
      return 100;
    case 2:
      return 150; // 48
    case 3:
      return 200; // 46
    case 4:
      return 250; // 48
    case 5:
      return 300; // 46
    case 6:
      return 420; // 48
    case 7:
      return 376; // 46
    case 8:
      return 424; // 48
    case 9:
      return 470; // 46
    case 10:
      return 518; // 48
    default:
      return 300;
  }
};

const h3style = {
  display: "block",
  fontSize: "1.17em",
  marginBlockStart: "1em",
  marginBlockEnd: "1em",
  marginInlineStart: "0px",
  marginInlineEnd: "0px",
  fontWeight: "bold",
};
const NoDataFound = (props) => {
  return (
    <td>
      <div
        style={{
          position: "absolute",
          textAlign: "center",
          // maxWidth: "11rem",
          // justifyContent:"center",
          // alignItems:"center",
          marginTop: "4%",
          marginLeft: "43%",
          // top:"calc(30% + 2rem)",
          // left:"calc(50% - 4rem)"
        }}
      >
        <img src={nodataimg} alt="notfound" />
        <h5 style={{ textAlign: "center", color: "#666666", ...h3style }}>
          {props.msg || "Data Not Found"}
        </h5>
      </div>
    </td>
  );
};

const DateFormatter = ({ value }) => {
  const { FORMAT, timeZone } = helpers.getDateFormatAdvance();
  // console.log(
  //   "moment.unix(value).tz(timeZone).format(FORMAT)",
  //   FORMAT,
  //   timeZone,
  //   value,
  //   moment.unix(value).tz(timeZone).format(FORMAT)
  // );
  return moment.unix(value).tz(timeZone).format(FORMAT);
};
const AmountTypeFormatter = ({ value }) => {
  return <Typography>{parseFloat(value || 0).toFixed(2)}</Typography>;
};
const EmailTypeFormatter = ({ value }) => {
  return (
    <Typography
      style={{
        textTransform: "none",
      }}
      align="left"
    >
      {value}
    </Typography>
  );
};
const DateTypeProvider = (props) => (
  <DataTypeProvider formatterComponent={DateFormatter} {...props} />
);

const AmountTypeProvider = (props) => (
  <DataTypeProvider formatterComponent={AmountTypeFormatter} {...props} />
);
const EmailTypeProvider = (props) => (
  <DataTypeProvider formatterComponent={EmailTypeFormatter} {...props} />
);
const getWidthInValue = (totalWidth, percentagewidth) => {
  console.log(
    "tableswidth",
    totalWidth,
    percentagewidth,
    totalWidth * (percentagewidth / 100)
  );
  return totalWidth * (percentagewidth / 100);
};

const GetchooseColumBtn = (ref) => {
  return (toolProps) => {
    console.log(toolProps, 333);
    toolProps.buttonRef(ref);
    return (
      <IconButton
        style={{ marginBottom: "19px" }}
        size="small"
        onClick={toolProps.onToggle}
      >
        <img src="/images/columnChoose.svg" alt="alt" />
      </IconButton>
    );
  };
};
const ChooseColumnIcon = (toolProps) => {
  console.log(toolProps, "buttonRef", toolProps.buttonRef, "333");
  return (
    <IconButton
      style={{ marginBottom: "19px" }}
      size="small"
      ref={toolProps.buttonRef}
      // onClick={toolProps.onToggle}
      onClick={toolProps.toggleVisibility}
    >
      <img src="/images/columnChoose.svg" alt="alt" />
    </IconButton>
  );
};

class DexTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShow: false,
      columnWidths: props.defaultColumnWidths,
    };
    this.tableRef = React.createRef();
    this.buttonRef = React.createRef();

    this.overlay = connectProps(Overlay, () => {
      const { isShow } = this.state;
      return {
        isShow,
        onClose: this.toggleVisibility,
      };
    });
    this.toggleVisibility = () => {
      this.setState((prevState) => ({
        isShow: !prevState.isShow,
      }));
    };
  }

  getFromatters = () => {
    return (this.props.formatters || []).map((f) => {
      return (
        <DataTypeProvider formatterComponent={f.formatterFn} for={f.columns} />
      );
    });
  };

  handleColumnWidthChange = (widths) => {
    this.setState({ columnWidths: widths });
  };

  tableRow = ({ row, ...restProps }) => {
    // console.log(row, "*******8");
    let flag = false;
    if (this.props.Read && this.props.onClickRow !== undefined) {
      flag = true;
    }
    return (
      <Table.Row
        hover={true}
        {...restProps}
        onClick={() => {
          flag && this.props.onClickRow(row);
        }}
        style={{
          cursor: flag ? "pointer" : "text",
        }}
      />
    );
  };

  componentDidMount() {
    console.log(
      "tableswidth",
      this.tableRef.current,
      (this.tableRef.current || {}).offsetWidth
    );
    if (this.props.columWidthsType === "percentage") {
      let newColumWidths = this.state.columnWidths.map((f) => {
        let newWidth = getWidthInValue(
          (this.tableRef.current || {}).offsetWidth,
          f.width
        );
        console.log("tableswidth", newWidth);
        return { ...f, width: newWidth };
      });
      this.setState({ columnWidths: newColumWidths });
    }
  }

  // toggleButton = () => {
  //   return (
  //     <button>
  //       <img
  //         style={{ border: "solid 1px red", background: "black" }}
  //         width="20px"
  //         src={"/images/columnChoose.svg"}
  //         alt=""
  //       />
  //     </button>
  //   );
  // };

  handleChooseColumn = (hideCols) => {
    const { props } = this;
    if (props.columns.length - hideCols.length >= 1) {
      console.log("hideCols : ", hideCols);
      // this.setState({
      //   hiddenColumnNames: hideCols,
      // });
      // call redux dispatch fun to update hidden values
      props.setHiddenColmn(hideCols);
    }
  };

  componentDidUpdate() {
    this.overlay.update();
  }

  toggleButton(props) {
    return (
      <IconButton
        style={{ marginRight: 5 }}
        size="small"
        onClick={props.onToggle}
        buttonRef={props.buttonRef}
      >
        <img src="/images/columnChoose.svg" alt="alt" />
      </IconButton>
    );
  }

  render() {
    console.log("sorting", this.props);
    const { classes } = this.props;
    const { hiddenColumnNames } = this.state;
    console.log(hiddenColumnNames, "3456789jhgfdsdfg");
    var chooseIcon = document.createElement("img");
    chooseIcon.src = "/images/columnChoose.svg";
    chooseIcon.alt = "Column Choose";
    console.log(
      this.tableRef.current?.childNodes[2]?.childNodes[0]?.childNodes[1]
        ?.childNodes[0]?.childNodes[0],
      "table ref ref"
    );
    // const iconElement =
    //   this.tableRef.current?.childNodes[2]?.childNodes[0]?.childNodes[1]
    //     ?.childNodes[0]?.childNodes[0];
    // console.log(iconElement, "sgsgdgndgdf");
    // if (iconElement) {
    //   iconElement?.parentNode.replaceChild(chooseIcon, iconElement);
    // }

    return (
      <Paper className={classes.paper} key={this.props.key || 0}>
        {/* <button onClick={this.toggleVisibility}>Show Column Chooser</button> */}

        <div
          id="test_dex"
          ref={this.tableRef}
          className={`${classes.myDevTable}`}
          style={{
            // border: "1px solid #E2E2E3",
            height:
              this.props.height ||
              (this.props.data.length < 5
                ? getInsideTableHeight(this.props.data)
                : "300px"),
          }}
        >
          <Grid
            id="devexpress"
            rows={this.props.data}
            columns={this.props.columns}
            // onContentReady={(e) => {
            //   console.log("grid grid", e);
            //   var columnChooserView = e.component.getView("columnChooserView");
            //   if (!columnChooserView._popupContainer) {
            //     columnChooserView._initializePopupContainer();
            //     columnChooserView.render();
            //     columnChooserView._popupContainer.option("position", {
            //       of: e.element,
            //       my: "right top",
            //       at: "right top",
            //       offset: "0 50",
            //     });
            //   }
            // }}
          >
            {this.getFromatters()}

            <VirtualTable
              height={
                this.props.height ||
                (this.props.data.length < 5
                  ? getInsideTableHeight(this.props.data)
                  : "300px")
              }
              // height={
              //   this.props.isCustomHeight
              //     ? getWindowDimensions().height - 260
              //     : getInsideTableHeight(this.props.data)
              // }
              noDataCellComponent={() => {
                return (
                  <>
                    {this.props.loading ? (
                      <CircularProgress className={classes.loader} />
                    ) : (
                      <NoDataFound
                        msg={this.props.noDataMsg || "No Data Found"}
                      />
                    )}
                  </>
                );
              }}
              rowComponent={this.props.tableRow || this.tableRow}
            />

            <TableColumnResizing
              resizingMode="widget"
              columnWidths={this.state.columnWidths}
              onColumnWidthsChange={this.handleColumnWidthChange}
            />
            {this.props.EmailColumn && (
              <EmailTypeProvider for={this.props.EmailColumn} />
            )}
            {this.props.dateColumns && (
              <DateTypeProvider for={this.props.dateColumns} />
            )}
            {this.props.amountColumns && (
              <AmountTypeProvider for={this.props.amountColumns} />
            )}

            {!this.props.disableReordering && (
              <>
                {" "}
                <DragDropProvider />{" "}
              </>
            )}
            {!this.props.disableReordering && (
              <TableColumnReordering
                defaultOrder={this.props.columnOrder || []}
              />
            )}
            {this.props.sorting !== undefined && (
              <SortingState
                sorting={this.props.sorting}
                onSortingChange={this.props.onSortingChange}
              />
            )}
            <TableHeaderRow
              id="table-header"
              showSortingControls={this.props.sorting !== undefined}
            />
            {!this.props.hideColumnChooser && (
              <TableColumnVisibility
                hiddenColumnNames={this.props.hidden}
                onHiddenColumnNamesChange={this.handleChooseColumn}
                columnExtensions={[
                  { columnName: "actions", togglingEnabled: false },
                ]}
              />
            )}
            {!this.props.hideColumnChooser && <Toolbar />}
            {!this.props.hideColumnChooser && (
              <ColumnChooser
                // overlayComponent={this.overlay}
                // // toggleButtonComponent={toggleButton}
                // toggleButtonComponent={(props) => {
                //   return (
                //     <div>
                //       <ChooseColumnIcon
                //         toggleVisibility={this.toggleVisibility}
                //         {...props}
                //       />
                //     </div>
                //   );
                // }}
                toggleButtonComponent={this.toggleButton}
              />
            )}
          </Grid>
          {/* {this.props.loading && <CircularProgress className={classes.loader}/>} */}
        </div>
      </Paper>
    );
  }
}

export default withStyles(Styles)(DexTable);
