import { React, useState, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";

import {
  IconButton,
  CardContent,
  FormGroup,
  Card,
  Grid,
  CardHeader,
  Divider,
  Button,
  TextField,
  Typography,
  FormLabel,
  Select,
  Tooltip,
  Grow,
} from "@material-ui/core";

import withSection from "components/Section/Section";

const useStyles = makeStyles((theme) => ({
  line: {
    marginTop: -23,
  },
  table: {
    minWidth: 500,
  },
  dividerline: {
    // border: "1px solid black",
    width: "70%",
    margin: "-25px 0px 0px 125px",
    [theme.breakpoints.up("md")]: {
      width: "82%",
      margin: "-25px 0px 0px 260px",
    },
  },
}));
const StyledTableCell = withStyles((theme) => ({
  head: {
    // backgroundColor: "#999682",
    backgroundColor: theme.palette.primary.main,

    color: theme.palette.common.white,
  },
  body: {
    // height: "14vh",
    width: 15,
    height: 1,
  },
  btn: {
    width: "300px",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({}))(TableRow);

const CustomTableRow = (props) => {
  const {
    row,
    index,
    removeRows,
    onChange,
    editWebhook,
    deleteWebhook,
    addWebhook,
  } = props;

  const classes = useStyles();
  const [edit, setEdit] = useState(row._id == undefined || false);
  return (
    <>
      <StyledTableCell component="th" scope="row">
        <FormControl
          fullWidth
          size="small"
          variant="outlined"
          className={classes.btn}
          disabled={edit ? false : true}
        >
          <Select
            required
            name="type"
            value={row.type}
            native
            onChange={(e) => onChange(e, index)}
          >
            <option value="none">-Select-</option>

            <option aria-label="None" value="On Subscribe">
              On Subscribe
            </option>
            <option value="On Unsubscribe">On Unsubscribe</option>
          </Select>
        </FormControl>
      </StyledTableCell>
      <StyledTableCell align="left">
        <TextField
          disabled={edit ? false : true}
          required
          className={classes.btn}
          size="small"
          variant="outlined"
          fullWidth
          id="name"
          value={row.url}
          name="url"
          onChange={(e) => onChange(e, index)}
        />
      </StyledTableCell>
      <StyledTableCell align="left">
        <FormControl
          fullWidth
          disabled={edit ? false : true}
          required
          size="small"
          variant="outlined"
          className={classes.btn}
          value={row.method}
          name="method"
        >
          <Select
            required
            name="method"
            value={row.method}
            native
            onChange={(e) => onChange(e, index)}
          >
            <option value="none">-Select-</option>
            <option value="GET">GET</option>
            <option value="POST">POST</option>
            <option value="PUT">PUT</option>
          </Select>
        </FormControl>
      </StyledTableCell>
      <StyledTableCell align="left">
        <TextField
          disabled={edit ? false : true}
          required
          className={classes.btn}
          size="small"
          variant="outlined"
          fullWidth
          name="apiKey"
          value={row.apiKey}
          onChange={(e) => onChange(e, index)}
        />
      </StyledTableCell>
      <StyledTableCell align="left">
        {edit && (
          <>
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              style={{
                marginLeft: 0,
                backgroundColor: "transparent",
                boxShadow: "none",
                color: "#9e9e9e",
              }}
              onClick={async () => {
                if (row._id == undefined) {
                  await addWebhook(row.appId, row);
                } else {
                  await editWebhook(row.appId, row._id, row);
                }
                setEdit(false);
              }}
              variant="contained"
            >
              Save
            </Button>{" "}
            <Button
              style={{
                marginLeft: 0,
                backgroundColor: "transparent",
                boxShadow: "none",
                color: "#9e9e9e",
              }}
              variant="contained"
              color="primary"
              onClick={() => {
                if (row._id == undefined) {
                  removeRows(row, index);
                  return;
                }
                setEdit(false);
              }}
            >
              Cancel
            </Button>
          </>
        )}
        {!edit && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
            }}
          >
            <div item style={{ width: "4%" }}>
              <Tooltip title="Edit" aria-label="edit">
                <EditOutlinedIcon
                  onClick={() => {
                    setEdit(true);
                  }}
                />
              </Tooltip>
            </div>
            <div item style={{ width: "4%" }}>
              <Tooltip title="Delete" aria-label="delete">
                <DeleteOutlineIcon
                  onClick={() => deleteWebhook(row.appId, row._id)}
                />
              </Tooltip>
            </div>
            <div item style={{ width: "4%" }}>
              {props.addNewRow}
            </div>
          </div>
        )}
      </StyledTableCell>
    </>
  );
};

function ApplicationWebhooks(props) {
  const { appId, webhooks, editWebhook, deleteWebhook, addWebhook } = props;

  const classes = useStyles();
  const initialRows = [
    { type: "", applicationApiUrl: "", applicationMethod: "" },
  ];
  let [rows, setRows] = useState(webhooks);

  const addRows = () => {
    const item = { appId, type: "", url: "", method: "", apiKey: "" };
    setRows([...rows, item]);
  };

  useEffect(() => {
    setRows(webhooks);
    console.log(rows);
  }, [webhooks]);

  const [edit, setEdit] = useState(false);

  const removeRows = (payload, index) => {
    console.log({ payload });
    if (rows.length != 1) {
      var filtered = rows.filter(function (el, i) {
        console.log(index, i, index !== i);
        return index !== i;
      });
      console.log({ filtered });
      setRows([...filtered]);
    }
  };

  const onChange = (event, index) => {
    let payload = rows.filter(function (el, i) {
      console.log(index, i, index !== i);
      return index == i;
    })[0];
    let newpayload = { ...payload };
    newpayload[event.currentTarget.name] = event.currentTarget.value;
    let webhooks = [...rows];
    webhooks[index] = newpayload;
    console.log({ newpayload });
    setRows([...webhooks]);
  };

  return (
    <div id="appliation-webhook-section">
      <div className="w-100 d-flex justify-content-end">
        {webhooks.length == 0 && (
          <Tooltip title="Add" aria-label="add">
            <Button
              startIcon={<AddCircleOutlineOutlinedIcon />}
              color="secondary"
              onClick={addRows}
            >
              Add Webhook
            </Button>
          </Tooltip>
        )}
      </div>

      <div>
        <TableContainer style={{ borderRadius: "0" }}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left"> Type </StyledTableCell>
                <StyledTableCell align="left"> URL </StyledTableCell>
                <StyledTableCell align="left"> Method </StyledTableCell>
                <StyledTableCell align="left"> API Key </StyledTableCell>
                <StyledTableCell align="center" style={{ width: "14%" }}>
                  Actions
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <StyledTableRow>
                  <CustomTableRow
                    row={row}
                    appId={appId}
                    onChange={onChange}
                    removeRows={removeRows}
                    addWebhook={addWebhook}
                    editWebhook={editWebhook}
                    deleteWebhook={deleteWebhook}
                    index={index}
                    addNewRow={
                      index == rows.length - 1 && (
                        <Tooltip title="Add" aria-label="add">
                          <AddCircleOutlineOutlinedIcon
                            color="secondary"
                            onClick={addRows}
                          />
                        </Tooltip>
                      )
                    }
                  />
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default withSection(ApplicationWebhooks, "Webhooks");
