import * as types from "./actionTypes";
const intialState = {
  users: [],
  rolesData: [],
  loading: false,
};

export default function reducer(state = intialState, action) {
  switch (action.type) {
    case types.FETCH_USERS:
      return {
        ...state,
        users: [...action.payload],
      };
    case types.FETCH_USERS_FAILED:
      return {
        ...state,
        users: [],
      };
    case types.LOAD_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
}
