import React, { useEffect } from "react";
import { checkPermission } from "helpers/permissons";
import {
  Container,
  Typography,
  Grid,
  Button,
  TextField,
  InputAdornment,
  IconButton,
  Badge,
  Chip,
  Avatar,
  Tooltip,
} from "@material-ui/core/";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import NotificationsOffOutlinedIcon from "@material-ui/icons/NotificationsOffOutlined";
import BlockIcon from "@material-ui/icons/Block";
import { RiEditLine, RiDeleteBin5Line } from "react-icons/ri";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Applicationtable from "./Applicationtable";
import { Link } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import FilterComponent from "../../../components/FilterComponent/FilterComponent";
import Pagination from "@material-ui/lab/Pagination";
import * as actionTypes from "../redux/actionTypes";
import appApi from "api/ApplicationAPIs/index";
import {
  getApplications,
  updateFilterData,
  updateSort,
  storeSearchedValue,
  updateApplicationHiddenTableCol,
} from "./Redux/action";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Styles from "../Styles/Styles";
import axios from "axios";
import getURL from "../../../api/config";
import DexTable from "components/DexTable/DexTable.jsx";
import { DataTypeProvider } from "@devexpress/dx-react-grid";
import Breadcrumb from "components/Breadcrumbs/Breadcrumb";
import { CustomTextField } from "components/Customized";
import dataOptions from "./filterOptions.json";
import Loader from "../../../components/common/stuff/Loader";
import HelpdeskFAB from "../../../views/HelpdeskFAB/HelpdeskFab";
import { withSnackbar } from "notistack";
import bell_Inactive from "../../../assets/images/Bell_Inactive.svg";
import bell_Active from "../../../assets/images/Bell_Active.svg";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  indicator: {
    backgroundColor: "#1890ff",
  },
  button: {
    "&:hover": {
      color: "White",
    },
    color: "white",
    boxShadow: "none",
    padding: "9px 30px 9px 30px",
  },
  dot: {
    marginLeft: "-15px",
    marginTop: "11px",
  },
  customBadgeApproved: {
    backgroundColor: "#21BA45",
    marginLeft: "-15px",
    marginTop: "11px",
  },
}));

class Applications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      openDropdown: false,
      setDropdown: false,
      page: 1,
      sort: { createdOn: -1 },
      sorting: [{ columnName: "createdOn", direction: "desc" }],
      filters: {},
      size: 10,
      name: "",
      searchkey: "",
      filterData: [],
      total: 0,
      totalpublished: 0,
      data_options: [...dataOptions],
      category: [],
      email: "",
      userId: "",
    };
  }
  fetchApp = async (filters, val) => {
    const { getData, filterProps } = this.props;
    let res = await getData(
      this.state.page,
      this.state.size,
      JSON.stringify(filters),
      JSON.stringify(filterProps.sort),
      val
    );
    this.setState({ aplicationList: res });
  };
  async componentDidMount() {
    this.props.clearAllApplicationDetails();
    const { filterProps } = this.props;
    // this.setState({ searchkey: searchedValue });
    const { filters } = filterProps;
    this.getCategories();
    await this.fetchApp({ ...filters, status: "Published" }, "nullArray");
    await this.fetchApp({ ...filters });
  }

  handleLimitChangePageRow = async (size) => {
    const { filterProps } = this.props;
    const { filters } = filterProps;
    await this.setState({
      size: size,
      page: 0,
    });
    this.props.getData(
      this.state.page + 1,
      size,
      JSON.stringify(filters),
      JSON.stringify(filterProps.sort),
      this.state.value
    );
  };

  handleSortByChange = (sorting) => {
    console.log(sorting, "sorting");
    const { filterProps, updateSorting, setHiddenColumn } = this.props;
    let filters = { ...filterProps.filters };
    let sort = {};
    sorting.forEach((f) => {
      sort[f.columnName] = f.direction === "asc" ? 1 : -1;
    });
    if (this.state.value === 1) {
      filters["status"] = "Published";
    }
    // this.setState({ sorting, sort });
    updateSorting({
      sorting,
      sort,
    });
    this.props.getData(
      this.state.page,
      this.state.size,
      JSON.stringify(filters),
      JSON.stringify(sort),
      this.state.value
    );
  };

  findAndExecuteAjax = (type) => {
    switch (type) {
      case "Application Name":
        return (operator, value) => {
          if (operator === "Contains of") {
            return { name: { $regex: value, $options: "i" } };
          }
          if (operator === "Starts With") {
            return { name: { $regex: `^${value}`, $options: "i" } };
          }
          if (operator === "Ends With") {
            return { name: { $regex: `${value}$`, $options: "i" } };
          }
          if (operator === "Equals To") {
            return { name: { $regex: `^${value}$`, $options: "i" } };
          }
        };
      case "Status":
        return (operator, value) => {
          if (operator === "Equals To") {
            return { status: value };
          }
        };
      case "Created On":
        return (operator, value) => {
          var timestamp = new Date(value.split(".").join("-")).getTime() / 1000;
          if (operator === "Equals To") {
            let gte = new Date(value.split(".").join("-")).getTime() / 1000;
            let lte = new Date(`${value}T23:59:59+05:30`).getTime() / 1000;
            return { createdon: { $gte: gte, $lte: lte } };
          }
          if (operator === "Greater than") {
            let gt = new Date(`${value}T23:59:59+05:30`).getTime() / 1000;
            return { createdon: { $gt: gt } };
          }
          if (operator === "Lesser than") {
            let lt = new Date(`${value}T00:00:00+05:30`).getTime() / 1000;
            return { createdon: { $lt: lt } };
          }
          if (operator === "Greater than or Equal To") {
            return { createdon: { $gte: timestamp } };
          }
          if (operator === "Less than or Equals To") {
            return { createdon: { $lte: timestamp } };
          }
        };
      case "Category":
        return (operator, value) => {
          let val = 0;
          this.state.category.map((item, id) => {
            if (value === item.name) {
              val = item._id;
            }
          });
          if (operator === "Equals To") {
            return { category: val };
          }
        };

      default:
        return {};
    }
  };

  filterFunc = (arr) => {
    let filters = {};
    if (arr.length === 1) {
      const { columns, operator, value } = arr[0];
      console.log(value);
      var category_id = this.props.data;
      console.log("catid", category_id, columns, operator, value);
      let filter = this.findAndExecuteAjax(columns)(operator, value);
      this.getApplicationsByFilter({ ...filter }, arr);
    } else {
      arr.map((item, id) => {
        const { columns, operator, value } = item;
        let filter = this.findAndExecuteAjax(columns)(operator, value);
        filters = { ...filters, ...filter };
      });
      this.getApplicationsByFilter({ ...filters }, arr);
    }
  };

  getApplicationsByFilter = async (filter, arr, search) => {
    let page = 0;
    if (arr[0].columns.trim().length === 0) {
      page = this.state.page;
    }
    const { updateFilter, filterProps } = this.props;
    const { filters } = this.state;
    let filterMerg = {};
    if (this.state.value === 1) {
      if (filter.status === "Draft") {
        filterMerg = {
          ...filter,
          status: "",
        };
        updateFilter({
          filter: { ...filterMerg, status: "Draft" },
          filterArray: arr,
        });
        // this.setState({
        //   filters: { ...filterMerg, status: "Draft" },
        // });
      } else {
        // this.setState({
        //   filters: { ...filter },
        // });
        updateFilter({
          filter: { ...filter },
          filterArray: arr,
        });
        filterMerg = { ...filter, status: "Published" };
      }
    } else {
      filterMerg = { ...filter };
      // this.setState({
      //   filters: { ...filterMerg },
      // });
      updateFilter({
        filter: { ...filter },
        filterArray: arr,
      });
    }
    this.props.getData(
      page,
      this.state.size,
      JSON.stringify(filterMerg),
      JSON.stringify(filterProps.sort),
      this.state.value
    );
    if (!search) {
      this.setState({
        searchkey: "",
      });
    }
  };

  getCategories = async () => {
    try {
      let res = await appApi.get_Categories();
      if (res.data && res.data.data) {
        this.setState({ category: [...res.data.data] });
        let filter_Data = dataOptions.map((item, id) => {
          if (item.value === "Category") {
            return {
              ...item,
              fieldValues: res.data.data.map((e, id) => {
                return {
                  key: id + 1,
                  value: e.name,
                };
              }),
            };
          } else {
            return { ...item };
          }
        });
        this.setState({ data_options: [...filter_Data] });
        console.log(res.data.data, filter_Data, 56543465434);
      }
    } catch (err) {}
  };

  openDropDown = () => {
    this.setState({ openDropDown: true });
  };
  dropUp = () => {
    this.setState({ setDropdown: true });
  };

  handleChange = async (event, newValue) => {
    const { filterProps } = this.props;
    let filters = { ...filterProps.filters };
    console.log(filters, this.props.totalCount, "sdfsdfsdf");
    if (newValue === 1) {
      filters["status"] = "Published";
      this.props.getData(
        1,
        10,
        JSON.stringify(filters),
        JSON.stringify(filterProps.sort),
        newValue
      );
    } else {
      this.props.getData(
        1,
        10,
        JSON.stringify(filters),
        JSON.stringify(filterProps.sort),
        newValue
      );
    }
    this.setState({ value: newValue, page: 1, size: 10 });
  };

  editApplication = (row, e) => {
    e && e.stopPropagation();
    const { _id } = row;
    this.props.history.push("/app/detail", {
      appId: _id,
      edit: e ? true : false,
    });
  };

  handleClick = (row, e) => {
    // e.stopPropagation();
    console.log("fjhghjfgjh", row, e);
    this.editApplication(row);
    // this.props.store_SearchedValue(this.state.searchkey);
  };

  onChangeSearchAppName = async (event) => {
    const { filterProps } = this.props;
    let filters = { ...filterProps.filters };
    let page = 1;
    this.setState({
      searchkey: event.target.value,
      page: page,
    });
    if (this.state.value === 1) {
      filters["status"] = "Published";
      filters["name"] = { $regex: "^" + event.target.value, $options: "i" };
    } else {
      filters["name"] = { $regex: "^" + event.target.value, $options: "i" };
    }
    // this.getApplicationsByFilter(filters, filterProps.filterArray, true);
    this.props.getData(
      page,
      this.state.size,
      JSON.stringify(filters),
      JSON.stringify(filterProps.sort),
      this.state.value
    );
  };

  handlePageChange = (e, newPage) => {
    const { filterProps } = this.props;
    let filters = { ...filterProps.filters };
    this.setState({
      page: newPage,
    });
    if (this.state.value === 1) {
      filters["status"] = "Published";
    }
    if (this.state.searchkey.trim().length !== 0) {
      filters["name"] = { $regex: "^" + this.state.searchkey, $options: "i" };
    }

    this.props.getData(
      newPage,
      this.state.size,
      JSON.stringify(filters),
      JSON.stringify(filterProps.sort),
      this.state.value
    );
  };

  tagProvider = ({ value }) => {
    let tag_components = [...(value || [])].map(function (i, key) {
      return <Chip key={key} label={<span>{i}</span>} />;
    });
    return tag_components;
  };

  statusProvider = ({ value }) => {
    let status = value || "";
    if (status === "Published") {
      return (
        <div className="d-flex justify-content align-items-center">
          <Badge
            color="secondary"
            variant="dot"
            style={{ marginRight: "10px" }}
          />
          <Typography variant="body1"> Published </Typography>
        </div>
      );
    } else {
      return (
        <div className="d-flex justify-content align-items-center">
          <Badge
            classes={{ badge: this.props.classes.customBadgeDraft }}
            variant="dot"
            style={{ marginRight: "10px" }}
          />
          <Typography variant="body1"> Draft </Typography>
        </div>
      );
    }
  };

  catergoryProvider = (obj) => {
    let category = obj.value || [];
    return (category[0] || {}).name || "";
  };

  appProvider = ({ row }) => {
    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item>
          <img
            style={{
              textIndent: "10000px",
              textAlign: "center",
              objectFit: "fill",
              width: "40px",
              height: "40px",
              borderRadius: "50px",
            }}
            // style={{ width: "40px", height: "40px" }}
            // alt="img"
            src={`data:image/png;base64,${row.applicationIconBase64}`}
          />
          {/* <Avatar style={{ borderRadius: "10px" }}></Avatar> */}
        </Grid>
        <Grid item className="pl-3">
          <Typography variant="body1">
            {row.name}
            <br />
          </Typography>
        </Grid>
      </Grid>
    );
  };

  pushToOnboardPage = (e) => {
    e.stopPropagation();
    // this.props.store_SearchedValue(this.state.searchkey);
    this.props.history.push("/app/onboard-application");
  };

  actionProvider = ({ row, e }) => {
    console.log(row, "row");
    //console.log("trrrrrrrrrrrrrsxssdd",this.state.subscribe)
    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <Tooltip title="Edit" aria-label="edit">
            <IconButton
              // disabled={flag && !checkPermission("btn47") ? true : false}
              size="small"
              color="inherit"
              onClick={(e) =>
                checkPermission("btn47") && this.editApplication(row, e)
              }
            >
              <RiEditLine fontSize="20px" />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip
            title={row.appSubscribe ? "UnSubscribe" : "Subscribe"}
            aria-label="block"
          >
            <IconButton
              size="small"
              color="inherit"
              onClick={(e) => {
                e.stopPropagation();
                {
                  row.appSubscribe
                    ? this.removeSubscribe(row)
                    : this.SubscribeApp(row);
                }
              }}
            >
              {row?.appSubscribe ? (
                <img src={bell_Active} />
              ) : (
                <img src={bell_Inactive} />
              )}
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    );
  };

  SubscribeApp = async (row, values) => {
    console.log("rowwwww", values, row);
    const { filterProps } = this.props;
    const { filters } = filterProps;
    const { _id } = row;
    let appId = _id;
    let payload = {
      email: this.props?.userInfo?.email || "",
      userid: this.props?.userInfo?._id || "",
    };
    try {
      let res = await appApi.addSubscribe(appId, payload);
      console.log("1111111111111111222", res);
      this.props.enqueueSnackbar(res.data._msg || "", {
        variant: "success",
        autoHideDuration: 3000,
      });
      this.state.value === 1
        ? await this.fetchApp({ ...filters, status: "Published" })
        : await this.fetchApp({ ...filters });

      console.log("suuuuuuuuuuuuuuuuuuub", res);
    } catch (err) {
      this.props.enqueueSnackbar(
        err?.response?.data?._msg || "connection error!",
        {
          variant: "error",
        }
      );
    }
  };

  removeSubscribe = async (row) => {
    const { filterProps } = this.props;
    const { filters } = filterProps;
    const { _id } = row;
    let appId = _id;
    let userId = this.props?.userInfo?._id || "";
    try {
      let res = await appApi.removeSubscribe(appId, userId);
      console.log("1111111111111111", res);
      this.props.enqueueSnackbar(res.data._msg || "", {
        variant: "success",
      });
      this.state.value === 1
        ? await this.fetchApp({ ...filters, status: "Published" })
        : await this.fetchApp({ ...filters });
      // await this.fetchApp({ ...filters });
      console.log("suuuuuuuuuuuuuuuuuuub", res);
    } catch (err) {
      this.props.enqueueSnackbar(
        err?.response?.data?._msg || "connection error!",
        {
          variant: "error",
        }
      );
    }
  };

  render() {
    const { props, state } = this;
    const { value, data_options } = state;
    const {
      data,
      totalCount,
      classes,
      totalPublished,
      loading,
      filterProps,
      userInfo,
      hidden,
      setHiddenColumn,
    } = props;
    console.log(
      "*****************",
      this.state.aplicationList,
      this.state.subscribedList
    );
    return (
      <div>
        <div
        // className={classes.appTopNav}
        >
          {loading && <Loader />}
          <Grid container style={{}}>
            <Grid item sm={12} md={12} xs={12} lg={12}>
              <Breadcrumb
                {...this.props}
                links={[
                  { name: "Applications", url: "/app/applications" },
                  { name: "Applications List" },
                ]}
              />
            </Grid>
            <Grid item xs={12} sm={5} md={5}>
              <AppBar
                className={classes.appbarresponsive}
                style={{ backgroundColor: "#f2f2f2" }}
              >
                <Tabs
                  style={{
                    marginBottom: "3%",
                    // borderBottom: "1px solid #e8e8e8",
                  }}
                  value={value}
                  onChange={this.handleChange}
                  aria-label="simple tabs example"
                >
                  <Tab
                    style={{ textTransform: "none" }}
                    // label={`All (${totalCount})`}
                    label={`All ( ${totalCount} )`}
                    {...a11yProps(0)}
                  />
                  <Tab
                    style={{ textTransform: "none" }}
                    label={`Published ( ${totalPublished} )`}
                    {...a11yProps(1)}
                  />
                </Tabs>
              </AppBar>
            </Grid>
            <Grid item xs={12} sm={7} md={7}>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-start"
                spacing={4}
              >
                <Grid item md={4} sm={4} xs={12}>
                  <CustomTextField
                    className=" mt-0 "
                    autoComplete="off"
                    fullWidth
                    id="filled-search"
                    type="text"
                    variant="outlined"
                    size="small"
                    placeholder="Search"
                    value={this.state.searchkey}
                    margin="normal"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {" "}
                          <SearchIcon />{" "}
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      this.onChangeSearchAppName(e);
                    }}
                  />
                </Grid>
                <div className={classes.applicationvlline}></div>
                <Grid item md={2} sm={4} xs={12}>
                  <FilterComponent
                    getDataByFilter={this.getApplicationsByFilter}
                    openDropDown={this.openDropDown}
                    dropUp={this.dropUp}
                    open={this.openDropdown}
                    filterData={this.state.filterData}
                    dataOptions={data_options}
                    filterFunc={this.filterFunc}
                    filterHistory={filterProps.filterArray}
                  />
                </Grid>

                <div className={classes.applicationvlline}></div>

                <Grid item md={2} sm={3} xs={12}>
                  <Button
                    // component={Link}
                    style={{
                      color: "white",
                      width: "100%",
                      boxShadow: "none",
                      padding: "6px 11px 6px 11px",
                    }}
                    disabled={!checkPermission("btn69")}
                    variant="contained"
                    color="primary"
                    size="small"
                    alignItems="center"
                    onClick={(e) =>
                      checkPermission("btn69") && this.pushToOnboardPage(e)
                    }
                    // to={checkPermission("btn69") && "/app/onboard-application"}
                    startIcon={<AddIcon />}
                  >
                    Onboard
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <TabPanel value={value} index={0}>
            <DexTable
              // loading={loading}
              data={data || []}
              columnOrder={[
                "name",
                "category",
                "tags",
                "status",
                "createdon",
                "shortKey",
                "applicationType",
                "actions",
              ]}
              columns={[
                { name: "name", title: "Application Name" },
                { name: "category", title: "Category" },
                { name: "createdon", title: "Created On" },
                { name: "tags", title: "Tags" },
                { name: "status", title: "Status" },
                { name: "shortKey", title: "Short Key" },
                { name: "applicationType", title: "Type" },
                { name: "actions", title: "Actions" },
              ]}
              columWidthsType="percentage"
              defaultColumnWidths={[
                { columnName: "name", width: 20 },
                { columnName: "createdon", width: 15 },
                { columnName: "tags", width: 30 },
                { columnName: "status", width: 15 },
                { columnName: "category", width: 15 },
                { columnName: "shortKey", width: 15 },
                { columnName: "applicationType", width: 15 },
                { columnName: "actions", width: 15 },
              ]}
              hidden={[...hidden]}
              sorting={filterProps.sorting}
              onSortingChange={this.handleSortByChange}
              dateColumns={["createdon"]}
              formatters={[
                { formatterFn: this.actionProvider, columns: ["actions"] },
                { formatterFn: this.tagProvider, columns: ["tags"] },
                { formatterFn: this.statusProvider, columns: ["status"] },
                { formatterFn: this.catergoryProvider, columns: ["category"] },
                { formatterFn: this.appProvider, columns: ["name"] },
              ]}
              pagination={true}
              paginationConfig={{
                currentPage: this.state.page,
                pageSize: this.state.size,
                onCurrentPageChange: this.handlePageChange,
                onPageSizeChange: this.handleLimitChangePageRow,
                pageSizes: [4, 5, 6],
                totalCount: totalCount,
              }}
              onClickRow={this.handleClick}
              setHiddenColmn={(hidden) => {
                this.props.setHiddenColumn(hidden);
              }}
              Read={checkPermission("btn214")}
              disableReordering={false}
              height="60vh"
              tableType="virtual"
            />
            {data.length !== 0 && (
              <div className={classes.footer}>
                <div className={classes.customPagination}>
                  <Pagination
                    count={Math.ceil(totalCount / 10)}
                    page={this.state.page}
                    onChange={this.handlePageChange}
                    shape="rounded"
                    showFirstButton
                    showLastButton
                  />
                </div>
              </div>
            )}
            {/* <Applicationtable
              handleClick={(id) => this.handleClick(id)}
              data={data}
              page={page}
              size={size}
              totalCount={totalCount}
              handlePageChange={this.handlePageChange}
              handleOrderChange={this.handleOrderChange}
              handleSortByChange={this.handleSortByChange}
              handleLimitChangePageRow={this.handleLimitChangePageRow}
              // searchSpace={this.searchSpace}
              searchkey={this.searchkey}
            /> */}
            <HelpdeskFAB category="Applications" />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <DexTable
              // loading={loading}
              data={data || []}
              columnOrder={[
                "name",
                "category",
                "tags",
                "status",
                "createdon",
                "shortKey",
                "applicationType",
                "actions",
              ]}
              columns={[
                { name: "name", title: "Application Name" },
                { name: "category", title: "Category" },
                { name: "createdon", title: "Created On" },
                { name: "tags", title: "Tags" },
                { name: "status", title: "Status" },
                { name: "shortKey", title: "Short Key" },
                { name: "applicationType", title: "Type" },
                { name: "actions", title: "Actions" },
              ]}
              columWidthsType="percentage"
              defaultColumnWidths={[
                { columnName: "name", width: 20 },
                { columnName: "createdon", width: 15 },
                { columnName: "tags", width: 30 },
                { columnName: "status", width: 15 },
                { columnName: "category", width: 15 },
                { columnName: "shortKey", width: 15 },
                { columnName: "applicationType", width: 15 },
                { columnName: "actions", width: 15 },
              ]}
              hidden={[...hidden]}
              sorting={filterProps.sorting}
              onSortingChange={this.handleSortByChange}
              dateColumns={["createdon"]}
              formatters={[
                { formatterFn: this.actionProvider, columns: ["actions"] },
                { formatterFn: this.tagProvider, columns: ["tags"] },
                { formatterFn: this.statusProvider, columns: ["status"] },
                { formatterFn: this.catergoryProvider, columns: ["category"] },
                { formatterFn: this.appProvider, columns: ["name"] },
              ]}
              pagination={true}
              paginationConfig={{
                currentPage: this.state.page,
                pageSize: this.state.size,
                onCurrentPageChange: this.handlePageChange,
                onPageSizeChange: this.handleLimitChangePageRow,
                pageSizes: [4, 5, 6],
                totalCount: totalCount,
              }}
              onClickRow={this.handleClick}
              setHiddenColmn={(hidden) => {
                this.props.setHiddenColumn(hidden);
              }}
              Read={checkPermission("btn214")}
              disableReordering={false}
              height="60vh"
              tableType="virtual"
            />
            {data.length !== 0 && (
              <div className={classes.footer}>
                <div className={classes.customPagination}>
                  <Pagination
                    count={Math.ceil(totalCount / 10)}
                    page={this.state.page}
                    onChange={this.handlePageChange}
                    shape="rounded"
                    showFirstButton
                    showLastButton
                  />
                </div>
              </div>
            )}{" "}
          </TabPanel>
        </div>
        <HelpdeskFAB category="Applications" />
      </div>
    );
  }
}

Applications.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  getData: (page, size, filters, sort, num) =>
    dispatch(getApplications(page, size, filters, sort, num)),
  updateFilter: (filter) => dispatch(updateFilterData(filter)),
  updateSorting: (sortProps) => dispatch(updateSort(sortProps)),
  store_SearchedValue: (value) => dispatch(storeSearchedValue(value)),
  setHiddenColumn: (hidden) =>
    dispatch(updateApplicationHiddenTableCol(hidden)),
  clearAllApplicationDetails: () =>
    dispatch({
      type: "CLEAR_ALL_APPLICATIONS_DETAILS",
    }),
});

const mapStateToProps = (state) => {
  return {
    data: state.AppList.applicationData,
    totalCount: state.AppList.totalCount,
    actions: state.AppList.action,
    loading: state.AppList.loading,
    totalPublished: state.AppList.totalPublished,
    filterProps: state.AppList.filterProps,
    searchedValue: state.AppList.searchedValue,
    userInfo: state.apearenceReducer.userInfo || null,
    hidden: state.AppList.hidden,
  };
};

const ApplicationComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(Applications);

export default withRouter(
  withSnackbar(withStyles(Styles)(ApplicationComponent))
);

// if (columns === "Status" && operator === "Equals To")
//   return this.getApplicationsByFilter({ status: value });
// if (columns === "Created On" && operator === "Equals To")
//   return this.getApplicationsByFilter({ createdOn: value });
// if (columns === "Category" && operator === "Equals To")
// backend catgegory data.. map with value name if matched means fetch _id
// return this.getApplicationsByFilter({ category: value });
// return "";

// if (columns === "Created On" && operator === "Greater than")
//  return props.getApplicationsByFilter({ name: timestamp });
// if (columns === "Created On" && operator === "Lesser than" )
//  return props.getApplicationsByFilter({ name: timestamp });
// if (columns === "Created On" && operator === "Greater than or Equal to" )
//  return props.getApplicationsByFilter({ name: timestamp });
// if (columns === "Created On" && operator === "Less than or Equals To")
//  return props.getApplicationsByFilter({ name: timestamp });
// if (columns === "Created On" && operator === "Equals To")
//  return props.getApplicationsByFilter({ name: timestamp });
// }
// filter={"name":{"$regex":"project"}}
