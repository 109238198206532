import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  TextField,
  Button,
  Link,
  Card,
  CardContent,
  Avatar,
  IconButton,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Modal from "./Modal";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import withSection from "components/Section/Section";
import { RiDeleteBin5Line } from "react-icons/ri";
import Styles from "./Styles";
import { withStyles } from "@material-ui/styles";
import { checkPermission } from "helpers/permissons";
import HelpdeskFAB from "../../views/HelpdeskFAB/HelpdeskFab";
import { confirmDialog } from "components/DeleteConfirmationDialog/Index";
import { dltMsg } from "helpers/helpers";
// const useStyles = makeStyles((theme) => ({
//   chip: {
//     margin: theme.spacing(0.5),
//     borderRadius: "14px",
//   },
// }));

const TeamUsers = (props) => {
  const { details, isEdit, classes, isDisabled } = props;
  const [openmodal, setOpen] = React.useState(false);
  // const  = useStyles();

  //   let gst = details["GST/Tax_registration_number"];
  const handleOpenModal = () => {
    setOpen(true);
  };
  const handleCloseModal = () => {
    props.getUsers({});
    setOpen(false);
  };
  const onsubmit = (users_data, type) => {
    // if (type === undefined) {
    //   props.handleUsersRoles(users_data);
    //   setOpen(false);
    // } else {
    //   let data = users_data.filter((item, id) => {
    //     return item.status === true;
    //   });
    //   props.updateTeamMember(data);
    //   props.handleUsersRoles(data);
    //   setOpen(false);
    // }

    props.handleUsersRoles(users_data, type);
    setOpen(false);
  };

  return (
    <>
      <Grid container spacing={3}>
        {!isDisabled && (
          <>
            <Grid item sm={11}>
              <Typography variant="body2">
                Add Team member <span style={{ color: "red" }}>*</span>
              </Typography>
            </Grid>
            <Grid item sm={1}>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                alignItems="center"
                // onClick={() => createUser()}
                onClick={() => checkPermission("btn39") && handleOpenModal()}
                startIcon={<AddIcon />}
                disabled={!checkPermission("btn39")}
              >
                Add
              </Button>
            </Grid>
          </>
        )}
        <Grid container spacing={1} className="pl-3">
          {/* <Grid container spacing={1}>
            {props.userMap.map((item, id) => (
              // <Grid
              //   item
              //   md={6}
              //   container
              //   direction="row"
              //   justifyContent="space-between"
              //   alignItems="center"
              // >
                <Card>
                  <CardContent>
                    <Avatar />
                    <div>
                      <Typography>{item.name}</Typography>
                      <Typography>{item.email}</Typography>
                    </div>
                    <IconButton
                      onClick={isEdit ? null : () => props.handleDelete(id)}
                    >
                      "del"
                    </IconButton>
                  </CardContent>
                </Card>
              // </Grid>
            ))}
          </Grid> */}

          {props.userMap.map((item, id) => {
            return (
              <>
                {item && (
                  // <Chip
                  //   label={item.name}
                  //   onDelete={isEdit ? null : () => props.handleDelete(id)}
                  //   variant="outlined"
                  //   className={classes.chip}
                  // />
                  <Grid item md={3} className="mt-3">
                    <Card variant="outlined" className={classes.teamCardStyle}>
                      <CardContent
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          alignItems: "center",
                        }}
                      >
                        <Avatar />
                        <div className="ml-1">
                          <Typography style={{ fontWeight: "bolder" }}>
                            {item.name}
                          </Typography>
                          <Typography>{item.email}</Typography>
                        </div>
                        <div className="ml-5">
                          <IconButton
                            disabled={
                              props.isDisabled || !checkPermission("btn338")
                            }
                            onClick={() => {
                              if (props.isEdit) {
                                checkPermission("btn338") &&
                                  confirmDialog(dltMsg(item?.name || ""), () =>
                                    props.handleDelete(id, item)
                                  );
                              } else {
                                checkPermission("btn338") &&
                                  confirmDialog(dltMsg(item?.name || ""), () =>
                                    props.handleDelete(id)
                                  );
                                // checkPermission("btn338") &&
                                //   props.handleDelete(id);
                              }
                            }}
                          >
                            <RiDeleteBin5Line color="primary" />
                          </IconButton>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                )}
              </>
            );
          })}
        </Grid>
        <HelpdeskFAB />
      </Grid>
      <div>
        <Modal
          openmodal={openmodal}
          onsubmit={onsubmit}
          handleCloseModal={handleCloseModal}
          // property={property}
          // classes={classes}
          // Modaldata={Modaldata}
          // Appdata={Appdata}
          {...props}
        />
      </div>
    </>
  );
};
// export default (withStyles(withSection(Styles));
export default withStyles(Styles)(withSection(TeamUsers, "Team Users"));
