import * as actions from "./actionTypes";

const intialState = {
  data: [],
  loading: false,
  totalCount: 0,
  rolesData: [],
  adddata: {},
  editdata: {},
  deldata: {},
  blockdata: {},
  load: false,
  filterProps: {
    filters: {},
    filterArray: [{ key: 1, columns: "", operator: "", value: "" }],
    sort: { createdOn: -1 },
    sorting: [{ columnName: "createdon", direction: "desc" }],
  },
  hidden: ["createdon", "contactNo"],
};

export default (state = intialState, action) => {
  switch (action.type) {
    case actions.FETCHING_USER_MANAGEMENT:
      return { ...state, loading: action.loading };
    case actions.FETCHING_USER_MANAGEMENT_SUCCESS:
      return {
        ...state,
        loading: action.loading,
        data: action.data,
        totalCount: action.totalCount,
      };
    case actions.FETCHING_USER_MANAGEMENT_FAILURE:
      return { ...state, loading: action.loading, error: action.error };

    case actions.FETCHING_USER_MANAGEMENT_ROLES:
      return { ...state, loading: action.loading };
    case actions.FETCHING_USER_MANAGEMENT_ROLES_SUCCESS:
      return {
        ...state,
        loading: action.loading,
        rolesData: [...action.rolesData],
      };
    case actions.FETCHING_USER_MANAGEMENT_ROLES_FAILURE:
      return { ...state, loading: action.loading, error: action.error };
    case actions.ADD_USER_MANAGEMENT:
      return { ...state, loading: action.loading };
    case actions.ADD_USER_MANAGEMENT_SUCCESS:
      return { ...state, loading: action.loading, adddata: action.adddata };
    case actions.ADD_USER_MANAGEMENT_FAILURE:
      return { ...state, loading: action.loading, error: action.error };

    case actions.EDIT_USER_MANAGEMENT:
      return { ...state, loading: action.loading };
    case actions.EDIT_USER_MANAGEMENT_SUCCESS:
      return { ...state, loading: action.loading, editdata: action.editdata };
    case actions.EDIT_USER_MANAGEMENT_FAILURE:
      return { ...state, loading: action.loading, error: action.error };

    case actions.DELETE_USER_MANAGEMENT:
      return { ...state, loading: action.loading };
    case actions.DELETE_USER_MANAGEMENT_SUCCESS:
      return { ...state, loading: action.loading, deldata: action.deldata };
    case actions.DELETE_USER_MANAGEMENT_FAILURE:
      return { ...state, loading: action.loading, error: action.error };

    case actions.BLOCK_USER_MANAGEMENT:
      return { ...state, loading: action.loading };
    case actions.BLOCK_USER_MANAGEMENT_SUCCESS:
      return { ...state, loading: action.loading, blockdata: action.blockdata };
    case actions.BLOCK_USER_MANAGEMENT_FAILURE:
      return { ...state, loading: action.loading, error: action.error };

    case actions.LOAD_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case actions.UPDATE_USERS_FILTER_DATA:
      return {
        ...state,
        filterProps: {
          ...state.filterProps,
          filters: action.payload.filter,
          filterArray: action.payload.filterArray,
        },
      };
    case actions.UPDATE_USERS_SORT:
      return {
        ...state,
        filterProps: {
          ...state.filterProps,
          sorting: action.payload.sorting,
          sort: action.payload.sort,
        },
      };
    case actions.USER_STORE_SEARCHED_VALUE:
      return {
        ...state,
        searchedValue: action.payload,
      };
    case actions.UPDATE_HIDDEN_COL:
      return {
        ...state,
        hidden: action.payload,
      };

    default:
      return state;
  }
};
