import React, { useState } from "react";
import { Handle, Position } from "react-flow-renderer";
import Chip from "@mui/material/Chip";
import { Paper, TextField, List, ListItem } from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { display } from "@mui/system";
import { IconButton } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import ReactTextareaComponent from "../../../../components/ReactTextArea/ReactTextArea";
// import ResizableBox from "../Resizable";
export default function ExpressionNode(props) {
  console.log("props", { ...props });

  const getOptionsFromNode = (node, parentPath) => {
    console.log("expression debugging", 1, node, parentPath);
    const nodeArr = node.children || [];
    let options = [];
    nodeArr.forEach((currentNode) => {
      let title =
        currentNode.title === "arrayElement" ? "#" : currentNode.title;
      if (title !== "#") {
        options.push({ value: `${parentPath}.${title}`, label: title });
      }
      if (currentNode.children && currentNode.children.length !== 0) {
        recursiveHelper(
          `${parentPath}`,
          `${title}`,
          currentNode.children,
          options
        );
      }
    });
    return options;
  };
  // takes childrens and returns titles
  const recursiveHelper = (parentPath, parentTitle, nodes, options) => {
    nodes.forEach((node) => {
      let title = node.title == "arrayElement" ? "#" : node.title;
      if (title !== "#" && node.type !== "array") {
        options.push({
          value: `${parentPath}_${parentTitle}.${title}`,
          label: `${parentTitle}_${title}`,
        });
      }

      if (node.children && node.children.length !== 0) {
        recursiveHelper(
          `${parentPath}`,
          `${parentTitle}_${title}`,
          node.children,
          options
        );
      }
    });
  };

  const onClickAdd = (e) => {
    e.preventDefault();
    props.data.onChangeNodeData(props.id, props.type, {
      handles: (props.data.input?.handles || 1) + 1,
    });
  };
  const onClickRemove = (e) => {
    e.preventDefault();
    if (props.data.input?.handles <= 1) {
      return;
    }
    props.data.onChangeNodeData(props.id, props.type, {
      handles: (props.data.input?.handles || 1) - 1,
    });
  };
  let options = [];
  const connectedNodes = props.data.input?.connectedNode || [];
  connectedNodes.map((r) => {
    let opt = getOptionsFromNode(
      r.node,
      (r.parentPath || "").slice(6).replaceAll("__", ".")
    );
    options = [...options, ...opt];
  });
  const rtl = props.data.type === "cdutoapi";
  console.log("expression debugging", connectedNodes, options);
  return (
    <Paper
      elevation={props.selected ? 12 : 3}
      style={{
        borderRadius: 16,
      }}
    >
      <div>
        <div
          style={{
            background: "#38929B",
            height: "50px",
            width: "100%",
            color: "white",
            fontSize: "20px",
            borderBottomLeftRadius: "0px",
            borderBottomRightRadius: "0px",
            justifyContent: "space-around",
            display: "flex",
            alignItems: "center",
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
          }}
        >
          <p style={{ margin: 0 }}>Expression</p>
          {props.selected && (
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
              onClick={(e) => {
                props.data.onNodeDelete(props.id);
              }}
              style={{
                position: "absolute",
                top: "-29px",
                right: "-31px",
                color: "red",
              }}
            >
              <CancelIcon />
            </IconButton>
          )}
        </div>
        <div style={{ margin: "0 8px 0 8px" }}>
          <h6
            style={{
              fontWeight: "500",
              borderBottom: "1px solid #c5c5c5",
              paddingBottom: "5px",
              margin: 0,
              padding: 8,
              display: "flex",
              justifyContent: rtl ? "right" : "left",
            }}
          >
            Input
          </h6>
        </div>

        <div style={{ marginTop: 10, padding: 8, display: "flex" }}>
          <Handle
            className={`circle-port ${
              rtl ? "circle-port-right" : "circle-port-left"
            }`}
            position={rtl ? Position.Right : Position.Left}
            type={"target"}
            id={"input"}
            key={"input"}
            style={{ top: "70px" }}
          />
          <ReactTextareaComponent
            options={options}
            text="Expression"
            value={props.data.input?.expString}
            onChange={(e) => {
              console.log("onchange working");
              props.data.onChangeNodeData(props.id, props.type, {
                expString: e.target.value,
              });
            }}
          />
        </div>
        <div style={{ marginTop: 10, padding: 8 }}>
          <h6
            style={{
              fontWeight: "500",
              borderBottom: "1px solid #c5c5c5",
              paddingBottom: "5px",
              margin: 0,
              display: "flex",
              justifyContent: rtl ? "left" : "right",
            }}
          >
            Output
          </h6>
          {!rtl ? (
            <>
              <Handle
                className={"circle-port circle-port-right"}
                position={Position.Right}
                type={"source"}
                id={`output}`}
                key={`output`}
                style={{ right: "-14px", top: "auto", bottom: 6 }}
              />
            </>
          ) : (
            <>
              <Handle
                className={"circle-port circle-port-right"}
                position={Position.Left}
                type={"source"}
                id={`output`}
                key={`output`}
                style={{ left: "-14px", top: "auto", bottom: 30 }}
              />
            </>
          )}
        </div>
      </div>
    </Paper>
  );
}
