import ApplicationOnBoard from "../views/Application/ApplicationOnBoard/ApplicationOnBoard";
import ApplicationList from "../views/Application/ApplicationList/Applications";
//import Customerlist from "../views/Customer/Customerlist";

// import CDU from "../views/CDO/CDU";
import CreateTeam from "views/CreateTeams/index";
import ApplicationDetails from "../views/Application/ApplicationDetails/Applicationview";
//import Issuedetail from "../views/Applicationview/components/Detailissue";
//import Viewcustomer from "../views/Customer/Viewcustomer";
//import Issuelist from "../views/Customer/Issuelist";
//import Viewbill from "../views/Customer/Viewbill";
import Mcds from "../views/Mcds/Mcds";
import Valuemap from "../views/Mcds/Valuemap";
import Outgoingcdumap from "../views/OutgoingCdu/Outgoingcdumap";
import Outgoingvaluemap from "../views/OutgoingCdu/Outgoingvaluemap";
import CustomerManagement from "views/CustomerManagement";
import CreateBillingService from "../views/Application/CreateBillingService/index";
import CreateWebhookForm from "../views/Application/CreateWebhookForm/index";
import CreateAuthRole from "../views/Application/CreateAuthRole/index.jsx";
import CreateModules from "../views/Application/CreateModules/index";
import CreateAPI from "../views/Application/CreateApi/index";
import CreateEnvironment from "../views/Application/CreateEnvironment/index";
import DetailModuleForm from "../views/Application/ApplicationDetails/ModuleDetails/index";
import CreateOutgoing from "views/Application/ApplicationDetails/createOutgoing";
import BillingMaster from "views/BillingMaster/BillingMaster";
import EditBillableservice from "views/BillingMaster/EditBillableservice";
import CreateDocument from "views/Documentation/CreateDocument";
import SubscriptionModel from "views/Subscription/SubscriptionModel";
import PricingMaster from "views/PricingMaster/PricingMaster";
import EditPricingMaster from "views/PricingMaster/EditPricingmaster";
import CustomerProfile from "views/CustomerProfile";
import Settings from "views/UserProfile/Settings";
import Invoice from "views/Invoice";
import UserManagement from "views/UserManagement/userManagement";
import AddUserManagement from "views/UserManagement/addUserManagement";
import RoleManagement from "views/RoleManagement/RoleManagementForm";
import AddRoleManagement from "views/RoleManagement/createRoleManagement";
import TeamManagement from "views/Teams/TeamsForm";
import DataStandards from "views/DataStandards/DataStandardslist";
import CreateDataStandards from "views/CreateDataStandards";
import FieldRules from "views/CreateDataStandards/components/FieldRules";
import CreateSubscriptionPlan from "views/Subscription/CreateSubscription";
import CreateValueMap from "views/CreateValueMap/index";
import HelpDesk from "views/HelpDesk/HelpdeskList";
import CreateHelpDesk from "views/CreateHelpDesk";
import Create from "views/UsageDocumentation/Create";
import StatusProfile from "views/StatusProfile";
import EditStatusProfile from "views/StatusProfile/EditStatusProfile";
import AppUsageDescriptions from "views/AppUsageDescriptions";
import CreateDoc from "views/Application/ApplicationDetails/CreateDocuments/CreateDoc";
import OrganizationDetails from "views/Organization/OrganizationDetails/OrganizationDetails";
import EditOrganizationScreen from "views/Organization/editOrganization/EditOrganizationScreen";
import InvoiceList from "views/InvoiceList/Invoicelist";
import AddCreditnote from "views/Invoice/AddCreditnote";
import AddDebitNote from "views/Invoice/AddDebitNote";
import ErrorPageForNoAccess from "views/ErroPage/ErrorPage";
import DataFabric from "views/DataFabric/DataFabric";
import Monitoring from "views/Monitor/monitoring";
import AppDocumentation from "views/UsageDocumentation/AppDocumentation";
const routes = [
  {
    url: "/app/error",
    component: ErrorPageForNoAccess,
    permission: null,
    resource: null,
  },
  {
    name: "Onboard Application",
    title: "Onboard Application",
    url: "/app/onboard-application",
    component: ApplicationOnBoard,
    resource: "Applications",
    permission: ["Create"],
  },

  {
    name: " Applications",
    title: " Applications",
    url: "/app/applications",
    component: ApplicationList,
    resource: "Applications",
    permission: ["ListAll"],
  },
  {
    name: "ApplicationView",
    title: "ApplicationDetail",
    url: "/app/detail",
    component: ApplicationDetails,
    resource: "Applications",
    permission: ["Read", "Edit"],
  },
  {
    name: "Billing Service",
    title: "Billing Service",
    url: "/app/create-billing-form",
    component: CreateBillingService,
    resource: "Applications",
    permission: ["Read", "Edit", "Create"],
  },

  {
    name: "Webhook Form",
    title: "Webhook Form",
    url: "/app/create-webhook-form",
    component: CreateWebhookForm,
    resource: "Applications",
    permission: ["Read", "Edit", "Create"],
  },
  {
    name: "Create Form",
    title: "Create Form",
    url: "/app/create-auth-form",
    component: CreateAuthRole,
    resource: "Applications",
    permission: ["Read", "Edit", "Create"],
  },
  {
    name: "Module Form",
    title: "Module Form",
    url: "/app/create-modules-form",
    component: CreateModules,
    resource: "Applications",
    permission: ["Read", "Edit", "Create"],
  },
  {
    name: "API Form",
    title: "API Form",
    url: "/app/create-api-form",
    component: CreateAPI,
    resource: "Applications",
    permission: ["Read", "Edit", "Create"],
  },
  {
    name: "Environment Form",
    title: "Environment Form",
    url: "/app/create-env-form",
    component: CreateEnvironment,
    resource: "Applications",
    permission: ["Read", "Edit", "Create"],
  },
  {
    name: "Module Detail Form",
    title: "Module Detail Form",
    url: "/app/module-detail-form",
    component: DetailModuleForm,
    resource: "Applications",
    permission: ["Read", "Edit", "Create"],
  },
  {
    name: "Outgoing  Form",
    title: "Outgoing Form",
    url: "/app/outgoing-api-form",
    component: CreateOutgoing,
    resource: "Applications",
    permission: ["Read", "Edit", "Create"],
  },
  {
    name: "Create Document",
    title: "Create Document",
    url: "/app/create-doc",
    component: CreateDoc,
    resource: "Applications",
    permission: ["Read", "Edit", "Create"],
  },
  // {
  //   name: "Map CDU",
  //   title: "Map CDU",
  //   url: "/app/map-cdu",
  //   component: Mcds,
  // },
  // {
  //   name: "Valuemap",
  //   title: "Valuemap",
  //   url: "/app/valuemap",
  //   component: Valuemap,
  // },
  // {
  //   name: "Outgoingcdumap",
  //   title: "Outgoingcdumap",
  //   url: "/app/outgoingcdu",
  //   component: Outgoingcdumap,
  // },
  // {
  //   name: "Outgoingvaluemap",
  //   title: "Outgoingvaluemap",
  //   url: "/app/outgoingvaluemap",
  //   component: Outgoingvaluemap,
  // },
  {
    name: "Billing",
    title: "ApplicationDetail",
    url: "/app/billing",
    component: BillingMaster,
    resource: "BillableServices",
    permission: ["Read", "ListAll"],
  },
  {
    name: "Edit Billable",
    title: "Edit Billable",
    url: "/app/edit_billable",
    component: EditBillableservice,
    resource: "BillableServices",
    permission: ["Read", "Create", "Edit"],
  },
  {
    name: "Customer Management",
    title: "Customer Management",
    url: "/app/customer-management",
    component: CustomerManagement,
    resource: "Accounts",
    permission: ["Read", "ListAll"],
  },
  {
    name: "Create Document",
    title: "Create Document",
    url: "/app/document/create_document/:id",
    component: CreateDocument,
    resource: "Applications",
    permission: ["Read", "Edit", "Create"],
  },
  {
    name: "Create Usage Document",
    title: "Create Usage Document",
    url: "/app/usagedocument/create_usage_document/:id",
    component: Create,
    resource: "Applications",
    permission: ["Read", "Edit", "Create"],
  },
  {
    name: "Subscription",
    title: "Subscription",
    url: "/app/subscription",
    component: SubscriptionModel,
    resource: "SubscriptionPlans",
    permission: ["Read", "ListAll"],
  },
  {
    name: "Create Subscription Plan",
    title: "Create Subscription Plan",
    url: "/app/create-subscription-plan",
    component: CreateSubscriptionPlan,
    resource: "SubscriptionPlans",
    permission: ["Read", "Create", "Edit"],
  },
  {
    name: "Pricing Master",
    title: "Pricing Master",
    url: "/app/pricingmaster",
    component: PricingMaster,
    resource: "PricingDefinition",
    permission: ["Read", "ListAll"],
  },
  {
    name: "Edit Pricing Master",
    title: "Edit Pricing Master",
    url: "/app/editpricingmaster",
    component: EditPricingMaster,
    resource: "PricingDefinition",
    permission: ["Read", "Edit", "Create"],
  },
  {
    name: "Customer Details",
    title: "Customer Details",
    url: "/app/customer-details",
    component: CustomerProfile,
    resource: "Accounts",
    permission: ["Read", "Edit"],
  },
  {
    name: "Settings",
    title: "User Settings",
    url: "/app/setting",
    component: Settings,
    resource: null,
    permission: null,
  },
  {
    name: "User Management",
    title: "User Management",
    url: "/app/user-management",
    component: UserManagement,
    resource: "AdminUsers",
    permission: ["Read", "ListAll"],
  },
  {
    name: "Add User Management",
    title: "Add User Management",
    url: "/app/create-usermanagement",
    component: AddUserManagement,
    resource: "AdminUsers",
    permission: ["Read", "Edit", "Create"],
  },
  {
    name: "Role Management",
    title: "Role Management",
    url: "/app/role-management",
    component: RoleManagement,
    resource: "Roles",
    permission: ["Read", "ListAll"],
  },
  {
    name: "Add Role Management",
    title: "Add Role Management",
    url: "/app/create-rolemanagement",
    component: AddRoleManagement,
    resource: "Roles",
    permission: ["Read", "Edit", "Create"],
  },
  {
    name: "Team Management",
    title: "Team Management",
    url: "/app/team-management",
    component: TeamManagement,
    resource: "Teams",
    permission: ["Read", "ListAll"],
  },
  {
    name: "Create Team",
    title: "Create Team",
    url: "/app/create-team",
    component: CreateTeam,
    resource: "Teams",
    permission: ["Read", "Edit", "Create"],
  },
  {
    name: "Active Metadata",
    title: "Active Metadata",
    url: "/app/data_standards",
    component: DataStandards,
    resource: "ActiveMetadata",
    permission: ["Read", "ListAll"],
  },
  {
    name: "Create DataStandards",
    title: "Create DataStandards",
    url: "/app/create-datastandards",
    component: CreateDataStandards,
    resource: "ActiveMetadata",
    permission: ["Read", "Edit", "Create"],
  },
  {
    name: "Create ValueMap",
    title: "Create ValueMap",
    url: "/app/create-valuemap",
    component: CreateValueMap,
    resource: "ActiveMetadata",
    permission: ["Read", "Edit", "Create"],
  },
  {
    name: "Help Desk",
    title: "Help Desk",
    url: "/app/helpdesk",
    component: HelpDesk,
    resource: "Helpdesk",
    permission: ["Read", "ListAll"],
  },
  {
    name: "Create Help Desk",
    title: "Create Help Desk",
    url: "/app/create-helpdesk",
    component: CreateHelpDesk,
    resource: "Helpdesk",
    permission: ["Create", "Read", "Edit"],
  },
  {
    name: "Status Profile",
    title: "Status Profile",
    url: "/app/status-profile",
    component: StatusProfile,
    resource: "Status",
    permission: ["Read", "ListAll"],
  },
  {
    name: "Edit Status Profile",
    title: "Edit Status Profile",
    url: "/app/edit-status-profile",
    component: EditStatusProfile,
    resource: "Status",
    permission: ["Read", "Create", "Edit"],
  },
  {
    name: "App Usage Description",
    title: "App Usage Description",
    url: "/app/app-usage-description",
    component: AppUsageDescriptions,
  },

  {
    name: "Organization Details",
    title: "Organization Details",
    url: "/app/organization-details",
    component: OrganizationDetails,
    resource: null,
    permission: null,
  },
  {
    name: "Edit Organization Details",
    title: "Edit Organization Details",
    url: "/app/edit-organization-details",
    component: EditOrganizationScreen,
    resource: null,
    permission: null,
  },
  {
    name: "Invoice",
    title: "Invoice",
    url: "/app/view-invoice",
    component: Invoice,
    resource: "Invoices",
    permission: ["Read", "Edit"],
  },
  {
    name: "Invoice List",
    title: "Invoice List",
    url: "/app/invoice",
    component: InvoiceList,
    resource: "Invoices",
    permission: ["Read", "ListAll"],
  },
  {
    name: "Create Credit Note",
    title: "Create Credit Note",
    url: "/app/create-credit",
    component: AddCreditnote,
    resource: "Invoices",
    permission: ["CreateNote"],
  },
  {
    name: "Create Debit Note",
    title: "Create Debit Note",
    url: "/app/create-debit",
    resource: "Invoices",
    permission: ["CreateNote"],
    component: AddDebitNote,
  },
  {
    name: "Data Fabric",
    title: "Data Fabric",
    url: "/app/data-fabric",
    resource: "Applications",
    permission: ["Read", "Edit"],
    component: DataFabric,
  },
  {
    name: "Monitoring",
    title: "Monitoring",
    url: "/app/Monitoring",
    component: Monitoring,
    resource: "AdminUsers",
    permission: ["Read"],
  },
  {
    name: "Application Documentation",
    title: "Application Documentation",
    url: "/app/documentation/:id",
    component: AppDocumentation,
    resource: "AdminUsers",
    permission: ["Read"],
  },
];

export default routes;
