import getURL from "../../../api/config";
import API_helpers from "../../../api/index";
import axios from "axios";
import * as types from "./actionType";
import APIs from "api/CustomerManagementAPI/index";
import moment from "moment";
export const fetchCustomerApp = (id) => {
  return async (dispatch) => {
    dispatch({
      type: types.FETCHING_CUSTOMER_SUB_APP,
    });
    try {
      let res = await API_helpers.GET(getURL("FETCH_CUSTOMER_SUB_APP")(id));
      dispatch({
        type: types.FETCHED_CUSTOMER_SUB_APP,
        payload: res.data.data || [],
        // msg: res.data._msg,
      });
    } catch (err) {
      const { _msg } = err?.response?.data || { _msg: "Network issue!" };
      dispatch({
        type: types.FETCH_CUSTOMER_SUB_APP_FAILED,
        msg: _msg,
      });
    }
  };
};

export const fetchCustomerInvoice = (id) => async (dispatch) => {
  dispatch({
    type: types.FETCHING_INVOICE,
  });
  try {
    const res = await APIs.getCustomerWiseInvoices(id);
    dispatch({
      type: types.FETCHED_INVOICE,
      payload: res.data.data || [],
      // msg: res.data._msg,
    });
  } catch (err) {
    const { _msg } = err?.response?.data || { _msg: "Network issue!" };
    dispatch({
      type: types.FETCH_INVOICE_FAILED,
      msg: _msg,
    });
  }
};

export const fetchCustomerIsssues = (id) => async (dispatch) => {
  dispatch({
    type: types.FETCHING_CUSTOMER_ISSUE,
  });
  try {
    let res = await APIs.fetchCustomerIssues(id);
    // console.log(res, "customer issues err");
    dispatch({
      type: types.FETCHED_CUSTOMER_ISSUE,
      payload: res.data.data || [],
      // msg: res.data._msg,
    });
  } catch (err) {
    const { _msg } = err?.response?.data || { _msg: "Network issue!" };
    dispatch({
      type: types.FETCH_CUSTOMER_ISSUE_FAILED,
      msg: _msg,
    });
    // console.log(err, "customer issues err");
  }
};

export const updateCustomerProfile = (payload, id) => async (dispatch) => {
  dispatch({
    type: types.UPDATING_CUSTOMER_PROFILE,
  });
  try {
    const res = await APIs.upadateCustomerProfile(payload, id);
    dispatch({
      type: types.UPDATED_CUSTOMER_PROFILE,
    });
    const { data } = res;
    return data;
  } catch (err) {
    dispatch({
      type: types.UPDATE_CUSTOMER_PROFILE_FAILED,
    });
    const { data } = err?.response || {};
    return data || { _msg: "Network issue", _status: 0 };
  }
};

export const fetchCustomerSettingData = (id) => async () => {
  try {
    const res = await APIs.fetchCustomerSetting(id);
    return res.data.data || {};
  } catch (err) {
    return {};
  }
};

export const fetchApplicationUsageDetails =
  (custId, appId) => async (dispatch) => {
    dispatch({
      type: types.FETCHING_CUST_APP_BILLABLE_SERVICES,
    });
    try {
      const res = await APIs.fetchAppUsage(custId, appId);
      let billableId = {};
      let data = res.data.data || [];
      data.forEach(function (f) {
        if (billableId[f.BilliablServiceID] === undefined) {
          billableId[f.BilliablServiceID] = [f];
        } else {
          var arr = billableId[f.BilliablServiceID];
          billableId[f.BilliablServiceID] = [...arr, f];
        }
      });
      console.log(billableId);
      dispatch({
        type: types.FETCHED_CUST_APP_BILLABLE_SERVICES,
        payload: billableId,
      });
      return true;
    } catch (err) {
      dispatch({
        type: types.FETCH_CUST_APP_BILLABLE_SERVICES_FAILED,
      });
      return false;
    }
  };

export const updateEndEdit = (val) => async (dispatch) => {
  const { id, endDate ,customerUrl} = val;
  let end = moment(endDate).format();
  console.log(end);
  let payload = {
    endDate: end,
    customerUrl:customerUrl,
  };
  dispatch({
    type: types.UPDATING_ENDDATE_SUBSCRIPTION,
  });
  try {
    const res = await APIs.updateEndDateSubscription(payload, id);
    console.log("ressssssssssssssss", res);
    dispatch({
      type: types.UPDATED_ENDDATE_SUBSCRIPTION,
    });

    const { _msg } = res.data;

    return { _msg, success: true };
  } catch (err) {
    const { _msg } = err.response.data || {
      _msg: "Network issue",
    };

    dispatch({
      type: types.UPDATE_ENDDATE_SUBSCRIPTION,
    });

    return {
      _msg,
      success: false,
    };
  }
};

export const update_subscribedApp_status = (payload) => async (dispatch) => {
  try {
    const res = await APIs.update_subapp_status(payload);
    const { _msg } = res.data;
    console.log("update status", 1, res.data);
    return {
      _msg,
      success: true,
    };
  } catch (err) {
    console.log("update status", 2, err);
    const { _msg } = err.response.data || {
      _msg: "Network issue!",
    };
    return {
      _msg,
      success: false,
    };
  }
};

export const disableEdit = (bool) => ({
  type: types.ENABLE_EDIT,
  payload: bool,
});

export const customerUpadateHiddenCol = (hidden, key) => ({
  type: types.UPDATE_CUSTOMER_PROFILE_HIDDEN_COL,
  payload: hidden,
  key: key,
});
