import React, { Component, useEffect, useState } from "react";
import Breadcrumb from "components/Breadcrumbs/Breadcrumb";
import SearchIcon from "@material-ui/icons/Search";
import FilterComponent from "components/FilterComponent/FilterComponent";
import {
  Typography,
  Grid,
  Button,
  InputAdornment,
  Tooltip,
  IconButton,
  Badge,
  Link,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { CustomTextField } from "components/Customized";
import DexTable from "components/DexTable/DexTable";
import AddIcon from "@material-ui/icons/Add";
import actions from "./redux/action";
import StatusAction from "../StatusProfile/redux/action";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";
import { RiEditLine, RiDeleteBin5Line } from "react-icons/ri";
import { AiOutlineLock } from "react-icons/ai";
import { withSnackbar } from "notistack";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import Styles from "./styles";
import helpDeskAPIS from "api/HelpDeskAPIs/index";
import pricingMasterAPIS from "api/PricingMasterAPIs/index";
import { deleteHelpDeskData } from "./redux/action";
import dataOptions from "./filterOptins.json";
import HelpersFunc from "helpers/helpers";
import appApi from "api/ApplicationAPIs/index";
import { checkPermission } from "helpers/permissons";
import CreateHelpDesk from "views/CreateHelpDesk/index.jsx";

const HelperFilterFunComp = (props) => {
  const { status_data, getDataByFilter, filterHistory, filterFunc } = props;
  const [DataOptions, setDataOptions] = useState([...dataOptions]);

  const updateDataOptions = () => {
    let status = [];
    status_data.map((row, id) => {
      if (row.name === "Helpdesk") {
        status = row?.status || [];
      }
      return 0;
    });

    let FilterOptions = DataOptions.map((item, id) => {
      if (item.fieldName === "status") {
        return {
          ...item,
          fieldValues: status.map((st, id) => {
            return {
              key: id + 1,
              value: st.status,
            };
          }),
        };
      } else {
        return {
          ...item,
        };
      }
    });
    // console.log("gsshfsfshfshg", FilterOptions);
    setDataOptions([...FilterOptions]);
  };
  useEffect(() => {
    updateDataOptions();
  }, [status_data]);
  return (
    <div>
      <FilterComponent
        getDataByFilter={getDataByFilter}
        dataOptions={DataOptions}
        filterFunc={filterFunc}
        filterHistory={filterHistory}
      />
    </div>
  );
};

class HelpdeskList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status_count: 0,
      page: 1,
      size: 10,
      filters: {},
      sort: { createdOn: -1 },
      sorting: [{ columnName: "createdon", direction: "desc" }],
      edit: false,
      searchkey: "",
      value: 0,
      openDropdown: false,
      setDropdown: false,
      setOpen: false,
      totalCount: 1,
      allPublishedApp: [],
      data_options: [...dataOptions],
    };
  }

  async componentDidMount() {
    let { page, size } = this.state;
    let { filterProps } = this.props;

    this.getCategories();
    this.props.getData(
      page,
      size,
      JSON.stringify(filterProps.filters),
      JSON.stringify(filterProps.sort)
    );
    this.props.getAllStatus();
  }

  handleSortByChange = (sorting) => {
    let { filterProps, updateSorting, getData } = this.props;
    let filters = { ...filterProps.filters };
    let sort = {};
    sorting.forEach((f) => {
      sort[f.columnName] = f.direction == "asc" ? 1 : -1;
    });
    updateSorting({ sorting, sort });
    getData(
      this.state.page,
      this.state.size,
      JSON.stringify(filters),
      JSON.stringify(sort)
    );
  };
  searchAndPaginationHelperFunc = (page, val) => {
    let { filterProps } = this.props;
    let filtersCopy = { ...filterProps.filters };
    let filters = {
      ...filtersCopy,
      category: { $regex: "^" + val, $options: "i" },
    };
    // this.getFilteredData(filters, filterProps.filterArray, true);
    this.props.getData(
      page,
      this.state.size,
      JSON.stringify(filters),
      JSON.stringify(filterProps.sort)
    );
  };

  onChangeSearchAppName = (event) => {
    let page = 1;
    this.setState({
      searchkey: event.target.value,
      page: page,
    });
    this.searchAndPaginationHelperFunc(page, event.target.value);
  };

  viewTeamdata = (row) => {
    // let data = { ...row };
    this.props.history.push("/app/create-helpdesk", {
      //   team_data: data,
      edit: true,
      disabled: true,
    });
  };

  openDropDown = () => {
    this.setState({ openDropDown: true });
  };
  dropUp = () => {
    this.setState({ setDropdown: true });
  };

  handlePageChange = async (e, newPage) => {
    this.setState({
      page: newPage,
    });
    let { filterProps } = this.props;
    if (this.state.searchkey.trim().length !== 0) {
      //when search is enabled while user click pagination we need this function!
      this.searchAndPaginationHelperFunc(newPage, this.state.searchkey);
    } else {
      await this.props.getData(
        newPage,
        this.state.size,
        JSON.stringify(filterProps.filters),
        JSON.stringify(filterProps.sort)
      );
    }
  };

  actionProvider = ({ row }) => {
    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <Tooltip title="Edit" aria-label="edit">
            <IconButton
              color="inherit"
              size="small"
              disabled={!checkPermission("btn28")}
              onClick={(e) =>
                checkPermission("btn28") && this.editHelpDesk(row, e)
              }
            >
              <RiEditLine fontSize="20px" />
            </IconButton>
          </Tooltip>
        </Grid>
        {/* <Grid item>
          <Tooltip title="Delete" aria-label="delete">
            <IconButton
              color="inherit"
              size="small"
              onClick={(e) => this.removeHelpDesk(row, e)}
            >
              <RiDeleteBin5Line fontSize="20px" />
            </IconButton>
          </Tooltip>
        </Grid> */}
      </Grid>
    );
  };

  createHelpDesk = () => {
    let data = {
      appId: "",
      module: "",
    };
    this.props.history.push("/app/create-helpdesk", {
      Helpdesk_Data: data,
      edit: false,
    });
  };

  editHelpDesk = (row, e) => {
    e && e.stopPropagation();
    let data = { ...row };

    this.props.history.push("/app/create-helpdesk", {
      Helpdesk_Data: data,
      edit: true,
      disabled: e ? false : true,
      helpDeskPage: true,
    });
  };

  // removeHelpDesk = async (row, e) => {
  //   e && e.stopPropagation();
  //   let { page, size, filters, sort } = this.state;
  //   let res = await this.props.deleteHelpDeskData(row._id);
  //   if (res.data._status === 20) {
  //     this.props.enqueueSnackbar(
  //       res.data._msg || "Billable Service deleted successfully",
  //       {
  //         variant: "success",
  //         autoHideDuration: 2000,
  //       }
  //     );
  //     await this.props.getData(page, size, filters, sort);
  //   } else {
  //     this.props.enqueueSnackbar(res.response.data._msg, {
  //       variant: "error",
  //       autoHideDuration: 2000,
  //     });
  //   }
  // };

  removeHelpDesk = async (row, e) => {
    e && e.stopPropagation();
    // let { page, size, filters, sort } = this.state;
    // let res = await this.props.deleteHelpDeskData(row._id);
    // console.log(res.response, "eee");
    this.props.enqueueSnackbar(
      "you do not have permission to access Delete Helpdesk, Please contact your Admin",
      {
        variant: "error",
        autoHideDuration: 2000,
      }
    );
    // if (res.data._status === 200) {
    //   this.props.enqueueSnackbar(
    //     res.data._msg || "Isssue deleted successfully",
    //     {
    //       variant: "success",
    //       autoHideDuration: 2000,
    //     }
    //   );
    //   await this.props.getData(page, size, filters, sort);
    // } else {
    //   this.props.enqueueSnackbar(res.response.data._msg, {
    //     variant: "error",
    //     autoHideDuration: 2000,
    //   });
    // }
  };

  viewHelpdeskdata = (row) => {
    this.editHelpDesk(row);
  };

  statusProvider = ({ row }) => {
    const { status_data } = this.props;
    let values = {
      status: "",
      color: "",
    };
    console.log(status_data, "status_data");
    let profileStatus =
      status_data.find((f) => f.name == "Helpdesk")?.status || [];

    let status = profileStatus.filter((f) => f.status === row.status
    );
    let defaultStatus = profileStatus.filter((f) => f.default);

    console.log("fhfghhj36786373636", profileStatus, row);

    if (status.length === 0) {
      values = defaultStatus[0] || {
        name: "",
        status: "",
      };
    } else {
      values = status[0] || {
        status: "",
        color: "",
      };
    }

    const Classes = {
      customBadge: {
        backgroundColor: values.color,
        width: "8px",
        height: "8px",
        borderRadius: "50%",
        marginRight: 4,
      },
    };

    return (
      <>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              ...Classes.customBadge,
            }}
          ></div>
          <Typography variant="body1"> {values.status} </Typography>
        </div>
      </>
    );
  };

  getFilteredData = async (filters, arr, search) => {
    let page = 1;
    this.setState({
      page: 1,
    });
    let { filterProps } = this.props;
    this.props.updateFilter({ filter: filters, filterArray: arr });
    this.props.getData(
      page,
      this.state.size,
      JSON.stringify(filters),
      JSON.stringify(filterProps.sort)
    );
    if (!search) {
      this.setState({
        searchkey: "",
      });
    }
  };

  filterFunc = (arr) => {
    let filters = {};
    if (arr.length === 1) {
      let { operator, value, type } = arr[0];
      let filter = HelpersFunc.findAndExecuteAjax(type)(operator, value);
      this.getFilteredData({ ...filter }, arr);
    } else {
      arr.map((item, id) => {
        const { operator, value, type } = item;
        let filter = HelpersFunc.findAndExecuteAjax(type)(operator, value);
        filters = { ...filters, ...filter };
        return 0;
      });
      this.getFilteredData({ ...filters }, arr);
    }
  };

  getCategories = async () => {
    // try {
    //   let res = await appApi.get_Categories();
    //   if (res.data && res.data.data) {
    //     this.setState({ category: [...res.data.data] });
    //     let filter_Data = dataOptions.map((item, id) => {
    //       if (item.value === "Category") {
    //         return {
    //           ...item,
    //           fieldValues: res.data.data.map((e, id) => {
    //             return {
    //               key: id + 1,
    //               value: e.name,
    //             };
    //           }),
    //         };
    //       } else {
    //         return { ...item };
    //       }
    //     });
    //     this.setState({ data_options: [...filter_Data] });
    //     console.log(res.data.data, filter_Data, 56543465434);
    //   }
    // } catch (err) {}
  };

  productName = ({ row }) => {
    return (
      <>
        <Typography>{row.appId}</Typography>
      </>
    );
  };

  moduleName = ({ row }) => {
    return (
      <>
        <Typography>{row?.module || ""}</Typography>
      </>
    );
  };

  render() {
    let { classes, data, totalCount, loading, filterProps, hidden } =
      this.props;
    console.log(data, "==========datattt");
    return (
      <>
        <Grid container>
          <Grid item sm={12} md={12} xs={12} lg={12}>
            <Breadcrumb
              {...this.props}
              links={[
                { name: "Help Desk" },
                {
                  name: "Help Desk List",
                },
              ]}
            />
          </Grid>
          <Grid item sm={7} md={7} lg={7} xs={12}>
            <Typography variant="h6" style={{ fontWeight: "bold" }}>
              Help Desk {`( ${this.props.totalCount} )`}
            </Typography>
          </Grid>
          <Grid item sm={5} md={5} lg={5} xs={12}>
            <Grid container spacing={2}>
              <Grid item sm={5} md={5} lg={5} xs={12}>
                <CustomTextField
                  className=" mt-0 "
                  autoComplete="off"
                  fullWidth
                  id="filled-search"
                  type="text"
                  variant="outlined"
                  size="small"
                  placeholder="Search"
                  value={this.state.searchkey}
                  margin="normal"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {" "}
                        <SearchIcon />{" "}
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    this.onChangeSearchAppName(e);
                  }}
                />
              </Grid>
              <Grid item sm={3} md={3} lg={3} xs={12}>
                <HelperFilterFunComp
                  getDataByFilter={this.getFilteredData}
                  filterFunc={this.filterFunc}
                  filterHistory={filterProps.filterArray}
                  {...this.props}
                />
              </Grid>
              <Grid item sm={4} md={4} lg={4} xs={12}>
                <Button
                  style={{
                    color: "white",
                    width: "100%",
                    boxShadow: "none",
                    padding: "6px 11px 6px 11px",
                  }}
                  variant="contained"
                  color="primary"
                  size="small"
                  alignItems="center"
                  disabled={!checkPermission("btn27")}
                  onClick={() =>
                    checkPermission("btn27") && this.createHelpDesk()
                  }
                  startIcon={<AddIcon />}
                >
                  Create
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12} md={12} xs={12} lg={12} className="mt-2">
            <DexTable
              data={[...data]}
              // data={[...this.data]}
              columnOrder={[
                "_id",
                "category",
                "createdon",
                "type",
                // "application",
                // "module",
                "status",
                "actions",
              ]}
              columns={[
                { name: "_id", title: "Ticket ID" },
                { name: "category", title: "Category" },
                { name: "createdon", title: "Created On" },
                { name: "type", title: "Type" },
                // { name: "application", title: "Application" },
                // { name: "module", title: "Module" },
                { name: "status", title: "Status" },
                { name: "actions", title: "Actions" },
              ]}
              columWidthsType="percentage"
              defaultColumnWidths={[
                // { columnName: "_id", width: 20 },
                // { columnName: "category", width: 20 },
                // { columnName: "createdon", width: 13 },
                // { columnName: "type", width: 14 },
                // { columnName: "status", width: 14 },
                // { columnName: "actions", width: 18 },
                { columnName: "_id", width: 25 },
                { columnName: "category", width: 20 },
                { columnName: "createdon", width: 18 },
                { columnName: "type", width: 15 },
                // { columnName: "application", width: 18 },
                // { columnName: "module", width: 18 },
                { columnName: "status", width: 18 },
                { columnName: "actions", width: 18 },
              ]}
              formatters={[
                { formatterFn: this.actionProvider, columns: ["actions"] },
                { formatterFn: this.statusProvider, columns: ["status"] },
                {
                  formatterFn: this.productName,
                  columns: ["application"],
                },
                {
                  formatterFn: this.moduleName,
                  columns: ["module"],
                },
              ]}
              sorting={filterProps.sorting}
              onSortingChange={this.handleSortByChange}
              dateColumns={["createdon"]}
              hidden={[...hidden]}
              disableReordering={false}
              height="60vh"
              loading={loading}
              tableType="virtual"
              onClickRow={(row) => this.viewHelpdeskdata(row)}
              setHiddenColmn={(hidden) => {
                this.props.setHiddenColumn(hidden);
              }}
              Read={checkPermission("btn29")}
            />
          </Grid>
          {data.length !== 0 && (
            <div className={classes.footer}>
              <div className={classes.customPagination}>
                <Pagination
                  count={Math.ceil(totalCount / 10)}
                  page={this.state.page}
                  onChange={this.handlePageChange}
                  shape="rounded"
                  showFirstButton
                  showLastButton
                />
              </div>
            </div>
          )}
        </Grid>
      </>
    );
  }
}

HelpdeskList.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    data: state.helpdesk.data,
    loading: state.helpdesk.loading,
    totalCount: state.helpdesk.totalCount,
    filterProps: state.helpdesk.filterProps,
    status_data: state.statusProfile.data,
    hidden: state.helpdesk.hidden,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getData: (pageno, size, filters, sort) =>
      dispatch(actions.actionfetchAllHelpDeskData(pageno, size, filters, sort)),
    deleteHelpDeskData: (id) => dispatch(deleteHelpDeskData(id)),
    updateFilter: (filter) => dispatch(actions.updateFilterData(filter)),
    updateSorting: (sortProps) => dispatch(actions.updateSort(sortProps)),
    getAllStatus: () =>
      dispatch(StatusAction.actionfetchAllStatusProfileData()),
    setHiddenColumn: (hidden) =>
      dispatch(actions.udateHelpDeskHiddenCol(hidden)),
  };
};
const HelpDesk = connect(mapStateToProps, mapDispatchToProps)(HelpdeskList);

export default withRouter(withStyles(Styles)(withSnackbar(HelpDesk)));
