import React, { useEffect, useState } from "react";
import Breadcrumb from "components/Breadcrumbs/Breadcrumb";
import TreeListComponent from "components/TreeList";
import { useHistory, useLocation } from "react-router";
import { useSnackbar } from "notistack";
import APIs from "api/ApplicationAPIs/index";
import { Grid, Typography, Paper } from "@material-ui/core/";

import SunEditor from "suneditor-react";
import {
  align,
  font,
  fontColor,
  fontSize,
  formatBlock,
  hiliteColor,
  horizontalRule,
  lineHeight,
  list,
  paragraphStyle,
  table,
  template,
  textStyle,
  image,
  link,
} from "suneditor/src/plugins";
import helpFunc from "helpers/helpers";
import { ArrowForward } from "@material-ui/icons";

const AppDocumentation = (props) => {
  const history = useHistory();
  const [allContents, setContents] = useState([]);
  const [state, setState] = useState({
    location: {},
  });
  const [appDocSec, setAppDocSec] = useState([]);
  const [htmlContent, setHtmlContent] = useState("");
  const location = useLocation();
  const type = location?.state?.type || "";
  let docId = location?.state?.documentationData?.usageDoc?._id || undefined;
  let docName = location?.state?.documentationData?.usageDoc?.name || "";
  let appName = location?.state?.documentationData?.name || "";
  const { enqueueSnackbar } = useSnackbar();

  const displayMsg = (msg, type) => {
    enqueueSnackbar(msg, {
      variant: type ? "success" : "error",
    });
  };

  const sectionNameEmpty = (name) => {
    if (name.trim().length === 0) {
      displayMsg("Section name can not be empty!", false);
      getSections(props.match.params.id);
      return true;
    } else {
      return false;
    }
  };

  const isSectionNameDuplicate = (val, type) => {
    let filterDoc = appDocSec.filter(
      (f) =>
        f.name.toUpperCase() === val.name.toUpperCase() &&
        f.Head_ID === val.Head_ID
    );

    if (filterDoc.length > 0) {
      displayMsg("Document with this name already exists!", false);
      getSections(props.match.params.id);
      return true;
    } else {
      return false;
    }
  };

  const handleDragUpdate = async (values, allSection, payload) => {
    console.log("handle drag update", values, allSection, payload);

    payload["type"] = type;
    if (type === "usage") {
      payload["documentId"] = docId;
    }

    try {
      let res = await APIs.updateSection(
        props.match.params.id,
        values._id,
        payload
      );
      enqueueSnackbar(res.data._msg, {
        variant: "success",
      });
      sequanceOrder(allSection);
    } catch (err) {
      console.log("errr", err);
      enqueueSnackbar(err?.response?.data?._msg || "Connection error!", {
        variant: "error",
      });
    }
  };

  //creating array for suneditor  to enable and disable on click of row
  const setContentsFun = (data) => {
    let cnts = data.map((item, index) => {
      return {
        id: item._id,
        content: item.content,
        show: index == 0 ? true : false,
        name: item.name,
        disable: true,
        parentName:
          item.Head_ID !== -1
            ? data.find((f) => f.ID === item.Head_ID)?.name || null
            : null,
      };
    });
    cnts.length > 0
      ? setContents([...cnts])
      : setContents([
          {
            show: true,
          },
        ]);
  };

  //sequence order to fetch all  doc
  const sequanceOrder = async (values) => {
    try {
      await APIs.fetchSequenceOrder(props.match.params.id, {
        [`${type}DocumentSequanceOrder`]: values,
        type: type,
        docId: docId,
      });
      getSections(props.match.params.id);
    } catch (err) {
      enqueueSnackbar(err?.response?.data?._msg || "Connection error!", {
        variant: "error",
      });
    }
  };

  //generate tree data for TreeList
  const generateTreeData = (arr, idx, result) => {
    let treeData = [...result];
    arr.forEach((item) => {
      const { sections, ...otherData } = item;
      if (sections) {
        return generateTreeData(sections, treeData.length + 1, treeData);
      } else {
        treeData.push({
          ...otherData,
          status: false,
          Head_ID: idx,
          ID: treeData.length + 1,
        });
      }
    });
    return treeData;
  };

  //get all doc
  const getSections = async (app_id, id) => {
    try {
      let res = await APIs.fetchSections(app_id, type, docId);
      let app_sections = res.data.data || [];
      let treeData = [];
      app_sections.forEach((item, index) => {
        const { sections, ...otherData } = item;
        if (sections && sections.length !== 0) {
          let obj = {
            ...otherData,
            Head_ID: -1,
            ID: treeData.length + 1,
            status: false,
          };
          treeData.push(obj);
          let result = generateTreeData(sections, obj.ID, treeData);
          treeData = [...result];
        } else {
          treeData.push({
            ...otherData,
            Head_ID: -1,
            ID: treeData.length + 1,
            status: false,
          });
        }
      });
      setAppDocSec(treeData);
      setContentsFun(treeData);
    } catch (err) {
      setAppDocSec([]);
      setContents([]);
    }
  };

  //create doc
  const handleCreationSection = async (values) => {
    if (sectionNameEmpty(values.data?.name || "")) {
      return;
    }

    if (isSectionNameDuplicate(values.data)) {
      return;
    }

    let pSection = appDocSec.find((f) => f.ID === values.data.Head_ID);
    const { content } = allContents.find((f) => f.show) || { content: "" };

    try {
      const res = await APIs.addSection(
        props.match.params.id,
        type === "intro"
          ? {
              name: values.data.name,
              content: content === "" ? "<p><br></p>" : content,
              sectionId: pSection?._id || "",
              type: type,
            }
          : {
              name: values.data.name,
              content: content === "" ? "<p><br></p>" : content,
              sectionId: pSection?._id || "",
              type: type,
              documentId: docId,
            }
      );
      displayMsg(res.data._msg, true);
      const section_id = res?.data?.data?.documentId || "";
      let allSections = helpFunc.sectionsSequenceOrder(appDocSec);
      if (pSection) {
        allSections = allSections.map((item) => {
          if (item.id === pSection._id) {
            return {
              ...item,
              childSequenceArray: [
                ...item.childSequenceArray,
                {
                  id: section_id,
                },
              ],
            };
          } else {
            return item;
          }
        });
      } else {
        allSections.push({
          id: section_id,
          childSequenceArray: [],
        });
      }
      sequanceOrder(allSections);
      console.log("2345678sdfghjk", content, pSection, values, allSections);
    } catch (err) {
      displayMsg(err?.response?.data?._msg || "Connection error!", false);
    }
  };

  //update doc
  const handleUpdate = (values) => {
    let payload = allContents.find((f) => f.show);

    if (!values) {
      helperUpdateFun(payload);
      return;
    }

    if (sectionNameEmpty(values.name || "")) {
      return;
    }

    if (isSectionNameDuplicate(values, "edit")) {
      return;
    }
    payload["name"] = values.name;
    helperUpdateFun(payload);
  };

  const helperUpdateFun = async (payload) => {
    try {
      let res = await APIs.updateSection(
        props.match.params.id,
        payload.id,
        type === "usage"
          ? {
              name: payload.name,
              content:
                payload.content === "" ? "<p><br/></p>" : payload.content,
              type: type,
              documentId: docId,
            }
          : {
              name: payload.name,
              content:
                payload.content === "" ? "<p><br/></p>" : payload.content,
              type: type,
            }
      );
      displayMsg(res.data._msg, true);
      getSections(props.match.params.id, payload.id);
    } catch (err) {
      displayMsg(err?.response?.data?._msg || "Connection error!", false);
      throw err;
    }
  };

  //delete doc
  const handleSectionDelete = async (values) => {
    const appId = props.match.params.id;
    try {
      const res = await APIs.deleteSection(appId, values.data._id);
      enqueueSnackbar(res.data._msg, {
        variant: "success",
      });
      sequanceOrder(
        helpFunc.sectionsSequenceOrder(
          appDocSec.filter((f) => f.ID !== values.data.ID)
        )
      );
    } catch (err) {
      enqueueSnackbar(err?.response?.data._msg || "Connection error!", {
        variant: "error",
      });
    }
    console.log(
      "delete sections 789",
      appDocSec,
      values,
      helpFunc.sectionsSequenceOrder(
        appDocSec.filter((f) => f.ID !== values.data.ID)
      )
    );
  };

  //onchange html content
  const updateHtmlContent = (html_, index) => {
    const data = allContents;
    data[index] = {
      ...data[index],
      disable: false,
      content: html_,
    };
    setContents(data);
    console.log("dd1243hdjhfdgfdgfd", html_, data[index]);
  };

  //on click enable suneditor
  const onClickToggleContent = (data, flag) => {
    const contents = allContents;
    let updatedContents = [];
    if (data) {
      updatedContents = contents.map((item) => {
        if (data._id === item.id) {
          return {
            ...item,
            show: true,
            disable: flag,
          };
        } else {
          return {
            ...item,
            show: false,
            disable: true,
          };
        }
      });
    } else {
      updatedContents = contents.map((item) => {
        return {
          ...item,
          show: false,
          disable: true,
        };
      });
      updatedContents.push({
        show: true,
        disable: false,
      });
    }
    setContents(updatedContents);
  };

  useEffect(() => {
    if (location.state) {
      getSections(location.state.documentationData.ID);
      setState({
        ...(location.state.documentationData || {}),
      });
    } else {
      history.push("/login");
    }
  }, []);

  console.log("appDoc124", allContents);

  return (
    <div>
      <Breadcrumb
        {...props}
        links={
          type === "usage"
            ? [
                { name: "Applications", url: "/app/applications" },
                {
                  name: `${appName}`,
                  url: "/app/detail",
                  routeParams: {
                    appId: location?.state?.documentationData.ID,
                  },
                },

                {
                  name: `${docName}`,
                  url: "/app/create-doc",
                  routeParams: {
                    data: location?.state?.documentationData,
                  },
                },
                {
                  name: "Usage Documentation",
                },
              ]
            : [
                { name: "Applications", url: "/app/applications" },
                {
                  name: `${appName}`,
                  url: "/app/detail",
                  routeParams: {
                    appId: location?.state?.documentationData.ID,
                  },
                },

                {
                  name: "Intro Documentation",
                },
              ]
        }
      />
      <Paper>
        <Grid container spacing={1}>
          <Grid item md={3}>
            <TreeListComponent
              treeData={appDocSec}
              getSections={getSections}
              handleSectionDelete={handleSectionDelete}
              handleUpdate={handleUpdate}
              handleCreationSection={handleCreationSection}
              handleDragUpdate={handleDragUpdate}
              onClickToggleContent={onClickToggleContent}
              Text
            />
          </Grid>
          <Grid item md={9}>
            <div>
              {allContents.map(
                (item, key) =>
                  item.show && (
                    <div>
                      {item.parentName ? (
                        <article>
                          <h6
                            style={{
                              paddingTop: 5,
                              verticalAlign: "middle",
                            }}
                          >
                            <b>Section: </b>
                            <span> {item.parentName} </span>
                            <ArrowForward style={{ fontSize: 17 }} />
                            <span> {item.name}</span>
                          </h6>
                        </article>
                      ) : (
                        <article>
                          <h6
                            style={{
                              paddingTop: 5,
                              verticalAlign: "middle",
                            }}
                          >
                            <b>Section:</b> {item.name}
                          </h6>
                        </article>
                      )}

                      <SunEditor
                        key={key}
                        setContents={item.content || ""}
                        // disable={item.disable}
                        onChange={(htmlC) => {
                          updateHtmlContent(htmlC, key);
                        }}
                        setOptions={{
                          minHeight: "63vh",
                          placeholder: "Please type here...",
                          plugins: [
                            align,
                            font,
                            fontColor,
                            fontSize,
                            formatBlock,
                            hiliteColor,
                            horizontalRule,
                            lineHeight,
                            list,
                            paragraphStyle,
                            table,
                            template,
                            textStyle,
                            image,
                            link,
                          ],
                          buttonList: [
                            ["undo", "redo"],
                            ["font", "fontSize", "formatBlock"],
                            ["paragraphStyle"],
                            [
                              "bold",
                              "underline",
                              "italic",
                              "strike",
                              "subscript",
                              "superscript",
                            ],
                            ["fontColor", "hiliteColor"],
                            ["removeFormat"],
                            "/", // Line break
                            ["outdent", "indent"],
                            ["align", "horizontalRule", "list", "lineHeight"],
                            ["table", "link", "image"],
                          ],
                          formats: [
                            "p",
                            "div",
                            "h1",
                            "h2",
                            "h3",
                            "h4",
                            "h5",
                            "h6",
                          ],
                          font: [
                            "Arial",
                            "Calibri",
                            "Comic Sans",
                            "Courier",
                            "Garamond",
                            "Georgia",
                            "Impact",
                            "Lucida Console",
                            "Palatino Linotype",
                            "Segoe UI",
                            "Tahoma",
                            "Times New Roman",
                            "Trebuchet MS",
                          ],
                        }}
                      />
                    </div>
                  )
              )}
            </div>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default AppDocumentation;
