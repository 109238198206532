export const FETCH_SUBSCRIBSION = "FETCH_SUBSCRIBSION";
export const FETCHING_SUBSCRIBSION_SUCCESS = "FETCHING_SUBSCRIBSION_SUCCESS";
export const FETCH_SUBSCRIBSION_FAILED = "FETCH_SUBSCRIBSION_FAILED";

export const ADD_SUBSCRIBSION = "ADD_SUBSCRIBSION";
export const ADD_SUBSCRIBSION_SUCCESS = "ADD_SUBSCRIBSION_SUCCESS";
export const ADD_SUBSCRIBSION_FAILURE = "ADD_SUBSCRIBSION_FAILURE";

export const EDIT_SUBSCRIBSION = "EDIT_SUBSCRIBSION";
export const EDIT_SUBSCRIBSION_SUCCESS = "EDIT_SUBSCRIBSION_SUCCESS";
export const EDIT_SUBSCRIBSION_FAILURE = "EDIT_SUBSCRIBSION_FAILURE";

export const UPDATE_SUBSCRIPTION_FILTER_DATA = "UPDATE_SUBSCRIPTION_FILTER_DATA";
export const CLEAR_SUBSCRIPTION_FILTER_DATA = "CLEAR_SUBSCRIPTION_FILTER_DATA";

export const UPDATE_SUBSCRIPTION_SORT = "UPDATE_SUBSCRIPTION_SORT";
export const UPDATE_SUBSCRIPTION_HIDDEN_COL="UPDATE_SUBSCRIPTION_HIDDEN_COL";
