import React, { Component, useEffect, useState } from "react";
import {
  Button,
  Grid,
  TextField,
  Typography,
  FormControl,
  FormHelperText,
  Select,
  Divider,
  Card,
  CardContent,
} from "@material-ui/core";
import {
  Container,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import Breadcrumb from "components/Breadcrumbs/Breadcrumb";
import {
  TextValidator,
  SelectValidator,
  ValidatorForm,
} from "react-material-ui-form-validator";
import Styles from "./Styles";
import { withStyles } from "@material-ui/styles";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import actions from "./redux/action";
import APIs from "api/RoleManagementAPIs/index";
import { RiEditLine } from "react-icons/ri";

import HelpdeskFAB from "../../views/HelpdeskFAB/HelpdeskFab";

import nodataimg from "assets/images/nodata.svg";
// import UserRoles from "./UserRoles";

const NoDataFound = (props) => {
  return (
    <div
      style={{
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        border: "1px solid #DEDEDF",
        borderRadius: 4,
        padding: 30,
        textAlign: "center",
        marginTop: 5,
      }}
    >
      <img src={nodataimg} alt="notfound" />
      <h3
        style={{
          marginTop: 5,
          font: "normal normal normal 16px/22px Nunito",
          color: "#B5B5B5",
        }}
      >
        {"No Permission set for this role"}
      </h3>
    </div>
  );
};

const SelectAll = (props) => {
  const [indeterminate, setIndeterminate] = useState(false);

  useEffect(() => {
    let length = props.masterpermissions.length;
    let arr = props.masterpermissions.filter((item, id) => {
      return !item.permission;
    });
    let arrTwo = props.masterpermissions.filter((item, id) => {
      return item.permission;
    });
    console.log(arrTwo.length, arr.length, length, indeterminate, "ytryryryt");
    if (arrTwo.length === length) {
      setIndeterminate(false);
    } else if (arr.length !== length) {
      setIndeterminate(true);
    } else {
      setIndeterminate(false);
    }
  });

  return (
    <>
      {props.status && (
        <FormControlLabel
          control={
            <Checkbox
              disabled={props.isDisabled}
              color="primary"
              checked={props.flag}
              onChange={(e) =>
                props.selectALl(
                  e.target.checked,
                  props.masterpermissions,
                  props.permissionData,
                  props.index
                )
              }
              indeterminate={indeterminate}
            />
          }
          label={props.label}
        />
      )}
    </>
  );
};

class createUserManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      isEdit: false,
      err_msg: "",
      index: 0,
      roleData: {
        name: "",
      },
      payload: {
        name: "",
        claims: [],
      },
      permissionData: [],
      indeterminiate: false,
      isDisabled: false,
    };
  }

  fetchMasterPermission = async (edit, claims) => {
    try {
      const res = await APIs.fetchMasterPermissions();
      let data = [];
      if (edit) {
        if (res.data) {
          // console.log(claims, res.data, "23456789");
          data = res.data.map((item, id) => {
            const idx = claims.map((itm) => itm.resourcetype).indexOf(item._id);
            if (idx !== -1) {
              return {
                ...item,
                status: true,
                selectAll: true,
                masterpermissions: item.permissions.map((val, i) => {
                  if (claims[idx].permissions.indexOf(val) !== -1) {
                    return { name: val, permission: true };
                  } else {
                    return { name: val, permission: false };
                  }
                }),
              };
            } else {
              return {
                ...item,
                status: false,
                selectAll: true,
                masterpermissions: item.permissions.map((val, i) => {
                  return { name: val, permission: true };
                }),
              };
            }
          });
        }
      } else {
        if (res.data) {
          data = res.data.map((item, id) => {
            return {
              ...item,
              status: false,
              selectAll: true,
              masterpermissions: item.permissions.map((val, i) => {
                return { name: val, permission: true };
              }),
            };
          });
        }
      }
      console.log("final filtered data", data);
      this.setState({
        permissionData: data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  async componentDidMount() {
    const { location } = this.props;
    this.props.getData();

    if (location.state !== undefined) {
      let { roleData, edit, disabled } = location.state;
      this.fetchMasterPermission(edit, roleData.claims);
      this.setState({
        payload: {
          ...this.state.payload,
          ...roleData,
        },
        isEdit: edit,
        isDisabled: disabled,
      });
    } else {
      this.props.history.push("/app/role-management");
    }
  }
  handleResourceCheck = (val, data, id, index) => {
    let updatePermissionData = [...this.state.permissionData];
    data[id] = val;
    updatePermissionData[index].masterpermissions = data;

    this.setState({
      permissionData: [...updatePermissionData],
    });
  };

  listPermissions = (list, status, index, isDisabled) => {
    return (
      <>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          backgroundColor="#ecf0f1"
        >
          {list.map((val, id) => (
            <div>
              {status && (
                <FormControlLabel
                  key={id}
                  control={
                    <Checkbox
                      disabled={isDisabled}
                      checked={val.permission}
                      color="primary"
                      onChange={(e) =>
                        this.handleResourceCheck(
                          {
                            ...val,
                            permission: e.target.checked,
                          },
                          list,
                          id,
                          index
                        )
                      }
                      // name={item._id}
                    />
                  }
                  label={val.name}
                />
              )}
            </div>
          ))}
        </Grid>
      </>
    );
  };

  handleChange = (check, id) => {
    let data = [...this.state.permissionData];
    if (check) {
      data = data.map((item, idx) => {
        return id === idx ? { ...item, status: true } : { ...item };
      });
    } else {
      data = data.map((item, idx) => {
        return id === idx ? { ...item, status: false } : { ...item };
      });
    }
    this.setState({
      permissionData: [...data],
    });
  };

  updatePermissionData = (arr, id, bool) => {
    let result = arr.map((val, idx) => {
      return idx === id ? { ...val, selectAll: bool } : { ...val };
    });
    return result;
  };

  updateMasterPermission = (data, bool) => {
    let result = data.map((item, id) => {
      return { ...item, permission: bool };
    });
    return result;
  };

  selectALl = (check, data, permissionData, id) => {
    let updatedData = [...permissionData];
    let masterPermission = [];
    if (check) {
      updatedData = this.updatePermissionData(permissionData, id, true);
      masterPermission = this.updateMasterPermission(data, true);
    } else {
      updatedData = this.updatePermissionData(permissionData, id, false);
      masterPermission = this.updateMasterPermission(data, false);
    }
    updatedData[id].masterpermissions = masterPermission;
    this.setState({
      permissionData: [...updatedData],
    });
  };

  filter_payload = (payload, permission_data) => {
    console.log(payload, permission_data, "checking payload");
    payload.claims = [];
    permission_data.map((item, index) => {
      if (item.status === true) {
        payload.claims.push({
          resourcetype: item._id,
          permissions: item.masterpermissions
            .filter((v, i) => {
              return v.permission;
            })
            .map((item, id) => {
              if (item.permission) {
                return item.name;
              }
            }),
          fieldscope: {},
        });
      }
    });

    // item.masterpermissions.map((v, i) => {
    //   if (v.permission) {
    //     return v.name;
    //   }
    // }),
    return payload;
  };

  addRolemanagement = async (type) => {
    let filterPayload = this.filter_payload(
      this.state.payload,
      this.state.permissionData
    );
    if (filterPayload.claims.length === 0) {
      this.props.enqueueSnackbar("Please select resource type", {
        variant: "error",
      });
      return;
    }

    try {
      let res = await APIs.addMasterRolemanagement(filterPayload);
      this.props.enqueueSnackbar("Roles Created successfully", {
        variant: "success",
        autoHideDuration: 2000,
      });
      if (type) {
        this.props.history.push("/app/role-management");
      }
    } catch (err) {
      this.props.enqueueSnackbar(
        err.response.data._msg || "something went wrong!",
        {
          variant: "error",
          autoHideDuration: 2000,
        }
      );
    }
  };

  editRolemanagement = async () => {
    let filterPayload = this.filter_payload(
      this.state.payload,
      this.state.permissionData
    );

    if (filterPayload.claims.length === 0) {
      this.props.enqueueSnackbar("Please select resource type", {
        variant: "error",
      });
      return;
    }

    try {
      const { _id, ...data } = filterPayload;
      let res = await APIs.editMasterRolemanagement(
        this.state.payload._id,
        data
      );
      this.props.enqueueSnackbar(res.data._msg, {
        variant: "success",
        autoHideDuration: 2000,
      });
      this.props.history.push("/app/role-management");
    } catch (err) {
      this.props.enqueueSnackbar(
        err.response.data._msg || "something went wrong!",
        {
          variant: "error",
          autoHideDuration: 2000,
        }
      );
    }
  };

  multiAdd = async () => {
    let { isEdit } = this.state;
    if (isEdit) {
      await this.editRolemanagement();
    } else {
      this.addRolemanagement(false);
    }
  };

  onSubmitUser = (e) => {
    if (e.nativeEvent.submitter.name === "multiple") {
      this.multiAdd();
    }
    if (e.nativeEvent.submitter.name === "single") {
      this.addRolemanagement(true);
    }
  };

  render() {
    const { isEdit, indeterminiate, permissionData, payload, isDisabled } =
      this.state;
    console.log(234567890, permissionData, payload);

    const imgFlag = permissionData.filter((item) => item.status).length === 0;

    return (
      <div>
        <Grid item sm={12} md={12} xs={12} lg={12}>
          <Breadcrumb
            {...this.props}
            links={[
              { name: "Role", url: "/app/role-management" },

              {
                name: `${isEdit ? payload.name : "Role"}`,
                url: "/app/create-rolemanagement",
              },
            ]}
          />
        </Grid>
        <ValidatorForm
          autoComplete="off"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              return;
            }
          }}
          onSubmit={(e) => {
            this.onSubmitUser(e);
          }}
        >
          <Grid container spacing={0}>
            <Grid item sm={6} md={6} lg={6} xs={12}>
              <Typography variant="h6" style={{ fontWeight: "bold" }}>
                {isEdit ? payload.name : "Role Manager"}
              </Typography>
            </Grid>
            <Grid
              item
              sm={6}
              md={6}
              container
              justifyContent="flex-end"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                {!isDisabled && (
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={() => {
                      this.props.history.push("/app/role-management");
                    }}
                  >
                    Cancel
                  </Button>
                )}
              </Grid>
              {isEdit && !isDisabled ? (
                <Grid item>
                  <Button
                    name="multiple"
                    size="small"
                    variant="contained"
                    type="submit"
                  >
                    Save{" "}
                  </Button>
                </Grid>
              ) : null}
              <Grid item>
                {!isEdit && (
                  <Button
                    name="single"
                    size="small"
                    variant="contained"
                    type="submit"
                  >
                    {"Create"}
                  </Button>
                )}
              </Grid>
              <Grid item>
                {isDisabled && (
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() =>
                      this.setState({
                        isDisabled: false,
                      })
                    }
                    startIcon={<RiEditLine style={{ fontSize: "20px" }} />}
                  >
                    Edit
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={12}>
            <Divider
              style={{
                marginTop: "12px",
                height: "2px",
                background: "rgb(175, 175, 175)",
              }}
            />{" "}
          </Grid>
          <Grid
            container
            spacing={3}
            // style={{ border: "1px solid red", height: "400px" }}
          >
            <Grid item md={3} className="mt-3">
              <Grid container spacing={2}>
                <Grid item md={12}>
                  <Typography style={{ marginBottom: "2px" }} variant="body2">
                    Resource Types
                  </Typography>
                  <Card
                    style={{
                      background: "#f2f4f6",
                      border: "none",
                      boxShadow: "none",
                    }}
                  >
                    <CardContent>
                      {permissionData.map((item, id) => (
                        <div>
                          <FormControlLabel
                            control={
                              <Checkbox
                                disabled={isDisabled}
                                color="primary"
                                checked={item.status}
                                onChange={(e) =>
                                  this.handleChange(e.target.checked, id)
                                }
                              />
                            }
                            label={item.label}
                          />
                        </div>
                      ))}
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={9} className="mt-3">
              <Grid item md={5}>
                <Typography style={{ marginBottom: "2px" }} variant="body2">
                  Role Name <span style={{ color: "red" }}>*</span>
                </Typography>
                <TextValidator
                  size="small"
                  variant="outlined"
                  name="name"
                  fullWidth
                  disabled={isEdit}
                  value={payload.name}
                  onChange={async (e) => {
                    await this.setState({
                      payload: { ...payload, name: e.target.value.trimStart() },
                    });
                  }}
                  validators={["required"]}
                  errorMessages={["Please enter role"]}
                />
              </Grid>
              <Grid item sm={12} className="mt-4">
                <Typography style={{ marginBottom: "2px" }} variant="body2">
                  Permissions
                </Typography>
                <Card
                  className="mb-3"
                  style={{
                    // height: "300px",
                    // overflow: "auto",
                    // background: "#f2f4f6",
                    border: "none",
                    boxShadow: "none",
                  }}
                >
                  {this.state.permissionData.map((item, index) => (
                    <>
                      {item.status && (
                        <CardContent
                          style={{
                            background: "#f2f4f6",
                            border: "none",
                            boxShadow: "none",
                            marginBottom: 3,
                          }}
                        >
                          <FormGroup>
                            <>
                              <SelectAll
                                selectALl={this.selectALl}
                                masterpermissions={item.masterpermissions}
                                flag={item.selectAll}
                                permissionData={this.state.permissionData}
                                index={index}
                                label={item.label}
                                status={item.status}
                                isDisabled={isDisabled}
                                {...this.props}
                              />
                              {this.listPermissions(
                                item.masterpermissions,
                                item.status,
                                index,
                                isDisabled
                              )}
                            </>
                          </FormGroup>
                        </CardContent>
                      )}
                    </>
                  ))}
                </Card>
                <div>{imgFlag && <NoDataFound />}</div>
              </Grid>
            </Grid>
            <HelpdeskFAB />
          </Grid>
        </ValidatorForm>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.roleManagement.data,

    loading: state.userManagement.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getData: () => dispatch(actions.fetchMasterPermissions()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(createUserManagement));
