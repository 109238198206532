import { React, useEffect, useState, useRef } from "react";

import Breadcrumb from "components/Breadcrumbs/Breadcrumb";
import { RiEditLine, RiDeleteBin5Line } from "react-icons/ri";
import Styles from "./Style";
import { Link } from "react-router-dom";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import AddIcon from "@material-ui/icons/Add";
import {
  TextField,
  IconButton,
  Button,
  Typography,
  Divider,
  Grid,
} from "@material-ui/core";
import DexTable from "components/DexTable/DexTable";
import { withStyles } from "@material-ui/styles";
import { Pagination } from "@material-ui/lab";
import { withSnackbar } from "notistack";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import axios from "axios";
import API from "api/StatusProfileAPIs/index";
import { useSnackbar } from "notistack";
import HelpdeskFAB from "../../views/HelpdeskFAB/HelpdeskFab";

const EditStatusProfile = (props) => {
  const { classes } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState("true");
  const [open, setOpen] = useState("false");
  const [statusCardData, setStatusCardData] = useState([]);
  const activeColor = useRef(null);
  const [colorPicker, setColorPicker] = useState("#rrggbb");
  const [colorActive, setcolorActive] = useState("");
  const [status, setStatus] = useState("");
  const [customcolor, setCustomColor] = useState("");
  let [statusInput, setStatusInput] = useState("");
  const [commentnew, setCommentnew] = useState([]);
  const statusmsg = [];
  const [flag, setflag] = useState(false);
  const [editedVal, seteditedVal] = useState(null);
  const [isEditStatus, setEditStatus] = useState(false);
  const [StatusProfileData, setStatusProfileData] = useState({});
  const [inputDisableFlag, setInputDisableFlag] = useState(true);
  const [errorInput, seterrorInput] = useState(false);
  const [errObj, setErrObj] = useState({ status: false });

  const [payload, setPayload] = useState({
    color: "",
    status: "",
  });

  // console.log(StatusProfileData.Editable, "===========866666");
  const circleColorPicker = [
    "#F82329",
    "#34BC41",
    "#00B5AD",
    "#2185D0",
    "#B5CC18",
    "#FBBD08",
    "#F2711C",
    "#6435C9",
    "#E03997",
    "#767676",
  ];

  // const  StatusProfile= {
  //   status: []
  // },

  const handleColorPickerChange = (e) => {
    setColorPicker(e.target.value);
    setCustomColor(e.target.value);
  };

  const handleSelectCircleColor = (color, index) => {
    setCustomColor(color);
    setcolorActive(index);
    setPayload({ ...payload, color: color });
  };
  console.log(commentnew, "uuuuuuuuuuuuuuu");

  useEffect(() => {
    setStatusInput("");
    setCustomColor("");
    setColorPicker("#rrggbb");
    setcolorActive("");
  }, [commentnew.status]);

  useEffect(() => {
    if (payload.status.length === 0) {
      setflag(false);
      setcolorActive("");
    }
  }, [payload.status, flag]);

  const handleRemoveStatus = async (status) => {
    try {
      const config = {
        status: status,
      };
      let { _msg, _status } = await API.deleteStatusProfile(
        StatusProfileData.name,
        config
      );
      if (_status === 200) {
        fetchStatus(StatusProfileData.name);
      }
      enqueueSnackbar(_msg, { variant: "success", autoHideDuration: 3000 });
    } catch (err) {
      const { _msg } = err?.response?.data || { _msg: "Network Issue" };
      const msg = _msg;
      enqueueSnackbar(msg, { variant: "error", autoHideDuration: 3000 });
    }
  };

  useEffect(() => {
    const { location } = props;

    if (location.state !== undefined) {
      const { data } = location.state;
      setStatusProfileData({ ...data });
      console.log(data, "3456789sdfghjjhdrtyu");
      fetchStatus(data.name);
      // setCommentnew([...(data.status || [])]);
    } else {
      props.history.push("app/applications");
    }
  }, []);

  const handleClickDefault = async (status_name) => {
    const config = {
      resourcetype: StatusProfileData.name,
      status: status_name,
    };
    try {
      let { _status, _msg } = await API.setDefaultStatusProfile(config);
      fetchStatus(StatusProfileData.name);
      props.enqueueSnackbar(_msg, { variant: "success" });
    } catch (err) {
      const { _msg } = err?.response?.data || { _msg: "Network issue" };
      props.enqueueSnackbar(_msg, { variant: "error" });
    }
  };

  const data = {};

  const fetchStatus = async (name) => {
    try {
      let res = await API.getStatusProfileByName(name);
      setCommentnew([...(res.data.status || [])]);
    } catch (err) {}
  };

  const handleSubmitComment = async () => {
    if (payload.status.trim().length === 0) {
      setErrObj({
        status: true,
      });
      return;
    }

    try {
      let res = await API.addStatusProfile(
        StatusProfileData.name.trim(),
        payload
      );
      console.log("fhfjshfgsdgfdsg", res);

      const { _msg, _status } = res.data;
      setflag(false);
      fetchStatus(StatusProfileData.name);
      setPayload({
        color: "",
        status: "",
      });
      enqueueSnackbar(_msg, { variant: "success", autoHideDuration: 3000 });
    } catch (err) {
      displayErrMsg(err);
    }
  };

  const displayErrMsg = (err) => {
    const { _msg, data } = err?.response?.data || { _msg: "Network issue" };
    if (data && Array.isArray(data)) {
      data.map((i) => {
        enqueueSnackbar(i, {
          variant: "error",
          autoHideDuration: 3000,
        });
      });
    } else {
      enqueueSnackbar(_msg, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  const handleEditStatus = (val) => {
    seteditedVal(val);
    setflag(true);
    setStatusInput(val.status);
    const foundColor = circleColorPicker.filter(
      (color, i) => color === val.color
    );
    circleColorPicker.map((color, index) => {
      if (color === val.color) {
        setcolorActive(index);
        setColorPicker("#rrggbb");
      } else if (foundColor.length === 0) {
        setColorPicker(val.color);
        setcolorActive("");
      }
    });
  };

  const handleSaveEditedStatus = async () => {
    if (payload.status.trim().length === 0) {
      setErrObj({
        status: true,
      });
      return;
    }

    let id = payload.status_id;
    const config = {
      status: payload.status.trim(),
      default: StatusProfileData.default_status === id,
      color: payload.color,
    };

    console.log("dghgdhhd44", config, payload.status);

    try {
      let { _msg } = await API.EditStatusProfile(
        StatusProfileData.name.trim(),
        payload.statusToBeEdit,
        config
      );

      setflag(false);
      setStatusInput("");
      setcolorActive("");
      setCustomColor("");
      enqueueSnackbar(_msg, {
        variant: "success",
      });
      fetchStatus(StatusProfileData.name);
      setPayload({ color: "", status: "" });
    } catch (error) {
      displayErrMsg(error);
    }
    setEditStatus(false);
  };

  const onChangeStatusName = (e) => {
    var RegExpression = /^[a-zA-Z\s]*$/;

    if (RegExpression.test(e.target.value)) {
      setPayload({ ...payload, status: e.target.value });
    }
  };
  return (
    <>
      <Grid container>
        <Grid item sm={12} md={12} xs={12} lg={12}>
          <Breadcrumb
            {...props}
            links={[
              { name: "Status Profile", url: "/app/status-profile" },
              {
                name: `Edit Status Profile`,
                url: "/app/edit-status-profile",
              },
            ]}
          />
        </Grid>
        <Grid item sm={8} md={8} lg={8} xs={12} className="mb-2">
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            Status Profile
          </Typography>
        </Grid>
        <Grid item md={12}>
          <Divider
            style={{
              marginTop: "4px",
              height: "2px",
              background: "rgb(175, 175, 175)",
            }}
          />
        </Grid>

        <Grid item sm={12}>
          <Grid item sm={3} className={classes.wrapper}>
            <Typography style={{ marginBottom: "2px" }} variant="body2">
              Object Type
            </Typography>

            <TextField
              // disabled={isDisabled}
              size="small"
              variant="outlined"
              fullWidth
              name="name"
              disabled
              value={StatusProfileData.name}
              // value={SubscriptionData.subscription}
              // onChange={(e) =>
              //   this.onChange({
              //     ...SubscriptionData,
              //     subscription: e.target.value,
              //   })
              // }
              validators={["required"]}
              errorMessages={["Please enter subscription"]}
              // onFocus={this.setDuplicateNull}
              // error={error}
              // helperText={error ? "Subscription Plan already exist" : ""}
            />
          </Grid>
          <Grid item sm={9}></Grid>
        </Grid>
        <Grid item sm={12}>
          <Grid item sm={3}>
            <Typography
              className={classes.fieldHeading}
              style={{ marginBottom: "2px" }}
              variant="body2"
            >
              Status list
            </Typography>
          </Grid>
          <Grid item sm={9}></Grid>
        </Grid>
        <hr className={classes.border} style={{ height: 0.5 }} />
        <Grid item xs={12} sm={12} style={{ display: "flex" }}>
          <Grid item sm={6}>
            <Grid item sm={6}>
              <Typography style={{ marginBottom: "2px" }} variant="body2">
                Status Name<text style={{ color: "red" }}> *</text>
              </Typography>
              <TextField
                // disabled={isDisabled}
                size="small"
                variant="outlined"
                fullWidth
                name="name"
                value={payload.status}
                autoComplete="off"
                // onChange={(e) =>
                //   this.onChange({
                //     ...SubscriptionData,
                //     subscription: e.target.value,
                //   })
                // }
                // onChange={(e) => {
                //   setCommentnew({ ...commentnew, val: e.target.value });
                // }}
                onChange={onChangeStatusName}
                // onChange={handleChangeStatusInput}
                // onChange={(e) => (setStatus = e.target.value)}
                validators={["required"]}
                errorMessages={["Please enter name"]}
                // onFocus={this.setDuplicateNull}
                error={errObj.status}
                onFocus={() => setErrObj({ status: false })}
                // helperText={error ? "Subscription Plan already exist" : ""}
              />
              <div>
                {errObj.status && (
                  <text style={{ color: "red", fontSize: "11px" }}>
                    please enter the status name
                  </text>
                )}
              </div>
            </Grid>

            <Typography className="mt-4 mb-2" variant="body2">
              Choose Color
            </Typography>
            <div
              className={classes.circleColorPicker}
              style={{ display: "flex" }}
            >
              {circleColorPicker.map((color, index) => {
                return (
                  <div
                    className={
                      colorActive === index ? classes.active : classes.inactive
                    }
                  >
                    <div
                      className={classes.innerCircleColorPicker}
                      style={{
                        backgroundColor: color,
                        cursor: "pointer",
                      }}
                      key={index}
                      ref={activeColor}
                      id="circleColors"
                      onClick={() => handleSelectCircleColor(color, index)}
                    ></div>
                  </div>
                );
              })}
              <div style={{ display: "flex", marginLeft: "16px" }}>
                <p className="mr-3 mt-1">Custom Color</p>
                <input
                  type="color"
                  className={classes.inputTypeColorElement}
                  value={payload.color}
                  onChange={(e) => {
                    // handl:eColorPickerChange(e);
                    setPayload({ ...payload, color: e.target.value });
                  }}
                />
              </div>
            </div>

            <Grid item sm={3}>
              {isEditStatus === false ? (
                <Button
                  className="mt-2"
                  // component={Link}
                  style={{
                    color: "white",
                    boxShadow: "none",
                    //   width: "100%",
                    //   padding: "6px 11px 6px 11px",
                  }}
                  variant="contained"
                  color="primary"
                  size="small"
                  alignItems="center"
                  // onClick={this.createSubscription}
                  onClick={(e) => handleSubmitComment()}
                  startIcon={<AddIcon />}
                >
                  Add
                </Button>
              ) : (
                <Button
                  className="mt-2"
                  // component={Link}
                  style={{
                    color: "white",
                    boxShadow: "none",
                    //   width: "100%",
                    //   padding: "6px 11px 6px 11px",
                  }}
                  disabled={false}
                  variant="contained"
                  color="primary"
                  size="small"
                  alignItems="center"
                  // onClick={this.createSubscription}
                  onClick={(e) => handleSaveEditedStatus()}
                  // startIcon={<AddIcon />}
                >
                  Save
                </Button>
              )}
            </Grid>
          </Grid>
          <Divider
            orientation="vertical"
            className={classes.MuiCenterDivider}
          />
          <Grid item sm={5}>
            <div
              style={{
                height: "420px",
                position: "relative",
                overflow: "scroll",
              }}
            >
              {commentnew.map((msg, id) => {
                return (
                  <div key={id}>
                    <div>
                      <div style={{ display: "flex", marginLeft: "25px" }}>
                        <div
                          //   onClick={() => setStatusInput(data.title)}
                          className={classes.newStatusAddBox}
                        >
                          <div
                            // style={{ color: data.color }}

                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                              justifyContent: "space-between",
                              padding: "12px 5px 0px 20px",
                            }}
                            // onClick={() => handleSetStatusDefault(data.id)}
                          >
                            <p
                              onClick={() => {
                                // handleEditStatus(msg);
                                setPayload({
                                  ...payload,
                                  status: msg.status,
                                  statusToBeEdit: msg.status,
                                  color: msg.color,
                                  status_id: msg._id,
                                });
                                setEditStatus(true);
                                setInputDisableFlag(data.Editable);
                              }}
                              style={{
                                // color: data.color,
                                cursor: "pointer",
                                color: `${msg.color}`,
                              }}
                            >
                              <span
                                className={classes.predefinedColorDots}
                                // style={{ backgroundColor: data.color }}
                                style={{
                                  backgroundColor: `${msg.color}`,
                                  marginTop: "5px",
                                }}
                              ></span>

                              {msg.status}
                            </p>
                            {/* <Grid item lg={4} md={4} sm={4}>
                              <Grid container justify="flex-end">
                                <Grid
                                  item
                                  // onClick={() => handleClickDefault(data)}
                                  onClick={() => handleClickDefault()}
                                >
                                  <p>
                                    <span
                                      className={classes.markAsDefault}
                                      style={{
                                        color: "grey",
                                        cursor: "pointer",
                                      }}
                                    >
                                      Mark as default
                                    </span>
                                  </p>
                                </Grid>
                              </Grid>
                            </Grid> */}
                            <Grid item lg={4} md={4} sm={4}>
                              <Grid container justify="flex-end">
                                <Grid
                                  item
                                  onClick={() => handleClickDefault(msg.status)}
                                >
                                  <p>
                                    {msg.default ? (
                                      <span
                                        style={{
                                          color: "2185D0",
                                          marginRight: "20px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        Default
                                      </span>
                                    ) : (
                                      <span
                                        className={classes.markAsDefault}
                                        style={{
                                          color: "grey",
                                          cursor: "pointer",
                                        }}
                                      >
                                        Mark as default
                                      </span>
                                    )}
                                  </p>
                                </Grid>
                              </Grid>
                            </Grid>
                          </div>
                        </div>
                        {StatusProfileData &&
                        StatusProfileData.Editable === false ? null : (
                          <IconButton
                            color="inherit"
                            aria-label="delete"
                            edge="start"
                            style={{
                              marginLeft: 12,
                            }}
                            onClick={() => handleRemoveStatus(msg.status)}
                          >
                            <img
                              src="/images/deleteLine.svg"
                              alt="card"
                              style={{ filter: "brightness(0.1)" }}
                            />
                          </IconButton>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </Grid>
        </Grid>
        <HelpdeskFAB category="Status Profile" />
      </Grid>
    </>
  );
};
export default withStyles(Styles)(withSnackbar(EditStatusProfile));
