/**
 * import all actions
 */

import * as actions from "./actionTypes";
import billingAPIS from "api/BillableServiceAPIs/index";
import { Hidden } from "@material-ui/core";

function actionFetchBillableServices(pageno, size, filters, sort) {
  console.log({ actions });
  return async (dispatch) => {
    dispatch({
      type: actions.FETCHING_BILLABLE_SERVICES,
      loading: true,
    });
    try {
      let data = await billingAPIS.fetchAllBillableServicesWithParams(
        pageno,
        size,
        filters,
        sort
      );
      dispatch({
        type: actions.FETCHING_BILLABLE_SERVICES_SUCCESS,
        loading: false,
        totalCount: data.totalCount,
        data: data.data || [],
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: actions.FETCHING_BILLABLE_SERVICES_FAILURE,
        loading: false,
        error: err,
      });
    }
  };
}

function editBillableServices(billableId, payload) {
  return async (dispatch) => {
    dispatch({
      type: actions.EDIT_BILLABLE_SERVICES,
      loading: true,
    });
    try {
      let editdata = await billingAPIS.editBillableServices(
        billableId,
        payload
      );
      dispatch({
        type: actions.EDIT_BILLABLE_SERVICES_SUCCESS,
        loading: false,
        editdata,
      });
    } catch (err) {
      dispatch({
        type: actions.EDIT_BILLABLE_SERVICES_FAILURE,
        loading: false,
        error: err,
      });
    }
  };
}

function deleteBillableServices(billableId) {
  return async (dispatch) => {
    dispatch({
      type: actions.DELETE_BILLABLE_SERVICES,
      loading: true,
    });
    try {
      let deldata = await billingAPIS.deleteBillableServices(billableId);
      dispatch({
        type: actions.DELETE_BILLABLE_SERVICES_SUCCESS,
        loading: false,
        deldata,
      });

      const { _msg } = deldata.data;

      return {
        _status: true,
        _msg,
      };
    } catch (err) {
      dispatch({
        type: actions.DELETE_BILLABLE_SERVICES_FAILURE,
        loading: false,
        error: err,
      });
      const { _msg } = err?.response?.data || { _msg: "Network issue!" };
      return {
        _msg,
        _status: false,
      };
    }
  };
}

function addBillableServices(payload) {
  return async (dispatch) => {
    dispatch({
      type: actions.ADD_BILLABLE_SERVICES,
      loading: true,
    });
    try {
      let adddata = await billingAPIS.addBillableServices(payload);
      dispatch({
        type: actions.ADD_BILLABLE_SERVICES_SUCCESS,
        loading: false,
        adddata,
      });
    } catch (err) {
      dispatch({
        type: actions.ADD_BILLABLE_SERVICES_FAILURE,
        loading: false,
        error: err,
      });
    }
  };
}

const updateFilterData = ({ filter, filterArray }) => ({
  type: actions.UPDATE_BILLABLE_FILTER_DATA,
  payload: { filter, filterArray },
});

const updateSort = ({ sorting, sort }) => ({
  type: actions.UPDATE_BILLABLE_SORT,
  payload: { sorting, sort },
});


const udateBillingHiddenCol=(Hidden)=>({
type:actions.UPDATE_BILLABLE_HIDDEN_COL,
payload:Hidden,
});
export default {
  actionFetchBillableServices,
  editBillableServices,
  deleteBillableServices,
  addBillableServices,
  updateFilterData,
  updateSort,
  udateBillingHiddenCol
};
