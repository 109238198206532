import * as types from "./actionTypes";
// import Immutable from "seamless-immutable";
const initialState = {
  applicationData: [],
  applicationDetails: {
    applicationApi: [],
    applicationDetail: {},
    applicationModules: [],
    authorization: [],
    billingServices: [],
    environments: [],
    outgoingApi: [],
    webhooks: [],
  },
  usageDocumentData: [],
  apis: [],
  loading: false,
  totalCount: 0,
  app_id: 0,
  appApi: [],
  action: "",
  documents: {
    loading: false,
    success: false,
    message: null,
    singleAdd: null,
  },
  appTeamData: {
    teams: [],
    selectedTeams: [],
    clickedTeam: { users: [] },
  },
  introDoc: [],
  message: null,
  success: false,
  msg: null,
  //  message: {},
  appTable: {
    env: [],
    module: [],
    apiHidden: [],
    outgoingApiHidden: [],
    authorizationHidden: [],
    billingServicesHidden: [],
    webhooksHidden: [],
    introDocHidden: [],
    usageDocHidden: [],
  },
  onBoardTable: {
    environment: [],
    moduleHide: [],
    api: ["cluster", "username", "method", "password"],
    outgoingHidden: [],
    authorization: [],
    billingServices: [],
    webhook: [],
    introDocHidden: [],
    usageDocHidden: [],
  },
};
export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case types.FETCH_APPLICATION_DETAILS:
      return {
        ...state,
        loading: true,
      };
    case types.FETCH_APPLICATION_DETAILS_SUCCESS:
      return {
        ...state,
        applicationDetails: {
          ...action.payload,
        },
        message: action.message,
        action: action.type,
        loading: false,
      };
    case types.FETCH_APPLICATION_DETAILS_FAILED:
      return {
        ...state,
        action: action.type,
        loading: false,
        message: action.message,
      };

    case types.CLEAR_MESSAGE:
      return {
        ...state,
        message: null,
      };
    case types.PUBLISHED_APPLICATION:
      return {
        ...state,
        message: action.message,
        action: action.type,
      };
    case types.PUBLISH_APPLICATION_FAILED:
      return {
        ...state,
        message: action.message,
        action: action.type,
      };
    case types.FETCHED_APPLICATIONS_API:
      return {
        ...state,
        appApi: action.payload,
        action: action.type,
      };
    case types.UPDATE_APP_APIS:
      return {
        ...state,
        apis: [...action.payload],
      };
    case types.UPDATE_ACTIONS:
      return {
        ...state,
        action: "",
      };
    case types.ACTION_BEGIN_USAGE_DOC:
      return {
        ...state,
        documents: {
          ...state.documents,
          loading: true,
        },
      };
    case types.ACTION_SUCCESS_USAGE_DOC:
      return {
        ...state,
        documents: {
          ...state.documents,
          loading: false,
          success: true,
          message: action.msg,
          singleAdd: action.single,
        },
      };
    case types.ACTION_FAILED_USAGE_DOC:
      return {
        ...state,
        documents: {
          ...state.documents,
          loading: false,
          success: false,
          message: action.msg,
          singleAdd: false,
        },
      };

    case types.FETCHED_DOC:
      return {
        ...state,
        usageDocumentData: action.payload,
      };
    case types.FETCH_DOC_FAILED:
      return {
        ...state,
        usageDocumentData: [],
      };

    case types.FETCHED_TEAMS_FOR_APP_DETAIL:
      return {
        ...state,
        appTeamData: {
          selectedTeams: action.selectedTeam,
          clickedTeam: action.clickedTeam,
          teams: action.payload,
        },
      };
    case types.FETCH_TEAMS_FOR_APP_DETAIL_FAIL:
      return {
        ...state,
        appTeamData: {
          selectedTeams: [],
          clickedTeam: { users: [] },
          teams: [],
        },
      };

    case types.UPDATE_TEAMS_FOR_APP_DETAIL:
      return {
        ...state,
        appTeamData: {
          selectedTeams: action.selectedTeam,
          clickedTeam: action.clickedTeam,
          teams: action.updateTeams,
        },
      };

    case types.ASSIGN_DISMISS_TEAM:
      return {
        ...state,
        loading: true,
      };

    case types.ADD_TEAM_TO_APP_DETAIL:
      return {
        ...state,
        loading: false,
        appTeamData: {
          selectedTeams: action.selectedTeam,
          clickedTeam: action.clickedTeam,
          teams: action.updateTeams,
        },
        msg: action.msg,
        success: true,
      };

    case types.RESET_TEAM_IN_APP_DETAIL:
      return {
        ...state,
        loading: false,
        appTeamData: {
          selectedTeams: [...(state.appTeamData.selectedTeams || [])],
          clickedTeam: {
            users: [...(state.appTeamData.clickedTeam.users || [])],
          },
          teams: [...(state.appTeamData.teams || [])],
        },
        msg: action.msg,
        success: false,
      };

    case types.FETCH_INTRO_DOC:
      return {
        ...state,
        documents: {
          ...state.documents,
          loading: true,
        },
      };
    case types.FETCHED_INTRO_DOC:
      return {
        ...state,
        documents: {
          ...state.documents,
          loading: false,
          message: action.msg,
          success: true,
        },
        introDoc: action.payload,
      };
    case types.FETCH_INTRO_DOC_FAIL:
      return {
        ...state,
        documents: {
          ...state.documents,
          loading: false,
          message: action.msg,
          success: false,
        },
        introDoc: [],
      };

    case types.RESET_DOC:
      return {
        ...state,
        documents: {
          loading: false,
          success: false,
          message: null,
          singleAdd: null,
        },
      };

    case types.SET_CLICKED_TEAM:
      return {
        ...state,
        appTeamData: {
          ...state.appTeamData,
          clickedTeam: action.payload,
        },
      };

    case types.RESET_PROPS:
      return {
        ...state,
        msg: null,
        success: false,
      };
    case types.CLEAR_ALL_APPLICATIONS_DETAILS:
      return {
        ...initialState,
      };
    case types.UPDATE_APPLICATION_DETAILS_HIDDEN_COL:
      return {
        ...state,
        appTable: {
          ...state.appTable,
          [action.key]: action.payload,
        },
      };
    case types.UPDATE_ONBOARD_HIDDEN_COL:
      return {
        ...state,
        onBoardTable: {
          ...state.onBoardTable,
          [action.key]: action.payload,
        },
      };

    default:
      return state;
  }
}
