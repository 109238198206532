import React, { useEffect, useState } from "react";
import { checkPermission } from "../../helpers/permissons";
import {
  Typography,
  Grid,
  Button,
  Divider,
  TextField,
} from "@material-ui/core/";
import Breadcrumb from "components/Breadcrumbs/Breadcrumb";
import { makeStyles } from "@material-ui/core/styles";
import AddCategoryBilling from "./AddCategoryBilling";
import actions from "./redux/action";
import { connect } from "react-redux";
import APIs from "../../api/BillableServiceAPIs/index";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { isDuplicate } from "helpers/helpers";
import { withSnackbar } from "notistack";
import { RiEditLine } from "react-icons/ri";
import HelpdeskFAB from "../../views/HelpdeskFAB/HelpdeskFab";

// const useStyles = makeStyles((theme) => ({
//   divider: {
//     marginTop: "12px",
//     height: "2px",
//     background: "rgb(175, 175, 175)",
//   },
// }));
class EditBillableservice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ID: localStorage.getItem("billableId"),
      error: false,
      isEdit: false,
      ajax: false,
      billingData: {
        name: "",
        unit: "",
        category: "",
        description: "",
      },
      err_msg: "",
      index: 0,
      category: [],
      autoCompleteVal: { name: "" },
      isDisabled: false,
    };
  }

  onChange = (value) => {
    this.setState({
      billingData: { ...value },
    });
  };
  getCategories = async () => {
    try {
      let res = await APIs.getAllCategories();
      if (res.data) {
        this.setState({ category: res.data.data || [] });
      }
    } catch (err) {
      console.log("categories", err);
    }
  };

  addCategory = async (value) => {
    try {
      let res = await APIs.addCategories(value);
      let categoryId = "";
      if (res.data.data.categoryId) {
        this.getCategories();
        categoryId = res.data.data.categoryId;
      }
      return { categoryId, success: true };
    } catch (err) {
      let _msg = err?.response?.data?._msg || "Connection error!";
      return { success: false, _msg };
    }
  };

  async componentDidMount() {
    const { location } = this.props;
    if (location.state !== undefined) {
      await this.getCategories();
      let { billingData, edit, ajax, disabled } = location.state;
      if (edit) {
        this.state.category.map((item, id) =>
          item._id === billingData.category
            ? this.setState({
                autoCompleteVal: { name: item.name },
              })
            : null
        );
      }
      this.setState({
        billingData: {
          ...billingData,
        },
        isEdit: edit,
        ajax: ajax,
        isDisabled: disabled,
      });
    }
  }

  editBilling = async () => {
    let { _id, ...data } = this.state.billingData;
    // let res = await APIs.editBillableServices(_id, data);
    // if (res.data) {
    //   this.props.enqueueSnackbar("Billable Service updated successfully", {
    //     variant: "success",
    //     autoHideDuration: 2000,
    //   });
    //   this.props.history.push("/app/billing");
    // }
    try {
      const res = await APIs.editBillableServices(_id, data);
      this.props.enqueueSnackbar(
        res.data._msg || "Billable Service updated successfully",
        { variant: "success", autoHideDuration: 2000 }
      );
      if (res.data) {
        this.props.history.push("/app/billing");
      }
    } catch (err) {
      console.log(err.response, "res");
      this.props.enqueueSnackbar(
        err?.response?.data._msg || "Something Went wrong",
        {
          variant: "error",
          autoHideDuration: 2000,
        }
      );
      console.error(err);
    }
  };

  addBilling = async () => {
    let { ...data } = this.state.billingData;
    try {
      const res = await APIs.addBillableServices(data);
      console.log(res, "resp");
      this.props.enqueueSnackbar(
        res.data._msg || "Billable Service added successfully",
        { variant: "success", autoHideDuration: 2000 }
      );
      this.props.history.push("/app/billing");
    } catch (err) {
      this.props.enqueueSnackbar(
        err?.response?.data._msg || "Something Went wrong",
        {
          variant: "error",
          autoHideDuration: 2000,
        }
      );
      console.error(err);
    }
  };

  multiAdd = async () => {
    let { isEdit } = this.state;
    if (isEdit && checkPermission("btn8")) {
      await this.editBilling();
    } else {
      try {
        const res = await APIs.addBillableServices(this.state.billingData);
        this.props.enqueueSnackbar(
          res.data._msg || "Billable Service added successfully",
          { variant: "success", autoHideDuration: 2000 }
        );
      } catch (err) {
        this.props.enqueueSnackbar(
          err?.response?.data._msg || "Something Went wrong",
          {
            variant: "error",
            autoHideDuration: 2000,
          }
        );
        console.error(err);
      }
      this.setState({
        billingData: {
          name: "",
          unit: "",
          category: "",
          description: "",
        },
        autoCompleteVal: null,
        isEdit: false,
      });
    }
  };

  onSubmitBilling = (e) => {
    const { billingData } = this.state;
    let flag = false;
    this.setState({
      billingData,
    });
    if (flag) {
      this.setState({
        error: true,
      });
      return;
    }
    this.setState({
      err_msg: "",
      error: false,
    });
    if (e.nativeEvent.submitter.name === "multiple") {
      this.multiAdd();
    }
    if (e.nativeEvent.submitter.name === "single") {
      this.addBilling();
    }
  };

  render() {
    const { billingData, isEdit, autoCompleteVal, isDisabled } = this.state;
    return (
      <div>
        <ValidatorForm
          ref="form"
          autoComplete="off"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              return;
            }
          }}
          onSubmit={(e) => {
            this.onSubmitBilling(e);
          }}
        >
          <Grid container>
            <Grid item sm={12} md={12} xs={12} lg={12}>
              <Breadcrumb
                {...this.props}
                links={[
                  { name: "Billable Services", url: "/app/billing" },
                  {
                    name: `${
                      isEdit
                        ? "Edit Billable Service"
                        : "Create Billable Service"
                    }`,
                    url: "/app/edit_billable",
                  },
                ]}
              />
            </Grid>
            <Grid item sm={6} md={6} lg={6} xs={12}>
              <Typography variant="h6" style={{ fontWeight: "bold" }}>
                {isEdit ? billingData.name : "Billable Service"}
              </Typography>
            </Grid>
            <Grid
              item
              sm={6}
              md={6}
              container
              justifyContent="flex-end"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                {!isDisabled && (
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={() => {
                      this.props.history.push("/app/billing");
                    }}
                  >
                    Cancel
                  </Button>
                )}
              </Grid>
              <Grid item>
                {!isDisabled ? (
                  <Button
                    name="multiple"
                    color={isEdit ? "" : "primary"}
                    size="small"
                    variant={isEdit ? "contained" : "outlined"}
                    type="submit"
                    id={isEdit ? "btn8" : "btn7"}
                    disabled={
                      isEdit
                        ? !checkPermission("btn8")
                        : !checkPermission("btn7")
                    }
                  >
                    {isEdit ? "Save" : "Create and Add Another"}
                  </Button>
                ) : null}
              </Grid>
              <Grid item>
                {!isEdit && (
                  <Button
                    name="single"
                    size="small"
                    variant="contained"
                    type="submit"
                    disabled={!checkPermission("btn7")}
                  >
                    {"Create"}
                  </Button>
                )}
              </Grid>
              <Grid item>
                {isDisabled && (
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() =>
                      checkPermission("btn8") &&
                      this.setState({
                        isDisabled: false,
                      })
                    }
                    disabled={!checkPermission("btn8")}
                    startIcon={<RiEditLine style={{ fontSize: "20px" }} />}
                  >
                    Edit
                  </Button>
                )}
              </Grid>
            </Grid>

            <Grid item md={12}>
              <Divider
                style={{
                  marginTop: "12px",
                  height: "2px",
                  background: "rgb(175, 175, 175)",
                }}
              />
            </Grid>
            <Grid container spacing={2} className="mt-3">
              <Grid item sm={3} md={3} style={{ width: "100%" }}>
                <Typography style={{ marginBottom: "2px" }} variant="body2">
                  Name <span style={{ color: "red" }}>*</span>
                </Typography>
                <TextValidator
                  // disabled={}
                  size="small"
                  disabled={isDisabled}
                  variant="outlined"
                  fullWidth
                  name="name"
                  value={billingData.name}
                  onChange={(e) =>
                    this.onChange({
                      ...billingData,
                      name: e.target.value.trimStart(),
                    })
                  }
                  validators={["required"]}
                  errorMessages={["Please enter name"]}
                />
              </Grid>
              <Grid item sm={3} md={3} style={{ width: "100%" }}>
                <Typography style={{ marginBottom: "2px" }} variant="body2">
                  Unit <span style={{ color: "red" }}>*</span>
                </Typography>
                <TextValidator
                  disabled={isDisabled}
                  size="small"
                  variant="outlined"
                  fullWidth
                  name="unit"
                  value={billingData.unit}
                  onChange={(e) =>
                    this.onChange({
                      ...billingData,
                      unit: e.target.value.trimStart(),
                    })
                  }
                  validators={["required"]}
                  errorMessages={["Please enter unit"]}
                />
              </Grid>
              <Grid item sm={3} md={3} style={{ width: "100%" }}>
                <Typography style={{ marginBottom: "2px" }} variant="body2">
                  Category <span style={{ color: "red" }}>*</span>
                </Typography>
                {/* <TextField
                  size="small"
                  variant="outlined"
                  fullWidth
                  name="authDescription"
                  value={billingData.category}
                  onChange={(e) =>
                    this.onChange({
                      ...billingData,
                      category: e.target.value,
                    })
                  }
                /> */}
                <AddCategoryBilling
                  billingData={billingData}
                  onChange={this.onChange}
                  addCategory={this.addCategory}
                  defaultCategory={this.state.category}
                  isEdit={false}
                  obj={autoCompleteVal}
                  isDisabled={this.state.isDisabled}
                />
              </Grid>
              <Grid item sm={3} md={3}>
                <Typography style={{ marginBottom: "2px" }} variant="body2">
                  Description
                </Typography>
                <TextField
                  disabled={isDisabled}
                  size="small"
                  multiline
                  rows={7}
                  variant="outlined"
                  fullWidth
                  name="authDescription"
                  value={billingData.description}
                  onChange={(e) =>
                    this.onChange({
                      ...billingData,
                      description: e.target.value,
                    })
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <HelpdeskFAB category="Billable Services" />
        </ValidatorForm>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    editdata: state.billingMaster.editdata,
    loading: state.billingMaster.loading,
    adddata: state.billingMaster.adddata,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    editBillableServices: (billableId, payload) =>
      dispatch(actions.editBillableServices(billableId, payload)),
    addBillableServices: (payload) =>
      dispatch(actions.addBillableServices(payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(EditBillableservice));
