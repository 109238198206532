import React, { useEffect, useState } from "react";
import { Typography, Grid, TextField, Button, Link } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Modal from "./Modal";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import withSection from "components/Section/Section";
import { checkPermission } from "../../helpers/permissons";
const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5),
    borderRadius: "14px",
    color: "inherit",
  },
}));

const Organisation = (props) => {
  const { details, isEdit, isDisabled, permission } = props;
  const [openmodal, setOpen] = React.useState(false);
  const classes = useStyles();

  //   let gst = details["GST/Tax_registration_number"];
  const handleOpenModal = () => {
    setOpen(true);
    props.searchRole({});
  };
  const handleCloseModal = () => {
    setOpen(false);
  };
  const onsubmit = (roles, type) => {
    // let data = roles.filter((item, id) => {
    //   return item !== undefined;
    // });
    props.handleUsersRoles(roles, type);
    setOpen(false);
  };

  return (
    <>
      <Grid container spacing={3}>
        {isDisabled ? null : (
          <>
            <Grid item sm={11}>
              <Typography variant="body2">
                Assign Roles <span style={{ color: "red" }}>*</span>
              </Typography>
            </Grid>
            <Grid item sm={1}>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                alignItems="center"
                disabled={
                  isEdit ? !checkPermission("btn53") : !checkPermission("btn51")
                }
                // onClick={() => createUser()}
                onClick={() => handleOpenModal()}
                startIcon={<AddIcon />}
              >
                Add
              </Button>
            </Grid>
          </>
        )}
        <Grid item md={12}>
          {props.rolesMap.map((item, id) => {
            return (
              <>
                {item && (
                  <Chip
                    label={item.name}
                    onDelete={
                      !isDisabled
                        ? () => props.handleDelete(id, item._id)
                        : null
                    }
                    variant="outlined"
                    color="inherit"
                    className={classes.chip}
                  />
                )}
              </>
            );
          })}
        </Grid>
      </Grid>
      <div>
        <Modal
          openmodal={openmodal}
          onsubmit={onsubmit}
          handleCloseModal={handleCloseModal}
          // property={property}
          // classes={classes}
          // Modaldata={Modaldata}
          // Appdata={Appdata}
          {...props}
        />
      </div>
    </>
  );
};
export default withSection(Organisation, "User Roles");
