export const FETCH_HELPDESKDATA = "FETCH_HELPDESKDATA";
export const FETCH_HELPDESKDATA_SUCCESS = "FETCH_HELPDESKDATA_SUCCESS";
export const FETCH_HELPDESKDATA_FAILURE = "FETCH_HELPDESKDATA_FAILURE";

export const DELETE_HELPDESKDATA = "DELETE_HELPDESKDATA";
export const DELETE_HELPDESKDATA_SUCCESS = "DELETE_HELPDESKDATA_SUCCESS";
export const DELETE_HELPDESKDATA_FAILURE = "DELETE_HELPDESKDATA_FAILURE";

export const UPDATE_HELPDESK_FILTER_DATA = "UPDATE_HELPDESK_FILTER_DATA";
export const CLEAR_HELPDESK_FILTER_DATA = "CLEAR_HELPDESK_FILTER_DATA";

export const UPDATE_HELPDESK_SORT = "UPDATE_HELPDESK_SORT";

export const UPDATE_HELPDESK_HIDDEN_COL = "UPDATE_HELPDESK_HIDDEN_COL";
