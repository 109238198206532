import { React, useEffect } from "react";
// import ToggleButton from "@material-ui/core/ToggleButton";
import {
  CardContent,
  FormGroup,
  Card,
  Grid,
  Button,
  Popover,
  FormControl,
  TextField,
  Typography,
  Tooltip,
  FormLabel,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CustomIcon from "../../../components/CustomIcon/Icon";
import Checkbox from "@material-ui/core/Checkbox";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import withSection from "components/Section/Section";
import { TextValidator } from "react-material-ui-form-validator";
import { RiDeleteBin5Line, RiEditLine } from "react-icons/ri";
import DexTable from "components/DexTable/DexTable";
import WrappedDexTable from "components/DexTable/WrappedDexTable";
import { checkPermission } from "helpers/permissons";
import { confirmDialog } from "components/DeleteConfirmationDialog/Index";
import { dltMsg } from "helpers/helpers";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import { ApplicationOnboardUpadateHiddenCol } from "../ApplicationDetails/Redux/action";
const useStyles = makeStyles((theme) => ({
  line: {
    marginTop: -23,
  },
  para: {
    padding: "30px 0px 0px 130px",
  },
  btn: {
    marginTop: "-10px",
    backgroundColor: "white",
  },
  radiobtn: {
    marginRight: "50px",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  rtsubdiv: {
    marginLeft: "0",

    [theme.breakpoints.up("md")]: {
      margin: "20px 0% 0px 80px",
    },
  },
  maindiv: {
    padding: "0",
    [theme.breakpoints.up("md")]: {
      padding: "0px 0px 10px 30px",
    },
  },
  dividerline: {
    width: "80%",
    margin: "-25px 0px 0px 80px",
    [theme.breakpoints.up("md")]: {
      width: "91%",
      margin: "-25px 0px 0px 125px",
    },
  },
  deleteButton: {
    width: "10%",
    [theme.breakpoints.only("sm")]: {
      width: "8%",
    },
    [theme.breakpoints.up("md")]: {
      width: "4%",
    },
  },
  addButton: {
    width: "14%",
    marginRight: "4px",
    [theme.breakpoints.only("sm")]: {
      marginRight: "5px",
      width: "8%",
    },
    [theme.breakpoints.up("md")]: {
      marginRight: "3px",
      width: "4%",
    },
  },
  radioGroup: {
    marginTop: "10px",
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
  },
  radio: {
    display: "flex",
    flexDirection: "row",
  },
}));

function Environment(props) {
  let error = false;
  const {
    data,
    setEnvironment,
    environMent,
    errValue,
    handleSetColor,
    permission,
    onBoardTable,
  } = props;
  const { environment } = onBoardTable;
  const classes = useStyles();

  useEffect(() => {
    if (props.environmentsData) {
      setEnvironment([...props.environmentsData]);
    }
  }, [props.environmentsData, setEnvironment]);

  const pushToEnvironmentForm = () => {
    let data = {
      name: "",
      domainUrl: "",
      isProductionEnvironment: false,
      isHealthCheckEnable: false,
      healthCheckUrl: "",
      method: "POST",
      key: props.environmentsData.length || 0,
    };
    props.history.push("/app/create-env-form", {
      environment_data: data,
      edit: false,
    });
  };

  useEffect(() => {
    handleSetColor(errValue.env ? "red" : "inherit");
  }, [handleSetColor, errValue]);

  const editEnvironment = ({ row }) => {
    props.history.push("/app/create-env-form", {
      environment_data: row,
      edit: true,
    });
  };

  const removeEnvironmentData = async ({ row }) => {
    let data = [...props.environmentsData];
    let filteredData = data.filter((item, index) => {
      return item.key !== row.key;
    });
    await props.deleteENV(filteredData);
  };

  const actionProvider = (row) => {
    console.log(row, "3436436");
    return (
      <div
        style={{
          display: "flex",
        }}
      >
        <div style={{ marginRight: 20 }}>
          <Tooltip title="Edit" aria-label="edit">
            <IconButton
              size="small"
              color="inherit"
              disabled={!checkPermission("btn78")}
              onClick={() => checkPermission("btn78") && editEnvironment(row)}
            >
              <RiEditLine fontSize="20px" />
            </IconButton>
          </Tooltip>
        </div>
        <div>
          <Tooltip title="Delete" aria-label="delete">
            <IconButton
              size="small"
              color="inherit"
              disabled={!checkPermission("btn84")}
              onClick={() =>
                checkPermission("btn84") &&
                confirmDialog(dltMsg(row?.row?.name || ""), () =>
                  removeEnvironmentData(row)
                )
              }
            >
              <RiDeleteBin5Line fontSize="20px" />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    );
  };
  const markAsProduction = ({ row }) => {
    return (
      <Typography variant="body2">
        {" "}
        {row.isProductionEnvironment ? "Yes" : "No"}
      </Typography>
    );
  };
  return (
    <div>
      <Grid container spacing={2}>
        <Grid xs={12} item container justifyContent="flex-end">
          <Button
            size="small"
            variant="contained"
            onClick={() => checkPermission("btn72") && pushToEnvironmentForm()}
            startIcon={<AddIcon />}
            style={{ padding: "4px 30px 4px 30px" }}
            disabled={!checkPermission("btn72")}
          >
            Create Environment
          </Button>
        </Grid>
        <Grid item xs={12}>
          <WrappedDexTable
            data={[...environMent]}
            // hideColumnChooser={true}
            columWidthsType="percentage"
            columnOrder={[
              "name",
              "domainUrl",
              "isProductionEnvironment",
              "actions",
            ]}
            columns={[
              { name: "name", title: "Environment Name" },
              { name: "domainUrl", title: "Domain URL" },
              {
                name: "isProductionEnvironment",
                title: "Mark as production ",
              },
              { name: "actions", title: "Actions" },
            ]}
            formatters={[
              { formatterFn: actionProvider, columns: ["actions"] },
              {
                formatterFn: markAsProduction,
                columns: ["isProductionEnvironment"],
              },
            ]}
            defaultColumnWidths={[
              { columnName: "name", width: 19 },
              { columnName: "domainUrl", width: 25 },
              { columnName: "isProductionEnvironment", width: 40 },
              { columnName: "actions", width: 14 },
            ]}
            hidden={[...environment]}
            setHiddenColmn={(hide) => {
              props.setHiddenColumn(hide, "environment");
            }}
            pagination={false}
            noDataMsg="Environment not found"
            Read={checkPermission("btn217")}
          />
        </Grid>
      </Grid>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setHiddenColumn: (hide, type) =>
    dispatch(ApplicationOnboardUpadateHiddenCol(hide, type)),
});

const mapStateToProps = (state) => {
  return {
    onBoardTable: state.appDetailsRedcer.onBoardTable,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withSection(Environment, "Environment *")));
