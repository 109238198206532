const Styles = (theme) => ({
  title: {
    textTransform: "capitalize",
    fontWeight: "bold",
    justifyContent: "center",
    paddingBottom:"0px"
  },
  divider: {
    marginTop: "12px",
    height: "2px",
    background: "rgb(175, 175, 175)",
  },
  modalDropzone: {
    paddingRight:"10px",
    paddingLeft:"10px",
    "& .documentDropperClass": {
      paddingTop: "3.5rem",
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      border: `3px solid ${theme.palette.primary.main}`,
      borderRadius: 10,
      textAlign: "center",
      font: "normal normal 600 16px/22px Nunito",
      color: `${theme.palette.primary.main}`,

      "& .MuiDropzoneArea-textContainer": {
        display: "flex",
        flexDirection: "column-reverse",
        alignItems: "center",
        justifyContent: "center",

        "& .MuiDropzoneArea-icon": {
          color: `${theme.palette.primary.main}`,
          transform: "scale(1.4)",
        },

        "& .documentDropperTextClass": {
          color: `${theme.palette.primary.main}`,
          font: "normal normal 600 16px/22px Nunito",
        },
      },
    },
  },
})


export default Styles;