import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  Divider,
  Button,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import React from "react";
import FileUpload from "../../components/FileUpload/FileUpload";
import Style from "./styles";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import InputPayload from "./InputPayloadTable";
import CDUMasterAPIs from "../../api/CduMaster/index";
import ApplicationAPIs from "../../api/ApplicationAPIs/index";
import { withSnackbar } from "notistack";
import { DropzoneArea } from "material-ui-dropzone";
import Breadcrumb from "components/Breadcrumbs/Breadcrumb";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Styles = (theme) => ({
  dropzoneStyle: {
    minHeight: "130px",
    width: "100%",
  },
  dropzoneParagraphClass: {
    fontSize: "15px",
    padding: "1%",
  },
});

class Mcds extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      clicked: true,
      userUploadedJson: "",
      uploadefile: [],
      inputFile: [],
      appId: "",
      apiDetails: {},
      cdu: [],
      currentTab: 0,
      cduMapping_input: [],
      cduMapping_output: [],
      isReferenceMapEnabed: false,
      cdu:[]
    };
  }

  toggleTabs = (e, tab) => {
    console.log(tab);
    this.setState({
      currentTab: tab,
      cduMapping_input: [...this.state.cduMapping_input],
      cduMapping_output: [...this.state.cduMapping_output],
    });
  };

  // function to manage upload files
  updateInputUploadedFiles = (files) => {
    if (files.length == 0) return;
    try {
      var reader = new FileReader();
      reader.onload = (e) => {
        console.log(reader.result);
        let data = reader.result;
        let parseData = data.split("\r\n").map((f) => f.split(","));
        let header = parseData.shift(1);
        let cduValues = [];
        parseData.forEach((f) => {
          let cduValue = {};
          cduValue[header[0]] = f[0];
          cduValue[header[1]] = f[1];
          cduValues.push(cduValue);
        });
        this.setState({ cduMapping_input: cduValues });
        console.log(parseData, cduValues);
      };
      reader.readAsText(files[0]);
      this.setState({ ...this.state, inputFile: [...files] });
    } catch {}
  };
  updateOutputUploadedFiles = (files) => {
    if (files.length == 0) return;
    try {
      var reader = new FileReader();
      reader.onload = (e) => {
        console.log(reader.result);
        let data = reader.result;
        let parseData = data.split("\r\n").map((f) => f.split(","));
        let header = parseData.shift(1);
        let cduValues = [];
        parseData.forEach((f) => {
          let cduValue = {};
          cduValue[header[0]] = f[0];
          cduValue[header[1]] = f[1];
          cduValues.push(cduValue);
        });
        this.setState({ cduMapping_output: cduValues });
        console.log(parseData, cduValues);
      };
      reader.readAsText(files[0]);
      this.setState({ ...this.state, outputFile: [...files] });
    } catch {}
  };

  handleCancel = () => {
    this.props.history.push("/app/detail", { appId: this.state.appId });
  };

  componentDidMount = async () => {
    const { getDetails, location } = this.props;
    if (location.state != undefined) {
      let appId = location.state.appId;
      let apiDetails = location.state.apiDetails;
      let appName = location.state.appName;
      let cduMapping_input = location.state.cduMapping_input || [{}];
      let cduMapping_output = location.state.cduMapping_output || [{}];
      await this.setState({
        appName,
        appId,
        apiDetails,
        cduMapping_input,
        cduMapping_output,
      });
      this.isReferenceEnabled();
      await this.getCDUmasterDetails();
    } else {
      this.props.history.push("/app/applications");
    }
  };

  handleInputCDUvalues = async (type, rows) => {
    if (type == "input") {
      await this.setState({ cduMapping_input: [...rows] });
      this.isReferenceEnabled();
    } else {
      await this.setState({ cduMapping_output: [...rows] });
      this.isReferenceEnabled();
    }
  };

  getCDUmasterDetails = async () => {
    try {
      let res = await CDUMasterAPIs.fetchCDUmaster();
      console.log(res);
      this.setState({ cdu: res });
    } catch (err) {
      console.error(err);
    }
  };

  handlevaluemap = async () => {
    if (this.state.isReferenceMapEnabed) {
      this.props.history.push("/app/valuemap", {
        cduMapping_input: this.state.cduMapping_input,
        cduMapping_output: this.state.cduMapping_output,
        appName: this.state.appName,
        appId: this.state.appId,
        apiDetails: this.state.apiDetails,
      });
    } else {
      await this.saveCDUValues();
    }
  };

  saveCDUValues = async () => {
    let payload = {};
    payload.req = this.state.cduMapping_input;
    payload.res = this.state.cduMapping_output;
    const { getDetails } = this.props;
    try {
      let p = { ...payload };
      const res = await ApplicationAPIs.editApplicationAPI(
        this.state.appId,
        this.state.apiDetails._id,
      );
      this.props.enqueueSnackbar(
        res.data._msg || "API details saved successfully",
        { variant: "success", autoHideDuration: 3000 }
      );
      this.props.history.push("/app/detail", { appId: this.state.appId });
    } catch (err) {
      console.log(err);
      this.props.enqueueSnackbar(err.message || "Something Went wrong", {
        variant: "error",
        autoHideDuration: 3000,
      });
      console.error(err);
    }
  };

  onChange = (e) => {
    console.log(e.target.files[0]);
    let reader = new FileReader();
    reader.onload = (e) => {
      let base64Data = reader.result;
      const database64 = base64Data.split(";base64,")[1];
      let buff = new Buffer(database64, "base64");
      let base64ToStringNew = buff.toString("ascii");
      let jsonObj = JSON.parse(base64ToStringNew);
      this.setState({ userUploadedJson: jsonObj });
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  isReferenceEnabled = () => {
    let filteredInput = this.state.cduMapping_input.filter((f) => f.valueMap);
    let filteredOutput = this.state.cduMapping_output.filter((f) => f.valueMap);
    let isReferenceMapEnabed =
      filteredInput.length !== 0 || filteredOutput.length != 0;
    this.setState({ isReferenceMapEnabed });
  };

  handleRemove = (index) => {
    console.log("delete the row number ", index);
    if (this.state.currentTab == 0) {
      let newDataKeys = this.state.cduMapping_input.filter((f, i) => {
        return i !== index;
      });
      this.setState({ cduMapping_input: newDataKeys });
    } else {
      let newDataKeys = this.state.cduMapping_output.filter((f, i) => {
        return i !== index;
      });
      this.setState({ cduMapping_output: newDataKeys });
    }
  };

  render() {
    const { props, state } = this;
    const { userUploadedJson } = state;
    const { classes } = props;
    console.log(this.state, "sdfs");
    return (
      <div style={{ padding: "10px 0px 0px 0px" }}>
        <Grid container spacing={1}>
          <Breadcrumb
            {...this.props}
            links={[
              { name: "Applications", url: "/app/applications" },
              {
                name: this.state.appName,
                url: "/app/detail",
                routeParams: { appId: this.state.appId },
              },
              {
                name: `${this.state.apiDetails.name} Field Mapping`,
                url: "/app/map-cdu",
                routeParams: this.props.location.state,
              },
            ]}
          />
          <Grid container item xs={12} md={12} justify="space-between">
            <div>
              <Typography variant="h6">
                <span style={{ fontWeight: "semibold" }}>
                  {/* {this.state.apiDetails.name || ""}{" "} */}
                </span>
                {/* - Common Data Field mapping */}
                {this.state.clicked
                  ? "Passive to Active metadata mapping"
                  : "Active to Passive metadata mapping"}
              </Typography>
            </div>
            <div>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    style={{ marginTop: "2px", padding: "5px 26px" }}
                    onClick={this.handleCancel}
                  >
                    {" "}
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    style={{ color: "white", padding: "5px 26px" }}
                    onClick={this.handlevaluemap}
                  >
                    {this.state.isReferenceMapEnabed
                      ? "Go to value map"
                      : "Save"}
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid
            container
            spacing={1}
            md={12}
            xs={12}
            style={{ marginTop: "15px" }}
          >
            <Grid container item xs={12} sm={2} md={3}>
              <Card style={{ width: "100%", minHeight: "70vh" }}>
                <CardHeader
                  alignItems="center"
                  titleTypographyProps={{ variant: "subtitle1" }}
                  title="Upload Json schema"
                  action={
                    <Button
                      style={{ marginTop: "10%" }}
                      size="small"
                      color="primary"
                      variant="outlined"
                    >
                      {" "}
                      AI proposal
                    </Button>
                  }
                ></CardHeader>
                <Divider style={{ margin: "1%" }} />
                <CardContent>
                  {this.state.currentTab == 0 ? (
                    <DropzoneArea
                      key={0}
                      style={{ width: "100%" }}
                      acceptedFiles={[".csv"]}
                      filesLimit={1}
                      clearOnUnmount={true}
                      initialFiles={this.state.inputFile}
                      onChange={this.updateInputUploadedFiles}
                      onDelete={() => {
                        this.setState({ cduMapping_input: [] });
                      }}
                      // showPreviews={true}
                      showPreviewsInDropzone={true}
                      useChipsForPreview
                      dropzoneParagraphClass="MuiTypography-body1"
                      showAlerts={false}
                      dropzoneText="Upload Passive to Active metadata mapping file"
                      dropzoneParagraphClass={classes.dropzoneParagraphClass}
                      dropzoneClass={classes.dropzoneStyle}
                      // dropzoneProps = {{disabled:this.state.inputFile.length ==0}}
                      // previewGridProps={{container: { spacing: 1, direction: 'row' }}}
                      // previewChipProps={{classes: { root: classes.previewChip } }}
                      // previewText="Selected files"
                    />
                  ) : (
                    <DropzoneArea
                      key={1}
                      acceptedFiles={[".csv"]}
                      style={{ width: "100%" }}
                      filesLimit={1}
                      clearOnUnmount={true}
                      initialFiles={this.state.outputFile}
                      onChange={this.updateOutputUploadedFiles}
                      onDelete={() => {
                        this.setState({ cduMapping_output: [] });
                      }}
                      dropzoneParagraphClass="MuiTypography-body1"
                      showPreviewsInDropzone={true}
                      useChipsForPreview
                      showAlerts={false}
                      dropzoneText="Upload Active to Passive metadata mapping file"
                      dropzoneParagraphClass={classes.dropzoneParagraphClass}
                      dropzoneClass={classes.dropzoneStyle}
                    />
                  )}
                </CardContent>
              </Card>
            </Grid>
            <Grid container item xs={12} sm={10} md={9}>
              <Card style={{ minHeight: "70vh", width: "100%" }}>
                <CardHeader
                  style={{ paddingTop: "1%" }}
                  title={
                    <AppBar
                      position="static"
                      style={{
                        backgroundColor: "transparent",
                        borderBottom: "1px solid #e8e8e8",
                        boxShadow: "none",
                        color: "gray",
                      }}
                    >
                      <Tabs
                        value={this.state.currentTab}
                        onChange={this.toggleTabs}
                        aria-label="simple tabs example"
                      >
                        <Tab
                          onClick={() => this.setState({ clicked: true })}
                          style={{ textTransform: "capitalize" }}
                          label={
                            <Typography variant="subtitle1">
                              Input Payload
                            </Typography>
                          }
                          {...a11yProps(0)}
                        />
                        <Tab
                          onClick={() => this.setState({ clicked: false })}
                          style={{ textTransform: "capitalize" }}
                          label={
                            <Typography variant="subtitle1">
                              Output Payload
                            </Typography>
                          }
                          {...a11yProps(1)}
                        />
                      </Tabs>
                    </AppBar>
                  }
                />
                <CardContent style={{ padding: "0px" }}>
                  <TabPanel
                    value={this.state.currentTab}
                    index={0}
                    style={{ padding: "0px" }}
                  >
                    <InputPayload
                      active={this.state.currentTab == 0}
                      values={[...this.state.cduMapping_input]}
                      cduValues={[...this.state.cdu]}
                      handleInputCDUvalues={this.handleInputCDUvalues}
                      type="input"
                      handleRemove={this.handleRemove}
                    />
                  </TabPanel>
                  <TabPanel value={this.state.currentTab} index={1}>
                    <InputPayload
                      active={this.state.currentTab == 1}
                      values={[...this.state.cduMapping_output]}
                      cduValues={[...this.state.cdu]}
                      handleInputCDUvalues={this.handleInputCDUvalues}
                      type="output"
                      handleRemove={this.handleRemove}
                    />
                  </TabPanel>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}
export default withStyles(Styles)(withSnackbar(Mcds));
