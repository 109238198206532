import * as types from "./actionTypes";
import APIS from "api/UserManagementAPIs/index";
import TeamAPI from "../../../api/TeamsAPIs/index";
export const getAllUsers = (filter) => async (dispatch) => {
  try {
    let res = await APIS.fetchAllUserManagementWithParams(
      undefined,
      undefined,
      JSON.stringify(filter),
      undefined
    );
    console.log(res.data, "6586587687");
    dispatch({
      type: types.FETCH_USERS,
      loading: true,
      payload: res.data || [],
    });
  } catch (err) {
    dispatch({
      type: types.FETCH_USERS_FAILED,
      loading: false,
    });
  }
};

export const add_team = (payload) => async () => {
  payload.users = payload.users
    .filter((item, id) => {
      return item !== undefined && item !== null;
    })
    .map((val, i) => {
      return val.id;
    });
  try {
    const res = await TeamAPI.addTeam(payload);
    return res;
  } catch (err) {
    return err;
  }
};

export const update_team_member = (type, teamId, payload) => async () => {
  let users = payload
    .filter((item, id) => {
      return item !== undefined && item !== null;
    })
    .map((val, i) => {
      return val._id;
    });
  console.log(type, payload, users, teamId, 1, "updated users");
  try {
    let res;
    if (type) {
      res = await TeamAPI.updateMember(type, teamId, {
        users: [...users],
      });
    } else {
      res = await TeamAPI.deleteTeamMember(teamId, {
        users: [...users],
      });
    }
    return res;
  } catch (err) {
    return err;
  }
};

export const loadingDispatch = (bool) => async (dispatch) => {
  dispatch({
    type: types.LOAD_LOADING,
    payload: bool,
  });
};
