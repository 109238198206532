import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FormControl, Select, InputLabel, FormLabel } from "@material-ui/core";
import { Options } from "devextreme-react/autocomplete";

// export const NativeDropdown = ({
//   variant,
//   value,
//   handleChange,
//   CustomOptions,
// }) => {
//   return (
//     <FormControl variant={variant} fullWidth>
//       <Select
//         native
//         value={value}
//         onChange={handleChange}
//         label="Age"
//         inputProps={{
//           name: "age",
//           id: "outlined-age-native-simple",
//         }}
//       >
//         <option aria-label="None" value="" />
//         {<CustomOptions />}
//       </Select>
//     </FormControl>
//   );
// };

const useStyles = makeStyles((theme) => ({
  // formControl: {
  //   margin: theme.spacing(1),
  // },
  // selectEmpty: {
  //   marginTop: theme.spacing(2),
  // },
}));
export const CustomDropDown = (props) => {
  const { values, info, rowsArr, label, className, name, handleChange } = props;
  const classes = useStyles();
  return (
    <div>
      <FormLabel>{label}</FormLabel>
      <FormControl
        hiddenLabel
        fullWidth
        size="small"
        variant="outlined"
        className={classes.formControl}
      >
        <Select
          native
          onChange={(e) => handleChange(e)}
          name={name}
          inputProps={{
            className: { className },
          }}
          value={info}
        >
          <option value="">- select - </option>
          {values.map((item, id) => (
            <option
              disabled={
                rowsArr.length !== 0 &&
                rowsArr.findIndex((itm) => itm.columns === item.value) !== -1
              }
              value={item.value}
            >
              {item.value}
            </option>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export const FilterDropdown = (props) => {
  const {
    values,
    label,
    className,
    id,
    name,
    handleChange,
    component,
    required,
    msg,
    info,
  } = props;

  const classes = useStyles();
  return (
    <div>
      <div>
        <FormLabel>{label}</FormLabel>
        <FormControl
          hiddenLabel
          fullWidth
          size="small"
          variant="outlined"
          className={classes.formControl}
        >
          <Select
            native
            value={info}
            onChange={(e) => handleChange(e)}
            name={name}
            inputProps={{
              className: { className },
            }}
          >
            <option value="">- select - </option>
            {values.map((item, id) => (
              <option value={item.value}>{item.value}</option>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );
};

const DropDown = (props) => {
  const {
    values,
    label,
    className,
    id,
    name,
    handleChange,
    component,
    required,
    msg,
  } = props;
  const classes = useStyles();

  return (
    <div>
      {!component ? (
        <div>
          <FormLabel>{label}</FormLabel>
          <FormControl
            hiddenLabel
            fullWidth
            size="small"
            variant="outlined"
            className={classes.formControl}
          >
            <Select
              native
              onChange={(e) => handleChange(e)}
              name={name}
              inputProps={{
                className: { className },
              }}
            >
              <option value="">- select - </option>
              {values.map((item, id) => (
                <option value={item.value}>{item.value}</option>
              ))}
            </Select>
          </FormControl>
        </div>
      ) : (
        <div>
          <FormControl
            required={required}
            hiddenLabel
            fullWidth
            size="small"
            variant="outlined"
            className={classes.formControl}
          >
            <InputLabel>{label}</InputLabel>
            <Select
              label={label}
              native
              onChange={(e) => handleChange(e)}
              name={name}
              inputProps={{
                className: { className },
              }}
            >
              <option value={""}>{""}</option>
              {values.map((item, id) => (
                <option key={id} value={item.value}>
                  {item.value}
                </option>
              ))}
            </Select>
          </FormControl>
        </div>
      )}
    </div>
  );
};
export default DropDown;
