import React, { useState } from "react";
import { TextField,FormLabel,IconButton,InputAdornment,Typography,Button} from '@material-ui/core';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
const ResetPassword = (props)=>{
    const {classes,history}=props;
    console.log(classes,"other props");
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    return(<>

             <Typography
            variant="h6"
            className={classes.loginText}
            color="primary"
            >
            Reset your password
          </Typography>
          <form onSubmit={()=>history.push('/login') }>
            <FormLabel >Password</FormLabel>
            <TextField
              size="small"
              placeholder=""
              variant="outlined"
              className={classes.margin}
              required
              fullWidth
              id="email"
              name="username"
            //   onChange={(e) => getTextFieldValue(e)}
            />
            <FormLabel>Confirm Password</FormLabel>
            <TextField
              size="small"
              variant="outlined"
              // margin="normal"
              required
              fullWidth
              name="password"
            //   onChange={(e) => getTextFieldValue(e)}
              type={showPassword ? "text" : "password"}
              id="password"
              InputProps={{
                // <-- This is where the toggle button is added.
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      style={{ width: "40px", height: "40px" }}
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            
            >
              Reset Password
            </Button>
              </form>
    </>)
}
export default ResetPassword;