import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import {} from "../redux/action";
import { useSnackbar } from "notistack";
import {
  Button,
  Grid,
  TextField,
  Typography,
  Divider,
  FormHelperText,
  FormControl,
} from "@material-ui/core";
import { withSnackbar } from "notistack";
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator";
import contextAPIs from "../../../../api/Context/index";
import Styles from "./Styles";
import { withStyles } from "@material-ui/styles";
import Breadcrumb from "components/Breadcrumbs/Breadcrumb";
import { getApplicationDetails, updateApplicationAPIS } from "../Redux/action";
import { isDuplicate } from "../../../../helpers/helpers";
import APIs from "../../../../api/ApplicationAPIs/index";
import Outgoingcdumap from "./CduMap";
import Outgoingvaluemap from "./Outgoingvaluemap";
import { RiEditLine } from "react-icons/ri";
import { checkPermission } from "helpers/permissons";
import HelpdeskFAB from "../../../../views/HelpdeskFAB/HelpdeskFab";
import SchedulingComponent from "./Scheduling";
import { MessageBrokerCompo } from "./MessageBrokerApi";
import ApplicationAPIs from "../../../../api/ApplicationAPIs/index";
import ContextApi from "../../../../api/Context/index";
const style = {
  pointerEvents: "none",
  opacity: "0.7",
};
const initialState = {
  name: "",
  url: "",
  outgoingAppId: "",
  outgoingApiId: "",
};
class CreateOutgoingForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      apiType: null,
      application: [],
      ID: 0,
      check_name: "",
      err_msg: "",
      error: false,
      outgoing: {
        ...initialState,
      },
      isEdit: false,
      index: 0,
      ajax: false,
      apis: [],
      allPublishedApp: [],
      isDisabled: false,
      res: {},
      req: {},
      context: {},
    };
  }

  fetchPublishedApp = async (app_id) => {
    let status = JSON.stringify({ status: "Published" });

    try {
      const res = await ApplicationAPIs.getApplicationsByStatus(status);
      let allPubApp = res?.data?.data || [];
      allPubApp = allPubApp.filter((row) => row._id !== app_id);
      this.setState({
        allPublishedApp: allPubApp,
      });
    } catch (err) {
      this.setState({
        allPublishedApp: [],
      });
    }
  };

  async componentDidMount() {
    const { location } = this.props;
    if (location.state !== undefined) {
      let { edit, apiData, appID, disabled, from, api_Type, appName, ajax } =
        location.state;
      console.log("location state outgoing data", apiData, appName);

      if (from === "dataFabric-cdutoapi") {
        let { mapping } = location.state;
        // this.setState({ req: { ...mapping }, res: res });
        this.setState({
          outgoing: {
            ...apiData,
            res: {
              ...mapping,
            },
          },
          apiType: api_Type || null,
        });
      } else if (from === "dataFabric-apitocdu") {
        let { mapping } = location.state;
        // this.setState({ res: mapping, req: req });
        this.setState({
          outgoing: {
            ...apiData,
            req: {
              ...mapping,
            },
          },
          apiType: api_Type || null,
        });
      } else {
        if (edit) {
          const {
            url,
            industryType,
            name,
            outgoingApiId,
            outgoingApiName,
            outgoingAppId,
            req,
            res,
            _id,
            messageBroker,
            contexts,
            ...otherData
          } = apiData;
          this.setState({
            outgoing: {
              url,
              industryType,
              name,
              outgoingApiId,
              outgoingApiName,
              outgoingAppId,
              _id,
              req:
                contexts.req && Object.keys(contexts.req).length !== 0
                  ? contexts.req
                  : null || null,
              res:
                contexts.res && Object.keys(contexts.res).length !== 0
                  ? contexts.res
                  : null || null,
              schedule: otherData.schedule || {},
              source: otherData.source || {},
              messageBroker,
              api_method: apiData.api_method,
            },
            apiType: apiData.apiType,
          });
        }
      }
      this.setState({
        isEdit: edit,
        ID: appID,
        isDisabled: disabled,

        ajax: ajax,

        appName: appName,
      });
      this.fetchPublishedApp(appID);
      edit && this.getOutgoingApiId(apiData.outgoingAppId);
    } else {
      this.history.push("app/applications");
    }
    //
  }
  displayMsg = (msg, type) => {
    this.props.enqueueSnackbar(msg, {
      variant: type ? "success" : "error",
      autoHideDuration: 3000,
    });
  };

  getOutgoingApiId = async (apiId) => {
    try {
      let res = await APIs.getApplicationsApi(apiId);
      console.log(res.data.data, "api testssssssssssssssssssss", 2323);
      this.props.updateAPIS(res.data.data || []);
      this.setState({
        apis: [...(res.data.data || [])],
      });
    } catch (err) {
      const msg = err?.response?.data?._msg || "Something Went wrong";
      this.displayMsg(msg, false);
    }
  };

  addResReqToOutgoing = (item, other_data, res, req) => {
    let request = null;
    let response = null;
    if (res) {
      response = {
        ...res,
        api_nodes: [],
        nodes: [],
        edges: [],
        valueMapping: [],
      };
    }
    if (req) {
      request = {
        ...req,
        api_nodes: [],
        nodes: [],
        edges: [],
        valueMapping: [],
      };
    }
    this.setState({
      outgoing: {
        ...other_data,
        outgoingApiName: item.name,
        industryType: item.industryType,
        req: request,
        res: response,
        api_method: item.method,
      },
      apiType: item.apiType,
    });
  };

  fetch_context = async (item, other_data) => {
    const { _id } = item;

    try {
      const response = await contextAPIs.fetchContextByAppIdAndApId(
        other_data.outgoingAppId,
        _id
      );
      this.setState({
        context: response || {},
      });
      const res = response?.res || item?.res || null;
      const req = response?.req || item?.req || null;
      this.addResReqToOutgoing(item, other_data, res, req);
    } catch (err) {
      this.addResReqToOutgoing(
        item,
        other_data,
        item?.res || null,
        item?.req || null
      );
    }
  };

  handleOnchange = async (value, api) => {
    const {
      schedule,
      messageBroker,
      source,
      req,
      res,
      industryType,
      ...other_data
    } = value;
    if (api && api !== "api") {
      if (value.outgoingAppId.trim().length !== 0) {
        await this.getOutgoingApiId(value.outgoingAppId);
      } else {
        this.props.updateAPIS([]);
      }
      this.setState({
        apiType: null,
        outgoing: {
          ...other_data,
          outgoingApiId: "",
          outgoingApiName: "",
        },
      });
      return;
    }
    if (api === "api") {
      this.props.apis.map((item, id) => {
        if (item._id === value.outgoingApiId) {
          this.fetch_context(item, other_data);
        }
        return 0;
      });
    } else {
      this.setState({
        outgoing: { ...value },
      });
    }
  };

  checkDuplicate = (value, data) => {
    let flag = false;
    data.map((item, id) =>
      value.name === item.name ? (flag = true) : (flag = false)
    );
    return flag;
  };

  multilineAdd = async () => {
    const { isEdit, modules } = this.state;
    if (isEdit) {
      let updateModules = [...this.props.onBoard.modules];
      let data = updateModules.map((item, index) => {
        return item.key === modules.key ? { ...modules } : { ...item };
      });
      await this.props.edit_module(data);
      this.props.history.push("/app/onboard-application", { status: true });
    } else {
      await this.props.add_module(this.state.modules);
      this.setState({
        modules: {
          name: "",
          description: "",
          key: this.props.onBoard.modules.length,
        },
        isEdit: false,
      });
    }
  };

  singleObjectSubmit = async () => {
    if (this.state.ajax) {
      console.log(this.state.modules, "23232323");
    } else {
      const { modules } = this.state;
      await this.props.add_module(modules);
      this.props.history.push("/app/onboard-application", { status: true });
    }
  };

  //ajax call func

  filterPayload = () => {
    let payload = {};
    payload = {
      timeZone: this.props.userDetails?.timeZone || "",
      type: "Caller",

      group: {
        type: "application",
        id: this.state.ID,
      },
      ...this.state.outgoing,
    };
    if (this.state.apiType === "MessageBroker") {
      payload["apiType"] = "MessageBroker";
    } else {
      payload["apiType"] = "Https";
    }
    return payload;
  };

  editOutGoingApi = async () => {
    // let { _id, ...data } = this.state.outgoing;
    const { getDetails } = this.props;
    let { _id, ...data } = this.filterPayload();
    try {
      const res = await APIs.editOutgoingAPI(this.state.ID, _id, data);
      let msg = res.data._msg || "Outgoing API details saved successfully";
      this.displayMsg(msg, true);
      await getDetails(this.state.ID);
      this.props.history.push("/app/detail", { appId: this.state.ID });
    } catch (err) {
      let msg = err?.response?.data?._msg || "Connection error!";
      this.displayMsg(msg, false);
    }
  };

  addOutgoingApi = async (push) => {
    const { getDetails } = this.props;

    try {
      let res = await APIs.addOutgoingAPI(this.state.ID, {
        ...this.filterPayload(),
      });
      const msg = res.data._msg;
      this.displayMsg(msg, true);
      getDetails(this.state.ID);
      if (push) {
        this.props.history.push("/app/detail", { appId: this.state.ID });
      } else {
        await getDetails(this.state.ID);
        this.setState({
          outgoing: {
            ...initialState,
          },
          isEdit: false,
        });
      }
    } catch (err) {
      this.setState({
        outgoing: {
          ...initialState,
        },
        apiType: null,
      });
      const msg = err?.response?.data?._msg || "Connection error!";
      this.displayMsg(msg, false);
    }
    console.log("payyyyyyyyyyyload", this.filterPayload());
  };

  ajaxFuncs = async () => {
    let { isEdit } = this.state;
    if (isEdit) {
      this.editOutGoingApi();
    } else {
      await this.addOutgoingApi(false);
    }
  };

  singleAjaxFunc = async () => {
    await this.addOutgoingApi(true);
  };

  onSubmitAjax = async (e) => {
    if (e.nativeEvent.submitter.name === "multiple") {
      this.ajaxFuncs();
    }
    if (e.nativeEvent.submitter.name === "single") {
      this.singleAjaxFunc();
    }
  };

  setDuplicateNull = () => {
    this.setState({
      error: false,
      err_msg: "",
    });
  };

  navigateToDatafabric = (type) => {
    let { outgoing, isEdit, ID, isDisabled, apiType, appName, ajax } =
      this.state;
    let flag = false;
    if (apiType === "Https" && outgoing.res && outgoing.req) {
      flag = true;
    }
    this.props.history.push("/app/data-fabric", {
      type: type,
      apiData: { ...outgoing },
      edit: isEdit,
      appID: ID,
      disabled: isDisabled,
      pageFrom: "outgoingAPI",
      api_Type: apiType,
      toggleBtn: flag,
      appName,
      ajax,
    });
  };
  render() {
    const { classes, apis } = this.props;
    // const { appName } = this.props.location.state;
    const {
      isEdit,
      error,
      err_msg,
      outgoing,
      allPublishedApp,
      ID,
      isDisabled,
      apiType,
      api_method,
      ajax,
      appName,
    } = this.state;
    console.log("propsssssssssssss", this.props);
    return (
      <div>
        <Grid item sm={12} md={12} xs={12} lg={12}>
          <Breadcrumb
            {...this.props}
            links={[
              { name: "Applications", url: "/app/applications" },
              {
                name: `${appName}`,
                url: "/app/detail",
                routeParams: { appId: ID },
              },

              {
                name: "Outgoing API",
                url: "/app/outgoing-api-form",
              },
            ]}
          />
        </Grid>
        <div>
          <ValidatorForm
            ref="form"
            onSubmit={(e) => {
              this.onSubmitAjax(e);
            }}
          >
            <Grid container spacing={0}>
              <Grid item sm={12} md={12} xs={12} lg={12}>
                <Grid
                  container
                  spacing={1}
                  direction="row"
                  alignItems="center"
                  className={classes.root}
                >
                  <Grid item md={6}>
                    <div>
                      <Typography variant="h6" className={classes.title}>
                        Outgoing API
                      </Typography>
                    </div>
                  </Grid>
                  <Grid
                    item
                    sm={6}
                    container
                    justifyContent="flex-end"
                    spacing={1}
                  >
                    <Grid item>
                      {!isDisabled && (
                        <Button
                          variant="outlined"
                          color="primary"
                          size="small"
                          onClick={() => {
                            this.props.history.push("/app/detail", {
                              appId: this.state.ID,
                            });
                          }}
                        >
                          Cancel
                        </Button>
                      )}
                    </Grid>
                    <Grid item>
                      {!isDisabled && (
                        <Button
                          color="primary"
                          size="small"
                          variant={isEdit ? "contained" : "outlined"}
                          type="submit"
                          name="multiple"
                          disabled={
                            isEdit
                              ? !checkPermission("btn125")
                              : !checkPermission("btn124")
                          }
                        >
                          {isEdit ? "Save" : "Create and Add Another"}
                        </Button>
                      )}
                    </Grid>
                    <Grid item>
                      {!isEdit && (
                        <Button
                          name="single"
                          appID
                          size="small"
                          variant="contained"
                          type="submit"
                          disabled={!checkPermission("btn123")}
                        >
                          {"Create"}
                        </Button>
                      )}
                    </Grid>
                    <Grid>
                      {isDisabled && (
                        <Button
                          size="small"
                          variant="contained"
                          onClick={() =>
                            this.setState({
                              isDisabled: false,
                            })
                          }
                          disabled={!checkPermission("btn126")}
                          startIcon={
                            <RiEditLine style={{ fontSize: "20px" }} />
                          }
                        >
                          Edit
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={12}>
                <Divider className={classes.divider} />
              </Grid>
              <Grid item md={12} style={{ marginTop: "22px" }}>
                <Grid container spacing={2}>
                  <Grid item md={3}>
                    <div style={{ width: "100%" }}>
                      <Typography
                        style={{ marginBottom: "2px" }}
                        variant="body2"
                      >
                        Name <span style={{ color: "red" }}>*</span>
                      </Typography>

                      <TextValidator
                        disabled={isDisabled}
                        style={{ width: "100%" }}
                        fullwidth
                        name="name"
                        value={outgoing.name}
                        variant="outlined"
                        onChange={(e) =>
                          this.handleOnchange({
                            ...outgoing,
                            name: e.target.value.trimStart(),
                          })
                        }
                        autoComplete="off"
                        size="small"
                        error={error}
                        onFocus={this.setDuplicateNull}
                        validators={["required"]}
                        errorMessages={["Please enter name"]}
                        helperText={err_msg}
                      />
                    </div>
                  </Grid>
                  <Grid item md={3}>
                    <div style={{ width: "100%" }}>
                      <Typography
                        style={{ marginBottom: "2px" }}
                        variant="body2"
                      >
                        Proxy URL <span style={{ color: "red" }}>*</span>
                      </Typography>

                      <TextValidator
                        disabled={isDisabled}
                        style={{ width: "100%" }}
                        fullwidth
                        name="url"
                        value={outgoing.url}
                        variant="outlined"
                        onChange={(e) =>
                          this.handleOnchange({
                            ...outgoing,
                            url: e.target.value.trimStart(),
                          })
                        }
                        autoComplete="off"
                        size="small"
                        error={error}
                        onFocus={this.setDuplicateNull}
                        validators={["required"]}
                        errorMessages={["Please enter proxy url"]}
                        helperText={err_msg}
                      />
                    </div>
                  </Grid>

                  <Grid item md={3}>
                    <div style={{ width: "100%" }}>
                      <Typography
                        style={{ marginBottom: "2px" }}
                        variant="body2"
                      >
                        {" "}
                        Applications <span style={{ color: "red" }}>*</span>
                      </Typography>
                      <FormControl error={error} fullWidth>
                        <SelectValidator
                          disabled={isEdit}
                          variant="outlined"
                          fullWidth
                          size="small"
                          SelectProps={{
                            native: true,
                          }}
                          value={outgoing.outgoingAppId}
                          onChange={(e) =>
                            this.handleOnchange(
                              {
                                ...outgoing,
                                outgoingAppId: e.target.value,
                              },
                              true
                            )
                          }
                          inputProps={{
                            name: "outgoingAppId",
                            id: "appId",
                          }}
                          onFocus={this.setDuplicateNull}
                          validators={["required"]}
                          errorMessages={["Please select application "]}
                          error={error}
                        >
                          <option aria-label="None" value="">
                            {" "}
                            -Select-
                            {"  "}
                          </option>
                          {allPublishedApp.map((item, index) => (
                            <option key={index} value={item._id}>
                              {item.name}
                            </option>
                          ))}
                        </SelectValidator>
                        {error ? (
                          <FormHelperText>
                            {error
                              ? "Billing service is already selected"
                              : null}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item md={3}>
                    <div style={{ width: "100%" }}>
                      <Typography
                        style={{ marginBottom: "2px" }}
                        variant="body2"
                      >
                        {" "}
                        API <span style={{ color: "red" }}>*</span>
                      </Typography>
                      <FormControl error={error} fullWidth>
                        <SelectValidator
                          disabled={isEdit}
                          variant="outlined"
                          fullWidth
                          size="small"
                          SelectProps={{
                            native: true,
                          }}
                          value={outgoing.outgoingApiId}
                          onChange={(e) =>
                            this.handleOnchange(
                              {
                                ...outgoing,
                                outgoingApiId: e.target.value,
                              },
                              "api"
                            )
                          }
                          inputProps={{
                            name: "outgoingApiId",
                            id: "outgoingApiId",
                          }}
                          onFocus={this.setDuplicateNull}
                          validators={["required"]}
                          errorMessages={["Please select api "]}
                          error={error}
                        >
                          <option aria-label="None" value="">
                            -Select-
                          </option>
                          {apis.map((item, index) => (
                            <option
                              key={index}
                              value={item._id}
                              aria-label="None"
                            >
                              {item.name}
                            </option>
                          ))}
                        </SelectValidator>
                        {error ? (
                          <FormHelperText>
                            {error
                              ? "Billing service is already selected"
                              : null}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={12}>
                {apiType === "MessageBroker" && (
                  <MessageBrokerCompo
                    api={outgoing}
                    handleOnchange={this.handleOnchange}
                    isDisabled={isDisabled}
                    {...this.props}
                  />
                )}
              </Grid>
              <Grid item md={12}>
                {apiType === "MessageBroker" && (
                  <SchedulingComponent
                    outgoing={outgoing}
                    isDisabled={isDisabled}
                    handleOnchange={this.handleOnchange}
                    {...this.props}
                  />
                )}
              </Grid>
            </Grid>
            <div className={classes.div}></div>
            <Grid container>
              <Grid item md={12}>
                {outgoing.industryType && (
                  <div className={classes.cdu}>
                    <Typography variant="h5">
                      API - Outgoing Active Metadata mapping
                    </Typography>
                  </div>
                )}
                {outgoing.industryType && (
                  <Divider
                    style={{
                      width: "100%",
                      marginTop: "1%",
                    }}
                  />
                )}
                <Grid container spacing={1} style={{ marginTop: 5 }}>
                  <Grid item md={4}>
                    {outgoing.industryType && (
                      <div>
                        {apiType === "Https" && outgoing.req && (
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => {
                              this.navigateToDatafabric("apitocdu");
                            }}
                            style={{ marginRight: 10 }}
                          >
                            Input Mapping
                          </Button>
                        )}
                        {apiType === "Https" && outgoing.res && (
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => {
                              this.navigateToDatafabric("cdutoapi");
                            }}
                          >
                            Output Mapping
                          </Button>
                        )}
                        {outgoing.api_method === "subscribe" && outgoing.req && (
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => {
                              this.navigateToDatafabric("apitocdu");
                            }}
                            style={{ marginRight: 10 }}
                          >
                            Input Mapping
                          </Button>
                        )}
                        {outgoing.api_method === "publish" && outgoing.res && (
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => {
                              this.navigateToDatafabric("cdutoapi");
                            }}
                          >
                            Output Mapping
                          </Button>
                        )}
                      </div>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </ValidatorForm>
        </div>
        <HelpdeskFAB category="" />
      </div>
    );
  }
}
CreateOutgoingForm.propTypes = {
  classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => {
  return {
    outgoingApi: state.appDetailsRedcer.applicationDetails.outgoingApi,
    apis: state.appDetailsRedcer.apis || [],
    userDetails: state.apearenceReducer.userInfo,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getDetails: (id) => dispatch(getApplicationDetails(id)),
  updateAPIS: (data) => dispatch(updateApplicationAPIS(data)),
});

const CreateOutgoing = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateOutgoingForm);

export default withStyles(Styles)(withSnackbar(CreateOutgoing));
