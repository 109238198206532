import { Grid, Typography, FormControl } from "@material-ui/core";
import {
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator";
export const MessageBrokerCompo = ({ isDisabled, api, handleOnchange }) => {
  return (
    <>
      <Grid container spacing={2} style={{ marginTop: "2px" }}>
        <Grid item md={3}>
          <div style={{ width: "100%" }}>
            <Typography style={{ marginBottom: "2px" }} variant="body2">
              Message Broker <span style={{ color: "red" }}>*</span>
            </Typography>
            <FormControl size="small" variant="outlined" fullWidth>
              <SelectValidator
                disabled={isDisabled}
                fullWidth
                variant="outlined"
                size="small"
                SelectProps={{
                  native: true,
                }}
                value={api.messageBroker || ""}
                inputProps={{
                  name: "messageBroker",
                  id: "messageBroker",
                }}
                onChange={(e) =>
                  handleOnchange({
                    ...api,
                    messageBroker: e.target.value,
                  })
                }
                validators={["required"]}
                errorMessages={["Please select message broker"]}
              >
                <option value=""> - select -</option>
                <option value="Kafka">Kafka</option>
                <option value="RabbitMQ">RabbitMQ</option>
                <option value="MQTT">MQTT</option>
              </SelectValidator>
            </FormControl>
          </div>
        </Grid>

        <Grid item md={3}>
          <div style={{ width: "100%" }}>
            <Typography style={{ marginBottom: "2px" }} variant="body2">
              Broker Address <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextValidator
              disabled={isDisabled}
              fullWidth
              size="small"
              variant="outlined"
              value={api?.source?.cluster || ""}
              onChange={(e) =>
                handleOnchange({
                  ...api,
                  source: {
                    ...api.source,
                    cluster: e.target.value,
                  },
                })
              }
              name="cluster-address"
              validators={["required"]}
              errorMessages={["Please enter broker address"]}
            />
          </div>
        </Grid>

        <Grid item md={3}>
          <div style={{ width: "100%" }}>
            <Typography style={{ marginBottom: "2px" }} variant="body2">
              Username
            </Typography>
            <TextValidator
              disabled={isDisabled}
              fullWidth
              size="small"
              variant="outlined"
              value={api?.source?.username || ""}
              onChange={(e) =>
                handleOnchange({
                  ...api,
                  source: {
                    ...api.source,
                    username: e.target.value,
                  },
                })
              }
              name="user-name"
            />
          </div>
        </Grid>

        <Grid item md={3}>
          <div style={{ width: "100%" }}>
            <Typography style={{ marginBottom: "2px" }} variant="body2">
              Password
            </Typography>
            <TextValidator
              disabled={isDisabled}
              fullWidth
              size="small"
              variant="outlined"
              value={api?.source?.password || ""}
              onChange={(e) =>
                handleOnchange({
                  ...api,
                  source: {
                    ...api.source,
                    password: e.target.value,
                  },
                })
              }
              name="password"
            />
          </div>
        </Grid>

        <Grid item md={3}>
          <div style={{ width: "100%" }}>
            <Typography style={{ marginBottom: "2px" }} variant="body2">
              Topic <span style={{ color: "red" }}>*</span>
            </Typography>

            <TextValidator
              disabled={isDisabled}
              fullWidth
              autoComplete="off"
              size="small"
              variant="outlined"
              id="Topic"
              value={api?.source?.topic || ""}
              name="applicationApiTopic"
              onChange={(e) =>
                handleOnchange({
                  ...api,
                  source: {
                    ...api.source,
                    topic: e.target.value,
                  },
                })
              }
              validators={["required"]}
              errorMessages={["Please enter topic"]}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
};
