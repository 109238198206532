/**
 *
 */

import React, { Component } from "react";
import Breadcrumb from "components/Breadcrumbs/Breadcrumb";
import { CustomTextField } from "components/Customized";
import { RiEditLine, RiDeleteBin5Line } from "react-icons/ri";
import FilterComponent from "components/FilterComponent/FilterComponent";
import { checkPermission } from "../../helpers/permissons";
import { Link } from "react-router-dom";
import {
  Typography,
  Grid,
  Button,
  IconButton,
  Tooltip,
  InputAdornment,
} from "@material-ui/core/";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";

import DexTable from "components/DexTable/DexTable";
import { withStyles } from "@material-ui/styles";
import { Pagination } from "@material-ui/lab";
import { withSnackbar } from "notistack";
import dataOptions from "./filterOptions.json";
import subscriptionModelAPIS from "api/SubscriptionModelAPIs/index";
import HelpersFunc from "helpers/helpers";
import actions from "./redux/action";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import HelpdeskFAB from "../../views/HelpdeskFAB/HelpdeskFab";
import { confirmDialog } from "components/DeleteConfirmationDialog/Index";
import { dltMsg } from "helpers/helpers";
const findAndExecuteAjax = (type) => {
  switch (type) {
    case "Subscription Plan":
      return (operator, value) => {
        if (operator === "Contains of") {
          return { subscription: { $regex: value, $options: "i" } };
        }
        if (operator === "Starts With") {
          return { subscription: { $regex: `^${value}`, $options: "i" } };
        }
        if (operator === "Ends With") {
          return { subscription: { $regex: `${value}$`, $options: "i" } };
        }
        if (operator === "Equals To") {
          return { subscription: { $regex: `^${value}$`, $options: "i" } };
        }
      };
    case "Duration":
      return (operator, value) => {
        let val = parseInt(value);
        if (operator === "Equals To") {
          return { value: { $eq: val } };
        }
        if (operator === "Greater than") {
          let gt = new Date(`${value}T23:59:59+05:30`).getTime() / 1000;
          return { value: { $gt: gt } };
        }
        if (operator === "Lesser than") {
          let lt = new Date(`${value}T00:00:00+05:30`).getTime() / 1000;
          return { value: { $lt: lt } };
        }
        if (operator === "Greater than or Equal To") {
          return { value: { $gte: val } };
        }
        if (operator === "Less than or Equals To") {
          return { value: { $lte: val } };
        }
      };

    default:
      return {};
  }
};

const Styles = (theme) => ({
  footer: {
    position: "fixed",
    bottom: 0,
    left: 0,
    padding: 20,
    width: "100%",
    textAlign: "right",
    zIndex: 999,
    backgroundColor: "#f2f2f2",
  },
  customPagination: {
    width: "fit-content",
    marginLeft: "auto",
    "& .MuiPaginationItem-page.Mui-selected": {
      border: "0.5px solid #707070",
      margin: "0 10px",
      height: 30,
      width: 30,
      padding: "10px 13px",
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      boxShadow: "0px 3px 10px #5757571a",
      lineHeight: 1,
      borderRadius: 5,
      opacity: 1,
    },
    "& .MuiPaginationItem-page": {
      font: "normal normal normal 14px/19px Nunito Sans",
      letterSpacing: 0,
      color: "#000000",
      opacity: 0.6,
    },
  },
});

class SubscriptionModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      size: 10,
      subscriptionModel: [],
      totalCount: 0,
      loading: true,
      searchkey: "",
      value: 0,
      filters: {},

      sort: { subscription: -1 },
      sorting: [{ columnName: "subscription", direction: "desc" }],

      data_options: [...dataOptions],
    };
  }

  fetchSubscription = async (page, size, filters, sort) => {
    let res = await subscriptionModelAPIS.getSubscriptionModel(
      page,
      size,
      JSON.stringify(filters),
      JSON.stringify(sort)
    );
    let count = res.data.totalCount;
    if (res.data.data) {
      this.setState({
        subscriptionModel: [...(res.data.data || [])],
        totalCount: count,
        loading: false,
      });
    } else {
      this.setState({
        subscriptionModel: [],
        totalCount: 0,
        loading: false,
      });
    }
    // console.log(this.state.subscriptionModel, "daad");
  };

  async componentDidMount() {
    const { filterProps } = this.props;
    let { page, size } = this.state;
    this.fetchSubscription(page, size, filterProps.filters, filterProps.sort);
  }

  filterFunc = (arr) => {
    let filters = {};
    if (arr.length === 1) {
      const { type, operator, value } = arr[0];
      console.log(value);
      var category_id = this.props.data;
      console.log(category_id, operator, value, "catid");
      let filter = HelpersFunc.findAndExecuteAjax(type)(operator, value);
      this.getApplicationsByFilter({ ...filter }, arr);
    } else {
      arr.map((item, id) => {
        const { type, operator, value } = item;
        let filter = HelpersFunc.findAndExecuteAjax(type)(operator, value);
        filters = { ...filters, ...filter };
      });
      this.getApplicationsByFilter({ ...filters }, arr);
    }
  };

  getApplicationsByFilter = async (filters, arr, search) => {
    let page = 1;
    this.setState({
      page: 1,
    });
    let { filterProps, updateFilter } = this.props;
    updateFilter({
      filter: filters,
      filterArray: arr,
    });
    await this.fetchSubscription(
      page,
      this.state.size,
      filters,
      filterProps.sort
    );
    if (!search) {
      this.setState({
        searchkey: "",
      });
    }
  };

  handlePageChange = async (e, newPage) => {
    this.setState({
      page: newPage,
    });

    let { filterProps } = this.props;
    if (this.state.searchkey.trim().length !== 0) {
      //when search is enabled while user click pagination we need this function!
      this.searchAndPaginationHelperFunc(newPage, this.state.searchkey);
    } else {
      await this.fetchSubscription(
        newPage,
        this.state.size,
        filterProps.filters,
        filterProps.sort
      );
    }
  };
  searchAndPaginationHelperFunc = (page, val) => {
    let { filterProps } = this.props;
    let filtersCopy = { ...filterProps.filters };
    let filters = {
      ...filtersCopy,
      subscription: { $regex: "^" + val, $options: "i" },
    };
    // this.getApplicationsByFilter(filters, filterProps.filterArray, true);

    this.fetchSubscription(page, this.state.size, filters, filterProps.sort);
  };
  onChangeSearchAppName = (event) => {
    let page = 1;
    this.setState({
      searchkey: event.target.value,
      page: page,
    });
    this.searchAndPaginationHelperFunc(page, event.target.value);
  };

  createSubscription = () => {
    let data = {
      subscription: "",
      value: "",
      description: "",
    };
    this.props.history.push("/app/create-subscription-plan", {
      subscription_Data: data,
      edit: false,
    });
  };

  editSubscriptionModel = (row, e) => {
    e && e.stopPropagation();
    let data = { ...row };
    this.props.history.push("/app/create-subscription-plan", {
      subscription_Data: data,
      edit: true,
      disabled: e ? false : true,
    });
  };

  viewsubscriptiondata = (row) => {
    this.editSubscriptionModel(row);
  };

  removeSubscriptionModel = async (row, e) => {
    e && e.stopPropagation();

    try {
      let res = await subscriptionModelAPIS.deleteSubscription(row._id);
      if (res.data && res.data._status === 200) {
        this.props.enqueueSnackbar(
          res.data._msg || "Subscription Plan deleted successfully",
          {
            variant: "success",
            autoHideDuration: 2000,
          }
        );
        await this.fetchSubscription();
      }
    } catch (err) {
      err.response &&
        this.props.enqueueSnackbar(err.response.data._msg, {
          variant: "error",
          autoHideDuration: 2000,
        });
    }
  };

  actionProvider = ({ row }) => {
    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <Tooltip title="Edit" aria-label="edit">
            <IconButton
              color="inherit"
              size="small"
              disabled={!checkPermission("btn10")}
              onClick={(e) =>
                checkPermission("btn10") && this.editSubscriptionModel(row, e)
              }
            >
              <RiEditLine fontSize="20px" />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title="Delete" aria-label="delete">
            <IconButton
              size="small"
              color="inherit"
              disabled={!checkPermission("btn14")}
              onClick={(e) => {
                e.stopPropagation();
                checkPermission("btn14") &&
                  confirmDialog(dltMsg(row?.subscription || ""), () =>
                    this.removeSubscriptionModel(row, e)
                  );
                // this.removeSubscriptionModel(row, e)
              }}
            >
              <RiDeleteBin5Line fontSize="20px" />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    );
  };

  handleSortByChange = (sorting) => {
    let { filterProps, updateSorting } = this.props;
    let filters = { ...filterProps.filters };
    let sort = {};
    sorting.forEach((f) => {
      sort[f.columnName] = f.direction == "asc" ? 1 : -1;
    });
    updateSorting({ sorting, sort });
    this.fetchSubscription(this.state.page, this.state.size, filters, sort);
  };

  render() {
    console.log("Data", this.state.subscriptionModel);
    let { classes, filterProps, hidden } = this.props;
    console.log(
      "hidddddddddddddddddddddddddddddddddddennnnnnnnnnnnnnnnnnnnn",
      hidden
    );
    let { subscriptionModel, totalCount, loading, data_options } = this.state;
    return (
      <Grid container>
        <Grid item sm={12} md={12} xs={12} lg={12}>
          <Breadcrumb
            {...this.props}
            links={[
              { name: "Subscription Plan" },
              {
                name: "Subscription Plan List",
              },
            ]}
          />
        </Grid>
        <Grid item sm={8} md={8} lg={8} xs={12} className="mb-2">
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            Subscription Plan {`( ${this.state.totalCount} )`}
          </Typography>
        </Grid>

        <Grid item sm={4} md={4} lg={4} xs={12}>
          <Grid container spacing={2}>
            <Grid item sm={6} md={6} lg={6} xs={12}>
              <CustomTextField
                className=" mt-0 "
                autoComplete="off"
                fullWidth
                id="filled-search"
                type="text"
                variant="outlined"
                size="small"
                placeholder="Search"
                value={this.state.searchkey}
                margin="normal"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {" "}
                      <SearchIcon />{" "}
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  this.onChangeSearchAppName(e);
                }}
              />
            </Grid>
            <Grid item sm={3} md={3} lg={3} xs={12}>
              <FilterComponent
                getDataByFilter={this.getApplicationsByFilter}
                dataOptions={this.state.data_options}
                filterFunc={this.filterFunc}
                filterHistory={filterProps.filterArray}
              />
            </Grid>
            <Grid item sm={3} md={3} lg={3} xs={12}>
              <Button
                // component={Link}
                style={{
                  color: "white",
                  boxShadow: "none",
                  width: "100%",
                  padding: "6px 11px 6px 11px",
                }}
                variant="contained"
                color="primary"
                size="small"
                alignItems="center"
                disabled={!checkPermission("btn9")}
                onClick={checkPermission("btn9") && this.createSubscription}
                startIcon={<AddIcon />}
              >
                Create
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          sm={12}
          md={12}
          xs={12}
          lg={12}
          style={{ marginTop: "4px" }}
          className="pt-2"
        >
          <DexTable
            data={[...subscriptionModel]}
            columnOrder={["subscription", "value", "description", "actions"]}
            columns={[
              { name: "subscription", title: "Subscription plan" },
              { name: "value", title: "Duration (in Months)" },
              { name: "description", title: "Description" },
              { name: "actions", title: "Actions" },
            ]}
            columWidthsType="percentage"
            defaultColumnWidths={[
              { columnName: "subscription", width: 20 },
              { columnName: "value", width: 20 },
              { columnName: "description", width: 20 },
              { columnName: "actions", width: 19 },
            ]}
            formatters={[
              { formatterFn: this.actionProvider, columns: ["actions"] },
            ]}
            hidden={[...(hidden || [])]}
            sorting={filterProps.sorting}
            onSortingChange={this.handleSortByChange}
            disableReordering={false}
            height="60vh"
            loading={loading}
            tableType="virtual"
            onClickRow={(row) => this.viewsubscriptiondata(row)}
            setHiddenColmn={(hidden) => {
              console.log("hidddddddddddddddddden", hidden);
              this.props.setHiddenColumn(hidden);
            }}
            Read={checkPermission("btn11")}
          />
        </Grid>
        {subscriptionModel.length !== 0 && (
          <div className={classes.footer}>
            <div className={classes.customPagination}>
              <Pagination
                count={Math.ceil(totalCount / 10)}
                page={this.state.page}
                onChange={this.handlePageChange}
                shape="rounded"
                showFirstButton
                showLastButton
              />
            </div>
          </div>
        )}
        <HelpdeskFAB category="Subscription Plan" />
      </Grid>
    );
  }
}

// export default withStyles(Styles)(withSnackbar(SubscriptionModel));

const mapStateToProps = (state, ownProps) => {
  return {
    filterProps: state.subscriptionPlan.filterProps,
    hidden: state.subscriptionPlan.hidden,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateFilter: (filter) => dispatch(actions.updateFilterData(filter)),
    updateSorting: (sortProps) => dispatch(actions.updateSort(sortProps)),
    setHiddenColumn: (hidden) =>
      dispatch(actions.updateHiddenSubscriptionCol(hidden)),
  };
};
const Subscription = connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionModel);
export default withRouter(withStyles(Styles)(withSnackbar(Subscription)));
