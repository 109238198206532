import React from "react";
import { withSnackbar } from "notistack";
import {
  Typography,
  Grid,
  Button,
  Divider,
  FormControl,
  TextField,
  Tooltip,
  IconButton,
} from "@material-ui/core/";
import Breadcrumb from "components/Breadcrumbs/Breadcrumb";
import { makeStyles } from "@material-ui/core/styles";
import actions from "./redux/action";
import { connect } from "react-redux";
import Select from "@material-ui/core/Select";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DexTable from "components/DexTable/DexTable";
import { RiEditLine, RiDeleteBin5Line } from "react-icons/ri";
import {
  TextValidator,
  SelectValidator,
  ValidatorForm,
} from "react-material-ui-form-validator";
import CustomTableRow from "./CustomTableRow";
import pricingMasterAPIS from "api/PricingMasterAPIs/index";
import DiscountTable from "./DiscountTable";
import Loader from "../../components/common/stuff/Loader";

import { CustomNumberFeild } from "components/Customized/index";
import moment from "moment";
import helpers from "../../helpers/helpers";
import { checkPermission } from "../../helpers/permissons";
import HelpdeskFAB from "../../views/HelpdeskFAB/HelpdeskFab";

// const useStyles = makeStyles((theme) => ({
//   divider: {
//     marginTop: "12px",
//     height: "2px",
//     background: "rgb(175, 175, 175)",
//   },
// }));

// const EditPricingmaster = (props) => {
//   const { PricingMasterData, loadisetSubscriptionPlanuseState("flat");

//   const classes = useStyles();
//   // function toggle() {
//   //   setIsOpened((wasOpened) => !wasOpened);
//   // }

class EditPricingmaster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      slabFixedRate: {
        gt: null,
        price: null,
      },
      dateError: false,
      appName: "",
      ID: localStorage.getItem("_id"),
      error: false,
      isEdit: false,
      ajax: false,
      allPublishedApp: [],
      billableServices: [],
      PricingMasterData: {
        appId: "",
        quantity: "",
        billableId: "",
        type: "flat",
        quantityScale: [
          {
            start: 0,
            end: "",
            price: "",
            add: true,
            edit: false,
          },
        ],
        validFrom: "",
        validTill: "",
        counterType: "",
        module: "",
        discount: [
          {
            subscriptionId: "",
            unit: null,
          },
        ],
      },
      edit: false,
      value: "flat",
      err_msg: "",
      index: 0,
      newRow: false,
      showHideComp1: false,
      hide: true,
      modules: [],
      subscriptionPlan: [],
      isDisabled: false,
    };
  }

  getSubscriptionPlan = async () => {
    let res = await pricingMasterAPIS.fetchSubscriptionPlan();
    if (res.data.data) {
      this.setState({
        subscriptionPlan: [...res.data.data],
      });
    } else {
      this.setState({
        subscriptionPlan: [],
      });
    }
  };

  getModuleAndBillableService = async (appId) => {
    try {
      let res = await pricingMasterAPIS.fetchModulesByApplicationId(appId);
      this.setState({
        modules: [...(res.data.data || [])],
      });
    } catch (err) {}
    try {
      let response = await pricingMasterAPIS.fetchBillServiceByAppIdModID(
        appId
      );
      this.setState({
        billableServices: [...(response.data.data || [])],
      });
    } catch (err) {}
  };

  fetchBillableAndmodule = async (appId) => {
    try {
      let res = await pricingMasterAPIS.fetchModulesByApplicationId(appId);
      this.setState({
        modules: [...(res.data.data || [])],
      });
    } catch (err) {}
    ///
    try {
      let response = await pricingMasterAPIS.fetchBillServiceByAppIdModID(
        appId
      );
      this.setState({
        billableServices: [...(response.data.data || [])],
      });
    } catch (err) {}
  };

  fetchAllPublishedApp = async () => {
    try {
      let res = await pricingMasterAPIS.getApplicationsBywithoutStatus();
      if (res.data) {
        await this.setState({
          allPublishedApp: [...(res.data.data || [])],
        });
      }
    } catch (err) {}
  };

  async componentDidMount() {
    // const { time } = helpers.getDateFormatAdvance();
    const { location } = this.props;
    if (location.state !== undefined) {
      const { PricingMaster_Data, edit, disabled } = location.state;
      let qtyScale = [...(PricingMaster_Data?.quantityScale || [])];
      let fixedRate = {
        start: null,
        price: null,
      };
      if (qtyScale.length > 1) {
        fixedRate = qtyScale.pop();
      }
      this.setState({
        isEdit: edit,
        isDisabled: disabled,
      });
      this.getSubscriptionPlan();
      this.fetchAllPublishedApp();
      console.log(
        moment.unix(PricingMaster_Data.validFrom).format("YYYY-MM-DDTHH:mm"),
        45,
        "timetime",
        PricingMaster_Data.validFrom
      );

      console.log("fshfhgsdgsda", PricingMaster_Data);
      if (edit || disabled) {
        this.setState({
          slabFixedRate: { gt: fixedRate["start"], price: fixedRate.price },
          PricingMasterData: {
            ...this.state.PricingMasterData,
            ...PricingMaster_Data,
            quantityScale: qtyScale,
            validFrom: moment
              .unix(PricingMaster_Data.validFrom)
              .format(`YYYY-MM-DDTHH:mm`),
            validTill: moment
              .unix(PricingMaster_Data.validTill)
              .format("YYYY-MM-DDTHH:mm"),
            module: PricingMaster_Data?.module?._id || "",
          },
          appName: PricingMaster_Data.application.name,
        });
        this.getModuleAndBillableService(PricingMaster_Data.appId);
      } else {
        this.setState({
          PricingMasterData: {
            ...this.state.PricingMasterData,
            ...PricingMaster_Data,
          },
        });
      }
      this.props.setLoading(false);
    } else {
      this.history.push("/app/pricingmaster");
    }
  }

  handleChangeOfModule = async (val) => {
    console.log(val, "sdfghjgfd4565435");
    let data = { moduleId: val.module };
    this.setState({
      PricingMasterData: { ...val },
    });
    // let res = await pricingMasterAPIS.fetchBillServiceByAppIdModID(
    //   val.appId,
    //   data
    // );
    // if (res.data.data) {
    //   this.setState({
    //     billableServices: [...res.data.data],
    //   });
    // } else {
    //   this.setState({
    //     billableServices: [],
    //   });
    // }
  };

  handleChangeDate = (val, till) => {
    var dateFrom = new Date(val.validFrom);
    var dateTill = new Date(val.validTill);
    if (dateTill < dateFrom) {
      val.validTill = "";
      this.setState({ PricingMasterData: { ...val } });
    } else {
      this.setState({
        PricingMasterData: { ...val },
        dateError: false,
      });
    }
  };
  handleChange = async (val, id) => {
    console.log(val, "34567sdfghgfdsdgh");
    this.setState({
      PricingMasterData: { ...val },
    });

    if (id) {
      // let res = await pricingMasterAPIS.fetchModulesByApplicationId(id);
      // if (res.data.data) {
      //   this.setState({
      //     modules: [...res.data.data],
      //   });
      // } else {
      //   this.setState({
      //     modules: [],
      //   });
      // }
      this.fetchBillableAndmodule(id);
    }
  };

  onchangePricingMasterData = (e, value) => {
    if (e.target.name === "name") {
      this.state.PricingMasterData.map((item, id) => {
        if (item.name === value.name) {
          value.id = item._id;
        }
      });
    }
    this.setState({
      PricingMasterData: { ...value },
    });
  };

  onSaveQuantityScale = (arr) => {
    const { PricingMasterData } = this.state;
    this.setState({
      PricingMasterData: { ...PricingMasterData, quantityScale: [...arr] },
    });
  };

  filterPayload = () => {
    let error_value = false;
    const { application, ...otherPricingMasterPayload } =
      this.state.PricingMasterData;
    let updatePricingMaster = { ...otherPricingMasterPayload };
    let { quantityScale, discount } = this.state.PricingMasterData;
    let validFrom = updatePricingMaster.validFrom;
    let validTill = updatePricingMaster.validTill;
    updatePricingMaster.validFrom =
      new Date(validFrom.split(".").join("-")).getTime() / 1000;
    updatePricingMaster.validTill =
      new Date(validTill.split(".").join("-")).getTime() / 1000;
    let dicountData = discount.filter((item, id) => {
      if (item === undefined) {
        return item !== undefined;
      } else {
        return item.unit !== null;
      }
    });

    if (updatePricingMaster.type === "flat") {
      updatePricingMaster.quantity = parseInt(updatePricingMaster.quantity);
      delete updatePricingMaster.quantityScale;
    } else {
      delete updatePricingMaster.quantity;
      quantityScale = quantityScale.filter((item, id) => {
        return item.end.trim() !== "";
      });
      let qty = quantityScale.map((item, id) => {
        let { edit, add, status, ...leftData } = item;
        return {
          ...leftData,
          end: parseInt(item.end),
          price: parseInt(item.price),
        };
      });
      qty.map((item, id) => {
        if (item.start > item.end) {
          error_value = true;
        }
      });
      const fixedRate = {
        start: parseInt(this.state.slabFixedRate.gt),
        price: parseInt(this.state.slabFixedRate.price),
      };
      qty.push(fixedRate);
      updatePricingMaster.quantityScale = [...qty];
    }
    updatePricingMaster.discount = [...dicountData];
    return error_value ? true : updatePricingMaster;
  };

  singleObjectSubmit = async (push, payload) => {
    //let payload = this.filterPayload();
    this.props.setLoading(true);
    try {
      let res = await pricingMasterAPIS.addPricingMaster(payload);
      this.props.enqueueSnackbar(res.data._msg || "Connection error!", {
        variant: "success",
      });
      this.props.setLoading(false);
      if (push) {
        this.props.history.push("/app/pricingmaster");
      }
    } catch (err) {
      this.props.enqueueSnackbar(
        err?.response?.data?._msg || "Connection error!",
        {
          variant: "error",
        }
      );
      this.props.setLoading(false);
    }
  };

  multilineAdd = async (payload) => {
    const { isEdit, PricingMasterData } = this.state;
    // let payload = this.filterPayload();
    console.log(payload);
    if (isEdit) {
      try {
        let res = await pricingMasterAPIS.editPricingMaster(
          PricingMasterData._id,
          payload
        );
        this.props.enqueueSnackbar(res.data._msg || "Connection error!", {
          variant: "success",
        });
        this.props.history.push("/app/pricingmaster");
      } catch (err) {
        this.props.enqueueSnackbar(
          err?.response?.data?._msg || "Connection error!",
          {
            variant: "error",
          }
        );
      }
    } else {
      await this.singleObjectSubmit(false, payload);
      await this.getSubscriptionPlan();
      this.setState({
        slabFixedRate: {
          gt: null,
          price: null,
        },
        PricingMasterData: {
          appId: "",
          quantity: "",
          billableId: "",
          type: "flat",
          quantityScale: [
            {
              start: 0,
              end: "",
              price: "",
              add: true,
              edit: false,
            },
          ],
          validFrom: "",
          validTill: "",
          counterType: "",
          module: "",
          discount: [
            {
              subscriptionId: "",
              unit: null,
            },
          ],
        },
      });
    }
  };

  onSubmit = async (e) => {
    let payload = this.filterPayload();
    console.log(payload, "dfghjk345678987654345");
    if (payload === true) {
      this.props.enqueueSnackbar("Invalid Quantity Scale values", {
        variant: "error",
      });
      return;
    }

    if (e.nativeEvent.submitter.name === "multiple") {
      this.multilineAdd(payload);
    }

    if (e.nativeEvent.submitter.name === "single") {
      this.singleObjectSubmit(true, payload);
    }
  };

  addSubscriptionPlan = (data) => {
    this.setState({
      PricingMasterData: {
        ...this.state.PricingMasterData,
        discount: [...data],
      },
    });
  };

  typename = ({ row }) => {
    return (
      <>
        <Typography>{row.discount.type}</Typography>
      </>
    );
  };
  subscriptionname = ({ row }) => {
    console.log(row, "ro");
    return (
      <>
        <Typography>{row.discount.subscriptionId}</Typography>
      </>
    );
  };
  amountname = ({ row }) => {
    return (
      <>
        <Typography>{row.discount.unit}</Typography>
      </>
    );
  };

  actionProvider = ({ row }) => {
    return (
      <div
        style={{
          display: "flex",
        }}
      >
        <div style={{ marginRight: 20 }}>
          <Tooltip title="Edit" aria-label="edit">
            <IconButton
              color="inherit"
              // onClick={() => this.editPricingMaster(row)}
            >
              <RiEditLine fontSize="20px" />
            </IconButton>
          </Tooltip>
        </div>
        <div>
          <Tooltip title="Delete" aria-label="delete">
            <IconButton
              color="inherit"
              // onClick={() => removePricingMaster(row)}
            >
              <RiDeleteBin5Line fontSize="20px" />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    );
  };

  setSlabFixedRate = (val) => {
    this.setState({
      slabFixedRate: {
        ...val,
      },
    });
  };

  render() {
    const { load } = this.props;
    const {
      isEdit,
      modules,
      PricingMasterData,
      subscriptionPlan,
      appName,
      dateError,
      isDisabled,
      billableServices,
      slabFixedRate,
    } = this.state;
    let min = 0;
    // new Date(validFrom.split(".").join("-")).getTime() / 1000;
    let datd =
      new Date(PricingMasterData.validFrom.split(".").join("-")).getTime() /
      1000;
    console.log("23456789asdfghj", slabFixedRate, PricingMasterData);

    return (
      <>
        <ValidatorForm
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              return;
            }
          }}
          onSubmit={(e) => {
            this.onSubmit(e);
          }}
        >
          {load && <Loader />}
          <Grid container>
            <Grid item sm={12} md={12} xs={12} lg={12}>
              <Breadcrumb
                {...this.props}
                links={[
                  { name: "Pricing Master", url: "/app/pricingmaster" },
                  {
                    name: `${
                      isEdit ? "Edit Pricing Master" : "Define Pricing Master"
                    }`,

                    url: "/app/editpricingmaster",
                  },
                ]}
              />
            </Grid>
            <Grid item sm={6} md={6} lg={6} xs={12}>
              <Typography variant="h6" style={{ fontWeight: "bold" }}>
                {isEdit ? appName : "Pricing Master"}
              </Typography>
            </Grid>
            <Grid
              item
              sm={6}
              md={6}
              container
              alignItems="center"
              justifyContent="flex-end"
              spacing={1}
            >
              <Grid item>
                {!isDisabled && (
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    addPricingMaster
                    onClick={() => {
                      this.props.history.push("/app/pricingmaster");
                    }}
                  >
                    Cancel
                  </Button>
                )}
              </Grid>
              <Grid item>
                {!isDisabled && (
                  <Button
                    name="multiple"
                    color={isEdit ? "" : "primary"}
                    size="small"
                    variant={isEdit ? "contained" : "outlined"}
                    type="submit"
                    id={isEdit ? "btn19" : "btn18"}
                    disabled={
                      isEdit
                        ? !checkPermission("btn19")
                        : !checkPermission("btn18")
                    }
                  >
                    {isEdit ? "Save" : "Create and Add Another"}
                  </Button>
                )}
              </Grid>
              <Grid item>
                {!isEdit && (
                  <Button
                    name="single"
                    size="small"
                    variant="contained"
                    type="submit"
                    disabled={!checkPermission("btn18")}
                  >
                    {"Create"}
                  </Button>
                )}
              </Grid>
              <Grid item>
                {isDisabled && (
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() =>
                      checkPermission("btn18") &&
                      this.setState({
                        isDisabled: false,
                      })
                    }
                    startIcon={<RiEditLine style={{ fontSize: "20px" }} />}
                  >
                    Edit
                  </Button>
                )}
              </Grid>
            </Grid>
            <Grid item md={12}>
              <Divider
                style={{
                  marginTop: "12px",
                  height: "2px",
                  background: "rgb(175, 175, 175)",
                }}
              />
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={2}>
            <Grid item xs={3} sm={3} md={3}>
              <div>
                <Typography variant="body2" style={{ marginBottom: "2px" }}>
                  Application <span style={{ color: "red" }}>*</span>
                </Typography>
              </div>

              <SelectValidator
                disabled={isEdit}
                variant="outlined"
                fullWidth
                size="small"
                SelectProps={{
                  native: true,
                }}
                value={PricingMasterData.appId}
                inputProps={{
                  name: "name",
                }}
                onChange={(e) =>
                  this.handleChange(
                    {
                      ...PricingMasterData,
                      appId: e.target.value,
                    },
                    e.target.value
                  )
                }
                validators={["required"]}
                errorMessages={["Please select application name "]}
              >
                <option value={""}>- select -</option>
                {this.state.allPublishedApp.map((item) => (
                  <option value={item._id}>{item.name}</option>
                ))}
              </SelectValidator>
            </Grid>

            <Grid item sm={3} md={3} style={{ width: "100%" }}>
              <Typography style={{ marginBottom: "2px" }} variant="body2">
                {" "}
                Billable Service <span style={{ color: "red" }}>*</span>
              </Typography>
              <FormControl fullWidth>
                <SelectValidator
                  disabled={isEdit}
                  variant="outlined"
                  fullWidth
                  size="small"
                  SelectProps={{
                    native: true,
                  }}
                  value={PricingMasterData.billableId}
                  inputProps={{
                    name: "name",
                  }}
                  onChange={(e) =>
                    this.handleChange({
                      ...PricingMasterData,
                      billableId: e.target.value,
                    })
                  }
                  validators={["required"]}
                  errorMessages={["Please select billing services"]}
                >
                  <option value={""}>- select -</option>

                  {this.state.billableServices.map((item) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
                </SelectValidator>
              </FormControl>
            </Grid>

            <Grid item xs={3} sm={3} md={3}>
              <div>
                <Typography variant="body2" style={{ marginBottom: "2px" }}>
                  {" "}
                  Module
                </Typography>
              </div>

              <SelectValidator
                disabled={isDisabled}
                variant="outlined"
                fullWidth
                size="small"
                SelectProps={{
                  native: true,
                }}
                inputProps={{
                  name: "moduleName",
                }}
                value={PricingMasterData.module}
                onChange={(e) =>
                  this.handleChangeOfModule({
                    ...PricingMasterData,
                    module: e.target.value,
                  })
                }
                errorMessages={["Please select module name"]}
              >
                <option value="">- select -</option>

                {modules.map((item, id) => (
                  <option value={item._id}>{item.name}</option>
                ))}
              </SelectValidator>
            </Grid>

            <Grid item xs={3} sm={3}>
              <div>
                <Typography style={{ marginBottom: "2px" }} variant="body2">
                  {" "}
                  Counter Type
                </Typography>
              </div>

              <SelectValidator
                disabled={isDisabled}
                variant="outlined"
                fullWidth
                size="small"
                SelectProps={{
                  native: true,
                }}
                value={PricingMasterData.counterType}
                inputProps={{
                  name: "counterType",
                }}
                onChange={(e) =>
                  this.handleChange({
                    ...PricingMasterData,
                    counterType: e.target.value,
                  })
                }
              >
                <option value={""}>- select -</option>
                <option value={"hourly"}>Hourly </option>
                <option value={"daily"}> Daily</option>
                <option value={"weekly"}> Weekly</option>
                <option value={"monthly"}> Monthly</option>
              </SelectValidator>
            </Grid>

            <Grid item sm={3} md={3} style={{ width: "100%" }}>
              <Typography style={{ marginBottom: "2px" }} variant="body2">
                Valid From<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextValidator
                inputProps={{
                  // min: "2021-02-20T00:00" <- this format,
                  min: isEdit
                    ? PricingMasterData.validFrom
                    : new Date().toISOString().slice(0, 16),
                }}
                disabled={isDisabled}
                fullWidth
                type="datetime-local"
                autoComplete="off"
                size="small"
                variant="outlined"
                value={PricingMasterData.validFrom}
                onChange={(e) =>
                  this.handleChangeDate({
                    ...PricingMasterData,
                    validFrom: e.target.value,
                  })
                }
                validators={["required"]}
                errorMessages={["Please select date"]}
              />
            </Grid>
            <Grid item sm={3} md={3} style={{ width: "100%" }}>
              <Typography style={{ marginBottom: "2px" }} variant="body2">
                Valid Till <span style={{ color: "red" }}>*</span>
              </Typography>
              <TextValidator
                inputProps={{
                  // min: "2021-02-20T00:00" <- this format,
                  min: isEdit
                    ? PricingMasterData.validTill
                    : new Date().toISOString().slice(0, 16),
                }}
                disabled={isDisabled}
                fullWidth
                type="datetime-local"
                autoComplete="off"
                size="small"
                variant="outlined"
                onChange={(e) =>
                  this.handleChangeDate(
                    {
                      ...PricingMasterData,
                      validTill: e.target.value,
                    },
                    e.target.value
                  )
                }
                value={PricingMasterData.validTill}
                validators={["required"]}
                errorMessages={["Please select date"]}
                error={dateError}
                onFocus={() => {
                  this.setState({
                    dateError: false,
                  });
                }}
                helperText={dateError ? "Please select proper date" : ""}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography variant="body2">
                {" "}
                Type <span style={{ color: "red" }}>*</span>{" "}
              </Typography>
              <RadioGroup
                aria-label="gender"
                name="prcingmaster"
                value={PricingMasterData.type}
                onChange={(e) =>
                  this.handleChange({
                    ...PricingMasterData,
                    type: e.target.value,
                  })
                }
              >
                <div style={{ display: "flex" }}>
                  <FormControlLabel
                    disabled={isDisabled}
                    value="flat"
                    control={<Radio color="primary" />}
                    label={
                      <Typography style={{ marginBottom: "2px", fontSize: 15 }}>
                        Flat Rate
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    disabled={isDisabled}
                    value="slab"
                    control={<Radio color="primary" />}
                    label={
                      <Typography style={{ fontSize: 15 }}>
                        Slab Rate
                      </Typography>
                    }
                  />
                </div>
              </RadioGroup>
            </Grid>

            {PricingMasterData.type === "flat" && (
              <Grid item sm={3} md={3}>
                <Typography variant="body2" style={{ marginBottom: "2px" }}>
                  Flat Rate<span style={{ color: "red" }}>*</span>{" "}
                </Typography>

                <CustomNumberFeild
                  disabled={isDisabled}
                  // style={{
                  //   width: "410px",
                  // }}
                  inputProps={{ min }}
                  type="number"
                  size="small"
                  variant="outlined"
                  fullWidth
                  name="flatRate"
                  value={PricingMasterData.quantity}
                  onChange={(e) => {
                    if (e.target.value < 0) {
                      this.handleChange({
                        ...PricingMasterData,
                        quantity: 0,
                      });
                    } else {
                      this.handleChange({
                        ...PricingMasterData,
                        quantity: e.target.value,
                      });
                    }
                  }}
                  validators={["required"]}
                  errorMessages={["Please enter quantity"]}
                />
              </Grid>
            )}

            {PricingMasterData.type === "slab" && (
              <Grid item sm={12} md={12} className="mt-3">
                {/* <DexTable
                data={[...PricingMasterData.quantityScale]}
                columnOrder={["name", "price", "price", "actions"]}
                columns={[
                  { name: "name", title: "Start Value" },
                  { name: "price", title: "End Value" },
                  { name: "price", title: "Unit Price" },
                  { name: "actions", title: "Actions" },
                ]}
                columWidthsType="percentage"
                defaultColumnWidths={[
                  { columnName: "name", width: 30 },
                  { columnName: "price", width: 24 },
                  { columnName: "price", width: 24 },
                  { columnName: "actions", width: 20 },
                ]}
                formatters={[
                  { formatterFn: this.actionProvider, columns: ["actions"] },
                  { formatterFn: this.unitfield, columns: ["name"] },
                ]}
                dateColumns={["createdOn"]}
                disableReordering={false}
                loading={loading}
                tableType="virtual"
                tableRow={(p) => {
                  return (
                    <CustomTableRow
                      {...p}
                      length={PricingMasterData.quantityScale.length}
                      quantityScale={PricingMasterData.quantityScale}
                      {...this.props}
                      allPublishedApp={this.props.allPublishedApp}
                      onSaveQuantityScale={this.onSaveQuantityScale}
                      tableType="virtual"
                      isDisabled={isDisabled}
                    />
                  );
                }}
              /> */}
                <CustomTableRow
                  length={PricingMasterData.quantityScale.length}
                  quantityScale={PricingMasterData.quantityScale}
                  {...this.props}
                  onSaveQuantityScale={this.onSaveQuantityScale}
                  tableType="virtual"
                  isDisabled={isDisabled}
                  setSlabFixedRate={this.setSlabFixedRate}
                  slabFixedRate={slabFixedRate}
                />
              </Grid>
            )}
            <Grid item sm={12} md={12}>
              <DiscountTable
                addSubscriptionPlan={this.addSubscriptionPlan}
                data={subscriptionPlan}
                discount={PricingMasterData.discount}
                {...this.props}
                isEdit={isEdit}
                isDisabled={isDisabled}
              />
            </Grid>
          </Grid>
          <HelpdeskFAB category="Pricing Master" />
        </ValidatorForm>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    load: state.PricingMaster.load,
    //subscription: state.PricingMaster.subscription,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (bool) => dispatch(actions.loadingDispatch(bool)),
    editBillableServices: (billableId, payload) =>
      dispatch(actions.editBillableServices(billableId, payload)),
    addBillableServices: (payload) =>
      dispatch(actions.addBillableServices(payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(EditPricingmaster));
