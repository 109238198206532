import React, { useState } from "react";
import { Grid, Typography, Tabs, Tab, Paper } from "@material-ui/core";
import manageStyle from "styles/CommonStyles/manageScreenStyles";
import { makeStyles } from "@material-ui/core/styles";
const useTabStyles = makeStyles({
  root: {
    justifyContent: "flex-start",
    width: "90vw",
  },
  scroller: {
    flexGrow: "0",
  },
});
const ViewTeams = (props) => {
  const { projectTeams, history, appId } = props;
  const styles = manageStyle();
  const classes = useTabStyles();
  const [teamTab, setTeamTab] = useState(0);

  const handleChangeTeamTab = (event, newValue) => {
    setTeamTab(newValue);
  };

  // console.log(projectTeams, "34567890sdfghjkjhgfds");
  return (
    <div>
      <>
        {projectTeams.length === 0 && (
          <div>
            <Typography variant="h6" className={styles.fieldText}>
              Teams is not selected
            </Typography>
          </div>
        )}

        <Tabs
          classes={{ root: classes.root, scroller: classes.scroller }}
          value={teamTab}
          onChange={handleChangeTeamTab}
          indicatorColor="primary"
          textColor="primary"
          className={styles.teamTab}
          variant={"scrollable"}
          scrollButtons={"on"}
        >
          {projectTeams.map((team, index) => (
            <Tab label={team.name} key={index} />
          ))}
        </Tabs>

        {projectTeams.map(
          (team, index) =>
            teamTab === index && (
              <div className={styles.teamChips} key={index}>
                <Grid container spacing={3}>
                  {team?.users?.map((data, index) => (
                    <Grid item xs={3} sm={3} key={index}>
                      <Paper
                        className={styles.teamMemberPaper}
                        onClick={() =>
                          history.push(`/app/create-usermanagement`, {
                            userData: data,
                            edit: true,
                            disabled: true,
                            appDetail: appId,
                          })
                        }
                      >
                        {data?.image ? (
                          <img
                            src={data?.image}
                            className={styles.memberProfile}
                            alt="profile"
                          />
                        ) : (
                          <img src="/images/users/default.svg" alt="profile" />
                        )}
                        <Typography
                          variant="body1"
                          className={styles.teamMemberName}
                        >
                          {data?.name}
                        </Typography>
                        <Typography
                          variant="body2"
                          className={styles.teamMemberDesgination}
                        >
                          {data?.desgination}
                        </Typography>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </div>
            )
        )}
      </>
    </div>
  );
};
export default ViewTeams;
