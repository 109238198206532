import * as types from "./actionTypes";
const intialState = {
  users: [],
  data: [],
  deldata: [],
  load: false,
  loading: false,
  hidden: [],
};

export default function reducer(state = intialState, action) {
  switch (action.type) {
    case types.FETCH_VALUE_MAP_DATA_SUCCESS:
      return {
        ...state,
        loading: action.loading,
        data: [...action.payload],
        totalCount: action.totalCount,
      };
    case types.FETCH_VALUE_MAP_DATA_FAILED:
      return {
        ...state,
        loading: action.loading,
        error: action.error,
        data: [],
      };

    case types.DELETE_VALUEMAP_DATA:
      return { ...state, loading: action.loading };
    case types.DELETE_VALUEMAP_DATA_SUCCESS:
      return { ...state, loading: action.loading, deldata: action.deldata };
    case types.DELETE_VALUEMAP_DATA_FAILURE:
      return { ...state, loading: action.loading, error: action.error };
    case types.LOAD_LOADING:
      return { ...state, load: action.payload };
    case types.UPDATE_VALUE_MAP_HIDDEN_COL:
      return { ...state, hidden: action.payload };
    default:
      return state;
  }
}
