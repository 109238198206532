import React, { Component } from "react";
import Breadcrumb from "components/Breadcrumbs/Breadcrumb";
import SearchIcon from "@material-ui/icons/Search";
import FilterComponent from "components/FilterComponent/FilterComponent";
import {
  Typography,
  Grid,
  Button,
  InputAdornment,
  Tooltip,
  IconButton,
  Link,
  Badge,
} from "@material-ui/core/";
import { Pagination } from "@material-ui/lab";
import { CustomTextField } from "components/Customized";
import DexTable from "components/DexTable/DexTable";
import AddIcon from "@material-ui/icons/Add";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";
// import { RiEditLine, RiDeleteBin5Line } from "react-icons/ri";
import { withSnackbar } from "notistack";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import Styles from "./styles";
import ViewIcon from "@material-ui/icons/Visibility";
import pricingMasterAPIS from "api/PricingMasterAPIs/index";
import actions from "./redux/action";
import dataOptions from "./filterOptions.json";
import HelpersFunc from "helpers/helpers";
import { compose } from "redux";
import helperFun from "helpers/helpers";
import { checkPermission } from "helpers/permissons";
import HelpdeskFAB from "../../views/HelpdeskFAB/HelpdeskFab";
class Invoicelist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      size: 10,
      filters: {},
      sort: { DateFrom: -1 },
      sorting: [{ columnName: "DateFrom", direction: "desc" }],
      edit: false,
      searchkey: "",
      value: 0,
      data_options: [...dataOptions],
      openDropdown: false,
      setDropdown: false,
      setOpen: false,
    };
  }

  fetchInvoiceList = (page, size, filter, sort) => {
    this.props.getData(
      page,
      size,
      JSON.stringify(filter),
      JSON.stringify(sort)
    );
  };

  async componentDidMount() {
    let { page, size, filters, sort } = this.state;
    this.fetchInvoiceList(page, size, filters, sort);
  }

  getFilteredData = async (filter) => {
    let { page, size, sort } = this.state;

    this.setState({
      filters: { ...filter },
    });
    this.fetchInvoiceList(page, size, filter, sort);
  };

  filterFunc = (arr) => {
    let filters = {};
    if (arr.length === 1) {
      let { operator, value, type } = arr[0];
      let filter = HelpersFunc.findAndExecuteAjax(type)(operator, value);
      this.getFilteredData({ ...filter });
    } else {
      arr.map((item, id) => {
        const { operator, value, type } = item;
        let filter = HelpersFunc.findAndExecuteAjax(type)(operator, value);
        filters = { ...filters, ...filter };
        return 0;
      });
      this.getFilteredData({ ...filters });
    }
  };

  handleSortByChange = (sorting) => {
    let { page, size, filters } = this.state;
    let sort = {};
    sorting.forEach((f) => {
      sort[f.columnName] = f.direction == "asc" ? 1 : -1;
    });
    this.setState({ sorting, sort });
    this.fetchInvoiceList(page, size, filters, sort);
  };

  openDropDown = () => {
    this.setState({ openDropDown: true });
  };
  dropUp = () => {
    this.setState({ setDropdown: true });
  };

  handlePageChange = async (e, newPage) => {
    let { size, filters, sort } = this.state;
    this.setState({
      page: newPage,
    });
    this.fetchInvoiceList(newPage, size, filters, sort);
  };

  invoicemonthProvider = ({ row }) => {
    return (
      <Typography variant="body1"> {row.DateFrom.split("T")[0]} </Typography>
    );
  };
  statusProvider = ({ value }) => {
    let Status = value || "";
    if (Status == "Paid") {
      return (
        <div className="d-flex justify-content align-items-center">
          <Badge
            classes={{ badge: this.props.classes.activeBadge }}
            variant="dot"
            style={{ marginRight: "10px" }}
          />
          <Typography variant="body1"> Paid </Typography>
        </div>
      );
    } else {
      return (
        <div className="d-flex justify-content align-items-center">
          <Badge color="error" variant="dot" style={{ marginRight: "10px" }} />
          <Typography variant="body1"> Pending </Typography>
        </div>
      );
    }
  };

  actionProvider = ({ row }) => {
    return (
      <Grid container>
        <Grid item>
          <Tooltip title="View" placement="bottom">
            <IconButton
              size="small"
              aria-label="view"
              id="btn21"
              disabled={!checkPermission("btn21")}
              onClick={(e) => {
                this.handleClick(row, e);
              }}
            >
              <ViewIcon style={{ fontSize: "19px" }} />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    );
  };

  amountProvider = ({ value }) => {
    let TotalInvoiceAmount = value;
    return (
      <div>
        {value ? <Typography> $ {TotalInvoiceAmount}</Typography> : "-"}
      </div>
    );
  };

  handleClick = (row, e) => {
    e && e.stopPropagation();
    let data = { ...row };
    console.log(row, "gty");
    this.props.history.push("/app/view-invoice", {
      Invoice_Data: data,
    });
  };

  viewInvoicedata = (row) => {
    console.log(row, "gty");
    let data = { ...row };
    this.props.history.push("/app/view-invoice", {
      Invoice_Data: data,
    });
  };

  organizationName = ({ row }) => {
    return (
      <div>
        <Typography>{row?.CustomerDetails?.organization_name || ""}</Typography>
      </div>
    );
  };

  customerName = ({ row }) => {
    return (
      <div>
        <Typography>{row?.CustomerDetails?.alias || ""}</Typography>
      </div>
    );
  };
  onChangeSearchAppName = (value) => {
    let { page, size, filters, sort } = this.state;
    this.setState({ searchkey: value });
    filters["name"] = { $regex: "^" + value, $options: "i" };
    this.fetchInvoiceList(page, size, filters, sort);
  };

  momentMonth = ({ row }) => {
    return (
      <div>
        <Typography>{helperFun.getMonth(row.Month || 0)}</Typography>
      </div>
    );
  };

  DateTo = ({ row }) => {
    return (
      <Typography variant="body2">
        {row?.DateTo.split("T")[0] || "-"}
      </Typography>
    );
  };

  render() {
    let { data, classes, loading, totalCount, hidden } = this.props;
    let { searchkey, filters } = this.state;
    console.log(data, totalCount, filters, "invoice list  reducer");

    return (
      <>
        <Grid container>
          <Grid item sm={12} md={12} xs={12} lg={12}>
            <Breadcrumb
              {...this.props}
              links={[
                { name: "Invoices" },
                {
                  name: "Invoices List",
                },
              ]}
            />
          </Grid>
          <Grid item md={8} xs={12}>
            <Typography variant="h6" style={{ fontWeight: "bold" }}>
              {`Invoices (${totalCount})`}
            </Typography>
          </Grid>
          <Grid item md={4} xs={12}>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item>
                {/* <CustomTextField
                  className=" mt-0 "
                  fullWidth
                  id="filled-search"
                  type="text"
                  variant="outlined"
                  size="small"
                  placeholder="Search"
                  value={searchkey}
                  margin="normal"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {" "}
                        <SearchIcon />{" "}
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    this.onChangeSearchAppName(e.target.value);
                  }}
                /> */}
              </Grid>
              <Grid item style={{ paddingRight: "3%" }}>
                {/* <FilterComponent
                  getDataByFilter={this.getFilteredData}
                  dataOptions={this.state.data_options}
                  filterFunc={this.filterFunc}
                /> */}
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12} md={12} xs={12} lg={12}>
            <DexTable
              data={[...data]}
              columnOrder={[
                "ID",
                "name",
                "alias",
                "Month",
                "TaxAmt",
                "TotalInvoiceAmount",
                "Status",
                "DateTo",
                "actions",
              ]}
              columns={[
                { name: "name", title: "Organization Name" },
                { name: "alias", title: "Customer Name" },
                { name: "TaxAmt", title: "Tax" },
                { name: "Month", title: "Invoice Month" },
                { name: "ID", title: "Invoice Number" },
                { name: "TotalInvoiceAmount", title: "Amount" },
                { name: "Status", title: "Status" },
                { name: "DateTo", title: "Billing Date" },
                { name: "actions", title: "Actions" },
              ]}
              columWidthsType="percentage"
              defaultColumnWidths={[
                // { columnName: "name", width: 20 },
                // { columnName: "TaxAmt", width: 8 },
                // { columnName: "Month", width: 13 },
                // { columnName: "ID", width: 14 },
                // { columnName: "TotalInvoiceAmount", width: 11 },
                // { columnName: "Status", width: 15 },
                // { columnName: "actions", width: 10 },
                { columnName: "name", width: 19 },
                { columnName: "alias", width: 19 },
                { columnName: "TaxAmt", width: 19 },
                { columnName: "Month", width: 19 },
                { columnName: "ID", width: 19 },
                { columnName: "TotalInvoiceAmount", width: 19 },
                { columnName: "Status", width: 19 },
                { columnName: "DateTo", width: 19 },
                { columnName: "actions", width: 19 },
              ]}
              formatters={[
                { formatterFn: this.organizationName, columns: ["name"] },
                { formatterFn: this.customerName, columns: ["alias"] },
                { formatterFn: this.actionProvider, columns: ["actions"] },
                { formatterFn: this.statusProvider, columns: ["Status"] },
                { formatterFn: this.DateTo, columns: ["DateTo"] },

                {
                  formatterFn: this.momentMonth,
                  columns: ["Month"],
                },

                // {
                //   formatterFn: this.amountProvider,
                //   columns: ["TotalInvoiceAmount"],
                // },
                // {
                //   formatterFn: this.invoicemonthProvider,
                //   columns: ["DateFrom"],
                // },
              ]}
              hidden={[...hidden]}
              sorting={this.state.sorting}
              onSortingChange={this.handleSortByChange}
              amountColumns={["TaxAmt", "TotalInvoiceAmount"]}
              // amountColumns={[]}
              setHiddenColmn={(hidden) => {
                this.props.setHiddenColumn(hidden);
              }}
              disableReordering={false}
              height="60vh"
              loading={loading}
              tableType="virtual"
              onClickRow={(row) => this.viewInvoicedata(row)}
              Read={checkPermission("btn22")}
            />
          </Grid>
          {data.length !== 0 && (
            <div className={classes.footer}>
              <div className={classes.customPagination}>
                <Pagination
                  count={Math.ceil(totalCount / 10)}
                  page={this.state.page}
                  onChange={this.handlePageChange}
                  shape="rounded"
                  showFirstButton
                  showLastButton
                />
              </div>
            </div>
          )}
          <HelpdeskFAB category="Invoices" />
        </Grid>
      </>
    );
  }
}

Invoicelist.propTypes = {
  classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => {
  console.log(state.ListInvoice, "invoice state");
  return {
    data: state.ListInvoice.data,
    loading: state.ListInvoice.loading,
    totalCount: state.ListInvoice.totalCount,
    hidden: state.ListInvoice.hidden,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getData: (pageno, size, filters, sort) =>
      dispatch(actions.actionfetchAllInvoices(pageno, size, filters, sort)),
    setHiddenColumn: (hidden) =>
      dispatch(actions.updateInvoiceHiddenCol(hidden)),
  };
};
const InvoiceListComponent = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Styles),
  withSnackbar
)(Invoicelist);
export default InvoiceListComponent;
