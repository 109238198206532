import getURL from "../config";
import API_helpers from "../index";
export const getMsgBrokerData = async (interval) => {
  const url = getURL("MONITORING_MESSAGE_BROKER")(interval);
  try {
    const res = await API_helpers.GET(url);
    return res;
  } catch (err) {
    throw err;
  }
};
