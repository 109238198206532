const Styles = (theme) => ({
  // root: {
  //   marginTop: "8px",
  // },
  title: {
    textTransform: "capitalize",
    fontWeight: "bold",
    justifyContent: "center",
  },
  divider: {
    marginTop: "12px",
    height: "2px",
    background: "rgb(175, 175, 175)",
  },
  div: {
    marginTop: 20,
  },
  tab: {
    marginTop: 10,
    marginBottom: 10,
  },
  cdu: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  table: {
    maxHeight: 500,
  },

  documentDropperWrapper: {
    // marginTop: "1.5rem",
    // marginBottom: "1.5rem",
    "& .documentDropperClass": {
      // width: "15rem",
      minHeight: "100px",
      padding: "1.5rem 3rem 0rem",
      border: "2.5px solid",
      borderColor: theme.palette.primary.main,
      borderRadius: 10,

      "& .MuiDropzoneArea-textContainer": {
        display: "flex",
        flexDirection: "column-reverse",
        alignItems: "center",
        justifyContent: "space-around",

        "& .MuiDropzoneArea-icon": {
          color: "#00285580",
          transform: "scale(1.4)",
        },

        "& .documentDropperTextClass": {
          fontSize: "1rem",
          fontWeight: "600",
          color: "#00285580",
        },
      },
    },
  },
});
export default Styles;
