import React, { Component } from "react";

import Breadcrumb from "components/Breadcrumbs/Breadcrumb";
import { RiEditLine, RiDeleteBin5Line } from "react-icons/ri";
import Styles from "./Style";
import { Link } from "react-router-dom";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import AddIcon from "@material-ui/icons/Add";
import {
  Paper,
  Tooltip,
  TableHead,
  TableCell,
  Table,
  TableRow,
  TableBody,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Typography,
  Divider,
  Grid,
} from "@material-ui/core";
import DexTable from "components/DexTable/DexTable";
import { withStyles } from "@material-ui/styles";
import { Pagination } from "@material-ui/lab";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import APIs from "../../api/StatusProfileAPIs/index";
import actions from "./redux/action";
import { checkPermission } from "helpers/permissons";
import HelpdeskFAB from "../../views/HelpdeskFAB/HelpdeskFab";

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      size: 10,
      // subscriptionModel: [],

      loading: true,
      open: false,
      // StatusProfile: {
      //   status: [],
      // },
      status: [],
      StatusProfileData: [],

      // sort: { subscription: -1 },
      // sorting: [{ columnName: "subscription", direction: "desc" }],
    };
  }

  handleClose = (e) => {
    // e && e.stopPropagation();
    this.setState({
      open: false,
    });
  };

  statusDropdown = ({ row }) => {
    let { classes } = this.props;

    let openModal = (e) => {
      e && e.stopPropagation();
      this.setState({
        StatusProfileData: [...row.status],
        setOpen: true,

        tm: true,
        lm: false,
      });
    };

    const handleClickOpen = (e) => {
      e && e.stopPropagation();
      this.setState({
        open: true,
        status: row.status,
      });
    };

    return (
      <IconButton
        color="inherit"
        aria-label="edit"
        edge="start"
        onClick={(e) => {
          // setresourceName(row.name);
          // setflag(!flag);
          handleClickOpen(e);
        }}
        className={classes.iconHover}
        // style={{marginLeft:0.1}}
      >
        {/* {row.users.length}  */}
        Statuses
        <ArrowDropDownIcon fontSize="small" />
      </IconButton>
    );
  };

  editstatus = (row, e) => {
    e && e.stopPropagation();
    let data = { ...row };
    console.log(data, "------rowww");
    this.props.history.push("/app/edit-status-profile", { data: data });
  };

  fetchStatusProfile = async (page, size, filters, sort) => {
    let res = await APIs.getStatusProfileData(
      page,
      size,
      JSON.stringify(filters),
      JSON.stringify(sort)
    );
    let count = res.data.totalCount;
    if (res.data.data) {
      this.setState({
        StatusProfileData: [...res.data.data],
        // totalCount: count,
        loading: false,
      });
    } else {
      this.setState({
        StatusProfileData: [],
        // totalCount: 0,
        loading: false,
      });
    }
    // console.log(this.state.subscriptionModel, "daad");
  };

  async componentDidMount() {
    let { page, size, filters, sort } = this.state;
    this.props.actionfetchAllStatusProfileData(
      page,
      size,
      JSON.stringify(filters),
      JSON.stringify(sort)
    );
  }

  actionProvider = ({ row }) => {
    return (
      <div
        style={{
          display: "flex",
        }}
      >
        <div style={{ marginRight: 20 }}>
          <Tooltip title="Edit" aria-label="edit">
            <IconButton
              size="small"
              color="primary"
              disabled={!checkPermission("btn31")}
              onClick={(e) =>
                checkPermission("btn31") && this.editstatus(row, e)
              }
            >
              <RiEditLine fontSize="20px" />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    );
  };

  viewStatusProfiledata = (row) => {
    this.editstatus(row);
  };

  handleSubmitComment = (val) => {
    // console.log(val, "--------------11111");
    const { status } = this.state.StatusProfile;
    this.setState({
      StatusProfile: { ...this.state.StatusProfile, status: [...status, val] },
    });
  };

  render() {
    let { totalCount, loading, classes, data, _msg, hidden } = this.props;
    let { status, StatusProfileData } = this.state;
    console.log(totalCount, "============7777");

    return (
      <>
        <Grid container>
          <Grid item sm={12} md={12} xs={12} lg={12}>
            <Breadcrumb
              {...this.props}
              links={[
                { name: "Status Profile" },
                {
                  name: "Status Profile List",
                },
              ]}
            />
          </Grid>
          <Grid item sm={8} md={8} lg={8} xs={12} className="mb-2">
            <Typography variant="h6" style={{ fontWeight: "bold" }}>
              Status Profile {`( ${this.props.totalCount} )`}
            </Typography>
          </Grid>

          <Grid item sm={4} md={4} lg={4} xs={12}>
            <Grid container spacing={2}>
              <Grid item sm={6} md={6} lg={6} xs={12}></Grid>
              <Grid item sm={3} md={3} lg={3} xs={12}></Grid>
              <Grid item sm={3} md={3} lg={3} xs={12}></Grid>
            </Grid>
          </Grid>

          <Grid
            item
            sm={12}
            md={12}
            xs={12}
            lg={12}
            style={{ marginTop: "4px" }}
            className="pt-2"
          >
            <DexTable
              data={[...data]}
              columnOrder={["name", "status", "actions"]}
              columns={[
                { name: "name", title: "Object Type" },
                { name: "status", title: "Status" },
                { name: "actions", title: "Actions" },
              ]}
              columWidthsType="percentage"
              defaultColumnWidths={[
                { columnName: "name", width: 40 },
                { columnName: "status", width: 40 },
                { columnName: "actions", width: 19 },
              ]}
              formatters={[
                { formatterFn: this.actionProvider, columns: ["actions"] },
                { formatterFn: this.statusDropdown, columns: ["status"] },
              ]}
              // sorting={this.state.sorting}
              // onSortingChange={this.handleSortByChange}
              hidden={[...hidden]}
              disableReordering={false}
              height="60vh"
              loading={loading}
              tableType="virtual"
              onClickRow={(row) => this.viewStatusProfiledata(row)}
              Read={checkPermission("btn215")}
              setHiddenColmn={(hidden) => {
                this.props.setHiddenColumn(hidden);
              }}
            />
          </Grid>
          {/* {subscriptionModel.length !== 0 && ( */}
          <div className={classes.footer}>
            <div className={classes.customPagination}>
              <Pagination
                count={Math.ceil(totalCount / 10)}
                page={this.state.page}
                onChange={this.handlePageChange}
                shape="rounded"
                showFirstButton
                showLastButton
              />
            </div>
          </div>
          {/* )} */}
          <HelpdeskFAB category="Status Profile" />
        </Grid>
        <div>
          <Dialog open={this.state.open} onClose={this.handleClose}>
            <DialogTitle>
              {/* <Typography>{resourceName}</Typography> */}
              <Typography>Helpdesk</Typography>
            </DialogTitle>
            <Divider />
            <DialogContent>
              <div style={{ height: "60vh", overflow: "auto" }}>
                {status.map((row, id) => (
                  <Paper
                    variant="outlined"
                    square
                    key={id}
                    style={{
                      marginBottom: 10,
                      marginTop: "15px",
                    }}
                  >
                    <div
                      // style={{ color: data.color }}

                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        justifyContent: "space-between",
                        padding: "15px 5px 0px 20px",
                      }}
                      // onClick={() => handleSetStatusDefault(data.id)}
                    >
                      <p
                        style={{
                          color: row.color,
                          // color: "blue",
                        }}
                      >
                        <span
                          className={classes.predefinedColorDots}
                          // style={{ backgroundColor: }}
                          style={{ backgroundColor: row.color }}
                        ></span>
                        {row.status}
                        {/* title */}
                      </p>
                      <p>
                        {/* {data.isDefault ? (
                                    <span style={{ color: "blue" }}>
                                      Default
                                    </span>
                                  ) : (
                                    <span
                                      className={classes.markAsDefault}
                                      style={{ color: "grey" }}
                                    >
                                      Mark as default
                                    </span>
                                  )} */}
                      </p>
                    </div>
                  </Paper>
                ))}
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.handleClose}
                className={classes.statusModalCloseButton}
              >
                Done
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </>
    );
  }
}
// export default withStyles(Styles)(withSnackbar());

const mapStateToProps = (state) => {
  return {
    totalCount: state.statusProfile.totalCount,
    // editdata: state.subscriptionPlan.editdata,
    loading: state.statusProfile.loading,
    // adddata: state.subscriptionPlan.adddata,
    data: state.statusProfile.data,
    hidden: state.statusProfile.hidden,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actionfetchAllStatusProfileData: (pageno, size, filters, sort) =>
      dispatch(
        actions.actionfetchAllStatusProfileData(pageno, size, filters, sort)
      ),
    setHiddenColumn: (hidden) =>
      dispatch(actions.updateStatusProfileHiddenCol(hidden)),
    // addSubscription: (payload) => dispatch(actions.addSubscription(payload)),
    // editSubscription: (id, payload) =>
    //   dispatch(actions.editSubscription(id, payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(Styles)(withSnackbar(index)));
