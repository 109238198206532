/**
 * Contains All the API call for the Billable services
 */
import getURL from "../config";
import API_helpers from "../index";

// const getSubscriptionModel = async () => {
//   try {
//     let res = await API_helpers.GET(getURL("FETCH_ALL_SUBSCRIPTION_MODEL"));
//     return res;
//   } catch (err) {
//     throw err;
//   }
// };

const getSubscriptionModel = async (page, size, filters, sort) => {
  console.log(page, size, filters, sort, 45678765445);
  let url = getURL("FETCH_ALL_SUBSCRIPTION_MODEL");
  url = url + "?";
  let params = { page, size, filters, sort };
  Object.keys(params)
    .filter((k) => params[k] != undefined || params[k] != null)
    .forEach((k) => (url = `${url}${k}=${params[k]}&`));
  url = url.substr(0, url.length - 1);
  console.log(url, "urll");
  try {
    let res = await API_helpers.GET(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  } catch (err) {
    throw err;
  }
};

const addSubscription = async (payload) => {
  try {
    let res = await API_helpers.POST(getURL("ADD_SUBSCRIPTION_PLAN"), payload, {
      headers: { "Content-Type": "application/json" },
    });
    return res;
  } catch (err) {
    throw err;
  }
};

const editSubscription = async (id, payload) => {
  try {
    let res = await API_helpers.PUT(
      getURL("EDIT_SUBSCRIPTION_PLAN")(id),
      payload,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return res;
  } catch (err) {
    throw err;
  }
};

const deleteSubscription = async (id) => {
  try {
    let res = await API_helpers.DELETE(getURL("DELETE_SUBSCRIPTION_PLAN")(id), {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  } catch (err) {
    throw err;
  }
};

var APIs = {
  getSubscriptionModel,
  addSubscription,
  editSubscription,
  deleteSubscription,
};

export default APIs;
