import { TextField, IconButton } from "@material-ui/core/";
import { withStyles } from "@material-ui/core/styles";
import { TextValidator } from "react-material-ui-form-validator";

export const CustomTextField = withStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      height: "35px",
    },
  },
}))(TextField);

export const CustomIconButton = withStyles((theme) => ({
  root: {
    "&.MuiIconButton-root": {
      background: theme.palette.primary.main,
      width: "30px",
      height: "30px",
      color: "white",
    },
  },
}))(IconButton);

export const CustomNumberFeild = withStyles((theme) => ({
  root: {
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
      {
        display: "none",
      },
  },
}))(TextValidator);


