import getURL from "../config";
import API_helpers from "../index";

async function fetchAllUserManagementrolesWithParams(
  page,
  size,
  filters,
  sort
) {
  let url = getURL("FETCH_USERMANAGEMENT_ROLES");
  url = url + "?";
  let params = { page, size, filters, sort };
  Object.keys(params)
    .filter((k) => params[k] != undefined || params[k] != null)
    .forEach((k) => (url = `${url}${k}=${params[k]}&`));
  url = url.substr(0, url.length - 1);
  console.log(url, "urll");
  try {
    let res = await API_helpers.GET(url, {
      headers: {
        "Content-Type": "application/json",
        userdetails: JSON.stringify({ name: "palani" }),
        organization: "11111",
      },
    });
    return res.data;
  } catch (err) {
    throw err;
  }
}

async function fetchMasterPermissions() {
  let url = getURL("FETCH_MASTER_PERMISSIONS");
  try {
    let res = await API_helpers.GET(url, {
      headers: {
        "Content-Type": "application/json",
        userdetails: JSON.stringify({ name: "palani" }),
        organization: "11111",
      },

      // userdetails: JSON.stringify({ name: "palani" }),
    });
    return res.data;
  } catch (err) {
    throw err;
  }
}
const addMasterRolemanagement = (payload) => {
  try {
    let res = API_helpers.POST(getURL("ADD_MASTER_ROLE_MANAGEMENT"), payload, {
      headers: {
        "Content-Type": "application/json",
        userdetails: JSON.stringify({ name: "palani" }),
        organization: "11111",
      },
    });
    return res;
  } catch (err) {
    throw err;
  }
};
const editMasterRolemanagement = (id, payload) => {
  try {
    let res = API_helpers.PUT(
      getURL("UPDATE_MASTER_ROLE_MANAGEMENT")(id),
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          userdetails: JSON.stringify({ name: "palani" }),
          organization: "11111",
        },
      }
    );
    return res;
  } catch (err) {
    throw err;
  }
};

const deleteMasterRolemanagement = (id) => {
  try {
    let res = API_helpers.DELETE(getURL("UPDATE_MASTER_ROLE_MANAGEMENT")(id), {
      headers: {
        "Content-Type": "application/json",
        userdetails: JSON.stringify({ name: "palani" }),
        organization: "11111",
      },
    });
    return res;
  } catch (err) {
    throw err;
  }
};

var APIs = {
  fetchAllUserManagementrolesWithParams,
  fetchMasterPermissions,
  addMasterRolemanagement,
  editMasterRolemanagement,
  deleteMasterRolemanagement,
};

export default APIs;
