/**
 * Tool tip
 */

import React from "react";
import { useDispatch } from "react-redux";
import { closeToolTip } from "../Redux/action";
import { Grid, Paper, IconButton, Tooltip } from "@material-ui/core";
import StopIcon from "@material-ui/icons/Stop";
import CloseIcon from "@material-ui/icons/Close";
import LinkTable from "./LinkTable";
import NodeTable from "./NodeTable";
const CustomizeLinkTooltip = ({ toolTip, setToolTip }) => {
  const dispatch = useDispatch();
  const { style, enabled, toolTipInfo } = toolTip;
  const { node } = toolTipInfo;

  return (
    <div  id={"tool-tip"} style={enabled ? { ...style } : { display: "none" }}>
      <Paper style={{ width: "400px", borderRadius: 8, position: "relative" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div
            style={{
              width: "2%",
              backgroundColor: "green",
              padding: 4,
              border: "solid 0.2 green",
              borderRadius: "8px 0 0 8px ",
            }}
          ></div>
          <div style={{ width: "98%" }}>
            <Grid container spacing={1}>
              <Grid item md={12}>
                <div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 5,
                      borderBottom: "solid 1px rgb(221, 221, 221)",
                      padding: "0 8px",
                    }}
                  >
                    <span style={{ fontSize: 12, fontWeight: "bold" }}>
                      Broker details
                    </span>

                    <div>
                      <Tooltip title="stop">
                        <IconButton size="small" style={{ color: "red" }}>
                          <StopIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="close">
                        <IconButton
                          size={"small"}
                          onClick={() => {
                            dispatch(closeToolTip());
                          }}
                        >
                          <CloseIcon style={{ color: "black" }} />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                  <div style={{ padding: "0 8px", marginBottom: 8 }}>
                    {!node ? (
                      <LinkTable {...toolTipInfo} />
                    ) : (
                      <NodeTable {...toolTipInfo} />
                    )}
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default CustomizeLinkTooltip;
