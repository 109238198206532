import React from "react";
import { Typography, Grid, TextField } from "@material-ui/core";

import withSection from "components/Section/Section";
const Organisation = (props) => {
  const { classes, details } = props;
  let gst = details["GST/Tax_registration_number"];
  return (
    <Grid container spacing={3}>
      <Grid item md={3}>
        <Typography style={{ marginBottom: "2px" }} variant="body2">
          Name
        </Typography>
        <TextField
          value={details.organization_name}
          fullWidth
          size="small"
          variant="outlined"
          disabled
        />
      </Grid>

      <Grid item md={3}>
        <Typography style={{ marginBottom: "2px" }} variant="body2">
          TIN
        </Typography>
        <TextField
          value={gst}
          fullWidth
          size="small"
          variant="outlined"
          disabled
        />
      </Grid>
      <Grid item md={3}>
        <Typography style={{ marginBottom: "2px" }} variant="body2">
          Industry
        </Typography>
        <TextField
          value={details.industry}
          fullWidth
          size="small"
          variant="outlined"
          disabled
        />
      </Grid>
      <Grid item md={3}>
        <Typography style={{ marginBottom: "2px" }} variant="body2">
          Company URL
        </Typography>
        <TextField
          value={details.company_url}
          fullWidth
          size="small"
          variant="outlined"
          disabled
        />
      </Grid>

      <Grid item md={3}>
        <Typography style={{ marginBottom: "2px" }} variant="body2">
          Size
        </Typography>
        <TextField
          value={details.organization_size}
          fullWidth
          size="small"
          variant="outlined"
          disabled
        />
      </Grid>
      <Grid item md={3}>
        <Typography style={{ marginBottom: "2px" }} variant="body2">
          Address
        </Typography>
        <TextField
          fullWidth
          value={details.address}
          size="small"
          variant="outlined"
          disabled
        />
      </Grid>
    </Grid>
  );
};
export default withSection(Organisation, "Organization Details");
