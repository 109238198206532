import { ApiHelper } from "api/services/helper/ApiHelper";
import getURL from "../config";
import API_helpers from "../index";
const fetchDetails = async (id) => {
  try {
    const res = await API_helpers.GET(getURL("FETCH_USER_DETAILS")(id), {
      headers: {
        organization: "11111",
        userdetails: JSON.stringify({ name: "palani" }),
      },
    });
    const { _status, data, _msg } = res.data;
    console.log("this is data", 23, data);
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};
const login = async (user) => {
  let url = getURL("LOGIN");
  try {
    const res = await API_helpers.AUTH_POST(url, user, {
      headers: {
        organization: "11111",
      },
    });
    const { _status, data, _msg } = res.data;
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg || "Network issue!",
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

const editDetails = async (userId, val) => {
  try {
    let res = await API_helpers.PUT(getURL("EDIT_USER_DETAILS")(userId), val, {
      headers: {
        organization: "11111",
        userdetails: JSON.stringify({ name: "palani" }),
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

const change2FA = async (userId, payload) => {
  // let id;
  // if (typeof payload === "object") {
  //   id = payload._id;
  // } else {
  //   id = payload;
  // }
  try {
    let res = await API_helpers.PUT(
      getURL("TWO_FACTOR_AUTH")(userId),
      payload,
      {
        headers: {
          organization: "11111",
          userdetails: JSON.stringify({ name: "palani" }),
        },
      }
    );
    return res;
  } catch (err) {
    return err;
  }
};

const forgetPassword = async (req_data) => {
  try {
    let res = await API_helpers.AUTH_POST(getURL("FORGET_PASSWORD"), req_data, {
      headers: {
        organization: "11111",
        userdetails: JSON.stringify({ name: "palani" }),
      },
    });
    const { _status, _msg, data } = res.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

export const verifyCodeForgetPassword = async (req_data) => {
  try {
    const res_data = await API_helpers.AUTH_POST(
      getURL("RESET_PASSWORD_VERIFY_CODE"),
      req_data,
      {
        headers: {
          organization: "11111",
          userdetails: JSON.stringify({ name: "palani" }),
        },
      }
    );
    const { _msg, _status, data } = res_data.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

const generateQR_codeApi = async (id) => {
  try {
    const res = await API_helpers.GET(getURL("GENERATE_USER_QR_CODE")(id), {
      headers: {
        organization: "11111",
        userdetails: JSON.stringify({ name: "palani" }),
      },
    });
    return res;
  } catch (err) {
    throw err;
  }
};

const verify2FACode = async (id, code) => {
  console.log(id, code, "verifycode");
  try {
    const res = await API_helpers.GET(getURL("VERIFY_2FACODE")(id, code), {
      headers: {
        organization: "11111",
        userdetails: JSON.stringify({ name: "palani" }),
      },
    });
    return res;
  } catch (err) {
    throw err;
  }
};

const signUp = async (payload, id) => {
  try {
    const res = await API_helpers.PUT(getURL("SIGNUP")(id), payload, {
      headers: {
        organization: "11111",
        userdetails: JSON.stringify({ name: "palani" }),
      },
    });
    const { _msg, _status, data } = res.data;
    return {
      _msg,
      _status,
      data,
    };
  } catch (err) {
    return {
      _msg: err?.response?.data?._msg || "Network issue!",
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

let APIs = {
  login,
  editDetails,
  fetchDetails,
  change2FA,
  forgetPassword,
  verifyCodeForgetPassword,
  generateQR_codeApi,
  verify2FACode,
  signUp,
};
export default APIs;
