import getURL from "../../../api/config";
import API_helpers from "../../../api/index";
import axios from "axios";
import teamsAPIS from "api/TeamsAPIs/index";
import * as types from "./actionTypes";

//onBoard Redux

export const onboardApplication = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.ONBOARD_APPLICATION,
      loading: true,
    });
    try {
      let res = await API_helpers.POST(getURL("ONBOARD_APPLICATION"), data, {
        headers: {
          "Content-Type": "application/json",
          userdetails: JSON.stringify({ name: "palani" }),
          organization: "11111",
        },
      });
      let msg = "";
      let id = 0;
      //console.log(res.data.data.id, "response log");
      if (res.data) {
        msg = res.data;
        id = res.data.data.id;
      }
      dispatch({
        type: types.ONBOARDED_APPLICATION,
        message: msg,
        appId: id,
        loading: false,
      });
      dispatch({
        type: types.RESET_ONBOARD_SUBMIT,
      });
    } catch (err) {
      let msg = {};
      if (err.response) {
        msg = err.response.data;
      }
      dispatch({
        type: types.ONBOARDED_APPLICATION_FAILED,
        message: msg,
        loading: false,
      });
      dispatch({
        type: types.RESET_ONBOARD_SUBMIT,
      });
    }
  };
};

export const clearMessage = () => ({
  type: types.CLEAR_MESSAGE,
});

export const outgoingApi = (appId, apiId, url) => {
  return async (dispatch) => {
    console.log(appId, apiId, url, "hsghgsfhfsh");
    try {
      let res = await API_helpers.POST(
        getURL("OUTGOING_API")(appId, apiId),
        url
      );
      console.log(res, "saving outgoing");
    } catch (err) {
      console.log(err, "saving outgoing");
    }
  };
};

export const addUsageParams = (data) => ({
  type: types.ADD_USAGE_PARAMS,
  payload: data,
});

export const updateBillingService = (data) => ({
  type: types.UPDATE_USAGE_PARAMS,
  payload: data,
});

export const addWebhooksUsageParams = (data) => ({
  type: types.ADD_WEBHOOKS,
  payload: data,
});

export const updateWebhooks = (data) => ({
  type: types.UPDATE_WEBHOOKS,
  payload: data,
});
export const addAuthObject = (data) => ({
  type: types.ADD_AUTH_PARAMS,
  payload: data,
});
export const updateAuthObject = (data) => ({
  type: types.UPDATE_AUTH_PARAMS,
  payload: data,
});
export const addModule = (data) => ({
  type: types.ADD_MODULE,
  payload: data,
});

export const updateModule = (data, name, newName) => ({
  type: types.UPDATE_MODULE,
  payload: data,
  moduleName: name,
  updateName: newName,
});

export const addEnvironmentForm = (data) => ({
  type: types.ADD_ENVIRONMENTFORM,
  payload: data,
});

export const updateEnvironmentForm = (data) => ({
  type: types.UPDATE_ENVIRONMENTFORM,
  payload: data,
});
export const addAPI = (data) => ({
  type: types.ADD_API,
  payload: data,
});

export const updateAPI = (data) => ({
  type: types.UPDATE_API,
  payload: data,
});

export const addAppBasicInfo = (data) => ({
  type: types.ADD_BASIC_INFO,
  payload: data,
});

export const addAuthWebhooks = (data) => ({
  type: types.ADD_AUTH_WEBHOOKS,
  payload: data,
});

export const addBillingWebhooks = (data) => ({
  type: types.ADD_BILLING_WEBHOOKS,
  payload: data,
});

export const cancelApplication = () => ({
  type: types.CANCEL_APPLICATION,
});

const updateTeamsInAppDetails = (params) => {
  let { selectedTeam, teams } = params;
  let updatedTeamData = [];
  teams.map((item, id) => {
    let flag = false;
    selectedTeam.map((itm, idx) => {
      if (teams[id]._id === selectedTeam[idx]._id) {
        updatedTeamData.push({ ...item, checked: true });
        flag = true;
      }
      return 0;
    });
    if (!flag) {
      updatedTeamData.push({ ...item, checked: false });
    }
    return 0;
  });
  return {
    updateTeams: updatedTeamData,
  };
};

export const fetchTeams = (filter, selectedTeam) => async (dispatch) => {
  dispatch({
    type: types.FETCH_TEAMS_FOR_ONBOARD_APP,
  });
  try {
    let res = await teamsAPIS.fetchAllTeamsWithParams(
      undefined,
      undefined,
      filter
    );
    let teams = res.data || [];
    let result = {};
    selectedTeam = selectedTeam || [];
    result = await updateTeamsInAppDetails({ selectedTeam, teams });
    dispatch({
      type: types.FETCHED_TEAMS_FOR_ONBOARD_APP,
      loading: false,
      totalCount: res._totalcount,
      payload: result.updateTeams,
    });
  } catch (err) {
    dispatch({
      type: types.FETCH_TEAMS_FOR_ONBOARD_APP_FAIL,
    });
  }
};

export const handleSelectTeam =
  (selectedTeams, team, data, val) => (dispatch) => {
    let updateTeams = [];
    let clickedTeam = { users: [] };
    let selectedTeam = [];
    try {
      updateTeams = data.map((item, id) => {
        if (item._id === team._id) {
          clickedTeam = val ? { ...item } : { users: [] };
          return {
            ...item,
            checked: val,
          };
        } else {
          return { ...item };
        }
      });
      if (val) {
        selectedTeam = selectedTeams.concat(
          updateTeams.filter((item) => item.checked)
        );
        selectedTeam = Array.from(new Set(selectedTeam.map((a) => a.name))).map(
          (name) => {
            return selectedTeam.find((a) => a.name === name);
          }
        );
      } else {
        selectedTeam = selectedTeams.filter((itm) => itm.name !== team.name);
      }

      dispatch({
        type: types.ADD_TEAM_TO_ONBOARD_APP,
        updateTeams: updateTeams,
        selectedTeam: selectedTeam,
        clickedTeam: clickedTeam,
      });
    } catch (err) {
      dispatch({
        type: types.RESET_TEAM_IN_ONBOARD_APP,
      });
    }
  };

export const set_ClickedTeam = (team) => ({
  type: types.SET_CLICKED_TEAM,
  payload: team,
});
