// appearance
export const USER_VISIBLE_REQUEST = "USER_VISIBLE_REQUEST";
export const USER_VISIBLE_SUCCESS = "USER_VISIBLE_SUCCESS";
export const USER_VISIBLE_RESET = "USER_VISIBLE_RESET";
export const USER_VISIBLE_FAIL = "USER_VISIBLE_FAIL";
//tabs
export const USER_TAB_REQUEST = "USER_TAB_REQUEST";
export const USER_TAB_SUCCESS = "USER_TAB_SUCCESS";
export const USER_TAB_FAIL = "USER_TAB_FAIL";
//user
export const USER_DETAILS_REQUEST = "USER_DETAILS_REQUEST";
export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS";
export const USER_DETAILS_FAIL = "USER_DETAILS_FAIL";
export const USER_RESET = "USER_RESET";
export const USER_RETAIN = "USER_RETAIN";
//edit
export const USER_EDIT_REQUEST = "USER_EDIT_REQUEST";
export const USER_EDIT_SUCCESS = "USER_EDIT_SUCCESS";
export const USER_EDIT_FAIL = "USER_EDIT_FAIL";
export const RESET_ORGANIZATION = "RESET_ORGANIZATION";
