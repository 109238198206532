import React, { Component, useState } from "react";
import SunEditor, { buttonList } from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { Grid, Typography, Button } from "@material-ui/core";
import Breadcrumb from "components/Breadcrumbs/Breadcrumb";
import Styles from "./Styles";
import { withStyles } from "@material-ui/styles";
import Paper from "@material-ui/core/Paper";
// import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import TextField from "@material-ui/core/TextField";
import getURL from "../../api/config";
import APIs from "api/ApplicationAPIs/index";
import API_helpers from "../../api/index";
import { withSnackbar } from "notistack";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { Draggable, DragDropContext, Droppable } from "react-beautiful-dnd";
import DragComponent from "./DraggabeleComponent";
import {
  align,
  font,
  fontColor,
  fontSize,
  formatBlock,
  hiliteColor,
  horizontalRule,
  lineHeight,
  list,
  paragraphStyle,
  table,
  template,
  textStyle,
  image,
  link,
} from "suneditor/src/plugins";
import { FaChevronRight, FaChevronDown } from "react-icons/fa";
import Helper from "./helper";
const SubSection = ({
  classes,
  item,
  index,
  handleClick,
  state,
  handleDynoSectioNameChange,
  onBlur,
  handleSectionClick,
  handleSectionDelete,
  handleSubSectionName,
  createSubSection,
}) => {
  let currentSection = item;
  let id = item._id;

  const [itemsVal, setItems] = useState(currentSection.sections);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      itemsVal,
      result.source.index,
      result.destination.index
    );

    setItems(items);
  };

  return (
    <>
      <Grid container>
        <Grid md={11}>
          <ListItem
            button
            onClick={(e) => {
              // edit

              handleClick(item._id, e, index);
            }}
            className={classes.dropDownList}
          >
            <ListItemIcon className={classes.dropDownListIcon}>
              {/* {this.state.DropDown ? ( */}
              {/* <ArrowDropDownIcon
                  style={{ width: 30, height: 30 }}
                  className={classes.sideBarIcon}
                />
              ) : ( */}
              {!state.slide[index] ? (
                <FaChevronRight className={classes.sideBarIcon} />
              ) : (
                <FaChevronDown className={classes.sideBarIcon} />
              )}

              {/* )} */}
            </ListItemIcon>
            {/* edit  */}
            {state["dynamicSectionEdit" + id] === true && (
              <TextField
                // onBlur={() => onBlur("dynamicSectionEdit", id, false)}
                variant="standard"
                autoFocus="on"
                value={state["dynamicSectionsName" + id]}
                onChange={(e) => handleDynoSectioNameChange(id, e)}
              />
            )}
            {state["dynamicSectionEdit" + id] === false && (
              <ListItemText
                primary={currentSection.name}
                className={classes.paperLeftSideBar}
              />
            )}
            {/* {this.state[id] ? <ExpandLess /> : <ExpandMore />} */}
          </ListItem>
          {state.slide[index] ? (
            <Collapse in={state[id]} timeout="auto" unmountOnExit>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <List component="div" innerRef={provided.innerRef}>
                      {currentSection.sections != null &&
                        itemsVal.map((value, key) => {
                          let subId = value._id;
                          return (
                            <Grid container>
                              <Grid md={11}>
                                <Draggable
                                  key={value._id}
                                  draggableId={value._id}
                                  index={key}
                                >
                                  {(provided) => (
                                    <ListItem
                                      ContainerComponent="li"
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      key={key}
                                      selected={state.selectedIndex === subId}
                                      buttonv
                                      onClick={(e) => {
                                        handleSectionClick(
                                          value._id,
                                          e,
                                          key,
                                          true
                                        );
                                      }}
                                      className={classes.innerNestedHeading}
                                    >
                                      {state["dynamicSectionEdit" + subId] ===
                                        true && (
                                        <TextField
                                          // onBlur={() => {
                                          //   onBlur("dynamicSectionEdit", subId, false);
                                          // }}
                                          variant="standard"
                                          autoFocus="on"
                                          value={
                                            state["dynamicSectionsName" + subId]
                                          }
                                          onChange={(e) => {
                                            handleDynoSectioNameChange(
                                              subId,
                                              e
                                            );
                                          }}
                                        />
                                      )}
                                      {state["dynamicSectionEdit" + subId] ===
                                        false && (
                                        <>
                                          {/* <ArrowForwardIosIcon
                                className={classes.sideBarIcon}
                                style={{ fontSize: "12px" }}
                              /> */}
                                          <ListItemText primary={value.name} />
                                          <DeleteOutlinedIcon
                                            onClick={(e) =>
                                              handleSectionDelete(subId, e)
                                            }
                                            style={{
                                              marginTop: "8px",
                                              fontWeight: "800",
                                              color: "red",
                                              cursor: "pointer",
                                            }}
                                          />
                                        </>
                                      )}
                                    </ListItem>
                                  )}
                                </Draggable>
                              </Grid>
                            </Grid>
                          );
                        })}
                      {state.createSubSectionFlag === true && (
                        <ListItem className={classes.innerNestedHeading}>
                          <TextField
                            autoFocus
                            value={state.subSectionName}
                            onChange={handleSubSectionName}
                          />
                        </ListItem>
                      )}
                      {state.createSubSectionFlag === false && (
                        <ListItem
                          button
                          onClick={(e) => createSubSection(id, e)}
                          className={classes.innerNestedHeading}
                        >
                          <AddOutlinedIcon
                            role="button"
                            style={{
                              fontSize: "18px",
                              border: "2px solid #2185d0",
                              borderRadius: "50px",
                              color: "#2185d0",
                            }}
                          />
                          &nbsp;
                          <ListItemText primary="Sub section" />
                        </ListItem>
                      )}
                    </List>
                  )}
                </Droppable>
              </DragDropContext>
            </Collapse>
          ) : null}
        </Grid>
        <Grid md={1} style={{ paddingTop: "5px" }}>
          <DeleteOutlinedIcon
            onClick={(e) => handleSectionDelete(id, e)}
            style={{ fontWeight: "800", color: "red", cursor: "pointer" }}
          />
        </Grid>
      </Grid>
    </>
  );
};

class Create extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slide: {},
      OnlyDisplay: false,
      open: false,
      createSection: false,
      sectionName: "",
      createDocumentName: false,
      htmlContent: "",
      sections: [],
      submitBtn: true,
      updateBtn: false,
      sectionId: "",
      selectedIndex: "",
      createSubSectionFlag: false,
      subSectionName: "",
      parentSectionId: "",
      docName: "",
      viewUsageDocData: {},
      items: "",
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleCreateSection = this.handleCreateSection.bind(this);
    this.handleSectioNameChange = this.handleSectioNameChange.bind(this);
    this.handleHtmlContentChange = this.handleHtmlContentChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    // this.onAllowDropInsideItemChanged =
    //   this.onAllowDropInsideItemChanged.bind(this);
    // this.onAllowReorderingChanged = this.onAllowReorderingChanged.bind(this);
    // this.onShowDragIconsChanged = this.onShowDragIconsChanged.bind(this);
  }

  componentDidMount() {
    const { location } = this.props;
    const { viewUsageDocData } = location.state;
    const { usageDoc } = viewUsageDocData || { usageDoc: { name: "" } };
    console.log("jhgsadjhgsdh", viewUsageDocData, usageDoc);
    this.setState({
      docName: usageDoc.name,
      viewUsageDocData: { ...viewUsageDocData },
    });
    this.fetchSections();
  }

  async fetchSections() {
    const appId = this.props.match.params.id;
    let url = getURL("GET_SECTIONS_USAGE")(appId);
    try {
      let res = await API_helpers.GET(url, {
        headers: { "Content-Type": "application/json" },
      });

      console.log("Fetched sections: ", res);

      let sections = [];
      if (res.data.data) {
        for (let i = 0; i < res.data.data.length; i++) {
          let item = res.data.data[i];
          if (item.type === "usage") {
            sections.push(item);
          }
        }
      }
      this.setState({
        sections: sections,
      });
      let collectionOfIds = [];
      if (this.state.sections != null) {
        this.state.sections.map(function (item, index) {
          this.setState({
            [item._id]: false,
            ["dynamicSectionsName" + item._id]: item.name,
            ["dynamicSectionEdit" + item._id]: false,
          });
          collectionOfIds.push(item._id);
          if (item.sections != null) {
            let subSections = item.sections;
            subSections.map(function (value, key) {
              this.setState({
                [value._id]: false,
                ["dynamicSectionsName" + value._id]: value.name,
                ["dynamicSectionEdit" + value._id]: false,
              });
              collectionOfIds.push(value._id);
            }, this);
          }
        }, this);
        this.setState({
          collectionOfIds: collectionOfIds,
        });
      }
      //return (res.data || {}).data || [];
    } catch (err) {
      throw err;
    }
  }

  async handleSectionClick(id, e, index, subsection = false) {
    // close all sections first only if not subsection
    if (!subsection) {
      let sections = this.state.sections || [];
      for (let i = 0; i < sections.length; i++) {
        let sec_id = sections[i]._id;
        if (id !== sec_id) {
          this.setState({
            [sec_id]: false,
            createSubSectionFlag: false,
          });
        }
      }
    }

    if (subsection) {
      this.setState({
        createSubSectionFlag: false,
      });
    }

    const appId = this.props.match.params.id;
    let url = getURL("GET_SECTION_CONTENT")(appId, id);
    try {
      let res = await API_helpers.GET(url, {
        headers: { "Content-Type": "application/json" },
      });

      this.state.collectionOfIds.map((doc_id) => {
        this.setState({
          ["dynamicSectionEdit" + doc_id]: id !== doc_id ? false : true,
        });
      });

      this.setState({
        // [id]: !this.state[id],
        [id]: true,
        htmlContent: res.data.data.content,
        sectionId: id,
        submitBtn: false,
        updateBtn: true,
        createSection: false,
        selectedIndex: id,
        // ["dynamicSectionEdit" + id]: !this.state["dynamicSectionEdit" + id],
      });
      console.log("this.state:", this.state);
      //return (res.data || {}).data || [];
    } catch (err) {
      throw err;
    }
  }

  // async handleSectionClick(id, e, index) {
  //   const appId = this.props.match.params.id;
  //   let url = getURL("GET_SECTION_CONTENT")(appId, id);
  //   try {
  //     let res = await API_helpers.GET(url, {
  //       headers: { "Content-Type": "application/json" },
  //     });

  //     this.setState({
  //       htmlContent: res.data.data.content,
  //       sectionId: id,
  //       submitBtn: false,
  //       updateBtn: true,
  //       createSection: false,
  //       selectedIndex: id,
  //       ["dynamicSectionEdit" + id]: !this.state["dynamicSectionEdit" + id],
  //     });
  //     console.log("this.state:", this.state);
  //     //return (res.data || {}).data || [];
  //   } catch (err) {
  //     throw err;
  //   }
  // }

  handleClick = (id, e, index_) => {
    this.setState({
      slide: {
        [index_]: !this.state.slide[index_],
      },
    });
    this.handleSectionClick(id, e, 0);
    // this.setState({
    //   DropDown: true,
    //   [id]: !this.state[id],
    //   sectionId: id,
    //   submitBtn: false,
    //   updateBtn: true,
    //   createSection: false,
    //   selectedIndex: id,
    //   ["dynamicSectionEdit" + id]: !this.state["dynamicSectionEdit" + id],
    //   htmlContent: "",
    // });
  };
  handleCreateSection() {
    this.setState({
      createSection: true,
      sectionId: "",
      htmlContent: "",
      submitBtn: true,
      updateBtn: false,
      slide: {},
    });

    this.state.collectionOfIds.map((doc_id) => {
      this.setState({
        ["dynamicSectionEdit" + doc_id]: false,
      });
    });
  }

  handleSectioNameChange = (event) => {
    this.setState({
      sectionName: event.target.value,
    });
  };

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  onDragEnd(result) {
    // if item dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = this.reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );

    this.setState({
      items,
    });
  }

  handleDynoSectioNameChange = (id, event) => {
    this.setState({
      ["dynamicSectionsName" + id]: event.target.value,
    });
  };

  handleSectionDelete = async (id, e) => {
    console.log("jsghjgshgfsga", this.props);
    const appId = this.props.match.params.id;
    let url = getURL("DELETE_SECTION")(appId, id);
    try {
      let res = await API_helpers.DELETE(url, {
        headers: { "Content-Type": "application/json" },
      });
      this.props.enqueueSnackbar(res.data._msg, {
        variant: "success",
      });
      this.setState({
        slide: {},
        OnlyDisplay: false,
        open: false,
        createSection: false,
        sectionName: "",
        createDocumentName: false,
        htmlContent: "",
        sections: [],
        submitBtn: true,
        updateBtn: false,
        sectionId: "",
        selectedIndex: "",
        createSubSectionFlag: false,
        subSectionName: "",
        parentSectionId: "",
      });
      this.fetchSections();
    } catch (err) {
      this.props.enqueueSnackbar(
        err?.response?.data._msg || "Connection error!",
        {
          variant: "error",
        }
      );
      // window.location.reload();
      throw err;
    }
  };

  handleHtmlContentChange = (event) => {
    this.setState({
      htmlContent: event,
    });
  };

  handleSectionEdit = (id, e) => {
    this.setState({
      ["dynamicSectionEdit" + id]: !this.state["dynamicSectionEdit" + id],
    });
  };

  display_msg = (msg, variant) => {
    this.props.enqueueSnackbar(msg, {
      variant: variant ? "success" : "error",
    });
  };

  initialState = () => {
    this.setState({
      parentSectionId: null,
      subSectionName: null,
    });
  };

  handle_submit = async () => {
    const applicationId = this.props.match.params.id;
    const content =
      this.state.htmlContent === "<p><br></p>" ? "" : this.state.htmlContent;
    const payload = {
      name: this.state.subSectionName,
      content: content,
      sectionId: this.state.parentSectionId,
      type: "usage",
      documentId: this.state.viewUsageDocData?.usageDoc?._id || undefined,
    };
    try {
      const res = await APIs.add_app_document(payload, applicationId);
      this.display_msg(res.data._msg, true);
      this.initialState();
      this.fetchSections();
    } catch (err) {
      const msg = err?.response?.data?._msg || "Connection error!";
      this.display_msg(msg, false);
      this.initialState();
    }
  };

  addSubSection = (section) => {
    console.log("item clicked", section);
    this.setState({
      parentSectionId: section.id,
    });
  };

  onChangeSubSection = (value) => {
    this.setState({
      subSectionName: value,
    });
  };

  async handleSubmit() {
    const appId = this.props.match.params.id;
    let url = getURL("ADD_DOCUMENT")(appId);
    let sectionName = "";
    console.log("parent mulpa ulle", this.state.parentSectionId);

    if (this.state.parentSectionId !== "") {
      sectionName = this.state.subSectionName;
    } else {
      sectionName = this.state.sectionName;
    }
    if (sectionName.trim().length === 0) {
      this.props.enqueueSnackbar("Section name can not be empty", {
        variant: "error",
      });
      return;
    }
    const content =
      this.state.htmlContent === "<p><br></p>" ? "" : this.state.htmlContent;
    try {
      let res = await API_helpers.POST(
        url,
        {
          name: sectionName,
          content: content,
          sectionId: this.state.parentSectionId,
          type: "usage",
          documentId: this.state.viewUsageDocData?.usageDoc?._id || undefined,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      this.props.enqueueSnackbar(res.data._msg, {
        variant: "success",
      });
      // window.location.reload();
      this.setState({
        slide: {},
        OnlyDisplay: false,
        open: false,
        createSection: false,
        sectionName: "",
        createDocumentName: false,
        htmlContent: "",
        sections: [],
        submitBtn: true,
        updateBtn: false,
        sectionId: "",
        selectedIndex: "",
        createSubSectionFlag: false,
        subSectionName: "",
        parentSectionId: "",
      });
      this.fetchSections();
    } catch (err) {
      this.props.enqueueSnackbar(
        err?.response?.data?._msg || "Connection error!",
        {
          variant: "error",
        }
      );
    }
  }

  async handleUpdate() {
    const appId = this.props.match.params.id;
    let url = getURL("UPDATE_DOCUMENTATION")(appId, this.state.sectionId);
    let activeSectionId = this.state.sectionId;
    const content =
      this.state.htmlContent === "<p><br></p>" ? "" : this.state.htmlContent;
    try {
      let res = await API_helpers.PUT(
        url,
        {
          name: this.state["dynamicSectionsName" + activeSectionId],
          content: content,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      this.props.enqueueSnackbar(res.data._msg, {
        variant: "success",
      });
      this.setState({
        slide: {},
        OnlyDisplay: false,
        open: false,
        createSection: false,
        sectionName: "",
        createDocumentName: false,
        htmlContent: "",
        sections: [],
        submitBtn: true,
        updateBtn: false,
        sectionId: "",
        selectedIndex: "",
        createSubSectionFlag: false,
        subSectionName: "",
        parentSectionId: "",
      });
      this.fetchSections();
    } catch (err) {
      this.props.enqueueSnackbar(
        err?.response?.data?._msg || "Connection error!",
        {
          variant: "error",
        }
      );
      throw err;
    }
    // window.location.reload();
  }

  createSubSection = (id, e) => {
    this.setState({
      submitBtn: true,
      updateBtn: false,
      createSubSectionFlag: !this.state.createSubSectionFlag,
      parentSectionId: id,
      htmlContent: "",
    });
    this.handleSectionClick(id);
  };

  handleSubSectionName = (e) => {
    this.setState({
      subSectionName: e.target.value,
    });
  };

  onBlur = (str, id_, bool) => {
    this.setState({
      [str + id_]: bool,
    });
  };
  // onDragChange(e) {
  //   const visibleRows = e.component.getVisibleRows();
  //   const sourceNode = e.component.getNodeByKey(e.itemData.ID);
  //   let targetNode = visibleRows[e.toIndex].node;

  //   while (targetNode && targetNode.data) {
  //     if (targetNode.data.ID === sourceNode.data.ID) {
  //       e.cancel = true;
  //       break;
  //     }
  //     targetNode = targetNode.parent;
  //   }
  // }

  // onReorder(e) {
  //   const visibleRows = e.component.getVisibleRows();
  //   let sourceData = e.itemData;
  //   let { employees } = this.state;
  //   const sourceIndex = employees.indexOf(sourceData);

  //   if (e.dropInsideItem) {
  //     sourceData = { ...sourceData, Head_ID: visibleRows[e.toIndex].key };
  //     employees = [
  //       ...employees.slice(0, sourceIndex),
  //       sourceData,
  //       ...employees.slice(sourceIndex + 1),
  //     ];
  //   } else {
  //     const toIndex = e.fromIndex > e.toIndex ? e.toIndex - 1 : e.toIndex;
  //     let targetData = toIndex >= 0 ? visibleRows[toIndex].node.data : null;

  //     if (targetData && e.component.isRowExpanded(targetData.ID)) {
  //       sourceData = { ...sourceData, Head_ID: targetData.ID };
  //       targetData = null;
  //     } else {
  //       const headId = targetData ? targetData.Head_ID : -1;
  //       if (sourceData.Head_ID !== headId) {
  //         sourceData = { ...sourceData, Head_ID: headId };
  //       }
  //     }

  //     employees = [
  //       ...employees.slice(0, sourceIndex),
  //       ...employees.slice(sourceIndex + 1),
  //     ];

  //     const targetIndex = employees.indexOf(targetData) + 1;
  //     employees = [
  //       ...employees.slice(0, targetIndex),
  //       sourceData,
  //       ...employees.slice(targetIndex),
  //     ];
  //   }

  //   this.setState({
  //     employees,
  //   });
  // }

  // onAllowDropInsideItemChanged(args) {
  //   this.setState({
  //     allowDropInsideItem: args.value,
  //   });
  // }

  // onAllowReorderingChanged(args) {
  //   this.setState({
  //     allowReordering: args.value,
  //   });
  // }

  // onShowDragIconsChanged(args) {
  //   this.setState({
  //     showDragIcons: args.value,
  //   });
  // }

  render() {
    const { classes } = this.props;
    const { docName, viewUsageDocData } = this.state;
    console.log("usage documents state", this.state.sections);
    return (
      <>
        <Grid container>
          <Grid item sm={12} md={12} xs={12} lg={12}>
            <Breadcrumb
              {...this.props}
              links={[
                { name: "Applications", url: "/app/applications" },

                {
                  name: `${docName}`,
                  url: "/app/create-doc",
                  routeParams: { data: viewUsageDocData },
                },
                {
                  name: `${"Editor"}`,
                },
              ]}
            />
          </Grid>
        </Grid>

        <Grid container spacing={0}>
          <Grid item sm={12} md={12} xs={12} lg={12}>
            <Grid
              container
              spacing={1}
              direction="row"
              alignItems="center"
              className={classes.root}
            >
              <Grid item md={9}>
                <div>
                  <Typography variant="h6" className={classes.title}>
                    {`${docName}`}
                  </Typography>

                  {this.state.createDocumentName && (
                    <TextField label="Document name" variant="standard" />
                  )}
                </div>
              </Grid>
              <Grid item sm={3} container justifyContent="flex-end">
                <Grid item>
                  {this.state.submitBtn && (
                    <Button
                      size="small"
                      variant="contained"
                      type="button"
                      onClick={this.handleSubmit}
                    >
                      {"Submit"}
                    </Button>
                  )}
                  {this.state.updateBtn && (
                    <Button
                      size="small"
                      variant="contained"
                      type="button"
                      onClick={this.handleUpdate}
                    >
                      {"Update"}
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Paper className={classes.paperBody}>
          <Grid container spacing={0}>
            <Grid item sm={3} md={3} xs={3} lg={3}>
              <Grid container style={{ borderBottom: "2px solid #eee" }}>
                <Grid item md={11}>
                  <Typography className={classes.paperLeftTitle}>
                    {" "}
                    Sections{" "}
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={1}
                  style={{ paddingTop: "12px", color: "#262626" }}
                >
                  <AddOutlinedIcon
                    role="button"
                    onClick={this.handleCreateSection}
                  />
                </Grid>
              </Grid>

              <Grid>
                <Collapse in={this.state} timeout="auto" unmountOnExit>
                  <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId="droppable">
                      {/* <TreeList
                  id="employees"
                  dataSource={this.state.employees}
                  rootValue={-1}
                  keyExpr="ID"
                  showRowLines={true}
                  showBorders={true}
                  parentIdExpr="Head_ID"
                  defaultExpandedRowKeys={expandedRowKeys}
                  columnAutoWidth={true}
                >
                  <RowDragging
                    onDragChange={this.onDragChange}
                    onReorder={this.onReorder}
                    allowDropInsideItem={this.state.allowDropInsideItem}
                    allowReordering={this.state.allowReordering}
                    showDragIcons={this.state.showDragIcons}
                  /> */}

                      {this.state.createSection && (
                        <ListItem>
                          <TextField
                            label="Section name"
                            variant="standard"
                            autoFocus="on"
                            value={this.state.sectionName}
                            onChange={this.handleSectioNameChange}
                          />
                        </ListItem>
                      )}
                      {/* <DragComponent /> */}
                      <DragComponent
                        // key={index}
                        classes={classes}
                        // item={item}
                        // index={index}
                        handleClick={this.handleClick}
                        handleDynoSectioNameChange={
                          this.handleDynoSectioNameChange
                        }
                        onBlur={this.onBlur}
                        handleSectionClick={this.handleSectionClick}
                        handleSectionDelete={this.handleSectionDelete}
                        handleSubSectionName={this.handleSubSectionName}
                        createSubSection={this.createSubSection}
                        state={this.state}
                        value={this.state.sectionName}
                        data={this.state.sections}
                        onChange={this.handleSectioNameChange}
                        handle_submit={this.handle_submit}
                        onChangeSubSection={this.onChangeSubSection}
                        addSubSection={this.addSubSection}
                        parentId={this.state.parentSectionId}
                        {...this.props}
                      />
                      {/* </TreeList> */}
                      {this.state.sections &&
                        this.state.sections.map((item, index) => (
                          // if (false) {
                          //   return (
                          //     <Grid container>
                          //       <Grid md={11}>
                          //         <ListItem
                          //           selected={this.state.selectedIndex === id}
                          //           onClick={(e) =>
                          //             this.handleSectionClick(item._id, e, index)
                          //           }
                          //           button
                          //           className={classes.dropDownList}
                          //           key={index}
                          //         >
                          //           <ListItemIcon
                          //             className={classes.dropDownListIcon}
                          //           >
                          //             <ArrowForwardIosIcon
                          //               className={classes.sideBarIcon}
                          //             />
                          //           </ListItemIcon>
                          //           {this.state["dynamicSectionEdit" + id] ===
                          //             true && (
                          //             <TextField
                          //               onBlur={() => {
                          //                 this.setState({
                          //                   ["dynamicSectionEdit" + id]: false,
                          //                 });
                          //               }}
                          //               variant="standard"
                          //               autoFocus="on"
                          //               value={this.state["dynamicSectionsName" + id]}
                          //               onChange={(e) =>
                          //                 this.handleDynoSectioNameChange(id, e)
                          //               }
                          //             />
                          //           )}
                          //           {this.state["dynamicSectionEdit" + id] ===
                          //             false && (
                          //             <ListItemText
                          //               primary={item.name}
                          //               className={classes.paperLeftSideBar}
                          //             />
                          //           )}
                          //         </ListItem>
                          //       </Grid>
                          //       <Grid md={1} style={{ paddingTop: "5px" }}>
                          //         <DeleteOutlinedIcon
                          //           onClick={(e) => this.handleSectionDelete(id, e)}
                          //           style={{
                          //             marginTop: "8px",
                          //             fontWeight: "800",
                          //             color: "red",
                          //             cursor: "pointer",
                          //           }}
                          //         />
                          //       </Grid>
                          //     </Grid>
                          //   );
                          // } else {
                          // return this.subsection(classes, item, index);
                          <SubSection
                            key={index}
                            classes={classes}
                            item={item}
                            index={index}
                            handleClick={this.handleClick}
                            handleDynoSectioNameChange={
                              this.handleDynoSectioNameChange
                            }
                            onBlur={this.onBlur}
                            handleSectionClick={this.handleSectionClick}
                            handleSectionDelete={this.handleSectionDelete}
                            handleSubSectionName={this.handleSubSectionName}
                            createSubSection={this.createSubSection}
                            state={this.state}
                            value={this.state.sectionName}
                            onChange={this.handleSectioNameChange}
                            {...this.props}
                          />
                          // <></>

                          // }
                        ))}
                    </Droppable>
                  </DragDropContext>
                </Collapse>
                {/* <ListItem button onClick={() => this.handleClick('001')} className={classes.dropDownList}>
                  <ListItemIcon className={classes.dropDownListIcon}>
                    <ArrowForwardIosIcon className={classes.sideBarIcon} />
                  </ListItemIcon>
                  <ListItemText primary="Wikipedia" className={classes.paperLeftSideBar} />
                  {this.state.open ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={this.state.open} timeout="auto" unmountOnExit>
                  <List component="div">
                    <ListItem button className={classes.innerNestedHeading}>
                      <ArrowForwardIosIcon className={classes.sideBarIcon} style={{ fontSize: "12px" }} />
                      &nbsp;<ListItemText primary="Testing1" />
                    </ListItem>

                    <ListItem button className={classes.innerNestedHeading} >
                      <AddOutlinedIcon role="button" style={{ fontSize: "18px", border: "2px solid #2185d0", borderRadius: "50px", color: "#2185d0" }} />
                      &nbsp;<ListItemText primary="Add sub section" />
                    </ListItem>
                  </List>
                </Collapse> */}
              </Grid>
            </Grid>
            <Grid item sm={9} md={9} xs={9} lg={9}>
              <SunEditor
                // value={this.state.htmlContent}
                onChange={this.handleHtmlContentChange}
                setOptions={{
                  minHeight: "63vh",
                  placeholder: "Please type here...",
                  plugins: [
                    align,
                    font,
                    fontColor,
                    fontSize,
                    formatBlock,
                    hiliteColor,
                    horizontalRule,
                    lineHeight,
                    list,
                    paragraphStyle,
                    table,
                    template,
                    textStyle,
                    image,
                    link,
                  ],
                  buttonList: [
                    ["undo", "redo"],
                    ["font", "fontSize", "formatBlock"],
                    ["paragraphStyle"],
                    [
                      "bold",
                      "underline",
                      "italic",
                      "strike",
                      "subscript",
                      "superscript",
                    ],
                    ["fontColor", "hiliteColor"],
                    ["removeFormat"],
                    "/", // Line break
                    ["outdent", "indent"],
                    ["align", "horizontalRule", "list", "lineHeight"],
                    ["table", "link", "image"],
                  ],
                  formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
                  font: [
                    "Arial",
                    "Calibri",
                    "Comic Sans",
                    "Courier",
                    "Garamond",
                    "Georgia",
                    "Impact",
                    "Lucida Console",
                    "Palatino Linotype",
                    "Segoe UI",
                    "Tahoma",
                    "Times New Roman",
                    "Trebuchet MS",
                  ],
                }}
                setContents={this.state.htmlContent}
              />
            </Grid>
          </Grid>
        </Paper>
      </>
    );
  }
}

export default withStyles(Styles)(withSnackbar(Create));
