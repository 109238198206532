import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Dialog, DialogContent, DialogActions } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { TextField, Button } from "@material-ui/core/";

// const useStyles = makeStyles({
//   avatar: {
//     backgroundColor: blue[100],
//     color: blue[600],
//   },
// });

function EditEndDateModal(props) {
  const [err, setErr] = useState({
    date_err: false,
    url_err: false,
  });

  const { open, close, end_Date, setEndDate } = props;

  const submit_endDate = async () => {
    let err_obj = {
      date_err: end_Date.endDate.trim().length === 0,
      url_err:
        end_Date.customerUrl !== null &&
        end_Date.customerUrl.trim().length === 0,
    };
    if (err_obj.date_err || err_obj.url_err) {
      setErr({ ...err_obj });
      return;
    }
    await props.submitEndDate(end_Date);
    close();
  };

  let value = end_Date.endDate.split("T")[0];

  return (
    <Dialog
      // fullWidth
      // maxWidth={"md"}
      onClose={close}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={open}
    >
      <DialogTitle>
        {/* <Typography>{"Extend End Time"}</Typography> */}
      </DialogTitle>

      <DialogContent
        style={{
          height: "50%",
          width: "450px",
          marginTop: "30px",
        }}
      >
        <div>
          <Typography>End Date:</Typography>
          <TextField
            id="outlined-search"
            name="endDate"
            type="date"
            variant="outlined"
            size="small"
            fullWidth
            inputProps={{
              min: new Date().toISOString().slice(0, 10),
            }}
            margin="normal"
            onChange={(e) => {
              setErr({
                ...err,
                date_err: false,
              });
              setEndDate({ ...end_Date, endDate: e.target.value });
            }}
            value={value}
            error={err.date_err}
            helperText={
              <span style={{ color: "red" }}>
                {err.date_err && "Please select date"}
              </span>
            }
          />
        </div>
        <br />
        {end_Date.customerUrl !== null && (
          <div>
            <Typography>URL:</Typography>
            <TextField
              id="outlined-search"
              name="url"
              type="text"
              variant="outlined"
              size="small"
              fullWidth
              margin="normal"
              onChange={(e) => {
                setErr({
                  ...err,
                  url_err: false,
                });
                setEndDate({ ...end_Date, customerUrl: e.target.value });
              }}
              value={end_Date.customerUrl}
              error={err.url_err}
              helperText={
                <span style={{ color: "red" }}>
                  {err.url_err && "Please enter URL"}
                </span>
              }
            />
          </div>
        )}
      </DialogContent>

      <DialogActions>
        <Button
          style={{
            marginLeft: 0,
            // backgroundColor: "transparent",
            // boxShadow: "none",
            // color: "#9e9e9e",
          }}
          size="small"
          variant="contained"
          color="primary"
          onClick={() => {
            submit_endDate();
          }}
        >
          Submit
        </Button>
        <Button
          style={{
            marginLeft: 0,
            backgroundColor: "transparent",
            boxShadow: "none",
            color: "#9e9e9e",
          }}
          variant="contained"
          color="primary"
          onClick={() => {
            setErr(false);
            close();
          }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditEndDateModal;
