import * as types from "./actionTypes";
// import Immutable from "seamless-immutable";
const initialState = {
  applicationData: [],
  searchedValue: "",
  message: "",
  loading: false,
  totalCount: 0,
  totalPublished: 0,
  filterProps: {
    filters: {},
    filterArray: [{ key: 1, columns: "", operator: "", value: "" }],
    sort: { createdon: -1 },
    sorting: [{ columnName: "createdOn", direction: "desc" }],
  },
  hidden: ["shortKey", "applicationType", "createdon"],
  //  message: {},
};
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.FETCHING_APPLICATIONS:
      return {
        ...state,
        action: action.type,
        loading: true,
      };
    case types.FETCHING_APPLICATIONS_SUCCESS:
      return {
        ...state,
        applicationData: action.payload,
        totalCount:
          action.num !== 1 && action.num !== "nullArray"
            ? action.totalCount
            : state.totalCount,
        action: action.type,
        totalPublished:
          action.num === "nullArray" || action.num === 1
            ? action.totalCount
            : state.totalPublished,
        loading: false,
      };
    case types.FECTH_APPLICATIONS_FAILED:
      return {
        ...state,
        loading: false,
        action: action.type,
        // totalPublished: 0,
        // totalCount: 0,
        // applicationData: [],
      };
    case types.UPDATE_APPLICATION_FILTER_DATA:
      return {
        ...state,
        filterProps: {
          ...state.filterProps,
          filters: action.payload.filter,
          filterArray: action.payload.filterArray,
        },
      };
    case types.UPDATE_APP_SORT:
      return {
        ...state,
        filterProps: {
          ...state.filterProps,
          sorting: action.payload.sorting,
          sort: action.payload.sort,
        },
      };
      case types.UPDATE_COLUMN_HIDDEN:
        return{
          ...state,
          hidden:action.payload
        }
    case types.STORE_SEARCHED_VALUE:
      return {
        ...state,
        searchedValue: action.payload,
      };
    default:
      return state;
  }
}
