import getURL from "../config";
import API_helpers from "../index";

async function fetchAllUserManagement(id) {
  let url = getURL("FETCH_USERMANAGEMENT");
  url = id ? `${url}/${id}` : url;
  try {
    let res = await API_helpers.GET(url, {
      headers: {
        "Content-Type": "application/json",
        userdetails: JSON.stringify({ name: "palani" }),
        organization: "11111",
      },
    });
    return (res.data || {}).data || [];
  } catch (err) {
    throw err;
  }
}

async function fetchAllUserManagementWithParams(page, size, filters, sort) {
  let url = getURL("FETCH_USERMANAGEMENT");
  url = url + "?";
  let params = { page, size, filters, sort };
  Object.keys(params)
    .filter((k) => params[k] != undefined || params[k] != null)
    .forEach((k) => (url = `${url}${k}=${params[k]}&`));
  url = url.substr(0, url.length - 1);
  console.log(url, "urll");
  try {
    let res = await API_helpers.GET(url, {
      headers: { "Content-Type": "application/json", organization: "11111" },
    });
    return res.data;
  } catch (err) {
    throw err;
  }
}

async function fetchAllUserManagementRoles() {
  let url = getURL("FETCH_USERMANAGEMENT_ROLES");
  try {
    let res = await API_helpers.GET(url, {
      headers: {
        "Content-Type": "application/json",
        userdetails: JSON.stringify({ name: "palani" }),
        organization: "11111",
      },

      // userdetails: JSON.stringify({ name: "palani" }),
    });
    return res;
  } catch (err) {
    throw err;
  }
}

async function addUserManagement(payload) {
  let url = getURL("ADD_USERMANAGEMENT");
  try {
    let res = await API_helpers.POST(url, payload, {
      headers: {
        "Content-Type": "application/json",
        organization: "11111",
        userdetails: JSON.stringify({ name: "palani" }),
      },
    });
    return res.data;
  } catch (err) {
    throw err;
  }
}

async function editUserManagement(userManagementId, payload) {
  let url = getURL("EDIT_USERMANAGEMENT")(userManagementId);
  try {
    let res = await API_helpers.PUT(url, payload, {
      headers: {
        "Content-Type": "application/json",
        userdetails: JSON.stringify({ name: "palani" }),
        organization: "11111",
      },
    });
    return res.data;
  } catch (err) {
    throw err;
  }
}

async function deleteUserManagement(userManagementId) {
  let url = getURL("DELETE_USERMANAGEMENT")(userManagementId);
  try {
    let res = await API_helpers.DELETE(url, {
      headers: {
        "Content-Type": "application/json",
        userdetails: JSON.stringify({ name: "palani" }),
        organization: "11111",
      },
    });
    return res;
  } catch (err) {
    throw err;
  }
}

async function blockUserManagement(userManagementId, type) {
  let url = getURL("BLOCK_USERMANAGEMENT")(userManagementId, type);
  try {
    let res = await API_helpers.PUT(
      url,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          userdetails: JSON.stringify({ name: "palani" }),
          organization: "11111",
        },
      }
    );
    return res;
  } catch (err) {
    throw err;
  }
}

const editUserRole = (type, id, payload) => {
  try {
    let res = API_helpers.POST(getURL("USER_ROLES_ADD")(id), payload, {
      headers: {
        "Content-Type": "application/json",
        userdetails: JSON.stringify({ name: "palani" }),
        organization: "11111",
      },
    });
    return res;
  } catch (err) {
    throw err;
  }
};

const deleteUserRole = (id, payload) => {
  try {
    let res = API_helpers.POST(getURL("USER_ROLES_DELETE")(id), payload, {
      headers: {
        "Content-Type": "application/json",
        userdetails: JSON.stringify({ name: "palani" }),
        organization: "11111",
      },
    });
    return res;
  } catch (err) {
    throw err;
  }
};

const projectsAssigned = (id) => {
  try {
    let res = API_helpers.GET(getURL("USER_ASSIGNEDAPPLICATIONS")(id), {
      headers: {
        "Content-Type": "application/json",
        userdetails: JSON.stringify({ name: "palani" }),
        organization: "11111",
      },
    });
    console.log(res, "oiuytrertyuiol");
    return res;
  } catch (err) {
    throw err;
  }
};
const getCountryCodes = () => {
  try {
    let res = API_helpers.GET(getURL("FETCH_COUNTRY_CODES"), {
      headers: {
        "Content-Type": "application/json",
        userdetails: JSON.stringify({ name: "palani" }),
        organization: "11111",
      },
    });
    console.log(res, "oiuytrertyuiol");
    return res;
  } catch (err) {
    throw err;
  }
};

var APIs = {
  fetchAllUserManagement,
  fetchAllUserManagementWithParams,
  fetchAllUserManagementRoles,
  addUserManagement,
  deleteUserManagement,
  editUserManagement,
  blockUserManagement,
  editUserRole,
  deleteUserRole,
  projectsAssigned,
  getCountryCodes,
};

export default APIs;
