import * as types from "./actionTypes";
import loginAPi from "../../../api/LoginApi";
export const changeTabByUser = (val) => async (dispatch) => {
  try {
    dispatch({
      type: types.USER_TAB_REQUEST,
    });
    dispatch({ type: types.USER_TAB_SUCCESS, payload: val });
  } catch (error) {
    dispatch({
      type: types.USER_TAB_FAIL,
      payload: error,
    });
  }
};

// export const userDetailAction = () => async (dispatch, getState) => {
//   try {
//     dispatch({
//       type: USER_DETAILS_REQUEST,
//     });
//     const {
//       authReducer: { userid },
//     } = getState();
//     const {
//       userDetails: { success },
//     } = getState();
//     const {
//       data: { data },
//     } = await _UserProfile.fetchDetails(userid);
//     dispatch({ type: USER_DETAILS_SUCCESS, payload: data });
//     // dispatch({ type: USER_VISIBLE_RESET });
//     dispatch({ type: USER_RETAIN });
//   } catch (error) {
//     dispatch({
//       type: USER_DETAILS_FAIL,
//       payload: error,
//     });
//   }
// };
export const userDetailAction = (userId) => async (dispatch) => {
  try {
    dispatch({
      type: types.USER_DETAILS_REQUEST,
    });
    const res = await loginAPi.fetchDetails(userId);
    const { data } = res;
    if (data) {
      dispatch({ type: types.USER_DETAILS_SUCCESS, payload: data });
    }
    // dispatch({ type: types.USER_VISIBLE_RESET });
    dispatch({ type: types.USER_RETAIN });
  } catch (error) {
    dispatch({
      type: types.USER_DETAILS_FAIL,
      payload: error,
    });
  }
};

// action for the user edit
export const editUserDetails = (val) => async (dispatch, getState) => {
  try {
    dispatch({
      type: types.USER_EDIT_REQUEST,
    });
    const {
      apearenceReducer: { userInfo },
    } = getState();
    const {
      apearenceReducer: { success },
    } = getState();
    const {
      data: { data, _status },
    } = await loginAPi.editDetails(userInfo._id, val);
    console.log(_status, "56789dfgchjhgfddsgdhf");
    dispatch({ type: types.USER_EDIT_SUCCESS, payload: data });
    dispatch({ type: types.USER_VISIBLE_RESET });
    await dispatch(userDetailAction(userInfo._id));
    return _status;
  } catch (error) {
    dispatch({
      type: types.USER_EDIT_FAIL,
      payload: error,
    });
    dispatch({ type: types.USER_VISIBLE_RESET });
  }
};
