// config all users here.
const baseURL = process.env.REACT_APP_BASE_URL + "api/v1.0.0/";
const bs_baseURL = process.env.REACT_APP_BASE_URL_BS + "api/v1.0.0/";
const baseURL_cdu = process.env.REACT_APP_BASE_URL_CDU + "api/v1.0.0/";
const baseURL_ctx = process.env.REACT_APP_BASE_URL_CTX + "api/v1.0.0/";
const baseURL_cm = process.env.REACT_APP_BASE_URL_CM + "api/v1.0.0/";
const baseURL_Login = process.env.REACT_APP_BASE_LOGIN + "api/v1.0.0/";
const baseURL_UM = process.env.REACT_APP_BASE_URL_UM + "api/v1.0.0/";
const baseURL_DS = process.env.REACT_APP_BASE_URL_DS + "api/v1.0.0/";
const baseURL_HD = process.env.REACT_APP_BASE_URL_HD + "api/v1.0.0/";
const baseURL_SP = process.env.REACT_APP_BASE_URL_SP + "api/v1.0.0/";
const baseURL_INVOICE = process.env.REACT_APP_BASE_URL_INVOICE + "api/v1.0.0/";
const ws_baseURL = process.env.REACT_APP_BASE_WS + "api/v1.0.0/";

// `ws://49.205.193.195:7100/api/v1.0.0/websocket?token=${token}`

// const customerSetting = process.env.REACT_APP_CUSTOMER_SETTING + "api/v1.0.0/";
// const baseURL = process.env.REACT_APP_BASE_URL;
// const bs_baseURL = process.env.REACT_APP_BASE_URL_BS;
// const baseURL_cdu = process.env.REACT_APP_BASE_URL_CDU ;

function getURL(api) {
  switch (api) {
    case "LOGIN":
      return `${baseURL_Login}users/login`;
    case "FORGET_PASSWORD":
      return `${baseURL_Login}users/forgetpassword`;

    case "RESET_PASSWORD_VERIFY_CODE":
      return `${baseURL_Login}users/verifycodeforforgetpassword`;

    case "FETCH_USER_DETAILS":
      return (userID) => {
        return `${baseURL_Login}users/${userID}`;
      };

    case "APPLICATIONS":
      return `${baseURL}applications`;
    case "FETCH_APPLICATION_DETAILS":
      return (appId) => {
        return `${baseURL}applications/${appId}`;
      };
    case "EDIT_ENVIRONMENT":
      return (appId, envId) => {
        return `${baseURL}applications/${appId}/environments/${envId}`;
      };
    case "ADD_ENVIRONMENT":
      return (appId) => {
        return `${baseURL}applications/${appId}/environments`;
      };
    case "ONBOARD_APPLICATION":
      return `${baseURL}applications`;
    case "MODULE_END_POINT":
      return (appId, moduleId) => {
        return `${baseURL}applications/${appId}/modules/${moduleId}`;
      };
    case "ADD_MODULE":
      return (appId) => {
        return `${baseURL}applications/${appId}/modules/add`;
      };
    case "ADD_API":
      return (appId) => {
        return `${baseURL}applications/${appId}/apis/add`;
      };
    case "APPLICATION_API_END_POINT":
      return (appId, apiId) => {
        return `${baseURL}applications/${appId}/apis/${apiId}`;
      };
    case "ADD_APPLICATION_BILLING":
      return (appId) => {
        return `${baseURL}applications/${appId}/billableServices/add`;
      };
    case "APPLICATION_BILLING_END_POINT":
      return (appId, billableId) => {
        return `${baseURL}applications/${appId}/billableServices/${billableId}`;
      };
    case "BILLABLE_SERVICES":
      return `${bs_baseURL}billableServices`;
    case "ADD_WEBHOOK":
      return (appId) => {
        return `${baseURL}applications/${appId}/webhooks/add`;
      };
    case "WEBHOOK_END_POINT":
      return (appId, webhookId) => {
        return `${baseURL}applications/${appId}/webhooks/${webhookId}`;
      };
    case "APPLICATION_ROLES_ADD":
      return (appId) => {
        return `${baseURL}applications/${appId}/roles/add`;
      };
    case "APPLICATION_ROLES_END_POINT":
      return (appId, roleId) => {
        return `${baseURL}applications/${appId}/roles/${roleId}`;
      };
    case "APPLICATION_ENDPOINT":
      return (appId) => {
        return `${baseURL}applications/${appId}`;
      };
    case "CDU_MASTER_FEILDS":
      return `${baseURL_cdu}cdu/fields`;

    case "PUBLISH_APPLICATIONS":
      return (appId) => {
        return `${baseURL}applications/${appId}/state`;
      };
    case "CDU_MASTER_FEILDS_VALUES":
      return `${baseURL_DS}fields`;

    case "FETCH_FIELDS_VALUES":
      return (key) => {
        return `${baseURL_DS}/fields/${key}/values`;
      };

    case "CATEGORIES":
      return `${baseURL}categories`;
    case "TAGS":
      return `${baseURL}applications/tags`;

    case "GET_APPLICATIONS_BY_STATUS":
      return (status) => {
        return `${baseURL}applications?filters=${status}`;
      };
    case "GET_ALL_APPLICATIONS_API":
      return (appId) => {
        return `${baseURL}applications/${appId}/apis`;
      };

    case "OUTGOING_API":
      return (appId) => {
        return `${baseURL}applications/${appId}/outgoingApi/add`;
      };

    case "EDIT_OUTGOING_API":
      return (appId, id) => {
        return `${baseURL}applications/${appId}/outgoingApi/${id}`;
      };
    case "DELETE_OUTGOING_API":
      return (appId, id) => {
        return `${baseURL}applications/${appId}/outgoingApi/${id}`;
      };
    case "FETCH_CTX_BY_APP_AND_APIID":
      return (appId, apiId) => {
        return `${baseURL_ctx}application/${appId}/apis/${apiId}/contexts`;
      };
    case "ADD_CONTEXT":
      return `${baseURL_ctx}context/add`;
    case "GET_CONTEXTS":
      return `${baseURL_ctx}contexts`;
    case "APPLICATION_API_PUBLISH":
      return (appId, apiId) => {
        return `${baseURL}applications/${appId}/apis/${apiId}/publish`;
      };
    case "AUTHORIZATION_SYNC":
      return (appId) => {
        return `${baseURL}applications/${appId}/authorization/sync`;
      };
    case "ADD_CATEGORY":
      return `${baseURL}categories`;

    case "ADD_CDU_MASTER":
      return `${baseURL_cdu}cdu/fields/add`;

    case "EDIT_CDU_MASTER_FEILDS":
      return (dataKey) => {
        return `${baseURL_cdu}cdu/fields/${dataKey}`;
      };
    case "DELETE_CDU_MASTER_FEILDS":
      return (dataKey) => {
        return `${baseURL_cdu}cdu/fields/${dataKey}`;
      };
    case "BULK_UPLOAD_CDU":
      return `${baseURL_cdu}cdu/fields/upload`;

    case "ADD_CDU_VALUE":
      return `${baseURL_cdu}cdu/values/add`;

    case "EDIT_CDU_VALUE":
      return (dataKey, valueId) => {
        return `${baseURL_cdu}cdu/values/${dataKey}/${valueId}`;
      };
    case "DELETE_CDU_VALUE":
      return (dataKey, valueId) => {
        return `${baseURL_cdu}cdu/values/${dataKey}/${valueId}`;
      };
    case "FETCH_CDU_VALUES":
      return `${baseURL_cdu}cdu/values/`;
    case "BULK_UPLOAD_VALUES":
      return `${baseURL_cdu}cdu/values/upload`;
    case "FETCH_CUSTOMER_LIST":
      return `${baseURL_cm}subscribers`;

    case "FETCH_OUTGOING_DETAILS":
      return (appId, apiId) => {
        return `${baseURL_ctx}application/${appId}/apis/${apiId}/contexts`;
      };
    case "ENVIRONMENT_TAGSTATUS":
      return (appId, envId) => {
        return `${baseURL}applications/${appId}/environments/${envId}/healthCheck`;
      };

    case "ADD_DOCUMENT":
      return (appId) => {
        return `${baseURL}applications/${appId}/introDocuments/add`;
      };

    case "GET_SECTIONS_USAGE":
      return (appId, type, Id) => {
        if (type === "usage") {
          return `${baseURL}applications/${appId}/introDocuments?filters={"sectionId":"","type":"${type}","documentId":"${Id}"}`;
        } else {
          return `${baseURL}applications/${appId}/introDocuments?filters={"sectionId":"","type":"${type}"}`;
        }
      };
    case "GET_SECTIONS":
      return (appId) => {
        return `${baseURL}applications/${appId}/introDocuments?filters={"sectionId":"","type":"intro"}`;
      };

    case "GET_DOCUMENTS_TYPE_INTRO":
      return (appId) => {
        return `${baseURL}applications/${appId}/introDocuments?filters={"type":"intro"}`;
      };
    case "GET_SECTION_CONTENT":
      return (appId, sectionId) => {
        return `${baseURL}applications/${appId}/introDocuments/${sectionId}`;
      };
    case "DELETE_SECTION":
      return (appId, sectionId) => {
        return `${baseURL}applications/${appId}/introDocuments/${sectionId}`;
      };
    case "UPDATE_DOCUMENTATION":
      return (appId, sectionId) => {
        return `${baseURL}applications/${appId}/introDocuments/${sectionId}`;
      };
    // case "FETCH_DOCUMENTATION":
    //   return (appId) => {
    //     return `${baseURL}applications/${appId}/introDocuments?filters={"sectionId":""}`;
    //   };
    case "FETCH_DOCUMENTATION":
      return (appId) => {
        return `${baseURL}applications/${appId}/introDocuments`;
      };
    case "FETCH_ALL_SUBSCRIPTION_MODEL":
      return `${bs_baseURL}subscriptionPlans`;

    case "FETCH_USERMANAGEMENT":
      return `${baseURL_UM}users`;

    case "FETCH_USERMANAGEMENT_ROLES":
      return `${baseURL_UM}roles`;

    case "ADD_USERMANAGEMENT":
      return `${baseURL_UM}users`;

    case "EDIT_USERMANAGEMENT":
      return (userManagementId) => {
        return `${baseURL_UM}users/${userManagementId}`;
      };
    case "DELETE_USERMANAGEMENT":
      return (userManagementId) => {
        return `${baseURL_UM}users/${userManagementId}`;
      };

    case "BLOCK_USERMANAGEMENT":
      return (userManagementId, type) => {
        return `${baseURL_UM}users/${userManagementId}/${type}`;
      };

    case "FETCH_MASTER_PERMISSIONS":
      return `${baseURL_UM}resourceTypes`;

    case "ADD_MASTER_ROLE_MANAGEMENT":
      return `${baseURL_UM}roles`;

    case "UPDATE_MASTER_ROLE_MANAGEMENT":
      return (id) => {
        return `${baseURL_UM}roles/${id}`;
      };
    case "FETCH_TEAMS":
      return `${baseURL_UM}teams`;

    case "ADD_TEAM":
      return `${baseURL_UM}teams`;
    case "BLOCK_TEAMS":
      return (teamId, type) => {
        return `${baseURL_UM}teams/${teamId}/block/${type}`;
      };

    case "LOCK_TEAMS":
      return (teamId, type) => {
        return `${baseURL_UM}teams/${teamId}/lock/${type}`;
      };

    // case "BLOCK_TEAMS":
    //   return (teamId, type) => {
    //     return `${baseURL_UM}teams/${teamId}/${type}`;
    //   };
    case "UPDATE_MEMBER":
      return (teamId) => {
        return `${baseURL_UM}teams/${teamId}/users`;
      };
    case "DELETE_TEAM_MEMBER":
      return (teamId) => {
        return `${baseURL_UM}teams/${teamId}/users/_delete`;
      };
    case "FETCH_DATASTANDARDS":
      return `${baseURL_DS}fields`;
    case "BLOCK_DATASTANDARDS":
      return (dataStandardId, type) => {
        return `${baseURL_DS}field/${dataStandardId}/${type}`;
      };
    case "ADD_DATASTANDARDS":
      return `${baseURL_DS}fields`;

    case "EDIT_DATASTANDARDS":
      return (dataStandardId) => {
        return `${baseURL_DS}fields/${dataStandardId}`;
      };
    case "BULKUPLOAD_FILES":
      return `${baseURL_DS}fields/bulkfieldloaders`;
    case "EDIT_USER_DETAILS":
      return (userid) => {
        return `${baseURL_Login}users/${userid}`;
      };
    case "TWO_FACTOR_AUTH":
      return (userid) => {
        return `${baseURL_Login}users/twofactorauthentication/${userid}`;
      };

    case "ADD_SUBSCRIPTION_PLAN":
      return `${bs_baseURL}subscriptionPlans/add`;

    case "EDIT_SUBSCRIPTION_PLAN":
      return (id) => {
        return `${bs_baseURL}subscriptionPlans/${id}`;
      };

    case "DELETE_SUBSCRIPTION_PLAN":
      return (id) => {
        return `${bs_baseURL}subscriptionPlans/${id}`;
      };

    case "FETCH_ALL_VALUEMAP_DATA":
      return (name) => {
        return `${baseURL_DS}fields/${name}/values`;
      };

    case "ADD_VALUEMAP_DATA":
      return (fieldname) => {
        return `${baseURL_DS}fields/${fieldname}/values/add`;
      };

    case "EDIT_VALUEMAP_DATA":
      return (fieldname, id) => {
        return `${baseURL_DS}fields/${fieldname}/values/${id}`;
      };

    case "DELETE_VALUE_MAP_DATA":
      return (fieldname, id) => {
        return `${baseURL_DS}fields/${fieldname}/values/${id}`;
      };

    case "FETCH_ALL_BILLABLE_SERVICES":
      return `${baseURL}applications/billableServices`;

    case "FETCH_ALL_MODULES":
      return `${baseURL}applications/modules`;

    case "PRICING_MASTER":
      return `${bs_baseURL}pricingServices`;

    case "ADD_PRICING_MASTER":
      return `${bs_baseURL}pricingServices`;

    case "DELETE_PRICING_MASTER":
      return (id) => {
        return `${bs_baseURL}pricingServices/${id}`;
      };
    case "EDIT_PRICING_MASTER":
      return (id) => {
        return `${bs_baseURL}pricingServices/${id}`;
      };

    case "FETCH_MODULES_BY_APPLICATION_ID":
      return (appId) => {
        return `${baseURL}applications/${appId}/modules`;
      };

    case "FETCH_BILLABLE_SERVICE_BY_APPID_MOD_ID":
      return (appId, mod) => {
        return `${baseURL}applications/${appId}/billableServices?filters=${mod}`;
      };

    case "FETCH_ALL_SUBSCRIPTION_PLAN":
      return `${bs_baseURL}subscriptionPlans`;

    case "BLOCK_SUBSCRIBERS":
      return (subID, type) => {
        return `${baseURL_cm}subscribers/${subID}/${type}`;
      };

    case "FETCH_HELP_DATA":
      return `${baseURL_HD}issues`;

    case "ADD_HELP_DATA":
      return `${baseURL_HD}issues`;

    case "DELETE_HELP_DESK_DATA":
      return (id) => {
        return `${baseURL_HD}issues/${id}`;
      };

    case "UPDATE_HELP_DESK_ISSUES":
      return (id) => {
        return `${baseURL_HD}issues/${id}/update`;
      };

    case "EDIT_HELP_DESK_IMAGE":
      return (id) => {
        return `${baseURL_HD}issues/${id}`;
      };

    case "FETCH_HELP_DESK_IMAGE":
      return (id, name) => {
        return `${baseURL_HD}issues/${id}/screenshot/${name}`;
      };

    case "DELETE_BILLABLESERVICES":
      return (billableId) => {
        return `${bs_baseURL}billableServices/${billableId}`;
      };
    case "ADD_BILLABLESERVICES":
      return `${bs_baseURL}billableServices`;

    case "BILLING_CATEGORIES":
      return `${bs_baseURL}billableServices/categories`;
    case "ADD_BILLING_CATEGORY":
      return `${bs_baseURL}billableServices/categories`;

    case "EDIT_BILLABLESERVICES":
      return (billableId) => {
        return `${bs_baseURL}billableServices/${billableId}`;
      };

    case "FETCH_ALL_STATUS_PROFILE":
      return `${baseURL_SP}resources/status`;

    case "GET_CUSTOMER_WISE_INVOICES":
      return (id) => {
        return `${baseURL_INVOICE}customers/${id}/invoices`;
      };

    case "UPDATE_CUSTOMER_PROFILE":
      return (id) => {
        return `${baseURL_INVOICE}subscriptions/${id}/settings`;
      };
    case "FETCH_CUSTOMER_SUB_APP":
      return (id) => {
        return `${baseURL_INVOICE}customers/${id}/applications`;
      };

    case "USER_ROLES_DELETE":
      return (roleId) => {
        return `${baseURL_UM}users/${roleId}/roles/_remove`;
      };
    case "USER_ROLES_ADD":
      return (roleId) => {
        return `${baseURL_UM}users/${roleId}/roles/_add`;
      };

    case "FETCH_APP_USAGE_DETAILS":
      return (cusId, appId) => {
        return `${baseURL_INVOICE}customers/${cusId}/applications/${appId}/usage`;
      };

    case "FETCH_ORGANIZATION":
      return `${baseURL}organizations`;

    case "FETCH_ORGANIZATIONBY_ID":
      // return (orgID) => {
      return `${baseURL}organizations`;
    // };

    case "EDIT_ORGANIZATIONDETAILS":
      return `${baseURL}organizations`;

    case "USAGE_DOCUMENTATION":
      return (appId, type) => {
        return `${baseURL}applications/${appId}/usageDocuments${type}`;
      };

    case "UPDATE_USAGE_DOCUMENTATION":
      return (appId, docId) => {
        return `${baseURL}applications/${appId}/usageDocuments/${docId}`;
      };

    case "ADD_STATUS_PROFILE":
      return (name) => {
        return `${baseURL_SP}resources/status/${name}`;
      };

    case "GET_STATUS_PROFILE_BY_NAME":
      return (name) => {
        return `${baseURL_SP}resources/${name}/status`;
      };

    case "DELETE_STATUS_PROFILE":
      return (name) => {
        return `${baseURL_SP}resources/status/${name}/_delete`;
      };

    case "SET_DEFAULT_STATUS_PROFILE":
      return `${baseURL_SP}resources/defaultstatus`;

    case "SELECT_OR_UNSELECT_TEAM":
      return (type, appId, teamId) => {
        return `${baseURL}applications/${appId}/${type}/teams/${teamId}`;
      };

    case "USER_ASSIGNEDAPPLICATIONS":
      return (ID) => {
        return `${baseURL}applications/user/${ID}`;
      };

    case "FETCH_STATUS":
      return `${baseURL_SP}resources/Helpdesk/status`;

    case "EDIT_STATUS_PROFILE":
      return (name, status_name) => {
        return `${baseURL_SP}resources/${name}/status/${status_name}`;
      };

    case "DELETE_HELP_DESK_IMAGE":
      return (helpdeskId, imageToDelete) => {
        return `${baseURL_HD}issues/${helpdeskId}/screenshot/${imageToDelete}`;
      };
    case "FETCH_INDUSTRY_TYPE":
      return `${baseURL_DS}fields/industrytype`;

    case "FETCH_ALL_INVOICES":
      return `${baseURL_INVOICE}invoices`;

    case "ADD_CREDITNOTES":
      return (id) => {
        return `${baseURL_INVOICE}invoices/${id}/notes`;
      };

    case "FETCH_CREDIT_DEBIT_NOTES":
      return (id) => {
        return `${baseURL_INVOICE}invoices/${id}/notes`;
      };

    case "GET_CREDIT_DETAILS":
      return (Id, invoiceId) => {
        return `${baseURL_INVOICE}invoices/${Id}/notes/${invoiceId}`;
      };

    case "APPROVE_INVOICE":
      return (Id, invoiceId, type) => {
        return `${baseURL_INVOICE}invoices/${Id}/notes/${invoiceId}/_action/${type}`;
      };

    case "REJECT_INVOICE":
      return (Id, invoiceId, type) => {
        return `${baseURL_INVOICE}invoices/${Id}/notes/${invoiceId}/_action/${type}`;
      };

    case "FETHC_INVOICE_DETAILS_BY_ID":
      return (id) => {
        return `${baseURL_INVOICE}invoices/${id}`;
      };
    case "GENERATE_USER_QR_CODE":
      return (id) => {
        return `${baseURL_Login}users/${id}/qrGeneration`;
      };
    case "VERIFY_2FACODE":
      return (id, code) => {
        return `${baseURL_Login}users/${id}/verify2FACode?code=${code}`;
      };
    case "SIGNUP":
      return (id) => {
        return `${baseURL_Login}users/insertpassword/${id}`;
      };
    case "FETCH_APP_MODULE_DETAILS_FOR_GRAPH":
      return `${baseURL_INVOICE}applications/usage`;

    case "UPDATE_END_DATE_SUBSCRIPTIOPN":
      return (id) => {
        return `${baseURL_INVOICE}subscriptions/${id}`;
      };

    case "UPDATE_SUBSCRIBED_APP_STATUS":
      return (id) => {
        return `${baseURL_INVOICE}subscriptions/${id}/status`;
      };

    case "FETCH_COUNTRY_CODES":
      return `${baseURL}country_codes`;

    case "CHECK_CDU_FEILD":
      return (feild, indType) => {
        return `${baseURL}fields/${feild}/check?industryType=${indType}`;
      };
    case "BULK_UPLOAD_FIELD_VALUE":
      return (field_name) => {
        return `${baseURL}fields/${field_name}/values/upload`;
      };

    case "INDUSTRY_TYPES":
      return `${baseURL}industryTypes`;

    case "MONITORING_MESSAGE_BROKER":
      return (interval) => {
        return `${baseURL}monitoring?interval=${interval}`;
      };

    case "APPLICATION_SUBSCRIBE":
      return (appId) => {
        return `${baseURL}applications/${appId}/subscribe`;
      };

    case "APPLICATION_UNSUBSCRIBE":
      return (appId, userId) => {
        return `${baseURL}notifications/group/application_${appId}/remove/${userId}`;
      };

    case "FETCH_NOTIFICATIONS":
      return (userId, page) => {
        return `${baseURL}notifications/users/${userId}?page=${page}&pageSize=10`;
      };

    case "FETCH_USER_SUBSCRIBED_APPLICATIONS":
      return (userId) => {
        return `${baseURL}notifications/users/${userId}/groups`;
      };

    case "FETCH_USER_NOTIFICATIONS":
      return (token) => {
        return `${ws_baseURL}websocket?token=${token}`;
      };

    case "UPDATE_NOTIFICATIONS":
      return (id) => {
        return `${baseURL}notifications/${id}`;
      };

    case "SEQUENCE_ORDER":
      return (id) => {
        return `${baseURL}applications/${id}/sequanceorder`;
      };

    case "HELPDESK_COMMENT":
      return (id) => {
        return `${baseURL}issues/${id}/reply`;
      };

    case "USERS_DETAILS":
      return `${baseURL}users/getidsdetails `;

    case "GET_APPLICATIONS_BY_WITHOUT_STATUS":
      return `${baseURL}applications `;

    default:
  }
}
export default getURL;
