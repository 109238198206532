import React, { useState } from "react";
//import DexTable from "components/DexTable/DexTable.jsx";
import WrappedDexTable from "components/DexTable/WrappedDexTable";
import {
  Badge,
  Typography,
  Grid,
  Avatar,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import withSection from "components/Section/Section";
import ViewIcon from "@material-ui/icons/DoneAllOutlined";
import UsageDescription from "./component/UsageDescription";
import { useHistory } from "react-router-dom";
import { RiEditLine, RiDeleteBin5Line } from "react-icons/ri";
import EditEndDateModal from "./Modal";
import DialogModal from "./component/DialogComponent/index";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import { customerUpadateHiddenCol } from "../CustomerProfile/redux/action";
const ProductsTable = (props) => {
  const [custUrl, setCustUrl] = useState({
    launchStatus: "Approved",
    customerUrl: "",
    subId: 0,
  });

  const history = useHistory();
  const { classes, details, info, colTable } = props;
  const { application } = colTable;
  const { loadingSubApp } = info;
  const [open, setOpen] = React.useState(false);
  const [openDialog, setDialog] = useState(false);
  const [end_Date, setEndDate] = React.useState({
    id: 0,
    endDate: "",
    customerUrl: "",
  });

  const handleClickOpen = (id, value, customerUrl) => {
    setOpen(true);
    setEndDate({
      endDate: value,
      id: id,
      customerUrl: customerUrl,
    });
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  // const handleOpenOrClose = async (bool, appId) => {
  //   let flag = await props.getUsageDiscriptionOFApp(appId);
  //   console.log(flag, "test api ");
  //   if (flag) {
  //     history.push("/app/app-usage-description", {
  //       customerData: { ...details },
  //     });
  //   } else {
  //   }

  //   // setOpen(bool);
  // };

  // const editModal = ({ row }) => {
  //   setOpenmodal(true);
  //   return (
  //     <>
  //       <Modal>HI</Modal>
  //     </>
  //   );
  // };

  const dateProvider = ({ row }) => {
    const date = row?.Subscription?.startDate || "";
    return (
      <>
        <Typography> {date.split("T")[0]} </Typography>
      </>
    );
  };

  const endDateProvider = ({ row }) => {
    const date = row?.Subscription?.endDate || "";
    return (
      <>
        <Typography> {date.split("T")[0]} </Typography>
      </>
    );
  };

  const actionProvider = ({ row }) => {
    const date = row?.Subscription?.endDate || "";
    const customerUrl = row?.Subscription?.customerUrl || null;
    const id = row?.Subscription?.ID || undefined;
    const status = row?.Subscription?.launchStatus || "-";
    const flag = status === "Requested";
    console.log("sghafgadghsa", customerUrl);
    return (
      <>
        <div style={{ display: "flex" }}>
          <IconButton
            onClick={() => handleClickOpen(id, date, customerUrl)}
            size="small"
            disabled={props.propsDisabled}
          >
            <Tooltip title="Edit end date">
              <div>
                <RiEditLine style={{ fontSize: "20" }} />
              </div>
            </Tooltip>
          </IconButton>

          {flag && (
            <div style={{ marginLeft: 15 }}>
              <IconButton disabled={props.propsDisabled} size="small">
                <Tooltip title="Approve">
                  <ViewIcon
                    style={{ fontSize: "20" }}
                    onClick={() => {
                      setDialog(true);
                      setCustUrl({
                        ...custUrl,
                        subId: id,
                      });
                    }}
                  />
                </Tooltip>
              </IconButton>
            </div>
          )}
        </div>
      </>
    );
  };

  const currentUnBilledAmount = ({ row }) => {
    console.log("adhfashgfaHFSHGSHK", row);
    const data = row.UnusedBillable || [];
    let amount = 0;
    data.map((row) => (amount = row.GrossAmount + amount));
    return (
      <>
        <Typography>{amount}</Typography>
      </>
    );
  };

  function statusProvider({ row }) {
    console.log("asghdfdghfhdg", row.Subscription.launchStatus);
    const value = row?.Subscription?.launchStatus || "-";
    let color = null;
    if (value === "Approved") {
      color = classes.activeBadge;
    } else {
      color = classes.blockBadge;
    }
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Badge
          classes={{ badge: color }}
          variant="dot"
          style={{ marginRight: "10px" }}
        />
        <Typography variant="body1"> {value} </Typography>
      </div>
    );
  }

  const handleClick = (row) => {
    // props.history.push("/app/customer-details", { customerData: row });
  };

  const appProvider = ({ row }) => {
    console.log(row, "3456789dfgh");
    return (
      <Typography variant="body1">
        {row?.Application.name || "-"}
        <br />
      </Typography>
    );
  };
  const subscriptionType = ({ row }) => {
    return <Typography>{row?.Subscription?.type || ""}</Typography>;
  };

  const customerURL = ({ row }) => {
    return <Typography>{row?.Subscription?.customerUrl}</Typography>;
  };
  console.log("asdgsadjgdjgadgah", props.data);
  return (
    <>
      <div className={classes.table} style={{overflow:"auto"}}>
        <WrappedDexTable
          
          data={[...props.data]}
          // hideColumnChooser={true}
          columnOrder={[
            "application",
            "type",
            // "status",
            // "users",
            "startDate",
            "currentUnBilledAmount",
            "launchStatus",
            "endDate",
            "customerUrl",
            "actions",
          ]}
          columWidthsType="percentage"
          columns={[
            // { name: "name", title: "Application" },
            { name: "application", title: "Application" },
            // { name: "status", title: "Status" },
            // { name: "users", title: "Users" },
            { name: "startDate", title: "Subscribed On" },
            {
              name: "type",
              title: "Type",
            },
            {
              name: "currentUnBilledAmount",
              title: "Unbilled Amount",
            },
            {
              name: "endDate",
              title: "End Date",
            },
            // { name: "currentUnBilledAmount", title: "Current Unbilled Amount" },
            { name: "actions", title: "Actions" },
            {
              name: "launchStatus",
              title: "Status",
            },
            {
              name: "customerUrl",
              title: "URL",
            },
          ]}
          defaultColumnWidths={[
            { columnName: "application", width: 15 },
            // { columnName: "status", width: 200 },
            // { columnName: "users", width: 200 },
            { columnName: "startDate", width: 18 },
            { columnName: "currentUnBilledAmount", width: 18 },
            { columnName: "endDate", width: 15 },
            { columnName: "type", width: 18 },
            { columnName: "actions", width: 10 },
            {
              columnName: "launchStatus",
              width: 15,
            },
            {
              columnName: "customerUrl",
              width: 40,
            },
          ]}
          // dateColumns={["startDate"]}
          formatters={[
            { formatterFn: appProvider, columns: ["application"] },
            { formatterFn: dateProvider, columns: ["startDate"] },
            { formatterFn: statusProvider, columns: ["launchStatus"] },
            {
              formatterFn: subscriptionType,
              columns: ["type"],
            },
            {
              formatterFn: currentUnBilledAmount,
              columns: ["currentUnBilledAmount"],
            },
            { formatterFn: endDateProvider, columns: ["endDate"] },
            { formatterFn: actionProvider, columns: ["actions"] },
            {
              formatterFn: customerURL,
              columns: ["customerUrl"],
            },
          ]}
          hidden={[...application]}
          setHiddenColmn={(hidden) => {
            props.setHiddenColumn(hidden, "application");
          }}
          pagination={false}
          // paginationConfig={{
          //   currentPage: this.state.page,
          //   pageSize: this.state.size,
          //   onCurrentPageChange: this.handlePageChange,
          //   onPageSizeChange: this.handleLimitChangePageRow,
          //   pageSizes: [4, 5, 6],
          //   totalCount: totalCount,
          // }}
          // onClickRow={handleClick}
          // disableReordering={false}
        />
      </div>
      <EditEndDateModal
        open={open}
        close={handleClose}
        end_Date={end_Date}
        custUrl={custUrl}
        submitEndDate={props.submitEndDate}
        setEndDate={setEndDate}
      />
      <DialogModal
        custUrl={custUrl}
        setCustUrl={setCustUrl}
        open={openDialog}
        setDialog={setDialog}
        {...props}
      />

      {/* <UsageDescription open={open} handleClose={handleOpenOrClose} /> */}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setHiddenColumn: (hidden, type) =>
    dispatch(customerUpadateHiddenCol(hidden, type)),
});
const mapStateToProps = (state) => {
  return {
    colTable: state.customerProfile.colTable,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withSection(ProductsTable, "Applications")));
