import { useTheme } from "@material-ui/styles";
import React, { Component, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  IconButton,
  Popover,
  Typography,
  Grid,
  CardContent,
  TextField,
  FormLabel,
  Divider,
  CardActions,
  Container,
} from "@material-ui/core";
import DropDown from "../DropDown/DropDown";
import CancelIcon from "@material-ui/icons/CancelOutlined";
import { CustomDropDown, FilterDropdown } from "components/DropDown/DropDown";
/*
 Filter option maintains internal state 
*/
const FilterOption = (props) => {
  const {
    filters,
    onChange,
    removeFilter,
    rows,
    setRow,
    setErr,
    Classes,
    index,
    err,
    row,
  } = props;
  const [filterOptions, setFilterOptions] = useState([]);
  const [fieldType, setFieldType] = useState("text");
  const [fieldValues, setFieldValues] = useState([]);
  const { filterValues, setFilterValues } = props;
  //const []

  useEffect(() => {
    setFunc(row.columns);
  }, []);

  const setFunc = (filedName) => {
    let filtersvalues = [...filters];
    let selectedOption = filtersvalues.filter((e) => {
      return e.value === filedName;
    })[0];
    if (filedName) {
      setFieldType(selectedOption.fieldType || "text");
      setFilterOptions(selectedOption.filterOptions);
      setFieldValues(selectedOption.fieldValues || []);
    }
  };

  const handleChange = (event, val) => {
    let type = "";

    filters.map((itm, i) => {
      if (itm.value === val.columns) {
        type = itm.fieldName;
      }
    });

    let copy = [...rows];
    copy[index] = {
      ...val,
      type: type,
      key: index + 1,
    };
    setRow([...copy]);
    setFilterValues({ ...filterValues, ...val });
    setFunc(event.currentTarget.value);
  };
  const handleFilterOptionChange = (values, index) => {
    let copy = [...rows];
    copy[index] = { ...values, key: index + 1 };
    setRow([...copy]);
    setFilterValues({ ...values });
  };
  console.log(filterValues, filters, rows, err, "this is filter values");
  return (
    <>
      {/* <Grid item xs={10} md={2}>
        {index >= 1 ? (
          <DropDown
            label={"Operator"}
            values={[
              { key: 1, value: "And" },
              { key: 2, value: "Or" },
            ]}
            handleChange={(event) =>
              handleFilterOptionChange(
                {
                  ...filterValues,
                  type: event.target.value,
                },
                index
              )
            }
          />
        ) : null}
      </Grid> */}
      <Grid
        container
        spacing="2"
        alignItems="center"
        justifyContent="flex-start"
      >
        <Grid item xs={5} md={3}>
          <div>
            <CustomDropDown
              label={"Columns"}
              values={filters}
              info={row.columns}
              rowsArr={rows}
              row={row}
              handleChange={(e) => {
                handleChange(e, {
                  ...row,
                  columns: e.target.value,
                  operator: "",
                  value: "",
                });
                setErr({ ...err, col: false });
              }}
              name="column"
            />
            <span className={Classes.errorMsg}>
              {err.col && row.columns.trim() === ""
                ? "Column is required"
                : "   "}
            </span>
          </div>
        </Grid>
        <Grid item xs={8} md={3}>
          <div>
            <FilterDropdown
              name="operators"
              label={"Operators"}
              values={filterOptions}
              info={row.operator}
              handleChange={(event) => {
                handleFilterOptionChange(
                  {
                    ...row,
                    operator: event.target.value,
                  },
                  index
                );
                setErr({ ...err, op: false });
              }}
            />
            <span className={Classes.errorMsg}>
              {err.op && row.operator.trim() === ""
                ? "Operator is required"
                : "  "}
            </span>
          </div>
        </Grid>

        <Grid item xs={5} md={3}>
          {fieldType == "dropdown" ? (
            <div>
              <FilterDropdown
                label={"Value"}
                values={fieldValues}
                info={row.value}
                handleChange={(event) => {
                  handleFilterOptionChange(
                    {
                      ...row,
                      value: event.target.value,
                    },
                    index
                  );
                  setErr({ ...err, val: false });
                }}
              />
              <span className={Classes.errorMsg}>
                {err.val && row.value.trim() === ""
                  ? "Value is required"
                  : "   "}
              </span>
            </div>
          ) : (
            <div>
              <div
                style={{
                  display: "flex",
                  color: "#000",
                  flexDirection: "column",
                }}
              >
                <FormLabel style={{ paddingTop: 4 }}>Value</FormLabel>
                <TextField
                  variant="outlined"
                  size="small"
                  id="name"
                  name="value"
                  value={row.value}
                  type={fieldType || "text"}
                  onChange={(event) => {
                    handleFilterOptionChange(
                      {
                        ...row,
                        value: event.target.value,
                      },
                      index
                    );
                    setErr({ ...err, val: false });
                  }}
                />
              </div>
              <span className={Classes.errorMsg}>
                {err.val && row.value.trim() === ""
                  ? "Value is required"
                  : "   "}
              </span>
            </div>
          )}
        </Grid>
        {index >= 1 ? (
          <Grid item xs={12} md={1} style={{ paddingTop: "3%" }}>
            <IconButton onClick={() => removeFilter(index)}>
              <CancelIcon color="secondary" />
            </IconButton>
          </Grid>
        ) : null}
        <Grid item xs={12} alignItems="center">
          <Divider />
        </Grid>
      </Grid>
    </>
  );
};

export default FilterOption;
