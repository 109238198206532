import * as types from "./actionType";
const initialState = {
  loading: false,
  userLoginData: {},
  QR_Code: null,
  verifyCodeRes: null,
  msg: null,
  success: null,
  privateRoute: false,
  // signupData:{}
};
export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case types.ADD_USER_LOG_INFO:
      return { ...state, userLoginData: { ...action.payload } };
    case types.GENERATED_QR_CODE:
      return {
        ...state,
        QR_Code: action.payload,
      };
    case types.GENERATE_QR_CODE_FAILED:
      return {
        ...state,
        QR_Code: null,
      };
    case types.VERIFYING_2FA_CODE:
      return {
        ...state,
        loading: true,
      };
    case types.VERIFY_2FA_CODE_SUCCESS:
      return {
        ...state,
        verifyCodeRes: action.payload,
        msg: action.msg,
        success: true,
      };
    case types.VERIFY_2FA_FAIL:
      return {
        ...state,
        verifyCodeRes: null,
        msg: action.msg,
        success: false,
        loading: false,
      };
    case types.RESET_LOGIN_PROPS:
      return {
        ...state,
        verifyCodeRes: null,
        QR_Code: null,
        msg: null,
        success: null,
      };
    case types.SIGNUP_SUCCESS:
      return {
        ...state,
        signupData: { ...action.payload },
      };
    case types.SIGNUP_FAIL:
      return {
        ...state,
        signupData: {},
      };
    case types.CLEAR_MSG:
      return {
        ...state,
        msg: null,
        success: null,
      };
    case types.PRIVATE_ROUTE_TRUE:
      return {
        ...state,
        privateRoute: true,
        loading: false,
      };
    case types.PRIVATE_ROUTE_FALSE:
      return {
        ...state,
        privateRoute: false,
      };
    default:
      return state;
  }
}
