const Styles = (theme) => ({
  root: {
    marginTop: "4%",
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  success: {
    font: "normal normal bold 18px/23px Nunito",
    backgroundColor: "#FCFFF5",
    color: "#1E561F",
    border: "solid 1px #A8C599",
  },
  error: {
    font: "normal normal bold 18px/23px Nunito",
    color: "#973937",
    border: "solid 1px #973937",
    backgroundColor: "#FFF6F6",
  },
});
export default Styles;
