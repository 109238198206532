import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import Modal from "../Components/Modal";
import { useSnackbar } from "notistack";
import { TextValidator } from "react-material-ui-form-validator";

const filter = createFilterOptions();

export default function AutoComplete(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [read_value, setValue] = React.useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (props.edit) {
      props.defaultCategory.map((item, index) => {
        if (props.basicInfo.category === item._id) {
          setValue({ name: item.name });
        }
      });
    } else {
      props.defaultCategory.map((item, index) => {
        return props.basicInfo.category === item._id
          ? setValue({ name: item.name })
          : null;
      });
    }
  }, [props.basicInfo, props.defaultCategory, props.edit]);

  const add_category = async () => {
    let { categoryId, success, _msg } = await props.addCategory(
      read_value.name
    );
    if (success) {
      props.handleOnChange({ ...props.basicInfo, category: categoryId });
    } else {
      enqueueSnackbar(_msg, { variant: "error" });
    }
    setValue(null);
    setOpen(false);
  };

  const openModal = (value) => {
    setOpen(true);
  };
  const selectCategory = (value) => {
    setValue(value);
    if (value) {
      props.handleOnChange({ ...props.basicInfo, category: value._id });
    } else {
      props.handleOnChange({
        ...props.basicInfo,
        category: "",
      });
    }
  };
  const handleClose = () => {
    setValue(null);
    setOpen(false);
  };

  let isTrue =
    !props.editDisable && props.editDisable !== undefined ? true : false;

  return (
    <div>
      <Autocomplete
        fullWidth
        disabled={isTrue}
        style={{ width: "100%" }}
        size="small"
        value={read_value}
        onChange={(event, newValue) => {
          if (typeof newValue === "string") {
            setValue({
              name: newValue,
            });
            openModal();
          } else if (newValue && newValue.inputValue) {
            setValue({
              name: newValue.inputValue,
            });
            openModal();
          } else {
            selectCategory(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          // Suggest the creation of a new value
          if (params.inputValue !== "") {
            filtered.push({
              inputValue: params.inputValue,
              name: `Add "${params.inputValue}"`,
            });
          }
          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        id="free-solo-with-text-demo"
        options={props.defaultCategory}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          // Add "xyz" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.name;
        }}
        renderOption={(option) => option.name}
        freeSolo
        renderInput={(params) => (
          <div>
            <TextValidator
              {...params}
              value={read_value ? read_value.name : ""}
              variant="outlined"
              validators={["required"]}
              errorMessages={["Please select category"]}
            />
          </div>
        )}
      />
      <Modal
        open={open}
        handleClose={handleClose}
        add={add_category}
        text={read_value}
      />
    </div>
  );
}
