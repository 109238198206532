import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import Styles from "../styles";
// import DialogTitle from "@material-ui/core/DialogTitle";

const BlockModal = (props) => {
  const { open, handleClose, text, action, Icon, yes, color, classes } = props;
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className={classes.modalstyle}>
          <DialogContentText id="alert-dialog-description">
            {Icon && <div className={classes.modaltextstyle}>{Icon}</div>}
            <Typography variant="body2">{text}</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            size="small"
            onClick={handleClose}
            color="primary"
            autoFocus
          >
            No
          </Button>
          <Button
            style={{ color: "white" }}
            variant="contained"
            size="small"
            onClick={action}
            color="primary"
          >
            {yes}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withStyles(Styles)(BlockModal);
