import { useCallback, useState } from "react";
import { Handle, Position } from "react-flow-renderer";
import { SortableTreeWithoutDndContext as SortableTree } from "@nosferatu500/react-sortable-tree";
import Node from "./Node";
import { Grid, Paper, Tooltip, Typography } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import { blueGrey } from "@mui/material/colors";
import arrow from "assets/images/arrow.svg";
import { Avatar, IconButton } from "@material-ui/core";
import { blue, indigo, cyan } from "@mui/material/colors";
import FieldValueModal from "../components/FieldValueModal";
const getColor = {
  array: blue[300],
  object: indigo[300],
  string: cyan[300],
  number: cyan[300],
  boolean: cyan[300],
  integer: cyan[300],
};

const style = {
  width: "100%",
  bgcolor: "background.paper",
};

const ListNode = ({
  data,
  count,
  panel,
  parentTitle = "$",
  type,
  position,
  handleType,
  showHandle,
  handleOpenCloseModal,
  dataName,
}) => {
  const findPosition = () => {
    if (type === "cdutoapi") {
      if (panel === "api") {
        return Position.Left;
      } else {
        return Position.Right;
      }
    } else {
      if (panel === "api") {
        return Position.Right;
      } else {
        return Position.Left;
      }
    }
  };
  console.log("092384020002", panel);
  return (
    <div>
      <div
        style={{
          backgroundColor: blueGrey[900 - count * 100],
          color: "white",
        }}
      >
        <ListItem>
          <div
            style={{
              paddingLeft: count * 25,
              display: "flex",
              justifyContent: "center",
              color: "white",
              alignItems: "center",
            }}
          >
            <img
              src={arrow}
              style={{ width: "20px", height: "20%", marginRight: 2 }}
              alt="arrow"
            />
            <ListItemText primary={`${data.title}`} />
            {data.type[0] && (
              <div style={{ display: "flex" }}>
                <Avatar
                  style={{
                    backgroundColor: getColor[data.type],
                    marginLeft: 10,
                    width: 18,
                    height: 18,
                    fontSize: 14,
                  }}
                >
                  {data.type[0]?.toUpperCase()}
                </Avatar>

                {panel === "cdu" &&
                  data.title !== "root" &&
                  data.title !== "arrayElement" &&
                  data.type === "string" && (
                    <Tooltip title="Value Mapping">
                      <IconButton
                        style={{
                          marginLeft: 5,
                          backgroundColor: "white",
                          width: "18px",
                          height: "18px",
                          textAlign: "center",
                        }}
                        size="small"
                        onClick={() =>
                          handleOpenCloseModal(
                            true,
                            parentTitle + "__" + data.title
                          )
                        }
                      >
                        <span
                          style={{
                            fontSize: "10px",
                            fontWeight: 900,
                            color: "black",
                            textAlign: "center",
                            paddingTop: 2,
                          }}
                        >
                          V
                        </span>
                      </IconButton>
                    </Tooltip>
                  )}
              </div>
            )}
          </div>
          {showHandle && (
            <Handle
              className={[
                "circle-port ",
                position === Position.Left
                  ? "circle-port-left"
                  : "circle-port-right",
              ].join(" ")}
              position={position}
              dataName={dataName}
              type={handleType}
              id={panel + "__" + parentTitle + "__" + data.title}
              key={panel + "__" + parentTitle + "__" + data.title}
              style={{ zIndex: 99999 }}
            />
          )}
        </ListItem>

        <Divider />
        {data.children?.length
          ? data.children.map((v, id) => {
              let isArrObj =
                v.type === "array" &&
                v.children.filter(
                  (f) => f.title === "arrayElement" && f.type === "object"
                ).length > 0;
              return (
                <ListNode
                  key={id}
                  data={v}
                  count={count + 1}
                  panel={panel}
                  handleOpenCloseModal={handleOpenCloseModal}
                  parentTitle={parentTitle + "__" + data.title}
                  handleType={handleType}
                  position={position}
                  // showHandle={
                  //   handleType === "target"
                  //     ? v.type !== "object" && v.type !== "array"
                  //     : true
                  // }
                  showHandle={
                    handleType === "target"
                      ? v.title !== "arrayElement" && !isArrObj
                      : v.title !== "arrayElement"
                  }
                />
              );
            })
          : null}
      </div>
    </div>
  );
};

function CustomValueMapNode({ data, selected, ...otherProps }) {
  console.log("234234234234234", data, otherProps);
  const [openModal, setOpen] = useState(false);
  const [fieldName, setFieldName] = useState("");
  const getHandlePos = () => {
    return data.panel == "api" ? Position.Right : Position.Left;
  };
  const getHandleType = () => {
    if (data.type == "cdutoapi") {
      return data.panel == "cdu" ? "source" : "target";
    } else {
      return data.panel == "cdu" ? "target" : "source";
    }
  };

  const handleOpenCloseModal = (bool, name) => {
    setOpen(bool);
    bool &&
      setFieldName(
        name.split("__").slice(2).join(".").replaceAll("arrayElement", "#")
      );
  };

  const hanldeSubmitFieldValueModal = async (name, payload) => {
    let str = "";
    payload.map((row) => {
      str = `${str},${row.key}:${row.value}`;
    });

    data.onChangeNodeData(otherProps.id, otherProps.type, {
      valueMapping: {
        ...(data?.input?.valueMapping || {}),
        [name]: str.slice(1),
      },
    });
    setOpen(false);
  };

  return (
    <>
      <Paper elevation={selected ? 12 : 3}>
        <Grid
          container
          style={{ padding: 3, width: "min-content", minWidth: 250 }}
        >
          <Grid item sm={12} md={12} lg={12}>
            <Typography variant="h5">{data.name}</Typography>
          </Grid>
          <Grid item sm={12} md={12} lg={12}>
            <List
              dense={true}
              sx={style}
              component="nav"
              aria-label="mailbox folders"
            >
              {data.data.map((f, id) => {
                return (
                  <ListNode
                    data={f}
                    key={id}
                    count={0}
                    handleOpenCloseModal={handleOpenCloseModal}
                    panel={data.panel}
                    type={data.type}
                    dataName={data.name}
                    position={getHandlePos()}
                    handleType={getHandleType()}
                    showHandle={
                      getHandleType() == "target" &&
                      f.type !== "object" &&
                      f.type === "array"
                    }
                  />
                );
              })}
            </List>
          </Grid>
        </Grid>
      </Paper>
      <FieldValueModal
        open={openModal}
        handleModal={handleOpenCloseModal}
        handleSubmit={hanldeSubmitFieldValueModal}
        fieldName={fieldName}
        valueMapping={data?.input?.valueMapping || {}}
      />
    </>
  );
}

export default CustomValueMapNode;
