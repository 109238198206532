import AppList from "views/Application/ApplicationList/Redux/reducer";
import ApplicationReducer from "../views/Application/redux/reducer";
import appDetailsRedcer from "../views/Application/ApplicationDetails/Redux/reducer";
import CustomerManagementReducer from "views/CustomerManagement/redux/reducer";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import billingMaster from "views/BillingMaster/redux/reducer";
import PricingMaster from "views/PricingMaster/redux/reducer";
import CustomerProfile from "views/CustomerProfile/redux/reducer";
import apearenceReducer from "views/UserProfile/redux/reducer";
import loginReducer from "views/login/redux/reducer";
import ListInvoice from "views/InvoiceList/redux/reducer";
import createTeamReducer from "views/CreateTeams/redux/reducer";
import userManagement from "views/UserManagement/redux/reducer";
import roleManagement from "views/RoleManagement/redux/reducer";
import teamManagement from "views/Teams/redux/reducer";
import dataStandards from "views/DataStandards/redux/reducer";
import userApear from "views/UserProfile/Apear/apearReducer";
import { userEditReducer } from "views/UserProfile/redux/Edit/editReducer";
import userSelectTab from "views/UserProfile/redux/Tab/selectTabReducer";
import invoiceDetails from "views/Invoice/redux/reducer";
import logger from "redux-logger";
import subscriptionPlan from "views/Subscription/redux/reducer";
import valueMap from "views/CreateValueMap/redux/reducer";
import helpdesk from "views/HelpDesk/redux/reducer";
import createDataStandards from "views/CreateDataStandards/redux/reducer";
import statusProfile from "views/StatusProfile/redux/reducer";
import organizationReducer from "views/Organization/OrganizationDetails/redux/reducer";
import editorganizationReducer from "views/Organization/editOrganization/redux/reducer";
import monitorReducer from "views/Monitor/Redux/reducer";
import dataFabricReducer from "views/DataFabric/redux/reducer";
const persistConfig = {
  key: "root",
  storage,
  blacklist: [
    "userEdit",
    "userApear",
    "appDetailsRedcer",
    "PricingMaster",
    "billingMaster",
    "appDetails",
    "PricingMaster",
    "viewInvoice",
    "roleManagement",
    "userManagement",
    "teamManagement",
    "createTeamReducer",
    "customerProfile",
    "userSelectTab",
    "customerManagement",
    "subscriptionPlan",
    "valueMap",
    "helpdesk",
    "dataStandards",
    "createDataStandards",
    "statusProfile",
    "AppList",
    "invoiceDetails",
    "ListInvoice",
    "editorganizationReducer",
    "monitorReducer",
    "dataFabricReducer",
  ],
};
const appReducer = combineReducers({
  AppList: AppList,
  application: ApplicationReducer,
  appDetailsRedcer: appDetailsRedcer,
  customerManagement: CustomerManagementReducer,
  billingMaster: billingMaster,
  PricingMaster: PricingMaster,
  customerProfile: CustomerProfile,
  apearenceReducer: apearenceReducer,
  loginReducer: loginReducer,
  ListInvoice: ListInvoice,
  userManagement: userManagement,
  roleManagement: roleManagement,
  teamManagement: teamManagement,
  createTeamReducer: createTeamReducer,
  userApear: userApear,
  userEdit: userEditReducer,
  userSelectTab: userSelectTab,
  subscriptionPlan: subscriptionPlan,
  dataStandards: dataStandards,
  valueMap: valueMap,
  helpdesk: helpdesk,
  createDataStandards: createDataStandards,
  statusProfile: statusProfile,
  organizationReducer: organizationReducer,
  editorganizationReducer: editorganizationReducer,
  invoiceDetails: invoiceDetails,
  dataFabricReducer: dataFabricReducer,
  monitorReducer,
});

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === "USER_LOGGED_OUT") {
    state = undefined;
  }
  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, appReducer);
// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  let store = createStore(
    persistedReducer,
    composeWithDevTools(applyMiddleware(...[thunk, logger]))
  );
  let persistor = persistStore(store);
  return { store, persistor };
};
