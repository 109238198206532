import React from "react";
import { Handle, Position } from "react-flow-renderer";
import { Paper, TextField, List, ListItem } from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { IconButton } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import { removeAllEdgeWithHandle } from "helpers/valueMapHelpers";

export default function ConcatNode(props) {
  console.log("props", { ...props });
  const onClickAdd = (e) => {
    console.log("xxxxx", props.data.input);
    e.preventDefault();
    props.data.onChangeNodeData(props.id, props.type, {
      handles: (props.data.input?.handles || 1) + 1,
    });
  };
  const onClickRemove = (e) => {
    e.preventDefault();
    if (props.data.input?.handles <= 1) {
      return;
    }
    props.data.onChangeNodeData(props.id, props.type, {
      handles: (props.data.input?.handles || 1) - 1,
    });
    let length = props.data.input?.handles || 1;
    let edges = props.data.getEdgesWithNodeIdAndHandleId(
      "target",
      props.id,
      `input_${length - 1}`
    );
    console.log(edges);
    props.data.deleteMultipleEdges(edges.map((f) => f.id));
  };
  const rtl = props.data.type == "cdutoapi";

  return (
    <Paper
      elevation={props.selected ? 12 : 3}
      style={{
        borderRadius: 16,
      }}
    >
      <div>
        <div
          style={{
            background: "#e91e63",
            height: "50px",
            width: "100%",
            color: "white",
            fontSize: "20px",
            borderBottomLeftRadius: "0px",
            borderBottomRightRadius: "0px",
            justifyContent: "space-around",
            display: "flex",
            alignItems: "center",
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
          }}
        >
          <AddCircleOutlineIcon
            style={{ color: "green", cursor: "pointer" }}
            onClick={(e) => {
              onClickAdd(e);
            }}
          />
          <p style={{ margin: 0 }}>Concatenate</p>
          <RemoveCircleOutlineIcon
            style={{ color: "white", cursor: "pointer" }}
            onClick={onClickRemove}
          />
          {props.selected && (
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
              onClick={(e) => {
                props.data.onNodeDelete(props.id);
              }}
              style={{
                position: "absolute",
                top: "-29px",
                right: "-31px",
                color: "red",
              }}
            >
              <CancelIcon />
            </IconButton>
          )}
        </div>
        <div style={{ marginTop: 10, padding: 8 }}>
          <TextField
            autoComplete="off"
            label="Concatenate with"
            id="outlined-size-small"
            size="small"
            value={props.data.input?.concatinateString}
            onChange={(e) => {
              props.data.onChangeNodeData(props.id, props.type, {
                concatinateString: e.target.value,
              });
            }}
          />
        </div>
        <div style={{ marginTop: 10, padding: 8 }}>
          <h6
            style={{
              fontWeight: "500",
              borderBottom: "1px solid #c5c5c5",
              paddingBottom: "5px",
              margin: 0,
              justifyContent: rtl ? "right" : "left",
              display: "flex",
            }}
          >
            Input
          </h6>
          <List dense={true}>
            {[...Array(props.data.input?.handles || 1).keys()].map((d, i) => {
              return rtl ? (
                <ListItem
                  key={i}
                  style={{ justifyContent: "flex-end", color: "#545151" }}
                >
                  <Handle
                    className={`circle-port "circle-port-right`}
                    position={Position.Right}
                    type={"target"}
                    id={`input_${d}`}
                    key={`input_${d}`}
                    style={{ right: "-14px" }}
                  />
                  Element - {d}
                </ListItem>
              ) : (
                <ListItem
                  style={{ justifyContent: "flex-start", color: "#545151" }}
                >
                  Element - {d}
                  <Handle
                    className={`circle-port ${
                      rtl ? "circle-port-left" : "circle-port-right"
                    }`}
                    position={Position.Left}
                    type={"target"}
                    id={`input_${d}`}
                    key={`input_${d}`}
                    style={{ left: "-14px" }}
                  />
                </ListItem>
              );
            })}
          </List>
        </div>
        <div
          style={{
            paddingBottom: "15px",
            display: "flex",
            justifyContent: rtl ? "flex-start" : "flex-end",
            paddingRight: "15px",
            paddingLeft: "15px",
          }}
        >
          <h6
            style={{
              fontWeight: "500",
              paddingBottom: "5px",
              margin: 0,
            }}
          >
            Output
          </h6>
          <Handle
            className={"circle-port"}
            position={rtl ? Position.Left : Position.Right}
            type={"source"}
            id={"output"}
            key={"output"}
            style={{ bottom: "19px", top: "auto", right: "-6px" }}
          />
        </div>
      </div>
    </Paper>
  );
}
