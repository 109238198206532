import * as actions from "./actionTypes";

const intialState = {
  data: [],
  loading: false,
  totalCount: 0,
  adddata: {},
  editdata: {},
  filterProps: {
    filters: {},
    filterArray: [{ key: 1, columns: "", operator: "", value: "" }],
    sort: { subscription: -1 },
    sorting: [{ columnName: "subscription", direction: "desc" }],
  },
  hidden: [],
};

export default (state = intialState, action) => {
  switch (action.type) {
    case actions.FETCH_SUBSCRIBSION:
      return { ...state, loading: action.loading };
    case actions.FETCHING_SUBSCRIBSION_SUCCESS:
      console.log(action.data, 234567890987654);
      return {
        ...state,
        loading: action.loading,
        data: action.data,
        totalCount: action.totalCount,
      };
    case actions.FETCH_SUBSCRIBSION_FAILED:
      return { ...state, loading: action.loading, error: action.error };

    case actions.ADD_SUBSCRIBSION:
      return { ...state, loading: action.loading };
    case actions.ADD_SUBSCRIBSION_SUCCESS:
      return { ...state, loading: action.loading, adddata: action.adddata };
    case actions.ADD_SUBSCRIBSION_FAILURE:
      return { ...state, loading: action.loading, error: action.error };

    case actions.EDIT_SUBSCRIBSION:
      return { ...state, loading: action.loading };
    case actions.EDIT_SUBSCRIBSION_SUCCESS:
      return { ...state, loading: action.loading, editdata: action.editdata };
    case actions.EDIT_SUBSCRIBSION_FAILURE:
      return { ...state, loading: action.loading, error: action.error };
    case actions.UPDATE_SUBSCRIPTION_FILTER_DATA:
      return {
        ...state,
        filterProps: {
          ...state.filterProps,
          filters: action.payload.filter,
          filterArray: action.payload.filterArray,
        },
      };
    case actions.UPDATE_SUBSCRIPTION_SORT:
      return {
        ...state,
        filterProps: {
          ...state.filterProps,
          sorting: action.payload.sorting,
          sort: action.payload.sort,
        },
      };

    case actions.UPDATE_SUBSCRIPTION_HIDDEN_COL:
      return {
        ...state,
        hidden: action.payload,
      };
    default:
      return state;
  }
};
