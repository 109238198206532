import Login from "../views/login/Login";
import Reset from "../views/ResetPassword/Reset";
import ForgetPassword from "views/login/ForgetPassword";
import SignupScreen from "views/login/SignupScreen";
import VerifyOTPScreen from "views/login/VerifyOTPScreen";

const loginRoute = [
  {
    name: "Reset Password",
    title: "Reset Password",
    url: "/login/reset-password",
    component: (props) => <SignupScreen {...props} screenType="reset" />,
  },
  {
    name: "Register",
    title: "Register",
    url: "/login/register/:base64",
    component: (props) => <SignupScreen {...props} screenType="signup" />,
  },
  {
    name: "Forgot Password",
    title: "Forgot Password",
    url: "/login/forget-password",
    // isPrivate: false,
    component: ForgetPassword,
  },
  {
    name: "Login",
    title: "Login",
    url: "/login",
    component: Login,
  },
  {
    name: "Verify OTP",
    title: "Verify OTP",
    url: "/login/verify-otp",
    isPrivate: false,
    component: (props) => <VerifyOTPScreen {...props} />,
  },
];
export default loginRoute;

// import Dashboard from "../views/Dashboard/Dashboard";
// const routes = [
//   {
//     name: "Dashboard",
//     title: "Dashboard",
//     url: "/app/dashboard",
//     component: Dashboard,
//   },
// ];
// export default routes;
