export const FETCHING_USER_ROLES = "FETCHING_USER_ROLES";
export const FETCHING_USER_ROLES_SUCCESS = "FETCHING_USER_ROLES_SUCCESS";
export const FETCHING_USER_ROLES_FAILURE = "FETCHING_USER_ROLES_FAILURE";

export const FETCHING_MASTER_PERMISSIONS = "FETCHING_MASTER_PERMISSIONS";
export const FETCHING_MASTER_PERMISSIONS_SUCCESS =
  "FETCHING_MASTER_PERMISSIONS_SUCCESS";
export const FETCHING_MASTER_PERMISSIONS_FAILURE =
  "FETCHING_MASTER_PERMISSIONS_FAILURE";

export const UPDATE_ROLES_FILTER_DATA = "UPDATE_ROLES_FILTER_DATA";
export const CLEAR_ROLES_FILTER_DATA = "CLEAR_ROLES_FILTER_DATA";

export const UPDATE_ROLES_SORT = "UPDATE_ROLES_SORT";

export const UPDATE_HIDDEN_COL = "UPDATE_HIDDEN_COL";
