import React, { useState, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircle from "@material-ui/icons/AddCircleOutlineOutlined";
import Avatar from "@material-ui/core/Avatar";
import StarIcon from "@material-ui/icons/Star";
import Divider from "@material-ui/core/Divider";
import DexTable from "components/DexTable/DexTable";
import { checkPermission } from "helpers/permissons";
import {
  IconButton,
  CardContent,
  FormGroup,
  Card,
  Grid,
  Button,
  TextField,
  Typography,
  FormLabel,
  Select,
  FormControl,
  Tooltip,
  FormHelperText,
} from "@material-ui/core";
import SyncIcon from "@material-ui/icons/Sync";
import InputLabel from "@material-ui/core/InputLabel";
import DropDown from "../../../components/DropDown/DropDown";
import { RiDeleteBin5Line, RiEditLine } from "react-icons/ri";
import AddIcon from "@material-ui/icons/Add";
import withSection from "components/Section/Section";
import WrappedDexTable from "components/DexTable/WrappedDexTable";
import { confirmDialog } from "components/DeleteConfirmationDialog/Index";
import { dltMsg } from "helpers/helpers";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import { ApplicationOnboardUpadateHiddenCol } from "../ApplicationDetails/Redux/action";
const useStyles = makeStyles((theme) => ({
  line: {
    marginTop: -23,
  },

  dividerline: {
    // border: "1px solid black",
    width: "70%",
    margin: "-25px 0px 0px 110px",
    [theme.breakpoints.up("md")]: {
      width: "82%",
      margin: "-25px 0px 0px 260px",
    },
  },
  table: {
    minWidth: 850,
  },
}));
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,

    color: theme.palette.common.white,
  },
  body: {
    height: 1,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  // root: {
  //   "&:nth-of-type(odd)": {
  //     backgroundColor: theme.palette.action.hover,
  //   },
  // },
}))(TableRow);

function BillingConfig(props) {
  let error = false;
  const {
    billableServices,
    billingService_multiple,
    setBillingService_multiple,
    customFormData,
    usageParams,
    billingWH,
    handleSetColor,
    errValue,
    permission,
    onBoardTable,
  } = props;
  const { billingServices } = onBoardTable;
  const classes = useStyles();

  useEffect(() => {
    billingService_multiple.usageParams = [...usageParams];
    billingService_multiple.webhooks = { ...billingWH };
    setBillingService_multiple({ ...billingService_multiple });
  }, [usageParams, billingWH]);

  const onChangeWebhooks = (values) => {
    props.addBillingWebhooks(values);
    // setBillingService_multiple({
    //   ...billingService_multiple,
    //   webhooks: { ...values },
    // });
  };

  useEffect(() => {
    handleSetColor(errValue.billable ? "red" : "inherit");
  }, [handleSetColor, errValue]);

  const removeBillingService = async ({ row }) => {
    console.log(row, "row edit");
    let data = [...billingService_multiple.usageParams];

    let filteredUsageParams = data.filter((item, index) => {
      return item.key !== row.key;
    });
    await props.deleteBS(filteredUsageParams);
  };

  const pushToCreateForm = () => {
    let data = {
      name: "",
      dataKey: "",
      description: "",
      moduleName: "",
      key: props.usageParams.length || 0,
    };
    props.history.push("/app/create-billing-form", {
      billableServices: billableServices,
      moduleData: props.modules,
      usageParamsData: data,
      edit: false,
    });
  };

  const editUsageParams = ({ row }) => {
    console.log(row, "this is edit", 23);
    props.history.push("/app/create-billing-form", {
      billableServices: billableServices,
      usageParamsData: row,
      edit: true,
      moduleData: props.modules,
    });
  };
  const actionProvider = (row) => {
    console.log(row, "3436436");
    return (
      <div
        style={{
          display: "flex",
        }}
      >
        <div style={{ marginRight: 20 }}>
          <Tooltip title="Edit" aria-label="edit">
            <IconButton
              size="small"
              color="inherit"
              disabled={!checkPermission("btn81")}
              onClick={() => checkPermission("btn81") && editUsageParams(row)}
            >
              <RiEditLine fontSize="20px" />
            </IconButton>
          </Tooltip>
        </div>
        <div>
          <Tooltip title="Delete" aria-label="delete">
            <IconButton
              size="small"
              color="inherit"
              disabled={!checkPermission("btn87")}
              onClick={() =>
                checkPermission("btn87") &&
                confirmDialog(dltMsg(row?.row?.name || ""), () =>
                  removeBillingService(row)
                )
              }
            >
              <RiDeleteBin5Line fontSize="20px" />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    );
  };
  return (
    <div>
      {/* <div className=" mt-1  mt-md-3 w-100"> */}
      <div className="mb-3">
        <Typography>Setup billing webhook</Typography>
      </div>
      {/* <div className=" mt-3 pb-3" style={{ width: "100%" }}> */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Typography variant="body2" className="mb-1">
            URL
          </Typography>
          <TextField
            //label="URL"
            size="small"
            variant="outlined"
            fullWidth
            id="url"
            name="billingUrl"
            value={billingService_multiple.webhooks.url}
            onChange={(e) =>
              onChangeWebhooks({
                ...billingService_multiple.webhooks,
                url: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          {/* <TextField
                  label="Method"
                  size="small"
                  variant="outlined"
                  fullWidth
                  id="method"
                  onChange={(e) => onChangeWebhooks(e)}
                  name="billingMethod"
                /> */}
          <Typography variant="body2" className="mb-1">
            {" "}
            Method
          </Typography>
          <FormControl
            size="small"
            variant="outlined"
            name="method"
            className={classes.btncolor}
            style={{ width: "100%" }}
          >
            {/* <InputLabel htmlFor="method-type">Method</InputLabel> */}
            <Select
              native
              //label="Method"
              name="billingMethod"
              id="billingMethod"
              value={billingService_multiple.webhooks.method}
              onChange={(e) =>
                onChangeWebhooks({
                  ...billingService_multiple.webhooks,
                  method: e.target.value,
                })
              }
            >
              <option value="">- select -</option>
              <option value="GET">GET</option>
              <option value="POST">POST</option>
              <option value="PUT">PUT</option>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="body2" className="mb-1">
            {" "}
            API Key (Security)
          </Typography>
          <TextField
            //label="API Key (Security)"
            className={classes.btn}
            size="small"
            variant="outlined"
            fullWidth
            id="apikey"
            value={billingService_multiple.webhooks.apiKey}
            onChange={(e) =>
              onChangeWebhooks({
                ...billingService_multiple.webhooks,
                apiKey: e.target.value,
              })
            }
            name="billingApiKey"
          />
        </Grid>
        <Grid
          xs={12}
          sm={4}
          item
          container
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          <Button
            className="mr-1"
            size="small"
            variant="contained"
            onClick={() => checkPermission("btn75") && pushToCreateForm()}
            startIcon={<AddIcon />}
            disabled={!checkPermission("btn75")}
            style={{ padding: "4px 22px 4px 22px", marginTop: "25px" }}
          >
            Create Billable Services
          </Button>
        </Grid>
        {/* <Grid
          xs={12}
          sm={4}
          item
          container
          justifyContent="flex-end"
          alignItems="flex-end"
        > */}

        {/* </Grid> */}

        <Grid item md={12}>
          <Grid container spacing={2}>
            <Grid xs={12} item container justifyContent="flex-end">
              {/* <Button
              className="mr-1"
              size="small"
              variant="contained"
              onClick={() => checkPermission("btn75") && pushToCreateForm()}
              startIcon={<AddIcon />}
              disabled={!checkPermission("btn75")}
              style={{ padding: "4px 22px 4px 22px" }}
            >
              Create Billable Services
            </Button> */}
            </Grid>
            <Grid item xs={12}>
              <WrappedDexTable
                data={[...billingService_multiple.usageParams]}
                // hideColumnChooser={true}
                columWidthsType="percentage"
                columnOrder={[
                  "name",
                  "moduleName",
                  "dataKey",
                  "description",
                  "actions",
                ]}
                columns={[
                  { name: "name", title: "Billable Service" },
                  { name: "moduleName", title: "Module Name" },
                  { name: "dataKey", title: "Data Key" },
                  { name: "description", title: "Description" },
                  { name: "actions", title: "Actions" },
                ]}
                formatters={[
                  { formatterFn: actionProvider, columns: ["actions"] },
                ]}
                defaultColumnWidths={[
                  { columnName: "name", width: 15 },
                  { columnName: "moduleName", width: 20 },
                  { columnName: "dataKey", width: 24 },
                  { columnName: "description", width: 25 },
                  { columnName: "actions", width: 10 },
                ]}
                hidden={[...billingServices]}
                setHiddenColmn={(hide) => {
                  props.setHiddenColumn(hide, "billingServices");
                }}
                pagination={false}
                noDataMsg="Billable Services not found"
                Read={checkPermission("btn220")}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* </div> */}
    </div>
    // </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setHiddenColumn: (hide, type) =>
    dispatch(ApplicationOnboardUpadateHiddenCol(hide, type)),
});

const mapStateToProps = (state) => {
  return {
    onBoardTable: state.appDetailsRedcer.onBoardTable,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withSection(BillingConfig, "Billable Services *")));
