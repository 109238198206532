import { React, useState, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import Avatar from "@material-ui/core/Avatar";
import StarIcon from "@material-ui/icons/Star";
import Divider from "@material-ui/core/Divider";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import SyncIcon from "@material-ui/icons/Sync";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import { TextValidator } from "react-material-ui-form-validator";
import { RiDeleteBin5Line, RiEditLine } from "react-icons/ri";
import AddIcon from "@material-ui/icons/Add";
import { checkPermission } from "helpers/permissons";
import { Tooltip } from "@material-ui/core";

import {
  IconButton,
  CardContent,
  FormGroup,
  Card,
  Grid,
  Button,
  TextField,
  Typography,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@material-ui/core";

import withSection from "components/Section/Section";

import DexTable from "components/DexTable/DexTable.jsx";
import WrappedDexTable from "components/DexTable/WrappedDexTable";
import { confirmDialog } from "components/DeleteConfirmationDialog/Index";
import { dltMsg } from "helpers/helpers";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import { ApplicationOnboardUpadateHiddenCol } from "../ApplicationDetails/Redux/action";
const useStyles = makeStyles((theme) => ({
  line: {
    marginTop: -23,
  },
  table: {
    width: "99%",
  },
  dividerline: {
    // border: "1px solid black",
    width: "70%",
    margin: "-25px 0px 0px 125px",
    [theme.breakpoints.up("md")]: {
      width: "82%",
      margin: "-25px 0px 0px 260px",
    },
  },
}));

function AuthorizationObjects(props) {
  const {
    authObject,
    errValue,
    handleSetColor,
    setAuthObject,
    data,
    setBasicInfo,
    basicInfo,
    authRole,
    permission,
    onBoardTable,
  } = props;
  const { authorization } = onBoardTable;
  const classes = useStyles();

  useEffect(() => {
    let data = { ...authObject };
    data.roles = [...authRole];
    data.webhooks = { ...props.authWH };
    setAuthObject({ ...data });
  }, [authRole, props.authWH]);

  useEffect(() => {
    handleSetColor(errValue.auth ? "red" : "inherit");
  }, [handleSetColor, errValue]);

  console.log(props.authWH, "fhfghfghfh");
  const pushToCreateForm = () => {
    let data = {
      name: "",
      description: "",
      applicationRoleName: "",
      key: authRole.length || 0,
    };
    props.history.push("/app/create-auth-form", {
      authRoleData: data,
      edit: false,
    });
  };

  const editauthrole = ({ row }) => {
    props.history.push("/app/create-auth-form", {
      authRoleData: row,
      edit: true,
    });
  };

  const removeauthrole = async ({ row }) => {
    let data = [...authRole];
    let filteredData = data.filter((item, index) => {
      return item.key !== row.key;
    });
    await props.deleteAuthRole(filteredData);
  };

  const actionProvider = (row) => {
    console.log(row, "3436436");
    return (
      <div
        style={{
          display: "flex",
        }}
      >
        <div style={{ marginRight: 20 }}>
          <Tooltip title="Edit" aria-label="edit">
            <IconButton
              size="small"
              color="inherit"
              disabled={!checkPermission("btn80")}
              onClick={() => checkPermission("btn80") && editauthrole(row)}
            >
              <RiEditLine fontSize="20px" />
            </IconButton>
          </Tooltip>
        </div>
        <div>
          <Tooltip title="Delete" aria-label="delete">
            <IconButton size="small" color="inherit">
              <RiDeleteBin5Line
                fontSize="20px"
                onClick={() =>
                  checkPermission("btn86") &&
                  confirmDialog(dltMsg(row?.row?.name || ""), () =>
                    removeauthrole(row)
                  )
                }
                disabled={!checkPermission("btn86")}
              />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    );
  };

  const handleChange = (value) => {
    props.addAuthWBH(value);
  };

  const handle_Change = (value) => {
    props.addAppBasicDetails(value);
  };
  console.log(basicInfo, "gggggggg");
  return (
    <div>
      <div>
        <Grid>
          <Grid item xs={12} sm={12} md={12} container direction="row">
            <Typography variant="body2" className="pr-3 mt-2">
              Single sign on :
            </Typography>
            <RadioGroup
              value={basicInfo.singleSignOn}
              onChange={(e) => {
                handle_Change({
                  ...basicInfo,
                  singleSignOn: e.target.value,
                });
              }}
            >
              <div style={{ display: "flex" }}>
                <FormControlLabel
                  name="supported"
                  value="Supported"
                  control={<Radio color="primary" />}
                  label={
                    <Typography style={{ fontSize: 15 }}>Supported</Typography>
                  }
                />{" "}
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                <FormControlLabel
                  value="Not Supported"
                  control={<Radio color="primary" />}
                  label={
                    <Typography style={{ fontSize: 15 }}>
                      Not Supported
                    </Typography>
                  }
                  color="primary"
                />
              </div>
            </RadioGroup>
          </Grid>
        </Grid>
        {basicInfo.singleSignOn === "Supported" && (
          <div>
            <Typography>Setup webhook</Typography>
            <div className=" mt-3 pb-3" style={{ width: "100%" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography variant="body2" className="mb-1">
                    URL
                  </Typography>
                  <TextField
                    name="authUrl"
                    size="small"
                    variant="outlined"
                    fullWidth
                    id="authUrl"
                    value={authObject.webhooks.url}
                    onChange={(e) =>
                      handleChange({
                        ...authObject.webhooks,
                        url: e.target.value.trimStart(),
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={2} md={2}>
                  <Typography variant="body2" className="mb-1">
                    {" "}
                    Method
                  </Typography>
                  <FormControl
                    size="small"
                    variant="outlined"
                    name="method"
                    className={classes.btncolor}
                    style={{ width: "100%" }}
                  >
                    {/* <InputLabel htmlFor="method-type">Method</InputLabel> */}
                    <Select
                      native
                      value={authObject.webhooks.method}
                      name="authMethod"
                      id="method"
                      onChange={(e) =>
                        handleChange({
                          ...authObject.webhooks,
                          method: e.target.value,
                        })
                      }
                    >
                      <option value="">- select -</option>
                      <option value="GET">GET</option>
                      <option value="POST">POST</option>
                      <option value="PUT">PUT</option>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={2} md={2}>
                  <Typography variant="body2" className="mb-1">
                    {" "}
                    API Key (Security)
                  </Typography>
                  <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    id="apikey"
                    value={authObject.webhooks.apiKey}
                    onChange={(e) =>
                      handleChange({
                        ...authObject.webhooks,
                        apiKey: e.target.value.trimStart(),
                      })
                    }
                    // value={data.apikey}
                    name="authApiKey"
                  />
                </Grid>
                {/* <Grid
                  xs={12}
                  sm={4}
                  item
                  container
                  justifyContent="flex-end"
                  alignItems="flex-end"
                >
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() => pushToCreateForm()}
                    startIcon={<AddIcon />}
                    style={{ padding: "4px 54px 4px 54px" }}
                  >
                    Create Roles
                  </Button>
                </Grid>

                <Grid item xs={12}>
                  <WrappedDexTable
                    height="300px"
                    data={[...authObject.roles]}
                    columWidthsType="percentage"
                    columnOrder={[
                      "name",
                      "applicationRoleName",
                      "description",
                      "actions",
                    ]}
                    columns={[
                      { name: "name", title: "Role Name" },
                      {
                        name: "applicationRoleName",
                        title: "Application Role Name",
                      },
                      { name: "description", title: "Description" },
                      { name: "actions", title: "Actions" },
                    ]}
                    formatters={[
                      { formatterFn: actionProvider, columns: ["actions"] },
                    ]}
                    defaultColumnWidths={[
                      { columnName: "name", width: 19 },
                      { columnName: "applicationRoleName", width: 25 },
                      { columnName: "description", width: 40 },
                      { columnName: "actions", width: 14 },
                    ]}
                    pagination={false}
                    noDataMsg="Roles not found"
                  />
                </Grid> */}
                <Grid
                  xs={12}
                  sm={4}
                  item
                  container
                  justifyContent="flex-end"
                  alignItems="flex-end"
                >
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() =>
                      checkPermission("btn74") && pushToCreateForm()
                    }
                    startIcon={<AddIcon />}
                    style={{ padding: "4px 54px 4px 54px" }}
                    disabled={!checkPermission("btn74")}
                  >
                    Create Roles
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
        )}

        {basicInfo.singleSignOn === "Supported" && (
          <Grid container spacing={2}>
            {/* <Grid
              xs={12}
              item
              container
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <Button
                size="small"
                variant="contained"
                onClick={() => checkPermission("btn74") && pushToCreateForm()}
                startIcon={<AddIcon />}
                style={{ padding: "4px 54px 4px 54px" }}
                disabled={!checkPermission("btn74")}
              >
                Create Roles
              </Button>
            </Grid> */}

            <Grid item xs={12}>
              <WrappedDexTable
                data={[...authObject.roles]}
                // hideColumnChooser={true}
                columWidthsType="percentage"
                columnOrder={[
                  "name",
                  "applicationRoleName",
                  "description",
                  "actions",
                ]}
                columns={[
                  { name: "name", title: "Role Name" },
                  {
                    name: "applicationRoleName",
                    title: "Application Role Name",
                  },
                  { name: "description", title: "Description" },
                  { name: "actions", title: "Actions" },
                ]}
                formatters={[
                  { formatterFn: actionProvider, columns: ["actions"] },
                ]}
                defaultColumnWidths={[
                  { columnName: "name", width: 19 },
                  { columnName: "applicationRoleName", width: 25 },
                  { columnName: "description", width: 40 },
                  { columnName: "actions", width: 14 },
                ]}
                hidden={[...authorization]}
                setHiddenColmn={(hide) => {
                  props.setHiddenColumn(hide, "authorization");
                }}
                pagination={false}
                noDataMsg="Roles not found"
                Read={checkPermission("btn219")}
              />
            </Grid>
          </Grid>
        )}
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setHiddenColumn: (hide, type) =>
    dispatch(ApplicationOnboardUpadateHiddenCol(hide, type)),
});

const mapStateToProps = (state) => {
  return {
    onBoardTable: state.appDetailsRedcer.onBoardTable,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withSection(AuthorizationObjects, "Authorization Roles *")));
