/**
 *
 */

import * as actions from "./actionTypes";

const intialState = {
  data: [],
  loading: false,
  load: true,
  editdata: {},
  totalCount: 0,
  subscription: [],
  filterProps: {
    filters: {},
    filterArray: [{ key: 1, columns: "", operator: "", value: "" }],
    sort: { createdon: -1 },
    sorting: [{ columnName: "validFrom", direction: "desc" }],
  },
  hidden: ["validFrom", "validTill", "counterType"],
};

const reducer = (state = intialState, action) => {
  switch (action.type) {
    case actions.FETCH_PRICING_MASTER:
      return { ...state, loading: action.loading };
    case actions.FETCH_PRICING_MASTER_SUCCESS:
      return {
        ...state,
        loading: action.loading,
        data: action.data,
        totalCount: action.totalCount,
      };
    case actions.FETCH_PRICING_MASTER_FAILURE:
      return { ...state, loading: action.loading, error: action.error };

    case actions.EDIT_PRICING_MASTER:
      return { ...state, loading: action.loading };
    case actions.EDIT_PRICING_MASTER_SUCCESS:
      return { ...state, loading: action.loading, data: action.editdata };
    case actions.EDIT_PRICING_MASTER_FAILURE:
      return { ...state, loading: action.loading, error: action.error };

    case actions.DELETE_PRICING_MASTER:
      return { ...state, loading: action.loading };
    case actions.DELETE_PRICING_MASTER_SUCCESS:
      return { ...state, loading: action.loading, deldata: action.deldata };
    case actions.DELETE_PRICING_MASTER_FAILURE:
      return { ...state, loading: action.loading, error: action.error };
    case actions.LOAD_LOADING:
      return { ...state, load: action.payload };
    case actions.UPDATE_PRICING_FILTER_DATA:
      return {
        ...state,
        filterProps: {
          ...state.filterProps,
          filters: action.payload.filter,
          filterArray: action.payload.filterArray,
        },
      };
    case actions.UPDATE_PM_HIDDEN_COL:
      return {
        ...state,
        hidden: action.payload,
      };
    case actions.UPDATE_PRICINGMASTER_SORT:
      return {
        ...state,
        filterProps: {
          ...state.filterProps,
          sorting: action.payload.sorting,
          sort: action.payload.sort,
        },
      };
    default:
      return state;
  }
};

export default reducer;
