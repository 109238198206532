import { React, useState, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import AddIcon from "@material-ui/icons/Add";
import DeviceHubIcon from "@material-ui/icons/DeviceHub";

import { CustomNumberFeild } from "components/Customized/index";

import SaveIcon from "@material-ui/icons/Save";
import {
  Tooltip,
  Button,
  TextField,
  IconButton,
  Typography,
  TableContainer,
  TableBody,
} from "@material-ui/core";
import {
  TextValidator,
  SelectValidator,
  ValidatorForm,
} from "react-material-ui-form-validator";
import withSection from "components/Section/Section";
import DexTable from "components/DexTable/DexTable";
import Grid from "@material-ui/core/Grid";
import { RiEditLine, RiDeleteBin5Line } from "react-icons/ri";
import WrappedDexTable from "components/DexTable/WrappedDexTable";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  button: {
    padding: "4px 30px",
  },
  dividerline: {
    width: "70%",
    margin: "-25px 0px 0px 125px",
    [theme.breakpoints.up("md")]: {
      width: "82%",
      margin: "-25px 0px 0px 260px",
    },
  },
}));
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    width: 15,
    height: 1,
  },
  btn: {
    width: "300px",
  },
}))(TableCell);

const CustomTableRow = (props) => {
  const classes = useStyles();
  const { length, quantityScale, slabFixedRate, setSlabFixedRate } = props;
  const [msg, setMsg] = useState("");
  const [err, setErr] = useState(false);
  const [rows, setRows] = useState([...quantityScale]);
  const min = 1;
  const max = 100;

  useEffect(() => {
    setRows([...quantityScale]);
  }, [quantityScale]);
  const addRow = (arr, rowId) => {
    let num = 1 + parseInt(rows[rowId].end);
    let data = {
      start: num,
      end: "",
      price: "",
      add: true,
      edit: false,
    };
    arr.push(data);
    props.onSaveQuantityScale(arr);
  };

  const onSave = (rowId) => {
    console.log(rows[rowId]);
    if (rows[rowId].end.trim() === "" || rows[rowId].price.trim() === "") {
      setErr(true);
      return;
    }
    setErr(false);
    let updateRows = rows.map((item, id) => {
      return id === rowId ? { ...item, status: true } : { ...item };
    });
    addRow(updateRows, rowId);
  };

  const onDelete = (rowId) => {
    let updateRows = [];
    if (length === 1) {
      let data = {
        start: 0,
        end: "",
        price: "",
        add: true,
        edit: false,
      };
      updateRows = rows.map((item, id) => {
        return { ...data };
      });
      setSlabFixedRate({
        gt: null,
        price: null,
      });
      props.onSaveQuantityScale(updateRows);
    } else {
      updateRows = rows.filter((item, id) => {
        return id !== rowId;
      });
      props.onSaveQuantityScale(updateRows);
      let obj = updateRows[updateRows.length - 1];
      setSlabFixedRate({
        ...slabFixedRate,
        gt: length === 1 ? null : parseInt(obj.end) + 1,
      });
    }
  };
  const onCancel = (rowId) => {
    onDelete(rowId);
  };

  const handleOnChange = (val, rowId) => {
    let data = [...rows];
    data[rowId] = { ...val };
    if (data[rowId + 1]) {
      data[rowId + 1].start = 1 + parseInt(data[rowId].end);
    }
    if (data[rowId].status) {
      props.onSaveQuantityScale(data);
    } else {
      setRows([...data]);
      props.onSaveQuantityScale(data);
    }
  };
  console.log();
  // let disable = rowId + 1 !== rows.length ? true : false;
  let disable = false;

  // rows[rowId].start > rows[rowId].end && rows[rowId].end.trim() !== ""
  //   ? true
  //   : false;
  let valueErr = false;

  return (
    <div>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: "20%" }}>Start Value</TableCell>
              <TableCell style={{ width: "20%" }} align="center">
                End Value
              </TableCell>
              <TableCell style={{ width: "20%" }} align="center">
                {" "}
                Unit Price{" "}
              </TableCell>
              <TableCell style={{ width: "8%" }} align="center">
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow style={{ paddingTop: "2px", paddingBottom: "2px" }}>
                {
                  (valueErr =
                    (row.status && row.start > row.end) ||
                    (row.status && row.end.trim() === "")
                      ? true
                      : false)
                }
                <StyledTableCell>
                  <CustomNumberFeild
                    className="mt-2"
                    disabled
                    size="small"
                    variant="outlined"
                    fullWidth
                    name="apiKey"
                    value={row.start}
                    onChange={(e) => {
                      handleOnChange({ ...row, start: e.target.value }, index);
                    }}
                    helperText={<div style={{ minHeight: "20px" }}></div>}
                  />
                </StyledTableCell>
                <StyledTableCell align="left">
                  <CustomNumberFeild
                    className="mt-2"
                    disabled={props.isDisabled}
                    size="small"
                    variant="outlined"
                    fullWidth
                    type="number"
                    value={row.end}
                    onChange={(e) => {
                      setSlabFixedRate({
                        ...slabFixedRate,
                        gt: parseInt(e.target.value) + 1,
                      });
                      handleOnChange({ ...row, end: e.target.value }, index);
                    }}
                    onFocus={() => setErr(false)}
                    error={(err && row.end.trim() === "") || valueErr}
                    helperText={
                      <div style={{ minHeight: "20px" }}>
                        {err && row.end.trim() === ""
                          ? "End range required"
                          : ""}
                        {valueErr && "Invalid end value"}
                        <span style={{ color: "#DF0E0E" }}>
                          {" "}
                          {!valueErr && row.end.trim() !== ""
                            ? row.start > row.end
                              ? "End range should be greater than start range"
                              : ""
                            : ""}
                        </span>
                      </div>
                    }
                    validators={["required"]}
                    errorMessages={["End range required"]}
                  />
                </StyledTableCell>
                <StyledTableCell align="left">
                  <CustomNumberFeild
                    className="mt-2"
                    size="small"
                    disabled={props.isDisabled}
                    variant="outlined"
                    fullWidth
                    name="apiKey"
                    type="number"
                    value={row.price}
                    onChange={(e) => {
                      handleOnChange({ ...row, price: e.target.value }, index);
                    }}
                    onFocus={() => setErr(false)}
                    error={err && row.price.trim() === ""}
                    helperText={
                      <div style={{ minHeight: "20px" }}>
                        {err && row.price.trim() === "" ? "Price required" : ""}
                      </div>
                    }
                    validators={["required"]}
                    errorMessages={["Price required"]}
                  />
                </StyledTableCell>

                <StyledTableCell align="left">
                  {row.add && index + 1 === rows.length ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around",
                        paddingBottom: "20px",
                      }}
                    >
                      <Tooltip title={`Add`}>
                        <IconButton
                          size="small"
                          onClick={() => onSave(index)}
                          disabled={props.isDisabled}
                        >
                          <AddIcon
                            style={{ minHeight: "20px" }}
                            color="inherit"
                          />
                        </IconButton>
                      </Tooltip>
                      <div>
                        {length > 1 || row.status !== undefined ? (
                          <div>
                            {" "}
                            <IconButton
                              size="small"
                              onClick={() => onCancel(index)}
                              disabled={props.isDisabled}
                            >
                              <RiDeleteBin5Line
                                color="inherit"
                                fontSize="20px"
                              />
                            </IconButton>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ) : null}
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {slabFixedRate.gt && (
          <Table>
            <TableBody>
              <TableRow style={{ paddingTop: "2px", paddingBottom: "2px" }}>
                <StyledTableCell style={{ width: "20%" }}>
                  <Typography variant="body2">Greater than</Typography>
                  <div style={{ minHeight: "6px" }}></div>
                </StyledTableCell>
                <StyledTableCell style={{ width: "20%" }}>
                  <div style={{ width: "100%" }}>
                    <CustomNumberFeild
                      disabled
                      className="mt-2"
                      size="small"
                      variant="outlined"
                      fullWidth
                      name="gt"
                      type="number"
                      value={slabFixedRate.gt}
                    />
                  </div>
                  <div style={{ minHeight: "6px" }}></div>
                </StyledTableCell>
                <StyledTableCell style={{ width: "20%" }}>
                  <div style={{ width: "100%" }}>
                    <CustomNumberFeild
                      className="mt-2"
                      size="small"
                      disabled={props.isDisabled}
                      variant="outlined"
                      fullWidth
                      name="apiKey"
                      type="number"
                      value={slabFixedRate.price}
                      onChange={(e) => {
                        setSlabFixedRate({
                          ...slabFixedRate,
                          price:
                            e.target.value.trim() !== ""
                              ? e.target.value
                              : null,
                        });
                      }}
                      validators={["required"]}
                      errorMessages={["Price required"]}
                    />
                  </div>
                  <div style={{ minHeight: "6px" }}></div>
                </StyledTableCell>

                <StyledTableCell
                  align="left"
                  style={{ width: "8%" }}
                ></StyledTableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </div>
  );
};

export default CustomTableRow;
