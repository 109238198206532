import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Card,
  Divider,
  CardContent,
} from "@material-ui/core";
import PrintSummary from "../print/PrintSummary";
import AddCreditnote from "../AddCreditnote";
const InvoiceSummary = (props) => {
  const { classes, appUsageDetails, InvoiceData, type } = props;
  const [invoiceSummary, setInvoiceSummary] = useState({
    TotalCredit: 0,
    TotalDebit: 0,
  });

  useEffect(() => {
    let obj = { ...invoiceSummary };
    if (InvoiceData.NotesSum) {
      InvoiceData.NotesSum.map((itm) => {
        if (itm.Type === "credit") {
          obj = {
            ...obj,
            TotalCredit: itm.Total,
          };
        }
        if (itm.Type === "debit") {
          obj = {
            ...obj,
            TotalDebit: itm.Total,
          };
        }
        setInvoiceSummary({
          ...obj,
        });
        return 0;
      });
    } else {
      setInvoiceSummary({
        TotalCredit: 0,
        TotalDebit: 0,
      });
    }
  }, [InvoiceData]);

  console.log(InvoiceData.NotesSum, "notes");
  return (
    <>
      {type !== "print" && (
        <Card>
          <Grid container spacing={2}>
            <Grid item md={12} sm={12}>
              <div className={classes.flx}>
                <Typography className={classes.heading} variant="h5">
                  Total Invoice
                </Typography>
                {/* <Typography className={classes.totalvalue}>{`${parseInt(
                InvoiceData.TotalInvoiceAmount -
                  invoiceSummary.TotalCredit +
                  invoiceSummary.TotalDebit || 0
              ).toFixed(2)}`}</Typography> */}
                <Typography className={classes.totalvalue}>{`${parseFloat(
                  InvoiceData.TotalInvoiceAmount || 0
                ).toFixed(2)}`}</Typography>
              </div>
            </Grid>
          </Grid>
          <Divider fullWidth />
          <CardContent>
            <Typography variant="h5" className={classes.summary}>
              Invoice Summary
            </Typography>
            <br />
            {Object.keys(appUsageDetails).map((row) => {
              const { appDetails, billableService } =
                appUsageDetails[row] || {};
              return (
                <Grid container>
                  <Grid item md={12} container alignItems="center">
                    <Typography
                      variant="h4"
                      style={{
                        fontWeight: "bold",
                        textTransform: "capitalize",
                      }}
                    >
                      {appDetails.name}
                    </Typography>
                  </Grid>
                  <Grid item md={12}>
                    <div>
                      {billableService.map((row) => (
                        <Grid
                          container
                          justifyContent="space-between"
                          direction="row"
                        >
                          <Grid item style={{ marginLeft: "2%" }}>
                            <Typography>{row?.BillableDetails.name}</Typography>
                          </Grid>
                          <Grid item>
                            <Typography>{`${parseFloat(
                              row.GrossAmount || 0
                            ).toFixed(2)}`}</Typography>
                          </Grid>
                     
                        </Grid>
                      ))}
                    </div>
                    <br />
                  </Grid>
                </Grid>
              );
            })}
            <Grid container>
              <Grid item md={12}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 3,
                  }}
                >
                  <Typography variant="body2">Discount </Typography>
                  <Typography>{`-${parseFloat(
                    InvoiceData?.DiscountAmount || 0
                  ).toFixed(2)}`}</Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 3,
                  }}
                >
                  <Typography variant="body2">Tax </Typography>
                  <Typography>{`${parseFloat(InvoiceData?.TaxAmt || 0).toFixed(
                    2
                  )}`}</Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 3,
                  }}
                >
                  <Typography variant="body2">Credit note </Typography>
                  <Typography>{`-${parseFloat(
                    invoiceSummary.TotalCredit || 0
                  ).toFixed(2)}`}</Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body2">Debit note </Typography>
                  <Typography>{`${parseFloat(
                    invoiceSummary.TotalDebit || 0
                  ).toFixed(2)}`}</Typography>
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}

      {type === "print" && (
        <PrintSummary invoiceSummary={invoiceSummary} {...props} />
      )}
      <br />
      {/* <Grid container>
        <Grid item md={12}>
          <div className={classes.flx}>
            <Typography className={classes.issued}>Issued on :</Typography>
            <Typography className={classes.issued}>Paid on :</Typography>
          </div>
        </Grid>
      </Grid> */}
    </>
  );
};
export default InvoiceSummary;
