import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  main: {
    marginTop: "4rem",

    "& .MuiContainer-root": {
      paddingLeft: 30,
      paddingRight: 30,
    },
  },
  topContent: {
    position: "fixed",
    top: "3.7rem",
    left: 0,
    padding: "0 30px",
    zIndex: 1000,
    width: "100%",
    backgroundColor: theme.palette.background.default,
  },
  wrapper: {
    position: "relative",
    top: "6rem",
    margin: "0 30px",
  },
  fieldWrap: {
    marginTop: "1rem",
  },
  // fieldPanel: {
  //   marginBottom: '1rem',
  //   marginTop: '1rem',
  //   boxShadow: '0px 1px 5px -1px rgb(225 225 225 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
  //   border: '1px solid #DFDFDF',

  //   "&:before": {
  //     backgroundColor: 'unset'
  //   },

  //   "& .MuiAccordionDetails-root": {
  //     display: 'block'
  //   }
  // },
  fieldPanel: {
    marginBottom: "1rem",
    marginTop: "1rem",
    boxShadow: "unset",
    border: "unset",
    backgroundColor: "transparent",

    "&:before": {
      backgroundColor: "unset",
    },

    "& .MuiAccordionSummary-root": {
      minHeight: 48,
      padding: 0,
      borderBottom: "2px solid #afafaf",

      "& .MuiAccordionSummary-content.Mui-expanded": {
        margin: 0,
      },

      "& .MuiIconButton-root": {
        padding: "0px 12px",

        "& .MuiTouchRipple-root": {
          display: "none",
        },
      },
    },

    "& .MuiAccordionDetails-root": {
      display: "block",
    },
  },
  accordianIcon: {
    fontSize: "3rem",
    paddingTop: 0,
    paddingBottom: 0,
    color: "#000000",
  },
  heading: {
    font: "normal normal bold 21px/28px Nunito",
    letterSpacing: 0,
    color: "#161616",
    marginTop: 10,
    marginBottom: 10,
  },
  sectionHeading: {
    font: "normal normal 600 16px/19px Nunito;",
    letterSpacing: 0,
    color: "#000000",
    width: "100%",
  },
  fieldgroupHeading: {
    font: "normal normal 600 15px/21px Nunito;",
    letterSpacing: 0,
    color: "#000000",
    width: "100%",
    borderBottom: "2px solid #afafaf",
    margin: 0,
    padding: "1rem 0 .5rem",
  },
  fieldHeading: {
    font: "normal normal 600 14px/19px Nunito;",
    letterSpacing: 0,
    color: "#000000",
    width: "100%",
    opacity: 1,
  },
  fileName: {
    font: "normal normal 12px/16px Nunito;",
    letterSpacing: 0,
    color: "#000000",
    width: "100%",
  },
  fieldHeading2: {
    font: "normal normal 600 14px/19px Nunito",
    letterSpacing: 0,
    color: "#000000",
    width: "100%",
  },
  input: {
    width: "100%",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    height: "38px",
    padding: "0 15px",
    marginTop: 5,
    marginBottom: 5,
    outline: "none",
    font: "normal normal normal 12px/16px Nunito",
    // color: "#000000C6",

    "&::before": {
      borderBottom: "unset",
    },
    // "&::placeholder": {
    //   textAlign: "left",
    //   font: "normal normal 600 13px/18px Nunito",
    //   letterSpacing: "0px",
    //   color: "#707070",
    //   opacity: "0.6",
    // },
  },
  input2: {
    width: "100%",
    background: "#f5f5f5 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    height: "38px",
    padding: "0 15px",
    marginTop: 5,
    marginBottom: 5,
    outline: "none",
    font: "normal normal normal 12px/16px Nunito",

    "&::before": {
      borderBottom: "unset",
    },
  },
  select: {
    width: "100%",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    height: "38px",
    marginTop: 5,
    font: "normal normal normal 12px/16px Nunito",

    "&::before": {
      borderBottom: "unset !important",
    },

    "& .MuiSelect-select": {
      paddingLeft: 15,
    },
  },
  rootSelect: {},
  textarea: {
    width: "100%",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    // height: "80%",
    font: "normal normal normal 12px/16px Nunito",
    marginTop: "5px",
    resize: "vertical",
    minHeight: "2.2rem",
    maxHeight: "13.2rem",
    padding: "10px 15px",
    outline: "none",
    font: "normal normal normal 12px/16px Nunito;",
  },
  textarea2: {
    width: "100%",
    background: "#f5f5f5 0% 0% no-repeat padding-box",
    font: "normal normal normal 12px/16px Nunito",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    height: "80%",
    marginTop: "5px",
    resize: "none",
    padding: "10px 15px",
  },
  editMode: {
    background: "#f5f5f5 0% 0% no-repeat padding-box !important",
    paddingBottom: 2,
    borderBottom: "1px solid #DFDFDF",
  },
  error: {
    border: "1px solid #E22626 !important",
  },
  errorSearch: {
    borderBottom: "1px solid #E22626 !important",
  },
  red: {
    color: "#E22626",
  },
  errorAuto: {
    // borderBottom: "2px solid #E22626 !important",
    "& .MuiAutocomplete-inputRoot::before": {
      borderBottom: "1px solid #E22626 !important",
    },
    marginBottom: 5,
  },
  errorMsg: {
    color: "#DF0E0E",
    font: "normal normal 600 12px/16px Nunito;",
    margin: 0,
  },
  datePickerIcon: {
    "& button": {
      paddingLeft: 5,
      pointerEvents: "all",

      "&:hover": {
        backgroundColor: "unset",
      },
    },
  },
  editModeDatePicker: {
    "& button": {
      pointerEvents: "none",
    },
  },
  modifyFieldBtn: {
    background: "#002855 0% 0% no-repeat padding-box",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#002855",
    },
  },
  fieldItem: {
    marginTop: "2rem",
    marginBottom: "2rem",
  },
  fieldText: {
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: 10,
    font: "normal normal 600 12px/16px Nunito",
  },
  searchInput: {
    width: "50%",
  },
  searchInput2: {
    width: "25%",
  },
  customSearchInput: {
    border: "unset",
    borderBottom: "1px solid #707070",
    background: "unset !important",
    borderRadius: 0,

    "& input": {
      padding: 0,
    },
  },
  searchInput3: {
    width: "100%",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    height: "35px",
    marginTop: "5px",
    marginBottom: 5,

    "& .MuiInput-underline": {
      padding: "0 15px",
      "&::before": {
        borderBottom: "unset !important",
      },
    },
  },
  selectedServices: {
    marginTop: "1rem",
  },
  serviceChips: {
    marginTop: -10,
  },
  serviceChip: {
    marginRight: 15,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #707070",
    "& span": {
      font: "normal normal 600 13px/22px Nunito",
      color: "#000000",
      marginRight: 10,
    },
    "& svg": {
      color: theme.palette.primary.main,
    },
  },
  teamCheckbox: {
    padding: "5px 5px 5px 10px",
  },
  technologyCheck: {
    display: "flex",
    alignItems: "center",
  },
  border: {
    borderBottom: "1px solid #afafaf",
    opacity: 0.4,
    marginBottom: "1rem",
  },
  section: {
    marginTop: "1rem",
  },
  paper: {
    // margin: '10px 0',
    padding: "8px 15px",
    boxShadow: "0px 3px 6px #3C3C3C1A",
    borderRadius: "10px",
  },
  teamTab: {
    marginBottom: "1rem",
    "& .MuiInput-underline": {
      padding: "0 15px",
      "&::before": {
        borderBottom: "unset !important",
      },
    },
  },
  selectedServices: {
    marginTop: "1rem",
  },
  serviceChips: {
    marginTop: -10,
  },
  serviceChip: {
    marginRight: 15,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #707070",
    "& span": {
      font: "normal normal 600 13px/22px Nunito",
      color: "#000000",
      marginRight: 10,
    },
    "& svg": {
      color: theme.palette.primary.main,
    },
  },
  teamCheckbox: {
    padding: "5px 5px 5px 10px",
  },
  memberProfile: {
    borderRadius: "50%",
  },
  technologyCheck: {
    display: "flex",
    alignItems: "center",
  },
  border: {
    borderBottom: "2px solid #afafaf",
    opacity: 0.4,
    marginBottom: "1rem",
  },
  teamTab: {
    marginBottom: "1rem",

    "& button": {
      minWidth: 100,
    },
    "& span": {
      fontSize: 16,
      textTransform: "none",
    },
  },
  teamMemberPaper: {
    borderRadius: 0,
    padding: "10px 15px",
    boxShadow: "0px 3px 6px #3C3C3C1A",
    textAlign: "center",
    cursor: "pointer",

    "& img": {
      height: 60,
      width: 60,
      marginBottom: 15,
    },
  },
  teamWrapper: {
    display: "flex",
    alignItems: "center",

    "& img": {
      width: 40,
      height: 40,
    },
  },
  teamMember: {
    marginLeft: 20,
  },
  teamMemberName: {
    fontSize: 14,
    fontWeight: "600",
  },
  teamMemberDesgination: {
    fontSize: 12,
    marginTop: 5,
    opacity: 0.8,
  },
  editIcon: {
    marginLeft: 10,
    padding: 5,
  },
  spaceBetweenText: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    "& p": {
      fontSize: 14,
    },
  },
  apiTab: {
    marginTop: "1rem",
    marginBottom: "1rem",
    borderBottom: "2px solid rgba(0, 40, 85, 1)",
    position: "relative",
    bottom: 0,

    "& button": {
      minWidth: 150,
    },
    "& span": {
      fontSize: 16,
      textTransform: "capitalize",
    },
  },
  documentTab: {
    "& button": {
      minWidth: 150,
    },
    "& span": {
      fontSize: 16,
      textTransform: "capitalize",
    },
  },
  documentDropperWrapper: {
    marginTop: "1.5rem",
    marginBottom: "1.5rem",

    "& .documentDropperClass": {
      width: "20rem",
      minHeight: "100px",
      padding: "1.5rem 3rem 0rem",
      border: "2.5px solid",
      borderColor: theme.palette.primary.main,
      borderRadius: 10,

      "& .MuiDropzoneArea-textContainer": {
        display: "flex",
        flexDirection: "column-reverse",
        alignItems: "center",
        justifyContent: "space-around",

        "& .MuiDropzoneArea-icon": {
          color: "#00285580",
          transform: "scale(1.4)",
        },

        "& .documentDropperTextClass": {
          fontSize: "1rem",
          color: "rgba(0, 0, 0, 0.8)",
          fontWeight: "600",
          color: "#00285580",
        },
      },
    },
  },
  documentDropperWrapper2: {
    marginTop: "1.5rem",
    marginBottom: "1.5rem",

    "& .documentDropperClass": {
      width: "20rem",
      minHeight: "100px",
      padding: "1.5rem 3rem 0rem",
      border: "2.5px solid",
      borderColor: theme.palette.primary.main,
      borderRadius: 10,

      "& .MuiDropzoneArea-textContainer": {
        display: "flex",
        flexDirection: "column-reverse",
        alignItems: "center",
        justifyContent: "space-around",

        "& .MuiDropzoneArea-icon": {
          color: "#00285580",
          transform: "scale(1.4)",
        },

        "& .documentDropperTextClass": {
          fontSize: "1rem",
          color: "rgba(0, 0, 0, 0.8)",
          fontWeight: "600",
          color: "#00285580",
        },
      },
    },
  },
  ErrordocumentDropperWrapper2: {
    marginTop: "1.5rem",
    marginBottom: "1.5rem",

    "& .documentDropperClass": {
      width: "20rem",
      minHeight: "100px",
      padding: "1.5rem 3rem 0rem",
      border: "2.5px solid",
      borderColor: "#E22626",
      borderRadius: 10,

      "& .MuiDropzoneArea-textContainer": {
        display: "flex",
        flexDirection: "column-reverse",
        alignItems: "center",
        justifyContent: "space-around",

        "& .MuiDropzoneArea-icon": {
          color: "#00285580",
          transform: "scale(1.4)",
        },

        "& .documentDropperTextClass": {
          fontSize: "1rem",
          color: "rgba(0, 0, 0, 0.8)",
          fontWeight: "600",
          color: "#00285580",
        },
      },
    },
  },
  droppedDocuments: {
    marginTop: "2rem",
    marginBottom: "1rem",
    border: "2px solid",
    borderColor: theme.palette.primary.main,
    borderRadius: 15,
    padding: "1.5rem 1rem 1.5rem .8rem",
  },
  documentEditorWrapper: {
    marginTop: "1.5rem",
    marginBottom: "2rem",
    "& .rdw-editor-main": {
      background: "#FFFFFF",
      padding: "0 10px",
      maxHeight: "20rem",
      minHeight: "20rem",

      "& .public-DraftStyleDefault-ltr": {
        margin: "10px 0",
      },
    },
  },
  createDocumentBtn: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  customTable: {
    "& thead": {
      backgroundColor: theme.palette.primary.main,
      border: "1px solid #E2E2E3",

      "& tr th": {
        color: "#FFFFFF",
        padding: "8px 10px",

        "& span": {
          color: "#FFFFFF",
        },
      },
    },
  },
  actionIcon2: {
    padding: 8,
    margin: "0 10px",
  },
  footerWrap: {
    marginTop: "8rem",
  },
  footer: {
    position: "fixed",
    bottom: 0,
    left: 0,
    zIndex: 999,
    textAlign: "right",
    width: "100%",
    padding: 20,
    backgroundColor: "#f2f2f2",
  },
  actionIcon: {
    padding: 8,
    margin: "0 10px",
  },

  // Teams
  serviceLine1: {
    marginBottom: 15,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    border: "1px solid",
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },

  inputTypeColorElement: {
    width: "23px",
    height: "21px",
    border: "none",
    borderRadius: "50%",
    marginLeft: 8,
    marginTop: "13px",

    "&::-webkit-color-swatch-wrapper ": {
      padding: "0",
    },
    "&::-webkit-color-swatch": {
      border: "1px",
      borderRadius: "50%",
      // backgroundColor:"red!important",
    },
  },
  addStatusColorButton: {
    color: "#fff",
    fontSize: "15px",
    width: "120px",
    // textTransform: "capitalize",
    backgroundColor: "#002855",
    height: "32px",
    marginTop: 12,
    font: "normal normal 600 14px/19px Nunito",
  },

  completedStatus: {
    "& span": {
      height: 16,
      width: 16,
      borderRadius: "50%",
      display: "block",
      marginLeft: 12,
      backgroundColor: "#21BA45",
    },
    "& p": {
      fontSize: 13,
      marginLeft: 20,
      color: "#21BA45",
    },
  },

  innerCircleColorPicker: {
    margin: "4px",
    height: "20px",
    width: "20px",
    borderRadius: "50%",
    // border: "5px solid black",
  },
  active: {
    borderColor: "blue",
    border: "2px solid",
    borderRadius: "50%",
    marginLeft: 5,
    marginRight: 5,
    // border: "2px solid",
    // marginLeft: "3px",
    // borderColor: "blue",
    // height: "26px",
    // width: "26px",
    // marginTop: "12px",
    // marginRight: "3px",
    // borderRadius: "50%",
    // position:"relative"
  },
  inactive: {
    borderColor: "transparent",
  },

  colorPicker: {
    marginLeft: 12,
    "& .MuiInput-underline::before": {
      borderBottom: "unset",
    },
    "& .MuiInput-underline::after": {
      borderBottom: "unset",
    },
  },
  customColorDot: {
    height: "18px",
    width: "18px",
    borderRadius: "50%",
    display: "inline-block",
    border: "1px solid #bbb",
    marginTop: "16px",
    marginLeft: "10px",
    // position:"absolute"
  },
  MuiDivider: {
    width: "2px",
    height: "24px",
    color: "#535353",
    marginLeft: 12,
  },
  MuiCenterDivider: {
    width: "2px",
    height: "300px",
    color: "#535353",
  },
  // status profile
  predefinedColorDots: {
    height: "15px",
    width: "15px",
    borderRadius: "50%",
    display: " inline-block",
    border: "1px solid #bbb",
    marginRight: "5px",
  },
  newStatusAddBox: {
    border: "1px solid #D6D6D6",
    width: "80%",
    display: "flex",
    alignItems: "center",
    opacity: 1,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    "& span": {
      font: "normal normal 600 16px/22px Nunito",
      fontSize: 16,
      marginTop: "2px",
      // marginLeft:"5px"
    },
  },
  markAsDefault: {
    opacity: 0,
    "&:hover": {
      opacity: 1,
    },
  },

  statusEditBox: {
    border: "1px solid #D6D6D6",
    width: 442,
    height: 37,
    backgroundColor: "#FFFFFF",
    marginTop: 12,
    marginLeft: 35,
    "& span": {
      height: 16,
      width: 16,
      borderRadius: "50%",
      display: "block",
      marginLeft: 12,
      backgroundColor: "#FBBD08",
    },
    "& p": {
      fontSize: 13,
      marginLeft: 20,
      color: "#FBBD08",
    },
  },

  converted: {
    ".active, .btn:hover": {
      backgroundColor: "#666",
      color: "white",
      border: "1px solid",
      borderRadius: "15%",
      // Access Maintenance
    },
  },
  resouceTypes: {
    background: "#F2F4F6 0% 0% no-repeat padding-box",
    boxShadow: "0px 2px 3px #B7B7B714",
    padding: "10px 20px",
    marginTop: 5,

    "& .MuiFormControlLabel-label": {
      font: "normal normal normal 14px/16px Nunito",
      color: "#4D4D4D",
    },
  },
  permission: {
    background: "#F2F4F6 0% 0% no-repeat padding-box",
    boxShadow: "0px 2px 3px #B7B7B714",
    borderRadius: 4,
    padding: "10px 15px",
    marginBottom: 10,

    "& .permissionHead .MuiFormControlLabel-label": {
      font: "normal normal bold 14px/16px Nunito",
      color: "#4D4D4D",
    },

    "& .permissionHead h6": {
      font: "normal normal normal 12px/24px Nunito",
      textDecoration: "underline",
      color: "#126CFD",
      cursor: "pointer",
      margin: 0,
    },

    "& .MuiFormControlLabel-label": {
      font: "normal normal normal 14px/16px Nunito",
      color: "#4D4D4D",
    },
  },
  notFound: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DEDEDF",
    borderRadius: 4,
    padding: 30,
    textAlign: "center",
    marginTop: 5,

    "& h3": {
      font: "normal normal normal 16px/22px Nunito",
      color: "#B5B5B5",
    },
  },
  modalHeader: {
    backgroundColor: theme.palette.background.default,
    color: "#000000",
    width: "100%",
    margin: 0,
    padding: "10px 24px",

    "& h2": {
      font: "normal normal 600 16px/22px Nunito",
    },
  },
  modalContent: {
    padding: "15px 24px 15px",
  },
  modalLabel: {
    font: "normal normal 600 14px/16px Nunito",
    color: "#333333DE",
    marginTop: 10,
  },
  permissionModalField: {
    background: "#F9FAFD 0% 0% no-repeat padding-box",
    borderRadius: 10,
    marginBottom: 10,
    padding: 10,

    "& .MuiFormControlLabel-label": {
      font: "normal normal normal 14px/16px Nunito",
      color: "#4D4D4D",
    },
  },
  submitbuttonwrapper: {
    marginTop: "6rem",
    marginBottom: "1rem",
    display: "flex",
    justifyContent: "flex-end",
  },
  iconUploadwrapper: {
    height: 150,
    width: "100%",
    backgroundColor: "#fff",
    font: "normal normal normal 12px/16px Nunito",
    border: "1px dotted #DEDEDF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#B5B5B5",
  },
  Iconswrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  UploadIcon: {
    height: 40,
    width: 40,
    backgroundColor: "#21A8FB",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  linkIcon: {
    height: 40,
    width: 40,
    backgroundColor: "#F2BB62",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  additionalinfo: {
    color: "#B5B5B5",
    font: "normal normal normal 10px/14px Nunito",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  uploadTableLabel: {
    font: "normal normal normal 10px/14px Nunito",
  },
  documentsProjectDetailsButtons: {
    width: 120,
    height: 32,
    font: "normal normal 600 14px/16px Nunito",
    backgroundColor: "#002855",
    color: "#FFFFFF",
    textTransform: "none",
  },
  border_line: {
    border: "1px solid #002855",
    opacity: 0.4,
    width: "100%",
    marginTop: 10,
  },

  // Application gov
  doc_container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "25vw",
  },
  group1: {
    display: "flex",
    flexDirection: "column",
  },
  label: {
    font: "normal normal normal 12px/16px Nunito",
    color: "#000000",
  },
  value: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: 5,
    width: 240,
    height: 30,
    marginTop: 5,
  },
  iconwrapper: {
    width: 100,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  icon: {
    width: 50,
    height: 50,
    background: "#f5f5f5",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
  },
  //datastandard create field styiling
  fieldMenu: {
    color: "#A8A8A8",
    padding: "2px",
  },
  fielddatastand: {
    marginLeft: "30px",
  },
  datastandard: {
    border: "solid 5px",
  },
  dropdownContent: {
    width: "100%",
    color: "#00000089",
    font: "normal normal 600 14px/19px Nunito",
    background: "#FBFBFB 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    minHeight: "181.6px",
    opacity: "1",
    height: "90%",
    marginTop: "5px",
    resize: "none",
    padding: "10px 15px",
  },
  fieldclearAll: {
    font: "normal normal 600 14px/19px Nunito",
    color: "#0C5BB4",
    opacity: "0.5",
  },
  fieldclearAllVisible: {
    font: "normal normal 600 14px/19px Nunito",
    color: "#0C5BB4",
    opacity: 1,
    cursor: "pointer",
  },
  fieldcancelbtn: {
    width: "99px",
    height: "40px",
    font: "normal normal bold 14px/19px Nunito",
    letterspacing: "0px",
    color: "#585858",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #888888",
    borderradius: "5px",
    opacity: "1",
    marginLeft: "10px",
    textTransform: "capitalize",
  },
  fieldcreataddbtn: {
    border: "1px solid 002855",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    font: "normal normal bold 14px/19px Nunito",
    border: "1px solid #002855",
    borderradius: "5px",
    opacity: "1",
    marginLeft: "10px",
    textTransform: "capitalize",
    width: "208px",
    height: "40px",
  },
  fieldcreatebtn: {
    margin: "20px 10px 20px 10px",
    textTransform: "capitalize",
    width: "98px",
    height: "40px",
  },
  fieldgroupradiowrapper: {
    width: "100%",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DFDFDF",
    borderRadius: "5px",
    height: "35px",
    padding: "0 15px",
    marginTop: 5,
    display: "flex",

    "& .MuiFormControlLabel-root": {
      marginTop: "-5px",
      opacity: "0.5",
    },

    "& .MuiFormGroup-root span": {
      font: "normal normal 600 14px/19px Nunito",
    },
  },

  toggleRadioBtn: {
    "&.toggleActive": {
      opacity: "1",
    },
  },

  fieldgrouppaper: {
    padding: "10px",
    backgroundColor: "#002855",
    textAlign: "center",
    font: "normal normal bold 14px/18px Nunito",
    letterspacing: "0px",
    color: "#FFFFFF",
    opacity: "1",
    width: "155px",
    height: "42px",
    border: "1px solid #DEDFDF",
    textTransform: "capitalize",
  },
  fieldselect: {
    display: "flex",
  },
  //edit field
  editDetails: {
    marginTop: "20px",
    display: "flex",
    height: "40px",
    background: "#EBEBEB 0% 0% no-repeat padding-box",

    "& .MuiGrid-item": {
      marginTop: "10px",
      textAlign: "center",
    },

    "& .MuiGrid-item label": {
      font: "normal normal 600 15px/20px Nunito",
      letterspacing: "0px",
      color: "#000000",
      opacity: "0.5",
    },

    "& .MuiGrid-item span": {
      font: "normal normal 600 15px/20px Nunito",
      letterspacing: "0px",
      color: "#000000",
      opacity: "1",
    },
  },
  //edit field group footer

  fieldgroupfooter: {
    textAlign: "end",
  },

  creatfieldselectedlist: {
    display: "flex",
    marginTop: "15px",
    marginBottom: "15px",
    textalign: "left",
    font: "normal normal bold 14px/19px Nunito",
    letterspacing: "0px",
    color: "#222725",
    opacity: "0.8",
  },

  // Modal and DataStandards Table Styling
  modalTableContainer: {
    height: "300px",
    marginLeft: "12px",
    border: "1px solid #DEDFDF",
  },

  modalTable: {
    "& thead": {
      background: "#EBEBEB 0% 0% no-repeat padding-box",
      border: "10px solid #DFDFDF",
      borderRadius: "2px",
      opacity: "1",

      "& th": {
        textAlign: "left",
        font: "normal normal 600 14px/19px Nunito Sans",
        letterSpacing: "0px",
        textTransform: "capitalize",
        color: "#000000",
        opacity: "1",
      },

      "& tr": {
        textAlign: "left",
        font: "normal normal 600 14px/19px Nunito Sans",
        letterSpacing: "0px",
        color: "#000000",
      },
    },
    "& tbody": {
      "& tr td": {
        font: "normal normal normal 14px/19px Nunito Sans;",
        letterSpacing: "0px",
        color: "#000000",
        opacity: "0.8",
        background: "#FFFFFF 0% 0% no-repeat padding-box",
      },
    },
  },
  btnIcon: {
    width: "18px",
    height: "18px",
    marginTop: -2,
  },
  customPagination: {
    width: "fit-content",
    marginLeft: "auto",
    marginTop: "1rem",
    marginBottom: "1rem",
    "& .MuiPaginationItem-page.Mui-selected": {
      border: "0.5px solid #707070",
      margin: "0 10px",
      height: 30,
      width: 30,
      padding: "10px 13px",
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      boxShadow: "0px 3px 10px #5757571a",
      lineHeight: 1,
      borderRadius: 5,
      opacity: 1,
    },
    "& .MuiPaginationItem-page": {
      font: "normal normal normal 14px/19px Nunito Sans",
      letterSpacing: 0,
      color: "#000000",
      opacity: 0.6,
    },
  },
  addNewValues: {
    font: "normal normal 600 14px/19px Nunito",
    marginLeft: "1.5rem",
    color: theme.palette.primary.main,
  },
  childDocumentSearchBox: {
    width: "100%",
    height: 35,
    paddingLeft: 10,
    border: "1px solid #DEDEDF",
    borderRadius: 5,
    margin: "0px 20px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",

    "&:hover::before": {
      borderBottom: "unset",
      transition: "unset",
    },

    "&::before": {
      borderBottom: "unset",
      transition: "unset",
    },

    "& input": {
      padding: "unset",
    },
  },
  avatar: {
    marginRight: 10,
  },
  testModal: {
    "& .ril__toolbar": {
      // top:'5rem',
      top: "92vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "unset",

      "& button": {
        backgroundColor: "rgba(0, 0, 0, 1)",
      },
    },
  },
  btnNormal: {
    backgroundColor: theme.palette.primary.main,
    color: "#FFFFFF",
    width: "7.4rem",
    marginLeft: "32px",
    height: "2.35rem",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
}));
