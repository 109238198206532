import * as actions from "./actionTypes";
import APIS from "api/ValueMapAPI/index";

export default {
  // actionfetchAllValueMapData,
  // fetchAllValueMapData,
  addValueMap,
  editValueMap,
};

// function fetchAllValueMapData() {
//   return async (dispatch) => {
//     dispatch({
//       type: actions.FETCH_VALUEMAP_DATA,
//       loading: true,
//     });
//     try {
//       let data = await APIS.fetchValueMap();
//       dispatch({
//         type: actions.FETCH_VALUEMAP_DATA_SUCCESS,
//         loading: false,
//         totalCount: data._totalcount,
//         data: data.data || [],
//       });
//     } catch (err) {
//       console.log(err);
//       dispatch({
//         type: actions.FETCH_VALUEMAP_DATA_FAILED,
//         loading: false,
//         error: err,
//       });
//     }
//   };
// }

function addValueMap(payload) {
  return async () => {
    try {
      let res = await APIS.addValueMap(payload);
      return res.data;
    } catch (err) {
      return err.response;
    }
  };
}

function editValueMap(id, payload) {
  return async (dispatch) => {
    dispatch({
      type: actions.EDIT_VALUEMAP_DATA,
      loading: true,
    });
    try {
      let editdata = await APIS.editValueMap(id, payload);
      dispatch({
        type: actions.EDIT_VALUEMAP_DATA_SUCCESS,
        loading: false,
        editdata,
      });
    } catch (err) {
      dispatch({
        type: actions.EDIT_VALUEMAP_DATA_FAILURE,
        loading: false,
        error: err,
      });
    }
  };
}
