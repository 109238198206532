import { React, useEffect, useState } from "react";
import withSection from "components/Section/Section";
import { DropzoneArea } from "material-ui-dropzone";
import Upload from "./../../../src/assets/images/Icon metro-folder-upload.svg";
import {
  Grid,
  TextField,
  Avatar,
  Tooltip,
  IconButton,
} from "@material-ui/core/";
import { RiDeleteBin5Line } from "react-icons/ri";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app
const CustomDropperIcon = () => <img src={Upload} width="100" height="50" />;

function HelpDeskImage(props) {
  const [viewerIsOpen, setViewerIsOpen] = useState({
    isOpen: false,
    img: null,
    isOpenNewScreenshot: false,
  });
  let [photoIndex, setphotoIndex] = useState(0);
  let [file, setfile] = useState([]);
  let [filenew, setfilenew] = useState([props.images]);
  const {
    classes,
    // files,
    // HelpdeskData,
    handleImg,
    // handleImageChange,
    // newFiles,
    // img,
    // event,
    isDisabled,
    random,
    images,
    deleteFunc,
    handleDeleteImage,
    HelpdeskData,
    ExistImages,
    handleDeleteNewScreenShot,
  } = props;

  // var binaryData = [];
  // binaryData.push(images);
  // window.URL.createObjectURL(new Blob(binaryData, { type: "application/zip" }));

  console.log(images, "========images");
  // console.log(images.length, images[0], "========images");
  // console.log(isDisabled, "000000000000000");
  // const handleImageChange = (event, newFiles) => {
  //   const newDoc = file.map((e) => {
  //     if (e.name === newFiles.name) {
  //       const renameFile = new File([e], event.target.value);
  //       return renameFile;
  //     } else {
  //       return e;
  //     }
  //   });
  //   setfile(newDoc);
  // };

  // const handleFileRead = async (event) => {
  //   const file = event.target.files[0];
  //   const base64 = await convertBase64(file);
  //   console.log(base64);false
  //   return new Promise((resolve, reject) => {
  //     const fileReader = new FileReader();
  //     fileReader.readAsDataURL(file);
  //     fileReader.onload = () => {
  //       resolve(fileReader.result);
  //     };
  //     fileReader.onerror = (error) => {
  //       reject(error);
  //     };
  //   });
  // };

  // const onDropHandler = (files) => {
  //   files.map((file) => {
  //     const reader = new FileReader();
  //     reader.onload = (event) => {
  //       //store result into your state array.
  //       // this.setState((prevState) => {
  //       //   const updatedImages = [...prevState.images, event.target.result];
  //       //   return {
  //       //     images: updatedImages,
  //       //   };
  //       // });
  //       // console.log(event.target.result, 34567876545678);
  //     };
  //     reader.readAsDataURL(file);
  //   });
  // };

  // const handleDeleteImage = (imageToDelete, index) => () => {
  //   let modifiedFiles = filenew.filter((e, id) => index !== id);
  //   setfilenew(modifiedFiles);
  //   setRandom(Math.random());
  //   deleteFunc(index);
  //   console.log(imageToDelete, index);
  // };
  console.log(HelpdeskData.image, "dhfhgdferyrw4365786978654");
  return (
    <div
      className={classes.documentDropperWrapper}
      style={isDisabled ? { pointerEvents: "none", opacity: "0.7" } : null}
    >
      <Grid container>
        <Grid item md={4}>
          <label htmlFor="projectDescription" className={classes.fieldHeading}>
            Upload Images
          </label>
          <div>
            <DropzoneArea
              key={random}
              Icon={CustomDropperIcon}
              showPreviews={false}
              filesLimit={10}
              dropzoneText="Drag and drop or click to browse here"
              dropzoneClass="documentDropperClass"
              dropzoneParagraphClass="documentDropperTextClass"
              showAlerts={["error"]}
              showPreviewsInDropzone={false}
              acceptedFiles={[".jpeg,.png,.jpg"]}
              onChange={(files) => props.handleImg(files)}
              // onChange={(files) => onDropHandler(fi  les)}

              // onChange={(files) => uploadScreenshot(files)}
              // onChange={(e) => handleFileRead(e)}
            />
          </div>
          <span className={classes.warningTxt}>
            Upload jpg, jpeg or png of size less than 2 MB
          </span>
        </Grid>
        <Grid
          item
          style={{
            right: 10,
          }}
          lg
          md
          sm={12}
        >
          <Grid
            container
            justify="flex-end"
            spacing={3}
            style={{ width: "100%" }}
          >
            <Grid item md={8}>
              {HelpdeskData.image.length > 0 &&
                HelpdeskData.image.map((image, index) => {
                  console.log(image, "images bse64");
                  return (
                    <Grid container key={index} spacing={6}>
                      <Grid item sm={2} xs={2}>
                        <img
                          onClick={() => {
                            setViewerIsOpen({
                              ...viewerIsOpen,
                              isOpenNewScreenshot: true,
                              img: image,
                            });
                            setphotoIndex(index);
                          }}
                          className={classes.imgStyle}
                          alt={`${image.name}`}
                          src={URL.createObjectURL(image)}
                        />
                      </Grid>
                      <Grid item sm={9} xs={9}>
                        <label htmlFor="" className={classes.fieldHeading}>
                          File Name
                        </label>
                        <TextField
                          size="small"
                          variant="outlined"
                          fullWidth
                          name="name"
                          value={image.name}
                          autoComplete="off"
                        />
                      </Grid>
                      <Grid item sm={1} xs={1} className={classes.delBtnStyle}>
                        <Tooltip title="Delete" aria-label="delete">
                          <IconButton color="primary">
                            <RiDeleteBin5Line
                              onClick={() => {
                                handleDeleteNewScreenShot(image, index);
                              }}
                              fontSize="22px"
                            />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                      &nbsp;
                    </Grid>
                  );
                })}
              {/* &nbsp; */}
            </Grid>

            <Grid item md={8}>
              {ExistImages.length > 0 &&
                ExistImages.map((img, index) => {
                  console.log(img, "images bse64");
                  return (
                    <Grid container key={index} spacing={6}>
                      <Grid item sm={2} xs={2}>
                        <img
                          onClick={() => {
                            setViewerIsOpen({
                              ...viewerIsOpen,
                              isOpen: true,
                              img: img.base64,
                            });
                            setphotoIndex(index);
                          }}
                          className={classes.imgStyle}
                          alt={`${img.filename}`}
                          src={`${img.base64}`}
                        />
                      </Grid>
                      <Grid item sm={9} xs={9}>
                        <label htmlFor="" className={classes.fieldHeading}>
                          File Name
                        </label>
                        <TextField
                          size="small"
                          variant="outlined"
                          fullWidth
                          name="name"
                          value={img.filename}
                          autoComplete="off"
                        />
                      </Grid>
                      <Grid item sm={1} xs={1} className={classes.delBtnStyle}>
                        <Tooltip title="Delete" aria-label="delete">
                          <IconButton color="primary">
                            <RiDeleteBin5Line
                              onClick={() => {
                                handleDeleteImage(img, index);
                              }}
                              fontSize="22px"
                            />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                      &nbsp;
                    </Grid>
                  );
                })}
              {/* &nbsp; */}
            </Grid>
          </Grid>
        </Grid>
        {/* )}  */}
      </Grid>
      {viewerIsOpen.isOpen && (
        <Lightbox
          wrapperClassName={classes.testModal}
          imagePadding={75}
          enableZoom={true}
          onCloseRequest={() =>
            setViewerIsOpen({ ...viewerIsOpen, img: null, isOpen: false })
          }
          mainSrc={viewerIsOpen.img}
        />
      )}
      {viewerIsOpen.isOpenNewScreenshot && (
        <Lightbox
          wrapperClassName={classes.testModal}
          imagePadding={75}
          enableZoom={true}
          onCloseRequest={() =>
            setViewerIsOpen({
              ...viewerIsOpen,
              isOpen: false,
              isOpenNewScreenshot: false,
              img: null,
            })
          }
          mainSrc={URL.createObjectURL(viewerIsOpen.img)}
        />
      )}
    </div>
  );
}
export default withSection(HelpDeskImage, "Helpdesk Image Section");
