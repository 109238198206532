import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    height: 40,
    width: "32rem",
    // background: "#002855 0% 0% no-repeat padding-box",
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "center",
    fontSize: 14,
    fontFamily: "Nunito",
    fontWeight: "bold",
    color: "white",
    cursor: "pointer",
    backgroundColor: theme.palette.primary.main,
  },
}));

export default function CustomButton(props) {
  const styles = useStyles();
  const { type, label, onClick, customStyles } = props;
  if (type === "long") {
    return (
      <button
        className={styles.button}
        style={customStyles ? { ...customStyles } : {}}
        name="Next"
        onClick={onClick}
      >
        {label}
      </button>
    );
  }
}

export const CustomButtonSubmit = (props) => {
  const styles = useStyles();
  const { type, label, onClick, customStyles } = props;
  if (type === "submit") {
    return (
      <button
        type={type}
        className={styles.button}
        style={customStyles ? { ...customStyles } : {}}
        name="Next"
        onClick={onClick}
      >
        {label}
      </button>
    );
  }
};
