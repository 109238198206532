/**
 *  parent does not have link. go for child.
 */

function extractschema(api_nodes, cdu_nodes, nodes, edges, type) {
  console.log(JSON.stringify({ api_nodes, cdu_nodes, nodes, edges }));
  if (type == "cdutoapi") {
    let valueMapping = generateValueMappings(api_nodes, nodes, edges, "api");
    let cdu = nodes.find((f) => f.data.panel == "cdu");
    let reqValueMap = cdu?.data?.input?.valueMapping || {};
    return {
      api_nodes,
      cdu_nodes,
      nodes,
      edges,
      valueMapping,
      reqValueMap,
    };
  } else {
    let valueMapping = generateValueMappings(api_nodes, nodes, edges, "cdu");
    let cdu = nodes.find((f) => f.data.panel == "cdu");
    let resValueMap = cdu?.data?.input?.valueMapping || {};
    return {
      api_nodes,
      cdu_nodes,
      nodes,
      edges,
      valueMapping,
      resValueMap,
    };
  }
}

function generateValueMappings(nodes, flowNodes, edges, targetPanel) {
  let sourcePanel = targetPanel == "cdu" ? "api" : "cdu";
  var connections = [];
  edges
    .filter((edge) => {
      return edge.target == targetPanel;
    })
    .map((edge) => {
      if (edge.source == sourcePanel) {
        let connection = {};
        connection.source = edge.sourceHandle
          .split("__")
          .slice(3)
          .join(".")
          .replaceAll("arrayElement", "#");
        connection.target = edge.targetHandle
          .split("__")
          .slice(3)
          .join(".")
          .replaceAll("arrayElement", "#");
        let cduNode = flowNodes.filter((f) => {
          return f.data.panel == "cdu";
        });
        connections.push(connection);
      } else {
        let node = flowNodes.find((nodes) => {
          return nodes.id == edge.source;
        });
        console.log(node);
        let nodeType = node.type;
        let connection = {};
        connection.target = edge.targetHandle
          .split("__")
          .slice(3)
          .join(".")
          .replaceAll("arrayElement", "#");
        switch (nodeType) {
          case "concat":
            connection.source = handleConcat(edge, edges, node);
            break;
          case "split":
            connection.source = handleSplit(edge, edges, node);
            break;
          case "join":
            connection.source = handleJoin(edge, edges, node);
            break;
          case "filter":
            connection.source = handleFilter(edge, edges, node);
            break;
          case "expression":
            connection.source = handleExpression(edge, edges, node);
            break;
          default:
        }
        let cduNode = flowNodes.filter((f) => {
          return f.data.panel == "cdu";
        });
        connections.push(connection);
      }
    });
  return connections;
}

function handleConcat(currentEdge, edges, concatNode) {
  let fnString = "";
  edges
    .filter((edge) => {
      return edge.target == currentEdge.source;
    })
    .sort((a, b) => {
      let aIndex = a.targetHandle.split("_")[1];
      let bIndex = b.targetHandle.split("-")[1];
      return parseInt(aIndex) - parseInt(bIndex);
    })
    .forEach((edge) => {
      fnString =
        fnString +
        "," +
        edge.sourceHandle
          .split("__")
          .slice(3)
          .join(".")
          .replaceAll("arrayElement", "#");
    });
  console.log(fnString);

  return `concat(${concatNode.data.input?.concatinateString || ""}${fnString})`;
}

function handleSplit(currentEdge, edges, node) {
  let filteredEdges = edges.filter((edge) => {
    return edge.target == currentEdge.source;
  });
  if (filteredEdges.length == 0) {
    return "";
  }
  // Rule there will be only one input for Split
  let filteredEdge = filteredEdges[0].sourceHandle
    .split("__")
    .slice(3)
    .join(".")
    .replaceAll("arrayElement", "#");
  let index = currentEdge.sourceHandle.split("_")[1];
  console.log(
    "xxxxxxx",
    `split(${node.data.input?.splitString || ""},${filteredEdge},${index})`
  );
  return `split(${
    node.data.input?.splitString || ""
  },${filteredEdge},${index})`;
}

function handleJoin(currentEdge, edges, node) {
  let filteredEdges = edges.filter((edge) => {
    return edge.target == currentEdge.source;
  });
  if (filteredEdges.length == 0) {
    return "";
  }
  // Rule there will be only one input for Join
  let filteredEdge = filteredEdges[0].sourceHandle
    .split("__")
    .slice(3)
    .join(".")
    .replace("arrayElement", "#");
  return `join(${node.data.input?.joinString || ""},${filteredEdge})`;
}

function handleFilter(currentEdge, edges, node) {
  // if (node.data.input?.filterOn == "") return "";
  // get filter source node..
  let sourceEdge =
    edges.filter((f) => f.target == currentEdge.source)[0] || null;
  if (!sourceEdge) return;
  let sourceHandle = `${sourceEdge.sourceHandle
    .split("__")
    .slice(3)
    .join("---")
    .replaceAll("arrayElement", "#")
    .replaceAll("---", ".")}`;

  if (node.data.input?.filterOn == undefined) {
    console.log("dhdfjfdjh", sourceHandle, 5);
    // filteron will be empty for array of strings.
    return `${sourceHandle}.#(${node.data.input.filterOp}${node.data.input.filterValue})#`;
  }
  let b = `${sourceHandle}_${node.data.input?.filterOn}`.split("_#_");
  let extraEdges = sourceHandle.split("_#_").slice(1);
  let numberofbrackets = b.length;
  let edgeKey = [
    ...extraEdges,
    ...currentEdge.sourceHandle.slice(7).split("#_").slice(1),
  ].join(".#.");
  console.log("dhdfjfdjh", 1, b);
  let str = b.length === 1 ? `${b[0]}#.(` : b.join(".#(");

  // return `${b.join(".#(")}${node.data.input.filterOp}${
  //   node.data.input.filterValue
  // }${Array(numberofbrackets - 1)
  //   .fill(")")
  //   .join("")}#.${edgeKey}`;

  return `${str}${node.data.input.filterOp}${
    node.data.input.filterValue
  }${Array(numberofbrackets > 1 ? numberofbrackets - 1 : 1)
    .fill(")")
    .join("")}#.${edgeKey}`;
}

function handleExpression(currentEdge, edges, node) {
  return `expr(${node.data.input?.expString})`;
}

function handleValueMap(targetHandle, cduNode, edge) {
  return cduNode?.data?.data?.input?.valueMap?.targetHandle || "";
}

export default extractschema;
