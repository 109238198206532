import * as actions from "./actionTypes";
import userManagementAPIS from "api/UserManagementAPIs/index";
import APIS_ROLE from "api/RoleManagementAPIs/index";

function actionfetchAllUserManagement(pageno, size, filters, sort) {
  console.log({ actions });
  return async (dispatch) => {
    dispatch({
      type: actions.FETCHING_USER_MANAGEMENT,
      loading: true,
    });
    try {
      let data = await userManagementAPIS.fetchAllUserManagementWithParams(
        pageno,
        size,
        filters,
        sort
      );
      dispatch({
        type: actions.FETCHING_USER_MANAGEMENT_SUCCESS,
        loading: false,
        totalCount: data._totalcount,
        data: data.data || [],
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: actions.FETCHING_USER_MANAGEMENT_FAILURE,
        loading: false,
        error: err,
      });
    }
  };
}

function addUserManagement(payload) {
  return async (dispatch) => {
    dispatch({
      type: actions.ADD_USER_MANAGEMENT,
      loading: true,
    });
    try {
      let adddata = await userManagementAPIS.addUserManagement(payload);
      dispatch({
        type: actions.ADD_USER_MANAGEMENT_SUCCESS,
        loading: false,
        adddata,
      });
    } catch (err) {
      dispatch({
        type: actions.ADD_USER_MANAGEMENT_FAILURE,
        loading: false,
        error: err,
      });
    }
  };
}

function editUserManagement(userManagementId, payload) {
  return async (dispatch) => {
    dispatch({
      type: actions.EDIT_USER_MANAGEMENT,
      loading: true,
    });
    try {
      let editdata = await userManagementAPIS.editUserManagement(
        userManagementId,
        payload
      );
      dispatch({
        type: actions.EDIT_USER_MANAGEMENT_SUCCESS,
        loading: false,
        editdata,
      });
    } catch (err) {
      dispatch({
        type: actions.EDIT_USER_MANAGEMENT_FAILURE,
        loading: false,
        error: err,
      });
    }
  };
}

function deleteUserManagement(userManagementId) {
  return async (dispatch) => {
    dispatch({
      type: actions.DELETE_USER_MANAGEMENT,
      loading: true,
    });
    try {
      let deldata = await userManagementAPIS.deleteUserManagement(
        userManagementId
      );
      dispatch({
        type: actions.DELETE_USER_MANAGEMENT_SUCCESS,
        loading: false,
        deldata,
      });
      return deldata;
    } catch (err) {
      dispatch({
        type: actions.DELETE_USER_MANAGEMENT_FAILURE,
        loading: false,
        error: err,
      });
      return err;
    }
  };
}

function fetchAllUserManagementRoles(filter) {
  console.log({ actions });
  return async (dispatch) => {
    dispatch({
      type: actions.FETCHING_USER_MANAGEMENT_ROLES,
      loading: true,
    });
    try {
      let res = await APIS_ROLE.fetchAllUserManagementrolesWithParams(
        undefined,
        undefined,
        filter,
        undefined
      );
      dispatch({
        type: actions.FETCHING_USER_MANAGEMENT_ROLES_SUCCESS,
        loading: false,
        rolesData: res.data || [],
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: actions.FETCHING_USER_MANAGEMENT_ROLES_FAILURE,
        loading: false,
        error: err,
      });
    }
  };
}

function blockUserManagement(userManagementId, type) {
  return async (dispatch) => {
    // dispatch({
    //   type: actions.BLOCK_USER_MANAGEMENT,
    //   loading: true,
    // });
    try {
      let blockdata = await userManagementAPIS.blockUserManagement(
        userManagementId,
        type
      );
      const { _msg, _status } = blockdata.data;
      // console.log(_msg, "kjhgf");
      return {
        success: true,
        _msg,
      };
      // dispatch({
      //   type: actions.BLOCK_USER_MANAGEMENT_SUCCESS,
      //   loading: false,
      //   blockdata,
      // });
    } catch (err) {
      const { _msg } = err?.response?.data || { _msg: "Network issue!" };
      return {
        success: false,
        _msg,
      };

      // dispatch({
      //   type: actions.BLOCK_USER_MANAGEMENT_FAILURE,
      //   loading: false,
      //   error: err,
      // });
    }
  };
}

export const update_User_Role = (type, userRoleId, payload) => async () => {
  let roles = payload;
  // .filter((item, id) => {
  //   return item !== undefined && item !== null;
  // })
  // .map((val, i) => {
  //   return val._id;
  // });
  console.log(type, payload, roles, "updated users");
  try {
    let res;
    if (type) {
      res = await userManagementAPIS.editUserRole(type, userRoleId, {
        roleId: payload,
      });
    } else {
      res = await userManagementAPIS.deleteUserRole(userRoleId, {
        roleId: payload,
      });
    }
    return res;
  } catch (err) {
    return err;
  }
};

export const loadingDispatch = (bool) => async (dispatch) => {
  dispatch({
    type: actions.LOAD_LOADING,
    payload: bool,
  });
};
export const projectsAssigned = async (ID) => {
  try {
    const res = await userManagementAPIS.projectsAssigned(ID);
    const { _msg, _status, data } = res.data;
    console.log(res.data, res._msg, "oiuythjk");
    return {
      _status,
      data,
      _msg,
    };
  } catch (err) {
    console.log(err.response);
    return {
      _msg: err?.response?.data?._msg,
      _status: err?.response?.data?._status,
      data: err?.response?.data?.data,
    };
  }
};

const updateFilterData = ({ filter, filterArray }) => ({
  type: actions.UPDATE_USERS_FILTER_DATA,
  payload: { filter, filterArray },
});

const updateSort = ({ sorting, sort }) => ({
  type: actions.UPDATE_USERS_SORT,
  payload: { sorting, sort },
});
const storeSearchedValue = (value) => ({
  type: actions.USER_STORE_SEARCHED_VALUE,
  payload: value,
});

const updateHiddenTableCol = (hidden) => ({
  type: actions.UPDATE_HIDDEN_COL,
  payload:hidden
});

export default {
  actionfetchAllUserManagement,
  addUserManagement,
  editUserManagement,
  deleteUserManagement,
  fetchAllUserManagementRoles,
  blockUserManagement,
  // deleteUserRole,
  // editUserRole,
  updateFilterData,
  updateSort,
  storeSearchedValue,
  updateHiddenTableCol
};
