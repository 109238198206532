/**
 * fetch all the context
 */

import getURL from "../config";
import API_helpers from "../index";

var APIs = {
  fetchContextByAppIdAndApId,
  addContext,
  fetchContextWithFilter,
};

async function fetchContextByAppIdAndApId(appId, apiId) {
  let url = getURL("FETCH_CTX_BY_APP_AND_APIID")(appId, apiId);
  url = `${url}?contexttype=Receiver`;
  try {
    let res = await API_helpers.GET(url, {
      headers: { "Content-Type": "application/json" },
    });
    return (res.data || {}).data || null;
  } catch (err) {
    throw err;
  }
}

async function addContext(payload) {
  let url = getURL("ADD_CONTEXT");
  try {
    let res = await API_helpers.POST(url, payload, {
      headers: { "Content-Type": "application/json" },
    });
    return res;
  } catch (err) {
    throw err;
  }
}

async function fetchContextWithFilter(filter) {
  let url = getURL("GET_CONTEXTS");
  if (filter !== undefined) {
    url = `${url}?filters=${JSON.stringify(filter)}`;
  }
  try {
    let res = await API_helpers.GET(url, {
      headers: { "Content-Type": "application/json" },
    });
    console.log(res);
    return res.data;
  } catch (err) {
    throw err;
  }
}

export default APIs;
