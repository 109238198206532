import {
  Grid,
  Backdrop,
  CircularProgress,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  TextField,
  Button,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { ArrowDropDownRounded } from "@material-ui/icons";
// files
import CreateCustomerStyles from "../../../styles/CommonStyles/CreateAndDetailsScreenStyle";
import BreadCrumbs from "../../../components/Breadcrumbs/Breadcrumb";
// import { fetchCurrentORGDetails } from "../../../utils/organization/organizationUtils";
import { useSnackbar } from "notistack";
import { AppButton } from "../../../components/common/Button";
import moment from "moment";
import { connect } from "react-redux";
import { RiEditLine } from "react-icons/ri";
import {
  getOrganizationDetails,
  getOrganizationDetailsByID,
  add_current_organization_data,
} from "./redux/action";
import { useSelector, useDispatch } from "react-redux";
import { checkPermission } from "helpers/permissons";
// import { add_current_organization_data } from "../../../redux/actions/organizationAction";
import HelpdeskFAB from "../../../views/HelpdeskFAB/HelpdeskFab";
import Loader from "components/common/stuff/Loader";
import helpers from "../../../helpers/helpers";
const OrganizationDetailsScreen = (props) => {
  const styles = CreateCustomerStyles();
  const histroy = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [organizationData, setOrganizationData] = useState("");
  const [address, setAddress] = useState("");
  const organizationInfo = useSelector((state) => state.organizationReducer);
  const { organizationDetails, loading } = organizationInfo;
  const org_info = organizationDetails || {};
  const { userInfo } = props;

  useEffect(() => {
    const API = async () => {
      dispatch(getOrganizationDetails());
    };
    API();
  }, []);

  useEffect(() => {
    setOrganizationData({ ...org_info });
    setAddress(org_info.address || "");
  }, [org_info]);

  let myArr = [" "];
  if (typeof address !== "boolean") {
    myArr = address.split(",");
  }

  const handleEdit = () => {
    if (organizationData.status === "inactive") {
      enqueueSnackbar(
        "Cannot edit a Blocked Organization. Kindly unblock first.",
        {
          variant: "error",
        }
      );
    } else {
      histroy.push(`/app/edit-organization-details`);
    }
  };

  return (
    <main className={styles.main}>
      {loading && <Loader />}
      <Backdrop className={styles.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {!loading ? (
        <div>
          <div className={styles.topContent}>
            <BreadCrumbs
              links={[
                {
                  name: "Organization",
                  url: "/app/organization-details",
                },
                {
                  name: "Organization Details",
                },
              ]}
              // items={[
              //   { title: "Organizations", path: "/organization-deatils" },
              //   { title: `${organizationData.name ? organizationData.name : ""}` },
              // ]}
            />
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              style={{ marginBottom: "9px", marginTop: "8px" }}
            >
              <h2 className={styles.title}>{organizationData.name}</h2>

              {/* <AppButton
            color="primary"
            buttonName="Edit"
            variant="contained"
            startIcon={<RiEditLine />}
            // startIcon={<img src="/images/edit.svg" alt="" />}
            disabled={false}
            style={{ padding: "6px 15px" }}
            className="btnsmall"
            onClick={handleEdit}
          /> */}
              <Button
                style={{ padding: "6px 15px" }}
                variant="contained"
                size="small"
                startIcon={<RiEditLine />}
                disabled={!checkPermission("btn32")}
                onClick={checkPermission("btn32") && handleEdit}
              >
                Edit
              </Button>
            </Grid>
            {/* <div>
              <hr />
            </div> */}
            {/* <div className={styles.border_line} /> */}
            <hr style={{ backgroundColor: "gray", fontSize: "2px" }} />
          </div>

          <div className={styles.wrapper} style={{ marginTop: 95 }}>
            <Grid container spacing={3}>
              <Grid item xs={9}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4} md={4}>
                    <Typography
                      style={{ marginBottom: "2px", fontSize: "14px" }}
                      variant="body2"
                    >
                      Organization Name <span style={{ color: "red" }}>*</span>
                    </Typography>

                    {/* <label className={styles.fieldHeading}>
                  Organization Name <span className={styles.red}>*</span>
                </label>
                <input
                  name="name"
                  type="text"
                  className={styles.input2}
                  value={organizationData.name}
                  autoComplete="off"
                  disabled
                /> */}
                    <TextField
                      name="name"
                      type="text"
                      value={organizationData.name}
                      autoComplete="off"
                      disabled
                      size="small"
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <Typography
                      style={{ marginBottom: "2px", fontSize: "14px" }}
                      variant="body2"
                    >
                      Username <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      type="text"
                      fullWidth
                      value={organizationData.username}
                      size="small"
                      autoComplete="off"
                      variant="outlined"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <Typography
                      style={{ marginBottom: "2px", fontSize: "14px" }}
                      variant="body2"
                    >
                      Industry
                    </Typography>
                    <TextField
                      name="name"
                      type="text"
                      value={organizationData.industry}
                      size="small"
                      autoComplete="off"
                      variant="outlined"
                      fullWidth
                      disabled
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} md={4}>
                    <Typography
                      style={{ marginBottom: "2px", fontSize: "14px" }}
                      variant="body2"
                    >
                      Created By
                    </Typography>
                    <TextField
                      name="name"
                      type="text"
                      value={organizationData.createdby}
                      size="small"
                      autoComplete="off"
                      variant="outlined"
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <Typography
                      style={{ marginBottom: "2px", fontSize: "14px" }}
                      variant="body2"
                    >
                      Created On
                    </Typography>
                    <TextField
                      type="text"
                      value={
                        userInfo?.timeZone
                          ? // moment
                            //     .unix(organizationData.createdon)
                            //     .tz(userInfo.timeZone)
                            //     .format("DD-MM-YYYY, hh:mm A")
                            helpers.date_Func(organizationData.createdon)
                          : // .format(
                            //   userInfo.time === "h:mm"
                            //     ? `${userInfo.dateFormat}, ${userInfo.time} A`
                            //     : `${userInfo.dateFormat} ${userInfo.time}`
                            // )
                            // moment
                            //   .unix(organizationData.createdon)
                            //   .format("DD/MM/YYYY, hh:mm A")
                            helpers.date_Func(organizationData.createdon)
                      }
                      size="small"
                      autoComplete="off"
                      variant="outlined"
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <Typography
                      style={{ marginBottom: "2px", fontSize: "14px" }}
                      variant="body2"
                    >
                      Updated By
                    </Typography>
                    <TextField
                      type="text"
                      value={organizationData.updatedby}
                      size="small"
                      autoComplete="off"
                      variant="outlined"
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <Typography
                      style={{ marginBottom: "2px", fontSize: "14px" }}
                      variant="body2"
                    >
                      Updated On
                    </Typography>
                    {/* <label htmlFor="" className={styles.fieldHeading}>
                  Updated On
                </label> */}
                    <TextField
                      value={
                        userInfo?.timeZone
                          ? //  moment
                            //     .unix(organizationData.updatedon)
                            //     .tz(userInfo.timeZone)
                            //     .format("DD-MM-YYYY, hh:mm A")
                            helpers.date_Func(organizationData.updatedon)
                          : // .format(
                            //   userInfo.time === "h:mm"
                            //     ? `${userInfo.dateFormat}, ${userInfo.time} A`
                            //     : `${userInfo.dateFormat} ${userInfo.time}`
                            // )
                            // moment
                            //   .unix(organizationData.updatedon)
                            //   .format("DD/MM/YYYY, hh:mm A")
                            helpers.date_Func(organizationData.updatedon)
                      }
                      type="text"
                      size="small"
                      autoComplete="off"
                      variant="outlined"
                      fullWidth
                      disabled
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography
                      style={{ marginBottom: "2px", fontSize: "14px" }}
                      variant="body2"
                    >
                      Description
                    </Typography>
                    <TextField
                      name="description"
                      value={organizationData.description}
                      disabled
                      multiline
                      rows={7}
                      size="small"
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>

          <div className={styles.wrapper} style={{ marginTop: 15 }}>
            <Accordion className={styles.fieldPanel} defaultExpanded>
              <AccordionSummary
                expandIcon={
                  <ArrowDropDownRounded className={styles.accordianIcon} />
                }
              >
                <Typography className={styles.fieldHeading2}>
                  Contact details
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={3} style={{ marginTop: 15 }}>
                  <Grid item xs={9}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={4} md={4}>
                        <Typography
                          style={{ marginBottom: "2px", fontSize: "14px" }}
                          variant="body2"
                        >
                          Email <span style={{ color: "red" }}>*</span>
                        </Typography>
                        <TextField
                          value={organizationData.email}
                          type="text"
                          size="small"
                          autoComplete="off"
                          variant="outlined"
                          fullWidth
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={4}>
                        <Typography
                          style={{ marginBottom: "2px", fontSize: "14px" }}
                          variant="body2"
                        >
                          Phone Number <span style={{ color: "red" }}>*</span>
                        </Typography>
                        <TextField
                          value={organizationData.contactNumber}
                          type="text"
                          size="small"
                          autoComplete="off"
                          variant="outlined"
                          fullWidth
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={12} sm={3} md={3}>
                    <Typography
                      style={{ marginBottom: "2px", fontSize: "14px" }}
                      variant="body2"
                    >
                      Street
                    </Typography>
                    <TextField
                      value={myArr[0] ? myArr[0] : ""}
                      // value={address.street}
                      type="text"
                      size="small"
                      autoComplete="off"
                      variant="outlined"
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <Typography
                      style={{ marginBottom: "2px", fontSize: "14px" }}
                      variant="body2"
                    >
                      City
                    </Typography>
                    <TextField
                      value={myArr[1] ? myArr[1] : ""}
                      // value={address.city}
                      type="text"
                      size="small"
                      autoComplete="off"
                      variant="outlined"
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <Typography
                      style={{ marginBottom: "2px", fontSize: "14px" }}
                      variant="body2"
                    >
                      State
                    </Typography>
                    <TextField
                      value={myArr[2] ? myArr[2] : ""}
                      // value={address.state}
                      type="text"
                      size="small"
                      autoComplete="off"
                      variant="outlined"
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <Typography
                      style={{ marginBottom: "2px", fontSize: "14px" }}
                      variant="body2"
                    >
                      Country
                    </Typography>
                    <TextField
                      value={myArr[3] ? myArr[3] : ""}
                      // value={address.country}
                      type="text"
                      size="small"
                      autoComplete="off"
                      variant="outlined"
                      fullWidth
                      disabled
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </div>
          <div className={styles.wrapper} style={{ marginTop: 15 }}>
            <Accordion className={styles.fieldPanel} defaultExpanded>
              <AccordionSummary
                expandIcon={
                  <ArrowDropDownRounded className={styles.accordianIcon} />
                }
              >
                <Typography className={styles.fieldHeading2}>Logo</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid
                  container
                  spacing={3}
                  style={{ marginTop: 10, marginBottom: 70 }}
                >
                  <Grid item xs={12} sm={3} md={3}>
                    <Typography
                      style={{ marginBottom: "2px", fontSize: "14px" }}
                      variant="body2"
                    >
                      Page Title
                    </Typography>
                    <TextField
                      name="name"
                      type="text"
                      value={organizationData.title}
                      size="small"
                      autoComplete="off"
                      variant="outlined"
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={1}></Grid>
                  <Grid item xs={4}>
                    <Typography
                      style={{ marginBottom: "2px", fontSize: "14px" }}
                      variant="body2"
                    >
                      Logo
                    </Typography>
                    <div
                      className={styles.iconwrapper}
                      style={{ marginTop: 20 }}
                    >
                      {organizationData.logo && (
                        <img
                          variant="square"
                          src={organizationData.logo}
                          alt=""
                          style={{ maxHeight: 100, maxWidth: 100 }}
                        />
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      style={{ marginBottom: "2px", fontSize: "14px" }}
                      variant="body2"
                    >
                      Favicon
                    </Typography>
                    <div
                      className={styles.iconwrapper}
                      style={{ marginTop: 20 }}
                    >
                      {organizationData.favicon && (
                        <img
                          variant="square"
                          src={organizationData.favicon}
                          alt=""
                          style={{ maxHeight: 100, maxWidth: 100 }}
                        />
                      )}
                    </div>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <HelpdeskFAB category="Organization" />
          </div>
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            background: "white",
            backgroundColor: "white",
            height: "90vh",
          }}
        ></div>
      )}
    </main>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    // add_current_organization_data,
  };
};

const mapStateToProps = (state) => {
  const { userInfo } = state.apearenceReducer;
  return {
    userInfo,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationDetailsScreen);
