import React, {  useState } from "react";
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Arrow from '@material-ui/icons/ArrowBackIos';
import {
  Container,
  Link,
  Typography,
  Button,
  TextField,
  IconButton,
  FormLabel
  
} from "@material-ui/core/";
import Styles from "./style"
import EmailSent from './EmailSent'
import ResetPassword from "./ResetPassword";
const  ResetForm= (props) =>{
    const { classes,history } = props;
    let [userName,setUserName]=useState('')
    let [hide,setHide]=useState(false)
    let [user, setUser] = useState({
    username: "",
  });
  const [emailSent,setAlert]=useState(false)
  const getTextFieldValue = (e) => {
    const copyUserObject = user;
    if (e.target.name === "username") {
      copyUserObject.username = e.target.value;
    }
    setUser(copyUserObject);
  };

  const submitResetPassword = (e) => {
    e.preventDefault();
    setTimeout(() => {
      setAlert(false)
      setHide(true);
    }, 4000);
    setAlert(true)
    // setUserName(user.username)
  };

  return (
    <>
<Link component={RouterLink} to="/login" className={classes.linkTest}>
<IconButton>
<Arrow fontSize="small" />
</IconButton>
Back To Login
</Link>
      <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
          <Typography
            variant="h6"
            className={classes.loginText}
            color="primary"
          >
            Reset your password
          </Typography>
          <Typography variant="body2" style={{marginBottom:15}} >
          Enter the email address you use to sign in
          </Typography>
          <form
            className={classes.form}
            onSubmit={submitResetPassword}
            autoComplete="off"
          >
        <FormLabel >Email ID</FormLabel>
            <TextField
              size="small"
              style={emailSent ? {background:"#F2F2F2"} : {}}
              placeholder=""
              variant="outlined"
              className={classes.margin}
              required
              disabled={emailSent ? true : false}
              fullWidth
              id="email"
              name="username"
              onChange={(e) => getTextFieldValue(e)}
            />
           
           {!hide && <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Get reset link
            </Button>}
           
          </form>

          
        </div>
      </Container>
     <Container fullWidth maxWidth="xl"  >
     {emailSent && <div>
              <EmailSent username={user.username}/>
             
          </div>}
     </Container>
     <Container fullWidth maxWidth="sm" >
   { hide && <ResetPassword classes={classes}  history={history}/> }
     </Container>
    </>
  );
}
ResetForm.propTypes = {
    classes: PropTypes.object.isRequired,
  };
export default withStyles(Styles)(ResetForm)
