import React from "react";
import { Handle, Position } from "react-flow-renderer";
import Chip from "@mui/material/Chip";
import MenuItem from "@mui/material/MenuItem";
import { Paper, TextField } from "@mui/material";
import {IconButton} from "@material-ui/core"
import CancelIcon from '@material-ui/icons/Cancel';
export default function JoinNode(props) {
  console.log("props", { ...props });
  return (
    <Paper
      elevation={props.selected ? 12 : 3}
      style={{
        borderRadius: 16,
        // borderRadius:16,
        height: 120,
      }}
    >
      <div>
        <Handle
          className={"circle-port"}
          position={Position.Left}
          type={"target"}
          id={"input"}
          key={"input"}
        />
        {props.selected &&
            ( <IconButton 
                color="primary" 
                aria-label="upload picture" 
                component="span" onClick ={(e)=>{props.data.onNodeDelete(props.id)}}
                style ={{ position: "absolute", top: "-29px", right: "-31px", color: "red", }}
              >
          <CancelIcon />
        </IconButton>)}
        <Chip
          label="Join"
          style={{
            background: "#00a0b2",
            height: "50px",
            width: "100%",
            color: "white",
            fontSize: "20px",
            borderBottomLeftRadius: "0px",
            borderBottomRightRadius: "0px",
          }}
        />
        <div style={{ marginTop: 10, padding: 8 }}>
          <TextField
            autoComplete="off"
            label="Join"
            id="outlined-size-small"
            size="small"
            value={props.data.input?.joinString}

            onChange={(e) => {
              props.data.onChangeNodeData(props.id, props.type, {
                joinString: e.target.value,
              });
            }}
          />
        </div>
        <Handle
          className={"circle-port"}
          position={Position.Right}
          type={"source"}
          id={"output"}
          key={"output"}
        />
      </div>
    </Paper>
  );
}
