import * as types from "./actionTypes";
// import Immutable from "seamless-immutable";
const initialState = {
  onBoard: {
    usageParams: [],
    webhooks: [],
    authRole: [],
    modules: [],
    environments: [],
    API: [],
    appBasicDetails: {
      name: "",
      applicationIconBase64: "",
      description: "",
      category: "",
      shortKey: "",
      singleSignOn: "Supported",
      applicationType: "Both GUI and API",
      tags: [],
      isMicroFrontend: false,
      enableTrialAccess: false,
      trialPeriod: null,
      email: "",
      launchType: "ManualLaunch",
    },
    authWebhooks: {},
    billingWebhooks: {},
  },

  message: null,
  loading: false,
  totalCount: 0,
  app_id: 0,
  appApi: [],
  totalPublished: 0,
  appTeamData: {
    teams: [],
    selectedTeams: [],
    clickedTeam: { users: [] },
  },
  //  message: {},
};
export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    // case types.FETCHING_APPLICATIONS:
    //   return {
    //     ...state,
    //     action: action.type,
    //     loading: action.loading,
    //   };
    // case types.FETCHING_APPLICATIONS_SUCCESS:
    //   return {
    //     ...state,
    //     applicationData: action.payload,
    //     totalCount: action.num !== 1 ? action.totalCount : state.totalCount,
    //     action: action.type,
    //     totalPublished:
    //       action.status === "Published"
    //         ? action.totalCount
    //         : state.totalPublished,
    //     loading: action.loading,
    //   };
    // case types.FECTH_APPLICATIONS_FAILED:
    //   return {
    //     ...state,
    //     loading: action.loading,
    //     action: action.type,
    //     totalPublished: 0,
    //     totalCount: 0,
    //   };
    // case types.FETCH_APPLICATION_DETAILS:
    //   return {
    //     ...state,
    //     loading: true,
    //   };
    // case types.FETCH_APPLICATION_DETAILS_SUCCESS:
    //   console.log(action.payload, "app details34343434");
    //   return {
    //     ...state,
    //     applicationDetails: {
    //       ...action.payload,
    //     },
    //     message: action.message,
    //     action: action.type,
    //     loading: false,
    //   };
    // case types.FETCH_APPLICATION_DETAILS_FAILED:
    //   return {
    //     ...state,
    //     action: action.type,
    //   };
    // case types.FETCH_APPLICATION_DETAILS:
    //   return {
    //     ...state,
    //     loading: true,
    //   };
    // case types.FETCH_APPLICATION_DETAILS_SUCCESS:
    //   console.log(action.payload, "app details34343434");
    //   return {
    //     ...state,
    //     applicationDetails: {
    //       ...action.payload,
    //     },
    //     message: action.message,
    //     action: action.type,
    //     loading: false,
    //   };
    // case types.FETCH_APPLICATION_DETAILS_FAILED:
    //   return {
    //     ...state,
    //     action: action.type,
    //     loading: false,
    //     message: action.message,
    //   };
    case types.ONBOARD_APPLICATION:
      return {
        ...state,
        loading: true,
        action: action.type,
      };
    case types.ONBOARDED_APPLICATION:
      return {
        ...state,
        loading: false,
        message: action.message,
        success: "success",
        action: action.type,
        app_id: action.appId,
      };
    case types.ONBOARDED_APPLICATION_FAILED:
      return {
        ...state,
        loading: false,
        message: action.message,
        success: "fail",
        action: action.type,
      };
    case types.RESET_ONBOARD_SUBMIT:
      return {
        ...state,
        loading: false,
        message: null,
        success: null,
      };
    case types.CLEAR_MESSAGE:
      return {
        ...state,
        message: "",
      };
    // case types.PUBLISHED_APPLICATION:
    //   return {
    //     ...state,
    //     message: action.message,
    //     action: action.type,
    //   };
    // case types.PUBLISH_APPLICATION_FAILED:
    //   return {
    //     ...state,
    //     message: action.message,
    //     action: action.type,
    //   };
    // case types.FETCHED_APPLICATIONS_API:
    //   return {
    //     ...state,
    //     appApi: action.payload,
    //     action: action.type,
    //   };
    case types.ADD_USAGE_PARAMS:
      let arr = [...state.onBoard.usageParams];
      arr.push(action.payload);
      return {
        ...state,
        onBoard: { ...state.onBoard, usageParams: arr },
      };
    case types.UPDATE_USAGE_PARAMS:
      return {
        ...state,
        onBoard: { ...state.onBoard, usageParams: action.payload },
      };
    case types.ADD_WEBHOOKS:
      let webhooksarr = [...state.onBoard.webhooks];
      webhooksarr.push(action.payload);
      return {
        ...state,
        onBoard: { ...state.onBoard, webhooks: webhooksarr },
      };
    case types.UPDATE_WEBHOOKS:
      return {
        ...state,
        onBoard: { ...state.onBoard, webhooks: action.payload },
      };
    case types.ADD_AUTH_PARAMS:
      let authRole = [...state.onBoard.authRole];
      authRole.push(action.payload);
      return {
        ...state,
        onBoard: { ...state.onBoard, authRole: authRole },
      };

    case types.UPDATE_AUTH_PARAMS:
      console.log(action.payload, 3433434);
      return {
        ...state,
        onBoard: { ...state.onBoard, authRole: action.payload },
      };

    case types.ADD_MODULE:
      let modules = [...state.onBoard.modules];
      modules.push(action.payload);
      return {
        ...state,
        onBoard: { ...state.onBoard, modules: modules },
      };

    case types.UPDATE_MODULE:
      let billableData = state.onBoard.usageParams.map((item, id) => {
        if (item.moduleName === action.moduleName) {
          return { ...item, moduleName: action.updateName };
        } else {
          return { ...item };
        }
      });

      return {
        ...state,
        onBoard: {
          ...state.onBoard,
          modules: action.payload,
          usageParams: [...billableData],
        },
      };

    case types.ADD_ENVIRONMENTFORM:
      let environments = [...state.onBoard.environments];

      environments = environments.map((item, id) => {
        return { ...item, isProductionEnvironment: false };
      });

      environments.push(action.payload);
      return {
        ...state,
        onBoard: { ...state.onBoard, environments: environments },
      };

    case types.UPDATE_ENVIRONMENTFORM:
      return {
        ...state,
        onBoard: { ...state.onBoard, environments: action.payload },
      };

    case types.ADD_API:
      let api = [...state.onBoard.API];
      api.push(action.payload);
      return {
        ...state,
        onBoard: { ...state.onBoard, API: api },
      };

    case types.UPDATE_API:
      return {
        ...state,
        onBoard: { ...state.onBoard, API: action.payload },
      };

    case types.ADD_BASIC_INFO:
      return {
        ...state,
        onBoard: { ...state.onBoard, appBasicDetails: action.payload },
      };

    case types.ADD_AUTH_WEBHOOKS:
      return {
        ...state,
        onBoard: { ...state.onBoard, authWebhooks: action.payload },
      };

    case types.ADD_BILLING_WEBHOOKS:
      return {
        ...state,
        onBoard: { ...state.onBoard, billingWebhooks: action.payload },
      };

    case types.CANCEL_APPLICATION:
      return {
        ...initialState,
      };

    case types.FETCHED_TEAMS_FOR_ONBOARD_APP:
      return {
        ...state,
        appTeamData: {
          selectedTeams: state.appTeamData.selectedTeams || [],
          clickedTeam: { users: [] },
          teams: action.payload,
        },
      };
    case types.FETCH_TEAMS_FOR_ONBOARD_APP_FAIL:
      return {
        ...state,
        appTeamData: {
          selectedTeams: [],
          clickedTeam: { users: [] },
          teams: [],
        },
      };

    case types.ADD_TEAM_TO_ONBOARD_APP:
      return {
        ...state,
        appTeamData: {
          selectedTeams: action.selectedTeam,
          clickedTeam: action.clickedTeam,
          teams: action.updateTeams,
        },
      };

    case types.RESET_TEAM_IN_ONBOARD_APP:
      return {
        ...state,
        appTeamData: {
          selectedTeams: [],
          clickedTeam: { users: [] },
          teams: [],
        },
      };
    case types.SET_CLICKED_TEAM:
      return {
        ...state,
        appTeamData: {
          ...state.appTeamData,
          clickedTeam: action.payload,
        },
      };

    default:
      return state;
  }
}
