import { React, useEffect, useState } from "react";
import WrappedDexTable from "components/DexTable/WrappedDexTable";
import {
  Button,
  TextField,
  Typography,
  FormLabel,
  Select,
  Tooltip,
  IconButton,
  Grid,
  Chip,
} from "@material-ui/core";
import { checkPermission } from "helpers/permissons";
import AddIcon from "@material-ui/icons/Add";
import withSection from "components/Section/Section";
// import VisibilityIcon from "@material-ui/icons/Visibility";
import Helpers from "helpers/helpers";
import { RiEditLine, RiDeleteBin5Line } from "react-icons/ri";
import { confirmDialog } from "components/DeleteConfirmationDialog/Index";
import { dltMsg } from "helpers/helpers";
import useAxiosIIFE from "customHooks/ApiHooks/Index";
import moment from "moment";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import { ApplicationDetailsUpadateHiddenCol } from "../ApplicationDetails/Redux/action";
const updated_on = ({ row }) => {
  const { FORMAT, timeZone } = Helpers.getDateFormatAdvance();
  const upadtedOn = row.updatedon
    ? moment.unix(row.updatedon).tz(timeZone).format(FORMAT)
    : "-";
  return (
    <div>
      <Typography>{upadtedOn}</Typography>
    </div>
  );
};

const updated_by = ({ row }) => {
  const upadtedOn = row.updatedby ? row.updatedby : "-";
  return (
    <div>
      <Typography>{upadtedOn}</Typography>
    </div>
  );
};

const IntroDocSection = (props) => {
  const { appData, appTable } = props;
  const { introDocHidden } = appTable;
  const [loading, setLoading] = useState(true);
  console.log("app data", appData);
  const data = useAxiosIIFE("GET_DOCUMENTS_TYPE_INTRO", appData._id);
  const [introDoc, setIntroDoc] = useState([]);

  console.log("intro documents", data, appData);

  useEffect(() => {
    if (data) {
      console.log("data barpunda", data);
      setIntroDoc(data);
      setLoading(false);
    } else {
      setIntroDoc([]);
    }
  }, [data]);

  // const pushToUsageDocuments = () => {
  //   props.history.push("/app/create-doc", {
  //     data: {
  //       ID: appId,
  //       isEdit: false,
  //       isDisabled: false,
  //       modules: props.modulesData,
  //     },
  //   });
  // };

  const pushToDocumentation = () => {
    checkPermission("btn122") &&
      props.history.push(`/app/documentation/${appData._id}`, {
        documentationData: { ...appData, ID: appData._id },
        type: "intro",
      });
    // props.history.push("/app/create-doc" + appData._id, {
    //   documentationData: { ...appData, ID: appData._id },
    //   type: "intro",
    // });
  };

  console.log("intro document333333555", introDoc, appData);
  const IntroEmpty = introDoc.length !== 0;
  return (
    <>
      <Grid container alignItems="center" spacing={2}>
        <Grid item md={12} container justifyContent="flex-end">
          <div>
            <Button
              size="small"
              variant="contained"
              onClick={() => checkPermission("btn122") && pushToDocumentation()}
              startIcon={IntroEmpty ? <RiEditLine /> : <AddIcon />}
              style={{ padding: "4px 25px 4px 25px" }}
              disabled={!checkPermission("btn122")}
            >
              {IntroEmpty ? "Edit" : "Create"}
            </Button>
          </div>
        </Grid>
        <Grid item md={12}>
          <WrappedDexTable
            data={introDoc || []}
            columWidthsType="percentage"
            columnOrder={[
              "name",
              "createdon",
              "createdby",
              "updatedby",
              "updatedon",
            ]}
            columns={[
              { name: "name", title: "Section Name" },
              { name: "createdon", title: "Created On" },
              { name: "createdby", title: "Created By" },
              {
                name: "updatedby",
                title: "Updated By",
              },
              {
                name: "updatedon",
                title: "Updated On",
              },
            ]}
            defaultColumnWidths={[
              { columnName: "name", width: 18 },
              { columnName: "createdon", width: 18 },
              { columnName: "createdby", width: 18 },
              { columnName: "updatedby", width: 18 },
              { columnName: "updatedon", width: 15 },
            ]}
            dateColumns={["createdon"]}
            disableReordering={true}
            pagination={false}
            // hideColumnChooser={true}
            noDataMsg="Intro documentation not found"
            formatters={[
              { formatterFn: updated_on, columns: ["updatedon"] },
              {
                formatterFn: updated_by,
                columns: ["updatedby"],
              },
            ]}
            hidden={[...introDocHidden]}
            setHiddenColmn={(hidden) => {
              props.setHiddenColumn(hidden, "introDocHidden");
            }}
            //   onClickRow={(row) => viewModuledata(row)}
            //   Read={checkPermission("btn222")}
          />
        </Grid>
      </Grid>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setHiddenColumn: (hidden, type) =>
    dispatch(ApplicationDetailsUpadateHiddenCol(hidden, type)),
});

const mapStateToProps = (state) => {
  return {
    appTable: state.appDetailsRedcer.appTable,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withSection(IntroDocSection, "Intro Documentation")));
