import React, { Component } from 'react';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';

export default class Resizer extends Component {
  getStyle() {
    if (this.props.direction === 'column') {
      return {
        width: '100%',
        height: this.props.size,
        background: "#dee2e6",
       
      };
    }

    return {
      width: this.props.size,
      height: '100%',
      background: "#dee2e6",
      display:"flex",
      alignItems:"center",
      justifyContent:"center"
    };
  }

  render() {
    return (
      <div  style={this.getStyle()} >
        <div onMouseDown={this.props.onMouseDown} style={{ 
          height:"35px",
          borderRadius:"8px",
          cursor: 'row-resize',
          width:this.props.size+2,
          background:"orange",
          zIndex:"1000",
          display:"flex",
          alignItems:"center",
          justifyContent:"center"
          }}
        >
          <DragIndicatorOutlinedIcon/>
        </div>
        
      </div>
    );
  }
}
