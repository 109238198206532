import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import AppBasic from "./AppBasic";
import Environment from "../Components/Environment";
import BillingConfig from "../Components/BillingConfig";
import AuthorizationObjects from "../Components/AuthorizationObjects";
import ApplicationAPI from "../Components/ApplicationAPI";
import Document from "../Components/Document";
import ApplicationModules from "../Components/ApplicationModules";
import ApplicationWebhooks from "../Components/ApplicationWebhooks";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { makeStyles } from "@material-ui/core/styles";
import { checkPermission } from "helpers/permissons";
import {
  IconButton,
  CardContent,
  Container,
  Card,
  Grid,
  Button,
  TextField,
  Typography,
  FormLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import SendIcon from "@material-ui/icons/Send";
import {} from "../redux/action";
import { DataUsageOutlined } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import Breadcrumb from "components/Breadcrumbs/Breadcrumb";
import AppTeam from "../Components/Teams";
import HelpdeskFAB from "../../../views/HelpdeskFAB/HelpdeskFab";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },

  positionFixed: {
    paddingTop: "20px",
    marginLeft: -2,
    marginTop: -20,
    width: "92%",
    position: "fixed",
    zIndex: 889,
    paddingBottom: "10px",
    backgroundColor: "#fafafa",
    [theme.breakpoints.up("md")]: {
      // paddingTop: "19px",
      marginLeft: 0,
      // marginTop: -20,
      width: "98.6%",
      paddingBottom: "15px",
    },
    [theme.breakpoints.only("sm")]: {
      // paddingTop: "25px",
      marginLeft: -2,
      // marginTop: -20,
      width: "96.3%",
      paddingBottom: "15px",
    },
  },
}));

const ApplicationComponent = (props) => {
  const { permission } = props;
  const [errValue, setErrValue] = useState({
    module: false,
    billable: false,
    auth: false,
    env: false,
  });
  const classes = useStyles();
  const [duplicate, setDuplicate] = React.useState([
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
  ]);
  const { enqueueSnackbar } = useSnackbar();

  const [chipData, setChipData] = useState([]);
  const [environMent, setEnvironment] = React.useState([]);
  const [microRadio, setMicroRadio] = useState(true);
  const [basicInfo, setBasicInfo] = React.useState({
    name: "",
    applicationIconBase64: "",
    description: "",
    category: "",
    singleSignOn: "Supported",
    applicationType: "Both GUI and API",
    tags: [],
    isMicroFrontend: false,
    enableTrialAccess: false,
    trialPeriod: null,
    email: "",
    launchType: "ManualLaunch",
  });

  const [applicationApi, setApplicationApi] = React.useState([]);
  const [applicationModule, setApplicationModule] = React.useState([]);
  const [authObject, setAuthObject] = React.useState({
    roles: [],
    webhooks: {
      url: "",
      method: "",
      apiKey: "",
    },
  });

  const [applicationWebhook_multiple, setApplicationWebhook_multiple] =
    React.useState([
      {
        type: "",
        url: "",
        method: "",
        apiKey: "",
      },
    ]);

  const [applicationWebhook, setApplicationWebhook] = React.useState([]);

  const [billingService_multiple, setBillingService_multiple] = React.useState({
    usageParams: [{}],
    webhooks: {
      url: "",
      method: "",
      apiKey: "",
    },
  });

  useEffect(() => {
    let variant = props.color;
    if (props.message) {
      let { _msg } = props.message;
      let msg = _msg || "Network issue";
      _msg !== undefined && enqueueSnackbar(msg, { variant });
    }
  }, [props.message]);

  const finalSubmit = (e) => {
    const { onBoard, appTeamData } = props;
    let { selectedTeams } = appTeamData;
    selectedTeams = selectedTeams.map((item, id) => {
      const { _id, ...other } = item;
      return _id;
    });
    e.preventDefault();
    setDuplicate([[], [], [], [], [], [], [], []]);
    let suport = onBoard.appBasicDetails.singleSignOn;
    let auth =
      suport === "Supported" && onBoard.authRole.length === 0 ? true : false;

    if (
      onBoard.usageParams.length === 0 ||
      auth ||
      onBoard.modules.length === 0 ||
      onBoard.environments.length === 0
    ) {
      setErrValue({
        ...errValue,
        module: onBoard.modules.length === 0 ? true : false,
        env: onBoard.environments.length === 0 ? true : false,
        auth: auth,
        billable: onBoard.usageParams.length === 0 ? true : false,
      });

      enqueueSnackbar(`Please fill all the required fields`, {
        variant: "error",
      });
      return;
    }
    let basic_info = { ...basicInfo };
    if (!microRadio) {
      const { isMicroFrontend, ...other } = basicInfo;
      basic_info = other;
    }
    const onboardPayload = {
      applicationDetails: { ...basic_info },
      applicationModules: [...applicationModule],
      environments: [...environMent],
      applicationApi: [...applicationApi],
      authorization: { ...authObject },
      billingServices: { ...billingService_multiple },
      webhooks: [...applicationWebhook_multiple],
      teams: [...selectedTeams],
    };
    console.log(onboardPayload, "final payload");
    props.onBoardApplications(onboardPayload);
  };

  const cancel_Application = async () => {
    await props.cancelApp();
    props.history.push("/app/applications");
  };

  return (
    <div>
      <ValidatorForm
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            return;
          }
        }}
        onSubmit={checkPermission("btn70") && finalSubmit}
        autoComplete="off"
      >
        <Grid container alignItems="center">
          <Grid item md={12}>
            <Grid
              container
              style={{
                position: "fixed",
                zIndex: 889,
                backgroundColor: "#f2f2f2",
                top: "50px",
              }}
            >
              <Grid item md={12}>
                <Breadcrumb
                  {...props}
                  links={[
                    { name: "Applications", url: "/app/applications" },
                    {
                      name: "Onboard Application",
                      url: "/app/onboard-application",
                    },
                  ]}
                />
              </Grid>
              <Grid
                item
                md={12}
                container
                direction="row"
                justifyContent="space-between"
              >
                <Grid item>
                  <Typography variant="h6" style={{ fontWeight: "bold" }}>
                    Onboard Application
                  </Typography>
                </Grid>
                <Grid item style={{ paddingRight: "46px" }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      cancel_Application();
                    }}
                    alignItems="center"
                    size="small"
                  >
                    Cancel
                  </Button>
                  <Button
                    className="ml-3"
                    variant="contained"
                    color="primary"
                    alignItems="center"
                    size="small"
                    type="submit"
                    disabled={!checkPermission("btn70")}
                    style={{ color: "white" }}
                    // onClick={finalSubmit}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
              <Grid item md={12}>
                <div
                  style={{
                    marginTop: "10px",
                    borderBottom: "solid  2px rgb(175, 175, 175)",
                    marginRight: "45px",
                  }}
                ></div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <div className="pt-5"></div>
        <div>
          <AppBasic
            setBasicInfo={setBasicInfo}
            chipData={chipData}
            setChipData={setChipData}
            basicInfo={basicInfo}
            addCategory={props.addCategory}
            appBasicDetails={props.onBoard.appBasicDetails}
            setMicroRadio={setMicroRadio}
            microRadio={microRadio}
            {...props}
          />
        </div>

        <ApplicationModules
          setDuplicate={setDuplicate}
          applicationModule={applicationModule}
          setApplicationModule={setApplicationModule}
          duplicate={duplicate}
          modules={props.onBoard.modules}
          billingData={props.usageParams}
          errValue={errValue}
          {...props}
        />

        <Environment
          duplicate={duplicate}
          setDuplicate={setDuplicate}
          data={props.data}
          setEnvironment={setEnvironment}
          environMent={environMent}
          environmentsData={props.onBoard.environments}
          errValue={errValue}
          {...props}
        />

        <ApplicationAPI
          duplicate={duplicate}
          setDuplicate={setDuplicate}
          applicationApi={applicationApi}
          setApplicationApi={setApplicationApi}
          application_API={props.onBoard.API}
          {...props}
        />

        <AuthorizationObjects
          duplicate={duplicate}
          setDuplicate={setDuplicate}
          authObject={authObject}
          setAuthObject={setAuthObject}
          setBasicInfo={setBasicInfo}
          basicInfo={basicInfo}
          authRole={props.onBoard.authRole}
          authWH={props.onBoard.authWebhooks}
          errValue={errValue}
          {...props}
        />

        <BillingConfig
          duplicate={duplicate}
          setDuplicate={setDuplicate}
          billingService_multiple={billingService_multiple}
          setBillingService_multiple={setBillingService_multiple}
          billingWH={props.onBoard.billingWebhooks}
          {...props}
          modules={props.onBoard.modules}
          errValue={errValue}
        />

        <AppTeam editDisable={true} {...props} />

        <ApplicationWebhooks
          duplicate={duplicate}
          setDuplicate={setDuplicate}
          applicationWebhook={applicationWebhook}
          setApplicationWebhook={setApplicationWebhook}
          applicationWebhook_multiple={applicationWebhook_multiple}
          setApplicationWebhook_multiple={setApplicationWebhook_multiple}
          webhooks={props.onBoard.webhooks}
          {...props}
        />
        <HelpdeskFAB category="Onboard Application" />
      </ValidatorForm>
    </div>
  );
};
export default ApplicationComponent;
