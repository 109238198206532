/**
 * Use Recently Visted used LRU cache algorithm to keep track of what user visted recently
 */

import React,{useState} from 'react';

function useRecentlyVisted(){
    const [pages,setPages] = useState([])
    function markRecentlyVist(page){
        let newPages = [page,...pages.filter(f=>f.pageName !== page.pageName)]
        if(newPages.length > 3){ // by default maintain at length 5
            newPages.pop()
        }
        setPages(newPages)
    }
    return [pages, markRecentlyVist]
}

export default useRecentlyVisted