import React, { useEffect, useState } from "react";
import { Grid, Card, CardContent, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import withSection from "components/Section/Section";
import Styles from "./Styles";
import { withStyles } from "@material-ui/styles";

const UserTeamsData = (props) => {
  const { details, isEdit, isDisabled, teamsData, classes } = props;
  console.log(teamsData.teams, classes, "fghjkl");
  let teams_data = props.teamsData.teams ? props.teamsData.teams : [];
  return (
    <>
      <Grid
        item
        md={12}
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        {teams_data.map((item, id) => {
          return (
            <>
              {item && (
                <Grid item md={2} className="mt-3">
                  <Card variant="outlined" className={classes.teamCardStyle}>
                    <CardContent
                      style={{
                        // display: "flex",
                        // justifyContent: "space-around",
                        // alignItems: "center",
                        padding: "14px",
                      }}
                    >
                      <div className="ml-1">
                        <Typography style={{ fontWeight: "bolder" }}>
                          {item.name}
                        </Typography>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              )}
            </>
          );
        })}
      </Grid>
    </>
  );
};
export default withStyles(Styles)(withSection(UserTeamsData, "User Teams"));
