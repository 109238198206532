const Styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#fafafa",
    marginTop: 5,
  },

  filter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  filterPl: {
    paddingLeft: 60,
  },
  tabs: {
    textTransform: "capitalize",
  },
  table: {
    marginTop: 15,
  },
  activeBadge: {
    backgroundColor: "#21BA45",
  },
  inactiveBadge: {
    backgroundColor: "#BEBEBE",
  },

  blockBadge: {
    backgroundColor: "#DB2828",
  },
  badge: {
    display: "flex",
    alignItems: "center",
  },
  typo: {
    marginRight: 10,
  },
  block: {
    color: "red",
  },
  active: {},
  footer: {
    position: "fixed",
    bottom: 0,
    left: 0,
    padding: 20,
    width: "100%",
    textAlign: "right",
    zIndex: 999,
    backgroundColor: "#f2f2f2",
  },
  customPagination: {
    width: "fit-content",
    marginLeft: "auto",
    "& .MuiPaginationItem-page.Mui-selected": {
      border: "0.5px solid #707070",
      margin: "0 10px",
      height: 30,
      width: 30,
      padding: "10px 13px",
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      boxShadow: "0px 3px 10px #5757571a",
      lineHeight: 1,
      borderRadius: 5,
      opacity: 1,
    },
    "& .MuiPaginationItem-page": {
      font: "normal normal normal 14px/19px Nunito Sans",
      letterSpacing: 0,
      color: "#000000",
      opacity: 0.6,
    },
  },
});
export default Styles;
