import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { TextValidator } from "react-material-ui-form-validator";
import { useEffect, useState } from "react";
const filter = createFilterOptions();
export const IndustryTypesAutoComplete = ({
  industryTypes,
  onChange,
  value,
  disabled,
}) => {
  const [industryTypeData, setIndustryTypes] = useState([]);
  useEffect(() => {
    setIndustryTypes([
      ...industryTypes.map((r) => {
        return {
          name: r,
        };
      }),
    ]);
  }, [industryTypes]);
  return (
    <>
      <Autocomplete
        disabled={disabled}
        fullWidth
        style={{ width: "100%" }}
        size="small"
        value={{ name: value ?? "" }}
        onChange={(event, newValue) => {
          if (typeof newValue === "string") {
            console.log("affghfshggd", 1, newValue);
            onChange(newValue);
          } else if (newValue && newValue.inputValue) {
            console.log("affghfshggd", 2, newValue);
            onChange(newValue.inputValue);
          } else {
            console.log("affghfshggd", 3, newValue);
            onChange(newValue?.name || "");
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          // Suggest the creation of a new value
          if (params.inputValue !== "") {
            filtered.push({
              inputValue: params.inputValue,
              name: `Add "${params.inputValue}"`,
            });
          }
          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        id="free-solo-with-text-demo"
        options={industryTypeData}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          // Add "xyz" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.name;
        }}
        renderOption={(option) => option.name}
        freeSolo
        renderInput={(params) => (
          <div>
            <TextValidator
              {...params}
              value={value}
              size="small"
              variant="outlined"
              validators={["required"]}
              errorMessages={["Please select industry type"]}
            />
          </div>
        )}
      />
    </>
  );
};
