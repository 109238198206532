import * as actions from "./actionTypes";
import StatusProfileAPIS from "api/StatusProfileAPIs/index";

function actionfetchAllStatusProfileData(pageno, size, filters, sort) {
  console.log({ actions });
  return async (dispatch) => {
    dispatch({
      type: actions.FETCH_STATUS_PROFILE,
      loading: true,
    });
    try {
      let res = await StatusProfileAPIS.getStatusProfileData(
        pageno,
        size,
        filters,
        sort
      );
      dispatch({
        type: actions.FETCH_STATUS_PROFILE_SUCCESS,
        loading: false,
        totalCount: res.data._totalcount,
        data: res.data.data || [],
      });

      const { _msg, _status } = res.data;
      return {
        _msg,
        _status,
      };
    } catch (err) {
      console.log(err);
      dispatch({
        type: actions.FETCH_STATUS_PROFILE_FAILURE,
        loading: false,
        error: err,
      });
      return {
        _msg: err?.response?.data?._msg,
        _status: err?.response?.data?._status,
      };
    }
  };
}
function updateStatusProfileHiddenCol(hidden) {
  console.log({ actions });
  return async (dispatch) => {
    dispatch({
      type: actions.UPDATE_STATUS_PROFILE_HIDDEN_COL,
      payload: hidden,
    });
  };
}

// export const deleteHelpDeskData = (id) => async (dispatch) => {
//   dispatch({
//     type: actions.DELETE_HELPDESKDATA,
//     loading: true,
//   });
//   try {
//     let res = await HelpDeskAPIS.deleteHelpDeskData(id);
//     dispatch({
//       type: actions.DELETE_HELPDESKDATA_SUCCESS,
//       loading: false,
//     });
//     return res;
//     console.log(res, "001");
//   } catch (err) {
//     dispatch({
//       type: actions.DELETE_HELPDESKDATA_FAILURE,
//       loading: false,
//     });
//     console.log(err.response, "002");
//     return err;
//   }
// };

export default {
  actionfetchAllStatusProfileData,
  updateStatusProfileHiddenCol,
};
