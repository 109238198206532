import * as types from "./actionType";

export const addModule = (data) => ({
  type: types.ADD_MODULE,
  payload: data,
});

export const updateModule = (data) => ({
  type: types.UPDATE_MODULE,
  payload: data,
});
