const Styles = (theme) => ({
  title: {
    textTransform: "capitalize",
    fontWeight: "bold",
    justifyContent: "center",
  },
  divider: {
    marginTop: "12px",
    height: "2px",
    background: "rgb(175, 175, 175)",
  },
  btnstyle: {
    width: "200px",
  },
  liststyles: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #e0e0e0",
    opacity: 1,
    width: "536px",
    // padding: "5px 0",
    borderRadius: 0,
  },
  teamCardStyle: {
    // width: "345px",
    height: "80px",
    marginRight: "10px",
    backgroundColor: "transparent",
  },
  cardListStyle: {
    width: "490px",
    height: "450px",
  },
  radioStyle: {
    height: "42px",
  },
  title1: {
    backgroundColor: "rgba(0, 0, 0, 0.043)",
    borderBottom: "1px solid rgba(0, 0, 0, 0.043)",
  },
  actions: {
    backgroundColor: "rgb(249, 250, 253)",
  },
});
export default Styles;
