import React, { Component } from "react";
import {
  Typography,
  Grid,
  Button,
  Divider,
  TextField,
  FormGroup,
  Select,
  IconButton,
  Input,
  FormControl,
  InputLabel,
  InputAdornment,
  Visibility,
  VisibilityOff,
} from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
// import IconButton from "@mui/material/IconButton";
// import Input from "@mui/material/Input";

// import InputLabel from "@mui/material/InputLabel";
// import InputAdornment from "@mui/material/InputAdornment";

// import FormControl from "@mui/material/FormControl";

// import Visibility from "@mui/icons-material/Visibility";
// import VisibilityOff from "@mui/icons-material/VisibilityOff";

import Breadcrumb from "components/Breadcrumbs/Breadcrumb";
import UserRoles from "./UserRoles";
import actions from "./redux/action";
import { connect } from "react-redux";
import APIs from "../../api/UserManagementAPIs/index";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { withSnackbar } from "notistack";
import moment from "moment";
import APIS_ROLE from "../../api/RoleManagementAPIs/index";

import { CustomNumberFeild } from "components/Customized";

import { RiEditLine } from "react-icons/ri";
import { Sync } from "@material-ui/icons";
import { update_User_Role } from "./redux/action";
import Loader from "../../components/common/stuff/Loader";
import { LOAD_LOADING } from "./redux/actionTypes";
import HelpdeskFAB from "../../views/HelpdeskFAB/HelpdeskFab";
import { withStyles } from "@material-ui/core/styles";
import UserTeams from "./UserTeams";
import { checkPermission } from "helpers/permissons";
import PhoneInputComponent from "components/PhoneNumber/PhoneInputComponent";
import helpers from "../../helpers/helpers";
const useStyles = (theme) => ({
  card: {
    boxShadow: "0px 3px 6px 0px #e4e4e4",
  },
  lableText: {
    fontSize: "12px",
    fontWeight: "700",
    fontFamily: ['"Nunito"', "sans-serif"].join(","),
    color: "#000",
    paddingTop: "20px",
  },
  heading: {
    textAlign: "center",
    fontSize: "21px",
    color: "primary",
    fontWeight: "800",
  },
  underline: {
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
  },
});

const initialUserInfo = {
  name: "",
  email: "",
  contactNo: "",
  countryCode: "+65",
  roles: [],
};
class addUserManagementData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ID: localStorage.getItem("userManagementId"),
      isEdit: false,
      ajax: false,
      userData: {
        ...initialUserInfo,
      },
      err_msg: "",
      index: 0,
      loading: false,
      isDisabled: false,
      appId: 0,
      countryCode: [],
      code: "+65 Singapore",
      defaultCountryCode: false,
    };
  }

  onChange = (value) => {
    this.setState({
      userData: { ...value },
    });
  };

  fetchUserDetails = async (id) => {
    try {
      let res = await APIs.fetchAllUserManagement(id);
      this.setState({
        userData: { ...(res || {}) },
      });
      return true;
    } catch (err) {
      return null;
    }
  };
  handleCountryCodeChange = (e) => {
    console.log("clg", e.target.value);
    this.setState({
      code: e.target.value,
    });
  };
  fetchCountryCodes = async () => {
    try {
      const res = await APIs.getCountryCodes();
      const country = res.data.data.countries;
      this.setState({
        countryCode: country,
      });
      return res;
    } catch (err) {
      return null;
    }
  };

  viewUserDetails = async (data, edit, disabled, appDetail) => {
    let res = await this.fetchUserDetails(data._id);
    console.log(res, "34567uzxfgbvd");
    this.setState({
      loading: true,
    });
    if (res) {
      this.setState({
        isEdit: edit,
        isDisabled: disabled,
        appId: appDetail,
        loading: false,
      });
    } else {
      appDetail
        ? this.props.history.push("/app/detail", { appId: appDetail })
        : this.props.history.push("/app/user-management");
    }
  };

  async componentDidMount() {
    const { location } = this.props;
    this.fetchCountryCodes();
    await this.setState({
      loading: true,
    });
    if (location.state !== undefined) {
      this.props.getrolesData(JSON.stringify({}));
      let { userData, edit, disabled, appDetail } = location.state;
      if (edit || disabled) {
        this.viewUserDetails(userData, edit, disabled, appDetail);
      } else {
        this.setState({
          isEdit: edit,
          isDisabled: disabled,
          userData: { ...this.state.userData, ...userData },
          appId: appDetail,
        });
      }
    } else {
      this.props.history.push("/login");
    }
    await this.setState({
      loading: false,
    });
  }

  editUserdata = async () => {
    let { appId } = this.state;
    let {
      _id,
      roles,
      email,
      updatedby,
      updatedon,
      createdby,
      createdon,
      ...data
    } = this.state.userData;
    await this.setState({
      loading: true,
    });

    try {
      const res = await APIs.editUserManagement(_id, data);
      this.props.enqueueSnackbar(
        res.data._msg || "User details updated successfully",
        {
          variant: "success",
          autoHideDuration: 2000,
        }
      );
      await this.setState({
        loading: false,
      });

      appId
        ? this.props.history.push("/app/detail", { appId: appId })
        : this.props.history.push("/app/user-management");
    } catch (err) {
      this.props.enqueueSnackbar(
        err?.response?.data?._msg || "Connection error!",
        {
          variant: "error",
          autoHideDuration: 2000,
        }
      );
      await this.setState({
        loading: false,
      });
    }

    // if (res.data) {
    //   this.props.enqueueSnackbar("User details updated successfully", {
    //     variant: "success",
    //     autoHideDuration: 2000,
    //   });
    //   this.props.history.push("/app/user-management");
    // }
  };

  addUserdata = async (type) => {
    await this.setState({
      loading: true,
    });
    const { userData } = this.state;
    if (userData.roles.length === 0) {
      await this.setState({
        loading: false,
      });
      this.props.enqueueSnackbar("Please add roles!", {
        variant: "error",
        autoHideDuration: 2000,
      });
      return;
    }
    let userRoles = [];
    userData.roles.map((item, id) => {
      if (item) {
        userRoles.push(item.id);
      }
    });
    let user_data = { ...userData };
    user_data.roles = [...userRoles];
    try {
      const res = await APIs.addUserManagement(user_data);
      this.props.enqueueSnackbar(res._msg || "User Created Successfully", {
        variant: "success",
        autoHideDuration: 2000,
      });
      if (type) {
        this.props.history.push("/app/user-management");
      } else {
        await this.setState({
          userData: {
            ...initialUserInfo,
          },
          autoCompleteVal: null,
          isEdit: false,
          loading: false,
        });
      }
    } catch (err) {
      this.props.enqueueSnackbar(
        err?.response?.data._msg || "Something Went wrong",
        {
          variant: "error",
          autoHideDuration: 2000,
        }
      );
      await this.setState({
        loading: false,
      });
    }
  };

  multiAdd = async () => {
    let { isEdit } = this.state;
    if (isEdit) {
      await this.editUserdata();
    } else {
      this.addUserdata(false);
    }
  };

  onSubmitUser = (e) => {
    let { userData } = this.state;
    if (userData.roles.filter((item, id) => item !== undefined).length === 0) {
      this.props.enqueueSnackbar("Please add roles!", {
        variant: "error",
        autoHideDuration: 2000,
      });
      return;
    }
    if (e.nativeEvent.submitter.name === "multiple") {
      this.multiAdd();
    }
    if (e.nativeEvent.submitter.name === "single") {
      this.addUserdata(true);
    }
  };

  handleUsersRoles = (arr, type) => {
    if (this.state.isEdit) {
      let data = arr.filter((item, id) => {
        return item.status === true;
      });
      this.editRoles(data);
      console.log(arr, data, "jvgbh");
    } else {
      this.setState({
        userData: { ...this.state.userData, roles: [...arr] },
      });
    }
  };

  editRoles = async (role) => {
    let { _id, roles } = this.state.userData;
    const { data, status, response } = await this.props.update_Role(
      true,
      _id,
      role[0]?._id
    );
    console.log(_id, status, "editt");
    let flag = status === 200 ? true : false;
    if (flag) {
      let updateRoles = [...roles];
      let refreshPayload = { ...this.state.userData };
      updateRoles.push(role[0]);
      refreshPayload.roles = [...updateRoles];
      this.props.history.push("/app/create-usermanagement", {
        userData: refreshPayload,
        edit: true,
      });
      this.setState({
        userData: { ...this.state.userData, roles: [...updateRoles] },
      });
    }

    this.props.enqueueSnackbar(
      flag
        ? data && data._msg
        : response && response.data
        ? response.data._msg
        : "something went wrong",
      {
        variant: flag ? "success" : "error",
        autoHideDuration: 1000,
      }
    );
  };

  handleDelete = (id, role_id) => {
    let updateRoles = [...this.state.userData.roles];
    updateRoles = updateRoles.map((item, idx) => {
      if (idx === id) {
        return undefined;
      } else {
        return item;
      }
    });
    if (this.state.isEdit) {
      this.removeRole(role_id, updateRoles);
    } else {
      this.setState({
        userData: { ...this.state.userData, roles: [...updateRoles] },
      });
    }
  };

  removeRole = async (id, updateRoles) => {
    let { _id, roles } = this.state.userData;
    console.log(this.state.userData, updateRoles, "dattaaa");
    const { data, status, response } = await this.props.update_Role(
      false,
      _id,
      id
    );
    let flag = status === 200 ? true : false;
    if (flag) {
      let refreshPayload = { ...this.state.userData };
      refreshPayload.roles = [...updateRoles];
      this.props.history.push("/app/create-usermanagement", {
        userData: refreshPayload,
        edit: true,
      });
      this.setState({
        userData: { ...this.state.userData, roles: [...updateRoles] },
      });
    }
    this.props.enqueueSnackbar(
      flag
        ? data && data._msg
        : response && response.data
        ? response.data._msg
        : "something went wrong",
      {
        variant: flag ? "success" : "error",
        autoHideDuration: 2000,
      }
    );
  };

  search_Role = async (filter) => {
    try {
      this.props.getrolesData(JSON.stringify(filter));
      // await APIS_ROLE.fetchAllUserManagementrolesWithParams(
      //   JSON.stringify(filter)
      // );
    } catch (err) {}
  };

  render() {
    const { userData, isEdit, isDisabled, loading, appId } = this.state;
    console.log("21562516fdshfhgdfhg", userData);
    return (
      <div>
        {loading && <Loader />}
        <ValidatorForm
          ref="form"
          autoComplete="off"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              return;
            }
          }}
          onSubmit={(e) => {
            this.onSubmitUser(e);
          }}
        >
          <Grid container>
            <Grid item sm={12} md={12} xs={12} lg={12}>
              <Breadcrumb
                {...this.props}
                links={[
                  {
                    name: appId ? "Application Details" : "User",
                    url: appId ? "/app/detail" : "/app/user-management",
                    routeParams: appId ? { appId: appId } : undefined,
                  },
                  {
                    name: `${isEdit ? userData.name : "User"}`,
                    url: "/app/create-usermanagement",
                  },
                ]}
              />
            </Grid>

            <Grid item sm={6} md={6} lg={6} xs={12}>
              <Typography variant="h6" style={{ fontWeight: "bold" }}>
                {isEdit ? userData.name : "Create User"}
              </Typography>
            </Grid>
            <Grid
              item
              sm={6}
              md={6}
              container
              justifyContent="flex-end"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                {!isDisabled && (
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={() => {
                      appId
                        ? this.props.history.push("/app/detail", {
                            appId: appId,
                          })
                        : this.props.history.push("/app/user-management");
                    }}
                  >
                    Cancel
                  </Button>
                )}
              </Grid>
              <Grid item>
                {!isDisabled ? (
                  <Button
                    name="multiple"
                    color={isEdit ? "" : "primary"}
                    size="small"
                    variant={isEdit ? "contained" : "outlined"}
                    type="submit"
                    disabled={
                      isEdit
                        ? !checkPermission("btn52")
                        : !checkPermission("btn50")
                    }
                  >
                    {isEdit ? "Save" : "Create and Add Another"}
                  </Button>
                ) : null}
              </Grid>
              <Grid item>
                {!isEdit && (
                  <Button
                    // disabled={loading ? true : false}
                    name="single"
                    size="small"
                    variant="contained"
                    type="submit"
                    disabled={!checkPermission("btn50")}
                  >
                    {"Create"}
                  </Button>
                )}
              </Grid>
              <Grid item>
                {isDisabled && (
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() =>
                      checkPermission("btn52") &&
                      this.setState({
                        isDisabled: false,
                      })
                    }
                    disabled={!checkPermission("btn52")}
                    startIcon={<RiEditLine style={{ fontSize: "20px" }} />}
                  >
                    Edit
                  </Button>
                )}
              </Grid>
            </Grid>
            <Grid item md={12}>
              <Divider
                style={{
                  marginTop: "12px",
                  height: "2px",
                  background: "rgb(175, 175, 175)",
                }}
              />
            </Grid>

            <Grid item md={12} style={{ marginTop: "22px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4} md={3}>
                  <Typography style={{ marginBottom: "2px" }} variant="body2">
                    User Name <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextValidator
                    disabled={isEdit}
                    size="small"
                    variant="outlined"
                    fullWidth
                    name="name"
                    value={userData.name}
                    onChange={(e) =>
                      this.onChange({
                        ...userData,
                        name: e.target.value.trimStart(),
                      })
                    }
                    validators={["required"]}
                    errorMessages={["Please enter name"]}
                    helperText={<div style={{ height: "12px" }}></div>}
                  />
                </Grid>
                <Grid item md={3} xs={12} sm={3}>
                  <Typography style={{ marginBottom: "2px" }} variant="body2">
                    Email <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextValidator
                    disabled={isEdit ? true : false}
                    id="email"
                    name="email"
                    size="small"
                    variant="outlined"
                    fullWidth
                    value={userData.email}
                    onChange={(e) =>
                      this.onChange({ ...userData, email: e.target.value })
                    }
                    validators={["required", "isEmail"]}
                    errorMessages={["Please enter email", "email is not valid"]}
                    helperText={<div style={{ height: "12px" }}></div>}
                  />
                </Grid>
                <Grid item md={3} xs={12} sm={3}>
                  <PhoneInputComponent
                    disabled={isEdit ? true : false}
                    text="Contact Number"
                    required={true}
                    minNumber={8}
                    handleChangeCountryCode={(e) =>
                      this.onChange({
                        ...userData,
                        countryCode: e.target.value,
                      })
                    }
                    handleChangeNumber={(e) => {
                      this.onChange({
                        ...userData,
                        contactNo: e.target.value,
                      });
                    }}
                    code={userData.countryCode}
                    countryCode={this.state.countryCode}
                    defaultCountryCode={this.state.defaultCountryCode}
                    numValue={userData.contactNo}
                  />

                  {/* <div style={{ display: "flex" }}>
                    <div>
                      <SelectComponentNative
                        value={this.state.code}
                        handleChange={this.handleCountryCodeChange}
                        countryCode={countryCode}
                      />
                    </div>
                    <div>
                      <TextValidator
                        disabled={isDisabled}
                        name="phone"
                        type="number"
                        id="phone"
                        size="small"
                        variant="outlined"
                        fullWidth
                        value={userData.contactNo}
                        onChange={(e) =>
                          this.onChange({
                            ...userData,
                            contactNo: e.target.value,
                          })
                        }
                        validators={["required"]}
                        errorMessages={["Please enter number"]}
                        helperText={<div style={{ height: "12px" }}></div>}
                      />
                    </div>
                  </div> */}

                  {/* <FormGroup row>
                    <Select
                      variant="outlined"
                      style={{
                        width: "40%",
                        height: "38px",
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                      }}
                      value={this.state.code}
                      onChange={(e) => this.handleCountryCodeChange(e)}
                    > */}
                  {/* {countryCode.map((item, id) => (
                        <option key={id} value={item.code}>
                          {item.code}&nbsp; {item.name}
                        </option>
                      ))} */}
                  {/* </Select>
                    <CustomNumberFeild
                      disabled={isDisabled}
                      name="phone"
                      type="number"
                      id="phone"
                      size="small"
                      variant="outlined"
                      fullWidth
                      value={userData.contactNo}
                      onChange={(e) =>
                        this.onChange({
                          ...userData,
                          contactNo: e.target.value,
                        })
                      }
                      validators={["required"]}
                      errorMessages={["Please enter number"]}
                      helperText={<div style={{ height: "12px" }}></div>}
                    />
                  </FormGroup> */}
                </Grid>

                {isEdit && (
                  <>
                    <Grid item md={3} xs={12} sm={3}>
                      <Typography
                        style={{ marginBottom: "2px" }}
                        variant="body2"
                      >
                        Created by
                      </Typography>
                      <TextField
                        disabled={true}
                        size="small"
                        variant="outlined"
                        fullWidth
                        value={userData.createdby}
                      />
                    </Grid>
                    <Grid item md={3} xs={12} sm={3}>
                      <Typography
                        style={{ marginBottom: "2px" }}
                        variant="body2"
                      >
                        Created on
                      </Typography>
                      <TextField
                        disabled={true}
                        size="small"
                        variant="outlined"
                        fullWidth
                        // value={moment
                        //   .unix(userData.createdon)
                        //   .format("DD-MM-YYYY, hh:MM A")}
                        value={helpers.date_Func(userData.createdon)}
                      />
                    </Grid>
                    {userData.updatedby && (
                      <Grid item md={3} xs={12} sm={3}>
                        <Typography
                          style={{ marginBottom: "2px" }}
                          variant="body2"
                        >
                          Updated by
                        </Typography>
                        <TextField
                          disabled={true}
                          size="small"
                          variant="outlined"
                          fullWidth
                          value={userData.updatedby}
                        />
                      </Grid>
                    )}
                    {userData.updatedon && (
                      <Grid item md={3} xs={12} sm={3}>
                        <Typography
                          style={{ marginBottom: "2px" }}
                          variant="body2"
                        >
                          Updated on
                        </Typography>
                        <TextField
                          disabled={true}
                          size="small"
                          variant="outlined"
                          Customer
                          fullWidth
                          // value={moment
                          //   .unix(userData.updatedon)
                          //   .format("DD-MM-YYYY, hh:MM A")}
                          value={helpers.date_Func(userData.updatedon)}
                        />
                      </Grid>
                    )}
                  </>
                )}
              </Grid>
            </Grid>
            <HelpdeskFAB />
          </Grid>
        </ValidatorForm>

        <Grid item md={12}>
          <div>
            <UserRoles
              handleUsersRoles={this.handleUsersRoles}
              rolesMap={this.state.userData.roles}
              isEdit={this.state.isEdit}
              rolesData={this.props.rolesData}
              handleDelete={this.handleDelete}
              searchRole={this.search_Role}
              removeRole={this.removeRole}
              isDisabled={this.state.isDisabled}
              editRoles={this.editRoles}
              {...this.props}
            />
          </div>
        </Grid>

        <Grid item md={12}>
          <div>
            <UserTeams {...this.props} teamsData={this.state.userData} />
          </div>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    editdata: state.userManagement.editdata,
    adddata: state.userManagement.adddata,
    rolesData: state.userManagement.rolesData,
    load: state.userManagement.load,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    editUserManagement: (userManagementId, payload) =>
      dispatch(actions.editUserManagement(userManagementId, payload)),
    addUserManagement: (payload) =>
      dispatch(actions.addUserManagement(payload)),
    getrolesData: (filter) =>
      dispatch(actions.fetchAllUserManagementRoles(filter)),
    update_Role: (type, id, data) => dispatch(update_User_Role(type, id, data)),
  };
};

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(withSnackbar(addUserManagementData));

const addUserManagement = connect(
  mapStateToProps,
  mapDispatchToProps
)(addUserManagementData);
export default withStyles(useStyles)(withSnackbar(addUserManagement));
