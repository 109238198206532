import * as types from "../actionTypes";
const selectTabReducer = (state = { userTab: 0 }, action) => {
  switch (action.type) {
    case types.USER_TAB_REQUEST:
      return { loading: true };
    case types.USER_TAB_SUCCESS:
      return {
        loading: false,
        success: true,
        userTab: action.payload,
      };
    case types.USER_TAB_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export default selectTabReducer;
