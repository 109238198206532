import React, { useEffect, useState } from "react";
import { CustomIconButton } from "../../components/Customized/index";
import { IconButton, Typography } from "@material-ui/core";
const days = [
  {
    active: true,
    key: "Sun",
    name: "Sunday",
  },
  {
    name: "Monday",
    key: "Mon",
    active: false,
  },
  {
    name: "Tuesday",
    key: "Tue",
    active: false,
  },
  {
    name: "Wednesday",
    active: false,
    key: "Wed",
  },
  {
    name: "Thursday",
    active: false,
    key: "Thu",
  },
  {
    name: "Friday",
    active: false,
    key: "Fri",
  },
  {
    name: "Saturday",
    active: false,
    key: "Sat",
  },
];

const PrintDay = ({ val }) => {
  return (
    <>
      <span
        style={{
          width: "50px",
          height: "50px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "10px",
          fontWeight: 800,
        }}
      >
        {val}
      </span>
    </>
  );
};

export const SelectDayComponent = (props) => {
  const { onChange, text, defaultValue, disabled, background } = props;
  const [daysArr, setDaysArr] = useState(days);

  useEffect(() => {
    let updateDaysArr = [...daysArr];
    updateDaysArr = updateDaysArr.map((day, id) => {
      if (day.name === defaultValue) {
        return {
          ...day,
          active: true,
        };
      } else {
        return {
          ...day,
          active: false,
        };
      }
    });
    setDaysArr([...updateDaysArr]);
  }, [defaultValue]);

  const setActiveOrInactive = (id, day, active) => {
    let nameOfDay = day.name;
    onChange && onChange(nameOfDay);
    if (defaultValue === undefined) {
      let allDays = [...daysArr];
      allDays = allDays.map((d, idx) => {
        if (idx === id) {
          return {
            ...d,
            active: active,
          };
        } else {
          return {
            ...d,
            active: false,
          };
        }
      });
      setDaysArr([...allDays]);
    }
  };

  return (
    <div>
      {text && (
        <Typography style={{ marginBottom: "2px" }} variant="body2">
          {text}
        </Typography>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        {daysArr.map((day, id) =>
          day.active ? (
            <CustomIconButton
              style={{
                marginRight: 10,
              }}
              key={id}
              disabled={disabled}
            >
              <PrintDay val={day.key} />
            </CustomIconButton>
          ) : (
            <IconButton
              key={id}
              disabled={disabled}
              onClick={() => setActiveOrInactive(id, day, true)}
              style={{
                border: "solid 1px #AFAFAF",
                marginRight: 10,
                width: "30px",
                height: "30px",
                backgroundColor: background || "",
              }}
            >
              <PrintDay val={day.key} />
            </IconButton>
          )
        )}
      </div>
    </div>
  );
};
