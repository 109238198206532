import React from "react";
import {
  Typography,
  Grid,
  Card,
  Divider,
  Badge,
  Avatar,
  CardContent,
} from "@material-ui/core";
import { RiDownload2Line } from "react-icons/ri";
import TotalChart from "./Totalchart";
const DataCard = (props) => {
  const { classes, app, itm } = props;
  const { bill, chartProps, name, total } = app || {
    name: "",
    bill: [],
    chartProps: {
      datasets: [
        {
          label: "Dataset",
          data: [],
          backgroundColor: [],
          hoverBackgroundColor: [],
          hoverOffset: 3,
        },
      ],
    },
  };
  // console.log(item, 45667);

  return (
    <>
      <Card elevation={0} style={{ border: "1px solid #D8D8D8", padding: 0 }}>
        <CardContent style={{ padding: "0 3px " }}>
          <Grid
            container
            spacing={0}
            alignItems="center"
            justifyContent="space-between"
            style={{ padding: 0, margin: 0 }}
          >
            <Grid item md={3}>
              <div className={classes.flxRowAlgnC}>
                <div></div>
                {/* <Avatar className={classes.small}>H</Avatar> */}
                <Typography
                  style={{ paddingLeft: 4, marginLeft: 10 }}
                  variant="caption"
                >
                  {name}
                </Typography>
              </div>
            </Grid>
            <Grid item md={4}>
              <Grid container justifyContent="center" alignItems="center">
                <Grid item md={6} style={{ paddingBottom: "5px" }}>
                  <TotalChart total={total} dataSet={chartProps} {...props} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={3}>
              {bill.map((itm, key) => (
                <div key={key} className={classes.dataStyle}>
                  <div className={classes.flxW100AlgnC}>
                    <div className={classes.flxW100AlgnC}>
                      <div
                        style={{
                          backgroundColor: itm.color,
                          padding: 5,
                          width: 10,
                          marginRight: 4,
                        }}
                      ></div>
                      <Typography variant="caption">{itm.name}</Typography>
                    </div>
                  </div>
                  <Typography
                    variant="caption"
                    style={{ width: "100%", textAlign: "end", marginRight: 10 }}
                  >
                    {itm.amount}
                  </Typography>
                </div>
              ))}
            </Grid>
            <Grid item style={{ paddingRight: 2 }}>
              {/* <RiDownload2Line /> */}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default DataCard;
