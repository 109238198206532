export const FETCHING_APPLICATIONS = "FETCHING_APPLICATIONS";
export const FETCHING_APPLICATIONS_SUCCESS = "FETCHING_APPLICATIONS_SUCCESS";
export const FECTH_APPLICATIONS_FAILED = "FECTH_APPLICATIONS_FAILED";
export const FETCH_APPLICATION_DETAILS = "FETCH_APPLICATION_DETAILS";
export const FETCH_APPLICATION_DETAILS_FAILED =
  "FETCH_APPLICATION_DETAILS_FAILED";
export const FETCH_APPLICATION_DETAILS_SUCCESS =
  "FETCH_APPLICATION_DETAILS_SUCCESS";

export const EDIT_ENVIRONMENT = "EDIT_ENVIRONMENT";
export const EDITED_ENVIRONMENT = "EDITED_ENVIRONMENT";
export const EDIT_ENVIRONMENT_FAILED = "EDIT_ENVIRONMENT_FAILED";

export const ADD_ENVIRONMENT = "ADD_ENVIRONMENT";
export const ENVIRONMENT_ADDED = "ENVIRONMENT_ADDED";
export const ADD_ENVIRONMENT_FAILED = "ADD_ENVIRONMENT_FAILED";

export const ONBOARD_APPLICATION = "ONBOARD_APPLICATION";
export const ONBOARDED_APPLICATION = "ONBOARDED_APPLICATION";
export const ONBOARDED_APPLICATION_FAILED = "ONBOARDED_APPLICATION_FAILED";
export const RESET_ONBOARD_SUBMIT = "RESET_ONBOARD_SUBMIT";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const PUBLISHED_APPLICATION = "PUBLISHED_APPLICATION ";
export const PUBLISHING_APPLICATION = "PUBLISHING_APPLICATION";
export const PUBLISH_APPLICATION_FAILED = "PUBLISH_APPLICATION_FAILED";

export const FETCHING_APPLICATIONS_API = "FETCHING_APPLICATIONS_API";
export const FETCHED_APPLICATIONS_API = "FETCHED_APPLICATIONS_API";
export const FETCHE_APPLICATIONS_API_FAILED = "FETCHE_APPLICATIONS_API_FAILED";

export const SAVE_OUTGOING_API = "SAVE_OUTGOING_API";
export const SAVED_OUTGOING_API = "SAVED_OUTGOING_API";
export const SAVE_OUTGOING_API_FAILED = "SAVE_OUTGOING_API_FAILED";

export const ADD_USAGE_PARAMS = "ADD_USAGE_PARAMS";
export const UPDATE_USAGE_PARAMS = "UPDATE_USAGE_PARAMS";

export const ADD_WEBHOOKS = "ADD_WEBHOOKS";
export const UPDATE_WEBHOOKS = "UPDATE_WEBHOOKS";

export const ADD_AUTH_PARAMS = "ADD_AUTH_PARAMS";
export const UPDATE_AUTH_PARAMS = "AUPDATE_AUTH_PARAMS";

export const ADD_MODULE = "ADD_MODULE";
export const UPDATE_MODULE = "UPDATE_MODULE";

export const ADD_ENVIRONMENTFORM = "ADD_ENVIRONMENTFORM";
export const UPDATE_ENVIRONMENTFORM = "UPDATE_ENVIRONMENTFORM";

export const ADD_API = "ADD_API";
export const UPDATE_API = "UPDATE_API";

export const ADD_BASIC_INFO = "ADD_BASIC_INFO";
export const ADD_AUTH_WEBHOOKS = "ADD_AUTH_WEBHOOKS";

export const ADD_BILLING_WEBHOOKS = "ADD_BILLING_WEBHOOKS";

export const CANCEL_APPLICATION = "CANCEL_APPLICATION";

export const FETCH_TEAMS_FOR_ONBOARD_APP = "FETCH_TEAMS_FOR_ONBOARD_APP";
export const FETCHED_TEAMS_FOR_ONBOARD_APP = "FETCHED_TEAMS_FOR_ONBOARD_APP";
export const FETCH_TEAMS_FOR_ONBOARD_APP_FAIL =
  "FETCH_TEAMS_FOR_ONBOARD_APP_FAIL";
export const ADD_TEAM_TO_ONBOARD_APP = "ADD_TEAMS_TO_ONBOARD_APP";
export const RESET_TEAM_IN_ONBOARD_APP = "RESET_TEAM_IN_ONBOARD_APP";
export const RESET_PROPS = "RESET_PROPS";

export const SET_CLICKED_TEAM = "SET_CLICKED_TEAM";
